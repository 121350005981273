import React from "react";
import FooterWrapper from "./Footer.style";

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="footer-container" style={{background:'#414782',color:'white',padding:'20px'}}>
        <p className="footer-text">
          © {new Date().getFullYear()}. All Rights Reserved to Mordor
          Intelligence.
        </p>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
