import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedReport: {},
  subscribedReport: {},
};

const libraryAssignment = createSlice({
  name: "libraryAssignment",
  initialState: initialState,
  reducers: {
    storeSelectedReport: (state, action) => {
      //Check if the action.payload.key is already in the state.selectedReport then add the action.payload.value to the existing value
      if (state.selectedReport.hasOwnProperty(action.payload.key)) {
        // Map over the existing array
        state.selectedReport[action.payload.key] = [...action.payload.value];
      }
      //Else add the action.payload.key and action.payload.value to the state.selectedReport
      else {
        state.selectedReport[action.payload.key] = action.payload.value;
      }

      const otherData = action.payload.otherData;
      if (otherData?.length > 0) {
        otherData.forEach((item) => {
          const key = item.hub_new_name;
          if (!state.selectedReport[key]) {
            // If the key does not exist, create it and set its value to an array containing the current item
            state.selectedReport[key] = [item];
          } else {
            // If the key does exist, find the index of an object with the same report_id as the current item
            const index = state.selectedReport[key].findIndex(
              (report) => report.id === item.id
            );
            if (index !== -1) {
              // If such an object exists, replace it with the current item
              state.selectedReport[key][index] = item;
            } else {
              // If no such object exists, add the current item to the array
              state.selectedReport[key].push(item);
            }
          }
        });
      }
    },
    storeSubscription: (state, action) => {
      const newData = action.payload.value;
      newData.forEach((item) => {
        const key = item.hub_new_name;
        if (!state.selectedReport[key]) {
          // If the key does not exist, create it and set its value to an array containing the current item
          state.selectedReport[key] = [item];
        } else {
          // If the key does exist, find the index of an object with the same report_id as the current item
          const index = state.selectedReport[key].findIndex(
            (report) => report.id === item.id
          );
          if (index !== -1) {
            // If such an object exists, replace it with the current item
            state.selectedReport[key][index] = item;
          } else {
            // If no such object exists, add the current item to the array
            state.selectedReport[key].push(item);
          }
        }
      });
    },
    clearSelectedReport: (state) => {
      state.selectedReport = {};
      state.subscribedReport = {};
    },
    updateSelectedReports: (state, action) => {
      const { key, value } = action.payload;
      if (state.selectedReport.hasOwnProperty(key)) {
        state.selectedReport[key] = value;
      } else {
        state.selectedReport[key] = value;
      }
    },
    updateSubscribedReports: (state, action) => {
      const newData = action.payload.value;
      state.subscribedReport = {};
      newData.forEach((item) => {
        const key = item.hub_new_name;
        if (!state.subscribedReport[key]) {
          // If the key does not exist, create it and set its value to an array containing the current item
          state.subscribedReport[key] = [item];
        } else {
          state.subscribedReport[key] = [
            ...state?.subscribedReport?.[key],
            item,
          ];
        }
      });
    },
    deleteSubscribedReport: (state, action) => {
      const newData = action.payload.value;
      newData.forEach((item) => {
        const key = item.hub_new_name;
        if (!state.selectedReport[key]) {
          return;
        } else {
          // If the key does exist, find the index of an object with the same report_id as the current item
          const index = state.selectedReport[key].findIndex(
            (report) => report.id === item.id
          );
          if (index !== -1) {
            // If such an object exists, then remove it
            state.selectedReport[key].splice(index, 1);
          }
        }
      });
    },
  },
});

export const {
  storeSelectedReport,
  clearSelectedReport,
  storeSubscription,
  updateSelectedReports,
  updateSubscribedReports,
  deleteSubscribedReport,
} = libraryAssignment.actions;
export default libraryAssignment.reducer;
