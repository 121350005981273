import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import _, { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import API from "../../utils/api";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Step,
  StepButton,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  useAssignPermissionMutation,
  useGetRelationShipManagerEmailQuery,
  useLazyCheckUserEmailQuery,
  useLazyGetParticularUserQuery,
  useLazyGetReportsQuery,
  useUpdateParticularUserMutation,
} from "../../redux/api/createUserApi";
import {
  ArrowForwardIos,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import RenderTableRows from "./RenderTableRows";
import RenderCards from "./RenderCards";
import {
  clearSelectedReport,
  storeSelectedReport,
  updateSelectedReports,
  updateSubscribedReports,
} from "../../redux/assignPermissionSlice";
import dayjs from "dayjs";
import RenderTableRows2 from "./RenderTableRows2";
import { useNavigate } from "react-router-dom";

const validateEmail = (email) => {
  const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return re.test(email);
};

const AssignPermission = ({ userData }) => {
  const cookies = new Cookies();

  let token = cookies.get("TOKEN");
  const { Base_URL, getHubList } = API;

  let config = {
    method: "get",
    headers: { authorization: `Bearer ${token}` },
    maxBodyLength: Infinity,
    url: `${Base_URL}${getHubList}`,
  };

  const {
    register,
    handleSubmit,
    formState,
    control,
    getValues,
    reset,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      email: "",
      password: "",
      relationshipManager: "",
      relationshipManagerEmail: "",
      company_name: "",
      accountType: "",
      hubSelect: "",
      hubAccessType: "",
      multipleHubSelect: [],
      subscription_date: null,
      expiry_date: null,
      trial_expiry_date: dayjs(),
      permission: [],
      accessType: "",
      searchText: "",
    },
  });
  const { errors } = formState;
  const accessTypeList = [
    {
      id: "trial",
      name: "Trial",
    },
    {
      id: "limited",
      name: "Limited",
    },
    {
      id: "full",
      name: "Full",
    },
  ];

  const permissionTypeList = [
    // {
    //   id: "Download Access",
    //   name: "Download Access",
    // },
    {
      id: "Dashboard",
      name: "Dashboard",
      child: ["Download Access", "View Access"],
    },
    {
      id: "Report",
      name: "Report",
      child: ["Download Access", "View Access"],
    },
    {
      id: "Datasheet",
      name: "Datasheet",
      child: ["Download Access", "View Access"],
    },
  ];

  const rowAccess = [
    {
      id: "Download Access",
      name: "Download Access",
    },
    {
      id: "View Access",
      name: "View Access",
    },
  ];

  const accountTypes = [
    {
      id: "owner",
      name: "Admin Access",
    },
    {
      id: "view only",
      name: "View Only",
    },
    {
      id: "view and download",
      name: "View and Download",
    },
  ];

  const hubAccessType = [
    {
      id: "companies",
      name: "Companies",
    },
    {
      id: "market share",
      name: "Market Share",
    },
    {
      id: "visualize",
      name: "Visualize",
    },
    {
      id: "master data",
      name: "Master Data",
    },
    {
      id: "indicators",
      name: "Indicators",
    },
  ];

  const [trigger, result] = useLazyGetReportsQuery();
  const [checkEmail, emailCheckResult] = useLazyCheckUserEmailQuery();
  const {
    data: relationshipManagerEmailData,
    isLoading: relationshipManagerEmailLoading,
    isError: relationshipManagerEmailError,
  } = useGetRelationShipManagerEmailQuery();
  const [getUserDetails, { data: userDetailsResult }] =
    useLazyGetParticularUserQuery();
  const [updateUser, { isLoading: isUpdatingUser }] =
    useUpdateParticularUserMutation();
  const {
    data: reportData,
    isLoading: reportDataLoading,
    isError: reportDataError,
  } = result;

  const isNavBarShrink = useSelector((store) => store.interface.isNavBarShrink);
  const [lastCheckedEmail, setLastCheckedEmail] = useState("");
  const [hubList, setHubList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [activeStep, setActiveStep] = React.useState(0);

  //Subscribed Reports pagination hooks
  const [subscribedPage, setSubscribedPage] = React.useState(0);
  const [subscribedRowsPerPage, setSubscribedRowsPerPage] = useState(5);

  const handleChangePageForSubscribed = (event, newPage) => {
    setSubscribedPage(newPage);
  };

  const handleMainChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setSubscribedRowsPerPage(parseInt(event.target.value, 10));
    setSubscribedPage(0);
  };

  const columns = [
    "ID",
    "Title",
    // "Access Type",
    "Subscription Date",
    "Expiry Date",
    "Published Year",
  ];

  const steps = ["Edit Data", "Review the Data"];

  const [stateColumns, setStateColumn] = useState(columns); //Used for storing realtime data column
  const [searchText, setSearchText] = useState(""); //Used for storing searchText
  const [finalSearchText, setFinalSearchText] = useState(""); //Used for storing searchText when button is clicked.
  const [searchData, setSearchData] = useState({
    hubSelect: "",
    accessType: "",
  });
  const [selected, setSelected] = React.useState([]); //Used for storing selected row data id
  const [selectedRowData, setSelectedRowData] = useState([]); //Used for storing selected row data

  const [selectedSub, setSelectedSub] = React.useState([]); //Used for storing subscribed selected row data id
  const [selectedSubData, setSelectedSubData] = useState([]); //Used for storing subscribed selected row data

  const [rowData, setRowData] = useState([]);
  const [subscribedRowData, setSubscribedRowData] = useState([]);
  const [otherSubscribedRowData, setOtherSubscribedRowData] = useState([]); //Used for storing other subscribed row data
  const [mainLoader, setMainLoader] = useState(false);
  const [completed, setCompleted] = React.useState({});
  const [categories, setCategories] = useState({});
  const [formatData, setFormatData] = useState([]);
  const dispatch = useDispatch();
  const previouslySelectedReport = useSelector(
    (state) => state.assignPermission.selectedReport
  );
  const subscribedReport = useSelector(
    (state) => state.assignPermission.subscribedReport
  );

  const [createUser, { isLoading: isCreatingUser }] =
    useAssignPermissionMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [multipleHubSelect, setMultipleHubSelect] = useState([]);
  const [multipleHubModule, setMultipleHubModule] = useState([]);
  const [newSelectedHub, setNewSelectedHub] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !searchData.hubSelect &&
      hubList?.length > 0 &&
      getValues("hubAccessType") === "limited" &&
      !userData?._id
    ) {
      setSearchData({
        ...searchData,
        hubSelect: hubList[0]?.id,
      });
    }
  }, [hubList, getValues("hubAccessType")]);

  useEffect(() => {
    if (searchData.hubSelect) {
      //Get the object from the previously selected report
      const hubName = hubList.find(
        (hub) => hub?.id === searchData?.hubSelect
      )?.name;
      const selectedReport = previouslySelectedReport[hubName];
      if (selectedReport) {
        setSelectedRowData(selectedReport);
        const copyReportData = reportData?.data?.reports?.map((value) => {
          //Remove the value which is already subscribed
          const isAlreadySubscribed = subscribedRowData.find(
            (item) => item.report_id === value.id
          );
          if (isAlreadySubscribed) {
            return null;
          }
          const selectedRow = selectedReport.find(
            (item) => item.id === value.id
          );
          if (selectedRow) {
            return {
              ...value,
              ...selectedRow,
            };
          } else {
            return {
              ...value,
              accessType: "Download Access", //Add additional Row
              permission: [
                {
                  id: "Dashboard",
                  name: "Dashboard",
                  child: "",
                },
              ],
              subscription_date: searchData.subscription_date ?? dayjs(),
              expiry_date: searchData.expiry_date ?? dayjs(),
            };
          }
        });
        //Remove the null value from the copyReportData
        setRowData(copyReportData.filter((item) => item !== null));

        setSelected(selectedReport.map((item) => item.id));
      }
    }
  }, [searchData.hubSelect, subscribedRowData]);

  useEffect(() => {
    if (reportData?.data?.reports?.length > 0) {
      setStateColumn(Object.keys(reportData?.data?.reports?.[0]));
      const hubName = hubList.find(
        (hub) => hub?.id === searchData?.hubSelect
      )?.name;
      if (previouslySelectedReport?.[hubName]?.length > 0) {
        const copyReportData = reportData?.data?.reports?.map((value) => {
          //Remove the value which is already subscribed
          const isAlreadySubscribed = subscribedRowData.find(
            (item) => item.report_id === value.id
          );
          if (isAlreadySubscribed) {
            return null;
          }
          const selectedReport = previouslySelectedReport[hubName];
          const selectedRow = selectedReport.find(
            (item) => item.id === value.id
          );
          if (selectedRow) {
            return {
              ...value,
              ...selectedRow,
            };
          } else {
            return {
              ...value,
              accessType: "Download Access", //Add additional Row
              permission: [
                {
                  id: "Dashboard",
                  name: "Dashboard",
                  child: "",
                },
              ],
              subscription_date: searchData.subscription_date ?? dayjs(),
              expiry_date: searchData.expiry_date ?? dayjs(),
            };
          }
        });
        setRowData(copyReportData.filter((item) => item !== null));
      } else {
        const copyReportData = reportData?.data?.reports?.map((value) => {
          //Remove the value which is already subscribed
          const isAlreadySubscribed = subscribedRowData.find(
            (item) => item.report_id === value.id
          );
          if (isAlreadySubscribed) {
            return null;
          }
          return {
            ...value,
            accessType: "Download Access", //Add additional Row
            permission: [
              {
                id: "Dashboard",
                name: "Dashboard",
                child: "",
              },
            ],
            subscription_date: searchData?.subscription_date?.isValid()
              ? searchData.subscription_date
              : dayjs(),
            expiry_date: searchData?.expiry_date?.isValid()
              ? searchData.expiry_date
              : dayjs(),
          };
        });
        setRowData(copyReportData.filter((item) => item !== null));
      }
    } else {
      setRowData([]);
    }
  }, [reportData]);

  useEffect(() => {
    if (searchData.hubSelect) {
      (async () => {
        setMainLoader(true);
        try {
          await trigger({
            // limit: rowsPerPage,
            // page: page + 1,
            searchText: finalSearchText,
            hubId: searchData?.hubSelect,
          });
        } catch (e) {
          console.log("ERROR:", e);
        } finally {
          setMainLoader(false);
        }
      })();
    }
  }, [finalSearchText, searchData?.hubSelect]);

  useEffect(() => {
    axios(config).then((res) => {
      const hubList = res?.data?.data;
      const sortedData = _.sortBy(hubList, "name");
      setHubList(sortedData);
    });
  }, []);

  useEffect(() => {
    if (selectedRowData.length > 0) {
      const formattedData = _.groupBy(selectedRowData, "hub_name");
      setFormatData(formattedData);
    }
  }, [selectedRowData]);

  useEffect(() => {
    //Component Did Remove reset the redux store
    return () => {
      dispatch(clearSelectedReport());
    };
  }, []);

  const fetchUserDetails = async (email) => {
    try {
      const res = await getUserDetails({ email });
      if (res.error) {
        alert(res.error.data.message);
      } else {
        return res.data;
      }
    } catch (e) {
      console.log("ERROR:", e);
    }
  };
  useEffect(() => {
    if (userData?._id) {
      setValue("name", userData?.name);
      setValue("email", userData?.email);
      fetchUserDetails(userData?.email);
    }
  }, []);

  useEffect(() => {
    if (userDetailsResult?.data) {
      const {
        access_type,
        hubs,
        account_type,
        email,
        password,
        relationship_manager,
        company_name,
        analyst_hours,
        expiry_date,
        subscribed_date,
        subscribed_reports,
      } = userDetailsResult?.data;
      setValue("hubAccessType", access_type);
      setValue("multipleHubSelect", hubs);
      setValue("accountType", account_type);
      setValue("email", email);
      setValue("password", password);
      // setValue("relationshipManager", relationship_manager?.[0]?.username);
      setValue("relationshipManagerEmail", relationship_manager?.[0]?.email);
      setValue("companyName", company_name);
      setValue("analystHours", analyst_hours);
      setValue("name", userData?.name);
      let subscriptionDate = null;
      let expiryDate = null;
      const removeNullHubs = hubs.filter((item) => item !== "");
      const allSameSubscribedOn = subscribed_reports?.every(
        (report, i, arr) =>
          i === 0 || report.subscribed_on === arr[i - 1].subscribed_on
      );
      const allSameExpiry = subscribed_reports?.every(
        (report, i, arr) =>
          i === 0 || report.report_expiry === arr[i - 1].report_expiry
      );
      const modifiedSubscribedReports = subscribed_reports.map((item) => {
        const newSubscribedDate = new Date(item?.subscribed_on);
        const newExpiryDate = new Date(item?.report_expiry);
        return {
          ...item,
          subscription_date: dayjs(newSubscribedDate),
          expiry_date: dayjs(newExpiryDate),
          id: item?.report_id,
          hub_new_name: hubList?.find((hub) => hub?.id === item?.hub_id)?.name,
        };
      });
      if (access_type === "limited") {
        setValue("hubSelect", removeNullHubs[0]);
        setSubscribedRowData(modifiedSubscribedReports);
        setSelectedSubData(modifiedSubscribedReports);
        setSelectedSub(modifiedSubscribedReports.map((item) => item.report_id));
        dispatch(
          updateSubscribedReports({
            value: modifiedSubscribedReports,
          })
        );
        if (allSameSubscribedOn) {
          subscriptionDate = new Date(subscribed_reports?.[0]?.subscribed_on);
          setValue(
            "subscription_date",
            dayjs(subscribed_reports?.[0]?.subscribed_on)
          );
        }
        if (allSameExpiry) {
          expiryDate = new Date(subscribed_reports?.[0]?.report_expiry);
          setValue(
            "expiry_date",
            dayjs(subscribed_reports?.[0]?.report_expiry)
          );
        }
      } else {
        setOtherSubscribedRowData(modifiedSubscribedReports);

        if (access_type === "full") {
          subscriptionDate = new Date(subscribed_date);
        }
        expiryDate = new Date(expiry_date);
        setMultipleHubSelect(removeNullHubs);
      }
      if (subscriptionDate) {
        setValue("subscription_date", dayjs(subscriptionDate));
      }
      if (expiryDate) {
        setValue("expiry_date", dayjs(expiryDate));
      }
      setSearchData({
        hubAccessType: access_type,
        name: userData?.name,
        hubSelect: removeNullHubs[0] ?? "",
        multipleHubSelect: access_type !== "limited" ? removeNullHubs : [],
        accountType: account_type,
        email: email,
        password: password,
        // relationshipManager: relationship_manager?.[0]?.username,
        relationshipManagerEmail: relationship_manager?.[0]?.email,
        companyName: company_name,
        analystHours: analyst_hours,
        subscription_date: dayjs(subscriptionDate),
        expiry_date: dayjs(expiryDate),
      });
      setNewSelectedHub(removeNullHubs);
    }
  }, [userDetailsResult, hubList]);

  useEffect(() => {
    if (multipleHubSelect?.length > 0) {
      setValue("hubSelect", multipleHubSelect[0]);
      setSearchData({
        ...searchData,
        hubSelect: multipleHubSelect[0],
      });
    }
  }, [multipleHubSelect]);
  useEffect(() => {
    if (
      getValues("hubAccessType") === "trial" ||
      getValues("hubAccessType") === "full"
    ) {
      // const totalSelectedHubs = [];
      // if (previouslySelectedReport) {
      //   for (const [key, value] of Object.entries(previouslySelectedReport)) {
      //     //First check if the key is present in the multipleHubSelect
      //     const isPresent = multipleHubSelect.includes(
      //       hubList.find((hub) => hub?.name === key)?.id
      //     );
      //     if (!isPresent) {
      //       totalSelectedHubs.push(
      //         hubList.find((hub) => hub?.name === key)?.id
      //       );
      //     }
      //   }
      // }
      // if (subscribedReport) {
      //   for (const [key, value] of Object.entries(subscribedReport)) {
      //     //First check if the key is present in the multipleHubSelect
      //     const isPresent = multipleHubSelect.includes(
      //       hubList.find((hub) => hub?.name === key)?.id
      //     );
      //     if (!isPresent) {
      //       totalSelectedHubs.push(
      //         hubList.find((hub) => hub?.name === key)?.id
      //       );
      //     }
      //   }
      // }
      // const removeDuplicateHubs = Array.from(new Set(totalSelectedHubs));
      // setMultipleHubSelect(
      //   multipleHubSelect?.length > 0
      //     ? multipleHubSelect
      //     : removeDuplicateHubs?.length > 0
      //     ? removeDuplicateHubs
      //     : userDetailsResult?.data?.hubs
      // );
      // setValue(
      //   "multipleHubSelect",
      //   multipleHubSelect?.length > 0
      //     ? multipleHubSelect
      //     : removeDuplicateHubs?.length > 0
      //     ? removeDuplicateHubs
      //     : userDetailsResult?.data?.hubs
      // );
      setMultipleHubSelect(
        multipleHubSelect?.length > 0
          ? multipleHubSelect ?? []
          : userDetailsResult?.data?.hubs ?? []
      );
      setValue(
        "multipleHubSelect",
        multipleHubSelect?.length > 0
          ? multipleHubSelect ?? []
          : userDetailsResult?.data?.hubs ?? []
      );
    }
    if (
      searchData.hubAccessType === "limited" &&
      otherSubscribedRowData?.length > 0
    ) {
      setSubscribedRowData(otherSubscribedRowData);
      setSelectedSubData(otherSubscribedRowData);
      setSelectedSub(otherSubscribedRowData.map((item) => item.id));
      dispatch(
        updateSubscribedReports({
          value: otherSubscribedRowData,
        })
      );
    }
  }, [getValues("hubAccessType")]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (e, index, key) => {
    setSearchText("");
    setFinalSearchText("");
    //If key is hubSelect then set the value of hubSelect in searchData and also form should be validated
    if (
      key === "hubSelect" &&
      searchData.hubSelect &&
      Object.keys(formState.errors).length === 0 &&
      selectedRowData.length > 0
    ) {
      dispatch(
        storeSelectedReport({
          // key: searchData.hubSelect,
          //Key should be selected hub name
          key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
          value: selectedRowData,
        })
      );
      setSelectedRowData([]);
      setSelected([]);
    }

    //If index is not present then set the value of accessType in searchData
    if (index === undefined) {
      const { name, value } = e.target;
      setSearchData({
        ...searchData,
        [name]: value,
      });
    }
    //If index is present then set the value of accessType in selectedRowData
    else {
      //Modify the rowData and set the value of accessType
      const updatedRowData = [...rowData];

      //If the key is permission then set the value of permission
      if (key === "permission") {
        updatedRowData[index][key] = e.target.value;
      } else {
        updatedRowData[index][key] = e.target.value;
      }
      setRowData(updatedRowData);
    }
  };

  const handleSubscribedChange = (e, index, key) => {
    //If key is hubSelect then set the value of hubSelect in searchData and also form should be validated
    if (
      key === "hubSelect" &&
      searchData.hubSelect &&
      Object.keys(formState.errors).length === 0 &&
      selectedSubData.length > 0
    ) {
      dispatch(
        storeSelectedReport({
          // key: searchData.hubSelect,
          //Key should be selected hub name
          key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
          value: selectedSubData,
        })
      );
      setSelectedSubData([]);
      setSelectedSub([]);
    }

    //If index is not present then set the value of accessType in searchData
    if (index === undefined) {
      const { name, value } = e.target;
      setSearchData({
        ...searchData,
        [name]: value,
      });
    }
    //If index is present then set the value of accessType in selectedRowData
    else {
      //Modify the rowData and set the value of accessType
      const updatedRowData = [...selectedSubData];

      //If the key is permission then set the value of permission
      if (key === "permission") {
        updatedRowData[index][key] = e.target.value;
      } else {
        updatedRowData[index][key] = e.target.value;
      }
      setSelectedSubData(updatedRowData);
    }
  };
  const handleCheckBoxChange = (value, index, name) => {
    const updatedRowData = [...rowData];
    if (value) {
      updatedRowData[index]["permission"].push({
        id: name,
        name,
        child: "",
      });
    } else {
      updatedRowData[index]["permission"] = updatedRowData[index][
        "permission"
      ].filter((item) => item.name !== name);
    }
    setRowData(updatedRowData);
  };

  const handleRadioChange = (event, rowIndex, name) => {
    let value = event.target.value;
    const updatedRowData = [...rowData];
    const selectedRow = rowData[rowIndex];
    const index = selectedRow.permission.findIndex(
      (item) => item.name === name
    );

    if (index !== -1) {
      updatedRowData[rowIndex].permission[index].child = value;
    }
    setRowData(updatedRowData);
  };

  const handleSearch = (value) => {
    setFinalSearchText(value);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowData;
      setSelected(newSelected.map((row) => row.id));
      const modifySelectedRowData = newSelected.map((row) => {
        return {
          ...row,
          subscription_date: searchData.subscription_date ?? dayjs(),
          expiry_date: searchData.expiry_date ?? dayjs(),
          hub: searchData.hubSelect,
          hub_id: row?.hub_id ?? searchData.hubSelect ?? null,
          hub_name:
            row?.hub_name ??
            hubList?.find((hub) => hub?.id === searchData?.hubSelect)?.name ??
            null,
          permission: searchData?.permission?.map((item) => {
            return {
              id: item,
              name: item,
              child: searchData?.accessType ?? item?.child,
            };
          }),
        };
      });
      setSelectedRowData(modifySelectedRowData);
      dispatch(
        updateSelectedReports({
          key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
          value: modifySelectedRowData,
        })
      );
      setRowData(modifySelectedRowData);
      const removedSubscribedRowData = modifySelectedRowData
        .map((row) => {
          const isAlreadySubscribed = subscribedRowData.find(
            (item) => item.report_id === row.id
          );
          if (isAlreadySubscribed) {
            //Remove the item from modifiedSelectedRowData
            return null;
          }
          return row;
        })
        ?.filter((item) => item !== null);
      dispatch(
        updateSelectedReports({
          key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
          value: removedSubscribedRowData,
        })
      );
      return;
    }
    dispatch(
      updateSelectedReports({
        key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
        value: [],
      })
    );
    setSelected([]);
    setSelectedRowData([]);
  };

  const handleSelectAllSubscribedClick = (event) => {
    if (event.target.checked) {
      const newSelected = subscribedRowData;
      setSelectedSub(newSelected.map((row) => row.report_id));
      const modifySelectedRowData = newSelected.map((row) => {
        return {
          ...row,
          subscription_date: searchData.subscription_date ?? dayjs(),
          expiry_date: searchData.expiry_date ?? dayjs(),
          hub: searchData.hubSelect,
          hub_id: row?.hub_id ?? searchData.hubSelect ?? null,
          hub_name:
            row?.hub_name ??
            hubList?.find((hub) => hub?.id === searchData?.hubSelect).name ??
            null,
          permission: searchData?.permission?.map((item) => {
            return {
              id: item,
              name: item,
              child: searchData?.accessType ?? item?.child,
            };
          }),
        };
      });
      setSelectedSubData(modifySelectedRowData);
      dispatch(
        updateSubscribedReports({
          value: modifySelectedRowData,
        })
      );
      return;
    }
    dispatch(
      updateSubscribedReports({
        value: [],
      })
    );
    setSelectedSub([]);
    setSelectedSubData([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isSubscribedSelected = (id) => selectedSub.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);

    //Set and update row data as per the search data
    const wholeRowData = rowData;
    //Find the index of the selected row in the rowData
    const rowDataIndex = wholeRowData.findIndex((row) => row.id === id);
    //Update the

    const selectedRow = rowData.find((row) => row.id === id);
    let newSelected = [];
    let newSelectedRowData = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      const newSelectedRow = {
        ...selectedRow,
        subscription_date: searchData?.subscription_date?.isValid()
          ? searchData.subscription_date
          : dayjs(),
        expiry_date: dayjs(searchData.expiry_date).isValid()
          ? searchData.expiry_date
          : dayjs(),
        hub: searchData.hubSelect,
        hub_id: searchData.hubSelect,
        hub_name: hubList?.find((hub) => hub?.id === searchData?.hubSelect)
          ?.name,
      };
      if (searchData?.permission?.length > 0) {
        newSelectedRow.permission = searchData.permission.map((item) => {
          return {
            id: item,
            name: item,
            child: searchData?.accessType ?? item?.child,
          };
        });
      }

      newSelectedRowData = [...selectedRowData, newSelectedRow];
      //Update the rowData with new selectedRowData
      const updatedRowData = [...rowData];
      updatedRowData[rowDataIndex] = newSelectedRow;
      setRowData(updatedRowData);
    } else {
      newSelected = selected.filter((selectedId) => selectedId !== id);
      newSelectedRowData = selectedRowData.filter((row) => row.id !== id);
    }

    setSelected(newSelected);
    setSelectedRowData(newSelectedRowData);

    dispatch(
      updateSelectedReports({
        key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
        value: newSelectedRowData,
      })
    );
  };

  const handleSubscribedClick = (event, id) => {
    const selectedIndex = selectedSub.indexOf(id);

    const wholeRowData = subscribedRowData;
    const rowDataIndex = wholeRowData.findIndex((row) => row.report_id === id);
    const selectedRow = subscribedRowData.find((row) => row.report_id === id);
    let newSelected = [];
    let newSelectedRowData = [];
    let unSelectedRowData = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSub, id);
      const newSelectedRow = {
        ...selectedRow,
        subscribed_on: searchData.subscription_date,
        report_expiry: searchData.expiry_date,
        hub: searchData.hubSelect,
        hub_id: searchData.hubSelect,
        hub_name: hubList?.find((hub) => hub?.id === searchData?.hubSelect)
          ?.name,
      };
      if (searchData?.permission?.length > 0) {
        newSelectedRow.permission = searchData.permission.map((item) => {
          return {
            id: item,
            name: item,
            child: searchData?.accessType ?? item?.child,
          };
        });
      }

      newSelectedRowData = [...selectedSubData, newSelectedRow];

      const updatedRowData = [...subscribedRowData];
      updatedRowData[rowDataIndex] = newSelectedRow;
      setSubscribedRowData(updatedRowData);
      dispatch(
        updateSubscribedReports({
          value: newSelectedRowData,
        })
      );
    } else {
      newSelected = selectedSub.filter((selectedId) => selectedId !== id);
      newSelectedRowData = selectedSubData.filter(
        (row) => row.report_id !== id
      );
    }
    //RowData which is not selected from the subscribedRowData
    // unSelectedRowData = subscribedRowData.filter((row) => {
    //   return !newSelectedRowData.some(
    //     (selectedRow) => selectedRow.report_id === row.report_id
    //   );
    // });
    dispatch(
      updateSubscribedReports({
        value: newSelectedRowData,
      })
    );
    setSelectedSub(newSelected);
    setSelectedSubData(newSelectedRowData);
    dispatch(
      updateSubscribedReports({
        value: newSelectedRowData,
      })
    );
    // if (unSelectedRowData.length > 0) {
    //   dispatch(deleteSubscribedReport({ value: unSelectedRowData }));
    // }
  };

  const handleDateChange = (date, index, key, report_id) => {
    const updatedRowData = [...rowData];
    updatedRowData[index] = { ...updatedRowData[index], [key]: date };

    // setSelectedRowData(
    //   selectedRowData.map((item, i) => {
    //     return item.id === report_id ? { ...item, [key]: date } : item;
    //   })
    // );

    //Modify selectedCloneRowData
    const modifedSelectedRowData = selectedRowData.map((item) => {
      return item.id === report_id ? { ...item, [key]: date } : item;
    });

    setRowData(updatedRowData);
    dispatch(
      updateSelectedReports({
        key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
        //Only need to pass selectedRowData
        value: modifedSelectedRowData,
      })
    );
    setSelectedRowData(modifedSelectedRowData);
  };

  const handleSubscribedDateChange = (date, index, key, report_id) => {
    let updatedRowData = [...selectedSubData];
    const newUpdatedRowData = updatedRowData.map((item, i) =>
      i === index ? { ...item, [key]: date } : item
    );
    setSelectedSubData(
      selectedSubData.map((item, i) =>
        item.report_id === report_id ? { ...item, [key]: date } : item
      )
    );
    //Update the subscribedRowData
    setSubscribedRowData(
      //Update the rowData which index is selected
      subscribedRowData.map((row, i) => {
        if (i === index) {
          return {
            ...row,
            [key]: date,
          };
        }
        return row;
      })
    );
    dispatch(
      updateSubscribedReports({
        value: newUpdatedRowData,
      })
    );
  };

  const updateSelectedRowData = (value, key) => {
    if (key === "permission") {
      //Set the value of permission in selectedRowData
      const computeChildValue =
        getValues("accountType") === "view only"
          ? "View Access"
          : getValues("accessType") ?? "";
      const newValue = value.map((item) => {
        return {
          id: item,
          name: item,
          child: computeChildValue,
        };
      });

      //Update the selectedRowData
      setRowData(
        //Update the rowData which value is selected
        rowData.map((row) => {
          if (selected.includes(row.id)) {
            return {
              ...row,
              [key]: newValue,
            };
          }
          return row;
        })
      );

      setSelectedRowData(
        selectedRowData.map((row) => {
          return {
            ...row,
            [key]: newValue,
          };
        })
      );

      // if (selectedRowData.length > 0) {
      dispatch(
        updateSelectedReports({
          key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
          value: selectedRowData,
        })
      );
      // }

      setSearchData({
        ...searchData,
        [key]: value,
      });

      if (subscribedRowData.length > 0) {
        //Update the subcribedRowData
        setSubscribedRowData(
          //Update the rowData which value is selected
          subscribedRowData.map((row) => {
            if (selectedSub.includes(row.report_id)) {
              return {
                ...row,
                [key]: newValue,
              };
            }
            return row;
          })
        );

        setSelectedSubData(
          selectedSubData.map((row) => {
            return {
              ...row,
              [key]: newValue,
            };
          })
        );
      }
      //Redux store update
      dispatch(
        updateSubscribedReports({
          value: selectedSubData,
        })
      );
    } else if (key === "accessType") {
      //Update the selectedRowData
      setRowData(
        //Update the rowData which value is selected
        rowData?.map((row) => {
          if (selected?.includes(row.id)) {
            return {
              ...row,
              permission: [
                ...row?.permission?.map((item) => {
                  return {
                    ...item,
                    child: value,
                  };
                }),
              ],
            };
          }
          return row;
        })
      );

      //Update the selectedRowData
      setSelectedRowData(
        selectedRowData?.map((row) => {
          return {
            ...row,
            permission: [
              ...row?.permission?.map((item) => {
                return {
                  ...item,
                  child: value,
                };
              }),
            ],
          };
        })
      );

      //Redux store update
      dispatch(
        updateSelectedReports({
          key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
          value: selectedRowData,
        })
      );

      if (subscribedRowData.length > 0) {
        //Update the subcribedRowData
        setSubscribedRowData(
          //Update the rowData which value is selected
          subscribedRowData.map((row) => {
            if (selectedSub.includes(row.report_id)) {
              return {
                ...row,
                permission: [
                  ...row.permission.map((item) => {
                    return {
                      ...item,
                      child: value,
                    };
                  }),
                ],
              };
            }
            return row;
          })
        );

        setSelectedSubData(
          selectedSubData.map((row) => {
            return {
              ...row,
              permission: [
                ...row.permission.map((item) => {
                  return {
                    ...item,
                    child: value,
                  };
                }),
              ],
            };
          })
        );
      }

      //Redux store update
      dispatch(
        updateSubscribedReports({
          value: selectedSubData,
        })
      );

      setSearchData({
        ...searchData,
        [key]: value,
      });
    } else {
      //Update the selectedRowData
      setRowData(
        //Update the rowData which value is selected
        rowData.map((row) => {
          if (selected.includes(row.id)) {
            return {
              ...row,
              [key]: value,
            };
          }
          return row;
        })
      );

      //Update the selectedRowData
      const selectedRowDataClone = selectedRowData.map((row) => {
        return {
          ...row,
          [key]: value,
        };
      });
      setSelectedRowData(selectedRowDataClone);

      //Redux store update
      dispatch(
        updateSelectedReports({
          key: hubList.find((hub) => hub?.id === searchData?.hubSelect)?.name,
          value: selectedRowDataClone,
        })
      );

      if (subscribedRowData.length > 0) {
        //Replace subscription_date and expiry_date with the new key
        let replaceKey = key;
        // if (replaceKey === "subscription_date") {
        //   replaceKey = "subscribed_on";
        // } else if (replaceKey === "expiry_date") {
        //   replaceKey = "report_expiry";
        // }

        //Update the subcribedRowData
        setSubscribedRowData(
          //Update the rowData which value is selected
          subscribedRowData.map((row) => {
            if (selectedSub.includes(row.report_id)) {
              return {
                ...row,
                [replaceKey]: value,
              };
            }
            return row;
          })
        );
        const selectedSubDataClone = selectedSubData.map((row) => {
          return {
            ...row,
            [replaceKey]: value,
          };
        });
        setSelectedSubData(selectedSubDataClone);
        dispatch(
          updateSubscribedReports({
            value: selectedSubDataClone,
          })
        );
        return;
      }

      //Redux store update
      dispatch(
        updateSubscribedReports({
          value: selectedSubData,
        })
      );

      setSearchData({
        ...searchData,
        [key]: value,
      });
    }
  };

  const handleStep = (step) => () => {
    if (step === 1) {
      //If the form is valid then move to the next step
      handleSubmit((data) => {
        if (selected.length > 0) {
          setActiveStep(1);
        } else {
          alert("Please select at least one report");
        }
      })();
    } else {
      setActiveStep(step);
    }
  };

  const clearAllState = () => {
    setSelected([]);
    setSelectedRowData([]);
    dispatch(clearSelectedReport());
    setSearchData({
      hubSelect: "",
      accessType: "",
    });
  };

  const computeData = () => {
    // const previousSelectedData = previouslySelectedReport;
    const result = _.mergeWith(
      {},
      previouslySelectedReport,
      subscribedReport,
      (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return _.unionBy(srcValue, objValue, "id");
        }
      }
    );
    const otherData = getValues();
    const reports = [];
    const hubs = [];

    Object.entries(result).forEach(([key, value]) => {
      if (key && !hubs.includes(key)) {
        // hubs.push(key)
        //We need to push the id of the hub
        hubs.push(hubList.find((hub) => hub?.name === key)?.id);
      }
      if (value.length > 0) {
        value.forEach((item) => {
          //Push item in reports array just modify subscription_date,expiry_date
          reports.push({
            title: item.title,
            slug: item.slug,
            id: item.id,
            permission: item.permission,
            subscription_date: item?.subscription_date
              ? dayjs(item?.subscription_date).format("YYYY/MM/DD")
              : "",
            expiry_date: item?.expiry_date
              ? dayjs(item?.expiry_date).format("YYYY/MM/DD")
              : "",
            hub_id: item.hub_id,
            account_type: otherData.accountType,
          });
        });
      }
    });
    const removeNullHubs = hubs.filter((item) => item !== "" || item !== null);
    const getRelationshipManagerName = relationshipManagerEmailData?.data?.find(
      (item) => item.email_id === otherData.relationshipManagerEmail
    );
    const data = {
      hubLevelAccessType: otherData.hubAccessType,
      companyName: otherData.companyName,
      hub_module_access: multipleHubModule,
      // hubs: hubs,
      //Remove null values from the hubs
      hubs: removeNullHubs,
      viewOnlyReports: [],
      analystHours: parseInt(otherData.analystHours),
      name: otherData.name,
      email: otherData.email,
      password: otherData.password,
      relationshipManagerEmail: otherData.relationshipManagerEmail,
      //Compute relationship manager name from the email
      relationshipManager: getRelationshipManagerName?.name ?? "",
      // reports: reports,
      reports: reports,
      account_type: otherData.accountType,
      subscription_date: otherData?.subscription_date
        ? // ? otherData.subscription_date.format("YYYY/MM/DD")
          dayjs(otherData.subscription_date).format("YYYY/MM/DD")
        : "",
      expiry_date: otherData?.expiry_date
        ? // ? otherData.expiry_date.format("YYYY/MM/DD")
          dayjs(otherData.expiry_date).format("YYYY/MM/DD")
        : "",
    };
    return data;
  };

  const handleAssignPermission = async () => {
    //Assign the permission to the user
    const apiData = computeData();
    if (userData?._id) {
      updateUser({
        id: userData._id,
        body: {
          ...apiData,
        },
      }).then((res) => {
        if (res.data) {
          window.alert(res?.data?.message ?? "User updated successfully");
          clearAllState();
          dispatch(clearSelectedReport());
          setMultipleHubSelect([]);
          setMultipleHubModule([]);
          reset();
          navigate("/home/user-list");
        } else {
          window.alert(res?.error?.data?.message ?? "Error for updating user");
        }
      });
    } else {
      createUser(apiData)
        .then((res) => {
          if (res.data) {
            window.alert(res?.data?.message ?? "User created successfully");
            clearAllState();
            dispatch(clearSelectedReport());
            setMultipleHubSelect([]);
            setMultipleHubModule([]);
            reset();
          } else {
            window.alert(
              res?.error?.data?.message ?? "Error for creating user"
            );
          }
          setActiveStep(0);
        })
        .catch((e) => {
          window.alert(e?.message ?? "Error for creating user");
        });
    }
  };

  const handleAssignPermissionToHub = async () => {
    //Assign the permission to the user
    //If the form is valid then move to the next step
    const apiData = computeData();
    const subscriptionDate =
      apiData.hubLevelAccessType === "trial" ? "" : apiData.subscription_date;
    if (userData?._id) {
      updateUser({
        id: userData._id,
        body: {
          ...apiData,
          hubs: multipleHubSelect,
          subscription_date: subscriptionDate,
          reports: [],
        },
      }).then((res) => {
        if (res.data) {
          window.alert(res?.data?.message ?? "User updated successfully");
          clearAllState();
          dispatch(clearSelectedReport());
          setMultipleHubSelect([]);
          setMultipleHubModule([]);
          reset();
          navigate("/home/user-list");
        } else {
          window.alert(res?.error?.data?.message ?? "Error for updating user");
        }
      });
    } else {
      createUser({
        ...apiData,
        hubs: multipleHubSelect,
        subscription_date: subscriptionDate,
        reports: [],
      })
        .then((res) => {
          if (res.data) {
            window.alert(res?.data?.message ?? "User created successfully");
            clearAllState();
            dispatch(clearSelectedReport());
            setMultipleHubSelect([]);
            setMultipleHubModule([]);
            reset();
          } else {
            window.alert(
              res?.error?.data?.message ?? "Error for creating user"
            );
          }
        })
        .catch((e) => {
          window.alert(e?.message ?? "Error for creating user");
        });
    }
  };
  const debouncedSearch = debounce(handleSearch, 300);
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ flex: isNavBarShrink ? "0 1 96%" : " 0 1 85%" }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton
                  disabled={
                    index === 0 || (index === 1 && selected.length > 0)
                      ? false
                      : true
                  }
                  color="inherit"
                  onClick={handleStep(index)}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <Box sx={{ marginTop: "1rem" }}>
              <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                    error={errors.hubAccessType}
                  >
                    <InputLabel id="access-type">
                      Hub Level Access Type
                    </InputLabel>
                    <Controller
                      name="hubAccessType"
                      control={control}
                      defaultValue=""
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Select
                          id="hubAccessType"
                          labelId="hubAccess-type"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                            updateSelectedRowData(
                              e.target.value,
                              "hubAccessType"
                            );
                          }}
                          // error={errors.hubAccessType}
                          // required
                        >
                          {accessTypeList?.map((hub) => (
                            <MenuItem key={hub.id} value={hub.id}>
                              {hub.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.hubAccessType && (
                      <FormHelperText>
                        {errors.hubAccessType.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                    error={errors.multipleHubModule}
                  >
                    <InputLabel
                      id="hub-select"
                      error={errors?.multipleHubModule ? true : false}
                    >
                      Hub Module Access
                    </InputLabel>
                    <Controller
                      name="multipleHubModule"
                      control={control}
                      defaultValue={searchData["multipleHubModule"] ?? []}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <Select
                          id="multipleHubModule"
                          labelId="multiple-module-select"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e); // call the onChange prop from react-hook-form
                            setMultipleHubModule(e.target.value); // call your custom onChange handler
                          }}
                          name="multipleHubModule"
                          value={multipleHubModule}
                          multiple
                          renderValue={(selected) =>
                            selected
                              .map((value) => {
                                const hub = hubAccessType.find(
                                  (hub) => hub.id === value
                                );
                                return hub ? hub.name : "";
                              })
                              .join(", ")
                          }
                          error={errors.multipleHubModule}
                        >
                          {hubAccessType?.map((hub) => (
                            <MenuItem key={hub.id} value={hub.id}>
                              <Checkbox
                                checked={field.value.indexOf(hub.id) > -1}
                              />
                              <ListItemText primary={hub.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.multipleHubModule && (
                      <FormHelperText error>
                        {errors.multipleHubModule.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {getValues("hubAccessType") === "limited" ? (
                  <Grid item sm={12} md={3}>
                    <FormControl
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                      required
                      error={errors.hubSelect}
                    >
                      <InputLabel id="hub-select">Select Hub</InputLabel>
                      <Select
                        {...register("hubSelect", {
                          required: "This field is required",
                        })}
                        id="hubSelect"
                        onChange={(e) =>
                          handleChange(e, undefined, "hubSelect")
                        }
                        value={searchData["hubSelect"]}
                        name="hubSelect"
                        error={errors.hubSelect}
                        required
                      >
                        {hubList?.map((hub) => (
                          <MenuItem key={hub.id} value={hub.id}>
                            {hub.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.hubSelect && (
                        <FormHelperText>This field is required</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item sm={12} md={3}>
                    <FormControl
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                      required
                      error={errors.hubSelect}
                    >
                      <InputLabel
                        id="hub-select"
                        error={errors?.multipleHubSelect ? true : false}
                      >
                        Select Hub
                      </InputLabel>
                      <Controller
                        name="multipleHubSelect"
                        control={control}
                        defaultValue={searchData["multipleHubSelect"] ?? []}
                        rules={{
                          required:
                            getValues("hubAccessType") === "limited"
                              ? false
                              : "This field is required",
                        }}
                        render={({ field }) => (
                          <Select
                            name="multipleHubSelect"
                            id="multipleHubSelect"
                            labelId="hub-select"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e); // call the onChange prop from react-hook-form
                              setMultipleHubSelect(e.target.value); // call your custom onChange handler
                            }}
                            value={multipleHubSelect}
                            multiple
                            renderValue={(selected) =>
                              selected
                                .map((value) => {
                                  const hub = hubList.find(
                                    (hub) => hub.id === value
                                  );
                                  return hub ? hub.name : "";
                                })
                                .join(", ")
                            }
                            error={errors.multipleHubSelect}
                          >
                            {hubList?.map((hub) => (
                              <MenuItem key={hub.id} value={hub.id}>
                                <Checkbox
                                  checked={field.value.indexOf(hub.id) > -1}
                                />
                                <ListItemText primary={hub.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.multipleHubSelect && (
                        <FormHelperText error>
                          {errors.multipleHubSelect.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}

                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                    error={errors.accountType}
                  >
                    <InputLabel id="access-type">Account Type</InputLabel>
                    <Controller
                      name="accountType"
                      control={control}
                      defaultValue=""
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Select
                          id="accountType"
                          labelId="account-type"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                            updateSelectedRowData(
                              e.target.value,
                              "accountType"
                            );
                            if (e.target.value === "view only") {
                              setValue("accessType", "View Access");
                              updateSelectedRowData(
                                "View Access",
                                "accessType"
                              );
                            }
                          }}
                        >
                          {accountTypes?.map?.((hub) => (
                            <MenuItem key={hub.id} value={hub.id}>
                              {hub.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.accountType && (
                      <FormHelperText>
                        {errors.accountType.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {false ? (
                  <>
                    <Grid item sm={12} md={3}>
                      <FormControl
                        variant="standard"
                        sx={{
                          width: "100%",
                        }}
                        // required
                        // error={errors.accessType}
                      >
                        <InputLabel id="access-type">
                          Report Level Access Type
                        </InputLabel>
                        <Controller
                          name="accessType"
                          control={control}
                          defaultValue={searchData["accessType"] ?? ""}
                          disabled={getValues("accountType") === "view only"}
                          // disabled={getValues("accountType") === "view only"}
                          render={({ field }) => (
                            <Select
                              id="accessType"
                              labelId="access-type"
                              {...field}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                updateSelectedRowData(
                                  e.target.value,
                                  "accessType"
                                );
                              }}
                              // error={errors.accessType}
                              // required
                            >
                              {rowAccess?.map((hub) => (
                                <MenuItem key={hub.id} value={hub.id}>
                                  {hub.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {/* {errors.accessType && (
                      <FormHelperText>
                        {errors.accessType.message}
                      </FormHelperText>
                    )} */}
                      </FormControl>
                    </Grid>

                    <Grid item sm={12} md={3}>
                      <FormControl
                        variant="standard"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <InputLabel id="access-type">Module Type</InputLabel>
                        <Controller
                          name="permission"
                          control={control}
                          defaultValue={searchData["permission"] ?? []}
                          render={({ field }) => (
                            <Select
                              id="permission"
                              labelId="access-type"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e); // call the onChange prop from react-hook-form
                                updateSelectedRowData(
                                  e.target.value,
                                  "permission"
                                ); // call your custom onChange handler
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              multiple
                              renderValue={(selected) => selected.join(", ")}
                            >
                              {permissionTypeList?.map((hub) => (
                                <MenuItem key={hub.id} value={hub.id}>
                                  <Checkbox
                                    checked={field.value.indexOf(hub.id) > -1}
                                  />
                                  <ListItemText primary={hub.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </>
                ) : null}

                {searchData?.hubAccessType !== "trial" && (
                  <Grid item sm={12} md={3}>
                    <FormControl
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                      required={searchData?.hubAccessType !== "trial"}
                      error={errors.subscription_date}
                    >
                      <Controller
                        name="subscription_date"
                        control={control}
                        defaultValue={null}
                        rules={{
                          required:
                            searchData?.hubAccessType === "limited"
                              ? false
                              : "Subscription Date is required",
                        }}
                        render={({ field }) => (
                          <DatePicker
                            slotProps={{
                              textField: {
                                variant: "standard",
                                required:
                                  searchData?.hubAccessType === "limited"
                                    ? false
                                    : true,
                                error: errors.subscription_date,
                              },
                            }}
                            label="Subscription Date"
                            onChange={(newDate) => {
                              field.onChange(newDate); // call the onChange prop from react-hook-form
                              updateSelectedRowData(
                                newDate,
                                "subscription_date"
                              ); // call your custom onChange handler
                            }}
                            format="YYYY/MM/DD"
                            value={field.value} // set the value from react-hook-form
                            disablePast
                          />
                        )}
                      />
                      {errors.subscription_date && (
                        <FormHelperText>
                          {errors.subscription_date.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required={searchData?.hubAccessType !== "trial"}
                    error={errors.expiry_date}
                  >
                    <Controller
                      name="expiry_date"
                      control={control}
                      defaultValue={null}
                      rules={{
                        required:
                          searchData?.hubAccessType === "limited"
                            ? false
                            : "Expiry Date is required",
                        validate: (value) => {
                          if (
                            searchData?.hubAccessType === "limited" &&
                            value
                          ) {
                            const subscriptionDate =
                              getValues("subscription_date");
                            if (value < subscriptionDate) {
                              return "Expiry Date should be greater or equal then Subscription Date";
                            }
                          } else if (searchData?.hubAccessType === "full") {
                            const subscriptionDate =
                              getValues("subscription_date") ?? Date.now();
                            //Expiry Date should be greater or equal then Subscription Date
                            if (value < subscriptionDate) {
                              return "Expiry Date should be greater or equal then Subscription Date";
                            }
                          } else if (searchData?.hubAccessType === "trial") {
                            const compareDate = Date.now();

                            if (value < compareDate) {
                              return "Expiry Date should be greater or equal then Current Date";
                            }
                          }
                        },
                      }}
                      render={({ field }) => (
                        <DatePicker
                          slotProps={{
                            textField: {
                              variant: "standard",
                              required:
                                searchData?.hubAccessType === "limited"
                                  ? false
                                  : true,
                              error: errors.expiry_date,
                            },
                          }}
                          label="Expiry Date"
                          onChange={(newDate) => {
                            field.onChange(newDate); // call the onChange prop from react-hook-form
                            updateSelectedRowData(newDate, "expiry_date"); // call your custom onChange handler
                          }}
                          format="YYYY/MM/DD"
                          value={field.value} // set the value from react-hook-form
                          disablePast
                          minDate={getValues("subscription_date")}
                        />
                      )}
                    />
                    {errors.expiry_date && (
                      <FormHelperText>
                        {errors.expiry_date.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                  >
                    <TextField
                      label="Name"
                      key="name-field"
                      name="name"
                      type="text"
                      variant="standard"
                      disabled={userData?._id}
                      error={errors.name}
                      helperText={errors.name && errors.name.message}
                      required
                      {...register("name", {
                        required: "This field is required",
                        //Only allow alphabets and can't add space at only on start
                        pattern: {
                          value: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                          message: "Invalid Name",
                        },
                      })}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                  >
                    <TextField
                      label="Email"
                      disabled={userData?._id}
                      key="email-field"
                      name="email"
                      type="email"
                      variant="standard"
                      required
                      error={errors.email}
                      helperText={errors.email && errors.email.message}
                      {...register("email", {
                        required: "This field is required",
                        validate: (value) =>
                          validateEmail(value) || "Invalid email",
                      })}
                      onBlur={async () => {
                        const currentEmail = getValues("email");
                        if (errors.email || currentEmail === lastCheckedEmail)
                          return;

                        setLastCheckedEmail(currentEmail);
                        const res = await checkEmail({
                          email: getValues("email"),
                        });
                        if (res?.data?.message) {
                          alert(res?.data?.message);
                        } else {
                          alert(res?.error?.data?.message);
                        }
                      }}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <IconButton
                      //         disabled={
                      //           errors.email ||
                      //           !getValues("email") ||
                      //           userData?._id
                      //         }
                      //       >
                      //         <DoneOutlineIcon
                      //           onClick={async () => {
                      //             const res = await checkEmail({
                      //               email: getValues("email"),
                      //             });
                      //             if (res?.data?.message) {
                      //               alert(res?.data?.message);
                      //             } else {
                      //               alert(res?.error?.data?.message);
                      //             }
                      //           }}
                      //         />
                      //       </IconButton>
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                  >
                    <Controller
                      name="password"
                      control={control} // control prop from useForm
                      defaultValue=""
                      rules={{
                        required: "This field is required",
                        minLength: {
                          value: 8,
                          message: "Password should be greater than 8",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          disabled={userData?._id}
                          label="Password"
                          key="password-field"
                          type={showPassword ? "text" : "password"}
                          variant="standard"
                          error={!!errors.password}
                          helperText={
                            errors.password && errors.password.message
                          }
                          {...field}
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  disabled={userData?._id}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                  >
                    <Controller
                      name="relationshipManager"
                      control={control} // control prop from useForm
                      defaultValue={searchData.relationshipManager ?? ""}
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                          message: "Invalid Relationship Manager Name",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          label="Relationship Manager Name"
                          key="relationshipManager-field"
                          type="text"
                          variant="standard"
                          error={!!errors.relationshipManager}
                          helperText={
                            errors.relationshipManager &&
                            errors.relationshipManager.message
                          }
                          {...field}
                          required
                        />
                      )}
                    />
                  </FormControl>
                </Grid> */}

                {/* <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                  >
                    <Controller
                      name="relationshipManagerEmail"
                      control={control} // control prop from useForm
                      defaultValue={searchData.relationshipManagerEmail ?? ""}
                      rules={{
                        required: "This field is required",
                        validate: (value) =>
                          validateEmail(value) || "Invalid email",
                      }}
                      render={({ field }) => (
                        <TextField
                          label="Relationship Manager Email"
                          key="managerEmail-field"
                          type="email"
                          variant="standard"
                          error={!!errors.relationshipManagerEmail}
                          helperText={
                            errors.relationshipManagerEmail &&
                            errors.relationshipManagerEmail.message
                          }
                          {...field}
                          required
                        />
                      )}
                    />
                  </FormControl>
                </Grid> */}

                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                    error={errors.relationshipManagerEmail}
                  >
                    <InputLabel id="manager-email">
                      Relationship Manager
                    </InputLabel>
                    <Controller
                      name="relationshipManagerEmail"
                      control={control}
                      defaultValue=""
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Select
                          id="relationshipManagerEmail"
                          labelId="manager-email"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                            updateSelectedRowData(
                              e.target.value,
                              "relationshipManagerEmail"
                            );
                          }}
                        >
                          {relationshipManagerEmailData?.data?.map(
                            (manager, index) => (
                              <MenuItem key={index} value={manager.email_id}>
                                {manager.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      )}
                    />
                    {errors.relationshipManagerEmail && (
                      <FormHelperText>This field is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                  >
                    <Controller
                      name="companyName"
                      control={control} // control prop from useForm
                      defaultValue={searchData.companyName ?? ""}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          label="Company Name"
                          key="name-field"
                          type="text"
                          variant="standard"
                          error={!!errors.companyName}
                          helperText={
                            errors.companyName && errors.companyName.message
                          }
                          {...field}
                          required
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={3}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    required
                    error={errors.analystHours}
                  >
                    <Controller
                      name="analystHours"
                      control={control}
                      defaultValue={null}
                      rules={{
                        required: "Analyst Hours is required",
                        min: {
                          value: 1,
                          message: "Analyst Hours should be greater then 0",
                        },
                        max: {
                          value: 1000,
                          message: "Analyst Hours should be less than 1000",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          id="outlined-number"
                          label="Analyst Hours"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          required
                          error={!!errors.analystHours}
                          onChange={(e) => {
                            field.onChange(e); // call the onChange prop from react-hook-form
                            updateSelectedRowData(
                              e.target.value,
                              "analystHours"
                            ); // call your custom onChange handler
                          }}
                          value={field.value} // set the value from react-hook-form
                          min={1}
                        />
                      )}
                    />
                    {errors.analystHours && (
                      <FormHelperText>
                        {errors.analystHours.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              {getValues()?.["hubAccessType"] === "limited" ? (
                <>
                  <Grid item sm={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <TextField
                        label="Search Reports"
                        key="search-field"
                        name="searchText"
                        value={searchText}
                        type="text"
                        onChange={(e) => {
                          const value = e.target.value;
                          setSearchText(value);
                          debouncedSearch(value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleSearch}>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>

                  {selected.length > 0 ? (
                    <Typography
                      sx={{ flex: "1 1 100%" }}
                      color="inherit"
                      variant="subtitle1"
                      component="div"
                    >
                      {selected.length} Report selected
                    </Typography>
                  ) : null}
                  <Button />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      variant="outlined"
                      endIcon={<ArrowForwardIos />}
                      onClick={() => {
                        const prevLength = Object.keys(
                          previouslySelectedReport
                        ).length;
                        const subLength = Object.keys(subscribedReport).length;

                        //If the form is valid then move to the next step
                        handleSubmit((data) => {
                          if (prevLength || subLength) {
                            const newMappedData = selectedSubData?.map(
                              (item) => {
                                return {
                                  ...item,
                                  subscription_date: item?.subscribed_on,
                                  expiry_date: item?.report_expiry,
                                  id: item?.report_id,
                                  hub_new_name: hubList.find(
                                    (hub) => hub?.id === item?.hub_id
                                  )?.name,
                                };
                              }
                            );
                            // dispatch(
                            //   storeSelectedReport({
                            //     // key: searchData.hubSelect,
                            //     //Key should be selected hub name
                            //     key: hubList.find(
                            //       (hub) => hub?.id === searchData?.hubSelect
                            //     )?.name,
                            //     value: [...selectedRowData],
                            //     otherData: newMappedData,
                            //   })
                            // );
                            if (searchData?.hubAccessType === "limited") {
                              // dispatch(
                              //   storeSubscription({
                              //     value: [...selectedRowData, ...newMappedData],
                              //   })
                              // );
                            }
                            setActiveStep(1);
                          } else {
                            alert("Please select at least one report");
                          }
                        })();
                      }}
                      // disabled={selected.length === 0}
                    >
                      Review the Data
                    </Button>
                  </Box>
                  {userData?._id &&
                  searchData?.hubAccessType === "limited" &&
                  userDetailsResult?.data?.subscribed_reports ? (
                    <>
                      <Typography variant="h5">Subscribed Report:</Typography>
                      <TableContainer
                        sx={{
                          marginTop: "1rem",
                          border: "0.5px solid",
                        }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minHeight: "400px", position: "relative" }}
                        >
                          {false ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : userDetailsResult?.data?.subscribed_reports
                              ?.length > 0 ? (
                            <TableBody>
                              {subscribedRowData
                                ?.slice(
                                  subscribedPage * subscribedRowsPerPage,
                                  subscribedPage * subscribedRowsPerPage +
                                    subscribedRowsPerPage
                                )
                                .map((row, rowIndex) => {
                                  return (
                                    <RenderTableRows2
                                      key={row._id}
                                      labelId={row._id}
                                      row={row}
                                      rowIndex={rowIndex}
                                      isItemSelected={isSubscribedSelected(
                                        row.report_id
                                      )}
                                      handleClick={handleSubscribedClick}
                                      handleChange={handleSubscribedChange}
                                      handleCheckBoxChange={
                                        handleCheckBoxChange
                                      }
                                      handleRadioChange={handleRadioChange}
                                      handleDateChange={
                                        handleSubscribedDateChange
                                      }
                                      rowAccess={rowAccess}
                                      permissionTypeList={permissionTypeList}
                                      isViewAccountType={
                                        getValues("accountType") === "view only"
                                      }
                                    />
                                  );
                                })}
                            </TableBody>
                          ) : (
                            <Typography
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "47%",
                              }}
                            >
                              No Data Found!
                            </Typography>
                          )}

                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  indeterminate={
                                    selectedSub.length > 0 &&
                                    selectedSub.length < rowData?.length
                                  }
                                  checked={
                                    selectedSubData?.length > 0 &&
                                    selectedSub.length ===
                                      selectedSubData?.length
                                  }
                                  onChange={handleSelectAllSubscribedClick}
                                  inputProps={{
                                    "aria-label": "select all desserts",
                                  }}
                                  // disabled={searchData}
                                />
                              </TableCell>
                              {columns.map((column) => (
                                <TableCell key={column}>{column}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={subscribedRowData?.length}
                          rowsPerPage={subscribedRowsPerPage}
                          page={subscribedPage}
                          onPageChange={handleChangePageForSubscribed}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableContainer>
                    </>
                  ) : null}
                  {rowData?.length > 0 &&
                  rowData.every((item) => item !== null) ? (
                    <>
                      <Typography
                        style={{
                          marginTop: "1rem",
                        }}
                        variant="h5"
                      >
                        Select Report:
                      </Typography>
                      <TableContainer
                        sx={{
                          marginTop: "1rem",
                          border: "0.5px solid",
                        }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minHeight: "400px", position: "relative" }}
                        >
                          {mainLoader || reportDataLoading ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : rowData?.length > 0 &&
                            rowData.every((item) => item !== null) ? (
                            <TableBody>
                              {rowData
                                ?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                ?.map((row, rowIndex) => {
                                  if (!row?.id) return null;
                                  if (subscribedRowData?.length > 0) {
                                    const isAlreadySubscribed =
                                      subscribedRowData.find(
                                        (item) => item.report_id === row?.id
                                      );
                                    if (isAlreadySubscribed) {
                                      return null;
                                    }
                                  }
                                  return (
                                    <RenderTableRows
                                      key={row.id}
                                      labelId={row.id}
                                      row={row}
                                      rowIndex={rowIndex}
                                      isItemSelected={isSelected(row.id)}
                                      handleClick={handleClick}
                                      handleChange={handleChange}
                                      handleCheckBoxChange={
                                        handleCheckBoxChange
                                      }
                                      handleRadioChange={handleRadioChange}
                                      handleDateChange={handleDateChange}
                                      rowAccess={rowAccess}
                                      permissionTypeList={permissionTypeList}
                                      isViewAccountType={
                                        getValues("accountType") === "view only"
                                      }
                                      selectedHub={searchData.hubSelect}
                                    />
                                  );
                                })}
                            </TableBody>
                          ) : (
                            <Typography
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "47%",
                              }}
                            >
                              No Data Found!
                            </Typography>
                          )}

                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  indeterminate={
                                    selected.length > 0 &&
                                    selected.length < rowData?.length
                                  }
                                  checked={
                                    rowData?.length > 0 &&
                                    selected.length === rowData?.length
                                  }
                                  onChange={handleSelectAllClick}
                                  inputProps={{
                                    "aria-label": "select all desserts",
                                  }}
                                  disabled={!searchData.hubSelect}
                                />
                              </TableCell>
                              {columns.map((column) => (
                                <TableCell key={column}>{column}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[rowsPerPage]}
                          component="div"
                          style={{ justifyContent: "center", display: "flex" }}
                          count={rowData?.length ?? 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleMainChangeRowsPerPage}
                        />
                      </TableContainer>
                    </>
                  ) : null}
                </>
              ) : (
                // <Button
                //   sx={{
                //     textTransform: "capitalize",
                //   }}
                //   variant="contained"
                //   onClick={() => handleAssignPermissionToHub()}
                // >
                //   Assign Permission
                // </Button>
                <Button
                  sx={{ textTransform: "capitalize", marginTop: "0.5rem" }}
                  variant="contained"
                  onClick={() => {
                    //If the form is valid then move to the next step
                    handleSubmit(() => {
                      handleAssignPermissionToHub();
                    })();
                  }}
                  // disabled={selected.length === 0}
                >
                  {userData ? "Update User" : "Create User"}
                </Button>
              )}
            </Box>
          ) : (
            // Need to show the review selected data using card
            <Box sx={{ marginTop: "2rem" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    marginBottom: "1rem",
                  }}
                  variant="contained"
                  color="success"
                  endIcon={<ArrowForwardIos />}
                  onClick={handleAssignPermission}
                >
                  Confirm
                </Button>
              </Box>
              <RenderCards
                formatData={{
                  accessType: searchData.hubAccessType,
                }}
              />
            </Box>
          )}
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default AssignPermission;
