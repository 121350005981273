import React from "react";
import { Input, List, Tooltip } from "antd";
import UserReportsListWrapper from "./UserReportsList.style";
import { ArrowLeftOutlined, FileAddOutlined } from "@ant-design/icons";

// const sampleData = [
//   {
//     report_id: "RPT001",
//     title: "Report 1",
//   },
//   {
//     report_id: "RPT002",
//     title: "Report 2",
//   },
//   {
//     report_id: "RPT003",
//     title: "Report 3",
//   },
// ];
// const sampleUser = {
//   name: "John Doe",
// };

const UserReportsList = ({
  data = [],
  user = {},
  getConversationData,
  toggleConversationPopup,
  setConversationUser,
  getReportFiles,
  toggleAttachPopup,
  from,
  handleBack,
  onSearch,
}) => {
  return (
    <UserReportsListWrapper>
      <div className="flex section-header" style={{marginBottom:'20px'}}>
        <Tooltip message="Go Back" onClick={handleBack}>
          <ArrowLeftOutlined type="arrow-left" className="back-arrow-btn" />
        </Tooltip>
        <h2>Reports purchased by {user?.name}</h2>
        <Input
          style={{
            width: "20%",
            marginLeft: "auto",
          }}
          placeholder="Search reports"
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <List style={{border:'1px solid lightgrey'}}
        header={
          <div className="flex header-user-wrapper">
            <p className="user-name">Report ID</p>
            <p className="user-email-id">Report Name</p>
          </div>
        }
        dataSource={data}
        renderItem={(report, i) => (
          <List.Item key={i + 1}>
            <div className="flex user-wrapper">
              <p className="user-name">{report.report_id}</p>
              <p className="user-email-id">{report.title}</p>
              <div className="user-actions">
                <Tooltip title={"Attach Reports"}>
                  <FileAddOutlined
                    type={from === "reports" ? "file-add" : "message"}
                    className="view-chat-icon"
                    onClick={() =>
                      toggleAttachPopup(
                        report.report_id,
                        report.hub_id,
                        report.is_email_to_client
                      )
                    }
                  />
                </Tooltip>
              </div>
            </div>
          </List.Item>
        )}
        pagination={true}
      />
    </UserReportsListWrapper>
  );
};

export default UserReportsList;
