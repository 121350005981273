import React from "react";
import LogoWrapper from "./Logo.style";
import { Box } from "@mui/material";
import MainHeader from "../../MainHeader/MainHeader";

const Logo = () => {
  return (
    <LogoWrapper>
      <Box sx={{display:'flex',alignItems:'center',marginTop:'10px'}}>
      {/* <h3 className="main-heading">MI SYNAPSE CMS</h3> */}
      <img
                className="pic"
                style={{ width: "142px", height: "36px", marginLeft: "20px" }}
                src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/logo.png"
                alt="logo"
      />
        <h3>~CMS</h3>
      <MainHeader/>
      </Box>
    </LogoWrapper>
  );
};

export default Logo;
