import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trial: [],
  full: [],
  limited: [],
};

const assignedReportsNewSlice = createSlice({
  name: "assignedReportsNew",
  initialState,
  reducers: {
    assignReport(state, action) {
      const {
        access_type,
        modules,
        selectHub,
        expiry_date,
        subscription_date,
        report,
      } = action.payload;

      const updateIfExistsLimited = (state, newItem) => {
        // Check if there is an existing item with the same selectHub ID
        const checkHubId = (hub) => hub.id === newItem.selectHub[0].id; // Assuming selectHub is an array with one item
        const existingIndex = state.limited.findIndex((item) =>
          item.selectHub.some(checkHubId)
        );
        // Remove the existing item if found
        if (existingIndex !== -1) {
          state.limited.splice(existingIndex, 1);
        }

        // Add the new item
        state.limited.push(newItem);
      };

      const updateIfExists = (array, newItem) => {
        // Find the index of the item with the same access_type
        const accessTypeIndex = array.findIndex(
          (item) => item.access_type === newItem.access_type
        );

        // Find the index of the item with the same selectHub ID
        const hubIndex = array.findIndex((item) =>
          item.selectHub.some((hub) => hub.id === newItem.selectHub[0].id)
        );

        // Check if the item with the same selectHub ID exists and the access_type is not 'limited'
        if (hubIndex !== -1 && newItem.access_type !== "limited") {
          // Update existing item
          array[hubIndex] = { ...array[hubIndex], ...newItem };
        } else if (hubIndex === -1 && accessTypeIndex !== -1) {
          // Add new hub to the existing item with the same access_type
          array[accessTypeIndex].selectHub.push(...newItem.selectHub);
          array[accessTypeIndex] = { ...array[accessTypeIndex], ...newItem };
        } else {
          // Add new item
          array.push(newItem);
        }
      };

      if (access_type === "trial") {
        updateIfExists(state.trial, {
          access_type,
          modules,
          selectHub,
          expiry_date,
          subscription_date,
          report,
        });
      } else if (access_type === "full") {
        updateIfExists(state.full, {
          access_type,
          modules,
          selectHub,
          expiry_date,
          subscription_date,
          report,
        });
      } else if (access_type === "limited") {
        updateIfExistsLimited(state, {
          access_type,
          modules,
          selectHub,
          expiry_date,
          subscription_date,
          report,
        });
      }
    },
    deleteReport(state, action) {
      const { access_type, selectHub } = action.payload;
      if (access_type === "trial") {
        state.trial = state.trial.filter(
          (item) => item.selectHub.id !== selectHub.id
        );
      } else if (access_type === "full") {
        state.full = state.full.filter(
          (item) => item.selectHub.id !== selectHub.id
        );
      } else if (access_type === "limited") {
        state.limited = state.limited.filter(
          (item) => !item.selectHub.some((hub) => hub.id === selectHub[0].id)
        );
      }
    },
    clearAssignedReportState(state) {
      state.trial = [];
      state.full = [];
      state.limited = [];
    },
  },
});

export const { assignReport, deleteReport, clearAssignedReportState } =
  assignedReportsNewSlice.actions;
export default assignedReportsNewSlice.reducer;
