import Styled from 'styled-components';

const SideMenuWrapper = Styled.div`
    width: 20%;
    display: flex;
    flex-direction:column;
    padding:0 0 8px 0;
    .ant-menu-inline {
        border: none;
        overflow: hidden;
    }

`;

export default SideMenuWrapper;