import styled from 'styled-components';

const PreloaderWrapper = styled.div`
  position: relative; /* Changed from fixed to relative */
  width: 100%; /* Adjust width as needed */
  height: 100%; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #809eb942;
  z-index: 10;

  @keyframes moveup {
    0%, 60%, 100% {
      transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
    }
    25% {
      transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(1em);
    }
  }

  @keyframes movedown {
    0%, 60%, 100% {
      transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
    }
    25% {
      transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(-1em);
    }
  }

  .layer {
    display: block;
    position: absolute;
    height: 3em;
    width: 3em;
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg);
  }

  .layer:nth-of-type(1) {
    background: #333;
    margin-top: 1.5em;
    animation: movedown 0.9s cubic-bezier(0.39, 0.575, 0.565, 1) 0.45s infinite normal;
  }

  .layer:nth-of-type(1):before {
    content: '';
    position: absolute;
    width: 85%;
    height: 85%;
    background: #333;
  }

  .layer:nth-of-type(2) {
    background: #5a96bc;
    margin-top: 0.75em;
  }

  .layer:nth-of-type(3) {
    background: rgba(255, 255, 255, 0.6);
    animation: moveup 0.9s cubic-bezier(0.39, 0.575, 0.565, 1) infinite normal;
  }
`;

export default PreloaderWrapper;
