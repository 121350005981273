import React, { useState, useEffect } from "react";
import { Table, Button, DatePicker, message, Select, Input } from "antd";
import Cookies from "universal-cookie";
import API from "../../utils/api";
import moment from "moment";
import { useLazyGetReportsQuery } from "../../redux/api/createUserApi";
import { Option } from "antd/es/mentions";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  addRowKey,
  removeRowKey,
  setRowKeys,
} from "../../redux/assignedReportsLimitedReport";

const ReportTable = ({
  onAssignReports,
  hubLevelType,
  selectedHub,
  report,
  subscriptionDate,
  expiryDate,
}) => {
  const cookies = new Cookies();
  const { Base_URL, getHubList } = API;
  const token = cookies.get("TOKEN");
  const [trigger, result] = useLazyGetReportsQuery();
  const [data, setData] = useState([]);
  // const [selectedRowKeys, setSelectedRowKeys] = useState(
  //   report ? report.map((item) => item.id) : []
  // );
  const [reportDates, setReportDates] = useState({}); // State to manage report-specific dates
  const [searchInput, setSearchInput] = useState("");
  const [searchType, setSearchType] = useState("title");
  const [filteredData, setFilteredData] = useState([]);
  const [laoding, setLoading] = useState(false);
  const [subscriptionDateLocal, setSubscriptionDateLocal] =
    useState(subscriptionDate);
  const [expiryDateLocal, setExpiryDateLocal] = useState(expiryDate);
  const dispatch = useDispatch();
  const selectedRowKeys = useSelector((state) => state.limitedReports);
  // Fetch reports based on hub selection
  useEffect(() => {
    setLoading(true);
    setSearchInput("");
    setSearchType("title");
    if (hubLevelType === "limited" && (selectedHub?.id || selectedHub?.key)) {
      (async () => {
        try {
          const response = await trigger({
            hubId: selectedHub.id || selectedHub?.key,
          });
          setData(response?.data?.data?.reports || []);
        } catch (error) {
          console.error("Error fetching reports:", error);
        }
        setLoading(false);
      })();
    }
  }, [hubLevelType, selectedHub]);

  // const onSelectChange = (selectedRowKeys) => {
  //   setSelectedRowKeys(selectedRowKeys);
  // };

  const handleDateChange = (dateType, reportId, date) => {
    setReportDates((prevDates) => ({
      ...prevDates,
      [reportId]: {
        ...prevDates[reportId],
        [dateType]: date,
      },
    }));
  };

  const handleAllDateChange = () => {
    let whatToChange;
    let newDate;
    if (subscriptionDate !== subscriptionDateLocal) {
      whatToChange = "subscription_date";
      newDate = subscriptionDate;
      setSubscriptionDateLocal(subscriptionDate);
    } else if (expiryDate !== expiryDateLocal) {
      whatToChange = "expiry_date";
      newDate = expiryDate;
      setExpiryDateLocal(expiryDate);
    } else return;
    selectedRowKeys.forEach((reportId) => {
      const stringReportId = String(reportId);
      setReportDates((prevDates) => ({
        ...prevDates,
        [stringReportId]: {
          ...prevDates[stringReportId],
          [whatToChange]: newDate,
        },
      }));
    });
  };

  useEffect(() => {
    handleAllDateChange();
  }, [subscriptionDate, expiryDate]);

  useEffect(() => {
    if (selectedRowKeys.length === 0 && data?.length === 0) return;
    handleAssignReports();
  }, [selectedRowKeys, reportDates, data]);

  const handleAssignReports = () => {
    const reportIDs = selectedRowKeys.filter(
      (id) => id !== null && id !== undefined
    );
    if (reportIDs.length === 0) {
      message.error("Please select at least one report.");
      return;
    }
    const selectedReports = reportIDs.map((reportId) => ({
      id: reportId,
      title:
        data.find((report) => report.id === reportId)?.title || "Unknown Title",
      subscription_date:
        reportDates[reportId]?.subscription_date || subscriptionDate || dayjs(),
      expiry_date: reportDates[reportId]?.expiry_date || expiryDate || dayjs(),
    }));
    onAssignReports(selectedReports);
  };

  const createReportDates = () => {
    const datescheck = report.reduce(
      (acc, { id, subscription_date, expiry_date }) => {
        acc[id] = {
          subscription_date: dayjs(subscription_date),
          expiry_date: dayjs(expiry_date),
        };
        return acc;
      },
      {}
    );
    setReportDates(datescheck);
  };
  useEffect(() => {
    if (report) {
      createReportDates();
    }
  }, []);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Subscription Date",
      dataIndex: "subscription_date",
      key: "subscription_date",
      render: (text, record) => {
        const isEnabled = selectedRowKeys.some((item) => item === record.id);
        const latestDate = subscriptionDate;
        return (
          <DatePicker
            style={{ minWidth: "100px" }}
            onChange={(date, dateString) =>
              handleDateChange("subscription_date", record.id, dateString)
            }
            disabled={!isEnabled}
            value={
              (reportDates[record.id]?.subscription_date
                ? dayjs(reportDates[record.id].subscription_date)
                : null) ||
              subscriptionDate ||
              dayjs()
            }
            minDate={dayjs()}
            defaultValue={
              (reportDates[record.id]?.subscription_date
                ? dayjs(reportDates[record.id]?.subscription_date)
                : null) ||
              subscriptionDate ||
              dayjs()
            }
          />
        );
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (text, record) => {
        const isEnabled = selectedRowKeys.some((item) => item === record.id);
        return (
          <DatePicker
            style={{ minWidth: "100px" }}
            onChange={(date, dateString) =>
              handleDateChange("expiry_date", record.id, dateString)
            }
            disabled={!isEnabled}
            minDate={dayjs()}
            value={
              (reportDates[record.id]?.expiry_date
                ? dayjs(reportDates[record.id].expiry_date)
                : null) ||
              expiryDate ||
              dayjs()
            }
            defaultValue={
              (reportDates[record.id]?.expiry_date
                ? dayjs(reportDates[record.id].expiry_date)
                : null) ||
              expiryDate ||
              dayjs()
            }
          />
        );
      },
    },
    {
      title: "Published Year",
      dataIndex: "published_year",
      key: "published_year",
      render: (text, record) =>
        moment(record.published_year).format("DD MMMM, YYYY"),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected) => {
      if (selected) {
        dispatch(addRowKey(record.id));
      } else {
        dispatch(removeRowKey(record.id));
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        const newKeys = selectedRows?.map((row) => row?.id);
        dispatch(
          setRowKeys(Array.from(new Set([...selectedRowKeys, ...newKeys])))
        );
      } else {
        const removeKeys = changeRows?.map((row) => row?.id);
        dispatch(
          setRowKeys(selectedRowKeys.filter((id) => !removeKeys.includes(id)))
        );
      }
    },
    // columnTitle: <></>,
  };

  useEffect(() => {
    handleSearch();
  }, [searchInput, searchType, data]);

  const handleSearch = () => {
    const filtered = data.filter((report) => {
      if (searchType === "id") {
        return report.id.toString().includes(searchInput);
      } else if (searchType === "title") {
        return report.title.toLowerCase().includes(searchInput.toLowerCase());
      }
      return true;
    });
    setFilteredData(filtered);
  };

  return (
    <div>
      <Select
        defaultValue="title"
        style={{ width: "20%" }}
        onChange={(value) => setSearchType(value)}
      >
        <Option value="title">Title</Option>
        <Option value="id">ID</Option>
      </Select>
      <Input
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        style={{ width: "70%", marginLeft: 10, border: "1px solid #67bfff" }}
      />
      <Table
        className="custom-table-style"
        rowSelection={rowSelection}
        columns={columns}
        loading={laoding}
        dataSource={filteredData}
        pagination={true}
        rowKey="id"
      />
      {/* <Button
        type="primary"
        style={{ marginTop: "20px", marginBottom: "20px" }}
        onClick={handleAssignReports}
      >
        Assign Selected Reports
      </Button> */}
    </div>
  );
};

export default ReportTable;
