import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";
import styles from "./styles.module.css";
import NavBar from "../../components/NavBar";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import _, { debounce } from "lodash";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import {
  useDeleteParticularUserMutation,
  useGetUserApiQuery,
} from "../../redux/api/createUserApi";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DeleteOutline, NoAccounts } from "@mui/icons-material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const UserList = () => {
  const [searchString, setSearchString] = useState("");
  const [result, setResult] = useState([]);
  const isNavBarShrink = useSelector((store) => store.interface.isNavBarShrink);
  const { data: userData, error, isLoading, isFetching } = useGetUserApiQuery();
  const [triggerDeleteUser, { data: deleteUserData, error: deleteError }] =
    useDeleteParticularUserMutation();
  const navigate = useNavigate();
  let fuse;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (userData) {
      let newResult = [...userData?.data].map((user) => {
        return {
          item: user,
        };
      });
      setResult(newResult);
    }
  }, [userData]);

  const navigateToEditUser = (userData) => {
    // Navigate to edit user page
    navigate(`/home/user-management/update-user`, { state: { userData } });
  };
  const handleDeleteUser = async (userId) => {
    // Delete user
    const res = await triggerDeleteUser({ userId: dialogUserId?.userId });
    setOpen(false);
  };

  const handleSearch = (value) => {
    value = typeof value === "string" ? value.trim() : searchString.trim();
    let searchResult;
    if (value === "") {
      searchResult = [...userData?.data].map((user) => {
        return {
          item: user,
        };
      });
    } else {
      const options = {
        includeScore: false,
        minMatchCharLength: 3,
        keys: ["name", "email"],
      };
      fuse = new Fuse([...userData?.data], options);
      searchResult = fuse.search(value);
    }
    setResult(searchResult);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchString(value);
    debouncedSearch(value);
  };

  const debouncedSearch = debounce(handleSearch, 300);
  const handleClose = () => {
    setOpen(false);
  };
  const [dialogUserId, setDialogUserId] = useState({});
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${dialogUserId.disable ? "Disable" : "Enable"} User Confirmation`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to <b>{dialogUserId.name}</b>{" "}
            {dialogUserId.disable ? "disable" : "enable"} this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleDeleteUser} autoFocus>
            {dialogUserId.disable ? "Disable" : "Enable"}
          </Button>
        </DialogActions>
      </Dialog>
      <Header>User List</Header>
      {isLoading || isFetching ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid item sm={12}>
            <FormControl
              sx={{
                width: "100%",
                marginTop: " 10px",
                marginBottom: "30px",
              }}
            >
              <TextField
                label="Search Users by Name or Email"
                key="search-field"
                name="searchText"
                value={searchString}
                type="text"
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Relationship Manager</TableCell>
                  <TableCell>Access Type</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result?.map((user) => (
                  <TableRow
                    sx={{
                      backgroundColor: user?.item?.active ? "white" : "#f5f5f5",
                    }}
                    key={user?.item._id}
                  >
                    <TableCell>{user?.item.name}</TableCell>
                    <TableCell>{user?.item.email}</TableCell>
                    <TableCell>
                      {user?.item?.relationship_manager?.[0]?.username}
                    </TableCell>
                    <TableCell>{user?.item.access_type}</TableCell>
                    <TableCell>{user?.item.company_name}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => navigateToEditUser(user?.item)}
                      >
                        <EditIcon />
                      </IconButton>
                      {user?.item?.active ? (
                        <IconButton
                          onClick={() => {
                            setDialogUserId({
                              userId: user?.item._id,
                              disable: true,
                              name: user?.item.name,
                            });
                            setOpen(true);
                          }}
                          aria-label="Disable User"
                          title="Disable User"
                        >
                          <NoAccounts />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setDialogUserId({
                              userId: user?.item._id,
                              disable: false,
                              name: user?.item.name,
                            });
                            setOpen(true);
                          }}
                          aria-label="Enable User"
                          title="Enable User"
                        >
                          <PersonAddIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default UserList;
