import styled from 'styled-components';

const LogoWrapper = styled.div`
    margin: 0px 30px;
    background-color: #fff;
    box-shadow: 0 0 5px 1px #b2b2b2;
    border-radius: 10px;
    .main-heading {
      padding: 13px 0;
      margin: 0;
      text-align: center;
    }
`;
export default LogoWrapper;