import React, { useEffect } from "react";
import DashboardWrapper from "./Dashboard.style";
import SideMenu from "../../components/SideMenu/SideMenu";
import Main from "../../components/Main/Main";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveMenuItemKey,
  setOpenKeys,
  setTitle,
} from "../../redux/sidebarSlice";
import { useLocation, useRoutes } from "react-router-dom";
import Logo from "../../components/SideMenu/Logo/Logo";
import Footer from "../../components/Footer/Footer";
import MenuButton from "../../components/SideMenu/Menu/MenuButtons";

const NewDashboard = () => {
  const dispatch = useDispatch();
  const openKeys = useSelector((state) => state.slider.openKeys);
  const selectedKeys = useSelector((state) => state.slider.activeMenuItemKey);
  const location = useLocation();
  const computeTitle = (key) => {
    return key
      .replace(/\d+/g, "") // remove numbers
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    //Set all the menu expanded by default
    dispatch(
      setOpenKeys([
        "dashboard",
        "custom-reports-management",
        "custom-reports-management",
        "settings",
        "content-management",
        "user-management",
      ])
    );
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const pathArray = path.split("/");
    if (pathArray.length === 2) {
      dispatch(setActiveMenuItemKey(pathArray[2]));
      dispatch(setTitle(computeTitle(pathArray[2])));
    } else if (pathArray.length > 2) {
      // dispatch(setOpenKeys([...openKeys, pathArray[2]]));
      dispatch(setTitle(computeTitle(pathArray[pathArray.length - 1])));
      dispatch(setActiveMenuItemKey(pathArray[pathArray.length - 1]));
    }
  }, [location.pathname]);

  const handleMenuItemSelect = (key) => {
    dispatch(setActiveMenuItemKey(key.key));
    const currentKey = key.key;

    //For example, if key is "report-assignment" then title will be "Report Assignment"
    dispatch(setTitle(computeTitle(currentKey)));
  };

  const handleSubMenuSelect = (key) => {
    if (openKeys[0] === key.key) {
      dispatch(setOpenKeys([]));
    } else {
      //If the key is not already in the openKeys array, add it
      if (!openKeys.includes(key.key)) {
        dispatch(setOpenKeys([...openKeys, key.key]));
      } else {
        //If the key is already in the openKeys array, remove it
        dispatch(
          setOpenKeys(openKeys.filter((openKey) => openKey !== key.key))
        );
      }
    }
  };
  return (
    <DashboardWrapper style={{
      // backgroundImage: 'https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/hero_banner_1.svg',
    }}>
      <div style={{
        backgroundImage: `url('https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/Banner.svg')`,
        height: '200px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        border: '1px solid transparent'
      }}>
        <Logo />
        <div style={{marginLeft:'50px',marginTop:'40px'}}>
          <MenuButton />
          </div>
      </div>
      <div style={{padding:'0px'}} className="flex dashboard-container">
        {/* <SideMenu
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onSubMenuSelect={handleSubMenuSelect}
          onMenuItemClick={handleMenuItemSelect}
        /> */}
        <Main />
      </div>
      <Footer/>
    </DashboardWrapper>
  );
};

export default NewDashboard;
