import styled from "styled-components";

const DashboardWrapper = styled.div`
  .dashboard-container {
    overflow: hidden;
    padding: 20px;
  }
`;

export default DashboardWrapper;

// max-height: 100vh;