import React from "react";
import AssignPermission from "../AssignPermission";
import styles from "./styles.module.css";
import Layout from "../Layout";
import { useLocation, useNavigate } from "react-router-dom";

const UpdateUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = location?.state?.userData ?? {};
  if (!userData?._id) {
    navigate("/home/dashboard");
  }
  return <AssignPermission userData={userData} />;
};

export default UpdateUser;
