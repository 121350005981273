import Styled from "styled-components";

const MainWrapper = Styled.div`
    width: 100%;
    display: flex;
    min-height:calc(100vh - 107px);;
    flex-direction:column;
    .content {
        padding: 30px 50px;
        background-color: #fff;
        box-shadow: 0 0 5px 1px #b2b2b2;
        min-height: 100vh
    }
`;

export default MainWrapper;
