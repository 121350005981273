export const newReportAssignmentData = [
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b128",
    },
    id: 56,
    title: "Healthcare",
    slug: "healthcare",
    active: true,
    meta: {
      tags: "Healthcare Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "healthcare industry market size, healthcare industry market share, healthcare industry market research, market share of healthcare industry, medical device industry market size, healthcare market, pharmaceutical market, medical devices market, diagnostics market, animal health market, healthcare it market, biotechnology market",
      description:
        "We offer a broad spectrum of Market Research, Consulting, and Advisory services on markets across Pharmaceuticals, Medical Devices, Biotechnology, Healthcare IT, and animal healthcare.",
    },
    description: {
      short_description:
        "MI translates market information on healthcare (market dynamics, competition, varying consumer demands, and regulations) into actionable business insight. This helps organizations edge competition, pioneer new strategies and make products for animal healthcare, pharmaceuticals, and diagnostics.",
      full_description:
        "We offer actionable and valuable insights, analysis, and forecasts on various aspects of the Healthcare industry which mainly include Pharmaceuticals, Medical Devices, Biotechnology, and Healthcare IT.",
    },
    picture: null,
    __v: 0,
    picture_url: "healthcare.png",
    cat_email: "hc@mordorintelligence.com",
    cat_pw_id: 12424,
    parent: "/",
    path: "/healthcare",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b14f",
        },
        id: 566,
        title: "Molecular Diagnostics",
        slug: "molecular-diagnostics-healthcare",
        active: true,
        meta: {
          description:
            "Molecular diagnostics is a new addition to the diagnostics segment of healthcare. It works on the modern technologies that detect the alterations in the genes on the molecular level.",
          keywords:
            "Molecular Diagnostics Market Research Reports, Molecular Diagnostics Market, Diagnostics Market, Molecular Diagnostics Industry, Molecular Diagnostics Market Trends, Molecular Diagnostics Market Research Report, Molecular Diagnostics Market Industry Report.",
          tags: "Molecular Diagnostics Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Molecular diagnostics is a new addition to the diagnostics segment of healthcare. It works on the modern technologies that detect the alterations in the genes on the molecular level. Our molecular diagnostics market research portfolio is composed of reports like global genetic testing market, Europe genetic testing market analysis and forecast etc.",
          short_description:
            "We analyze and report breakthroughs in the most recent technologies in molecular diagnostics. Molecular diagnostics include next-generation sequencing, PCR technologies, oncology, genetic and infectious disease diagnosis & therapies.",
        },
        picture: null,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/molecular-diagnostics-healthcare",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b150",
        },
        id: 5624,
        title: "Biotechnology",
        slug: "biotechnology",
        active: true,
        meta: {
          description:
            "Biotechnology is the use of living systems and organisms to develop or make products, or any technological application that uses biological systems, living organisms, or derivatives thereof, to make or modify.",
          keywords:
            "Biotechnology Market Research Reports, biotechnology market, biotechnology industry outlook, biotechnology industry reports, biotechnology market report.",
          tags: "Biotechnology Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            'Biotechnology is the use of living systems and organisms to develop or make products, or "any technological application that uses biological systems, living organisms or derivatives thereof, to make or modify .',
          short_description:
            "This section looks at the intricacies of the biotechnology market and studies all the variables involved. Mordor Intelligence™ compilation of reports on recently emerging markets includes biobanking, genetic testing, and stem cell therapies to help you understand the global biotechnology market.",
        },
        picture: null,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/biotechnology",
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b412",
            },
            slug: "biopharma",
            title: "Biopharma",
            id: 1000293,
            parent: "/healthcare/biotechnology",
            path: "/healthcare/biotechnology/biopharma",
            active: true,
            meta: {
              tags: "Biopharma",
              keywords: "Biopharma",
              description: "Biopharma",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b413",
                },
                slug: "recombinant-products",
                title: "Recombinant Products",
                id: 1000294,
                parent: "/healthcare/biotechnology/biopharma",
                path: "/healthcare/biotechnology/biopharma/recombinant-products",
                active: true,
                meta: {
                  tags: "Recombinant Products",
                  keywords: "Recombinant Products",
                  description: "Recombinant Products",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b414",
                    },
                    slug: "recombinant-growth-factors",
                    title: "Recombinant Growth Factors",
                    id: 1000295,
                    parent:
                      "/healthcare/biotechnology/biopharma/recombinant-products",
                    path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-growth-factors",
                    active: true,
                    meta: {
                      tags: "Recombinant Growth Factors",
                      keywords: "Recombinant Growth Factors",
                      description: "Recombinant Growth Factors",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b415",
                        },
                        slug: "erythropoietin",
                        title: "Erythropoietin",
                        id: 1000296,
                        parent:
                          "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-growth-factors",
                        path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-growth-factors/erythropoietin",
                        active: true,
                        meta: {
                          tags: "Erythropoietin",
                          keywords: "Erythropoietin",
                          description: "Erythropoietin",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b416",
                        },
                        slug: "granulocyte-colony-stimulating-factor",
                        title: "Granulocyte Colony Stimulating Factor",
                        id: 1000297,
                        parent:
                          "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-growth-factors",
                        path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-growth-factors/granulocyte-colony-stimulating-factor",
                        active: true,
                        meta: {
                          tags: "Granulocyte Colony Stimulating Factor",
                          keywords: "Granulocyte Colony Stimulating Factor",
                          description: "Granulocyte Colony Stimulating Factor",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b417",
                    },
                    slug: "recombinant-proteins",
                    title: "Recombinant Proteins",
                    id: 1000298,
                    parent:
                      "/healthcare/biotechnology/biopharma/recombinant-products",
                    path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-proteins",
                    active: true,
                    meta: {
                      tags: "Recombinant Proteins",
                      keywords: "Recombinant Proteins",
                      description: "Recombinant Proteins",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b418",
                        },
                        slug: "serum-albumin",
                        title: "Serum Albumin",
                        id: 1000299,
                        parent:
                          "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-proteins",
                        path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-proteins/serum-albumin",
                        active: true,
                        meta: {
                          tags: "Serum Albumin",
                          keywords: "Serum Albumin",
                          description: "Serum Albumin",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b419",
                        },
                        slug: "amyloid-protein",
                        title: "Amyloid Protein",
                        id: 1000300,
                        parent:
                          "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-proteins",
                        path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-proteins/amyloid-protein",
                        active: true,
                        meta: {
                          tags: "Amyloid Protein",
                          keywords: "Amyloid Protein",
                          description: "Amyloid Protein",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b41a",
                        },
                        slug: "defensin",
                        title: "Defensin",
                        id: 1000301,
                        parent:
                          "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-proteins",
                        path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-proteins/defensin",
                        active: true,
                        meta: {
                          tags: "Defensin",
                          keywords: "Defensin",
                          description: "Defensin",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b41b",
                        },
                        slug: "transferrin",
                        title: "Transferrin",
                        id: 1000302,
                        parent:
                          "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-proteins",
                        path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-proteins/transferrin",
                        active: true,
                        meta: {
                          tags: "Transferrin",
                          keywords: "Transferrin",
                          description: "Transferrin",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b41c",
                    },
                    slug: "recombinant-hormones",
                    title: "Recombinant Hormones",
                    id: 1000303,
                    parent:
                      "/healthcare/biotechnology/biopharma/recombinant-products",
                    path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-hormones",
                    active: true,
                    meta: {
                      tags: "Recombinant Hormones",
                      keywords: "Recombinant Hormones",
                      description: "Recombinant Hormones",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b41d",
                        },
                        slug: "recombinant-human-growth-hormones",
                        title: "Recombinant Human Growth Hormones",
                        id: 1000304,
                        parent:
                          "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-hormones",
                        path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-hormones/recombinant-human-growth-hormones",
                        active: true,
                        meta: {
                          tags: "Recombinant Human Growth Hormones",
                          keywords: "Recombinant Human Growth Hormones",
                          description: "Recombinant Human Growth Hormones",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b41e",
                        },
                        slug: "recombinant-insulin",
                        title: "Recombinant Insulin",
                        id: 1000305,
                        parent:
                          "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-hormones",
                        path: "/healthcare/biotechnology/biopharma/recombinant-products/recombinant-hormones/recombinant-insulin",
                        active: true,
                        meta: {
                          tags: "Recombinant Insulin",
                          keywords: "Recombinant Insulin",
                          description: "Recombinant Insulin",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b41f",
                },
                slug: "monoclonal-antibodies",
                title: "Monoclonal Antibodies",
                id: 1000306,
                parent: "/healthcare/biotechnology/biopharma",
                path: "/healthcare/biotechnology/biopharma/monoclonal-antibodies",
                active: true,
                meta: {
                  tags: "Monoclonal Antibodies",
                  keywords: "Monoclonal Antibodies",
                  description: "Monoclonal Antibodies",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b420",
                    },
                    slug: "anti-cancer-monoclonal-antibodies",
                    title: "Anti cancer Monoclonal Antibodies",
                    id: 1000307,
                    parent:
                      "/healthcare/biotechnology/biopharma/monoclonal-antibodies",
                    path: "/healthcare/biotechnology/biopharma/monoclonal-antibodies/anti-cancer-monoclonal-antibodies",
                    active: true,
                    meta: {
                      tags: "Anti cancer Monoclonal Antibodies",
                      keywords: "Anti cancer Monoclonal Antibodies",
                      description: "Anti cancer Monoclonal Antibodies",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b421",
                    },
                    slug: "anti-inflammatory-monoclonal-antibodies",
                    title: "Anti inflammatory Monoclonal Antibodies",
                    id: 1000308,
                    parent:
                      "/healthcare/biotechnology/biopharma/monoclonal-antibodies",
                    path: "/healthcare/biotechnology/biopharma/monoclonal-antibodies/anti-inflammatory-monoclonal-antibodies",
                    active: true,
                    meta: {
                      tags: "Anti inflammatory Monoclonal Antibodies",
                      keywords: "Anti inflammatory Monoclonal Antibodies",
                      description: "Anti inflammatory Monoclonal Antibodies",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b422",
                },
                slug: "purified-proteins",
                title: "Purified Proteins",
                id: 1000309,
                parent: "/healthcare/biotechnology/biopharma",
                path: "/healthcare/biotechnology/biopharma/purified-proteins",
                active: true,
                meta: {
                  tags: "Purified Proteins",
                  keywords: "Purified Proteins",
                  description: "Purified Proteins",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b423",
                    },
                    slug: "leukemia-inhibitory-factor",
                    title: "Leukemia Inhibitory Factor",
                    id: 1000310,
                    parent:
                      "/healthcare/biotechnology/biopharma/purified-proteins",
                    path: "/healthcare/biotechnology/biopharma/purified-proteins/leukemia-inhibitory-factor",
                    active: true,
                    meta: {
                      tags: "Leukemia Inhibitory Factor",
                      keywords: "Leukemia Inhibitory Factor",
                      description: "Leukemia Inhibitory Factor",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b424",
                    },
                    slug: "p53-protein",
                    title: "P53 Protein",
                    id: 1000311,
                    parent:
                      "/healthcare/biotechnology/biopharma/purified-proteins",
                    path: "/healthcare/biotechnology/biopharma/purified-proteins/p53-protein",
                    active: true,
                    meta: {
                      tags: "P53 Protein",
                      keywords: "P53 Protein",
                      description: "P53 Protein",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b425",
                    },
                    slug: "p38-protein",
                    title: "P38 Protein",
                    id: 1000312,
                    parent:
                      "/healthcare/biotechnology/biopharma/purified-proteins",
                    path: "/healthcare/biotechnology/biopharma/purified-proteins/p38-protein",
                    active: true,
                    meta: {
                      tags: "P38 Protein",
                      keywords: "P38 Protein",
                      description: "P38 Protein",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b426",
                },
                slug: "enzymes",
                title: "Enzymes",
                id: 1000313,
                parent: "/healthcare/biotechnology/biopharma",
                path: "/healthcare/biotechnology/biopharma/enzymes",
                active: true,
                meta: {
                  tags: "Enzymes",
                  keywords: "Enzymes",
                  description: "Enzymes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b427",
                },
                slug: "nanomedicine",
                title: "Nanomedicine",
                id: 1000314,
                parent: "/healthcare/biotechnology/biopharma",
                path: "/healthcare/biotechnology/biopharma/nanomedicine",
                active: true,
                meta: {
                  tags: "Nanomedicine",
                  keywords: "Nanomedicine",
                  description: "Nanomedicine",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b428",
            },
            slug: "bioservices",
            title: "Bioservices",
            id: 1000315,
            parent: "/healthcare/biotechnology",
            path: "/healthcare/biotechnology/bioservices",
            active: true,
            meta: {
              tags: "Bioservices",
              keywords: "Bioservices",
              description: "Bioservices",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b429",
                },
                slug: "clinical-trial-services",
                title: "Clinical Trial Services",
                id: 1000316,
                parent: "/healthcare/biotechnology/bioservices",
                path: "/healthcare/biotechnology/bioservices/clinical-trial-services",
                active: true,
                meta: {
                  tags: "Clinical Trial Services",
                  keywords: "Clinical Trial Services",
                  description: "Clinical Trial Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b42a",
                },
                slug: "clinical-research-organizations",
                title: "Clinical Research Organizations",
                id: 1000317,
                parent: "/healthcare/biotechnology/bioservices",
                path: "/healthcare/biotechnology/bioservices/clinical-research-organizations",
                active: true,
                meta: {
                  tags: "Clinical Research Organizations",
                  keywords: "Clinical Research Organizations",
                  description: "Clinical Research Organizations",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b42b",
                },
                slug: "contract-manufacturers-organizations",
                title: "Contract Manufacturers Organizations",
                id: 1000318,
                parent: "/healthcare/biotechnology/bioservices",
                path: "/healthcare/biotechnology/bioservices/contract-manufacturers-organizations",
                active: true,
                meta: {
                  tags: "Contract Manufacturers Organizations",
                  keywords: "Contract Manufacturers Organizations",
                  description: "Contract Manufacturers Organizations",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b42c",
                },
                slug: "contract-research-and-manufacturing-services",
                title: "Contract Research and Manufacturing Services",
                id: 1000319,
                parent: "/healthcare/biotechnology/bioservices",
                path: "/healthcare/biotechnology/bioservices/contract-research-and-manufacturing-services",
                active: true,
                meta: {
                  tags: "Contract Research and Manufacturing Services",
                  keywords: "Contract Research and Manufacturing Services",
                  description: "Contract Research and Manufacturing Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b42d",
                },
                slug: "drug-development",
                title: "Drug Development",
                id: 1000320,
                parent: "/healthcare/biotechnology/bioservices",
                path: "/healthcare/biotechnology/bioservices/drug-development",
                active: true,
                meta: {
                  tags: "Drug Development",
                  keywords: "Drug Development",
                  description: "Drug Development",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b42e",
                },
                slug: "biobanks",
                title: "Biobanks",
                id: 1000321,
                parent: "/healthcare/biotechnology/bioservices",
                path: "/healthcare/biotechnology/bioservices/biobanks",
                active: true,
                meta: {
                  tags: "Biobanks",
                  keywords: "Biobanks",
                  description: "Biobanks",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b42f",
                },
                slug: "bioethics",
                title: "Bioethics",
                id: 1000322,
                parent: "/healthcare/biotechnology/bioservices",
                path: "/healthcare/biotechnology/bioservices/bioethics",
                active: true,
                meta: {
                  tags: "Bioethics",
                  keywords: "Bioethics",
                  description: "Bioethics",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b430",
            },
            slug: "biotechnology-r-and-d",
            title: "Biotechnology R&D",
            id: 1000323,
            parent: "/healthcare/biotechnology",
            path: "/healthcare/biotechnology/biotechnology-r-and-d",
            active: true,
            meta: {
              tags: "Biotechnology R&D",
              keywords: "Biotechnology R&D",
              description: "Biotechnology R&D",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b431",
                },
                slug: "life-science-research-technologies",
                title: "Life Science Research Technologies",
                id: 1000324,
                parent: "/healthcare/biotechnology/biotechnology-r-and-d",
                path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                active: true,
                meta: {
                  tags: "Life Science Research Technologies",
                  keywords: "Life Science Research Technologies",
                  description: "Life Science Research Technologies",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b432",
                    },
                    slug: "antibody-technologies",
                    title: "Antibody Technologies",
                    id: 1000325,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/antibody-technologies",
                    active: true,
                    meta: {
                      tags: "Antibody Technologies",
                      keywords: "Antibody Technologies",
                      description: "Antibody Technologies",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b433",
                    },
                    slug: "rnai-technology",
                    title: "RNAi Technology",
                    id: 1000326,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/rnai-technology",
                    active: true,
                    meta: {
                      tags: "RNAi Technology",
                      keywords: "RNAi Technology",
                      description: "RNAi Technology",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b434",
                    },
                    slug: "biomarkers",
                    title: "Biomarkers",
                    id: 1000327,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/biomarkers",
                    active: true,
                    meta: {
                      tags: "Biomarkers",
                      keywords: "Biomarkers",
                      description: "Biomarkers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b435",
                    },
                    slug: "biomaterials",
                    title: "Biomaterials",
                    id: 1000328,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/biomaterials",
                    active: true,
                    meta: {
                      tags: "Biomaterials",
                      keywords: "Biomaterials",
                      description: "Biomaterials",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b436",
                    },
                    slug: "cell-culture",
                    title: "Cell Culture",
                    id: 1000329,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/cell-culture",
                    active: true,
                    meta: {
                      tags: "Cell Culture",
                      keywords: "Cell Culture",
                      description: "Cell Culture",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b437",
                    },
                    slug: "emerging-technology",
                    title: "Emerging Technology",
                    id: 1000330,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/emerging-technology",
                    active: true,
                    meta: {
                      tags: "Emerging Technology",
                      keywords: "Emerging Technology",
                      description: "Emerging Technology",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b438",
                    },
                    slug: "gene-editing",
                    title: "Gene Editing",
                    id: 1000331,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/gene-editing",
                    active: true,
                    meta: {
                      tags: "Gene Editing",
                      keywords: "Gene Editing",
                      description: "Gene Editing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b439",
                    },
                    slug: "cytology",
                    title: "Cytology",
                    id: 1000332,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/cytology",
                    active: true,
                    meta: {
                      tags: "Cytology",
                      keywords: "Cytology",
                      description: "Cytology",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b43a",
                    },
                    slug: "gene-therapy",
                    title: "Gene Therapy",
                    id: 1000333,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/gene-therapy",
                    active: true,
                    meta: {
                      tags: "Gene Therapy",
                      keywords: "Gene Therapy",
                      description: "Gene Therapy",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b43b",
                    },
                    slug: "genetic-engineering",
                    title: "Genetic Engineering",
                    id: 1000334,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/genetic-engineering",
                    active: true,
                    meta: {
                      tags: "Genetic Engineering",
                      keywords: "Genetic Engineering",
                      description: "Genetic Engineering",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b43c",
                    },
                    slug: "genomics",
                    title: "Genomics",
                    id: 1000335,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/genomics",
                    active: true,
                    meta: {
                      tags: "Genomics",
                      keywords: "Genomics",
                      description: "Genomics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b43d",
                    },
                    slug: "bioinformatics",
                    title: "Bioinformatics",
                    id: 1000336,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/bioinformatics",
                    active: true,
                    meta: {
                      tags: "Bioinformatics",
                      keywords: "Bioinformatics",
                      description: "Bioinformatics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b43e",
                    },
                    slug: "molecular-biology",
                    title: "Molecular Biology",
                    id: 1000337,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/molecular-biology",
                    active: true,
                    meta: {
                      tags: "Molecular Biology",
                      keywords: "Molecular Biology",
                      description: "Molecular Biology",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b43f",
                    },
                    slug: "protein-engineering",
                    title: "Protein Engineering",
                    id: 1000338,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/protein-engineering",
                    active: true,
                    meta: {
                      tags: "Protein Engineering",
                      keywords: "Protein Engineering",
                      description: "Protein Engineering",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b440",
                    },
                    slug: "stem-cell",
                    title: "Stem Cell",
                    id: 1000339,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/stem-cell",
                    active: true,
                    meta: {
                      tags: "Stem Cell",
                      keywords: "Stem Cell",
                      description: "Stem Cell",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b441",
                    },
                    slug: "tissue-engineering",
                    title: "Tissue Engineering",
                    id: 1000340,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/life-science-research-technologies/tissue-engineering",
                    active: true,
                    meta: {
                      tags: "Tissue Engineering",
                      keywords: "Tissue Engineering",
                      description: "Tissue Engineering",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b442",
                },
                slug: "biotechnology-research-equipment",
                title: "Biotechnology Research Equipment",
                id: 1000341,
                parent: "/healthcare/biotechnology/biotechnology-r-and-d",
                path: "/healthcare/biotechnology/biotechnology-r-and-d/biotechnology-research-equipment",
                active: true,
                meta: {
                  tags: "Biotechnology Research Equipment",
                  keywords: "Biotechnology Research Equipment",
                  description: "Biotechnology Research Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b443",
                    },
                    slug: "spectrometry",
                    title: "Spectrometry",
                    id: 1000342,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/biotechnology-research-equipment",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/biotechnology-research-equipment/spectrometry",
                    active: true,
                    meta: {
                      tags: "Spectrometry",
                      keywords: "Spectrometry",
                      description: "Spectrometry",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b444",
                    },
                    slug: "microarray",
                    title: "Microarray",
                    id: 1000343,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/biotechnology-research-equipment",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/biotechnology-research-equipment/microarray",
                    active: true,
                    meta: {
                      tags: "Microarray",
                      keywords: "Microarray",
                      description: "Microarray",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b445",
                    },
                    slug: "genomic-sequencing",
                    title: "Genomic Sequencing",
                    id: 1000344,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/biotechnology-research-equipment",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/biotechnology-research-equipment/genomic-sequencing",
                    active: true,
                    meta: {
                      tags: "Genomic Sequencing",
                      keywords: "Genomic Sequencing",
                      description: "Genomic Sequencing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b446",
                    },
                    slug: "next-generation-sequencing",
                    title: "Next Generation Sequencing",
                    id: 1000345,
                    parent:
                      "/healthcare/biotechnology/biotechnology-r-and-d/biotechnology-research-equipment",
                    path: "/healthcare/biotechnology/biotechnology-r-and-d/biotechnology-research-equipment/next-generation-sequencing",
                    active: true,
                    meta: {
                      tags: "Next Generation Sequencing",
                      keywords: "Next Generation Sequencing",
                      description: "Next Generation Sequencing",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b151",
        },
        id: 5638,
        title: "Pharmaceuticals",
        slug: "pharmaceuticals",
        active: true,
        meta: {
          description:
            "Our well-assorted panel of analysts, experts, and R&D experts in pharma have an answer to your market queries. Browse through a catalog of more than 1000 reports, readily customized and continuously updated.",
          keywords:
            "Pharmaceuticals Market Research Reports, pharmaceutical industry trends, pharmaceutical industry overview, pharmaceutical industry analysis, pharmaceutical industry market research,global pharmaceutical industry analysis, pharmaceutical market report, pharmaceutical industry market analysis, pharmaceutical market report, pharmaceutical market industry report",
          tags: "Pharmaceuticals Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Regardless of your need, our well assorted panel of analysts, experts and R&D experts in pharma have an answer to your market queries. Browse through a catalogue of more than a 1000 reports, readily customized and continuously updated.",
          short_description:
            "The global pharmaceutical market involves studying factors such as pipeline analysis, pre-clinical and clinical trials, and the drug's impact on the current market (post-approval). Browse through some of our industry reports on the global market for pharmaceuticals.",
        },
        picture: null,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/pharmaceuticals",
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b486",
            },
            slug: "active-pharmaceutical-ingredients",
            title: "Active Pharmaceutical Ingredients",
            id: 1000410,
            parent: "/healthcare/pharmaceuticals",
            path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
            active: true,
            meta: {
              tags: "Active Pharmaceutical Ingredients",
              keywords: "Active Pharmaceutical Ingredients",
              description: "Active Pharmaceutical Ingredients",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b487",
                },
                slug: "general-pharmaceuticals",
                title: "General Pharmaceuticals",
                id: 1000411,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                active: true,
                meta: {
                  tags: "General Pharmaceuticals",
                  keywords: "General Pharmaceuticals",
                  description: "General Pharmaceuticals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b488",
                    },
                    slug: "agonists",
                    title: "Agonists",
                    id: 1000412,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/agonists",
                    active: true,
                    meta: {
                      tags: "Agonists",
                      keywords: "Agonists",
                      description: "Agonists",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b489",
                    },
                    slug: "analgesics",
                    title: "Analgesics",
                    id: 1000413,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/analgesics",
                    active: true,
                    meta: {
                      tags: "Analgesics",
                      keywords: "Analgesics",
                      description: "Analgesics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b48a",
                    },
                    slug: "anesthetic-drugs",
                    title: "Anesthetic Drugs",
                    id: 1000414,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/anesthetic-drugs",
                    active: true,
                    meta: {
                      tags: "Anesthetic Drugs",
                      keywords: "Anesthetic Drugs",
                      description: "Anesthetic Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b48b",
                    },
                    slug: "anorexiants",
                    title: "Anorexiants",
                    id: 1000415,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/anorexiants",
                    active: true,
                    meta: {
                      tags: "Anorexiants",
                      keywords: "Anorexiants",
                      description: "Anorexiants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b48c",
                    },
                    slug: "antacids",
                    title: "Antacids",
                    id: 1000416,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antacids",
                    active: true,
                    meta: {
                      tags: "Antacids",
                      keywords: "Antacids",
                      description: "Antacids",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b48d",
                    },
                    slug: "anti-aging",
                    title: "Anti-aging",
                    id: 1000417,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/anti-aging",
                    active: true,
                    meta: {
                      tags: "Anti-aging",
                      keywords: "Anti-aging",
                      description: "Anti-aging",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b48e",
                    },
                    slug: "antibacterials",
                    title: "Antibacterials",
                    id: 1000418,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antibacterials",
                    active: true,
                    meta: {
                      tags: "Antibacterials",
                      keywords: "Antibacterials",
                      description: "Antibacterials",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b48f",
                    },
                    slug: "antibody-therapeutics",
                    title: "Antibody Therapeutics",
                    id: 1000419,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antibody-therapeutics",
                    active: true,
                    meta: {
                      tags: "Antibody Therapeutics",
                      keywords: "Antibody Therapeutics",
                      description: "Antibody Therapeutics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b490",
                    },
                    slug: "antibiotics",
                    title: "Antibiotics",
                    id: 1000420,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antibiotics",
                    active: true,
                    meta: {
                      tags: "Antibiotics",
                      keywords: "Antibiotics",
                      description: "Antibiotics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b491",
                    },
                    slug: "anticoagulants",
                    title: "Anticoagulants",
                    id: 1000421,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/anticoagulants",
                    active: true,
                    meta: {
                      tags: "Anticoagulants",
                      keywords: "Anticoagulants",
                      description: "Anticoagulants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b492",
                    },
                    slug: "anticonvulsants",
                    title: "Anticonvulsants",
                    id: 1000422,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/anticonvulsants",
                    active: true,
                    meta: {
                      tags: "Anticonvulsants",
                      keywords: "Anticonvulsants",
                      description: "Anticonvulsants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b493",
                    },
                    slug: "antidiarrheals",
                    title: "Antidiarrheals",
                    id: 1000423,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antidiarrheals",
                    active: true,
                    meta: {
                      tags: "Antidiarrheals",
                      keywords: "Antidiarrheals",
                      description: "Antidiarrheals",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b494",
                    },
                    slug: "antidotal",
                    title: "Antidotal",
                    id: 1000424,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antidotal",
                    active: true,
                    meta: {
                      tags: "Antidotal",
                      keywords: "Antidotal",
                      description: "Antidotal",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b495",
                    },
                    slug: "antiemetics",
                    title: "Antiemetics",
                    id: 1000425,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antiemetics",
                    active: true,
                    meta: {
                      tags: "Antiemetics",
                      keywords: "Antiemetics",
                      description: "Antiemetics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b496",
                    },
                    slug: "antifungals",
                    title: "Antifungals",
                    id: 1000426,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antifungals",
                    active: true,
                    meta: {
                      tags: "Antifungals",
                      keywords: "Antifungals",
                      description: "Antifungals",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b497",
                    },
                    slug: "antihistamines",
                    title: "Antihistamines",
                    id: 1000427,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antihistamines",
                    active: true,
                    meta: {
                      tags: "Antihistamines",
                      keywords: "Antihistamines",
                      description: "Antihistamines",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b498",
                    },
                    slug: "anti-hypersensitives",
                    title: "Anti-hypersensitives",
                    id: 1000428,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/anti-hypersensitives",
                    active: true,
                    meta: {
                      tags: "Anti-hypersensitives",
                      keywords: "Anti-hypersensitives",
                      description: "Anti-hypersensitives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b499",
                    },
                    slug: "anti-inflammatory",
                    title: "Anti-inflammatory",
                    id: 1000429,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/anti-inflammatory",
                    active: true,
                    meta: {
                      tags: "Anti-inflammatory",
                      keywords: "Anti-inflammatory",
                      description: "Anti-inflammatory",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b49a",
                    },
                    slug: "anti-infectives",
                    title: "Anti-infectives",
                    id: 1000430,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/anti-infectives",
                    active: true,
                    meta: {
                      tags: "Anti-infectives",
                      keywords: "Anti-infectives",
                      description: "Anti-infectives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b49b",
                    },
                    slug: "antineoplastics",
                    title: "Antineoplastics",
                    id: 1000431,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antineoplastics",
                    active: true,
                    meta: {
                      tags: "Antineoplastics",
                      keywords: "Antineoplastics",
                      description: "Antineoplastics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b49c",
                    },
                    slug: "antiparasitic",
                    title: "Antiparasitic",
                    id: 1000432,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antiparasitic",
                    active: true,
                    meta: {
                      tags: "Antiparasitic",
                      keywords: "Antiparasitic",
                      description: "Antiparasitic",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b49d",
                    },
                    slug: "antipyretics",
                    title: "Antipyretics",
                    id: 1000433,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antipyretics",
                    active: true,
                    meta: {
                      tags: "Antipyretics",
                      keywords: "Antipyretics",
                      description: "Antipyretics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b49e",
                    },
                    slug: "antiseptics",
                    title: "Antiseptics",
                    id: 1000434,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antiseptics",
                    active: true,
                    meta: {
                      tags: "Antiseptics",
                      keywords: "Antiseptics",
                      description: "Antiseptics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b49f",
                    },
                    slug: "anti-venom",
                    title: "Anti-venom",
                    id: 1000435,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/anti-venom",
                    active: true,
                    meta: {
                      tags: "Anti-venom",
                      keywords: "Anti-venom",
                      description: "Anti-venom",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4a0",
                    },
                    slug: "antivirals",
                    title: "Antivirals",
                    id: 1000436,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antivirals",
                    active: true,
                    meta: {
                      tags: "Antivirals",
                      keywords: "Antivirals",
                      description: "Antivirals",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4a1",
                        },
                        slug: "anti-retroviral",
                        title: "Anti-retroviral",
                        id: 1000437,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antivirals",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/antivirals/anti-retroviral",
                        active: true,
                        meta: {
                          tags: "Anti-retroviral",
                          keywords: "Anti-retroviral",
                          description: "Anti-retroviral",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4a2",
                    },
                    slug: "beta-blockers",
                    title: "Beta-Blockers",
                    id: 1000438,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/beta-blockers",
                    active: true,
                    meta: {
                      tags: "Beta-Blockers",
                      keywords: "Beta-Blockers",
                      description: "Beta-Blockers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4a3",
                    },
                    slug: "bronchodilators",
                    title: "Bronchodilators",
                    id: 1000439,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/bronchodilators",
                    active: true,
                    meta: {
                      tags: "Bronchodilators",
                      keywords: "Bronchodilators",
                      description: "Bronchodilators",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4a4",
                    },
                    slug: "corticosteroids",
                    title: "Corticosteroids",
                    id: 1000440,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/corticosteroids",
                    active: true,
                    meta: {
                      tags: "Corticosteroids",
                      keywords: "Corticosteroids",
                      description: "Corticosteroids",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4a5",
                    },
                    slug: "decongestants",
                    title: "Decongestants",
                    id: 1000441,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/decongestants",
                    active: true,
                    meta: {
                      tags: "Decongestants",
                      keywords: "Decongestants",
                      description: "Decongestants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4a6",
                    },
                    slug: "diuretics",
                    title: "Diuretics",
                    id: 1000442,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/diuretics",
                    active: true,
                    meta: {
                      tags: "Diuretics",
                      keywords: "Diuretics",
                      description: "Diuretics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4a7",
                    },
                    slug: "hormones",
                    title: "Hormones",
                    id: 1000443,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/hormones",
                    active: true,
                    meta: {
                      tags: "Hormones",
                      keywords: "Hormones",
                      description: "Hormones",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4a8",
                    },
                    slug: "hyperglycemics",
                    title: "Hyperglycemics",
                    id: 1000444,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/hyperglycemics",
                    active: true,
                    meta: {
                      tags: "Hyperglycemics",
                      keywords: "Hyperglycemics",
                      description: "Hyperglycemics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4a9",
                    },
                    slug: "inhibitors",
                    title: "Inhibitors",
                    id: 1000445,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/inhibitors",
                    active: true,
                    meta: {
                      tags: "Inhibitors",
                      keywords: "Inhibitors",
                      description: "Inhibitors",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4aa",
                    },
                    slug: "laxatives",
                    title: "Laxatives",
                    id: 1000446,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/laxatives",
                    active: true,
                    meta: {
                      tags: "Laxatives",
                      keywords: "Laxatives",
                      description: "Laxatives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4ab",
                    },
                    slug: "regulators",
                    title: "Regulators",
                    id: 1000447,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/regulators",
                    active: true,
                    meta: {
                      tags: "Regulators",
                      keywords: "Regulators",
                      description: "Regulators",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4ac",
                    },
                    slug: "sleeping-drugs",
                    title: "Sleeping Drugs",
                    id: 1000448,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/sleeping-drugs",
                    active: true,
                    meta: {
                      tags: "Sleeping Drugs",
                      keywords: "Sleeping Drugs",
                      description: "Sleeping Drugs",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4ad",
                        },
                        slug: "benzodiazepines",
                        title: "Benzodiazepines",
                        id: 1000449,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/sleeping-drugs",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/sleeping-drugs/benzodiazepines",
                        active: true,
                        meta: {
                          tags: "Benzodiazepines",
                          keywords: "Benzodiazepines",
                          description: "Benzodiazepines",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4ae",
                        },
                        slug: "barbiturates",
                        title: "Barbiturates",
                        id: 1000450,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/sleeping-drugs",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/sleeping-drugs/barbiturates",
                        active: true,
                        meta: {
                          tags: "Barbiturates",
                          keywords: "Barbiturates",
                          description: "Barbiturates",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4af",
                    },
                    slug: "suppressants",
                    title: "Suppressants",
                    id: 1000451,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/suppressants",
                    active: true,
                    meta: {
                      tags: "Suppressants",
                      keywords: "Suppressants",
                      description: "Suppressants",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4b0",
                        },
                        slug: "cough-suppressants",
                        title: "Cough Suppressants",
                        id: 1000452,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/suppressants",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/suppressants/cough-suppressants",
                        active: true,
                        meta: {
                          tags: "Cough Suppressants",
                          keywords: "Cough Suppressants",
                          description: "Cough Suppressants",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4b1",
                        },
                        slug: "immunosuppressives",
                        title: "Immunosuppressives",
                        id: 1000453,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/suppressants",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/suppressants/immunosuppressives",
                        active: true,
                        meta: {
                          tags: "Immunosuppressives",
                          keywords: "Immunosuppressives",
                          description: "Immunosuppressives",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4b2",
                        },
                        slug: "muscle-relaxants",
                        title: "Muscle Relaxants",
                        id: 1000454,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/suppressants",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/suppressants/muscle-relaxants",
                        active: true,
                        meta: {
                          tags: "Muscle Relaxants",
                          keywords: "Muscle Relaxants",
                          description: "Muscle Relaxants",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4b3",
                    },
                    slug: "tranquilizers",
                    title: "Tranquilizers",
                    id: 1000455,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers",
                    active: true,
                    meta: {
                      tags: "Tranquilizers",
                      keywords: "Tranquilizers",
                      description: "Tranquilizers",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4b4",
                        },
                        slug: "antidepressants",
                        title: "Antidepressants",
                        id: 1000456,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers/antidepressants",
                        active: true,
                        meta: {
                          tags: "Antidepressants",
                          keywords: "Antidepressants",
                          description: "Antidepressants",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4b5",
                        },
                        slug: "antianxiety-drugs",
                        title: "Antianxiety Drugs",
                        id: 1000457,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers/antianxiety-drugs",
                        active: true,
                        meta: {
                          tags: "Antianxiety Drugs",
                          keywords: "Antianxiety Drugs",
                          description: "Antianxiety Drugs",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4b6",
                        },
                        slug: "antipsychotics",
                        title: "Antipsychotics",
                        id: 1000458,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers/antipsychotics",
                        active: true,
                        meta: {
                          tags: "Antipsychotics",
                          keywords: "Antipsychotics",
                          description: "Antipsychotics",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4b7",
                        },
                        slug: "sedatives",
                        title: "Sedatives",
                        id: 1000459,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers/sedatives",
                        active: true,
                        meta: {
                          tags: "Sedatives",
                          keywords: "Sedatives",
                          description: "Sedatives",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4b8",
                        },
                        slug: "opioids",
                        title: "Opioids",
                        id: 1000460,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers/opioids",
                        active: true,
                        meta: {
                          tags: "Opioids",
                          keywords: "Opioids",
                          description: "Opioids",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b4b9",
                        },
                        slug: "cannabis",
                        title: "Cannabis",
                        id: 1000461,
                        parent:
                          "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers",
                        path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/general-pharmaceuticals/tranquilizers/cannabis",
                        active: true,
                        meta: {
                          tags: "Cannabis",
                          keywords: "Cannabis",
                          description: "Cannabis",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4ba",
                },
                slug: "injectables",
                title: "Injectables",
                id: 1000462,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/injectables",
                active: true,
                meta: {
                  tags: "Injectables",
                  keywords: "Injectables",
                  description: "Injectables",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4bb",
                    },
                    slug: "intravenous-injections",
                    title: "Intravenous Injections",
                    id: 1000463,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/injectables",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/injectables/intravenous-injections",
                    active: true,
                    meta: {
                      tags: "Intravenous Injections",
                      keywords: "Intravenous Injections",
                      description: "Intravenous Injections",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4bc",
                    },
                    slug: "intramuscular-injections",
                    title: "Intramuscular Injections",
                    id: 1000464,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/injectables",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/injectables/intramuscular-injections",
                    active: true,
                    meta: {
                      tags: "Intramuscular Injections",
                      keywords: "Intramuscular Injections",
                      description: "Intramuscular Injections",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4bd",
                    },
                    slug: "subcutaneous-injections",
                    title: "Subcutaneous Injections",
                    id: 1000465,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/injectables",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/injectables/subcutaneous-injections",
                    active: true,
                    meta: {
                      tags: "Subcutaneous Injections",
                      keywords: "Subcutaneous Injections",
                      description: "Subcutaneous Injections",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4be",
                    },
                    slug: "intradermal-injections",
                    title: "Intradermal Injections",
                    id: 1000466,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/injectables",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/injectables/intradermal-injections",
                    active: true,
                    meta: {
                      tags: "Intradermal Injections",
                      keywords: "Intradermal Injections",
                      description: "Intradermal Injections",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4bf",
                },
                slug: "nasal-drugs",
                title: "Nasal Drugs",
                id: 1000467,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/nasal-drugs",
                active: true,
                meta: {
                  tags: "Nasal Drugs",
                  keywords: "Nasal Drugs",
                  description: "Nasal Drugs",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4c0",
                    },
                    slug: "nasal-systemic-drugs",
                    title: "Nasal Systemic Drugs",
                    id: 1000468,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/nasal-drugs",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/nasal-drugs/nasal-systemic-drugs",
                    active: true,
                    meta: {
                      tags: "Nasal Systemic Drugs",
                      keywords: "Nasal Systemic Drugs",
                      description: "Nasal Systemic Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4c1",
                    },
                    slug: "nasal-topical-drugs",
                    title: "Nasal Topical Drugs",
                    id: 1000469,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/nasal-drugs",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/nasal-drugs/nasal-topical-drugs",
                    active: true,
                    meta: {
                      tags: "Nasal Topical Drugs",
                      keywords: "Nasal Topical Drugs",
                      description: "Nasal Topical Drugs",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4c2",
                },
                slug: "oral-drugs",
                title: "Oral Drugs",
                id: 1000470,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/oral-drugs",
                active: true,
                meta: {
                  tags: "Oral Drugs",
                  keywords: "Oral Drugs",
                  description: "Oral Drugs",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4c3",
                    },
                    slug: "oral-liquid-drugs",
                    title: "Oral Liquid Drugs",
                    id: 1000471,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/oral-drugs",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/oral-drugs/oral-liquid-drugs",
                    active: true,
                    meta: {
                      tags: "Oral Liquid Drugs",
                      keywords: "Oral Liquid Drugs",
                      description: "Oral Liquid Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4c4",
                    },
                    slug: "oral-solid-drugs",
                    title: "Oral Solid Drugs",
                    id: 1000472,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/oral-drugs",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/oral-drugs/oral-solid-drugs",
                    active: true,
                    meta: {
                      tags: "Oral Solid Drugs",
                      keywords: "Oral Solid Drugs",
                      description: "Oral Solid Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4c5",
                    },
                    slug: "oral-topical-drugs",
                    title: "Oral Topical Drugs",
                    id: 1000473,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/oral-drugs",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/oral-drugs/oral-topical-drugs",
                    active: true,
                    meta: {
                      tags: "Oral Topical Drugs",
                      keywords: "Oral Topical Drugs",
                      description: "Oral Topical Drugs",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4c6",
                },
                slug: "parenteral-drugs",
                title: "Parenteral Drugs",
                id: 1000474,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/parenteral-drugs",
                active: true,
                meta: {
                  tags: "Parenteral Drugs",
                  keywords: "Parenteral Drugs",
                  description: "Parenteral Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4c7",
                },
                slug: "suppositories",
                title: "Suppositories",
                id: 1000475,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/suppositories",
                active: true,
                meta: {
                  tags: "Suppositories",
                  keywords: "Suppositories",
                  description: "Suppositories",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4c8",
                },
                slug: "topical-drugs",
                title: "Topical Drugs",
                id: 1000476,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/topical-drugs",
                active: true,
                meta: {
                  tags: "Topical Drugs",
                  keywords: "Topical Drugs",
                  description: "Topical Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4c9",
                },
                slug: "ointments",
                title: "Ointments",
                id: 1000477,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/ointments",
                active: true,
                meta: {
                  tags: "Ointments",
                  keywords: "Ointments",
                  description: "Ointments",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4ca",
                },
                slug: "synthetic-compounds",
                title: "Synthetic Compounds",
                id: 1000478,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/synthetic-compounds",
                active: true,
                meta: {
                  tags: "Synthetic Compounds",
                  keywords: "Synthetic Compounds",
                  description: "Synthetic Compounds",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4cb",
                },
                slug: "alternative-medicine",
                title: "Alternative Medicine",
                id: 1000479,
                parent:
                  "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients",
                path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/alternative-medicine",
                active: true,
                meta: {
                  tags: "Alternative Medicine",
                  keywords: "Alternative Medicine",
                  description: "Alternative Medicine",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4cc",
                    },
                    slug: "precision-medicine",
                    title: "Precision Medicine",
                    id: 1000480,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/alternative-medicine",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/alternative-medicine/precision-medicine",
                    active: true,
                    meta: {
                      tags: "Precision Medicine",
                      keywords: "Precision Medicine",
                      description: "Precision Medicine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4cd",
                    },
                    slug: "herbal-drugs",
                    title: "Herbal Drugs",
                    id: 1000481,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/alternative-medicine",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/alternative-medicine/herbal-drugs",
                    active: true,
                    meta: {
                      tags: "Herbal Drugs",
                      keywords: "Herbal Drugs",
                      description: "Herbal Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4ce",
                    },
                    slug: "homeopathic-products",
                    title: "Homeopathic Products",
                    id: 1000482,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/alternative-medicine",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/alternative-medicine/homeopathic-products",
                    active: true,
                    meta: {
                      tags: "Homeopathic Products",
                      keywords: "Homeopathic Products",
                      description: "Homeopathic Products",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4cf",
                    },
                    slug: "ayurvedic-drugs",
                    title: "Ayurvedic Drugs",
                    id: 1000483,
                    parent:
                      "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/alternative-medicine",
                    path: "/healthcare/pharmaceuticals/active-pharmaceutical-ingredients/alternative-medicine/ayurvedic-drugs",
                    active: true,
                    meta: {
                      tags: "Ayurvedic Drugs",
                      keywords: "Ayurvedic Drugs",
                      description: "Ayurvedic Drugs",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b4d0",
            },
            slug: "finished-dosage-form",
            title: "Finished Dosage Form",
            id: 1000484,
            parent: "/healthcare/pharmaceuticals",
            path: "/healthcare/pharmaceuticals/finished-dosage-form",
            active: true,
            meta: {
              tags: "Finished Dosage Form",
              keywords: "Finished Dosage Form",
              description: "Finished Dosage Form",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4d1",
                },
                slug: "generic-drugs",
                title: "Generic Drugs",
                id: 1000485,
                parent: "/healthcare/pharmaceuticals/finished-dosage-form",
                path: "/healthcare/pharmaceuticals/finished-dosage-form/generic-drugs",
                active: true,
                meta: {
                  tags: "Generic Drugs",
                  keywords: "Generic Drugs",
                  description: "Generic Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4d2",
                },
                slug: "otc-drugs",
                title: "OTC Drugs",
                id: 1000486,
                parent: "/healthcare/pharmaceuticals/finished-dosage-form",
                path: "/healthcare/pharmaceuticals/finished-dosage-form/otc-drugs",
                active: true,
                meta: {
                  tags: "OTC Drugs",
                  keywords: "OTC Drugs",
                  description: "OTC Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4d3",
                },
                slug: "regenerative-medicine",
                title: "Regenerative Medicine",
                id: 1000487,
                parent: "/healthcare/pharmaceuticals/finished-dosage-form",
                path: "/healthcare/pharmaceuticals/finished-dosage-form/regenerative-medicine",
                active: true,
                meta: {
                  tags: "Regenerative Medicine",
                  keywords: "Regenerative Medicine",
                  description: "Regenerative Medicine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4d4",
                },
                slug: "biosimilars",
                title: "Biosimilars",
                id: 1000488,
                parent: "/healthcare/pharmaceuticals/finished-dosage-form",
                path: "/healthcare/pharmaceuticals/finished-dosage-form/biosimilars",
                active: true,
                meta: {
                  tags: "Biosimilars",
                  keywords: "Biosimilars",
                  description: "Biosimilars",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4d5",
                },
                slug: "biologics",
                title: "Biologics",
                id: 1000489,
                parent: "/healthcare/pharmaceuticals/finished-dosage-form",
                path: "/healthcare/pharmaceuticals/finished-dosage-form/biologics",
                active: true,
                meta: {
                  tags: "Biologics",
                  keywords: "Biologics",
                  description: "Biologics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4d6",
                },
                slug: "vaccines",
                title: "Vaccines",
                id: 1000490,
                parent: "/healthcare/pharmaceuticals/finished-dosage-form",
                path: "/healthcare/pharmaceuticals/finished-dosage-form/vaccines",
                active: true,
                meta: {
                  tags: "Vaccines",
                  keywords: "Vaccines",
                  description: "Vaccines",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b4d7",
            },
            slug: "clinical-nutrition",
            title: "Clinical Nutrition",
            id: 1000491,
            parent: "/healthcare/pharmaceuticals",
            path: "/healthcare/pharmaceuticals/clinical-nutrition",
            active: true,
            meta: {
              tags: "Clinical Nutrition",
              keywords: "Clinical Nutrition",
              description: "Clinical Nutrition",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b4d8",
            },
            slug: "non-regulated-drugs",
            title: "Non regulated Drugs",
            id: 1000492,
            parent: "/healthcare/pharmaceuticals",
            path: "/healthcare/pharmaceuticals/non-regulated-drugs",
            active: true,
            meta: {
              tags: "Non regulated Drugs",
              keywords: "Non regulated Drugs",
              description: "Non regulated Drugs",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b4d9",
            },
            slug: "disease-specific-drugs",
            title: "Disease Specific Drugs",
            id: 1000493,
            parent: "/healthcare/pharmaceuticals",
            path: "/healthcare/pharmaceuticals/disease-specific-drugs",
            active: true,
            meta: {
              tags: "Disease Specific Drugs",
              keywords: "Disease Specific Drugs",
              description: "Disease Specific Drugs",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4da",
                },
                slug: "infectious-disease-drugs",
                title: "Infectious Disease Drugs",
                id: 1000494,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/infectious-disease-drugs",
                active: true,
                meta: {
                  tags: "Infectious Disease Drugs",
                  keywords: "Infectious Disease Drugs",
                  description: "Infectious Disease Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4db",
                },
                slug: "parasitic-disease-drugs",
                title: "Parasitic Disease Drugs",
                id: 1000495,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/parasitic-disease-drugs",
                active: true,
                meta: {
                  tags: "Parasitic Disease Drugs",
                  keywords: "Parasitic Disease Drugs",
                  description: "Parasitic Disease Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4dc",
                },
                slug: "immune-system-drugs",
                title: "Immune System Drugs",
                id: 1000496,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/immune-system-drugs",
                active: true,
                meta: {
                  tags: "Immune System Drugs",
                  keywords: "Immune System Drugs",
                  description: "Immune System Drugs",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4dd",
                    },
                    slug: "immunodeficiencies",
                    title: "Immunodeficiencies",
                    id: 1000497,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/immune-system-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/immune-system-drugs/immunodeficiencies",
                    active: true,
                    meta: {
                      tags: "Immunodeficiencies",
                      keywords: "Immunodeficiencies",
                      description: "Immunodeficiencies",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4de",
                    },
                    slug: "autoimmune-disorders",
                    title: "Autoimmune Disorders",
                    id: 1000498,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/immune-system-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/immune-system-drugs/autoimmune-disorders",
                    active: true,
                    meta: {
                      tags: "Autoimmune Disorders",
                      keywords: "Autoimmune Disorders",
                      description: "Autoimmune Disorders",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4df",
                    },
                    slug: "autoinflammatory-disorders",
                    title: "Autoinflammatory Disorders",
                    id: 1000499,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/immune-system-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/immune-system-drugs/autoinflammatory-disorders",
                    active: true,
                    meta: {
                      tags: "Autoinflammatory Disorders",
                      keywords: "Autoinflammatory Disorders",
                      description: "Autoinflammatory Disorders",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4e0",
                    },
                    slug: "hypersensitivity-disorders",
                    title: "Hypersensitivity Disorders",
                    id: 1000500,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/immune-system-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/immune-system-drugs/hypersensitivity-disorders",
                    active: true,
                    meta: {
                      tags: "Hypersensitivity Disorders",
                      keywords: "Hypersensitivity Disorders",
                      description: "Hypersensitivity Disorders",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4e1",
                },
                slug: "genetic-disorder-drugs",
                title: "Genetic Disorder Drugs",
                id: 1000501,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/genetic-disorder-drugs",
                active: true,
                meta: {
                  tags: "Genetic Disorder Drugs",
                  keywords: "Genetic Disorder Drugs",
                  description: "Genetic Disorder Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4e2",
                },
                slug: "metabolic-disorder-drugs",
                title: "Metabolic Disorder Drugs",
                id: 1000502,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/metabolic-disorder-drugs",
                active: true,
                meta: {
                  tags: "Metabolic Disorder Drugs",
                  keywords: "Metabolic Disorder Drugs",
                  description: "Metabolic Disorder Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4e3",
                },
                slug: "chronic-disorder-drugs",
                title: "Chronic Disorder Drugs",
                id: 1000503,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                active: true,
                meta: {
                  tags: "Chronic Disorder Drugs",
                  keywords: "Chronic Disorder Drugs",
                  description: "Chronic Disorder Drugs",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4e4",
                    },
                    slug: "oncology-drugs",
                    title: "Oncology Drugs",
                    id: 1000504,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/oncology-drugs",
                    active: true,
                    meta: {
                      tags: "Oncology Drugs",
                      keywords: "Oncology Drugs",
                      description: "Oncology Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4e5",
                    },
                    slug: "cardiovascular-drugs",
                    title: "Cardiovascular Drugs",
                    id: 1000505,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/cardiovascular-drugs",
                    active: true,
                    meta: {
                      tags: "Cardiovascular Drugs",
                      keywords: "Cardiovascular Drugs",
                      description: "Cardiovascular Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4e6",
                    },
                    slug: "orthopedic-drugs",
                    title: "Orthopedic Drugs",
                    id: 1000506,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/orthopedic-drugs",
                    active: true,
                    meta: {
                      tags: "Orthopedic Drugs",
                      keywords: "Orthopedic Drugs",
                      description: "Orthopedic Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4e7",
                    },
                    slug: "ophthalmic-drugs",
                    title: "Ophthalmic Drugs",
                    id: 1000507,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/ophthalmic-drugs",
                    active: true,
                    meta: {
                      tags: "Ophthalmic Drugs",
                      keywords: "Ophthalmic Drugs",
                      description: "Ophthalmic Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4e8",
                    },
                    slug: "dental-care-drugs",
                    title: "Dental Care Drugs",
                    id: 1000508,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/dental-care-drugs",
                    active: true,
                    meta: {
                      tags: "Dental Care Drugs",
                      keywords: "Dental Care Drugs",
                      description: "Dental Care Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4e9",
                    },
                    slug: "nephrology-drugs",
                    title: "Nephrology Drugs",
                    id: 1000509,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/nephrology-drugs",
                    active: true,
                    meta: {
                      tags: "Nephrology Drugs",
                      keywords: "Nephrology Drugs",
                      description: "Nephrology Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4ea",
                    },
                    slug: "diabetes-care-drugs",
                    title: "Diabetes Care Drugs",
                    id: 1000510,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/diabetes-care-drugs",
                    active: true,
                    meta: {
                      tags: "Diabetes Care Drugs",
                      keywords: "Diabetes Care Drugs",
                      description: "Diabetes Care Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4eb",
                    },
                    slug: "respiratory-system-drugs",
                    title: "Respiratory System Drugs",
                    id: 1000511,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/respiratory-system-drugs",
                    active: true,
                    meta: {
                      tags: "Respiratory System Drugs",
                      keywords: "Respiratory System Drugs",
                      description: "Respiratory System Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4ec",
                    },
                    slug: "neurology-drugs",
                    title: "Neurology Drugs",
                    id: 1000512,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/neurology-drugs",
                    active: true,
                    meta: {
                      tags: "Neurology Drugs",
                      keywords: "Neurology Drugs",
                      description: "Neurology Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4ed",
                    },
                    slug: "gastroenterology-drugs",
                    title: "Gastroenterology Drugs",
                    id: 1000513,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/gastroenterology-drugs",
                    active: true,
                    meta: {
                      tags: "Gastroenterology Drugs",
                      keywords: "Gastroenterology Drugs",
                      description: "Gastroenterology Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4ee",
                    },
                    slug: "endocrinology-drugs",
                    title: "Endocrinology Drugs",
                    id: 1000514,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/endocrinology-drugs",
                    active: true,
                    meta: {
                      tags: "Endocrinology Drugs",
                      keywords: "Endocrinology Drugs",
                      description: "Endocrinology Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4ef",
                    },
                    slug: "liver-disease-drugs",
                    title: "Liver Disease Drugs",
                    id: 1000515,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/liver-disease-drugs",
                    active: true,
                    meta: {
                      tags: "Liver Disease Drugs",
                      keywords: "Liver Disease Drugs",
                      description: "Liver Disease Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4f0",
                    },
                    slug: "circulatory-system-drugs",
                    title: "Circulatory System Drugs",
                    id: 1000516,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/circulatory-system-drugs",
                    active: true,
                    meta: {
                      tags: "Circulatory System Drugs",
                      keywords: "Circulatory System Drugs",
                      description: "Circulatory System Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4f1",
                    },
                    slug: "urology-drugs",
                    title: "Urology Drugs",
                    id: 1000517,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/urology-drugs",
                    active: true,
                    meta: {
                      tags: "Urology Drugs",
                      keywords: "Urology Drugs",
                      description: "Urology Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4f2",
                    },
                    slug: "central-nervous-system-drugs",
                    title: "Central Nervous System Drugs",
                    id: 1000518,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/central-nervous-system-drugs",
                    active: true,
                    meta: {
                      tags: "Central Nervous System Drugs",
                      keywords: "Central Nervous System Drugs",
                      description: "Central Nervous System Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4f3",
                    },
                    slug: "dermatological-drugs",
                    title: "Dermatological Drugs",
                    id: 1000519,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/chronic-disorder-drugs/dermatological-drugs",
                    active: true,
                    meta: {
                      tags: "Dermatological Drugs",
                      keywords: "Dermatological Drugs",
                      description: "Dermatological Drugs",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4f4",
                },
                slug: "behavioural-disorder-drugs",
                title: "Behavioural Disorder Drugs",
                id: 1000520,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/behavioural-disorder-drugs",
                active: true,
                meta: {
                  tags: "Behavioural Disorder Drugs",
                  keywords: "Behavioural Disorder Drugs",
                  description: "Behavioural Disorder Drugs",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4f5",
                    },
                    slug: "psychological-syndrome",
                    title: "Psychological Syndrome",
                    id: 1000521,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/behavioural-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/behavioural-disorder-drugs/psychological-syndrome",
                    active: true,
                    meta: {
                      tags: "Psychological Syndrome",
                      keywords: "Psychological Syndrome",
                      description: "Psychological Syndrome",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4f6",
                    },
                    slug: "sleep-wake-disorders",
                    title: "Sleep Wake Disorders",
                    id: 1000522,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/behavioural-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/behavioural-disorder-drugs/sleep-wake-disorders",
                    active: true,
                    meta: {
                      tags: "Sleep Wake Disorders",
                      keywords: "Sleep Wake Disorders",
                      description: "Sleep Wake Disorders",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4f7",
                    },
                    slug: "sexual-dysfunctions",
                    title: "Sexual Dysfunctions",
                    id: 1000523,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/behavioural-disorder-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/behavioural-disorder-drugs/sexual-dysfunctions",
                    active: true,
                    meta: {
                      tags: "Sexual Dysfunctions",
                      keywords: "Sexual Dysfunctions",
                      description: "Sexual Dysfunctions",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4f8",
                },
                slug: "ob/gyn-drugs",
                title: "OB/GYN Drugs",
                id: 1000524,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/ob/gyn-drugs",
                active: true,
                meta: {
                  tags: "OB/GYN Drugs",
                  keywords: "OB/GYN Drugs",
                  description: "OB/GYN Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4f9",
                },
                slug: "rare-disease-drugs",
                title: "Rare Disease Drugs",
                id: 1000525,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/rare-disease-drugs",
                active: true,
                meta: {
                  tags: "Rare Disease Drugs",
                  keywords: "Rare Disease Drugs",
                  description: "Rare Disease Drugs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4fa",
                },
                slug: "developmental-anomalities",
                title: "Developmental Anomalities",
                id: 1000526,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/developmental-anomalities",
                active: true,
                meta: {
                  tags: "Developmental Anomalities",
                  keywords: "Developmental Anomalities",
                  description: "Developmental Anomalities",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4fb",
                },
                slug: "external-condition-drugs",
                title: "External Condition Drugs",
                id: 1000527,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/external-condition-drugs",
                active: true,
                meta: {
                  tags: "External Condition Drugs",
                  keywords: "External Condition Drugs",
                  description: "External Condition Drugs",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b4fc",
                    },
                    slug: "sports-medicine",
                    title: "Sports Medicine",
                    id: 1000528,
                    parent:
                      "/healthcare/pharmaceuticals/disease-specific-drugs/external-condition-drugs",
                    path: "/healthcare/pharmaceuticals/disease-specific-drugs/external-condition-drugs/sports-medicine",
                    active: true,
                    meta: {
                      tags: "Sports Medicine",
                      keywords: "Sports Medicine",
                      description: "Sports Medicine",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b4fd",
                },
                slug: "traditional-medicine-disorders",
                title: "Traditional Medicine Disorders",
                id: 1000529,
                parent: "/healthcare/pharmaceuticals/disease-specific-drugs",
                path: "/healthcare/pharmaceuticals/disease-specific-drugs/traditional-medicine-disorders",
                active: true,
                meta: {
                  tags: "Traditional Medicine Disorders",
                  keywords: "Traditional Medicine Disorders",
                  description: "Traditional Medicine Disorders",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b152",
        },
        id: 5641,
        title: "Healthcare IT",
        slug: "healthcare-it",
        active: true,
        meta: {
          description:
            "Global recognition of Healthcare IT in solving preventive and other diseases in both emerging and developed countries is well known. Invest in a team of analysts who can bridge the knowledge gap between your existing operations and the optimal integration of software, hardware, and operation: invest in Mordor Intelligence™.",
          keywords:
            "Healthcare IT Market Research Reports, healthcare it market, healthcare it market report, healthcare it market research, healthcare it industry, healthcare it industry report, healthcare it industry research",
          tags: "Healthcare IT Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Global recognition of Healthcare IT in solving preventive and other diseases in both emerging and developed countries is well known. Invest in a team of analysts who can bridge the knowledge-gap between your existing operations and the optimal integration of software, hardware and operation: invest in MI.",
          short_description:
            "This section studies the healthcare IT market and analyzes factors affecting it. We cover clinical information systems, telemedicine, prescriptive analytics, and cloud computing in healthcare and IoT in healthcare among other sub-segments.",
        },
        picture: null,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/healthcare-it",
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b392",
            },
            slug: "healthcare-provider-it",
            title: "Healthcare Provider IT",
            id: 1000163,
            parent: "/healthcare/healthcare-it",
            path: "/healthcare/healthcare-it/healthcare-provider-it",
            active: true,
            meta: {
              tags: "Healthcare Provider IT",
              keywords: "Healthcare Provider IT",
              description: "Healthcare Provider IT",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b393",
                },
                slug: "clinical-it",
                title: "Clinical IT",
                id: 1000164,
                parent: "/healthcare/healthcare-it/healthcare-provider-it",
                path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                active: true,
                meta: {
                  tags: "Clinical IT",
                  keywords: "Clinical IT",
                  description: "Clinical IT",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b394",
                    },
                    slug: "ehr",
                    title: "EHR",
                    id: 1000165,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/ehr",
                    active: true,
                    meta: {
                      tags: "EHR",
                      keywords: "EHR",
                      description: "EHR",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b395",
                    },
                    slug: "pacs-vna",
                    title: "PACS VNA",
                    id: 1000166,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/pacs-vna",
                    active: true,
                    meta: {
                      tags: "PACS VNA",
                      keywords: "PACS VNA",
                      description: "PACS VNA",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b396",
                    },
                    slug: "cpoe",
                    title: "CPOE",
                    id: 1000167,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/cpoe",
                    active: true,
                    meta: {
                      tags: "CPOE",
                      keywords: "CPOE",
                      description: "CPOE",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b397",
                    },
                    slug: "clinical-decision-support-system",
                    title: "Clinical Decision Support System",
                    id: 1000168,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/clinical-decision-support-system",
                    active: true,
                    meta: {
                      tags: "Clinical Decision Support System",
                      keywords: "Clinical Decision Support System",
                      description: "Clinical Decision Support System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b398",
                    },
                    slug: "e-prescribing",
                    title: "E-Prescribing",
                    id: 1000169,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/e-prescribing",
                    active: true,
                    meta: {
                      tags: "E-Prescribing",
                      keywords: "E-Prescribing",
                      description: "E-Prescribing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b399",
                    },
                    slug: "patient-engagement-solutions",
                    title: "Patient Engagement Solutions",
                    id: 1000170,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/patient-engagement-solutions",
                    active: true,
                    meta: {
                      tags: "Patient Engagement Solutions",
                      keywords: "Patient Engagement Solutions",
                      description: "Patient Engagement Solutions",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b39a",
                    },
                    slug: "radiology-information-systems",
                    title: "Radiology Information Systems",
                    id: 1000171,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/radiology-information-systems",
                    active: true,
                    meta: {
                      tags: "Radiology Information Systems",
                      keywords: "Radiology Information Systems",
                      description: "Radiology Information Systems",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b39b",
                    },
                    slug: "practice-management-systems",
                    title: "Practice Management Systems",
                    id: 1000172,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/practice-management-systems",
                    active: true,
                    meta: {
                      tags: "Practice Management Systems",
                      keywords: "Practice Management Systems",
                      description: "Practice Management Systems",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b39c",
                    },
                    slug: "radiation-dose-management",
                    title: "Radiation Dose Management",
                    id: 1000173,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/radiation-dose-management",
                    active: true,
                    meta: {
                      tags: "Radiation Dose Management",
                      keywords: "Radiation Dose Management",
                      description: "Radiation Dose Management",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b39d",
                    },
                    slug: "specialty-information-management-systems",
                    title: "Specialty Information Management Systems",
                    id: 1000174,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/specialty-information-management-systems",
                    active: true,
                    meta: {
                      tags: "Specialty Information Management Systems",
                      keywords: "Specialty Information Management Systems",
                      description: "Specialty Information Management Systems",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b39e",
                    },
                    slug: "medical-image-analysis",
                    title: "Medical Image Analysis",
                    id: 1000175,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/medical-image-analysis",
                    active: true,
                    meta: {
                      tags: "Medical Image Analysis",
                      keywords: "Medical Image Analysis",
                      description: "Medical Image Analysis",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b39f",
                    },
                    slug: "population-health-management-solutions",
                    title: "Population Health Management Solutions",
                    id: 1000176,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/population-health-management-solutions",
                    active: true,
                    meta: {
                      tags: "Population Health Management Solutions",
                      keywords: "Population Health Management Solutions",
                      description: "Population Health Management Solutions",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3a0",
                    },
                    slug: "care-management-systems",
                    title: "Care Management Systems",
                    id: 1000177,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/care-management-systems",
                    active: true,
                    meta: {
                      tags: "Care Management Systems",
                      keywords: "Care Management Systems",
                      description: "Care Management Systems",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3a1",
                    },
                    slug: "patient-registry",
                    title: "Patient Registry",
                    id: 1000178,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/patient-registry",
                    active: true,
                    meta: {
                      tags: "Patient Registry",
                      keywords: "Patient Registry",
                      description: "Patient Registry",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3a2",
                    },
                    slug: "laboratory-information-systems",
                    title: "Laboratory Information Systems",
                    id: 1000179,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/laboratory-information-systems",
                    active: true,
                    meta: {
                      tags: "Laboratory Information Systems",
                      keywords: "Laboratory Information Systems",
                      description: "Laboratory Information Systems",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3a3",
                    },
                    slug: "mheath-solutions",
                    title: "mheath Solutions",
                    id: 1000180,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/mheath-solutions",
                    active: true,
                    meta: {
                      tags: "mheath Solutions",
                      keywords: "mheath Solutions",
                      description: "mheath Solutions",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3a4",
                    },
                    slug: "telemedicine",
                    title: "Telemedicine",
                    id: 1000181,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/telemedicine",
                    active: true,
                    meta: {
                      tags: "Telemedicine",
                      keywords: "Telemedicine",
                      description: "Telemedicine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3a5",
                    },
                    slug: "healthcare-it-integration-systems",
                    title: "Healthcare IT Integration Systems",
                    id: 1000182,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/healthcare-it-integration-systems",
                    active: true,
                    meta: {
                      tags: "Healthcare IT Integration Systems",
                      keywords: "Healthcare IT Integration Systems",
                      description: "Healthcare IT Integration Systems",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3a6",
                        },
                        slug: "healthcare-it-integration-products",
                        title: "Healthcare IT Integration Products",
                        id: 1000183,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/healthcare-it-integration-systems",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/healthcare-it-integration-systems/healthcare-it-integration-products",
                        active: true,
                        meta: {
                          tags: "Healthcare IT Integration Products",
                          keywords: "Healthcare IT Integration Products",
                          description: "Healthcare IT Integration Products",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b3a7",
                            },
                            slug: "hcit-integration-software",
                            title: "HCIT Integration Software",
                            id: 1000184,
                            parent:
                              "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/healthcare-it-integration-systems/healthcare-it-integration-products",
                            path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/healthcare-it-integration-systems/healthcare-it-integration-products/hcit-integration-software",
                            active: true,
                            meta: {
                              tags: "HCIT Integration Software",
                              keywords: "HCIT Integration Software",
                              description: "HCIT Integration Software",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b3a8",
                            },
                            slug: "hcit-integration-hardware",
                            title: "HCIT Integration Hardware",
                            id: 1000185,
                            parent:
                              "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/healthcare-it-integration-systems/healthcare-it-integration-products",
                            path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/healthcare-it-integration-systems/healthcare-it-integration-products/hcit-integration-hardware",
                            active: true,
                            meta: {
                              tags: "HCIT Integration Hardware",
                              keywords: "HCIT Integration Hardware",
                              description: "HCIT Integration Hardware",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3a9",
                    },
                    slug: "infection-surveillance",
                    title: "Infection Surveillance",
                    id: 1000186,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/infection-surveillance",
                    active: true,
                    meta: {
                      tags: "Infection Surveillance",
                      keywords: "Infection Surveillance",
                      description: "Infection Surveillance",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3aa",
                    },
                    slug: "healthcare-practice-management",
                    title: "Healthcare Practice Management",
                    id: 1000187,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/clinical-it/healthcare-practice-management",
                    active: true,
                    meta: {
                      tags: "Healthcare Practice Management",
                      keywords: "Healthcare Practice Management",
                      description: "Healthcare Practice Management",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3ab",
                },
                slug: "non-clinical-it",
                title: "Non Clinical IT",
                id: 1000188,
                parent: "/healthcare/healthcare-it/healthcare-provider-it",
                path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                active: true,
                meta: {
                  tags: "Non Clinical IT",
                  keywords: "Non Clinical IT",
                  description: "Non Clinical IT",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3ac",
                    },
                    slug: "pharmacy-information-systems",
                    title: "Pharmacy Information Systems",
                    id: 1000189,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/pharmacy-information-systems",
                    active: true,
                    meta: {
                      tags: "Pharmacy Information Systems",
                      keywords: "Pharmacy Information Systems",
                      description: "Pharmacy Information Systems",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3ad",
                    },
                    slug: "healthcare-asset-management-solutions",
                    title: "Healthcare Asset Management Solutions",
                    id: 1000190,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-asset-management-solutions",
                    active: true,
                    meta: {
                      tags: "Healthcare Asset Management Solutions",
                      keywords: "Healthcare Asset Management Solutions",
                      description: "Healthcare Asset Management Solutions",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3ae",
                    },
                    slug: "healthcare-workforce-management",
                    title: "Healthcare Workforce Management",
                    id: 1000191,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-workforce-management",
                    active: true,
                    meta: {
                      tags: "Healthcare Workforce Management",
                      keywords: "Healthcare Workforce Management",
                      description: "Healthcare Workforce Management",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3af",
                    },
                    slug: "revenue-cycle-management",
                    title: "Revenue Cycle Management",
                    id: 1000192,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/revenue-cycle-management",
                    active: true,
                    meta: {
                      tags: "Revenue Cycle Management",
                      keywords: "Revenue Cycle Management",
                      description: "Revenue Cycle Management",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3b0",
                        },
                        slug: "front-end-rcm",
                        title: "Front End RCM",
                        id: 1000193,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/revenue-cycle-management",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/revenue-cycle-management/front-end-rcm",
                        active: true,
                        meta: {
                          tags: "Front End RCM",
                          keywords: "Front End RCM",
                          description: "Front End RCM",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3b1",
                        },
                        slug: "mid-rcm",
                        title: "Mid RCM",
                        id: 1000194,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/revenue-cycle-management",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/revenue-cycle-management/mid-rcm",
                        active: true,
                        meta: {
                          tags: "Mid RCM",
                          keywords: "Mid RCM",
                          description: "Mid RCM",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3b2",
                        },
                        slug: "back-end-rcm",
                        title: "Back End RCM",
                        id: 1000195,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/revenue-cycle-management",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/revenue-cycle-management/back-end-rcm",
                        active: true,
                        meta: {
                          tags: "Back End RCM",
                          keywords: "Back End RCM",
                          description: "Back End RCM",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3b3",
                    },
                    slug: "medical-document-management",
                    title: "Medical Document Management",
                    id: 1000196,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/medical-document-management",
                    active: true,
                    meta: {
                      tags: "Medical Document Management",
                      keywords: "Medical Document Management",
                      description: "Medical Document Management",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3b4",
                    },
                    slug: "medical-coding-solutions",
                    title: "Medical Coding Solutions",
                    id: 1000197,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/medical-coding-solutions",
                    active: true,
                    meta: {
                      tags: "Medical Coding Solutions",
                      keywords: "Medical Coding Solutions",
                      description: "Medical Coding Solutions",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3b5",
                    },
                    slug: "healthcare-information-exchanges",
                    title: "Healthcare Information Exchanges",
                    id: 1000198,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-information-exchanges",
                    active: true,
                    meta: {
                      tags: "Healthcare Information Exchanges",
                      keywords: "Healthcare Information Exchanges",
                      description: "Healthcare Information Exchanges",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3b6",
                    },
                    slug: "medical-supply-chain-management-solutions",
                    title: "Medical Supply Chain Management Solutions",
                    id: 1000199,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/medical-supply-chain-management-solutions",
                    active: true,
                    meta: {
                      tags: "Medical Supply Chain Management Solutions",
                      keywords: "Medical Supply Chain Management Solutions",
                      description: "Medical Supply Chain Management Solutions",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3b7",
                    },
                    slug: "healthcare-analytics",
                    title: "Healthcare Analytics",
                    id: 1000200,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-analytics",
                    active: true,
                    meta: {
                      tags: "Healthcare Analytics",
                      keywords: "Healthcare Analytics",
                      description: "Healthcare Analytics",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3b8",
                        },
                        slug: "clinical-analytics",
                        title: "Clinical Analytics",
                        id: 1000201,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-analytics",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-analytics/clinical-analytics",
                        active: true,
                        meta: {
                          tags: "Clinical Analytics",
                          keywords: "Clinical Analytics",
                          description: "Clinical Analytics",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3b9",
                        },
                        slug: "healthcare-financial-analytics",
                        title: "Healthcare Financial Analytics",
                        id: 1000202,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-analytics",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-analytics/healthcare-financial-analytics",
                        active: true,
                        meta: {
                          tags: "Healthcare Financial Analytics",
                          keywords: "Healthcare Financial Analytics",
                          description: "Healthcare Financial Analytics",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3ba",
                        },
                        slug: "healthcare-operational-analytics",
                        title: "Healthcare Operational Analytics",
                        id: 1000203,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-analytics",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-analytics/healthcare-operational-analytics",
                        active: true,
                        meta: {
                          tags: "Healthcare Operational Analytics",
                          keywords: "Healthcare Operational Analytics",
                          description: "Healthcare Operational Analytics",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3bb",
                    },
                    slug: "healthcare-crm",
                    title: "Healthcare CRM",
                    id: 1000204,
                    parent:
                      "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it",
                    path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm",
                    active: true,
                    meta: {
                      tags: "Healthcare CRM",
                      keywords: "Healthcare CRM",
                      description: "Healthcare CRM",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3bc",
                        },
                        slug: "medication-management-solutions",
                        title: "Medication Management Solutions",
                        id: 1000205,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/medication-management-solutions",
                        active: true,
                        meta: {
                          tags: "Medication Management Solutions",
                          keywords: "Medication Management Solutions",
                          description: "Medication Management Solutions",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b3bd",
                            },
                            slug: "electronic-medication-administration",
                            title: "Electronic Medication Administration",
                            id: 1000206,
                            parent:
                              "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/medication-management-solutions",
                            path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/medication-management-solutions/electronic-medication-administration",
                            active: true,
                            meta: {
                              tags: "Electronic Medication Administration",
                              keywords: "Electronic Medication Administration",
                              description:
                                "Electronic Medication Administration",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b3be",
                            },
                            slug: "barcode-medication-administration",
                            title: "Barcode Medication Administration",
                            id: 1000207,
                            parent:
                              "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/medication-management-solutions",
                            path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/medication-management-solutions/barcode-medication-administration",
                            active: true,
                            meta: {
                              tags: "Barcode Medication Administration",
                              keywords: "Barcode Medication Administration",
                              description: "Barcode Medication Administration",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b3bf",
                            },
                            slug: "medication-inventory-management",
                            title: "Medication Inventory Management",
                            id: 1000208,
                            parent:
                              "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/medication-management-solutions",
                            path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/medication-management-solutions/medication-inventory-management",
                            active: true,
                            meta: {
                              tags: "Medication Inventory Management",
                              keywords: "Medication Inventory Management",
                              description: "Medication Inventory Management",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b3c0",
                            },
                            slug: "medication-adherence-systems",
                            title: "Medication Adherence Systems",
                            id: 1000209,
                            parent:
                              "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/medication-management-solutions",
                            path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/medication-management-solutions/medication-adherence-systems",
                            active: true,
                            meta: {
                              tags: "Medication Adherence Systems",
                              keywords: "Medication Adherence Systems",
                              description: "Medication Adherence Systems",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3c1",
                        },
                        slug: "healthcare-quality-management-solutions",
                        title: "Healthcare Quality Management Solutions",
                        id: 1000210,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/healthcare-quality-management-solutions",
                        active: true,
                        meta: {
                          tags: "Healthcare Quality Management Solutions",
                          keywords: "Healthcare Quality Management Solutions",
                          description:
                            "Healthcare Quality Management Solutions",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3c2",
                        },
                        slug: "healthcare-interoperability-solutions",
                        title: "Healthcare Interoperability Solutions",
                        id: 1000211,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/healthcare-interoperability-solutions",
                        active: true,
                        meta: {
                          tags: "Healthcare Interoperability Solutions",
                          keywords: "Healthcare Interoperability Solutions",
                          description: "Healthcare Interoperability Solutions",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3c3",
                        },
                        slug: "healthcare-financial-management-systems",
                        title: "Healthcare Financial Management Systems",
                        id: 1000212,
                        parent:
                          "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm",
                        path: "/healthcare/healthcare-it/healthcare-provider-it/non-clinical-it/healthcare-crm/healthcare-financial-management-systems",
                        active: true,
                        meta: {
                          tags: "Healthcare Financial Management Systems",
                          keywords: "Healthcare Financial Management Systems",
                          description:
                            "Healthcare Financial Management Systems",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b3c4",
            },
            slug: "healthcare-payer-solutions",
            title: "Healthcare Payer Solutions",
            id: 1000213,
            parent: "/healthcare/healthcare-it",
            path: "/healthcare/healthcare-it/healthcare-payer-solutions",
            active: true,
            meta: {
              tags: "Healthcare Payer Solutions",
              keywords: "Healthcare Payer Solutions",
              description: "Healthcare Payer Solutions",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3c5",
                },
                slug: "healthcare-claims-management-solutions",
                title: "Healthcare Claims Management Solutions",
                id: 1000214,
                parent: "/healthcare/healthcare-it/healthcare-payer-solutions",
                path: "/healthcare/healthcare-it/healthcare-payer-solutions/healthcare-claims-management-solutions",
                active: true,
                meta: {
                  tags: "Healthcare Claims Management Solutions",
                  keywords: "Healthcare Claims Management Solutions",
                  description: "Healthcare Claims Management Solutions",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3c6",
                },
                slug: "payer-crm",
                title: "Payer CRM",
                id: 1000215,
                parent: "/healthcare/healthcare-it/healthcare-payer-solutions",
                path: "/healthcare/healthcare-it/healthcare-payer-solutions/payer-crm",
                active: true,
                meta: {
                  tags: "Payer CRM",
                  keywords: "Payer CRM",
                  description: "Payer CRM",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3c7",
                },
                slug: "healthcare-fraud-analytics",
                title: "Healthcare Fraud Analytics",
                id: 1000216,
                parent: "/healthcare/healthcare-it/healthcare-payer-solutions",
                path: "/healthcare/healthcare-it/healthcare-payer-solutions/healthcare-fraud-analytics",
                active: true,
                meta: {
                  tags: "Healthcare Fraud Analytics",
                  keywords: "Healthcare Fraud Analytics",
                  description: "Healthcare Fraud Analytics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3c8",
                },
                slug: "healthcare-provider-network-management",
                title: "Healthcare Provider Network Management",
                id: 1000217,
                parent: "/healthcare/healthcare-it/healthcare-payer-solutions",
                path: "/healthcare/healthcare-it/healthcare-payer-solutions/healthcare-provider-network-management",
                active: true,
                meta: {
                  tags: "Healthcare Provider Network Management",
                  keywords: "Healthcare Provider Network Management",
                  description: "Healthcare Provider Network Management",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3c9",
                },
                slug: "pharmacy-analysis",
                title: "Pharmacy Analysis",
                id: 1000218,
                parent: "/healthcare/healthcare-it/healthcare-payer-solutions",
                path: "/healthcare/healthcare-it/healthcare-payer-solutions/pharmacy-analysis",
                active: true,
                meta: {
                  tags: "Pharmacy Analysis",
                  keywords: "Pharmacy Analysis",
                  description: "Pharmacy Analysis",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3ca",
                },
                slug: "healthcare-member-eligibility-management",
                title: "Healthcare Member Eligibility Management",
                id: 1000219,
                parent: "/healthcare/healthcare-it/healthcare-payer-solutions",
                path: "/healthcare/healthcare-it/healthcare-payer-solutions/healthcare-member-eligibility-management",
                active: true,
                meta: {
                  tags: "Healthcare Member Eligibility Management",
                  keywords: "Healthcare Member Eligibility Management",
                  description: "Healthcare Member Eligibility Management",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3cb",
                },
                slug: "healthcare-payment-management",
                title: "Healthcare Payment Management",
                id: 1000220,
                parent: "/healthcare/healthcare-it/healthcare-payer-solutions",
                path: "/healthcare/healthcare-it/healthcare-payer-solutions/healthcare-payment-management",
                active: true,
                meta: {
                  tags: "Healthcare Payment Management",
                  keywords: "Healthcare Payment Management",
                  description: "Healthcare Payment Management",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b3cc",
            },
            slug: "healthcare-outsourcing",
            title: "Healthcare Outsourcing",
            id: 1000221,
            parent: "/healthcare/healthcare-it",
            path: "/healthcare/healthcare-it/healthcare-outsourcing",
            active: true,
            meta: {
              tags: "Healthcare Outsourcing",
              keywords: "Healthcare Outsourcing",
              description: "Healthcare Outsourcing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3cd",
                },
                slug: "provider-outsourcing-services",
                title: "Provider Outsourcing services",
                id: 1000222,
                parent: "/healthcare/healthcare-it/healthcare-outsourcing",
                path: "/healthcare/healthcare-it/healthcare-outsourcing/provider-outsourcing-services",
                active: true,
                meta: {
                  tags: "Provider Outsourcing services",
                  keywords: "Provider Outsourcing services",
                  description: "Provider Outsourcing services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3ce",
                    },
                    slug: "revenue-cycle-management-outsourcing",
                    title: "Revenue Cycle Management Outsourcing",
                    id: 1000223,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/provider-outsourcing-services",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/provider-outsourcing-services/revenue-cycle-management-outsourcing",
                    active: true,
                    meta: {
                      tags: "Revenue Cycle Management Outsourcing",
                      keywords: "Revenue Cycle Management Outsourcing",
                      description: "Revenue Cycle Management Outsourcing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3cf",
                    },
                    slug: "medical-document-management-industry",
                    title: "Medical Document Management Industry",
                    id: 1000224,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/provider-outsourcing-services",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/provider-outsourcing-services/medical-document-management-industry",
                    active: true,
                    meta: {
                      tags: "Medical Document Management",
                      keywords: "Medical Document Management",
                      description: "Medical Document Management",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3d0",
                    },
                    slug: "laboratory-information-management-services",
                    title: "Laboratory Information Management Services",
                    id: 1000225,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/provider-outsourcing-services",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/provider-outsourcing-services/laboratory-information-management-services",
                    active: true,
                    meta: {
                      tags: "Laboratory Information Management Services",
                      keywords: "Laboratory Information Management Services",
                      description: "Laboratory Information Management Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3d1",
                },
                slug: "payer-outsourcing-services",
                title: "Payer Outsourcing services",
                id: 1000226,
                parent: "/healthcare/healthcare-it/healthcare-outsourcing",
                path: "/healthcare/healthcare-it/healthcare-outsourcing/payer-outsourcing-services",
                active: true,
                meta: {
                  tags: "Payer Outsourcing services",
                  keywords: "Payer Outsourcing services",
                  description: "Payer Outsourcing services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3d2",
                    },
                    slug: "healthcare-claims-management-outsourcing",
                    title: "Healthcare Claims Management Outsourcing",
                    id: 1000227,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/payer-outsourcing-services",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/payer-outsourcing-services/healthcare-claims-management-outsourcing",
                    active: true,
                    meta: {
                      tags: "Healthcare Claims Management Outsourcing",
                      keywords: "Healthcare Claims Management Outsourcing",
                      description: "Healthcare Claims Management Outsourcing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3d3",
                    },
                    slug: "provider-network-management",
                    title: "Provider Network Management",
                    id: 1000228,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/payer-outsourcing-services",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/payer-outsourcing-services/provider-network-management",
                    active: true,
                    meta: {
                      tags: "Provider Network Management",
                      keywords: "Provider Network Management",
                      description: "Provider Network Management",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3d4",
                    },
                    slug: "healthcare-financial-management",
                    title: "Healthcare Financial Management",
                    id: 1000229,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/payer-outsourcing-services",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/payer-outsourcing-services/healthcare-financial-management",
                    active: true,
                    meta: {
                      tags: "Healthcare Financial Management",
                      keywords: "Healthcare Financial Management",
                      description: "Healthcare Financial Management",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3d5",
                    },
                    slug: "healthcare-fraud-analytics-outsourcing",
                    title: "Healthcare Fraud Analytics Outsourcing",
                    id: 1000230,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/payer-outsourcing-services",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/payer-outsourcing-services/healthcare-fraud-analytics-outsourcing",
                    active: true,
                    meta: {
                      tags: "Healthcare Fraud Analytics Outsourcing",
                      keywords: "Healthcare Fraud Analytics Outsourcing",
                      description: "Healthcare Fraud Analytics Outsourcing",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3d6",
                },
                slug: "healthcare-operational-it-outsourcing",
                title: "Healthcare Operational IT Outsourcing",
                id: 1000231,
                parent: "/healthcare/healthcare-it/healthcare-outsourcing",
                path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-operational-it-outsourcing",
                active: true,
                meta: {
                  tags: "Healthcare Operational IT Outsourcing",
                  keywords: "Healthcare Operational IT Outsourcing",
                  description: "Healthcare Operational IT Outsourcing",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3d7",
                    },
                    slug: "healthcare-business-process-management",
                    title: "Healthcare Business Process Management",
                    id: 1000232,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-operational-it-outsourcing",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-operational-it-outsourcing/healthcare-business-process-management",
                    active: true,
                    meta: {
                      tags: "Healthcare Business Process Management",
                      keywords: "Healthcare Business Process Management",
                      description: "Healthcare Business Process Management",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3d8",
                    },
                    slug: "medical-supply-chain-management",
                    title: "Medical Supply Chain Management",
                    id: 1000233,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-operational-it-outsourcing",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-operational-it-outsourcing/medical-supply-chain-management",
                    active: true,
                    meta: {
                      tags: "Medical Supply Chain Management",
                      keywords: "Medical Supply Chain Management",
                      description: "Medical Supply Chain Management",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3d9",
                },
                slug: "healthcare-it-management-solutions",
                title: "Healthcare IT Management Solutions",
                id: 1000234,
                parent: "/healthcare/healthcare-it/healthcare-outsourcing",
                path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions",
                active: true,
                meta: {
                  tags: "Healthcare IT Management Solutions",
                  keywords: "Healthcare IT Management Solutions",
                  description: "Healthcare IT Management Solutions",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3da",
                    },
                    slug: "healthcare-software",
                    title: "Healthcare Software",
                    id: 1000235,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-software",
                    active: true,
                    meta: {
                      tags: "Healthcare Software",
                      keywords: "Healthcare Software",
                      description: "Healthcare Software",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3db",
                    },
                    slug: "healthcare-hardware",
                    title: "Healthcare Hardware",
                    id: 1000236,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-hardware",
                    active: true,
                    meta: {
                      tags: "Healthcare Hardware",
                      keywords: "Healthcare Hardware",
                      description: "Healthcare Hardware",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3dc",
                    },
                    slug: "healthcare-it-services",
                    title: "Healthcare IT Services",
                    id: 1000237,
                    parent:
                      "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions",
                    path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-it-services",
                    active: true,
                    meta: {
                      tags: "Healthcare IT Services",
                      keywords: "Healthcare IT Services",
                      description: "Healthcare IT Services",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3dd",
                        },
                        slug: "healthcare-support-maintenance",
                        title: "Healthcare Support Maintenance",
                        id: 1000238,
                        parent:
                          "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-it-services",
                        path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-it-services/healthcare-support-maintenance",
                        active: true,
                        meta: {
                          tags: "Healthcare Support Maintenance",
                          keywords: "Healthcare Support Maintenance",
                          description: "Healthcare Support Maintenance",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3de",
                        },
                        slug: "healthcare-integration",
                        title: "Healthcare Integration",
                        id: 1000239,
                        parent:
                          "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-it-services",
                        path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-it-services/healthcare-integration",
                        active: true,
                        meta: {
                          tags: "Healthcare Integration",
                          keywords: "Healthcare Integration",
                          description: "Healthcare Integration",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b3df",
                            },
                            slug: "healthcare-training-education",
                            title: "Healthcare Training, Education",
                            id: 1000240,
                            parent:
                              "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-it-services/healthcare-integration",
                            path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-it-services/healthcare-integration/healthcare-training-education",
                            active: true,
                            meta: {
                              tags: "Healthcare Training, Education",
                              keywords: "Healthcare Training, Education",
                              description: "Healthcare Training, Education",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b3e0",
                            },
                            slug: "healthcare-consulting",
                            title: "Healthcare Consulting",
                            id: 1000241,
                            parent:
                              "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-it-services/healthcare-integration",
                            path: "/healthcare/healthcare-it/healthcare-outsourcing/healthcare-it-management-solutions/healthcare-it-services/healthcare-integration/healthcare-consulting",
                            active: true,
                            meta: {
                              tags: "Healthcare Consulting",
                              keywords: "Healthcare Consulting",
                              description: "Healthcare Consulting",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b154",
        },
        id: 56100,
        title: "Medical Devices",
        slug: "medical-devices",
        active: true,
        meta: {
          description:
            "A medical device is an instrument, apparatus, implant, in vitro reagent, or similar or related article that is used to diagnose, prevent, or treat disease or other conditions, and does not achieve its purposes through chemical action within or on the body (which would make it a drug).",
          keywords:
            "Medical Devices Market Research Reports, medical devices industry, medical devices market, medical devices market report, medical devices market reports, medical devices industry report, medical devices industry reports, medical devices industry analysis, medical devices industry growth, medical devices market intelligence report",
          tags: "Medical Devices Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "A medical device is an instrument, apparatus, implant, in vitro reagent, or similar or related article that is used to diagnose, prevent, or treat disease or other conditions, and does not achieve its purposes through chemical action within or on the body (which would make it a drug).",
          short_description:
            "This section studies the growth, trends & analysis of all available medical devices and the dynamics of the medical devices market. Diagnostics, imaging, wearable devices, remote patient monitoring devices, and point-of-care testing devices include a few core application areas for medical devices.",
        },
        picture: null,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/medical-devices",
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b2f2",
            },
            slug: "medical-imaging",
            title: "Medical Imaging",
            id: 1000002,
            parent: "/healthcare/medical-devices",
            path: "/healthcare/medical-devices/medical-imaging",
            active: true,
            meta: {
              tags: "Medical Imaging",
              keywords: "Medical Imaging",
              description: "Medical Imaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b2f3",
                },
                slug: "diagnostic-imaging",
                title: "Diagnostic Imaging",
                id: 1000003,
                parent: "/healthcare/medical-devices/medical-imaging",
                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging",
                active: true,
                meta: {
                  tags: "Diagnostic Imaging",
                  keywords: "Diagnostic Imaging",
                  description: "Diagnostic Imaging",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b2f4",
                    },
                    slug: "ultrasound",
                    title: "Ultrasound",
                    id: 1000004,
                    parent:
                      "/healthcare/medical-devices/medical-imaging/diagnostic-imaging",
                    path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/ultrasound",
                    active: true,
                    meta: {
                      tags: "Ultrasound",
                      keywords: "Ultrasound",
                      description: "Ultrasound",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b2f5",
                    },
                    slug: "mri",
                    title: "MRI",
                    id: 1000005,
                    parent:
                      "/healthcare/medical-devices/medical-imaging/diagnostic-imaging",
                    path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/mri",
                    active: true,
                    meta: {
                      tags: "MRI",
                      keywords: "MRI",
                      description: "MRI",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b2f6",
                    },
                    slug: "x-ray",
                    title: "X-ray",
                    id: 1000006,
                    parent:
                      "/healthcare/medical-devices/medical-imaging/diagnostic-imaging",
                    path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/x-ray",
                    active: true,
                    meta: {
                      tags: "X-ray",
                      keywords: "X-ray",
                      description: "X-ray",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b2f7",
                        },
                        slug: "radiography",
                        title: "Radiography",
                        id: 1000007,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/x-ray",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/x-ray/radiography",
                        active: true,
                        meta: {
                          tags: "Radiography",
                          keywords: "Radiography",
                          description: "Radiography",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b2f8",
                        },
                        slug: "ct-scan",
                        title: "CT Scan",
                        id: 1000008,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/x-ray",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/x-ray/ct-scan",
                        active: true,
                        meta: {
                          tags: "CT Scan",
                          keywords: "CT Scan",
                          description: "CT Scan",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b2f9",
                        },
                        slug: "fluoroscopy",
                        title: "Fluoroscopy",
                        id: 1000009,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/x-ray",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/x-ray/fluoroscopy",
                        active: true,
                        meta: {
                          tags: "Fluoroscopy",
                          keywords: "Fluoroscopy",
                          description: "Fluoroscopy",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b2fa",
                        },
                        slug: "mammography",
                        title: "Mammography",
                        id: 1000010,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/x-ray",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/x-ray/mammography",
                        active: true,
                        meta: {
                          tags: "Mammography",
                          keywords: "Mammography",
                          description: "Mammography",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b2fb",
                    },
                    slug: "nuclear-medicine-imaging",
                    title: "Nuclear Medicine Imaging",
                    id: 1000011,
                    parent:
                      "/healthcare/medical-devices/medical-imaging/diagnostic-imaging",
                    path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/nuclear-medicine-imaging",
                    active: true,
                    meta: {
                      tags: "Nuclear Medicine Imaging",
                      keywords: "Nuclear Medicine Imaging",
                      description: "Nuclear Medicine Imaging",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b2fc",
                        },
                        slug: "pet-scan",
                        title: "PET Scan",
                        id: 1000012,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/nuclear-medicine-imaging",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/nuclear-medicine-imaging/pet-scan",
                        active: true,
                        meta: {
                          tags: "PET Scan",
                          keywords: "PET Scan",
                          description: "PET Scan",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b2fd",
                        },
                        slug: "spect",
                        title: "SPECT",
                        id: 1000013,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/nuclear-medicine-imaging",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/nuclear-medicine-imaging/spect",
                        active: true,
                        meta: {
                          tags: "SPECT",
                          keywords: "SPECT",
                          description: "SPECT",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b2fe",
                    },
                    slug: "medical-optical-imaging",
                    title: "Medical Optical Imaging",
                    id: 1000014,
                    parent:
                      "/healthcare/medical-devices/medical-imaging/diagnostic-imaging",
                    path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging",
                    active: true,
                    meta: {
                      tags: "Medical Optical Imaging",
                      keywords: "Medical Optical Imaging",
                      description: "Medical Optical Imaging",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b2ff",
                        },
                        slug: "microscopy",
                        title: "Microscopy",
                        id: 1000015,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy",
                        active: true,
                        meta: {
                          tags: "Microscopy",
                          keywords: "Microscopy",
                          description: "Microscopy",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b300",
                            },
                            slug: "electron-microscopy",
                            title: "Electron Microscopy",
                            id: 1000016,
                            parent:
                              "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy",
                            path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/electron-microscopy",
                            active: true,
                            meta: {
                              tags: "Electron Microscopy",
                              keywords: "Electron Microscopy",
                              description: "Electron Microscopy",
                            },
                            sub_categories: [
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b301",
                                },
                                slug: "scanning-electron-microscopy",
                                title: "Scanning Electron Microscopy",
                                id: 1000017,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/electron-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/electron-microscopy/scanning-electron-microscopy",
                                active: true,
                                meta: {
                                  tags: "Scanning Electron Microscopy",
                                  keywords: "Scanning Electron Microscopy",
                                  description: "Scanning Electron Microscopy",
                                },
                                sub_categories: [],
                              },
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b302",
                                },
                                slug: "transmission-electron-microscopy",
                                title: "Transmission Electron Microscopy",
                                id: 1000018,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/electron-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/electron-microscopy/transmission-electron-microscopy",
                                active: true,
                                meta: {
                                  tags: "Transmission Electron Microscopy",
                                  keywords: "Transmission Electron Microscopy",
                                  description:
                                    "Transmission Electron Microscopy",
                                },
                                sub_categories: [],
                              },
                            ],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b303",
                            },
                            slug: "fluorescence-microscopy",
                            title: "Fluorescence Microscopy",
                            id: 1000019,
                            parent:
                              "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy",
                            path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy",
                            active: true,
                            meta: {
                              tags: "Fluorescence Microscopy",
                              keywords: "Fluorescence Microscopy",
                              description: "Fluorescence Microscopy",
                            },
                            sub_categories: [
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b304",
                                },
                                slug: "epifluorescence-microscopy",
                                title: "Epifluorescence Microscopy",
                                id: 1000020,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy/epifluorescence-microscopy",
                                active: true,
                                meta: {
                                  tags: "Epifluorescence Microscopy",
                                  keywords: "Epifluorescence Microscopy",
                                  description: "Epifluorescence Microscopy",
                                },
                                sub_categories: [],
                              },
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b305",
                                },
                                slug: "multiphoton-microscopy",
                                title: "Multiphoton Microscopy",
                                id: 1000021,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy/multiphoton-microscopy",
                                active: true,
                                meta: {
                                  tags: "Multiphoton Microscopy",
                                  keywords: "Multiphoton Microscopy",
                                  description: "Multiphoton Microscopy",
                                },
                                sub_categories: [],
                              },
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b306",
                                },
                                slug: "tirf-microscopy",
                                title: "TIRF Microscopy",
                                id: 1000022,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy/tirf-microscopy",
                                active: true,
                                meta: {
                                  tags: "TIRF Microscopy",
                                  keywords: "TIRF Microscopy",
                                  description: "TIRF Microscopy",
                                },
                                sub_categories: [],
                              },
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b307",
                                },
                                slug: "confocal-microscopy",
                                title: "Confocal Microscopy",
                                id: 1000023,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy/confocal-microscopy",
                                active: true,
                                meta: {
                                  tags: "Confocal Microscopy",
                                  keywords: "Confocal Microscopy",
                                  description: "Confocal Microscopy",
                                },
                                sub_categories: [],
                              },
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b308",
                                },
                                slug: "super-resolution-fluorescence-microscopy",
                                title:
                                  "Super Resolution Fluorescence Microscopy",
                                id: 1000024,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/fluorescence-microscopy/super-resolution-fluorescence-microscopy",
                                active: true,
                                meta: {
                                  tags: "Super Resolution Fluorescence Microscopy",
                                  keywords:
                                    "Super Resolution Fluorescence Microscopy",
                                  description:
                                    "Super Resolution Fluorescence Microscopy",
                                },
                                sub_categories: [],
                              },
                            ],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b309",
                            },
                            slug: "scanning-probe-microscopy",
                            title: "Scanning Probe Microscopy",
                            id: 1000025,
                            parent:
                              "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy",
                            path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/scanning-probe-microscopy",
                            active: true,
                            meta: {
                              tags: "Scanning Probe Microscopy",
                              keywords: "Scanning Probe Microscopy",
                              description: "Scanning Probe Microscopy",
                            },
                            sub_categories: [
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b30a",
                                },
                                slug: "atomic-force-microscopy",
                                title: "Atomic Force Microscopy",
                                id: 1000026,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/scanning-probe-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/scanning-probe-microscopy/atomic-force-microscopy",
                                active: true,
                                meta: {
                                  tags: "Atomic Force Microscopy",
                                  keywords: "Atomic Force Microscopy",
                                  description: "Atomic Force Microscopy",
                                },
                                sub_categories: [],
                              },
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b30b",
                                },
                                slug: "near-field-scanning-optical-microscopy",
                                title: "Near-Field Scanning Optical Microscopy",
                                id: 1000027,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/scanning-probe-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/scanning-probe-microscopy/near-field-scanning-optical-microscopy",
                                active: true,
                                meta: {
                                  tags: "Near-Field Scanning Optical Microscopy",
                                  keywords:
                                    "Near-Field Scanning Optical Microscopy",
                                  description:
                                    "Near-Field Scanning Optical Microscopy",
                                },
                                sub_categories: [],
                              },
                              {
                                _id: {
                                  $oid: "6480c9e11b708a832f67b30c",
                                },
                                slug: "scanning-tunneling-microscopy",
                                title: "Scanning Tunneling Microscopy",
                                id: 1000028,
                                parent:
                                  "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/scanning-probe-microscopy",
                                path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/microscopy/scanning-probe-microscopy/scanning-tunneling-microscopy",
                                active: true,
                                meta: {
                                  tags: "Scanning Tunneling Microscopy",
                                  keywords: "Scanning Tunneling Microscopy",
                                  description: "Scanning Tunneling Microscopy",
                                },
                                sub_categories: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b30d",
                        },
                        slug: "spectroscopy",
                        title: "Spectroscopy",
                        id: 1000029,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/spectroscopy",
                        active: true,
                        meta: {
                          tags: "Spectroscopy",
                          keywords: "Spectroscopy",
                          description: "Spectroscopy",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b30e",
                            },
                            slug: "atomic-absorption-spectroscopy",
                            title: "Atomic Absorption Spectroscopy",
                            id: 1000030,
                            parent:
                              "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/spectroscopy",
                            path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/spectroscopy/atomic-absorption-spectroscopy",
                            active: true,
                            meta: {
                              tags: "Atomic Absorption Spectroscopy",
                              keywords: "Atomic Absorption Spectroscopy",
                              description: "Atomic Absorption Spectroscopy",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b30f",
                            },
                            slug: "atomic-emission-spectroscopy",
                            title: "Atomic Emission Spectroscopy",
                            id: 1000031,
                            parent:
                              "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/spectroscopy",
                            path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/spectroscopy/atomic-emission-spectroscopy",
                            active: true,
                            meta: {
                              tags: "Atomic Emission Spectroscopy",
                              keywords: "Atomic Emission Spectroscopy",
                              description: "Atomic Emission Spectroscopy",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b310",
                            },
                            slug: "atomic-fluorescence-spectroscopy",
                            title: "Atomic Fluorescence Spectroscopy",
                            id: 1000032,
                            parent:
                              "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/spectroscopy",
                            path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/spectroscopy/atomic-fluorescence-spectroscopy",
                            active: true,
                            meta: {
                              tags: "Atomic Fluorescence Spectroscopy",
                              keywords: "Atomic Fluorescence Spectroscopy",
                              description: "Atomic Fluorescence Spectroscopy",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b311",
                        },
                        slug: "laser-imaging",
                        title: "Laser Imaging",
                        id: 1000033,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/laser-imaging",
                        active: true,
                        meta: {
                          tags: "Laser Imaging",
                          keywords: "Laser Imaging",
                          description: "Laser Imaging",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b312",
                            },
                            slug: "laser-doppler",
                            title: "Laser Doppler",
                            id: 1000034,
                            parent:
                              "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/laser-imaging",
                            path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/laser-imaging/laser-doppler",
                            active: true,
                            meta: {
                              tags: "Laser Doppler",
                              keywords: "Laser Doppler",
                              description: "Laser Doppler",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b313",
                        },
                        slug: "coherence-tomography",
                        title: "Coherence Tomography",
                        id: 1000035,
                        parent:
                          "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging",
                        path: "/healthcare/medical-devices/medical-imaging/diagnostic-imaging/medical-optical-imaging/coherence-tomography",
                        active: true,
                        meta: {
                          tags: "Coherence Tomography",
                          keywords: "Coherence Tomography",
                          description: "Coherence Tomography",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b314",
                },
                slug: "non-diagnostic-imaging",
                title: "Non Diagnostic Imaging",
                id: 1000036,
                parent: "/healthcare/medical-devices/medical-imaging",
                path: "/healthcare/medical-devices/medical-imaging/non-diagnostic-imaging",
                active: true,
                meta: {
                  tags: "Non Diagnostic Imaging",
                  keywords: "Non Diagnostic Imaging",
                  description: "Non Diagnostic Imaging",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b315",
                    },
                    slug: "neuroimaging",
                    title: "Neuroimaging",
                    id: 1000037,
                    parent:
                      "/healthcare/medical-devices/medical-imaging/non-diagnostic-imaging",
                    path: "/healthcare/medical-devices/medical-imaging/non-diagnostic-imaging/neuroimaging",
                    active: true,
                    meta: {
                      tags: "Neuroimaging",
                      keywords: "Neuroimaging",
                      description: "Neuroimaging",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b316",
                    },
                    slug: "surgical-imaging",
                    title: "Surgical Imaging",
                    id: 1000038,
                    parent:
                      "/healthcare/medical-devices/medical-imaging/non-diagnostic-imaging",
                    path: "/healthcare/medical-devices/medical-imaging/non-diagnostic-imaging/surgical-imaging",
                    active: true,
                    meta: {
                      tags: "Surgical Imaging",
                      keywords: "Surgical Imaging",
                      description: "Surgical Imaging",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b317",
            },
            slug: "diagnostic-devices",
            title: "Diagnostic Devices",
            id: 1000039,
            parent: "/healthcare/medical-devices",
            path: "/healthcare/medical-devices/diagnostic-devices",
            active: true,
            meta: {
              tags: "Diagnostic Devices",
              keywords: "Diagnostic Devices",
              description: "Diagnostic Devices",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b318",
                },
                slug: "clinical-diagnostic-devices",
                title: "Clinical Diagnostic Devices",
                id: 1000040,
                parent: "/healthcare/medical-devices/diagnostic-devices",
                path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices",
                active: true,
                meta: {
                  tags: "Clinical Diagnostic Devices",
                  keywords: "Clinical Diagnostic Devices",
                  description: "Clinical Diagnostic Devices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b319",
                    },
                    slug: "monitoring-devices",
                    title: "Monitoring Devices",
                    id: 1000041,
                    parent:
                      "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices",
                    path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices",
                    active: true,
                    meta: {
                      tags: "Monitoring Devices",
                      keywords: "Monitoring Devices",
                      description: "Monitoring Devices",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b31a",
                        },
                        slug: "handheld-monitoring-devices",
                        title: "Handheld Monitoring Devices",
                        id: 1000042,
                        parent:
                          "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices",
                        path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices/handheld-monitoring-devices",
                        active: true,
                        meta: {
                          tags: "Handheld Monitoring Devices",
                          keywords: "Handheld Monitoring Devices",
                          description: "Handheld Monitoring Devices",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b31b",
                        },
                        slug: "tabletop-monitoring-devices",
                        title: "Tabletop Monitoring Devices",
                        id: 1000043,
                        parent:
                          "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices",
                        path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices/tabletop-monitoring-devices",
                        active: true,
                        meta: {
                          tags: "Tabletop Monitoring Devices",
                          keywords: "Tabletop Monitoring Devices",
                          description: "Tabletop Monitoring Devices",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b31c",
                        },
                        slug: "wearable-monitoring-devices",
                        title: "Wearable Monitoring Devices",
                        id: 1000044,
                        parent:
                          "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices",
                        path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices/wearable-monitoring-devices",
                        active: true,
                        meta: {
                          tags: "Wearable Monitoring Devices",
                          keywords: "Wearable Monitoring Devices",
                          description: "Wearable Monitoring Devices",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b31d",
                        },
                        slug: "invasive-monitoring-devices",
                        title: "Invasive Monitoring Devices",
                        id: 1000045,
                        parent:
                          "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices",
                        path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices/invasive-monitoring-devices",
                        active: true,
                        meta: {
                          tags: "Invasive Monitoring Devices",
                          keywords: "Invasive Monitoring Devices",
                          description: "Invasive Monitoring Devices",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b31e",
                        },
                        slug: "remote-monitoring-devices",
                        title: "Remote Monitoring Devices",
                        id: 1000046,
                        parent:
                          "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices",
                        path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices/remote-monitoring-devices",
                        active: true,
                        meta: {
                          tags: "Remote Monitoring Devices",
                          keywords: "Remote Monitoring Devices",
                          description: "Remote Monitoring Devices",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b31f",
                        },
                        slug: "single-time-monitoring-devices",
                        title: "Single time Monitoring Devices",
                        id: 1000047,
                        parent:
                          "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices",
                        path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/monitoring-devices/single-time-monitoring-devices",
                        active: true,
                        meta: {
                          tags: "Single time Monitoring Devices",
                          keywords: "Single time Monitoring Devices",
                          description: "Single time Monitoring Devices",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b320",
                    },
                    slug: "clinical-diagnostic-laboratory-equipment",
                    title: "Clinical Diagnostic Laboratory Equipment",
                    id: 1000048,
                    parent:
                      "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices",
                    path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment",
                    active: true,
                    meta: {
                      tags: "Clinical Diagnostic Laboratory Equipment",
                      keywords: "Clinical Diagnostic Laboratory Equipment",
                      description: "Clinical Diagnostic Laboratory Equipment",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b321",
                        },
                        slug: "clinical-diagnostic-instrument",
                        title: "Clinical Diagnostic Instrument",
                        id: 1000049,
                        parent:
                          "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment",
                        path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/clinical-diagnostic-instrument",
                        active: true,
                        meta: {
                          tags: "Clinical Diagnostic Instrument",
                          keywords: "Clinical Diagnostic Instrument",
                          description: "Clinical Diagnostic Instrument",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b322",
                        },
                        slug: "disease-specific-diagnostic-devices",
                        title: "Disease Specific Diagnostic Devices",
                        id: 1000050,
                        parent:
                          "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment",
                        path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                        active: true,
                        meta: {
                          tags: "Disease Specific Diagnostic Devices",
                          keywords: "Disease Specific Diagnostic Devices",
                          description: "Disease Specific Diagnostic Devices",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b323",
                            },
                            slug: "oncology-diagnostics",
                            title: "Oncology Diagnostics",
                            id: 1000051,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/oncology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Oncology Diagnostics",
                              keywords: "Oncology Diagnostics",
                              description: "Oncology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b324",
                            },
                            slug: "cardiovascular-diagnostics",
                            title: "Cardiovascular Diagnostics",
                            id: 1000052,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/cardiovascular-diagnostics",
                            active: true,
                            meta: {
                              tags: "Cardiovascular Diagnostics",
                              keywords: "Cardiovascular Diagnostics",
                              description: "Cardiovascular Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b325",
                            },
                            slug: "orthopedic-diagnostics",
                            title: "Orthopedic Diagnostics",
                            id: 1000053,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/orthopedic-diagnostics",
                            active: true,
                            meta: {
                              tags: "Orthopedic Diagnostics",
                              keywords: "Orthopedic Diagnostics",
                              description: "Orthopedic Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b326",
                            },
                            slug: "ophthalmic-diagnostics",
                            title: "Ophthalmic Diagnostics",
                            id: 1000054,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/ophthalmic-diagnostics",
                            active: true,
                            meta: {
                              tags: "Ophthalmic Diagnostics",
                              keywords: "Ophthalmic Diagnostics",
                              description: "Ophthalmic Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b327",
                            },
                            slug: "dental-care-diagnostics",
                            title: "Dental Care Diagnostics",
                            id: 1000055,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/dental-care-diagnostics",
                            active: true,
                            meta: {
                              tags: "Dental Care Diagnostics",
                              keywords: "Dental Care Diagnostics",
                              description: "Dental Care Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b328",
                            },
                            slug: "nephrology-diagnostics",
                            title: "Nephrology Diagnostics",
                            id: 1000056,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/nephrology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Nephrology Diagnostics",
                              keywords: "Nephrology Diagnostics",
                              description: "Nephrology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b329",
                            },
                            slug: "diabetes-care-diagnostics",
                            title: "Diabetes Care Diagnostics",
                            id: 1000057,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/diabetes-care-diagnostics",
                            active: true,
                            meta: {
                              tags: "Diabetes Care Diagnostics",
                              keywords: "Diabetes Care Diagnostics",
                              description: "Diabetes Care Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b32a",
                            },
                            slug: "pulmonology-diagnostics",
                            title: "Pulmonology Diagnostics",
                            id: 1000058,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/pulmonology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Pulmonology Diagnostics",
                              keywords: "Pulmonology Diagnostics",
                              description: "Pulmonology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b32b",
                            },
                            slug: "neurology-diagnostics",
                            title: "Neurology Diagnostics",
                            id: 1000059,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/neurology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Neurology Diagnostics",
                              keywords: "Neurology Diagnostics",
                              description: "Neurology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b32c",
                            },
                            slug: "gastroenterology-diagnostics",
                            title: "Gastroenterology Diagnostics",
                            id: 1000060,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/gastroenterology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Gastroenterology Diagnostics",
                              keywords: "Gastroenterology Diagnostics",
                              description: "Gastroenterology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b32d",
                            },
                            slug: "endocrinology-diagnostics",
                            title: "Endocrinology Diagnostics",
                            id: 1000061,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/endocrinology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Endocrinology Diagnostics",
                              keywords: "Endocrinology Diagnostics",
                              description: "Endocrinology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b32e",
                            },
                            slug: "hepatology-diagnostics",
                            title: "Hepatology Diagnostics",
                            id: 1000062,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/hepatology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Hepatology Diagnostics",
                              keywords: "Hepatology Diagnostics",
                              description: "Hepatology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b32f",
                            },
                            slug: "hematology-diagnostics",
                            title: "Hematology Diagnostics",
                            id: 1000063,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/hematology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Hematology Diagnostics",
                              keywords: "Hematology Diagnostics",
                              description: "Hematology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b330",
                            },
                            slug: "obstetrics-gynecology-diagnostics",
                            title: "Obstetrics Gynecology Diagnostics",
                            id: 1000064,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/obstetrics-gynecology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Obstetrics Gynecology Diagnostics",
                              keywords: "Obstetrics Gynecology Diagnostics",
                              description: "Obstetrics Gynecology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b331",
                            },
                            slug: "genetic-testing",
                            title: "Genetic Testing",
                            id: 1000065,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/genetic-testing",
                            active: true,
                            meta: {
                              tags: "Genetic Testing",
                              keywords: "Genetic Testing",
                              description: "Genetic Testing",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b332",
                            },
                            slug: "urology-diagnostics",
                            title: "Urology Diagnostics",
                            id: 1000066,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/urology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Urology Diagnostics",
                              keywords: "Urology Diagnostics",
                              description: "Urology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b333",
                            },
                            slug: "dermatology-diagnostics",
                            title: "Dermatology Diagnostics",
                            id: 1000067,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/dermatology-diagnostics",
                            active: true,
                            meta: {
                              tags: "Dermatology Diagnostics",
                              keywords: "Dermatology Diagnostics",
                              description: "Dermatology Diagnostics",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b334",
                            },
                            slug: "infectious-disease-testing",
                            title: "Infectious Disease Testing",
                            id: 1000068,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/infectious-disease-testing",
                            active: true,
                            meta: {
                              tags: "Infectious Disease Testing",
                              keywords: "Infectious Disease Testing",
                              description: "Infectious Disease Testing",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b335",
                            },
                            slug: "drug-screening",
                            title: "Drug Screening",
                            id: 1000069,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/drug-screening",
                            active: true,
                            meta: {
                              tags: "Drug Screening",
                              keywords: "Drug Screening",
                              description: "Drug Screening",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "6480c9e11b708a832f67b336",
                            },
                            slug: "immune-system-diagnostics",
                            title: "Immune System Diagnostics",
                            id: 1000070,
                            parent:
                              "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices",
                            path: "/healthcare/medical-devices/diagnostic-devices/clinical-diagnostic-devices/clinical-diagnostic-laboratory-equipment/disease-specific-diagnostic-devices/immune-system-diagnostics",
                            active: true,
                            meta: {
                              tags: "Immune System Diagnostics",
                              keywords: "Immune System Diagnostics",
                              description: "Immune System Diagnostics",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b337",
                },
                slug: "in-vitro-diagnostic-devices",
                title: "In Vitro Diagnostic Devices",
                id: 1000071,
                parent: "/healthcare/medical-devices/diagnostic-devices",
                path: "/healthcare/medical-devices/diagnostic-devices/in-vitro-diagnostic-devices",
                active: true,
                meta: {
                  tags: "In Vitro Diagnostic Devices",
                  keywords: "In Vitro Diagnostic Devices",
                  description: "In Vitro Diagnostic Devices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b338",
                    },
                    slug: "molecular-diagnostic-devices",
                    title: "Molecular Diagnostic Devices",
                    id: 1000072,
                    parent:
                      "/healthcare/medical-devices/diagnostic-devices/in-vitro-diagnostic-devices",
                    path: "/healthcare/medical-devices/diagnostic-devices/in-vitro-diagnostic-devices/molecular-diagnostic-devices",
                    active: true,
                    meta: {
                      tags: "Molecular Diagnostic Devices",
                      keywords: "Molecular Diagnostic Devices",
                      description: "Molecular Diagnostic Devices",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b339",
            },
            slug: "surgical-devices",
            title: "Surgical Devices",
            id: 1000073,
            parent: "/healthcare/medical-devices",
            path: "/healthcare/medical-devices/surgical-devices",
            active: true,
            meta: {
              tags: "Surgical Devices",
              keywords: "Surgical Devices",
              description: "Surgical Devices",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b33a",
                },
                slug: "electrosurgical-devices",
                title: "ElectroSurgical Devices",
                id: 1000074,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/electrosurgical-devices",
                active: true,
                meta: {
                  tags: "ElectroSurgical Devices",
                  keywords: "ElectroSurgical Devices",
                  description: "ElectroSurgical Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b33b",
                },
                slug: "laparoscopic-devices",
                title: "Laparoscopic Devices",
                id: 1000075,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/laparoscopic-devices",
                active: true,
                meta: {
                  tags: "Laparoscopic Devices",
                  keywords: "Laparoscopic Devices",
                  description: "Laparoscopic Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b33c",
                },
                slug: "wound-care-devices",
                title: "Wound Care Devices",
                id: 1000076,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/wound-care-devices",
                active: true,
                meta: {
                  tags: "Wound Care Devices",
                  keywords: "Wound Care Devices",
                  description: "Wound Care Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b33d",
                },
                slug: "suction-irrigation-devices",
                title: "Suction Irrigation Devices",
                id: 1000077,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/suction-irrigation-devices",
                active: true,
                meta: {
                  tags: "Suction Irrigation Devices",
                  keywords: "Suction Irrigation Devices",
                  description: "Suction Irrigation Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b33e",
                },
                slug: "surgical-access-devices",
                title: "Surgical Access Devices",
                id: 1000078,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/surgical-access-devices",
                active: true,
                meta: {
                  tags: "Surgical Access Devices",
                  keywords: "Surgical Access Devices",
                  description: "Surgical Access Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b33f",
                },
                slug: "ablation-devices",
                title: "Ablation Devices",
                id: 1000079,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/ablation-devices",
                active: true,
                meta: {
                  tags: "Ablation Devices",
                  keywords: "Ablation Devices",
                  description: "Ablation Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b340",
                },
                slug: "general-surgical-devices",
                title: "General Surgical Devices",
                id: 1000080,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/general-surgical-devices",
                active: true,
                meta: {
                  tags: "General Surgical Devices",
                  keywords: "General Surgical Devices",
                  description: "General Surgical Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b341",
                },
                slug: "minimally-invasive-surgery-devices",
                title: "Minimally Invasive Surgery Devices",
                id: 1000081,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/minimally-invasive-surgery-devices",
                active: true,
                meta: {
                  tags: "Minimally Invasive Surgery Devices",
                  keywords: "Minimally Invasive Surgery Devices",
                  description: "Minimally Invasive Surgery Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b342",
                },
                slug: "specialty-surgical-devices",
                title: "Specialty Surgical Devices",
                id: 1000082,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                active: true,
                meta: {
                  tags: "Specialty Surgical Devices",
                  keywords: "Specialty Surgical Devices",
                  description: "Specialty Surgical Devices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b343",
                    },
                    slug: "bariatric-surgery-devices",
                    title: "Bariatric Surgery Devices",
                    id: 1000083,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/bariatric-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Bariatric Surgery Devices",
                      keywords: "Bariatric Surgery Devices",
                      description: "Bariatric Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b344",
                    },
                    slug: "neurourgery-devices",
                    title: "Neurourgery Devices",
                    id: 1000084,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/neurourgery-devices",
                    active: true,
                    meta: {
                      tags: "Neurourgery Devices",
                      keywords: "Neurourgery Devices",
                      description: "Neurourgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b345",
                    },
                    slug: "dental-surgery-devices",
                    title: "Dental Surgery Devices",
                    id: 1000085,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/dental-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Dental Surgery Devices",
                      keywords: "Dental Surgery Devices",
                      description: "Dental Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b346",
                    },
                    slug: "colon-rectal-surgery-devices",
                    title: "Colon Rectal Surgery Devices",
                    id: 1000086,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/colon-rectal-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Colon Rectal Surgery Devices",
                      keywords: "Colon Rectal Surgery Devices",
                      description: "Colon Rectal Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b347",
                    },
                    slug: "ophthalmological-surgery-devices",
                    title: "Ophthalmological Surgery Devices",
                    id: 1000087,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/ophthalmological-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Ophthalmological Surgery Devices",
                      keywords: "Ophthalmological Surgery Devices",
                      description: "Ophthalmological Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b348",
                    },
                    slug: "cardiothoracic-surgery-devices",
                    title: "Cardiothoracic Surgery Devices",
                    id: 1000088,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/cardiothoracic-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Cardiothoracic Surgery Devices",
                      keywords: "Cardiothoracic Surgery Devices",
                      description: "Cardiothoracic Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b349",
                    },
                    slug: "breast-surgery-devices",
                    title: "Breast Surgery Devices",
                    id: 1000089,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/breast-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Breast Surgery Devices",
                      keywords: "Breast Surgery Devices",
                      description: "Breast Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b34a",
                    },
                    slug: "endocrine-surgery-devices",
                    title: "Endocrine Surgery Devices",
                    id: 1000090,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/endocrine-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Endocrine Surgery Devices",
                      keywords: "Endocrine Surgery Devices",
                      description: "Endocrine Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b34b",
                    },
                    slug: "gynecological-surgery-devices",
                    title: "Gynecological Surgery Devices",
                    id: 1000091,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/gynecological-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Gynecological Surgery Devices",
                      keywords: "Gynecological Surgery Devices",
                      description: "Gynecological Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b34c",
                    },
                    slug: "oral-maxillofacial-surgery-devices",
                    title: "Oral Maxillofacial Surgery Devices",
                    id: 1000092,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/oral-maxillofacial-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Oral Maxillofacial Surgery Devices",
                      keywords: "Oral Maxillofacial Surgery Devices",
                      description: "Oral Maxillofacial Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b34d",
                    },
                    slug: "orthopedic-surgery-devices",
                    title: "Orthopedic Surgery Devices",
                    id: 1000093,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/orthopedic-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Orthopedic Surgery Devices",
                      keywords: "Orthopedic Surgery Devices",
                      description: "Orthopedic Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b34e",
                    },
                    slug: "gynecological-surgery-devices",
                    title: "Gynecological Surgery Devices",
                    id: 1000094,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/gynecological-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Gynecological Surgery Devices",
                      keywords: "Gynecological Surgery Devices",
                      description: "Gynecological Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b34f",
                    },
                    slug: "trauma-surgery-devices",
                    title: "Trauma Surgery Devices",
                    id: 1000095,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/trauma-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Trauma Surgery Devices",
                      keywords: "Trauma Surgery Devices",
                      description: "Trauma Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b350",
                    },
                    slug: "otorhinolaryngological-surgery-devices",
                    title: "Otorhinolaryngological Surgery Devices",
                    id: 1000096,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/otorhinolaryngological-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Otorhinolaryngological Surgery Devices",
                      keywords: "Otorhinolaryngological Surgery Devices",
                      description: "Otorhinolaryngological Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b351",
                    },
                    slug: "pediatric-surgery-devices",
                    title: "Pediatric Surgery Devices",
                    id: 1000097,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/pediatric-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Pediatric Surgery Devices",
                      keywords: "Pediatric Surgery Devices",
                      description: "Pediatric Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b352",
                    },
                    slug: "urologic-surgery-devices",
                    title: "Urologic Surgery Devices",
                    id: 1000098,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/urologic-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Urologic Surgery Devices",
                      keywords: "Urologic Surgery Devices",
                      description: "Urologic Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b353",
                    },
                    slug: "vascular-surgery-devices",
                    title: "Vascular Surgery Devices",
                    id: 1000099,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/vascular-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Vascular Surgery Devices",
                      keywords: "Vascular Surgery Devices",
                      description: "Vascular Surgery Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b354",
                    },
                    slug: "hernia-surgery-devices",
                    title: "Hernia Surgery Devices",
                    id: 1000100,
                    parent:
                      "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices",
                    path: "/healthcare/medical-devices/surgical-devices/specialty-surgical-devices/hernia-surgery-devices",
                    active: true,
                    meta: {
                      tags: "Hernia Surgery Devices",
                      keywords: "Hernia Surgery Devices",
                      description: "Hernia Surgery Devices",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b355",
                },
                slug: "robotic-surgical-devices",
                title: "Robotic Surgical Devices",
                id: 1000101,
                parent: "/healthcare/medical-devices/surgical-devices",
                path: "/healthcare/medical-devices/surgical-devices/robotic-surgical-devices",
                active: true,
                meta: {
                  tags: "Robotic Surgical Devices",
                  keywords: "Robotic Surgical Devices",
                  description: "Robotic Surgical Devices",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b356",
            },
            slug: "treatment-equipment",
            title: "Treatment Equipment",
            id: 1000102,
            parent: "/healthcare/medical-devices",
            path: "/healthcare/medical-devices/treatment-equipment",
            active: true,
            meta: {
              tags: "Treatment Equipment",
              keywords: "Treatment Equipment",
              description: "Treatment Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b357",
                },
                slug: "life-support-devices",
                title: "Life Support Devices",
                id: 1000103,
                parent: "/healthcare/medical-devices/treatment-equipment",
                path: "/healthcare/medical-devices/treatment-equipment/life-support-devices",
                active: true,
                meta: {
                  tags: "Life Support Devices",
                  keywords: "Life Support Devices",
                  description: "Life Support Devices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b358",
                    },
                    slug: "defibrillators",
                    title: "Defibrillators",
                    id: 1000104,
                    parent:
                      "/healthcare/medical-devices/treatment-equipment/life-support-devices",
                    path: "/healthcare/medical-devices/treatment-equipment/life-support-devices/defibrillators",
                    active: true,
                    meta: {
                      tags: "Defibrillators",
                      keywords: "Defibrillators",
                      description: "Defibrillators",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b359",
                    },
                    slug: "ventilators",
                    title: "Ventilators",
                    id: 1000105,
                    parent:
                      "/healthcare/medical-devices/treatment-equipment/life-support-devices",
                    path: "/healthcare/medical-devices/treatment-equipment/life-support-devices/ventilators",
                    active: true,
                    meta: {
                      tags: "Ventilators",
                      keywords: "Ventilators",
                      description: "Ventilators",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b35a",
                    },
                    slug: "anesthesia-equipment",
                    title: "Anesthesia Equipment",
                    id: 1000106,
                    parent:
                      "/healthcare/medical-devices/treatment-equipment/life-support-devices",
                    path: "/healthcare/medical-devices/treatment-equipment/life-support-devices/anesthesia-equipment",
                    active: true,
                    meta: {
                      tags: "Anesthesia Equipment",
                      keywords: "Anesthesia Equipment",
                      description: "Anesthesia Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b35b",
                    },
                    slug: "insulin-pumps",
                    title: "Insulin Pumps",
                    id: 1000107,
                    parent:
                      "/healthcare/medical-devices/treatment-equipment/life-support-devices",
                    path: "/healthcare/medical-devices/treatment-equipment/life-support-devices/insulin-pumps",
                    active: true,
                    meta: {
                      tags: "Insulin Pumps",
                      keywords: "Insulin Pumps",
                      description: "Insulin Pumps",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b35c",
                    },
                    slug: "dialysis",
                    title: "Dialysis",
                    id: 1000108,
                    parent:
                      "/healthcare/medical-devices/treatment-equipment/life-support-devices",
                    path: "/healthcare/medical-devices/treatment-equipment/life-support-devices/dialysis",
                    active: true,
                    meta: {
                      tags: "Dialysis",
                      keywords: "Dialysis",
                      description: "Dialysis",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b35d",
                },
                slug: "personal-mobility-devices",
                title: "Personal Mobility Devices",
                id: 1000109,
                parent: "/healthcare/medical-devices/treatment-equipment",
                path: "/healthcare/medical-devices/treatment-equipment/personal-mobility-devices",
                active: true,
                meta: {
                  tags: "Personal Mobility Devices",
                  keywords: "Personal Mobility Devices",
                  description: "Personal Mobility Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b35e",
                },
                slug: "apheresis",
                title: "Apheresis",
                id: 1000110,
                parent: "/healthcare/medical-devices/treatment-equipment",
                path: "/healthcare/medical-devices/treatment-equipment/apheresis",
                active: true,
                meta: {
                  tags: "Apheresis",
                  keywords: "Apheresis",
                  description: "Apheresis",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b35f",
                },
                slug: "contraceptive-devices",
                title: "Contraceptive Devices",
                id: 1000111,
                parent: "/healthcare/medical-devices/treatment-equipment",
                path: "/healthcare/medical-devices/treatment-equipment/contraceptive-devices",
                active: true,
                meta: {
                  tags: "Contraceptive Devices",
                  keywords: "Contraceptive Devices",
                  description: "Contraceptive Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b360",
                },
                slug: "fertility-devices",
                title: "Fertility Devices",
                id: 1000112,
                parent: "/healthcare/medical-devices/treatment-equipment",
                path: "/healthcare/medical-devices/treatment-equipment/fertility-devices",
                active: true,
                meta: {
                  tags: "Fertility Devices",
                  keywords: "Fertility Devices",
                  description: "Fertility Devices",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b361",
            },
            slug: "medical-supplies",
            title: "Medical Supplies",
            id: 1000113,
            parent: "/healthcare/medical-devices",
            path: "/healthcare/medical-devices/medical-supplies",
            active: true,
            meta: {
              tags: "Medical Supplies",
              keywords: "Medical Supplies",
              description: "Medical Supplies",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b362",
                },
                slug: "medical-consumables",
                title: "Medical Consumables",
                id: 1000114,
                parent: "/healthcare/medical-devices/medical-supplies",
                path: "/healthcare/medical-devices/medical-supplies/medical-consumables",
                active: true,
                meta: {
                  tags: "Medical Consumables",
                  keywords: "Medical Consumables",
                  description: "Medical Consumables",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b363",
                    },
                    slug: "wound-care-management",
                    title: "Wound Care Management",
                    id: 1000115,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/wound-care-management",
                    active: true,
                    meta: {
                      tags: "Wound Care Management",
                      keywords: "Wound Care Management",
                      description: "Wound Care Management",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b364",
                    },
                    slug: "laboratory-supplies",
                    title: "Laboratory Supplies",
                    id: 1000116,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/laboratory-supplies",
                    active: true,
                    meta: {
                      tags: "Laboratory Supplies",
                      keywords: "Laboratory Supplies",
                      description: "Laboratory Supplies",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b365",
                        },
                        slug: "testing-kits",
                        title: "Testing Kits",
                        id: 1000117,
                        parent:
                          "/healthcare/medical-devices/medical-supplies/medical-consumables/laboratory-supplies",
                        path: "/healthcare/medical-devices/medical-supplies/medical-consumables/laboratory-supplies/testing-kits",
                        active: true,
                        meta: {
                          tags: "Testing Kits",
                          keywords: "Testing Kits",
                          description: "Testing Kits",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b366",
                        },
                        slug: "reagents-and-solutions",
                        title: "Reagents and Solutions",
                        id: 1000118,
                        parent:
                          "/healthcare/medical-devices/medical-supplies/medical-consumables/laboratory-supplies",
                        path: "/healthcare/medical-devices/medical-supplies/medical-consumables/laboratory-supplies/reagents-and-solutions",
                        active: true,
                        meta: {
                          tags: "Reagents and Solutions",
                          keywords: "Reagents and Solutions",
                          description: "Reagents and Solutions",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b367",
                        },
                        slug: "assays",
                        title: "Assays",
                        id: 1000119,
                        parent:
                          "/healthcare/medical-devices/medical-supplies/medical-consumables/laboratory-supplies",
                        path: "/healthcare/medical-devices/medical-supplies/medical-consumables/laboratory-supplies/assays",
                        active: true,
                        meta: {
                          tags: "Assays",
                          keywords: "Assays",
                          description: "Assays",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b368",
                    },
                    slug: "respiratory-supplies",
                    title: "Respiratory Supplies",
                    id: 1000120,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/respiratory-supplies",
                    active: true,
                    meta: {
                      tags: "Respiratory Supplies",
                      keywords: "Respiratory Supplies",
                      description: "Respiratory Supplies",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b369",
                    },
                    slug: "drug-delivery-products",
                    title: "Drug Delivery Products",
                    id: 1000121,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/drug-delivery-products",
                    active: true,
                    meta: {
                      tags: "Drug Delivery Products",
                      keywords: "Drug Delivery Products",
                      description: "Drug Delivery Products",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b36a",
                    },
                    slug: "dialysis-consumables",
                    title: "Dialysis Consumables",
                    id: 1000122,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/dialysis-consumables",
                    active: true,
                    meta: {
                      tags: "Dialysis Consumables",
                      keywords: "Dialysis Consumables",
                      description: "Dialysis Consumables",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b36b",
                    },
                    slug: "medical-sterilization",
                    title: "Medical Sterilization",
                    id: 1000123,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/medical-sterilization",
                    active: true,
                    meta: {
                      tags: "Medical Sterilization",
                      keywords: "Medical Sterilization",
                      description: "Medical Sterilization",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b36c",
                    },
                    slug: "incontinence-products",
                    title: "Incontinence Products",
                    id: 1000124,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/incontinence-products",
                    active: true,
                    meta: {
                      tags: "Incontinence Products",
                      keywords: "Incontinence Products",
                      description: "Incontinence Products",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b36d",
                    },
                    slug: "surgical-supplies",
                    title: "Surgical Supplies",
                    id: 1000125,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/surgical-supplies",
                    active: true,
                    meta: {
                      tags: "Surgical Supplies",
                      keywords: "Surgical Supplies",
                      description: "Surgical Supplies",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b36e",
                    },
                    slug: "dental-supplies",
                    title: "Dental Supplies",
                    id: 1000126,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/dental-supplies",
                    active: true,
                    meta: {
                      tags: "Dental Supplies",
                      keywords: "Dental Supplies",
                      description: "Dental Supplies",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b36f",
                    },
                    slug: "personal-protective-equipment",
                    title: "Personal Protective Equipment",
                    id: 1000127,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/personal-protective-equipment",
                    active: true,
                    meta: {
                      tags: "Personal Protective Equipment",
                      keywords: "Personal Protective Equipment",
                      description: "Personal Protective Equipment",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b370",
                        },
                        slug: "medical-eye-gear",
                        title: "Medical Eye Gear",
                        id: 1000128,
                        parent:
                          "/healthcare/medical-devices/medical-supplies/medical-consumables/personal-protective-equipment",
                        path: "/healthcare/medical-devices/medical-supplies/medical-consumables/personal-protective-equipment/medical-eye-gear",
                        active: true,
                        meta: {
                          tags: "Medical Eye Gear",
                          keywords: "Medical Eye Gear",
                          description: "Medical Eye Gear",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b371",
                        },
                        slug: "medical-masks",
                        title: "Medical Masks",
                        id: 1000129,
                        parent:
                          "/healthcare/medical-devices/medical-supplies/medical-consumables/personal-protective-equipment",
                        path: "/healthcare/medical-devices/medical-supplies/medical-consumables/personal-protective-equipment/medical-masks",
                        active: true,
                        meta: {
                          tags: "Medical Masks",
                          keywords: "Medical Masks",
                          description: "Medical Masks",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b372",
                        },
                        slug: "medical-gloves",
                        title: "Medical Gloves",
                        id: 1000130,
                        parent:
                          "/healthcare/medical-devices/medical-supplies/medical-consumables/personal-protective-equipment",
                        path: "/healthcare/medical-devices/medical-supplies/medical-consumables/personal-protective-equipment/medical-gloves",
                        active: true,
                        meta: {
                          tags: "Medical Gloves",
                          keywords: "Medical Gloves",
                          description: "Medical Gloves",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b373",
                        },
                        slug: "medical-gowns",
                        title: "Medical Gowns",
                        id: 1000131,
                        parent:
                          "/healthcare/medical-devices/medical-supplies/medical-consumables/personal-protective-equipment",
                        path: "/healthcare/medical-devices/medical-supplies/medical-consumables/personal-protective-equipment/medical-gowns",
                        active: true,
                        meta: {
                          tags: "Medical Gowns",
                          keywords: "Medical Gowns",
                          description: "Medical Gowns",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b374",
                    },
                    slug: "medical-sanitization",
                    title: "Medical Sanitization",
                    id: 1000132,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-consumables",
                    path: "/healthcare/medical-devices/medical-supplies/medical-consumables/medical-sanitization",
                    active: true,
                    meta: {
                      tags: "Medical Sanitization",
                      keywords: "Medical Sanitization",
                      description: "Medical Sanitization",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b375",
                },
                slug: "medical-instruments",
                title: "Medical Instruments",
                id: 1000133,
                parent: "/healthcare/medical-devices/medical-supplies",
                path: "/healthcare/medical-devices/medical-supplies/medical-instruments",
                active: true,
                meta: {
                  tags: "Medical Instruments",
                  keywords: "Medical Instruments",
                  description: "Medical Instruments",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b376",
                    },
                    slug: "ambulatory-medical-instruments",
                    title: "Ambulatory Medical Instruments",
                    id: 1000134,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-instruments",
                    path: "/healthcare/medical-devices/medical-supplies/medical-instruments/ambulatory-medical-instruments",
                    active: true,
                    meta: {
                      tags: "Ambulatory Medical Instruments",
                      keywords: "Ambulatory Medical Instruments",
                      description: "Ambulatory Medical Instruments",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b377",
                    },
                    slug: "hospital-and-clinical-use",
                    title: "Hospital and Clinical Use",
                    id: 1000135,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-instruments",
                    path: "/healthcare/medical-devices/medical-supplies/medical-instruments/hospital-and-clinical-use",
                    active: true,
                    meta: {
                      tags: "Hospital and Clinical Use",
                      keywords: "Hospital and Clinical Use",
                      description: "Hospital and Clinical Use",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b378",
                    },
                    slug: "dental-medical-instruments",
                    title: "Dental Medical Instruments",
                    id: 1000136,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-instruments",
                    path: "/healthcare/medical-devices/medical-supplies/medical-instruments/dental-medical-instruments",
                    active: true,
                    meta: {
                      tags: "Dental Medical Instruments",
                      keywords: "Dental Medical Instruments",
                      description: "Dental Medical Instruments",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b379",
                    },
                    slug: "laboratory-medical-instruments",
                    title: "Laboratory Medical Instruments",
                    id: 1000137,
                    parent:
                      "/healthcare/medical-devices/medical-supplies/medical-instruments",
                    path: "/healthcare/medical-devices/medical-supplies/medical-instruments/laboratory-medical-instruments",
                    active: true,
                    meta: {
                      tags: "Laboratory Medical Instruments",
                      keywords: "Laboratory Medical Instruments",
                      description: "Laboratory Medical Instruments",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b37a",
            },
            slug: "medical-implants",
            title: "Medical Implants",
            id: 1000138,
            parent: "/healthcare/medical-devices",
            path: "/healthcare/medical-devices/medical-implants",
            active: true,
            meta: {
              tags: "Medical Implants",
              keywords: "Medical Implants",
              description: "Medical Implants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b37b",
                },
                slug: "orthopedic-implants",
                title: "Orthopedic Implants",
                id: 1000139,
                parent: "/healthcare/medical-devices/medical-implants",
                path: "/healthcare/medical-devices/medical-implants/orthopedic-implants",
                active: true,
                meta: {
                  tags: "Orthopedic Implants",
                  keywords: "Orthopedic Implants",
                  description: "Orthopedic Implants",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b37c",
                    },
                    slug: "hip-replacement",
                    title: "Hip Replacement",
                    id: 1000140,
                    parent:
                      "/healthcare/medical-devices/medical-implants/orthopedic-implants",
                    path: "/healthcare/medical-devices/medical-implants/orthopedic-implants/hip-replacement",
                    active: true,
                    meta: {
                      tags: "Hip Replacement",
                      keywords: "Hip Replacement",
                      description: "Hip Replacement",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b37d",
                    },
                    slug: "joint-reconstruction",
                    title: "Joint Reconstruction",
                    id: 1000141,
                    parent:
                      "/healthcare/medical-devices/medical-implants/orthopedic-implants",
                    path: "/healthcare/medical-devices/medical-implants/orthopedic-implants/joint-reconstruction",
                    active: true,
                    meta: {
                      tags: "Joint Reconstruction",
                      keywords: "Joint Reconstruction",
                      description: "Joint Reconstruction",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b37e",
                    },
                    slug: "knee-replacement",
                    title: "Knee Replacement",
                    id: 1000142,
                    parent:
                      "/healthcare/medical-devices/medical-implants/orthopedic-implants",
                    path: "/healthcare/medical-devices/medical-implants/orthopedic-implants/knee-replacement",
                    active: true,
                    meta: {
                      tags: "Knee Replacement",
                      keywords: "Knee Replacement",
                      description: "Knee Replacement",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b37f",
                    },
                    slug: "spine-implants",
                    title: "Spine Implants",
                    id: 1000143,
                    parent:
                      "/healthcare/medical-devices/medical-implants/orthopedic-implants",
                    path: "/healthcare/medical-devices/medical-implants/orthopedic-implants/spine-implants",
                    active: true,
                    meta: {
                      tags: "Spine Implants",
                      keywords: "Spine Implants",
                      description: "Spine Implants",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b380",
                },
                slug: "cardiovascular-implants",
                title: "Cardiovascular Implants",
                id: 1000144,
                parent: "/healthcare/medical-devices/medical-implants",
                path: "/healthcare/medical-devices/medical-implants/cardiovascular-implants",
                active: true,
                meta: {
                  tags: "Cardiovascular Implants",
                  keywords: "Cardiovascular Implants",
                  description: "Cardiovascular Implants",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b381",
                    },
                    slug: "pacing-devices",
                    title: "Pacing Devices",
                    id: 1000145,
                    parent:
                      "/healthcare/medical-devices/medical-implants/cardiovascular-implants",
                    path: "/healthcare/medical-devices/medical-implants/cardiovascular-implants/pacing-devices",
                    active: true,
                    meta: {
                      tags: "Pacing Devices",
                      keywords: "Pacing Devices",
                      description: "Pacing Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b382",
                    },
                    slug: "structural-cardiac-implants",
                    title: "Structural Cardiac Implants",
                    id: 1000146,
                    parent:
                      "/healthcare/medical-devices/medical-implants/cardiovascular-implants",
                    path: "/healthcare/medical-devices/medical-implants/cardiovascular-implants/structural-cardiac-implants",
                    active: true,
                    meta: {
                      tags: "Structural Cardiac Implants",
                      keywords: "Structural Cardiac Implants",
                      description: "Structural Cardiac Implants",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b383",
                },
                slug: "ophthalmic-implants",
                title: "Ophthalmic Implants",
                id: 1000147,
                parent: "/healthcare/medical-devices/medical-implants",
                path: "/healthcare/medical-devices/medical-implants/ophthalmic-implants",
                active: true,
                meta: {
                  tags: "Ophthalmic Implants",
                  keywords: "Ophthalmic Implants",
                  description: "Ophthalmic Implants",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b384",
                    },
                    slug: "intraocular-lens",
                    title: "Intraocular lens",
                    id: 1000148,
                    parent:
                      "/healthcare/medical-devices/medical-implants/ophthalmic-implants",
                    path: "/healthcare/medical-devices/medical-implants/ophthalmic-implants/intraocular-lens",
                    active: true,
                    meta: {
                      tags: "Intraocular lens",
                      keywords: "Intraocular lens",
                      description: "Intraocular lens",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b385",
                    },
                    slug: "glaucoma-implants",
                    title: "Glaucoma Implants",
                    id: 1000149,
                    parent:
                      "/healthcare/medical-devices/medical-implants/ophthalmic-implants",
                    path: "/healthcare/medical-devices/medical-implants/ophthalmic-implants/glaucoma-implants",
                    active: true,
                    meta: {
                      tags: "Glaucoma Implants",
                      keywords: "Glaucoma Implants",
                      description: "Glaucoma Implants",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b386",
                },
                slug: "dental-implants",
                title: "Dental Implants",
                id: 1000150,
                parent: "/healthcare/medical-devices/medical-implants",
                path: "/healthcare/medical-devices/medical-implants/dental-implants",
                active: true,
                meta: {
                  tags: "Dental Implants",
                  keywords: "Dental Implants",
                  description: "Dental Implants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b387",
                },
                slug: "facial-implants",
                title: "Facial Implants",
                id: 1000151,
                parent: "/healthcare/medical-devices/medical-implants",
                path: "/healthcare/medical-devices/medical-implants/facial-implants",
                active: true,
                meta: {
                  tags: "Facial Implants",
                  keywords: "Facial Implants",
                  description: "Facial Implants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b388",
                },
                slug: "breast-implants",
                title: "Breast Implants",
                id: 1000152,
                parent: "/healthcare/medical-devices/medical-implants",
                path: "/healthcare/medical-devices/medical-implants/breast-implants",
                active: true,
                meta: {
                  tags: "Breast Implants",
                  keywords: "Breast Implants",
                  description: "Breast Implants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b389",
                },
                slug: "ent-implants",
                title: "ENT Implants",
                id: 1000153,
                parent: "/healthcare/medical-devices/medical-implants",
                path: "/healthcare/medical-devices/medical-implants/ent-implants",
                active: true,
                meta: {
                  tags: "ENT Implants",
                  keywords: "ENT Implants",
                  description: "ENT Implants",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b38a",
                    },
                    slug: "ear-implants",
                    title: "Ear Implants",
                    id: 1000154,
                    parent:
                      "/healthcare/medical-devices/medical-implants/ent-implants",
                    path: "/healthcare/medical-devices/medical-implants/ent-implants/ear-implants",
                    active: true,
                    meta: {
                      tags: "Ear Implants",
                      keywords: "Ear Implants",
                      description: "Ear Implants",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b38b",
                        },
                        slug: "hearing-aids",
                        title: "Hearing Aids",
                        id: 1000155,
                        parent:
                          "/healthcare/medical-devices/medical-implants/ent-implants/ear-implants",
                        path: "/healthcare/medical-devices/medical-implants/ent-implants/ear-implants/hearing-aids",
                        active: true,
                        meta: {
                          tags: "Hearing Aids",
                          keywords: "Hearing Aids",
                          description: "Hearing Aids",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b38c",
                        },
                        slug: "hearing-implants",
                        title: "Hearing Implants",
                        id: 1000156,
                        parent:
                          "/healthcare/medical-devices/medical-implants/ent-implants/ear-implants",
                        path: "/healthcare/medical-devices/medical-implants/ent-implants/ear-implants/hearing-implants",
                        active: true,
                        meta: {
                          tags: "Hearing Implants",
                          keywords: "Hearing Implants",
                          description: "Hearing Implants",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b38d",
                    },
                    slug: "nose-implants",
                    title: "Nose Implants",
                    id: 1000157,
                    parent:
                      "/healthcare/medical-devices/medical-implants/ent-implants",
                    path: "/healthcare/medical-devices/medical-implants/ent-implants/nose-implants",
                    active: true,
                    meta: {
                      tags: "Nose Implants",
                      keywords: "Nose Implants",
                      description: "Nose Implants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b38e",
                    },
                    slug: "throat-implants",
                    title: "Throat Implants",
                    id: 1000158,
                    parent:
                      "/healthcare/medical-devices/medical-implants/ent-implants",
                    path: "/healthcare/medical-devices/medical-implants/ent-implants/throat-implants",
                    active: true,
                    meta: {
                      tags: "Throat Implants",
                      keywords: "Throat Implants",
                      description: "Throat Implants",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b38f",
            },
            slug: "aesthetic-devices",
            title: "Aesthetic Devices",
            id: 1000159,
            parent: "/healthcare/medical-devices",
            path: "/healthcare/medical-devices/aesthetic-devices",
            active: true,
            meta: {
              tags: "Aesthetic Devices",
              keywords: "Aesthetic Devices",
              description: "Aesthetic Devices",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b390",
                },
                slug: "energy-based-aesthetic-devices",
                title: "Energy Based Aesthetic Devices",
                id: 1000160,
                parent: "/healthcare/medical-devices/aesthetic-devices",
                path: "/healthcare/medical-devices/aesthetic-devices/energy-based-aesthetic-devices",
                active: true,
                meta: {
                  tags: "Energy Based Aesthetic Devices",
                  keywords: "Energy Based Aesthetic Devices",
                  description: "Energy Based Aesthetic Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b391",
                },
                slug: "non-energy-based-aesthetic-devices",
                title: "Non Energy Based Aesthetic Devices",
                id: 1000161,
                parent: "/healthcare/medical-devices/aesthetic-devices",
                path: "/healthcare/medical-devices/aesthetic-devices/non-energy-based-aesthetic-devices",
                active: true,
                meta: {
                  tags: "Non Energy Based Aesthetic Devices",
                  keywords: "Non Energy Based Aesthetic Devices",
                  description: "Non Energy Based Aesthetic Devices",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b155",
        },
        id: 56101,
        title: "Diagnostics",
        slug: "diagnostics",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Diagnostics Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Diagnostics Market Research Reports, healthcare market research reports, healthcare market report, Diagnostics Market, Diagnostics industry, Diagnostics market report, Diagnostics industry report, Diagnostics market research report, Diagnostics industry research report",
          tags: "Diagnostics Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Statistics for the 2023 Diagnostics market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Diagnostics Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          short_description:
            "Statistics for the 2023 Diagnostics market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Diagnostics Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        picture: null,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/diagnostics",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b156",
        },
        id: 56102,
        title: "Therapeutics",
        slug: "therapeutics",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Therapeutics Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Therapeutics Market Research Reports, healthcare market research reports, healthcare market report, therapeutics market, therapeutics industry, therapeutics market report, therapeutics industry report, therapeutics market research, therapeutics industry research, therapeutics market overview, \n",
          tags: "Therapeutics Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Therapy (aceology or iamatology; often abbreviated tx or Tx) is the attempted remediation of a health problem, usually following a diagnosis.",
          short_description:
            "The global therapeutics market is driven by the demand for cancer therapies, chemotherapy, and radiotherapy. Mordor Intelligence™ translates market information on therapeutics (market dynamics, competition, varying consumer needs, and regulations) into actionable business insights.",
        },
        picture: null,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/therapeutics",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b157",
        },
        id: 56126,
        title: "Animal Health",
        slug: "animal-health",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Animal Health Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Animal Health Market Research Reports, Animal Health Market, Animal Health industry, Animal Health market report, Animal Health industry report, Animal Health market research report, Animal Health industry research report",
          tags: "Animal Health Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Animal therapeutic drugs, veterinary diagnostics and veterinary vaccines defines the complete concepts of animal healthcare. We offer comprehensive market research reports for topics like global veterinary healthcare, global veterinary vaccines etc. Animal healthcare happens to be our strong suit which enables us to provide customize reports to our clients.",
          short_description:
            "Our comprehensive set of periodically updated reports will serve all your market intelligence needs on health, food, and diseases for companions and commercially reared animals. From competitive intelligence to industry regulations, we have you covered below.",
        },
        picture: null,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/animal-health",
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b3e1",
            },
            slug: "animal-medicine",
            title: "Animal Medicine",
            id: 1000243,
            parent: "/healthcare/animal-health",
            path: "/healthcare/animal-health/animal-medicine",
            active: true,
            meta: {
              tags: "Animal Medicine",
              keywords: "Animal Medicine",
              description: "Animal Medicine",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3e2",
                },
                slug: "animal-drugs",
                title: "Animal Drugs",
                id: 1000244,
                parent: "/healthcare/animal-health/animal-medicine",
                path: "/healthcare/animal-health/animal-medicine/animal-drugs",
                active: true,
                meta: {
                  tags: "Animal Drugs",
                  keywords: "Animal Drugs",
                  description: "Animal Drugs",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3e3",
                    },
                    slug: "antiparasitic-animal-drugs",
                    title: "Antiparasitic Animal Drugs",
                    id: 1000245,
                    parent:
                      "/healthcare/animal-health/animal-medicine/animal-drugs",
                    path: "/healthcare/animal-health/animal-medicine/animal-drugs/antiparasitic-animal-drugs",
                    active: true,
                    meta: {
                      tags: "Antiparasitic Animal Drugs",
                      keywords: "Antiparasitic Animal Drugs",
                      description: "Antiparasitic Animal Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3e4",
                    },
                    slug: "anti-inflammatory-animal-drugs",
                    title: "Anti Inflammatory Animal Drugs",
                    id: 1000246,
                    parent:
                      "/healthcare/animal-health/animal-medicine/animal-drugs",
                    path: "/healthcare/animal-health/animal-medicine/animal-drugs/anti-inflammatory-animal-drugs",
                    active: true,
                    meta: {
                      tags: "Anti Inflammatory Animal Drugs",
                      keywords: "Anti Inflammatory Animal Drugs",
                      description: "Anti Inflammatory Animal Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3e5",
                    },
                    slug: "anti-infectives-animal-drugs",
                    title: "Anti Infectives Animal Drugs",
                    id: 1000247,
                    parent:
                      "/healthcare/animal-health/animal-medicine/animal-drugs",
                    path: "/healthcare/animal-health/animal-medicine/animal-drugs/anti-infectives-animal-drugs",
                    active: true,
                    meta: {
                      tags: "Anti Infectives Animal Drugs",
                      keywords: "Anti Infectives Animal Drugs",
                      description: "Anti Infectives Animal Drugs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3e6",
                    },
                    slug: "analgesic-animal-drugs",
                    title: "Analgesic Animal Drugs",
                    id: 1000248,
                    parent:
                      "/healthcare/animal-health/animal-medicine/animal-drugs",
                    path: "/healthcare/animal-health/animal-medicine/animal-drugs/analgesic-animal-drugs",
                    active: true,
                    meta: {
                      tags: "Analgesic Animal Drugs",
                      keywords: "Analgesic Animal Drugs",
                      description: "Analgesic Animal Drugs",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3e7",
                },
                slug: "animal-vaccines",
                title: "Animal Vaccines",
                id: 1000249,
                parent: "/healthcare/animal-health/animal-medicine",
                path: "/healthcare/animal-health/animal-medicine/animal-vaccines",
                active: true,
                meta: {
                  tags: "Animal Vaccines",
                  keywords: "Animal Vaccines",
                  description: "Animal Vaccines",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3e8",
                    },
                    slug: "modified-live-animal-vaccines",
                    title: "Modified Live Animal Vaccines",
                    id: 1000250,
                    parent:
                      "/healthcare/animal-health/animal-medicine/animal-vaccines",
                    path: "/healthcare/animal-health/animal-medicine/animal-vaccines/modified-live-animal-vaccines",
                    active: true,
                    meta: {
                      tags: "Modified Live Animal Vaccines",
                      keywords: "Modified Live Animal Vaccines",
                      description: "Modified Live Animal Vaccines",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3e9",
                    },
                    slug: "killed-inactivated-animal-vaccines",
                    title: "Killed Inactivated Animal Vaccines",
                    id: 1000251,
                    parent:
                      "/healthcare/animal-health/animal-medicine/animal-vaccines",
                    path: "/healthcare/animal-health/animal-medicine/animal-vaccines/killed-inactivated-animal-vaccines",
                    active: true,
                    meta: {
                      tags: "Killed Inactivated Animal Vaccines",
                      keywords: "Killed Inactivated Animal Vaccines",
                      description: "Killed Inactivated Animal Vaccines",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3ea",
                    },
                    slug: "dna-animal-vaccines",
                    title: "DNA Animal Vaccines",
                    id: 1000252,
                    parent:
                      "/healthcare/animal-health/animal-medicine/animal-vaccines",
                    path: "/healthcare/animal-health/animal-medicine/animal-vaccines/dna-animal-vaccines",
                    active: true,
                    meta: {
                      tags: "DNA Animal Vaccines",
                      keywords: "DNA Animal Vaccines",
                      description: "DNA Animal Vaccines",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3eb",
                    },
                    slug: "recombinant-animal-vaccines",
                    title: "Recombinant Animal Vaccines",
                    id: 1000253,
                    parent:
                      "/healthcare/animal-health/animal-medicine/animal-vaccines",
                    path: "/healthcare/animal-health/animal-medicine/animal-vaccines/recombinant-animal-vaccines",
                    active: true,
                    meta: {
                      tags: "Recombinant Animal Vaccines",
                      keywords: "Recombinant Animal Vaccines",
                      description: "Recombinant Animal Vaccines",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3ec",
                },
                slug: "medical-feed-additives",
                title: "Medical Feed Additives",
                id: 1000254,
                parent: "/healthcare/animal-health/animal-medicine",
                path: "/healthcare/animal-health/animal-medicine/medical-feed-additives",
                active: true,
                meta: {
                  tags: "Medical Feed Additives",
                  keywords: "Medical Feed Additives",
                  description: "Medical Feed Additives",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3ed",
                    },
                    slug: "animal-antibiotics",
                    title: "Animal Antibiotics",
                    id: 1000255,
                    parent:
                      "/healthcare/animal-health/animal-medicine/medical-feed-additives",
                    path: "/healthcare/animal-health/animal-medicine/medical-feed-additives/animal-antibiotics",
                    active: true,
                    meta: {
                      tags: "Animal Antibiotics",
                      keywords: "Animal Antibiotics",
                      description: "Animal Antibiotics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3ee",
                    },
                    slug: "animal-vitamins",
                    title: "Animal Vitamins",
                    id: 1000256,
                    parent:
                      "/healthcare/animal-health/animal-medicine/medical-feed-additives",
                    path: "/healthcare/animal-health/animal-medicine/medical-feed-additives/animal-vitamins",
                    active: true,
                    meta: {
                      tags: "Animal Vitamins",
                      keywords: "Animal Vitamins",
                      description: "Animal Vitamins",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3ef",
                    },
                    slug: "animal-amino-acids",
                    title: "Animal Amino Acids",
                    id: 1000257,
                    parent:
                      "/healthcare/animal-health/animal-medicine/medical-feed-additives",
                    path: "/healthcare/animal-health/animal-medicine/medical-feed-additives/animal-amino-acids",
                    active: true,
                    meta: {
                      tags: "Animal Amino Acids",
                      keywords: "Animal Amino Acids",
                      description: "Animal Amino Acids",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3f0",
                    },
                    slug: "animal-enzymes",
                    title: "Animal Enzymes",
                    id: 1000258,
                    parent:
                      "/healthcare/animal-health/animal-medicine/medical-feed-additives",
                    path: "/healthcare/animal-health/animal-medicine/medical-feed-additives/animal-enzymes",
                    active: true,
                    meta: {
                      tags: "Animal Enzymes",
                      keywords: "Animal Enzymes",
                      description: "Animal Enzymes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3f1",
                    },
                    slug: "animal-antioxidants",
                    title: "Animal Antioxidants",
                    id: 1000259,
                    parent:
                      "/healthcare/animal-health/animal-medicine/medical-feed-additives",
                    path: "/healthcare/animal-health/animal-medicine/medical-feed-additives/animal-antioxidants",
                    active: true,
                    meta: {
                      tags: "Animal Antioxidants",
                      keywords: "Animal Antioxidants",
                      description: "Animal Antioxidants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3f2",
                    },
                    slug: "animal-prebiotics-probiotics",
                    title: "Animal Prebiotics Probiotics",
                    id: 1000260,
                    parent:
                      "/healthcare/animal-health/animal-medicine/medical-feed-additives",
                    path: "/healthcare/animal-health/animal-medicine/medical-feed-additives/animal-prebiotics-probiotics",
                    active: true,
                    meta: {
                      tags: "Animal Prebiotics Probiotics",
                      keywords: "Animal Prebiotics Probiotics",
                      description: "Animal Prebiotics Probiotics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3f3",
                    },
                    slug: "animal-minerals",
                    title: "Animal Minerals",
                    id: 1000261,
                    parent:
                      "/healthcare/animal-health/animal-medicine/medical-feed-additives",
                    path: "/healthcare/animal-health/animal-medicine/medical-feed-additives/animal-minerals",
                    active: true,
                    meta: {
                      tags: "Animal Minerals",
                      keywords: "Animal Minerals",
                      description: "Animal Minerals",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b3f4",
            },
            slug: "animal-medical-devices",
            title: "Animal Medical Devices",
            id: 1000262,
            parent: "/healthcare/animal-health",
            path: "/healthcare/animal-health/animal-medical-devices",
            active: true,
            meta: {
              tags: "Animal Medical Devices",
              keywords: "Animal Medical Devices",
              description: "Animal Medical Devices",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3f5",
                },
                slug: "veterinary-equipment",
                title: "Veterinary Equipment",
                id: 1000263,
                parent: "/healthcare/animal-health/animal-medical-devices",
                path: "/healthcare/animal-health/animal-medical-devices/veterinary-equipment",
                active: true,
                meta: {
                  tags: "Veterinary Equipment",
                  keywords: "Veterinary Equipment",
                  description: "Veterinary Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3f6",
                    },
                    slug: "veterinary-diagnostic-equipment",
                    title: "Veterinary Diagnostic Equipment",
                    id: 1000264,
                    parent:
                      "/healthcare/animal-health/animal-medical-devices/veterinary-equipment",
                    path: "/healthcare/animal-health/animal-medical-devices/veterinary-equipment/veterinary-diagnostic-equipment",
                    active: true,
                    meta: {
                      tags: "Veterinary Diagnostic Equipment",
                      keywords: "Veterinary Diagnostic Equipment",
                      description: "Veterinary Diagnostic Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3f7",
                    },
                    slug: "veterinary-surgical-equipment",
                    title: "Veterinary Surgical Equipment",
                    id: 1000265,
                    parent:
                      "/healthcare/animal-health/animal-medical-devices/veterinary-equipment",
                    path: "/healthcare/animal-health/animal-medical-devices/veterinary-equipment/veterinary-surgical-equipment",
                    active: true,
                    meta: {
                      tags: "Veterinary Surgical Equipment",
                      keywords: "Veterinary Surgical Equipment",
                      description: "Veterinary Surgical Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3f8",
                    },
                    slug: "veterinary-treatment-devices",
                    title: "Veterinary Treatment Devices",
                    id: 1000266,
                    parent:
                      "/healthcare/animal-health/animal-medical-devices/veterinary-equipment",
                    path: "/healthcare/animal-health/animal-medical-devices/veterinary-equipment/veterinary-treatment-devices",
                    active: true,
                    meta: {
                      tags: "Veterinary Treatment Devices",
                      keywords: "Veterinary Treatment Devices",
                      description: "Veterinary Treatment Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3f9",
                    },
                    slug: "veterinary-imaging-devices",
                    title: "Veterinary Imaging Devices",
                    id: 1000267,
                    parent:
                      "/healthcare/animal-health/animal-medical-devices/veterinary-equipment",
                    path: "/healthcare/animal-health/animal-medical-devices/veterinary-equipment/veterinary-imaging-devices",
                    active: true,
                    meta: {
                      tags: "Veterinary Imaging Devices",
                      keywords: "Veterinary Imaging Devices",
                      description: "Veterinary Imaging Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3fa",
                    },
                    slug: "veterinary-implants",
                    title: "Veterinary Implants",
                    id: 1000268,
                    parent:
                      "/healthcare/animal-health/animal-medical-devices/veterinary-equipment",
                    path: "/healthcare/animal-health/animal-medical-devices/veterinary-equipment/veterinary-implants",
                    active: true,
                    meta: {
                      tags: "Veterinary Implants",
                      keywords: "Veterinary Implants",
                      description: "Veterinary Implants",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b3fb",
                },
                slug: "vet-supplies",
                title: "Vet Supplies",
                id: 1000269,
                parent: "/healthcare/animal-health/animal-medical-devices",
                path: "/healthcare/animal-health/animal-medical-devices/vet-supplies",
                active: true,
                meta: {
                  tags: "Vet Supplies",
                  keywords: "Vet Supplies",
                  description: "Vet Supplies",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b3fc",
                    },
                    slug: "vet-diagnostic-supplies",
                    title: "Vet Diagnostic Supplies",
                    id: 1000270,
                    parent:
                      "/healthcare/animal-health/animal-medical-devices/vet-supplies",
                    path: "/healthcare/animal-health/animal-medical-devices/vet-supplies/vet-diagnostic-supplies",
                    active: true,
                    meta: {
                      tags: "Vet Diagnostic Supplies",
                      keywords: "Vet Diagnostic Supplies",
                      description: "Vet Diagnostic Supplies",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3fd",
                        },
                        slug: "animal-testing-kits",
                        title: "Animal Testing Kits",
                        id: 1000271,
                        parent:
                          "/healthcare/animal-health/animal-medical-devices/vet-supplies/vet-diagnostic-supplies",
                        path: "/healthcare/animal-health/animal-medical-devices/vet-supplies/vet-diagnostic-supplies/animal-testing-kits",
                        active: true,
                        meta: {
                          tags: "Animal Testing Kits",
                          keywords: "Animal Testing Kits",
                          description: "Animal Testing Kits",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3fe",
                        },
                        slug: "animal-reagents",
                        title: "Animal Reagents",
                        id: 1000272,
                        parent:
                          "/healthcare/animal-health/animal-medical-devices/vet-supplies/vet-diagnostic-supplies",
                        path: "/healthcare/animal-health/animal-medical-devices/vet-supplies/vet-diagnostic-supplies/animal-reagents",
                        active: true,
                        meta: {
                          tags: "Animal Reagents",
                          keywords: "Animal Reagents",
                          description: "Animal Reagents",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b3ff",
                        },
                        slug: "animal-health-assays",
                        title: "Animal Health Assays",
                        id: 1000273,
                        parent:
                          "/healthcare/animal-health/animal-medical-devices/vet-supplies/vet-diagnostic-supplies",
                        path: "/healthcare/animal-health/animal-medical-devices/vet-supplies/vet-diagnostic-supplies/animal-health-assays",
                        active: true,
                        meta: {
                          tags: "Animal Health Assays",
                          keywords: "Animal Health Assays",
                          description: "Animal Health Assays",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b400",
                    },
                    slug: "veterinary-dental-supplies",
                    title: "Veterinary Dental Supplies",
                    id: 1000274,
                    parent:
                      "/healthcare/animal-health/animal-medical-devices/vet-supplies",
                    path: "/healthcare/animal-health/animal-medical-devices/vet-supplies/veterinary-dental-supplies",
                    active: true,
                    meta: {
                      tags: "Veterinary Dental Supplies",
                      keywords: "Veterinary Dental Supplies",
                      description: "Veterinary Dental Supplies",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b401",
                    },
                    slug: "veterinary-surgical-supplies",
                    title: "Veterinary Surgical Supplies",
                    id: 1000275,
                    parent:
                      "/healthcare/animal-health/animal-medical-devices/vet-supplies",
                    path: "/healthcare/animal-health/animal-medical-devices/vet-supplies/veterinary-surgical-supplies",
                    active: true,
                    meta: {
                      tags: "Veterinary Surgical Supplies",
                      keywords: "Veterinary Surgical Supplies",
                      description: "Veterinary Surgical Supplies",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b402",
                    },
                    slug: "veterinary-laboratory-instruments",
                    title: "Veterinary Laboratory Instruments",
                    id: 1000276,
                    parent:
                      "/healthcare/animal-health/animal-medical-devices/vet-supplies",
                    path: "/healthcare/animal-health/animal-medical-devices/vet-supplies/veterinary-laboratory-instruments",
                    active: true,
                    meta: {
                      tags: "Veterinary Laboratory Instruments",
                      keywords: "Veterinary Laboratory Instruments",
                      description: "Veterinary Laboratory Instruments",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b403",
            },
            slug: "veterinary-services",
            title: "Veterinary Services",
            id: 1000277,
            parent: "/healthcare/animal-health",
            path: "/healthcare/animal-health/veterinary-services",
            active: true,
            meta: {
              tags: "Veterinary Services",
              keywords: "Veterinary Services",
              description: "Veterinary Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b404",
                },
                slug: "veterinary-clinical-services",
                title: "Veterinary Clinical Services",
                id: 1000278,
                parent: "/healthcare/animal-health/veterinary-services",
                path: "/healthcare/animal-health/veterinary-services/veterinary-clinical-services",
                active: true,
                meta: {
                  tags: "Veterinary Clinical Services",
                  keywords: "Veterinary Clinical Services",
                  description: "Veterinary Clinical Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b405",
                    },
                    slug: "animal-physical-examination",
                    title: "Animal Physical Examination",
                    id: 1000279,
                    parent:
                      "/healthcare/animal-health/veterinary-services/veterinary-clinical-services",
                    path: "/healthcare/animal-health/veterinary-services/veterinary-clinical-services/animal-physical-examination",
                    active: true,
                    meta: {
                      tags: "Animal Physical Examination",
                      keywords: "Animal Physical Examination",
                      description: "Animal Physical Examination",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b406",
                    },
                    slug: "animal-dental-care",
                    title: "Animal Dental Care",
                    id: 1000280,
                    parent:
                      "/healthcare/animal-health/veterinary-services/veterinary-clinical-services",
                    path: "/healthcare/animal-health/veterinary-services/veterinary-clinical-services/animal-dental-care",
                    active: true,
                    meta: {
                      tags: "Animal Dental Care",
                      keywords: "Animal Dental Care",
                      description: "Animal Dental Care",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b407",
                },
                slug: "veterinary-preventive-services",
                title: "Veterinary Preventive Services",
                id: 1000281,
                parent: "/healthcare/animal-health/veterinary-services",
                path: "/healthcare/animal-health/veterinary-services/veterinary-preventive-services",
                active: true,
                meta: {
                  tags: "Veterinary Preventive Services",
                  keywords: "Veterinary Preventive Services",
                  description: "Veterinary Preventive Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b408",
                    },
                    slug: "animal-vaccination",
                    title: "Animal Vaccination",
                    id: 1000282,
                    parent:
                      "/healthcare/animal-health/veterinary-services/veterinary-preventive-services",
                    path: "/healthcare/animal-health/veterinary-services/veterinary-preventive-services/animal-vaccination",
                    active: true,
                    meta: {
                      tags: "Animal Vaccination",
                      keywords: "Animal Vaccination",
                      description: "Animal Vaccination",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b409",
                    },
                    slug: "animal-parasite-prevention",
                    title: "Animal Parasite Prevention",
                    id: 1000283,
                    parent:
                      "/healthcare/animal-health/veterinary-services/veterinary-preventive-services",
                    path: "/healthcare/animal-health/veterinary-services/veterinary-preventive-services/animal-parasite-prevention",
                    active: true,
                    meta: {
                      tags: "Animal Parasite Prevention",
                      keywords: "Animal Parasite Prevention",
                      description: "Animal Parasite Prevention",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b40a",
                },
                slug: "veterinary-support-services",
                title: "Veterinary Support Services",
                id: 1000284,
                parent: "/healthcare/animal-health/veterinary-services",
                path: "/healthcare/animal-health/veterinary-services/veterinary-support-services",
                active: true,
                meta: {
                  tags: "Veterinary Support Services",
                  keywords: "Veterinary Support Services",
                  description: "Veterinary Support Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b40b",
                    },
                    slug: "artificial-insemination",
                    title: "Artificial Insemination",
                    id: 1000285,
                    parent:
                      "/healthcare/animal-health/veterinary-services/veterinary-support-services",
                    path: "/healthcare/animal-health/veterinary-services/veterinary-support-services/artificial-insemination",
                    active: true,
                    meta: {
                      tags: "Artificial Insemination",
                      keywords: "Artificial Insemination",
                      description: "Artificial Insemination",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b40c",
                    },
                    slug: "animal-breeding-services",
                    title: "Animal Breeding Services",
                    id: 1000286,
                    parent:
                      "/healthcare/animal-health/veterinary-services/veterinary-support-services",
                    path: "/healthcare/animal-health/veterinary-services/veterinary-support-services/animal-breeding-services",
                    active: true,
                    meta: {
                      tags: "Animal Breeding Services",
                      keywords: "Animal Breeding Services",
                      description: "Animal Breeding Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b40d",
                    },
                    slug: "pet-behavioural-counseling",
                    title: "Pet Behavioural Counseling",
                    id: 1000287,
                    parent:
                      "/healthcare/animal-health/veterinary-services/veterinary-support-services",
                    path: "/healthcare/animal-health/veterinary-services/veterinary-support-services/pet-behavioural-counseling",
                    active: true,
                    meta: {
                      tags: "Pet Behavioural Counseling",
                      keywords: "Pet Behavioural Counseling",
                      description: "Pet Behavioural Counseling",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b40e",
                        },
                        slug: "pet-grooming",
                        title: "Pet Grooming",
                        id: 1000288,
                        parent:
                          "/healthcare/animal-health/veterinary-services/veterinary-support-services/pet-behavioural-counseling",
                        path: "/healthcare/animal-health/veterinary-services/veterinary-support-services/pet-behavioural-counseling/pet-grooming",
                        active: true,
                        meta: {
                          tags: "Pet Grooming",
                          keywords: "Pet Grooming",
                          description: "Pet Grooming",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b40f",
                        },
                        slug: "pet-training",
                        title: "Pet Training",
                        id: 1000289,
                        parent:
                          "/healthcare/animal-health/veterinary-services/veterinary-support-services/pet-behavioural-counseling",
                        path: "/healthcare/animal-health/veterinary-services/veterinary-support-services/pet-behavioural-counseling/pet-training",
                        active: true,
                        meta: {
                          tags: "Pet Training",
                          keywords: "Pet Training",
                          description: "Pet Training",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b410",
                        },
                        slug: "pet-transportation",
                        title: "Pet Transportation",
                        id: 1000290,
                        parent:
                          "/healthcare/animal-health/veterinary-services/veterinary-support-services/pet-behavioural-counseling",
                        path: "/healthcare/animal-health/veterinary-services/veterinary-support-services/pet-behavioural-counseling/pet-transportation",
                        active: true,
                        meta: {
                          tags: "Pet Transportation",
                          keywords: "Pet Transportation",
                          description: "Pet Transportation",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b411",
                },
                slug: "animal-digital-health",
                title: "Animal Digital Health",
                id: 1000291,
                parent: "/healthcare/animal-health/veterinary-services",
                path: "/healthcare/animal-health/veterinary-services/animal-digital-health",
                active: true,
                meta: {
                  tags: "Animal Digital Health",
                  keywords: "Animal Digital Health",
                  description: "Animal Digital Health",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b159",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Other Healthcare Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Healthcare Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Other Healthcare Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Healthcare Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Others Healthcare Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Healthcare Market Research Reports, healthcare market research reports, healthcare market report, animal health market research, medical device industry, home healthcare market, digital healthcare market, medical equipment industry, medical supplies market, smart medical devices market, medical device and diagnostic industry",
          description:
            "Mordor Intelligence™ 【  Other Healthcare Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Other Healthcare Research",
        slug: "animal-healthcare-others",
        id: 56167,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/animal-healthcare-others",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b15a",
        },
        picture_url: null,
        description: {
          short_description:
            "Mordor Intelligence™ offers a broad spectrum of Market Research, Consulting, and Advisory services on markets across diabetes drugs & devices. In-depth industry statistics and market share insights of the Diabetes Drugs & Devices sector for 2020, 2021, and 2022. These Diabetes Drugs & Devices research reports provide a comprehensive outlook of the market size and an industry growth forecast for 2023 to 2028.",
          full_description:
            "We offer a broad spectrum of Market Research, Consulting, and Advisory services on markets across diabetes drugs & devices",
        },
        meta: {
          tags: "Diabetes Drugs & Devices Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Diabetes Drugs & Devices Market Research Reports, diabetes drugs devices market, diabetes drugs devices market report, diabetes drugs devices market research, diabetes drugs devices industry, diabetes drugs devices industry report, diabetes drugs devices industry research",
          description:
            "Mordor Intelligence™ 【  Diabetes Drugs & Devices Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Diabetes Drugs & Devices",
        slug: "diabetes-drugs-devices",
        id: 56219,
        __v: 0,
        parent: "/healthcare",
        path: "/healthcare/diabetes-drugs-devices",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b447",
        },
        slug: "device-drug-combination",
        title: "Device Drug Combination",
        id: 56600,
        parent: "/healthcare",
        path: "/healthcare/device-drug-combination",
        active: true,
        meta: {
          tags: "Device Drug Combination",
          keywords: "Device Drug Combination",
          description: "Device Drug Combination",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b448",
            },
            slug: "drug-filled-devices",
            title: "Drug Filled Devices",
            id: 1000347,
            parent: "/healthcare/device-drug-combination",
            path: "/healthcare/device-drug-combination/drug-filled-devices",
            active: true,
            meta: {
              tags: "Drug Filled Devices",
              keywords: "Drug Filled Devices",
              description: "Drug Filled Devices",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b449",
                },
                slug: "drug-filled-devices-with-needle",
                title: "Drug Filled Devices With Needle",
                id: 1000348,
                parent:
                  "/healthcare/device-drug-combination/drug-filled-devices",
                path: "/healthcare/device-drug-combination/drug-filled-devices/drug-filled-devices-with-needle",
                active: true,
                meta: {
                  tags: "Drug Filled Devices With Needle",
                  keywords: "Drug Filled Devices With Needle",
                  description: "Drug Filled Devices With Needle",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b44a",
                },
                slug: "drug-filled-devices-without-needle",
                title: "Drug Filled Devices Without Needle",
                id: 1000349,
                parent:
                  "/healthcare/device-drug-combination/drug-filled-devices",
                path: "/healthcare/device-drug-combination/drug-filled-devices/drug-filled-devices-without-needle",
                active: true,
                meta: {
                  tags: "Drug Filled Devices Without Needle",
                  keywords: "Drug Filled Devices Without Needle",
                  description: "Drug Filled Devices Without Needle",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b44b",
            },
            slug: "drug-coated-devices",
            title: "Drug Coated Devices",
            id: 1000350,
            parent: "/healthcare/device-drug-combination",
            path: "/healthcare/device-drug-combination/drug-coated-devices",
            active: true,
            meta: {
              tags: "Drug Coated Devices",
              keywords: "Drug Coated Devices",
              description: "Drug Coated Devices",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b44c",
                },
                slug: "peripheral-intervention",
                title: "Peripheral Intervention",
                id: 1000351,
                parent:
                  "/healthcare/device-drug-combination/drug-coated-devices",
                path: "/healthcare/device-drug-combination/drug-coated-devices/peripheral-intervention",
                active: true,
                meta: {
                  tags: "Peripheral Intervention",
                  keywords: "Peripheral Intervention",
                  description: "Peripheral Intervention",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b44d",
                    },
                    slug: "medical-balloons",
                    title: "Medical Balloons",
                    id: 1000352,
                    parent:
                      "/healthcare/device-drug-combination/drug-coated-devices/peripheral-intervention",
                    path: "/healthcare/device-drug-combination/drug-coated-devices/peripheral-intervention/medical-balloons",
                    active: true,
                    meta: {
                      tags: "Medical Balloons",
                      keywords: "Medical Balloons",
                      description: "Medical Balloons",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b44e",
                    },
                    slug: "stents",
                    title: "Stents",
                    id: 1000353,
                    parent:
                      "/healthcare/device-drug-combination/drug-coated-devices/peripheral-intervention",
                    path: "/healthcare/device-drug-combination/drug-coated-devices/peripheral-intervention/stents",
                    active: true,
                    meta: {
                      tags: "Stents",
                      keywords: "Stents",
                      description: "Stents",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b44f",
                    },
                    slug: "pacing-leads",
                    title: "Pacing Leads",
                    id: 1000354,
                    parent:
                      "/healthcare/device-drug-combination/drug-coated-devices/peripheral-intervention",
                    path: "/healthcare/device-drug-combination/drug-coated-devices/peripheral-intervention/pacing-leads",
                    active: true,
                    meta: {
                      tags: "Pacing Leads",
                      keywords: "Pacing Leads",
                      description: "Pacing Leads",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b450",
                },
                slug: "implantable-drug-coated-devices",
                title: "Implantable Drug Coated Devices",
                id: 1000355,
                parent:
                  "/healthcare/device-drug-combination/drug-coated-devices",
                path: "/healthcare/device-drug-combination/drug-coated-devices/implantable-drug-coated-devices",
                active: true,
                meta: {
                  tags: "Implantable Drug Coated Devices",
                  keywords: "Implantable Drug Coated Devices",
                  description: "Implantable Drug Coated Devices",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b451",
            },
            slug: "co-packages",
            title: "Co-Packages",
            id: 1000356,
            parent: "/healthcare/device-drug-combination",
            path: "/healthcare/device-drug-combination/co-packages",
            active: true,
            meta: {
              tags: "Co-Packages",
              keywords: "Co-Packages",
              description: "Co-Packages",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b452",
                },
                slug: "therapeutic-systems",
                title: "Therapeutic Systems",
                id: 1000357,
                parent: "/healthcare/device-drug-combination/co-packages",
                path: "/healthcare/device-drug-combination/co-packages/therapeutic-systems",
                active: true,
                meta: {
                  tags: "Therapeutic Systems",
                  keywords: "Therapeutic Systems",
                  description: "Therapeutic Systems",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b453",
                    },
                    slug: "nuclear-medicine",
                    title: "Nuclear Medicine",
                    id: 1000358,
                    parent:
                      "/healthcare/device-drug-combination/co-packages/therapeutic-systems",
                    path: "/healthcare/device-drug-combination/co-packages/therapeutic-systems/nuclear-medicine",
                    active: true,
                    meta: {
                      tags: "Nuclear Medicine",
                      keywords: "Nuclear Medicine",
                      description: "Nuclear Medicine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b454",
                    },
                    slug: "photodynamic-therapy",
                    title: "Photodynamic Therapy",
                    id: 1000359,
                    parent:
                      "/healthcare/device-drug-combination/co-packages/therapeutic-systems",
                    path: "/healthcare/device-drug-combination/co-packages/therapeutic-systems/photodynamic-therapy",
                    active: true,
                    meta: {
                      tags: "Photodynamic Therapy",
                      keywords: "Photodynamic Therapy",
                      description: "Photodynamic Therapy",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b455",
                    },
                    slug: "electro-therapy",
                    title: "Electro Therapy",
                    id: 1000360,
                    parent:
                      "/healthcare/device-drug-combination/co-packages/therapeutic-systems",
                    path: "/healthcare/device-drug-combination/co-packages/therapeutic-systems/electro-therapy",
                    active: true,
                    meta: {
                      tags: "Electro Therapy",
                      keywords: "Electro Therapy",
                      description: "Electro Therapy",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b456",
                    },
                    slug: "physiotherapy",
                    title: "Physiotherapy",
                    id: 1000361,
                    parent:
                      "/healthcare/device-drug-combination/co-packages/therapeutic-systems",
                    path: "/healthcare/device-drug-combination/co-packages/therapeutic-systems/physiotherapy",
                    active: true,
                    meta: {
                      tags: "Physiotherapy",
                      keywords: "Physiotherapy",
                      description: "Physiotherapy",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b457",
                },
                slug: "therapeutic-products",
                title: "Therapeutic Products",
                id: 1000362,
                parent: "/healthcare/device-drug-combination/co-packages",
                path: "/healthcare/device-drug-combination/co-packages/therapeutic-products",
                active: true,
                meta: {
                  tags: "Therapeutic Products",
                  keywords: "Therapeutic Products",
                  description: "Therapeutic Products",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b458",
                    },
                    slug: "medical-cements",
                    title: "Medical Cements",
                    id: 1000363,
                    parent:
                      "/healthcare/device-drug-combination/co-packages/therapeutic-products",
                    path: "/healthcare/device-drug-combination/co-packages/therapeutic-products/medical-cements",
                    active: true,
                    meta: {
                      tags: "Medical Cements",
                      keywords: "Medical Cements",
                      description: "Medical Cements",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b459",
                    },
                    slug: "medical-gel",
                    title: "Medical Gel",
                    id: 1000364,
                    parent:
                      "/healthcare/device-drug-combination/co-packages/therapeutic-products",
                    path: "/healthcare/device-drug-combination/co-packages/therapeutic-products/medical-gel",
                    active: true,
                    meta: {
                      tags: "Medical Gel",
                      keywords: "Medical Gel",
                      description: "Medical Gel",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b45a",
                    },
                    slug: "medical-patches",
                    title: "Medical Patches",
                    id: 1000365,
                    parent:
                      "/healthcare/device-drug-combination/co-packages/therapeutic-products",
                    path: "/healthcare/device-drug-combination/co-packages/therapeutic-products/medical-patches",
                    active: true,
                    meta: {
                      tags: "Medical Patches",
                      keywords: "Medical Patches",
                      description: "Medical Patches",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b45b",
                    },
                    slug: "medical-agents",
                    title: "Medical Agents",
                    id: 1000366,
                    parent:
                      "/healthcare/device-drug-combination/co-packages/therapeutic-products",
                    path: "/healthcare/device-drug-combination/co-packages/therapeutic-products/medical-agents",
                    active: true,
                    meta: {
                      tags: "Medical Agents",
                      keywords: "Medical Agents",
                      description: "Medical Agents",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b45c",
                },
                slug: "therapeutic-software",
                title: "Therapeutic Software",
                id: 1000367,
                parent: "/healthcare/device-drug-combination/co-packages",
                path: "/healthcare/device-drug-combination/co-packages/therapeutic-software",
                active: true,
                meta: {
                  tags: "Therapeutic Software",
                  keywords: "Therapeutic Software",
                  description: "Therapeutic Software",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b45d",
            },
            slug: "diagnostics-and-therapeutics",
            title: "Diagnostics and Therapeutics",
            id: 1000368,
            parent: "/healthcare/device-drug-combination",
            path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
            active: true,
            meta: {
              tags: "Diagnostics and Therapeutics",
              keywords: "Diagnostics and Therapeutics",
              description: "Diagnostics and Therapeutics",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b45e",
                },
                slug: "oncology",
                title: "Oncology",
                id: 1000369,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/oncology",
                active: true,
                meta: {
                  tags: "Oncology",
                  keywords: "Oncology",
                  description: "Oncology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b45f",
                },
                slug: "cardiovascular",
                title: "Cardiovascular",
                id: 1000370,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/cardiovascular",
                active: true,
                meta: {
                  tags: "Cardiovascular",
                  keywords: "Cardiovascular",
                  description: "Cardiovascular",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b460",
                },
                slug: "orthopedic",
                title: "Orthopedic",
                id: 1000371,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/orthopedic",
                active: true,
                meta: {
                  tags: "Orthopedic",
                  keywords: "Orthopedic",
                  description: "Orthopedic",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b461",
                },
                slug: "ophthalmology",
                title: "Ophthalmology",
                id: 1000372,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/ophthalmology",
                active: true,
                meta: {
                  tags: "Ophthalmology",
                  keywords: "Ophthalmology",
                  description: "Ophthalmology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b462",
                },
                slug: "dental-care",
                title: "Dental Care",
                id: 1000373,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/dental-care",
                active: true,
                meta: {
                  tags: "Dental Care",
                  keywords: "Dental Care",
                  description: "Dental Care",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b463",
                },
                slug: "nephrology",
                title: "Nephrology",
                id: 1000374,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/nephrology",
                active: true,
                meta: {
                  tags: "Nephrology",
                  keywords: "Nephrology",
                  description: "Nephrology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b464",
                },
                slug: "diabetes-care",
                title: "Diabetes Care",
                id: 1000375,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/diabetes-care",
                active: true,
                meta: {
                  tags: "Diabetes Care",
                  keywords: "Diabetes Care",
                  description: "Diabetes Care",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b465",
                },
                slug: "pulmonology",
                title: "Pulmonology",
                id: 1000376,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/pulmonology",
                active: true,
                meta: {
                  tags: "Pulmonology",
                  keywords: "Pulmonology",
                  description: "Pulmonology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b466",
                },
                slug: "neurology",
                title: "Neurology",
                id: 1000377,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/neurology",
                active: true,
                meta: {
                  tags: "Neurology",
                  keywords: "Neurology",
                  description: "Neurology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b467",
                },
                slug: "gastroenterology",
                title: "Gastroenterology",
                id: 1000378,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/gastroenterology",
                active: true,
                meta: {
                  tags: "Gastroenterology",
                  keywords: "Gastroenterology",
                  description: "Gastroenterology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b468",
                },
                slug: "endocrinology",
                title: "Endocrinology",
                id: 1000379,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/endocrinology",
                active: true,
                meta: {
                  tags: "Endocrinology",
                  keywords: "Endocrinology",
                  description: "Endocrinology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b469",
                },
                slug: "hepatology",
                title: "Hepatology",
                id: 1000380,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/hepatology",
                active: true,
                meta: {
                  tags: "Hepatology",
                  keywords: "Hepatology",
                  description: "Hepatology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b46a",
                },
                slug: "hematology",
                title: "Hematology",
                id: 1000381,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/hematology",
                active: true,
                meta: {
                  tags: "Hematology",
                  keywords: "Hematology",
                  description: "Hematology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b46b",
                },
                slug: "obstetrics-and-gynecology",
                title: "Obstetrics and Gynecology",
                id: 1000382,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/obstetrics-and-gynecology",
                active: true,
                meta: {
                  tags: "Obstetrics and Gynecology",
                  keywords: "Obstetrics and Gynecology",
                  description: "Obstetrics and Gynecology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b46c",
                },
                slug: "urology",
                title: "Urology",
                id: 1000383,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/urology",
                active: true,
                meta: {
                  tags: "Urology",
                  keywords: "Urology",
                  description: "Urology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b46d",
                },
                slug: "dermatology",
                title: "Dermatology",
                id: 1000384,
                parent:
                  "/healthcare/device-drug-combination/diagnostics-and-therapeutics",
                path: "/healthcare/device-drug-combination/diagnostics-and-therapeutics/dermatology",
                active: true,
                meta: {
                  tags: "Dermatology",
                  keywords: "Dermatology",
                  description: "Dermatology",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b46e",
        },
        slug: "health-services",
        title: "Health Services",
        id: 56601,
        parent: "/healthcare",
        path: "/healthcare/health-services",
        active: true,
        meta: {
          tags: "Health Services",
          keywords: "Health Services",
          description: "Health Services",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b46f",
            },
            slug: "acute-care",
            title: "Acute Care",
            id: 1000386,
            parent: "/healthcare/health-services",
            path: "/healthcare/health-services/acute-care",
            active: true,
            meta: {
              tags: "Acute Care",
              keywords: "Acute Care",
              description: "Acute Care",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b470",
                },
                slug: "emergency-rooms",
                title: "Emergency Rooms",
                id: 1000387,
                parent: "/healthcare/health-services/acute-care",
                path: "/healthcare/health-services/acute-care/emergency-rooms",
                active: true,
                meta: {
                  tags: "Emergency Rooms",
                  keywords: "Emergency Rooms",
                  description: "Emergency Rooms",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b471",
                },
                slug: "hospital",
                title: "Hospital",
                id: 1000388,
                parent: "/healthcare/health-services/acute-care",
                path: "/healthcare/health-services/acute-care/hospital",
                active: true,
                meta: {
                  tags: "Hospital",
                  keywords: "Hospital",
                  description: "Hospital",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b472",
                },
                slug: "trauma-care-and-surgery-centers",
                title: "Trauma Care and Surgery Centers",
                id: 1000389,
                parent: "/healthcare/health-services/acute-care",
                path: "/healthcare/health-services/acute-care/trauma-care-and-surgery-centers",
                active: true,
                meta: {
                  tags: "Trauma Care and Surgery Centers",
                  keywords: "Trauma Care and Surgery Centers",
                  description: "Trauma Care and Surgery Centers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b473",
                },
                slug: "rehabilitation-centers",
                title: "Rehabilitation Centers",
                id: 1000390,
                parent: "/healthcare/health-services/acute-care",
                path: "/healthcare/health-services/acute-care/rehabilitation-centers",
                active: true,
                meta: {
                  tags: "Rehabilitation Centers",
                  keywords: "Rehabilitation Centers",
                  description: "Rehabilitation Centers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b474",
                },
                slug: "physical-therapy-centers",
                title: "Physical Therapy Centers",
                id: 1000391,
                parent: "/healthcare/health-services/acute-care",
                path: "/healthcare/health-services/acute-care/physical-therapy-centers",
                active: true,
                meta: {
                  tags: "Physical Therapy Centers",
                  keywords: "Physical Therapy Centers",
                  description: "Physical Therapy Centers",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b475",
            },
            slug: "long-term-care",
            title: "Long Term Care",
            id: 1000392,
            parent: "/healthcare/health-services",
            path: "/healthcare/health-services/long-term-care",
            active: true,
            meta: {
              tags: "Long Term Care",
              keywords: "Long Term Care",
              description: "Long Term Care",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b476",
                },
                slug: "institutional-long-term-care",
                title: "Institutional Long Term Care",
                id: 1000393,
                parent: "/healthcare/health-services/long-term-care",
                path: "/healthcare/health-services/long-term-care/institutional-long-term-care",
                active: true,
                meta: {
                  tags: "Institutional Long Term Care",
                  keywords: "Institutional Long Term Care",
                  description: "Institutional Long Term Care",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b477",
                    },
                    slug: "nursing-homes",
                    title: "Nursing Homes",
                    id: 1000394,
                    parent:
                      "/healthcare/health-services/long-term-care/institutional-long-term-care",
                    path: "/healthcare/health-services/long-term-care/institutional-long-term-care/nursing-homes",
                    active: true,
                    meta: {
                      tags: "Nursing Homes",
                      keywords: "Nursing Homes",
                      description: "Nursing Homes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b478",
                    },
                    slug: "assisted-living",
                    title: "Assisted Living",
                    id: 1000395,
                    parent:
                      "/healthcare/health-services/long-term-care/institutional-long-term-care",
                    path: "/healthcare/health-services/long-term-care/institutional-long-term-care/assisted-living",
                    active: true,
                    meta: {
                      tags: "Assisted Living",
                      keywords: "Assisted Living",
                      description: "Assisted Living",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b479",
                },
                slug: "private-long-term-care",
                title: "Private Long Term Care",
                id: 1000396,
                parent: "/healthcare/health-services/long-term-care",
                path: "/healthcare/health-services/long-term-care/private-long-term-care",
                active: true,
                meta: {
                  tags: "Private Long Term Care",
                  keywords: "Private Long Term Care",
                  description: "Private Long Term Care",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b47a",
                    },
                    slug: "home-health",
                    title: "Home Health",
                    id: 1000397,
                    parent:
                      "/healthcare/health-services/long-term-care/private-long-term-care",
                    path: "/healthcare/health-services/long-term-care/private-long-term-care/home-health",
                    active: true,
                    meta: {
                      tags: "Home Health",
                      keywords: "Home Health",
                      description: "Home Health",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b47b",
                    },
                    slug: "senior-care",
                    title: "Senior Care",
                    id: 1000398,
                    parent:
                      "/healthcare/health-services/long-term-care/private-long-term-care",
                    path: "/healthcare/health-services/long-term-care/private-long-term-care/senior-care",
                    active: true,
                    meta: {
                      tags: "Senior Care",
                      keywords: "Senior Care",
                      description: "Senior Care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b47c",
                    },
                    slug: "respite-care",
                    title: "Respite Care",
                    id: 1000399,
                    parent:
                      "/healthcare/health-services/long-term-care/private-long-term-care",
                    path: "/healthcare/health-services/long-term-care/private-long-term-care/respite-care",
                    active: true,
                    meta: {
                      tags: "Respite Care",
                      keywords: "Respite Care",
                      description: "Respite Care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b47d",
                    },
                    slug: "hospice-care",
                    title: "Hospice Care",
                    id: 1000400,
                    parent:
                      "/healthcare/health-services/long-term-care/private-long-term-care",
                    path: "/healthcare/health-services/long-term-care/private-long-term-care/hospice-care",
                    active: true,
                    meta: {
                      tags: "Hospice Care",
                      keywords: "Hospice Care",
                      description: "Hospice Care",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b47e",
            },
            slug: "ambulatory-care",
            title: "Ambulatory Care",
            id: 1000401,
            parent: "/healthcare/health-services",
            path: "/healthcare/health-services/ambulatory-care",
            active: true,
            meta: {
              tags: "Ambulatory Care",
              keywords: "Ambulatory Care",
              description: "Ambulatory Care",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b47f",
                },
                slug: "clinical-settings",
                title: "Clinical Settings",
                id: 1000402,
                parent: "/healthcare/health-services/ambulatory-care",
                path: "/healthcare/health-services/ambulatory-care/clinical-settings",
                active: true,
                meta: {
                  tags: "Clinical Settings",
                  keywords: "Clinical Settings",
                  description: "Clinical Settings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b480",
                },
                slug: "urgent-care",
                title: "Urgent Care",
                id: 1000403,
                parent: "/healthcare/health-services/ambulatory-care",
                path: "/healthcare/health-services/ambulatory-care/urgent-care",
                active: true,
                meta: {
                  tags: "Urgent Care",
                  keywords: "Urgent Care",
                  description: "Urgent Care",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b481",
            },
            slug: "behavioral-healthcare",
            title: "Behavioral Healthcare",
            id: 1000404,
            parent: "/healthcare/health-services",
            path: "/healthcare/health-services/behavioral-healthcare",
            active: true,
            meta: {
              tags: "Behavioral Healthcare",
              keywords: "Behavioral Healthcare",
              description: "Behavioral Healthcare",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b482",
            },
            slug: "laboratory-services",
            title: "Laboratory Services",
            id: 1000405,
            parent: "/healthcare/health-services",
            path: "/healthcare/health-services/laboratory-services",
            active: true,
            meta: {
              tags: "Laboratory Services",
              keywords: "Laboratory Services",
              description: "Laboratory Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b483",
            },
            slug: "healthcare-administrative-services",
            title: "Healthcare Administrative Services",
            id: 1000406,
            parent: "/healthcare/health-services",
            path: "/healthcare/health-services/healthcare-administrative-services",
            active: true,
            meta: {
              tags: "Healthcare Administrative Services",
              keywords: "Healthcare Administrative Services",
              description: "Healthcare Administrative Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b484",
                },
                slug: "healthcare-staffing",
                title: "Healthcare Staffing",
                id: 1000407,
                parent:
                  "/healthcare/health-services/healthcare-administrative-services",
                path: "/healthcare/health-services/healthcare-administrative-services/healthcare-staffing",
                active: true,
                meta: {
                  tags: "Healthcare Staffing",
                  keywords: "Healthcare Staffing",
                  description: "Healthcare Staffing",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b485",
            },
            slug: "healthcare-facility-management",
            title: "Healthcare Facility Management",
            id: 1000408,
            parent: "/healthcare/health-services",
            path: "/healthcare/health-services/healthcare-facility-management",
            active: true,
            meta: {
              tags: "Healthcare Facility Management",
              keywords: "Healthcare Facility Management",
              description: "Healthcare Facility Management",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b125",
    },
    picture_url: null,
    description: {
      short_description:
        "Statistics for the 2023 Home and Property Improvement  market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Home and Property Improvement  analysis includes a market forecast outlook to 2028 and historical overview. Get a sample of this industry analysis as a free report PDF download.",
      full_description:
        "Statistics for the 2023 Home and Property Improvement  market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Home and Property Improvement  analysis includes a market forecast outlook to 2028 and historical overview. Get a sample of this industry analysis as a free report PDF download.",
    },
    meta: {
      tags: "Home and Property Improvement Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "home and property improvement reports, home services market, construction machinery industry, home decor market, kitchen appliance industry, appliance industry, home appliances industry, data analytics market size, additive manufacturing market, retail market analysis, outdoor furniture market, home improvement market size, office supplies industry, office furniture market, home decor industry",
      description:
        "Mordor Intelligence™ 【  Home and Property Market Research  】 Industry Reports, including market share insights for 2020, 2021, and 2022.  Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
    },
    active: true,
    title: "Home and Property Improvement",
    slug: "home-property-improvement",
    id: 80,
    __v: 0,
    parent: "/",
    path: "/home-property-improvement",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b13b",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Home Appliances market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Home Appliances Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Home Appliances market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Home Appliances Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Home Appliances Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Home Appliances Market Research Reports, home appliances market, home appliances market report, home appliances market size, home appliances market analysis, home appliances market research, home appliances industry report, home appliances industry",
          description:
            "Mordor Intelligence™ 【  Home Appliances Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Home Appliances",
        slug: "home-appliances",
        id: 80214,
        __v: 0,
        parent: "/home-property-improvement",
        path: "/home-property-improvement/home-appliances",
        sub_categories: [
          {
            _id: {
              $oid: "64809557188eb1b8a1c5b13a",
            },
            picture_url: null,
            description: {
              short_description:
                "Statistics for the 2023 Kitchen Appliances market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Kitchen Appliances Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
              full_description:
                "Statistics for the 2023 Kitchen Appliances market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Kitchen Appliances Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
            },
            meta: {
              tags: "Kitchen Appliances Market Size & Share Analysis - Industry Research Reports - Growth Trends",
              keywords:
                "Kitchen Appliances Market Research Reports, kitchen appliances market, kitchen appliances market research report, kitchen appliances industry, kitchen appliances industry research report, kitchen appliances market intelligence report.",
              description:
                "Mordor Intelligence™ 【  Kitchen Appliances Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
            },
            active: true,
            title: "Kitchen Appliances",
            slug: "kitchen-appliances",
            id: 80213,
            __v: 0,
            parent: "/home-property-improvement/home-appliances",
            path: "/home-property-improvement/home-appliances/kitchen-appliances",
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51dae2",
                },
                slug: "large-kitchen-appliances",
                title: "Large Kitchen Appliances",
                id: 14000092,
                parent:
                  "/home-property-improvement/home-appliances/kitchen-appliances",
                path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances",
                active: true,
                meta: {
                  tags: "Large Kitchen Appliances",
                  keywords: "Large Kitchen Appliances",
                  description: "Large Kitchen Appliances",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dae3",
                    },
                    slug: "electric-ranges",
                    title: "Electric Ranges",
                    id: 14000093,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/electric-ranges",
                    active: true,
                    meta: {
                      tags: "Electric Ranges",
                      keywords: "Electric Ranges",
                      description: "Electric Ranges",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dae4",
                    },
                    slug: "flour-milling-machines",
                    title: "Flour Milling Machines",
                    id: 14000094,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/flour-milling-machines",
                    active: true,
                    meta: {
                      tags: "Flour Milling Machines",
                      keywords: "Flour Milling Machines",
                      description: "Flour Milling Machines",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dae5",
                    },
                    slug: "hobs-and-cooktops",
                    title: "Hobs and Cooktops",
                    id: 14000095,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/hobs-and-cooktops",
                    active: true,
                    meta: {
                      tags: "Hobs and Cooktops",
                      keywords: "Hobs and Cooktops",
                      description: "Hobs and Cooktops",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dae6",
                        },
                        slug: "gas-hobs",
                        title: "Gas Hobs",
                        id: 14000096,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/hobs-and-cooktops",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/hobs-and-cooktops/gas-hobs",
                        active: true,
                        meta: {
                          tags: "Gas Hobs",
                          keywords: "Gas Hobs",
                          description: "Gas Hobs",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dae7",
                        },
                        slug: "induction-cooktops",
                        title: "Induction Cooktops",
                        id: 14000097,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/hobs-and-cooktops",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/hobs-and-cooktops/induction-cooktops",
                        active: true,
                        meta: {
                          tags: "Induction Cooktops",
                          keywords: "Induction Cooktops",
                          description: "Induction Cooktops",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dae8",
                        },
                        slug: "induction-hobs",
                        title: "Induction Hobs",
                        id: 14000098,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/hobs-and-cooktops",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/hobs-and-cooktops/induction-hobs",
                        active: true,
                        meta: {
                          tags: "Induction Hobs",
                          keywords: "Induction Hobs",
                          description: "Induction Hobs",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dae9",
                    },
                    slug: "kitchen-hoods",
                    title: "Kitchen Hoods",
                    id: 14000099,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/kitchen-hoods",
                    active: true,
                    meta: {
                      tags: "Kitchen Hoods",
                      keywords: "Kitchen Hoods",
                      description: "Kitchen Hoods",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daea",
                    },
                    slug: "ovens",
                    title: "Ovens",
                    id: 14000100,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/ovens",
                    active: true,
                    meta: {
                      tags: "Ovens",
                      keywords: "Ovens",
                      description: "Ovens",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51daeb",
                        },
                        slug: "convection-ovens",
                        title: "Convection Ovens",
                        id: 14000101,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/ovens",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/ovens/convection-ovens",
                        active: true,
                        meta: {
                          tags: "Convection Ovens",
                          keywords: "Convection Ovens",
                          description: "Convection Ovens",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51daec",
                        },
                        slug: "microwave-oven",
                        title: "Microwave Oven",
                        id: 14000102,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/ovens",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/ovens/microwave-oven",
                        active: true,
                        meta: {
                          tags: "Microwave Oven",
                          keywords: "Microwave Oven",
                          description: "Microwave Oven",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51daed",
                        },
                        slug: "multifunctional-ovens",
                        title: "Multifunctional Ovens",
                        id: 14000103,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/ovens",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/large-kitchen-appliances/ovens/multifunctional-ovens",
                        active: true,
                        meta: {
                          tags: "Multifunctional Ovens",
                          keywords: "Multifunctional Ovens",
                          description: "Multifunctional Ovens",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51daee",
                },
                slug: "small-kitchen-appliances",
                title: "Small Kitchen Appliances",
                id: 14000104,
                parent:
                  "/home-property-improvement/home-appliances/kitchen-appliances",
                path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                active: true,
                meta: {
                  tags: "Small Kitchen Appliances",
                  keywords: "Small Kitchen Appliances",
                  description: "Small Kitchen Appliances",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daef",
                    },
                    slug: "air-fryers",
                    title: "Air Fryers",
                    id: 14000105,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/air-fryers",
                    active: true,
                    meta: {
                      tags: "Air Fryers",
                      keywords: "Air Fryers",
                      description: "Air Fryers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daf0",
                    },
                    slug: "blenders",
                    title: "Blenders",
                    id: 14000106,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/blenders",
                    active: true,
                    meta: {
                      tags: "Blenders",
                      keywords: "Blenders",
                      description: "Blenders",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daf1",
                    },
                    slug: "coffee-machine",
                    title: "Coffee Machine",
                    id: 14000107,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/coffee-machine",
                    active: true,
                    meta: {
                      tags: "Coffee Machine",
                      keywords: "Coffee Machine",
                      description: "Coffee Machine",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51daf2",
                        },
                        slug: "espresso-machines",
                        title: "Espresso Machines",
                        id: 14000108,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/coffee-machine",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/coffee-machine/espresso-machines",
                        active: true,
                        meta: {
                          tags: "Espresso Machines",
                          keywords: "Espresso Machines",
                          description: "Espresso Machines",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daf3",
                    },
                    slug: "cookers",
                    title: "Cookers",
                    id: 14000109,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/cookers",
                    active: true,
                    meta: {
                      tags: "Cookers",
                      keywords: "Cookers",
                      description: "Cookers",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51daf4",
                        },
                        slug: "egg-cookers",
                        title: "Egg Cookers",
                        id: 14000110,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/cookers",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/cookers/egg-cookers",
                        active: true,
                        meta: {
                          tags: "Egg Cookers",
                          keywords: "Egg Cookers",
                          description: "Egg Cookers",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51daf5",
                        },
                        slug: "electric-cookers",
                        title: "Electric Cookers",
                        id: 14000111,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/cookers",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/cookers/electric-cookers",
                        active: true,
                        meta: {
                          tags: "Electric Cookers",
                          keywords: "Electric Cookers",
                          description: "Electric Cookers",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51daf6",
                        },
                        slug: "smart-cookers",
                        title: "Smart Cookers",
                        id: 14000112,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/cookers",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/cookers/smart-cookers",
                        active: true,
                        meta: {
                          tags: "Smart Cookers",
                          keywords: "Smart Cookers",
                          description: "Smart Cookers",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51daf7",
                        },
                        slug: "solar-cookers",
                        title: "Solar Cookers",
                        id: 14000113,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/cookers",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/cookers/solar-cookers",
                        active: true,
                        meta: {
                          tags: "Solar Cookers",
                          keywords: "Solar Cookers",
                          description: "Solar Cookers",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daf8",
                    },
                    slug: "electric-food-choppers",
                    title: "Electric Food Choppers",
                    id: 14000114,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/electric-food-choppers",
                    active: true,
                    meta: {
                      tags: "Electric Food Choppers",
                      keywords: "Electric Food Choppers",
                      description: "Electric Food Choppers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daf9",
                    },
                    slug: "electric-grills",
                    title: "Electric Grills",
                    id: 14000115,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/electric-grills",
                    active: true,
                    meta: {
                      tags: "Electric Grills",
                      keywords: "Electric Grills",
                      description: "Electric Grills",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dafa",
                        },
                        slug: "smokeless-indoor-grills",
                        title: "Smokeless Indoor Grills",
                        id: 14000116,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/electric-grills",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/electric-grills/smokeless-indoor-grills",
                        active: true,
                        meta: {
                          tags: "Smokeless Indoor Grills",
                          keywords: "Smokeless Indoor Grills",
                          description: "Smokeless Indoor Grills",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dafb",
                    },
                    slug: "electric-icecream-makers",
                    title: "Electric Icecream Makers",
                    id: 14000117,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/electric-icecream-makers",
                    active: true,
                    meta: {
                      tags: "Electric Icecream Makers",
                      keywords: "Electric Icecream Makers",
                      description: "Electric Icecream Makers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dafc",
                    },
                    slug: "electric-kettle",
                    title: "Electric Kettle",
                    id: 14000118,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/electric-kettle",
                    active: true,
                    meta: {
                      tags: "Electric Kettle",
                      keywords: "Electric Kettle",
                      description: "Electric Kettle",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dafd",
                    },
                    slug: "electric-whiskers",
                    title: "Electric Whiskers",
                    id: 14000119,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/electric-whiskers",
                    active: true,
                    meta: {
                      tags: "Electric Whiskers",
                      keywords: "Electric Whiskers",
                      description: "Electric Whiskers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dafe",
                    },
                    slug: "household-popcorn-makers",
                    title: "Household Popcorn Makers",
                    id: 14000120,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/household-popcorn-makers",
                    active: true,
                    meta: {
                      tags: "Household Popcorn Makers",
                      keywords: "Household Popcorn Makers",
                      description: "Household Popcorn Makers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daff",
                    },
                    slug: "mixer-grinders",
                    title: "Mixer Grinders",
                    id: 14000121,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/mixer-grinders",
                    active: true,
                    meta: {
                      tags: "Mixer Grinders",
                      keywords: "Mixer Grinders",
                      description: "Mixer Grinders",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db00",
                    },
                    slug: "outdoor-cooking",
                    title: "Outdoor Cooking",
                    id: 14000122,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/outdoor-cooking",
                    active: true,
                    meta: {
                      tags: "Outdoor Cooking",
                      keywords: "Outdoor Cooking",
                      description: "Outdoor Cooking",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51db01",
                        },
                        slug: "barbeque-grills",
                        title: "Barbeque Grills",
                        id: 14000123,
                        parent:
                          "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/outdoor-cooking",
                        path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/outdoor-cooking/barbeque-grills",
                        active: true,
                        meta: {
                          tags: "Barbeque Grills",
                          keywords: "Barbeque Grills",
                          description: "Barbeque Grills",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db02",
                    },
                    slug: "portable-soda-makers",
                    title: "Portable Soda Makers",
                    id: 14000124,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/portable-soda-makers",
                    active: true,
                    meta: {
                      tags: "Portable Soda Makers",
                      keywords: "Portable Soda Makers",
                      description: "Portable Soda Makers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db03",
                    },
                    slug: "toasters-and-sandwitch-makers",
                    title: "Toasters and Sandwitch Makers",
                    id: 14000125,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/toasters-and-sandwitch-makers",
                    active: true,
                    meta: {
                      tags: "Toasters and Sandwitch Makers",
                      keywords: "Toasters and Sandwitch Makers",
                      description: "Toasters and Sandwitch Makers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db04",
                    },
                    slug: "vegetable-and-fruit-cleaners",
                    title: "Vegetable and Fruit Cleaners",
                    id: 14000126,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/vegetable-and-fruit-cleaners",
                    active: true,
                    meta: {
                      tags: "Vegetable and Fruit Cleaners",
                      keywords: "Vegetable and Fruit Cleaners",
                      description: "Vegetable and Fruit Cleaners",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db05",
                    },
                    slug: "waffle-makers",
                    title: "Waffle Makers",
                    id: 14000127,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/waffle-makers",
                    active: true,
                    meta: {
                      tags: "Waffle Makers",
                      keywords: "Waffle Makers",
                      description: "Waffle Makers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db06",
                    },
                    slug: "wine-fridge",
                    title: "Wine Fridge",
                    id: 14000128,
                    parent:
                      "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances",
                    path: "/home-property-improvement/home-appliances/kitchen-appliances/small-kitchen-appliances/wine-fridge",
                    active: true,
                    meta: {
                      tags: "Wine Fridge",
                      keywords: "Wine Fridge",
                      description: "Wine Fridge",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dae1",
            },
            slug: "beauty-appliances",
            title: "Beauty Appliances",
            id: 14000090,
            parent: "/home-property-improvement/home-appliances",
            path: "/home-property-improvement/home-appliances/beauty-appliances",
            active: true,
            meta: {
              tags: "Beauty Appliances",
              keywords: "Beauty Appliances",
              description: "Beauty Appliances",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db07",
            },
            slug: "large-home-appliances",
            title: "Large Home Appliances",
            id: 14000129,
            parent: "/home-property-improvement/home-appliances",
            path: "/home-property-improvement/home-appliances/large-home-appliances",
            active: true,
            meta: {
              tags: "Large Home Appliances",
              keywords: "Large Home Appliances",
              description: "Large Home Appliances",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51db08",
                },
                slug: "air-coolers",
                title: "Air Coolers",
                id: 14000130,
                parent:
                  "/home-property-improvement/home-appliances/large-home-appliances",
                path: "/home-property-improvement/home-appliances/large-home-appliances/air-coolers",
                active: true,
                meta: {
                  tags: "Air Coolers",
                  keywords: "Air Coolers",
                  description: "Air Coolers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db09",
                    },
                    slug: "desert-air-coolers",
                    title: "Desert Air Coolers",
                    id: 14000131,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/air-coolers",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/air-coolers/desert-air-coolers",
                    active: true,
                    meta: {
                      tags: "Desert Air Coolers",
                      keywords: "Desert Air Coolers",
                      description: "Desert Air Coolers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db0a",
                },
                slug: "dishwashers",
                title: "Dishwashers",
                id: 14000132,
                parent:
                  "/home-property-improvement/home-appliances/large-home-appliances",
                path: "/home-property-improvement/home-appliances/large-home-appliances/dishwashers",
                active: true,
                meta: {
                  tags: "Dishwashers",
                  keywords: "Dishwashers",
                  description: "Dishwashers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db0b",
                    },
                    slug: "compact-dishwashers",
                    title: "Compact Dishwashers",
                    id: 14000133,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/dishwashers",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/dishwashers/compact-dishwashers",
                    active: true,
                    meta: {
                      tags: "Compact Dishwashers",
                      keywords: "Compact Dishwashers",
                      description: "Compact Dishwashers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db0c",
                },
                slug: "electric-tumble-dryer",
                title: "Electric Tumble Dryer",
                id: 14000134,
                parent:
                  "/home-property-improvement/home-appliances/large-home-appliances",
                path: "/home-property-improvement/home-appliances/large-home-appliances/electric-tumble-dryer",
                active: true,
                meta: {
                  tags: "Electric Tumble Dryer",
                  keywords: "Electric Tumble Dryer",
                  description: "Electric Tumble Dryer",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db0d",
                },
                slug: "freezer",
                title: "Freezer",
                id: 14000135,
                parent:
                  "/home-property-improvement/home-appliances/large-home-appliances",
                path: "/home-property-improvement/home-appliances/large-home-appliances/freezer",
                active: true,
                meta: {
                  tags: "Freezer",
                  keywords: "Freezer",
                  description: "Freezer",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db0e",
                },
                slug: "refrigerators",
                title: "Refrigerators",
                id: 14000136,
                parent:
                  "/home-property-improvement/home-appliances/large-home-appliances",
                path: "/home-property-improvement/home-appliances/large-home-appliances/refrigerators",
                active: true,
                meta: {
                  tags: "Refrigerators",
                  keywords: "Refrigerators",
                  description: "Refrigerators",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db0f",
                    },
                    slug: "bottom-freezer-refrigerators",
                    title: "Bottom Freezer Refrigerators",
                    id: 14000137,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/refrigerators",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/refrigerators/bottom-freezer-refrigerators",
                    active: true,
                    meta: {
                      tags: "Bottom Freezer Refrigerators",
                      keywords: "Bottom Freezer Refrigerators",
                      description: "Bottom Freezer Refrigerators",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db10",
                    },
                    slug: "built-in-refrigerators",
                    title: "Built-in Refrigerators",
                    id: 14000138,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/refrigerators",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/refrigerators/built-in-refrigerators",
                    active: true,
                    meta: {
                      tags: "Built-in Refrigerators",
                      keywords: "Built-in Refrigerators",
                      description: "Built-in Refrigerators",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db11",
                    },
                    slug: "french-door-refrigerators",
                    title: "French-door Refrigerators",
                    id: 14000139,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/refrigerators",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/refrigerators/french-door-refrigerators",
                    active: true,
                    meta: {
                      tags: "French-door Refrigerators",
                      keywords: "French-door Refrigerators",
                      description: "French-door Refrigerators",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db12",
                    },
                    slug: "side-by-side-refrigerators",
                    title: "Side by Side Refrigerators",
                    id: 14000140,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/refrigerators",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/refrigerators/side-by-side-refrigerators",
                    active: true,
                    meta: {
                      tags: "Side by Side Refrigerators",
                      keywords: "Side by Side Refrigerators",
                      description: "Side by Side Refrigerators",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db13",
                    },
                    slug: "smart-refrigerators",
                    title: "Smart Refrigerators",
                    id: 14000141,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/refrigerators",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/refrigerators/smart-refrigerators",
                    active: true,
                    meta: {
                      tags: "Smart Refrigerators",
                      keywords: "Smart Refrigerators",
                      description: "Smart Refrigerators",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db14",
                },
                slug: "residential-hvac",
                title: "Residential HVAC",
                id: 14000142,
                parent:
                  "/home-property-improvement/home-appliances/large-home-appliances",
                path: "/home-property-improvement/home-appliances/large-home-appliances/residential-hvac",
                active: true,
                meta: {
                  tags: "Residential HVAC",
                  keywords: "Residential HVAC",
                  description: "Residential HVAC",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db15",
                    },
                    slug: "air-conditioner",
                    title: "Air Conditioner",
                    id: 14000143,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/residential-hvac",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/residential-hvac/air-conditioner",
                    active: true,
                    meta: {
                      tags: "Air Conditioner",
                      keywords: "Air Conditioner",
                      description: "Air Conditioner",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51db16",
                        },
                        slug: "portable-air-conditioner",
                        title: "Portable Air Conditioner",
                        id: 14000144,
                        parent:
                          "/home-property-improvement/home-appliances/large-home-appliances/residential-hvac/air-conditioner",
                        path: "/home-property-improvement/home-appliances/large-home-appliances/residential-hvac/air-conditioner/portable-air-conditioner",
                        active: true,
                        meta: {
                          tags: "Portable Air Conditioner",
                          keywords: "Portable Air Conditioner",
                          description: "Portable Air Conditioner",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db17",
                    },
                    slug: "home-ventilation-systems",
                    title: "Home Ventilation Systems",
                    id: 14000145,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/residential-hvac",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/residential-hvac/home-ventilation-systems",
                    active: true,
                    meta: {
                      tags: "Home Ventilation Systems",
                      keywords: "Home Ventilation Systems",
                      description: "Home Ventilation Systems",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db18",
                },
                slug: "washing-machines",
                title: "Washing Machines",
                id: 14000146,
                parent:
                  "/home-property-improvement/home-appliances/large-home-appliances",
                path: "/home-property-improvement/home-appliances/large-home-appliances/washing-machines",
                active: true,
                meta: {
                  tags: "Washing Machines",
                  keywords: "Washing Machines",
                  description: "Washing Machines",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db19",
                    },
                    slug: "portable-washing-machines",
                    title: "Portable Washing Machines",
                    id: 14000147,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/washing-machines",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/washing-machines/portable-washing-machines",
                    active: true,
                    meta: {
                      tags: "Portable Washing Machines",
                      keywords: "Portable Washing Machines",
                      description: "Portable Washing Machines",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db1a",
                    },
                    slug: "smart-washing-machines",
                    title: "Smart Washing Machines",
                    id: 14000148,
                    parent:
                      "/home-property-improvement/home-appliances/large-home-appliances/washing-machines",
                    path: "/home-property-improvement/home-appliances/large-home-appliances/washing-machines/smart-washing-machines",
                    active: true,
                    meta: {
                      tags: "Smart Washing Machines",
                      keywords: "Smart Washing Machines",
                      description: "Smart Washing Machines",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db1b",
            },
            slug: "luxury-home-appliances",
            title: "Luxury Home Appliances",
            id: 14000150,
            parent: "/home-property-improvement/home-appliances",
            path: "/home-property-improvement/home-appliances/luxury-home-appliances",
            active: true,
            meta: {
              tags: "Luxury Home Appliances",
              keywords: "Luxury Home Appliances",
              description: "Luxury Home Appliances",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db1c",
            },
            slug: "small-home-appliances",
            title: "Small Home Appliances",
            id: 14000151,
            parent: "/home-property-improvement/home-appliances",
            path: "/home-property-improvement/home-appliances/small-home-appliances",
            active: true,
            meta: {
              tags: "Small Home Appliances",
              keywords: "Small Home Appliances",
              description: "Small Home Appliances",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51db1d",
                },
                slug: "air-treatment-devices",
                title: "Air Treatment Devices",
                id: 14000152,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/air-treatment-devices",
                active: true,
                meta: {
                  tags: "Air Treatment Devices",
                  keywords: "Air Treatment Devices",
                  description: "Air Treatment Devices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db1e",
                    },
                    slug: "air-purifiers",
                    title: "Air Purifiers",
                    id: 14000153,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/air-treatment-devices",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/air-treatment-devices/air-purifiers",
                    active: true,
                    meta: {
                      tags: "Air Purifiers",
                      keywords: "Air Purifiers",
                      description: "Air Purifiers",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51db1f",
                        },
                        slug: "smart-air-purifiers",
                        title: "Smart Air Purifiers",
                        id: 14000154,
                        parent:
                          "/home-property-improvement/home-appliances/small-home-appliances/air-treatment-devices/air-purifiers",
                        path: "/home-property-improvement/home-appliances/small-home-appliances/air-treatment-devices/air-purifiers/smart-air-purifiers",
                        active: true,
                        meta: {
                          tags: "Smart Air Purifiers",
                          keywords: "Smart Air Purifiers",
                          description: "Smart Air Purifiers",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db20",
                    },
                    slug: "humidifier",
                    title: "Humidifier",
                    id: 14000155,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/air-treatment-devices",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/air-treatment-devices/humidifier",
                    active: true,
                    meta: {
                      tags: "Humidifier",
                      keywords: "Humidifier",
                      description: "Humidifier",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db21",
                    },
                    slug: "portable-air-treatment-devices",
                    title: "Portable Air Treatment Devices",
                    id: 14000156,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/air-treatment-devices",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/air-treatment-devices/portable-air-treatment-devices",
                    active: true,
                    meta: {
                      tags: "Portable Air Treatment Devices",
                      keywords: "Portable Air Treatment Devices",
                      description: "Portable Air Treatment Devices",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db22",
                },
                slug: "beauty-fridges",
                title: "Beauty Fridges",
                id: 14000157,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/beauty-fridges",
                active: true,
                meta: {
                  tags: "Beauty Fridges",
                  keywords: "Beauty Fridges",
                  description: "Beauty Fridges",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db23",
                },
                slug: "countertop-ice-makers",
                title: "Countertop Ice Makers",
                id: 14000158,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/countertop-ice-makers",
                active: true,
                meta: {
                  tags: "Countertop Ice Makers",
                  keywords: "Countertop Ice Makers",
                  description: "Countertop Ice Makers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db24",
                },
                slug: "electric-fireplace",
                title: "Electric Fireplace",
                id: 14000159,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/electric-fireplace",
                active: true,
                meta: {
                  tags: "Electric Fireplace",
                  keywords: "Electric Fireplace",
                  description: "Electric Fireplace",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db25",
                },
                slug: "electric-room-heaters",
                title: "Electric Room Heaters",
                id: 14000160,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/electric-room-heaters",
                active: true,
                meta: {
                  tags: "Electric Room Heaters",
                  keywords: "Electric Room Heaters",
                  description: "Electric Room Heaters",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db26",
                },
                slug: "electric-travel-coolers",
                title: "Electric Travel Coolers",
                id: 14000161,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/electric-travel-coolers",
                active: true,
                meta: {
                  tags: "Electric Travel Coolers",
                  keywords: "Electric Travel Coolers",
                  description: "Electric Travel Coolers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db27",
                },
                slug: "fans",
                title: "Fans",
                id: 14000162,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/fans",
                active: true,
                meta: {
                  tags: "Fans",
                  keywords: "Fans",
                  description: "Fans",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db28",
                    },
                    slug: "ceiling-fans",
                    title: "Ceiling Fans",
                    id: 14000163,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/fans",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/fans/ceiling-fans",
                    active: true,
                    meta: {
                      tags: "Ceiling Fans",
                      keywords: "Ceiling Fans",
                      description: "Ceiling Fans",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db29",
                    },
                    slug: "tower-fans",
                    title: "Tower Fans",
                    id: 14000164,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/fans",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/fans/tower-fans",
                    active: true,
                    meta: {
                      tags: "Tower Fans",
                      keywords: "Tower Fans",
                      description: "Tower Fans",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db2a",
                },
                slug: "food-waste-disposers",
                title: "Food Waste Disposers",
                id: 14000165,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/food-waste-disposers",
                active: true,
                meta: {
                  tags: "Food Waste Disposers",
                  keywords: "Food Waste Disposers",
                  description: "Food Waste Disposers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db2b",
                },
                slug: "garment-care-appliances",
                title: "Garment Care Appliances",
                id: 14000166,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/garment-care-appliances",
                active: true,
                meta: {
                  tags: "Garment Care Appliances",
                  keywords: "Garment Care Appliances",
                  description: "Garment Care Appliances",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db2c",
                    },
                    slug: "fabric-shavers",
                    title: "Fabric Shavers",
                    id: 14000167,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/garment-care-appliances",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/garment-care-appliances/fabric-shavers",
                    active: true,
                    meta: {
                      tags: "Fabric Shavers",
                      keywords: "Fabric Shavers",
                      description: "Fabric Shavers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db2d",
                    },
                    slug: "garment-steamers",
                    title: "Garment Steamers",
                    id: 14000168,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/garment-care-appliances",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/garment-care-appliances/garment-steamers",
                    active: true,
                    meta: {
                      tags: "Garment Steamers",
                      keywords: "Garment Steamers",
                      description: "Garment Steamers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db2e",
                },
                slug: "hand-dryers",
                title: "Hand Dryers",
                id: 14000169,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/hand-dryers",
                active: true,
                meta: {
                  tags: "Hand Dryers",
                  keywords: "Hand Dryers",
                  description: "Hand Dryers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db2f",
                },
                slug: "mini-beverage-coolers",
                title: "Mini Beverage Coolers",
                id: 14000170,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/mini-beverage-coolers",
                active: true,
                meta: {
                  tags: "Mini Beverage Coolers",
                  keywords: "Mini Beverage Coolers",
                  description: "Mini Beverage Coolers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db30",
                },
                slug: "mini-refrigerators",
                title: "Mini Refrigerators",
                id: 14000171,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/mini-refrigerators",
                active: true,
                meta: {
                  tags: "Mini Refrigerators",
                  keywords: "Mini Refrigerators",
                  description: "Mini Refrigerators",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db31",
                },
                slug: "sewing-machines",
                title: "Sewing Machines",
                id: 14000172,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/sewing-machines",
                active: true,
                meta: {
                  tags: "Sewing Machines",
                  keywords: "Sewing Machines",
                  description: "Sewing Machines",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db32",
                },
                slug: "vacuum-cleaners",
                title: "Vacuum Cleaners",
                id: 14000173,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/vacuum-cleaners",
                active: true,
                meta: {
                  tags: "Vacuum Cleaners",
                  keywords: "Vacuum Cleaners",
                  description: "Vacuum Cleaners",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db33",
                    },
                    slug: "cordless-vacuum-cleaners",
                    title: "Cordless Vacuum Cleaners",
                    id: 14000174,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/vacuum-cleaners",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/vacuum-cleaners/cordless-vacuum-cleaners",
                    active: true,
                    meta: {
                      tags: "Cordless Vacuum Cleaners",
                      keywords: "Cordless Vacuum Cleaners",
                      description: "Cordless Vacuum Cleaners",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db34",
                    },
                    slug: "handheld-vacuum-cleaners",
                    title: "Handheld Vacuum Cleaners",
                    id: 14000175,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/vacuum-cleaners",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/vacuum-cleaners/handheld-vacuum-cleaners",
                    active: true,
                    meta: {
                      tags: "Handheld Vacuum Cleaners",
                      keywords: "Handheld Vacuum Cleaners",
                      description: "Handheld Vacuum Cleaners",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db35",
                    },
                    slug: "residential-robotic-vacuum-cleaners",
                    title: "Residential Robotic Vacuum Cleaners",
                    id: 14000176,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/vacuum-cleaners",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/vacuum-cleaners/residential-robotic-vacuum-cleaners",
                    active: true,
                    meta: {
                      tags: "Residential Robotic Vacuum Cleaners",
                      keywords: "Residential Robotic Vacuum Cleaners",
                      description: "Residential Robotic Vacuum Cleaners",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db36",
                },
                slug: "water-heaters",
                title: "Water Heaters",
                id: 14000177,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/water-heaters",
                active: true,
                meta: {
                  tags: "Water Heaters",
                  keywords: "Water Heaters",
                  description: "Water Heaters",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db37",
                    },
                    slug: "electric-water-heaters",
                    title: "Electric Water Heaters",
                    id: 14000178,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/water-heaters",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/water-heaters/electric-water-heaters",
                    active: true,
                    meta: {
                      tags: "Electric Water Heaters",
                      keywords: "Electric Water Heaters",
                      description: "Electric Water Heaters",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db38",
                },
                slug: "water-treatment-devices",
                title: "Water Treatment Devices",
                id: 14000179,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/water-treatment-devices",
                active: true,
                meta: {
                  tags: "Water Treatment Devices",
                  keywords: "Water Treatment Devices",
                  description: "Water Treatment Devices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db39",
                    },
                    slug: "water-purifier",
                    title: "Water Purifier",
                    id: 14000180,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/water-treatment-devices",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/water-treatment-devices/water-purifier",
                    active: true,
                    meta: {
                      tags: "Water Purifier",
                      keywords: "Water Purifier",
                      description: "Water Purifier",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db3a",
                    },
                    slug: "water-softener",
                    title: "Water Softener",
                    id: 14000181,
                    parent:
                      "/home-property-improvement/home-appliances/small-home-appliances/water-treatment-devices",
                    path: "/home-property-improvement/home-appliances/small-home-appliances/water-treatment-devices/water-softener",
                    active: true,
                    meta: {
                      tags: "Water Softener",
                      keywords: "Water Softener",
                      description: "Water Softener",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db3b",
                },
                slug: "wine-coolers",
                title: "Wine Coolers",
                id: 14000182,
                parent:
                  "/home-property-improvement/home-appliances/small-home-appliances",
                path: "/home-property-improvement/home-appliances/small-home-appliances/wine-coolers",
                active: true,
                meta: {
                  tags: "Wine Coolers",
                  keywords: "Wine Coolers",
                  description: "Wine Coolers",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b13c",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Laundry Appliances market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Laundry Appliances Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Laundry Appliances market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Laundry Appliances Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Laundry Appliances Reports Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Laundry Appliances Market Research Reports, laundry appliances market growth, laundry appliances market analysis, laundry appliances industry analysis, laundry appliances market report, laundry appliances industry report, laundry appliances industry analysis, laundry appliances industry forecast, laundry appliances industry, laundry appliances market",
          description:
            "Mordor Intelligence™ 【  Laundry Appliances Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Laundry Appliances",
        slug: "laundry-appliances",
        id: 80215,
        __v: 0,
        parent: "/home-property-improvement",
        path: "/home-property-improvement/laundry-appliances",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b13d",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Home Decor market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Home Decor Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Home Decor market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Home Decor Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Home Decor Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Home Decor Market Research Reports, home decor, home decor industry, home decor industry report, home decor market report, home decor market, home decor industry analysis, home decor industry growth, home decor market research, home decor market intelligence report",
          description:
            "Mordor Intelligence™ 【  Home Decor Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Home Decor",
        slug: "home-decor",
        id: 80216,
        __v: 0,
        parent: "/home-property-improvement",
        path: "/home-property-improvement/home-decor",
        sub_categories: [
          {
            _id: {
              $oid: "64c253a073d43befcf51db3c",
            },
            slug: "artificial-grass-and-turf",
            title: "Artificial Grass and Turf",
            id: 14000184,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/artificial-grass-and-turf",
            active: true,
            meta: {
              tags: "Artificial Grass and Turf",
              keywords: "Artificial Grass and Turf",
              description: "Artificial Grass and Turf",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db3d",
            },
            slug: "awnings",
            title: "Awnings",
            id: 14000185,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/awnings",
            active: true,
            meta: {
              tags: "Awnings",
              keywords: "Awnings",
              description: "Awnings",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db3e",
            },
            slug: "decorative-lighting",
            title: "Decorative Lighting",
            id: 14000186,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/decorative-lighting",
            active: true,
            meta: {
              tags: "Decorative Lighting",
              keywords: "Decorative Lighting",
              description: "Decorative Lighting",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db3f",
            },
            slug: "doors-and-windows",
            title: "Doors and Windows",
            id: 14000187,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/doors-and-windows",
            active: true,
            meta: {
              tags: "Doors and Windows",
              keywords: "Doors and Windows",
              description: "Doors and Windows",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51db40",
                },
                slug: "upvc-doors-and-windows",
                title: "UPVC Doors and Windows",
                id: 14000188,
                parent:
                  "/home-property-improvement/home-decor/doors-and-windows",
                path: "/home-property-improvement/home-decor/doors-and-windows/upvc-doors-and-windows",
                active: true,
                meta: {
                  tags: "UPVC Doors and Windows",
                  keywords: "UPVC Doors and Windows",
                  description: "UPVC Doors and Windows",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db41",
            },
            slug: "flower-pots",
            title: "Flower Pots",
            id: 14000189,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/flower-pots",
            active: true,
            meta: {
              tags: "Flower Pots",
              keywords: "Flower Pots",
              description: "Flower Pots",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db42",
            },
            slug: "gutter-guards",
            title: "Gutter Guards",
            id: 14000190,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/gutter-guards",
            active: true,
            meta: {
              tags: "Gutter Guards",
              keywords: "Gutter Guards",
              description: "Gutter Guards",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db43",
            },
            slug: "home-improvement",
            title: "Home Improvement",
            id: 14000191,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/home-improvement",
            active: true,
            meta: {
              tags: "Home Improvement",
              keywords: "Home Improvement",
              description: "Home Improvement",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51db44",
                },
                slug: "diy-home-improvement",
                title: "DIY Home Improvement",
                id: 14000192,
                parent:
                  "/home-property-improvement/home-decor/home-improvement",
                path: "/home-property-improvement/home-decor/home-improvement/diy-home-improvement",
                active: true,
                meta: {
                  tags: "DIY Home Improvement",
                  keywords: "DIY Home Improvement",
                  description: "DIY Home Improvement",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db45",
            },
            slug: "homeware",
            title: "Homeware",
            id: 14000193,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/homeware",
            active: true,
            meta: {
              tags: "Homeware",
              keywords: "Homeware",
              description: "Homeware",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db46",
            },
            slug: "kitchenware",
            title: "Kitchenware",
            id: 14000194,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/kitchenware",
            active: true,
            meta: {
              tags: "Kitchenware",
              keywords: "Kitchenware",
              description: "Kitchenware",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51db47",
                },
                slug: "cookware",
                title: "Cookware",
                id: 14000195,
                parent: "/home-property-improvement/home-decor/kitchenware",
                path: "/home-property-improvement/home-decor/kitchenware/cookware",
                active: true,
                meta: {
                  tags: "Cookware",
                  keywords: "Cookware",
                  description: "Cookware",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51db48",
                },
                slug: "tableware",
                title: "Tableware",
                id: 14000196,
                parent: "/home-property-improvement/home-decor/kitchenware",
                path: "/home-property-improvement/home-decor/kitchenware/tableware",
                active: true,
                meta: {
                  tags: "Tableware",
                  keywords: "Tableware",
                  description: "Tableware",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db49",
                    },
                    slug: "ceramic-tableware",
                    title: "Ceramic Tableware",
                    id: 14000197,
                    parent:
                      "/home-property-improvement/home-decor/kitchenware/tableware",
                    path: "/home-property-improvement/home-decor/kitchenware/tableware/ceramic-tableware",
                    active: true,
                    meta: {
                      tags: "Ceramic Tableware",
                      keywords: "Ceramic Tableware",
                      description: "Ceramic Tableware",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51db4a",
                    },
                    slug: "disposable-tableware",
                    title: "Disposable Tableware",
                    id: 14000198,
                    parent:
                      "/home-property-improvement/home-decor/kitchenware/tableware",
                    path: "/home-property-improvement/home-decor/kitchenware/tableware/disposable-tableware",
                    active: true,
                    meta: {
                      tags: "Disposable Tableware",
                      keywords: "Disposable Tableware",
                      description: "Disposable Tableware",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db4b",
            },
            slug: "laminates",
            title: "Laminates",
            id: 14000199,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/laminates",
            active: true,
            meta: {
              tags: "Laminates",
              keywords: "Laminates",
              description: "Laminates",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51db4c",
            },
            slug: "side-decking",
            title: "Side Decking",
            id: 14000200,
            parent: "/home-property-improvement/home-decor",
            path: "/home-property-improvement/home-decor/side-decking",
            active: true,
            meta: {
              tags: "Side Decking",
              keywords: "Side Decking",
              description: "Side Decking",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b13e",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Furniture and Furnishings market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Furniture and Furnishings Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Furniture and Furnishings market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Furniture and Furnishings Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Furniture and Furnishings Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Furniture and Furnishings Market Research Reports, Furniture and Furnishings Market, Furniture and Furnishings industry, Furniture and Furnishings Market Research, Furniture and Furnishings Industry research, Furniture and Furnishings Market research report, Furniture and Furnishings industry research report",
          description:
            "Mordor Intelligence™ 【  Furniture and Furnishings Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Furniture and Furnishings",
        slug: "furniture-and-furnishings",
        id: 80217,
        __v: 0,
        parent: "/home-property-improvement",
        path: "/home-property-improvement/furniture-and-furnishings",
        sub_categories: [
          {
            _id: {
              $oid: "64c253a073d43befcf51daae",
            },
            slug: "commercial-furniture",
            title: "Commercial Furniture",
            id: 14000038,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/commercial-furniture",
            active: true,
            meta: {
              tags: "Commercial Furniture",
              keywords: "Commercial Furniture",
              description: "Commercial Furniture",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51daaf",
                },
                slug: "restaurant-furniture",
                title: "Restaurant Furniture",
                id: 14000039,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/commercial-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/commercial-furniture/restaurant-furniture",
                active: true,
                meta: {
                  tags: "Restaurant Furniture",
                  keywords: "Restaurant Furniture",
                  description: "Restaurant Furniture",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dab0",
            },
            slug: "folding-furniture",
            title: "Folding Furniture",
            id: 14000040,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/folding-furniture",
            active: true,
            meta: {
              tags: "Folding Furniture",
              keywords: "Folding Furniture",
              description: "Folding Furniture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dab1",
            },
            slug: "high-end-furniture",
            title: "High-end Furniture",
            id: 14000041,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/high-end-furniture",
            active: true,
            meta: {
              tags: "High-end Furniture",
              keywords: "High-end Furniture",
              description: "High-end Furniture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dab2",
            },
            slug: "home-furniture",
            title: "Home Furniture",
            id: 14000042,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/home-furniture",
            active: true,
            meta: {
              tags: "Home Furniture",
              keywords: "Home Furniture",
              description: "Home Furniture",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51dab3",
                },
                slug: "bathroom-furniture",
                title: "Bathroom Furniture",
                id: 14000043,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/home-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/home-furniture/bathroom-furniture",
                active: true,
                meta: {
                  tags: "Bathroom Furniture",
                  keywords: "Bathroom Furniture",
                  description: "Bathroom Furniture",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51dab4",
                },
                slug: "bedroom-furniture",
                title: "Bedroom Furniture",
                id: 14000044,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/home-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/home-furniture/bedroom-furniture",
                active: true,
                meta: {
                  tags: "Bedroom Furniture",
                  keywords: "Bedroom Furniture",
                  description: "Bedroom Furniture",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dab5",
                    },
                    slug: "beds",
                    title: "Beds",
                    id: 14000045,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-furniture/bedroom-furniture",
                    path: "/home-property-improvement/furniture-and-furnishings/home-furniture/bedroom-furniture/beds",
                    active: true,
                    meta: {
                      tags: "Beds",
                      keywords: "Beds",
                      description: "Beds",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dab6",
                        },
                        slug: "smart-beds",
                        title: "Smart Beds",
                        id: 14000046,
                        parent:
                          "/home-property-improvement/furniture-and-furnishings/home-furniture/bedroom-furniture/beds",
                        path: "/home-property-improvement/furniture-and-furnishings/home-furniture/bedroom-furniture/beds/smart-beds",
                        active: true,
                        meta: {
                          tags: "Smart Beds",
                          keywords: "Smart Beds",
                          description: "Smart Beds",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dab7",
                    },
                    slug: "mattresses",
                    title: "Mattresses",
                    id: 14000047,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-furniture/bedroom-furniture",
                    path: "/home-property-improvement/furniture-and-furnishings/home-furniture/bedroom-furniture/mattresses",
                    active: true,
                    meta: {
                      tags: "Mattresses",
                      keywords: "Mattresses",
                      description: "Mattresses",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dab8",
                    },
                    slug: "wardrobes-and-closets",
                    title: "Wardrobes and Closets",
                    id: 14000048,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-furniture/bedroom-furniture",
                    path: "/home-property-improvement/furniture-and-furnishings/home-furniture/bedroom-furniture/wardrobes-and-closets",
                    active: true,
                    meta: {
                      tags: "Wardrobes and Closets",
                      keywords: "Wardrobes and Closets",
                      description: "Wardrobes and Closets",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51dab9",
                },
                slug: "home-office-furniture",
                title: "Home Office Furniture",
                id: 14000049,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/home-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/home-furniture/home-office-furniture",
                active: true,
                meta: {
                  tags: "Home Office Furniture",
                  keywords: "Home Office Furniture",
                  description: "Home Office Furniture",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51daba",
                },
                slug: "kitchen-furniture",
                title: "Kitchen Furniture",
                id: 14000050,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/home-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/home-furniture/kitchen-furniture",
                active: true,
                meta: {
                  tags: "Kitchen Furniture",
                  keywords: "Kitchen Furniture",
                  description: "Kitchen Furniture",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dabb",
                    },
                    slug: "countertops",
                    title: "Countertops",
                    id: 14000051,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-furniture/kitchen-furniture",
                    path: "/home-property-improvement/furniture-and-furnishings/home-furniture/kitchen-furniture/countertops",
                    active: true,
                    meta: {
                      tags: "Countertops",
                      keywords: "Countertops",
                      description: "Countertops",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dabc",
                    },
                    slug: "kitchen-cabinets",
                    title: "Kitchen Cabinets",
                    id: 14000052,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-furniture/kitchen-furniture",
                    path: "/home-property-improvement/furniture-and-furnishings/home-furniture/kitchen-furniture/kitchen-cabinets",
                    active: true,
                    meta: {
                      tags: "Kitchen Cabinets",
                      keywords: "Kitchen Cabinets",
                      description: "Kitchen Cabinets",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dabd",
                    },
                    slug: "modular-kitchen",
                    title: "Modular Kitchen",
                    id: 14000053,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-furniture/kitchen-furniture",
                    path: "/home-property-improvement/furniture-and-furnishings/home-furniture/kitchen-furniture/modular-kitchen",
                    active: true,
                    meta: {
                      tags: "Modular Kitchen",
                      keywords: "Modular Kitchen",
                      description: "Modular Kitchen",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51dabe",
                },
                slug: "living-and-dining-room-furniture",
                title: "Living and Dining Room Furniture",
                id: 14000054,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/home-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/home-furniture/living-and-dining-room-furniture",
                active: true,
                meta: {
                  tags: "Living and Dining Room Furniture",
                  keywords: "Living and Dining Room Furniture",
                  description: "Living and Dining Room Furniture",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dabf",
                    },
                    slug: "gaming-chair",
                    title: "Gaming Chair",
                    id: 14000055,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-furniture/living-and-dining-room-furniture",
                    path: "/home-property-improvement/furniture-and-furnishings/home-furniture/living-and-dining-room-furniture/gaming-chair",
                    active: true,
                    meta: {
                      tags: "Gaming Chair",
                      keywords: "Gaming Chair",
                      description: "Gaming Chair",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51dac0",
                },
                slug: "outdoor-furniture",
                title: "Outdoor Furniture",
                id: 14000056,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/home-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/home-furniture/outdoor-furniture",
                active: true,
                meta: {
                  tags: "Outdoor Furniture",
                  keywords: "Outdoor Furniture",
                  description: "Outdoor Furniture",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dac1",
            },
            slug: "home-textiles",
            title: "Home Textiles",
            id: 14000057,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/home-textiles",
            active: true,
            meta: {
              tags: "Home Textiles",
              keywords: "Home Textiles",
              description: "Home Textiles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51dac2",
                },
                slug: "bed-and-bath-linen",
                title: "Bed and Bath Linen",
                id: 14000058,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/home-textiles",
                path: "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen",
                active: true,
                meta: {
                  tags: "Bed and Bath Linen",
                  keywords: "Bed and Bath Linen",
                  description: "Bed and Bath Linen",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dac3",
                    },
                    slug: "bath-linen",
                    title: "Bath Linen",
                    id: 14000059,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen",
                    path: "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bath-linen",
                    active: true,
                    meta: {
                      tags: "Bath Linen",
                      keywords: "Bath Linen",
                      description: "Bath Linen",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dac4",
                        },
                        slug: "towels",
                        title: "Towels",
                        id: 14000060,
                        parent:
                          "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bath-linen",
                        path: "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bath-linen/towels",
                        active: true,
                        meta: {
                          tags: "Towels",
                          keywords: "Towels",
                          description: "Towels",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dac5",
                    },
                    slug: "bed-linen",
                    title: "Bed Linen",
                    id: 14000061,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen",
                    path: "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bed-linen",
                    active: true,
                    meta: {
                      tags: "Bed Linen",
                      keywords: "Bed Linen",
                      description: "Bed Linen",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dac6",
                        },
                        slug: "bedsheets",
                        title: "Bedsheets",
                        id: 14000062,
                        parent:
                          "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bed-linen",
                        path: "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bed-linen/bedsheets",
                        active: true,
                        meta: {
                          tags: "Bedsheets",
                          keywords: "Bedsheets",
                          description: "Bedsheets",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dac7",
                        },
                        slug: "blankets",
                        title: "Blankets",
                        id: 14000063,
                        parent:
                          "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bed-linen",
                        path: "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bed-linen/blankets",
                        active: true,
                        meta: {
                          tags: "Blankets",
                          keywords: "Blankets",
                          description: "Blankets",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dac8",
                        },
                        slug: "cushions-and-cushion-covers",
                        title: "Cushions and Cushion Covers",
                        id: 14000064,
                        parent:
                          "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bed-linen",
                        path: "/home-property-improvement/furniture-and-furnishings/home-textiles/bed-and-bath-linen/bed-linen/cushions-and-cushion-covers",
                        active: true,
                        meta: {
                          tags: "Cushions and Cushion Covers",
                          keywords: "Cushions and Cushion Covers",
                          description: "Cushions and Cushion Covers",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51dac9",
                },
                slug: "kitchen-linen",
                title: "Kitchen Linen",
                id: 14000065,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/home-textiles",
                path: "/home-property-improvement/furniture-and-furnishings/home-textiles/kitchen-linen",
                active: true,
                meta: {
                  tags: "Kitchen Linen",
                  keywords: "Kitchen Linen",
                  description: "Kitchen Linen",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daca",
                    },
                    slug: "hand-towels",
                    title: "Hand Towels",
                    id: 14000066,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-textiles/kitchen-linen",
                    path: "/home-property-improvement/furniture-and-furnishings/home-textiles/kitchen-linen/hand-towels",
                    active: true,
                    meta: {
                      tags: "Hand Towels",
                      keywords: "Hand Towels",
                      description: "Hand Towels",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51dacb",
                },
                slug: "upholstery",
                title: "Upholstery",
                id: 14000067,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/home-textiles",
                path: "/home-property-improvement/furniture-and-furnishings/home-textiles/upholstery",
                active: true,
                meta: {
                  tags: "Upholstery",
                  keywords: "Upholstery",
                  description: "Upholstery",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dacc",
                    },
                    slug: "blinds",
                    title: "Blinds",
                    id: 14000068,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-textiles/upholstery",
                    path: "/home-property-improvement/furniture-and-furnishings/home-textiles/upholstery/blinds",
                    active: true,
                    meta: {
                      tags: "Blinds",
                      keywords: "Blinds",
                      description: "Blinds",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dacd",
                    },
                    slug: "curtains",
                    title: "Curtains",
                    id: 14000069,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/home-textiles/upholstery",
                    path: "/home-property-improvement/furniture-and-furnishings/home-textiles/upholstery/curtains",
                    active: true,
                    meta: {
                      tags: "Curtains",
                      keywords: "Curtains",
                      description: "Curtains",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51dace",
                        },
                        slug: "automatic-curtains",
                        title: "Automatic Curtains",
                        id: 14000070,
                        parent:
                          "/home-property-improvement/furniture-and-furnishings/home-textiles/upholstery/curtains",
                        path: "/home-property-improvement/furniture-and-furnishings/home-textiles/upholstery/curtains/automatic-curtains",
                        active: true,
                        meta: {
                          tags: "Automatic Curtains",
                          keywords: "Automatic Curtains",
                          description: "Automatic Curtains",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dacf",
            },
            slug: "institutional-furniture",
            title: "Institutional Furniture",
            id: 14000071,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/institutional-furniture",
            active: true,
            meta: {
              tags: "Institutional Furniture",
              keywords: "Institutional Furniture",
              description: "Institutional Furniture",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51dad0",
                },
                slug: "school-furniture",
                title: "School Furniture",
                id: 14000072,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/institutional-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/institutional-furniture/school-furniture",
                active: true,
                meta: {
                  tags: "School Furniture",
                  keywords: "School Furniture",
                  description: "School Furniture",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51dad1",
                    },
                    slug: "k-12-furniture",
                    title: "K-12 Furniture",
                    id: 14000073,
                    parent:
                      "/home-property-improvement/furniture-and-furnishings/institutional-furniture/school-furniture",
                    path: "/home-property-improvement/furniture-and-furnishings/institutional-furniture/school-furniture/k-12-furniture",
                    active: true,
                    meta: {
                      tags: "K-12 Furniture",
                      keywords: "K-12 Furniture",
                      description: "K-12 Furniture",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dad2",
            },
            slug: "luxury-furniture",
            title: "Luxury Furniture",
            id: 14000074,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/luxury-furniture",
            active: true,
            meta: {
              tags: "Luxury Furniture",
              keywords: "Luxury Furniture",
              description: "Luxury Furniture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dad3",
            },
            slug: "multifunctional-furniture",
            title: "Multifunctional Furniture",
            id: 14000075,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/multifunctional-furniture",
            active: true,
            meta: {
              tags: "Multifunctional Furniture",
              keywords: "Multifunctional Furniture",
              description: "Multifunctional Furniture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dad4",
            },
            slug: "office-furniture",
            title: "Office Furniture",
            id: 14000076,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/office-furniture",
            active: true,
            meta: {
              tags: "Office Furniture",
              keywords: "Office Furniture",
              description: "Office Furniture",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51dad5",
                },
                slug: "chairs",
                title: "Chairs",
                id: 14000077,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/office-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/office-furniture/chairs",
                active: true,
                meta: {
                  tags: "Chairs",
                  keywords: "Chairs",
                  description: "Chairs",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51dad6",
                },
                slug: "contract-furniture",
                title: "Contract Furniture",
                id: 14000078,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/office-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/office-furniture/contract-furniture",
                active: true,
                meta: {
                  tags: "Contract Furniture",
                  keywords: "Contract Furniture",
                  description: "Contract Furniture",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51dad7",
                },
                slug: "storage-units",
                title: "Storage Units",
                id: 14000079,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/office-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/office-furniture/storage-units",
                active: true,
                meta: {
                  tags: "Storage Units",
                  keywords: "Storage Units",
                  description: "Storage Units",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51dad8",
                },
                slug: "tables",
                title: "Tables",
                id: 14000080,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/office-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/office-furniture/tables",
                active: true,
                meta: {
                  tags: "Tables",
                  keywords: "Tables",
                  description: "Tables",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dad9",
            },
            slug: "plastic-furniture",
            title: "Plastic Furniture",
            id: 14000081,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/plastic-furniture",
            active: true,
            meta: {
              tags: "Plastic Furniture",
              keywords: "Plastic Furniture",
              description: "Plastic Furniture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dada",
            },
            slug: "rattan-furniture",
            title: "Rattan Furniture",
            id: 14000082,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/rattan-furniture",
            active: true,
            meta: {
              tags: "Rattan Furniture",
              keywords: "Rattan Furniture",
              description: "Rattan Furniture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dadb",
            },
            slug: "rta-furniture",
            title: "RTA Furniture",
            id: 14000083,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/rta-furniture",
            active: true,
            meta: {
              tags: "RTA Furniture",
              keywords: "RTA Furniture",
              description: "RTA Furniture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dadc",
            },
            slug: "smart-furniture",
            title: "Smart Furniture",
            id: 14000084,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/smart-furniture",
            active: true,
            meta: {
              tags: "Smart Furniture",
              keywords: "Smart Furniture",
              description: "Smart Furniture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dadd",
            },
            slug: "sustainable-furniture",
            title: "Sustainable Furniture",
            id: 14000085,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/sustainable-furniture",
            active: true,
            meta: {
              tags: "Sustainable Furniture",
              keywords: "Sustainable Furniture",
              description: "Sustainable Furniture",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51dade",
                },
                slug: "sustainable-office-furniture",
                title: "Sustainable Office Furniture",
                id: 14000086,
                parent:
                  "/home-property-improvement/furniture-and-furnishings/sustainable-furniture",
                path: "/home-property-improvement/furniture-and-furnishings/sustainable-furniture/sustainable-office-furniture",
                active: true,
                meta: {
                  tags: "Sustainable Office Furniture",
                  keywords: "Sustainable Office Furniture",
                  description: "Sustainable Office Furniture",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dadf",
            },
            slug: "upholstered-furniture",
            title: "Upholstered Furniture",
            id: 14000087,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/upholstered-furniture",
            active: true,
            meta: {
              tags: "Upholstered Furniture",
              keywords: "Upholstered Furniture",
              description: "Upholstered Furniture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51dae0",
            },
            slug: "wood-furniture",
            title: "Wood Furniture",
            id: 14000088,
            parent: "/home-property-improvement/furniture-and-furnishings",
            path: "/home-property-improvement/furniture-and-furnishings/wood-furniture",
            active: true,
            meta: {
              tags: "Wood Furniture",
              keywords: "Wood Furniture",
              description: "Wood Furniture",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64c253a073d43befcf51da8a",
        },
        slug: "bathroom-fittings",
        title: "Bathroom Fittings",
        id: 80688,
        parent: "/home-property-improvement",
        path: "/home-property-improvement/bathroom-fittings",
        active: true,
        meta: {
          tags: "Bathroom Fittings",
          keywords: "Bathroom Fittings",
          description: "Bathroom Fittings",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c253a073d43befcf51da8b",
            },
            slug: "bathroom-enclosures",
            title: "Bathroom Enclosures",
            id: 14000002,
            parent: "/home-property-improvement/bathroom-fittings",
            path: "/home-property-improvement/bathroom-fittings/bathroom-enclosures",
            active: true,
            meta: {
              tags: "Bathroom Enclosures",
              keywords: "Bathroom Enclosures",
              description: "Bathroom Enclosures",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51da8c",
                },
                slug: "shower-cubicle",
                title: "Shower Cubicle",
                id: 14000003,
                parent:
                  "/home-property-improvement/bathroom-fittings/bathroom-enclosures",
                path: "/home-property-improvement/bathroom-fittings/bathroom-enclosures/shower-cubicle",
                active: true,
                meta: {
                  tags: "Shower Cubicle",
                  keywords: "Shower Cubicle",
                  description: "Shower Cubicle",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51da8d",
                },
                slug: "steam-rooms",
                title: "Steam Rooms",
                id: 14000004,
                parent:
                  "/home-property-improvement/bathroom-fittings/bathroom-enclosures",
                path: "/home-property-improvement/bathroom-fittings/bathroom-enclosures/steam-rooms",
                active: true,
                meta: {
                  tags: "Steam Rooms",
                  keywords: "Steam Rooms",
                  description: "Steam Rooms",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51da8e",
            },
            slug: "faucets",
            title: "Faucets",
            id: 14000005,
            parent: "/home-property-improvement/bathroom-fittings",
            path: "/home-property-improvement/bathroom-fittings/faucets",
            active: true,
            meta: {
              tags: "Faucets",
              keywords: "Faucets",
              description: "Faucets",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51da8f",
            },
            slug: "hot-tub",
            title: "Hot Tub",
            id: 14000006,
            parent: "/home-property-improvement/bathroom-fittings",
            path: "/home-property-improvement/bathroom-fittings/hot-tub",
            active: true,
            meta: {
              tags: "Hot Tub",
              keywords: "Hot Tub",
              description: "Hot Tub",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51da90",
            },
            slug: "sanitaryware",
            title: "Sanitaryware",
            id: 14000007,
            parent: "/home-property-improvement/bathroom-fittings",
            path: "/home-property-improvement/bathroom-fittings/sanitaryware",
            active: true,
            meta: {
              tags: "Sanitaryware",
              keywords: "Sanitaryware",
              description: "Sanitaryware",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51da91",
                },
                slug: "ceramic-sanitaryware",
                title: "Ceramic Sanitaryware",
                id: 14000008,
                parent:
                  "/home-property-improvement/bathroom-fittings/sanitaryware",
                path: "/home-property-improvement/bathroom-fittings/sanitaryware/ceramic-sanitaryware",
                active: true,
                meta: {
                  tags: "Ceramic Sanitaryware",
                  keywords: "Ceramic Sanitaryware",
                  description: "Ceramic Sanitaryware",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51da92",
                },
                slug: "concealed-cisterns",
                title: "Concealed Cisterns",
                id: 14000009,
                parent:
                  "/home-property-improvement/bathroom-fittings/sanitaryware",
                path: "/home-property-improvement/bathroom-fittings/sanitaryware/concealed-cisterns",
                active: true,
                meta: {
                  tags: "Concealed Cisterns",
                  keywords: "Concealed Cisterns",
                  description: "Concealed Cisterns",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51da93",
            },
            slug: "toilet-assist-devices",
            title: "Toilet Assist Devices",
            id: 14000010,
            parent: "/home-property-improvement/bathroom-fittings",
            path: "/home-property-improvement/bathroom-fittings/toilet-assist-devices",
            active: true,
            meta: {
              tags: "Toilet Assist Devices",
              keywords: "Toilet Assist Devices",
              description: "Toilet Assist Devices",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64c253a073d43befcf51da94",
        },
        slug: "commercial-appliances",
        title: "Commercial Appliances",
        id: 80692,
        parent: "/home-property-improvement",
        path: "/home-property-improvement/commercial-appliances",
        active: true,
        meta: {
          tags: "Commercial Appliances",
          keywords: "Commercial Appliances",
          description: "Commercial Appliances",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c253a073d43befcf51da95",
            },
            slug: "commercial-kitchen-appliances",
            title: "Commercial Kitchen Appliances",
            id: 14000012,
            parent: "/home-property-improvement/commercial-appliances",
            path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances",
            active: true,
            meta: {
              tags: "Commercial Kitchen Appliances",
              keywords: "Commercial Kitchen Appliances",
              description: "Commercial Kitchen Appliances",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51da96",
                },
                slug: "commercial-large-kitchen-appliances",
                title: "Commercial Large Kitchen Appliances",
                id: 14000013,
                parent:
                  "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances",
                path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances",
                active: true,
                meta: {
                  tags: "Commercial Large Kitchen Appliances",
                  keywords: "Commercial Large Kitchen Appliances",
                  description: "Commercial Large Kitchen Appliances",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51da97",
                    },
                    slug: "commercial-dishwasher",
                    title: "Commercial Dishwasher",
                    id: 14000014,
                    parent:
                      "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances",
                    path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances/commercial-dishwasher",
                    active: true,
                    meta: {
                      tags: "Commercial Dishwasher",
                      keywords: "Commercial Dishwasher",
                      description: "Commercial Dishwasher",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51da98",
                    },
                    slug: "commercial-microwave",
                    title: "Commercial Microwave",
                    id: 14000015,
                    parent:
                      "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances",
                    path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances/commercial-microwave",
                    active: true,
                    meta: {
                      tags: "Commercial Microwave",
                      keywords: "Commercial Microwave",
                      description: "Commercial Microwave",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51da99",
                    },
                    slug: "commercial-refrigeration",
                    title: "Commercial Refrigeration",
                    id: 14000016,
                    parent:
                      "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances",
                    path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances/commercial-refrigeration",
                    active: true,
                    meta: {
                      tags: "Commercial Refrigeration",
                      keywords: "Commercial Refrigeration",
                      description: "Commercial Refrigeration",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51da9a",
                        },
                        slug: "chest-freezer",
                        title: "Chest Freezer",
                        id: 14000017,
                        parent:
                          "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances/commercial-refrigeration",
                        path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances/commercial-refrigeration/chest-freezer",
                        active: true,
                        meta: {
                          tags: "Chest Freezer",
                          keywords: "Chest Freezer",
                          description: "Chest Freezer",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51da9b",
                        },
                        slug: "eelctric-cigar-humidors",
                        title: "Eelctric Cigar Humidors",
                        id: 14000018,
                        parent:
                          "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances/commercial-refrigeration",
                        path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances/commercial-refrigeration/eelctric-cigar-humidors",
                        active: true,
                        meta: {
                          tags: "Eelctric Cigar Humidors",
                          keywords: "Eelctric Cigar Humidors",
                          description: "Eelctric Cigar Humidors",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253a073d43befcf51da9c",
                        },
                        slug: "kegerators",
                        title: "Kegerators",
                        id: 14000019,
                        parent:
                          "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances/commercial-refrigeration",
                        path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-large-kitchen-appliances/commercial-refrigeration/kegerators",
                        active: true,
                        meta: {
                          tags: "Kegerators",
                          keywords: "Kegerators",
                          description: "Kegerators",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51da9d",
                },
                slug: "commercial-small-kitchen-appliances",
                title: "Commercial Small Kitchen Appliances",
                id: 14000020,
                parent:
                  "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances",
                path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-small-kitchen-appliances",
                active: true,
                meta: {
                  tags: "Commercial Small Kitchen Appliances",
                  keywords: "Commercial Small Kitchen Appliances",
                  description: "Commercial Small Kitchen Appliances",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51da9e",
                    },
                    slug: "commercial-electric-popcorn-machines",
                    title: "Commercial Electric Popcorn Machines",
                    id: 14000021,
                    parent:
                      "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-small-kitchen-appliances",
                    path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-small-kitchen-appliances/commercial-electric-popcorn-machines",
                    active: true,
                    meta: {
                      tags: "Commercial Electric Popcorn Machines",
                      keywords: "Commercial Electric Popcorn Machines",
                      description: "Commercial Electric Popcorn Machines",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51da9f",
                    },
                    slug: "commercial-ice-makers",
                    title: "Commercial Ice Makers",
                    id: 14000022,
                    parent:
                      "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-small-kitchen-appliances",
                    path: "/home-property-improvement/commercial-appliances/commercial-kitchen-appliances/commercial-small-kitchen-appliances/commercial-ice-makers",
                    active: true,
                    meta: {
                      tags: "Commercial Ice Makers",
                      keywords: "Commercial Ice Makers",
                      description: "Commercial Ice Makers",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64c253a073d43befcf51daa0",
        },
        slug: "floor-coverings",
        title: "Floor Coverings",
        id: 80689,
        parent: "/home-property-improvement",
        path: "/home-property-improvement/floor-coverings",
        active: true,
        meta: {
          tags: "Floor Coverings",
          keywords: "Floor Coverings",
          description: "Floor Coverings",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c253a073d43befcf51daa1",
            },
            slug: "carpet-tiles",
            title: "Carpet Tiles",
            id: 14000024,
            parent: "/home-property-improvement/floor-coverings",
            path: "/home-property-improvement/floor-coverings/carpet-tiles",
            active: true,
            meta: {
              tags: "Carpet Tiles",
              keywords: "Carpet Tiles",
              description: "Carpet Tiles",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51daa2",
            },
            slug: "carpets-and-rugs",
            title: "Carpets and Rugs",
            id: 14000025,
            parent: "/home-property-improvement/floor-coverings",
            path: "/home-property-improvement/floor-coverings/carpets-and-rugs",
            active: true,
            meta: {
              tags: "Carpets and Rugs",
              keywords: "Carpets and Rugs",
              description: "Carpets and Rugs",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51daa3",
            },
            slug: "non-resilient-flooring",
            title: "Non Resilient Flooring",
            id: 14000026,
            parent: "/home-property-improvement/floor-coverings",
            path: "/home-property-improvement/floor-coverings/non-resilient-flooring",
            active: true,
            meta: {
              tags: "Non Resilient Flooring",
              keywords: "Non Resilient Flooring",
              description: "Non Resilient Flooring",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51daa4",
                },
                slug: "floor-and-wall-tiles",
                title: "Floor and Wall Tiles",
                id: 14000027,
                parent:
                  "/home-property-improvement/floor-coverings/non-resilient-flooring",
                path: "/home-property-improvement/floor-coverings/non-resilient-flooring/floor-and-wall-tiles",
                active: true,
                meta: {
                  tags: "Floor and Wall Tiles",
                  keywords: "Floor and Wall Tiles",
                  description: "Floor and Wall Tiles",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daa5",
                    },
                    slug: "ceramic-and-porcelain-tiles",
                    title: "Ceramic and Porcelain Tiles",
                    id: 14000028,
                    parent:
                      "/home-property-improvement/floor-coverings/non-resilient-flooring/floor-and-wall-tiles",
                    path: "/home-property-improvement/floor-coverings/non-resilient-flooring/floor-and-wall-tiles/ceramic-and-porcelain-tiles",
                    active: true,
                    meta: {
                      tags: "Ceramic and Porcelain Tiles",
                      keywords: "Ceramic and Porcelain Tiles",
                      description: "Ceramic and Porcelain Tiles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daa6",
                    },
                    slug: "decorative-tiles",
                    title: "Decorative Tiles",
                    id: 14000029,
                    parent:
                      "/home-property-improvement/floor-coverings/non-resilient-flooring/floor-and-wall-tiles",
                    path: "/home-property-improvement/floor-coverings/non-resilient-flooring/floor-and-wall-tiles/decorative-tiles",
                    active: true,
                    meta: {
                      tags: "Decorative Tiles",
                      keywords: "Decorative Tiles",
                      description: "Decorative Tiles",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51daa7",
                },
                slug: "laminate-flooring",
                title: "Laminate Flooring",
                id: 14000030,
                parent:
                  "/home-property-improvement/floor-coverings/non-resilient-flooring",
                path: "/home-property-improvement/floor-coverings/non-resilient-flooring/laminate-flooring",
                active: true,
                meta: {
                  tags: "Laminate Flooring",
                  keywords: "Laminate Flooring",
                  description: "Laminate Flooring",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51daa8",
                },
                slug: "stone-flooring",
                title: "Stone Flooring",
                id: 14000031,
                parent:
                  "/home-property-improvement/floor-coverings/non-resilient-flooring",
                path: "/home-property-improvement/floor-coverings/non-resilient-flooring/stone-flooring",
                active: true,
                meta: {
                  tags: "Stone Flooring",
                  keywords: "Stone Flooring",
                  description: "Stone Flooring",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253a073d43befcf51daa9",
                },
                slug: "wood-flooring",
                title: "Wood Flooring",
                id: 14000032,
                parent:
                  "/home-property-improvement/floor-coverings/non-resilient-flooring",
                path: "/home-property-improvement/floor-coverings/non-resilient-flooring/wood-flooring",
                active: true,
                meta: {
                  tags: "Wood Flooring",
                  keywords: "Wood Flooring",
                  description: "Wood Flooring",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daaa",
                    },
                    slug: "hardwood-flooring",
                    title: "Hardwood Flooring",
                    id: 14000033,
                    parent:
                      "/home-property-improvement/floor-coverings/non-resilient-flooring/wood-flooring",
                    path: "/home-property-improvement/floor-coverings/non-resilient-flooring/wood-flooring/hardwood-flooring",
                    active: true,
                    meta: {
                      tags: "Hardwood Flooring",
                      keywords: "Hardwood Flooring",
                      description: "Hardwood Flooring",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253a073d43befcf51daab",
            },
            slug: "resilient-flooring",
            title: "Resilient Flooring",
            id: 14000034,
            parent: "/home-property-improvement/floor-coverings",
            path: "/home-property-improvement/floor-coverings/resilient-flooring",
            active: true,
            meta: {
              tags: "Resilient Flooring",
              keywords: "Resilient Flooring",
              description: "Resilient Flooring",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253a073d43befcf51daac",
                },
                slug: "vinyl-flooring",
                title: "Vinyl Flooring",
                id: 14000035,
                parent:
                  "/home-property-improvement/floor-coverings/resilient-flooring",
                path: "/home-property-improvement/floor-coverings/resilient-flooring/vinyl-flooring",
                active: true,
                meta: {
                  tags: "Vinyl Flooring",
                  keywords: "Vinyl Flooring",
                  description: "Vinyl Flooring",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253a073d43befcf51daad",
                    },
                    slug: "luxury-vinyl-tiles",
                    title: "Luxury Vinyl Tiles",
                    id: 14000036,
                    parent:
                      "/home-property-improvement/floor-coverings/resilient-flooring/vinyl-flooring",
                    path: "/home-property-improvement/floor-coverings/resilient-flooring/vinyl-flooring/luxury-vinyl-tiles",
                    active: true,
                    meta: {
                      tags: "Luxury Vinyl Tiles",
                      keywords: "Luxury Vinyl Tiles",
                      description: "Luxury Vinyl Tiles",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64c253a073d43befcf51db4d",
        },
        slug: "home-utility",
        title: "Home Utility",
        id: 80691,
        parent: "/home-property-improvement",
        path: "/home-property-improvement/home-utility",
        active: true,
        meta: {
          tags: "Home Utility",
          keywords: "Home Utility",
          description: "Home Utility",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c253a073d43befcf51db4e",
            },
            slug: "organizers-and-storage",
            title: "Organizers and Storage",
            id: 14000202,
            parent: "/home-property-improvement/home-utility",
            path: "/home-property-improvement/home-utility/organizers-and-storage",
            active: true,
            meta: {
              tags: "Organizers and Storage",
              keywords: "Organizers and Storage",
              description: "Organizers and Storage",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64c253a073d43befcf51db4f",
        },
        slug: "pool-and-playground",
        title: "Pool and Playground",
        id: 80690,
        parent: "/home-property-improvement",
        path: "/home-property-improvement/pool-and-playground",
        active: true,
        meta: {
          tags: "Pool and Playground",
          keywords: "Pool and Playground",
          description: "Pool and Playground",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c253a073d43befcf51db50",
            },
            slug: "swimming-pool",
            title: "Swimming Pool",
            id: 14000204,
            parent: "/home-property-improvement/pool-and-playground",
            path: "/home-property-improvement/pool-and-playground/swimming-pool",
            active: true,
            meta: {
              tags: "Swimming Pool",
              keywords: "Swimming Pool",
              description: "Swimming Pool",
            },
            sub_categories: [],
          },
        ],
      },
    ],
    updatedAt: {
      $date: "2024-05-08T09:00:06.444Z",
    },
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b12a",
    },
    id: 57,
    title: "Energy & Power",
    slug: "energy-power",
    active: true,
    meta: {
      tags: "Energy & Power Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "energy and power industry market reports, energy and power industry, oil and gas market, power market, renewable energy market, oil field services market, oil and gas industry outlook, power generation market, power distribution market, power transmission market, petroleum industry outlook, wind energy market",
      description:
        "Our Energy and Power industry reports offer insights on major trends, technologies, competition and growth opportunities in the market. The global and regional analysis includes statistics, market segments, overview, key players, market drivers, and the future developments.",
    },
    description: {
      short_description:
        "Energy is crucial to any industry and has several applications in niche segments. Mordor Intelligence™ helps determine the most reliable analytics and trends in the market. Our studies encompass the entire energy value chain and cover oil, gas, power, and renewables.",
      full_description:
        "The Energy & Power sector is undergoing rapid change with smarter extraction, processing, and utilization on one hand and the search for alternative and sustainable sources on the other. Our cross-disciplinary focus on research helps us provide valuable insights to our clients across the industry.",
    },
    picture: null,
    __v: 0,
    picture_url: "energy-&-power.png",
    cat_email: "enp@mordorintelligence.com",
    cat_pw_id: 12426,
    parent: "/",
    path: "/energy-power",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b161",
        },
        id: 574,
        title: "Oil and Gas",
        slug: "oil-and-gas",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Oil and Gas Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022.  Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.\n",
          keywords:
            "Oil & Gas Market Research Reports, oil market, gas market, oil industry report, oil market intelligence, oil and gas market intelligence, upstream services market reports, oil and gas industry, oil market report, gas market report, oil industry market report, gas industry market report, oil and gas market report",
          tags: "Oil and Gas Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Oil and Gas Market Research extensively covers Upstream Industry (Exploration and Production), Midstream and Downstream industry.",
          short_description:
            "Upstream, midstream, or downstream, Mordor Intelligence™ has you covered. Our extensive market intelligence repository helps you get ahead of the curve, especially in sectors like oil exploration and production. Browse through some of our industry reports",
        },
        picture: null,
        __v: 0,
        parent: "/energy-power",
        path: "/energy-power/oil-and-gas",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b22",
            },
            slug: "oil-and-gas-water-management-services",
            title: "Oil and Gas Water Management Services",
            id: 7000194,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/oil-and-gas-water-management-services",
            active: true,
            meta: {
              tags: "Oil and Gas Water Management Services",
              keywords: "Oil and Gas Water Management Services",
              description: "Oil and Gas Water Management Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ae4",
            },
            slug: "downstream",
            title: "Downstream",
            id: 7000132,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/downstream",
            active: true,
            meta: {
              tags: "Downstream",
              keywords: "Downstream",
              description: "Downstream",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ae8",
                },
                slug: "oil-refining",
                title: "Oil Refining",
                id: 7000136,
                parent: "/energy-power/oil-and-gas/downstream",
                path: "/energy-power/oil-and-gas/downstream/oil-refining",
                active: true,
                meta: {
                  tags: "Oil Refining",
                  keywords: "Oil Refining",
                  description: "Oil Refining",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ae5",
                },
                slug: "city-gas-distribution-cgd",
                title: "City Gas Distribution (CGD)",
                id: 7000133,
                parent: "/energy-power/oil-and-gas/downstream",
                path: "/energy-power/oil-and-gas/downstream/city-gas-distribution-cgd",
                active: true,
                meta: {
                  tags: "City Gas Distribution (CGD)",
                  keywords: "City Gas Distribution (CGD)",
                  description: "City Gas Distribution (CGD)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995af5",
                },
                slug: "retail-fuel",
                title: "Retail Fuel",
                id: 7000149,
                parent: "/energy-power/oil-and-gas/downstream",
                path: "/energy-power/oil-and-gas/downstream/retail-fuel",
                active: true,
                meta: {
                  tags: "Retail Fuel",
                  keywords: "Retail Fuel",
                  description: "Retail Fuel",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995af7",
                    },
                    slug: "petrol-station",
                    title: "Petrol Station",
                    id: 7000151,
                    parent: "/energy-power/oil-and-gas/downstream/retail-fuel",
                    path: "/energy-power/oil-and-gas/downstream/retail-fuel/petrol-station",
                    active: true,
                    meta: {
                      tags: "Petrol Station",
                      keywords: "Petrol Station",
                      description: "Petrol Station",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995af6",
                    },
                    slug: "fuel-station",
                    title: "Fuel Station",
                    id: 7000150,
                    parent: "/energy-power/oil-and-gas/downstream/retail-fuel",
                    path: "/energy-power/oil-and-gas/downstream/retail-fuel/fuel-station",
                    active: true,
                    meta: {
                      tags: "Fuel Station",
                      keywords: "Fuel Station",
                      description: "Fuel Station",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ae9",
                },
                slug: "refined-petroleum-products",
                title: "Refined Petroleum Products",
                id: 7000137,
                parent: "/energy-power/oil-and-gas/downstream",
                path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products",
                active: true,
                meta: {
                  tags: "Refined Petroleum Products",
                  keywords: "Refined Petroleum Products",
                  description: "Refined Petroleum Products",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995af1",
                    },
                    slug: "diesel",
                    title: "Diesel",
                    id: 7000145,
                    parent:
                      "/energy-power/oil-and-gas/downstream/refined-petroleum-products",
                    path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products/diesel",
                    active: true,
                    meta: {
                      tags: "Diesel",
                      keywords: "Diesel",
                      description: "Diesel",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995af2",
                    },
                    slug: "gasoline",
                    title: "Gasoline",
                    id: 7000146,
                    parent:
                      "/energy-power/oil-and-gas/downstream/refined-petroleum-products",
                    path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products/gasoline",
                    active: true,
                    meta: {
                      tags: "Gasoline",
                      keywords: "Gasoline",
                      description: "Gasoline",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995aea",
                    },
                    slug: "aviation-fuel",
                    title: "Aviation Fuel",
                    id: 7000138,
                    parent:
                      "/energy-power/oil-and-gas/downstream/refined-petroleum-products",
                    path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel",
                    active: true,
                    meta: {
                      tags: "Aviation Fuel",
                      keywords: "Aviation Fuel",
                      description: "Aviation Fuel",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995aec",
                        },
                        slug: "defense-aircraft-aviation-fuel",
                        title: "Defense Aircraft Aviation Fuel",
                        id: 7000140,
                        parent:
                          "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel",
                        path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel/defense-aircraft-aviation-fuel",
                        active: true,
                        meta: {
                          tags: "Defense Aircraft Aviation Fuel",
                          keywords: "Defense Aircraft Aviation Fuel",
                          description: "Defense Aircraft Aviation Fuel",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995aeb",
                        },
                        slug: "commercial-aircraft-aviation-fuel",
                        title: "Commercial Aircraft Aviation Fuel",
                        id: 7000139,
                        parent:
                          "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel",
                        path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel/commercial-aircraft-aviation-fuel",
                        active: true,
                        meta: {
                          tags: "Commercial Aircraft Aviation Fuel",
                          keywords: "Commercial Aircraft Aviation Fuel",
                          description: "Commercial Aircraft Aviation Fuel",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995aed",
                        },
                        slug: "jet-fuel",
                        title: "Jet Fuel",
                        id: 7000141,
                        parent:
                          "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel",
                        path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel/jet-fuel",
                        active: true,
                        meta: {
                          tags: "Jet Fuel",
                          keywords: "Jet Fuel",
                          description: "Jet Fuel",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995aee",
                            },
                            slug: "military-jet-fuel",
                            title: "Military Jet Fuel",
                            id: 7000142,
                            parent:
                              "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel/jet-fuel",
                            path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel/jet-fuel/military-jet-fuel",
                            active: true,
                            meta: {
                              tags: "Military Jet Fuel",
                              keywords: "Military Jet Fuel",
                              description: "Military Jet Fuel",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995aef",
                        },
                        slug: "renewable-aviation-fuel",
                        title: "Renewable Aviation Fuel",
                        id: 7000143,
                        parent:
                          "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel",
                        path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products/aviation-fuel/renewable-aviation-fuel",
                        active: true,
                        meta: {
                          tags: "Renewable Aviation Fuel",
                          keywords: "Renewable Aviation Fuel",
                          description: "Renewable Aviation Fuel",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995af0",
                    },
                    slug: "bunker-fuel",
                    title: "Bunker Fuel",
                    id: 7000144,
                    parent:
                      "/energy-power/oil-and-gas/downstream/refined-petroleum-products",
                    path: "/energy-power/oil-and-gas/downstream/refined-petroleum-products/bunker-fuel",
                    active: true,
                    meta: {
                      tags: "Bunker Fuel",
                      keywords: "Bunker Fuel",
                      description: "Bunker Fuel",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ae6",
                },
                slug: "cng",
                title: "CNG",
                id: 7000134,
                parent: "/energy-power/oil-and-gas/downstream",
                path: "/energy-power/oil-and-gas/downstream/cng",
                active: true,
                meta: {
                  tags: "CNG",
                  keywords: "CNG",
                  description: "CNG",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995ae7",
                    },
                    slug: "cng-dispenser",
                    title: "CNG Dispenser",
                    id: 7000135,
                    parent: "/energy-power/oil-and-gas/downstream/cng",
                    path: "/energy-power/oil-and-gas/downstream/cng/cng-dispenser",
                    active: true,
                    meta: {
                      tags: "CNG Dispenser",
                      keywords: "CNG Dispenser",
                      description: "CNG Dispenser",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995af3",
                },
                slug: "refuelling-infrastructure",
                title: "Refuelling Infrastructure",
                id: 7000147,
                parent: "/energy-power/oil-and-gas/downstream",
                path: "/energy-power/oil-and-gas/downstream/refuelling-infrastructure",
                active: true,
                meta: {
                  tags: "Refuelling Infrastructure",
                  keywords: "Refuelling Infrastructure",
                  description: "Refuelling Infrastructure",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995af4",
                    },
                    slug: "natural-gas-refueling-infrastructure",
                    title: "Natural Gas Refueling Infrastructure",
                    id: 7000148,
                    parent:
                      "/energy-power/oil-and-gas/downstream/refuelling-infrastructure",
                    path: "/energy-power/oil-and-gas/downstream/refuelling-infrastructure/natural-gas-refueling-infrastructure",
                    active: true,
                    meta: {
                      tags: "Natural Gas Refueling Infrastructure",
                      keywords: "Natural Gas Refueling Infrastructure",
                      description: "Natural Gas Refueling Infrastructure",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995af8",
                },
                slug: "sulphur-recovery-technologies",
                title: "Sulphur Recovery Technologies",
                id: 7000152,
                parent: "/energy-power/oil-and-gas/downstream",
                path: "/energy-power/oil-and-gas/downstream/sulphur-recovery-technologies",
                active: true,
                meta: {
                  tags: "Sulphur Recovery Technologies",
                  keywords: "Sulphur Recovery Technologies",
                  description: "Sulphur Recovery Technologies",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995afb",
            },
            slug: "line-pipe",
            title: "Line Pipe",
            id: 7000155,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/line-pipe",
            active: true,
            meta: {
              tags: "Line Pipe",
              keywords: "Line Pipe",
              description: "Line Pipe",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b16",
            },
            slug: "offshore-helicopter-services",
            title: "Offshore Helicopter Services",
            id: 7000182,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/offshore-helicopter-services",
            active: true,
            meta: {
              tags: "Offshore Helicopter Services",
              keywords: "Offshore Helicopter Services",
              description: "Offshore Helicopter Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b1b",
            },
            slug: "oil-and-gas-capex",
            title: "Oil and Gas CAPEX",
            id: 7000187,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/oil-and-gas-capex",
            active: true,
            meta: {
              tags: "Oil and Gas CAPEX",
              keywords: "Oil and Gas CAPEX",
              description: "Oil and Gas CAPEX",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b1d",
            },
            slug: "oil-and-gas-epc",
            title: "Oil and Gas EPC",
            id: 7000189,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/oil-and-gas-epc",
            active: true,
            meta: {
              tags: "Oil and Gas EPC",
              keywords: "Oil and Gas EPC",
              description: "Oil and Gas EPC",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b81",
            },
            slug: "vapor-recovery-units",
            title: "Vapor Recovery Units",
            id: 7000289,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/vapor-recovery-units",
            active: true,
            meta: {
              tags: "Vapor Recovery Units",
              keywords: "Vapor Recovery Units",
              description: "Vapor Recovery Units",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995af9",
            },
            slug: "flame-arrestors",
            title: "Flame Arrestors",
            id: 7000153,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/flame-arrestors",
            active: true,
            meta: {
              tags: "Flame Arrestors",
              keywords: "Flame Arrestors",
              description: "Flame Arrestors",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995afc",
            },
            slug: "liquefied-natural-gas-lng",
            title: "Liquefied Natural Gas (LNG)",
            id: 7000156,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng",
            active: true,
            meta: {
              tags: "Liquefied Natural Gas (LNG)",
              keywords: "Liquefied Natural Gas (LNG)",
              description: "Liquefied Natural Gas (LNG)",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b01",
                },
                slug: "lng-storage-tank",
                title: "LNG Storage Tank",
                id: 7000161,
                parent: "/energy-power/oil-and-gas/liquefied-natural-gas-lng",
                path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-storage-tank",
                active: true,
                meta: {
                  tags: "LNG Storage Tank",
                  keywords: "LNG Storage Tank",
                  description: "LNG Storage Tank",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995aff",
                },
                slug: "lng-bunkering",
                title: "LNG Bunkering",
                id: 7000159,
                parent: "/energy-power/oil-and-gas/liquefied-natural-gas-lng",
                path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-bunkering",
                active: true,
                meta: {
                  tags: "LNG Bunkering",
                  keywords: "LNG Bunkering",
                  description: "LNG Bunkering",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b00",
                },
                slug: "lng-infrastructure",
                title: "LNG Infrastructure",
                id: 7000160,
                parent: "/energy-power/oil-and-gas/liquefied-natural-gas-lng",
                path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-infrastructure",
                active: true,
                meta: {
                  tags: "LNG Infrastructure",
                  keywords: "LNG Infrastructure",
                  description: "LNG Infrastructure",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b06",
                },
                slug: "small-scale-lng",
                title: "Small scale LNG",
                id: 7000166,
                parent: "/energy-power/oil-and-gas/liquefied-natural-gas-lng",
                path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/small-scale-lng",
                active: true,
                meta: {
                  tags: "Small scale LNG",
                  keywords: "Small scale LNG",
                  description: "Small scale LNG",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995afd",
                },
                slug: "floating-lng",
                title: "Floating LNG",
                id: 7000157,
                parent: "/energy-power/oil-and-gas/liquefied-natural-gas-lng",
                path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/floating-lng",
                active: true,
                meta: {
                  tags: "Floating LNG",
                  keywords: "Floating LNG",
                  description: "Floating LNG",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995afe",
                    },
                    slug: "flng-power-plant",
                    title: "FLNG Power Plant",
                    id: 7000158,
                    parent:
                      "/energy-power/oil-and-gas/liquefied-natural-gas-lng/floating-lng",
                    path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/floating-lng/flng-power-plant",
                    active: true,
                    meta: {
                      tags: "FLNG Power Plant",
                      keywords: "FLNG Power Plant",
                      description: "FLNG Power Plant",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b02",
                },
                slug: "lng-terminal",
                title: "LNG Terminal",
                id: 7000162,
                parent: "/energy-power/oil-and-gas/liquefied-natural-gas-lng",
                path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-terminal",
                active: true,
                meta: {
                  tags: "LNG Terminal",
                  keywords: "LNG Terminal",
                  description: "LNG Terminal",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b04",
                    },
                    slug: "liquefaction-terminal",
                    title: "Liquefaction Terminal",
                    id: 7000164,
                    parent:
                      "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-terminal",
                    path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-terminal/liquefaction-terminal",
                    active: true,
                    meta: {
                      tags: "Liquefaction Terminal",
                      keywords: "Liquefaction Terminal",
                      description: "Liquefaction Terminal",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b03",
                    },
                    slug: "large-scale-lng-terminal",
                    title: "Large Scale LNG Terminal",
                    id: 7000163,
                    parent:
                      "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-terminal",
                    path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-terminal/large-scale-lng-terminal",
                    active: true,
                    meta: {
                      tags: "Large Scale LNG Terminal",
                      keywords: "Large Scale LNG Terminal",
                      description: "Large Scale LNG Terminal",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b05",
                    },
                    slug: "regasification-terminal",
                    title: "Regasification Terminal",
                    id: 7000165,
                    parent:
                      "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-terminal",
                    path: "/energy-power/oil-and-gas/liquefied-natural-gas-lng/lng-terminal/regasification-terminal",
                    active: true,
                    meta: {
                      tags: "Regasification Terminal",
                      keywords: "Regasification Terminal",
                      description: "Regasification Terminal",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b18",
            },
            slug: "oil-and-gas-asset-integrity-management-services",
            title: "Oil and Gas Asset Integrity Management Services",
            id: 7000184,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/oil-and-gas-asset-integrity-management-services",
            active: true,
            meta: {
              tags: "Oil and Gas Asset Integrity Management Services",
              keywords: "Oil and Gas Asset Integrity Management Services",
              description: "Oil and Gas Asset Integrity Management Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b20",
            },
            slug: "oil-and-gas-separator",
            title: "Oil and Gas Separator",
            id: 7000192,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/oil-and-gas-separator",
            active: true,
            meta: {
              tags: "Oil and Gas Separator",
              keywords: "Oil and Gas Separator",
              description: "Oil and Gas Separator",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b21",
                },
                slug: "marine-oil-water-separator",
                title: "Marine Oil Water Separator",
                id: 7000193,
                parent: "/energy-power/oil-and-gas/oil-and-gas-separator",
                path: "/energy-power/oil-and-gas/oil-and-gas-separator/marine-oil-water-separator",
                active: true,
                meta: {
                  tags: "Marine Oil Water Separator",
                  keywords: "Marine Oil Water Separator",
                  description: "Marine Oil Water Separator",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b23",
            },
            slug: "shale",
            title: "Shale",
            id: 7000195,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/shale",
            active: true,
            meta: {
              tags: "Shale",
              keywords: "Shale",
              description: "Shale",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b24",
                },
                slug: "shale-gas",
                title: "Shale Gas",
                id: 7000196,
                parent: "/energy-power/oil-and-gas/shale",
                path: "/energy-power/oil-and-gas/shale/shale-gas",
                active: true,
                meta: {
                  tags: "Shale Gas",
                  keywords: "Shale Gas",
                  description: "Shale Gas",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b25",
                },
                slug: "shale-oil",
                title: "Shale Oil",
                id: 7000197,
                parent: "/energy-power/oil-and-gas/shale",
                path: "/energy-power/oil-and-gas/shale/shale-oil",
                active: true,
                meta: {
                  tags: "Shale Oil",
                  keywords: "Shale Oil",
                  description: "Shale Oil",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b17",
            },
            slug: "oil-and-gas-accumulator",
            title: "Oil and Gas Accumulator",
            id: 7000183,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/oil-and-gas-accumulator",
            active: true,
            meta: {
              tags: "Oil and Gas Accumulator",
              keywords: "Oil and Gas Accumulator",
              description: "Oil and Gas Accumulator",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b1c",
            },
            slug: "oil-and-gas-drone-services",
            title: "Oil and Gas Drone Services",
            id: 7000188,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/oil-and-gas-drone-services",
            active: true,
            meta: {
              tags: "Oil and Gas Drone Services",
              keywords: "Oil and Gas Drone Services",
              description: "Oil and Gas Drone Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b1e",
            },
            slug: "oil-and-gas-packer",
            title: "Oil and Gas Packer",
            id: 7000190,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/oil-and-gas-packer",
            active: true,
            meta: {
              tags: "Oil and Gas Packer",
              keywords: "Oil and Gas Packer",
              description: "Oil and Gas Packer",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b1f",
                },
                slug: "oil-and-gas-swell-packers",
                title: "Oil and Gas Swell Packers",
                id: 7000191,
                parent: "/energy-power/oil-and-gas/oil-and-gas-packer",
                path: "/energy-power/oil-and-gas/oil-and-gas-packer/oil-and-gas-swell-packers",
                active: true,
                meta: {
                  tags: "Oil and Gas Swell Packers",
                  keywords: "Oil and Gas Swell Packers",
                  description: "Oil and Gas Swell Packers",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b26",
            },
            slug: "tank-protection",
            title: "Tank Protection",
            id: 7000198,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/tank-protection",
            active: true,
            meta: {
              tags: "Tank Protection",
              keywords: "Tank Protection",
              description: "Tank Protection",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b27",
            },
            slug: "top-drive-system",
            title: "Top Drive System",
            id: 7000199,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/top-drive-system",
            active: true,
            meta: {
              tags: "Top Drive System",
              keywords: "Top Drive System",
              description: "Top Drive System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b28",
            },
            slug: "upstream",
            title: "Upstream",
            id: 7000200,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/upstream",
            active: true,
            meta: {
              tags: "Upstream",
              keywords: "Upstream",
              description: "Upstream",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b70",
                },
                slug: "seismic-services",
                title: "Seismic Services",
                id: 7000272,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/seismic-services",
                active: true,
                meta: {
                  tags: "Seismic Services",
                  keywords: "Seismic Services",
                  description: "Seismic Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b71",
                    },
                    slug: "offshore-seismic-services",
                    title: "Offshore Seismic Services",
                    id: 7000273,
                    parent:
                      "/energy-power/oil-and-gas/upstream/seismic-services",
                    path: "/energy-power/oil-and-gas/upstream/seismic-services/offshore-seismic-services",
                    active: true,
                    meta: {
                      tags: "Offshore Seismic Services",
                      keywords: "Offshore Seismic Services",
                      description: "Offshore Seismic Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b78",
                },
                slug: "unconventional-gas",
                title: "Unconventional Gas",
                id: 7000280,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/unconventional-gas",
                active: true,
                meta: {
                  tags: "Unconventional Gas",
                  keywords: "Unconventional Gas",
                  description: "Unconventional Gas",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b7c",
                },
                slug: "wireline-logging-services",
                title: "Wireline Logging Services",
                id: 7000284,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/wireline-logging-services",
                active: true,
                meta: {
                  tags: "Wireline Logging Services",
                  keywords: "Wireline Logging Services",
                  description: "Wireline Logging Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b7e",
                    },
                    slug: "open-hole-logging-services",
                    title: "Open Hole Logging Services",
                    id: 7000286,
                    parent:
                      "/energy-power/oil-and-gas/upstream/wireline-logging-services",
                    path: "/energy-power/oil-and-gas/upstream/wireline-logging-services/open-hole-logging-services",
                    active: true,
                    meta: {
                      tags: "Open Hole Logging Services",
                      keywords: "Open Hole Logging Services",
                      description: "Open Hole Logging Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b7d",
                    },
                    slug: "cased-hole-logging-services",
                    title: "Cased Hole Logging Services",
                    id: 7000285,
                    parent:
                      "/energy-power/oil-and-gas/upstream/wireline-logging-services",
                    path: "/energy-power/oil-and-gas/upstream/wireline-logging-services/cased-hole-logging-services",
                    active: true,
                    meta: {
                      tags: "Cased Hole Logging Services",
                      keywords: "Cased Hole Logging Services",
                      description: "Cased Hole Logging Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b5f",
                },
                slug: "nuclear-magnetic-resonance",
                title: "Nuclear Magnetic Resonance",
                id: 7000255,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/nuclear-magnetic-resonance",
                active: true,
                meta: {
                  tags: "Nuclear Magnetic Resonance",
                  keywords: "Nuclear Magnetic Resonance",
                  description: "Nuclear Magnetic Resonance",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b6e",
                },
                slug: "reservoir-analysis",
                title: "Reservoir Analysis",
                id: 7000270,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/reservoir-analysis",
                active: true,
                meta: {
                  tags: "Reservoir Analysis",
                  keywords: "Reservoir Analysis",
                  description: "Reservoir Analysis",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b7f",
                },
                slug: "wireline-services",
                title: "Wireline Services",
                id: 7000287,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/wireline-services",
                active: true,
                meta: {
                  tags: "Wireline Services",
                  keywords: "Wireline Services",
                  description: "Wireline Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b80",
                    },
                    slug: "slickline-services",
                    title: "Slickline Services",
                    id: 7000288,
                    parent:
                      "/energy-power/oil-and-gas/upstream/wireline-services",
                    path: "/energy-power/oil-and-gas/upstream/wireline-services/slickline-services",
                    active: true,
                    meta: {
                      tags: "Slickline Services",
                      keywords: "Slickline Services",
                      description: "Slickline Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b65",
                },
                slug: "oil-country-tubular-goods",
                title: "Oil Country Tubular Goods",
                id: 7000261,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/oil-country-tubular-goods",
                active: true,
                meta: {
                  tags: "Oil Country Tubular Goods",
                  keywords: "Oil Country Tubular Goods",
                  description: "Oil Country Tubular Goods",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b72",
                },
                slug: "subsea-system",
                title: "Subsea System",
                id: 7000274,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/subsea-system",
                active: true,
                meta: {
                  tags: "Subsea System",
                  keywords: "Subsea System",
                  description: "Subsea System",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b74",
                    },
                    slug: "subsea-production-and-processing-system",
                    title: "Subsea Production and Processing System",
                    id: 7000276,
                    parent: "/energy-power/oil-and-gas/upstream/subsea-system",
                    path: "/energy-power/oil-and-gas/upstream/subsea-system/subsea-production-and-processing-system",
                    active: true,
                    meta: {
                      tags: "Subsea Production and Processing System",
                      keywords: "Subsea Production and Processing System",
                      description: "Subsea Production and Processing System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b75",
                    },
                    slug: "subsea-pumps",
                    title: "Subsea Pumps",
                    id: 7000277,
                    parent: "/energy-power/oil-and-gas/upstream/subsea-system",
                    path: "/energy-power/oil-and-gas/upstream/subsea-system/subsea-pumps",
                    active: true,
                    meta: {
                      tags: "Subsea Pumps",
                      keywords: "Subsea Pumps",
                      description: "Subsea Pumps",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b73",
                    },
                    slug: "subsea-control-system",
                    title: "Subsea Control System",
                    id: 7000275,
                    parent: "/energy-power/oil-and-gas/upstream/subsea-system",
                    path: "/energy-power/oil-and-gas/upstream/subsea-system/subsea-control-system",
                    active: true,
                    meta: {
                      tags: "Subsea Control System",
                      keywords: "Subsea Control System",
                      description: "Subsea Control System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b76",
                    },
                    slug: "subsea-well-access-and-bop-system",
                    title: "Subsea Well Access and BOP System",
                    id: 7000278,
                    parent: "/energy-power/oil-and-gas/upstream/subsea-system",
                    path: "/energy-power/oil-and-gas/upstream/subsea-system/subsea-well-access-and-bop-system",
                    active: true,
                    meta: {
                      tags: "Subsea Well Access and BOP System",
                      keywords: "Subsea Well Access and BOP System",
                      description: "Subsea Well Access and BOP System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b77",
                    },
                    slug: "subsea-well-intervention",
                    title: "Subsea Well Intervention",
                    id: 7000279,
                    parent: "/energy-power/oil-and-gas/upstream/subsea-system",
                    path: "/energy-power/oil-and-gas/upstream/subsea-system/subsea-well-intervention",
                    active: true,
                    meta: {
                      tags: "Subsea Well Intervention",
                      keywords: "Subsea Well Intervention",
                      description: "Subsea Well Intervention",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b2c",
                },
                slug: "coiled-tubing",
                title: "Coiled Tubing",
                id: 7000204,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/coiled-tubing",
                active: true,
                meta: {
                  tags: "Coiled Tubing",
                  keywords: "Coiled Tubing",
                  description: "Coiled Tubing",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b2d",
                    },
                    slug: "coiled-tubing-services",
                    title: "Coiled Tubing Services",
                    id: 7000205,
                    parent: "/energy-power/oil-and-gas/upstream/coiled-tubing",
                    path: "/energy-power/oil-and-gas/upstream/coiled-tubing/coiled-tubing-services",
                    active: true,
                    meta: {
                      tags: "Coiled Tubing Services",
                      keywords: "Coiled Tubing Services",
                      description: "Coiled Tubing Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b2a",
                },
                slug: "casing-and-cementation-hardware",
                title: "Casing and Cementation Hardware",
                id: 7000202,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/casing-and-cementation-hardware",
                active: true,
                meta: {
                  tags: "Casing and Cementation Hardware",
                  keywords: "Casing and Cementation Hardware",
                  description: "Casing and Cementation Hardware",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b2b",
                },
                slug: "christmas-tree",
                title: "Christmas Tree",
                id: 7000203,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/christmas-tree",
                active: true,
                meta: {
                  tags: "Christmas Tree",
                  keywords: "Christmas Tree",
                  description: "Christmas Tree",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b5d",
                },
                slug: "geosteering-services",
                title: "Geosteering Services",
                id: 7000253,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/geosteering-services",
                active: true,
                meta: {
                  tags: "Geosteering Services",
                  keywords: "Geosteering Services",
                  description: "Geosteering Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b60",
                },
                slug: "offshore-auv-and-rov",
                title: "Offshore AUV and ROV",
                id: 7000256,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/offshore-auv-and-rov",
                active: true,
                meta: {
                  tags: "Offshore AUV and ROV",
                  keywords: "Offshore AUV and ROV",
                  description: "Offshore AUV and ROV",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b62",
                    },
                    slug: "rov",
                    title: "ROV",
                    id: 7000258,
                    parent:
                      "/energy-power/oil-and-gas/upstream/offshore-auv-and-rov",
                    path: "/energy-power/oil-and-gas/upstream/offshore-auv-and-rov/rov",
                    active: true,
                    meta: {
                      tags: "ROV",
                      keywords: "ROV",
                      description: "ROV",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b61",
                    },
                    slug: "auv",
                    title: "AUV",
                    id: 7000257,
                    parent:
                      "/energy-power/oil-and-gas/upstream/offshore-auv-and-rov",
                    path: "/energy-power/oil-and-gas/upstream/offshore-auv-and-rov/auv",
                    active: true,
                    meta: {
                      tags: "AUV",
                      keywords: "AUV",
                      description: "AUV",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b79",
                },
                slug: "well-intervention",
                title: "Well Intervention",
                id: 7000281,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/well-intervention",
                active: true,
                meta: {
                  tags: "Well Intervention",
                  keywords: "Well Intervention",
                  description: "Well Intervention",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b7a",
                },
                slug: "well-testing-services",
                title: "Well Testing Services",
                id: 7000282,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/well-testing-services",
                active: true,
                meta: {
                  tags: "Well Testing Services",
                  keywords: "Well Testing Services",
                  description: "Well Testing Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b5c",
                },
                slug: "geophysical-equipment-and-services",
                title: "Geophysical Equipment and Services",
                id: 7000252,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/geophysical-equipment-and-services",
                active: true,
                meta: {
                  tags: "Geophysical Equipment and Services",
                  keywords: "Geophysical Equipment and Services",
                  description: "Geophysical Equipment and Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b6d",
                },
                slug: "pressure-pumping",
                title: "Pressure Pumping",
                id: 7000269,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/pressure-pumping",
                active: true,
                meta: {
                  tags: "Pressure Pumping",
                  keywords: "Pressure Pumping",
                  description: "Pressure Pumping",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b6f",
                },
                slug: "sand-control-system",
                title: "Sand Control System",
                id: 7000271,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/sand-control-system",
                active: true,
                meta: {
                  tags: "Sand Control System",
                  keywords: "Sand Control System",
                  description: "Sand Control System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b29",
                },
                slug: "blowout-preventers-bop",
                title: "Blowout Preventers (BOP)",
                id: 7000201,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/blowout-preventers-bop",
                active: true,
                meta: {
                  tags: "Blowout Preventers (BOP)",
                  keywords: "Blowout Preventers (BOP)",
                  description: "Blowout Preventers (BOP)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b55",
                },
                slug: "exploration-and-production-e-and-p",
                title: "Exploration & Production (E&P)",
                id: 7000245,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p",
                active: true,
                meta: {
                  tags: "Exploration & Production (E&P)",
                  keywords: "Exploration & Production (E&P)",
                  description: "Exploration & Production (E&P)",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b56",
                    },
                    slug: "artificial-lift-system",
                    title: "Artificial Lift System",
                    id: 7000246,
                    parent:
                      "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p",
                    path: "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p/artificial-lift-system",
                    active: true,
                    meta: {
                      tags: "Artificial Lift System",
                      keywords: "Artificial Lift System",
                      description: "Artificial Lift System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b57",
                    },
                    slug: "deep-water-and-ultra-deep-water-exploration-and-production-e-and-p",
                    title:
                      "Deep Water and Ultra Deep Water Exploration & Production (E&P)",
                    id: 7000247,
                    parent:
                      "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p",
                    path: "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p/deep-water-and-ultra-deep-water-exploration-and-production-e-and-p",
                    active: true,
                    meta: {
                      tags: "Deep Water and Ultra Deep Water Exploration & Production (E&P)",
                      keywords:
                        "Deep Water and Ultra Deep Water Exploration & Production (E&P)",
                      description:
                        "Deep Water and Ultra Deep Water Exploration & Production (E&P)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b5b",
                    },
                    slug: "production-testing",
                    title: "Production Testing",
                    id: 7000251,
                    parent:
                      "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p",
                    path: "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p/production-testing",
                    active: true,
                    meta: {
                      tags: "Production Testing",
                      keywords: "Production Testing",
                      description: "Production Testing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b59",
                    },
                    slug: "fpso",
                    title: "FPSO",
                    id: 7000249,
                    parent:
                      "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p",
                    path: "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p/fpso",
                    active: true,
                    meta: {
                      tags: "FPSO",
                      keywords: "FPSO",
                      description: "FPSO",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b58",
                    },
                    slug: "floating-production-system-fps",
                    title: "Floating Production System (FPS)",
                    id: 7000248,
                    parent:
                      "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p",
                    path: "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p/floating-production-system-fps",
                    active: true,
                    meta: {
                      tags: "Floating Production System (FPS)",
                      keywords: "Floating Production System (FPS)",
                      description: "Floating Production System (FPS)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b5a",
                    },
                    slug: "offshore-exploration-and-production",
                    title: "Offshore Exploration and Production",
                    id: 7000250,
                    parent:
                      "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p",
                    path: "/energy-power/oil-and-gas/upstream/exploration-and-production-e-and-p/offshore-exploration-and-production",
                    active: true,
                    meta: {
                      tags: "Offshore Exploration and Production",
                      keywords: "Offshore Exploration and Production",
                      description: "Offshore Exploration and Production",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b5e",
                },
                slug: "liner-hanger-system",
                title: "Liner Hanger System",
                id: 7000254,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/liner-hanger-system",
                active: true,
                meta: {
                  tags: "Liner Hanger System",
                  keywords: "Liner Hanger System",
                  description: "Liner Hanger System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b2f",
                },
                slug: "drilling-and-completion",
                title: "Drilling and Completion",
                id: 7000207,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                active: true,
                meta: {
                  tags: "Drilling and Completion",
                  keywords: "Drilling and Completion",
                  description: "Drilling and Completion",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b3f",
                    },
                    slug: "drilling-tools",
                    title: "Drilling Tools",
                    id: 7000223,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-tools",
                    active: true,
                    meta: {
                      tags: "Drilling Tools",
                      keywords: "Drilling Tools",
                      description: "Drilling Tools",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b51",
                    },
                    slug: "robotic-drilling",
                    title: "Robotic Drilling",
                    id: 7000241,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/robotic-drilling",
                    active: true,
                    meta: {
                      tags: "Robotic Drilling",
                      keywords: "Robotic Drilling",
                      description: "Robotic Drilling",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b40",
                    },
                    slug: "drilling-waste-management-services",
                    title: "Drilling Waste Management Services",
                    id: 7000224,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-waste-management-services",
                    active: true,
                    meta: {
                      tags: "Drilling Waste Management Services",
                      keywords: "Drilling Waste Management Services",
                      description: "Drilling Waste Management Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b43",
                    },
                    slug: "hydraulic-fracturing",
                    title: "Hydraulic Fracturing",
                    id: 7000227,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/hydraulic-fracturing",
                    active: true,
                    meta: {
                      tags: "Hydraulic Fracturing",
                      keywords: "Hydraulic Fracturing",
                      description: "Hydraulic Fracturing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b36",
                    },
                    slug: "drilling-and-completion-fluids",
                    title: "Drilling and Completion Fluids",
                    id: 7000214,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-and-completion-fluids",
                    active: true,
                    meta: {
                      tags: "Drilling and Completion Fluids",
                      keywords: "Drilling and Completion Fluids",
                      description: "Drilling and Completion Fluids",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b46",
                    },
                    slug: "oil-and-gas-drilling",
                    title: "Oil and Gas Drilling",
                    id: 7000230,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling",
                    active: true,
                    meta: {
                      tags: "Oil and Gas Drilling",
                      keywords: "Oil and Gas Drilling",
                      description: "Oil and Gas Drilling",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b48",
                        },
                        slug: "drill-bit",
                        title: "Drill Bit",
                        id: 7000232,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/drill-bit",
                        active: true,
                        meta: {
                          tags: "Drill Bit",
                          keywords: "Drill Bit",
                          description: "Drill Bit",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995b49",
                            },
                            slug: "polycrystalline-diamond-compact-pdc-drill-bit",
                            title:
                              "Polycrystalline Diamond Compact (PDC) Drill Bit",
                            id: 7000233,
                            parent:
                              "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/drill-bit",
                            path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/drill-bit/polycrystalline-diamond-compact-pdc-drill-bit",
                            active: true,
                            meta: {
                              tags: "Polycrystalline Diamond Compact (PDC) Drill Bit",
                              keywords:
                                "Polycrystalline Diamond Compact (PDC) Drill Bit",
                              description:
                                "Polycrystalline Diamond Compact (PDC) Drill Bit",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b4e",
                        },
                        slug: "offshore-contract-drilling",
                        title: "Offshore Contract Drilling",
                        id: 7000238,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/offshore-contract-drilling",
                        active: true,
                        meta: {
                          tags: "Offshore Contract Drilling",
                          keywords: "Offshore Contract Drilling",
                          description: "Offshore Contract Drilling",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b4f",
                        },
                        slug: "offshore-drilling",
                        title: "Offshore Drilling",
                        id: 7000239,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/offshore-drilling",
                        active: true,
                        meta: {
                          tags: "Offshore Drilling",
                          keywords: "Offshore Drilling",
                          description: "Offshore Drilling",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b4c",
                        },
                        slug: "drilling-services",
                        title: "Drilling Services",
                        id: 7000236,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/drilling-services",
                        active: true,
                        meta: {
                          tags: "Drilling Services",
                          keywords: "Drilling Services",
                          description: "Drilling Services",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b4d",
                        },
                        slug: "managed-pressure-drilling-services",
                        title: "Managed Pressure Drilling Services",
                        id: 7000237,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/managed-pressure-drilling-services",
                        active: true,
                        meta: {
                          tags: "Managed Pressure Drilling Services",
                          keywords: "Managed Pressure Drilling Services",
                          description: "Managed Pressure Drilling Services",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b47",
                        },
                        slug: "advanced-drill-data-management-solutions",
                        title: "Advanced Drill Data Management Solutions",
                        id: 7000231,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/advanced-drill-data-management-solutions",
                        active: true,
                        meta: {
                          tags: "Advanced Drill Data Management Solutions",
                          keywords: "Advanced Drill Data Management Solutions",
                          description:
                            "Advanced Drill Data Management Solutions",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b4b",
                        },
                        slug: "drill-pipe",
                        title: "Drill Pipe",
                        id: 7000235,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/drill-pipe",
                        active: true,
                        meta: {
                          tags: "Drill Pipe",
                          keywords: "Drill Pipe",
                          description: "Drill Pipe",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b4a",
                        },
                        slug: "drill-collar",
                        title: "Drill Collar",
                        id: 7000234,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/oil-and-gas-drilling/drill-collar",
                        active: true,
                        meta: {
                          tags: "Drill Collar",
                          keywords: "Drill Collar",
                          description: "Drill Collar",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b52",
                    },
                    slug: "solid-control-equipment",
                    title: "Solid Control Equipment",
                    id: 7000242,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/solid-control-equipment",
                    active: true,
                    meta: {
                      tags: "Solid Control Equipment",
                      keywords: "Solid Control Equipment",
                      description: "Solid Control Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b53",
                    },
                    slug: "well-cementing-services",
                    title: "Well Cementing Services",
                    id: 7000243,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/well-cementing-services",
                    active: true,
                    meta: {
                      tags: "Well Cementing Services",
                      keywords: "Well Cementing Services",
                      description: "Well Cementing Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b30",
                    },
                    slug: "completion-equipment-and-services",
                    title: "Completion Equipment and Services",
                    id: 7000208,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/completion-equipment-and-services",
                    active: true,
                    meta: {
                      tags: "Completion Equipment and Services",
                      keywords: "Completion Equipment and Services",
                      description: "Completion Equipment and Services",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b31",
                        },
                        slug: "completion-equipment",
                        title: "Completion Equipment",
                        id: 7000209,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/completion-equipment-and-services",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/completion-equipment-and-services/completion-equipment",
                        active: true,
                        meta: {
                          tags: "Completion Equipment",
                          keywords: "Completion Equipment",
                          description: "Completion Equipment",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b32",
                        },
                        slug: "intelligent-well-completion",
                        title: "Intelligent Well Completion",
                        id: 7000210,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/completion-equipment-and-services",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/completion-equipment-and-services/intelligent-well-completion",
                        active: true,
                        meta: {
                          tags: "Intelligent Well Completion",
                          keywords: "Intelligent Well Completion",
                          description: "Intelligent Well Completion",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b42",
                    },
                    slug: "fracking-fluids",
                    title: "Fracking Fluids",
                    id: 7000226,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/fracking-fluids",
                    active: true,
                    meta: {
                      tags: "Fracking Fluids",
                      keywords: "Fracking Fluids",
                      description: "Fracking Fluids",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b35",
                    },
                    slug: "downhole-drilling-tools",
                    title: "Downhole Drilling Tools",
                    id: 7000213,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/downhole-drilling-tools",
                    active: true,
                    meta: {
                      tags: "Downhole Drilling Tools",
                      keywords: "Downhole Drilling Tools",
                      description: "Downhole Drilling Tools",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b50",
                    },
                    slug: "perforating-gun",
                    title: "Perforating Gun",
                    id: 7000240,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/perforating-gun",
                    active: true,
                    meta: {
                      tags: "Perforating Gun",
                      keywords: "Perforating Gun",
                      description: "Perforating Gun",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b33",
                    },
                    slug: "deep-water-drilling",
                    title: "Deep Water Drilling",
                    id: 7000211,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/deep-water-drilling",
                    active: true,
                    meta: {
                      tags: "Deep Water Drilling",
                      keywords: "Deep Water Drilling",
                      description: "Deep Water Drilling",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b38",
                    },
                    slug: "drilling-rigs",
                    title: "Drilling Rigs",
                    id: 7000216,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs",
                    active: true,
                    meta: {
                      tags: "Drilling Rigs",
                      keywords: "Drilling Rigs",
                      description: "Drilling Rigs",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b3c",
                        },
                        slug: "onshore-drilling-rigs",
                        title: "Onshore Drilling Rigs",
                        id: 7000220,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs/onshore-drilling-rigs",
                        active: true,
                        meta: {
                          tags: "Onshore Drilling Rigs",
                          keywords: "Onshore Drilling Rigs",
                          description: "Onshore Drilling Rigs",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995b3d",
                            },
                            slug: "land-drilling-rigs",
                            title: "Land Drilling Rigs",
                            id: 7000221,
                            parent:
                              "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs/onshore-drilling-rigs",
                            path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs/onshore-drilling-rigs/land-drilling-rigs",
                            active: true,
                            meta: {
                              tags: "Land Drilling Rigs",
                              keywords: "Land Drilling Rigs",
                              description: "Land Drilling Rigs",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b3e",
                        },
                        slug: "rotary-drilling-rigs",
                        title: "Rotary Drilling Rigs",
                        id: 7000222,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs/rotary-drilling-rigs",
                        active: true,
                        meta: {
                          tags: "Rotary Drilling Rigs",
                          keywords: "Rotary Drilling Rigs",
                          description: "Rotary Drilling Rigs",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b39",
                        },
                        slug: "offshore-drilling-rigs",
                        title: "Offshore Drilling Rigs",
                        id: 7000217,
                        parent:
                          "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs",
                        path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs/offshore-drilling-rigs",
                        active: true,
                        meta: {
                          tags: "Offshore Drilling Rigs",
                          keywords: "Offshore Drilling Rigs",
                          description: "Offshore Drilling Rigs",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995b3a",
                            },
                            slug: "jackup-rigs",
                            title: "Jackup Rigs",
                            id: 7000218,
                            parent:
                              "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs/offshore-drilling-rigs",
                            path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs/offshore-drilling-rigs/jackup-rigs",
                            active: true,
                            meta: {
                              tags: "Jackup Rigs",
                              keywords: "Jackup Rigs",
                              description: "Jackup Rigs",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995b3b",
                            },
                            slug: "mobile-offshore-drilling-units",
                            title: "Mobile Offshore Drilling Units",
                            id: 7000219,
                            parent:
                              "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs/offshore-drilling-rigs",
                            path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-rigs/offshore-drilling-rigs/mobile-offshore-drilling-units",
                            active: true,
                            meta: {
                              tags: "Mobile Offshore Drilling Units",
                              keywords: "Mobile Offshore Drilling Units",
                              description: "Mobile Offshore Drilling Units",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b34",
                    },
                    slug: "directional-drilling",
                    title: "Directional Drilling",
                    id: 7000212,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/directional-drilling",
                    active: true,
                    meta: {
                      tags: "Directional Drilling",
                      keywords: "Directional Drilling",
                      description: "Directional Drilling",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b37",
                    },
                    slug: "drilling-automation",
                    title: "Drilling Automation",
                    id: 7000215,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/drilling-automation",
                    active: true,
                    meta: {
                      tags: "Drilling Automation",
                      keywords: "Drilling Automation",
                      description: "Drilling Automation",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b41",
                    },
                    slug: "frac-stack",
                    title: "Frac Stack",
                    id: 7000225,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/frac-stack",
                    active: true,
                    meta: {
                      tags: "Frac Stack",
                      keywords: "Frac Stack",
                      description: "Frac Stack",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b44",
                    },
                    slug: "logging-while-drilling",
                    title: "Logging While Drilling",
                    id: 7000228,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/logging-while-drilling",
                    active: true,
                    meta: {
                      tags: "Logging While Drilling",
                      keywords: "Logging While Drilling",
                      description: "Logging While Drilling",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b45",
                    },
                    slug: "measurement-while-drilling",
                    title: "Measurement While Drilling",
                    id: 7000229,
                    parent:
                      "/energy-power/oil-and-gas/upstream/drilling-and-completion",
                    path: "/energy-power/oil-and-gas/upstream/drilling-and-completion/measurement-while-drilling",
                    active: true,
                    meta: {
                      tags: "Measurement While Drilling",
                      keywords: "Measurement While Drilling",
                      description: "Measurement While Drilling",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b54",
                },
                slug: "enhanced-oil-recovery-eor",
                title: "Enhanced Oil Recovery (EOR)",
                id: 7000244,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/enhanced-oil-recovery-eor",
                active: true,
                meta: {
                  tags: "Enhanced Oil Recovery (EOR)",
                  keywords: "Enhanced Oil Recovery (EOR)",
                  description: "Enhanced Oil Recovery (EOR)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b64",
                },
                slug: "oil-and-gas-electric-submersible-pumps",
                title: "Oil and Gas Electric Submersible Pumps",
                id: 7000260,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/oil-and-gas-electric-submersible-pumps",
                active: true,
                meta: {
                  tags: "Oil and Gas Electric Submersible Pumps",
                  keywords: "Oil and Gas Electric Submersible Pumps",
                  description: "Oil and Gas Electric Submersible Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b66",
                },
                slug: "oilfield-equipment-and-services",
                title: "Oilfield Equipment and Services",
                id: 7000262,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services",
                active: true,
                meta: {
                  tags: "Oilfield Equipment and Services",
                  keywords: "Oilfield Equipment and Services",
                  description: "Oilfield Equipment and Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b68",
                    },
                    slug: "oilfield-equipment",
                    title: "Oilfield Equipment",
                    id: 7000264,
                    parent:
                      "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services",
                    path: "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services/oilfield-equipment",
                    active: true,
                    meta: {
                      tags: "Oilfield Equipment",
                      keywords: "Oilfield Equipment",
                      description: "Oilfield Equipment",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b69",
                        },
                        slug: "oilfield-equipment-rental-services",
                        title: "Oilfield Equipment Rental Services",
                        id: 7000265,
                        parent:
                          "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services/oilfield-equipment",
                        path: "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services/oilfield-equipment/oilfield-equipment-rental-services",
                        active: true,
                        meta: {
                          tags: "Oilfield Equipment Rental Services",
                          keywords: "Oilfield Equipment Rental Services",
                          description: "Oilfield Equipment Rental Services",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b67",
                    },
                    slug: "digital-oilfield-services",
                    title: "Digital Oilfield Services",
                    id: 7000263,
                    parent:
                      "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services",
                    path: "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services/digital-oilfield-services",
                    active: true,
                    meta: {
                      tags: "Digital Oilfield Services",
                      keywords: "Digital Oilfield Services",
                      description: "Digital Oilfield Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b6a",
                    },
                    slug: "oilfield-services",
                    title: "Oilfield Services",
                    id: 7000266,
                    parent:
                      "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services",
                    path: "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services/oilfield-services",
                    active: true,
                    meta: {
                      tags: "Oilfield Services",
                      keywords: "Oilfield Services",
                      description: "Oilfield Services",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b6b",
                        },
                        slug: "offshore-oilfield-services",
                        title: "Offshore Oilfield Services",
                        id: 7000267,
                        parent:
                          "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services/oilfield-services",
                        path: "/energy-power/oil-and-gas/upstream/oilfield-equipment-and-services/oilfield-services/offshore-oilfield-services",
                        active: true,
                        meta: {
                          tags: "Offshore Oilfield Services",
                          keywords: "Offshore Oilfield Services",
                          description: "Offshore Oilfield Services",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b2e",
                },
                slug: "digital-rock-analysis",
                title: "Digital Rock Analysis",
                id: 7000206,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/digital-rock-analysis",
                active: true,
                meta: {
                  tags: "Digital Rock Analysis",
                  keywords: "Digital Rock Analysis",
                  description: "Digital Rock Analysis",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b63",
                },
                slug: "offshore-crane",
                title: "Offshore Crane",
                id: 7000259,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/offshore-crane",
                active: true,
                meta: {
                  tags: "Offshore Crane",
                  keywords: "Offshore Crane",
                  description: "Offshore Crane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b6c",
                },
                slug: "oilfield-power-generation",
                title: "Oilfield Power Generation",
                id: 7000268,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/oilfield-power-generation",
                active: true,
                meta: {
                  tags: "Oilfield Power Generation",
                  keywords: "Oilfield Power Generation",
                  description: "Oilfield Power Generation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b7b",
                },
                slug: "wellhead-equipment",
                title: "Wellhead Equipment",
                id: 7000283,
                parent: "/energy-power/oil-and-gas/upstream",
                path: "/energy-power/oil-and-gas/upstream/wellhead-equipment",
                active: true,
                meta: {
                  tags: "Wellhead Equipment",
                  keywords: "Wellhead Equipment",
                  description: "Wellhead Equipment",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b07",
            },
            slug: "midstream",
            title: "Midstream",
            id: 7000167,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/midstream",
            active: true,
            meta: {
              tags: "Midstream",
              keywords: "Midstream",
              description: "Midstream",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b0c",
                },
                slug: "pipeline",
                title: "Pipeline",
                id: 7000172,
                parent: "/energy-power/oil-and-gas/midstream",
                path: "/energy-power/oil-and-gas/midstream/pipeline",
                active: true,
                meta: {
                  tags: "Pipeline",
                  keywords: "Pipeline",
                  description: "Pipeline",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b10",
                    },
                    slug: "pipeline-fabrication-and-construction",
                    title: "Pipeline Fabrication and Construction",
                    id: 7000176,
                    parent: "/energy-power/oil-and-gas/midstream/pipeline",
                    path: "/energy-power/oil-and-gas/midstream/pipeline/pipeline-fabrication-and-construction",
                    active: true,
                    meta: {
                      tags: "Pipeline Fabrication and Construction",
                      keywords: "Pipeline Fabrication and Construction",
                      description: "Pipeline Fabrication and Construction",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b13",
                    },
                    slug: "pipeline-services",
                    title: "Pipeline Services",
                    id: 7000179,
                    parent: "/energy-power/oil-and-gas/midstream/pipeline",
                    path: "/energy-power/oil-and-gas/midstream/pipeline/pipeline-services",
                    active: true,
                    meta: {
                      tags: "Pipeline Services",
                      keywords: "Pipeline Services",
                      description: "Pipeline Services",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b14",
                        },
                        slug: "pipeline-maintenance-services",
                        title: "Pipeline Maintenance Services",
                        id: 7000180,
                        parent:
                          "/energy-power/oil-and-gas/midstream/pipeline/pipeline-services",
                        path: "/energy-power/oil-and-gas/midstream/pipeline/pipeline-services/pipeline-maintenance-services",
                        active: true,
                        meta: {
                          tags: "Pipeline Maintenance Services",
                          keywords: "Pipeline Maintenance Services",
                          description: "Pipeline Maintenance Services",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b15",
                        },
                        slug: "pipeline-mro",
                        title: "Pipeline MRO",
                        id: 7000181,
                        parent:
                          "/energy-power/oil-and-gas/midstream/pipeline/pipeline-services",
                        path: "/energy-power/oil-and-gas/midstream/pipeline/pipeline-services/pipeline-mro",
                        active: true,
                        meta: {
                          tags: "Pipeline MRO",
                          keywords: "Pipeline MRO",
                          description: "Pipeline MRO",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b12",
                    },
                    slug: "pipeline-pigging-equipment",
                    title: "Pipeline Pigging Equipment",
                    id: 7000178,
                    parent: "/energy-power/oil-and-gas/midstream/pipeline",
                    path: "/energy-power/oil-and-gas/midstream/pipeline/pipeline-pigging-equipment",
                    active: true,
                    meta: {
                      tags: "Pipeline Pigging Equipment",
                      keywords: "Pipeline Pigging Equipment",
                      description: "Pipeline Pigging Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b0d",
                    },
                    slug: "intelligent-pigging",
                    title: "Intelligent Pigging",
                    id: 7000173,
                    parent: "/energy-power/oil-and-gas/midstream/pipeline",
                    path: "/energy-power/oil-and-gas/midstream/pipeline/intelligent-pigging",
                    active: true,
                    meta: {
                      tags: "Intelligent Pigging",
                      keywords: "Intelligent Pigging",
                      description: "Intelligent Pigging",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b11",
                    },
                    slug: "pipeline-integrity-management",
                    title: "Pipeline Integrity Management",
                    id: 7000177,
                    parent: "/energy-power/oil-and-gas/midstream/pipeline",
                    path: "/energy-power/oil-and-gas/midstream/pipeline/pipeline-integrity-management",
                    active: true,
                    meta: {
                      tags: "Pipeline Integrity Management",
                      keywords: "Pipeline Integrity Management",
                      description: "Pipeline Integrity Management",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b0e",
                    },
                    slug: "offshore-oil-and-gas-pipeline",
                    title: "Offshore Oil and Gas Pipeline",
                    id: 7000174,
                    parent: "/energy-power/oil-and-gas/midstream/pipeline",
                    path: "/energy-power/oil-and-gas/midstream/pipeline/offshore-oil-and-gas-pipeline",
                    active: true,
                    meta: {
                      tags: "Offshore Oil and Gas Pipeline",
                      keywords: "Offshore Oil and Gas Pipeline",
                      description: "Offshore Oil and Gas Pipeline",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b0f",
                    },
                    slug: "onshore-oil-and-gas-pipeline",
                    title: "Onshore Oil and Gas Pipeline",
                    id: 7000175,
                    parent: "/energy-power/oil-and-gas/midstream/pipeline",
                    path: "/energy-power/oil-and-gas/midstream/pipeline/onshore-oil-and-gas-pipeline",
                    active: true,
                    meta: {
                      tags: "Onshore Oil and Gas Pipeline",
                      keywords: "Onshore Oil and Gas Pipeline",
                      description: "Onshore Oil and Gas Pipeline",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b08",
                },
                slug: "oil-and-gas-storage",
                title: "Oil and Gas Storage",
                id: 7000168,
                parent: "/energy-power/oil-and-gas/midstream",
                path: "/energy-power/oil-and-gas/midstream/oil-and-gas-storage",
                active: true,
                meta: {
                  tags: "Oil and Gas Storage",
                  keywords: "Oil and Gas Storage",
                  description: "Oil and Gas Storage",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b0a",
                    },
                    slug: "oil-and-gas-storage-tank",
                    title: "Oil and Gas Storage Tank",
                    id: 7000170,
                    parent:
                      "/energy-power/oil-and-gas/midstream/oil-and-gas-storage",
                    path: "/energy-power/oil-and-gas/midstream/oil-and-gas-storage/oil-and-gas-storage-tank",
                    active: true,
                    meta: {
                      tags: "Oil and Gas Storage Tank",
                      keywords: "Oil and Gas Storage Tank",
                      description: "Oil and Gas Storage Tank",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b0b",
                    },
                    slug: "oil-storage",
                    title: "Oil Storage",
                    id: 7000171,
                    parent:
                      "/energy-power/oil-and-gas/midstream/oil-and-gas-storage",
                    path: "/energy-power/oil-and-gas/midstream/oil-and-gas-storage/oil-storage",
                    active: true,
                    meta: {
                      tags: "Oil Storage",
                      keywords: "Oil Storage",
                      description: "Oil Storage",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b09",
                    },
                    slug: "natural-gas-storage",
                    title: "Natural Gas Storage",
                    id: 7000169,
                    parent:
                      "/energy-power/oil-and-gas/midstream/oil-and-gas-storage",
                    path: "/energy-power/oil-and-gas/midstream/oil-and-gas-storage/natural-gas-storage",
                    active: true,
                    meta: {
                      tags: "Natural Gas Storage",
                      keywords: "Natural Gas Storage",
                      description: "Natural Gas Storage",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b19",
            },
            slug: "oil-and-gas-automation",
            title: "Oil and Gas Automation",
            id: 7000185,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/oil-and-gas-automation",
            active: true,
            meta: {
              tags: "Oil and Gas Automation",
              keywords: "Oil and Gas Automation",
              description: "Oil and Gas Automation",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b1a",
                },
                slug: "big-data-in-energy",
                title: "Big Data in Energy",
                id: 7000186,
                parent: "/energy-power/oil-and-gas/oil-and-gas-automation",
                path: "/energy-power/oil-and-gas/oil-and-gas-automation/big-data-in-energy",
                active: true,
                meta: {
                  tags: "Big Data in Energy",
                  keywords: "Big Data in Energy",
                  description: "Big Data in Energy",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995afa",
            },
            slug: "flare-gas-recovery-system",
            title: "Flare Gas Recovery System",
            id: 7000154,
            parent: "/energy-power/oil-and-gas",
            path: "/energy-power/oil-and-gas/flare-gas-recovery-system",
            active: true,
            meta: {
              tags: "Flare Gas Recovery System",
              keywords: "Flare Gas Recovery System",
              description: "Flare Gas Recovery System",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b162",
        },
        id: 5715,
        title: "Power",
        slug: "power",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Power Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Power Market Research Reports, power market, power market report, power transmission market, power transmission & motion control market size, substation batteries market, power transmission & motion control market analysis, power market research, power industry report, power industry",
          tags: "Power Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Power Market Research comprehensively covers Conventional and Unconventional Power, Power Generation, Transmission & Distribution Industry",
          short_description:
            "With the increased pressure of climate change, the dynamics of the world change unpredictably. Mordor Intelligence™ has an exhaustive repository of periodically updated business intelligence that helps you successfully decode and navigate the ever-changing market landscape for power.",
        },
        picture: null,
        __v: 0,
        parent: "/energy-power",
        path: "/energy-power/power",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b83",
            },
            slug: "offshore-power",
            title: "Offshore Power",
            id: 7000292,
            parent: "/energy-power/power",
            path: "/energy-power/power/offshore-power",
            active: true,
            meta: {
              tags: "Offshore Power",
              keywords: "Offshore Power",
              description: "Offshore Power",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995bb5",
            },
            slug: "power-management-system",
            title: "Power Management System",
            id: 7000342,
            parent: "/energy-power/power",
            path: "/energy-power/power/power-management-system",
            active: true,
            meta: {
              tags: "Power Management System",
              keywords: "Power Management System",
              description: "Power Management System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b84",
            },
            slug: "power-epc",
            title: "Power EPC",
            id: 7000293,
            parent: "/energy-power/power",
            path: "/energy-power/power/power-epc",
            active: true,
            meta: {
              tags: "Power EPC",
              keywords: "Power EPC",
              description: "Power EPC",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b85",
                },
                slug: "power-generation-epc",
                title: "Power Generation EPC",
                id: 7000294,
                parent: "/energy-power/power/power-epc",
                path: "/energy-power/power/power-epc/power-generation-epc",
                active: true,
                meta: {
                  tags: "Power Generation EPC",
                  keywords: "Power Generation EPC",
                  description: "Power Generation EPC",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b82",
            },
            slug: "digital-power-utility",
            title: "Digital Power Utility",
            id: 7000291,
            parent: "/energy-power/power",
            path: "/energy-power/power/digital-power-utility",
            active: true,
            meta: {
              tags: "Digital Power Utility",
              keywords: "Digital Power Utility",
              description: "Digital Power Utility",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995bb6",
            },
            slug: "power-transmission-and-distribution",
            title: "Power Transmission and Distribution",
            id: 7000343,
            parent: "/energy-power/power",
            path: "/energy-power/power/power-transmission-and-distribution",
            active: true,
            meta: {
              tags: "Power Transmission and Distribution",
              keywords: "Power Transmission and Distribution",
              description: "Power Transmission and Distribution",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bbc",
                },
                slug: "distribution-board",
                title: "Distribution Board",
                id: 7000349,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/distribution-board",
                active: true,
                meta: {
                  tags: "Distribution Board",
                  keywords: "Distribution Board",
                  description: "Distribution Board",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bb7",
                },
                slug: "busbar-trunking",
                title: "Busbar Trunking",
                id: 7000344,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/busbar-trunking",
                active: true,
                meta: {
                  tags: "Busbar Trunking",
                  keywords: "Busbar Trunking",
                  description: "Busbar Trunking",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bb9",
                },
                slug: "dehydrating-breather",
                title: "Dehydrating Breather",
                id: 7000346,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/dehydrating-breather",
                active: true,
                meta: {
                  tags: "Dehydrating Breather",
                  keywords: "Dehydrating Breather",
                  description: "Dehydrating Breather",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bbe",
                },
                slug: "distribution-voltage-regulator",
                title: "Distribution Voltage Regulator",
                id: 7000351,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/distribution-voltage-regulator",
                active: true,
                meta: {
                  tags: "Distribution Voltage Regulator",
                  keywords: "Distribution Voltage Regulator",
                  description: "Distribution Voltage Regulator",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995be2",
                },
                slug: "transformer",
                title: "Transformer",
                id: 7000387,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/transformer",
                active: true,
                meta: {
                  tags: "Transformer",
                  keywords: "Transformer",
                  description: "Transformer",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bea",
                    },
                    slug: "power-transformer",
                    title: "Power Transformer",
                    id: 7000395,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/power-transformer",
                    active: true,
                    meta: {
                      tags: "Power Transformer",
                      keywords: "Power Transformer",
                      description: "Power Transformer",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995beb",
                        },
                        slug: "large-power-transformer",
                        title: "Large Power Transformer",
                        id: 7000396,
                        parent:
                          "/energy-power/power/power-transmission-and-distribution/transformer/power-transformer",
                        path: "/energy-power/power/power-transmission-and-distribution/transformer/power-transformer/large-power-transformer",
                        active: true,
                        meta: {
                          tags: "Large Power Transformer",
                          keywords: "Large Power Transformer",
                          description: "Large Power Transformer",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995be4",
                    },
                    slug: "dry-type-transformer",
                    title: "Dry Type Transformer",
                    id: 7000389,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/dry-type-transformer",
                    active: true,
                    meta: {
                      tags: "Dry Type Transformer",
                      keywords: "Dry Type Transformer",
                      description: "Dry Type Transformer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995be7",
                    },
                    slug: "instrument-transformer",
                    title: "Instrument Transformer",
                    id: 7000392,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/instrument-transformer",
                    active: true,
                    meta: {
                      tags: "Instrument Transformer",
                      keywords: "Instrument Transformer",
                      description: "Instrument Transformer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995be8",
                    },
                    slug: "medium-voltage-transformer",
                    title: "Medium Voltage Transformer",
                    id: 7000393,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/medium-voltage-transformer",
                    active: true,
                    meta: {
                      tags: "Medium Voltage Transformer",
                      keywords: "Medium Voltage Transformer",
                      description: "Medium Voltage Transformer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995be3",
                    },
                    slug: "distribution-transformer",
                    title: "Distribution Transformer",
                    id: 7000388,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/distribution-transformer",
                    active: true,
                    meta: {
                      tags: "Distribution Transformer",
                      keywords: "Distribution Transformer",
                      description: "Distribution Transformer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995be5",
                    },
                    slug: "gas-insulated-transformer",
                    title: "Gas Insulated Transformer",
                    id: 7000390,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/gas-insulated-transformer",
                    active: true,
                    meta: {
                      tags: "Gas Insulated Transformer",
                      keywords: "Gas Insulated Transformer",
                      description: "Gas Insulated Transformer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995be6",
                    },
                    slug: "industrial-control-transformer",
                    title: "Industrial Control Transformer",
                    id: 7000391,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/industrial-control-transformer",
                    active: true,
                    meta: {
                      tags: "Industrial Control Transformer",
                      keywords: "Industrial Control Transformer",
                      description: "Industrial Control Transformer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995be9",
                    },
                    slug: "pad-mounted-transformer",
                    title: "Pad Mounted Transformer",
                    id: 7000394,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/pad-mounted-transformer",
                    active: true,
                    meta: {
                      tags: "Pad Mounted Transformer",
                      keywords: "Pad Mounted Transformer",
                      description: "Pad Mounted Transformer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bec",
                    },
                    slug: "smart-transformer",
                    title: "Smart Transformer",
                    id: 7000397,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/smart-transformer",
                    active: true,
                    meta: {
                      tags: "Smart Transformer",
                      keywords: "Smart Transformer",
                      description: "Smart Transformer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bed",
                    },
                    slug: "solid-state-transformer",
                    title: "Solid State Transformer",
                    id: 7000398,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/transformer",
                    path: "/energy-power/power/power-transmission-and-distribution/transformer/solid-state-transformer",
                    active: true,
                    meta: {
                      tags: "Solid State Transformer",
                      keywords: "Solid State Transformer",
                      description: "Solid State Transformer",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bbd",
                },
                slug: "distribution-feeder-automation-system",
                title: "Distribution Feeder Automation System",
                id: 7000350,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/distribution-feeder-automation-system",
                active: true,
                meta: {
                  tags: "Distribution Feeder Automation System",
                  keywords: "Distribution Feeder Automation System",
                  description: "Distribution Feeder Automation System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bbf",
                },
                slug: "electric-insulation",
                title: "Electric Insulation",
                id: 7000352,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/electric-insulation",
                active: true,
                meta: {
                  tags: "Electric Insulation",
                  keywords: "Electric Insulation",
                  description: "Electric Insulation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bc0",
                },
                slug: "electrical-bushing",
                title: "Electrical Bushing",
                id: 7000353,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/electrical-bushing",
                active: true,
                meta: {
                  tags: "Electrical Bushing",
                  keywords: "Electrical Bushing",
                  description: "Electrical Bushing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bc1",
                },
                slug: "emerging-grid-technologies",
                title: "Emerging Grid Technologies",
                id: 7000354,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/emerging-grid-technologies",
                active: true,
                meta: {
                  tags: "Emerging Grid Technologies",
                  keywords: "Emerging Grid Technologies",
                  description: "Emerging Grid Technologies",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bc3",
                    },
                    slug: "smart-grid",
                    title: "Smart Grid",
                    id: 7000356,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/emerging-grid-technologies",
                    path: "/energy-power/power/power-transmission-and-distribution/emerging-grid-technologies/smart-grid",
                    active: true,
                    meta: {
                      tags: "Smart Grid",
                      keywords: "Smart Grid",
                      description: "Smart Grid",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995bc4",
                        },
                        slug: "smart-grid-network",
                        title: "Smart Grid Network",
                        id: 7000357,
                        parent:
                          "/energy-power/power/power-transmission-and-distribution/emerging-grid-technologies/smart-grid",
                        path: "/energy-power/power/power-transmission-and-distribution/emerging-grid-technologies/smart-grid/smart-grid-network",
                        active: true,
                        meta: {
                          tags: "Smart Grid Network",
                          keywords: "Smart Grid Network",
                          description: "Smart Grid Network",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bc2",
                    },
                    slug: "microgrid",
                    title: "Microgrid",
                    id: 7000355,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/emerging-grid-technologies",
                    path: "/energy-power/power/power-transmission-and-distribution/emerging-grid-technologies/microgrid",
                    active: true,
                    meta: {
                      tags: "Microgrid",
                      keywords: "Microgrid",
                      description: "Microgrid",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bc5",
                    },
                    slug: "smart-meter",
                    title: "Smart Meter",
                    id: 7000358,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/emerging-grid-technologies",
                    path: "/energy-power/power/power-transmission-and-distribution/emerging-grid-technologies/smart-meter",
                    active: true,
                    meta: {
                      tags: "Smart Meter",
                      keywords: "Smart Meter",
                      description: "Smart Meter",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bc6",
                },
                slug: "high-voltage-direct-current-hvdc-transmission-system",
                title: "High Voltage Direct Current (HVDC) Transmission System",
                id: 7000359,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/high-voltage-direct-current-hvdc-transmission-system",
                active: true,
                meta: {
                  tags: "High Voltage Direct Current (HVDC) Transmission System",
                  keywords:
                    "High Voltage Direct Current (HVDC) Transmission System",
                  description:
                    "High Voltage Direct Current (HVDC) Transmission System",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bc7",
                    },
                    slug: "hvdc-converter-station",
                    title: "HVDC Converter Station",
                    id: 7000360,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/high-voltage-direct-current-hvdc-transmission-system",
                    path: "/energy-power/power/power-transmission-and-distribution/high-voltage-direct-current-hvdc-transmission-system/hvdc-converter-station",
                    active: true,
                    meta: {
                      tags: "HVDC Converter Station",
                      keywords: "HVDC Converter Station",
                      description: "HVDC Converter Station",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bc9",
                },
                slug: "outage-management-system",
                title: "Outage Management System",
                id: 7000362,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/outage-management-system",
                active: true,
                meta: {
                  tags: "Outage Management System",
                  keywords: "Outage Management System",
                  description: "Outage Management System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bd4",
                },
                slug: "ring-main-unit",
                title: "Ring Main Unit",
                id: 7000373,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/ring-main-unit",
                active: true,
                meta: {
                  tags: "Ring Main Unit",
                  keywords: "Ring Main Unit",
                  description: "Ring Main Unit",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bd5",
                },
                slug: "sectionalizer",
                title: "Sectionalizer",
                id: 7000374,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/sectionalizer",
                active: true,
                meta: {
                  tags: "Sectionalizer",
                  keywords: "Sectionalizer",
                  description: "Sectionalizer",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bc8",
                },
                slug: "laminated-busbar",
                title: "Laminated Busbar",
                id: 7000361,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/laminated-busbar",
                active: true,
                meta: {
                  tags: "Laminated Busbar",
                  keywords: "Laminated Busbar",
                  description: "Laminated Busbar",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bca",
                },
                slug: "power-cables",
                title: "Power Cables",
                id: 7000363,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/power-cables",
                active: true,
                meta: {
                  tags: "Power Cables",
                  keywords: "Power Cables",
                  description: "Power Cables",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bcb",
                    },
                    slug: "cable-accessories",
                    title: "Cable Accessories",
                    id: 7000364,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/power-cables",
                    path: "/energy-power/power/power-transmission-and-distribution/power-cables/cable-accessories",
                    active: true,
                    meta: {
                      tags: "Cable Accessories",
                      keywords: "Cable Accessories",
                      description: "Cable Accessories",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995bcc",
                        },
                        slug: "high-voltage-cable-accessories",
                        title: "High Voltage Cable Accessories",
                        id: 7000365,
                        parent:
                          "/energy-power/power/power-transmission-and-distribution/power-cables/cable-accessories",
                        path: "/energy-power/power/power-transmission-and-distribution/power-cables/cable-accessories/high-voltage-cable-accessories",
                        active: true,
                        meta: {
                          tags: "High Voltage Cable Accessories",
                          keywords: "High Voltage Cable Accessories",
                          description: "High Voltage Cable Accessories",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bd0",
                    },
                    slug: "medium-voltage-cables",
                    title: "Medium Voltage Cables",
                    id: 7000369,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/power-cables",
                    path: "/energy-power/power/power-transmission-and-distribution/power-cables/medium-voltage-cables",
                    active: true,
                    meta: {
                      tags: "Medium Voltage Cables",
                      keywords: "Medium Voltage Cables",
                      description: "Medium Voltage Cables",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bd1",
                    },
                    slug: "submarine-power-cables",
                    title: "Submarine Power Cables",
                    id: 7000370,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/power-cables",
                    path: "/energy-power/power/power-transmission-and-distribution/power-cables/submarine-power-cables",
                    active: true,
                    meta: {
                      tags: "Submarine Power Cables",
                      keywords: "Submarine Power Cables",
                      description: "Submarine Power Cables",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bcd",
                    },
                    slug: "high-voltage-cables",
                    title: "High Voltage Cables",
                    id: 7000366,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/power-cables",
                    path: "/energy-power/power/power-transmission-and-distribution/power-cables/high-voltage-cables",
                    active: true,
                    meta: {
                      tags: "High Voltage Cables",
                      keywords: "High Voltage Cables",
                      description: "High Voltage Cables",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995bce",
                        },
                        slug: "high-voltage-direct-current-hvdc-cables",
                        title: "High Voltage Direct Current (HVDC) Cables",
                        id: 7000367,
                        parent:
                          "/energy-power/power/power-transmission-and-distribution/power-cables/high-voltage-cables",
                        path: "/energy-power/power/power-transmission-and-distribution/power-cables/high-voltage-cables/high-voltage-direct-current-hvdc-cables",
                        active: true,
                        meta: {
                          tags: "High Voltage Direct Current (HVDC) Cables",
                          keywords: "High Voltage Direct Current (HVDC) Cables",
                          description:
                            "High Voltage Direct Current (HVDC) Cables",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bcf",
                    },
                    slug: "low-voltage-cables",
                    title: "Low Voltage Cables",
                    id: 7000368,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/power-cables",
                    path: "/energy-power/power/power-transmission-and-distribution/power-cables/low-voltage-cables",
                    active: true,
                    meta: {
                      tags: "Low Voltage Cables",
                      keywords: "Low Voltage Cables",
                      description: "Low Voltage Cables",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bdd",
                },
                slug: "switchgear",
                title: "Switchgear",
                id: 7000382,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/switchgear",
                active: true,
                meta: {
                  tags: "Switchgear",
                  keywords: "Switchgear",
                  description: "Switchgear",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bde",
                    },
                    slug: "air-insulated-switchgear",
                    title: "Air Insulated Switchgear",
                    id: 7000383,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/switchgear",
                    path: "/energy-power/power/power-transmission-and-distribution/switchgear/air-insulated-switchgear",
                    active: true,
                    meta: {
                      tags: "Air Insulated Switchgear",
                      keywords: "Air Insulated Switchgear",
                      description: "Air Insulated Switchgear",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bdf",
                    },
                    slug: "gas-insulated-switchgear",
                    title: "Gas Insulated Switchgear",
                    id: 7000384,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/switchgear",
                    path: "/energy-power/power/power-transmission-and-distribution/switchgear/gas-insulated-switchgear",
                    active: true,
                    meta: {
                      tags: "Gas Insulated Switchgear",
                      keywords: "Gas Insulated Switchgear",
                      description: "Gas Insulated Switchgear",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995be0",
                    },
                    slug: "high-voltage-switchgear",
                    title: "High Voltage Switchgear",
                    id: 7000385,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/switchgear",
                    path: "/energy-power/power/power-transmission-and-distribution/switchgear/high-voltage-switchgear",
                    active: true,
                    meta: {
                      tags: "High Voltage Switchgear",
                      keywords: "High Voltage Switchgear",
                      description: "High Voltage Switchgear",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995be1",
                    },
                    slug: "pad-mounted-switchgear",
                    title: "Pad Mounted Switchgear",
                    id: 7000386,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/switchgear",
                    path: "/energy-power/power/power-transmission-and-distribution/switchgear/pad-mounted-switchgear",
                    active: true,
                    meta: {
                      tags: "Pad Mounted Switchgear",
                      keywords: "Pad Mounted Switchgear",
                      description: "Pad Mounted Switchgear",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bb8",
                },
                slug: "dc-distribution-network",
                title: "DC Distribution Network",
                id: 7000345,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/dc-distribution-network",
                active: true,
                meta: {
                  tags: "DC Distribution Network",
                  keywords: "DC Distribution Network",
                  description: "DC Distribution Network",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bba",
                },
                slug: "demand-response-management-system-drms",
                title: "Demand Response Management System (DRMS)",
                id: 7000347,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/demand-response-management-system-drms",
                active: true,
                meta: {
                  tags: "Demand Response Management System (DRMS)",
                  keywords: "Demand Response Management System (DRMS)",
                  description: "Demand Response Management System (DRMS)",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bbb",
                    },
                    slug: "automated-demand-response-management-system-adrms",
                    title:
                      "Automated Demand Response Management System (ADRMS)",
                    id: 7000348,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/demand-response-management-system-drms",
                    path: "/energy-power/power/power-transmission-and-distribution/demand-response-management-system-drms/automated-demand-response-management-system-adrms",
                    active: true,
                    meta: {
                      tags: "Automated Demand Response Management System (ADRMS)",
                      keywords:
                        "Automated Demand Response Management System (ADRMS)",
                      description:
                        "Automated Demand Response Management System (ADRMS)",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bd2",
                },
                slug: "power-metering",
                title: "Power Metering",
                id: 7000371,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/power-metering",
                active: true,
                meta: {
                  tags: "Power Metering",
                  keywords: "Power Metering",
                  description: "Power Metering",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bd3",
                },
                slug: "power-transmission-tower",
                title: "Power Transmission Tower",
                id: 7000372,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/power-transmission-tower",
                active: true,
                meta: {
                  tags: "Power Transmission Tower",
                  keywords: "Power Transmission Tower",
                  description: "Power Transmission Tower",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bd6",
                },
                slug: "submarine-electricity-transmission-system",
                title: "Submarine Electricity Transmission System",
                id: 7000375,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/submarine-electricity-transmission-system",
                active: true,
                meta: {
                  tags: "Submarine Electricity Transmission System",
                  keywords: "Submarine Electricity Transmission System",
                  description: "Submarine Electricity Transmission System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bd7",
                },
                slug: "subsea-power-grid-system",
                title: "Subsea Power Grid System",
                id: 7000376,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/subsea-power-grid-system",
                active: true,
                meta: {
                  tags: "Subsea Power Grid System",
                  keywords: "Subsea Power Grid System",
                  description: "Subsea Power Grid System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bd8",
                },
                slug: "substation",
                title: "Substation",
                id: 7000377,
                parent:
                  "/energy-power/power/power-transmission-and-distribution",
                path: "/energy-power/power/power-transmission-and-distribution/substation",
                active: true,
                meta: {
                  tags: "Substation",
                  keywords: "Substation",
                  description: "Substation",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bd9",
                    },
                    slug: "gas-insulated-substation",
                    title: "Gas Insulated Substation",
                    id: 7000378,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/substation",
                    path: "/energy-power/power/power-transmission-and-distribution/substation/gas-insulated-substation",
                    active: true,
                    meta: {
                      tags: "Gas Insulated Substation",
                      keywords: "Gas Insulated Substation",
                      description: "Gas Insulated Substation",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bda",
                    },
                    slug: "modular-substation",
                    title: "Modular Substation",
                    id: 7000379,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/substation",
                    path: "/energy-power/power/power-transmission-and-distribution/substation/modular-substation",
                    active: true,
                    meta: {
                      tags: "Modular Substation",
                      keywords: "Modular Substation",
                      description: "Modular Substation",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bdc",
                    },
                    slug: "substation-grounding-system",
                    title: "Substation Grounding System",
                    id: 7000381,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/substation",
                    path: "/energy-power/power/power-transmission-and-distribution/substation/substation-grounding-system",
                    active: true,
                    meta: {
                      tags: "Substation Grounding System",
                      keywords: "Substation Grounding System",
                      description: "Substation Grounding System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bdb",
                    },
                    slug: "package-substation",
                    title: "Package Substation",
                    id: 7000380,
                    parent:
                      "/energy-power/power/power-transmission-and-distribution/substation",
                    path: "/energy-power/power/power-transmission-and-distribution/substation/package-substation",
                    active: true,
                    meta: {
                      tags: "Package Substation",
                      keywords: "Package Substation",
                      description: "Package Substation",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995bee",
            },
            slug: "utility-asset-management-services",
            title: "Utility Asset Management Services",
            id: 7000399,
            parent: "/energy-power/power",
            path: "/energy-power/power/utility-asset-management-services",
            active: true,
            meta: {
              tags: "Utility Asset Management Services",
              keywords: "Utility Asset Management Services",
              description: "Utility Asset Management Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995b86",
            },
            slug: "power-generation",
            title: "Power Generation",
            id: 7000295,
            parent: "/energy-power/power",
            path: "/energy-power/power/power-generation",
            active: true,
            meta: {
              tags: "Power Generation",
              keywords: "Power Generation",
              description: "Power Generation",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b87",
                },
                slug: "combined-heat-and-power",
                title: "Combined Heat and Power",
                id: 7000296,
                parent: "/energy-power/power/power-generation",
                path: "/energy-power/power/power-generation/combined-heat-and-power",
                active: true,
                meta: {
                  tags: "Combined Heat and Power",
                  keywords: "Combined Heat and Power",
                  description: "Combined Heat and Power",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b88",
                },
                slug: "distributed-power-generation",
                title: "Distributed Power Generation",
                id: 7000297,
                parent: "/energy-power/power/power-generation",
                path: "/energy-power/power/power-generation/distributed-power-generation",
                active: true,
                meta: {
                  tags: "Distributed Power Generation",
                  keywords: "Distributed Power Generation",
                  description: "Distributed Power Generation",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b89",
                    },
                    slug: "distributed-solar-power",
                    title: "Distributed Solar Power",
                    id: 7000298,
                    parent:
                      "/energy-power/power/power-generation/distributed-power-generation",
                    path: "/energy-power/power/power-generation/distributed-power-generation/distributed-solar-power",
                    active: true,
                    meta: {
                      tags: "Distributed Solar Power",
                      keywords: "Distributed Solar Power",
                      description: "Distributed Solar Power",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bb0",
                },
                slug: "thermal",
                title: "Thermal",
                id: 7000337,
                parent: "/energy-power/power/power-generation",
                path: "/energy-power/power/power-generation/thermal",
                active: true,
                meta: {
                  tags: "Thermal",
                  keywords: "Thermal",
                  description: "Thermal",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b8a",
                },
                slug: "power-generation-technologies",
                title: "Power Generation Technologies",
                id: 7000299,
                parent: "/energy-power/power/power-generation",
                path: "/energy-power/power/power-generation/power-generation-technologies",
                active: true,
                meta: {
                  tags: "Power Generation Technologies",
                  keywords: "Power Generation Technologies",
                  description: "Power Generation Technologies",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b8b",
                },
                slug: "power-plant",
                title: "Power Plant",
                id: 7000300,
                parent: "/energy-power/power/power-generation",
                path: "/energy-power/power/power-generation/power-plant",
                active: true,
                meta: {
                  tags: "Power Plant",
                  keywords: "Power Plant",
                  description: "Power Plant",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b8e",
                    },
                    slug: "power-plant-lightning-protection-services",
                    title: "Power Plant Lightning Protection Services",
                    id: 7000303,
                    parent: "/energy-power/power/power-generation/power-plant",
                    path: "/energy-power/power/power-generation/power-plant/power-plant-lightning-protection-services",
                    active: true,
                    meta: {
                      tags: "Power Plant Lightning Protection Services",
                      keywords: "Power Plant Lightning Protection Services",
                      description: "Power Plant Lightning Protection Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b8d",
                    },
                    slug: "floating-power-plant",
                    title: "Floating Power Plant",
                    id: 7000302,
                    parent: "/energy-power/power/power-generation/power-plant",
                    path: "/energy-power/power/power-generation/power-plant/floating-power-plant",
                    active: true,
                    meta: {
                      tags: "Floating Power Plant",
                      keywords: "Floating Power Plant",
                      description: "Floating Power Plant",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b8f",
                    },
                    slug: "power-plant-uranium",
                    title: "Power Plant Uranium",
                    id: 7000304,
                    parent: "/energy-power/power/power-generation/power-plant",
                    path: "/energy-power/power/power-generation/power-plant/power-plant-uranium",
                    active: true,
                    meta: {
                      tags: "Power Plant Uranium",
                      keywords: "Power Plant Uranium",
                      description: "Power Plant Uranium",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b90",
                    },
                    slug: "thermal-power-plant",
                    title: "Thermal Power Plant",
                    id: 7000305,
                    parent: "/energy-power/power/power-generation/power-plant",
                    path: "/energy-power/power/power-generation/power-plant/thermal-power-plant",
                    active: true,
                    meta: {
                      tags: "Thermal Power Plant",
                      keywords: "Thermal Power Plant",
                      description: "Thermal Power Plant",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b8c",
                    },
                    slug: "captive-power-plant",
                    title: "Captive Power Plant",
                    id: 7000301,
                    parent: "/energy-power/power/power-generation/power-plant",
                    path: "/energy-power/power/power-generation/power-plant/captive-power-plant",
                    active: true,
                    meta: {
                      tags: "Captive Power Plant",
                      keywords: "Captive Power Plant",
                      description: "Captive Power Plant",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995b91",
                },
                slug: "renewables",
                title: "Renewables",
                id: 7000306,
                parent: "/energy-power/power/power-generation",
                path: "/energy-power/power/power-generation/renewables",
                active: true,
                meta: {
                  tags: "Renewables",
                  keywords: "Renewables",
                  description: "Renewables",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b92",
                    },
                    slug: "bioenergy",
                    title: "Bioenergy",
                    id: 7000307,
                    parent: "/energy-power/power/power-generation/renewables",
                    path: "/energy-power/power/power-generation/renewables/bioenergy",
                    active: true,
                    meta: {
                      tags: "Bioenergy",
                      keywords: "Bioenergy",
                      description: "Bioenergy",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b94",
                        },
                        slug: "bio-ethanol",
                        title: "Bio-ethanol",
                        id: 7000309,
                        parent:
                          "/energy-power/power/power-generation/renewables/bioenergy",
                        path: "/energy-power/power/power-generation/renewables/bioenergy/bio-ethanol",
                        active: true,
                        meta: {
                          tags: "Bio-ethanol",
                          keywords: "Bio-ethanol",
                          description: "Bio-ethanol",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b97",
                        },
                        slug: "biogas",
                        title: "Biogas",
                        id: 7000312,
                        parent:
                          "/energy-power/power/power-generation/renewables/bioenergy",
                        path: "/energy-power/power/power-generation/renewables/bioenergy/biogas",
                        active: true,
                        meta: {
                          tags: "Biogas",
                          keywords: "Biogas",
                          description: "Biogas",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b98",
                        },
                        slug: "bioliquid-heat-and-power-generation",
                        title: "Bioliquid Heat and Power Generation",
                        id: 7000313,
                        parent:
                          "/energy-power/power/power-generation/renewables/bioenergy",
                        path: "/energy-power/power/power-generation/renewables/bioenergy/bioliquid-heat-and-power-generation",
                        active: true,
                        meta: {
                          tags: "Bioliquid Heat and Power Generation",
                          keywords: "Bioliquid Heat and Power Generation",
                          description: "Bioliquid Heat and Power Generation",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b93",
                        },
                        slug: "biodiesel",
                        title: "Biodiesel",
                        id: 7000308,
                        parent:
                          "/energy-power/power/power-generation/renewables/bioenergy",
                        path: "/energy-power/power/power-generation/renewables/bioenergy/biodiesel",
                        active: true,
                        meta: {
                          tags: "Biodiesel",
                          keywords: "Biodiesel",
                          description: "Biodiesel",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b95",
                        },
                        slug: "biofuel",
                        title: "Biofuel",
                        id: 7000310,
                        parent:
                          "/energy-power/power/power-generation/renewables/bioenergy",
                        path: "/energy-power/power/power-generation/renewables/bioenergy/biofuel",
                        active: true,
                        meta: {
                          tags: "Biofuel",
                          keywords: "Biofuel",
                          description: "Biofuel",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995b96",
                            },
                            slug: "advanced-biofuel",
                            title: "Advanced Biofuel",
                            id: 7000311,
                            parent:
                              "/energy-power/power/power-generation/renewables/bioenergy/biofuel",
                            path: "/energy-power/power/power-generation/renewables/bioenergy/biofuel/advanced-biofuel",
                            active: true,
                            meta: {
                              tags: "Advanced Biofuel",
                              keywords: "Advanced Biofuel",
                              description: "Advanced Biofuel",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b9b",
                    },
                    slug: "solar-power",
                    title: "Solar Power",
                    id: 7000316,
                    parent: "/energy-power/power/power-generation/renewables",
                    path: "/energy-power/power/power-generation/renewables/solar-power",
                    active: true,
                    meta: {
                      tags: "Solar Power",
                      keywords: "Solar Power",
                      description: "Solar Power",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b9c",
                        },
                        slug: "concentrated-solar-power-csp",
                        title: "Concentrated Solar Power (CSP)",
                        id: 7000317,
                        parent:
                          "/energy-power/power/power-generation/renewables/solar-power",
                        path: "/energy-power/power/power-generation/renewables/solar-power/concentrated-solar-power-csp",
                        active: true,
                        meta: {
                          tags: "Concentrated Solar Power (CSP)",
                          keywords: "Concentrated Solar Power (CSP)",
                          description: "Concentrated Solar Power (CSP)",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995b9d",
                            },
                            slug: "parabolic-trough-csp",
                            title: "Parabolic Trough CSP",
                            id: 7000318,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/concentrated-solar-power-csp",
                            path: "/energy-power/power/power-generation/renewables/solar-power/concentrated-solar-power-csp/parabolic-trough-csp",
                            active: true,
                            meta: {
                              tags: "Parabolic Trough CSP",
                              keywords: "Parabolic Trough CSP",
                              description: "Parabolic Trough CSP",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995b9e",
                            },
                            slug: "solar-thermal",
                            title: "Solar Thermal",
                            id: 7000319,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/concentrated-solar-power-csp",
                            path: "/energy-power/power/power-generation/renewables/solar-power/concentrated-solar-power-csp/solar-thermal",
                            active: true,
                            meta: {
                              tags: "Solar Thermal",
                              keywords: "Solar Thermal",
                              description: "Solar Thermal",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995ba0",
                        },
                        slug: "solar-pv",
                        title: "Solar PV",
                        id: 7000321,
                        parent:
                          "/energy-power/power/power-generation/renewables/solar-power",
                        path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                        active: true,
                        meta: {
                          tags: "Solar PV",
                          keywords: "Solar PV",
                          description: "Solar PV",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995ba2",
                            },
                            slug: "crystalline-silicon-solar-pv",
                            title: "Crystalline Silicon Solar PV",
                            id: 7000323,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/crystalline-silicon-solar-pv",
                            active: true,
                            meta: {
                              tags: "Crystalline Silicon Solar PV",
                              keywords: "Crystalline Silicon Solar PV",
                              description: "Crystalline Silicon Solar PV",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995ba6",
                            },
                            slug: "rooftop-solar",
                            title: "Rooftop Solar",
                            id: 7000327,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/rooftop-solar",
                            active: true,
                            meta: {
                              tags: "Rooftop Solar",
                              keywords: "Rooftop Solar",
                              description: "Rooftop Solar",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995ba5",
                            },
                            slug: "polymer-solar-cell",
                            title: "Polymer Solar Cell",
                            id: 7000326,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/polymer-solar-cell",
                            active: true,
                            meta: {
                              tags: "Polymer Solar Cell",
                              keywords: "Polymer Solar Cell",
                              description: "Polymer Solar Cell",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995ba3",
                            },
                            slug: "fixed-tilt-solar-pv",
                            title: "Fixed Tilt Solar PV",
                            id: 7000324,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/fixed-tilt-solar-pv",
                            active: true,
                            meta: {
                              tags: "Fixed Tilt Solar PV",
                              keywords: "Fixed Tilt Solar PV",
                              description: "Fixed Tilt Solar PV",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995ba4",
                            },
                            slug: "polycrystalline-solar-cell",
                            title: "Polycrystalline Solar Cell",
                            id: 7000325,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/polycrystalline-solar-cell",
                            active: true,
                            meta: {
                              tags: "Polycrystalline Solar Cell",
                              keywords: "Polycrystalline Solar Cell",
                              description: "Polycrystalline Solar Cell",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995ba8",
                            },
                            slug: "solar-panel-recycling",
                            title: "Solar Panel Recycling",
                            id: 7000329,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/solar-panel-recycling",
                            active: true,
                            meta: {
                              tags: "Solar Panel Recycling",
                              keywords: "Solar Panel Recycling",
                              description: "Solar Panel Recycling",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995baa",
                            },
                            slug: "thin-film-solar-pv",
                            title: "Thin Film Solar PV",
                            id: 7000331,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/thin-film-solar-pv",
                            active: true,
                            meta: {
                              tags: "Thin Film Solar PV",
                              keywords: "Thin Film Solar PV",
                              description: "Thin Film Solar PV",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995ba7",
                            },
                            slug: "solar-backsheet",
                            title: "Solar Backsheet",
                            id: 7000328,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/solar-backsheet",
                            active: true,
                            meta: {
                              tags: "Solar Backsheet",
                              keywords: "Solar Backsheet",
                              description: "Solar Backsheet",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995ba9",
                            },
                            slug: "solar-pv-wafer",
                            title: "Solar PV Wafer",
                            id: 7000330,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/solar-pv-wafer",
                            active: true,
                            meta: {
                              tags: "Solar PV Wafer",
                              keywords: "Solar PV Wafer",
                              description: "Solar PV Wafer",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9c9f476e848e83995ba1",
                            },
                            slug: "bipv",
                            title: "BIPV",
                            id: 7000322,
                            parent:
                              "/energy-power/power/power-generation/renewables/solar-power/solar-pv",
                            path: "/energy-power/power/power-generation/renewables/solar-power/solar-pv/bipv",
                            active: true,
                            meta: {
                              tags: "BIPV",
                              keywords: "BIPV",
                              description: "BIPV",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995b9f",
                        },
                        slug: "off-grid-solar-energy",
                        title: "Off-grid Solar Energy",
                        id: 7000320,
                        parent:
                          "/energy-power/power/power-generation/renewables/solar-power",
                        path: "/energy-power/power/power-generation/renewables/solar-power/off-grid-solar-energy",
                        active: true,
                        meta: {
                          tags: "Off-grid Solar Energy",
                          keywords: "Off-grid Solar Energy",
                          description: "Off-grid Solar Energy",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b99",
                    },
                    slug: "geothermal",
                    title: "Geothermal",
                    id: 7000314,
                    parent: "/energy-power/power/power-generation/renewables",
                    path: "/energy-power/power/power-generation/renewables/geothermal",
                    active: true,
                    meta: {
                      tags: "Geothermal",
                      keywords: "Geothermal",
                      description: "Geothermal",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bac",
                    },
                    slug: "waste-to-energy",
                    title: "Waste to Energy",
                    id: 7000333,
                    parent: "/energy-power/power/power-generation/renewables",
                    path: "/energy-power/power/power-generation/renewables/waste-to-energy",
                    active: true,
                    meta: {
                      tags: "Waste to Energy",
                      keywords: "Waste to Energy",
                      description: "Waste to Energy",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995b9a",
                    },
                    slug: "hydropower",
                    title: "Hydropower",
                    id: 7000315,
                    parent: "/energy-power/power/power-generation/renewables",
                    path: "/energy-power/power/power-generation/renewables/hydropower",
                    active: true,
                    meta: {
                      tags: "Hydropower",
                      keywords: "Hydropower",
                      description: "Hydropower",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bab",
                    },
                    slug: "tidal-power",
                    title: "Tidal Power",
                    id: 7000332,
                    parent: "/energy-power/power/power-generation/renewables",
                    path: "/energy-power/power/power-generation/renewables/tidal-power",
                    active: true,
                    meta: {
                      tags: "Tidal Power",
                      keywords: "Tidal Power",
                      description: "Tidal Power",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bad",
                    },
                    slug: "wind-power",
                    title: "Wind Power",
                    id: 7000334,
                    parent: "/energy-power/power/power-generation/renewables",
                    path: "/energy-power/power/power-generation/renewables/wind-power",
                    active: true,
                    meta: {
                      tags: "Wind Power",
                      keywords: "Wind Power",
                      description: "Wind Power",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995bae",
                        },
                        slug: "floating-offshore-wind-power",
                        title: "Floating Offshore Wind Power",
                        id: 7000335,
                        parent:
                          "/energy-power/power/power-generation/renewables/wind-power",
                        path: "/energy-power/power/power-generation/renewables/wind-power/floating-offshore-wind-power",
                        active: true,
                        meta: {
                          tags: "Floating Offshore Wind Power",
                          keywords: "Floating Offshore Wind Power",
                          description: "Floating Offshore Wind Power",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995baf",
                        },
                        slug: "offshore-wind-energy",
                        title: "Offshore Wind Energy",
                        id: 7000336,
                        parent:
                          "/energy-power/power/power-generation/renewables/wind-power",
                        path: "/energy-power/power/power-generation/renewables/wind-power/offshore-wind-energy",
                        active: true,
                        meta: {
                          tags: "Offshore Wind Energy",
                          keywords: "Offshore Wind Energy",
                          description: "Offshore Wind Energy",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bb1",
                },
                slug: "thermal-power",
                title: "Thermal Power",
                id: 7000338,
                parent: "/energy-power/power/power-generation",
                path: "/energy-power/power/power-generation/thermal-power",
                active: true,
                meta: {
                  tags: "Thermal Power",
                  keywords: "Thermal Power",
                  description: "Thermal Power",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bb2",
                    },
                    slug: "coal-fired-power-generation",
                    title: "Coal Fired Power Generation",
                    id: 7000339,
                    parent:
                      "/energy-power/power/power-generation/thermal-power",
                    path: "/energy-power/power/power-generation/thermal-power/coal-fired-power-generation",
                    active: true,
                    meta: {
                      tags: "Coal Fired Power Generation",
                      keywords: "Coal Fired Power Generation",
                      description: "Coal Fired Power Generation",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bb3",
                    },
                    slug: "nuclear-power",
                    title: "Nuclear Power",
                    id: 7000340,
                    parent:
                      "/energy-power/power/power-generation/thermal-power",
                    path: "/energy-power/power/power-generation/thermal-power/nuclear-power",
                    active: true,
                    meta: {
                      tags: "Nuclear Power",
                      keywords: "Nuclear Power",
                      description: "Nuclear Power",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995bb4",
                        },
                        slug: "nuclear-waste-management",
                        title: "Nuclear Waste Management",
                        id: 7000341,
                        parent:
                          "/energy-power/power/power-generation/thermal-power/nuclear-power",
                        path: "/energy-power/power/power-generation/thermal-power/nuclear-power/nuclear-waste-management",
                        active: true,
                        meta: {
                          tags: "Nuclear Waste Management",
                          keywords: "Nuclear Waste Management",
                          description: "Nuclear Waste Management",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9c9f476e848e83995a62",
        },
        slug: "battery",
        title: "Battery",
        id: 57624,
        parent: "/energy-power",
        path: "/energy-power/battery",
        active: true,
        meta: {
          tags: "Battery",
          keywords: "Battery",
          description: "Battery",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a6a",
            },
            slug: "battery-recycling",
            title: "Battery Recycling",
            id: 7000009,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/battery-recycling",
            active: true,
            meta: {
              tags: "Battery Recycling",
              keywords: "Battery Recycling",
              description: "Battery Recycling",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a6b",
                },
                slug: "lithium-ion-battery-recycling",
                title: "Lithium-ion Battery Recycling",
                id: 7000010,
                parent: "/energy-power/battery/battery-recycling",
                path: "/energy-power/battery/battery-recycling/lithium-ion-battery-recycling",
                active: true,
                meta: {
                  tags: "Lithium-ion Battery Recycling",
                  keywords: "Lithium-ion Battery Recycling",
                  description: "Lithium-ion Battery Recycling",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a64",
            },
            slug: "battery-cell",
            title: "Battery Cell",
            id: 7000003,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/battery-cell",
            active: true,
            meta: {
              tags: "Battery Cell",
              keywords: "Battery Cell",
              description: "Battery Cell",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a74",
            },
            slug: "lead-acid-battery",
            title: "Lead Acid Battery",
            id: 7000019,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/lead-acid-battery",
            active: true,
            meta: {
              tags: "Lead Acid Battery",
              keywords: "Lead Acid Battery",
              description: "Lead Acid Battery",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a75",
                },
                slug: "stationary-lead-acid-battery",
                title: "Stationary Lead Acid Battery",
                id: 7000020,
                parent: "/energy-power/battery/lead-acid-battery",
                path: "/energy-power/battery/lead-acid-battery/stationary-lead-acid-battery",
                active: true,
                meta: {
                  tags: "Stationary Lead Acid Battery",
                  keywords: "Stationary Lead Acid Battery",
                  description: "Stationary Lead Acid Battery",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a63",
            },
            slug: "alkaline-battery",
            title: "Alkaline Battery",
            id: 7000002,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/alkaline-battery",
            active: true,
            meta: {
              tags: "Alkaline Battery",
              keywords: "Alkaline Battery",
              description: "Alkaline Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a65",
            },
            slug: "battery-electrolyte",
            title: "Battery Electrolyte",
            id: 7000004,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/battery-electrolyte",
            active: true,
            meta: {
              tags: "Battery Electrolyte",
              keywords: "Battery Electrolyte",
              description: "Battery Electrolyte",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a66",
                },
                slug: "solid-electrolyte",
                title: "Solid Electrolyte",
                id: 7000005,
                parent: "/energy-power/battery/battery-electrolyte",
                path: "/energy-power/battery/battery-electrolyte/solid-electrolyte",
                active: true,
                meta: {
                  tags: "Solid Electrolyte",
                  keywords: "Solid Electrolyte",
                  description: "Solid Electrolyte",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a73",
            },
            slug: "industrial-battery",
            title: "Industrial Battery",
            id: 7000018,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/industrial-battery",
            active: true,
            meta: {
              tags: "Industrial Battery",
              keywords: "Industrial Battery",
              description: "Industrial Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a69",
            },
            slug: "battery-management-system",
            title: "Battery Management System",
            id: 7000008,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/battery-management-system",
            active: true,
            meta: {
              tags: "Battery Management System",
              keywords: "Battery Management System",
              description: "Battery Management System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a6c",
            },
            slug: "consumer-battery",
            title: "Consumer Battery",
            id: 7000011,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/consumer-battery",
            active: true,
            meta: {
              tags: "Consumer Battery",
              keywords: "Consumer Battery",
              description: "Consumer Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a7c",
            },
            slug: "residential-battery",
            title: "Residential Battery",
            id: 7000027,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/residential-battery",
            active: true,
            meta: {
              tags: "Residential Battery",
              keywords: "Residential Battery",
              description: "Residential Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a7d",
            },
            slug: "secondary-battery",
            title: "Secondary Battery",
            id: 7000028,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/secondary-battery",
            active: true,
            meta: {
              tags: "Secondary Battery",
              keywords: "Secondary Battery",
              description: "Secondary Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a6d",
            },
            slug: "electric-vehicle-battery",
            title: "Electric Vehicle Battery",
            id: 7000012,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/electric-vehicle-battery",
            active: true,
            meta: {
              tags: "Electric Vehicle Battery",
              keywords: "Electric Vehicle Battery",
              description: "Electric Vehicle Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a76",
            },
            slug: "lithium-battery",
            title: "Lithium Battery",
            id: 7000021,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/lithium-battery",
            active: true,
            meta: {
              tags: "Lithium Battery",
              keywords: "Lithium Battery",
              description: "Lithium Battery",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a77",
                },
                slug: "lithium-air-battery",
                title: "Lithium-Air Battery",
                id: 7000022,
                parent: "/energy-power/battery/lithium-battery",
                path: "/energy-power/battery/lithium-battery/lithium-air-battery",
                active: true,
                meta: {
                  tags: "Lithium-Air Battery",
                  keywords: "Lithium-Air Battery",
                  description: "Lithium-Air Battery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a78",
                },
                slug: "lithium-ion-battery",
                title: "Lithium-ion Battery",
                id: 7000023,
                parent: "/energy-power/battery/lithium-battery",
                path: "/energy-power/battery/lithium-battery/lithium-ion-battery",
                active: true,
                meta: {
                  tags: "Lithium-ion Battery",
                  keywords: "Lithium-ion Battery",
                  description: "Lithium-ion Battery",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995a79",
                    },
                    slug: "lithium-ion-battery-separator",
                    title: "Lithium-ion Battery Separator",
                    id: 7000024,
                    parent:
                      "/energy-power/battery/lithium-battery/lithium-ion-battery",
                    path: "/energy-power/battery/lithium-battery/lithium-ion-battery/lithium-ion-battery-separator",
                    active: true,
                    meta: {
                      tags: "Lithium-ion Battery Separator",
                      keywords: "Lithium-ion Battery Separator",
                      description: "Lithium-ion Battery Separator",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a80",
            },
            slug: "sodium-sulphur-battery",
            title: "Sodium-Sulphur Battery",
            id: 7000031,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/sodium-sulphur-battery",
            active: true,
            meta: {
              tags: "Sodium-Sulphur Battery",
              keywords: "Sodium-Sulphur Battery",
              description: "Sodium-Sulphur Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a70",
            },
            slug: "flow-battery",
            title: "Flow Battery",
            id: 7000015,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/flow-battery",
            active: true,
            meta: {
              tags: "Flow Battery",
              keywords: "Flow Battery",
              description: "Flow Battery",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a71",
                },
                slug: "vanadium-redox-flow-battery",
                title: "Vanadium Redox Flow Battery",
                id: 7000016,
                parent: "/energy-power/battery/flow-battery",
                path: "/energy-power/battery/flow-battery/vanadium-redox-flow-battery",
                active: true,
                meta: {
                  tags: "Vanadium Redox Flow Battery",
                  keywords: "Vanadium Redox Flow Battery",
                  description: "Vanadium Redox Flow Battery",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a7b",
            },
            slug: "primary-battery",
            title: "Primary Battery",
            id: 7000026,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/primary-battery",
            active: true,
            meta: {
              tags: "Primary Battery",
              keywords: "Primary Battery",
              description: "Primary Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a67",
            },
            slug: "battery-infrastructure",
            title: "Battery Infrastructure",
            id: 7000006,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/battery-infrastructure",
            active: true,
            meta: {
              tags: "Battery Infrastructure",
              keywords: "Battery Infrastructure",
              description: "Battery Infrastructure",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a68",
                },
                slug: "electric-vehicle-ev-charging-equipment",
                title: "Electric Vehicle (EV) Charging Equipment",
                id: 7000007,
                parent: "/energy-power/battery/battery-infrastructure",
                path: "/energy-power/battery/battery-infrastructure/electric-vehicle-ev-charging-equipment",
                active: true,
                meta: {
                  tags: "Electric Vehicle (EV) Charging Equipment",
                  keywords: "Electric Vehicle (EV) Charging Equipment",
                  description: "Electric Vehicle (EV) Charging Equipment",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a6e",
            },
            slug: "emerging-battery-technologies",
            title: "Emerging Battery Technologies",
            id: 7000013,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/emerging-battery-technologies",
            active: true,
            meta: {
              tags: "Emerging Battery Technologies",
              keywords: "Emerging Battery Technologies",
              description: "Emerging Battery Technologies",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a6f",
                },
                slug: "dual-carbon-battery",
                title: "Dual Carbon Battery",
                id: 7000014,
                parent: "/energy-power/battery/emerging-battery-technologies",
                path: "/energy-power/battery/emerging-battery-technologies/dual-carbon-battery",
                active: true,
                meta: {
                  tags: "Dual Carbon Battery",
                  keywords: "Dual Carbon Battery",
                  description: "Dual Carbon Battery",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a7e",
            },
            slug: "semiconductor-battery",
            title: "Semiconductor Battery",
            id: 7000029,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/semiconductor-battery",
            active: true,
            meta: {
              tags: "Semiconductor Battery",
              keywords: "Semiconductor Battery",
              description: "Semiconductor Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a72",
            },
            slug: "grid-scale-battery",
            title: "Grid Scale Battery",
            id: 7000017,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/grid-scale-battery",
            active: true,
            meta: {
              tags: "Grid Scale Battery",
              keywords: "Grid Scale Battery",
              description: "Grid Scale Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a7f",
            },
            slug: "sodium-ion-battery",
            title: "Sodium-ion Battery",
            id: 7000030,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/sodium-ion-battery",
            active: true,
            meta: {
              tags: "Sodium-ion Battery",
              keywords: "Sodium-ion Battery",
              description: "Sodium-ion Battery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a7a",
            },
            slug: "power-tool-battery",
            title: "Power Tool Battery",
            id: 7000025,
            parent: "/energy-power/battery",
            path: "/energy-power/battery/power-tool-battery",
            active: true,
            meta: {
              tags: "Power Tool Battery",
              keywords: "Power Tool Battery",
              description: "Power Tool Battery",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9c9f476e848e83995a99",
        },
        slug: "environmental-control-solutions",
        title: "Environmental Control Solutions",
        id: 57628,
        parent: "/energy-power",
        path: "/energy-power/environmental-control-solutions",
        active: true,
        meta: {
          tags: "Environmental Control Solutions",
          keywords: "Environmental Control Solutions",
          description: "Environmental Control Solutions",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a9c",
            },
            slug: "air-quality-control-system",
            title: "Air Quality Control System",
            id: 7000059,
            parent: "/energy-power/environmental-control-solutions",
            path: "/energy-power/environmental-control-solutions/air-quality-control-system",
            active: true,
            meta: {
              tags: "Air Quality Control System",
              keywords: "Air Quality Control System",
              description: "Air Quality Control System",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a9d",
                },
                slug: "air-scrubber",
                title: "Air Scrubber",
                id: 7000060,
                parent:
                  "/energy-power/environmental-control-solutions/air-quality-control-system",
                path: "/energy-power/environmental-control-solutions/air-quality-control-system/air-scrubber",
                active: true,
                meta: {
                  tags: "Air Scrubber",
                  keywords: "Air Scrubber",
                  description: "Air Scrubber",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a9e",
                },
                slug: "flue-gas-desulfurization-fgd",
                title: "Flue Gas Desulfurization (FGD)",
                id: 7000061,
                parent:
                  "/energy-power/environmental-control-solutions/air-quality-control-system",
                path: "/energy-power/environmental-control-solutions/air-quality-control-system/flue-gas-desulfurization-fgd",
                active: true,
                meta: {
                  tags: "Flue Gas Desulfurization (FGD)",
                  keywords: "Flue Gas Desulfurization (FGD)",
                  description: "Flue Gas Desulfurization (FGD)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a9f",
                },
                slug: "industrial-air-quality-control-system",
                title: "Industrial Air Quality Control System",
                id: 7000062,
                parent:
                  "/energy-power/environmental-control-solutions/air-quality-control-system",
                path: "/energy-power/environmental-control-solutions/air-quality-control-system/industrial-air-quality-control-system",
                active: true,
                meta: {
                  tags: "Industrial Air Quality Control System",
                  keywords: "Industrial Air Quality Control System",
                  description: "Industrial Air Quality Control System",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aa0",
            },
            slug: "air-quality-monitoring",
            title: "Air Quality Monitoring",
            id: 7000063,
            parent: "/energy-power/environmental-control-solutions",
            path: "/energy-power/environmental-control-solutions/air-quality-monitoring",
            active: true,
            meta: {
              tags: "Air Quality Monitoring",
              keywords: "Air Quality Monitoring",
              description: "Air Quality Monitoring",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a9a",
            },
            slug: "air-purification",
            title: "Air Purification",
            id: 7000057,
            parent: "/energy-power/environmental-control-solutions",
            path: "/energy-power/environmental-control-solutions/air-purification",
            active: true,
            meta: {
              tags: "Air Purification",
              keywords: "Air Purification",
              description: "Air Purification",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a9b",
                },
                slug: "residential-air-purifier",
                title: "Residential Air Purifier",
                id: 7000058,
                parent:
                  "/energy-power/environmental-control-solutions/air-purification",
                path: "/energy-power/environmental-control-solutions/air-purification/residential-air-purifier",
                active: true,
                meta: {
                  tags: "Residential Air Purifier",
                  keywords: "Residential Air Purifier",
                  description: "Residential Air Purifier",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aa2",
            },
            slug: "turbine-air-filtration",
            title: "Turbine Air Filtration",
            id: 7000065,
            parent: "/energy-power/environmental-control-solutions",
            path: "/energy-power/environmental-control-solutions/turbine-air-filtration",
            active: true,
            meta: {
              tags: "Turbine Air Filtration",
              keywords: "Turbine Air Filtration",
              description: "Turbine Air Filtration",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aa1",
            },
            slug: "portable-filtration-system",
            title: "Portable Filtration System",
            id: 7000064,
            parent: "/energy-power/environmental-control-solutions",
            path: "/energy-power/environmental-control-solutions/portable-filtration-system",
            active: true,
            meta: {
              tags: "Portable Filtration System",
              keywords: "Portable Filtration System",
              description: "Portable Filtration System",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9c9f476e848e83995bef",
        },
        slug: "power-equipment",
        title: "Power Equipment",
        id: 57631,
        parent: "/energy-power",
        path: "/energy-power/power-equipment",
        active: true,
        meta: {
          tags: "Power Equipment",
          keywords: "Power Equipment",
          description: "Power Equipment",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995bf0",
            },
            slug: "arc-flash-protection",
            title: "Arc Flash Protection",
            id: 7000401,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/arc-flash-protection",
            active: true,
            meta: {
              tags: "Arc Flash Protection",
              keywords: "Arc Flash Protection",
              description: "Arc Flash Protection",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995bf2",
            },
            slug: "boilers",
            title: "Boilers",
            id: 7000403,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/boilers",
            active: true,
            meta: {
              tags: "Boilers",
              keywords: "Boilers",
              description: "Boilers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bf3",
                },
                slug: "advanced-utility-boiler",
                title: "Advanced Utility Boiler",
                id: 7000404,
                parent: "/energy-power/power-equipment/boilers",
                path: "/energy-power/power-equipment/boilers/advanced-utility-boiler",
                active: true,
                meta: {
                  tags: "Advanced Utility Boiler",
                  keywords: "Advanced Utility Boiler",
                  description: "Advanced Utility Boiler",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bf4",
                },
                slug: "boiler-control",
                title: "Boiler Control",
                id: 7000405,
                parent: "/energy-power/power-equipment/boilers",
                path: "/energy-power/power-equipment/boilers/boiler-control",
                active: true,
                meta: {
                  tags: "Boiler Control",
                  keywords: "Boiler Control",
                  description: "Boiler Control",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bf5",
                },
                slug: "steam-boiler",
                title: "Steam Boiler",
                id: 7000406,
                parent: "/energy-power/power-equipment/boilers",
                path: "/energy-power/power-equipment/boilers/steam-boiler",
                active: true,
                meta: {
                  tags: "Steam Boiler",
                  keywords: "Steam Boiler",
                  description: "Steam Boiler",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c16",
            },
            slug: "power-monitoring-system",
            title: "Power Monitoring System",
            id: 7000439,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/power-monitoring-system",
            active: true,
            meta: {
              tags: "Power Monitoring System",
              keywords: "Power Monitoring System",
              description: "Power Monitoring System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c18",
            },
            slug: "solar-equipment",
            title: "Solar Equipment",
            id: 7000441,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/solar-equipment",
            active: true,
            meta: {
              tags: "Solar Equipment",
              keywords: "Solar Equipment",
              description: "Solar Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c19",
                },
                slug: "solar-power-window",
                title: "Solar Power Window",
                id: 7000442,
                parent: "/energy-power/power-equipment/solar-equipment",
                path: "/energy-power/power-equipment/solar-equipment/solar-power-window",
                active: true,
                meta: {
                  tags: "Solar Power Window",
                  keywords: "Solar Power Window",
                  description: "Solar Power Window",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c1c",
                },
                slug: "solar-tracker",
                title: "Solar Tracker",
                id: 7000445,
                parent: "/energy-power/power-equipment/solar-equipment",
                path: "/energy-power/power-equipment/solar-equipment/solar-tracker",
                active: true,
                meta: {
                  tags: "Solar Tracker",
                  keywords: "Solar Tracker",
                  description: "Solar Tracker",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c1d",
                    },
                    slug: "single-axis-solar-tracker",
                    title: "Single Axis Solar Tracker",
                    id: 7000446,
                    parent:
                      "/energy-power/power-equipment/solar-equipment/solar-tracker",
                    path: "/energy-power/power-equipment/solar-equipment/solar-tracker/single-axis-solar-tracker",
                    active: true,
                    meta: {
                      tags: "Single Axis Solar Tracker",
                      keywords: "Single Axis Solar Tracker",
                      description: "Single Axis Solar Tracker",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c1a",
                },
                slug: "solar-pv-inverter",
                title: "Solar PV Inverter",
                id: 7000443,
                parent: "/energy-power/power-equipment/solar-equipment",
                path: "/energy-power/power-equipment/solar-equipment/solar-pv-inverter",
                active: true,
                meta: {
                  tags: "Solar PV Inverter",
                  keywords: "Solar PV Inverter",
                  description: "Solar PV Inverter",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c1b",
                    },
                    slug: "string-inverter",
                    title: "String Inverter",
                    id: 7000444,
                    parent:
                      "/energy-power/power-equipment/solar-equipment/solar-pv-inverter",
                    path: "/energy-power/power-equipment/solar-equipment/solar-pv-inverter/string-inverter",
                    active: true,
                    meta: {
                      tags: "String Inverter",
                      keywords: "String Inverter",
                      description: "String Inverter",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c1e",
                },
                slug: "solar-water-heater",
                title: "Solar Water Heater",
                id: 7000447,
                parent: "/energy-power/power-equipment/solar-equipment",
                path: "/energy-power/power-equipment/solar-equipment/solar-water-heater",
                active: true,
                meta: {
                  tags: "Solar Water Heater",
                  keywords: "Solar Water Heater",
                  description: "Solar Water Heater",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c1f",
                },
                slug: "solar-water-pumps",
                title: "Solar Water Pumps",
                id: 7000448,
                parent: "/energy-power/power-equipment/solar-equipment",
                path: "/energy-power/power-equipment/solar-equipment/solar-water-pumps",
                active: true,
                meta: {
                  tags: "Solar Water Pumps",
                  keywords: "Solar Water Pumps",
                  description: "Solar Water Pumps",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c22",
            },
            slug: "turbines",
            title: "Turbines",
            id: 7000451,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/turbines",
            active: true,
            meta: {
              tags: "Turbines",
              keywords: "Turbines",
              description: "Turbines",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c25",
                },
                slug: "steam-turbine",
                title: "Steam Turbine",
                id: 7000454,
                parent: "/energy-power/power-equipment/turbines",
                path: "/energy-power/power-equipment/turbines/steam-turbine",
                active: true,
                meta: {
                  tags: "Steam Turbine",
                  keywords: "Steam Turbine",
                  description: "Steam Turbine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c23",
                },
                slug: "gas-turbine",
                title: "Gas Turbine",
                id: 7000452,
                parent: "/energy-power/power-equipment/turbines",
                path: "/energy-power/power-equipment/turbines/gas-turbine",
                active: true,
                meta: {
                  tags: "Gas Turbine",
                  keywords: "Gas Turbine",
                  description: "Gas Turbine",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c24",
                    },
                    slug: "gas-turbine-mro",
                    title: "Gas Turbine MRO",
                    id: 7000453,
                    parent:
                      "/energy-power/power-equipment/turbines/gas-turbine",
                    path: "/energy-power/power-equipment/turbines/gas-turbine/gas-turbine-mro",
                    active: true,
                    meta: {
                      tags: "Gas Turbine MRO",
                      keywords: "Gas Turbine MRO",
                      description: "Gas Turbine MRO",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c26",
                },
                slug: "turbo-expander",
                title: "Turbo Expander",
                id: 7000455,
                parent: "/energy-power/power-equipment/turbines",
                path: "/energy-power/power-equipment/turbines/turbo-expander",
                active: true,
                meta: {
                  tags: "Turbo Expander",
                  keywords: "Turbo Expander",
                  description: "Turbo Expander",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995bfe",
            },
            slug: "engine",
            title: "Engine",
            id: 7000415,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/engine",
            active: true,
            meta: {
              tags: "Engine",
              keywords: "Engine",
              description: "Engine",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bff",
                },
                slug: "diesel-power-engine",
                title: "Diesel Power Engine",
                id: 7000416,
                parent: "/energy-power/power-equipment/engine",
                path: "/energy-power/power-equipment/engine/diesel-power-engine",
                active: true,
                meta: {
                  tags: "Diesel Power Engine",
                  keywords: "Diesel Power Engine",
                  description: "Diesel Power Engine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c00",
                },
                slug: "gas-engine",
                title: "Gas Engine",
                id: 7000417,
                parent: "/energy-power/power-equipment/engine",
                path: "/energy-power/power-equipment/engine/gas-engine",
                active: true,
                meta: {
                  tags: "Gas Engine",
                  keywords: "Gas Engine",
                  description: "Gas Engine",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c01",
                    },
                    slug: "small-gas-engine",
                    title: "Small Gas Engine",
                    id: 7000418,
                    parent: "/energy-power/power-equipment/engine/gas-engine",
                    path: "/energy-power/power-equipment/engine/gas-engine/small-gas-engine",
                    active: true,
                    meta: {
                      tags: "Small Gas Engine",
                      keywords: "Small Gas Engine",
                      description: "Small Gas Engine",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c03",
            },
            slug: "generator",
            title: "Generator",
            id: 7000420,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/generator",
            active: true,
            meta: {
              tags: "Generator",
              keywords: "Generator",
              description: "Generator",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c0a",
                },
                slug: "turbo-generator",
                title: "Turbo Generator",
                id: 7000427,
                parent: "/energy-power/power-equipment/generator",
                path: "/energy-power/power-equipment/generator/turbo-generator",
                active: true,
                meta: {
                  tags: "Turbo Generator",
                  keywords: "Turbo Generator",
                  description: "Turbo Generator",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c04",
                },
                slug: "black-start-generator",
                title: "Black Start Generator",
                id: 7000421,
                parent: "/energy-power/power-equipment/generator",
                path: "/energy-power/power-equipment/generator/black-start-generator",
                active: true,
                meta: {
                  tags: "Black Start Generator",
                  keywords: "Black Start Generator",
                  description: "Black Start Generator",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c05",
                },
                slug: "diesel-generator",
                title: "Diesel Generator",
                id: 7000422,
                parent: "/energy-power/power-equipment/generator",
                path: "/energy-power/power-equipment/generator/diesel-generator",
                active: true,
                meta: {
                  tags: "Diesel Generator",
                  keywords: "Diesel Generator",
                  description: "Diesel Generator",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c06",
                },
                slug: "gas-generator",
                title: "Gas Generator",
                id: 7000423,
                parent: "/energy-power/power-equipment/generator",
                path: "/energy-power/power-equipment/generator/gas-generator",
                active: true,
                meta: {
                  tags: "Gas Generator",
                  keywords: "Gas Generator",
                  description: "Gas Generator",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c08",
                },
                slug: "power-generator-rental",
                title: "Power Generator Rental",
                id: 7000425,
                parent: "/energy-power/power-equipment/generator",
                path: "/energy-power/power-equipment/generator/power-generator-rental",
                active: true,
                meta: {
                  tags: "Power Generator Rental",
                  keywords: "Power Generator Rental",
                  description: "Power Generator Rental",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c07",
                },
                slug: "portable-generator",
                title: "Portable Generator",
                id: 7000424,
                parent: "/energy-power/power-equipment/generator",
                path: "/energy-power/power-equipment/generator/portable-generator",
                active: true,
                meta: {
                  tags: "Portable Generator",
                  keywords: "Portable Generator",
                  description: "Portable Generator",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c09",
                },
                slug: "temporary-power",
                title: "Temporary Power",
                id: 7000426,
                parent: "/energy-power/power-equipment/generator",
                path: "/energy-power/power-equipment/generator/temporary-power",
                active: true,
                meta: {
                  tags: "Temporary Power",
                  keywords: "Temporary Power",
                  description: "Temporary Power",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c21",
            },
            slug: "timing-relay",
            title: "Timing Relay",
            id: 7000450,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/timing-relay",
            active: true,
            meta: {
              tags: "Timing Relay",
              keywords: "Timing Relay",
              description: "Timing Relay",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c29",
            },
            slug: "wind-power-equipment",
            title: "Wind Power Equipment",
            id: 7000458,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/wind-power-equipment",
            active: true,
            meta: {
              tags: "Wind Power Equipment",
              keywords: "Wind Power Equipment",
              description: "Wind Power Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c2a",
                },
                slug: "wind-turbine",
                title: "Wind Turbine",
                id: 7000459,
                parent: "/energy-power/power-equipment/wind-power-equipment",
                path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                active: true,
                meta: {
                  tags: "Wind Turbine",
                  keywords: "Wind Turbine",
                  description: "Wind Turbine",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c2b",
                    },
                    slug: "direct-drive-wind-turbine",
                    title: "Direct Drive Wind Turbine",
                    id: 7000460,
                    parent:
                      "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                    path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/direct-drive-wind-turbine",
                    active: true,
                    meta: {
                      tags: "Direct Drive Wind Turbine",
                      keywords: "Direct Drive Wind Turbine",
                      description: "Direct Drive Wind Turbine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c36",
                    },
                    slug: "wind-turbine-tower",
                    title: "Wind Turbine Tower",
                    id: 7000471,
                    parent:
                      "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                    path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-tower",
                    active: true,
                    meta: {
                      tags: "Wind Turbine Tower",
                      keywords: "Wind Turbine Tower",
                      description: "Wind Turbine Tower",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c2d",
                    },
                    slug: "small-wind-turbine",
                    title: "Small Wind Turbine",
                    id: 7000462,
                    parent:
                      "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                    path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/small-wind-turbine",
                    active: true,
                    meta: {
                      tags: "Small Wind Turbine",
                      keywords: "Small Wind Turbine",
                      description: "Small Wind Turbine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c2e",
                    },
                    slug: "wind-turbine-gearbox",
                    title: "Wind Turbine Gearbox",
                    id: 7000463,
                    parent:
                      "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                    path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-gearbox",
                    active: true,
                    meta: {
                      tags: "Wind Turbine Gearbox",
                      keywords: "Wind Turbine Gearbox",
                      description: "Wind Turbine Gearbox",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995c30",
                        },
                        slug: "wind-turbine-gearbox-repair-and-refurbishment",
                        title: "Wind Turbine Gearbox Repair and Refurbishment",
                        id: 7000465,
                        parent:
                          "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-gearbox",
                        path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-gearbox/wind-turbine-gearbox-repair-and-refurbishment",
                        active: true,
                        meta: {
                          tags: "Wind Turbine Gearbox Repair and Refurbishment",
                          keywords:
                            "Wind Turbine Gearbox Repair and Refurbishment",
                          description:
                            "Wind Turbine Gearbox Repair and Refurbishment",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995c2f",
                        },
                        slug: "wind-turbine-direct-drive-system",
                        title: "Wind Turbine Direct Drive System",
                        id: 7000464,
                        parent:
                          "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-gearbox",
                        path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-gearbox/wind-turbine-direct-drive-system",
                        active: true,
                        meta: {
                          tags: "Wind Turbine Direct Drive System",
                          keywords: "Wind Turbine Direct Drive System",
                          description: "Wind Turbine Direct Drive System",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c31",
                    },
                    slug: "wind-turbine-mro",
                    title: "Wind Turbine MRO",
                    id: 7000466,
                    parent:
                      "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                    path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-mro",
                    active: true,
                    meta: {
                      tags: "Wind Turbine MRO",
                      keywords: "Wind Turbine MRO",
                      description: "Wind Turbine MRO",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c32",
                    },
                    slug: "wind-turbine-nacelle",
                    title: "Wind Turbine Nacelle",
                    id: 7000467,
                    parent:
                      "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                    path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-nacelle",
                    active: true,
                    meta: {
                      tags: "Wind Turbine Nacelle",
                      keywords: "Wind Turbine Nacelle",
                      description: "Wind Turbine Nacelle",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c33",
                    },
                    slug: "wind-turbine-rotor-blade",
                    title: "Wind Turbine Rotor Blade",
                    id: 7000468,
                    parent:
                      "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                    path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-rotor-blade",
                    active: true,
                    meta: {
                      tags: "Wind Turbine Rotor Blade",
                      keywords: "Wind Turbine Rotor Blade",
                      description: "Wind Turbine Rotor Blade",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9c9f476e848e83995c34",
                        },
                        slug: "wind-turbine-rotor-blade-recycling",
                        title: "Wind Turbine Rotor Blade Recycling",
                        id: 7000469,
                        parent:
                          "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-rotor-blade",
                        path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-rotor-blade/wind-turbine-rotor-blade-recycling",
                        active: true,
                        meta: {
                          tags: "Wind Turbine Rotor Blade Recycling",
                          keywords: "Wind Turbine Rotor Blade Recycling",
                          description: "Wind Turbine Rotor Blade Recycling",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c2c",
                    },
                    slug: "offshore-wind-turbine",
                    title: "Offshore Wind Turbine",
                    id: 7000461,
                    parent:
                      "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                    path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/offshore-wind-turbine",
                    active: true,
                    meta: {
                      tags: "Offshore Wind Turbine",
                      keywords: "Offshore Wind Turbine",
                      description: "Offshore Wind Turbine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995c35",
                    },
                    slug: "wind-turbine-shaft",
                    title: "Wind Turbine Shaft",
                    id: 7000470,
                    parent:
                      "/energy-power/power-equipment/wind-power-equipment/wind-turbine",
                    path: "/energy-power/power-equipment/wind-power-equipment/wind-turbine/wind-turbine-shaft",
                    active: true,
                    meta: {
                      tags: "Wind Turbine Shaft",
                      keywords: "Wind Turbine Shaft",
                      description: "Wind Turbine Shaft",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c0b",
            },
            slug: "hybrid-power-solutions",
            title: "Hybrid Power Solutions",
            id: 7000428,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/hybrid-power-solutions",
            active: true,
            meta: {
              tags: "Hybrid Power Solutions",
              keywords: "Hybrid Power Solutions",
              description: "Hybrid Power Solutions",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c12",
            },
            slug: "micro-thermoelectric-modules",
            title: "Micro Thermoelectric Modules",
            id: 7000435,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/micro-thermoelectric-modules",
            active: true,
            meta: {
              tags: "Micro Thermoelectric Modules",
              keywords: "Micro Thermoelectric Modules",
              description: "Micro Thermoelectric Modules",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c13",
            },
            slug: "nuclear-power-plant-equipment",
            title: "Nuclear Power Plant Equipment",
            id: 7000436,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/nuclear-power-plant-equipment",
            active: true,
            meta: {
              tags: "Nuclear Power Plant Equipment",
              keywords: "Nuclear Power Plant Equipment",
              description: "Nuclear Power Plant Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c14",
                },
                slug: "nuclear-reactor-construction",
                title: "Nuclear Reactor Construction",
                id: 7000437,
                parent:
                  "/energy-power/power-equipment/nuclear-power-plant-equipment",
                path: "/energy-power/power-equipment/nuclear-power-plant-equipment/nuclear-reactor-construction",
                active: true,
                meta: {
                  tags: "Nuclear Reactor Construction",
                  keywords: "Nuclear Reactor Construction",
                  description: "Nuclear Reactor Construction",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c15",
                },
                slug: "nuclear-steam-generator",
                title: "Nuclear Steam Generator",
                id: 7000438,
                parent:
                  "/energy-power/power-equipment/nuclear-power-plant-equipment",
                path: "/energy-power/power-equipment/nuclear-power-plant-equipment/nuclear-steam-generator",
                active: true,
                meta: {
                  tags: "Nuclear Steam Generator",
                  keywords: "Nuclear Steam Generator",
                  description: "Nuclear Steam Generator",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995bf1",
            },
            slug: "backup-power-system",
            title: "Backup Power System",
            id: 7000402,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/backup-power-system",
            active: true,
            meta: {
              tags: "Backup Power System",
              keywords: "Backup Power System",
              description: "Backup Power System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c02",
            },
            slug: "excitation-system",
            title: "Excitation System",
            id: 7000419,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/excitation-system",
            active: true,
            meta: {
              tags: "Excitation System",
              keywords: "Excitation System",
              description: "Excitation System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c0c",
            },
            slug: "hydraulic-power-unit",
            title: "Hydraulic Power Unit",
            id: 7000429,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/hydraulic-power-unit",
            active: true,
            meta: {
              tags: "Hydraulic Power Unit",
              keywords: "Hydraulic Power Unit",
              description: "Hydraulic Power Unit",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c0d",
            },
            slug: "hydro-power",
            title: "Hydro Power",
            id: 7000430,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/hydro-power",
            active: true,
            meta: {
              tags: "Hydro Power",
              keywords: "Hydro Power",
              description: "Hydro Power",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c10",
                },
                slug: "in-pipe-hydro-system",
                title: "In-pipe Hydro System",
                id: 7000433,
                parent: "/energy-power/power-equipment/hydro-power",
                path: "/energy-power/power-equipment/hydro-power/in-pipe-hydro-system",
                active: true,
                meta: {
                  tags: "In-pipe Hydro System",
                  keywords: "In-pipe Hydro System",
                  description: "In-pipe Hydro System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c0f",
                },
                slug: "hydro-turbine",
                title: "Hydro Turbine",
                id: 7000432,
                parent: "/energy-power/power-equipment/hydro-power",
                path: "/energy-power/power-equipment/hydro-power/hydro-turbine",
                active: true,
                meta: {
                  tags: "Hydro Turbine",
                  keywords: "Hydro Turbine",
                  description: "Hydro Turbine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995c0e",
                },
                slug: "hydro-generator",
                title: "Hydro Generator",
                id: 7000431,
                parent: "/energy-power/power-equipment/hydro-power",
                path: "/energy-power/power-equipment/hydro-power/hydro-generator",
                active: true,
                meta: {
                  tags: "Hydro Generator",
                  keywords: "Hydro Generator",
                  description: "Hydro Generator",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c17",
            },
            slug: "power-quality-equipment",
            title: "Power Quality Equipment",
            id: 7000440,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/power-quality-equipment",
            active: true,
            meta: {
              tags: "Power Quality Equipment",
              keywords: "Power Quality Equipment",
              description: "Power Quality Equipment",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c27",
            },
            slug: "uninterruptible-power-supply-ups",
            title: "Uninterruptible Power Supply (UPS)",
            id: 7000456,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/uninterruptible-power-supply-ups",
            active: true,
            meta: {
              tags: "Uninterruptible Power Supply (UPS)",
              keywords: "Uninterruptible Power Supply (UPS)",
              description: "Uninterruptible Power Supply (UPS)",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c11",
            },
            slug: "low-voltage-protection-control",
            title: "Low Voltage Protection Control",
            id: 7000434,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/low-voltage-protection-control",
            active: true,
            meta: {
              tags: "Low Voltage Protection Control",
              keywords: "Low Voltage Protection Control",
              description: "Low Voltage Protection Control",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995bf6",
            },
            slug: "circuit-breakers",
            title: "Circuit Breakers",
            id: 7000407,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/circuit-breakers",
            active: true,
            meta: {
              tags: "Circuit Breakers",
              keywords: "Circuit Breakers",
              description: "Circuit Breakers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bf9",
                },
                slug: "generator-circuit-breakers",
                title: "Generator Circuit Breakers",
                id: 7000410,
                parent: "/energy-power/power-equipment/circuit-breakers",
                path: "/energy-power/power-equipment/circuit-breakers/generator-circuit-breakers",
                active: true,
                meta: {
                  tags: "Generator Circuit Breakers",
                  keywords: "Generator Circuit Breakers",
                  description: "Generator Circuit Breakers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bfb",
                },
                slug: "vacuum-circuit-breakers",
                title: "Vacuum Circuit Breakers",
                id: 7000412,
                parent: "/energy-power/power-equipment/circuit-breakers",
                path: "/energy-power/power-equipment/circuit-breakers/vacuum-circuit-breakers",
                active: true,
                meta: {
                  tags: "Vacuum Circuit Breakers",
                  keywords: "Vacuum Circuit Breakers",
                  description: "Vacuum Circuit Breakers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bf7",
                },
                slug: "dc-circuit-breakers",
                title: "DC Circuit Breakers",
                id: 7000408,
                parent: "/energy-power/power-equipment/circuit-breakers",
                path: "/energy-power/power-equipment/circuit-breakers/dc-circuit-breakers",
                active: true,
                meta: {
                  tags: "DC Circuit Breakers",
                  keywords: "DC Circuit Breakers",
                  description: "DC Circuit Breakers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995bf8",
                    },
                    slug: "low-voltage-dc-circuit-breakers",
                    title: "Low Voltage DC Circuit Breakers",
                    id: 7000409,
                    parent:
                      "/energy-power/power-equipment/circuit-breakers/dc-circuit-breakers",
                    path: "/energy-power/power-equipment/circuit-breakers/dc-circuit-breakers/low-voltage-dc-circuit-breakers",
                    active: true,
                    meta: {
                      tags: "Low Voltage DC Circuit Breakers",
                      keywords: "Low Voltage DC Circuit Breakers",
                      description: "Low Voltage DC Circuit Breakers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bfa",
                },
                slug: "molded-case-circuit-breakers",
                title: "Molded Case Circuit Breakers",
                id: 7000411,
                parent: "/energy-power/power-equipment/circuit-breakers",
                path: "/energy-power/power-equipment/circuit-breakers/molded-case-circuit-breakers",
                active: true,
                meta: {
                  tags: "Molded Case Circuit Breakers",
                  keywords: "Molded Case Circuit Breakers",
                  description: "Molded Case Circuit Breakers",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995bfc",
            },
            slug: "control-system",
            title: "Control System",
            id: 7000413,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/control-system",
            active: true,
            meta: {
              tags: "Control System",
              keywords: "Control System",
              description: "Control System",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995bfd",
                },
                slug: "power-plant-control-system",
                title: "Power Plant Control System",
                id: 7000414,
                parent: "/energy-power/power-equipment/control-system",
                path: "/energy-power/power-equipment/control-system/power-plant-control-system",
                active: true,
                meta: {
                  tags: "Power Plant Control System",
                  keywords: "Power Plant Control System",
                  description: "Power Plant Control System",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c20",
            },
            slug: "surge-arresters",
            title: "Surge Arresters",
            id: 7000449,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/surge-arresters",
            active: true,
            meta: {
              tags: "Surge Arresters",
              keywords: "Surge Arresters",
              description: "Surge Arresters",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995c28",
            },
            slug: "utility-drone",
            title: "Utility Drone",
            id: 7000457,
            parent: "/energy-power/power-equipment",
            path: "/energy-power/power-equipment/utility-drone",
            active: true,
            meta: {
              tags: "Utility Drone",
              keywords: "Utility Drone",
              description: "Utility Drone",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9c9f476e848e83995a92",
        },
        slug: "energy-storage-technology",
        title: "Energy Storage Technology",
        id: 57627,
        parent: "/energy-power",
        path: "/energy-power/energy-storage-technology",
        active: true,
        meta: {
          tags: "Energy Storage Technology",
          keywords: "Energy Storage Technology",
          description: "Energy Storage Technology",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a94",
            },
            slug: "compressed-air-energy-storage-caes",
            title: "Compressed Air Energy Storage (CAES)",
            id: 7000051,
            parent: "/energy-power/energy-storage-technology",
            path: "/energy-power/energy-storage-technology/compressed-air-energy-storage-caes",
            active: true,
            meta: {
              tags: "Compressed Air Energy Storage (CAES)",
              keywords: "Compressed Air Energy Storage (CAES)",
              description: "Compressed Air Energy Storage (CAES)",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a93",
            },
            slug: "battery-energy-storage",
            title: "Battery Energy Storage",
            id: 7000050,
            parent: "/energy-power/energy-storage-technology",
            path: "/energy-power/energy-storage-technology/battery-energy-storage",
            active: true,
            meta: {
              tags: "Battery Energy Storage",
              keywords: "Battery Energy Storage",
              description: "Battery Energy Storage",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a95",
            },
            slug: "pumped-hydro-storage",
            title: "Pumped Hydro Storage",
            id: 7000052,
            parent: "/energy-power/energy-storage-technology",
            path: "/energy-power/energy-storage-technology/pumped-hydro-storage",
            active: true,
            meta: {
              tags: "Pumped Hydro Storage",
              keywords: "Pumped Hydro Storage",
              description: "Pumped Hydro Storage",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a96",
            },
            slug: "residential-energy-storage",
            title: "Residential Energy Storage",
            id: 7000053,
            parent: "/energy-power/energy-storage-technology",
            path: "/energy-power/energy-storage-technology/residential-energy-storage",
            active: true,
            meta: {
              tags: "Residential Energy Storage",
              keywords: "Residential Energy Storage",
              description: "Residential Energy Storage",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a97",
            },
            slug: "thermal-energy-storage",
            title: "Thermal Energy Storage",
            id: 7000054,
            parent: "/energy-power/energy-storage-technology",
            path: "/energy-power/energy-storage-technology/thermal-energy-storage",
            active: true,
            meta: {
              tags: "Thermal Energy Storage",
              keywords: "Thermal Energy Storage",
              description: "Thermal Energy Storage",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a98",
                },
                slug: "molten-salt-thermal-energy-storage",
                title: "Molten Salt Thermal Energy Storage",
                id: 7000055,
                parent:
                  "/energy-power/energy-storage-technology/thermal-energy-storage",
                path: "/energy-power/energy-storage-technology/thermal-energy-storage/molten-salt-thermal-energy-storage",
                active: true,
                meta: {
                  tags: "Molten Salt Thermal Energy Storage",
                  keywords: "Molten Salt Thermal Energy Storage",
                  description: "Molten Salt Thermal Energy Storage",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9c9f476e848e83995a86",
        },
        slug: "emerging-energy-technologies",
        title: "Emerging Energy Technologies",
        id: 57626,
        parent: "/energy-power",
        path: "/energy-power/emerging-energy-technologies",
        active: true,
        meta: {
          tags: "Emerging Energy Technologies",
          keywords: "Emerging Energy Technologies",
          description: "Emerging Energy Technologies",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a89",
            },
            slug: "fuel-cell",
            title: "Fuel Cell",
            id: 7000040,
            parent: "/energy-power/emerging-energy-technologies",
            path: "/energy-power/emerging-energy-technologies/fuel-cell",
            active: true,
            meta: {
              tags: "Fuel Cell",
              keywords: "Fuel Cell",
              description: "Fuel Cell",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a8a",
                },
                slug: "direct-methanol-fuel-cell",
                title: "Direct Methanol Fuel Cell",
                id: 7000041,
                parent: "/energy-power/emerging-energy-technologies/fuel-cell",
                path: "/energy-power/emerging-energy-technologies/fuel-cell/direct-methanol-fuel-cell",
                active: true,
                meta: {
                  tags: "Direct Methanol Fuel Cell",
                  keywords: "Direct Methanol Fuel Cell",
                  description: "Direct Methanol Fuel Cell",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a8c",
                },
                slug: "solid-oxide-fuel-cell",
                title: "Solid Oxide Fuel Cell",
                id: 7000043,
                parent: "/energy-power/emerging-energy-technologies/fuel-cell",
                path: "/energy-power/emerging-energy-technologies/fuel-cell/solid-oxide-fuel-cell",
                active: true,
                meta: {
                  tags: "Solid Oxide Fuel Cell",
                  keywords: "Solid Oxide Fuel Cell",
                  description: "Solid Oxide Fuel Cell",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995a8d",
                    },
                    slug: "planar-solid-oxide-fuel-cell",
                    title: "Planar Solid Oxide Fuel Cell",
                    id: 7000044,
                    parent:
                      "/energy-power/emerging-energy-technologies/fuel-cell/solid-oxide-fuel-cell",
                    path: "/energy-power/emerging-energy-technologies/fuel-cell/solid-oxide-fuel-cell/planar-solid-oxide-fuel-cell",
                    active: true,
                    meta: {
                      tags: "Planar Solid Oxide Fuel Cell",
                      keywords: "Planar Solid Oxide Fuel Cell",
                      description: "Planar Solid Oxide Fuel Cell",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a8b",
                },
                slug: "pem-fuel-cell",
                title: "PEM Fuel Cell",
                id: 7000042,
                parent: "/energy-power/emerging-energy-technologies/fuel-cell",
                path: "/energy-power/emerging-energy-technologies/fuel-cell/pem-fuel-cell",
                active: true,
                meta: {
                  tags: "PEM Fuel Cell",
                  keywords: "PEM Fuel Cell",
                  description: "PEM Fuel Cell",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a91",
            },
            slug: "power-to-gas",
            title: "Power to Gas",
            id: 7000048,
            parent: "/energy-power/emerging-energy-technologies",
            path: "/energy-power/emerging-energy-technologies/power-to-gas",
            active: true,
            meta: {
              tags: "Power to Gas",
              keywords: "Power to Gas",
              description: "Power to Gas",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a90",
            },
            slug: "lighting-management-system",
            title: "Lighting Management System",
            id: 7000047,
            parent: "/energy-power/emerging-energy-technologies",
            path: "/energy-power/emerging-energy-technologies/lighting-management-system",
            active: true,
            meta: {
              tags: "Lighting Management System",
              keywords: "Lighting Management System",
              description: "Lighting Management System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a87",
            },
            slug: "building-energy-management-system",
            title: "Building Energy Management System",
            id: 7000038,
            parent: "/energy-power/emerging-energy-technologies",
            path: "/energy-power/emerging-energy-technologies/building-energy-management-system",
            active: true,
            meta: {
              tags: "Building Energy Management System",
              keywords: "Building Energy Management System",
              description: "Building Energy Management System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a8f",
            },
            slug: "lighting-as-a-service",
            title: "Lighting as a Service",
            id: 7000046,
            parent: "/energy-power/emerging-energy-technologies",
            path: "/energy-power/emerging-energy-technologies/lighting-as-a-service",
            active: true,
            meta: {
              tags: "Lighting as a Service",
              keywords: "Lighting as a Service",
              description: "Lighting as a Service",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a88",
            },
            slug: "energy-as-a-service",
            title: "Energy as a Service",
            id: 7000039,
            parent: "/energy-power/emerging-energy-technologies",
            path: "/energy-power/emerging-energy-technologies/energy-as-a-service",
            active: true,
            meta: {
              tags: "Energy as a Service",
              keywords: "Energy as a Service",
              description: "Energy as a Service",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a8e",
            },
            slug: "hydrogen-generation",
            title: "Hydrogen Generation",
            id: 7000045,
            parent: "/energy-power/emerging-energy-technologies",
            path: "/energy-power/emerging-energy-technologies/hydrogen-generation",
            active: true,
            meta: {
              tags: "Hydrogen Generation",
              keywords: "Hydrogen Generation",
              description: "Hydrogen Generation",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9c9f476e848e83995aa3",
        },
        slug: "fuels",
        title: "Fuels",
        id: 57629,
        parent: "/energy-power",
        path: "/energy-power/fuels",
        active: true,
        meta: {
          tags: "Fuels",
          keywords: "Fuels",
          description: "Fuels",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aa7",
            },
            slug: "gaseous-fuel",
            title: "Gaseous Fuel",
            id: 7000070,
            parent: "/energy-power/fuels",
            path: "/energy-power/fuels/gaseous-fuel",
            active: true,
            meta: {
              tags: "Gaseous Fuel",
              keywords: "Gaseous Fuel",
              description: "Gaseous Fuel",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aa4",
            },
            slug: "coal",
            title: "Coal",
            id: 7000067,
            parent: "/energy-power/fuels",
            path: "/energy-power/fuels/coal",
            active: true,
            meta: {
              tags: "Coal",
              keywords: "Coal",
              description: "Coal",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995aa5",
                },
                slug: "clean-coal-technology",
                title: "Clean Coal Technology",
                id: 7000068,
                parent: "/energy-power/fuels/coal",
                path: "/energy-power/fuels/coal/clean-coal-technology",
                active: true,
                meta: {
                  tags: "Clean Coal Technology",
                  keywords: "Clean Coal Technology",
                  description: "Clean Coal Technology",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aaa",
            },
            slug: "natural-gas",
            title: "Natural Gas",
            id: 7000073,
            parent: "/energy-power/fuels",
            path: "/energy-power/fuels/natural-gas",
            active: true,
            meta: {
              tags: "Natural Gas",
              keywords: "Natural Gas",
              description: "Natural Gas",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aa6",
            },
            slug: "coal-trading",
            title: "Coal Trading",
            id: 7000069,
            parent: "/energy-power/fuels",
            path: "/energy-power/fuels/coal-trading",
            active: true,
            meta: {
              tags: "Coal Trading",
              keywords: "Coal Trading",
              description: "Coal Trading",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aa8",
            },
            slug: "liquefied-petroleum-gas-lpg",
            title: "Liquefied Petroleum Gas (LPG)",
            id: 7000071,
            parent: "/energy-power/fuels",
            path: "/energy-power/fuels/liquefied-petroleum-gas-lpg",
            active: true,
            meta: {
              tags: "Liquefied Petroleum Gas (LPG)",
              keywords: "Liquefied Petroleum Gas (LPG)",
              description: "Liquefied Petroleum Gas (LPG)",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aa9",
            },
            slug: "marine-gas-oil",
            title: "Marine Gas Oil",
            id: 7000072,
            parent: "/energy-power/fuels",
            path: "/energy-power/fuels/marine-gas-oil",
            active: true,
            meta: {
              tags: "Marine Gas Oil",
              keywords: "Marine Gas Oil",
              description: "Marine Gas Oil",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aab",
            },
            slug: "wood-pellet",
            title: "Wood Pellet",
            id: 7000074,
            parent: "/energy-power/fuels",
            path: "/energy-power/fuels/wood-pellet",
            active: true,
            meta: {
              tags: "Wood Pellet",
              keywords: "Wood Pellet",
              description: "Wood Pellet",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9c9f476e848e83995aac",
        },
        slug: "industrial-machinery",
        title: "Industrial Machinery",
        id: 57646,
        parent: "/energy-power",
        path: "/energy-power/industrial-machinery",
        active: true,
        meta: {
          tags: "Industrial Machinery",
          keywords: "Industrial Machinery",
          description: "Industrial Machinery",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aaf",
            },
            slug: "compressor",
            title: "Compressor",
            id: 7000078,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/compressor",
            active: true,
            meta: {
              tags: "Compressor",
              keywords: "Compressor",
              description: "Compressor",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab8",
                },
                slug: "rotary-lobe-compressor",
                title: "Rotary Lobe Compressor",
                id: 7000087,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/rotary-lobe-compressor",
                active: true,
                meta: {
                  tags: "Rotary Lobe Compressor",
                  keywords: "Rotary Lobe Compressor",
                  description: "Rotary Lobe Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995aba",
                },
                slug: "tandem-piston-compressor",
                title: "Tandem Piston Compressor",
                id: 7000089,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/tandem-piston-compressor",
                active: true,
                meta: {
                  tags: "Tandem Piston Compressor",
                  keywords: "Tandem Piston Compressor",
                  description: "Tandem Piston Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab2",
                },
                slug: "booster-compressor",
                title: "Booster Compressor",
                id: 7000081,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/booster-compressor",
                active: true,
                meta: {
                  tags: "Booster Compressor",
                  keywords: "Booster Compressor",
                  description: "Booster Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab3",
                },
                slug: "centrifugal-compressor",
                title: "Centrifugal Compressor",
                id: 7000082,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/centrifugal-compressor",
                active: true,
                meta: {
                  tags: "Centrifugal Compressor",
                  keywords: "Centrifugal Compressor",
                  description: "Centrifugal Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab4",
                },
                slug: "gas-compressor",
                title: "Gas Compressor",
                id: 7000083,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/gas-compressor",
                active: true,
                meta: {
                  tags: "Gas Compressor",
                  keywords: "Gas Compressor",
                  description: "Gas Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab6",
                },
                slug: "oil-and-gas-industry-gas-compressor",
                title: "Oil and Gas Industry Gas Compressor",
                id: 7000085,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/oil-and-gas-industry-gas-compressor",
                active: true,
                meta: {
                  tags: "Oil and Gas Industry Gas Compressor",
                  keywords: "Oil and Gas Industry Gas Compressor",
                  description: "Oil and Gas Industry Gas Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab0",
                },
                slug: "air-compressor",
                title: "Air Compressor",
                id: 7000079,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/air-compressor",
                active: true,
                meta: {
                  tags: "Air Compressor",
                  keywords: "Air Compressor",
                  description: "Air Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab1",
                },
                slug: "axial-compressor",
                title: "Axial Compressor",
                id: 7000080,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/axial-compressor",
                active: true,
                meta: {
                  tags: "Axial Compressor",
                  keywords: "Axial Compressor",
                  description: "Axial Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab5",
                },
                slug: "hydrogen-compressor",
                title: "Hydrogen Compressor",
                id: 7000084,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/hydrogen-compressor",
                active: true,
                meta: {
                  tags: "Hydrogen Compressor",
                  keywords: "Hydrogen Compressor",
                  description: "Hydrogen Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab7",
                },
                slug: "process-gas-compressor",
                title: "Process Gas Compressor",
                id: 7000086,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/process-gas-compressor",
                active: true,
                meta: {
                  tags: "Process Gas Compressor",
                  keywords: "Process Gas Compressor",
                  description: "Process Gas Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ab9",
                },
                slug: "screw-compressor",
                title: "Screw Compressor",
                id: 7000088,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/screw-compressor",
                active: true,
                meta: {
                  tags: "Screw Compressor",
                  keywords: "Screw Compressor",
                  description: "Screw Compressor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995abb",
                },
                slug: "turbo-compressor",
                title: "Turbo Compressor",
                id: 7000090,
                parent: "/energy-power/industrial-machinery/compressor",
                path: "/energy-power/industrial-machinery/compressor/turbo-compressor",
                active: true,
                meta: {
                  tags: "Turbo Compressor",
                  keywords: "Turbo Compressor",
                  description: "Turbo Compressor",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ac1",
            },
            slug: "electric-motor",
            title: "Electric Motor",
            id: 7000096,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/electric-motor",
            active: true,
            meta: {
              tags: "Electric Motor",
              keywords: "Electric Motor",
              description: "Electric Motor",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ac5",
                },
                slug: "parallel-shaft-geared-motor",
                title: "Parallel Shaft Geared Motor",
                id: 7000100,
                parent: "/energy-power/industrial-machinery/electric-motor",
                path: "/energy-power/industrial-machinery/electric-motor/parallel-shaft-geared-motor",
                active: true,
                meta: {
                  tags: "Parallel Shaft Geared Motor",
                  keywords: "Parallel Shaft Geared Motor",
                  description: "Parallel Shaft Geared Motor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ac3",
                },
                slug: "electric-traction-motor",
                title: "Electric Traction Motor",
                id: 7000098,
                parent: "/energy-power/industrial-machinery/electric-motor",
                path: "/energy-power/industrial-machinery/electric-motor/electric-traction-motor",
                active: true,
                meta: {
                  tags: "Electric Traction Motor",
                  keywords: "Electric Traction Motor",
                  description: "Electric Traction Motor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ac4",
                },
                slug: "inverter-duty-motor",
                title: "Inverter Duty Motor",
                id: 7000099,
                parent: "/energy-power/industrial-machinery/electric-motor",
                path: "/energy-power/industrial-machinery/electric-motor/inverter-duty-motor",
                active: true,
                meta: {
                  tags: "Inverter Duty Motor",
                  keywords: "Inverter Duty Motor",
                  description: "Inverter Duty Motor",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ac2",
                },
                slug: "brushed-dc-motor",
                title: "Brushed DC Motor",
                id: 7000097,
                parent: "/energy-power/industrial-machinery/electric-motor",
                path: "/energy-power/industrial-machinery/electric-motor/brushed-dc-motor",
                active: true,
                meta: {
                  tags: "Brushed DC Motor",
                  keywords: "Brushed DC Motor",
                  description: "Brushed DC Motor",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aca",
            },
            slug: "industrial-fans-and-blowers",
            title: "Industrial Fans and Blowers",
            id: 7000105,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/industrial-fans-and-blowers",
            active: true,
            meta: {
              tags: "Industrial Fans and Blowers",
              keywords: "Industrial Fans and Blowers",
              description: "Industrial Fans and Blowers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aad",
            },
            slug: "air-separation-unit-asu",
            title: "Air Separation Unit (ASU)",
            id: 7000076,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/air-separation-unit-asu",
            active: true,
            meta: {
              tags: "Air Separation Unit (ASU)",
              keywords: "Air Separation Unit (ASU)",
              description: "Air Separation Unit (ASU)",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995abc",
            },
            slug: "cooling-system",
            title: "Cooling System",
            id: 7000091,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/cooling-system",
            active: true,
            meta: {
              tags: "Cooling System",
              keywords: "Cooling System",
              description: "Cooling System",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995abd",
                },
                slug: "cooling-tower",
                title: "Cooling Tower",
                id: 7000092,
                parent: "/energy-power/industrial-machinery/cooling-system",
                path: "/energy-power/industrial-machinery/cooling-system/cooling-tower",
                active: true,
                meta: {
                  tags: "Cooling Tower",
                  keywords: "Cooling Tower",
                  description: "Cooling Tower",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995abf",
                    },
                    slug: "field-erected-cooling-tower",
                    title: "Field Erected Cooling Tower",
                    id: 7000094,
                    parent:
                      "/energy-power/industrial-machinery/cooling-system/cooling-tower",
                    path: "/energy-power/industrial-machinery/cooling-system/cooling-tower/field-erected-cooling-tower",
                    active: true,
                    meta: {
                      tags: "Field Erected Cooling Tower",
                      keywords: "Field Erected Cooling Tower",
                      description: "Field Erected Cooling Tower",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995abe",
                    },
                    slug: "cooling-tower-rental",
                    title: "Cooling Tower Rental",
                    id: 7000093,
                    parent:
                      "/energy-power/industrial-machinery/cooling-system/cooling-tower",
                    path: "/energy-power/industrial-machinery/cooling-system/cooling-tower/cooling-tower-rental",
                    active: true,
                    meta: {
                      tags: "Cooling Tower Rental",
                      keywords: "Cooling Tower Rental",
                      description: "Cooling Tower Rental",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995acb",
            },
            slug: "industrial-gearbox",
            title: "Industrial Gearbox",
            id: 7000106,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/industrial-gearbox",
            active: true,
            meta: {
              tags: "Industrial Gearbox",
              keywords: "Industrial Gearbox",
              description: "Industrial Gearbox",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995acd",
            },
            slug: "pressure-vessels",
            title: "Pressure Vessels",
            id: 7000108,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/pressure-vessels",
            active: true,
            meta: {
              tags: "Pressure Vessels",
              keywords: "Pressure Vessels",
              description: "Pressure Vessels",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995adb",
            },
            slug: "turbomachinery",
            title: "Turbomachinery",
            id: 7000122,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/turbomachinery",
            active: true,
            meta: {
              tags: "Turbomachinery",
              keywords: "Turbomachinery",
              description: "Turbomachinery",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995adf",
                },
                slug: "oil-and-gas-turbomachinery",
                title: "Oil and Gas Turbomachinery",
                id: 7000126,
                parent: "/energy-power/industrial-machinery/turbomachinery",
                path: "/energy-power/industrial-machinery/turbomachinery/oil-and-gas-turbomachinery",
                active: true,
                meta: {
                  tags: "Oil and Gas Turbomachinery",
                  keywords: "Oil and Gas Turbomachinery",
                  description: "Oil and Gas Turbomachinery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995adc",
                },
                slug: "machine-control-system",
                title: "Machine Control System",
                id: 7000123,
                parent: "/energy-power/industrial-machinery/turbomachinery",
                path: "/energy-power/industrial-machinery/turbomachinery/machine-control-system",
                active: true,
                meta: {
                  tags: "Machine Control System",
                  keywords: "Machine Control System",
                  description: "Machine Control System",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995add",
                    },
                    slug: "turbine-control-system",
                    title: "Turbine Control System",
                    id: 7000124,
                    parent:
                      "/energy-power/industrial-machinery/turbomachinery/machine-control-system",
                    path: "/energy-power/industrial-machinery/turbomachinery/machine-control-system/turbine-control-system",
                    active: true,
                    meta: {
                      tags: "Turbine Control System",
                      keywords: "Turbine Control System",
                      description: "Turbine Control System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9c9f476e848e83995ade",
                    },
                    slug: "turbomachinery-control-system-retrofitting-and-ancillary-services",
                    title:
                      "Turbomachinery Control System, Retrofitting, and Ancillary Services",
                    id: 7000125,
                    parent:
                      "/energy-power/industrial-machinery/turbomachinery/machine-control-system",
                    path: "/energy-power/industrial-machinery/turbomachinery/machine-control-system/turbomachinery-control-system-retrofitting-and-ancillary-services",
                    active: true,
                    meta: {
                      tags: "Turbomachinery Control System, Retrofitting, and Ancillary Services",
                      keywords:
                        "Turbomachinery Control System, Retrofitting, and Ancillary Services",
                      description:
                        "Turbomachinery Control System, Retrofitting, and Ancillary Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ac6",
            },
            slug: "electrical-components",
            title: "Electrical Components",
            id: 7000101,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/electrical-components",
            active: true,
            meta: {
              tags: "Electrical Components",
              keywords: "Electrical Components",
              description: "Electrical Components",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995acc",
            },
            slug: "pneumatic-conveying-system",
            title: "Pneumatic Conveying System",
            id: 7000107,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/pneumatic-conveying-system",
            active: true,
            meta: {
              tags: "Pneumatic Conveying System",
              keywords: "Pneumatic Conveying System",
              description: "Pneumatic Conveying System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ac9",
            },
            slug: "industrial-centrifuges",
            title: "Industrial Centrifuges",
            id: 7000104,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/industrial-centrifuges",
            active: true,
            meta: {
              tags: "Industrial Centrifuges",
              keywords: "Industrial Centrifuges",
              description: "Industrial Centrifuges",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ac0",
            },
            slug: "cryogenic-equipment",
            title: "Cryogenic Equipment",
            id: 7000095,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/cryogenic-equipment",
            active: true,
            meta: {
              tags: "Cryogenic Equipment",
              keywords: "Cryogenic Equipment",
              description: "Cryogenic Equipment",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ac7",
            },
            slug: "heat-exchanger",
            title: "Heat Exchanger",
            id: 7000102,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/heat-exchanger",
            active: true,
            meta: {
              tags: "Heat Exchanger",
              keywords: "Heat Exchanger",
              description: "Heat Exchanger",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ac8",
                },
                slug: "heat-recovery-steam-generator",
                title: "Heat Recovery Steam Generator",
                id: 7000103,
                parent: "/energy-power/industrial-machinery/heat-exchanger",
                path: "/energy-power/industrial-machinery/heat-exchanger/heat-recovery-steam-generator",
                active: true,
                meta: {
                  tags: "Heat Recovery Steam Generator",
                  keywords: "Heat Recovery Steam Generator",
                  description: "Heat Recovery Steam Generator",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ace",
            },
            slug: "industrial-pumps",
            title: "Industrial Pumps",
            id: 7000109,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/industrial-pumps",
            active: true,
            meta: {
              tags: "Industrial Pumps",
              keywords: "Industrial Pumps",
              description: "Industrial Pumps",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995acf",
                },
                slug: "axial-flow-pumps",
                title: "Axial Flow Pumps",
                id: 7000110,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/axial-flow-pumps",
                active: true,
                meta: {
                  tags: "Axial Flow Pumps",
                  keywords: "Axial Flow Pumps",
                  description: "Axial Flow Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad9",
                },
                slug: "pump-jack",
                title: "Pump Jack",
                id: 7000120,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/pump-jack",
                active: true,
                meta: {
                  tags: "Pump Jack",
                  keywords: "Pump Jack",
                  description: "Pump Jack",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ada",
                },
                slug: "submersible-pumps",
                title: "Submersible Pumps",
                id: 7000121,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/submersible-pumps",
                active: true,
                meta: {
                  tags: "Submersible Pumps",
                  keywords: "Submersible Pumps",
                  description: "Submersible Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad1",
                },
                slug: "cryogenic-pumps",
                title: "Cryogenic Pumps",
                id: 7000112,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/cryogenic-pumps",
                active: true,
                meta: {
                  tags: "Cryogenic Pumps",
                  keywords: "Cryogenic Pumps",
                  description: "Cryogenic Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad5",
                },
                slug: "hydraulic-dosing-pumps",
                title: "Hydraulic Dosing Pumps",
                id: 7000116,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/hydraulic-dosing-pumps",
                active: true,
                meta: {
                  tags: "Hydraulic Dosing Pumps",
                  keywords: "Hydraulic Dosing Pumps",
                  description: "Hydraulic Dosing Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad6",
                },
                slug: "hydraulic-submersible-pumps",
                title: "Hydraulic Submersible Pumps",
                id: 7000117,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/hydraulic-submersible-pumps",
                active: true,
                meta: {
                  tags: "Hydraulic Submersible Pumps",
                  keywords: "Hydraulic Submersible Pumps",
                  description: "Hydraulic Submersible Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad0",
                },
                slug: "centrifugal-pumps",
                title: "Centrifugal Pumps",
                id: 7000111,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/centrifugal-pumps",
                active: true,
                meta: {
                  tags: "Centrifugal Pumps",
                  keywords: "Centrifugal Pumps",
                  description: "Centrifugal Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad8",
                },
                slug: "positive-displacement-pumps",
                title: "Positive Displacement Pumps",
                id: 7000119,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/positive-displacement-pumps",
                active: true,
                meta: {
                  tags: "Positive Displacement Pumps",
                  keywords: "Positive Displacement Pumps",
                  description: "Positive Displacement Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad2",
                },
                slug: "diaphragm-pumps",
                title: "Diaphragm Pumps",
                id: 7000113,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/diaphragm-pumps",
                active: true,
                meta: {
                  tags: "Diaphragm Pumps",
                  keywords: "Diaphragm Pumps",
                  description: "Diaphragm Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad3",
                },
                slug: "dosing-system",
                title: "Dosing System",
                id: 7000114,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/dosing-system",
                active: true,
                meta: {
                  tags: "Dosing System",
                  keywords: "Dosing System",
                  description: "Dosing System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad4",
                },
                slug: "high-pressure-pumps",
                title: "High Pressure Pumps",
                id: 7000115,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/high-pressure-pumps",
                active: true,
                meta: {
                  tags: "High Pressure Pumps",
                  keywords: "High Pressure Pumps",
                  description: "High Pressure Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995ad7",
                },
                slug: "oil-and-gas-pumps",
                title: "Oil and Gas Pumps",
                id: 7000118,
                parent: "/energy-power/industrial-machinery/industrial-pumps",
                path: "/energy-power/industrial-machinery/industrial-pumps/oil-and-gas-pumps",
                active: true,
                meta: {
                  tags: "Oil and Gas Pumps",
                  keywords: "Oil and Gas Pumps",
                  description: "Oil and Gas Pumps",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995aae",
            },
            slug: "air-starter",
            title: "Air Starter",
            id: 7000077,
            parent: "/energy-power/industrial-machinery",
            path: "/energy-power/industrial-machinery/air-starter",
            active: true,
            meta: {
              tags: "Air Starter",
              keywords: "Air Starter",
              description: "Air Starter",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9c9f476e848e83995ae0",
        },
        slug: "offshore-support-vessels",
        title: "Offshore Support Vessels",
        id: 57630,
        parent: "/energy-power",
        path: "/energy-power/offshore-support-vessels",
        active: true,
        meta: {
          tags: "Offshore Support Vessels",
          keywords: "Offshore Support Vessels",
          description: "Offshore Support Vessels",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ae3",
            },
            slug: "platform-support-vessel-psv",
            title: "Platform Support Vessel (PSV)",
            id: 7000130,
            parent: "/energy-power/offshore-support-vessels",
            path: "/energy-power/offshore-support-vessels/platform-support-vessel-psv",
            active: true,
            meta: {
              tags: "Platform Support Vessel (PSV)",
              keywords: "Platform Support Vessel (PSV)",
              description: "Platform Support Vessel (PSV)",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ae2",
            },
            slug: "lng-carriers",
            title: "LNG Carriers",
            id: 7000129,
            parent: "/energy-power/offshore-support-vessels",
            path: "/energy-power/offshore-support-vessels/lng-carriers",
            active: true,
            meta: {
              tags: "LNG Carriers",
              keywords: "LNG Carriers",
              description: "LNG Carriers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995ae1",
            },
            slug: "crude-oil-carriers",
            title: "Crude Oil Carriers",
            id: 7000128,
            parent: "/energy-power/offshore-support-vessels",
            path: "/energy-power/offshore-support-vessels/crude-oil-carriers",
            active: true,
            meta: {
              tags: "Crude Oil Carriers",
              keywords: "Crude Oil Carriers",
              description: "Crude Oil Carriers",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9c9f476e848e83995a81",
        },
        slug: "decommissioning",
        title: "Decommissioning",
        id: 57625,
        parent: "/energy-power",
        path: "/energy-power/decommissioning",
        active: true,
        meta: {
          tags: "Decommissioning",
          keywords: "Decommissioning",
          description: "Decommissioning",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a82",
            },
            slug: "nuclear-power-decommissioning",
            title: "Nuclear Power Decommissioning",
            id: 7000033,
            parent: "/energy-power/decommissioning",
            path: "/energy-power/decommissioning/nuclear-power-decommissioning",
            active: true,
            meta: {
              tags: "Nuclear Power Decommissioning",
              keywords: "Nuclear Power Decommissioning",
              description: "Nuclear Power Decommissioning",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a83",
                },
                slug: "nuclear-reactor-decommissioning",
                title: "Nuclear Reactor Decommissioning",
                id: 7000034,
                parent:
                  "/energy-power/decommissioning/nuclear-power-decommissioning",
                path: "/energy-power/decommissioning/nuclear-power-decommissioning/nuclear-reactor-decommissioning",
                active: true,
                meta: {
                  tags: "Nuclear Reactor Decommissioning",
                  keywords: "Nuclear Reactor Decommissioning",
                  description: "Nuclear Reactor Decommissioning",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9c9f476e848e83995a84",
            },
            slug: "oil-and-gas-decommissioning",
            title: "Oil and Gas Decommissioning",
            id: 7000035,
            parent: "/energy-power/decommissioning",
            path: "/energy-power/decommissioning/oil-and-gas-decommissioning",
            active: true,
            meta: {
              tags: "Oil and Gas Decommissioning",
              keywords: "Oil and Gas Decommissioning",
              description: "Oil and Gas Decommissioning",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9c9f476e848e83995a85",
                },
                slug: "offshore-oil-and-gas-decommissioning",
                title: "Offshore Oil and Gas Decommissioning",
                id: 7000036,
                parent:
                  "/energy-power/decommissioning/oil-and-gas-decommissioning",
                path: "/energy-power/decommissioning/oil-and-gas-decommissioning/offshore-oil-and-gas-decommissioning",
                active: true,
                meta: {
                  tags: "Offshore Oil and Gas Decommissioning",
                  keywords: "Offshore Oil and Gas Decommissioning",
                  description: "Offshore Oil and Gas Decommissioning",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b129",
    },
    id: 58,
    title: "Agriculture",
    slug: "agriculture",
    active: true,
    meta: {
      tags: "Agriculture Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "agriculture market research reports, agriculture market size, agriculture market trends, agriculture market growth, agriculture market research, agriculture market industry, agriculture market value, agriculture market statistics, agriculture market segments, agriculture market overview, agriculture market outlook, agriculture market leaders, agriculture market drivers, smart agriculture market.",
      description:
        "The reports under the Agriculture Category cover the entire breadth of the agricultural value chain from inputs such as seed, fertilizer, crop protection, and agricultural machinery to a wide spectrum of agricultural commodities, including bulk dry commodities and fresh commodities.",
    },
    description: {
      short_description:
        "Agriculture is the largest industry globally. An estimated 60% increase in food demand by 2050 is set to drive the burgeoning agricultural market towards advanced technologies, leading to better yield and profitable cultivation. Browse through some of our industry reports on agriculture.",
      full_description:
        "Agriculture is the largest industry globally. Modern-day agriculture includes various industries like seeds, agrochemicals, animal feed, and machinery. Processing, marketing, and distributing crops and livestock products are an inseparable part of the industry. The estimated 60% increase in food demand by 2050 is changing the look of industry, making companies and farmers shift towards new technologies. The current industry is showing sluggish growth, soft demand, and sufficient supply, and the trend is making it difficult for companies to survive and grow in this environment. Our range of reports covering every agriculture domain will help you identify technologies, markets, and strategies to grow your business.",
    },
    picture: null,
    __v: 0,
    picture_url: "agriculture.png",
    cat_email: "afnb@mordorintelligence.com",
    cat_pw_id: 12423,
    parent: "/",
    path: "/agriculture",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b15b",
        },
        id: 58104,
        title: "Seeds",
        slug: "seeds",
        active: true,
        meta: {
          description:
            "Seeds Industry report analyses by seed type, by crop type and by geography. Mordor Intelligence™ 【  Seeds Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Seeds Market Research Reports, seeds industry, gm seeds market, non-gm seeds market, hybrid seeds market, organic seeds market,  grains, vegetables and oilseeds market, seed industry by crop type, gm seed industry by trait type, seeds industry report, seeds market report",
          tags: "Seeds Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Seeds Industry report analyses  by seed type, by crop type and by geography. Market Forecast up to 2020.",
          short_description:
            "Seed is the most vital input for crop production and agricultural industry growth. The recently increased consolidation of the seed industry necessitates a comprehensive analysis of the trade scenario in the seed market. Research reports analyze trends and sentiments with the most reliable market data.",
        },
        picture: null,
        __v: 0,
        parent: "/agriculture",
        path: "/agriculture/seeds",
        sub_categories: [
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42222",
            },
            slug: "crop-seeds",
            title: "Crop Seeds",
            id: 3000270,
            parent: "/agriculture/seeds",
            path: "/agriculture/seeds/crop-seeds",
            active: true,
            meta: {
              tags: "Crop Seeds",
              keywords: "Crop Seeds",
              description: "Crop Seeds",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42223",
                },
                slug: "fibre-crop-seeds",
                title: "Fibre Crop Seeds",
                id: 3000271,
                parent: "/agriculture/seeds/crop-seeds",
                path: "/agriculture/seeds/crop-seeds/fibre-crop-seeds",
                active: true,
                meta: {
                  tags: "Fibre Crop Seeds",
                  keywords: "Fibre Crop Seeds",
                  description: "Fibre Crop Seeds",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42224",
                    },
                    slug: "cotton-seed-for-sowing",
                    title: "Cotton Seed for Sowing",
                    id: 3000272,
                    parent: "/agriculture/seeds/crop-seeds/fibre-crop-seeds",
                    path: "/agriculture/seeds/crop-seeds/fibre-crop-seeds/cotton-seed-for-sowing",
                    active: true,
                    meta: {
                      tags: "Cotton Seed for Sowing",
                      keywords: "Cotton Seed for Sowing",
                      description: "Cotton Seed for Sowing",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42225",
                },
                slug: "forage-seeds",
                title: "Forage Seeds",
                id: 3000273,
                parent: "/agriculture/seeds/crop-seeds",
                path: "/agriculture/seeds/crop-seeds/forage-seeds",
                active: true,
                meta: {
                  tags: "Forage Seeds",
                  keywords: "Forage Seeds",
                  description: "Forage Seeds",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42226",
                    },
                    slug: "forage-sorghum-seed",
                    title: "Forage Sorghum Seed",
                    id: 3000274,
                    parent: "/agriculture/seeds/crop-seeds/forage-seeds",
                    path: "/agriculture/seeds/crop-seeds/forage-seeds/forage-sorghum-seed",
                    active: true,
                    meta: {
                      tags: "Forage Sorghum Seed",
                      keywords: "Forage Sorghum Seed",
                      description: "Forage Sorghum Seed",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42227",
                    },
                    slug: "alfalfa-seed",
                    title: "Alfalfa Seed",
                    id: 3000275,
                    parent: "/agriculture/seeds/crop-seeds/forage-seeds",
                    path: "/agriculture/seeds/crop-seeds/forage-seeds/alfalfa-seed",
                    active: true,
                    meta: {
                      tags: "Alfalfa Seed",
                      keywords: "Alfalfa Seed",
                      description: "Alfalfa Seed",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42228",
                },
                slug: "grain-seeds",
                title: "Grain Seeds",
                id: 3000276,
                parent: "/agriculture/seeds/crop-seeds",
                path: "/agriculture/seeds/crop-seeds/grain-seeds",
                active: true,
                meta: {
                  tags: "Grain Seeds",
                  keywords: "Grain Seeds",
                  description: "Grain Seeds",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42229",
                    },
                    slug: "maize-seeds",
                    title: "Maize Seeds",
                    id: 3000277,
                    parent: "/agriculture/seeds/crop-seeds/grain-seeds",
                    path: "/agriculture/seeds/crop-seeds/grain-seeds/maize-seeds",
                    active: true,
                    meta: {
                      tags: "Maize Seeds",
                      keywords: "Maize Seeds",
                      description: "Maize Seeds",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4222a",
                    },
                    slug: "rice-seed",
                    title: "Rice Seed",
                    id: 3000278,
                    parent: "/agriculture/seeds/crop-seeds/grain-seeds",
                    path: "/agriculture/seeds/crop-seeds/grain-seeds/rice-seed",
                    active: true,
                    meta: {
                      tags: "Rice Seed",
                      keywords: "Rice Seed",
                      description: "Rice Seed",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4222b",
                        },
                        slug: "hybrid-rice-seed",
                        title: "Hybrid Rice Seed",
                        id: 3000279,
                        parent:
                          "/agriculture/seeds/crop-seeds/grain-seeds/rice-seed",
                        path: "/agriculture/seeds/crop-seeds/grain-seeds/rice-seed/hybrid-rice-seed",
                        active: true,
                        meta: {
                          tags: "Hybrid Rice Seed",
                          keywords: "Hybrid Rice Seed",
                          description: "Hybrid Rice Seed",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4222c",
                    },
                    slug: "sorghum-seeds",
                    title: "Sorghum Seeds",
                    id: 3000280,
                    parent: "/agriculture/seeds/crop-seeds/grain-seeds",
                    path: "/agriculture/seeds/crop-seeds/grain-seeds/sorghum-seeds",
                    active: true,
                    meta: {
                      tags: "Sorghum Seeds",
                      keywords: "Sorghum Seeds",
                      description: "Sorghum Seeds",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4222d",
                    },
                    slug: "wheat-seeds",
                    title: "Wheat Seeds",
                    id: 3000281,
                    parent: "/agriculture/seeds/crop-seeds/grain-seeds",
                    path: "/agriculture/seeds/crop-seeds/grain-seeds/wheat-seeds",
                    active: true,
                    meta: {
                      tags: "Wheat Seeds",
                      keywords: "Wheat Seeds",
                      description: "Wheat Seeds",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4222e",
                },
                slug: "oilseed-for-sowing",
                title: "Oilseed for Sowing",
                id: 3000282,
                parent: "/agriculture/seeds/crop-seeds",
                path: "/agriculture/seeds/crop-seeds/oilseed-for-sowing",
                active: true,
                meta: {
                  tags: "Oilseed for Sowing",
                  keywords: "Oilseed for Sowing",
                  description: "Oilseed for Sowing",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4222f",
                    },
                    slug: "canola-seed-for-sowing",
                    title: "Canola Seed for Sowing",
                    id: 3000283,
                    parent: "/agriculture/seeds/crop-seeds/oilseed-for-sowing",
                    path: "/agriculture/seeds/crop-seeds/oilseed-for-sowing/canola-seed-for-sowing",
                    active: true,
                    meta: {
                      tags: "Canola Seed for Sowing",
                      keywords: "Canola Seed for Sowing",
                      description: "Canola Seed for Sowing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42230",
                    },
                    slug: "soybean-seed",
                    title: "Soybean Seed",
                    id: 3000284,
                    parent: "/agriculture/seeds/crop-seeds/oilseed-for-sowing",
                    path: "/agriculture/seeds/crop-seeds/oilseed-for-sowing/soybean-seed",
                    active: true,
                    meta: {
                      tags: "Soybean Seed",
                      keywords: "Soybean Seed",
                      description: "Soybean Seed",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42231",
                    },
                    slug: "sunflower-seed-for-sowing",
                    title: "Sunflower Seed for Sowing",
                    id: 3000285,
                    parent: "/agriculture/seeds/crop-seeds/oilseed-for-sowing",
                    path: "/agriculture/seeds/crop-seeds/oilseed-for-sowing/sunflower-seed-for-sowing",
                    active: true,
                    meta: {
                      tags: "Sunflower Seed for Sowing",
                      keywords: "Sunflower Seed for Sowing",
                      description: "Sunflower Seed for Sowing",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42232",
            },
            slug: "garden-seeds",
            title: "Garden Seeds",
            id: 3000286,
            parent: "/agriculture/seeds",
            path: "/agriculture/seeds/garden-seeds",
            active: true,
            meta: {
              tags: "Garden Seeds",
              keywords: "Garden Seeds",
              description: "Garden Seeds",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42233",
            },
            slug: "turf-seeds",
            title: "Turf Seeds",
            id: 3000287,
            parent: "/agriculture/seeds",
            path: "/agriculture/seeds/turf-seeds",
            active: true,
            meta: {
              tags: "Turf Seeds",
              keywords: "Turf Seeds",
              description: "Turf Seeds",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42234",
            },
            slug: "vegetable-seeds",
            title: "Vegetable Seeds",
            id: 3000288,
            parent: "/agriculture/seeds",
            path: "/agriculture/seeds/vegetable-seeds",
            active: true,
            meta: {
              tags: "Vegetable Seeds",
              keywords: "Vegetable Seeds",
              description: "Vegetable Seeds",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42235",
                },
                slug: "onion-seeds",
                title: "Onion Seeds",
                id: 3000289,
                parent: "/agriculture/seeds/vegetable-seeds",
                path: "/agriculture/seeds/vegetable-seeds/onion-seeds",
                active: true,
                meta: {
                  tags: "Onion Seeds",
                  keywords: "Onion Seeds",
                  description: "Onion Seeds",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42236",
                },
                slug: "tomato-seeds",
                title: "Tomato Seeds",
                id: 3000290,
                parent: "/agriculture/seeds/vegetable-seeds",
                path: "/agriculture/seeds/vegetable-seeds/tomato-seeds",
                active: true,
                meta: {
                  tags: "Tomato Seeds",
                  keywords: "Tomato Seeds",
                  description: "Tomato Seeds",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b15c",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Crop Protection market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Crop Protection Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Crop Protection market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Crop Protection Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Crop Protection Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Crop Protection Market Research Reports, crop protection industry, crop protection market, crop protection market report, crop protection market reports, crop protection industry report, crop protection industry reports, crop protection industry analysis, crop protection industry growth, crop protection market intelligence report",
          description:
            "Mordor Intelligence™ 【  Crop Protection Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Crop Protection",
        slug: "crop-protection",
        id: 58154,
        __v: 0,
        parent: "/agriculture",
        path: "/agriculture/crop-protection",
        sub_categories: [
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421da",
            },
            slug: "acaricides",
            title: "Acaricides",
            id: 3000196,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/acaricides",
            active: true,
            meta: {
              tags: "Acaricides",
              keywords: "Acaricides",
              description: "Acaricides",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421db",
            },
            slug: "agricultural-biologicals",
            title: "Agricultural Biologicals",
            id: 3000197,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/agricultural-biologicals",
            active: true,
            meta: {
              tags: "Agricultural Biologicals",
              keywords: "Agricultural Biologicals",
              description: "Agricultural Biologicals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421dc",
            },
            slug: "agricultural-enzymes",
            title: "Agricultural Enzymes",
            id: 3000198,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/agricultural-enzymes",
            active: true,
            meta: {
              tags: "Agricultural Enzymes",
              keywords: "Agricultural Enzymes",
              description: "Agricultural Enzymes",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421dd",
            },
            slug: "agricultural-films",
            title: "Agricultural Films",
            id: 3000199,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/agricultural-films",
            active: true,
            meta: {
              tags: "Agricultural Films",
              keywords: "Agricultural Films",
              description: "Agricultural Films",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421de",
            },
            slug: "agricultural-inoculants",
            title: "Agricultural Inoculants",
            id: 3000200,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/agricultural-inoculants",
            active: true,
            meta: {
              tags: "Agricultural Inoculants",
              keywords: "Agricultural Inoculants",
              description: "Agricultural Inoculants",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421df",
            },
            slug: "agriculture-additives",
            title: "Agriculture Additives",
            id: 3000201,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/agriculture-additives",
            active: true,
            meta: {
              tags: "Agriculture Additives",
              keywords: "Agriculture Additives",
              description: "Agriculture Additives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421e0",
                },
                slug: "agricultural-adjuvants",
                title: "Agricultural Adjuvants",
                id: 3000202,
                parent: "/agriculture/crop-protection/agriculture-additives",
                path: "/agriculture/crop-protection/agriculture-additives/agricultural-adjuvants",
                active: true,
                meta: {
                  tags: "Agricultural Adjuvants",
                  keywords: "Agricultural Adjuvants",
                  description: "Agricultural Adjuvants",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421e1",
                    },
                    slug: "agricultural-surfactants",
                    title: "Agricultural Surfactants",
                    id: 3000203,
                    parent:
                      "/agriculture/crop-protection/agriculture-additives/agricultural-adjuvants",
                    path: "/agriculture/crop-protection/agriculture-additives/agricultural-adjuvants/agricultural-surfactants",
                    active: true,
                    meta: {
                      tags: "Agricultural Surfactants",
                      keywords: "Agricultural Surfactants",
                      description: "Agricultural Surfactants",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421e2",
            },
            slug: "agrochemicals",
            title: "Agrochemicals",
            id: 3000204,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/agrochemicals",
            active: true,
            meta: {
              tags: "Agrochemicals",
              keywords: "Agrochemicals",
              description: "Agrochemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421e3",
                },
                slug: "agricultural-disinfectants",
                title: "Agricultural Disinfectants",
                id: 3000205,
                parent: "/agriculture/crop-protection/agrochemicals",
                path: "/agriculture/crop-protection/agrochemicals/agricultural-disinfectants",
                active: true,
                meta: {
                  tags: "Agricultural Disinfectants",
                  keywords: "Agricultural Disinfectants",
                  description: "Agricultural Disinfectants",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421e4",
            },
            slug: "agriculture-antibacterial",
            title: "Agriculture Antibacterial",
            id: 3000206,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/agriculture-antibacterial",
            active: true,
            meta: {
              tags: "Agriculture Antibacterial",
              keywords: "Agriculture Antibacterial",
              description: "Agriculture Antibacterial",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421e5",
            },
            slug: "biological-crop-protection",
            title: "Biological Crop Protection",
            id: 3000207,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/biological-crop-protection",
            active: true,
            meta: {
              tags: "Biological Crop Protection",
              keywords: "Biological Crop Protection",
              description: "Biological Crop Protection",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421e6",
                },
                slug: "biological-control",
                title: "Biological Control",
                id: 3000208,
                parent:
                  "/agriculture/crop-protection/biological-crop-protection",
                path: "/agriculture/crop-protection/biological-crop-protection/biological-control",
                active: true,
                meta: {
                  tags: "Biological Control",
                  keywords: "Biological Control",
                  description: "Biological Control",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421e7",
                    },
                    slug: "microbials",
                    title: "Microbials",
                    id: 3000209,
                    parent:
                      "/agriculture/crop-protection/biological-crop-protection/biological-control",
                    path: "/agriculture/crop-protection/biological-crop-protection/biological-control/microbials",
                    active: true,
                    meta: {
                      tags: "Microbials",
                      keywords: "Microbials",
                      description: "Microbials",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421e8",
                },
                slug: "biopesticides",
                title: "Biopesticides",
                id: 3000210,
                parent:
                  "/agriculture/crop-protection/biological-crop-protection",
                path: "/agriculture/crop-protection/biological-crop-protection/biopesticides",
                active: true,
                meta: {
                  tags: "Biopesticides",
                  keywords: "Biopesticides",
                  description: "Biopesticides",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421e9",
                    },
                    slug: "biofungicides",
                    title: "Biofungicides",
                    id: 3000211,
                    parent:
                      "/agriculture/crop-protection/biological-crop-protection/biopesticides",
                    path: "/agriculture/crop-protection/biological-crop-protection/biopesticides/biofungicides",
                    active: true,
                    meta: {
                      tags: "Biofungicides",
                      keywords: "Biofungicides",
                      description: "Biofungicides",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421ea",
                    },
                    slug: "bioinsecticides",
                    title: "Bioinsecticides",
                    id: 3000212,
                    parent:
                      "/agriculture/crop-protection/biological-crop-protection/biopesticides",
                    path: "/agriculture/crop-protection/biological-crop-protection/biopesticides/bioinsecticides",
                    active: true,
                    meta: {
                      tags: "Bioinsecticides",
                      keywords: "Bioinsecticides",
                      description: "Bioinsecticides",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421eb",
                    },
                    slug: "microbial-pesticides",
                    title: "Microbial Pesticides",
                    id: 3000213,
                    parent:
                      "/agriculture/crop-protection/biological-crop-protection/biopesticides",
                    path: "/agriculture/crop-protection/biological-crop-protection/biopesticides/microbial-pesticides",
                    active: true,
                    meta: {
                      tags: "Microbial Pesticides",
                      keywords: "Microbial Pesticides",
                      description: "Microbial Pesticides",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421ec",
            },
            slug: "fruits-and-vegetables-crop-protection",
            title: "Fruits and Vegetables Crop Protection",
            id: 3000214,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/fruits-and-vegetables-crop-protection",
            active: true,
            meta: {
              tags: "Fruits and Vegetables Crop Protection",
              keywords: "Fruits and Vegetables Crop Protection",
              description: "Fruits and Vegetables Crop Protection",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421ed",
            },
            slug: "fumigants",
            title: "Fumigants",
            id: 3000215,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/fumigants",
            active: true,
            meta: {
              tags: "Fumigants",
              keywords: "Fumigants",
              description: "Fumigants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421ee",
                },
                slug: "soil-fumigants",
                title: "Soil Fumigants",
                id: 3000216,
                parent: "/agriculture/crop-protection/fumigants",
                path: "/agriculture/crop-protection/fumigants/soil-fumigants",
                active: true,
                meta: {
                  tags: "Soil Fumigants",
                  keywords: "Soil Fumigants",
                  description: "Soil Fumigants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421ef",
                },
                slug: "warehouse-fumigants",
                title: "Warehouse Fumigants",
                id: 3000217,
                parent: "/agriculture/crop-protection/fumigants",
                path: "/agriculture/crop-protection/fumigants/warehouse-fumigants",
                active: true,
                meta: {
                  tags: "Warehouse Fumigants",
                  keywords: "Warehouse Fumigants",
                  description: "Warehouse Fumigants",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421f0",
            },
            slug: "fungicides",
            title: "Fungicides",
            id: 3000218,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/fungicides",
            active: true,
            meta: {
              tags: "Fungicides",
              keywords: "Fungicides",
              description: "Fungicides",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421f1",
                },
                slug: "sdhi-fungicides",
                title: "SDHI Fungicides",
                id: 3000219,
                parent: "/agriculture/crop-protection/fungicides",
                path: "/agriculture/crop-protection/fungicides/sdhi-fungicides",
                active: true,
                meta: {
                  tags: "SDHI Fungicides",
                  keywords: "SDHI Fungicides",
                  description: "SDHI Fungicides",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421f2",
            },
            slug: "herbicides",
            title: "Herbicides",
            id: 3000220,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/herbicides",
            active: true,
            meta: {
              tags: "Herbicides",
              keywords: "Herbicides",
              description: "Herbicides",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421f3",
                },
                slug: "dicamba",
                title: "Dicamba",
                id: 3000221,
                parent: "/agriculture/crop-protection/herbicides",
                path: "/agriculture/crop-protection/herbicides/dicamba",
                active: true,
                meta: {
                  tags: "Dicamba",
                  keywords: "Dicamba",
                  description: "Dicamba",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421f4",
                },
                slug: "glyphosate",
                title: "Glyphosate",
                id: 3000222,
                parent: "/agriculture/crop-protection/herbicides",
                path: "/agriculture/crop-protection/herbicides/glyphosate",
                active: true,
                meta: {
                  tags: "Glyphosate",
                  keywords: "Glyphosate",
                  description: "Glyphosate",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421f5",
            },
            slug: "insecticides",
            title: "Insecticides",
            id: 3000223,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/insecticides",
            active: true,
            meta: {
              tags: "Insecticides",
              keywords: "Insecticides",
              description: "Insecticides",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421f6",
                },
                slug: "larvicides",
                title: "Larvicides",
                id: 3000224,
                parent: "/agriculture/crop-protection/insecticides",
                path: "/agriculture/crop-protection/insecticides/larvicides",
                active: true,
                meta: {
                  tags: "Larvicides",
                  keywords: "Larvicides",
                  description: "Larvicides",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421f7",
                },
                slug: "organophosphates",
                title: "Organophosphates",
                id: 3000225,
                parent: "/agriculture/crop-protection/insecticides",
                path: "/agriculture/crop-protection/insecticides/organophosphates",
                active: true,
                meta: {
                  tags: "Organophosphates",
                  keywords: "Organophosphates",
                  description: "Organophosphates",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421f8",
            },
            slug: "molluscicides",
            title: "Molluscicides",
            id: 3000226,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/molluscicides",
            active: true,
            meta: {
              tags: "Molluscicides",
              keywords: "Molluscicides",
              description: "Molluscicides",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421f9",
            },
            slug: "nematicides",
            title: "Nematicides",
            id: 3000227,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/nematicides",
            active: true,
            meta: {
              tags: "Nematicides",
              keywords: "Nematicides",
              description: "Nematicides",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421fa",
            },
            slug: "pest-control",
            title: "Pest Control",
            id: 3000228,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/pest-control",
            active: true,
            meta: {
              tags: "Pest Control",
              keywords: "Pest Control",
              description: "Pest Control",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421fb",
                },
                slug: "household-insecticides",
                title: "Household Insecticides",
                id: 3000229,
                parent: "/agriculture/crop-protection/pest-control",
                path: "/agriculture/crop-protection/pest-control/household-insecticides",
                active: true,
                meta: {
                  tags: "Household Insecticides",
                  keywords: "Household Insecticides",
                  description: "Household Insecticides",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421fc",
                },
                slug: "industrial-insecticides",
                title: "Industrial Insecticides",
                id: 3000230,
                parent: "/agriculture/crop-protection/pest-control",
                path: "/agriculture/crop-protection/pest-control/industrial-insecticides",
                active: true,
                meta: {
                  tags: "Industrial Insecticides",
                  keywords: "Industrial Insecticides",
                  description: "Industrial Insecticides",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421fd",
                    },
                    slug: "stored-grain-insecticides",
                    title: "Stored Grain Insecticides",
                    id: 3000231,
                    parent:
                      "/agriculture/crop-protection/pest-control/industrial-insecticides",
                    path: "/agriculture/crop-protection/pest-control/industrial-insecticides/stored-grain-insecticides",
                    active: true,
                    meta: {
                      tags: "Stored Grain Insecticides",
                      keywords: "Stored Grain Insecticides",
                      description: "Stored Grain Insecticides",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421fe",
            },
            slug: "pheromones",
            title: "Pheromones",
            id: 3000232,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/pheromones",
            active: true,
            meta: {
              tags: "Pheromones",
              keywords: "Pheromones",
              description: "Pheromones",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421ff",
            },
            slug: "plant-growth-regulators",
            title: "Plant Growth Regulators",
            id: 3000233,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/plant-growth-regulators",
            active: true,
            meta: {
              tags: "Plant Growth Regulators",
              keywords: "Plant Growth Regulators",
              description: "Plant Growth Regulators",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42200",
            },
            slug: "rodenticides",
            title: "Rodenticides",
            id: 3000234,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/rodenticides",
            active: true,
            meta: {
              tags: "Rodenticides",
              keywords: "Rodenticides",
              description: "Rodenticides",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42201",
            },
            slug: "seed-coating",
            title: "Seed Coating",
            id: 3000235,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/seed-coating",
            active: true,
            meta: {
              tags: "Seed Coating",
              keywords: "Seed Coating",
              description: "Seed Coating",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42202",
                },
                slug: "seed-colorant",
                title: "Seed Colorant",
                id: 3000236,
                parent: "/agriculture/crop-protection/seed-coating",
                path: "/agriculture/crop-protection/seed-coating/seed-colorant",
                active: true,
                meta: {
                  tags: "Seed Colorant",
                  keywords: "Seed Colorant",
                  description: "Seed Colorant",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42203",
            },
            slug: "seed-treatment",
            title: "Seed Treatment",
            id: 3000237,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/seed-treatment",
            active: true,
            meta: {
              tags: "Seed Treatment",
              keywords: "Seed Treatment",
              description: "Seed Treatment",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42204",
            },
            slug: "turf-and-ornamental-crop-protection",
            title: "Turf & Ornamental Crop Protection",
            id: 3000238,
            parent: "/agriculture/crop-protection",
            path: "/agriculture/crop-protection/turf-and-ornamental-crop-protection",
            active: true,
            meta: {
              tags: "Turf & Ornamental Crop Protection",
              keywords: "Turf & Ornamental Crop Protection",
              description: "Turf & Ornamental Crop Protection",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b15d",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Fertilizers market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Fertilizers Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Fertilizers market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Fertilizers Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Fertilizers Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Fertilizers Market Research Reports, fertilizers, fertilizers market, fertilizers industry, fertilizers industry report, fertilizers market report, fertilizers industry analysis, fertilizers industry research, fertilizers market intelligence report, fertilizers market growth, fertilizers industry forecast",
          description:
            "Mordor Intelligence™ 【  Fertilizers Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Fertilizers",
        slug: "fertilizers",
        id: 58155,
        __v: 0,
        parent: "/agriculture",
        path: "/agriculture/fertilizers",
        sub_categories: [
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42205",
            },
            slug: "agricultural-chelates",
            title: "Agricultural Chelates",
            id: 3000240,
            parent: "/agriculture/fertilizers",
            path: "/agriculture/fertilizers/agricultural-chelates",
            active: true,
            meta: {
              tags: "Agricultural Chelates",
              keywords: "Agricultural Chelates",
              description: "Agricultural Chelates",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42206",
                },
                slug: "metal-chelates",
                title: "Metal Chelates",
                id: 3000241,
                parent: "/agriculture/fertilizers/agricultural-chelates",
                path: "/agriculture/fertilizers/agricultural-chelates/metal-chelates",
                active: true,
                meta: {
                  tags: "Metal Chelates",
                  keywords: "Metal Chelates",
                  description: "Metal Chelates",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42207",
            },
            slug: "biological-fertilizers",
            title: "Biological Fertilizers",
            id: 3000242,
            parent: "/agriculture/fertilizers",
            path: "/agriculture/fertilizers/biological-fertilizers",
            active: true,
            meta: {
              tags: "Biological Fertilizers",
              keywords: "Biological Fertilizers",
              description: "Biological Fertilizers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42208",
                },
                slug: "organic-fertilizers",
                title: "Organic Fertilizers",
                id: 3000243,
                parent: "/agriculture/fertilizers/biological-fertilizers",
                path: "/agriculture/fertilizers/biological-fertilizers/organic-fertilizers",
                active: true,
                meta: {
                  tags: "Organic Fertilizers",
                  keywords: "Organic Fertilizers",
                  description: "Organic Fertilizers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42209",
                    },
                    slug: "biofertilizers",
                    title: "Biofertilizers",
                    id: 3000244,
                    parent:
                      "/agriculture/fertilizers/biological-fertilizers/organic-fertilizers",
                    path: "/agriculture/fertilizers/biological-fertilizers/organic-fertilizers/biofertilizers",
                    active: true,
                    meta: {
                      tags: "Biofertilizers",
                      keywords: "Biofertilizers",
                      description: "Biofertilizers",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4220a",
                        },
                        slug: "azotobacter",
                        title: "Azotobacter",
                        id: 3000245,
                        parent:
                          "/agriculture/fertilizers/biological-fertilizers/organic-fertilizers/biofertilizers",
                        path: "/agriculture/fertilizers/biological-fertilizers/organic-fertilizers/biofertilizers/azotobacter",
                        active: true,
                        meta: {
                          tags: "Azotobacter",
                          keywords: "Azotobacter",
                          description: "Azotobacter",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4220b",
                        },
                        slug: "mycorrhiza",
                        title: "Mycorrhiza",
                        id: 3000246,
                        parent:
                          "/agriculture/fertilizers/biological-fertilizers/organic-fertilizers/biofertilizers",
                        path: "/agriculture/fertilizers/biological-fertilizers/organic-fertilizers/biofertilizers/mycorrhiza",
                        active: true,
                        meta: {
                          tags: "Mycorrhiza",
                          keywords: "Mycorrhiza",
                          description: "Mycorrhiza",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4220c",
                        },
                        slug: "rhizobium",
                        title: "Rhizobium",
                        id: 3000247,
                        parent:
                          "/agriculture/fertilizers/biological-fertilizers/organic-fertilizers/biofertilizers",
                        path: "/agriculture/fertilizers/biological-fertilizers/organic-fertilizers/biofertilizers/rhizobium",
                        active: true,
                        meta: {
                          tags: "Rhizobium",
                          keywords: "Rhizobium",
                          description: "Rhizobium",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b4220d",
            },
            slug: "fertilizer-additives",
            title: "Fertilizer Additives",
            id: 3000248,
            parent: "/agriculture/fertilizers",
            path: "/agriculture/fertilizers/fertilizer-additives",
            active: true,
            meta: {
              tags: "Fertilizer Additives",
              keywords: "Fertilizer Additives",
              description: "Fertilizer Additives",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b4220e",
            },
            slug: "micronutrient-fertilizers",
            title: "Micronutrient Fertilizers",
            id: 3000249,
            parent: "/agriculture/fertilizers",
            path: "/agriculture/fertilizers/micronutrient-fertilizers",
            active: true,
            meta: {
              tags: "Micronutrient Fertilizers",
              keywords: "Micronutrient Fertilizers",
              description: "Micronutrient Fertilizers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b4220f",
            },
            slug: "organic-soil-amendments",
            title: "Organic Soil Amendments",
            id: 3000250,
            parent: "/agriculture/fertilizers",
            path: "/agriculture/fertilizers/organic-soil-amendments",
            active: true,
            meta: {
              tags: "Organic Soil Amendments",
              keywords: "Organic Soil Amendments",
              description: "Organic Soil Amendments",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42210",
                },
                slug: "biostimulants",
                title: "Biostimulants",
                id: 3000251,
                parent: "/agriculture/fertilizers/organic-soil-amendments",
                path: "/agriculture/fertilizers/organic-soil-amendments/biostimulants",
                active: true,
                meta: {
                  tags: "Biostimulants",
                  keywords: "Biostimulants",
                  description: "Biostimulants",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42211",
            },
            slug: "primary-nutrient-fertilizers",
            title: "Primary Nutrient Fertilizers",
            id: 3000252,
            parent: "/agriculture/fertilizers",
            path: "/agriculture/fertilizers/primary-nutrient-fertilizers",
            active: true,
            meta: {
              tags: "Primary Nutrient Fertilizers",
              keywords: "Primary Nutrient Fertilizers",
              description: "Primary Nutrient Fertilizers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42212",
                },
                slug: "nitrogenous-fertilizers",
                title: "Nitrogenous Fertilizers",
                id: 3000253,
                parent: "/agriculture/fertilizers/primary-nutrient-fertilizers",
                path: "/agriculture/fertilizers/primary-nutrient-fertilizers/nitrogenous-fertilizers",
                active: true,
                meta: {
                  tags: "Nitrogenous Fertilizers",
                  keywords: "Nitrogenous Fertilizers",
                  description: "Nitrogenous Fertilizers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42213",
                    },
                    slug: "urea",
                    title: "Urea",
                    id: 3000254,
                    parent:
                      "/agriculture/fertilizers/primary-nutrient-fertilizers/nitrogenous-fertilizers",
                    path: "/agriculture/fertilizers/primary-nutrient-fertilizers/nitrogenous-fertilizers/urea",
                    active: true,
                    meta: {
                      tags: "Urea",
                      keywords: "Urea",
                      description: "Urea",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42214",
                },
                slug: "phosphatic-fertilizers",
                title: "Phosphatic Fertilizers",
                id: 3000255,
                parent: "/agriculture/fertilizers/primary-nutrient-fertilizers",
                path: "/agriculture/fertilizers/primary-nutrient-fertilizers/phosphatic-fertilizers",
                active: true,
                meta: {
                  tags: "Phosphatic Fertilizers",
                  keywords: "Phosphatic Fertilizers",
                  description: "Phosphatic Fertilizers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42215",
                },
                slug: "potash-fertilizers",
                title: "Potash Fertilizers",
                id: 3000256,
                parent: "/agriculture/fertilizers/primary-nutrient-fertilizers",
                path: "/agriculture/fertilizers/primary-nutrient-fertilizers/potash-fertilizers",
                active: true,
                meta: {
                  tags: "Potash Fertilizers",
                  keywords: "Potash Fertilizers",
                  description: "Potash Fertilizers",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42216",
            },
            slug: "secondary-macronutrient-fertilizers",
            title: "Secondary Macronutrient Fertilizers",
            id: 3000257,
            parent: "/agriculture/fertilizers",
            path: "/agriculture/fertilizers/secondary-macronutrient-fertilizers",
            active: true,
            meta: {
              tags: "Secondary Macronutrient Fertilizers",
              keywords: "Secondary Macronutrient Fertilizers",
              description: "Secondary Macronutrient Fertilizers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42217",
                },
                slug: "sulfur-fertilizers",
                title: "Sulfur Fertilizers",
                id: 3000258,
                parent:
                  "/agriculture/fertilizers/secondary-macronutrient-fertilizers",
                path: "/agriculture/fertilizers/secondary-macronutrient-fertilizers/sulfur-fertilizers",
                active: true,
                meta: {
                  tags: "Sulfur Fertilizers",
                  keywords: "Sulfur Fertilizers",
                  description: "Sulfur Fertilizers",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42218",
            },
            slug: "soil-treatment",
            title: "Soil Treatment",
            id: 3000259,
            parent: "/agriculture/fertilizers",
            path: "/agriculture/fertilizers/soil-treatment",
            active: true,
            meta: {
              tags: "Soil Treatment",
              keywords: "Soil Treatment",
              description: "Soil Treatment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42219",
                },
                slug: "soil-conditioners",
                title: "Soil Conditioners",
                id: 3000260,
                parent: "/agriculture/fertilizers/soil-treatment",
                path: "/agriculture/fertilizers/soil-treatment/soil-conditioners",
                active: true,
                meta: {
                  tags: "Soil Conditioners",
                  keywords: "Soil Conditioners",
                  description: "Soil Conditioners",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b4221a",
            },
            slug: "specialty-fertilizers",
            title: "Specialty Fertilizers",
            id: 3000261,
            parent: "/agriculture/fertilizers",
            path: "/agriculture/fertilizers/specialty-fertilizers",
            active: true,
            meta: {
              tags: "Specialty Fertilizers",
              keywords: "Specialty Fertilizers",
              description: "Specialty Fertilizers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4221b",
                },
                slug: "controlled-release-fertilizers",
                title: "Controlled Release Fertilizers",
                id: 3000262,
                parent: "/agriculture/fertilizers/specialty-fertilizers",
                path: "/agriculture/fertilizers/specialty-fertilizers/controlled-release-fertilizers",
                active: true,
                meta: {
                  tags: "Controlled Release Fertilizers",
                  keywords: "Controlled Release Fertilizers",
                  description: "Controlled Release Fertilizers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4221c",
                    },
                    slug: "polymer-coated-controlled-release-fertilizers",
                    title: "Polymer Coated Controlled Release Fertilizers",
                    id: 3000263,
                    parent:
                      "/agriculture/fertilizers/specialty-fertilizers/controlled-release-fertilizers",
                    path: "/agriculture/fertilizers/specialty-fertilizers/controlled-release-fertilizers/polymer-coated-controlled-release-fertilizers",
                    active: true,
                    meta: {
                      tags: "Polymer Coated Controlled Release Fertilizers",
                      keywords: "Polymer Coated Controlled Release Fertilizers",
                      description:
                        "Polymer Coated Controlled Release Fertilizers",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4221d",
                        },
                        slug: "polymer-coated-controlled-release-npk-fertilizers",
                        title:
                          "Polymer Coated Controlled Release NPK Fertilizers",
                        id: 3000264,
                        parent:
                          "/agriculture/fertilizers/specialty-fertilizers/controlled-release-fertilizers/polymer-coated-controlled-release-fertilizers",
                        path: "/agriculture/fertilizers/specialty-fertilizers/controlled-release-fertilizers/polymer-coated-controlled-release-fertilizers/polymer-coated-controlled-release-npk-fertilizers",
                        active: true,
                        meta: {
                          tags: "Polymer Coated Controlled Release NPK Fertilizers",
                          keywords:
                            "Polymer Coated Controlled Release NPK Fertilizers",
                          description:
                            "Polymer Coated Controlled Release NPK Fertilizers",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4221e",
                        },
                        slug: "polymer-coated-controlled-release-urea",
                        title: "Polymer Coated Controlled Release Urea",
                        id: 3000265,
                        parent:
                          "/agriculture/fertilizers/specialty-fertilizers/controlled-release-fertilizers/polymer-coated-controlled-release-fertilizers",
                        path: "/agriculture/fertilizers/specialty-fertilizers/controlled-release-fertilizers/polymer-coated-controlled-release-fertilizers/polymer-coated-controlled-release-urea",
                        active: true,
                        meta: {
                          tags: "Polymer Coated Controlled Release Urea",
                          keywords: "Polymer Coated Controlled Release Urea",
                          description: "Polymer Coated Controlled Release Urea",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4221f",
                },
                slug: "foliar-fertilizers",
                title: "Foliar Fertilizers",
                id: 3000266,
                parent: "/agriculture/fertilizers/specialty-fertilizers",
                path: "/agriculture/fertilizers/specialty-fertilizers/foliar-fertilizers",
                active: true,
                meta: {
                  tags: "Foliar Fertilizers",
                  keywords: "Foliar Fertilizers",
                  description: "Foliar Fertilizers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42220",
                },
                slug: "liquid-fertilizers",
                title: "Liquid Fertilizers",
                id: 3000267,
                parent: "/agriculture/fertilizers/specialty-fertilizers",
                path: "/agriculture/fertilizers/specialty-fertilizers/liquid-fertilizers",
                active: true,
                meta: {
                  tags: "Liquid Fertilizers",
                  keywords: "Liquid Fertilizers",
                  description: "Liquid Fertilizers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42221",
                },
                slug: "water-soluble-fertilizers",
                title: "Water Soluble Fertilizers",
                id: 3000268,
                parent: "/agriculture/fertilizers/specialty-fertilizers",
                path: "/agriculture/fertilizers/specialty-fertilizers/water-soluble-fertilizers",
                active: true,
                meta: {
                  tags: "Water Soluble Fertilizers",
                  keywords: "Water Soluble Fertilizers",
                  description: "Water Soluble Fertilizers",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b15e",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Agricultural Machinery & Tech market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Agricultural Machinery & Tech Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Agricultural Machinery & Tech market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Agricultural Machinery & Tech Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Agricultural Machinery & Tech Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Agricultural Machinery & Tech Market Research Reports, Agricultural Machinery & Tech Market, Agricultural Machinery & Tech industry, Agricultural Machinery & Tech Market report, Agricultural Machinery & Tech industry report, Agricultural Machinery & Tech market research report, Agricultural Machinery & Tech industry research report",
          description:
            "Mordor Intelligence™ 【  Agricultural Machinery & Tech Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Agricultural Machinery & Tech",
        slug: "agricultural-machinery-tech",
        id: 58156,
        __v: 0,
        parent: "/agriculture",
        path: "/agriculture/agricultural-machinery-tech",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b15f",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Agricultural Commodities market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Agricultural Commodities Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Agricultural Commodities market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Agricultural Commodities Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Agricultural Commodities Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Agricultural Commodities Market Research Reports, agricultural commodities market, agricultural commodities industry, agricultural market report, agricultural commodities market research report, agricultural industry report, agricultural market analysis, agricultural research industry trends",
          description:
            "Mordor Intelligence™ 【  Agricultural Commodities Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Agricultural Commodities",
        slug: "agricultural-commodities",
        id: 58157,
        __v: 0,
        parent: "/agriculture",
        path: "/agriculture/agricultural-commodities",
        sub_categories: [
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42119",
            },
            slug: "aquaculture",
            title: "Aquaculture",
            id: 3000002,
            parent: "/agriculture/agricultural-commodities",
            path: "/agriculture/agricultural-commodities/aquaculture",
            active: true,
            meta: {
              tags: "Aquaculture",
              keywords: "Aquaculture",
              description: "Aquaculture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b4211a",
            },
            slug: "bulk-commodities",
            title: "Bulk Commodities",
            id: 3000003,
            parent: "/agriculture/agricultural-commodities",
            path: "/agriculture/agricultural-commodities/bulk-commodities",
            active: true,
            meta: {
              tags: "Bulk Commodities",
              keywords: "Bulk Commodities",
              description: "Bulk Commodities",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4211b",
                },
                slug: "cocoa",
                title: "Cocoa",
                id: 3000004,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/cocoa",
                active: true,
                meta: {
                  tags: "Cocoa",
                  keywords: "Cocoa",
                  description: "Cocoa",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4211c",
                },
                slug: "coffee-beans",
                title: "Coffee Beans",
                id: 3000005,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/coffee-beans",
                active: true,
                meta: {
                  tags: "Coffee Beans",
                  keywords: "Coffee Beans",
                  description: "Coffee Beans",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4211d",
                },
                slug: "cotton",
                title: "Cotton",
                id: 3000006,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/cotton",
                active: true,
                meta: {
                  tags: "Cotton",
                  keywords: "Cotton",
                  description: "Cotton",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4211e",
                },
                slug: "grains",
                title: "Grains",
                id: 3000007,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/grains",
                active: true,
                meta: {
                  tags: "Grains",
                  keywords: "Grains",
                  description: "Grains",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4211f",
                    },
                    slug: "barley",
                    title: "Barley",
                    id: 3000008,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/grains",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/grains/barley",
                    active: true,
                    meta: {
                      tags: "Barley",
                      keywords: "Barley",
                      description: "Barley",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42120",
                    },
                    slug: "hops",
                    title: "Hops",
                    id: 3000009,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/grains",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/grains/hops",
                    active: true,
                    meta: {
                      tags: "Hops",
                      keywords: "Hops",
                      description: "Hops",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42121",
                    },
                    slug: "maize",
                    title: "Maize",
                    id: 3000010,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/grains",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/grains/maize",
                    active: true,
                    meta: {
                      tags: "Maize",
                      keywords: "Maize",
                      description: "Maize",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42122",
                    },
                    slug: "rice",
                    title: "Rice",
                    id: 3000011,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/grains",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/grains/rice",
                    active: true,
                    meta: {
                      tags: "Rice",
                      keywords: "Rice",
                      description: "Rice",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42123",
                    },
                    slug: "rye",
                    title: "Rye",
                    id: 3000012,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/grains",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/grains/rye",
                    active: true,
                    meta: {
                      tags: "Rye",
                      keywords: "Rye",
                      description: "Rye",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42124",
                    },
                    slug: "triticale",
                    title: "Triticale",
                    id: 3000013,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/grains",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/grains/triticale",
                    active: true,
                    meta: {
                      tags: "Triticale",
                      keywords: "Triticale",
                      description: "Triticale",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42125",
                    },
                    slug: "wheat",
                    title: "Wheat",
                    id: 3000014,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/grains",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/grains/wheat",
                    active: true,
                    meta: {
                      tags: "Wheat",
                      keywords: "Wheat",
                      description: "Wheat",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42126",
                },
                slug: "mate",
                title: "Maté",
                id: 3000015,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/mate",
                active: true,
                meta: {
                  tags: "Maté",
                  keywords: "Maté",
                  description: "Maté",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42127",
                },
                slug: "oilseeds",
                title: "Oilseeds",
                id: 3000016,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/oilseeds",
                active: true,
                meta: {
                  tags: "Oilseeds",
                  keywords: "Oilseeds",
                  description: "Oilseeds",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42128",
                    },
                    slug: "cottonseed",
                    title: "Cottonseed",
                    id: 3000017,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/oilseeds",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/oilseeds/cottonseed",
                    active: true,
                    meta: {
                      tags: "Cottonseed",
                      keywords: "Cottonseed",
                      description: "Cottonseed",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42129",
                    },
                    slug: "olives",
                    title: "Olives",
                    id: 3000018,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/oilseeds",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/oilseeds/olives",
                    active: true,
                    meta: {
                      tags: "Olives",
                      keywords: "Olives",
                      description: "Olives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4212a",
                    },
                    slug: "peanuts",
                    title: "Peanuts",
                    id: 3000019,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/oilseeds",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/oilseeds/peanuts",
                    active: true,
                    meta: {
                      tags: "Peanuts",
                      keywords: "Peanuts",
                      description: "Peanuts",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4212b",
                    },
                    slug: "rapeseed",
                    title: "Rapeseed",
                    id: 3000020,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/oilseeds",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/oilseeds/rapeseed",
                    active: true,
                    meta: {
                      tags: "Rapeseed",
                      keywords: "Rapeseed",
                      description: "Rapeseed",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4212c",
                    },
                    slug: "safflower-seed",
                    title: "Safflower Seed",
                    id: 3000021,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/oilseeds",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/oilseeds/safflower-seed",
                    active: true,
                    meta: {
                      tags: "Safflower Seed",
                      keywords: "Safflower Seed",
                      description: "Safflower Seed",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4212d",
                    },
                    slug: "sesame-seed",
                    title: "Sesame Seed",
                    id: 3000022,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/oilseeds",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/oilseeds/sesame-seed",
                    active: true,
                    meta: {
                      tags: "Sesame Seed",
                      keywords: "Sesame Seed",
                      description: "Sesame Seed",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4212e",
                    },
                    slug: "soybean",
                    title: "Soybean",
                    id: 3000023,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/oilseeds",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/oilseeds/soybean",
                    active: true,
                    meta: {
                      tags: "Soybean",
                      keywords: "Soybean",
                      description: "Soybean",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4212f",
                    },
                    slug: "sunflower-seed",
                    title: "Sunflower Seed",
                    id: 3000024,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/oilseeds",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/oilseeds/sunflower-seed",
                    active: true,
                    meta: {
                      tags: "Sunflower Seed",
                      keywords: "Sunflower Seed",
                      description: "Sunflower Seed",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42130",
                },
                slug: "potato",
                title: "Potato",
                id: 3000025,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/potato",
                active: true,
                meta: {
                  tags: "Potato",
                  keywords: "Potato",
                  description: "Potato",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42131",
                },
                slug: "pulses",
                title: "Pulses",
                id: 3000026,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/pulses",
                active: true,
                meta: {
                  tags: "Pulses",
                  keywords: "Pulses",
                  description: "Pulses",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42132",
                    },
                    slug: "chickpea",
                    title: "Chickpea",
                    id: 3000027,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/pulses",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/pulses/chickpea",
                    active: true,
                    meta: {
                      tags: "Chickpea",
                      keywords: "Chickpea",
                      description: "Chickpea",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42133",
                    },
                    slug: "dry-beans",
                    title: "Dry Beans",
                    id: 3000028,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/pulses",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/pulses/dry-beans",
                    active: true,
                    meta: {
                      tags: "Dry Beans",
                      keywords: "Dry Beans",
                      description: "Dry Beans",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42134",
                    },
                    slug: "fava-bean",
                    title: "Fava Bean",
                    id: 3000029,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/pulses",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/pulses/fava-bean",
                    active: true,
                    meta: {
                      tags: "Fava Bean",
                      keywords: "Fava Bean",
                      description: "Fava Bean",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42135",
                    },
                    slug: "lentils",
                    title: "Lentils",
                    id: 3000030,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/pulses",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/pulses/lentils",
                    active: true,
                    meta: {
                      tags: "Lentils",
                      keywords: "Lentils",
                      description: "Lentils",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42136",
                },
                slug: "spices",
                title: "Spices",
                id: 3000031,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/spices",
                active: true,
                meta: {
                  tags: "Spices",
                  keywords: "Spices",
                  description: "Spices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42137",
                    },
                    slug: "cardamom",
                    title: "Cardamom",
                    id: 3000032,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/spices",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/spices/cardamom",
                    active: true,
                    meta: {
                      tags: "Cardamom",
                      keywords: "Cardamom",
                      description: "Cardamom",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42138",
                    },
                    slug: "cinnamon",
                    title: "Cinnamon",
                    id: 3000033,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/spices",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/spices/cinnamon",
                    active: true,
                    meta: {
                      tags: "Cinnamon",
                      keywords: "Cinnamon",
                      description: "Cinnamon",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42139",
                    },
                    slug: "clove",
                    title: "Clove",
                    id: 3000034,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/spices",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/spices/clove",
                    active: true,
                    meta: {
                      tags: "Clove",
                      keywords: "Clove",
                      description: "Clove",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4213a",
                    },
                    slug: "dry-chilies",
                    title: "Dry Chilies",
                    id: 3000035,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/spices",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/spices/dry-chilies",
                    active: true,
                    meta: {
                      tags: "Dry Chilies",
                      keywords: "Dry Chilies",
                      description: "Dry Chilies",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4213b",
                    },
                    slug: "nutmeg",
                    title: "Nutmeg",
                    id: 3000036,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/spices",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/spices/nutmeg",
                    active: true,
                    meta: {
                      tags: "Nutmeg",
                      keywords: "Nutmeg",
                      description: "Nutmeg",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4213c",
                    },
                    slug: "poppy-seeds",
                    title: "Poppy Seeds",
                    id: 3000037,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/spices",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/spices/poppy-seeds",
                    active: true,
                    meta: {
                      tags: "Poppy Seeds",
                      keywords: "Poppy Seeds",
                      description: "Poppy Seeds",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4213d",
                    },
                    slug: "vanilla-beans",
                    title: "Vanilla Beans",
                    id: 3000038,
                    parent:
                      "/agriculture/agricultural-commodities/bulk-commodities/spices",
                    path: "/agriculture/agricultural-commodities/bulk-commodities/spices/vanilla-beans",
                    active: true,
                    meta: {
                      tags: "Vanilla Beans",
                      keywords: "Vanilla Beans",
                      description: "Vanilla Beans",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4213e",
                },
                slug: "taro",
                title: "Taro",
                id: 3000039,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/taro",
                active: true,
                meta: {
                  tags: "Taro",
                  keywords: "Taro",
                  description: "Taro",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4213f",
                },
                slug: "tea-leaves",
                title: "Tea Leaves",
                id: 3000040,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/tea-leaves",
                active: true,
                meta: {
                  tags: "Tea Leaves",
                  keywords: "Tea Leaves",
                  description: "Tea Leaves",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42140",
                },
                slug: "yams",
                title: "Yams",
                id: 3000041,
                parent:
                  "/agriculture/agricultural-commodities/bulk-commodities",
                path: "/agriculture/agricultural-commodities/bulk-commodities/yams",
                active: true,
                meta: {
                  tags: "Yams",
                  keywords: "Yams",
                  description: "Yams",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42141",
            },
            slug: "fresh-commodities",
            title: "Fresh Commodities",
            id: 3000042,
            parent: "/agriculture/agricultural-commodities",
            path: "/agriculture/agricultural-commodities/fresh-commodities",
            active: true,
            meta: {
              tags: "Fresh Commodities",
              keywords: "Fresh Commodities",
              description: "Fresh Commodities",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42142",
                },
                slug: "durian",
                title: "Durian",
                id: 3000043,
                parent:
                  "/agriculture/agricultural-commodities/fresh-commodities",
                path: "/agriculture/agricultural-commodities/fresh-commodities/durian",
                active: true,
                meta: {
                  tags: "Durian",
                  keywords: "Durian",
                  description: "Durian",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42143",
                },
                slug: "fruits",
                title: "Fruits",
                id: 3000044,
                parent:
                  "/agriculture/agricultural-commodities/fresh-commodities",
                path: "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                active: true,
                meta: {
                  tags: "Fruits",
                  keywords: "Fruits",
                  description: "Fruits",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42144",
                    },
                    slug: "avocado",
                    title: "Avocado",
                    id: 3000045,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/avocado",
                    active: true,
                    meta: {
                      tags: "Avocado",
                      keywords: "Avocado",
                      description: "Avocado",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42145",
                        },
                        slug: "hass-avocado",
                        title: "Hass Avocado",
                        id: 3000046,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/avocado",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/avocado/hass-avocado",
                        active: true,
                        meta: {
                          tags: "Hass Avocado",
                          keywords: "Hass Avocado",
                          description: "Hass Avocado",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42146",
                    },
                    slug: "banana",
                    title: "Banana",
                    id: 3000047,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/banana",
                    active: true,
                    meta: {
                      tags: "Banana",
                      keywords: "Banana",
                      description: "Banana",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42147",
                        },
                        slug: "organic-banana",
                        title: "Organic Banana",
                        id: 3000048,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/banana",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/banana/organic-banana",
                        active: true,
                        meta: {
                          tags: "Organic Banana",
                          keywords: "Organic Banana",
                          description: "Organic Banana",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42148",
                    },
                    slug: "citrus-fruits",
                    title: "Citrus Fruits",
                    id: 3000049,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/citrus-fruits",
                    active: true,
                    meta: {
                      tags: "Citrus Fruits",
                      keywords: "Citrus Fruits",
                      description: "Citrus Fruits",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42149",
                        },
                        slug: "lime",
                        title: "Lime",
                        id: 3000050,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/citrus-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/citrus-fruits/lime",
                        active: true,
                        meta: {
                          tags: "Lime",
                          keywords: "Lime",
                          description: "Lime",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4214a",
                        },
                        slug: "orange",
                        title: "Orange",
                        id: 3000051,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/citrus-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/citrus-fruits/orange",
                        active: true,
                        meta: {
                          tags: "Orange",
                          keywords: "Orange",
                          description: "Orange",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4214b",
                    },
                    slug: "dates",
                    title: "Dates",
                    id: 3000052,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/dates",
                    active: true,
                    meta: {
                      tags: "Dates",
                      keywords: "Dates",
                      description: "Dates",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4214c",
                    },
                    slug: "dragon-fruit",
                    title: "Dragon Fruit",
                    id: 3000053,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/dragon-fruit",
                    active: true,
                    meta: {
                      tags: "Dragon Fruit",
                      keywords: "Dragon Fruit",
                      description: "Dragon Fruit",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4214d",
                    },
                    slug: "fresh-berries",
                    title: "Fresh Berries",
                    id: 3000054,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/fresh-berries",
                    active: true,
                    meta: {
                      tags: "Fresh Berries",
                      keywords: "Fresh Berries",
                      description: "Fresh Berries",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4214e",
                        },
                        slug: "blueberry",
                        title: "Blueberry",
                        id: 3000055,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/fresh-berries",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/fresh-berries/blueberry",
                        active: true,
                        meta: {
                          tags: "Blueberry",
                          keywords: "Blueberry",
                          description: "Blueberry",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4214f",
                        },
                        slug: "cranberry",
                        title: "Cranberry",
                        id: 3000056,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/fresh-berries",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/fresh-berries/cranberry",
                        active: true,
                        meta: {
                          tags: "Cranberry",
                          keywords: "Cranberry",
                          description: "Cranberry",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42150",
                        },
                        slug: "gooseberry",
                        title: "Gooseberry",
                        id: 3000057,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/fresh-berries",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/fresh-berries/gooseberry",
                        active: true,
                        meta: {
                          tags: "Gooseberry",
                          keywords: "Gooseberry",
                          description: "Gooseberry",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42151",
                    },
                    slug: "grapes",
                    title: "Grapes",
                    id: 3000058,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/grapes",
                    active: true,
                    meta: {
                      tags: "Grapes",
                      keywords: "Grapes",
                      description: "Grapes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42152",
                    },
                    slug: "kiwi-fruit",
                    title: "Kiwi Fruit",
                    id: 3000059,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/kiwi-fruit",
                    active: true,
                    meta: {
                      tags: "Kiwi Fruit",
                      keywords: "Kiwi Fruit",
                      description: "Kiwi Fruit",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42153",
                    },
                    slug: "pineapple",
                    title: "Pineapple",
                    id: 3000060,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/pineapple",
                    active: true,
                    meta: {
                      tags: "Pineapple",
                      keywords: "Pineapple",
                      description: "Pineapple",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42154",
                    },
                    slug: "stone-fruits",
                    title: "Stone Fruits",
                    id: 3000061,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/fruits",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits",
                    active: true,
                    meta: {
                      tags: "Stone Fruits",
                      keywords: "Stone Fruits",
                      description: "Stone Fruits",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42155",
                        },
                        slug: "apples",
                        title: "Apples",
                        id: 3000062,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/apples",
                        active: true,
                        meta: {
                          tags: "Apples",
                          keywords: "Apples",
                          description: "Apples",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42156",
                        },
                        slug: "apricots",
                        title: "Apricots",
                        id: 3000063,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/apricots",
                        active: true,
                        meta: {
                          tags: "Apricots",
                          keywords: "Apricots",
                          description: "Apricots",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42157",
                        },
                        slug: "cherries",
                        title: "Cherries",
                        id: 3000064,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/cherries",
                        active: true,
                        meta: {
                          tags: "Cherries",
                          keywords: "Cherries",
                          description: "Cherries",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42158",
                        },
                        slug: "lychee",
                        title: "Lychee",
                        id: 3000065,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/lychee",
                        active: true,
                        meta: {
                          tags: "Lychee",
                          keywords: "Lychee",
                          description: "Lychee",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42159",
                        },
                        slug: "peaches-and-nectarines",
                        title: "Peaches & Nectarines",
                        id: 3000066,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/peaches-and-nectarines",
                        active: true,
                        meta: {
                          tags: "Peaches & Nectarines",
                          keywords: "Peaches & Nectarines",
                          description: "Peaches & Nectarines",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad96d036e2a4b4f5b4215a",
                            },
                            slug: "peaches",
                            title: "Peaches",
                            id: 3000067,
                            parent:
                              "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/peaches-and-nectarines",
                            path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/peaches-and-nectarines/peaches",
                            active: true,
                            meta: {
                              tags: "Peaches",
                              keywords: "Peaches",
                              description: "Peaches",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4215b",
                        },
                        slug: "pear",
                        title: "Pear",
                        id: 3000068,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/pear",
                        active: true,
                        meta: {
                          tags: "Pear",
                          keywords: "Pear",
                          description: "Pear",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4215c",
                        },
                        slug: "persimmon",
                        title: "Persimmon",
                        id: 3000069,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/persimmon",
                        active: true,
                        meta: {
                          tags: "Persimmon",
                          keywords: "Persimmon",
                          description: "Persimmon",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4215d",
                        },
                        slug: "plums-and-soles",
                        title: "Plums & Soles",
                        id: 3000070,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/fruits/stone-fruits/plums-and-soles",
                        active: true,
                        meta: {
                          tags: "Plums & Soles",
                          keywords: "Plums & Soles",
                          description: "Plums & Soles",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4215e",
                },
                slug: "livestock",
                title: "Livestock",
                id: 3000071,
                parent:
                  "/agriculture/agricultural-commodities/fresh-commodities",
                path: "/agriculture/agricultural-commodities/fresh-commodities/livestock",
                active: true,
                meta: {
                  tags: "Livestock",
                  keywords: "Livestock",
                  description: "Livestock",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4215f",
                },
                slug: "raw-seafood",
                title: "Raw Seafood",
                id: 3000072,
                parent:
                  "/agriculture/agricultural-commodities/fresh-commodities",
                path: "/agriculture/agricultural-commodities/fresh-commodities/raw-seafood",
                active: true,
                meta: {
                  tags: "Raw Seafood",
                  keywords: "Raw Seafood",
                  description: "Raw Seafood",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42160",
                },
                slug: "vegetables",
                title: "Vegetables",
                id: 3000073,
                parent:
                  "/agriculture/agricultural-commodities/fresh-commodities",
                path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                active: true,
                meta: {
                  tags: "Vegetables",
                  keywords: "Vegetables",
                  description: "Vegetables",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42161",
                    },
                    slug: "artichoke",
                    title: "Artichoke",
                    id: 3000074,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/artichoke",
                    active: true,
                    meta: {
                      tags: "Artichoke",
                      keywords: "Artichoke",
                      description: "Artichoke",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42162",
                    },
                    slug: "asparagus",
                    title: "Asparagus",
                    id: 3000075,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/asparagus",
                    active: true,
                    meta: {
                      tags: "Asparagus",
                      keywords: "Asparagus",
                      description: "Asparagus",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42163",
                    },
                    slug: "brassicas",
                    title: "Brassicas",
                    id: 3000076,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/brassicas",
                    active: true,
                    meta: {
                      tags: "Brassicas",
                      keywords: "Brassicas",
                      description: "Brassicas",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42164",
                        },
                        slug: "cabbages-and-other-brassicas",
                        title: "Cabbages & Other Brassicas",
                        id: 3000077,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/vegetables/brassicas",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/brassicas/cabbages-and-other-brassicas",
                        active: true,
                        meta: {
                          tags: "Cabbages & Other Brassicas",
                          keywords: "Cabbages & Other Brassicas",
                          description: "Cabbages & Other Brassicas",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42165",
                        },
                        slug: "carrots-and-turnips",
                        title: "Carrots & Turnips",
                        id: 3000078,
                        parent:
                          "/agriculture/agricultural-commodities/fresh-commodities/vegetables/brassicas",
                        path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/brassicas/carrots-and-turnips",
                        active: true,
                        meta: {
                          tags: "Carrots & Turnips",
                          keywords: "Carrots & Turnips",
                          description: "Carrots & Turnips",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42166",
                    },
                    slug: "garlic",
                    title: "Garlic",
                    id: 3000079,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/garlic",
                    active: true,
                    meta: {
                      tags: "Garlic",
                      keywords: "Garlic",
                      description: "Garlic",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42167",
                    },
                    slug: "microgreens",
                    title: "Microgreens",
                    id: 3000080,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/microgreens",
                    active: true,
                    meta: {
                      tags: "Microgreens",
                      keywords: "Microgreens",
                      description: "Microgreens",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42168",
                    },
                    slug: "mushrooms",
                    title: "Mushrooms",
                    id: 3000081,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/mushrooms",
                    active: true,
                    meta: {
                      tags: "Mushrooms",
                      keywords: "Mushrooms",
                      description: "Mushrooms",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42169",
                    },
                    slug: "peas",
                    title: "Peas",
                    id: 3000082,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/peas",
                    active: true,
                    meta: {
                      tags: "Peas",
                      keywords: "Peas",
                      description: "Peas",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4216a",
                    },
                    slug: "pumpkin",
                    title: "Pumpkin",
                    id: 3000083,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/pumpkin",
                    active: true,
                    meta: {
                      tags: "Pumpkin",
                      keywords: "Pumpkin",
                      description: "Pumpkin",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4216b",
                    },
                    slug: "tomato",
                    title: "Tomato",
                    id: 3000084,
                    parent:
                      "/agriculture/agricultural-commodities/fresh-commodities/vegetables",
                    path: "/agriculture/agricultural-commodities/fresh-commodities/vegetables/tomato",
                    active: true,
                    meta: {
                      tags: "Tomato",
                      keywords: "Tomato",
                      description: "Tomato",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b4216c",
            },
            slug: "honey",
            title: "Honey",
            id: 3000085,
            parent: "/agriculture/agricultural-commodities",
            path: "/agriculture/agricultural-commodities/honey",
            active: true,
            meta: {
              tags: "Honey",
              keywords: "Honey",
              description: "Honey",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b4216d",
            },
            slug: "specialty-commodities",
            title: "Specialty Commodities",
            id: 3000086,
            parent: "/agriculture/agricultural-commodities",
            path: "/agriculture/agricultural-commodities/specialty-commodities",
            active: true,
            meta: {
              tags: "Specialty Commodities",
              keywords: "Specialty Commodities",
              description: "Specialty Commodities",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4216e",
                },
                slug: "cannabis-cannabinoids",
                title: "Cannabis, Cannabinoids",
                id: 3000087,
                parent:
                  "/agriculture/agricultural-commodities/specialty-commodities",
                path: "/agriculture/agricultural-commodities/specialty-commodities/cannabis-cannabinoids",
                active: true,
                meta: {
                  tags: "Cannabis, Cannabinoids",
                  keywords: "Cannabis, Cannabinoids",
                  description: "Cannabis, Cannabinoids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4216f",
                },
                slug: "dry-fruits",
                title: "Dry Fruits",
                id: 3000088,
                parent:
                  "/agriculture/agricultural-commodities/specialty-commodities",
                path: "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits",
                active: true,
                meta: {
                  tags: "Dry Fruits",
                  keywords: "Dry Fruits",
                  description: "Dry Fruits",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42170",
                    },
                    slug: "areca-nuts",
                    title: "Areca Nuts",
                    id: 3000089,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits/areca-nuts",
                    active: true,
                    meta: {
                      tags: "Areca Nuts",
                      keywords: "Areca Nuts",
                      description: "Areca Nuts",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42171",
                    },
                    slug: "dried-grapes",
                    title: "Dried Grapes",
                    id: 3000090,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits/dried-grapes",
                    active: true,
                    meta: {
                      tags: "Dried Grapes",
                      keywords: "Dried Grapes",
                      description: "Dried Grapes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42172",
                    },
                    slug: "figs",
                    title: "Figs",
                    id: 3000091,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits/figs",
                    active: true,
                    meta: {
                      tags: "Figs",
                      keywords: "Figs",
                      description: "Figs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42173",
                    },
                    slug: "pecan",
                    title: "Pecan",
                    id: 3000092,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits/pecan",
                    active: true,
                    meta: {
                      tags: "Pecan",
                      keywords: "Pecan",
                      description: "Pecan",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42174",
                    },
                    slug: "prunes",
                    title: "Prunes",
                    id: 3000093,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/dry-fruits/prunes",
                    active: true,
                    meta: {
                      tags: "Prunes",
                      keywords: "Prunes",
                      description: "Prunes",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42175",
                },
                slug: "edible-insects",
                title: "Edible Insects",
                id: 3000094,
                parent:
                  "/agriculture/agricultural-commodities/specialty-commodities",
                path: "/agriculture/agricultural-commodities/specialty-commodities/edible-insects",
                active: true,
                meta: {
                  tags: "Edible Insects",
                  keywords: "Edible Insects",
                  description: "Edible Insects",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42176",
                },
                slug: "floriculture",
                title: "Floriculture",
                id: 3000095,
                parent:
                  "/agriculture/agricultural-commodities/specialty-commodities",
                path: "/agriculture/agricultural-commodities/specialty-commodities/floriculture",
                active: true,
                meta: {
                  tags: "Floriculture",
                  keywords: "Floriculture",
                  description: "Floriculture",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42177",
                },
                slug: "forages",
                title: "Forages",
                id: 3000096,
                parent:
                  "/agriculture/agricultural-commodities/specialty-commodities",
                path: "/agriculture/agricultural-commodities/specialty-commodities/forages",
                active: true,
                meta: {
                  tags: "Forages",
                  keywords: "Forages",
                  description: "Forages",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42178",
                    },
                    slug: "alfalfa",
                    title: "Alfalfa",
                    id: 3000097,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/forages",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/forages/alfalfa",
                    active: true,
                    meta: {
                      tags: "Alfalfa",
                      keywords: "Alfalfa",
                      description: "Alfalfa",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42179",
                },
                slug: "industrial-commodities",
                title: "Industrial Commodities",
                id: 3000098,
                parent:
                  "/agriculture/agricultural-commodities/specialty-commodities",
                path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                active: true,
                meta: {
                  tags: "Industrial Commodities",
                  keywords: "Industrial Commodities",
                  description: "Industrial Commodities",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4217a",
                    },
                    slug: "blood-meal",
                    title: "Blood Meal",
                    id: 3000099,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/blood-meal",
                    active: true,
                    meta: {
                      tags: "Blood Meal",
                      keywords: "Blood Meal",
                      description: "Blood Meal",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4217b",
                    },
                    slug: "feather-meal",
                    title: "Feather Meal",
                    id: 3000100,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/feather-meal",
                    active: true,
                    meta: {
                      tags: "Feather Meal",
                      keywords: "Feather Meal",
                      description: "Feather Meal",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4217c",
                    },
                    slug: "guar",
                    title: "Guar",
                    id: 3000101,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/guar",
                    active: true,
                    meta: {
                      tags: "Guar",
                      keywords: "Guar",
                      description: "Guar",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4217d",
                    },
                    slug: "natural-rubber",
                    title: "Natural Rubber",
                    id: 3000102,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/natural-rubber",
                    active: true,
                    meta: {
                      tags: "Natural Rubber",
                      keywords: "Natural Rubber",
                      description: "Natural Rubber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4217e",
                    },
                    slug: "oilseed-meals",
                    title: "Oilseed Meals",
                    id: 3000103,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/oilseed-meals",
                    active: true,
                    meta: {
                      tags: "Oilseed Meals",
                      keywords: "Oilseed Meals",
                      description: "Oilseed Meals",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4217f",
                        },
                        slug: "copra-meal",
                        title: "Copra Meal",
                        id: 3000104,
                        parent:
                          "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/oilseed-meals",
                        path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/oilseed-meals/copra-meal",
                        active: true,
                        meta: {
                          tags: "Copra Meal",
                          keywords: "Copra Meal",
                          description: "Copra Meal",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42180",
                        },
                        slug: "peanut-meal",
                        title: "Peanut Meal",
                        id: 3000105,
                        parent:
                          "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/oilseed-meals",
                        path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/oilseed-meals/peanut-meal",
                        active: true,
                        meta: {
                          tags: "Peanut Meal",
                          keywords: "Peanut Meal",
                          description: "Peanut Meal",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42181",
                        },
                        slug: "soybean-meal",
                        title: "Soybean Meal",
                        id: 3000106,
                        parent:
                          "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/oilseed-meals",
                        path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/oilseed-meals/soybean-meal",
                        active: true,
                        meta: {
                          tags: "Soybean Meal",
                          keywords: "Soybean Meal",
                          description: "Soybean Meal",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b42182",
                        },
                        slug: "sunflower-meal",
                        title: "Sunflower Meal",
                        id: 3000107,
                        parent:
                          "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/oilseed-meals",
                        path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/oilseed-meals/sunflower-meal",
                        active: true,
                        meta: {
                          tags: "Sunflower Meal",
                          keywords: "Sunflower Meal",
                          description: "Sunflower Meal",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42183",
                    },
                    slug: "silk-yarn",
                    title: "Silk Yarn",
                    id: 3000108,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/silk-yarn",
                    active: true,
                    meta: {
                      tags: "Silk Yarn",
                      keywords: "Silk Yarn",
                      description: "Silk Yarn",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42184",
                    },
                    slug: "wood",
                    title: "Wood",
                    id: 3000109,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/wood",
                    active: true,
                    meta: {
                      tags: "Wood",
                      keywords: "Wood",
                      description: "Wood",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42185",
                    },
                    slug: "wood-pulp",
                    title: "Wood Pulp",
                    id: 3000110,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/wood-pulp",
                    active: true,
                    meta: {
                      tags: "Wood Pulp",
                      keywords: "Wood Pulp",
                      description: "Wood Pulp",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42186",
                    },
                    slug: "wool",
                    title: "Wool",
                    id: 3000111,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/industrial-commodities/wool",
                    active: true,
                    meta: {
                      tags: "Wool",
                      keywords: "Wool",
                      description: "Wool",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42187",
                },
                slug: "superfoods",
                title: "Superfoods",
                id: 3000112,
                parent:
                  "/agriculture/agricultural-commodities/specialty-commodities",
                path: "/agriculture/agricultural-commodities/specialty-commodities/superfoods",
                active: true,
                meta: {
                  tags: "Superfoods",
                  keywords: "Superfoods",
                  description: "Superfoods",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42188",
                    },
                    slug: "canary-seeds",
                    title: "Canary Seeds",
                    id: 3000113,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/superfoods",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/superfoods/canary-seeds",
                    active: true,
                    meta: {
                      tags: "Canary Seeds",
                      keywords: "Canary Seeds",
                      description: "Canary Seeds",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42189",
                    },
                    slug: "chia-seeds",
                    title: "Chia Seeds",
                    id: 3000114,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/superfoods",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/superfoods/chia-seeds",
                    active: true,
                    meta: {
                      tags: "Chia Seeds",
                      keywords: "Chia Seeds",
                      description: "Chia Seeds",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4218a",
                    },
                    slug: "flax-seeds",
                    title: "Flax Seeds",
                    id: 3000115,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/superfoods",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/superfoods/flax-seeds",
                    active: true,
                    meta: {
                      tags: "Flax Seeds",
                      keywords: "Flax Seeds",
                      description: "Flax Seeds",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4218b",
                    },
                    slug: "quinoa-seeds",
                    title: "Quinoa Seeds",
                    id: 3000116,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/superfoods",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/superfoods/quinoa-seeds",
                    active: true,
                    meta: {
                      tags: "Quinoa Seeds",
                      keywords: "Quinoa Seeds",
                      description: "Quinoa Seeds",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4218c",
                },
                slug: "tree-nuts",
                title: "Tree Nuts",
                id: 3000117,
                parent:
                  "/agriculture/agricultural-commodities/specialty-commodities",
                path: "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts",
                active: true,
                meta: {
                  tags: "Tree Nuts",
                  keywords: "Tree Nuts",
                  description: "Tree Nuts",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4218d",
                    },
                    slug: "almonds",
                    title: "Almonds",
                    id: 3000118,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts/almonds",
                    active: true,
                    meta: {
                      tags: "Almonds",
                      keywords: "Almonds",
                      description: "Almonds",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4218e",
                    },
                    slug: "cashew",
                    title: "Cashew",
                    id: 3000119,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts/cashew",
                    active: true,
                    meta: {
                      tags: "Cashew",
                      keywords: "Cashew",
                      description: "Cashew",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b4218f",
                        },
                        slug: "cashew-kernel",
                        title: "Cashew Kernel",
                        id: 3000120,
                        parent:
                          "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts/cashew",
                        path: "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts/cashew/cashew-kernel",
                        active: true,
                        meta: {
                          tags: "Cashew Kernel",
                          keywords: "Cashew Kernel",
                          description: "Cashew Kernel",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42190",
                    },
                    slug: "chestnut",
                    title: "Chestnut",
                    id: 3000121,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts/chestnut",
                    active: true,
                    meta: {
                      tags: "Chestnut",
                      keywords: "Chestnut",
                      description: "Chestnut",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42191",
                    },
                    slug: "hazelnut",
                    title: "Hazelnut",
                    id: 3000122,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts/hazelnut",
                    active: true,
                    meta: {
                      tags: "Hazelnut",
                      keywords: "Hazelnut",
                      description: "Hazelnut",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42192",
                    },
                    slug: "macadamia",
                    title: "Macadamia",
                    id: 3000123,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts/macadamia",
                    active: true,
                    meta: {
                      tags: "Macadamia",
                      keywords: "Macadamia",
                      description: "Macadamia",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42193",
                    },
                    slug: "pistachio",
                    title: "Pistachio",
                    id: 3000124,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts/pistachio",
                    active: true,
                    meta: {
                      tags: "Pistachio",
                      keywords: "Pistachio",
                      description: "Pistachio",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42194",
                    },
                    slug: "walnut",
                    title: "Walnut",
                    id: 3000125,
                    parent:
                      "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts",
                    path: "/agriculture/agricultural-commodities/specialty-commodities/tree-nuts/walnut",
                    active: true,
                    meta: {
                      tags: "Walnut",
                      keywords: "Walnut",
                      description: "Walnut",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b160",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Other Market Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Market Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Other Market Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Market Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Other Agriculture Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Agriculture Market Research Reports, agriculture market, agriculture market report, agriculture market research, agriculture industry, agriculture industry report, agriculture industry research",
          description:
            "Mordor Intelligence™ 【  Other Agriculture Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Other Market Research",
        slug: "agriculture-others",
        id: 58158,
        __v: 0,
        parent: "/agriculture",
        path: "/agriculture/agriculture-others",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64ad96d036e2a4b4f5b42195",
        },
        slug: "agricultural-machinery-and-technology",
        title: "Agricultural Machinery and Technology",
        id: 58615,
        parent: "/agriculture",
        path: "/agriculture/agricultural-machinery-and-technology",
        active: true,
        meta: {
          tags: "Agricultural Machinery and Technology",
          keywords: "Agricultural Machinery and Technology",
          description: "Agricultural Machinery and Technology",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b42196",
            },
            slug: "agriculture-equipment",
            title: "Agriculture Equipment",
            id: 3000127,
            parent: "/agriculture/agricultural-machinery-and-technology",
            path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment",
            active: true,
            meta: {
              tags: "Agriculture Equipment",
              keywords: "Agriculture Equipment",
              description: "Agriculture Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b42197",
                },
                slug: "agricultural-implements",
                title: "Agricultural Implements",
                id: 3000128,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agriculture-equipment",
                path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements",
                active: true,
                meta: {
                  tags: "Agricultural Implements",
                  keywords: "Agricultural Implements",
                  description: "Agricultural Implements",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42198",
                    },
                    slug: "fertilizer-spreaders",
                    title: "Fertilizer Spreaders",
                    id: 3000129,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements/fertilizer-spreaders",
                    active: true,
                    meta: {
                      tags: "Fertilizer Spreaders",
                      keywords: "Fertilizer Spreaders",
                      description: "Fertilizer Spreaders",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b42199",
                    },
                    slug: "planting-machinery",
                    title: "Planting Machinery",
                    id: 3000130,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements/planting-machinery",
                    active: true,
                    meta: {
                      tags: "Planting Machinery",
                      keywords: "Planting Machinery",
                      description: "Planting Machinery",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4219a",
                    },
                    slug: "plowing-and-cultivating-machinery",
                    title: "Plowing & Cultivating Machinery",
                    id: 3000131,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements/plowing-and-cultivating-machinery",
                    active: true,
                    meta: {
                      tags: "Plowing & Cultivating Machinery",
                      keywords: "Plowing & Cultivating Machinery",
                      description: "Plowing & Cultivating Machinery",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4219b",
                    },
                    slug: "seeding-machinery",
                    title: "Seeding Machinery",
                    id: 3000132,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements/seeding-machinery",
                    active: true,
                    meta: {
                      tags: "Seeding Machinery",
                      keywords: "Seeding Machinery",
                      description: "Seeding Machinery",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4219c",
                    },
                    slug: "sprayers",
                    title: "Sprayers",
                    id: 3000133,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/agricultural-implements/sprayers",
                    active: true,
                    meta: {
                      tags: "Sprayers",
                      keywords: "Sprayers",
                      description: "Sprayers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b4219d",
                },
                slug: "forestry-equipment",
                title: "Forestry Equipment",
                id: 3000134,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agriculture-equipment",
                path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/forestry-equipment",
                active: true,
                meta: {
                  tags: "Forestry Equipment",
                  keywords: "Forestry Equipment",
                  description: "Forestry Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4219e",
                    },
                    slug: "chain-saw",
                    title: "Chain Saw",
                    id: 3000135,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/forestry-equipment",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/forestry-equipment/chain-saw",
                    active: true,
                    meta: {
                      tags: "Chain Saw",
                      keywords: "Chain Saw",
                      description: "Chain Saw",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b4219f",
                    },
                    slug: "stump-cutters-and-grinders",
                    title: "Stump Cutters & Grinders",
                    id: 3000136,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/forestry-equipment",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/forestry-equipment/stump-cutters-and-grinders",
                    active: true,
                    meta: {
                      tags: "Stump Cutters & Grinders",
                      keywords: "Stump Cutters & Grinders",
                      description: "Stump Cutters & Grinders",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421a0",
                },
                slug: "harvesting-machinery",
                title: "Harvesting Machinery",
                id: 3000137,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agriculture-equipment",
                path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/harvesting-machinery",
                active: true,
                meta: {
                  tags: "Harvesting Machinery",
                  keywords: "Harvesting Machinery",
                  description: "Harvesting Machinery",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421a1",
                    },
                    slug: "combine-harvesters",
                    title: "Combine Harvesters",
                    id: 3000138,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/harvesting-machinery",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/harvesting-machinery/combine-harvesters",
                    active: true,
                    meta: {
                      tags: "Combine Harvesters",
                      keywords: "Combine Harvesters",
                      description: "Combine Harvesters",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421a2",
                    },
                    slug: "fruit-pickers",
                    title: "Fruit Pickers",
                    id: 3000139,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/harvesting-machinery",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/harvesting-machinery/fruit-pickers",
                    active: true,
                    meta: {
                      tags: "Fruit Pickers",
                      keywords: "Fruit Pickers",
                      description: "Fruit Pickers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421a3",
                    },
                    slug: "sugarcane-harvesters",
                    title: "Sugarcane Harvesters",
                    id: 3000140,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/harvesting-machinery",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/harvesting-machinery/sugarcane-harvesters",
                    active: true,
                    meta: {
                      tags: "Sugarcane Harvesters",
                      keywords: "Sugarcane Harvesters",
                      description: "Sugarcane Harvesters",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421a4",
                },
                slug: "landscaping-tools",
                title: "Landscaping Tools",
                id: 3000141,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agriculture-equipment",
                path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/landscaping-tools",
                active: true,
                meta: {
                  tags: "Landscaping Tools",
                  keywords: "Landscaping Tools",
                  description: "Landscaping Tools",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421a5",
                    },
                    slug: "lawn-mower",
                    title: "Lawn Mower",
                    id: 3000142,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/landscaping-tools",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/landscaping-tools/lawn-mower",
                    active: true,
                    meta: {
                      tags: "Lawn Mower",
                      keywords: "Lawn Mower",
                      description: "Lawn Mower",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421a6",
                },
                slug: "post-harvest-equipment",
                title: "Post Harvest Equipment",
                id: 3000143,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agriculture-equipment",
                path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/post-harvest-equipment",
                active: true,
                meta: {
                  tags: "Post Harvest Equipment",
                  keywords: "Post Harvest Equipment",
                  description: "Post Harvest Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421a7",
                    },
                    slug: "haying-and-foraging-machinery",
                    title: "Haying and Foraging Machinery",
                    id: 3000144,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/post-harvest-equipment",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/post-harvest-equipment/haying-and-foraging-machinery",
                    active: true,
                    meta: {
                      tags: "Haying and Foraging Machinery",
                      keywords: "Haying and Foraging Machinery",
                      description: "Haying and Foraging Machinery",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421a8",
                },
                slug: "tractors",
                title: "Tractors",
                id: 3000145,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agriculture-equipment",
                path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/tractors",
                active: true,
                meta: {
                  tags: "Tractors",
                  keywords: "Tractors",
                  description: "Tractors",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421a9",
                    },
                    slug: "agricultural-tractor",
                    title: "Agricultural Tractor",
                    id: 3000146,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/tractors",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/tractors/agricultural-tractor",
                    active: true,
                    meta: {
                      tags: "Agricultural Tractor",
                      keywords: "Agricultural Tractor",
                      description: "Agricultural Tractor",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b421aa",
                        },
                        slug: "autonomous-tractors",
                        title: "Autonomous Tractors",
                        id: 3000147,
                        parent:
                          "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/tractors/agricultural-tractor",
                        path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/tractors/agricultural-tractor/autonomous-tractors",
                        active: true,
                        meta: {
                          tags: "Autonomous Tractors",
                          keywords: "Autonomous Tractors",
                          description: "Autonomous Tractors",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b421ab",
                        },
                        slug: "orchard-tractors",
                        title: "Orchard Tractors",
                        id: 3000148,
                        parent:
                          "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/tractors/agricultural-tractor",
                        path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/tractors/agricultural-tractor/orchard-tractors",
                        active: true,
                        meta: {
                          tags: "Orchard Tractors",
                          keywords: "Orchard Tractors",
                          description: "Orchard Tractors",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b421ac",
                        },
                        slug: "utility-tractors",
                        title: "Utility Tractors",
                        id: 3000149,
                        parent:
                          "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/tractors/agricultural-tractor",
                        path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/tractors/agricultural-tractor/utility-tractors",
                        active: true,
                        meta: {
                          tags: "Utility Tractors",
                          keywords: "Utility Tractors",
                          description: "Utility Tractors",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421ad",
                },
                slug: "transplanter",
                title: "Transplanter",
                id: 3000150,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agriculture-equipment",
                path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/transplanter",
                active: true,
                meta: {
                  tags: "Transplanter",
                  keywords: "Transplanter",
                  description: "Transplanter",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421ae",
                    },
                    slug: "rice-transplanter",
                    title: "Rice Transplanter",
                    id: 3000151,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/transplanter",
                    path: "/agriculture/agricultural-machinery-and-technology/agriculture-equipment/transplanter/rice-transplanter",
                    active: true,
                    meta: {
                      tags: "Rice Transplanter",
                      keywords: "Rice Transplanter",
                      description: "Rice Transplanter",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421af",
            },
            slug: "agritech",
            title: "AgriTech",
            id: 3000152,
            parent: "/agriculture/agricultural-machinery-and-technology",
            path: "/agriculture/agricultural-machinery-and-technology/agritech",
            active: true,
            meta: {
              tags: "AgriTech",
              keywords: "AgriTech",
              description: "AgriTech",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421b0",
                },
                slug: "agricultural-sensors",
                title: "Agricultural Sensors",
                id: 3000153,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agritech",
                path: "/agriculture/agricultural-machinery-and-technology/agritech/agricultural-sensors",
                active: true,
                meta: {
                  tags: "Agricultural Sensors",
                  keywords: "Agricultural Sensors",
                  description: "Agricultural Sensors",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421b1",
                    },
                    slug: "position-sensors",
                    title: "Position Sensors",
                    id: 3000154,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agritech/agricultural-sensors",
                    path: "/agriculture/agricultural-machinery-and-technology/agritech/agricultural-sensors/position-sensors",
                    active: true,
                    meta: {
                      tags: "Position Sensors",
                      keywords: "Position Sensors",
                      description: "Position Sensors",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421b2",
                },
                slug: "agricultural-software",
                title: "Agricultural Software",
                id: 3000155,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agritech",
                path: "/agriculture/agricultural-machinery-and-technology/agritech/agricultural-software",
                active: true,
                meta: {
                  tags: "Agricultural Software",
                  keywords: "Agricultural Software",
                  description: "Agricultural Software",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421b3",
                    },
                    slug: "farm-management-software",
                    title: "Farm Management Software",
                    id: 3000156,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agritech/agricultural-software",
                    path: "/agriculture/agricultural-machinery-and-technology/agritech/agricultural-software/farm-management-software",
                    active: true,
                    meta: {
                      tags: "Farm Management Software",
                      keywords: "Farm Management Software",
                      description: "Farm Management Software",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421b4",
                },
                slug: "agriculture-ai",
                title: "Agriculture AI",
                id: 3000157,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agritech",
                path: "/agriculture/agricultural-machinery-and-technology/agritech/agriculture-ai",
                active: true,
                meta: {
                  tags: "Agriculture AI",
                  keywords: "Agriculture AI",
                  description: "Agriculture AI",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421b5",
                },
                slug: "agriculture-drones",
                title: "Agriculture Drones",
                id: 3000158,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agritech",
                path: "/agriculture/agricultural-machinery-and-technology/agritech/agriculture-drones",
                active: true,
                meta: {
                  tags: "Agriculture Drones",
                  keywords: "Agriculture Drones",
                  description: "Agriculture Drones",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421b6",
                },
                slug: "agriculture-robots",
                title: "Agriculture Robots",
                id: 3000159,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agritech",
                path: "/agriculture/agricultural-machinery-and-technology/agritech/agriculture-robots",
                active: true,
                meta: {
                  tags: "Agriculture Robots",
                  keywords: "Agriculture Robots",
                  description: "Agriculture Robots",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421b7",
                },
                slug: "digital-agriculture",
                title: "Digital Agriculture",
                id: 3000160,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agritech",
                path: "/agriculture/agricultural-machinery-and-technology/agritech/digital-agriculture",
                active: true,
                meta: {
                  tags: "Digital Agriculture",
                  keywords: "Digital Agriculture",
                  description: "Digital Agriculture",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421b8",
                },
                slug: "indoor-farming",
                title: "Indoor Farming",
                id: 3000161,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agritech",
                path: "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming",
                active: true,
                meta: {
                  tags: "Indoor Farming",
                  keywords: "Indoor Farming",
                  description: "Indoor Farming",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421b9",
                    },
                    slug: "commercial-greenhouse",
                    title: "Commercial Greenhouse",
                    id: 3000162,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming",
                    path: "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/commercial-greenhouse",
                    active: true,
                    meta: {
                      tags: "Commercial Greenhouse",
                      keywords: "Commercial Greenhouse",
                      description: "Commercial Greenhouse",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421ba",
                    },
                    slug: "hydroponics",
                    title: "Hydroponics",
                    id: 3000163,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming",
                    path: "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/hydroponics",
                    active: true,
                    meta: {
                      tags: "Hydroponics",
                      keywords: "Hydroponics",
                      description: "Hydroponics",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b421bb",
                        },
                        slug: "aquaponics",
                        title: "Aquaponics",
                        id: 3000164,
                        parent:
                          "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/hydroponics",
                        path: "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/hydroponics/aquaponics",
                        active: true,
                        meta: {
                          tags: "Aquaponics",
                          keywords: "Aquaponics",
                          description: "Aquaponics",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad96d036e2a4b4f5b421bc",
                        },
                        slug: "hydroponic-inputs",
                        title: "Hydroponic Inputs",
                        id: 3000165,
                        parent:
                          "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/hydroponics",
                        path: "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/hydroponics/hydroponic-inputs",
                        active: true,
                        meta: {
                          tags: "Hydroponic Inputs",
                          keywords: "Hydroponic Inputs",
                          description: "Hydroponic Inputs",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad96d036e2a4b4f5b421bd",
                            },
                            slug: "hydroponic-nutrients",
                            title: "Hydroponic Nutrients",
                            id: 3000166,
                            parent:
                              "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/hydroponics/hydroponic-inputs",
                            path: "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/hydroponics/hydroponic-inputs/hydroponic-nutrients",
                            active: true,
                            meta: {
                              tags: "Hydroponic Nutrients",
                              keywords: "Hydroponic Nutrients",
                              description: "Hydroponic Nutrients",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ad96d036e2a4b4f5b421be",
                            },
                            slug: "plant-growth-chambers",
                            title: "Plant Growth Chambers",
                            id: 3000167,
                            parent:
                              "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/hydroponics/hydroponic-inputs",
                            path: "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/hydroponics/hydroponic-inputs/plant-growth-chambers",
                            active: true,
                            meta: {
                              tags: "Plant Growth Chambers",
                              keywords: "Plant Growth Chambers",
                              description: "Plant Growth Chambers",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421bf",
                    },
                    slug: "vertical-farming",
                    title: "Vertical Farming",
                    id: 3000168,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming",
                    path: "/agriculture/agricultural-machinery-and-technology/agritech/indoor-farming/vertical-farming",
                    active: true,
                    meta: {
                      tags: "Vertical Farming",
                      keywords: "Vertical Farming",
                      description: "Vertical Farming",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421c0",
                },
                slug: "precision-farming",
                title: "Precision Farming",
                id: 3000169,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/agritech",
                path: "/agriculture/agricultural-machinery-and-technology/agritech/precision-farming",
                active: true,
                meta: {
                  tags: "Precision Farming",
                  keywords: "Precision Farming",
                  description: "Precision Farming",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421c1",
                    },
                    slug: "fertigation-and-chemigation",
                    title: "Fertigation & Chemigation",
                    id: 3000170,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agritech/precision-farming",
                    path: "/agriculture/agricultural-machinery-and-technology/agritech/precision-farming/fertigation-and-chemigation",
                    active: true,
                    meta: {
                      tags: "Fertigation & Chemigation",
                      keywords: "Fertigation & Chemigation",
                      description: "Fertigation & Chemigation",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421c2",
                    },
                    slug: "precision-farming-software",
                    title: "Precision Farming Software",
                    id: 3000171,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agritech/precision-farming",
                    path: "/agriculture/agricultural-machinery-and-technology/agritech/precision-farming/precision-farming-software",
                    active: true,
                    meta: {
                      tags: "Precision Farming Software",
                      keywords: "Precision Farming Software",
                      description: "Precision Farming Software",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421c3",
                    },
                    slug: "precision-irrigation",
                    title: "Precision Irrigation",
                    id: 3000172,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agritech/precision-farming",
                    path: "/agriculture/agricultural-machinery-and-technology/agritech/precision-farming/precision-irrigation",
                    active: true,
                    meta: {
                      tags: "Precision Irrigation",
                      keywords: "Precision Irrigation",
                      description: "Precision Irrigation",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421c4",
                    },
                    slug: "variable-rate-technology",
                    title: "Variable Rate Technology",
                    id: 3000173,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/agritech/precision-farming",
                    path: "/agriculture/agricultural-machinery-and-technology/agritech/precision-farming/variable-rate-technology",
                    active: true,
                    meta: {
                      tags: "Variable Rate Technology",
                      keywords: "Variable Rate Technology",
                      description: "Variable Rate Technology",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421c5",
            },
            slug: "irrigation",
            title: "Irrigation",
            id: 3000174,
            parent: "/agriculture/agricultural-machinery-and-technology",
            path: "/agriculture/agricultural-machinery-and-technology/irrigation",
            active: true,
            meta: {
              tags: "Irrigation",
              keywords: "Irrigation",
              description: "Irrigation",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421c6",
                },
                slug: "irrigation-pumps",
                title: "Irrigation Pumps",
                id: 3000175,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/irrigation",
                path: "/agriculture/agricultural-machinery-and-technology/irrigation/irrigation-pumps",
                active: true,
                meta: {
                  tags: "Irrigation Pumps",
                  keywords: "Irrigation Pumps",
                  description: "Irrigation Pumps",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421c7",
                },
                slug: "micro-irrigation",
                title: "Micro Irrigation",
                id: 3000176,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/irrigation",
                path: "/agriculture/agricultural-machinery-and-technology/irrigation/micro-irrigation",
                active: true,
                meta: {
                  tags: "Micro Irrigation",
                  keywords: "Micro Irrigation",
                  description: "Micro Irrigation",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421c8",
                    },
                    slug: "drip-irrigation",
                    title: "Drip Irrigation",
                    id: 3000177,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/irrigation/micro-irrigation",
                    path: "/agriculture/agricultural-machinery-and-technology/irrigation/micro-irrigation/drip-irrigation",
                    active: true,
                    meta: {
                      tags: "Drip Irrigation",
                      keywords: "Drip Irrigation",
                      description: "Drip Irrigation",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421c9",
                    },
                    slug: "greenhouse-irrigation",
                    title: "Greenhouse Irrigation",
                    id: 3000178,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/irrigation/micro-irrigation",
                    path: "/agriculture/agricultural-machinery-and-technology/irrigation/micro-irrigation/greenhouse-irrigation",
                    active: true,
                    meta: {
                      tags: "Greenhouse Irrigation",
                      keywords: "Greenhouse Irrigation",
                      description: "Greenhouse Irrigation",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad96d036e2a4b4f5b421ca",
                    },
                    slug: "irrigation-controllers",
                    title: "Irrigation Controllers",
                    id: 3000179,
                    parent:
                      "/agriculture/agricultural-machinery-and-technology/irrigation/micro-irrigation",
                    path: "/agriculture/agricultural-machinery-and-technology/irrigation/micro-irrigation/irrigation-controllers",
                    active: true,
                    meta: {
                      tags: "Irrigation Controllers",
                      keywords: "Irrigation Controllers",
                      description: "Irrigation Controllers",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421cb",
            },
            slug: "post-harvest-management",
            title: "Post Harvest Management",
            id: 3000180,
            parent: "/agriculture/agricultural-machinery-and-technology",
            path: "/agriculture/agricultural-machinery-and-technology/post-harvest-management",
            active: true,
            meta: {
              tags: "Post Harvest Management",
              keywords: "Post Harvest Management",
              description: "Post Harvest Management",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421cc",
                },
                slug: "cotton-processing-machinery",
                title: "Cotton Processing Machinery",
                id: 3000181,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/post-harvest-management",
                path: "/agriculture/agricultural-machinery-and-technology/post-harvest-management/cotton-processing-machinery",
                active: true,
                meta: {
                  tags: "Cotton Processing Machinery",
                  keywords: "Cotton Processing Machinery",
                  description: "Cotton Processing Machinery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421cd",
                },
                slug: "grain-storage-silos",
                title: "Grain Storage Silos",
                id: 3000182,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/post-harvest-management",
                path: "/agriculture/agricultural-machinery-and-technology/post-harvest-management/grain-storage-silos",
                active: true,
                meta: {
                  tags: "Grain Storage Silos",
                  keywords: "Grain Storage Silos",
                  description: "Grain Storage Silos",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421ce",
                },
                slug: "rice-milling-machinery",
                title: "Rice Milling Machinery",
                id: 3000183,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/post-harvest-management",
                path: "/agriculture/agricultural-machinery-and-technology/post-harvest-management/rice-milling-machinery",
                active: true,
                meta: {
                  tags: "Rice Milling Machinery",
                  keywords: "Rice Milling Machinery",
                  description: "Rice Milling Machinery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421cf",
                },
                slug: "seed-processing-machinery",
                title: "Seed Processing Machinery",
                id: 3000184,
                parent:
                  "/agriculture/agricultural-machinery-and-technology/post-harvest-management",
                path: "/agriculture/agricultural-machinery-and-technology/post-harvest-management/seed-processing-machinery",
                active: true,
                meta: {
                  tags: "Seed Processing Machinery",
                  keywords: "Seed Processing Machinery",
                  description: "Seed Processing Machinery",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ad96d036e2a4b4f5b421d0",
        },
        slug: "agriculture-services",
        title: "Agriculture Services",
        id: 58614,
        parent: "/agriculture",
        path: "/agriculture/agriculture-services",
        active: true,
        meta: {
          tags: "Agriculture Services",
          keywords: "Agriculture Services",
          description: "Agriculture Services",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421d1",
            },
            slug: "agricultural-testing",
            title: "Agricultural Testing",
            id: 3000186,
            parent: "/agriculture/agriculture-services",
            path: "/agriculture/agriculture-services/agricultural-testing",
            active: true,
            meta: {
              tags: "Agricultural Testing",
              keywords: "Agricultural Testing",
              description: "Agricultural Testing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421d2",
                },
                slug: "agricultural-biologicals-testing",
                title: "Agricultural Biologicals Testing",
                id: 3000187,
                parent:
                  "/agriculture/agriculture-services/agricultural-testing",
                path: "/agriculture/agriculture-services/agricultural-testing/agricultural-biologicals-testing",
                active: true,
                meta: {
                  tags: "Agricultural Biologicals Testing",
                  keywords: "Agricultural Biologicals Testing",
                  description: "Agricultural Biologicals Testing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421d3",
                },
                slug: "feed-testing",
                title: "Feed Testing",
                id: 3000188,
                parent:
                  "/agriculture/agriculture-services/agricultural-testing",
                path: "/agriculture/agriculture-services/agricultural-testing/feed-testing",
                active: true,
                meta: {
                  tags: "Feed Testing",
                  keywords: "Feed Testing",
                  description: "Feed Testing",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421d4",
            },
            slug: "landscaping",
            title: "Landscaping",
            id: 3000189,
            parent: "/agriculture/agriculture-services",
            path: "/agriculture/agriculture-services/landscaping",
            active: true,
            meta: {
              tags: "Landscaping",
              keywords: "Landscaping",
              description: "Landscaping",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421d5",
                },
                slug: "landscaping-services",
                title: "Landscaping Services",
                id: 3000190,
                parent: "/agriculture/agriculture-services/landscaping",
                path: "/agriculture/agriculture-services/landscaping/landscaping-services",
                active: true,
                meta: {
                  tags: "Landscaping Services",
                  keywords: "Landscaping Services",
                  description: "Landscaping Services",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad96d036e2a4b4f5b421d6",
            },
            slug: "plant-breeding",
            title: "Plant Breeding",
            id: 3000191,
            parent: "/agriculture/agriculture-services",
            path: "/agriculture/agriculture-services/plant-breeding",
            active: true,
            meta: {
              tags: "Plant Breeding",
              keywords: "Plant Breeding",
              description: "Plant Breeding",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421d7",
                },
                slug: "plant-genomics",
                title: "Plant Genomics",
                id: 3000192,
                parent: "/agriculture/agriculture-services/plant-breeding",
                path: "/agriculture/agriculture-services/plant-breeding/plant-genomics",
                active: true,
                meta: {
                  tags: "Plant Genomics",
                  keywords: "Plant Genomics",
                  description: "Plant Genomics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421d8",
                },
                slug: "molecular-breeding",
                title: "Molecular Breeding",
                id: 3000193,
                parent: "/agriculture/agriculture-services/plant-breeding",
                path: "/agriculture/agriculture-services/plant-breeding/molecular-breeding",
                active: true,
                meta: {
                  tags: "Molecular Breeding",
                  keywords: "Molecular Breeding",
                  description: "Molecular Breeding",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad96d036e2a4b4f5b421d9",
                },
                slug: "phenotyping",
                title: "Phenotyping",
                id: 3000194,
                parent: "/agriculture/agriculture-services/plant-breeding",
                path: "/agriculture/agriculture-services/plant-breeding/phenotyping",
                active: true,
                meta: {
                  tags: "Phenotyping",
                  keywords: "Phenotyping",
                  description: "Phenotyping",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b12b",
    },
    id: 59,
    title: "Aerospace & Defense",
    slug: "aerospace-defense",
    active: true,
    meta: {
      tags: "Aerospace & Defense Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "aerospace & defence market reports, aerospace defense market, aerospace defense industry analysis, aerospace and defense market, aerospace and defense market size, aerospace and defense industry leaders, aerospace defense industry trends, aerospace & defense market size, c4isr market, smart weapons market, small uav market, military and defence avionics systems market",
      description:
        "Mordor Intelligence™ 【  Aerospace & Defense Market Research  】 Industry Reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
    },
    description: {
      short_description:
        "The aerospace and defense industry segment provides comprehensive analyses of market trends, technological advancements, growth opportunities, challenges, and drivers specific to sectors within the aviation and military industry. Here we list some reports on the global aerospace and defense market.",
      full_description:
        "The Aerospace and Defense Market reports analyze the market trends, growth opportunities, challenges to the market, and drivers for the market growth specific to the regional and global market for precise market forecasts. The market reports cover Commercial Aircraft MRO Market, Pilot Simulation and Training Markets, Nano & Micro Satellites Manufacturing and Launch Market, Commercial Aircraft Components Market, Synthetic Vision for Commercial Aircraft Market, Non-Lethal Weapons Market, Riot Control Equipment Market, Sports, and Recreational Weapons Market, Military Technology Market for C4ISR, ISR, Radar, Telemetry and Smart Textiles Market.",
    },
    picture: null,
    __v: 0,
    picture_url: "aerospace-&.png",
    cat_email: "aero@mordorintelligence.com",
    cat_pw_id: 12427,
    parent: "/",
    path: "/aerospace-defense",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b172",
        },
        id: 5925,
        title: "Defense",
        slug: "defense",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Defense Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Defense Market Research Reports, defense market, defense market report, defense industry, defense industry report, defense market research reports, mountain warfare market, defense industry research reports",
          tags: "Defense Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Aerospace is the human effort in science, engineering and business to fly in the atmosphere of Earth (aeronautics) and surrounding space (astronautics).",
          short_description:
            "The defense segment includes studies on market trends, developments, regulations, growth opportunities, challenges, drivers, restraints, technological developments, and the future market outlook for defense manufacturing industries and suppliers of arms and ammunition.",
        },
        picture: null,
        __v: 0,
        parent: "/aerospace-defense",
        path: "/aerospace-defense/defense",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1382c",
            },
            slug: "soldier-modernization",
            title: "Soldier Modernization",
            id: 9000129,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/soldier-modernization",
            active: true,
            meta: {
              tags: "Soldier Modernization",
              keywords: "Soldier Modernization",
              description: "Soldier Modernization",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1381a",
            },
            slug: "military-equipment-and-infrastructure",
            title: "Military Equipment and Infrastructure",
            id: 9000111,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/military-equipment-and-infrastructure",
            active: true,
            meta: {
              tags: "Military Equipment and Infrastructure",
              keywords: "Military Equipment and Infrastructure",
              description: "Military Equipment and Infrastructure",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1381e",
                },
                slug: "military-systems",
                title: "Military Systems",
                id: 9000115,
                parent:
                  "/aerospace-defense/defense/military-equipment-and-infrastructure",
                path: "/aerospace-defense/defense/military-equipment-and-infrastructure/military-systems",
                active: true,
                meta: {
                  tags: "Military Systems",
                  keywords: "Military Systems",
                  description: "Military Systems",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba1381f",
                    },
                    slug: "military-radar",
                    title: "Military Radar",
                    id: 9000116,
                    parent:
                      "/aerospace-defense/defense/military-equipment-and-infrastructure/military-systems",
                    path: "/aerospace-defense/defense/military-equipment-and-infrastructure/military-systems/military-radar",
                    active: true,
                    meta: {
                      tags: "Military Radar",
                      keywords: "Military Radar",
                      description: "Military Radar",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba13820",
                    },
                    slug: "military-security-and-access-management",
                    title: "Military Security and Access Management",
                    id: 9000117,
                    parent:
                      "/aerospace-defense/defense/military-equipment-and-infrastructure/military-systems",
                    path: "/aerospace-defense/defense/military-equipment-and-infrastructure/military-systems/military-security-and-access-management",
                    active: true,
                    meta: {
                      tags: "Military Security and Access Management",
                      keywords: "Military Security and Access Management",
                      description: "Military Security and Access Management",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1381b",
                },
                slug: "military-communication",
                title: "Military Communication",
                id: 9000112,
                parent:
                  "/aerospace-defense/defense/military-equipment-and-infrastructure",
                path: "/aerospace-defense/defense/military-equipment-and-infrastructure/military-communication",
                active: true,
                meta: {
                  tags: "Military Communication",
                  keywords: "Military Communication",
                  description: "Military Communication",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1381d",
                },
                slug: "military-infrastructure",
                title: "Military Infrastructure",
                id: 9000114,
                parent:
                  "/aerospace-defense/defense/military-equipment-and-infrastructure",
                path: "/aerospace-defense/defense/military-equipment-and-infrastructure/military-infrastructure",
                active: true,
                meta: {
                  tags: "Military Infrastructure",
                  keywords: "Military Infrastructure",
                  description: "Military Infrastructure",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1381c",
                },
                slug: "military-equipment",
                title: "Military Equipment",
                id: 9000113,
                parent:
                  "/aerospace-defense/defense/military-equipment-and-infrastructure",
                path: "/aerospace-defense/defense/military-equipment-and-infrastructure/military-equipment",
                active: true,
                meta: {
                  tags: "Military Equipment",
                  keywords: "Military Equipment",
                  description: "Military Equipment",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1380f",
            },
            slug: "armored-vehicles",
            title: "Armored Vehicles",
            id: 9000100,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/armored-vehicles",
            active: true,
            meta: {
              tags: "Armored Vehicles",
              keywords: "Armored Vehicles",
              description: "Armored Vehicles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13811",
                },
                slug: "military-commercial-vehicles",
                title: "Military Commercial Vehicles",
                id: 9000102,
                parent: "/aerospace-defense/defense/armored-vehicles",
                path: "/aerospace-defense/defense/armored-vehicles/military-commercial-vehicles",
                active: true,
                meta: {
                  tags: "Military Commercial Vehicles",
                  keywords: "Military Commercial Vehicles",
                  description: "Military Commercial Vehicles",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13810",
                },
                slug: "armoured-vehicle-cockpit-systems",
                title: "Armoured Vehicle Cockpit Systems",
                id: 9000101,
                parent: "/aerospace-defense/defense/armored-vehicles",
                path: "/aerospace-defense/defense/armored-vehicles/armoured-vehicle-cockpit-systems",
                active: true,
                meta: {
                  tags: "Armoured Vehicle Cockpit Systems",
                  keywords: "Armoured Vehicle Cockpit Systems",
                  description: "Armoured Vehicle Cockpit Systems",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13815",
            },
            slug: "isr",
            title: "ISR",
            id: 9000106,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/isr",
            active: true,
            meta: {
              tags: "ISR",
              keywords: "ISR",
              description: "ISR",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13816",
                },
                slug: "acoustic-gunshot-sensors",
                title: "Acoustic Gunshot Sensors",
                id: 9000107,
                parent: "/aerospace-defense/defense/isr",
                path: "/aerospace-defense/defense/isr/acoustic-gunshot-sensors",
                active: true,
                meta: {
                  tags: "Acoustic Gunshot Sensors",
                  keywords: "Acoustic Gunshot Sensors",
                  description: "Acoustic Gunshot Sensors",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13822",
            },
            slug: "naval-warfare",
            title: "Naval Warfare",
            id: 9000119,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/naval-warfare",
            active: true,
            meta: {
              tags: "Naval Warfare",
              keywords: "Naval Warfare",
              description: "Naval Warfare",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13824",
                },
                slug: "naval-navigation-and-detection",
                title: "Naval Navigation and Detection",
                id: 9000121,
                parent: "/aerospace-defense/defense/naval-warfare",
                path: "/aerospace-defense/defense/naval-warfare/naval-navigation-and-detection",
                active: true,
                meta: {
                  tags: "Naval Navigation and Detection",
                  keywords: "Naval Navigation and Detection",
                  description: "Naval Navigation and Detection",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba13825",
                    },
                    slug: "naval-detection",
                    title: "Naval Detection",
                    id: 9000122,
                    parent:
                      "/aerospace-defense/defense/naval-warfare/naval-navigation-and-detection",
                    path: "/aerospace-defense/defense/naval-warfare/naval-navigation-and-detection/naval-detection",
                    active: true,
                    meta: {
                      tags: "Naval Detection",
                      keywords: "Naval Detection",
                      description: "Naval Detection",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba13826",
                    },
                    slug: "naval-navigation",
                    title: "Naval Navigation",
                    id: 9000123,
                    parent:
                      "/aerospace-defense/defense/naval-warfare/naval-navigation-and-detection",
                    path: "/aerospace-defense/defense/naval-warfare/naval-navigation-and-detection/naval-navigation",
                    active: true,
                    meta: {
                      tags: "Naval Navigation",
                      keywords: "Naval Navigation",
                      description: "Naval Navigation",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1382b",
                },
                slug: "naval-weapons",
                title: "Naval Weapons",
                id: 9000128,
                parent: "/aerospace-defense/defense/naval-warfare",
                path: "/aerospace-defense/defense/naval-warfare/naval-weapons",
                active: true,
                meta: {
                  tags: "Naval Weapons",
                  keywords: "Naval Weapons",
                  description: "Naval Weapons",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13827",
                },
                slug: "naval-vessel",
                title: "Naval Vessel",
                id: 9000124,
                parent: "/aerospace-defense/defense/naval-warfare",
                path: "/aerospace-defense/defense/naval-warfare/naval-vessel",
                active: true,
                meta: {
                  tags: "Naval Vessel",
                  keywords: "Naval Vessel",
                  description: "Naval Vessel",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba13829",
                    },
                    slug: "naval-vessel-targeting-systems",
                    title: "Naval Vessel Targeting Systems",
                    id: 9000126,
                    parent:
                      "/aerospace-defense/defense/naval-warfare/naval-vessel",
                    path: "/aerospace-defense/defense/naval-warfare/naval-vessel/naval-vessel-targeting-systems",
                    active: true,
                    meta: {
                      tags: "Naval Vessel Targeting Systems",
                      keywords: "Naval Vessel Targeting Systems",
                      description: "Naval Vessel Targeting Systems",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba13828",
                    },
                    slug: "naval-vessel-engine",
                    title: "Naval Vessel Engine",
                    id: 9000125,
                    parent:
                      "/aerospace-defense/defense/naval-warfare/naval-vessel",
                    path: "/aerospace-defense/defense/naval-warfare/naval-vessel/naval-vessel-engine",
                    active: true,
                    meta: {
                      tags: "Naval Vessel Engine",
                      keywords: "Naval Vessel Engine",
                      description: "Naval Vessel Engine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba1382a",
                    },
                    slug: "submarine",
                    title: "Submarine",
                    id: 9000127,
                    parent:
                      "/aerospace-defense/defense/naval-warfare/naval-vessel",
                    path: "/aerospace-defense/defense/naval-warfare/naval-vessel/submarine",
                    active: true,
                    meta: {
                      tags: "Submarine",
                      keywords: "Submarine",
                      description: "Submarine",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13823",
                },
                slug: "maritime-security-and-access-management",
                title: "Maritime Security and Access Management",
                id: 9000120,
                parent: "/aerospace-defense/defense/naval-warfare",
                path: "/aerospace-defense/defense/naval-warfare/maritime-security-and-access-management",
                active: true,
                meta: {
                  tags: "Maritime Security and Access Management",
                  keywords: "Maritime Security and Access Management",
                  description: "Maritime Security and Access Management",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13813",
            },
            slug: "defense-cybersecurity",
            title: "Defense Cybersecurity",
            id: 9000104,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/defense-cybersecurity",
            active: true,
            meta: {
              tags: "Defense Cybersecurity",
              keywords: "Defense Cybersecurity",
              description: "Defense Cybersecurity",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13817",
            },
            slug: "less-lethal-equipment",
            title: "Less-lethal Equipment",
            id: 9000108,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/less-lethal-equipment",
            active: true,
            meta: {
              tags: "Less-lethal Equipment",
              keywords: "Less-lethal Equipment",
              description: "Less-lethal Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13818",
                },
                slug: "riot-control",
                title: "Riot Control",
                id: 9000109,
                parent: "/aerospace-defense/defense/less-lethal-equipment",
                path: "/aerospace-defense/defense/less-lethal-equipment/riot-control",
                active: true,
                meta: {
                  tags: "Riot Control",
                  keywords: "Riot Control",
                  description: "Riot Control",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13812",
            },
            slug: "counter-defense",
            title: "Counter Defense",
            id: 9000103,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/counter-defense",
            active: true,
            meta: {
              tags: "Counter Defense",
              keywords: "Counter Defense",
              description: "Counter Defense",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13821",
            },
            slug: "military-training",
            title: "Military Training",
            id: 9000118,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/military-training",
            active: true,
            meta: {
              tags: "Military Training",
              keywords: "Military Training",
              description: "Military Training",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1380e",
            },
            slug: "air-defense",
            title: "Air Defense",
            id: 9000099,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/air-defense",
            active: true,
            meta: {
              tags: "Air Defense",
              keywords: "Air Defense",
              description: "Air Defense",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13819",
            },
            slug: "military-cockpit-systems",
            title: "Military Cockpit Systems",
            id: 9000110,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/military-cockpit-systems",
            active: true,
            meta: {
              tags: "Military Cockpit Systems",
              keywords: "Military Cockpit Systems",
              description: "Military Cockpit Systems",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1382d",
            },
            slug: "weapons-and-ammunition",
            title: "Weapons and Ammunition",
            id: 9000130,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/weapons-and-ammunition",
            active: true,
            meta: {
              tags: "Weapons and Ammunition",
              keywords: "Weapons and Ammunition",
              description: "Weapons and Ammunition",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13830",
                },
                slug: "small-arms",
                title: "Small Arms",
                id: 9000133,
                parent: "/aerospace-defense/defense/weapons-and-ammunition",
                path: "/aerospace-defense/defense/weapons-and-ammunition/small-arms",
                active: true,
                meta: {
                  tags: "Small Arms",
                  keywords: "Small Arms",
                  description: "Small Arms",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1382f",
                },
                slug: "missile",
                title: "Missile",
                id: 9000132,
                parent: "/aerospace-defense/defense/weapons-and-ammunition",
                path: "/aerospace-defense/defense/weapons-and-ammunition/missile",
                active: true,
                meta: {
                  tags: "Missile",
                  keywords: "Missile",
                  description: "Missile",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1382e",
                },
                slug: "ammunition",
                title: "Ammunition",
                id: 9000131,
                parent: "/aerospace-defense/defense/weapons-and-ammunition",
                path: "/aerospace-defense/defense/weapons-and-ammunition/ammunition",
                active: true,
                meta: {
                  tags: "Ammunition",
                  keywords: "Ammunition",
                  description: "Ammunition",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13831",
                },
                slug: "targeting-systems",
                title: "Targeting Systems",
                id: 9000134,
                parent: "/aerospace-defense/defense/weapons-and-ammunition",
                path: "/aerospace-defense/defense/weapons-and-ammunition/targeting-systems",
                active: true,
                meta: {
                  tags: "Targeting Systems",
                  keywords: "Targeting Systems",
                  description: "Targeting Systems",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13814",
            },
            slug: "homeland-security",
            title: "Homeland Security",
            id: 9000105,
            parent: "/aerospace-defense/defense",
            path: "/aerospace-defense/defense/homeland-security",
            active: true,
            meta: {
              tags: "Homeland Security",
              keywords: "Homeland Security",
              description: "Homeland Security",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b174",
        },
        id: 5984,
        title: "Airport Operations",
        slug: "airport-operations",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Airport Operations Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Airport Operations Market Research Reports, airport operations, airport operations market growth, airport operations market analysis, airport operations industry analysis, airport operations market report, airport operations industry report, airport operations industry analysis, airport operations industry forecast, airport operations industry, airport operations market",
          tags: "Airport Operations Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Statistics for the 2023 Airport Operations market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Airport Operations Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          short_description:
            "Statistics for the 2023 Airport Operations market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Airport Operations Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        picture: null,
        __v: 0,
        parent: "/aerospace-defense",
        path: "/aerospace-defense/airport-operations",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137e3",
            },
            slug: "airport-infrastructure",
            title: "Airport Infrastructure",
            id: 9000054,
            parent: "/aerospace-defense/airport-operations",
            path: "/aerospace-defense/airport-operations/airport-infrastructure",
            active: true,
            meta: {
              tags: "Airport Infrastructure",
              keywords: "Airport Infrastructure",
              description: "Airport Infrastructure",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137e5",
                },
                slug: "smart-airport",
                title: "Smart Airport",
                id: 9000056,
                parent:
                  "/aerospace-defense/airport-operations/airport-infrastructure",
                path: "/aerospace-defense/airport-operations/airport-infrastructure/smart-airport",
                active: true,
                meta: {
                  tags: "Smart Airport",
                  keywords: "Smart Airport",
                  description: "Smart Airport",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137e4",
                },
                slug: "heliport",
                title: "Heliport",
                id: 9000055,
                parent:
                  "/aerospace-defense/airport-operations/airport-infrastructure",
                path: "/aerospace-defense/airport-operations/airport-infrastructure/heliport",
                active: true,
                meta: {
                  tags: "Heliport",
                  keywords: "Heliport",
                  description: "Heliport",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137f5",
            },
            slug: "inflight-catering",
            title: "Inflight Catering",
            id: 9000072,
            parent: "/aerospace-defense/airport-operations",
            path: "/aerospace-defense/airport-operations/inflight-catering",
            active: true,
            meta: {
              tags: "Inflight Catering",
              keywords: "Inflight Catering",
              description: "Inflight Catering",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137e6",
            },
            slug: "airport-technologies",
            title: "Airport Technologies",
            id: 9000057,
            parent: "/aerospace-defense/airport-operations",
            path: "/aerospace-defense/airport-operations/airport-technologies",
            active: true,
            meta: {
              tags: "Airport Technologies",
              keywords: "Airport Technologies",
              description: "Airport Technologies",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137e9",
                },
                slug: "airport-equipment",
                title: "Airport Equipment",
                id: 9000060,
                parent:
                  "/aerospace-defense/airport-operations/airport-technologies",
                path: "/aerospace-defense/airport-operations/airport-technologies/airport-equipment",
                active: true,
                meta: {
                  tags: "Airport Equipment",
                  keywords: "Airport Equipment",
                  description: "Airport Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba137ea",
                    },
                    slug: "air-traffic-management-equipment",
                    title: "Air Traffic Management Equipment",
                    id: 9000061,
                    parent:
                      "/aerospace-defense/airport-operations/airport-technologies/airport-equipment",
                    path: "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/air-traffic-management-equipment",
                    active: true,
                    meta: {
                      tags: "Air Traffic Management Equipment",
                      keywords: "Air Traffic Management Equipment",
                      description: "Air Traffic Management Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba137ed",
                    },
                    slug: "airport-vehicles",
                    title: "Airport Vehicles",
                    id: 9000064,
                    parent:
                      "/aerospace-defense/airport-operations/airport-technologies/airport-equipment",
                    path: "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/airport-vehicles",
                    active: true,
                    meta: {
                      tags: "Airport Vehicles",
                      keywords: "Airport Vehicles",
                      description: "Airport Vehicles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba137eb",
                    },
                    slug: "airport-passenger-handling",
                    title: "Airport Passenger Handling",
                    id: 9000062,
                    parent:
                      "/aerospace-defense/airport-operations/airport-technologies/airport-equipment",
                    path: "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/airport-passenger-handling",
                    active: true,
                    meta: {
                      tags: "Airport Passenger Handling",
                      keywords: "Airport Passenger Handling",
                      description: "Airport Passenger Handling",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9ef73f1f66acfba137ec",
                        },
                        slug: "airport-passenger-screening",
                        title: "Airport Passenger Screening",
                        id: 9000063,
                        parent:
                          "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/airport-passenger-handling",
                        path: "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/airport-passenger-handling/airport-passenger-screening",
                        active: true,
                        meta: {
                          tags: "Airport Passenger Screening",
                          keywords: "Airport Passenger Screening",
                          description: "Airport Passenger Screening",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba137f1",
                    },
                    slug: "ground-handling",
                    title: "Ground Handling",
                    id: 9000068,
                    parent:
                      "/aerospace-defense/airport-operations/airport-technologies/airport-equipment",
                    path: "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/ground-handling",
                    active: true,
                    meta: {
                      tags: "Ground Handling",
                      keywords: "Ground Handling",
                      description: "Ground Handling",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba137ee",
                    },
                    slug: "cargo-and-baggage-handling",
                    title: "Cargo and Baggage Handling",
                    id: 9000065,
                    parent:
                      "/aerospace-defense/airport-operations/airport-technologies/airport-equipment",
                    path: "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/cargo-and-baggage-handling",
                    active: true,
                    meta: {
                      tags: "Cargo and Baggage Handling",
                      keywords: "Cargo and Baggage Handling",
                      description: "Cargo and Baggage Handling",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9ef73f1f66acfba137f0",
                        },
                        slug: "airport-cargo-screening",
                        title: "Airport Cargo Screening",
                        id: 9000067,
                        parent:
                          "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/cargo-and-baggage-handling",
                        path: "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/cargo-and-baggage-handling/airport-cargo-screening",
                        active: true,
                        meta: {
                          tags: "Airport Cargo Screening",
                          keywords: "Airport Cargo Screening",
                          description: "Airport Cargo Screening",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9ef73f1f66acfba137ef",
                        },
                        slug: "airport-baggage-handling",
                        title: "Airport Baggage Handling",
                        id: 9000066,
                        parent:
                          "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/cargo-and-baggage-handling",
                        path: "/aerospace-defense/airport-operations/airport-technologies/airport-equipment/cargo-and-baggage-handling/airport-baggage-handling",
                        active: true,
                        meta: {
                          tags: "Airport Baggage Handling",
                          keywords: "Airport Baggage Handling",
                          description: "Airport Baggage Handling",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137e7",
                },
                slug: "advanced-airport-technologies",
                title: "Advanced Airport Technologies",
                id: 9000058,
                parent:
                  "/aerospace-defense/airport-operations/airport-technologies",
                path: "/aerospace-defense/airport-operations/airport-technologies/advanced-airport-technologies",
                active: true,
                meta: {
                  tags: "Advanced Airport Technologies",
                  keywords: "Advanced Airport Technologies",
                  description: "Advanced Airport Technologies",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137e8",
                },
                slug: "airport-catering-service",
                title: "Airport Catering Service",
                id: 9000059,
                parent:
                  "/aerospace-defense/airport-operations/airport-technologies",
                path: "/aerospace-defense/airport-operations/airport-technologies/airport-catering-service",
                active: true,
                meta: {
                  tags: "Airport Catering Service",
                  keywords: "Airport Catering Service",
                  description: "Airport Catering Service",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137f3",
                },
                slug: "airport-security",
                title: "Airport Security",
                id: 9000070,
                parent:
                  "/aerospace-defense/airport-operations/airport-technologies",
                path: "/aerospace-defense/airport-operations/airport-technologies/airport-security",
                active: true,
                meta: {
                  tags: "Airport Security",
                  keywords: "Airport Security",
                  description: "Airport Security",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137f2",
                },
                slug: "airport-information-systems",
                title: "Airport Information Systems",
                id: 9000069,
                parent:
                  "/aerospace-defense/airport-operations/airport-technologies",
                path: "/aerospace-defense/airport-operations/airport-technologies/airport-information-systems",
                active: true,
                meta: {
                  tags: "Airport Information Systems",
                  keywords: "Airport Information Systems",
                  description: "Airport Information Systems",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137f4",
            },
            slug: "airport-terminal-operations",
            title: "Airport Terminal Operations",
            id: 9000071,
            parent: "/aerospace-defense/airport-operations",
            path: "/aerospace-defense/airport-operations/airport-terminal-operations",
            active: true,
            meta: {
              tags: "Airport Terminal Operations",
              keywords: "Airport Terminal Operations",
              description: "Airport Terminal Operations",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b175",
        },
        id: 5995,
        title: "Aviation",
        slug: "aviation",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Aviation Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Aviation Market Research Reports, aviation market, aviation market report, aviation market research, aviation industry, aviation industry report, aviation industry research",
          tags: "Aviation Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Aerospace is the human effort in science, engineering and business to fly in the atmosphere of Earth (aeronautics) and surrounding space (astronautics).",
          short_description:
            "The aerospace segment provides a comprehensive analysis of market trends, technological advancements, growth opportunities, challenges, drivers, corporate mergers & acquisitions, and the future market outlook for the aviation & space industry and raw material suppliers.",
        },
        picture: null,
        __v: 0,
        parent: "/aerospace-defense",
        path: "/aerospace-defense/aviation",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137f7",
            },
            slug: "aircrafts",
            title: "Aircrafts",
            id: 9000075,
            parent: "/aerospace-defense/aviation",
            path: "/aerospace-defense/aviation/aircrafts",
            active: true,
            meta: {
              tags: "Aircrafts",
              keywords: "Aircrafts",
              description: "Aircrafts",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137f9",
                },
                slug: "freighter-aircrafts",
                title: "Freighter Aircrafts",
                id: 9000077,
                parent: "/aerospace-defense/aviation/aircrafts",
                path: "/aerospace-defense/aviation/aircrafts/freighter-aircrafts",
                active: true,
                meta: {
                  tags: "Freighter Aircrafts",
                  keywords: "Freighter Aircrafts",
                  description: "Freighter Aircrafts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137f8",
                },
                slug: "electric-aircraft",
                title: "Electric Aircraft",
                id: 9000076,
                parent: "/aerospace-defense/aviation/aircrafts",
                path: "/aerospace-defense/aviation/aircrafts/electric-aircraft",
                active: true,
                meta: {
                  tags: "Electric Aircraft",
                  keywords: "Electric Aircraft",
                  description: "Electric Aircraft",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137fa",
                },
                slug: "general-aviation",
                title: "General Aviation",
                id: 9000078,
                parent: "/aerospace-defense/aviation/aircrafts",
                path: "/aerospace-defense/aviation/aircrafts/general-aviation",
                active: true,
                meta: {
                  tags: "General Aviation",
                  keywords: "General Aviation",
                  description: "General Aviation",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba137fb",
                    },
                    slug: "business-jet",
                    title: "Business Jet",
                    id: 9000079,
                    parent:
                      "/aerospace-defense/aviation/aircrafts/general-aviation",
                    path: "/aerospace-defense/aviation/aircrafts/general-aviation/business-jet",
                    active: true,
                    meta: {
                      tags: "Business Jet",
                      keywords: "Business Jet",
                      description: "Business Jet",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba137fd",
                    },
                    slug: "helicopters",
                    title: "Helicopters",
                    id: 9000081,
                    parent:
                      "/aerospace-defense/aviation/aircrafts/general-aviation",
                    path: "/aerospace-defense/aviation/aircrafts/general-aviation/helicopters",
                    active: true,
                    meta: {
                      tags: "Helicopters",
                      keywords: "Helicopters",
                      description: "Helicopters",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9ef73f1f66acfba137fe",
                        },
                        slug: "helicopters-lifting-service",
                        title: "Helicopters Lifting Service",
                        id: 9000082,
                        parent:
                          "/aerospace-defense/aviation/aircrafts/general-aviation/helicopters",
                        path: "/aerospace-defense/aviation/aircrafts/general-aviation/helicopters/helicopters-lifting-service",
                        active: true,
                        meta: {
                          tags: "Helicopters Lifting Service",
                          keywords: "Helicopters Lifting Service",
                          description: "Helicopters Lifting Service",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9ef73f1f66acfba137ff",
                        },
                        slug: "helicopters-taxi",
                        title: "Helicopters Taxi",
                        id: 9000083,
                        parent:
                          "/aerospace-defense/aviation/aircrafts/general-aviation/helicopters",
                        path: "/aerospace-defense/aviation/aircrafts/general-aviation/helicopters/helicopters-taxi",
                        active: true,
                        meta: {
                          tags: "Helicopters Taxi",
                          keywords: "Helicopters Taxi",
                          description: "Helicopters Taxi",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9ef73f1f66acfba13800",
                        },
                        slug: "offshore-helicopters",
                        title: "Offshore Helicopters",
                        id: 9000084,
                        parent:
                          "/aerospace-defense/aviation/aircrafts/general-aviation/helicopters",
                        path: "/aerospace-defense/aviation/aircrafts/general-aviation/helicopters/offshore-helicopters",
                        active: true,
                        meta: {
                          tags: "Offshore Helicopters",
                          keywords: "Offshore Helicopters",
                          description: "Offshore Helicopters",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba13802",
                    },
                    slug: "turboprop",
                    title: "Turboprop",
                    id: 9000086,
                    parent:
                      "/aerospace-defense/aviation/aircrafts/general-aviation",
                    path: "/aerospace-defense/aviation/aircrafts/general-aviation/turboprop",
                    active: true,
                    meta: {
                      tags: "Turboprop",
                      keywords: "Turboprop",
                      description: "Turboprop",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba137fc",
                    },
                    slug: "gliders",
                    title: "Gliders",
                    id: 9000080,
                    parent:
                      "/aerospace-defense/aviation/aircrafts/general-aviation",
                    path: "/aerospace-defense/aviation/aircrafts/general-aviation/gliders",
                    active: true,
                    meta: {
                      tags: "Gliders",
                      keywords: "Gliders",
                      description: "Gliders",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba13801",
                    },
                    slug: "light-aircrafts",
                    title: "Light Aircrafts",
                    id: 9000085,
                    parent:
                      "/aerospace-defense/aviation/aircrafts/general-aviation",
                    path: "/aerospace-defense/aviation/aircrafts/general-aviation/light-aircrafts",
                    active: true,
                    meta: {
                      tags: "Light Aircrafts",
                      keywords: "Light Aircrafts",
                      description: "Light Aircrafts",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13803",
                },
                slug: "military-aviation",
                title: "Military Aviation",
                id: 9000087,
                parent: "/aerospace-defense/aviation/aircrafts",
                path: "/aerospace-defense/aviation/aircrafts/military-aviation",
                active: true,
                meta: {
                  tags: "Military Aviation",
                  keywords: "Military Aviation",
                  description: "Military Aviation",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba13804",
                    },
                    slug: "military-helicopters",
                    title: "Military Helicopters",
                    id: 9000088,
                    parent:
                      "/aerospace-defense/aviation/aircrafts/military-aviation",
                    path: "/aerospace-defense/aviation/aircrafts/military-aviation/military-helicopters",
                    active: true,
                    meta: {
                      tags: "Military Helicopters",
                      keywords: "Military Helicopters",
                      description: "Military Helicopters",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137f6",
            },
            slug: "aircraft-simulation-and-flight-training",
            title: "Aircraft Simulation and Flight Training",
            id: 9000074,
            parent: "/aerospace-defense/aviation",
            path: "/aerospace-defense/aviation/aircraft-simulation-and-flight-training",
            active: true,
            meta: {
              tags: "Aircraft Simulation and Flight Training",
              keywords: "Aircraft Simulation and Flight Training",
              description: "Aircraft Simulation and Flight Training",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13806",
            },
            slug: "aviation-services",
            title: "Aviation Services",
            id: 9000090,
            parent: "/aerospace-defense/aviation",
            path: "/aerospace-defense/aviation/aviation-services",
            active: true,
            meta: {
              tags: "Aviation Services",
              keywords: "Aviation Services",
              description: "Aviation Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13807",
                },
                slug: "air-medical-service",
                title: "Air Medical Service",
                id: 9000091,
                parent: "/aerospace-defense/aviation/aviation-services",
                path: "/aerospace-defense/aviation/aviation-services/air-medical-service",
                active: true,
                meta: {
                  tags: "Air Medical Service",
                  keywords: "Air Medical Service",
                  description: "Air Medical Service",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13808",
                },
                slug: "jet-services",
                title: "Jet Services",
                id: 9000092,
                parent: "/aerospace-defense/aviation/aviation-services",
                path: "/aerospace-defense/aviation/aviation-services/jet-services",
                active: true,
                meta: {
                  tags: "Jet Services",
                  keywords: "Jet Services",
                  description: "Jet Services",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13805",
            },
            slug: "airline",
            title: "Airline",
            id: 9000089,
            parent: "/aerospace-defense/aviation",
            path: "/aerospace-defense/aviation/airline",
            active: true,
            meta: {
              tags: "Airline",
              keywords: "Airline",
              description: "Airline",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9ef73f1f66acfba13832",
        },
        slug: "space",
        title: "Space",
        id: 59651,
        parent: "/aerospace-defense",
        path: "/aerospace-defense/space",
        active: true,
        meta: {
          tags: "Space",
          keywords: "Space",
          description: "Space",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13835",
            },
            slug: "launch-vehicles",
            title: "Launch Vehicles",
            id: 9000138,
            parent: "/aerospace-defense/space",
            path: "/aerospace-defense/space/launch-vehicles",
            active: true,
            meta: {
              tags: "Launch Vehicles",
              keywords: "Launch Vehicles",
              description: "Launch Vehicles",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13833",
            },
            slug: "astronaut-wearables",
            title: "Astronaut Wearables",
            id: 9000136,
            parent: "/aerospace-defense/space",
            path: "/aerospace-defense/space/astronaut-wearables",
            active: true,
            meta: {
              tags: "Astronaut Wearables",
              keywords: "Astronaut Wearables",
              description: "Astronaut Wearables",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13834",
            },
            slug: "celestial-object-exploration",
            title: "Celestial Object Exploration",
            id: 9000137,
            parent: "/aerospace-defense/space",
            path: "/aerospace-defense/space/celestial-object-exploration",
            active: true,
            meta: {
              tags: "Celestial Object Exploration",
              keywords: "Celestial Object Exploration",
              description: "Celestial Object Exploration",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13836",
            },
            slug: "satellite",
            title: "Satellite",
            id: 9000139,
            parent: "/aerospace-defense/space",
            path: "/aerospace-defense/space/satellite",
            active: true,
            meta: {
              tags: "Satellite",
              keywords: "Satellite",
              description: "Satellite",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13838",
                },
                slug: "satellite-orbital-system",
                title: "Satellite Orbital System",
                id: 9000141,
                parent: "/aerospace-defense/space/satellite",
                path: "/aerospace-defense/space/satellite/satellite-orbital-system",
                active: true,
                meta: {
                  tags: "Satellite Orbital System",
                  keywords: "Satellite Orbital System",
                  description: "Satellite Orbital System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13837",
                },
                slug: "satellite-engine",
                title: "Satellite Engine",
                id: 9000140,
                parent: "/aerospace-defense/space/satellite",
                path: "/aerospace-defense/space/satellite/satellite-engine",
                active: true,
                meta: {
                  tags: "Satellite Engine",
                  keywords: "Satellite Engine",
                  description: "Satellite Engine",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1383a",
            },
            slug: "space-unmanned-vehicles",
            title: "Space unmanned Vehicles",
            id: 9000143,
            parent: "/aerospace-defense/space",
            path: "/aerospace-defense/space/space-unmanned-vehicles",
            active: true,
            meta: {
              tags: "Space unmanned Vehicles",
              keywords: "Space unmanned Vehicles",
              description: "Space unmanned Vehicles",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13839",
            },
            slug: "space-electronics",
            title: "Space Electronics",
            id: 9000142,
            parent: "/aerospace-defense/space",
            path: "/aerospace-defense/space/space-electronics",
            active: true,
            meta: {
              tags: "Space Electronics",
              keywords: "Space Electronics",
              description: "Space Electronics",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9ef73f1f66acfba137bc",
        },
        slug: "aircraft-parts",
        title: "Aircraft Parts",
        id: 59649,
        parent: "/aerospace-defense",
        path: "/aerospace-defense/aircraft-parts",
        active: true,
        meta: {
          tags: "Aircraft Parts",
          keywords: "Aircraft Parts",
          description: "Aircraft Parts",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137d5",
            },
            slug: "aircraft-navigation-and-communication",
            title: "Aircraft Navigation and Communication",
            id: 9000039,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-navigation-and-communication",
            active: true,
            meta: {
              tags: "Aircraft Navigation and Communication",
              keywords: "Aircraft Navigation and Communication",
              description: "Aircraft Navigation and Communication",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137d7",
                },
                slug: "cockpit-data-recorder",
                title: "Cockpit Data Recorder",
                id: 9000041,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-navigation-and-communication",
                path: "/aerospace-defense/aircraft-parts/aircraft-navigation-and-communication/cockpit-data-recorder",
                active: true,
                meta: {
                  tags: "Cockpit Data Recorder",
                  keywords: "Cockpit Data Recorder",
                  description: "Cockpit Data Recorder",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137d6",
                },
                slug: "aircraft-vision-technology",
                title: "Aircraft Vision Technology",
                id: 9000040,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-navigation-and-communication",
                path: "/aerospace-defense/aircraft-parts/aircraft-navigation-and-communication/aircraft-vision-technology",
                active: true,
                meta: {
                  tags: "Aircraft Vision Technology",
                  keywords: "Aircraft Vision Technology",
                  description: "Aircraft Vision Technology",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137db",
            },
            slug: "aircraft-security",
            title: "Aircraft Security",
            id: 9000045,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-security",
            active: true,
            meta: {
              tags: "Aircraft Security",
              keywords: "Aircraft Security",
              description: "Aircraft Security",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137c8",
            },
            slug: "aircraft-engine",
            title: "Aircraft Engine",
            id: 9000026,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-engine",
            active: true,
            meta: {
              tags: "Aircraft Engine",
              keywords: "Aircraft Engine",
              description: "Aircraft Engine",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137c9",
                },
                slug: "aircraft-engine-components",
                title: "Aircraft Engine Components",
                id: 9000027,
                parent: "/aerospace-defense/aircraft-parts/aircraft-engine",
                path: "/aerospace-defense/aircraft-parts/aircraft-engine/aircraft-engine-components",
                active: true,
                meta: {
                  tags: "Aircraft Engine Components",
                  keywords: "Aircraft Engine Components",
                  description: "Aircraft Engine Components",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137ca",
                },
                slug: "aircraft-engine-control",
                title: "Aircraft Engine Control",
                id: 9000028,
                parent: "/aerospace-defense/aircraft-parts/aircraft-engine",
                path: "/aerospace-defense/aircraft-parts/aircraft-engine/aircraft-engine-control",
                active: true,
                meta: {
                  tags: "Aircraft Engine Control",
                  keywords: "Aircraft Engine Control",
                  description: "Aircraft Engine Control",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137cb",
                },
                slug: "aircraft-engine-housing",
                title: "Aircraft Engine Housing",
                id: 9000029,
                parent: "/aerospace-defense/aircraft-parts/aircraft-engine",
                path: "/aerospace-defense/aircraft-parts/aircraft-engine/aircraft-engine-housing",
                active: true,
                meta: {
                  tags: "Aircraft Engine Housing",
                  keywords: "Aircraft Engine Housing",
                  description: "Aircraft Engine Housing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137cc",
                },
                slug: "aircraft-engine-polymers",
                title: "Aircraft Engine Polymers",
                id: 9000030,
                parent: "/aerospace-defense/aircraft-parts/aircraft-engine",
                path: "/aerospace-defense/aircraft-parts/aircraft-engine/aircraft-engine-polymers",
                active: true,
                meta: {
                  tags: "Aircraft Engine Polymers",
                  keywords: "Aircraft Engine Polymers",
                  description: "Aircraft Engine Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137cd",
                },
                slug: "business-jet-engine",
                title: "Business Jet Engine",
                id: 9000031,
                parent: "/aerospace-defense/aircraft-parts/aircraft-engine",
                path: "/aerospace-defense/aircraft-parts/aircraft-engine/business-jet-engine",
                active: true,
                meta: {
                  tags: "Business Jet Engine",
                  keywords: "Business Jet Engine",
                  description: "Business Jet Engine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137ce",
                },
                slug: "rotorcraft-propulsion-system",
                title: "Rotorcraft Propulsion System",
                id: 9000032,
                parent: "/aerospace-defense/aircraft-parts/aircraft-engine",
                path: "/aerospace-defense/aircraft-parts/aircraft-engine/rotorcraft-propulsion-system",
                active: true,
                meta: {
                  tags: "Rotorcraft Propulsion System",
                  keywords: "Rotorcraft Propulsion System",
                  description: "Rotorcraft Propulsion System",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137da",
            },
            slug: "aircraft-safety-systems",
            title: "Aircraft Safety Systems",
            id: 9000044,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-safety-systems",
            active: true,
            meta: {
              tags: "Aircraft Safety Systems",
              keywords: "Aircraft Safety Systems",
              description: "Aircraft Safety Systems",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137cf",
            },
            slug: "aircraft-environment-control",
            title: "Aircraft Environment Control",
            id: 9000033,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-environment-control",
            active: true,
            meta: {
              tags: "Aircraft Environment Control",
              keywords: "Aircraft Environment Control",
              description: "Aircraft Environment Control",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137d8",
            },
            slug: "aircraft-power-systems",
            title: "Aircraft Power Systems",
            id: 9000042,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-power-systems",
            active: true,
            meta: {
              tags: "Aircraft Power Systems",
              keywords: "Aircraft Power Systems",
              description: "Aircraft Power Systems",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137d9",
                },
                slug: "aircraft-battery",
                title: "Aircraft Battery",
                id: 9000043,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-power-systems",
                path: "/aerospace-defense/aircraft-parts/aircraft-power-systems/aircraft-battery",
                active: true,
                meta: {
                  tags: "Aircraft Battery",
                  keywords: "Aircraft Battery",
                  description: "Aircraft Battery",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137be",
            },
            slug: "aircraft-aftermarket",
            title: "Aircraft Aftermarket",
            id: 9000016,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-aftermarket",
            active: true,
            meta: {
              tags: "Aircraft Aftermarket",
              keywords: "Aircraft Aftermarket",
              description: "Aircraft Aftermarket",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137d0",
            },
            slug: "aircraft-fuel-systems",
            title: "Aircraft Fuel Systems",
            id: 9000034,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-fuel-systems",
            active: true,
            meta: {
              tags: "Aircraft Fuel Systems",
              keywords: "Aircraft Fuel Systems",
              description: "Aircraft Fuel Systems",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137d1",
                },
                slug: "aircraft-fuel-storage",
                title: "Aircraft Fuel Storage",
                id: 9000035,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-fuel-systems",
                path: "/aerospace-defense/aircraft-parts/aircraft-fuel-systems/aircraft-fuel-storage",
                active: true,
                meta: {
                  tags: "Aircraft Fuel Storage",
                  keywords: "Aircraft Fuel Storage",
                  description: "Aircraft Fuel Storage",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137d2",
                },
                slug: "aviation-fuel-components",
                title: "Aviation Fuel Components",
                id: 9000036,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-fuel-systems",
                path: "/aerospace-defense/aircraft-parts/aircraft-fuel-systems/aviation-fuel-components",
                active: true,
                meta: {
                  tags: "Aviation Fuel Components",
                  keywords: "Aviation Fuel Components",
                  description: "Aviation Fuel Components",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137e1",
            },
            slug: "cockpit-systems",
            title: "Cockpit Systems",
            id: 9000051,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/cockpit-systems",
            active: true,
            meta: {
              tags: "Cockpit Systems",
              keywords: "Cockpit Systems",
              description: "Cockpit Systems",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137e2",
                },
                slug: "cockpit-electrical-components",
                title: "Cockpit Electrical Components",
                id: 9000052,
                parent: "/aerospace-defense/aircraft-parts/cockpit-systems",
                path: "/aerospace-defense/aircraft-parts/cockpit-systems/cockpit-electrical-components",
                active: true,
                meta: {
                  tags: "Cockpit Electrical Components",
                  keywords: "Cockpit Electrical Components",
                  description: "Cockpit Electrical Components",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137bd",
            },
            slug: "aerospace-polymers",
            title: "Aerospace Polymers",
            id: 9000015,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aerospace-polymers",
            active: true,
            meta: {
              tags: "Aerospace Polymers",
              keywords: "Aerospace Polymers",
              description: "Aerospace Polymers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137c5",
            },
            slug: "aircraft-data-and-diagnostics",
            title: "Aircraft Data and Diagnostics",
            id: 9000023,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-data-and-diagnostics",
            active: true,
            meta: {
              tags: "Aircraft Data and Diagnostics",
              keywords: "Aircraft Data and Diagnostics",
              description: "Aircraft Data and Diagnostics",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137c7",
                },
                slug: "aircraft-diagnostics",
                title: "Aircraft Diagnostics",
                id: 9000025,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-data-and-diagnostics",
                path: "/aerospace-defense/aircraft-parts/aircraft-data-and-diagnostics/aircraft-diagnostics",
                active: true,
                meta: {
                  tags: "Aircraft Diagnostics",
                  keywords: "Aircraft Diagnostics",
                  description: "Aircraft Diagnostics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137c6",
                },
                slug: "aircraft-data",
                title: "Aircraft Data",
                id: 9000024,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-data-and-diagnostics",
                path: "/aerospace-defense/aircraft-parts/aircraft-data-and-diagnostics/aircraft-data",
                active: true,
                meta: {
                  tags: "Aircraft Data",
                  keywords: "Aircraft Data",
                  description: "Aircraft Data",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137bf",
            },
            slug: "aircraft-cabin-interior",
            title: "Aircraft Cabin Interior",
            id: 9000017,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-cabin-interior",
            active: true,
            meta: {
              tags: "Aircraft Cabin Interior",
              keywords: "Aircraft Cabin Interior",
              description: "Aircraft Cabin Interior",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137c0",
                },
                slug: "aircraft-cabin-components",
                title: "Aircraft Cabin Components",
                id: 9000018,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-cabin-interior",
                path: "/aerospace-defense/aircraft-parts/aircraft-cabin-interior/aircraft-cabin-components",
                active: true,
                meta: {
                  tags: "Aircraft Cabin Components",
                  keywords: "Aircraft Cabin Components",
                  description: "Aircraft Cabin Components",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137c3",
                },
                slug: "aircraft-seating",
                title: "Aircraft Seating",
                id: 9000021,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-cabin-interior",
                path: "/aerospace-defense/aircraft-parts/aircraft-cabin-interior/aircraft-seating",
                active: true,
                meta: {
                  tags: "Aircraft Seating",
                  keywords: "Aircraft Seating",
                  description: "Aircraft Seating",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137c4",
                },
                slug: "inflight-entertainment",
                title: "Inflight Entertainment",
                id: 9000022,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-cabin-interior",
                path: "/aerospace-defense/aircraft-parts/aircraft-cabin-interior/inflight-entertainment",
                active: true,
                meta: {
                  tags: "Inflight Entertainment",
                  keywords: "Inflight Entertainment",
                  description: "Inflight Entertainment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137c2",
                },
                slug: "aircraft-interior-lighting",
                title: "Aircraft Interior Lighting",
                id: 9000020,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-cabin-interior",
                path: "/aerospace-defense/aircraft-parts/aircraft-cabin-interior/aircraft-interior-lighting",
                active: true,
                meta: {
                  tags: "Aircraft Interior Lighting",
                  keywords: "Aircraft Interior Lighting",
                  description: "Aircraft Interior Lighting",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137c1",
                },
                slug: "aircraft-furnishings",
                title: "Aircraft Furnishings",
                id: 9000019,
                parent:
                  "/aerospace-defense/aircraft-parts/aircraft-cabin-interior",
                path: "/aerospace-defense/aircraft-parts/aircraft-cabin-interior/aircraft-furnishings",
                active: true,
                meta: {
                  tags: "Aircraft Furnishings",
                  keywords: "Aircraft Furnishings",
                  description: "Aircraft Furnishings",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137d3",
            },
            slug: "aircraft-materials",
            title: "Aircraft Materials",
            id: 9000037,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/aircraft-materials",
            active: true,
            meta: {
              tags: "Aircraft Materials",
              keywords: "Aircraft Materials",
              description: "Aircraft Materials",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137d4",
                },
                slug: "advanced-aviation-materials",
                title: "Advanced Aviation Materials",
                id: 9000038,
                parent: "/aerospace-defense/aircraft-parts/aircraft-materials",
                path: "/aerospace-defense/aircraft-parts/aircraft-materials/advanced-aviation-materials",
                active: true,
                meta: {
                  tags: "Advanced Aviation Materials",
                  keywords: "Advanced Aviation Materials",
                  description: "Advanced Aviation Materials",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137dc",
            },
            slug: "airframe",
            title: "Airframe",
            id: 9000046,
            parent: "/aerospace-defense/aircraft-parts",
            path: "/aerospace-defense/aircraft-parts/airframe",
            active: true,
            meta: {
              tags: "Airframe",
              keywords: "Airframe",
              description: "Airframe",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137dd",
                },
                slug: "aircraft-brakes",
                title: "Aircraft Brakes",
                id: 9000047,
                parent: "/aerospace-defense/aircraft-parts/airframe",
                path: "/aerospace-defense/aircraft-parts/airframe/aircraft-brakes",
                active: true,
                meta: {
                  tags: "Aircraft Brakes",
                  keywords: "Aircraft Brakes",
                  description: "Aircraft Brakes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137df",
                },
                slug: "aircraft-tires",
                title: "Aircraft Tires",
                id: 9000049,
                parent: "/aerospace-defense/aircraft-parts/airframe",
                path: "/aerospace-defense/aircraft-parts/airframe/aircraft-tires",
                active: true,
                meta: {
                  tags: "Aircraft Tires",
                  keywords: "Aircraft Tires",
                  description: "Aircraft Tires",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137e0",
                },
                slug: "landing-gear",
                title: "Landing Gear",
                id: 9000050,
                parent: "/aerospace-defense/aircraft-parts/airframe",
                path: "/aerospace-defense/aircraft-parts/airframe/landing-gear",
                active: true,
                meta: {
                  tags: "Landing Gear",
                  keywords: "Landing Gear",
                  description: "Landing Gear",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137de",
                },
                slug: "aircraft-lighting",
                title: "Aircraft Lighting",
                id: 9000048,
                parent: "/aerospace-defense/aircraft-parts/airframe",
                path: "/aerospace-defense/aircraft-parts/airframe/aircraft-lighting",
                active: true,
                meta: {
                  tags: "Aircraft Lighting",
                  keywords: "Aircraft Lighting",
                  description: "Aircraft Lighting",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9ef73f1f66acfba13809",
        },
        slug: "aviation-mro",
        title: "Aviation MRO",
        id: 59650,
        parent: "/aerospace-defense",
        path: "/aerospace-defense/aviation-mro",
        active: true,
        meta: {
          tags: "Aviation MRO",
          keywords: "Aviation MRO",
          description: "Aviation MRO",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1380c",
            },
            slug: "aircraft-mro-software",
            title: "Aircraft MRO Software",
            id: 9000096,
            parent: "/aerospace-defense/aviation-mro",
            path: "/aerospace-defense/aviation-mro/aircraft-mro-software",
            active: true,
            meta: {
              tags: "Aircraft MRO Software",
              keywords: "Aircraft MRO Software",
              description: "Aircraft MRO Software",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1380b",
            },
            slug: "aircraft-landing-gear-service",
            title: "Aircraft Landing Gear Service",
            id: 9000095,
            parent: "/aerospace-defense/aviation-mro",
            path: "/aerospace-defense/aviation-mro/aircraft-landing-gear-service",
            active: true,
            meta: {
              tags: "Aircraft Landing Gear Service",
              keywords: "Aircraft Landing Gear Service",
              description: "Aircraft Landing Gear Service",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1380a",
            },
            slug: "aircraft-engine-service",
            title: "Aircraft Engine Service",
            id: 9000094,
            parent: "/aerospace-defense/aviation-mro",
            path: "/aerospace-defense/aviation-mro/aircraft-engine-service",
            active: true,
            meta: {
              tags: "Aircraft Engine Service",
              keywords: "Aircraft Engine Service",
              description: "Aircraft Engine Service",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1380d",
            },
            slug: "military-aircraft-service",
            title: "Military Aircraft Service",
            id: 9000097,
            parent: "/aerospace-defense/aviation-mro",
            path: "/aerospace-defense/aviation-mro/military-aircraft-service",
            active: true,
            meta: {
              tags: "Military Aircraft Service",
              keywords: "Military Aircraft Service",
              description: "Military Aircraft Service",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9ef73f1f66acfba137ba",
        },
        slug: "air-taxi",
        title: "Air Taxi",
        id: 59648,
        parent: "/aerospace-defense",
        path: "/aerospace-defense/air-taxi",
        active: true,
        meta: {
          tags: "Air Taxi",
          keywords: "Air Taxi",
          description: "Air Taxi",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137bb",
            },
            slug: "domestic-air-taxi",
            title: "Domestic Air Taxi",
            id: 9000013,
            parent: "/aerospace-defense/air-taxi",
            path: "/aerospace-defense/air-taxi/domestic-air-taxi",
            active: true,
            meta: {
              tags: "Domestic Air Taxi",
              keywords: "Domestic Air Taxi",
              description: "Domestic Air Taxi",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9ef73f1f66acfba137af",
        },
        slug: "aerospace-and-defense-technology",
        title: "Aerospace & Defense Technology",
        id: 59647,
        parent: "/aerospace-defense",
        path: "/aerospace-defense/aerospace-and-defense-technology",
        active: true,
        meta: {
          tags: "Aerospace & Defense Technology",
          keywords: "Aerospace & Defense Technology",
          description: "Aerospace & Defense Technology",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137b9",
            },
            slug: "rotorcraft-simulation-and-flight-training",
            title: "Rotorcraft Simulation and Flight Training",
            id: 9000011,
            parent: "/aerospace-defense/aerospace-and-defense-technology",
            path: "/aerospace-defense/aerospace-and-defense-technology/rotorcraft-simulation-and-flight-training",
            active: true,
            meta: {
              tags: "Rotorcraft Simulation and Flight Training",
              keywords: "Rotorcraft Simulation and Flight Training",
              description: "Rotorcraft Simulation and Flight Training",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137b4",
            },
            slug: "aircraft-additive-manufacturing",
            title: "Aircraft Additive Manufacturing",
            id: 9000006,
            parent: "/aerospace-defense/aerospace-and-defense-technology",
            path: "/aerospace-defense/aerospace-and-defense-technology/aircraft-additive-manufacturing",
            active: true,
            meta: {
              tags: "Aircraft Additive Manufacturing",
              keywords: "Aircraft Additive Manufacturing",
              description: "Aircraft Additive Manufacturing",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137b3",
            },
            slug: "air-mobility",
            title: "Air Mobility",
            id: 9000005,
            parent: "/aerospace-defense/aerospace-and-defense-technology",
            path: "/aerospace-defense/aerospace-and-defense-technology/air-mobility",
            active: true,
            meta: {
              tags: "Air Mobility",
              keywords: "Air Mobility",
              description: "Air Mobility",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137b5",
            },
            slug: "aviation-electric-engine-system",
            title: "Aviation Electric Engine System",
            id: 9000007,
            parent: "/aerospace-defense/aerospace-and-defense-technology",
            path: "/aerospace-defense/aerospace-and-defense-technology/aviation-electric-engine-system",
            active: true,
            meta: {
              tags: "Aviation Electric Engine System",
              keywords: "Aviation Electric Engine System",
              description: "Aviation Electric Engine System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137b8",
            },
            slug: "military-surveillance",
            title: "Military Surveillance",
            id: 9000010,
            parent: "/aerospace-defense/aerospace-and-defense-technology",
            path: "/aerospace-defense/aerospace-and-defense-technology/military-surveillance",
            active: true,
            meta: {
              tags: "Military Surveillance",
              keywords: "Military Surveillance",
              description: "Military Surveillance",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137b0",
            },
            slug: "aerospace-and-defense-ai",
            title: "Aerospace & Defense AI",
            id: 9000002,
            parent: "/aerospace-defense/aerospace-and-defense-technology",
            path: "/aerospace-defense/aerospace-and-defense-technology/aerospace-and-defense-ai",
            active: true,
            meta: {
              tags: "Aerospace & Defense AI",
              keywords: "Aerospace & Defense AI",
              description: "Aerospace & Defense AI",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba137b1",
                },
                slug: "defense-ai",
                title: "Defense AI",
                id: 9000003,
                parent:
                  "/aerospace-defense/aerospace-and-defense-technology/aerospace-and-defense-ai",
                path: "/aerospace-defense/aerospace-and-defense-technology/aerospace-and-defense-ai/defense-ai",
                active: true,
                meta: {
                  tags: "Defense AI",
                  keywords: "Defense AI",
                  description: "Defense AI",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137b6",
            },
            slug: "explosive-scanning-systems",
            title: "Explosive Scanning Systems",
            id: 9000008,
            parent: "/aerospace-defense/aerospace-and-defense-technology",
            path: "/aerospace-defense/aerospace-and-defense-technology/explosive-scanning-systems",
            active: true,
            meta: {
              tags: "Explosive Scanning Systems",
              keywords: "Explosive Scanning Systems",
              description: "Explosive Scanning Systems",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137b2",
            },
            slug: "aerospace-machine-learning",
            title: "Aerospace Machine Learning",
            id: 9000004,
            parent: "/aerospace-defense/aerospace-and-defense-technology",
            path: "/aerospace-defense/aerospace-and-defense-technology/aerospace-machine-learning",
            active: true,
            meta: {
              tags: "Aerospace Machine Learning",
              keywords: "Aerospace Machine Learning",
              description: "Aerospace Machine Learning",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba137b7",
            },
            slug: "military-electronics",
            title: "Military Electronics",
            id: 9000009,
            parent: "/aerospace-defense/aerospace-and-defense-technology",
            path: "/aerospace-defense/aerospace-and-defense-technology/military-electronics",
            active: true,
            meta: {
              tags: "Military Electronics",
              keywords: "Military Electronics",
              description: "Military Electronics",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9ef73f1f66acfba1383b",
        },
        slug: "unmanned-systems",
        title: "Unmanned Systems",
        id: 59652,
        parent: "/aerospace-defense",
        path: "/aerospace-defense/unmanned-systems",
        active: true,
        meta: {
          tags: "Unmanned Systems",
          keywords: "Unmanned Systems",
          description: "Unmanned Systems",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13846",
            },
            slug: "unmanned-ground-vehicles",
            title: "Unmanned Ground Vehicles",
            id: 9000155,
            parent: "/aerospace-defense/unmanned-systems",
            path: "/aerospace-defense/unmanned-systems/unmanned-ground-vehicles",
            active: true,
            meta: {
              tags: "Unmanned Ground Vehicles",
              keywords: "Unmanned Ground Vehicles",
              description: "Unmanned Ground Vehicles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13847",
                },
                slug: "defense-unmanned-ground-vehicles",
                title: "Defense Unmanned Ground Vehicles",
                id: 9000156,
                parent:
                  "/aerospace-defense/unmanned-systems/unmanned-ground-vehicles",
                path: "/aerospace-defense/unmanned-systems/unmanned-ground-vehicles/defense-unmanned-ground-vehicles",
                active: true,
                meta: {
                  tags: "Defense Unmanned Ground Vehicles",
                  keywords: "Defense Unmanned Ground Vehicles",
                  description: "Defense Unmanned Ground Vehicles",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13848",
            },
            slug: "unmanned-naval-systems",
            title: "Unmanned Naval Systems",
            id: 9000157,
            parent: "/aerospace-defense/unmanned-systems",
            path: "/aerospace-defense/unmanned-systems/unmanned-naval-systems",
            active: true,
            meta: {
              tags: "Unmanned Naval Systems",
              keywords: "Unmanned Naval Systems",
              description: "Unmanned Naval Systems",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba1383c",
            },
            slug: "uav",
            title: "UAV",
            id: 9000145,
            parent: "/aerospace-defense/unmanned-systems",
            path: "/aerospace-defense/unmanned-systems/uav",
            active: true,
            meta: {
              tags: "UAV",
              keywords: "UAV",
              description: "UAV",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1383d",
                },
                slug: "defense-uav",
                title: "Defense UAV",
                id: 9000146,
                parent: "/aerospace-defense/unmanned-systems/uav",
                path: "/aerospace-defense/unmanned-systems/uav/defense-uav",
                active: true,
                meta: {
                  tags: "Defense UAV",
                  keywords: "Defense UAV",
                  description: "Defense UAV",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13844",
                },
                slug: "zero-emission-uav",
                title: "Zero Emission UAV",
                id: 9000153,
                parent: "/aerospace-defense/unmanned-systems/uav",
                path: "/aerospace-defense/unmanned-systems/uav/zero-emission-uav",
                active: true,
                meta: {
                  tags: "Zero Emission UAV",
                  keywords: "Zero Emission UAV",
                  description: "Zero Emission UAV",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13843",
                },
                slug: "uav-navigation-system",
                title: "UAV Navigation System",
                id: 9000152,
                parent: "/aerospace-defense/unmanned-systems/uav",
                path: "/aerospace-defense/unmanned-systems/uav/uav-navigation-system",
                active: true,
                meta: {
                  tags: "UAV Navigation System",
                  keywords: "UAV Navigation System",
                  description: "UAV Navigation System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13842",
                },
                slug: "uav-engine",
                title: "UAV Engine",
                id: 9000151,
                parent: "/aerospace-defense/unmanned-systems/uav",
                path: "/aerospace-defense/unmanned-systems/uav/uav-engine",
                active: true,
                meta: {
                  tags: "UAV Engine",
                  keywords: "UAV Engine",
                  description: "UAV Engine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1383e",
                },
                slug: "delivery-uav",
                title: "Delivery UAV",
                id: 9000147,
                parent: "/aerospace-defense/unmanned-systems/uav",
                path: "/aerospace-defense/unmanned-systems/uav/delivery-uav",
                active: true,
                meta: {
                  tags: "Delivery UAV",
                  keywords: "Delivery UAV",
                  description: "Delivery UAV",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba1383f",
                },
                slug: "tactical-uav-and-subsystems",
                title: "Tactical UAV and Subsystems",
                id: 9000148,
                parent: "/aerospace-defense/unmanned-systems/uav",
                path: "/aerospace-defense/unmanned-systems/uav/tactical-uav-and-subsystems",
                active: true,
                meta: {
                  tags: "Tactical UAV and Subsystems",
                  keywords: "Tactical UAV and Subsystems",
                  description: "Tactical UAV and Subsystems",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9ef73f1f66acfba13840",
                },
                slug: "uav-electronics",
                title: "UAV Electronics",
                id: 9000149,
                parent: "/aerospace-defense/unmanned-systems/uav",
                path: "/aerospace-defense/unmanned-systems/uav/uav-electronics",
                active: true,
                meta: {
                  tags: "UAV Electronics",
                  keywords: "UAV Electronics",
                  description: "UAV Electronics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9ef73f1f66acfba13841",
                    },
                    slug: "uav-sensors",
                    title: "UAV Sensors",
                    id: 9000150,
                    parent:
                      "/aerospace-defense/unmanned-systems/uav/uav-electronics",
                    path: "/aerospace-defense/unmanned-systems/uav/uav-electronics/uav-sensors",
                    active: true,
                    meta: {
                      tags: "UAV Sensors",
                      keywords: "UAV Sensors",
                      description: "UAV Sensors",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9ef73f1f66acfba13845",
            },
            slug: "unmanned-defense-vehicles",
            title: "Unmanned Defense Vehicles",
            id: 9000154,
            parent: "/aerospace-defense/unmanned-systems",
            path: "/aerospace-defense/unmanned-systems/unmanned-defense-vehicles",
            active: true,
            meta: {
              tags: "Unmanned Defense Vehicles",
              keywords: "Unmanned Defense Vehicles",
              description: "Unmanned Defense Vehicles",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b12d",
    },
    id: 61,
    title: "Chemicals & Materials",
    slug: "chemicals-materials",
    active: true,
    meta: {
      tags: "Chemicals & Materials Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "chemical market research reports, chemical market forecast report, construction materials market, advanced materials market, chemicals and materials market research, chemicals and materials market research reports, chemical market report, chemical market analytics, Chemical industry analysis report, Chemical Industry Market Research Reports",
      description:
        "Chemicals and Materials industry reports offers market segmentation data, Major Players in the industry, Competitive Analysis of Industry  and growth opportunities in the market. it includes market share insights for 2020, 2021, and 2022.  Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
    },
    description: {
      short_description:
        "The segment deals with chemical products obtained from either petrochemicals or oleochemicals. We study raw materials and intermediaries for oil and gas, construction, food & beverage, and electrical and electronics industries to give you a comprehensive view of the chemicals and materials industry.",
      full_description:
        "Chemicals & Materials play a critical role across the globe. Companies in virtually every industry, such as construction, utilities, transportation and logistics, food and beverage, drugs, cosmetics, telecommunications, electronics, healthcare, oil and gas, petrochemicals, and packaging, use Chemicals & Materials for manufacturing, sales, marketing, service, and support functions.",
    },
    picture: null,
    __v: 0,
    picture_url: "chemicals-.png",
    cat_email: "cnm@mordorintelligence.com",
    cat_pw_id: 12428,
    parent: "/",
    path: "/chemicals-materials",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b183",
        },
        id: 6132,
        title: "Plastics, Polymers, and Elastomers",
        slug: "plastics-polymers-and-elastomers",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Plastics, Polymers, and Elastomers Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Plastics, Polymers, and Elastomers Market Research Reports, Plastics Market, Plastics Industry, Thermoplastics Market, Thermosetting Plastics Market, PVC Market, Polyurethane Market, Polystyrene Market, Polyolefins Market, Engineering Plastics Market, Elastomers Market, Specialty Polymers Market, Polymer Emulsions Market, Bioplastics Market, Plastics Industry Reports",
          tags: "Plastics, Polymers, and Elastomers Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Life without plastics would be much more expensive and much less comfortable. To name a few, types of plastics includes HDPE, LDPE, PVC, PP, PS, PETE and vinyl plastics, among others. The industry manufactures polymer materials that offers services in plastics important to a range of industries, including packaging, building and construction, electronics, aerospace, and transportation.",
          short_description:
            "It is tough to envisage life without plastics today. The plastics industry manufactures a wide range of polymers for applications in end-user industries such as packaging, construction, consumer goods, aerospace, and transportation, among others. Browse through our reports to learn more.",
        },
        picture: null,
        __v: 0,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/plastics-polymers-and-elastomers",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d544b",
            },
            slug: "elastomers",
            title: "Elastomers",
            id: 8000984,
            parent: "/chemicals-materials/plastics-polymers-and-elastomers",
            path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
            active: true,
            meta: {
              tags: "Elastomers",
              keywords: "Elastomers",
              description: "Elastomers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d544f",
                },
                slug: "fluoroelastomers",
                title: "Fluoroelastomers",
                id: 8000988,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/fluoroelastomers",
                active: true,
                meta: {
                  tags: "Fluoroelastomers",
                  keywords: "Fluoroelastomers",
                  description: "Fluoroelastomers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5453",
                },
                slug: "rubber",
                title: "Rubber",
                id: 8000992,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                active: true,
                meta: {
                  tags: "Rubber",
                  keywords: "Rubber",
                  description: "Rubber",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5454",
                    },
                    slug: "fast-curing-nitrile-butadiene-rubber-nbr",
                    title: "Fast Curing Nitrile Butadiene Rubber (NBR)",
                    id: 8000993,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/fast-curing-nitrile-butadiene-rubber-nbr",
                    active: true,
                    meta: {
                      tags: "Fast Curing Nitrile Butadiene Rubber (NBR)",
                      keywords: "Fast Curing Nitrile Butadiene Rubber (NBR)",
                      description: "Fast Curing Nitrile Butadiene Rubber (NBR)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5459",
                    },
                    slug: "nitrile-butadiene-rubber",
                    title: "Nitrile Butadiene Rubber",
                    id: 8000998,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/nitrile-butadiene-rubber",
                    active: true,
                    meta: {
                      tags: "Nitrile Butadiene Rubber",
                      keywords: "Nitrile Butadiene Rubber",
                      description: "Nitrile Butadiene Rubber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d545d",
                    },
                    slug: "synthetic-rubber",
                    title: "Synthetic Rubber",
                    id: 8001002,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/synthetic-rubber",
                    active: true,
                    meta: {
                      tags: "Synthetic Rubber",
                      keywords: "Synthetic Rubber",
                      description: "Synthetic Rubber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5458",
                    },
                    slug: "neoprene",
                    title: "Neoprene",
                    id: 8000997,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/neoprene",
                    active: true,
                    meta: {
                      tags: "Neoprene",
                      keywords: "Neoprene",
                      description: "Neoprene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d545b",
                    },
                    slug: "polyisobutylene-pib",
                    title: "Polyisobutylene (PIB)",
                    id: 8001000,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/polyisobutylene-pib",
                    active: true,
                    meta: {
                      tags: "Polyisobutylene (PIB)",
                      keywords: "Polyisobutylene (PIB)",
                      description: "Polyisobutylene (PIB)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d545a",
                    },
                    slug: "polybutadiene-rubber-pbr",
                    title: "Polybutadiene Rubber (PBR)",
                    id: 8000999,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/polybutadiene-rubber-pbr",
                    active: true,
                    meta: {
                      tags: "Polybutadiene Rubber (PBR)",
                      keywords: "Polybutadiene Rubber (PBR)",
                      description: "Polybutadiene Rubber (PBR)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5455",
                    },
                    slug: "industrial-rubber",
                    title: "Industrial Rubber",
                    id: 8000994,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/industrial-rubber",
                    active: true,
                    meta: {
                      tags: "Industrial Rubber",
                      keywords: "Industrial Rubber",
                      description: "Industrial Rubber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5457",
                    },
                    slug: "liquid-synthetic-rubber",
                    title: "Liquid Synthetic Rubber",
                    id: 8000996,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/liquid-synthetic-rubber",
                    active: true,
                    meta: {
                      tags: "Liquid Synthetic Rubber",
                      keywords: "Liquid Synthetic Rubber",
                      description: "Liquid Synthetic Rubber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d545c",
                    },
                    slug: "styrene-butadiene-rubber",
                    title: "Styrene Butadiene Rubber",
                    id: 8001001,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/styrene-butadiene-rubber",
                    active: true,
                    meta: {
                      tags: "Styrene Butadiene Rubber",
                      keywords: "Styrene Butadiene Rubber",
                      description: "Styrene Butadiene Rubber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5456",
                    },
                    slug: "liquid-silicone-rubber",
                    title: "Liquid Silicone Rubber",
                    id: 8000995,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/rubber/liquid-silicone-rubber",
                    active: true,
                    meta: {
                      tags: "Liquid Silicone Rubber",
                      keywords: "Liquid Silicone Rubber",
                      description: "Liquid Silicone Rubber",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d544e",
                },
                slug: "ethylene-propylene-diene-monomer-epdm",
                title: "Ethylene Propylene Diene Monomer (EPDM)",
                id: 8000987,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/ethylene-propylene-diene-monomer-epdm",
                active: true,
                meta: {
                  tags: "Ethylene Propylene Diene Monomer (EPDM)",
                  keywords: "Ethylene Propylene Diene Monomer (EPDM)",
                  description: "Ethylene Propylene Diene Monomer (EPDM)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5451",
                },
                slug: "medical-elastomers",
                title: "Medical Elastomers",
                id: 8000990,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/medical-elastomers",
                active: true,
                meta: {
                  tags: "Medical Elastomers",
                  keywords: "Medical Elastomers",
                  description: "Medical Elastomers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d545f",
                },
                slug: "thermoplastic-elastomers",
                title: "Thermoplastic Elastomers",
                id: 8001004,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/thermoplastic-elastomers",
                active: true,
                meta: {
                  tags: "Thermoplastic Elastomers",
                  keywords: "Thermoplastic Elastomers",
                  description: "Thermoplastic Elastomers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5461",
                    },
                    slug: "thermoplastic-vulcanizate",
                    title: "Thermoplastic Vulcanizate",
                    id: 8001006,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/thermoplastic-elastomers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/thermoplastic-elastomers/thermoplastic-vulcanizate",
                    active: true,
                    meta: {
                      tags: "Thermoplastic Vulcanizate",
                      keywords: "Thermoplastic Vulcanizate",
                      description: "Thermoplastic Vulcanizate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5460",
                    },
                    slug: "thermoplastic-polyester-elastomers",
                    title: "Thermoplastic Polyester Elastomers",
                    id: 8001005,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/thermoplastic-elastomers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/thermoplastic-elastomers/thermoplastic-polyester-elastomers",
                    active: true,
                    meta: {
                      tags: "Thermoplastic Polyester Elastomers",
                      keywords: "Thermoplastic Polyester Elastomers",
                      description: "Thermoplastic Polyester Elastomers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5452",
                },
                slug: "polyurea",
                title: "Polyurea",
                id: 8000991,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/polyurea",
                active: true,
                meta: {
                  tags: "Polyurea",
                  keywords: "Polyurea",
                  description: "Polyurea",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d545e",
                },
                slug: "styrene-ethylene-butylene-styrene-sebs",
                title: "Styrene-ethylene-butylene-styrene (SEBS)",
                id: 8001003,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/styrene-ethylene-butylene-styrene-sebs",
                active: true,
                meta: {
                  tags: "Styrene-ethylene-butylene-styrene (SEBS)",
                  keywords: "Styrene-ethylene-butylene-styrene (SEBS)",
                  description: "Styrene-ethylene-butylene-styrene (SEBS)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5450",
                },
                slug: "isoprene",
                title: "Isoprene",
                id: 8000989,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/isoprene",
                active: true,
                meta: {
                  tags: "Isoprene",
                  keywords: "Isoprene",
                  description: "Isoprene",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d544c",
                },
                slug: "cast-elastomers",
                title: "Cast Elastomers",
                id: 8000985,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/cast-elastomers",
                active: true,
                meta: {
                  tags: "Cast Elastomers",
                  keywords: "Cast Elastomers",
                  description: "Cast Elastomers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d544d",
                },
                slug: "elastomeric-membrane",
                title: "Elastomeric Membrane",
                id: 8000986,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/elastomeric-membrane",
                active: true,
                meta: {
                  tags: "Elastomeric Membrane",
                  keywords: "Elastomeric Membrane",
                  description: "Elastomeric Membrane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5462",
                },
                slug: "tire-materials",
                title: "Tire Materials",
                id: 8001007,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/elastomers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/elastomers/tire-materials",
                active: true,
                meta: {
                  tags: "Tire Materials",
                  keywords: "Tire Materials",
                  description: "Tire Materials",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5463",
            },
            slug: "plastics-and-polymers",
            title: "Plastics and Polymers",
            id: 8001008,
            parent: "/chemicals-materials/plastics-polymers-and-elastomers",
            path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
            active: true,
            meta: {
              tags: "Plastics and Polymers",
              keywords: "Plastics and Polymers",
              description: "Plastics and Polymers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5465",
                },
                slug: "acrylamide",
                title: "Acrylamide",
                id: 8001010,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/acrylamide",
                active: true,
                meta: {
                  tags: "Acrylamide",
                  keywords: "Acrylamide",
                  description: "Acrylamide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5468",
                },
                slug: "automotive-polymer-composites",
                title: "Automotive Polymer Composites",
                id: 8001013,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/automotive-polymer-composites",
                active: true,
                meta: {
                  tags: "Automotive Polymer Composites",
                  keywords: "Automotive Polymer Composites",
                  description: "Automotive Polymer Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5469",
                },
                slug: "bakelite",
                title: "Bakelite",
                id: 8001014,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bakelite",
                active: true,
                meta: {
                  tags: "Bakelite",
                  keywords: "Bakelite",
                  description: "Bakelite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54d6",
                },
                slug: "polyacrylamide",
                title: "Polyacrylamide",
                id: 8001123,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polyacrylamide",
                active: true,
                meta: {
                  tags: "Polyacrylamide",
                  keywords: "Polyacrylamide",
                  description: "Polyacrylamide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54da",
                },
                slug: "polymer-blends-alloys",
                title: "Polymer Blends Alloys",
                id: 8001127,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-blends-alloys",
                active: true,
                meta: {
                  tags: "Polymer Blends Alloys",
                  keywords: "Polymer Blends Alloys",
                  description: "Polymer Blends Alloys",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5512",
                },
                slug: "styrenic-block-copolymers",
                title: "Styrenic Block Copolymers",
                id: 8001183,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/styrenic-block-copolymers",
                active: true,
                meta: {
                  tags: "Styrenic Block Copolymers",
                  keywords: "Styrenic Block Copolymers",
                  description: "Styrenic Block Copolymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5466",
                },
                slug: "antimicrobial-plastics",
                title: "Antimicrobial Plastics",
                id: 8001011,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/antimicrobial-plastics",
                active: true,
                meta: {
                  tags: "Antimicrobial Plastics",
                  keywords: "Antimicrobial Plastics",
                  description: "Antimicrobial Plastics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54d4",
                },
                slug: "plastic-components",
                title: "Plastic Components",
                id: 8001121,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-components",
                active: true,
                meta: {
                  tags: "Plastic Components",
                  keywords: "Plastic Components",
                  description: "Plastic Components",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54f3",
                },
                slug: "polysiloxanes",
                title: "Polysiloxanes",
                id: 8001152,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polysiloxanes",
                active: true,
                meta: {
                  tags: "Polysiloxanes",
                  keywords: "Polysiloxanes",
                  description: "Polysiloxanes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54f5",
                },
                slug: "polyvinyl-alcohol-pva",
                title: "Polyvinyl Alcohol (PVA)",
                id: 8001154,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polyvinyl-alcohol-pva",
                active: true,
                meta: {
                  tags: "Polyvinyl Alcohol (PVA)",
                  keywords: "Polyvinyl Alcohol (PVA)",
                  description: "Polyvinyl Alcohol (PVA)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d547b",
                },
                slug: "engineering-plastics",
                title: "Engineering Plastics",
                id: 8001032,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                active: true,
                meta: {
                  tags: "Engineering Plastics",
                  keywords: "Engineering Plastics",
                  description: "Engineering Plastics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d547f",
                    },
                    slug: "crystalline-polyethylene-terephthalate-pet",
                    title: "Crystalline Polyethylene Terephthalate (PET)",
                    id: 8001036,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/crystalline-polyethylene-terephthalate-pet",
                    active: true,
                    meta: {
                      tags: "Crystalline Polyethylene Terephthalate (PET)",
                      keywords: "Crystalline Polyethylene Terephthalate (PET)",
                      description:
                        "Crystalline Polyethylene Terephthalate (PET)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5480",
                    },
                    slug: "fluorinated-polyimide",
                    title: "Fluorinated Polyimide",
                    id: 8001037,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluorinated-polyimide",
                    active: true,
                    meta: {
                      tags: "Fluorinated Polyimide",
                      keywords: "Fluorinated Polyimide",
                      description: "Fluorinated Polyimide",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d548d",
                    },
                    slug: "medical-polyoxymethylene",
                    title: "Medical Polyoxymethylene",
                    id: 8001050,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/medical-polyoxymethylene",
                    active: true,
                    meta: {
                      tags: "Medical Polyoxymethylene",
                      keywords: "Medical Polyoxymethylene",
                      description: "Medical Polyoxymethylene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d548f",
                    },
                    slug: "polycarbonate",
                    title: "Polycarbonate",
                    id: 8001052,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polycarbonate",
                    active: true,
                    meta: {
                      tags: "Polycarbonate",
                      keywords: "Polycarbonate",
                      description: "Polycarbonate",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5490",
                        },
                        slug: "polycarbonate-sheet",
                        title: "Polycarbonate Sheet",
                        id: 8001053,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polycarbonate",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polycarbonate/polycarbonate-sheet",
                        active: true,
                        meta: {
                          tags: "Polycarbonate Sheet",
                          keywords: "Polycarbonate Sheet",
                          description: "Polycarbonate Sheet",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5491",
                        },
                        slug: "polycarbonate-sheet-and-films",
                        title: "Polycarbonate Sheet and Films",
                        id: 8001054,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polycarbonate",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polycarbonate/polycarbonate-sheet-and-films",
                        active: true,
                        meta: {
                          tags: "Polycarbonate Sheet and Films",
                          keywords: "Polycarbonate Sheet and Films",
                          description: "Polycarbonate Sheet and Films",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5499",
                    },
                    slug: "polyethylene-terephthalate-pet-shrink-films",
                    title: "Polyethylene Terephthalate (PET) Shrink Films",
                    id: 8001062,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyethylene-terephthalate-pet-shrink-films",
                    active: true,
                    meta: {
                      tags: "Polyethylene Terephthalate (PET) Shrink Films",
                      keywords: "Polyethylene Terephthalate (PET) Shrink Films",
                      description:
                        "Polyethylene Terephthalate (PET) Shrink Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54a6",
                    },
                    slug: "polyphenylene-sulfide-pps-composites",
                    title: "Polyphenylene Sulfide (PPS) Composites",
                    id: 8001075,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyphenylene-sulfide-pps-composites",
                    active: true,
                    meta: {
                      tags: "Polyphenylene Sulfide (PPS) Composites",
                      keywords: "Polyphenylene Sulfide (PPS) Composites",
                      description: "Polyphenylene Sulfide (PPS) Composites",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54a8",
                    },
                    slug: "polytrimethylene-terephthalate",
                    title: "Polytrimethylene Terephthalate",
                    id: 8001077,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polytrimethylene-terephthalate",
                    active: true,
                    meta: {
                      tags: "Polytrimethylene Terephthalate",
                      keywords: "Polytrimethylene Terephthalate",
                      description: "Polytrimethylene Terephthalate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5495",
                    },
                    slug: "polyethylene-terephthalate-pet-compounding",
                    title: "Polyethylene Terephthalate (PET) Compounding",
                    id: 8001058,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyethylene-terephthalate-pet-compounding",
                    active: true,
                    meta: {
                      tags: "Polyethylene Terephthalate (PET) Compounding",
                      keywords: "Polyethylene Terephthalate (PET) Compounding",
                      description:
                        "Polyethylene Terephthalate (PET) Compounding",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5498",
                    },
                    slug: "polyethylene-terephthalate-pet-resins",
                    title: "Polyethylene Terephthalate (PET) Resins",
                    id: 8001061,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyethylene-terephthalate-pet-resins",
                    active: true,
                    meta: {
                      tags: "Polyethylene Terephthalate (PET) Resins",
                      keywords: "Polyethylene Terephthalate (PET) Resins",
                      description: "Polyethylene Terephthalate (PET) Resins",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54a4",
                    },
                    slug: "polyphenylene",
                    title: "Polyphenylene",
                    id: 8001073,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyphenylene",
                    active: true,
                    meta: {
                      tags: "Polyphenylene",
                      keywords: "Polyphenylene",
                      description: "Polyphenylene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d547d",
                    },
                    slug: "amorphous-polyethylene-terephthalate",
                    title: "Amorphous Polyethylene Terephthalate",
                    id: 8001034,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/amorphous-polyethylene-terephthalate",
                    active: true,
                    meta: {
                      tags: "Amorphous Polyethylene Terephthalate",
                      keywords: "Amorphous Polyethylene Terephthalate",
                      description: "Amorphous Polyethylene Terephthalate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d548b",
                    },
                    slug: "high-performance-polyamides",
                    title: "High Performance Polyamides",
                    id: 8001048,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/high-performance-polyamides",
                    active: true,
                    meta: {
                      tags: "High Performance Polyamides",
                      keywords: "High Performance Polyamides",
                      description: "High Performance Polyamides",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d549b",
                    },
                    slug: "polyimides",
                    title: "Polyimides",
                    id: 8001064,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyimides",
                    active: true,
                    meta: {
                      tags: "Polyimides",
                      keywords: "Polyimides",
                      description: "Polyimides",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5481",
                    },
                    slug: "fluoropolymers",
                    title: "Fluoropolymers",
                    id: 8001038,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers",
                    active: true,
                    meta: {
                      tags: "Fluoropolymers",
                      keywords: "Fluoropolymers",
                      description: "Fluoropolymers",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5487",
                        },
                        slug: "polyvinylidene-fluoride-pvdf",
                        title: "Polyvinylidene Fluoride (PVDF)",
                        id: 8001044,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers/polyvinylidene-fluoride-pvdf",
                        active: true,
                        meta: {
                          tags: "Polyvinylidene Fluoride (PVDF)",
                          keywords: "Polyvinylidene Fluoride (PVDF)",
                          description: "Polyvinylidene Fluoride (PVDF)",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5489",
                        },
                        slug: "ptfe-membrane",
                        title: "PTFE Membrane",
                        id: 8001046,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers/ptfe-membrane",
                        active: true,
                        meta: {
                          tags: "PTFE Membrane",
                          keywords: "PTFE Membrane",
                          description: "PTFE Membrane",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5482",
                        },
                        slug: "micronized-polytetrafluoroethylene-ptfe",
                        title: "Micronized Polytetrafluoroethylene (PTFE)",
                        id: 8001039,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers/micronized-polytetrafluoroethylene-ptfe",
                        active: true,
                        meta: {
                          tags: "Micronized Polytetrafluoroethylene (PTFE)",
                          keywords: "Micronized Polytetrafluoroethylene (PTFE)",
                          description:
                            "Micronized Polytetrafluoroethylene (PTFE)",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5484",
                        },
                        slug: "polychlorotrifluoroethylene",
                        title: "Polychlorotrifluoroethylene",
                        id: 8001041,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers/polychlorotrifluoroethylene",
                        active: true,
                        meta: {
                          tags: "Polychlorotrifluoroethylene",
                          keywords: "Polychlorotrifluoroethylene",
                          description: "Polychlorotrifluoroethylene",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5486",
                        },
                        slug: "polytetrafluoroethylene-ptfe-films",
                        title: "Polytetrafluoroethylene (PTFE) Films",
                        id: 8001043,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers/polytetrafluoroethylene-ptfe-films",
                        active: true,
                        meta: {
                          tags: "Polytetrafluoroethylene (PTFE) Films",
                          keywords: "Polytetrafluoroethylene (PTFE) Films",
                          description: "Polytetrafluoroethylene (PTFE) Films",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5485",
                        },
                        slug: "polytetrafluoroethylene-ptfe",
                        title: "Polytetrafluoroethylene (PTFE)",
                        id: 8001042,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers/polytetrafluoroethylene-ptfe",
                        active: true,
                        meta: {
                          tags: "Polytetrafluoroethylene (PTFE)",
                          keywords: "Polytetrafluoroethylene (PTFE)",
                          description: "Polytetrafluoroethylene (PTFE)",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5488",
                        },
                        slug: "ptfe-fabrics",
                        title: "PTFE Fabrics",
                        id: 8001045,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers/ptfe-fabrics",
                        active: true,
                        meta: {
                          tags: "PTFE Fabrics",
                          keywords: "PTFE Fabrics",
                          description: "PTFE Fabrics",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5483",
                        },
                        slug: "perfluoroalkoxy-alkane-pfa",
                        title: "Perfluoroalkoxy Alkane (PFA)",
                        id: 8001040,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/fluoropolymers/perfluoroalkoxy-alkane-pfa",
                        active: true,
                        meta: {
                          tags: "Perfluoroalkoxy Alkane (PFA)",
                          keywords: "Perfluoroalkoxy Alkane (PFA)",
                          description: "Perfluoroalkoxy Alkane (PFA)",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5492",
                    },
                    slug: "polyether-ether-ketone-peek",
                    title: "Polyether Ether Ketone (PEEK)",
                    id: 8001055,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyether-ether-ketone-peek",
                    active: true,
                    meta: {
                      tags: "Polyether Ether Ketone (PEEK)",
                      keywords: "Polyether Ether Ketone (PEEK)",
                      description: "Polyether Ether Ketone (PEEK)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5493",
                    },
                    slug: "polyetherimide",
                    title: "Polyetherimide",
                    id: 8001056,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyetherimide",
                    active: true,
                    meta: {
                      tags: "Polyetherimide",
                      keywords: "Polyetherimide",
                      description: "Polyetherimide",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5496",
                    },
                    slug: "polyethylene-terephthalate-pet-foam",
                    title: "Polyethylene Terephthalate (PET) Foam",
                    id: 8001059,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyethylene-terephthalate-pet-foam",
                    active: true,
                    meta: {
                      tags: "Polyethylene Terephthalate (PET) Foam",
                      keywords: "Polyethylene Terephthalate (PET) Foam",
                      description: "Polyethylene Terephthalate (PET) Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54a2",
                    },
                    slug: "polymethyl-methacrylate-pmma",
                    title: "Polymethyl Methacrylate (PMMA)",
                    id: 8001071,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymethyl-methacrylate-pmma",
                    active: true,
                    meta: {
                      tags: "Polymethyl Methacrylate (PMMA)",
                      keywords: "Polymethyl Methacrylate (PMMA)",
                      description: "Polymethyl Methacrylate (PMMA)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5494",
                    },
                    slug: "polyethylene-naphthalate",
                    title: "Polyethylene Naphthalate",
                    id: 8001057,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyethylene-naphthalate",
                    active: true,
                    meta: {
                      tags: "Polyethylene Naphthalate",
                      keywords: "Polyethylene Naphthalate",
                      description: "Polyethylene Naphthalate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d549c",
                    },
                    slug: "polymeric-films",
                    title: "Polymeric Films",
                    id: 8001065,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films",
                    active: true,
                    meta: {
                      tags: "Polymeric Films",
                      keywords: "Polymeric Films",
                      description: "Polymeric Films",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d549e",
                        },
                        slug: "polyimide-films",
                        title: "Polyimide Films",
                        id: 8001067,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films/polyimide-films",
                        active: true,
                        meta: {
                          tags: "Polyimide Films",
                          keywords: "Polyimide Films",
                          description: "Polyimide Films",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d549d",
                        },
                        slug: "fluoropolymer-films",
                        title: "Fluoropolymer Films",
                        id: 8001066,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films/fluoropolymer-films",
                        active: true,
                        meta: {
                          tags: "Fluoropolymer Films",
                          keywords: "Fluoropolymer Films",
                          description: "Fluoropolymer Films",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d54a0",
                        },
                        slug: "polyvinyl-alcohol-pva-films",
                        title: "Polyvinyl Alcohol (PVA) Films",
                        id: 8001069,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films/polyvinyl-alcohol-pva-films",
                        active: true,
                        meta: {
                          tags: "Polyvinyl Alcohol (PVA) Films",
                          keywords: "Polyvinyl Alcohol (PVA) Films",
                          description: "Polyvinyl Alcohol (PVA) Films",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d54a1",
                        },
                        slug: "thermoplastic-polyurethane-tpu-films",
                        title: "Thermoplastic Polyurethane (TPU) Films",
                        id: 8001070,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films/thermoplastic-polyurethane-tpu-films",
                        active: true,
                        meta: {
                          tags: "Thermoplastic Polyurethane (TPU) Films",
                          keywords: "Thermoplastic Polyurethane (TPU) Films",
                          description: "Thermoplastic Polyurethane (TPU) Films",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d549f",
                        },
                        slug: "polyurethane-films",
                        title: "Polyurethane Films",
                        id: 8001068,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polymeric-films/polyurethane-films",
                        active: true,
                        meta: {
                          tags: "Polyurethane Films",
                          keywords: "Polyurethane Films",
                          description: "Polyurethane Films",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54a5",
                    },
                    slug: "polyphenylene-sulfide-pps",
                    title: "Polyphenylene Sulfide (PPS)",
                    id: 8001074,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyphenylene-sulfide-pps",
                    active: true,
                    meta: {
                      tags: "Polyphenylene Sulfide (PPS)",
                      keywords: "Polyphenylene Sulfide (PPS)",
                      description: "Polyphenylene Sulfide (PPS)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d548c",
                    },
                    slug: "liquid-crystal-polymers",
                    title: "Liquid Crystal Polymers",
                    id: 8001049,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/liquid-crystal-polymers",
                    active: true,
                    meta: {
                      tags: "Liquid Crystal Polymers",
                      keywords: "Liquid Crystal Polymers",
                      description: "Liquid Crystal Polymers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d547c",
                    },
                    slug: "acrylonitrile-butadiene-styrene-abs-resins",
                    title: "Acrylonitrile Butadiene Styrene (ABS) Resins",
                    id: 8001033,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/acrylonitrile-butadiene-styrene-abs-resins",
                    active: true,
                    meta: {
                      tags: "Acrylonitrile Butadiene Styrene (ABS) Resins",
                      keywords: "Acrylonitrile Butadiene Styrene (ABS) Resins",
                      description:
                        "Acrylonitrile Butadiene Styrene (ABS) Resins",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5497",
                    },
                    slug: "polyethylene-terephthalate-pet-preform",
                    title: "Polyethylene Terephthalate (PET) Preform",
                    id: 8001060,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyethylene-terephthalate-pet-preform",
                    active: true,
                    meta: {
                      tags: "Polyethylene Terephthalate (PET) Preform",
                      keywords: "Polyethylene Terephthalate (PET) Preform",
                      description: "Polyethylene Terephthalate (PET) Preform",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d549a",
                    },
                    slug: "polyethyleneimine",
                    title: "Polyethyleneimine",
                    id: 8001063,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyethyleneimine",
                    active: true,
                    meta: {
                      tags: "Polyethyleneimine",
                      keywords: "Polyethyleneimine",
                      description: "Polyethyleneimine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54a3",
                    },
                    slug: "polyoxymethylene",
                    title: "Polyoxymethylene",
                    id: 8001072,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyoxymethylene",
                    active: true,
                    meta: {
                      tags: "Polyoxymethylene",
                      keywords: "Polyoxymethylene",
                      description: "Polyoxymethylene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54a7",
                    },
                    slug: "polyphthalamide",
                    title: "Polyphthalamide",
                    id: 8001076,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyphthalamide",
                    active: true,
                    meta: {
                      tags: "Polyphthalamide",
                      keywords: "Polyphthalamide",
                      description: "Polyphthalamide",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d547e",
                    },
                    slug: "bio-polyethylene-terephthalate-pet",
                    title: "Bio-Polyethylene Terephthalate (PET)",
                    id: 8001035,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/bio-polyethylene-terephthalate-pet",
                    active: true,
                    meta: {
                      tags: "Bio-Polyethylene Terephthalate (PET)",
                      keywords: "Bio-Polyethylene Terephthalate (PET)",
                      description: "Bio-Polyethylene Terephthalate (PET)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d548a",
                    },
                    slug: "glass-filled-nylon",
                    title: "Glass Filled Nylon",
                    id: 8001047,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/glass-filled-nylon",
                    active: true,
                    meta: {
                      tags: "Glass Filled Nylon",
                      keywords: "Glass Filled Nylon",
                      description: "Glass Filled Nylon",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d548e",
                    },
                    slug: "polyamides",
                    title: "Polyamides",
                    id: 8001051,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/polyamides",
                    active: true,
                    meta: {
                      tags: "Polyamides",
                      keywords: "Polyamides",
                      description: "Polyamides",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54a9",
                    },
                    slug: "recyclate-polyethylene-terephthalate-pet",
                    title: "Recyclate Polyethylene Terephthalate (PET)",
                    id: 8001078,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/engineering-plastics/recyclate-polyethylene-terephthalate-pet",
                    active: true,
                    meta: {
                      tags: "Recyclate Polyethylene Terephthalate (PET)",
                      keywords: "Recyclate Polyethylene Terephthalate (PET)",
                      description: "Recyclate Polyethylene Terephthalate (PET)",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5514",
                },
                slug: "thermoformed-plastics",
                title: "Thermoformed Plastics",
                id: 8001185,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoformed-plastics",
                active: true,
                meta: {
                  tags: "Thermoformed Plastics",
                  keywords: "Thermoformed Plastics",
                  description: "Thermoformed Plastics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5515",
                    },
                    slug: "heavy-gauge-and-thin-gauge-thermoformed-plastics",
                    title: "Heavy Gauge and Thin Gauge Thermoformed Plastics",
                    id: 8001186,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoformed-plastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoformed-plastics/heavy-gauge-and-thin-gauge-thermoformed-plastics",
                    active: true,
                    meta: {
                      tags: "Heavy Gauge and Thin Gauge Thermoformed Plastics",
                      keywords:
                        "Heavy Gauge and Thin Gauge Thermoformed Plastics",
                      description:
                        "Heavy Gauge and Thin Gauge Thermoformed Plastics",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d551d",
                },
                slug: "thermosetting-plastics",
                title: "Thermosetting Plastics",
                id: 8001194,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermosetting-plastics",
                active: true,
                meta: {
                  tags: "Thermosetting Plastics",
                  keywords: "Thermosetting Plastics",
                  description: "Thermosetting Plastics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d551f",
                },
                slug: "water-soluble-polymers",
                title: "Water Soluble Polymers",
                id: 8001196,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/water-soluble-polymers",
                active: true,
                meta: {
                  tags: "Water Soluble Polymers",
                  keywords: "Water Soluble Polymers",
                  description: "Water Soluble Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54ee",
                },
                slug: "polymers",
                title: "Polymers",
                id: 8001147,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymers",
                active: true,
                meta: {
                  tags: "Polymers",
                  keywords: "Polymers",
                  description: "Polymers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54f0",
                    },
                    slug: "polyurethane-elastomers",
                    title: "Polyurethane Elastomers",
                    id: 8001149,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymers/polyurethane-elastomers",
                    active: true,
                    meta: {
                      tags: "Polyurethane Elastomers",
                      keywords: "Polyurethane Elastomers",
                      description: "Polyurethane Elastomers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ef",
                    },
                    slug: "fluorinated-ethylene-propylene",
                    title: "Fluorinated Ethylene Propylene",
                    id: 8001148,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymers/fluorinated-ethylene-propylene",
                    active: true,
                    meta: {
                      tags: "Fluorinated Ethylene Propylene",
                      keywords: "Fluorinated Ethylene Propylene",
                      description: "Fluorinated Ethylene Propylene",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54f1",
                },
                slug: "polyolefin",
                title: "Polyolefin",
                id: 8001150,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polyolefin",
                active: true,
                meta: {
                  tags: "Polyolefin",
                  keywords: "Polyolefin",
                  description: "Polyolefin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54fe",
                },
                slug: "smart-polymers",
                title: "Smart Polymers",
                id: 8001163,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/smart-polymers",
                active: true,
                meta: {
                  tags: "Smart Polymers",
                  keywords: "Smart Polymers",
                  description: "Smart Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d551e",
                },
                slug: "ultra-high-molecular-weight-polyethylene",
                title: "Ultra High Molecular Weight Polyethylene",
                id: 8001195,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/ultra-high-molecular-weight-polyethylene",
                active: true,
                meta: {
                  tags: "Ultra High Molecular Weight Polyethylene",
                  keywords: "Ultra High Molecular Weight Polyethylene",
                  description: "Ultra High Molecular Weight Polyethylene",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5520",
                },
                slug: "wire-and-cable-compound",
                title: "Wire and Cable Compound",
                id: 8001197,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/wire-and-cable-compound",
                active: true,
                meta: {
                  tags: "Wire and Cable Compound",
                  keywords: "Wire and Cable Compound",
                  description: "Wire and Cable Compound",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5479",
                },
                slug: "conductive-polymers",
                title: "Conductive Polymers",
                id: 8001030,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/conductive-polymers",
                active: true,
                meta: {
                  tags: "Conductive Polymers",
                  keywords: "Conductive Polymers",
                  description: "Conductive Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54af",
                },
                slug: "green-and-bio-polyols",
                title: "Green and Bio-polyols",
                id: 8001084,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/green-and-bio-polyols",
                active: true,
                meta: {
                  tags: "Green and Bio-polyols",
                  keywords: "Green and Bio-polyols",
                  description: "Green and Bio-polyols",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54b3",
                },
                slug: "plastic-additives",
                title: "Plastic Additives",
                id: 8001088,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                active: true,
                meta: {
                  tags: "Plastic Additives",
                  keywords: "Plastic Additives",
                  description: "Plastic Additives",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ba",
                    },
                    slug: "dicyclopentadiene",
                    title: "Dicyclopentadiene",
                    id: 8001095,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/dicyclopentadiene",
                    active: true,
                    meta: {
                      tags: "Dicyclopentadiene",
                      keywords: "Dicyclopentadiene",
                      description: "Dicyclopentadiene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54bc",
                    },
                    slug: "diisononyl-phthalate-dinp",
                    title: "Diisononyl Phthalate (DINP)",
                    id: 8001097,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/diisononyl-phthalate-dinp",
                    active: true,
                    meta: {
                      tags: "Diisononyl Phthalate (DINP)",
                      keywords: "Diisononyl Phthalate (DINP)",
                      description: "Diisononyl Phthalate (DINP)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c5",
                    },
                    slug: "polymer-binders",
                    title: "Polymer Binders",
                    id: 8001106,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/polymer-binders",
                    active: true,
                    meta: {
                      tags: "Polymer Binders",
                      keywords: "Polymer Binders",
                      description: "Polymer Binders",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c6",
                    },
                    slug: "polymer-excipients",
                    title: "Polymer Excipients",
                    id: 8001107,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/polymer-excipients",
                    active: true,
                    meta: {
                      tags: "Polymer Excipients",
                      keywords: "Polymer Excipients",
                      description: "Polymer Excipients",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c7",
                    },
                    slug: "polymer-filler",
                    title: "Polymer Filler",
                    id: 8001108,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/polymer-filler",
                    active: true,
                    meta: {
                      tags: "Polymer Filler",
                      keywords: "Polymer Filler",
                      description: "Polymer Filler",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54cc",
                    },
                    slug: "polyurethane-additives",
                    title: "Polyurethane Additives",
                    id: 8001113,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/polyurethane-additives",
                    active: true,
                    meta: {
                      tags: "Polyurethane Additives",
                      keywords: "Polyurethane Additives",
                      description: "Polyurethane Additives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54cd",
                    },
                    slug: "propylene-glycol",
                    title: "Propylene Glycol",
                    id: 8001114,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/propylene-glycol",
                    active: true,
                    meta: {
                      tags: "Propylene Glycol",
                      keywords: "Propylene Glycol",
                      description: "Propylene Glycol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54cf",
                    },
                    slug: "pvc-stabilizers",
                    title: "PVC Stabilizers",
                    id: 8001116,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/pvc-stabilizers",
                    active: true,
                    meta: {
                      tags: "PVC Stabilizers",
                      keywords: "PVC Stabilizers",
                      description: "PVC Stabilizers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54d1",
                    },
                    slug: "stabilizers",
                    title: "Stabilizers",
                    id: 8001118,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/stabilizers",
                    active: true,
                    meta: {
                      tags: "Stabilizers",
                      keywords: "Stabilizers",
                      description: "Stabilizers",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d54d2",
                        },
                        slug: "uv-stabilizers",
                        title: "UV Stabilizers",
                        id: 8001119,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/stabilizers",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/stabilizers/uv-stabilizers",
                        active: true,
                        meta: {
                          tags: "UV Stabilizers",
                          keywords: "UV Stabilizers",
                          description: "UV Stabilizers",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54bb",
                    },
                    slug: "diethylene-glycol-deg",
                    title: "Diethylene Glycol (DEG)",
                    id: 8001096,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/diethylene-glycol-deg",
                    active: true,
                    meta: {
                      tags: "Diethylene Glycol (DEG)",
                      keywords: "Diethylene Glycol (DEG)",
                      description: "Diethylene Glycol (DEG)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c8",
                    },
                    slug: "polymer-processing-aid",
                    title: "Polymer Processing Aid",
                    id: 8001109,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/polymer-processing-aid",
                    active: true,
                    meta: {
                      tags: "Polymer Processing Aid",
                      keywords: "Polymer Processing Aid",
                      description: "Polymer Processing Aid",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c9",
                    },
                    slug: "polymer-reinforcing-filler",
                    title: "Polymer Reinforcing Filler",
                    id: 8001110,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/polymer-reinforcing-filler",
                    active: true,
                    meta: {
                      tags: "Polymer Reinforcing Filler",
                      keywords: "Polymer Reinforcing Filler",
                      description: "Polymer Reinforcing Filler",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54d0",
                    },
                    slug: "slip-additives",
                    title: "Slip Additives",
                    id: 8001117,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/slip-additives",
                    active: true,
                    meta: {
                      tags: "Slip Additives",
                      keywords: "Slip Additives",
                      description: "Slip Additives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54b5",
                    },
                    slug: "anti-static-agents",
                    title: "Anti Static Agents",
                    id: 8001090,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/anti-static-agents",
                    active: true,
                    meta: {
                      tags: "Anti Static Agents",
                      keywords: "Anti Static Agents",
                      description: "Anti Static Agents",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54b9",
                    },
                    slug: "coalescing-agent",
                    title: "Coalescing Agent",
                    id: 8001094,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/coalescing-agent",
                    active: true,
                    meta: {
                      tags: "Coalescing Agent",
                      keywords: "Coalescing Agent",
                      description: "Coalescing Agent",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54be",
                    },
                    slug: "hindered-amine-light-stabilize",
                    title: "Hindered Amine Light Stabilize",
                    id: 8001099,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/hindered-amine-light-stabilize",
                    active: true,
                    meta: {
                      tags: "Hindered Amine Light Stabilize",
                      keywords: "Hindered Amine Light Stabilize",
                      description: "Hindered Amine Light Stabilize",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c3",
                    },
                    slug: "plastisols",
                    title: "Plastisols",
                    id: 8001104,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/plastisols",
                    active: true,
                    meta: {
                      tags: "Plastisols",
                      keywords: "Plastisols",
                      description: "Plastisols",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ce",
                    },
                    slug: "purging-compound",
                    title: "Purging Compound",
                    id: 8001115,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/purging-compound",
                    active: true,
                    meta: {
                      tags: "Purging Compound",
                      keywords: "Purging Compound",
                      description: "Purging Compound",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54b6",
                    },
                    slug: "antifog-additives",
                    title: "Antifog Additives",
                    id: 8001091,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/antifog-additives",
                    active: true,
                    meta: {
                      tags: "Antifog Additives",
                      keywords: "Antifog Additives",
                      description: "Antifog Additives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c1",
                    },
                    slug: "plastic-antioxidants",
                    title: "Plastic Antioxidants",
                    id: 8001102,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/plastic-antioxidants",
                    active: true,
                    meta: {
                      tags: "Plastic Antioxidants",
                      keywords: "Plastic Antioxidants",
                      description: "Plastic Antioxidants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c4",
                    },
                    slug: "polyalkylene-glycols-pags",
                    title: "Polyalkylene Glycols (PAGs)",
                    id: 8001105,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/polyalkylene-glycols-pags",
                    active: true,
                    meta: {
                      tags: "Polyalkylene Glycols (PAGs)",
                      keywords: "Polyalkylene Glycols (PAGs)",
                      description: "Polyalkylene Glycols (PAGs)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ca",
                    },
                    slug: "polymer-stabilizers",
                    title: "Polymer Stabilizers",
                    id: 8001111,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/polymer-stabilizers",
                    active: true,
                    meta: {
                      tags: "Polymer Stabilizers",
                      keywords: "Polymer Stabilizers",
                      description: "Polymer Stabilizers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54b4",
                    },
                    slug: "anti-slip-additives",
                    title: "Anti Slip Additives",
                    id: 8001089,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/anti-slip-additives",
                    active: true,
                    meta: {
                      tags: "Anti Slip Additives",
                      keywords: "Anti Slip Additives",
                      description: "Anti Slip Additives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54b7",
                    },
                    slug: "bio-plasticizers",
                    title: "Bio Plasticizers",
                    id: 8001092,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/bio-plasticizers",
                    active: true,
                    meta: {
                      tags: "Bio Plasticizers",
                      keywords: "Bio Plasticizers",
                      description: "Bio Plasticizers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c0",
                    },
                    slug: "masterbatch",
                    title: "Masterbatch",
                    id: 8001101,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/masterbatch",
                    active: true,
                    meta: {
                      tags: "Masterbatch",
                      keywords: "Masterbatch",
                      description: "Masterbatch",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54d3",
                    },
                    slug: "tris-nonylphenyl-phosphite",
                    title: "Tris Nonylphenyl Phosphite",
                    id: 8001120,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/tris-nonylphenyl-phosphite",
                    active: true,
                    meta: {
                      tags: "Tris Nonylphenyl Phosphite",
                      keywords: "Tris Nonylphenyl Phosphite",
                      description: "Tris Nonylphenyl Phosphite",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54b8",
                    },
                    slug: "butylated-triphenyl-phosphate",
                    title: "Butylated Triphenyl Phosphate",
                    id: 8001093,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/butylated-triphenyl-phosphate",
                    active: true,
                    meta: {
                      tags: "Butylated Triphenyl Phosphate",
                      keywords: "Butylated Triphenyl Phosphate",
                      description: "Butylated Triphenyl Phosphate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54bd",
                    },
                    slug: "green-and-bio-based-plastic-additives",
                    title: "Green and Bio-based Plastic Additives",
                    id: 8001098,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/green-and-bio-based-plastic-additives",
                    active: true,
                    meta: {
                      tags: "Green and Bio-based Plastic Additives",
                      keywords: "Green and Bio-based Plastic Additives",
                      description: "Green and Bio-based Plastic Additives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54bf",
                    },
                    slug: "low-profile-additives",
                    title: "Low Profile Additives",
                    id: 8001100,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/low-profile-additives",
                    active: true,
                    meta: {
                      tags: "Low Profile Additives",
                      keywords: "Low Profile Additives",
                      description: "Low Profile Additives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54c2",
                    },
                    slug: "plastic-color-concentrates",
                    title: "Plastic Color Concentrates",
                    id: 8001103,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/plastic-color-concentrates",
                    active: true,
                    meta: {
                      tags: "Plastic Color Concentrates",
                      keywords: "Plastic Color Concentrates",
                      description: "Plastic Color Concentrates",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54cb",
                    },
                    slug: "polytetramethylene-ether-glycol-ptmeg",
                    title: "Polytetramethylene Ether Glycol (PTMEG)",
                    id: 8001112,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-additives/polytetramethylene-ether-glycol-ptmeg",
                    active: true,
                    meta: {
                      tags: "Polytetramethylene Ether Glycol (PTMEG)",
                      keywords: "Polytetramethylene Ether Glycol (PTMEG)",
                      description: "Polytetramethylene Ether Glycol (PTMEG)",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54d7",
                },
                slug: "polyacrylic-acid",
                title: "Polyacrylic Acid",
                id: 8001124,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polyacrylic-acid",
                active: true,
                meta: {
                  tags: "Polyacrylic Acid",
                  keywords: "Polyacrylic Acid",
                  description: "Polyacrylic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54e9",
                },
                slug: "polymeric-fiber",
                title: "Polymeric Fiber",
                id: 8001142,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-fiber",
                active: true,
                meta: {
                  tags: "Polymeric Fiber",
                  keywords: "Polymeric Fiber",
                  description: "Polymeric Fiber",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ec",
                    },
                    slug: "polypropylene-fiber",
                    title: "Polypropylene Fiber",
                    id: 8001145,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-fiber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-fiber/polypropylene-fiber",
                    active: true,
                    meta: {
                      tags: "Polypropylene Fiber",
                      keywords: "Polypropylene Fiber",
                      description: "Polypropylene Fiber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54eb",
                    },
                    slug: "modacrylic-fiber",
                    title: "Modacrylic Fiber",
                    id: 8001144,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-fiber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-fiber/modacrylic-fiber",
                    active: true,
                    meta: {
                      tags: "Modacrylic Fiber",
                      keywords: "Modacrylic Fiber",
                      description: "Modacrylic Fiber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ea",
                    },
                    slug: "acrylic-fiber",
                    title: "Acrylic Fiber",
                    id: 8001143,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-fiber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-fiber/acrylic-fiber",
                    active: true,
                    meta: {
                      tags: "Acrylic Fiber",
                      keywords: "Acrylic Fiber",
                      description: "Acrylic Fiber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ed",
                    },
                    slug: "viscose-staple-fiber",
                    title: "Viscose Staple Fiber",
                    id: 8001146,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-fiber",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-fiber/viscose-staple-fiber",
                    active: true,
                    meta: {
                      tags: "Viscose Staple Fiber",
                      keywords: "Viscose Staple Fiber",
                      description: "Viscose Staple Fiber",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54f6",
                },
                slug: "polyvinyl-chloride-membrane",
                title: "Polyvinyl Chloride Membrane",
                id: 8001155,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polyvinyl-chloride-membrane",
                active: true,
                meta: {
                  tags: "Polyvinyl Chloride Membrane",
                  keywords: "Polyvinyl Chloride Membrane",
                  description: "Polyvinyl Chloride Membrane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54f7",
                },
                slug: "polyvinylpyrrolidone",
                title: "Polyvinylpyrrolidone",
                id: 8001156,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polyvinylpyrrolidone",
                active: true,
                meta: {
                  tags: "Polyvinylpyrrolidone",
                  keywords: "Polyvinylpyrrolidone",
                  description: "Polyvinylpyrrolidone",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5467",
                },
                slug: "automotive-plastics",
                title: "Automotive Plastics",
                id: 8001012,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/automotive-plastics",
                active: true,
                meta: {
                  tags: "Automotive Plastics",
                  keywords: "Automotive Plastics",
                  description: "Automotive Plastics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54f8",
                },
                slug: "powder-injection-molding",
                title: "Powder Injection Molding",
                id: 8001157,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/powder-injection-molding",
                active: true,
                meta: {
                  tags: "Powder Injection Molding",
                  keywords: "Powder Injection Molding",
                  description: "Powder Injection Molding",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54ff",
                },
                slug: "specialty-films",
                title: "Specialty Films",
                id: 8001164,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                active: true,
                meta: {
                  tags: "Specialty Films",
                  keywords: "Specialty Films",
                  description: "Specialty Films",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5504",
                    },
                    slug: "high-performance-films",
                    title: "High Performance Films",
                    id: 8001169,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/high-performance-films",
                    active: true,
                    meta: {
                      tags: "High Performance Films",
                      keywords: "High Performance Films",
                      description: "High Performance Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5508",
                    },
                    slug: "optical-films",
                    title: "Optical Films",
                    id: 8001173,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/optical-films",
                    active: true,
                    meta: {
                      tags: "Optical Films",
                      keywords: "Optical Films",
                      description: "Optical Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d550d",
                    },
                    slug: "transparent-conductive-films",
                    title: "Transparent Conductive Films",
                    id: 8001178,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/transparent-conductive-films",
                    active: true,
                    meta: {
                      tags: "Transparent Conductive Films",
                      keywords: "Transparent Conductive Films",
                      description: "Transparent Conductive Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d550e",
                    },
                    slug: "water-soluble-films",
                    title: "Water Soluble Films",
                    id: 8001179,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/water-soluble-films",
                    active: true,
                    meta: {
                      tags: "Water Soluble Films",
                      keywords: "Water Soluble Films",
                      description: "Water Soluble Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5505",
                    },
                    slug: "industrial-films",
                    title: "Industrial Films",
                    id: 8001170,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/industrial-films",
                    active: true,
                    meta: {
                      tags: "Industrial Films",
                      keywords: "Industrial Films",
                      description: "Industrial Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d550a",
                    },
                    slug: "polyvinylidene-chloride-pvdc-coated-films",
                    title: "Polyvinylidene Chloride (PVDC) Coated Films",
                    id: 8001175,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/polyvinylidene-chloride-pvdc-coated-films",
                    active: true,
                    meta: {
                      tags: "Polyvinylidene Chloride (PVDC) Coated Films",
                      keywords: "Polyvinylidene Chloride (PVDC) Coated Films",
                      description:
                        "Polyvinylidene Chloride (PVDC) Coated Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5500",
                    },
                    slug: "automotive-films",
                    title: "Automotive Films",
                    id: 8001165,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/automotive-films",
                    active: true,
                    meta: {
                      tags: "Automotive Films",
                      keywords: "Automotive Films",
                      description: "Automotive Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5507",
                    },
                    slug: "micro-perforated-films",
                    title: "Micro Perforated Films",
                    id: 8001172,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/micro-perforated-films",
                    active: true,
                    meta: {
                      tags: "Micro Perforated Films",
                      keywords: "Micro Perforated Films",
                      description: "Micro Perforated Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5509",
                    },
                    slug: "polyolefin-po-shrink-films",
                    title: "Polyolefin (PO) Shrink Films",
                    id: 8001174,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/polyolefin-po-shrink-films",
                    active: true,
                    meta: {
                      tags: "Polyolefin (PO) Shrink Films",
                      keywords: "Polyolefin (PO) Shrink Films",
                      description: "Polyolefin (PO) Shrink Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5502",
                    },
                    slug: "cling-films",
                    title: "Cling Films",
                    id: 8001167,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/cling-films",
                    active: true,
                    meta: {
                      tags: "Cling Films",
                      keywords: "Cling Films",
                      description: "Cling Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5506",
                    },
                    slug: "metalized-films",
                    title: "Metalized Films",
                    id: 8001171,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/metalized-films",
                    active: true,
                    meta: {
                      tags: "Metalized Films",
                      keywords: "Metalized Films",
                      description: "Metalized Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d550c",
                    },
                    slug: "specialty-and-high-performance-films",
                    title: "Specialty and High Performance Films",
                    id: 8001177,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/specialty-and-high-performance-films",
                    active: true,
                    meta: {
                      tags: "Specialty and High Performance Films",
                      keywords: "Specialty and High Performance Films",
                      description: "Specialty and High Performance Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d550f",
                    },
                    slug: "window-films",
                    title: "Window Films",
                    id: 8001180,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/window-films",
                    active: true,
                    meta: {
                      tags: "Window Films",
                      keywords: "Window Films",
                      description: "Window Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5501",
                    },
                    slug: "breathable-films-and-membranes",
                    title: "Breathable Films and Membranes",
                    id: 8001166,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/breathable-films-and-membranes",
                    active: true,
                    meta: {
                      tags: "Breathable Films and Membranes",
                      keywords: "Breathable Films and Membranes",
                      description: "Breathable Films and Membranes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5503",
                    },
                    slug: "greenhouse-films",
                    title: "Greenhouse Films",
                    id: 8001168,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/greenhouse-films",
                    active: true,
                    meta: {
                      tags: "Greenhouse Films",
                      keywords: "Greenhouse Films",
                      description: "Greenhouse Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d550b",
                    },
                    slug: "solar-control-window-films",
                    title: "Solar Control Window Films",
                    id: 8001176,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-films/solar-control-window-films",
                    active: true,
                    meta: {
                      tags: "Solar Control Window Films",
                      keywords: "Solar Control Window Films",
                      description: "Solar Control Window Films",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5510",
                },
                slug: "specialty-polymers",
                title: "Specialty Polymers",
                id: 8001181,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/specialty-polymers",
                active: true,
                meta: {
                  tags: "Specialty Polymers",
                  keywords: "Specialty Polymers",
                  description: "Specialty Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5513",
                },
                slug: "synthetic-latex-polymers",
                title: "Synthetic Latex Polymers",
                id: 8001184,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/synthetic-latex-polymers",
                active: true,
                meta: {
                  tags: "Synthetic Latex Polymers",
                  keywords: "Synthetic Latex Polymers",
                  description: "Synthetic Latex Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5464",
                },
                slug: "3d-printing-filament",
                title: "3D Printing Filament",
                id: 8001009,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/3d-printing-filament",
                active: true,
                meta: {
                  tags: "3D Printing Filament",
                  keywords: "3D Printing Filament",
                  description: "3D Printing Filament",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d546a",
                },
                slug: "bio-based-polyurethane",
                title: "Bio-based Polyurethane",
                id: 8001015,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bio-based-polyurethane",
                active: true,
                meta: {
                  tags: "Bio-based Polyurethane",
                  keywords: "Bio-based Polyurethane",
                  description: "Bio-based Polyurethane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54ae",
                },
                slug: "gas-separation-membrane",
                title: "Gas Separation Membrane",
                id: 8001083,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/gas-separation-membrane",
                active: true,
                meta: {
                  tags: "Gas Separation Membrane",
                  keywords: "Gas Separation Membrane",
                  description: "Gas Separation Membrane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54db",
                },
                slug: "polymer-emulsions",
                title: "Polymer Emulsions",
                id: 8001128,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-emulsions",
                active: true,
                meta: {
                  tags: "Polymer Emulsions",
                  keywords: "Polymer Emulsions",
                  description: "Polymer Emulsions",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54f4",
                },
                slug: "polyurethane",
                title: "Polyurethane",
                id: 8001153,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polyurethane",
                active: true,
                meta: {
                  tags: "Polyurethane",
                  keywords: "Polyurethane",
                  description: "Polyurethane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5511",
                },
                slug: "starch-polymers",
                title: "Starch Polymers",
                id: 8001182,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/starch-polymers",
                active: true,
                meta: {
                  tags: "Starch Polymers",
                  keywords: "Starch Polymers",
                  description: "Starch Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d546b",
                },
                slug: "bioplastics",
                title: "Bioplastics",
                id: 8001016,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                active: true,
                meta: {
                  tags: "Bioplastics",
                  keywords: "Bioplastics",
                  description: "Bioplastics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d546f",
                    },
                    slug: "biomedical-textiles",
                    title: "Biomedical Textiles",
                    id: 8001020,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/biomedical-textiles",
                    active: true,
                    meta: {
                      tags: "Biomedical Textiles",
                      keywords: "Biomedical Textiles",
                      description: "Biomedical Textiles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5470",
                    },
                    slug: "degradable-bioplastics",
                    title: "Degradable Bioplastics",
                    id: 8001021,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/degradable-bioplastics",
                    active: true,
                    meta: {
                      tags: "Degradable Bioplastics",
                      keywords: "Degradable Bioplastics",
                      description: "Degradable Bioplastics",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5471",
                        },
                        slug: "bio-degradable-polymers",
                        title: "Bio Degradable Polymers",
                        id: 8001022,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/degradable-bioplastics",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/degradable-bioplastics/bio-degradable-polymers",
                        active: true,
                        meta: {
                          tags: "Bio Degradable Polymers",
                          keywords: "Bio Degradable Polymers",
                          description: "Bio Degradable Polymers",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5476",
                    },
                    slug: "thermoplastic-starch",
                    title: "Thermoplastic Starch",
                    id: 8001027,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/thermoplastic-starch",
                    active: true,
                    meta: {
                      tags: "Thermoplastic Starch",
                      keywords: "Thermoplastic Starch",
                      description: "Thermoplastic Starch",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5475",
                    },
                    slug: "polyhydroxyalkanoate-pha",
                    title: "Polyhydroxyalkanoate (PHA)",
                    id: 8001026,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/polyhydroxyalkanoate-pha",
                    active: true,
                    meta: {
                      tags: "Polyhydroxyalkanoate (PHA)",
                      keywords: "Polyhydroxyalkanoate (PHA)",
                      description: "Polyhydroxyalkanoate (PHA)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d546c",
                    },
                    slug: "bio-based-polymers",
                    title: "Bio-based Polymers",
                    id: 8001017,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/bio-based-polymers",
                    active: true,
                    meta: {
                      tags: "Bio-based Polymers",
                      keywords: "Bio-based Polymers",
                      description: "Bio-based Polymers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d546d",
                    },
                    slug: "bio-based-polypropylene",
                    title: "Bio-based Polypropylene",
                    id: 8001018,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/bio-based-polypropylene",
                    active: true,
                    meta: {
                      tags: "Bio-based Polypropylene",
                      keywords: "Bio-based Polypropylene",
                      description: "Bio-based Polypropylene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d546e",
                    },
                    slug: "bio-based-polyvinyl-chloride",
                    title: "Bio-based Polyvinyl Chloride",
                    id: 8001019,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/bio-based-polyvinyl-chloride",
                    active: true,
                    meta: {
                      tags: "Bio-based Polyvinyl Chloride",
                      keywords: "Bio-based Polyvinyl Chloride",
                      description: "Bio-based Polyvinyl Chloride",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5472",
                    },
                    slug: "fiber",
                    title: "Fiber",
                    id: 8001023,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/fiber",
                    active: true,
                    meta: {
                      tags: "Fiber",
                      keywords: "Fiber",
                      description: "Fiber",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5473",
                        },
                        slug: "extruded-pla-fiber",
                        title: "Extruded PLA Fiber",
                        id: 8001024,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/fiber",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/fiber/extruded-pla-fiber",
                        active: true,
                        meta: {
                          tags: "Extruded PLA Fiber",
                          keywords: "Extruded PLA Fiber",
                          description: "Extruded PLA Fiber",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5474",
                    },
                    slug: "polyethylene-furanoate-pef",
                    title: "Polyethylene Furanoate (PEF)",
                    id: 8001025,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/bioplastics/polyethylene-furanoate-pef",
                    active: true,
                    meta: {
                      tags: "Polyethylene Furanoate (PEF)",
                      keywords: "Polyethylene Furanoate (PEF)",
                      description: "Polyethylene Furanoate (PEF)",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5477",
                },
                slug: "cast-polymers",
                title: "Cast Polymers",
                id: 8001028,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/cast-polymers",
                active: true,
                meta: {
                  tags: "Cast Polymers",
                  keywords: "Cast Polymers",
                  description: "Cast Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54b1",
                },
                slug: "medical-plastics",
                title: "Medical Plastics",
                id: 8001086,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/medical-plastics",
                active: true,
                meta: {
                  tags: "Medical Plastics",
                  keywords: "Medical Plastics",
                  description: "Medical Plastics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54e8",
                },
                slug: "polymeric-adsorbents",
                title: "Polymeric Adsorbents",
                id: 8001141,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymeric-adsorbents",
                active: true,
                meta: {
                  tags: "Polymeric Adsorbents",
                  keywords: "Polymeric Adsorbents",
                  description: "Polymeric Adsorbents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54f2",
                },
                slug: "polyolefin-powder",
                title: "Polyolefin Powder",
                id: 8001151,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polyolefin-powder",
                active: true,
                meta: {
                  tags: "Polyolefin Powder",
                  keywords: "Polyolefin Powder",
                  description: "Polyolefin Powder",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54f9",
                },
                slug: "precious-metals-catalyst",
                title: "Precious Metals Catalyst",
                id: 8001158,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/precious-metals-catalyst",
                active: true,
                meta: {
                  tags: "Precious Metals Catalyst",
                  keywords: "Precious Metals Catalyst",
                  description: "Precious Metals Catalyst",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54fa",
                },
                slug: "resorbable-polymers",
                title: "Resorbable Polymers",
                id: 8001159,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/resorbable-polymers",
                active: true,
                meta: {
                  tags: "Resorbable Polymers",
                  keywords: "Resorbable Polymers",
                  description: "Resorbable Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54fb",
                },
                slug: "rigid-transparent-plastics",
                title: "Rigid Transparent Plastics",
                id: 8001160,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/rigid-transparent-plastics",
                active: true,
                meta: {
                  tags: "Rigid Transparent Plastics",
                  keywords: "Rigid Transparent Plastics",
                  description: "Rigid Transparent Plastics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5516",
                },
                slug: "thermoplastic-polymers",
                title: "Thermoplastic Polymers",
                id: 8001187,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers",
                active: true,
                meta: {
                  tags: "Thermoplastic Polymers",
                  keywords: "Thermoplastic Polymers",
                  description: "Thermoplastic Polymers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d551a",
                    },
                    slug: "solid-grade-thermoplastic-acrylic-beads-resins",
                    title: "Solid Grade Thermoplastic Acrylic (Beads) Resins",
                    id: 8001191,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers/solid-grade-thermoplastic-acrylic-beads-resins",
                    active: true,
                    meta: {
                      tags: "Solid Grade Thermoplastic Acrylic (Beads) Resins",
                      keywords:
                        "Solid Grade Thermoplastic Acrylic (Beads) Resins",
                      description:
                        "Solid Grade Thermoplastic Acrylic (Beads) Resins",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d551b",
                    },
                    slug: "thermoplastic-micro-molding",
                    title: "Thermoplastic Micro Molding",
                    id: 8001192,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers/thermoplastic-micro-molding",
                    active: true,
                    meta: {
                      tags: "Thermoplastic Micro Molding",
                      keywords: "Thermoplastic Micro Molding",
                      description: "Thermoplastic Micro Molding",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5517",
                    },
                    slug: "high-temperature-thermoplastics",
                    title: "High Temperature Thermoplastics",
                    id: 8001188,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers/high-temperature-thermoplastics",
                    active: true,
                    meta: {
                      tags: "High Temperature Thermoplastics",
                      keywords: "High Temperature Thermoplastics",
                      description: "High Temperature Thermoplastics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5518",
                    },
                    slug: "long-fiber-thermoplastics",
                    title: "Long Fiber Thermoplastics",
                    id: 8001189,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers/long-fiber-thermoplastics",
                    active: true,
                    meta: {
                      tags: "Long Fiber Thermoplastics",
                      keywords: "Long Fiber Thermoplastics",
                      description: "Long Fiber Thermoplastics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5519",
                    },
                    slug: "polyarylsulfone",
                    title: "Polyarylsulfone",
                    id: 8001190,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polymers/polyarylsulfone",
                    active: true,
                    meta: {
                      tags: "Polyarylsulfone",
                      keywords: "Polyarylsulfone",
                      description: "Polyarylsulfone",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d551c",
                },
                slug: "thermoplastic-polyurethane",
                title: "Thermoplastic Polyurethane",
                id: 8001193,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/thermoplastic-polyurethane",
                active: true,
                meta: {
                  tags: "Thermoplastic Polyurethane",
                  keywords: "Thermoplastic Polyurethane",
                  description: "Thermoplastic Polyurethane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5478",
                },
                slug: "chlorinated-polyethylene",
                title: "Chlorinated Polyethylene",
                id: 8001029,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/chlorinated-polyethylene",
                active: true,
                meta: {
                  tags: "Chlorinated Polyethylene",
                  keywords: "Chlorinated Polyethylene",
                  description: "Chlorinated Polyethylene",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d547a",
                },
                slug: "electroactive-polymers",
                title: "Electroactive Polymers",
                id: 8001031,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/electroactive-polymers",
                active: true,
                meta: {
                  tags: "Electroactive Polymers",
                  keywords: "Electroactive Polymers",
                  description: "Electroactive Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54aa",
                },
                slug: "functional-polymers",
                title: "Functional Polymers",
                id: 8001079,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/functional-polymers",
                active: true,
                meta: {
                  tags: "Functional Polymers",
                  keywords: "Functional Polymers",
                  description: "Functional Polymers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ac",
                    },
                    slug: "shape-memory-polymers",
                    title: "Shape Memory Polymers",
                    id: 8001081,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/functional-polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/functional-polymers/shape-memory-polymers",
                    active: true,
                    meta: {
                      tags: "Shape Memory Polymers",
                      keywords: "Shape Memory Polymers",
                      description: "Shape Memory Polymers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ab",
                    },
                    slug: "polymer-chameleon",
                    title: "Polymer Chameleon",
                    id: 8001080,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/functional-polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/functional-polymers/polymer-chameleon",
                    active: true,
                    meta: {
                      tags: "Polymer Chameleon",
                      keywords: "Polymer Chameleon",
                      description: "Polymer Chameleon",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54ad",
                    },
                    slug: "super-absorbent-polymers-sap",
                    title: "Super Absorbent Polymers SAP",
                    id: 8001082,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/functional-polymers",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/functional-polymers/super-absorbent-polymers-sap",
                    active: true,
                    meta: {
                      tags: "Super Absorbent Polymers SAP",
                      keywords: "Super Absorbent Polymers SAP",
                      description: "Super Absorbent Polymers SAP",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54b0",
                },
                slug: "heat-resistant-polymers",
                title: "Heat Resistant Polymers",
                id: 8001085,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/heat-resistant-polymers",
                active: true,
                meta: {
                  tags: "Heat Resistant Polymers",
                  keywords: "Heat Resistant Polymers",
                  description: "Heat Resistant Polymers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54b2",
                },
                slug: "oxygen-barrier-materials",
                title: "Oxygen Barrier Materials",
                id: 8001087,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/oxygen-barrier-materials",
                active: true,
                meta: {
                  tags: "Oxygen Barrier Materials",
                  keywords: "Oxygen Barrier Materials",
                  description: "Oxygen Barrier Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54d5",
                },
                slug: "plastic-injection-molding",
                title: "Plastic Injection Molding",
                id: 8001122,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/plastic-injection-molding",
                active: true,
                meta: {
                  tags: "Plastic Injection Molding",
                  keywords: "Plastic Injection Molding",
                  description: "Plastic Injection Molding",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54d8",
                },
                slug: "polydimethylsiloxane-pdms",
                title: "Polydimethylsiloxane (PDMS)",
                id: 8001125,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polydimethylsiloxane-pdms",
                active: true,
                meta: {
                  tags: "Polydimethylsiloxane (PDMS)",
                  keywords: "Polydimethylsiloxane (PDMS)",
                  description: "Polydimethylsiloxane (PDMS)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54d9",
                },
                slug: "polyetheramine",
                title: "Polyetheramine",
                id: 8001126,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polyetheramine",
                active: true,
                meta: {
                  tags: "Polyetheramine",
                  keywords: "Polyetheramine",
                  description: "Polyetheramine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54dc",
                },
                slug: "polymer-foam",
                title: "Polymer Foam",
                id: 8001129,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                active: true,
                meta: {
                  tags: "Polymer Foam",
                  keywords: "Polymer Foam",
                  description: "Polymer Foam",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54e2",
                    },
                    slug: "microcellular-polyurethane-foam",
                    title: "Microcellular Polyurethane Foam",
                    id: 8001135,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/microcellular-polyurethane-foam",
                    active: true,
                    meta: {
                      tags: "Microcellular Polyurethane Foam",
                      keywords: "Microcellular Polyurethane Foam",
                      description: "Microcellular Polyurethane Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54e4",
                    },
                    slug: "polyethylene-foam",
                    title: "Polyethylene Foam",
                    id: 8001137,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/polyethylene-foam",
                    active: true,
                    meta: {
                      tags: "Polyethylene Foam",
                      keywords: "Polyethylene Foam",
                      description: "Polyethylene Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54de",
                    },
                    slug: "blowing-agents",
                    title: "Blowing Agents",
                    id: 8001131,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/blowing-agents",
                    active: true,
                    meta: {
                      tags: "Blowing Agents",
                      keywords: "Blowing Agents",
                      description: "Blowing Agents",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54e1",
                    },
                    slug: "flexible-foam",
                    title: "Flexible Foam",
                    id: 8001134,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/flexible-foam",
                    active: true,
                    meta: {
                      tags: "Flexible Foam",
                      keywords: "Flexible Foam",
                      description: "Flexible Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54dd",
                    },
                    slug: "automotive-foam",
                    title: "Automotive Foam",
                    id: 8001130,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/automotive-foam",
                    active: true,
                    meta: {
                      tags: "Automotive Foam",
                      keywords: "Automotive Foam",
                      description: "Automotive Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54df",
                    },
                    slug: "engineered-foam",
                    title: "Engineered Foam",
                    id: 8001132,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/engineered-foam",
                    active: true,
                    meta: {
                      tags: "Engineered Foam",
                      keywords: "Engineered Foam",
                      description: "Engineered Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54e3",
                    },
                    slug: "packaging-foam",
                    title: "Packaging Foam",
                    id: 8001136,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/packaging-foam",
                    active: true,
                    meta: {
                      tags: "Packaging Foam",
                      keywords: "Packaging Foam",
                      description: "Packaging Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54e5",
                    },
                    slug: "polyolefin-foam",
                    title: "Polyolefin Foam",
                    id: 8001138,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/polyolefin-foam",
                    active: true,
                    meta: {
                      tags: "Polyolefin Foam",
                      keywords: "Polyolefin Foam",
                      description: "Polyolefin Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54e0",
                    },
                    slug: "flexible-elastomeric-foam",
                    title: "Flexible Elastomeric Foam",
                    id: 8001133,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/flexible-elastomeric-foam",
                    active: true,
                    meta: {
                      tags: "Flexible Elastomeric Foam",
                      keywords: "Flexible Elastomeric Foam",
                      description: "Flexible Elastomeric Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54e6",
                    },
                    slug: "polyurethane-foam",
                    title: "Polyurethane Foam",
                    id: 8001139,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/polyurethane-foam",
                    active: true,
                    meta: {
                      tags: "Polyurethane Foam",
                      keywords: "Polyurethane Foam",
                      description: "Polyurethane Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54e7",
                    },
                    slug: "spray-polyurethane-foam",
                    title: "Spray Polyurethane Foam",
                    id: 8001140,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/polymer-foam/spray-polyurethane-foam",
                    active: true,
                    meta: {
                      tags: "Spray Polyurethane Foam",
                      keywords: "Spray Polyurethane Foam",
                      description: "Spray Polyurethane Foam",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d54fc",
                },
                slug: "shaping",
                title: "Shaping",
                id: 8001161,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/shaping",
                active: true,
                meta: {
                  tags: "Shaping",
                  keywords: "Shaping",
                  description: "Shaping",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d54fd",
                    },
                    slug: "injection-molding-machines",
                    title: "Injection Molding Machines",
                    id: 8001162,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/shaping",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/plastics-and-polymers/shaping/injection-molding-machines",
                    active: true,
                    meta: {
                      tags: "Injection Molding Machines",
                      keywords: "Injection Molding Machines",
                      description: "Injection Molding Machines",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5521",
            },
            slug: "resins",
            title: "Resins",
            id: 8001198,
            parent: "/chemicals-materials/plastics-polymers-and-elastomers",
            path: "/chemicals-materials/plastics-polymers-and-elastomers/resins",
            active: true,
            meta: {
              tags: "Resins",
              keywords: "Resins",
              description: "Resins",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d552a",
                },
                slug: "ethylene-vinyl-acetate-eva",
                title: "Ethylene Vinyl Acetate (EVA)",
                id: 8001207,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/ethylene-vinyl-acetate-eva",
                active: true,
                meta: {
                  tags: "Ethylene Vinyl Acetate (EVA)",
                  keywords: "Ethylene Vinyl Acetate (EVA)",
                  description: "Ethylene Vinyl Acetate (EVA)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d552d",
                },
                slug: "impregnating-resins",
                title: "Impregnating Resins",
                id: 8001210,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/impregnating-resins",
                active: true,
                meta: {
                  tags: "Impregnating Resins",
                  keywords: "Impregnating Resins",
                  description: "Impregnating Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5537",
                },
                slug: "polyester",
                title: "Polyester",
                id: 8001220,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/polyester",
                active: true,
                meta: {
                  tags: "Polyester",
                  keywords: "Polyester",
                  description: "Polyester",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5538",
                    },
                    slug: "saturated-polyester-resins",
                    title: "Saturated Polyester Resins",
                    id: 8001221,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/resins/polyester",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/polyester/saturated-polyester-resins",
                    active: true,
                    meta: {
                      tags: "Saturated Polyester Resins",
                      keywords: "Saturated Polyester Resins",
                      description: "Saturated Polyester Resins",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5543",
                },
                slug: "vinyl-ester",
                title: "Vinyl Ester",
                id: 8001232,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/vinyl-ester",
                active: true,
                meta: {
                  tags: "Vinyl Ester",
                  keywords: "Vinyl Ester",
                  description: "Vinyl Ester",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d552c",
                },
                slug: "hbpa-epoxy-resins",
                title: "HBPA Epoxy Resins",
                id: 8001209,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/hbpa-epoxy-resins",
                active: true,
                meta: {
                  tags: "HBPA Epoxy Resins",
                  keywords: "HBPA Epoxy Resins",
                  description: "HBPA Epoxy Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5535",
                },
                slug: "phenolic-resins",
                title: "Phenolic Resins",
                id: 8001218,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/phenolic-resins",
                active: true,
                meta: {
                  tags: "Phenolic Resins",
                  keywords: "Phenolic Resins",
                  description: "Phenolic Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d553a",
                },
                slug: "specialty-resins",
                title: "Specialty Resins",
                id: 8001223,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/specialty-resins",
                active: true,
                meta: {
                  tags: "Specialty Resins",
                  keywords: "Specialty Resins",
                  description: "Specialty Resins",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d553b",
                    },
                    slug: "amino-resins",
                    title: "Amino Resins",
                    id: 8001224,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/resins/specialty-resins",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/specialty-resins/amino-resins",
                    active: true,
                    meta: {
                      tags: "Amino Resins",
                      keywords: "Amino Resins",
                      description: "Amino Resins",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d553c",
                    },
                    slug: "cyanate-ester-resins",
                    title: "Cyanate Ester Resins",
                    id: 8001225,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/resins/specialty-resins",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/specialty-resins/cyanate-ester-resins",
                    active: true,
                    meta: {
                      tags: "Cyanate Ester Resins",
                      keywords: "Cyanate Ester Resins",
                      description: "Cyanate Ester Resins",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d553d",
                    },
                    slug: "encapsulant",
                    title: "Encapsulant",
                    id: 8001226,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/resins/specialty-resins",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/specialty-resins/encapsulant",
                    active: true,
                    meta: {
                      tags: "Encapsulant",
                      keywords: "Encapsulant",
                      description: "Encapsulant",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d553e",
                    },
                    slug: "resins-capsule",
                    title: "Resins Capsule",
                    id: 8001227,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/resins/specialty-resins",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/specialty-resins/resins-capsule",
                    active: true,
                    meta: {
                      tags: "Resins Capsule",
                      keywords: "Resins Capsule",
                      description: "Resins Capsule",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d552e",
                },
                slug: "ion-exchange-resins",
                title: "Ion Exchange Resins",
                id: 8001211,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/ion-exchange-resins",
                active: true,
                meta: {
                  tags: "Ion Exchange Resins",
                  keywords: "Ion Exchange Resins",
                  description: "Ion Exchange Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5536",
                },
                slug: "photoresist",
                title: "Photoresist",
                id: 8001219,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/photoresist",
                active: true,
                meta: {
                  tags: "Photoresist",
                  keywords: "Photoresist",
                  description: "Photoresist",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d553f",
                },
                slug: "unsaturated-polyester-resins-upr",
                title: "Unsaturated Polyester Resins (UPR)",
                id: 8001228,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/unsaturated-polyester-resins-upr",
                active: true,
                meta: {
                  tags: "Unsaturated Polyester Resins (UPR)",
                  keywords: "Unsaturated Polyester Resins (UPR)",
                  description: "Unsaturated Polyester Resins (UPR)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5540",
                },
                slug: "uv-curable-resins",
                title: "UV Curable Resins",
                id: 8001229,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/uv-curable-resins",
                active: true,
                meta: {
                  tags: "UV Curable Resins",
                  keywords: "UV Curable Resins",
                  description: "UV Curable Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5544",
                },
                slug: "waterborne-resins",
                title: "Waterborne Resins",
                id: 8001233,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/waterborne-resins",
                active: true,
                meta: {
                  tags: "Waterborne Resins",
                  keywords: "Waterborne Resins",
                  description: "Waterborne Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5524",
                },
                slug: "c9-resins",
                title: "C9 Resins",
                id: 8001201,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/c9-resins",
                active: true,
                meta: {
                  tags: "C9 Resins",
                  keywords: "C9 Resins",
                  description: "C9 Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5525",
                },
                slug: "corrosion-resistant-resins",
                title: "Corrosion Resistant Resins",
                id: 8001202,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/corrosion-resistant-resins",
                active: true,
                meta: {
                  tags: "Corrosion Resistant Resins",
                  keywords: "Corrosion Resistant Resins",
                  description: "Corrosion Resistant Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5526",
                },
                slug: "epoxy",
                title: "Epoxy",
                id: 8001203,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/epoxy",
                active: true,
                meta: {
                  tags: "Epoxy",
                  keywords: "Epoxy",
                  description: "Epoxy",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5527",
                    },
                    slug: "curing-agent",
                    title: "Curing Agent",
                    id: 8001204,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/resins/epoxy",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/epoxy/curing-agent",
                    active: true,
                    meta: {
                      tags: "Curing Agent",
                      keywords: "Curing Agent",
                      description: "Curing Agent",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5528",
                        },
                        slug: "epoxy-curing-agent",
                        title: "Epoxy Curing Agent",
                        id: 8001205,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/resins/epoxy/curing-agent",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/epoxy/curing-agent/epoxy-curing-agent",
                        active: true,
                        meta: {
                          tags: "Epoxy Curing Agent",
                          keywords: "Epoxy Curing Agent",
                          description: "Epoxy Curing Agent",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5531",
                },
                slug: "novolac-resins",
                title: "Novolac Resins",
                id: 8001214,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/novolac-resins",
                active: true,
                meta: {
                  tags: "Novolac Resins",
                  keywords: "Novolac Resins",
                  description: "Novolac Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5534",
                },
                slug: "packaging-resins",
                title: "Packaging Resins",
                id: 8001217,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/packaging-resins",
                active: true,
                meta: {
                  tags: "Packaging Resins",
                  keywords: "Packaging Resins",
                  description: "Packaging Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5541",
                },
                slug: "vinyl-acetate",
                title: "Vinyl Acetate",
                id: 8001230,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/vinyl-acetate",
                active: true,
                meta: {
                  tags: "Vinyl Acetate",
                  keywords: "Vinyl Acetate",
                  description: "Vinyl Acetate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d552f",
                },
                slug: "melamine",
                title: "Melamine",
                id: 8001212,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/melamine",
                active: true,
                meta: {
                  tags: "Melamine",
                  keywords: "Melamine",
                  description: "Melamine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5532",
                },
                slug: "nylon",
                title: "Nylon",
                id: 8001215,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/nylon",
                active: true,
                meta: {
                  tags: "Nylon",
                  keywords: "Nylon",
                  description: "Nylon",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5533",
                    },
                    slug: "nylon-resins",
                    title: "Nylon Resins",
                    id: 8001216,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/resins/nylon",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/nylon/nylon-resins",
                    active: true,
                    meta: {
                      tags: "Nylon Resins",
                      keywords: "Nylon Resins",
                      description: "Nylon Resins",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5522",
                },
                slug: "acrylic-emulsions",
                title: "Acrylic Emulsions",
                id: 8001199,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/acrylic-emulsions",
                active: true,
                meta: {
                  tags: "Acrylic Emulsions",
                  keywords: "Acrylic Emulsions",
                  description: "Acrylic Emulsions",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5542",
                },
                slug: "vinyl-acetate-ethylene-vae",
                title: "Vinyl Acetate Ethylene (VAE)",
                id: 8001231,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/vinyl-acetate-ethylene-vae",
                active: true,
                meta: {
                  tags: "Vinyl Acetate Ethylene (VAE)",
                  keywords: "Vinyl Acetate Ethylene (VAE)",
                  description: "Vinyl Acetate Ethylene (VAE)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5529",
                },
                slug: "epoxy-resins",
                title: "Epoxy Resins",
                id: 8001206,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/epoxy-resins",
                active: true,
                meta: {
                  tags: "Epoxy Resins",
                  keywords: "Epoxy Resins",
                  description: "Epoxy Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5530",
                },
                slug: "melamine-formaldehyde",
                title: "Melamine Formaldehyde",
                id: 8001213,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/melamine-formaldehyde",
                active: true,
                meta: {
                  tags: "Melamine Formaldehyde",
                  keywords: "Melamine Formaldehyde",
                  description: "Melamine Formaldehyde",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5539",
                },
                slug: "polyvinyl-butyral-pvb",
                title: "Polyvinyl Butyral (PVB)",
                id: 8001222,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/polyvinyl-butyral-pvb",
                active: true,
                meta: {
                  tags: "Polyvinyl Butyral (PVB)",
                  keywords: "Polyvinyl Butyral (PVB)",
                  description: "Polyvinyl Butyral (PVB)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5523",
                },
                slug: "butadiene",
                title: "Butadiene",
                id: 8001200,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/butadiene",
                active: true,
                meta: {
                  tags: "Butadiene",
                  keywords: "Butadiene",
                  description: "Butadiene",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d552b",
                },
                slug: "flexible-epoxy-resins",
                title: "Flexible Epoxy Resins",
                id: 8001208,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/resins",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/resins/flexible-epoxy-resins",
                active: true,
                meta: {
                  tags: "Flexible Epoxy Resins",
                  keywords: "Flexible Epoxy Resins",
                  description: "Flexible Epoxy Resins",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5545",
            },
            slug: "silicone",
            title: "Silicone",
            id: 8001234,
            parent: "/chemicals-materials/plastics-polymers-and-elastomers",
            path: "/chemicals-materials/plastics-polymers-and-elastomers/silicone",
            active: true,
            meta: {
              tags: "Silicone",
              keywords: "Silicone",
              description: "Silicone",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5546",
                },
                slug: "conductive-silicone",
                title: "Conductive Silicone",
                id: 8001235,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/silicone",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/silicone/conductive-silicone",
                active: true,
                meta: {
                  tags: "Conductive Silicone",
                  keywords: "Conductive Silicone",
                  description: "Conductive Silicone",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d554a",
                },
                slug: "silicone-fluids",
                title: "Silicone Fluids",
                id: 8001239,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/silicone",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/silicone/silicone-fluids",
                active: true,
                meta: {
                  tags: "Silicone Fluids",
                  keywords: "Silicone Fluids",
                  description: "Silicone Fluids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5547",
                },
                slug: "fluorosilicone",
                title: "Fluorosilicone",
                id: 8001236,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/silicone",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/silicone/fluorosilicone",
                active: true,
                meta: {
                  tags: "Fluorosilicone",
                  keywords: "Fluorosilicone",
                  description: "Fluorosilicone",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d554b",
                },
                slug: "specialty-silicone",
                title: "Specialty Silicone",
                id: 8001240,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/silicone",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/silicone/specialty-silicone",
                active: true,
                meta: {
                  tags: "Specialty Silicone",
                  keywords: "Specialty Silicone",
                  description: "Specialty Silicone",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5548",
                },
                slug: "silicone-additives",
                title: "Silicone Additives",
                id: 8001237,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/silicone",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/silicone/silicone-additives",
                active: true,
                meta: {
                  tags: "Silicone Additives",
                  keywords: "Silicone Additives",
                  description: "Silicone Additives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5549",
                },
                slug: "silicone-films",
                title: "Silicone Films",
                id: 8001238,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/silicone",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/silicone/silicone-films",
                active: true,
                meta: {
                  tags: "Silicone Films",
                  keywords: "Silicone Films",
                  description: "Silicone Films",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d554c",
            },
            slug: "traditional-plastics",
            title: "Traditional Plastics",
            id: 8001241,
            parent: "/chemicals-materials/plastics-polymers-and-elastomers",
            path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics",
            active: true,
            meta: {
              tags: "Traditional Plastics",
              keywords: "Traditional Plastics",
              description: "Traditional Plastics",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d555a",
                },
                slug: "polystyrene-ps",
                title: "Polystyrene (PS)",
                id: 8001255,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polystyrene-ps",
                active: true,
                meta: {
                  tags: "Polystyrene (PS)",
                  keywords: "Polystyrene (PS)",
                  description: "Polystyrene (PS)",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d555b",
                    },
                    slug: "expandable-polystyrene-eps",
                    title: "Expandable Polystyrene (EPS)",
                    id: 8001256,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polystyrene-ps",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polystyrene-ps/expandable-polystyrene-eps",
                    active: true,
                    meta: {
                      tags: "Expandable Polystyrene (EPS)",
                      keywords: "Expandable Polystyrene (EPS)",
                      description: "Expandable Polystyrene (EPS)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d555c",
                    },
                    slug: "expanded-polystyrene",
                    title: "Expanded Polystyrene",
                    id: 8001257,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polystyrene-ps",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polystyrene-ps/expanded-polystyrene",
                    active: true,
                    meta: {
                      tags: "Expanded Polystyrene",
                      keywords: "Expanded Polystyrene",
                      description: "Expanded Polystyrene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d555d",
                    },
                    slug: "extruded-polystyrene-foam",
                    title: "Extruded Polystyrene Foam",
                    id: 8001258,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polystyrene-ps",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polystyrene-ps/extruded-polystyrene-foam",
                    active: true,
                    meta: {
                      tags: "Extruded Polystyrene Foam",
                      keywords: "Extruded Polystyrene Foam",
                      description: "Extruded Polystyrene Foam",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d555e",
                },
                slug: "polyvinyl-chloride-pvc",
                title: "Polyvinyl Chloride (PVC)",
                id: 8001259,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyvinyl-chloride-pvc",
                active: true,
                meta: {
                  tags: "Polyvinyl Chloride (PVC)",
                  keywords: "Polyvinyl Chloride (PVC)",
                  description: "Polyvinyl Chloride (PVC)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5553",
                },
                slug: "polypropylene-pp",
                title: "Polypropylene (PP)",
                id: 8001248,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp",
                active: true,
                meta: {
                  tags: "Polypropylene (PP)",
                  keywords: "Polypropylene (PP)",
                  description: "Polypropylene (PP)",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5554",
                    },
                    slug: "expanded-polypropylene-epp-foam",
                    title: "Expanded Polypropylene (EPP) Foam",
                    id: 8001249,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp/expanded-polypropylene-epp-foam",
                    active: true,
                    meta: {
                      tags: "Expanded Polypropylene (EPP) Foam",
                      keywords: "Expanded Polypropylene (EPP) Foam",
                      description: "Expanded Polypropylene (EPP) Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5555",
                    },
                    slug: "extruded-polypropylene-epp-foam",
                    title: "Extruded Polypropylene (EPP) Foam",
                    id: 8001250,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp/extruded-polypropylene-epp-foam",
                    active: true,
                    meta: {
                      tags: "Extruded Polypropylene (EPP) Foam",
                      keywords: "Extruded Polypropylene (EPP) Foam",
                      description: "Extruded Polypropylene (EPP) Foam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5556",
                    },
                    slug: "low-smoke-halogen-free-flame-retardant-polypropylene",
                    title:
                      "Low Smoke Halogen Free Flame Retardant Polypropylene",
                    id: 8001251,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp/low-smoke-halogen-free-flame-retardant-polypropylene",
                    active: true,
                    meta: {
                      tags: "Low Smoke Halogen Free Flame Retardant Polypropylene",
                      keywords:
                        "Low Smoke Halogen Free Flame Retardant Polypropylene",
                      description:
                        "Low Smoke Halogen Free Flame Retardant Polypropylene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5557",
                    },
                    slug: "polypropylene-compound",
                    title: "Polypropylene Compound",
                    id: 8001252,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp/polypropylene-compound",
                    active: true,
                    meta: {
                      tags: "Polypropylene Compound",
                      keywords: "Polypropylene Compound",
                      description: "Polypropylene Compound",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5558",
                    },
                    slug: "polystyrene",
                    title: "Polystyrene",
                    id: 8001253,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp/polystyrene",
                    active: true,
                    meta: {
                      tags: "Polystyrene",
                      keywords: "Polystyrene",
                      description: "Polystyrene",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5559",
                        },
                        slug: "polystyrene-foam",
                        title: "Polystyrene Foam",
                        id: 8001254,
                        parent:
                          "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp/polystyrene",
                        path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polypropylene-pp/polystyrene/polystyrene-foam",
                        active: true,
                        meta: {
                          tags: "Polystyrene Foam",
                          keywords: "Polystyrene Foam",
                          description: "Polystyrene Foam",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d554d",
                },
                slug: "polyethylene-pe",
                title: "Polyethylene (PE)",
                id: 8001242,
                parent:
                  "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics",
                path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe",
                active: true,
                meta: {
                  tags: "Polyethylene (PE)",
                  keywords: "Polyethylene (PE)",
                  description: "Polyethylene (PE)",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5551",
                    },
                    slug: "low-density-polyethylene-ldpe",
                    title: "Low Density Polyethylene (LDPE)",
                    id: 8001246,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe/low-density-polyethylene-ldpe",
                    active: true,
                    meta: {
                      tags: "Low Density Polyethylene (LDPE)",
                      keywords: "Low Density Polyethylene (LDPE)",
                      description: "Low Density Polyethylene (LDPE)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d554f",
                    },
                    slug: "high-density-polyethylene-hdpe",
                    title: "High Density Polyethylene (HDPE)",
                    id: 8001244,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe/high-density-polyethylene-hdpe",
                    active: true,
                    meta: {
                      tags: "High Density Polyethylene (HDPE)",
                      keywords: "High Density Polyethylene (HDPE)",
                      description: "High Density Polyethylene (HDPE)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5552",
                    },
                    slug: "metallocene-polyethylene-mpe",
                    title: "Metallocene Polyethylene (mPE)",
                    id: 8001247,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe/metallocene-polyethylene-mpe",
                    active: true,
                    meta: {
                      tags: "Metallocene Polyethylene (mPE)",
                      keywords: "Metallocene Polyethylene (mPE)",
                      description: "Metallocene Polyethylene (mPE)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d554e",
                    },
                    slug: "cross-linked-polyethylene-xlpe",
                    title: "Cross-Linked Polyethylene (XLPE)",
                    id: 8001243,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe/cross-linked-polyethylene-xlpe",
                    active: true,
                    meta: {
                      tags: "Cross-Linked Polyethylene (XLPE)",
                      keywords: "Cross-Linked Polyethylene (XLPE)",
                      description: "Cross-Linked Polyethylene (XLPE)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5550",
                    },
                    slug: "linear-low-density-polyethylene-lldpe",
                    title: "Linear Low Density Polyethylene (LLDPE)",
                    id: 8001245,
                    parent:
                      "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe",
                    path: "/chemicals-materials/plastics-polymers-and-elastomers/traditional-plastics/polyethylene-pe/linear-low-density-polyethylene-lldpe",
                    active: true,
                    meta: {
                      tags: "Linear Low Density Polyethylene (LLDPE)",
                      keywords: "Linear Low Density Polyethylene (LLDPE)",
                      description: "Linear Low Density Polyethylene (LLDPE)",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b184",
        },
        id: 6142,
        title: "Adhesives and Sealants",
        slug: "adhesives-and-sealants",
        active: true,
        meta: {
          description:
            "The Adhesives & Sealants Industry provides content on different types of adhesives, including pressure-sensitive and structural adhesives, as well as sealants. Major users of adhesives are the packaging, automotive, construction, and furniture industries. Sealants are used primarily by the construction, electronics, and automotive industries.",
          keywords:
            "Adhesives & Sealants Market Research Reports, Acrylic Adhesives Market, Electronics Adhesives Market, Epoxy Adhesives Market, Construction Adhesives Market, Contact Adhesives Market, Cyanoacrylate Adhesives Market, Hot Melt Adhesives Market, Pressure Sensitive Adhesives Market, reactive Adhesives Market, Waterborne Adhesives Market, Polyurethane Adhesives Market, Sealants Market",
          tags: "Adhesives & Sealants Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Adhesives & Sealants Industry provides content on different types of adhesives, including pressure-sensitive and structural adhesives, as well as sealants. Major users of adhesives are the packaging, automotive, construction and furniture industries. Sealants are used primarily by the construction, electronics and automotive industries.",
          short_description:
            "Adhesives and sealants have a wide range of application areas in the automotive, construction, electronics, and packaging sectors. This section covers different types of adhesives & sealants segmented by type and application area. Browse our industry reports on adhesives & sealants below.",
        },
        picture: null,
        __v: 0,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/adhesives-and-sealants",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5083",
            },
            slug: "adhesives",
            title: "Adhesives",
            id: 8000010,
            parent: "/chemicals-materials/adhesives-and-sealants",
            path: "/chemicals-materials/adhesives-and-sealants/adhesives",
            active: true,
            meta: {
              tags: "Adhesives",
              keywords: "Adhesives",
              description: "Adhesives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5084",
                },
                slug: "acrylic-adhesives",
                title: "Acrylic Adhesives",
                id: 8000011,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/acrylic-adhesives",
                active: true,
                meta: {
                  tags: "Acrylic Adhesives",
                  keywords: "Acrylic Adhesives",
                  description: "Acrylic Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d508c",
                },
                slug: "electrically-conductive-adhesives",
                title: "Electrically Conductive Adhesives",
                id: 8000019,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/electrically-conductive-adhesives",
                active: true,
                meta: {
                  tags: "Electrically Conductive Adhesives",
                  keywords: "Electrically Conductive Adhesives",
                  description: "Electrically Conductive Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5096",
                },
                slug: "laminating-adhesives",
                title: "Laminating Adhesives",
                id: 8000029,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/laminating-adhesives",
                active: true,
                meta: {
                  tags: "Laminating Adhesives",
                  keywords: "Laminating Adhesives",
                  description: "Laminating Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50a0",
                },
                slug: "reactive-adhesives",
                title: "Reactive Adhesives",
                id: 8000039,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/reactive-adhesives",
                active: true,
                meta: {
                  tags: "Reactive Adhesives",
                  keywords: "Reactive Adhesives",
                  description: "Reactive Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50b6",
                },
                slug: "water-borne-adhesives",
                title: "Water borne Adhesives",
                id: 8000061,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/water-borne-adhesives",
                active: true,
                meta: {
                  tags: "Water borne Adhesives",
                  keywords: "Water borne Adhesives",
                  description: "Water borne Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d508d",
                },
                slug: "electronics-adhesives",
                title: "Electronics Adhesives",
                id: 8000020,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/electronics-adhesives",
                active: true,
                meta: {
                  tags: "Electronics Adhesives",
                  keywords: "Electronics Adhesives",
                  description: "Electronics Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d509a",
                },
                slug: "polyurethane-pu-adhesives",
                title: "Polyurethane (PU) Adhesives",
                id: 8000033,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/polyurethane-pu-adhesives",
                active: true,
                meta: {
                  tags: "Polyurethane (PU) Adhesives",
                  keywords: "Polyurethane (PU) Adhesives",
                  description: "Polyurethane (PU) Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50a3",
                },
                slug: "specialty-adhesives",
                title: "Specialty Adhesives",
                id: 8000042,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                active: true,
                meta: {
                  tags: "Specialty Adhesives",
                  keywords: "Specialty Adhesives",
                  description: "Specialty Adhesives",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50a8",
                    },
                    slug: "engineering-adhesives",
                    title: "Engineering Adhesives",
                    id: 8000047,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/engineering-adhesives",
                    active: true,
                    meta: {
                      tags: "Engineering Adhesives",
                      keywords: "Engineering Adhesives",
                      description: "Engineering Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50aa",
                    },
                    slug: "high-performance-adhesives",
                    title: "High Performance Adhesives",
                    id: 8000049,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/high-performance-adhesives",
                    active: true,
                    meta: {
                      tags: "High Performance Adhesives",
                      keywords: "High Performance Adhesives",
                      description: "High Performance Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ab",
                    },
                    slug: "high-strength-laminated-adhesives",
                    title: "High Strength Laminated Adhesives",
                    id: 8000050,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/high-strength-laminated-adhesives",
                    active: true,
                    meta: {
                      tags: "High Strength Laminated Adhesives",
                      keywords: "High Strength Laminated Adhesives",
                      description: "High Strength Laminated Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50b1",
                    },
                    slug: "wood-adhesives",
                    title: "Wood Adhesives",
                    id: 8000056,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/wood-adhesives",
                    active: true,
                    meta: {
                      tags: "Wood Adhesives",
                      keywords: "Wood Adhesives",
                      description: "Wood Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50a5",
                    },
                    slug: "bio-based-adhesives",
                    title: "Bio-based Adhesives",
                    id: 8000044,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/bio-based-adhesives",
                    active: true,
                    meta: {
                      tags: "Bio-based Adhesives",
                      keywords: "Bio-based Adhesives",
                      description: "Bio-based Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50a7",
                    },
                    slug: "elastic-bonding-adhesives",
                    title: "Elastic Bonding Adhesives",
                    id: 8000046,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/elastic-bonding-adhesives",
                    active: true,
                    meta: {
                      tags: "Elastic Bonding Adhesives",
                      keywords: "Elastic Bonding Adhesives",
                      description: "Elastic Bonding Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ac",
                    },
                    slug: "hygiene-adhesives",
                    title: "Hygiene Adhesives",
                    id: 8000051,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/hygiene-adhesives",
                    active: true,
                    meta: {
                      tags: "Hygiene Adhesives",
                      keywords: "Hygiene Adhesives",
                      description: "Hygiene Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ad",
                    },
                    slug: "instant-adhesives",
                    title: "Instant Adhesives",
                    id: 8000052,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/instant-adhesives",
                    active: true,
                    meta: {
                      tags: "Instant Adhesives",
                      keywords: "Instant Adhesives",
                      description: "Instant Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50a6",
                    },
                    slug: "composite-adhesives",
                    title: "Composite Adhesives",
                    id: 8000045,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/composite-adhesives",
                    active: true,
                    meta: {
                      tags: "Composite Adhesives",
                      keywords: "Composite Adhesives",
                      description: "Composite Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ae",
                    },
                    slug: "low-voc-adhesives",
                    title: "Low VOC Adhesives",
                    id: 8000053,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/low-voc-adhesives",
                    active: true,
                    meta: {
                      tags: "Low VOC Adhesives",
                      keywords: "Low VOC Adhesives",
                      description: "Low VOC Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50a9",
                    },
                    slug: "flock-adhesives",
                    title: "Flock Adhesives",
                    id: 8000048,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/flock-adhesives",
                    active: true,
                    meta: {
                      tags: "Flock Adhesives",
                      keywords: "Flock Adhesives",
                      description: "Flock Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50a4",
                    },
                    slug: "adhesives-films",
                    title: "Adhesives Films",
                    id: 8000043,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/adhesives-films",
                    active: true,
                    meta: {
                      tags: "Adhesives Films",
                      keywords: "Adhesives Films",
                      description: "Adhesives Films",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50af",
                    },
                    slug: "metal-bonding-adhesives",
                    title: "Metal Bonding Adhesives",
                    id: 8000054,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/metal-bonding-adhesives",
                    active: true,
                    meta: {
                      tags: "Metal Bonding Adhesives",
                      keywords: "Metal Bonding Adhesives",
                      description: "Metal Bonding Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50b0",
                    },
                    slug: "moisture-curing-adhesives",
                    title: "Moisture Curing Adhesives",
                    id: 8000055,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/specialty-adhesives/moisture-curing-adhesives",
                    active: true,
                    meta: {
                      tags: "Moisture Curing Adhesives",
                      keywords: "Moisture Curing Adhesives",
                      description: "Moisture Curing Adhesives",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5090",
                },
                slug: "eva-adhesives",
                title: "EVA Adhesives",
                id: 8000023,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/eva-adhesives",
                active: true,
                meta: {
                  tags: "EVA Adhesives",
                  keywords: "EVA Adhesives",
                  description: "EVA Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5097",
                },
                slug: "methyl-methacrylate-mma-adhesives",
                title: "Methyl Methacrylate (MMA) Adhesives",
                id: 8000030,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/methyl-methacrylate-mma-adhesives",
                active: true,
                meta: {
                  tags: "Methyl Methacrylate (MMA) Adhesives",
                  keywords: "Methyl Methacrylate (MMA) Adhesives",
                  description: "Methyl Methacrylate (MMA) Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5098",
                },
                slug: "packaging-adhesives",
                title: "Packaging Adhesives",
                id: 8000031,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/packaging-adhesives",
                active: true,
                meta: {
                  tags: "Packaging Adhesives",
                  keywords: "Packaging Adhesives",
                  description: "Packaging Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50a2",
                },
                slug: "solventborne-adhesives",
                title: "Solventborne Adhesives",
                id: 8000041,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/solventborne-adhesives",
                active: true,
                meta: {
                  tags: "Solventborne Adhesives",
                  keywords: "Solventborne Adhesives",
                  description: "Solventborne Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50a1",
                },
                slug: "self-adhesive-labels",
                title: "Self Adhesive Labels",
                id: 8000040,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/self-adhesive-labels",
                active: true,
                meta: {
                  tags: "Self Adhesive Labels",
                  keywords: "Self Adhesive Labels",
                  description: "Self Adhesive Labels",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5085",
                },
                slug: "aerospace-adhesives",
                title: "Aerospace Adhesives",
                id: 8000012,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/aerospace-adhesives",
                active: true,
                meta: {
                  tags: "Aerospace Adhesives",
                  keywords: "Aerospace Adhesives",
                  description: "Aerospace Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d508b",
                },
                slug: "cyanoacrylate-adhesives",
                title: "Cyanoacrylate Adhesives",
                id: 8000018,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/cyanoacrylate-adhesives",
                active: true,
                meta: {
                  tags: "Cyanoacrylate Adhesives",
                  keywords: "Cyanoacrylate Adhesives",
                  description: "Cyanoacrylate Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d508a",
                },
                slug: "contact-adhesives",
                title: "Contact Adhesives",
                id: 8000017,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/contact-adhesives",
                active: true,
                meta: {
                  tags: "Contact Adhesives",
                  keywords: "Contact Adhesives",
                  description: "Contact Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5094",
                },
                slug: "hot-melt-adhesives",
                title: "Hot Melt Adhesives",
                id: 8000027,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/hot-melt-adhesives",
                active: true,
                meta: {
                  tags: "Hot Melt Adhesives",
                  keywords: "Hot Melt Adhesives",
                  description: "Hot Melt Adhesives",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5095",
                    },
                    slug: "polyurethane-pu-hot-melt-adhesives",
                    title: "Polyurethane (PU) Hot melt Adhesives",
                    id: 8000028,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/hot-melt-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/hot-melt-adhesives/polyurethane-pu-hot-melt-adhesives",
                    active: true,
                    meta: {
                      tags: "Polyurethane (PU) Hot melt Adhesives",
                      keywords: "Polyurethane (PU) Hot melt Adhesives",
                      description: "Polyurethane (PU) Hot melt Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5093",
                    },
                    slug: "polyester-hot-melt-adhesives",
                    title: "Polyester Hot Melt Adhesives",
                    id: 8000026,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/hot-melt-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/hot-melt-adhesives/polyester-hot-melt-adhesives",
                    active: true,
                    meta: {
                      tags: "Polyester Hot Melt Adhesives",
                      keywords: "Polyester Hot Melt Adhesives",
                      description: "Polyester Hot Melt Adhesives",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5099",
                },
                slug: "plastic-adhesives",
                title: "Plastic Adhesives",
                id: 8000032,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/plastic-adhesives",
                active: true,
                meta: {
                  tags: "Plastic Adhesives",
                  keywords: "Plastic Adhesives",
                  description: "Plastic Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50b3",
                },
                slug: "structural-adhesives",
                title: "Structural Adhesives",
                id: 8000058,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/structural-adhesives",
                active: true,
                meta: {
                  tags: "Structural Adhesives",
                  keywords: "Structural Adhesives",
                  description: "Structural Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50b4",
                },
                slug: "tackifiers",
                title: "Tackifiers",
                id: 8000059,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/tackifiers",
                active: true,
                meta: {
                  tags: "Tackifiers",
                  keywords: "Tackifiers",
                  description: "Tackifiers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50b5",
                },
                slug: "uv-curable-adhesives",
                title: "UV Curable Adhesives",
                id: 8000060,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/uv-curable-adhesives",
                active: true,
                meta: {
                  tags: "UV Curable Adhesives",
                  keywords: "UV Curable Adhesives",
                  description: "UV Curable Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d508f",
                },
                slug: "epoxy-adhesives",
                title: "Epoxy Adhesives",
                id: 8000022,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/epoxy-adhesives",
                active: true,
                meta: {
                  tags: "Epoxy Adhesives",
                  keywords: "Epoxy Adhesives",
                  description: "Epoxy Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50b2",
                },
                slug: "spray-adhesives",
                title: "Spray Adhesives",
                id: 8000057,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/spray-adhesives",
                active: true,
                meta: {
                  tags: "Spray Adhesives",
                  keywords: "Spray Adhesives",
                  description: "Spray Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5086",
                },
                slug: "anaerobic-adhesives",
                title: "Anaerobic Adhesives",
                id: 8000013,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/anaerobic-adhesives",
                active: true,
                meta: {
                  tags: "Anaerobic Adhesives",
                  keywords: "Anaerobic Adhesives",
                  description: "Anaerobic Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5087",
                },
                slug: "construction-adhesives",
                title: "Construction Adhesives",
                id: 8000014,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/construction-adhesives",
                active: true,
                meta: {
                  tags: "Construction Adhesives",
                  keywords: "Construction Adhesives",
                  description: "Construction Adhesives",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5088",
                    },
                    slug: "floor-adhesives",
                    title: "Floor Adhesives",
                    id: 8000015,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/construction-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/construction-adhesives/floor-adhesives",
                    active: true,
                    meta: {
                      tags: "Floor Adhesives",
                      keywords: "Floor Adhesives",
                      description: "Floor Adhesives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5089",
                    },
                    slug: "roofing-adhesives",
                    title: "Roofing Adhesives",
                    id: 8000016,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/construction-adhesives",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/construction-adhesives/roofing-adhesives",
                    active: true,
                    meta: {
                      tags: "Roofing Adhesives",
                      keywords: "Roofing Adhesives",
                      description: "Roofing Adhesives",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d508e",
                },
                slug: "emulsion-adhesives",
                title: "Emulsion Adhesives",
                id: 8000021,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/emulsion-adhesives",
                active: true,
                meta: {
                  tags: "Emulsion Adhesives",
                  keywords: "Emulsion Adhesives",
                  description: "Emulsion Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5091",
                },
                slug: "glass-bonding-adhesives",
                title: "Glass Bonding Adhesives",
                id: 8000024,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/glass-bonding-adhesives",
                active: true,
                meta: {
                  tags: "Glass Bonding Adhesives",
                  keywords: "Glass Bonding Adhesives",
                  description: "Glass Bonding Adhesives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d509b",
                },
                slug: "pressure-sensitive-adhesives-psa",
                title: "Pressure Sensitive Adhesives (PSA)",
                id: 8000034,
                parent: "/chemicals-materials/adhesives-and-sealants/adhesives",
                path: "/chemicals-materials/adhesives-and-sealants/adhesives/pressure-sensitive-adhesives-psa",
                active: true,
                meta: {
                  tags: "Pressure Sensitive Adhesives (PSA)",
                  keywords: "Pressure Sensitive Adhesives (PSA)",
                  description: "Pressure Sensitive Adhesives (PSA)",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d509c",
                    },
                    slug: "adhesives-tapes",
                    title: "Adhesives Tapes",
                    id: 8000035,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/adhesives/pressure-sensitive-adhesives-psa",
                    path: "/chemicals-materials/adhesives-and-sealants/adhesives/pressure-sensitive-adhesives-psa/adhesives-tapes",
                    active: true,
                    meta: {
                      tags: "Adhesives Tapes",
                      keywords: "Adhesives Tapes",
                      description: "Adhesives Tapes",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d509f",
                        },
                        slug: "release-liners",
                        title: "Release Liners",
                        id: 8000038,
                        parent:
                          "/chemicals-materials/adhesives-and-sealants/adhesives/pressure-sensitive-adhesives-psa/adhesives-tapes",
                        path: "/chemicals-materials/adhesives-and-sealants/adhesives/pressure-sensitive-adhesives-psa/adhesives-tapes/release-liners",
                        active: true,
                        meta: {
                          tags: "Release Liners",
                          keywords: "Release Liners",
                          description: "Release Liners",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d509d",
                        },
                        slug: "automotive-adhesives",
                        title: "Automotive Adhesives",
                        id: 8000036,
                        parent:
                          "/chemicals-materials/adhesives-and-sealants/adhesives/pressure-sensitive-adhesives-psa/adhesives-tapes",
                        path: "/chemicals-materials/adhesives-and-sealants/adhesives/pressure-sensitive-adhesives-psa/adhesives-tapes/automotive-adhesives",
                        active: true,
                        meta: {
                          tags: "Automotive Adhesives",
                          keywords: "Automotive Adhesives",
                          description: "Automotive Adhesives",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d509e",
                        },
                        slug: "packaging-tapes",
                        title: "Packaging Tapes",
                        id: 8000037,
                        parent:
                          "/chemicals-materials/adhesives-and-sealants/adhesives/pressure-sensitive-adhesives-psa/adhesives-tapes",
                        path: "/chemicals-materials/adhesives-and-sealants/adhesives/pressure-sensitive-adhesives-psa/adhesives-tapes/packaging-tapes",
                        active: true,
                        meta: {
                          tags: "Packaging Tapes",
                          keywords: "Packaging Tapes",
                          description: "Packaging Tapes",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50b9",
            },
            slug: "construction-adhesives-and-sealants",
            title: "Construction Adhesives and Sealants",
            id: 8000064,
            parent: "/chemicals-materials/adhesives-and-sealants",
            path: "/chemicals-materials/adhesives-and-sealants/construction-adhesives-and-sealants",
            active: true,
            meta: {
              tags: "Construction Adhesives and Sealants",
              keywords: "Construction Adhesives and Sealants",
              description: "Construction Adhesives and Sealants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50ba",
                },
                slug: "stone-and-tile-adhesives-and-sealants",
                title: "Stone and Tile Adhesives and Sealants",
                id: 8000065,
                parent:
                  "/chemicals-materials/adhesives-and-sealants/construction-adhesives-and-sealants",
                path: "/chemicals-materials/adhesives-and-sealants/construction-adhesives-and-sealants/stone-and-tile-adhesives-and-sealants",
                active: true,
                meta: {
                  tags: "Stone and Tile Adhesives and Sealants",
                  keywords: "Stone and Tile Adhesives and Sealants",
                  description: "Stone and Tile Adhesives and Sealants",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50bc",
            },
            slug: "sealants",
            title: "Sealants",
            id: 8000067,
            parent: "/chemicals-materials/adhesives-and-sealants",
            path: "/chemicals-materials/adhesives-and-sealants/sealants",
            active: true,
            meta: {
              tags: "Sealants",
              keywords: "Sealants",
              description: "Sealants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50bd",
                },
                slug: "silicone-sealants",
                title: "Silicone Sealants",
                id: 8000068,
                parent: "/chemicals-materials/adhesives-and-sealants/sealants",
                path: "/chemicals-materials/adhesives-and-sealants/sealants/silicone-sealants",
                active: true,
                meta: {
                  tags: "Silicone Sealants",
                  keywords: "Silicone Sealants",
                  description: "Silicone Sealants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50be",
                },
                slug: "specialty-sealants",
                title: "Specialty Sealants",
                id: 8000069,
                parent: "/chemicals-materials/adhesives-and-sealants/sealants",
                path: "/chemicals-materials/adhesives-and-sealants/sealants/specialty-sealants",
                active: true,
                meta: {
                  tags: "Specialty Sealants",
                  keywords: "Specialty Sealants",
                  description: "Specialty Sealants",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50bf",
                    },
                    slug: "high-temperature-sealants",
                    title: "High Temperature Sealants",
                    id: 8000070,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/sealants/specialty-sealants",
                    path: "/chemicals-materials/adhesives-and-sealants/sealants/specialty-sealants/high-temperature-sealants",
                    active: true,
                    meta: {
                      tags: "High Temperature Sealants",
                      keywords: "High Temperature Sealants",
                      description: "High Temperature Sealants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50c0",
                    },
                    slug: "potting-compound",
                    title: "Potting Compound",
                    id: 8000071,
                    parent:
                      "/chemicals-materials/adhesives-and-sealants/sealants/specialty-sealants",
                    path: "/chemicals-materials/adhesives-and-sealants/sealants/specialty-sealants/potting-compound",
                    active: true,
                    meta: {
                      tags: "Potting Compound",
                      keywords: "Potting Compound",
                      description: "Potting Compound",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50bb",
            },
            slug: "polyurethane-adhesives-and-sealants",
            title: "Polyurethane Adhesives and Sealants",
            id: 8000066,
            parent: "/chemicals-materials/adhesives-and-sealants",
            path: "/chemicals-materials/adhesives-and-sealants/polyurethane-adhesives-and-sealants",
            active: true,
            meta: {
              tags: "Polyurethane Adhesives and Sealants",
              keywords: "Polyurethane Adhesives and Sealants",
              description: "Polyurethane Adhesives and Sealants",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50c2",
            },
            slug: "specialty-adhesives-and-sealants",
            title: "Specialty Adhesives and Sealants",
            id: 8000073,
            parent: "/chemicals-materials/adhesives-and-sealants",
            path: "/chemicals-materials/adhesives-and-sealants/specialty-adhesives-and-sealants",
            active: true,
            meta: {
              tags: "Specialty Adhesives and Sealants",
              keywords: "Specialty Adhesives and Sealants",
              description: "Specialty Adhesives and Sealants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50c3",
                },
                slug: "dental-adhesives-and-sealants",
                title: "Dental Adhesives and Sealants",
                id: 8000074,
                parent:
                  "/chemicals-materials/adhesives-and-sealants/specialty-adhesives-and-sealants",
                path: "/chemicals-materials/adhesives-and-sealants/specialty-adhesives-and-sealants/dental-adhesives-and-sealants",
                active: true,
                meta: {
                  tags: "Dental Adhesives and Sealants",
                  keywords: "Dental Adhesives and Sealants",
                  description: "Dental Adhesives and Sealants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50c4",
                },
                slug: "high-temperature-adhesives-and-sealants",
                title: "High Temperature Adhesives and Sealants",
                id: 8000075,
                parent:
                  "/chemicals-materials/adhesives-and-sealants/specialty-adhesives-and-sealants",
                path: "/chemicals-materials/adhesives-and-sealants/specialty-adhesives-and-sealants/high-temperature-adhesives-and-sealants",
                active: true,
                meta: {
                  tags: "High Temperature Adhesives and Sealants",
                  keywords: "High Temperature Adhesives and Sealants",
                  description: "High Temperature Adhesives and Sealants",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50b7",
            },
            slug: "aerospace-adhesives-and-sealants",
            title: "Aerospace Adhesives and Sealants",
            id: 8000062,
            parent: "/chemicals-materials/adhesives-and-sealants",
            path: "/chemicals-materials/adhesives-and-sealants/aerospace-adhesives-and-sealants",
            active: true,
            meta: {
              tags: "Aerospace Adhesives and Sealants",
              keywords: "Aerospace Adhesives and Sealants",
              description: "Aerospace Adhesives and Sealants",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50b8",
            },
            slug: "automotive-adhesives-and-sealants",
            title: "Automotive Adhesives and Sealants",
            id: 8000063,
            parent: "/chemicals-materials/adhesives-and-sealants",
            path: "/chemicals-materials/adhesives-and-sealants/automotive-adhesives-and-sealants",
            active: true,
            meta: {
              tags: "Automotive Adhesives and Sealants",
              keywords: "Automotive Adhesives and Sealants",
              description: "Automotive Adhesives and Sealants",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50c1",
            },
            slug: "silicone-adhesives-and-sealants",
            title: "Silicone Adhesives and Sealants",
            id: 8000072,
            parent: "/chemicals-materials/adhesives-and-sealants",
            path: "/chemicals-materials/adhesives-and-sealants/silicone-adhesives-and-sealants",
            active: true,
            meta: {
              tags: "Silicone Adhesives and Sealants",
              keywords: "Silicone Adhesives and Sealants",
              description: "Silicone Adhesives and Sealants",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b185",
        },
        id: 6146,
        title: "Commodity Chemicals",
        slug: "commodity-chemicals",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Commodity Chemical Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Aromatics and Intermediates Market Research Reports, commodity chemicals, commodity chemicals market growth, commodity chemicals market analysis, commodity chemicals industry analysis, commodity chemicals market report, commodity chemicals industry report, commodity chemicals industry analysis, commodity chemicals industry forecast, commodity chemicals industry, commodity chemicals market",
          tags: "Commodity Chemicals Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "The group of chemicals that are made on a very large scale to satisfy global markets are defined as commodity chemicals. These chemical are very low in product differentiation, and constitute the largest sub-segment of chemical industry.  They act as intermediates to produce other chemicals used to produce wide range of end-user products including construction materials, adhesives, plastics, apparel and tires.",
          short_description:
            "Commodity chemicals act as intermediaries in the production of end-user chemical compounds. Their widespread manufacture suffices the needs of global markets. Commodity chemicals constitute the largest sub-segment of the chemicals and materials industry. Browse through our reports to learn more.",
        },
        picture: null,
        __v: 0,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/commodity-chemicals",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d51a2",
            },
            slug: "acids",
            title: "Acids",
            id: 8000299,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/acids",
            active: true,
            meta: {
              tags: "Acids",
              keywords: "Acids",
              description: "Acids",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51a3",
                },
                slug: "acetic-acid",
                title: "Acetic Acid",
                id: 8000300,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/acetic-acid",
                active: true,
                meta: {
                  tags: "Acetic Acid",
                  keywords: "Acetic Acid",
                  description: "Acetic Acid",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51a6",
                    },
                    slug: "peracetic-acid",
                    title: "Peracetic Acid",
                    id: 8000303,
                    parent:
                      "/chemicals-materials/commodity-chemicals/acids/acetic-acid",
                    path: "/chemicals-materials/commodity-chemicals/acids/acetic-acid/peracetic-acid",
                    active: true,
                    meta: {
                      tags: "Peracetic Acid",
                      keywords: "Peracetic Acid",
                      description: "Peracetic Acid",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51a4",
                    },
                    slug: "glacial-acetic-acid",
                    title: "Glacial Acetic Acid",
                    id: 8000301,
                    parent:
                      "/chemicals-materials/commodity-chemicals/acids/acetic-acid",
                    path: "/chemicals-materials/commodity-chemicals/acids/acetic-acid/glacial-acetic-acid",
                    active: true,
                    meta: {
                      tags: "Glacial Acetic Acid",
                      keywords: "Glacial Acetic Acid",
                      description: "Glacial Acetic Acid",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51a5",
                    },
                    slug: "monochloroacetic-acid",
                    title: "Monochloroacetic Acid",
                    id: 8000302,
                    parent:
                      "/chemicals-materials/commodity-chemicals/acids/acetic-acid",
                    path: "/chemicals-materials/commodity-chemicals/acids/acetic-acid/monochloroacetic-acid",
                    active: true,
                    meta: {
                      tags: "Monochloroacetic Acid",
                      keywords: "Monochloroacetic Acid",
                      description: "Monochloroacetic Acid",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51af",
                },
                slug: "glyoxylic-acid",
                title: "Glyoxylic Acid",
                id: 8000312,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/glyoxylic-acid",
                active: true,
                meta: {
                  tags: "Glyoxylic Acid",
                  keywords: "Glyoxylic Acid",
                  description: "Glyoxylic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b1",
                },
                slug: "humic-acid",
                title: "Humic Acid",
                id: 8000314,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/humic-acid",
                active: true,
                meta: {
                  tags: "Humic Acid",
                  keywords: "Humic Acid",
                  description: "Humic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51c2",
                },
                slug: "terephthalic-acid",
                title: "Terephthalic Acid",
                id: 8000331,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/terephthalic-acid",
                active: true,
                meta: {
                  tags: "Terephthalic Acid",
                  keywords: "Terephthalic Acid",
                  description: "Terephthalic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51a7",
                },
                slug: "acrylic-acid",
                title: "Acrylic Acid",
                id: 8000304,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/acrylic-acid",
                active: true,
                meta: {
                  tags: "Acrylic Acid",
                  keywords: "Acrylic Acid",
                  description: "Acrylic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51a8",
                },
                slug: "adipic-acid",
                title: "Adipic Acid",
                id: 8000305,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/adipic-acid",
                active: true,
                meta: {
                  tags: "Adipic Acid",
                  keywords: "Adipic Acid",
                  description: "Adipic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51a9",
                },
                slug: "butyric-acid",
                title: "Butyric Acid",
                id: 8000306,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/butyric-acid",
                active: true,
                meta: {
                  tags: "Butyric Acid",
                  keywords: "Butyric Acid",
                  description: "Butyric Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b9",
                },
                slug: "nitric-acid",
                title: "Nitric Acid",
                id: 8000322,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/nitric-acid",
                active: true,
                meta: {
                  tags: "Nitric Acid",
                  keywords: "Nitric Acid",
                  description: "Nitric Acid",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51ba",
                    },
                    slug: "concentrated-nitric-acid",
                    title: "Concentrated Nitric Acid",
                    id: 8000323,
                    parent:
                      "/chemicals-materials/commodity-chemicals/acids/nitric-acid",
                    path: "/chemicals-materials/commodity-chemicals/acids/nitric-acid/concentrated-nitric-acid",
                    active: true,
                    meta: {
                      tags: "Concentrated Nitric Acid",
                      keywords: "Concentrated Nitric Acid",
                      description: "Concentrated Nitric Acid",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51be",
                },
                slug: "propionic-acid",
                title: "Propionic Acid",
                id: 8000327,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/propionic-acid",
                active: true,
                meta: {
                  tags: "Propionic Acid",
                  keywords: "Propionic Acid",
                  description: "Propionic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51c3",
                },
                slug: "ultra-pure-sulfuric-acid",
                title: "Ultra Pure Sulfuric Acid",
                id: 8000332,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/ultra-pure-sulfuric-acid",
                active: true,
                meta: {
                  tags: "Ultra Pure Sulfuric Acid",
                  keywords: "Ultra Pure Sulfuric Acid",
                  description: "Ultra Pure Sulfuric Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b6",
                },
                slug: "levulinic-acid",
                title: "Levulinic Acid",
                id: 8000319,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/levulinic-acid",
                active: true,
                meta: {
                  tags: "Levulinic Acid",
                  keywords: "Levulinic Acid",
                  description: "Levulinic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51ad",
                },
                slug: "formic-acid",
                title: "Formic Acid",
                id: 8000310,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/formic-acid",
                active: true,
                meta: {
                  tags: "Formic Acid",
                  keywords: "Formic Acid",
                  description: "Formic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51bb",
                },
                slug: "octadecanedioic-acid-odda",
                title: "Octadecanedioic Acid (ODDA)",
                id: 8000324,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/octadecanedioic-acid-odda",
                active: true,
                meta: {
                  tags: "Octadecanedioic Acid (ODDA)",
                  keywords: "Octadecanedioic Acid (ODDA)",
                  description: "Octadecanedioic Acid (ODDA)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b4",
                },
                slug: "hydroxypropionic-acid",
                title: "Hydroxypropionic Acid",
                id: 8000317,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/hydroxypropionic-acid",
                active: true,
                meta: {
                  tags: "Hydroxypropionic Acid",
                  keywords: "Hydroxypropionic Acid",
                  description: "Hydroxypropionic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51bc",
                },
                slug: "oxalic-acid",
                title: "Oxalic Acid",
                id: 8000325,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/oxalic-acid",
                active: true,
                meta: {
                  tags: "Oxalic Acid",
                  keywords: "Oxalic Acid",
                  description: "Oxalic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51aa",
                },
                slug: "dimer-acid",
                title: "Dimer Acid",
                id: 8000307,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/dimer-acid",
                active: true,
                meta: {
                  tags: "Dimer Acid",
                  keywords: "Dimer Acid",
                  description: "Dimer Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51ae",
                },
                slug: "fumaric-acid",
                title: "Fumaric Acid",
                id: 8000311,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/fumaric-acid",
                active: true,
                meta: {
                  tags: "Fumaric Acid",
                  keywords: "Fumaric Acid",
                  description: "Fumaric Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b5",
                },
                slug: "isostearic-acid",
                title: "Isostearic Acid",
                id: 8000318,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/isostearic-acid",
                active: true,
                meta: {
                  tags: "Isostearic Acid",
                  keywords: "Isostearic Acid",
                  description: "Isostearic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51c1",
                },
                slug: "tartaric-acid",
                title: "Tartaric Acid",
                id: 8000330,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/tartaric-acid",
                active: true,
                meta: {
                  tags: "Tartaric Acid",
                  keywords: "Tartaric Acid",
                  description: "Tartaric Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51ab",
                },
                slug: "dodecanedioic-acid-ddda",
                title: "Dodecanedioic Acid (DDDA)",
                id: 8000308,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/dodecanedioic-acid-ddda",
                active: true,
                meta: {
                  tags: "Dodecanedioic Acid (DDDA)",
                  keywords: "Dodecanedioic Acid (DDDA)",
                  description: "Dodecanedioic Acid (DDDA)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b2",
                },
                slug: "hydrochloric-acid",
                title: "Hydrochloric Acid",
                id: 8000315,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/hydrochloric-acid",
                active: true,
                meta: {
                  tags: "Hydrochloric Acid",
                  keywords: "Hydrochloric Acid",
                  description: "Hydrochloric Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51bf",
                },
                slug: "stearic-acid",
                title: "Stearic Acid",
                id: 8000328,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/stearic-acid",
                active: true,
                meta: {
                  tags: "Stearic Acid",
                  keywords: "Stearic Acid",
                  description: "Stearic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51ac",
                },
                slug: "fluorosilicic-acid",
                title: "Fluorosilicic Acid",
                id: 8000309,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/fluorosilicic-acid",
                active: true,
                meta: {
                  tags: "Fluorosilicic Acid",
                  keywords: "Fluorosilicic Acid",
                  description: "Fluorosilicic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b0",
                },
                slug: "heptanoic-acid",
                title: "Heptanoic Acid",
                id: 8000313,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/heptanoic-acid",
                active: true,
                meta: {
                  tags: "Heptanoic Acid",
                  keywords: "Heptanoic Acid",
                  description: "Heptanoic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b3",
                },
                slug: "hydrofluoric-acid",
                title: "Hydrofluoric Acid",
                id: 8000316,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/hydrofluoric-acid",
                active: true,
                meta: {
                  tags: "Hydrofluoric Acid",
                  keywords: "Hydrofluoric Acid",
                  description: "Hydrofluoric Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b7",
                },
                slug: "long-chain-dicarboxylic-acid",
                title: "Long Chain Dicarboxylic Acid",
                id: 8000320,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/long-chain-dicarboxylic-acid",
                active: true,
                meta: {
                  tags: "Long Chain Dicarboxylic Acid",
                  keywords: "Long Chain Dicarboxylic Acid",
                  description: "Long Chain Dicarboxylic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51b8",
                },
                slug: "naphthenic-acid",
                title: "Naphthenic Acid",
                id: 8000321,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/naphthenic-acid",
                active: true,
                meta: {
                  tags: "Naphthenic Acid",
                  keywords: "Naphthenic Acid",
                  description: "Naphthenic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51bd",
                },
                slug: "phosphoric-acid",
                title: "Phosphoric Acid",
                id: 8000326,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/phosphoric-acid",
                active: true,
                meta: {
                  tags: "Phosphoric Acid",
                  keywords: "Phosphoric Acid",
                  description: "Phosphoric Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51c0",
                },
                slug: "sulfuric-acid",
                title: "Sulfuric Acid",
                id: 8000329,
                parent: "/chemicals-materials/commodity-chemicals/acids",
                path: "/chemicals-materials/commodity-chemicals/acids/sulfuric-acid",
                active: true,
                meta: {
                  tags: "Sulfuric Acid",
                  keywords: "Sulfuric Acid",
                  description: "Sulfuric Acid",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d51cf",
            },
            slug: "amines",
            title: "Amines",
            id: 8000344,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/amines",
            active: true,
            meta: {
              tags: "Amines",
              keywords: "Amines",
              description: "Amines",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51da",
                },
                slug: "trimethylamine",
                title: "Trimethylamine",
                id: 8000355,
                parent: "/chemicals-materials/commodity-chemicals/amines",
                path: "/chemicals-materials/commodity-chemicals/amines/trimethylamine",
                active: true,
                meta: {
                  tags: "Trimethylamine",
                  keywords: "Trimethylamine",
                  description: "Trimethylamine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51d7",
                },
                slug: "hexamethylenediamine",
                title: "Hexamethylenediamine",
                id: 8000352,
                parent: "/chemicals-materials/commodity-chemicals/amines",
                path: "/chemicals-materials/commodity-chemicals/amines/hexamethylenediamine",
                active: true,
                meta: {
                  tags: "Hexamethylenediamine",
                  keywords: "Hexamethylenediamine",
                  description: "Hexamethylenediamine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51d8",
                },
                slug: "p-phenylenediamine",
                title: "P-Phenylenediamine",
                id: 8000353,
                parent: "/chemicals-materials/commodity-chemicals/amines",
                path: "/chemicals-materials/commodity-chemicals/amines/p-phenylenediamine",
                active: true,
                meta: {
                  tags: "P-Phenylenediamine",
                  keywords: "P-Phenylenediamine",
                  description: "P-Phenylenediamine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51d6",
                },
                slug: "fatty-amines",
                title: "Fatty Amines",
                id: 8000351,
                parent: "/chemicals-materials/commodity-chemicals/amines",
                path: "/chemicals-materials/commodity-chemicals/amines/fatty-amines",
                active: true,
                meta: {
                  tags: "Fatty Amines",
                  keywords: "Fatty Amines",
                  description: "Fatty Amines",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51d0",
                },
                slug: "alkylamines",
                title: "Alkylamines",
                id: 8000345,
                parent: "/chemicals-materials/commodity-chemicals/amines",
                path: "/chemicals-materials/commodity-chemicals/amines/alkylamines",
                active: true,
                meta: {
                  tags: "Alkylamines",
                  keywords: "Alkylamines",
                  description: "Alkylamines",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51d3",
                },
                slug: "ethanolamines",
                title: "Ethanolamines",
                id: 8000348,
                parent: "/chemicals-materials/commodity-chemicals/amines",
                path: "/chemicals-materials/commodity-chemicals/amines/ethanolamines",
                active: true,
                meta: {
                  tags: "Ethanolamines",
                  keywords: "Ethanolamines",
                  description: "Ethanolamines",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51d4",
                    },
                    slug: "diethanolamine",
                    title: "Diethanolamine",
                    id: 8000349,
                    parent:
                      "/chemicals-materials/commodity-chemicals/amines/ethanolamines",
                    path: "/chemicals-materials/commodity-chemicals/amines/ethanolamines/diethanolamine",
                    active: true,
                    meta: {
                      tags: "Diethanolamine",
                      keywords: "Diethanolamine",
                      description: "Diethanolamine",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51d5",
                },
                slug: "ethyleneamines",
                title: "Ethyleneamines",
                id: 8000350,
                parent: "/chemicals-materials/commodity-chemicals/amines",
                path: "/chemicals-materials/commodity-chemicals/amines/ethyleneamines",
                active: true,
                meta: {
                  tags: "Ethyleneamines",
                  keywords: "Ethyleneamines",
                  description: "Ethyleneamines",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51d1",
                },
                slug: "diamines",
                title: "Diamines",
                id: 8000346,
                parent: "/chemicals-materials/commodity-chemicals/amines",
                path: "/chemicals-materials/commodity-chemicals/amines/diamines",
                active: true,
                meta: {
                  tags: "Diamines",
                  keywords: "Diamines",
                  description: "Diamines",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51d2",
                    },
                    slug: "dimethylaminopropylamine-dmapa",
                    title: "Dimethylaminopropylamine (DMAPA)",
                    id: 8000347,
                    parent:
                      "/chemicals-materials/commodity-chemicals/amines/diamines",
                    path: "/chemicals-materials/commodity-chemicals/amines/diamines/dimethylaminopropylamine-dmapa",
                    active: true,
                    meta: {
                      tags: "Dimethylaminopropylamine (DMAPA)",
                      keywords: "Dimethylaminopropylamine (DMAPA)",
                      description: "Dimethylaminopropylamine (DMAPA)",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51d9",
                },
                slug: "tetraacetylethylenediamine",
                title: "Tetraacetylethylenediamine",
                id: 8000354,
                parent: "/chemicals-materials/commodity-chemicals/amines",
                path: "/chemicals-materials/commodity-chemicals/amines/tetraacetylethylenediamine",
                active: true,
                meta: {
                  tags: "Tetraacetylethylenediamine",
                  keywords: "Tetraacetylethylenediamine",
                  description: "Tetraacetylethylenediamine",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d51db",
            },
            slug: "aromatics-and-intermediates",
            title: "Aromatics and Intermediates",
            id: 8000356,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates",
            active: true,
            meta: {
              tags: "Aromatics and Intermediates",
              keywords: "Aromatics and Intermediates",
              description: "Aromatics and Intermediates",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51f3",
                },
                slug: "chemical-intermediates",
                title: "Chemical Intermediates",
                id: 8000380,
                parent:
                  "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates",
                path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                active: true,
                meta: {
                  tags: "Chemical Intermediates",
                  keywords: "Chemical Intermediates",
                  description: "Chemical Intermediates",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51f8",
                    },
                    slug: "2-ethylhexanol",
                    title: "2-Ethylhexanol",
                    id: 8000385,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/2-ethylhexanol",
                    active: true,
                    meta: {
                      tags: "2-Ethylhexanol",
                      keywords: "2-Ethylhexanol",
                      description: "2-Ethylhexanol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5202",
                    },
                    slug: "benzyl-alcohol",
                    title: "Benzyl Alcohol",
                    id: 8000395,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/benzyl-alcohol",
                    active: true,
                    meta: {
                      tags: "Benzyl Alcohol",
                      keywords: "Benzyl Alcohol",
                      description: "Benzyl Alcohol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5206",
                    },
                    slug: "calcium-carbide",
                    title: "Calcium Carbide",
                    id: 8000399,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/calcium-carbide",
                    active: true,
                    meta: {
                      tags: "Calcium Carbide",
                      keywords: "Calcium Carbide",
                      description: "Calcium Carbide",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5211",
                    },
                    slug: "ethoxydiglycol",
                    title: "Ethoxydiglycol",
                    id: 8000410,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/ethoxydiglycol",
                    active: true,
                    meta: {
                      tags: "Ethoxydiglycol",
                      keywords: "Ethoxydiglycol",
                      description: "Ethoxydiglycol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5212",
                    },
                    slug: "ethyl-levulinate",
                    title: "Ethyl Levulinate",
                    id: 8000411,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/ethyl-levulinate",
                    active: true,
                    meta: {
                      tags: "Ethyl Levulinate",
                      keywords: "Ethyl Levulinate",
                      description: "Ethyl Levulinate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d521a",
                    },
                    slug: "hydroquinone",
                    title: "Hydroquinone",
                    id: 8000419,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/hydroquinone",
                    active: true,
                    meta: {
                      tags: "Hydroquinone",
                      keywords: "Hydroquinone",
                      description: "Hydroquinone",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51f7",
                    },
                    slug: "2-ethyl-hexanol",
                    title: "2-Ethyl Hexanol",
                    id: 8000384,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/2-ethyl-hexanol",
                    active: true,
                    meta: {
                      tags: "2-Ethyl Hexanol",
                      keywords: "2-Ethyl Hexanol",
                      description: "2-Ethyl Hexanol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51fb",
                    },
                    slug: "acetic-anhydride",
                    title: "Acetic Anhydride",
                    id: 8000388,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/acetic-anhydride",
                    active: true,
                    meta: {
                      tags: "Acetic Anhydride",
                      keywords: "Acetic Anhydride",
                      description: "Acetic Anhydride",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5205",
                    },
                    slug: "butyraldehyde",
                    title: "Butyraldehyde",
                    id: 8000398,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/butyraldehyde",
                    active: true,
                    meta: {
                      tags: "Butyraldehyde",
                      keywords: "Butyraldehyde",
                      description: "Butyraldehyde",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d520a",
                    },
                    slug: "diethyl-ether",
                    title: "diethyl-ether",
                    id: 8000403,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/diethyl-ether",
                    active: true,
                    meta: {
                      tags: "diethyl-ether",
                      keywords: "diethyl-ether",
                      description: "diethyl-ether",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5210",
                    },
                    slug: "ethanol",
                    title: "Ethanol",
                    id: 8000409,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/ethanol",
                    active: true,
                    meta: {
                      tags: "Ethanol",
                      keywords: "Ethanol",
                      description: "Ethanol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5221",
                    },
                    slug: "isopropyl-alcohol-ipa",
                    title: "Isopropyl Alcohol (IPA)",
                    id: 8000426,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/isopropyl-alcohol-ipa",
                    active: true,
                    meta: {
                      tags: "Isopropyl Alcohol (IPA)",
                      keywords: "Isopropyl Alcohol (IPA)",
                      description: "Isopropyl Alcohol (IPA)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5222",
                    },
                    slug: "maleic-anhydride",
                    title: "Maleic Anhydride",
                    id: 8000427,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/maleic-anhydride",
                    active: true,
                    meta: {
                      tags: "Maleic Anhydride",
                      keywords: "Maleic Anhydride",
                      description: "Maleic Anhydride",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5227",
                    },
                    slug: "n-methyl-pyrrolidone",
                    title: "N-Methyl Pyrrolidone",
                    id: 8000432,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/n-methyl-pyrrolidone",
                    active: true,
                    meta: {
                      tags: "N-Methyl Pyrrolidone",
                      keywords: "N-Methyl Pyrrolidone",
                      description: "N-Methyl Pyrrolidone",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d522a",
                    },
                    slug: "phthalic-anhydride",
                    title: "Phthalic Anhydride",
                    id: 8000435,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/phthalic-anhydride",
                    active: true,
                    meta: {
                      tags: "Phthalic Anhydride",
                      keywords: "Phthalic Anhydride",
                      description: "Phthalic Anhydride",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d522c",
                    },
                    slug: "polyacrylate",
                    title: "Polyacrylate",
                    id: 8000437,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/polyacrylate",
                    active: true,
                    meta: {
                      tags: "Polyacrylate",
                      keywords: "Polyacrylate",
                      description: "Polyacrylate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5230",
                    },
                    slug: "propanol",
                    title: "Propanol",
                    id: 8000441,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/propanol",
                    active: true,
                    meta: {
                      tags: "Propanol",
                      keywords: "Propanol",
                      description: "Propanol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5233",
                    },
                    slug: "sulfur-hexafluoride",
                    title: "Sulfur Hexafluoride",
                    id: 8000444,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/sulfur-hexafluoride",
                    active: true,
                    meta: {
                      tags: "Sulfur Hexafluoride",
                      keywords: "Sulfur Hexafluoride",
                      description: "Sulfur Hexafluoride",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51f4",
                    },
                    slug: "13-propanediol-pdo",
                    title: "1,3-Propanediol (PDO)",
                    id: 8000381,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/13-propanediol-pdo",
                    active: true,
                    meta: {
                      tags: "1,3-Propanediol (PDO)",
                      keywords: "1,3-Propanediol (PDO)",
                      description: "1,3-Propanediol (PDO)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5201",
                    },
                    slug: "azodicarbonamide",
                    title: "Azodicarbonamide",
                    id: 8000394,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/azodicarbonamide",
                    active: true,
                    meta: {
                      tags: "Azodicarbonamide",
                      keywords: "Azodicarbonamide",
                      description: "Azodicarbonamide",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5217",
                    },
                    slug: "glycol-ethers",
                    title: "Glycol Ethers",
                    id: 8000416,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/glycol-ethers",
                    active: true,
                    meta: {
                      tags: "Glycol Ethers",
                      keywords: "Glycol Ethers",
                      description: "Glycol Ethers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d522f",
                    },
                    slug: "polyether-polyol",
                    title: "Polyether Polyol",
                    id: 8000440,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/polyether-polyol",
                    active: true,
                    meta: {
                      tags: "Polyether Polyol",
                      keywords: "Polyether Polyol",
                      description: "Polyether Polyol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5232",
                    },
                    slug: "styrene",
                    title: "Styrene",
                    id: 8000443,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/styrene",
                    active: true,
                    meta: {
                      tags: "Styrene",
                      keywords: "Styrene",
                      description: "Styrene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51fa",
                    },
                    slug: "acetaldehyde",
                    title: "Acetaldehyde",
                    id: 8000387,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/acetaldehyde",
                    active: true,
                    meta: {
                      tags: "Acetaldehyde",
                      keywords: "Acetaldehyde",
                      description: "Acetaldehyde",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51fe",
                    },
                    slug: "adiponitrile",
                    title: "Adiponitrile",
                    id: 8000391,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/adiponitrile",
                    active: true,
                    meta: {
                      tags: "Adiponitrile",
                      keywords: "Adiponitrile",
                      description: "Adiponitrile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5209",
                    },
                    slug: "dibutyl-maleate-dbm",
                    title: "Dibutyl Maleate (DBM)",
                    id: 8000402,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/dibutyl-maleate-dbm",
                    active: true,
                    meta: {
                      tags: "Dibutyl Maleate (DBM)",
                      keywords: "Dibutyl Maleate (DBM)",
                      description: "Dibutyl Maleate (DBM)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d520b",
                    },
                    slug: "dimethyl-terephthalate-dmt",
                    title: "Dimethyl Terephthalate (DMT)",
                    id: 8000404,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/dimethyl-terephthalate-dmt",
                    active: true,
                    meta: {
                      tags: "Dimethyl Terephthalate (DMT)",
                      keywords: "Dimethyl Terephthalate (DMT)",
                      description: "Dimethyl Terephthalate (DMT)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5215",
                    },
                    slug: "formaldehyde",
                    title: "Formaldehyde",
                    id: 8000414,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/formaldehyde",
                    active: true,
                    meta: {
                      tags: "Formaldehyde",
                      keywords: "Formaldehyde",
                      description: "Formaldehyde",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5219",
                    },
                    slug: "higher-olefins",
                    title: "Higher Olefins",
                    id: 8000418,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/higher-olefins",
                    active: true,
                    meta: {
                      tags: "Higher Olefins",
                      keywords: "Higher Olefins",
                      description: "Higher Olefins",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d522b",
                    },
                    slug: "phthalic-anhydride-derivatives",
                    title: "Phthalic Anhydride Derivatives",
                    id: 8000436,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/phthalic-anhydride-derivatives",
                    active: true,
                    meta: {
                      tags: "Phthalic Anhydride Derivatives",
                      keywords: "Phthalic Anhydride Derivatives",
                      description: "Phthalic Anhydride Derivatives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5235",
                    },
                    slug: "carbamide",
                    title: "Carbamide",
                    id: 8000446,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/carbamide",
                    active: true,
                    meta: {
                      tags: "Carbamide",
                      keywords: "Carbamide",
                      description: "Carbamide",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d520f",
                    },
                    slug: "epichlorohydrin",
                    title: "Epichlorohydrin",
                    id: 8000408,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/epichlorohydrin",
                    active: true,
                    meta: {
                      tags: "Epichlorohydrin",
                      keywords: "Epichlorohydrin",
                      description: "Epichlorohydrin",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5213",
                    },
                    slug: "ethylene-carbonate",
                    title: "Ethylene Carbonate",
                    id: 8000412,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/ethylene-carbonate",
                    active: true,
                    meta: {
                      tags: "Ethylene Carbonate",
                      keywords: "Ethylene Carbonate",
                      description: "Ethylene Carbonate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5214",
                    },
                    slug: "ethylene-oxide",
                    title: "Ethylene Oxide",
                    id: 8000413,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/ethylene-oxide",
                    active: true,
                    meta: {
                      tags: "Ethylene Oxide",
                      keywords: "Ethylene Oxide",
                      description: "Ethylene Oxide",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5220",
                    },
                    slug: "isophorone",
                    title: "Isophorone",
                    id: 8000425,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/isophorone",
                    active: true,
                    meta: {
                      tags: "Isophorone",
                      keywords: "Isophorone",
                      description: "Isophorone",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5238",
                    },
                    slug: "vinyl-chloride-monomer",
                    title: "Vinyl Chloride Monomer",
                    id: 8000449,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/vinyl-chloride-monomer",
                    active: true,
                    meta: {
                      tags: "Vinyl Chloride Monomer",
                      keywords: "Vinyl Chloride Monomer",
                      description: "Vinyl Chloride Monomer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5204",
                    },
                    slug: "bisphenol-a",
                    title: "Bisphenol-A",
                    id: 8000397,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/bisphenol-a",
                    active: true,
                    meta: {
                      tags: "Bisphenol-A",
                      keywords: "Bisphenol-A",
                      description: "Bisphenol-A",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5207",
                    },
                    slug: "caprolactam",
                    title: "Caprolactam",
                    id: 8000400,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/caprolactam",
                    active: true,
                    meta: {
                      tags: "Caprolactam",
                      keywords: "Caprolactam",
                      description: "Caprolactam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d520c",
                    },
                    slug: "dimethylformamide",
                    title: "Dimethylformamide",
                    id: 8000405,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/dimethylformamide",
                    active: true,
                    meta: {
                      tags: "Dimethylformamide",
                      keywords: "Dimethylformamide",
                      description: "Dimethylformamide",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d520d",
                    },
                    slug: "dioctyl-maleate-dom",
                    title: "Dioctyl Maleate (DOM)",
                    id: 8000406,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/dioctyl-maleate-dom",
                    active: true,
                    meta: {
                      tags: "Dioctyl Maleate (DOM)",
                      keywords: "Dioctyl Maleate (DOM)",
                      description: "Dioctyl Maleate (DOM)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d520e",
                    },
                    slug: "dipropylene-glycol",
                    title: "Dipropylene Glycol",
                    id: 8000407,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/dipropylene-glycol",
                    active: true,
                    meta: {
                      tags: "Dipropylene Glycol",
                      keywords: "Dipropylene Glycol",
                      description: "Dipropylene Glycol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5226",
                    },
                    slug: "monopropylene-glycol",
                    title: "Monopropylene Glycol",
                    id: 8000431,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/monopropylene-glycol",
                    active: true,
                    meta: {
                      tags: "Monopropylene Glycol",
                      keywords: "Monopropylene Glycol",
                      description: "Monopropylene Glycol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5231",
                    },
                    slug: "pyrrolidone",
                    title: "Pyrrolidone",
                    id: 8000442,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/pyrrolidone",
                    active: true,
                    meta: {
                      tags: "Pyrrolidone",
                      keywords: "Pyrrolidone",
                      description: "Pyrrolidone",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51f5",
                    },
                    slug: "14-butanediol",
                    title: "1,4 Butanediol",
                    id: 8000382,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/14-butanediol",
                    active: true,
                    meta: {
                      tags: "1,4 Butanediol",
                      keywords: "1,4 Butanediol",
                      description: "1,4 Butanediol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51f6",
                    },
                    slug: "16-hexanediol",
                    title: "1,6-Hexanediol",
                    id: 8000383,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/16-hexanediol",
                    active: true,
                    meta: {
                      tags: "1,6-Hexanediol",
                      keywords: "1,6-Hexanediol",
                      description: "1,6-Hexanediol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51f9",
                    },
                    slug: "5-hydroxytryptophan-5-htp",
                    title: "5-Hydroxytryptophan (5-HTP)",
                    id: 8000386,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/5-hydroxytryptophan-5-htp",
                    active: true,
                    meta: {
                      tags: "5-Hydroxytryptophan (5-HTP)",
                      keywords: "5-Hydroxytryptophan (5-HTP)",
                      description: "5-Hydroxytryptophan (5-HTP)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51fc",
                    },
                    slug: "acetone",
                    title: "Acetone",
                    id: 8000389,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/acetone",
                    active: true,
                    meta: {
                      tags: "Acetone",
                      keywords: "Acetone",
                      description: "Acetone",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51fd",
                    },
                    slug: "acrylonitrile",
                    title: "Acrylonitrile",
                    id: 8000390,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/acrylonitrile",
                    active: true,
                    meta: {
                      tags: "Acrylonitrile",
                      keywords: "Acrylonitrile",
                      description: "Acrylonitrile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5218",
                    },
                    slug: "glyoxal",
                    title: "Glyoxal",
                    id: 8000417,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/glyoxal",
                    active: true,
                    meta: {
                      tags: "Glyoxal",
                      keywords: "Glyoxal",
                      description: "Glyoxal",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d521b",
                    },
                    slug: "isoamyl-acetate",
                    title: "Isoamyl Acetate",
                    id: 8000420,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/isoamyl-acetate",
                    active: true,
                    meta: {
                      tags: "Isoamyl Acetate",
                      keywords: "Isoamyl Acetate",
                      description: "Isoamyl Acetate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d521d",
                    },
                    slug: "isocyantes",
                    title: "Isocyantes",
                    id: 8000422,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/isocyantes",
                    active: true,
                    meta: {
                      tags: "Isocyantes",
                      keywords: "Isocyantes",
                      description: "Isocyantes",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d521f",
                        },
                        slug: "toluene-diisocyanate",
                        title: "Toluene Diisocyanate",
                        id: 8000424,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/isocyantes",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/isocyantes/toluene-diisocyanate",
                        active: true,
                        meta: {
                          tags: "Toluene Diisocyanate",
                          keywords: "Toluene Diisocyanate",
                          description: "Toluene Diisocyanate",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d521e",
                        },
                        slug: "methylene-diphenyl-di-isocyanate-mdi",
                        title: "Methylene Diphenyl Di-isocyanate (MDI)",
                        id: 8000423,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/isocyantes",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/isocyantes/methylene-diphenyl-di-isocyanate-mdi",
                        active: true,
                        meta: {
                          tags: "Methylene Diphenyl Di-isocyanate (MDI)",
                          keywords: "Methylene Diphenyl Di-isocyanate (MDI)",
                          description: "Methylene Diphenyl Di-isocyanate (MDI)",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5223",
                    },
                    slug: "methionine",
                    title: "Methionine",
                    id: 8000428,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/methionine",
                    active: true,
                    meta: {
                      tags: "Methionine",
                      keywords: "Methionine",
                      description: "Methionine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5224",
                    },
                    slug: "methylene-chloride",
                    title: "Methylene Chloride",
                    id: 8000429,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/methylene-chloride",
                    active: true,
                    meta: {
                      tags: "Methylene Chloride",
                      keywords: "Methylene Chloride",
                      description: "Methylene Chloride",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5228",
                    },
                    slug: "persulfate",
                    title: "Persulfate",
                    id: 8000433,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/persulfate",
                    active: true,
                    meta: {
                      tags: "Persulfate",
                      keywords: "Persulfate",
                      description: "Persulfate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d522d",
                    },
                    slug: "polyamide-precursors",
                    title: "Polyamide Precursors",
                    id: 8000438,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/polyamide-precursors",
                    active: true,
                    meta: {
                      tags: "Polyamide Precursors",
                      keywords: "Polyamide Precursors",
                      description: "Polyamide Precursors",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5234",
                    },
                    slug: "triazine",
                    title: "Triazine",
                    id: 8000445,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/triazine",
                    active: true,
                    meta: {
                      tags: "Triazine",
                      keywords: "Triazine",
                      description: "Triazine",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5236",
                    },
                    slug: "urea-resin",
                    title: "Urea Resin",
                    id: 8000447,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/urea-resin",
                    active: true,
                    meta: {
                      tags: "Urea Resin",
                      keywords: "Urea Resin",
                      description: "Urea Resin",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5237",
                        },
                        slug: "urea-formaldehyde-resins",
                        title: "Urea Formaldehyde Resins",
                        id: 8000448,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/urea-resin",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/urea-resin/urea-formaldehyde-resins",
                        active: true,
                        meta: {
                          tags: "Urea Formaldehyde Resins",
                          keywords: "Urea Formaldehyde Resins",
                          description: "Urea Formaldehyde Resins",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51ff",
                    },
                    slug: "allyl-chloride",
                    title: "Allyl Chloride",
                    id: 8000392,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/allyl-chloride",
                    active: true,
                    meta: {
                      tags: "Allyl Chloride",
                      keywords: "Allyl Chloride",
                      description: "Allyl Chloride",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5200",
                    },
                    slug: "aniline",
                    title: "Aniline",
                    id: 8000393,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/aniline",
                    active: true,
                    meta: {
                      tags: "Aniline",
                      keywords: "Aniline",
                      description: "Aniline",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5203",
                    },
                    slug: "bismaleimide-monomer",
                    title: "Bismaleimide Monomer",
                    id: 8000396,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/bismaleimide-monomer",
                    active: true,
                    meta: {
                      tags: "Bismaleimide Monomer",
                      keywords: "Bismaleimide Monomer",
                      description: "Bismaleimide Monomer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5208",
                    },
                    slug: "cetanol-cetyl-alcohol",
                    title: "Cetanol (Cetyl Alcohol)",
                    id: 8000401,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/cetanol-cetyl-alcohol",
                    active: true,
                    meta: {
                      tags: "Cetanol (Cetyl Alcohol)",
                      keywords: "Cetanol (Cetyl Alcohol)",
                      description: "Cetanol (Cetyl Alcohol)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5216",
                    },
                    slug: "glycol",
                    title: "Glycol",
                    id: 8000415,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/glycol",
                    active: true,
                    meta: {
                      tags: "Glycol",
                      keywords: "Glycol",
                      description: "Glycol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d521c",
                    },
                    slug: "isobutyl-stearate",
                    title: "Isobutyl Stearate",
                    id: 8000421,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/isobutyl-stearate",
                    active: true,
                    meta: {
                      tags: "Isobutyl Stearate",
                      keywords: "Isobutyl Stearate",
                      description: "Isobutyl Stearate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5225",
                    },
                    slug: "mono-ethylene-glycol",
                    title: "Mono-ethylene Glycol",
                    id: 8000430,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/mono-ethylene-glycol",
                    active: true,
                    meta: {
                      tags: "Mono-ethylene Glycol",
                      keywords: "Mono-ethylene Glycol",
                      description: "Mono-ethylene Glycol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5229",
                    },
                    slug: "phosphonates",
                    title: "Phosphonates",
                    id: 8000434,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/phosphonates",
                    active: true,
                    meta: {
                      tags: "Phosphonates",
                      keywords: "Phosphonates",
                      description: "Phosphonates",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d522e",
                    },
                    slug: "polyester-polyol",
                    title: "Polyester Polyol",
                    id: 8000439,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/chemical-intermediates/polyester-polyol",
                    active: true,
                    meta: {
                      tags: "Polyester Polyol",
                      keywords: "Polyester Polyol",
                      description: "Polyester Polyol",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5239",
                },
                slug: "medium-chain-triglycerides",
                title: "Medium Chain Triglycerides",
                id: 8000450,
                parent:
                  "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates",
                path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/medium-chain-triglycerides",
                active: true,
                meta: {
                  tags: "Medium Chain Triglycerides",
                  keywords: "Medium Chain Triglycerides",
                  description: "Medium Chain Triglycerides",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51dc",
                },
                slug: "aromatics",
                title: "Aromatics",
                id: 8000357,
                parent:
                  "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates",
                path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics",
                active: true,
                meta: {
                  tags: "Aromatics",
                  keywords: "Aromatics",
                  description: "Aromatics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51f0",
                    },
                    slug: "phenol",
                    title: "Phenol",
                    id: 8000377,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/phenol",
                    active: true,
                    meta: {
                      tags: "Phenol",
                      keywords: "Phenol",
                      description: "Phenol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51f1",
                    },
                    slug: "phenol-derivatives",
                    title: "Phenol Derivatives",
                    id: 8000378,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/phenol-derivatives",
                    active: true,
                    meta: {
                      tags: "Phenol Derivatives",
                      keywords: "Phenol Derivatives",
                      description: "Phenol Derivatives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51de",
                    },
                    slug: "hydrocarbon",
                    title: "Hydrocarbon",
                    id: 8000359,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon",
                    active: true,
                    meta: {
                      tags: "Hydrocarbon",
                      keywords: "Hydrocarbon",
                      description: "Hydrocarbon",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d51e9",
                        },
                        slug: "naphthalene",
                        title: "Naphthalene",
                        id: 8000370,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/naphthalene",
                        active: true,
                        meta: {
                          tags: "Naphthalene",
                          keywords: "Naphthalene",
                          description: "Naphthalene",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d51e0",
                        },
                        slug: "benzene",
                        title: "Benzene",
                        id: 8000361,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene",
                        active: true,
                        meta: {
                          tags: "Benzene",
                          keywords: "Benzene",
                          description: "Benzene",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d51e1",
                            },
                            slug: "bromobenzene",
                            title: "Bromobenzene",
                            id: 8000362,
                            parent:
                              "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene",
                            path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene/bromobenzene",
                            active: true,
                            meta: {
                              tags: "Bromobenzene",
                              keywords: "Bromobenzene",
                              description: "Bromobenzene",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d51e4",
                            },
                            slug: "linear-alkyl-benzene-lab",
                            title: "Linear Alkyl Benzene (LAB)",
                            id: 8000365,
                            parent:
                              "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene",
                            path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene/linear-alkyl-benzene-lab",
                            active: true,
                            meta: {
                              tags: "Linear Alkyl Benzene (LAB)",
                              keywords: "Linear Alkyl Benzene (LAB)",
                              description: "Linear Alkyl Benzene (LAB)",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d51e3",
                            },
                            slug: "ethylbenzene",
                            title: "Ethylbenzene",
                            id: 8000364,
                            parent:
                              "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene",
                            path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene/ethylbenzene",
                            active: true,
                            meta: {
                              tags: "Ethylbenzene",
                              keywords: "Ethylbenzene",
                              description: "Ethylbenzene",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d51e5",
                            },
                            slug: "nitrobenzene",
                            title: "Nitrobenzene",
                            id: 8000366,
                            parent:
                              "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene",
                            path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene/nitrobenzene",
                            active: true,
                            meta: {
                              tags: "Nitrobenzene",
                              keywords: "Nitrobenzene",
                              description: "Nitrobenzene",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d51e2",
                            },
                            slug: "chlorobenzene",
                            title: "Chlorobenzene",
                            id: 8000363,
                            parent:
                              "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene",
                            path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene/chlorobenzene",
                            active: true,
                            meta: {
                              tags: "Chlorobenzene",
                              keywords: "Chlorobenzene",
                              description: "Chlorobenzene",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d51e6",
                        },
                        slug: "benzene-toluene-xylene-btx",
                        title: "Benzene-Toluene-Xylene (BTX)",
                        id: 8000367,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/benzene-toluene-xylene-btx",
                        active: true,
                        meta: {
                          tags: "Benzene-Toluene-Xylene (BTX)",
                          keywords: "Benzene-Toluene-Xylene (BTX)",
                          description: "Benzene-Toluene-Xylene (BTX)",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d51e8",
                        },
                        slug: "cyclohexane",
                        title: "Cyclohexane",
                        id: 8000369,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/cyclohexane",
                        active: true,
                        meta: {
                          tags: "Cyclohexane",
                          keywords: "Cyclohexane",
                          description: "Cyclohexane",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d51e7",
                        },
                        slug: "cumene",
                        title: "Cumene",
                        id: 8000368,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/cumene",
                        active: true,
                        meta: {
                          tags: "Cumene",
                          keywords: "Cumene",
                          description: "Cumene",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d51ea",
                        },
                        slug: "toluene",
                        title: "Toluene",
                        id: 8000371,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/toluene",
                        active: true,
                        meta: {
                          tags: "Toluene",
                          keywords: "Toluene",
                          description: "Toluene",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d51df",
                        },
                        slug: "acetylene",
                        title: "Acetylene",
                        id: 8000360,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/acetylene",
                        active: true,
                        meta: {
                          tags: "Acetylene",
                          keywords: "Acetylene",
                          description: "Acetylene",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d51eb",
                        },
                        slug: "xylene",
                        title: "Xylene",
                        id: 8000372,
                        parent:
                          "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon",
                        path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/xylene",
                        active: true,
                        meta: {
                          tags: "Xylene",
                          keywords: "Xylene",
                          description: "Xylene",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d51ed",
                            },
                            slug: "ortho-xylene",
                            title: "Ortho-Xylene",
                            id: 8000374,
                            parent:
                              "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/xylene",
                            path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/xylene/ortho-xylene",
                            active: true,
                            meta: {
                              tags: "Ortho-Xylene",
                              keywords: "Ortho-Xylene",
                              description: "Ortho-Xylene",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d51ec",
                            },
                            slug: "meta-xylene",
                            title: "Meta-Xylene",
                            id: 8000373,
                            parent:
                              "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/xylene",
                            path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/xylene/meta-xylene",
                            active: true,
                            meta: {
                              tags: "Meta-Xylene",
                              keywords: "Meta-Xylene",
                              description: "Meta-Xylene",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d51ee",
                            },
                            slug: "para-xylene",
                            title: "Para-Xylene",
                            id: 8000375,
                            parent:
                              "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/xylene",
                            path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/hydrocarbon/xylene/para-xylene",
                            active: true,
                            meta: {
                              tags: "Para-Xylene",
                              keywords: "Para-Xylene",
                              description: "Para-Xylene",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51ef",
                    },
                    slug: "pentaerythritol",
                    title: "Pentaerythritol",
                    id: 8000376,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/pentaerythritol",
                    active: true,
                    meta: {
                      tags: "Pentaerythritol",
                      keywords: "Pentaerythritol",
                      description: "Pentaerythritol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51f2",
                    },
                    slug: "pyridine-derivatives",
                    title: "Pyridine Derivatives",
                    id: 8000379,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/pyridine-derivatives",
                    active: true,
                    meta: {
                      tags: "Pyridine Derivatives",
                      keywords: "Pyridine Derivatives",
                      description: "Pyridine Derivatives",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51dd",
                    },
                    slug: "benzoe-siam",
                    title: "Benzoe Siam",
                    id: 8000358,
                    parent:
                      "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics",
                    path: "/chemicals-materials/commodity-chemicals/aromatics-and-intermediates/aromatics/benzoe-siam",
                    active: true,
                    meta: {
                      tags: "Benzoe Siam",
                      keywords: "Benzoe Siam",
                      description: "Benzoe Siam",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d523a",
            },
            slug: "basic-petrochemicals",
            title: "Basic Petrochemicals",
            id: 8000451,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/basic-petrochemicals",
            active: true,
            meta: {
              tags: "Basic Petrochemicals",
              keywords: "Basic Petrochemicals",
              description: "Basic Petrochemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d523e",
                },
                slug: "methanol",
                title: "Methanol",
                id: 8000455,
                parent:
                  "/chemicals-materials/commodity-chemicals/basic-petrochemicals",
                path: "/chemicals-materials/commodity-chemicals/basic-petrochemicals/methanol",
                active: true,
                meta: {
                  tags: "Methanol",
                  keywords: "Methanol",
                  description: "Methanol",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d523d",
                },
                slug: "hexane",
                title: "Hexane",
                id: 8000454,
                parent:
                  "/chemicals-materials/commodity-chemicals/basic-petrochemicals",
                path: "/chemicals-materials/commodity-chemicals/basic-petrochemicals/hexane",
                active: true,
                meta: {
                  tags: "Hexane",
                  keywords: "Hexane",
                  description: "Hexane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d523f",
                },
                slug: "naphtha",
                title: "Naphtha",
                id: 8000456,
                parent:
                  "/chemicals-materials/commodity-chemicals/basic-petrochemicals",
                path: "/chemicals-materials/commodity-chemicals/basic-petrochemicals/naphtha",
                active: true,
                meta: {
                  tags: "Naphtha",
                  keywords: "Naphtha",
                  description: "Naphtha",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d523b",
                },
                slug: "bitumen",
                title: "Bitumen",
                id: 8000452,
                parent:
                  "/chemicals-materials/commodity-chemicals/basic-petrochemicals",
                path: "/chemicals-materials/commodity-chemicals/basic-petrochemicals/bitumen",
                active: true,
                meta: {
                  tags: "Bitumen",
                  keywords: "Bitumen",
                  description: "Bitumen",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5241",
                },
                slug: "paraffin",
                title: "Paraffin",
                id: 8000458,
                parent:
                  "/chemicals-materials/commodity-chemicals/basic-petrochemicals",
                path: "/chemicals-materials/commodity-chemicals/basic-petrochemicals/paraffin",
                active: true,
                meta: {
                  tags: "Paraffin",
                  keywords: "Paraffin",
                  description: "Paraffin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d523c",
                },
                slug: "butane",
                title: "Butane",
                id: 8000453,
                parent:
                  "/chemicals-materials/commodity-chemicals/basic-petrochemicals",
                path: "/chemicals-materials/commodity-chemicals/basic-petrochemicals/butane",
                active: true,
                meta: {
                  tags: "Butane",
                  keywords: "Butane",
                  description: "Butane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5242",
                },
                slug: "wax",
                title: "Wax",
                id: 8000459,
                parent:
                  "/chemicals-materials/commodity-chemicals/basic-petrochemicals",
                path: "/chemicals-materials/commodity-chemicals/basic-petrochemicals/wax",
                active: true,
                meta: {
                  tags: "Wax",
                  keywords: "Wax",
                  description: "Wax",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5240",
                },
                slug: "naphthalene-derivatives",
                title: "Naphthalene Derivatives",
                id: 8000457,
                parent:
                  "/chemicals-materials/commodity-chemicals/basic-petrochemicals",
                path: "/chemicals-materials/commodity-chemicals/basic-petrochemicals/naphthalene-derivatives",
                active: true,
                meta: {
                  tags: "Naphthalene Derivatives",
                  keywords: "Naphthalene Derivatives",
                  description: "Naphthalene Derivatives",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5245",
            },
            slug: "chlor-alkali-chemicals",
            title: "Chlor alkali Chemicals",
            id: 8000462,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/chlor-alkali-chemicals",
            active: true,
            meta: {
              tags: "Chlor alkali Chemicals",
              keywords: "Chlor alkali Chemicals",
              description: "Chlor alkali Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5246",
                },
                slug: "caustic-soda",
                title: "Caustic Soda",
                id: 8000463,
                parent:
                  "/chemicals-materials/commodity-chemicals/chlor-alkali-chemicals",
                path: "/chemicals-materials/commodity-chemicals/chlor-alkali-chemicals/caustic-soda",
                active: true,
                meta: {
                  tags: "Caustic Soda",
                  keywords: "Caustic Soda",
                  description: "Caustic Soda",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d524b",
            },
            slug: "halogens",
            title: "Halogens",
            id: 8000468,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/halogens",
            active: true,
            meta: {
              tags: "Halogens",
              keywords: "Halogens",
              description: "Halogens",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5256",
                },
                slug: "inorganic-iodide",
                title: "Inorganic Iodide",
                id: 8000479,
                parent: "/chemicals-materials/commodity-chemicals/halogens",
                path: "/chemicals-materials/commodity-chemicals/halogens/inorganic-iodide",
                active: true,
                meta: {
                  tags: "Inorganic Iodide",
                  keywords: "Inorganic Iodide",
                  description: "Inorganic Iodide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d524e",
                },
                slug: "chlorine",
                title: "Chlorine",
                id: 8000471,
                parent: "/chemicals-materials/commodity-chemicals/halogens",
                path: "/chemicals-materials/commodity-chemicals/halogens/chlorine",
                active: true,
                meta: {
                  tags: "Chlorine",
                  keywords: "Chlorine",
                  description: "Chlorine",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d524f",
                    },
                    slug: "chlorine-compounds",
                    title: "Chlorine Compounds",
                    id: 8000472,
                    parent:
                      "/chemicals-materials/commodity-chemicals/halogens/chlorine",
                    path: "/chemicals-materials/commodity-chemicals/halogens/chlorine/chlorine-compounds",
                    active: true,
                    meta: {
                      tags: "Chlorine Compounds",
                      keywords: "Chlorine Compounds",
                      description: "Chlorine Compounds",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5250",
                        },
                        slug: "hypochlorite-bleaches",
                        title: "Hypochlorite Bleaches",
                        id: 8000473,
                        parent:
                          "/chemicals-materials/commodity-chemicals/halogens/chlorine/chlorine-compounds",
                        path: "/chemicals-materials/commodity-chemicals/halogens/chlorine/chlorine-compounds/hypochlorite-bleaches",
                        active: true,
                        meta: {
                          tags: "Hypochlorite Bleaches",
                          keywords: "Hypochlorite Bleaches",
                          description: "Hypochlorite Bleaches",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d524c",
                },
                slug: "bromine",
                title: "Bromine",
                id: 8000469,
                parent: "/chemicals-materials/commodity-chemicals/halogens",
                path: "/chemicals-materials/commodity-chemicals/halogens/bromine",
                active: true,
                meta: {
                  tags: "Bromine",
                  keywords: "Bromine",
                  description: "Bromine",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d524d",
                    },
                    slug: "bromine-derivatives",
                    title: "Bromine Derivatives",
                    id: 8000470,
                    parent:
                      "/chemicals-materials/commodity-chemicals/halogens/bromine",
                    path: "/chemicals-materials/commodity-chemicals/halogens/bromine/bromine-derivatives",
                    active: true,
                    meta: {
                      tags: "Bromine Derivatives",
                      keywords: "Bromine Derivatives",
                      description: "Bromine Derivatives",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5251",
                },
                slug: "fluorine",
                title: "Fluorine",
                id: 8000474,
                parent: "/chemicals-materials/commodity-chemicals/halogens",
                path: "/chemicals-materials/commodity-chemicals/halogens/fluorine",
                active: true,
                meta: {
                  tags: "Fluorine",
                  keywords: "Fluorine",
                  description: "Fluorine",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5252",
                    },
                    slug: "fluorine-derivatives",
                    title: "Fluorine Derivatives",
                    id: 8000475,
                    parent:
                      "/chemicals-materials/commodity-chemicals/halogens/fluorine",
                    path: "/chemicals-materials/commodity-chemicals/halogens/fluorine/fluorine-derivatives",
                    active: true,
                    meta: {
                      tags: "Fluorine Derivatives",
                      keywords: "Fluorine Derivatives",
                      description: "Fluorine Derivatives",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5253",
                        },
                        slug: "functional-fluids",
                        title: "Functional Fluids",
                        id: 8000476,
                        parent:
                          "/chemicals-materials/commodity-chemicals/halogens/fluorine/fluorine-derivatives",
                        path: "/chemicals-materials/commodity-chemicals/halogens/fluorine/fluorine-derivatives/functional-fluids",
                        active: true,
                        meta: {
                          tags: "Functional Fluids",
                          keywords: "Functional Fluids",
                          description: "Functional Fluids",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d5254",
                            },
                            slug: "engineered-fluids",
                            title: "Engineered Fluids",
                            id: 8000477,
                            parent:
                              "/chemicals-materials/commodity-chemicals/halogens/fluorine/fluorine-derivatives/functional-fluids",
                            path: "/chemicals-materials/commodity-chemicals/halogens/fluorine/fluorine-derivatives/functional-fluids/engineered-fluids",
                            active: true,
                            meta: {
                              tags: "Engineered Fluids",
                              keywords: "Engineered Fluids",
                              description: "Engineered Fluids",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5255",
                    },
                    slug: "fluorochemicals",
                    title: "Fluorochemicals",
                    id: 8000478,
                    parent:
                      "/chemicals-materials/commodity-chemicals/halogens/fluorine",
                    path: "/chemicals-materials/commodity-chemicals/halogens/fluorine/fluorochemicals",
                    active: true,
                    meta: {
                      tags: "Fluorochemicals",
                      keywords: "Fluorochemicals",
                      description: "Fluorochemicals",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5257",
                },
                slug: "iodine",
                title: "Iodine",
                id: 8000480,
                parent: "/chemicals-materials/commodity-chemicals/halogens",
                path: "/chemicals-materials/commodity-chemicals/halogens/iodine",
                active: true,
                meta: {
                  tags: "Iodine",
                  keywords: "Iodine",
                  description: "Iodine",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d525f",
            },
            slug: "peroxide",
            title: "Peroxide",
            id: 8000488,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/peroxide",
            active: true,
            meta: {
              tags: "Peroxide",
              keywords: "Peroxide",
              description: "Peroxide",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5261",
                },
                slug: "hydrogen-peroxide",
                title: "Hydrogen Peroxide",
                id: 8000490,
                parent: "/chemicals-materials/commodity-chemicals/peroxide",
                path: "/chemicals-materials/commodity-chemicals/peroxide/hydrogen-peroxide",
                active: true,
                meta: {
                  tags: "Hydrogen Peroxide",
                  keywords: "Hydrogen Peroxide",
                  description: "Hydrogen Peroxide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5260",
                },
                slug: "calcium-peroxide",
                title: "Calcium Peroxide",
                id: 8000489,
                parent: "/chemicals-materials/commodity-chemicals/peroxide",
                path: "/chemicals-materials/commodity-chemicals/peroxide/calcium-peroxide",
                active: true,
                meta: {
                  tags: "Calcium Peroxide",
                  keywords: "Calcium Peroxide",
                  description: "Calcium Peroxide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5262",
                },
                slug: "organic-peroxide",
                title: "Organic Peroxide",
                id: 8000491,
                parent: "/chemicals-materials/commodity-chemicals/peroxide",
                path: "/chemicals-materials/commodity-chemicals/peroxide/organic-peroxide",
                active: true,
                meta: {
                  tags: "Organic Peroxide",
                  keywords: "Organic Peroxide",
                  description: "Organic Peroxide",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5243",
            },
            slug: "boron",
            title: "Boron",
            id: 8000460,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/boron",
            active: true,
            meta: {
              tags: "Boron",
              keywords: "Boron",
              description: "Boron",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5244",
                },
                slug: "hexagonal-boron-nitride",
                title: "Hexagonal Boron Nitride",
                id: 8000461,
                parent: "/chemicals-materials/commodity-chemicals/boron",
                path: "/chemicals-materials/commodity-chemicals/boron/hexagonal-boron-nitride",
                active: true,
                meta: {
                  tags: "Hexagonal Boron Nitride",
                  keywords: "Hexagonal Boron Nitride",
                  description: "Hexagonal Boron Nitride",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d51c4",
            },
            slug: "aerosol",
            title: "Aerosol",
            id: 8000333,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/aerosol",
            active: true,
            meta: {
              tags: "Aerosol",
              keywords: "Aerosol",
              description: "Aerosol",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51c5",
                },
                slug: "aerosol-propellants",
                title: "Aerosol Propellants",
                id: 8000334,
                parent: "/chemicals-materials/commodity-chemicals/aerosol",
                path: "/chemicals-materials/commodity-chemicals/aerosol/aerosol-propellants",
                active: true,
                meta: {
                  tags: "Aerosol Propellants",
                  keywords: "Aerosol Propellants",
                  description: "Aerosol Propellants",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5263",
            },
            slug: "solvents",
            title: "Solvents",
            id: 8000492,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/solvents",
            active: true,
            meta: {
              tags: "Solvents",
              keywords: "Solvents",
              description: "Solvents",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d526c",
                },
                slug: "dispersing-agents",
                title: "Dispersing Agents",
                id: 8000501,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/dispersing-agents",
                active: true,
                meta: {
                  tags: "Dispersing Agents",
                  keywords: "Dispersing Agents",
                  description: "Dispersing Agents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5264",
                },
                slug: "9-decanoic-acid-methyl-ester",
                title: "9-Decanoic Acid Methyl Ester",
                id: 8000493,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/9-decanoic-acid-methyl-ester",
                active: true,
                meta: {
                  tags: "9-Decanoic Acid Methyl Ester",
                  keywords: "9-Decanoic Acid Methyl Ester",
                  description: "9-Decanoic Acid Methyl Ester",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5265",
                },
                slug: "aprotic-solvents",
                title: "Aprotic Solvents",
                id: 8000494,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/aprotic-solvents",
                active: true,
                meta: {
                  tags: "Aprotic Solvents",
                  keywords: "Aprotic Solvents",
                  description: "Aprotic Solvents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d526b",
                },
                slug: "cyclopentane",
                title: "Cyclopentane",
                id: 8000500,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/cyclopentane",
                active: true,
                meta: {
                  tags: "Cyclopentane",
                  keywords: "Cyclopentane",
                  description: "Cyclopentane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d526e",
                },
                slug: "furfural-solvents",
                title: "Furfural Solvents",
                id: 8000503,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/furfural-solvents",
                active: true,
                meta: {
                  tags: "Furfural Solvents",
                  keywords: "Furfural Solvents",
                  description: "Furfural Solvents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5267",
                },
                slug: "chemical-additives",
                title: "Chemical Additives",
                id: 8000496,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/chemical-additives",
                active: true,
                meta: {
                  tags: "Chemical Additives",
                  keywords: "Chemical Additives",
                  description: "Chemical Additives",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5268",
                    },
                    slug: "caprylic/capric-triglycerides",
                    title: "Caprylic/Capric Triglycerides",
                    id: 8000497,
                    parent:
                      "/chemicals-materials/commodity-chemicals/solvents/chemical-additives",
                    path: "/chemicals-materials/commodity-chemicals/solvents/chemical-additives/caprylic/capric-triglycerides",
                    active: true,
                    meta: {
                      tags: "Caprylic/Capric Triglycerides",
                      keywords: "Caprylic/Capric Triglycerides",
                      description: "Caprylic/Capric Triglycerides",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5269",
                    },
                    slug: "crude-sulfate-turpentine",
                    title: "Crude Sulfate Turpentine",
                    id: 8000498,
                    parent:
                      "/chemicals-materials/commodity-chemicals/solvents/chemical-additives",
                    path: "/chemicals-materials/commodity-chemicals/solvents/chemical-additives/crude-sulfate-turpentine",
                    active: true,
                    meta: {
                      tags: "Crude Sulfate Turpentine",
                      keywords: "Crude Sulfate Turpentine",
                      description: "Crude Sulfate Turpentine",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d526a",
                },
                slug: "chptac",
                title: "CHPTAC",
                id: 8000499,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/chptac",
                active: true,
                meta: {
                  tags: "CHPTAC",
                  keywords: "CHPTAC",
                  description: "CHPTAC",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d526d",
                },
                slug: "ethyl-acetate",
                title: "Ethyl Acetate",
                id: 8000502,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/ethyl-acetate",
                active: true,
                meta: {
                  tags: "Ethyl Acetate",
                  keywords: "Ethyl Acetate",
                  description: "Ethyl Acetate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5266",
                },
                slug: "c2-chlorinated-solvents",
                title: "C2 Chlorinated Solvents",
                id: 8000495,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/c2-chlorinated-solvents",
                active: true,
                meta: {
                  tags: "C2 Chlorinated Solvents",
                  keywords: "C2 Chlorinated Solvents",
                  description: "C2 Chlorinated Solvents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5270",
                },
                slug: "ionic-liquid",
                title: "Ionic Liquid",
                id: 8000505,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/ionic-liquid",
                active: true,
                meta: {
                  tags: "Ionic Liquid",
                  keywords: "Ionic Liquid",
                  description: "Ionic Liquid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5273",
                },
                slug: "methyl-tertiary-butyl-ether-mtbe",
                title: "Methyl Tertiary Butyl Ether (MTBE)",
                id: 8000508,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/methyl-tertiary-butyl-ether-mtbe",
                active: true,
                meta: {
                  tags: "Methyl Tertiary Butyl Ether (MTBE)",
                  keywords: "Methyl Tertiary Butyl Ether (MTBE)",
                  description: "Methyl Tertiary Butyl Ether (MTBE)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d527a",
                },
                slug: "white-spirit",
                title: "White Spirit",
                id: 8000515,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/white-spirit",
                active: true,
                meta: {
                  tags: "White Spirit",
                  keywords: "White Spirit",
                  description: "White Spirit",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d526f",
                },
                slug: "green-and-bio-based-solvents",
                title: "Green and Bio based Solvents",
                id: 8000504,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/green-and-bio-based-solvents",
                active: true,
                meta: {
                  tags: "Green and Bio based Solvents",
                  keywords: "Green and Bio based Solvents",
                  description: "Green and Bio based Solvents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5274",
                },
                slug: "oxygenated-solvents",
                title: "Oxygenated Solvents",
                id: 8000509,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/oxygenated-solvents",
                active: true,
                meta: {
                  tags: "Oxygenated Solvents",
                  keywords: "Oxygenated Solvents",
                  description: "Oxygenated Solvents",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5275",
                    },
                    slug: "alcohols",
                    title: "Alcohols",
                    id: 8000510,
                    parent:
                      "/chemicals-materials/commodity-chemicals/solvents/oxygenated-solvents",
                    path: "/chemicals-materials/commodity-chemicals/solvents/oxygenated-solvents/alcohols",
                    active: true,
                    meta: {
                      tags: "Alcohols",
                      keywords: "Alcohols",
                      description: "Alcohols",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5277",
                        },
                        slug: "methyl-isobutyl-ketone-mibk",
                        title: "Methyl Isobutyl Ketone (MIBK)",
                        id: 8000512,
                        parent:
                          "/chemicals-materials/commodity-chemicals/solvents/oxygenated-solvents/alcohols",
                        path: "/chemicals-materials/commodity-chemicals/solvents/oxygenated-solvents/alcohols/methyl-isobutyl-ketone-mibk",
                        active: true,
                        meta: {
                          tags: "Methyl Isobutyl Ketone (MIBK)",
                          keywords: "Methyl Isobutyl Ketone (MIBK)",
                          description: "Methyl Isobutyl Ketone (MIBK)",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5276",
                        },
                        slug: "methyl-isobutyl-carbinol-mibc",
                        title: "Methyl Isobutyl Carbinol (MIBC)",
                        id: 8000511,
                        parent:
                          "/chemicals-materials/commodity-chemicals/solvents/oxygenated-solvents/alcohols",
                        path: "/chemicals-materials/commodity-chemicals/solvents/oxygenated-solvents/alcohols/methyl-isobutyl-carbinol-mibc",
                        active: true,
                        meta: {
                          tags: "Methyl Isobutyl Carbinol (MIBC)",
                          keywords: "Methyl Isobutyl Carbinol (MIBC)",
                          description: "Methyl Isobutyl Carbinol (MIBC)",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5271",
                },
                slug: "lithium-ion-battery-solvents",
                title: "Lithium-ion Battery Solvents",
                id: 8000506,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/lithium-ion-battery-solvents",
                active: true,
                meta: {
                  tags: "Lithium-ion Battery Solvents",
                  keywords: "Lithium-ion Battery Solvents",
                  description: "Lithium-ion Battery Solvents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5272",
                },
                slug: "methyl-ethyl-ketone",
                title: "Methyl Ethyl Ketone",
                id: 8000507,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/methyl-ethyl-ketone",
                active: true,
                meta: {
                  tags: "Methyl Ethyl Ketone",
                  keywords: "Methyl Ethyl Ketone",
                  description: "Methyl Ethyl Ketone",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5278",
                },
                slug: "special-boiling-solvents",
                title: "Special Boiling Solvents",
                id: 8000513,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/special-boiling-solvents",
                active: true,
                meta: {
                  tags: "Special Boiling Solvents",
                  keywords: "Special Boiling Solvents",
                  description: "Special Boiling Solvents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5279",
                },
                slug: "tetrahydrofuran-thf",
                title: "Tetrahydrofuran (THF)",
                id: 8000514,
                parent: "/chemicals-materials/commodity-chemicals/solvents",
                path: "/chemicals-materials/commodity-chemicals/solvents/tetrahydrofuran-thf",
                active: true,
                meta: {
                  tags: "Tetrahydrofuran (THF)",
                  keywords: "Tetrahydrofuran (THF)",
                  description: "Tetrahydrofuran (THF)",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d51c6",
            },
            slug: "agrichemicals",
            title: "Agrichemicals",
            id: 8000335,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/agrichemicals",
            active: true,
            meta: {
              tags: "Agrichemicals",
              keywords: "Agrichemicals",
              description: "Agrichemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51cb",
                },
                slug: "herbicide",
                title: "Herbicide",
                id: 8000340,
                parent:
                  "/chemicals-materials/commodity-chemicals/agrichemicals",
                path: "/chemicals-materials/commodity-chemicals/agrichemicals/herbicide",
                active: true,
                meta: {
                  tags: "Herbicide",
                  keywords: "Herbicide",
                  description: "Herbicide",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51cc",
                    },
                    slug: "penoxsulam",
                    title: "Penoxsulam",
                    id: 8000341,
                    parent:
                      "/chemicals-materials/commodity-chemicals/agrichemicals/herbicide",
                    path: "/chemicals-materials/commodity-chemicals/agrichemicals/herbicide/penoxsulam",
                    active: true,
                    meta: {
                      tags: "Penoxsulam",
                      keywords: "Penoxsulam",
                      description: "Penoxsulam",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51cd",
                },
                slug: "iron-phosphate",
                title: "Iron Phosphate",
                id: 8000342,
                parent:
                  "/chemicals-materials/commodity-chemicals/agrichemicals",
                path: "/chemicals-materials/commodity-chemicals/agrichemicals/iron-phosphate",
                active: true,
                meta: {
                  tags: "Iron Phosphate",
                  keywords: "Iron Phosphate",
                  description: "Iron Phosphate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51c8",
                },
                slug: "copper-ammonium-carbonate",
                title: "Copper Ammonium Carbonate",
                id: 8000337,
                parent:
                  "/chemicals-materials/commodity-chemicals/agrichemicals",
                path: "/chemicals-materials/commodity-chemicals/agrichemicals/copper-ammonium-carbonate",
                active: true,
                meta: {
                  tags: "Copper Ammonium Carbonate",
                  keywords: "Copper Ammonium Carbonate",
                  description: "Copper Ammonium Carbonate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51c9",
                },
                slug: "diammonium-hydrogen-phosphate",
                title: "Diammonium Hydrogen Phosphate",
                id: 8000338,
                parent:
                  "/chemicals-materials/commodity-chemicals/agrichemicals",
                path: "/chemicals-materials/commodity-chemicals/agrichemicals/diammonium-hydrogen-phosphate",
                active: true,
                meta: {
                  tags: "Diammonium Hydrogen Phosphate",
                  keywords: "Diammonium Hydrogen Phosphate",
                  description: "Diammonium Hydrogen Phosphate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51ce",
                },
                slug: "isoxaflutole",
                title: "Isoxaflutole",
                id: 8000343,
                parent:
                  "/chemicals-materials/commodity-chemicals/agrichemicals",
                path: "/chemicals-materials/commodity-chemicals/agrichemicals/isoxaflutole",
                active: true,
                meta: {
                  tags: "Isoxaflutole",
                  keywords: "Isoxaflutole",
                  description: "Isoxaflutole",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51c7",
                },
                slug: "calcium-nitrate",
                title: "Calcium Nitrate",
                id: 8000336,
                parent:
                  "/chemicals-materials/commodity-chemicals/agrichemicals",
                path: "/chemicals-materials/commodity-chemicals/agrichemicals/calcium-nitrate",
                active: true,
                meta: {
                  tags: "Calcium Nitrate",
                  keywords: "Calcium Nitrate",
                  description: "Calcium Nitrate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d51ca",
                },
                slug: "foliar-spray",
                title: "Foliar Spray",
                id: 8000339,
                parent:
                  "/chemicals-materials/commodity-chemicals/agrichemicals",
                path: "/chemicals-materials/commodity-chemicals/agrichemicals/foliar-spray",
                active: true,
                meta: {
                  tags: "Foliar Spray",
                  keywords: "Foliar Spray",
                  description: "Foliar Spray",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5247",
            },
            slug: "cyanides",
            title: "Cyanides",
            id: 8000464,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/cyanides",
            active: true,
            meta: {
              tags: "Cyanides",
              keywords: "Cyanides",
              description: "Cyanides",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5248",
                },
                slug: "gold-potassium-cyanide",
                title: "Gold Potassium Cyanide",
                id: 8000465,
                parent: "/chemicals-materials/commodity-chemicals/cyanides",
                path: "/chemicals-materials/commodity-chemicals/cyanides/gold-potassium-cyanide",
                active: true,
                meta: {
                  tags: "Gold Potassium Cyanide",
                  keywords: "Gold Potassium Cyanide",
                  description: "Gold Potassium Cyanide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d524a",
                },
                slug: "sodium-cyanide",
                title: "Sodium Cyanide",
                id: 8000467,
                parent: "/chemicals-materials/commodity-chemicals/cyanides",
                path: "/chemicals-materials/commodity-chemicals/cyanides/sodium-cyanide",
                active: true,
                meta: {
                  tags: "Sodium Cyanide",
                  keywords: "Sodium Cyanide",
                  description: "Sodium Cyanide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5249",
                },
                slug: "hydrogen-cyanide",
                title: "Hydrogen Cyanide",
                id: 8000466,
                parent: "/chemicals-materials/commodity-chemicals/cyanides",
                path: "/chemicals-materials/commodity-chemicals/cyanides/hydrogen-cyanide",
                active: true,
                meta: {
                  tags: "Hydrogen Cyanide",
                  keywords: "Hydrogen Cyanide",
                  description: "Hydrogen Cyanide",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5258",
            },
            slug: "industrial-salts",
            title: "Industrial Salts",
            id: 8000481,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/industrial-salts",
            active: true,
            meta: {
              tags: "Industrial Salts",
              keywords: "Industrial Salts",
              description: "Industrial Salts",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d525b",
                },
                slug: "magnesium-stearate",
                title: "Magnesium Stearate",
                id: 8000484,
                parent:
                  "/chemicals-materials/commodity-chemicals/industrial-salts",
                path: "/chemicals-materials/commodity-chemicals/industrial-salts/magnesium-stearate",
                active: true,
                meta: {
                  tags: "Magnesium Stearate",
                  keywords: "Magnesium Stearate",
                  description: "Magnesium Stearate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5259",
                },
                slug: "calcium-propionate",
                title: "Calcium Propionate",
                id: 8000482,
                parent:
                  "/chemicals-materials/commodity-chemicals/industrial-salts",
                path: "/chemicals-materials/commodity-chemicals/industrial-salts/calcium-propionate",
                active: true,
                meta: {
                  tags: "Calcium Propionate",
                  keywords: "Calcium Propionate",
                  description: "Calcium Propionate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d525e",
                },
                slug: "sodium-thiosulphate",
                title: "Sodium Thiosulphate",
                id: 8000487,
                parent:
                  "/chemicals-materials/commodity-chemicals/industrial-salts",
                path: "/chemicals-materials/commodity-chemicals/industrial-salts/sodium-thiosulphate",
                active: true,
                meta: {
                  tags: "Sodium Thiosulphate",
                  keywords: "Sodium Thiosulphate",
                  description: "Sodium Thiosulphate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d525d",
                },
                slug: "sodium-chloride",
                title: "Sodium Chloride",
                id: 8000486,
                parent:
                  "/chemicals-materials/commodity-chemicals/industrial-salts",
                path: "/chemicals-materials/commodity-chemicals/industrial-salts/sodium-chloride",
                active: true,
                meta: {
                  tags: "Sodium Chloride",
                  keywords: "Sodium Chloride",
                  description: "Sodium Chloride",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d525c",
                },
                slug: "metallic-stearate",
                title: "Metallic Stearate",
                id: 8000485,
                parent:
                  "/chemicals-materials/commodity-chemicals/industrial-salts",
                path: "/chemicals-materials/commodity-chemicals/industrial-salts/metallic-stearate",
                active: true,
                meta: {
                  tags: "Metallic Stearate",
                  keywords: "Metallic Stearate",
                  description: "Metallic Stearate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d525a",
                },
                slug: "calcium-stearate",
                title: "Calcium Stearate",
                id: 8000483,
                parent:
                  "/chemicals-materials/commodity-chemicals/industrial-salts",
                path: "/chemicals-materials/commodity-chemicals/industrial-salts/calcium-stearate",
                active: true,
                meta: {
                  tags: "Calcium Stearate",
                  keywords: "Calcium Stearate",
                  description: "Calcium Stearate",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d527c",
            },
            slug: "waxes",
            title: "Waxes",
            id: 8000517,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/waxes",
            active: true,
            meta: {
              tags: "Waxes",
              keywords: "Waxes",
              description: "Waxes",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d527e",
                },
                slug: "microcrystalline-wax",
                title: "Microcrystalline Wax",
                id: 8000519,
                parent: "/chemicals-materials/commodity-chemicals/waxes",
                path: "/chemicals-materials/commodity-chemicals/waxes/microcrystalline-wax",
                active: true,
                meta: {
                  tags: "Microcrystalline Wax",
                  keywords: "Microcrystalline Wax",
                  description: "Microcrystalline Wax",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5280",
                },
                slug: "slack-wax",
                title: "Slack Wax",
                id: 8000521,
                parent: "/chemicals-materials/commodity-chemicals/waxes",
                path: "/chemicals-materials/commodity-chemicals/waxes/slack-wax",
                active: true,
                meta: {
                  tags: "Slack Wax",
                  keywords: "Slack Wax",
                  description: "Slack Wax",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5281",
                },
                slug: "wax-additives",
                title: "Wax Additives",
                id: 8000522,
                parent: "/chemicals-materials/commodity-chemicals/waxes",
                path: "/chemicals-materials/commodity-chemicals/waxes/wax-additives",
                active: true,
                meta: {
                  tags: "Wax Additives",
                  keywords: "Wax Additives",
                  description: "Wax Additives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d527d",
                },
                slug: "lanolin",
                title: "Lanolin",
                id: 8000518,
                parent: "/chemicals-materials/commodity-chemicals/waxes",
                path: "/chemicals-materials/commodity-chemicals/waxes/lanolin",
                active: true,
                meta: {
                  tags: "Lanolin",
                  keywords: "Lanolin",
                  description: "Lanolin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d527f",
                },
                slug: "polyethylene-wax",
                title: "Polyethylene Wax",
                id: 8000520,
                parent: "/chemicals-materials/commodity-chemicals/waxes",
                path: "/chemicals-materials/commodity-chemicals/waxes/polyethylene-wax",
                active: true,
                meta: {
                  tags: "Polyethylene Wax",
                  keywords: "Polyethylene Wax",
                  description: "Polyethylene Wax",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d527b",
            },
            slug: "thiochemicals",
            title: "Thiochemicals",
            id: 8000516,
            parent: "/chemicals-materials/commodity-chemicals",
            path: "/chemicals-materials/commodity-chemicals/thiochemicals",
            active: true,
            meta: {
              tags: "Thiochemicals",
              keywords: "Thiochemicals",
              description: "Thiochemicals",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b186",
        },
        id: 6150,
        title: "Water and Wastewater Treatment",
        slug: "water-and-wastewater-treatment",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Water & Wastewater Treatment Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Water & Wastewater Treatment Market Research Reports, water & wastewater treatment market, water & wastewater treatment market report, water & wastewater treatment market research, water & wastewater treatment industry, water & wastewater treatment industry report, water & wastewater treatment industry research",
          tags: "Water & Wastewater Treatment Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Wastewater treatment is closely related to the standards and/or expectations set for the effluent quality. Wastewater treatment processes are designed to achieve improvements in the quality of the wastewater. From water treatment technologies to water treatment chemicals, MI covers a wide spectrum of water and wastewater treatment market.",
          short_description:
            "Wastewater treatment is closely related to the standards set for effluent quality. Treatment processes are designed to improve the quality of water for industrial and household use. Mordor Intelligence™ covers a wide spectrum of the market, encompassing many technologies and treatment chemicals.",
        },
        picture: null,
        __v: 0,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/water-and-wastewater-treatment",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5605",
            },
            slug: "biosolids",
            title: "Biosolids",
            id: 8001427,
            parent: "/chemicals-materials/water-and-wastewater-treatment",
            path: "/chemicals-materials/water-and-wastewater-treatment/biosolids",
            active: true,
            meta: {
              tags: "Biosolids",
              keywords: "Biosolids",
              description: "Biosolids",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5606",
            },
            slug: "water-and-wastewater-treatment-technologies",
            title: "Water and Wastewater Treatment Technologies",
            id: 8001428,
            parent: "/chemicals-materials/water-and-wastewater-treatment",
            path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
            active: true,
            meta: {
              tags: "Water and Wastewater Treatment Technologies",
              keywords: "Water and Wastewater Treatment Technologies",
              description: "Water and Wastewater Treatment Technologies",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5607",
                },
                slug: "agriculture-wastewater-treatment",
                title: "Agriculture Wastewater Treatment",
                id: 8001429,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/agriculture-wastewater-treatment",
                active: true,
                meta: {
                  tags: "Agriculture Wastewater Treatment",
                  keywords: "Agriculture Wastewater Treatment",
                  description: "Agriculture Wastewater Treatment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5608",
                },
                slug: "ballast-water-treatment",
                title: "Ballast Water Treatment",
                id: 8001430,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/ballast-water-treatment",
                active: true,
                meta: {
                  tags: "Ballast Water Treatment",
                  keywords: "Ballast Water Treatment",
                  description: "Ballast Water Treatment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d560c",
                },
                slug: "liquid-waster-management",
                title: "Liquid Waster Management",
                id: 8001434,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/liquid-waster-management",
                active: true,
                meta: {
                  tags: "Liquid Waster Management",
                  keywords: "Liquid Waster Management",
                  description: "Liquid Waster Management",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d560d",
                },
                slug: "membrane-water-and-wastewater-treatment",
                title: "Membrane Water and Wastewater Treatment",
                id: 8001435,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment",
                active: true,
                meta: {
                  tags: "Membrane Water and Wastewater Treatment",
                  keywords: "Membrane Water and Wastewater Treatment",
                  description: "Membrane Water and Wastewater Treatment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d560f",
                    },
                    slug: "melt-blown-polypropylene-filters",
                    title: "Melt Blown Polypropylene Filters",
                    id: 8001437,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment/melt-blown-polypropylene-filters",
                    active: true,
                    meta: {
                      tags: "Melt Blown Polypropylene Filters",
                      keywords: "Melt Blown Polypropylene Filters",
                      description: "Melt Blown Polypropylene Filters",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5610",
                    },
                    slug: "membrane-separation-technology",
                    title: "Membrane Separation Technology",
                    id: 8001438,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment/membrane-separation-technology",
                    active: true,
                    meta: {
                      tags: "Membrane Separation Technology",
                      keywords: "Membrane Separation Technology",
                      description: "Membrane Separation Technology",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5612",
                    },
                    slug: "spiral-membrane",
                    title: "Spiral Membrane",
                    id: 8001440,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment/spiral-membrane",
                    active: true,
                    meta: {
                      tags: "Spiral Membrane",
                      keywords: "Spiral Membrane",
                      description: "Spiral Membrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5613",
                    },
                    slug: "tubular-membrane",
                    title: "Tubular Membrane",
                    id: 8001441,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment/tubular-membrane",
                    active: true,
                    meta: {
                      tags: "Tubular Membrane",
                      keywords: "Tubular Membrane",
                      description: "Tubular Membrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d560e",
                    },
                    slug: "ion-exchange-membrane",
                    title: "Ion Exchange Membrane",
                    id: 8001436,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment/ion-exchange-membrane",
                    active: true,
                    meta: {
                      tags: "Ion Exchange Membrane",
                      keywords: "Ion Exchange Membrane",
                      description: "Ion Exchange Membrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5611",
                    },
                    slug: "reverse-osmosis-membrane",
                    title: "Reverse Osmosis Membrane",
                    id: 8001439,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/membrane-water-and-wastewater-treatment/reverse-osmosis-membrane",
                    active: true,
                    meta: {
                      tags: "Reverse Osmosis Membrane",
                      keywords: "Reverse Osmosis Membrane",
                      description: "Reverse Osmosis Membrane",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5614",
                },
                slug: "moving-bed-bioreactor-mbbr",
                title: "Moving Bed Bioreactor (MBBR)",
                id: 8001442,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/moving-bed-bioreactor-mbbr",
                active: true,
                meta: {
                  tags: "Moving Bed Bioreactor (MBBR)",
                  keywords: "Moving Bed Bioreactor (MBBR)",
                  description: "Moving Bed Bioreactor (MBBR)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5615",
                },
                slug: "zero-liquid-discharge-zld-system",
                title: "Zero Liquid Discharge (ZLD) System",
                id: 8001443,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/zero-liquid-discharge-zld-system",
                active: true,
                meta: {
                  tags: "Zero Liquid Discharge (ZLD) System",
                  keywords: "Zero Liquid Discharge (ZLD) System",
                  description: "Zero Liquid Discharge (ZLD) System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5609",
                },
                slug: "desalination-system",
                title: "Desalination System",
                id: 8001431,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/desalination-system",
                active: true,
                meta: {
                  tags: "Desalination System",
                  keywords: "Desalination System",
                  description: "Desalination System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d560a",
                },
                slug: "electrodeionization-edi-technology",
                title: "Electrodeionization (EDI) Technology",
                id: 8001432,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/electrodeionization-edi-technology",
                active: true,
                meta: {
                  tags: "Electrodeionization (EDI) Technology",
                  keywords: "Electrodeionization (EDI) Technology",
                  description: "Electrodeionization (EDI) Technology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d560b",
                },
                slug: "fracking-water-treatment",
                title: "Fracking Water Treatment",
                id: 8001433,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-and-wastewater-treatment-technologies/fracking-water-treatment",
                active: true,
                meta: {
                  tags: "Fracking Water Treatment",
                  keywords: "Fracking Water Treatment",
                  description: "Fracking Water Treatment",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5616",
            },
            slug: "water-treatment-chemicals",
            title: "Water Treatment Chemicals",
            id: 8001444,
            parent: "/chemicals-materials/water-and-wastewater-treatment",
            path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
            active: true,
            meta: {
              tags: "Water Treatment Chemicals",
              keywords: "Water Treatment Chemicals",
              description: "Water Treatment Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5619",
                },
                slug: "hydrazine",
                title: "Hydrazine",
                id: 8001447,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/hydrazine",
                active: true,
                meta: {
                  tags: "Hydrazine",
                  keywords: "Hydrazine",
                  description: "Hydrazine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5617",
                },
                slug: "defoamers",
                title: "Defoamers",
                id: 8001445,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/defoamers",
                active: true,
                meta: {
                  tags: "Defoamers",
                  keywords: "Defoamers",
                  description: "Defoamers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5618",
                },
                slug: "ferric-chloride",
                title: "Ferric Chloride",
                id: 8001446,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/ferric-chloride",
                active: true,
                meta: {
                  tags: "Ferric Chloride",
                  keywords: "Ferric Chloride",
                  description: "Ferric Chloride",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5620",
                },
                slug: "municipal-water-treatment-chemicals",
                title: "Municipal Water Treatment Chemicals",
                id: 8001454,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/municipal-water-treatment-chemicals",
                active: true,
                meta: {
                  tags: "Municipal Water Treatment Chemicals",
                  keywords: "Municipal Water Treatment Chemicals",
                  description: "Municipal Water Treatment Chemicals",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5621",
                },
                slug: "oxygen-scavengers",
                title: "Oxygen Scavengers",
                id: 8001455,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/oxygen-scavengers",
                active: true,
                meta: {
                  tags: "Oxygen Scavengers",
                  keywords: "Oxygen Scavengers",
                  description: "Oxygen Scavengers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d561a",
                },
                slug: "industrial-water-treatment-chemicals",
                title: "Industrial Water Treatment Chemicals",
                id: 8001448,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/industrial-water-treatment-chemicals",
                active: true,
                meta: {
                  tags: "Industrial Water Treatment Chemicals",
                  keywords: "Industrial Water Treatment Chemicals",
                  description: "Industrial Water Treatment Chemicals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d561b",
                    },
                    slug: "boiler-water-treatment-chemicals",
                    title: "Boiler Water Treatment Chemicals",
                    id: 8001449,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/industrial-water-treatment-chemicals",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/industrial-water-treatment-chemicals/boiler-water-treatment-chemicals",
                    active: true,
                    meta: {
                      tags: "Boiler Water Treatment Chemicals",
                      keywords: "Boiler Water Treatment Chemicals",
                      description: "Boiler Water Treatment Chemicals",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d561c",
                    },
                    slug: "cooling-water-treatment-chemicals",
                    title: "Cooling Water Treatment Chemicals",
                    id: 8001450,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/industrial-water-treatment-chemicals",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/industrial-water-treatment-chemicals/cooling-water-treatment-chemicals",
                    active: true,
                    meta: {
                      tags: "Cooling Water Treatment Chemicals",
                      keywords: "Cooling Water Treatment Chemicals",
                      description: "Cooling Water Treatment Chemicals",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d561e",
                    },
                    slug: "oil-ans-gas-water-treatment-chemicals",
                    title: "Oil ans Gas Water Treatment Chemicals",
                    id: 8001452,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/industrial-water-treatment-chemicals",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/industrial-water-treatment-chemicals/oil-ans-gas-water-treatment-chemicals",
                    active: true,
                    meta: {
                      tags: "Oil ans Gas Water Treatment Chemicals",
                      keywords: "Oil ans Gas Water Treatment Chemicals",
                      description: "Oil ans Gas Water Treatment Chemicals",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d561d",
                    },
                    slug: "industrial-sludge-treatment-chemicals",
                    title: "Industrial Sludge Treatment Chemicals",
                    id: 8001451,
                    parent:
                      "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/industrial-water-treatment-chemicals",
                    path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/industrial-water-treatment-chemicals/industrial-sludge-treatment-chemicals",
                    active: true,
                    meta: {
                      tags: "Industrial Sludge Treatment Chemicals",
                      keywords: "Industrial Sludge Treatment Chemicals",
                      description: "Industrial Sludge Treatment Chemicals",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5622",
                },
                slug: "poly-aluminum-chloride",
                title: "Poly Aluminum Chloride",
                id: 8001456,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/poly-aluminum-chloride",
                active: true,
                meta: {
                  tags: "Poly Aluminum Chloride",
                  keywords: "Poly Aluminum Chloride",
                  description: "Poly Aluminum Chloride",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5623",
                },
                slug: "potassium-permanganate",
                title: "Potassium Permanganate",
                id: 8001457,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/potassium-permanganate",
                active: true,
                meta: {
                  tags: "Potassium Permanganate",
                  keywords: "Potassium Permanganate",
                  description: "Potassium Permanganate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d561f",
                },
                slug: "membrane-water-treatment-chemicals",
                title: "Membrane Water Treatment Chemicals",
                id: 8001453,
                parent:
                  "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals",
                path: "/chemicals-materials/water-and-wastewater-treatment/water-treatment-chemicals/membrane-water-treatment-chemicals",
                active: true,
                meta: {
                  tags: "Membrane Water Treatment Chemicals",
                  keywords: "Membrane Water Treatment Chemicals",
                  description: "Membrane Water Treatment Chemicals",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b187",
        },
        id: 6152,
        title: "Advanced Materials",
        slug: "advanced-materials",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Advanced Materials Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Advanced Materials Market Research Reports, Advanced Materials Market, Advanced Materials Market Report, Advanced Materials Market Research, Advanced Materials Industry, PCTIM market, Aramid Fiber Market, Prepreg Market, Composite Metal Finishing Market, Titanium-Dioxide Nanomaterials Market, Geofoams Market, High-Performance Fibers Market, Nanotubes Market, Nanowires Market, Nano-Metal Oxides Market, Non-Polymeric Organic Nanomaterials Market, Marine Composite Market, Nanometals Market, Smart Polymers Market",
          tags: "Advanced Materials Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Materials is a very inclusive term, since it is the basic building block of all physical products. Advanced materials refer to all new materials and modifications to existing materials to obtain superior performance in one or more characteristics that are critical for the application under consideration.",
          short_description:
            "Material is an inclusive term as materials form the building blocks of any physical product. Advanced materials include new and modified existing materials, which enhance mission-critical characteristics required for specific application areas. Browse through our reports to learn more.",
        },
        picture: null,
        __v: 0,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/advanced-materials",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d510c",
            },
            slug: "diamond-wire",
            title: "Diamond Wire",
            id: 8000148,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/diamond-wire",
            active: true,
            meta: {
              tags: "Diamond Wire",
              keywords: "Diamond Wire",
              description: "Diamond Wire",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50c6",
            },
            slug: "aerospace-foam",
            title: "Aerospace Foam",
            id: 8000078,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/aerospace-foam",
            active: true,
            meta: {
              tags: "Aerospace Foam",
              keywords: "Aerospace Foam",
              description: "Aerospace Foam",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50c7",
            },
            slug: "aerospace-materials",
            title: "Aerospace Materials",
            id: 8000079,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/aerospace-materials",
            active: true,
            meta: {
              tags: "Aerospace Materials",
              keywords: "Aerospace Materials",
              description: "Aerospace Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50c8",
            },
            slug: "barrier-materials",
            title: "Barrier Materials",
            id: 8000080,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/barrier-materials",
            active: true,
            meta: {
              tags: "Barrier Materials",
              keywords: "Barrier Materials",
              description: "Barrier Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5110",
            },
            slug: "high-performance-foam",
            title: "High performance Foam",
            id: 8000152,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/high-performance-foam",
            active: true,
            meta: {
              tags: "High performance Foam",
              keywords: "High performance Foam",
              description: "High performance Foam",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5112",
            },
            slug: "metamaterials",
            title: "MetaMaterials",
            id: 8000154,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/metamaterials",
            active: true,
            meta: {
              tags: "MetaMaterials",
              keywords: "MetaMaterials",
              description: "MetaMaterials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d512d",
            },
            slug: "shape-memory-materials",
            title: "Shape Memory Materials",
            id: 8000181,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/shape-memory-materials",
            active: true,
            meta: {
              tags: "Shape Memory Materials",
              keywords: "Shape Memory Materials",
              description: "Shape Memory Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50c9",
            },
            slug: "bioactive-materials",
            title: "Bioactive Materials",
            id: 8000081,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/bioactive-materials",
            active: true,
            meta: {
              tags: "Bioactive Materials",
              keywords: "Bioactive Materials",
              description: "Bioactive Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d510b",
            },
            slug: "conductive-inks",
            title: "Conductive Inks",
            id: 8000147,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/conductive-inks",
            active: true,
            meta: {
              tags: "Conductive Inks",
              keywords: "Conductive Inks",
              description: "Conductive Inks",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5129",
            },
            slug: "piezoelectric-smart-materials",
            title: "Piezoelectric Smart Materials",
            id: 8000177,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/piezoelectric-smart-materials",
            active: true,
            meta: {
              tags: "Piezoelectric Smart Materials",
              keywords: "Piezoelectric Smart Materials",
              description: "Piezoelectric Smart Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d512e",
            },
            slug: "thermochromic-materials",
            title: "Thermochromic Materials",
            id: 8000182,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/thermochromic-materials",
            active: true,
            meta: {
              tags: "Thermochromic Materials",
              keywords: "Thermochromic Materials",
              description: "Thermochromic Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d510f",
            },
            slug: "high-heat-foam",
            title: "High heat Foam",
            id: 8000151,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/high-heat-foam",
            active: true,
            meta: {
              tags: "High heat Foam",
              keywords: "High heat Foam",
              description: "High heat Foam",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5127",
            },
            slug: "phase-change-materials",
            title: "Phase Change Materials",
            id: 8000175,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/phase-change-materials",
            active: true,
            meta: {
              tags: "Phase Change Materials",
              keywords: "Phase Change Materials",
              description: "Phase Change Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5128",
            },
            slug: "phase-change-thermal-interface-materials",
            title: "Phase Change Thermal Interface Materials",
            id: 8000176,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/phase-change-thermal-interface-materials",
            active: true,
            meta: {
              tags: "Phase Change Thermal Interface Materials",
              keywords: "Phase Change Thermal Interface Materials",
              description: "Phase Change Thermal Interface Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50c5",
            },
            slug: "advanced-functional-materials",
            title: "Advanced Functional Materials",
            id: 8000077,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/advanced-functional-materials",
            active: true,
            meta: {
              tags: "Advanced Functional Materials",
              keywords: "Advanced Functional Materials",
              description: "Advanced Functional Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d510d",
            },
            slug: "electrochromic-materials",
            title: "Electrochromic Materials",
            id: 8000149,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/electrochromic-materials",
            active: true,
            meta: {
              tags: "Electrochromic Materials",
              keywords: "Electrochromic Materials",
              description: "Electrochromic Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5111",
            },
            slug: "lasers",
            title: "Lasers",
            id: 8000153,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/lasers",
            active: true,
            meta: {
              tags: "Lasers",
              keywords: "Lasers",
              description: "Lasers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5113",
            },
            slug: "nanomaterials",
            title: "NanoMaterials",
            id: 8000155,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/nanomaterials",
            active: true,
            meta: {
              tags: "NanoMaterials",
              keywords: "NanoMaterials",
              description: "NanoMaterials",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d511b",
                },
                slug: "nano-oxide",
                title: "Nano Oxide",
                id: 8000163,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/nano-oxide",
                active: true,
                meta: {
                  tags: "Nano Oxide",
                  keywords: "Nano Oxide",
                  description: "Nano Oxide",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d511d",
                    },
                    slug: "metal-oxide-nanoparticles",
                    title: "Metal Oxide Nanoparticles",
                    id: 8000165,
                    parent:
                      "/chemicals-materials/advanced-materials/nanomaterials/nano-oxide",
                    path: "/chemicals-materials/advanced-materials/nanomaterials/nano-oxide/metal-oxide-nanoparticles",
                    active: true,
                    meta: {
                      tags: "Metal Oxide Nanoparticles",
                      keywords: "Metal Oxide Nanoparticles",
                      description: "Metal Oxide Nanoparticles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d511c",
                    },
                    slug: "cerium-oxide-nanoparticles",
                    title: "Cerium Oxide Nanoparticles",
                    id: 8000164,
                    parent:
                      "/chemicals-materials/advanced-materials/nanomaterials/nano-oxide",
                    path: "/chemicals-materials/advanced-materials/nanomaterials/nano-oxide/cerium-oxide-nanoparticles",
                    active: true,
                    meta: {
                      tags: "Cerium Oxide Nanoparticles",
                      keywords: "Cerium Oxide Nanoparticles",
                      description: "Cerium Oxide Nanoparticles",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d511e",
                },
                slug: "nanomembrane",
                title: "NanoMembrane",
                id: 8000166,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/nanomembrane",
                active: true,
                meta: {
                  tags: "NanoMembrane",
                  keywords: "NanoMembrane",
                  description: "NanoMembrane",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d511f",
                    },
                    slug: "nanoporous-membrane",
                    title: "Nanoporous Membrane",
                    id: 8000167,
                    parent:
                      "/chemicals-materials/advanced-materials/nanomaterials/nanomembrane",
                    path: "/chemicals-materials/advanced-materials/nanomaterials/nanomembrane/nanoporous-membrane",
                    active: true,
                    meta: {
                      tags: "Nanoporous Membrane",
                      keywords: "Nanoporous Membrane",
                      description: "Nanoporous Membrane",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5114",
                },
                slug: "carbon-nanomaterials",
                title: "Carbon NanoMaterials",
                id: 8000156,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/carbon-nanomaterials",
                active: true,
                meta: {
                  tags: "Carbon NanoMaterials",
                  keywords: "Carbon NanoMaterials",
                  description: "Carbon NanoMaterials",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5115",
                    },
                    slug: "carbon-nanotubes",
                    title: "Carbon Nanotubes",
                    id: 8000157,
                    parent:
                      "/chemicals-materials/advanced-materials/nanomaterials/carbon-nanomaterials",
                    path: "/chemicals-materials/advanced-materials/nanomaterials/carbon-nanomaterials/carbon-nanotubes",
                    active: true,
                    meta: {
                      tags: "Carbon Nanotubes",
                      keywords: "Carbon Nanotubes",
                      description: "Carbon Nanotubes",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5120",
                },
                slug: "nanosilica",
                title: "Nanosilica",
                id: 8000168,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/nanosilica",
                active: true,
                meta: {
                  tags: "Nanosilica",
                  keywords: "Nanosilica",
                  description: "Nanosilica",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5116",
                },
                slug: "gold-nanomaterials",
                title: "Gold NanoMaterials",
                id: 8000158,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/gold-nanomaterials",
                active: true,
                meta: {
                  tags: "Gold NanoMaterials",
                  keywords: "Gold NanoMaterials",
                  description: "Gold NanoMaterials",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5118",
                    },
                    slug: "gold-nanorods",
                    title: "Gold Nanorods",
                    id: 8000160,
                    parent:
                      "/chemicals-materials/advanced-materials/nanomaterials/gold-nanomaterials",
                    path: "/chemicals-materials/advanced-materials/nanomaterials/gold-nanomaterials/gold-nanorods",
                    active: true,
                    meta: {
                      tags: "Gold Nanorods",
                      keywords: "Gold Nanorods",
                      description: "Gold Nanorods",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5117",
                    },
                    slug: "gold-nanoparticles",
                    title: "Gold Nanoparticles",
                    id: 8000159,
                    parent:
                      "/chemicals-materials/advanced-materials/nanomaterials/gold-nanomaterials",
                    path: "/chemicals-materials/advanced-materials/nanomaterials/gold-nanomaterials/gold-nanoparticles",
                    active: true,
                    meta: {
                      tags: "Gold Nanoparticles",
                      keywords: "Gold Nanoparticles",
                      description: "Gold Nanoparticles",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5119",
                },
                slug: "magnesium-oxide-nanoparticle",
                title: "Magnesium Oxide Nanoparticle",
                id: 8000161,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/magnesium-oxide-nanoparticle",
                active: true,
                meta: {
                  tags: "Magnesium Oxide Nanoparticle",
                  keywords: "Magnesium Oxide Nanoparticle",
                  description: "Magnesium Oxide Nanoparticle",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5121",
                },
                slug: "nanotubes",
                title: "Nanotubes",
                id: 8000169,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/nanotubes",
                active: true,
                meta: {
                  tags: "Nanotubes",
                  keywords: "Nanotubes",
                  description: "Nanotubes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d511a",
                },
                slug: "magnetite-nanoparticles",
                title: "Magnetite Nanoparticles",
                id: 8000162,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/magnetite-nanoparticles",
                active: true,
                meta: {
                  tags: "Magnetite Nanoparticles",
                  keywords: "Magnetite Nanoparticles",
                  description: "Magnetite Nanoparticles",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5125",
                },
                slug: "titanium-dioxide-nanomaterials",
                title: "Titanium Dioxide NanoMaterials",
                id: 8000173,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/titanium-dioxide-nanomaterials",
                active: true,
                meta: {
                  tags: "Titanium Dioxide NanoMaterials",
                  keywords: "Titanium Dioxide NanoMaterials",
                  description: "Titanium Dioxide NanoMaterials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5122",
                },
                slug: "nanowires",
                title: "Nanowires",
                id: 8000170,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/nanowires",
                active: true,
                meta: {
                  tags: "Nanowires",
                  keywords: "Nanowires",
                  description: "Nanowires",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5123",
                },
                slug: "platinum-nanoparticles",
                title: "Platinum Nanoparticles",
                id: 8000171,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/platinum-nanoparticles",
                active: true,
                meta: {
                  tags: "Platinum Nanoparticles",
                  keywords: "Platinum Nanoparticles",
                  description: "Platinum Nanoparticles",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5124",
                },
                slug: "polymeric-nanoparticles",
                title: "Polymeric Nanoparticles",
                id: 8000172,
                parent: "/chemicals-materials/advanced-materials/nanomaterials",
                path: "/chemicals-materials/advanced-materials/nanomaterials/polymeric-nanoparticles",
                active: true,
                meta: {
                  tags: "Polymeric Nanoparticles",
                  keywords: "Polymeric Nanoparticles",
                  description: "Polymeric Nanoparticles",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d512a",
            },
            slug: "rapid-prototyping-materials",
            title: "Rapid Prototyping Materials",
            id: 8000178,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/rapid-prototyping-materials",
            active: true,
            meta: {
              tags: "Rapid Prototyping Materials",
              keywords: "Rapid Prototyping Materials",
              description: "Rapid Prototyping Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d512b",
            },
            slug: "retro-reflective-materials",
            title: "Retro reflective Materials",
            id: 8000179,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/retro-reflective-materials",
            active: true,
            meta: {
              tags: "Retro reflective Materials",
              keywords: "Retro reflective Materials",
              description: "Retro reflective Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d512c",
            },
            slug: "self-healing-materials",
            title: "Self Healing Materials",
            id: 8000180,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/self-healing-materials",
            active: true,
            meta: {
              tags: "Self Healing Materials",
              keywords: "Self Healing Materials",
              description: "Self Healing Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d512f",
            },
            slug: "thin-film-materials",
            title: "Thin Film Materials",
            id: 8000183,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/thin-film-materials",
            active: true,
            meta: {
              tags: "Thin Film Materials",
              keywords: "Thin Film Materials",
              description: "Thin Film Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d50ca",
            },
            slug: "composites",
            title: "Composites",
            id: 8000082,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/composites",
            active: true,
            meta: {
              tags: "Composites",
              keywords: "Composites",
              description: "Composites",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50cb",
                },
                slug: "advanced-composite-materials",
                title: "Advanced Composite Materials",
                id: 8000083,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/advanced-composite-materials",
                active: true,
                meta: {
                  tags: "Advanced Composite Materials",
                  keywords: "Advanced Composite Materials",
                  description: "Advanced Composite Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50da",
                },
                slug: "aerospace-and-defense-materials",
                title: "Aerospace and Defense Materials",
                id: 8000098,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/aerospace-and-defense-materials",
                active: true,
                meta: {
                  tags: "Aerospace and Defense Materials",
                  keywords: "Aerospace and Defense Materials",
                  description: "Aerospace and Defense Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50e1",
                },
                slug: "composite-materials",
                title: "Composite Materials",
                id: 8000105,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/composite-materials",
                active: true,
                meta: {
                  tags: "Composite Materials",
                  keywords: "Composite Materials",
                  description: "Composite Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50e5",
                },
                slug: "construction-materials",
                title: "Construction Materials",
                id: 8000109,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/construction-materials",
                active: true,
                meta: {
                  tags: "Construction Materials",
                  keywords: "Construction Materials",
                  description: "Construction Materials",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50e6",
                    },
                    slug: "construction-composites",
                    title: "Construction Composites",
                    id: 8000110,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/construction-materials",
                    path: "/chemicals-materials/advanced-materials/composites/construction-materials/construction-composites",
                    active: true,
                    meta: {
                      tags: "Construction Composites",
                      keywords: "Construction Composites",
                      description: "Construction Composites",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50e9",
                },
                slug: "fiber-reinforced-plastics-frp",
                title: "Fiber reinforced Plastics (FRP)",
                id: 8000113,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                active: true,
                meta: {
                  tags: "Fiber reinforced Plastics (FRP)",
                  keywords: "Fiber reinforced Plastics (FRP)",
                  description: "Fiber reinforced Plastics (FRP)",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50f1",
                    },
                    slug: "long-fiber-thermoset-composites",
                    title: "Long Fiber Thermoset Composites",
                    id: 8000121,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/long-fiber-thermoset-composites",
                    active: true,
                    meta: {
                      tags: "Long Fiber Thermoset Composites",
                      keywords: "Long Fiber Thermoset Composites",
                      description: "Long Fiber Thermoset Composites",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50f3",
                    },
                    slug: "polyurethane-composites",
                    title: "Polyurethane Composites",
                    id: 8000123,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/polyurethane-composites",
                    active: true,
                    meta: {
                      tags: "Polyurethane Composites",
                      keywords: "Polyurethane Composites",
                      description: "Polyurethane Composites",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ed",
                    },
                    slug: "fiber-reinforced-plastic-recycling",
                    title: "Fiber Reinforced Plastic Recycling",
                    id: 8000117,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/fiber-reinforced-plastic-recycling",
                    active: true,
                    meta: {
                      tags: "Fiber Reinforced Plastic Recycling",
                      keywords: "Fiber Reinforced Plastic Recycling",
                      description: "Fiber Reinforced Plastic Recycling",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ee",
                    },
                    slug: "fiber-reinforced-polymer-composites",
                    title: "Fiber Reinforced Polymer Composites",
                    id: 8000118,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/fiber-reinforced-polymer-composites",
                    active: true,
                    meta: {
                      tags: "Fiber Reinforced Polymer Composites",
                      keywords: "Fiber Reinforced Polymer Composites",
                      description: "Fiber Reinforced Polymer Composites",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ef",
                    },
                    slug: "frp-grating",
                    title: "FRP Grating",
                    id: 8000119,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/frp-grating",
                    active: true,
                    meta: {
                      tags: "FRP Grating",
                      keywords: "FRP Grating",
                      description: "FRP Grating",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50f0",
                    },
                    slug: "glass-fiber-reinforced-polymers",
                    title: "Glass Fiber Reinforced Polymers",
                    id: 8000120,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/glass-fiber-reinforced-polymers",
                    active: true,
                    meta: {
                      tags: "Glass Fiber Reinforced Polymers",
                      keywords: "Glass Fiber Reinforced Polymers",
                      description: "Glass Fiber Reinforced Polymers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ec",
                    },
                    slug: "fiber-reinforced-composites",
                    title: "Fiber Reinforced Composites",
                    id: 8000116,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/fiber-reinforced-composites",
                    active: true,
                    meta: {
                      tags: "Fiber Reinforced Composites",
                      keywords: "Fiber Reinforced Composites",
                      description: "Fiber Reinforced Composites",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50f2",
                    },
                    slug: "natural-fiber-reinforced-composites",
                    title: "Natural Fiber Reinforced Composites",
                    id: 8000122,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/natural-fiber-reinforced-composites",
                    active: true,
                    meta: {
                      tags: "Natural Fiber Reinforced Composites",
                      keywords: "Natural Fiber Reinforced Composites",
                      description: "Natural Fiber Reinforced Composites",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50f4",
                    },
                    slug: "short-fiber-thermoplastic-composites",
                    title: "Short Fiber Thermoplastic Composites",
                    id: 8000124,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/short-fiber-thermoplastic-composites",
                    active: true,
                    meta: {
                      tags: "Short Fiber Thermoplastic Composites",
                      keywords: "Short Fiber Thermoplastic Composites",
                      description: "Short Fiber Thermoplastic Composites",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ea",
                    },
                    slug: "carbon-fiber-reinforced-plastics",
                    title: "Carbon Fiber Reinforced Plastics",
                    id: 8000114,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/carbon-fiber-reinforced-plastics",
                    active: true,
                    meta: {
                      tags: "Carbon Fiber Reinforced Plastics",
                      keywords: "Carbon Fiber Reinforced Plastics",
                      description: "Carbon Fiber Reinforced Plastics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50eb",
                    },
                    slug: "carbon-fiber-reinforced-thermoplastics",
                    title: "Carbon Fiber Reinforced Thermoplastics",
                    id: 8000115,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp",
                    path: "/chemicals-materials/advanced-materials/composites/fiber-reinforced-plastics-frp/carbon-fiber-reinforced-thermoplastics",
                    active: true,
                    meta: {
                      tags: "Carbon Fiber Reinforced Thermoplastics",
                      keywords: "Carbon Fiber Reinforced Thermoplastics",
                      description: "Carbon Fiber Reinforced Thermoplastics",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50f5",
                },
                slug: "frp-panels-and-sheets",
                title: "FRP Panels and Sheets",
                id: 8000125,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/frp-panels-and-sheets",
                active: true,
                meta: {
                  tags: "FRP Panels and Sheets",
                  keywords: "FRP Panels and Sheets",
                  description: "FRP Panels and Sheets",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5107",
                },
                slug: "thermoset-composites",
                title: "Thermoset Composites",
                id: 8000143,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/thermoset-composites",
                active: true,
                meta: {
                  tags: "Thermoset Composites",
                  keywords: "Thermoset Composites",
                  description: "Thermoset Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5109",
                },
                slug: "wood-composites",
                title: "Wood Composites",
                id: 8000145,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/wood-composites",
                active: true,
                meta: {
                  tags: "Wood Composites",
                  keywords: "Wood Composites",
                  description: "Wood Composites",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d510a",
                    },
                    slug: "wood-plastic-composites",
                    title: "Wood Plastic Composites",
                    id: 8000146,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/wood-composites",
                    path: "/chemicals-materials/advanced-materials/composites/wood-composites/wood-plastic-composites",
                    active: true,
                    meta: {
                      tags: "Wood Plastic Composites",
                      keywords: "Wood Plastic Composites",
                      description: "Wood Plastic Composites",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50de",
                },
                slug: "carbon-composites",
                title: "Carbon Composites",
                id: 8000102,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/carbon-composites",
                active: true,
                meta: {
                  tags: "Carbon Composites",
                  keywords: "Carbon Composites",
                  description: "Carbon Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50e2",
                },
                slug: "composite-materials-in-renewable-energy",
                title: "Composite Materials in Renewable Energy",
                id: 8000106,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/composite-materials-in-renewable-energy",
                active: true,
                meta: {
                  tags: "Composite Materials in Renewable Energy",
                  keywords: "Composite Materials in Renewable Energy",
                  description: "Composite Materials in Renewable Energy",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50e4",
                },
                slug: "composite-repair",
                title: "Composite Repair",
                id: 8000108,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/composite-repair",
                active: true,
                meta: {
                  tags: "Composite Repair",
                  keywords: "Composite Repair",
                  description: "Composite Repair",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50fd",
                },
                slug: "prepreg",
                title: "Prepreg",
                id: 8000133,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/prepreg",
                active: true,
                meta: {
                  tags: "Prepreg",
                  keywords: "Prepreg",
                  description: "Prepreg",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ff",
                    },
                    slug: "carbon-prepreg",
                    title: "Carbon Prepreg",
                    id: 8000135,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/prepreg",
                    path: "/chemicals-materials/advanced-materials/composites/prepreg/carbon-prepreg",
                    active: true,
                    meta: {
                      tags: "Carbon Prepreg",
                      keywords: "Carbon Prepreg",
                      description: "Carbon Prepreg",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5100",
                    },
                    slug: "glass-prepreg",
                    title: "Glass Prepreg",
                    id: 8000136,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/prepreg",
                    path: "/chemicals-materials/advanced-materials/composites/prepreg/glass-prepreg",
                    active: true,
                    meta: {
                      tags: "Glass Prepreg",
                      keywords: "Glass Prepreg",
                      description: "Glass Prepreg",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50fe",
                    },
                    slug: "aramid-prepreg",
                    title: "Aramid Prepreg",
                    id: 8000134,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/prepreg",
                    path: "/chemicals-materials/advanced-materials/composites/prepreg/aramid-prepreg",
                    active: true,
                    meta: {
                      tags: "Aramid Prepreg",
                      keywords: "Aramid Prepreg",
                      description: "Aramid Prepreg",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5103",
                },
                slug: "sheet-molding-and-bulk-molding-compounds",
                title: "Sheet Molding and Bulk Molding Compounds",
                id: 8000139,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/sheet-molding-and-bulk-molding-compounds",
                active: true,
                meta: {
                  tags: "Sheet Molding and Bulk Molding Compounds",
                  keywords: "Sheet Molding and Bulk Molding Compounds",
                  description: "Sheet Molding and Bulk Molding Compounds",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50dc",
                },
                slug: "armor-materials",
                title: "Armor Materials",
                id: 8000100,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/armor-materials",
                active: true,
                meta: {
                  tags: "Armor Materials",
                  keywords: "Armor Materials",
                  description: "Armor Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50f6",
                },
                slug: "hybrid-composites",
                title: "Hybrid Composites",
                id: 8000126,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/hybrid-composites",
                active: true,
                meta: {
                  tags: "Hybrid Composites",
                  keywords: "Hybrid Composites",
                  description: "Hybrid Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50dd",
                },
                slug: "ballistic-composites",
                title: "Ballistic Composites",
                id: 8000101,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/ballistic-composites",
                active: true,
                meta: {
                  tags: "Ballistic Composites",
                  keywords: "Ballistic Composites",
                  description: "Ballistic Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5104",
                },
                slug: "sports-composites",
                title: "Sports Composites",
                id: 8000140,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/sports-composites",
                active: true,
                meta: {
                  tags: "Sports Composites",
                  keywords: "Sports Composites",
                  description: "Sports Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50df",
                },
                slug: "ceramic-matrix-composites",
                title: "Ceramic Matrix Composites",
                id: 8000103,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/ceramic-matrix-composites",
                active: true,
                meta: {
                  tags: "Ceramic Matrix Composites",
                  keywords: "Ceramic Matrix Composites",
                  description: "Ceramic Matrix Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5101",
                },
                slug: "rail-composites",
                title: "Rail Composites",
                id: 8000137,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/rail-composites",
                active: true,
                meta: {
                  tags: "Rail Composites",
                  keywords: "Rail Composites",
                  description: "Rail Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5102",
                },
                slug: "reinforced-plastics",
                title: "Reinforced Plastics",
                id: 8000138,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/reinforced-plastics",
                active: true,
                meta: {
                  tags: "Reinforced Plastics",
                  keywords: "Reinforced Plastics",
                  description: "Reinforced Plastics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5105",
                },
                slug: "textile-composites",
                title: "Textile Composites",
                id: 8000141,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/textile-composites",
                active: true,
                meta: {
                  tags: "Textile Composites",
                  keywords: "Textile Composites",
                  description: "Textile Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50f8",
                },
                slug: "marine-composites",
                title: "Marine Composites",
                id: 8000128,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/marine-composites",
                active: true,
                meta: {
                  tags: "Marine Composites",
                  keywords: "Marine Composites",
                  description: "Marine Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50fa",
                },
                slug: "nanocomposites",
                title: "NanoComposites",
                id: 8000130,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/nanocomposites",
                active: true,
                meta: {
                  tags: "NanoComposites",
                  keywords: "NanoComposites",
                  description: "NanoComposites",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50fc",
                    },
                    slug: "polymer-nanocomposites",
                    title: "Polymer Nanocomposites",
                    id: 8000132,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/nanocomposites",
                    path: "/chemicals-materials/advanced-materials/composites/nanocomposites/polymer-nanocomposites",
                    active: true,
                    meta: {
                      tags: "Polymer Nanocomposites",
                      keywords: "Polymer Nanocomposites",
                      description: "Polymer Nanocomposites",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50fb",
                    },
                    slug: "graphene-nanocomposites",
                    title: "Graphene Nanocomposites",
                    id: 8000131,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/nanocomposites",
                    path: "/chemicals-materials/advanced-materials/composites/nanocomposites/graphene-nanocomposites",
                    active: true,
                    meta: {
                      tags: "Graphene Nanocomposites",
                      keywords: "Graphene Nanocomposites",
                      description: "Graphene Nanocomposites",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5108",
                },
                slug: "wind-turbine-composites",
                title: "Wind Turbine Composites",
                id: 8000144,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/wind-turbine-composites",
                active: true,
                meta: {
                  tags: "Wind Turbine Composites",
                  keywords: "Wind Turbine Composites",
                  description: "Wind Turbine Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50f9",
                },
                slug: "metal-matrix-composites",
                title: "Metal Matrix Composites",
                id: 8000129,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/metal-matrix-composites",
                active: true,
                meta: {
                  tags: "Metal Matrix Composites",
                  keywords: "Metal Matrix Composites",
                  description: "Metal Matrix Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50cc",
                },
                slug: "advanced-fiber",
                title: "Advanced Fiber",
                id: 8000084,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                active: true,
                meta: {
                  tags: "Advanced Fiber",
                  keywords: "Advanced Fiber",
                  description: "Advanced Fiber",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50d5",
                    },
                    slug: "glass-fiber",
                    title: "Glass Fiber",
                    id: 8000093,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/glass-fiber",
                    active: true,
                    meta: {
                      tags: "Glass Fiber",
                      keywords: "Glass Fiber",
                      description: "Glass Fiber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50ce",
                    },
                    slug: "aramid-fiber",
                    title: "Aramid Fiber",
                    id: 8000086,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/aramid-fiber",
                    active: true,
                    meta: {
                      tags: "Aramid Fiber",
                      keywords: "Aramid Fiber",
                      description: "Aramid Fiber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50d3",
                    },
                    slug: "carbon-fiber-tapes",
                    title: "Carbon Fiber Tapes",
                    id: 8000091,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/carbon-fiber-tapes",
                    active: true,
                    meta: {
                      tags: "Carbon Fiber Tapes",
                      keywords: "Carbon Fiber Tapes",
                      description: "Carbon Fiber Tapes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50cd",
                    },
                    slug: "advanced-carbon-fiber",
                    title: "Advanced Carbon Fiber",
                    id: 8000085,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/advanced-carbon-fiber",
                    active: true,
                    meta: {
                      tags: "Advanced Carbon Fiber",
                      keywords: "Advanced Carbon Fiber",
                      description: "Advanced Carbon Fiber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50d1",
                    },
                    slug: "carbon-fiber",
                    title: "Carbon Fiber",
                    id: 8000089,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/carbon-fiber",
                    active: true,
                    meta: {
                      tags: "Carbon Fiber",
                      keywords: "Carbon Fiber",
                      description: "Carbon Fiber",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d50d2",
                        },
                        slug: "recycled-carbon-fiber",
                        title: "Recycled Carbon Fiber",
                        id: 8000090,
                        parent:
                          "/chemicals-materials/advanced-materials/composites/advanced-fiber/carbon-fiber",
                        path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/carbon-fiber/recycled-carbon-fiber",
                        active: true,
                        meta: {
                          tags: "Recycled Carbon Fiber",
                          keywords: "Recycled Carbon Fiber",
                          description: "Recycled Carbon Fiber",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50d6",
                    },
                    slug: "high-performance-fiber",
                    title: "High Performance Fiber",
                    id: 8000094,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/high-performance-fiber",
                    active: true,
                    meta: {
                      tags: "High Performance Fiber",
                      keywords: "High Performance Fiber",
                      description: "High Performance Fiber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50d7",
                    },
                    slug: "high-temperature-fiber",
                    title: "High Temperature Fiber",
                    id: 8000095,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/high-temperature-fiber",
                    active: true,
                    meta: {
                      tags: "High Temperature Fiber",
                      keywords: "High Temperature Fiber",
                      description: "High Temperature Fiber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50cf",
                    },
                    slug: "basalt",
                    title: "Basalt",
                    id: 8000087,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/basalt",
                    active: true,
                    meta: {
                      tags: "Basalt",
                      keywords: "Basalt",
                      description: "Basalt",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d50d0",
                        },
                        slug: "basalt-fiber",
                        title: "Basalt Fiber",
                        id: 8000088,
                        parent:
                          "/chemicals-materials/advanced-materials/composites/advanced-fiber/basalt",
                        path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/basalt/basalt-fiber",
                        active: true,
                        meta: {
                          tags: "Basalt Fiber",
                          keywords: "Basalt Fiber",
                          description: "Basalt Fiber",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50d4",
                    },
                    slug: "eco-fiber",
                    title: "Eco Fiber",
                    id: 8000092,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/eco-fiber",
                    active: true,
                    meta: {
                      tags: "Eco Fiber",
                      keywords: "Eco Fiber",
                      description: "Eco Fiber",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50d8",
                    },
                    slug: "honeycomb-core-materials",
                    title: "Honeycomb Core Materials",
                    id: 8000096,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/honeycomb-core-materials",
                    active: true,
                    meta: {
                      tags: "Honeycomb Core Materials",
                      keywords: "Honeycomb Core Materials",
                      description: "Honeycomb Core Materials",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d50d9",
                    },
                    slug: "nanofiber",
                    title: "Nanofiber",
                    id: 8000097,
                    parent:
                      "/chemicals-materials/advanced-materials/composites/advanced-fiber",
                    path: "/chemicals-materials/advanced-materials/composites/advanced-fiber/nanofiber",
                    active: true,
                    meta: {
                      tags: "Nanofiber",
                      keywords: "Nanofiber",
                      description: "Nanofiber",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50db",
                },
                slug: "aerospace-floor-panels",
                title: "Aerospace Floor Panels",
                id: 8000099,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/aerospace-floor-panels",
                active: true,
                meta: {
                  tags: "Aerospace Floor Panels",
                  keywords: "Aerospace Floor Panels",
                  description: "Aerospace Floor Panels",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50e0",
                },
                slug: "composite-bearings",
                title: "Composite Bearings",
                id: 8000104,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/composite-bearings",
                active: true,
                meta: {
                  tags: "Composite Bearings",
                  keywords: "Composite Bearings",
                  description: "Composite Bearings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50e3",
                },
                slug: "composite-rebar",
                title: "Composite Rebar",
                id: 8000107,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/composite-rebar",
                active: true,
                meta: {
                  tags: "Composite Rebar",
                  keywords: "Composite Rebar",
                  description: "Composite Rebar",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50e7",
                },
                slug: "core-materials-for-composites",
                title: "Core Materials for Composites",
                id: 8000111,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/core-materials-for-composites",
                active: true,
                meta: {
                  tags: "Core Materials for Composites",
                  keywords: "Core Materials for Composites",
                  description: "Core Materials for Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50e8",
                },
                slug: "epoxy-composites",
                title: "Epoxy Composites",
                id: 8000112,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/epoxy-composites",
                active: true,
                meta: {
                  tags: "Epoxy Composites",
                  keywords: "Epoxy Composites",
                  description: "Epoxy Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d50f7",
                },
                slug: "laminar-composites",
                title: "Laminar Composites",
                id: 8000127,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/laminar-composites",
                active: true,
                meta: {
                  tags: "Laminar Composites",
                  keywords: "Laminar Composites",
                  description: "Laminar Composites",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5106",
                },
                slug: "thermoplastic-composites",
                title: "Thermoplastic Composites",
                id: 8000142,
                parent: "/chemicals-materials/advanced-materials/composites",
                path: "/chemicals-materials/advanced-materials/composites/thermoplastic-composites",
                active: true,
                meta: {
                  tags: "Thermoplastic Composites",
                  keywords: "Thermoplastic Composites",
                  description: "Thermoplastic Composites",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d510e",
            },
            slug: "firefighting-foam",
            title: "Firefighting Foam",
            id: 8000150,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/firefighting-foam",
            active: true,
            meta: {
              tags: "Firefighting Foam",
              keywords: "Firefighting Foam",
              description: "Firefighting Foam",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5126",
            },
            slug: "patterning-materials",
            title: "Patterning Materials",
            id: 8000174,
            parent: "/chemicals-materials/advanced-materials",
            path: "/chemicals-materials/advanced-materials/patterning-materials",
            active: true,
            meta: {
              tags: "Patterning Materials",
              keywords: "Patterning Materials",
              description: "Patterning Materials",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b188",
        },
        id: 61106,
        title: "Construction Chemicals and Materials",
        slug: "construction-chemicals-and-materials",
        active: true,
        meta: {
          description:
            "The construction industry contributes about 10% to the global GDP and is one of the most important elements of every economy. The demands on buildings, roads, bridges, tunnels, and dams could not be met without construction chemicals. These are added as such or in formulations to or on construction materials at the construction site in order to improve workability, enhance performance, add functionality, or protect the construction material.",
          keywords:
            "Construction Chemicals & Materials Market Research Reports, Construction Chemicals Market, Cement Additives Market, Polymer Concrete Market, Construction Materials Market, Repair Construction Market, Geopolymer Market, Polyurethane Injections Market, Self Consolidating Concrete Market, Autoclaved Aerated Concrete Market, Asphalt Modifiers Market, ICF Market, Concrete Admixtures Market, Silica Sand Market, Construction Market Research, Construction Materials Industry, Building Materials Industry Report, Building Materials Industry Analysis, Building Materials Industry Overview",
          tags: "Construction Chemicals & Materials Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "The construction industry contributes about 10% to the global GDP, and is one of the most important elements of every economy. The demands on buildings, roads, bridges, tunnels and dams could not be met without construction chemicals. These are added as such or in formulations to or on construction materials at the construction site in order to improve workability, enhance performance, add functionality or protect the construction material.",
          short_description:
            "The rising demand for infrastructure across the globe cannot be met without construction chemicals. Construction chemicals are used as is or in formulated forms to enhance the performance and workability of the building material and to improve its functionality. Browse through our reports to learn more.",
        },
        picture: null,
        __v: 0,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/construction-chemicals-and-materials",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5282",
            },
            slug: "building-materials",
            title: "Building Materials",
            id: 8000524,
            parent: "/chemicals-materials/construction-chemicals-and-materials",
            path: "/chemicals-materials/construction-chemicals-and-materials/building-materials",
            active: true,
            meta: {
              tags: "Building Materials",
              keywords: "Building Materials",
              description: "Building Materials",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5288",
                },
                slug: "concrete",
                title: "Concrete",
                id: 8000530,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                active: true,
                meta: {
                  tags: "Concrete",
                  keywords: "Concrete",
                  description: "Concrete",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d528b",
                    },
                    slug: "cellular-concrete",
                    title: "Cellular Concrete",
                    id: 8000533,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/cellular-concrete",
                    active: true,
                    meta: {
                      tags: "Cellular Concrete",
                      keywords: "Cellular Concrete",
                      description: "Cellular Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d528f",
                    },
                    slug: "concrete-superplasticizer",
                    title: "Concrete Superplasticizer",
                    id: 8000537,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/concrete-superplasticizer",
                    active: true,
                    meta: {
                      tags: "Concrete Superplasticizer",
                      keywords: "Concrete Superplasticizer",
                      description: "Concrete Superplasticizer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5293",
                    },
                    slug: "glass-fiber-reinforced-concrete",
                    title: "Glass Fiber Reinforced Concrete",
                    id: 8000541,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/glass-fiber-reinforced-concrete",
                    active: true,
                    meta: {
                      tags: "Glass Fiber Reinforced Concrete",
                      keywords: "Glass Fiber Reinforced Concrete",
                      description: "Glass Fiber Reinforced Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d529e",
                    },
                    slug: "self-leveling-concrete",
                    title: "Self Leveling Concrete",
                    id: 8000552,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/self-leveling-concrete",
                    active: true,
                    meta: {
                      tags: "Self Leveling Concrete",
                      keywords: "Self Leveling Concrete",
                      description: "Self Leveling Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d529f",
                    },
                    slug: "sprayed-concrete-shotcrete",
                    title: "Sprayed Concrete (Shotcrete)",
                    id: 8000553,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/sprayed-concrete-shotcrete",
                    active: true,
                    meta: {
                      tags: "Sprayed Concrete (Shotcrete)",
                      keywords: "Sprayed Concrete (Shotcrete)",
                      description: "Sprayed Concrete (Shotcrete)",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d528c",
                    },
                    slug: "concrete-admixtures",
                    title: "Concrete Admixtures",
                    id: 8000534,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/concrete-admixtures",
                    active: true,
                    meta: {
                      tags: "Concrete Admixtures",
                      keywords: "Concrete Admixtures",
                      description: "Concrete Admixtures",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d528e",
                    },
                    slug: "concrete-bonding-agent",
                    title: "Concrete Bonding Agent",
                    id: 8000536,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/concrete-bonding-agent",
                    active: true,
                    meta: {
                      tags: "Concrete Bonding Agent",
                      keywords: "Concrete Bonding Agent",
                      description: "Concrete Bonding Agent",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5296",
                    },
                    slug: "hollow-concrete-block",
                    title: "Hollow Concrete Block",
                    id: 8000544,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/hollow-concrete-block",
                    active: true,
                    meta: {
                      tags: "Hollow Concrete Block",
                      keywords: "Hollow Concrete Block",
                      description: "Hollow Concrete Block",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d529b",
                    },
                    slug: "rapid-strength-concrete",
                    title: "Rapid Strength Concrete",
                    id: 8000549,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/rapid-strength-concrete",
                    active: true,
                    meta: {
                      tags: "Rapid Strength Concrete",
                      keywords: "Rapid Strength Concrete",
                      description: "Rapid Strength Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5294",
                    },
                    slug: "gypsum-concrete",
                    title: "Gypsum Concrete",
                    id: 8000542,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/gypsum-concrete",
                    active: true,
                    meta: {
                      tags: "Gypsum Concrete",
                      keywords: "Gypsum Concrete",
                      description: "Gypsum Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5297",
                    },
                    slug: "insulated-concrete-form",
                    title: "Insulated Concrete Form",
                    id: 8000545,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/insulated-concrete-form",
                    active: true,
                    meta: {
                      tags: "Insulated Concrete Form",
                      keywords: "Insulated Concrete Form",
                      description: "Insulated Concrete Form",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5298",
                    },
                    slug: "pervious-concrete",
                    title: "Pervious Concrete",
                    id: 8000546,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/pervious-concrete",
                    active: true,
                    meta: {
                      tags: "Pervious Concrete",
                      keywords: "Pervious Concrete",
                      description: "Pervious Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5299",
                    },
                    slug: "polymer-concrete",
                    title: "Polymer Concrete",
                    id: 8000547,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/polymer-concrete",
                    active: true,
                    meta: {
                      tags: "Polymer Concrete",
                      keywords: "Polymer Concrete",
                      description: "Polymer Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52a0",
                    },
                    slug: "ultra-high-performance-concrete",
                    title: "Ultra High Performance Concrete",
                    id: 8000554,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/ultra-high-performance-concrete",
                    active: true,
                    meta: {
                      tags: "Ultra High Performance Concrete",
                      keywords: "Ultra High Performance Concrete",
                      description: "Ultra High Performance Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d528a",
                    },
                    slug: "autoclaved-aerated-concrete",
                    title: "Autoclaved Aerated Concrete",
                    id: 8000532,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/autoclaved-aerated-concrete",
                    active: true,
                    meta: {
                      tags: "Autoclaved Aerated Concrete",
                      keywords: "Autoclaved Aerated Concrete",
                      description: "Autoclaved Aerated Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5290",
                    },
                    slug: "concrete-surface-retarder",
                    title: "Concrete Surface Retarder",
                    id: 8000538,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/concrete-surface-retarder",
                    active: true,
                    meta: {
                      tags: "Concrete Surface Retarder",
                      keywords: "Concrete Surface Retarder",
                      description: "Concrete Surface Retarder",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5295",
                    },
                    slug: "high-strength-concrete",
                    title: "High Strength Concrete",
                    id: 8000543,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/high-strength-concrete",
                    active: true,
                    meta: {
                      tags: "High Strength Concrete",
                      keywords: "High Strength Concrete",
                      description: "High Strength Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d529d",
                    },
                    slug: "self-compacting-concrete",
                    title: "Self Compacting Concrete",
                    id: 8000551,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/self-compacting-concrete",
                    active: true,
                    meta: {
                      tags: "Self Compacting Concrete",
                      keywords: "Self Compacting Concrete",
                      description: "Self Compacting Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5292",
                    },
                    slug: "fiber-reinforced-concrete",
                    title: "Fiber Reinforced Concrete",
                    id: 8000540,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/fiber-reinforced-concrete",
                    active: true,
                    meta: {
                      tags: "Fiber Reinforced Concrete",
                      keywords: "Fiber Reinforced Concrete",
                      description: "Fiber Reinforced Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5289",
                    },
                    slug: "3d-concrete-printing",
                    title: "3D Concrete Printing",
                    id: 8000531,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/3d-concrete-printing",
                    active: true,
                    meta: {
                      tags: "3D Concrete Printing",
                      keywords: "3D Concrete Printing",
                      description: "3D Concrete Printing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d528d",
                    },
                    slug: "concrete-blocks",
                    title: "Concrete Blocks",
                    id: 8000535,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/concrete-blocks",
                    active: true,
                    meta: {
                      tags: "Concrete Blocks",
                      keywords: "Concrete Blocks",
                      description: "Concrete Blocks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5291",
                    },
                    slug: "decorative-concrete",
                    title: "Decorative Concrete",
                    id: 8000539,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/decorative-concrete",
                    active: true,
                    meta: {
                      tags: "Decorative Concrete",
                      keywords: "Decorative Concrete",
                      description: "Decorative Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d529a",
                    },
                    slug: "precast-concrete",
                    title: "Precast Concrete",
                    id: 8000548,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/precast-concrete",
                    active: true,
                    meta: {
                      tags: "Precast Concrete",
                      keywords: "Precast Concrete",
                      description: "Precast Concrete",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d529c",
                    },
                    slug: "ready-mix-concrete",
                    title: "Ready-mix Concrete",
                    id: 8000550,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete/ready-mix-concrete",
                    active: true,
                    meta: {
                      tags: "Ready-mix Concrete",
                      keywords: "Ready-mix Concrete",
                      description: "Ready-mix Concrete",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52a1",
                },
                slug: "concrete-repair-mortar",
                title: "Concrete Repair Mortar",
                id: 8000555,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/concrete-repair-mortar",
                active: true,
                meta: {
                  tags: "Concrete Repair Mortar",
                  keywords: "Concrete Repair Mortar",
                  description: "Concrete Repair Mortar",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52a2",
                },
                slug: "construction-boards",
                title: "Construction Boards",
                id: 8000556,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards",
                active: true,
                meta: {
                  tags: "Construction Boards",
                  keywords: "Construction Boards",
                  description: "Construction Boards",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52a4",
                    },
                    slug: "gypsum-board",
                    title: "Gypsum Board",
                    id: 8000558,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards/gypsum-board",
                    active: true,
                    meta: {
                      tags: "Gypsum Board",
                      keywords: "Gypsum Board",
                      description: "Gypsum Board",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52a5",
                    },
                    slug: "medium-density-fiberboard",
                    title: "Medium Density Fiberboard",
                    id: 8000559,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards/medium-density-fiberboard",
                    active: true,
                    meta: {
                      tags: "Medium Density Fiberboard",
                      keywords: "Medium Density Fiberboard",
                      description: "Medium Density Fiberboard",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52a6",
                    },
                    slug: "oriented-strand-board",
                    title: "Oriented Strand Board",
                    id: 8000560,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards/oriented-strand-board",
                    active: true,
                    meta: {
                      tags: "Oriented Strand Board",
                      keywords: "Oriented Strand Board",
                      description: "Oriented Strand Board",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52a8",
                    },
                    slug: "plasterboard",
                    title: "Plasterboard",
                    id: 8000562,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards/plasterboard",
                    active: true,
                    meta: {
                      tags: "Plasterboard",
                      keywords: "Plasterboard",
                      description: "Plasterboard",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52a7",
                    },
                    slug: "particle-board",
                    title: "Particle Board",
                    id: 8000561,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards/particle-board",
                    active: true,
                    meta: {
                      tags: "Particle Board",
                      keywords: "Particle Board",
                      description: "Particle Board",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52a3",
                    },
                    slug: "cement-board",
                    title: "Cement Board",
                    id: 8000557,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-boards/cement-board",
                    active: true,
                    meta: {
                      tags: "Cement Board",
                      keywords: "Cement Board",
                      description: "Cement Board",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52a9",
                },
                slug: "construction-panels",
                title: "Construction Panels",
                id: 8000563,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels",
                active: true,
                meta: {
                  tags: "Construction Panels",
                  keywords: "Construction Panels",
                  description: "Construction Panels",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52ae",
                    },
                    slug: "metal-insulated-panel",
                    title: "Metal Insulated Panel",
                    id: 8000568,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/metal-insulated-panel",
                    active: true,
                    meta: {
                      tags: "Metal Insulated Panel",
                      keywords: "Metal Insulated Panel",
                      description: "Metal Insulated Panel",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52ad",
                    },
                    slug: "honeycomb-sandwich-panel",
                    title: "Honeycomb Sandwich Panel",
                    id: 8000567,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/honeycomb-sandwich-panel",
                    active: true,
                    meta: {
                      tags: "Honeycomb Sandwich Panel",
                      keywords: "Honeycomb Sandwich Panel",
                      description: "Honeycomb Sandwich Panel",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52b0",
                    },
                    slug: "structural-insulated-panels",
                    title: "Structural Insulated Panels",
                    id: 8000570,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/structural-insulated-panels",
                    active: true,
                    meta: {
                      tags: "Structural Insulated Panels",
                      keywords: "Structural Insulated Panels",
                      description: "Structural Insulated Panels",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52b2",
                    },
                    slug: "wood-panel",
                    title: "Wood Panel",
                    id: 8000572,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/wood-panel",
                    active: true,
                    meta: {
                      tags: "Wood Panel",
                      keywords: "Wood Panel",
                      description: "Wood Panel",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52aa",
                    },
                    slug: "composite-insulated-panel",
                    title: "Composite Insulated Panel",
                    id: 8000564,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/composite-insulated-panel",
                    active: true,
                    meta: {
                      tags: "Composite Insulated Panel",
                      keywords: "Composite Insulated Panel",
                      description: "Composite Insulated Panel",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52ab",
                    },
                    slug: "composite-panels",
                    title: "Composite Panels",
                    id: 8000565,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/composite-panels",
                    active: true,
                    meta: {
                      tags: "Composite Panels",
                      keywords: "Composite Panels",
                      description: "Composite Panels",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d52ac",
                        },
                        slug: "aluminum-composite-panels",
                        title: "Aluminum Composite Panels",
                        id: 8000566,
                        parent:
                          "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/composite-panels",
                        path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/composite-panels/aluminum-composite-panels",
                        active: true,
                        meta: {
                          tags: "Aluminum Composite Panels",
                          keywords: "Aluminum Composite Panels",
                          description: "Aluminum Composite Panels",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52af",
                    },
                    slug: "sandwich-panel",
                    title: "Sandwich Panel",
                    id: 8000569,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/sandwich-panel",
                    active: true,
                    meta: {
                      tags: "Sandwich Panel",
                      keywords: "Sandwich Panel",
                      description: "Sandwich Panel",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52b1",
                    },
                    slug: "vacuum-insulation-panel",
                    title: "Vacuum Insulation Panel",
                    id: 8000571,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/construction-panels/vacuum-insulation-panel",
                    active: true,
                    meta: {
                      tags: "Vacuum Insulation Panel",
                      keywords: "Vacuum Insulation Panel",
                      description: "Vacuum Insulation Panel",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52c3",
                },
                slug: "green-cement",
                title: "Green Cement",
                id: 8000589,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/green-cement",
                active: true,
                meta: {
                  tags: "Green Cement",
                  keywords: "Green Cement",
                  description: "Green Cement",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52d5",
                },
                slug: "polymer-modified-cementitious-coatings",
                title: "Polymer Modified Cementitious Coatings",
                id: 8000607,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/polymer-modified-cementitious-coatings",
                active: true,
                meta: {
                  tags: "Polymer Modified Cementitious Coatings",
                  keywords: "Polymer Modified Cementitious Coatings",
                  description: "Polymer Modified Cementitious Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52c6",
                },
                slug: "membrane",
                title: "Membrane",
                id: 8000592,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane",
                active: true,
                meta: {
                  tags: "Membrane",
                  keywords: "Membrane",
                  description: "Membrane",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52ca",
                    },
                    slug: "roofing-membrane",
                    title: "Roofing Membrane",
                    id: 8000596,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane/roofing-membrane",
                    active: true,
                    meta: {
                      tags: "Roofing Membrane",
                      keywords: "Roofing Membrane",
                      description: "Roofing Membrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52c7",
                    },
                    slug: "bitumen-membrane",
                    title: "Bitumen Membrane",
                    id: 8000593,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane/bitumen-membrane",
                    active: true,
                    meta: {
                      tags: "Bitumen Membrane",
                      keywords: "Bitumen Membrane",
                      description: "Bitumen Membrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52cb",
                    },
                    slug: "self-adhered-roofing-membrane",
                    title: "Self-adhered Roofing Membrane",
                    id: 8000597,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane/self-adhered-roofing-membrane",
                    active: true,
                    meta: {
                      tags: "Self-adhered Roofing Membrane",
                      keywords: "Self-adhered Roofing Membrane",
                      description: "Self-adhered Roofing Membrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52cd",
                    },
                    slug: "thermoplastic-polyolefin-membrane",
                    title: "Thermoplastic Polyolefin Membrane",
                    id: 8000599,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane/thermoplastic-polyolefin-membrane",
                    active: true,
                    meta: {
                      tags: "Thermoplastic Polyolefin Membrane",
                      keywords: "Thermoplastic Polyolefin Membrane",
                      description: "Thermoplastic Polyolefin Membrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52c8",
                    },
                    slug: "liquid-applied-membrane",
                    title: "Liquid Applied Membrane",
                    id: 8000594,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane/liquid-applied-membrane",
                    active: true,
                    meta: {
                      tags: "Liquid Applied Membrane",
                      keywords: "Liquid Applied Membrane",
                      description: "Liquid Applied Membrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52c9",
                    },
                    slug: "liquid-roofing",
                    title: "Liquid Roofing",
                    id: 8000595,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane/liquid-roofing",
                    active: true,
                    meta: {
                      tags: "Liquid Roofing",
                      keywords: "Liquid Roofing",
                      description: "Liquid Roofing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52cc",
                    },
                    slug: "single-ply-membrane",
                    title: "Single Ply Membrane",
                    id: 8000598,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane/single-ply-membrane",
                    active: true,
                    meta: {
                      tags: "Single Ply Membrane",
                      keywords: "Single Ply Membrane",
                      description: "Single Ply Membrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52ce",
                    },
                    slug: "waterproofing-membrane",
                    title: "Waterproofing Membrane",
                    id: 8000600,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/membrane/waterproofing-membrane",
                    active: true,
                    meta: {
                      tags: "Waterproofing Membrane",
                      keywords: "Waterproofing Membrane",
                      description: "Waterproofing Membrane",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52d7",
                },
                slug: "roofing-materials",
                title: "Roofing Materials",
                id: 8000609,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/roofing-materials",
                active: true,
                meta: {
                  tags: "Roofing Materials",
                  keywords: "Roofing Materials",
                  description: "Roofing Materials",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52d8",
                    },
                    slug: "stone-coated-steel-roofing",
                    title: "Stone Coated Steel Roofing",
                    id: 8000610,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/roofing-materials",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/roofing-materials/stone-coated-steel-roofing",
                    active: true,
                    meta: {
                      tags: "Stone Coated Steel Roofing",
                      keywords: "Stone Coated Steel Roofing",
                      description: "Stone Coated Steel Roofing",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52e0",
                },
                slug: "wooden-decking",
                title: "Wooden Decking",
                id: 8000618,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/wooden-decking",
                active: true,
                meta: {
                  tags: "Wooden Decking",
                  keywords: "Wooden Decking",
                  description: "Wooden Decking",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52d9",
                },
                slug: "supplementary-cementitious-materials",
                title: "Supplementary Cementitious Materials",
                id: 8000611,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/supplementary-cementitious-materials",
                active: true,
                meta: {
                  tags: "Supplementary Cementitious Materials",
                  keywords: "Supplementary Cementitious Materials",
                  description: "Supplementary Cementitious Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52c2",
                },
                slug: "green-building-materials",
                title: "Green Building Materials",
                id: 8000588,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/green-building-materials",
                active: true,
                meta: {
                  tags: "Green Building Materials",
                  keywords: "Green Building Materials",
                  description: "Green Building Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52d1",
                },
                slug: "mortar",
                title: "Mortar",
                id: 8000603,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/mortar",
                active: true,
                meta: {
                  tags: "Mortar",
                  keywords: "Mortar",
                  description: "Mortar",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52d2",
                    },
                    slug: "dry-mix-mortar",
                    title: "Dry-mix Mortar",
                    id: 8000604,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/mortar",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/mortar/dry-mix-mortar",
                    active: true,
                    meta: {
                      tags: "Dry-mix Mortar",
                      keywords: "Dry-mix Mortar",
                      description: "Dry-mix Mortar",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d52d3",
                        },
                        slug: "dry-mix-mortar-additives-and-chemicals",
                        title: "Dry-mix Mortar Additives and Chemicals",
                        id: 8000605,
                        parent:
                          "/chemicals-materials/construction-chemicals-and-materials/building-materials/mortar/dry-mix-mortar",
                        path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/mortar/dry-mix-mortar/dry-mix-mortar-additives-and-chemicals",
                        active: true,
                        meta: {
                          tags: "Dry-mix Mortar Additives and Chemicals",
                          keywords: "Dry-mix Mortar Additives and Chemicals",
                          description: "Dry-mix Mortar Additives and Chemicals",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52b4",
                },
                slug: "decorative-laminates",
                title: "Decorative Laminates",
                id: 8000574,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/decorative-laminates",
                active: true,
                meta: {
                  tags: "Decorative Laminates",
                  keywords: "Decorative Laminates",
                  description: "Decorative Laminates",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52b6",
                },
                slug: "fiber-cement",
                title: "Fiber Cement",
                id: 8000576,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/fiber-cement",
                active: true,
                meta: {
                  tags: "Fiber Cement",
                  keywords: "Fiber Cement",
                  description: "Fiber Cement",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52b9",
                },
                slug: "geomaterials",
                title: "GeoMaterials",
                id: 8000579,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials",
                active: true,
                meta: {
                  tags: "GeoMaterials",
                  keywords: "GeoMaterials",
                  description: "GeoMaterials",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52bc",
                    },
                    slug: "geomembrane",
                    title: "GeoMembrane",
                    id: 8000582,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials/geomembrane",
                    active: true,
                    meta: {
                      tags: "GeoMembrane",
                      keywords: "GeoMembrane",
                      description: "GeoMembrane",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52bf",
                    },
                    slug: "geotextiles",
                    title: "Geotextiles",
                    id: 8000585,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials/geotextiles",
                    active: true,
                    meta: {
                      tags: "Geotextiles",
                      keywords: "Geotextiles",
                      description: "Geotextiles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52ba",
                    },
                    slug: "geofoam",
                    title: "Geofoam",
                    id: 8000580,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials/geofoam",
                    active: true,
                    meta: {
                      tags: "Geofoam",
                      keywords: "Geofoam",
                      description: "Geofoam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52bd",
                    },
                    slug: "geopolymer",
                    title: "Geopolymer",
                    id: 8000583,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials/geopolymer",
                    active: true,
                    meta: {
                      tags: "Geopolymer",
                      keywords: "Geopolymer",
                      description: "Geopolymer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52bb",
                    },
                    slug: "geogrids",
                    title: "Geogrids",
                    id: 8000581,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials/geogrids",
                    active: true,
                    meta: {
                      tags: "Geogrids",
                      keywords: "Geogrids",
                      description: "Geogrids",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52be",
                    },
                    slug: "geosynthetics",
                    title: "Geosynthetics",
                    id: 8000584,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/geomaterials/geosynthetics",
                    active: true,
                    meta: {
                      tags: "Geosynthetics",
                      keywords: "Geosynthetics",
                      description: "Geosynthetics",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52c4",
                },
                slug: "limestone",
                title: "Limestone",
                id: 8000590,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/limestone",
                active: true,
                meta: {
                  tags: "Limestone",
                  keywords: "Limestone",
                  description: "Limestone",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52d6",
                },
                slug: "prefabricated-building-systems",
                title: "Prefabricated Building Systems",
                id: 8000608,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/prefabricated-building-systems",
                active: true,
                meta: {
                  tags: "Prefabricated Building Systems",
                  keywords: "Prefabricated Building Systems",
                  description: "Prefabricated Building Systems",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52d0",
                },
                slug: "modular-construction",
                title: "Modular Construction",
                id: 8000602,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/modular-construction",
                active: true,
                meta: {
                  tags: "Modular Construction",
                  keywords: "Modular Construction",
                  description: "Modular Construction",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52de",
                },
                slug: "waterproofing",
                title: "Waterproofing",
                id: 8000616,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/waterproofing",
                active: true,
                meta: {
                  tags: "Waterproofing",
                  keywords: "Waterproofing",
                  description: "Waterproofing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5287",
                },
                slug: "coal-tar-pitch",
                title: "Coal Tar Pitch",
                id: 8000529,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/coal-tar-pitch",
                active: true,
                meta: {
                  tags: "Coal Tar Pitch",
                  keywords: "Coal Tar Pitch",
                  description: "Coal Tar Pitch",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52b7",
                },
                slug: "fire-protection-materials",
                title: "Fire protection Materials",
                id: 8000577,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/fire-protection-materials",
                active: true,
                meta: {
                  tags: "Fire protection Materials",
                  keywords: "Fire protection Materials",
                  description: "Fire protection Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52b8",
                },
                slug: "fly-ash",
                title: "Fly Ash",
                id: 8000578,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/fly-ash",
                active: true,
                meta: {
                  tags: "Fly Ash",
                  keywords: "Fly Ash",
                  description: "Fly Ash",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52c1",
                },
                slug: "glue-laminated-beams",
                title: "Glue Laminated Beams",
                id: 8000587,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/glue-laminated-beams",
                active: true,
                meta: {
                  tags: "Glue Laminated Beams",
                  keywords: "Glue Laminated Beams",
                  description: "Glue Laminated Beams",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52c5",
                },
                slug: "marble",
                title: "Marble",
                id: 8000591,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/marble",
                active: true,
                meta: {
                  tags: "Marble",
                  keywords: "Marble",
                  description: "Marble",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52d4",
                },
                slug: "panelized-modular-construction-systems",
                title: "Panelized Modular Construction Systems",
                id: 8000606,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/panelized-modular-construction-systems",
                active: true,
                meta: {
                  tags: "Panelized Modular Construction Systems",
                  keywords: "Panelized Modular Construction Systems",
                  description: "Panelized Modular Construction Systems",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52da",
                },
                slug: "tiles",
                title: "Tiles",
                id: 8000612,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/tiles",
                active: true,
                meta: {
                  tags: "Tiles",
                  keywords: "Tiles",
                  description: "Tiles",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52dc",
                    },
                    slug: "glass-tiles",
                    title: "Glass Tiles",
                    id: 8000614,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/tiles",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/tiles/glass-tiles",
                    active: true,
                    meta: {
                      tags: "Glass Tiles",
                      keywords: "Glass Tiles",
                      description: "Glass Tiles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52db",
                    },
                    slug: "ceiling-tiles",
                    title: "Ceiling Tiles",
                    id: 8000613,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/tiles",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/tiles/ceiling-tiles",
                    active: true,
                    meta: {
                      tags: "Ceiling Tiles",
                      keywords: "Ceiling Tiles",
                      description: "Ceiling Tiles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52dd",
                    },
                    slug: "roofing-tiles",
                    title: "Roofing Tiles",
                    id: 8000615,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/building-materials/tiles",
                    path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/tiles/roofing-tiles",
                    active: true,
                    meta: {
                      tags: "Roofing Tiles",
                      keywords: "Roofing Tiles",
                      description: "Roofing Tiles",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5283",
                },
                slug: "brick",
                title: "Brick",
                id: 8000525,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/brick",
                active: true,
                meta: {
                  tags: "Brick",
                  keywords: "Brick",
                  description: "Brick",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5284",
                },
                slug: "calcium-hydroxide",
                title: "Calcium Hydroxide",
                id: 8000526,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/calcium-hydroxide",
                active: true,
                meta: {
                  tags: "Calcium Hydroxide",
                  keywords: "Calcium Hydroxide",
                  description: "Calcium Hydroxide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52c0",
                },
                slug: "glass-fiber-reinforced-gypsum-board",
                title: "Glass Fiber Reinforced Gypsum Board",
                id: 8000586,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/glass-fiber-reinforced-gypsum-board",
                active: true,
                meta: {
                  tags: "Glass Fiber Reinforced Gypsum Board",
                  keywords: "Glass Fiber Reinforced Gypsum Board",
                  description: "Glass Fiber Reinforced Gypsum Board",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52df",
                },
                slug: "white-cement",
                title: "White Cement",
                id: 8000617,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/white-cement",
                active: true,
                meta: {
                  tags: "White Cement",
                  keywords: "White Cement",
                  description: "White Cement",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5285",
                },
                slug: "calcium-oxide",
                title: "Calcium Oxide",
                id: 8000527,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/calcium-oxide",
                active: true,
                meta: {
                  tags: "Calcium Oxide",
                  keywords: "Calcium Oxide",
                  description: "Calcium Oxide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5286",
                },
                slug: "cement",
                title: "Cement",
                id: 8000528,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/cement",
                active: true,
                meta: {
                  tags: "Cement",
                  keywords: "Cement",
                  description: "Cement",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52b3",
                },
                slug: "cross-laminated-timber",
                title: "Cross Laminated Timber",
                id: 8000573,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/cross-laminated-timber",
                active: true,
                meta: {
                  tags: "Cross Laminated Timber",
                  keywords: "Cross Laminated Timber",
                  description: "Cross Laminated Timber",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52b5",
                },
                slug: "engineered-wood",
                title: "Engineered Wood",
                id: 8000575,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/engineered-wood",
                active: true,
                meta: {
                  tags: "Engineered Wood",
                  keywords: "Engineered Wood",
                  description: "Engineered Wood",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52cf",
                },
                slug: "modified-bitumen",
                title: "Modified Bitumen",
                id: 8000601,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/building-materials",
                path: "/chemicals-materials/construction-chemicals-and-materials/building-materials/modified-bitumen",
                active: true,
                meta: {
                  tags: "Modified Bitumen",
                  keywords: "Modified Bitumen",
                  description: "Modified Bitumen",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52e1",
            },
            slug: "construction-chemicals",
            title: "Construction Chemicals",
            id: 8000619,
            parent: "/chemicals-materials/construction-chemicals-and-materials",
            path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
            active: true,
            meta: {
              tags: "Construction Chemicals",
              keywords: "Construction Chemicals",
              description: "Construction Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52ec",
                },
                slug: "warm-mix-asphalt",
                title: "Warm Mix Asphalt",
                id: 8000630,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/warm-mix-asphalt",
                active: true,
                meta: {
                  tags: "Warm Mix Asphalt",
                  keywords: "Warm Mix Asphalt",
                  description: "Warm Mix Asphalt",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52ed",
                },
                slug: "waterproofing-admixture",
                title: "Waterproofing Admixture",
                id: 8000631,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/waterproofing-admixture",
                active: true,
                meta: {
                  tags: "Waterproofing Admixture",
                  keywords: "Waterproofing Admixture",
                  description: "Waterproofing Admixture",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52ee",
                },
                slug: "wood-preservative",
                title: "Wood Preservative",
                id: 8000632,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/wood-preservative",
                active: true,
                meta: {
                  tags: "Wood Preservative",
                  keywords: "Wood Preservative",
                  description: "Wood Preservative",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52e3",
                },
                slug: "anchors-and-grouts",
                title: "Anchors and Grouts",
                id: 8000621,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/anchors-and-grouts",
                active: true,
                meta: {
                  tags: "Anchors and Grouts",
                  keywords: "Anchors and Grouts",
                  description: "Anchors and Grouts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52e8",
                },
                slug: "roofing",
                title: "Roofing",
                id: 8000626,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/roofing",
                active: true,
                meta: {
                  tags: "Roofing",
                  keywords: "Roofing",
                  description: "Roofing",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52e9",
                    },
                    slug: "roofing-chemicals",
                    title: "Roofing Chemicals",
                    id: 8000627,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/roofing",
                    path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/roofing/roofing-chemicals",
                    active: true,
                    meta: {
                      tags: "Roofing Chemicals",
                      keywords: "Roofing Chemicals",
                      description: "Roofing Chemicals",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52e2",
                },
                slug: "aggregates",
                title: "Aggregates",
                id: 8000620,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/aggregates",
                active: true,
                meta: {
                  tags: "Aggregates",
                  keywords: "Aggregates",
                  description: "Aggregates",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52e7",
                },
                slug: "repair-and-rehabilitation",
                title: "Repair and Rehabilitation",
                id: 8000625,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/repair-and-rehabilitation",
                active: true,
                meta: {
                  tags: "Repair and Rehabilitation",
                  keywords: "Repair and Rehabilitation",
                  description: "Repair and Rehabilitation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52ea",
                },
                slug: "shotcrete-accelerator",
                title: "Shotcrete Accelerator",
                id: 8000628,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/shotcrete-accelerator",
                active: true,
                meta: {
                  tags: "Shotcrete Accelerator",
                  keywords: "Shotcrete Accelerator",
                  description: "Shotcrete Accelerator",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52eb",
                },
                slug: "slab-repair-products",
                title: "Slab Repair Products",
                id: 8000629,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/slab-repair-products",
                active: true,
                meta: {
                  tags: "Slab Repair Products",
                  keywords: "Slab Repair Products",
                  description: "Slab Repair Products",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52e4",
                },
                slug: "asphalt-modifiers",
                title: "Asphalt Modifiers",
                id: 8000622,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/asphalt-modifiers",
                active: true,
                meta: {
                  tags: "Asphalt Modifiers",
                  keywords: "Asphalt Modifiers",
                  description: "Asphalt Modifiers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d52e5",
                },
                slug: "flooring",
                title: "Flooring",
                id: 8000623,
                parent:
                  "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals",
                path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/flooring",
                active: true,
                meta: {
                  tags: "Flooring",
                  keywords: "Flooring",
                  description: "Flooring",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d52e6",
                    },
                    slug: "industrial-flooring",
                    title: "Industrial Flooring",
                    id: 8000624,
                    parent:
                      "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/flooring",
                    path: "/chemicals-materials/construction-chemicals-and-materials/construction-chemicals/flooring/industrial-flooring",
                    active: true,
                    meta: {
                      tags: "Industrial Flooring",
                      keywords: "Industrial Flooring",
                      description: "Industrial Flooring",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b18a",
        },
        id: 61107,
        title: "Paints and Coatings",
        slug: "paints-and-coatings",
        active: true,
        meta: {
          description:
            "The paints and coatings industry covers everything from the colored shells on chocolate candy to barnacle-resistant layers on ship hulls. It falls broadly under three categories: Architectural, Industrial, and Specialty. The industry consists of manufacturers of paints, varnishes, lacquers, shellacs, and stains.",
          keywords:
            "Paints & Coatings Market Research Reports, paints and coating, paints and coating market, paints and coating industry, paints and coating industry report, paints and coating market report, paints and coating industry analysis, paints and coating industry research, paints and coating market intelligence report, paints and coating market growth, paints and coating industry forecast",
          tags: "Paints & Coatings Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Paints and coatings industry covers everything from the colored shells on chocolate candy to barnacle-resistant layers on ship hulls. It falls broadly under three categories: Architectural, Industrial and Specialty. The industry consists of manufacturers of paints, varnishes, lacquers, shellacs and stains.",
          short_description:
            "The paints and coatings industry segment includes colored shells on chocolate candy to barnacle-resistant paint on ship hulls. The industry is segmented by architecture, industry, and specialty. The paints and coatings industry comprises manufacturers of paint, varnish, shellac, lacquer, and stain.",
        },
        picture: null,
        __v: 0,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/paints-and-coatings",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53db",
            },
            slug: "floor-coatings",
            title: "Floor Coatings",
            id: 8000871,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/floor-coatings",
            active: true,
            meta: {
              tags: "Floor Coatings",
              keywords: "Floor Coatings",
              description: "Floor Coatings",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53f7",
            },
            slug: "powder-coatings",
            title: "Powder Coatings",
            id: 8000899,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/powder-coatings",
            active: true,
            meta: {
              tags: "Powder Coatings",
              keywords: "Powder Coatings",
              description: "Powder Coatings",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53f8",
                },
                slug: "antimicrobial-powder-coatings",
                title: "Antimicrobial Powder Coatings",
                id: 8000900,
                parent:
                  "/chemicals-materials/paints-and-coatings/powder-coatings",
                path: "/chemicals-materials/paints-and-coatings/powder-coatings/antimicrobial-powder-coatings",
                active: true,
                meta: {
                  tags: "Antimicrobial Powder Coatings",
                  keywords: "Antimicrobial Powder Coatings",
                  description: "Antimicrobial Powder Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53f9",
                },
                slug: "epoxy-powder-coatings",
                title: "Epoxy Powder Coatings",
                id: 8000901,
                parent:
                  "/chemicals-materials/paints-and-coatings/powder-coatings",
                path: "/chemicals-materials/paints-and-coatings/powder-coatings/epoxy-powder-coatings",
                active: true,
                meta: {
                  tags: "Epoxy Powder Coatings",
                  keywords: "Epoxy Powder Coatings",
                  description: "Epoxy Powder Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53fb",
                },
                slug: "polyurethane-powder-coatings",
                title: "Polyurethane Powder Coatings",
                id: 8000903,
                parent:
                  "/chemicals-materials/paints-and-coatings/powder-coatings",
                path: "/chemicals-materials/paints-and-coatings/powder-coatings/polyurethane-powder-coatings",
                active: true,
                meta: {
                  tags: "Polyurethane Powder Coatings",
                  keywords: "Polyurethane Powder Coatings",
                  description: "Polyurethane Powder Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53fa",
                },
                slug: "low-temperature-powder-coatings",
                title: "Low Temperature Powder Coatings",
                id: 8000902,
                parent:
                  "/chemicals-materials/paints-and-coatings/powder-coatings",
                path: "/chemicals-materials/paints-and-coatings/powder-coatings/low-temperature-powder-coatings",
                active: true,
                meta: {
                  tags: "Low Temperature Powder Coatings",
                  keywords: "Low Temperature Powder Coatings",
                  description: "Low Temperature Powder Coatings",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53da",
            },
            slug: "epoxy-coatings",
            title: "Epoxy Coatings",
            id: 8000870,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/epoxy-coatings",
            active: true,
            meta: {
              tags: "Epoxy Coatings",
              keywords: "Epoxy Coatings",
              description: "Epoxy Coatings",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53ce",
            },
            slug: "acrylic-coatings",
            title: "Acrylic Coatings",
            id: 8000858,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/acrylic-coatings",
            active: true,
            meta: {
              tags: "Acrylic Coatings",
              keywords: "Acrylic Coatings",
              description: "Acrylic Coatings",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53cf",
                },
                slug: "acrylic-surface-coatings",
                title: "Acrylic Surface Coatings",
                id: 8000859,
                parent:
                  "/chemicals-materials/paints-and-coatings/acrylic-coatings",
                path: "/chemicals-materials/paints-and-coatings/acrylic-coatings/acrylic-surface-coatings",
                active: true,
                meta: {
                  tags: "Acrylic Surface Coatings",
                  keywords: "Acrylic Surface Coatings",
                  description: "Acrylic Surface Coatings",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53f4",
            },
            slug: "plastic-coatings",
            title: "Plastic Coatings",
            id: 8000896,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/plastic-coatings",
            active: true,
            meta: {
              tags: "Plastic Coatings",
              keywords: "Plastic Coatings",
              description: "Plastic Coatings",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53fc",
            },
            slug: "resins-for-paints-and-coatings",
            title: "Resins for Paints and Coatings",
            id: 8000904,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/resins-for-paints-and-coatings",
            active: true,
            meta: {
              tags: "Resins for Paints and Coatings",
              keywords: "Resins for Paints and Coatings",
              description: "Resins for Paints and Coatings",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53fd",
            },
            slug: "specialty-coatings",
            title: "Specialty Coatings",
            id: 8000905,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/specialty-coatings",
            active: true,
            meta: {
              tags: "Specialty Coatings",
              keywords: "Specialty Coatings",
              description: "Specialty Coatings",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5418",
                },
                slug: "fluorinated-ethylene-propylene-fep-coatings",
                title: "Fluorinated Ethylene Propylene (FEP) Coatings",
                id: 8000932,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/fluorinated-ethylene-propylene-fep-coatings",
                active: true,
                meta: {
                  tags: "Fluorinated Ethylene Propylene (FEP) Coatings",
                  keywords: "Fluorinated Ethylene Propylene (FEP) Coatings",
                  description: "Fluorinated Ethylene Propylene (FEP) Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d542a",
                },
                slug: "mold-release-coatings",
                title: "Mold Release Coatings",
                id: 8000950,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/mold-release-coatings",
                active: true,
                meta: {
                  tags: "Mold Release Coatings",
                  keywords: "Mold Release Coatings",
                  description: "Mold Release Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5437",
                },
                slug: "silicate-coatings",
                title: "Silicate Coatings",
                id: 8000963,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/silicate-coatings",
                active: true,
                meta: {
                  tags: "Silicate Coatings",
                  keywords: "Silicate Coatings",
                  description: "Silicate Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5438",
                },
                slug: "silicone-coatings",
                title: "Silicone Coatings",
                id: 8000964,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/silicone-coatings",
                active: true,
                meta: {
                  tags: "Silicone Coatings",
                  keywords: "Silicone Coatings",
                  description: "Silicone Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d543d",
                },
                slug: "thermal-spray",
                title: "Thermal Spray",
                id: 8000969,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-spray",
                active: true,
                meta: {
                  tags: "Thermal Spray",
                  keywords: "Thermal Spray",
                  description: "Thermal Spray",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d543f",
                    },
                    slug: "thermal-spray-equipment",
                    title: "Thermal Spray Equipment",
                    id: 8000971,
                    parent:
                      "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-spray",
                    path: "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-spray/thermal-spray-equipment",
                    active: true,
                    meta: {
                      tags: "Thermal Spray Equipment",
                      keywords: "Thermal Spray Equipment",
                      description: "Thermal Spray Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d543e",
                    },
                    slug: "thermal-spray-coatings",
                    title: "Thermal Spray Coatings",
                    id: 8000970,
                    parent:
                      "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-spray",
                    path: "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-spray/thermal-spray-coatings",
                    active: true,
                    meta: {
                      tags: "Thermal Spray Coatings",
                      keywords: "Thermal Spray Coatings",
                      description: "Thermal Spray Coatings",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5441",
                    },
                    slug: "thermal-spray-powder",
                    title: "Thermal Spray Powder",
                    id: 8000973,
                    parent:
                      "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-spray",
                    path: "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-spray/thermal-spray-powder",
                    active: true,
                    meta: {
                      tags: "Thermal Spray Powder",
                      keywords: "Thermal Spray Powder",
                      description: "Thermal Spray Powder",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5440",
                    },
                    slug: "thermal-spray-materials",
                    title: "Thermal Spray Materials",
                    id: 8000972,
                    parent:
                      "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-spray",
                    path: "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-spray/thermal-spray-materials",
                    active: true,
                    meta: {
                      tags: "Thermal Spray Materials",
                      keywords: "Thermal Spray Materials",
                      description: "Thermal Spray Materials",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5405",
                },
                slug: "antiviral-coatings",
                title: "Antiviral Coatings",
                id: 8000913,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/antiviral-coatings",
                active: true,
                meta: {
                  tags: "Antiviral Coatings",
                  keywords: "Antiviral Coatings",
                  description: "Antiviral Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5408",
                },
                slug: "chemical-resistant-coatings",
                title: "Chemical Resistant Coatings",
                id: 8000916,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/chemical-resistant-coatings",
                active: true,
                meta: {
                  tags: "Chemical Resistant Coatings",
                  keywords: "Chemical Resistant Coatings",
                  description: "Chemical Resistant Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5410",
                },
                slug: "composites-coatings",
                title: "Composites Coatings",
                id: 8000924,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/composites-coatings",
                active: true,
                meta: {
                  tags: "Composites Coatings",
                  keywords: "Composites Coatings",
                  description: "Composites Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d541d",
                },
                slug: "high-performance-coatings",
                title: "High Performance Coatings",
                id: 8000937,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/high-performance-coatings",
                active: true,
                meta: {
                  tags: "High Performance Coatings",
                  keywords: "High Performance Coatings",
                  description: "High Performance Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5426",
                },
                slug: "low-voc-paints",
                title: "Low VOC Paints",
                id: 8000946,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/low-voc-paints",
                active: true,
                meta: {
                  tags: "Low VOC Paints",
                  keywords: "Low VOC Paints",
                  description: "Low VOC Paints",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5429",
                },
                slug: "mirror-coatings",
                title: "Mirror Coatings",
                id: 8000949,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/mirror-coatings",
                active: true,
                meta: {
                  tags: "Mirror Coatings",
                  keywords: "Mirror Coatings",
                  description: "Mirror Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5436",
                },
                slug: "seal-coat-coatings",
                title: "Seal Coat Coatings",
                id: 8000962,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/seal-coat-coatings",
                active: true,
                meta: {
                  tags: "Seal Coat Coatings",
                  keywords: "Seal Coat Coatings",
                  description: "Seal Coat Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5402",
                },
                slug: "anti-slip-coatings",
                title: "Anti Slip Coatings",
                id: 8000910,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/anti-slip-coatings",
                active: true,
                meta: {
                  tags: "Anti Slip Coatings",
                  keywords: "Anti Slip Coatings",
                  description: "Anti Slip Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5414",
                },
                slug: "elastomeric-coatings",
                title: "Elastomeric Coatings",
                id: 8000928,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/elastomeric-coatings",
                active: true,
                meta: {
                  tags: "Elastomeric Coatings",
                  keywords: "Elastomeric Coatings",
                  description: "Elastomeric Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5416",
                },
                slug: "electron-beam-curable-coatings",
                title: "Electron Beam Curable Coatings",
                id: 8000930,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/electron-beam-curable-coatings",
                active: true,
                meta: {
                  tags: "Electron Beam Curable Coatings",
                  keywords: "Electron Beam Curable Coatings",
                  description: "Electron Beam Curable Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d541e",
                },
                slug: "high-temperature-coatings",
                title: "High Temperature Coatings",
                id: 8000938,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/high-temperature-coatings",
                active: true,
                meta: {
                  tags: "High Temperature Coatings",
                  keywords: "High Temperature Coatings",
                  description: "High Temperature Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d541f",
                },
                slug: "hydrophilic-coatings",
                title: "Hydrophilic Coatings",
                id: 8000939,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/hydrophilic-coatings",
                active: true,
                meta: {
                  tags: "Hydrophilic Coatings",
                  keywords: "Hydrophilic Coatings",
                  description: "Hydrophilic Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5421",
                },
                slug: "insulation-coatings",
                title: "Insulation Coatings",
                id: 8000941,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/insulation-coatings",
                active: true,
                meta: {
                  tags: "Insulation Coatings",
                  keywords: "Insulation Coatings",
                  description: "Insulation Coatings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5422",
                    },
                    slug: "thermal-insulation-coatings",
                    title: "Thermal Insulation Coatings",
                    id: 8000942,
                    parent:
                      "/chemicals-materials/paints-and-coatings/specialty-coatings/insulation-coatings",
                    path: "/chemicals-materials/paints-and-coatings/specialty-coatings/insulation-coatings/thermal-insulation-coatings",
                    active: true,
                    meta: {
                      tags: "Thermal Insulation Coatings",
                      keywords: "Thermal Insulation Coatings",
                      description: "Thermal Insulation Coatings",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d542c",
                },
                slug: "non-stick-coatings",
                title: "Non Stick Coatings",
                id: 8000952,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/non-stick-coatings",
                active: true,
                meta: {
                  tags: "Non Stick Coatings",
                  keywords: "Non Stick Coatings",
                  description: "Non Stick Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5409",
                },
                slug: "chemical-vapor-deposition-cvd",
                title: "Chemical Vapor Deposition (CVD)",
                id: 8000917,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/chemical-vapor-deposition-cvd",
                active: true,
                meta: {
                  tags: "Chemical Vapor Deposition (CVD)",
                  keywords: "Chemical Vapor Deposition (CVD)",
                  description: "Chemical Vapor Deposition (CVD)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5435",
                },
                slug: "road-marking-materials",
                title: "Road Marking Materials",
                id: 8000961,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/road-marking-materials",
                active: true,
                meta: {
                  tags: "Road Marking Materials",
                  keywords: "Road Marking Materials",
                  description: "Road Marking Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53fe",
                },
                slug: "abrasion-resistant-coatings",
                title: "Abrasion Resistant Coatings",
                id: 8000906,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/abrasion-resistant-coatings",
                active: true,
                meta: {
                  tags: "Abrasion Resistant Coatings",
                  keywords: "Abrasion Resistant Coatings",
                  description: "Abrasion Resistant Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53ff",
                },
                slug: "aerosol-paints",
                title: "Aerosol Paints",
                id: 8000907,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/aerosol-paints",
                active: true,
                meta: {
                  tags: "Aerosol Paints",
                  keywords: "Aerosol Paints",
                  description: "Aerosol Paints",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5400",
                },
                slug: "anti-graffiti-coatings",
                title: "Anti Graffiti Coatings",
                id: 8000908,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/anti-graffiti-coatings",
                active: true,
                meta: {
                  tags: "Anti Graffiti Coatings",
                  keywords: "Anti Graffiti Coatings",
                  description: "Anti Graffiti Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5404",
                },
                slug: "antimicrobial-coatings",
                title: "Antimicrobial Coatings",
                id: 8000912,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/antimicrobial-coatings",
                active: true,
                meta: {
                  tags: "Antimicrobial Coatings",
                  keywords: "Antimicrobial Coatings",
                  description: "Antimicrobial Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d540b",
                },
                slug: "coating-agents",
                title: "Coating Agents",
                id: 8000919,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/coating-agents",
                active: true,
                meta: {
                  tags: "Coating Agents",
                  keywords: "Coating Agents",
                  description: "Coating Agents",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d540c",
                    },
                    slug: "coating-agents-for-synthetic-leather",
                    title: "Coating Agents for Synthetic Leather",
                    id: 8000920,
                    parent:
                      "/chemicals-materials/paints-and-coatings/specialty-coatings/coating-agents",
                    path: "/chemicals-materials/paints-and-coatings/specialty-coatings/coating-agents/coating-agents-for-synthetic-leather",
                    active: true,
                    meta: {
                      tags: "Coating Agents for Synthetic Leather",
                      keywords: "Coating Agents for Synthetic Leather",
                      description: "Coating Agents for Synthetic Leather",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d540e",
                },
                slug: "coil-coatings",
                title: "Coil Coatings",
                id: 8000922,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/coil-coatings",
                active: true,
                meta: {
                  tags: "Coil Coatings",
                  keywords: "Coil Coatings",
                  description: "Coil Coatings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d540f",
                    },
                    slug: "metal-coatings",
                    title: "Metal Coatings",
                    id: 8000923,
                    parent:
                      "/chemicals-materials/paints-and-coatings/specialty-coatings/coil-coatings",
                    path: "/chemicals-materials/paints-and-coatings/specialty-coatings/coil-coatings/metal-coatings",
                    active: true,
                    meta: {
                      tags: "Metal Coatings",
                      keywords: "Metal Coatings",
                      description: "Metal Coatings",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5411",
                },
                slug: "conductive-polymer-coatings",
                title: "Conductive Polymer Coatings",
                id: 8000925,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/conductive-polymer-coatings",
                active: true,
                meta: {
                  tags: "Conductive Polymer Coatings",
                  keywords: "Conductive Polymer Coatings",
                  description: "Conductive Polymer Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d541c",
                },
                slug: "heat-resistant-coatings",
                title: "Heat Resistant Coatings",
                id: 8000936,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/heat-resistant-coatings",
                active: true,
                meta: {
                  tags: "Heat Resistant Coatings",
                  keywords: "Heat Resistant Coatings",
                  description: "Heat Resistant Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5425",
                },
                slug: "low-temperature-coatings",
                title: "Low Temperature Coatings",
                id: 8000945,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/low-temperature-coatings",
                active: true,
                meta: {
                  tags: "Low Temperature Coatings",
                  keywords: "Low Temperature Coatings",
                  description: "Low Temperature Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5427",
                },
                slug: "medical-coatings",
                title: "Medical Coatings",
                id: 8000947,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/medical-coatings",
                active: true,
                meta: {
                  tags: "Medical Coatings",
                  keywords: "Medical Coatings",
                  description: "Medical Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d542b",
                },
                slug: "nano-paints-and-coatings",
                title: "Nano Paints and Coatings",
                id: 8000951,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/nano-paints-and-coatings",
                active: true,
                meta: {
                  tags: "Nano Paints and Coatings",
                  keywords: "Nano Paints and Coatings",
                  description: "Nano Paints and Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5407",
                },
                slug: "ceramic-coatings",
                title: "Ceramic Coatings",
                id: 8000915,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/ceramic-coatings",
                active: true,
                meta: {
                  tags: "Ceramic Coatings",
                  keywords: "Ceramic Coatings",
                  description: "Ceramic Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d540a",
                },
                slug: "clear-coatings",
                title: "Clear Coatings",
                id: 8000918,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/clear-coatings",
                active: true,
                meta: {
                  tags: "Clear Coatings",
                  keywords: "Clear Coatings",
                  description: "Clear Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d540d",
                },
                slug: "coating-pretreatment",
                title: "Coating Pretreatment",
                id: 8000921,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/coating-pretreatment",
                active: true,
                meta: {
                  tags: "Coating Pretreatment",
                  keywords: "Coating Pretreatment",
                  description: "Coating Pretreatment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5412",
                },
                slug: "diamond-coatings",
                title: "Diamond Coatings",
                id: 8000926,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/diamond-coatings",
                active: true,
                meta: {
                  tags: "Diamond Coatings",
                  keywords: "Diamond Coatings",
                  description: "Diamond Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5413",
                },
                slug: "e-coat",
                title: "E-coat",
                id: 8000927,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/e-coat",
                active: true,
                meta: {
                  tags: "E-coat",
                  keywords: "E-coat",
                  description: "E-coat",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d541a",
                },
                slug: "glass-flake-coatings",
                title: "Glass Flake Coatings",
                id: 8000934,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/glass-flake-coatings",
                active: true,
                meta: {
                  tags: "Glass Flake Coatings",
                  keywords: "Glass Flake Coatings",
                  description: "Glass Flake Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5420",
                },
                slug: "hydrophobic-coatings",
                title: "Hydrophobic Coatings",
                id: 8000940,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/hydrophobic-coatings",
                active: true,
                meta: {
                  tags: "Hydrophobic Coatings",
                  keywords: "Hydrophobic Coatings",
                  description: "Hydrophobic Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5424",
                },
                slug: "low-friction-coatings",
                title: "Low Friction Coatings",
                id: 8000944,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/low-friction-coatings",
                active: true,
                meta: {
                  tags: "Low Friction Coatings",
                  keywords: "Low Friction Coatings",
                  description: "Low Friction Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d542d",
                },
                slug: "optical-coatings",
                title: "Optical Coatings",
                id: 8000953,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/optical-coatings",
                active: true,
                meta: {
                  tags: "Optical Coatings",
                  keywords: "Optical Coatings",
                  description: "Optical Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d542f",
                },
                slug: "pacvd-based-coatings",
                title: "PACVD Based Coatings",
                id: 8000955,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/pacvd-based-coatings",
                active: true,
                meta: {
                  tags: "PACVD Based Coatings",
                  keywords: "PACVD Based Coatings",
                  description: "PACVD Based Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5432",
                },
                slug: "physical-vapor-deposition-pvd-coatings",
                title: "Physical Vapor Deposition (PVD) Coatings",
                id: 8000958,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/physical-vapor-deposition-pvd-coatings",
                active: true,
                meta: {
                  tags: "Physical Vapor Deposition (PVD) Coatings",
                  keywords: "Physical Vapor Deposition (PVD) Coatings",
                  description: "Physical Vapor Deposition (PVD) Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5433",
                },
                slug: "polytetrafluoroethylene-ptfe-coatings",
                title: "Polytetrafluoroethylene (PTFE) Coatings",
                id: 8000959,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/polytetrafluoroethylene-ptfe-coatings",
                active: true,
                meta: {
                  tags: "Polytetrafluoroethylene (PTFE) Coatings",
                  keywords: "Polytetrafluoroethylene (PTFE) Coatings",
                  description: "Polytetrafluoroethylene (PTFE) Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5439",
                },
                slug: "smart-coatings",
                title: "Smart Coatings",
                id: 8000965,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/smart-coatings",
                active: true,
                meta: {
                  tags: "Smart Coatings",
                  keywords: "Smart Coatings",
                  description: "Smart Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5401",
                },
                slug: "anti-reflective-coatings",
                title: "Anti Reflective Coatings",
                id: 8000909,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/anti-reflective-coatings",
                active: true,
                meta: {
                  tags: "Anti Reflective Coatings",
                  keywords: "Anti Reflective Coatings",
                  description: "Anti Reflective Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5406",
                },
                slug: "automotive-appearance-chemicals",
                title: "Automotive Appearance Chemicals",
                id: 8000914,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/automotive-appearance-chemicals",
                active: true,
                meta: {
                  tags: "Automotive Appearance Chemicals",
                  keywords: "Automotive Appearance Chemicals",
                  description: "Automotive Appearance Chemicals",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5417",
                },
                slug: "fire-retardant-coatings",
                title: "Fire Retardant Coatings",
                id: 8000931,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/fire-retardant-coatings",
                active: true,
                meta: {
                  tags: "Fire Retardant Coatings",
                  keywords: "Fire Retardant Coatings",
                  description: "Fire Retardant Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5423",
                },
                slug: "intumescent-coatings",
                title: "Intumescent Coatings",
                id: 8000943,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/intumescent-coatings",
                active: true,
                meta: {
                  tags: "Intumescent Coatings",
                  keywords: "Intumescent Coatings",
                  description: "Intumescent Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d542e",
                },
                slug: "organic-coatings",
                title: "Organic Coatings",
                id: 8000954,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/organic-coatings",
                active: true,
                meta: {
                  tags: "Organic Coatings",
                  keywords: "Organic Coatings",
                  description: "Organic Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d543c",
                },
                slug: "thermal-barrier-coatings",
                title: "Thermal Barrier Coatings",
                id: 8000968,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/thermal-barrier-coatings",
                active: true,
                meta: {
                  tags: "Thermal Barrier Coatings",
                  keywords: "Thermal Barrier Coatings",
                  description: "Thermal Barrier Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5403",
                },
                slug: "anti-icing-coatings",
                title: "Anti-icing Coatings",
                id: 8000911,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/anti-icing-coatings",
                active: true,
                meta: {
                  tags: "Anti-icing Coatings",
                  keywords: "Anti-icing Coatings",
                  description: "Anti-icing Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5415",
                },
                slug: "electrically-conductive-coatings",
                title: "Electrically Conductive Coatings",
                id: 8000929,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/electrically-conductive-coatings",
                active: true,
                meta: {
                  tags: "Electrically Conductive Coatings",
                  keywords: "Electrically Conductive Coatings",
                  description: "Electrically Conductive Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5419",
                },
                slug: "gelcoat",
                title: "Gelcoat",
                id: 8000933,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/gelcoat",
                active: true,
                meta: {
                  tags: "Gelcoat",
                  keywords: "Gelcoat",
                  description: "Gelcoat",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d541b",
                },
                slug: "hard-coatings",
                title: "Hard Coatings",
                id: 8000935,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/hard-coatings",
                active: true,
                meta: {
                  tags: "Hard Coatings",
                  keywords: "Hard Coatings",
                  description: "Hard Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5428",
                },
                slug: "metal-finishing",
                title: "Metal Finishing",
                id: 8000948,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/metal-finishing",
                active: true,
                meta: {
                  tags: "Metal Finishing",
                  keywords: "Metal Finishing",
                  description: "Metal Finishing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5430",
                },
                slug: "paper-coating-materials",
                title: "Paper Coating Materials",
                id: 8000956,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/paper-coating-materials",
                active: true,
                meta: {
                  tags: "Paper Coating Materials",
                  keywords: "Paper Coating Materials",
                  description: "Paper Coating Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5434",
                },
                slug: "polyurea-coatings",
                title: "Polyurea Coatings",
                id: 8000960,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/polyurea-coatings",
                active: true,
                meta: {
                  tags: "Polyurea Coatings",
                  keywords: "Polyurea Coatings",
                  description: "Polyurea Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5431",
                },
                slug: "perfluoroalkoxy-pfa-coatings",
                title: "Perfluoroalkoxy (PFA) Coatings",
                id: 8000957,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/perfluoroalkoxy-pfa-coatings",
                active: true,
                meta: {
                  tags: "Perfluoroalkoxy (PFA) Coatings",
                  keywords: "Perfluoroalkoxy (PFA) Coatings",
                  description: "Perfluoroalkoxy (PFA) Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d543a",
                },
                slug: "stain-resistant-coatings",
                title: "Stain Resistant Coatings",
                id: 8000966,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/stain-resistant-coatings",
                active: true,
                meta: {
                  tags: "Stain Resistant Coatings",
                  keywords: "Stain Resistant Coatings",
                  description: "Stain Resistant Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d543b",
                },
                slug: "superhydrophobic-coatings",
                title: "Superhydrophobic Coatings",
                id: 8000967,
                parent:
                  "/chemicals-materials/paints-and-coatings/specialty-coatings",
                path: "/chemicals-materials/paints-and-coatings/specialty-coatings/superhydrophobic-coatings",
                active: true,
                meta: {
                  tags: "Superhydrophobic Coatings",
                  keywords: "Superhydrophobic Coatings",
                  description: "Superhydrophobic Coatings",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53f6",
            },
            slug: "polysiloxane-coatings",
            title: "Polysiloxane Coatings",
            id: 8000898,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/polysiloxane-coatings",
            active: true,
            meta: {
              tags: "Polysiloxane Coatings",
              keywords: "Polysiloxane Coatings",
              description: "Polysiloxane Coatings",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53d0",
            },
            slug: "architectural-coatings",
            title: "Architectural Coatings",
            id: 8000860,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/architectural-coatings",
            active: true,
            meta: {
              tags: "Architectural Coatings",
              keywords: "Architectural Coatings",
              description: "Architectural Coatings",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53d1",
                },
                slug: "facade-coatings",
                title: "Façade Coatings",
                id: 8000861,
                parent:
                  "/chemicals-materials/paints-and-coatings/architectural-coatings",
                path: "/chemicals-materials/paints-and-coatings/architectural-coatings/facade-coatings",
                active: true,
                meta: {
                  tags: "Façade Coatings",
                  keywords: "Façade Coatings",
                  description: "Façade Coatings",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53d2",
            },
            slug: "coating-additives",
            title: "Coating Additives",
            id: 8000862,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/coating-additives",
            active: true,
            meta: {
              tags: "Coating Additives",
              keywords: "Coating Additives",
              description: "Coating Additives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53d3",
                },
                slug: "aliphatic-hydrocarbon-solvents-and-thinners",
                title: "Aliphatic Hydrocarbon Solvents and Thinners",
                id: 8000863,
                parent:
                  "/chemicals-materials/paints-and-coatings/coating-additives",
                path: "/chemicals-materials/paints-and-coatings/coating-additives/aliphatic-hydrocarbon-solvents-and-thinners",
                active: true,
                meta: {
                  tags: "Aliphatic Hydrocarbon Solvents and Thinners",
                  keywords: "Aliphatic Hydrocarbon Solvents and Thinners",
                  description: "Aliphatic Hydrocarbon Solvents and Thinners",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53d8",
                },
                slug: "matting-agents",
                title: "Matting Agents",
                id: 8000868,
                parent:
                  "/chemicals-materials/paints-and-coatings/coating-additives",
                path: "/chemicals-materials/paints-and-coatings/coating-additives/matting-agents",
                active: true,
                meta: {
                  tags: "Matting Agents",
                  keywords: "Matting Agents",
                  description: "Matting Agents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53d7",
                },
                slug: "low-volatile-organic-compounds-coating-additives",
                title: "Low Volatile Organic Compounds Coating Additives",
                id: 8000867,
                parent:
                  "/chemicals-materials/paints-and-coatings/coating-additives",
                path: "/chemicals-materials/paints-and-coatings/coating-additives/low-volatile-organic-compounds-coating-additives",
                active: true,
                meta: {
                  tags: "Low Volatile Organic Compounds Coating Additives",
                  keywords: "Low Volatile Organic Compounds Coating Additives",
                  description:
                    "Low Volatile Organic Compounds Coating Additives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53d5",
                },
                slug: "crosslinking-agents",
                title: "Crosslinking Agents",
                id: 8000865,
                parent:
                  "/chemicals-materials/paints-and-coatings/coating-additives",
                path: "/chemicals-materials/paints-and-coatings/coating-additives/crosslinking-agents",
                active: true,
                meta: {
                  tags: "Crosslinking Agents",
                  keywords: "Crosslinking Agents",
                  description: "Crosslinking Agents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53d4",
                },
                slug: "chromium-phosphate",
                title: "Chromium Phosphate",
                id: 8000864,
                parent:
                  "/chemicals-materials/paints-and-coatings/coating-additives",
                path: "/chemicals-materials/paints-and-coatings/coating-additives/chromium-phosphate",
                active: true,
                meta: {
                  tags: "Chromium Phosphate",
                  keywords: "Chromium Phosphate",
                  description: "Chromium Phosphate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53d6",
                },
                slug: "defoaming-coating-additives",
                title: "Defoaming Coating Additives",
                id: 8000866,
                parent:
                  "/chemicals-materials/paints-and-coatings/coating-additives",
                path: "/chemicals-materials/paints-and-coatings/coating-additives/defoaming-coating-additives",
                active: true,
                meta: {
                  tags: "Defoaming Coating Additives",
                  keywords: "Defoaming Coating Additives",
                  description: "Defoaming Coating Additives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53d9",
                },
                slug: "packaging-coating-additives",
                title: "Packaging Coating Additives",
                id: 8000869,
                parent:
                  "/chemicals-materials/paints-and-coatings/coating-additives",
                path: "/chemicals-materials/paints-and-coatings/coating-additives/packaging-coating-additives",
                active: true,
                meta: {
                  tags: "Packaging Coating Additives",
                  keywords: "Packaging Coating Additives",
                  description: "Packaging Coating Additives",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53dc",
            },
            slug: "fluoropolymer-coatings",
            title: "Fluoropolymer Coatings",
            id: 8000872,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/fluoropolymer-coatings",
            active: true,
            meta: {
              tags: "Fluoropolymer Coatings",
              keywords: "Fluoropolymer Coatings",
              description: "Fluoropolymer Coatings",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53de",
                },
                slug: "pvdf-coatings",
                title: "PVDF Coatings",
                id: 8000874,
                parent:
                  "/chemicals-materials/paints-and-coatings/fluoropolymer-coatings",
                path: "/chemicals-materials/paints-and-coatings/fluoropolymer-coatings/pvdf-coatings",
                active: true,
                meta: {
                  tags: "PVDF Coatings",
                  keywords: "PVDF Coatings",
                  description: "PVDF Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53dd",
                },
                slug: "feve-coatings",
                title: "FEVE Coatings",
                id: 8000873,
                parent:
                  "/chemicals-materials/paints-and-coatings/fluoropolymer-coatings",
                path: "/chemicals-materials/paints-and-coatings/fluoropolymer-coatings/feve-coatings",
                active: true,
                meta: {
                  tags: "FEVE Coatings",
                  keywords: "FEVE Coatings",
                  description: "FEVE Coatings",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53df",
            },
            slug: "industrial-coatings",
            title: "Industrial Coatings",
            id: 8000875,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/industrial-coatings",
            active: true,
            meta: {
              tags: "Industrial Coatings",
              keywords: "Industrial Coatings",
              description: "Industrial Coatings",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53f0",
                },
                slug: "rail-coatings",
                title: "Rail Coatings",
                id: 8000892,
                parent:
                  "/chemicals-materials/paints-and-coatings/industrial-coatings",
                path: "/chemicals-materials/paints-and-coatings/industrial-coatings/rail-coatings",
                active: true,
                meta: {
                  tags: "Rail Coatings",
                  keywords: "Rail Coatings",
                  description: "Rail Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53e8",
                },
                slug: "protective-coatings",
                title: "Protective Coatings",
                id: 8000884,
                parent:
                  "/chemicals-materials/paints-and-coatings/industrial-coatings",
                path: "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings",
                active: true,
                meta: {
                  tags: "Protective Coatings",
                  keywords: "Protective Coatings",
                  description: "Protective Coatings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53ea",
                    },
                    slug: "marine-coatings",
                    title: "Marine Coatings",
                    id: 8000886,
                    parent:
                      "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings",
                    path: "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/marine-coatings",
                    active: true,
                    meta: {
                      tags: "Marine Coatings",
                      keywords: "Marine Coatings",
                      description: "Marine Coatings",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53ec",
                        },
                        slug: "hull-coatings",
                        title: "Hull Coatings",
                        id: 8000888,
                        parent:
                          "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/marine-coatings",
                        path: "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/marine-coatings/hull-coatings",
                        active: true,
                        meta: {
                          tags: "Hull Coatings",
                          keywords: "Hull Coatings",
                          description: "Hull Coatings",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53eb",
                        },
                        slug: "anti-fouling-coatings",
                        title: "Anti Fouling Coatings",
                        id: 8000887,
                        parent:
                          "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/marine-coatings",
                        path: "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/marine-coatings/anti-fouling-coatings",
                        active: true,
                        meta: {
                          tags: "Anti Fouling Coatings",
                          keywords: "Anti Fouling Coatings",
                          description: "Anti Fouling Coatings",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53ed",
                        },
                        slug: "marine-deck-coatings",
                        title: "Marine Deck Coatings",
                        id: 8000889,
                        parent:
                          "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/marine-coatings",
                        path: "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/marine-coatings/marine-deck-coatings",
                        active: true,
                        meta: {
                          tags: "Marine Deck Coatings",
                          keywords: "Marine Deck Coatings",
                          description: "Marine Deck Coatings",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53ee",
                    },
                    slug: "pipe-coatings",
                    title: "Pipe Coatings",
                    id: 8000890,
                    parent:
                      "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings",
                    path: "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/pipe-coatings",
                    active: true,
                    meta: {
                      tags: "Pipe Coatings",
                      keywords: "Pipe Coatings",
                      description: "Pipe Coatings",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53ef",
                        },
                        slug: "fusion-bonded-epoxy-coatings",
                        title: "Fusion Bonded Epoxy Coatings",
                        id: 8000891,
                        parent:
                          "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/pipe-coatings",
                        path: "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/pipe-coatings/fusion-bonded-epoxy-coatings",
                        active: true,
                        meta: {
                          tags: "Fusion Bonded Epoxy Coatings",
                          keywords: "Fusion Bonded Epoxy Coatings",
                          description: "Fusion Bonded Epoxy Coatings",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53e9",
                    },
                    slug: "anti-corrosion-coatings",
                    title: "Anti Corrosion Coatings",
                    id: 8000885,
                    parent:
                      "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings",
                    path: "/chemicals-materials/paints-and-coatings/industrial-coatings/protective-coatings/anti-corrosion-coatings",
                    active: true,
                    meta: {
                      tags: "Anti Corrosion Coatings",
                      keywords: "Anti Corrosion Coatings",
                      description: "Anti Corrosion Coatings",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53e6",
                },
                slug: "packaging-coatings",
                title: "Packaging Coatings",
                id: 8000882,
                parent:
                  "/chemicals-materials/paints-and-coatings/industrial-coatings",
                path: "/chemicals-materials/paints-and-coatings/industrial-coatings/packaging-coatings",
                active: true,
                meta: {
                  tags: "Packaging Coatings",
                  keywords: "Packaging Coatings",
                  description: "Packaging Coatings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53e7",
                    },
                    slug: "can-coatings",
                    title: "Can Coatings",
                    id: 8000883,
                    parent:
                      "/chemicals-materials/paints-and-coatings/industrial-coatings/packaging-coatings",
                    path: "/chemicals-materials/paints-and-coatings/industrial-coatings/packaging-coatings/can-coatings",
                    active: true,
                    meta: {
                      tags: "Can Coatings",
                      keywords: "Can Coatings",
                      description: "Can Coatings",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53e0",
                },
                slug: "aerospace-coatings",
                title: "Aerospace Coatings",
                id: 8000876,
                parent:
                  "/chemicals-materials/paints-and-coatings/industrial-coatings",
                path: "/chemicals-materials/paints-and-coatings/industrial-coatings/aerospace-coatings",
                active: true,
                meta: {
                  tags: "Aerospace Coatings",
                  keywords: "Aerospace Coatings",
                  description: "Aerospace Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53f1",
                },
                slug: "textile-coatings",
                title: "Textile Coatings",
                id: 8000893,
                parent:
                  "/chemicals-materials/paints-and-coatings/industrial-coatings",
                path: "/chemicals-materials/paints-and-coatings/industrial-coatings/textile-coatings",
                active: true,
                meta: {
                  tags: "Textile Coatings",
                  keywords: "Textile Coatings",
                  description: "Textile Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53f2",
                },
                slug: "wood-coatings",
                title: "Wood Coatings",
                id: 8000894,
                parent:
                  "/chemicals-materials/paints-and-coatings/industrial-coatings",
                path: "/chemicals-materials/paints-and-coatings/industrial-coatings/wood-coatings",
                active: true,
                meta: {
                  tags: "Wood Coatings",
                  keywords: "Wood Coatings",
                  description: "Wood Coatings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53f3",
                    },
                    slug: "fireproofing-coatings-for-wood",
                    title: "Fireproofing Coatings for Wood",
                    id: 8000895,
                    parent:
                      "/chemicals-materials/paints-and-coatings/industrial-coatings/wood-coatings",
                    path: "/chemicals-materials/paints-and-coatings/industrial-coatings/wood-coatings/fireproofing-coatings-for-wood",
                    active: true,
                    meta: {
                      tags: "Fireproofing Coatings for Wood",
                      keywords: "Fireproofing Coatings for Wood",
                      description: "Fireproofing Coatings for Wood",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53e1",
                },
                slug: "automotive-coatings",
                title: "Automotive Coatings",
                id: 8000877,
                parent:
                  "/chemicals-materials/paints-and-coatings/industrial-coatings",
                path: "/chemicals-materials/paints-and-coatings/industrial-coatings/automotive-coatings",
                active: true,
                meta: {
                  tags: "Automotive Coatings",
                  keywords: "Automotive Coatings",
                  description: "Automotive Coatings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53e2",
                    },
                    slug: "automotive-oem-coatings",
                    title: "Automotive OEM Coatings",
                    id: 8000878,
                    parent:
                      "/chemicals-materials/paints-and-coatings/industrial-coatings/automotive-coatings",
                    path: "/chemicals-materials/paints-and-coatings/industrial-coatings/automotive-coatings/automotive-oem-coatings",
                    active: true,
                    meta: {
                      tags: "Automotive OEM Coatings",
                      keywords: "Automotive OEM Coatings",
                      description: "Automotive OEM Coatings",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53e3",
                        },
                        slug: "automotive-oem-interior-coatings",
                        title: "Automotive OEM Interior Coatings",
                        id: 8000879,
                        parent:
                          "/chemicals-materials/paints-and-coatings/industrial-coatings/automotive-coatings/automotive-oem-coatings",
                        path: "/chemicals-materials/paints-and-coatings/industrial-coatings/automotive-coatings/automotive-oem-coatings/automotive-oem-interior-coatings",
                        active: true,
                        meta: {
                          tags: "Automotive OEM Interior Coatings",
                          keywords: "Automotive OEM Interior Coatings",
                          description: "Automotive OEM Interior Coatings",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53e4",
                },
                slug: "conformal-coatings",
                title: "Conformal Coatings",
                id: 8000880,
                parent:
                  "/chemicals-materials/paints-and-coatings/industrial-coatings",
                path: "/chemicals-materials/paints-and-coatings/industrial-coatings/conformal-coatings",
                active: true,
                meta: {
                  tags: "Conformal Coatings",
                  keywords: "Conformal Coatings",
                  description: "Conformal Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53e5",
                },
                slug: "consumer-appliance-coatings",
                title: "Consumer Appliance Coatings",
                id: 8000881,
                parent:
                  "/chemicals-materials/paints-and-coatings/industrial-coatings",
                path: "/chemicals-materials/paints-and-coatings/industrial-coatings/consumer-appliance-coatings",
                active: true,
                meta: {
                  tags: "Consumer Appliance Coatings",
                  keywords: "Consumer Appliance Coatings",
                  description: "Consumer Appliance Coatings",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53f5",
            },
            slug: "polyaspartic-coatings",
            title: "Polyaspartic Coatings",
            id: 8000897,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/polyaspartic-coatings",
            active: true,
            meta: {
              tags: "Polyaspartic Coatings",
              keywords: "Polyaspartic Coatings",
              description: "Polyaspartic Coatings",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5442",
            },
            slug: "uv-cured-coatings",
            title: "UV Cured Coatings",
            id: 8000974,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/uv-cured-coatings",
            active: true,
            meta: {
              tags: "UV Cured Coatings",
              keywords: "UV Cured Coatings",
              description: "UV Cured Coatings",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5443",
                },
                slug: "radiation-curable-coatings",
                title: "Radiation Curable Coatings",
                id: 8000975,
                parent:
                  "/chemicals-materials/paints-and-coatings/uv-cured-coatings",
                path: "/chemicals-materials/paints-and-coatings/uv-cured-coatings/radiation-curable-coatings",
                active: true,
                meta: {
                  tags: "Radiation Curable Coatings",
                  keywords: "Radiation Curable Coatings",
                  description: "Radiation Curable Coatings",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5444",
            },
            slug: "waterborne-coatings",
            title: "Waterborne Coatings",
            id: 8000976,
            parent: "/chemicals-materials/paints-and-coatings",
            path: "/chemicals-materials/paints-and-coatings/waterborne-coatings",
            active: true,
            meta: {
              tags: "Waterborne Coatings",
              keywords: "Waterborne Coatings",
              description: "Waterborne Coatings",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5447",
                },
                slug: "emulsion-coatings",
                title: "Emulsion Coatings",
                id: 8000979,
                parent:
                  "/chemicals-materials/paints-and-coatings/waterborne-coatings",
                path: "/chemicals-materials/paints-and-coatings/waterborne-coatings/emulsion-coatings",
                active: true,
                meta: {
                  tags: "Emulsion Coatings",
                  keywords: "Emulsion Coatings",
                  description: "Emulsion Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5448",
                },
                slug: "latex-paints",
                title: "Latex Paints",
                id: 8000980,
                parent:
                  "/chemicals-materials/paints-and-coatings/waterborne-coatings",
                path: "/chemicals-materials/paints-and-coatings/waterborne-coatings/latex-paints",
                active: true,
                meta: {
                  tags: "Latex Paints",
                  keywords: "Latex Paints",
                  description: "Latex Paints",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5445",
                },
                slug: "alkyd-coatings",
                title: "Alkyd Coatings",
                id: 8000977,
                parent:
                  "/chemicals-materials/paints-and-coatings/waterborne-coatings",
                path: "/chemicals-materials/paints-and-coatings/waterborne-coatings/alkyd-coatings",
                active: true,
                meta: {
                  tags: "Alkyd Coatings",
                  keywords: "Alkyd Coatings",
                  description: "Alkyd Coatings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5446",
                    },
                    slug: "water-borne-alkyd-coatings",
                    title: "Water Borne Alkyd Coatings",
                    id: 8000978,
                    parent:
                      "/chemicals-materials/paints-and-coatings/waterborne-coatings/alkyd-coatings",
                    path: "/chemicals-materials/paints-and-coatings/waterborne-coatings/alkyd-coatings/water-borne-alkyd-coatings",
                    active: true,
                    meta: {
                      tags: "Water Borne Alkyd Coatings",
                      keywords: "Water Borne Alkyd Coatings",
                      description: "Water Borne Alkyd Coatings",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5449",
                },
                slug: "polyurethane-coatings",
                title: "Polyurethane Coatings",
                id: 8000981,
                parent:
                  "/chemicals-materials/paints-and-coatings/waterborne-coatings",
                path: "/chemicals-materials/paints-and-coatings/waterborne-coatings/polyurethane-coatings",
                active: true,
                meta: {
                  tags: "Polyurethane Coatings",
                  keywords: "Polyurethane Coatings",
                  description: "Polyurethane Coatings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d544a",
                    },
                    slug: "waterborne-polyurethane-coatings",
                    title: "Waterborne Polyurethane Coatings",
                    id: 8000982,
                    parent:
                      "/chemicals-materials/paints-and-coatings/waterborne-coatings/polyurethane-coatings",
                    path: "/chemicals-materials/paints-and-coatings/waterborne-coatings/polyurethane-coatings/waterborne-polyurethane-coatings",
                    active: true,
                    meta: {
                      tags: "Waterborne Polyurethane Coatings",
                      keywords: "Waterborne Polyurethane Coatings",
                      description: "Waterborne Polyurethane Coatings",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b18b",
        },
        id: 61109,
        title: "Fine Chemicals",
        slug: "fine-chemicals",
        active: true,
        meta: {
          description:
            "Specialty chemicals are constituted by a mixture of different chemical substances, that are designed and produced in order to be applied to a specific application. Fine chemicals are chemical substances characterized by their chemical structure but are produced in small quantities. Mostly used as components in the formulation of specialty chemicals.",
          keywords:
            "Specialty & Fine Chemicals Market Research Reports, Specialty & Fine Chemicals, Specialty & Fine Chemicals market, Specialty & Fine Chemicals industry, Specialty & Fine Chemicals market report, Specialty & Fine Chemicals industry report, Specialty & Fine Chemicals market research report, Specialty & Fine Chemicals industry research report",
          tags: "Specialty & Fine Chemicals Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Specialty chemicals are constituted by a mixture of different chemical substances, that is designed and produced in order to be applied to a specific application. Fine chemicals are chemical substances characterized by their chemical structure, but are produced in a small quantity. Mostly used as components in the formulation of speciality chemicals.",
          short_description:
            "Specialty chemicals are a mix of chemical substances produced for specific applications. Fine chemicals, characterized by their unique structure, are produced in low quantities. This segment analyzes trends and provides information about the specialty and fine chemicals market.",
        },
        picture: null,
        __v: 0,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/fine-chemicals",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f8",
            },
            slug: "furfural",
            title: "Furfural",
            id: 8000643,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/furfural",
            active: true,
            meta: {
              tags: "Furfural",
              keywords: "Furfural",
              description: "Furfural",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5301",
            },
            slug: "sterol",
            title: "Sterol",
            id: 8000652,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/sterol",
            active: true,
            meta: {
              tags: "Sterol",
              keywords: "Sterol",
              description: "Sterol",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52fd",
            },
            slug: "melatonin",
            title: "Melatonin",
            id: 8000648,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/melatonin",
            active: true,
            meta: {
              tags: "Melatonin",
              keywords: "Melatonin",
              description: "Melatonin",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52fc",
            },
            slug: "isosorbide",
            title: "Isosorbide",
            id: 8000647,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/isosorbide",
            active: true,
            meta: {
              tags: "Isosorbide",
              keywords: "Isosorbide",
              description: "Isosorbide",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5300",
            },
            slug: "sodium-citrate",
            title: "Sodium Citrate",
            id: 8000651,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/sodium-citrate",
            active: true,
            meta: {
              tags: "Sodium Citrate",
              keywords: "Sodium Citrate",
              description: "Sodium Citrate",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f5",
            },
            slug: "choline-chloride",
            title: "Choline Chloride",
            id: 8000640,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/choline-chloride",
            active: true,
            meta: {
              tags: "Choline Chloride",
              keywords: "Choline Chloride",
              description: "Choline Chloride",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f9",
            },
            slug: "hydrazine-hydrate",
            title: "Hydrazine Hydrate",
            id: 8000644,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/hydrazine-hydrate",
            active: true,
            meta: {
              tags: "Hydrazine Hydrate",
              keywords: "Hydrazine Hydrate",
              description: "Hydrazine Hydrate",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52fb",
            },
            slug: "industrial-enzymes",
            title: "Industrial Enzymes",
            id: 8000646,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/industrial-enzymes",
            active: true,
            meta: {
              tags: "Industrial Enzymes",
              keywords: "Industrial Enzymes",
              description: "Industrial Enzymes",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f6",
            },
            slug: "cosmetic-antioxidants",
            title: "Cosmetic Antioxidants",
            id: 8000641,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/cosmetic-antioxidants",
            active: true,
            meta: {
              tags: "Cosmetic Antioxidants",
              keywords: "Cosmetic Antioxidants",
              description: "Cosmetic Antioxidants",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f7",
            },
            slug: "cysteine",
            title: "Cysteine",
            id: 8000642,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/cysteine",
            active: true,
            meta: {
              tags: "Cysteine",
              keywords: "Cysteine",
              description: "Cysteine",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52ff",
            },
            slug: "resorcinol",
            title: "Resorcinol",
            id: 8000650,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/resorcinol",
            active: true,
            meta: {
              tags: "Resorcinol",
              keywords: "Resorcinol",
              description: "Resorcinol",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5303",
            },
            slug: "xanthene-derivatives",
            title: "Xanthene Derivatives",
            id: 8000654,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/xanthene-derivatives",
            active: true,
            meta: {
              tags: "Xanthene Derivatives",
              keywords: "Xanthene Derivatives",
              description: "Xanthene Derivatives",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f4",
            },
            slug: "alpha-methylstyrene",
            title: "Alpha Methylstyrene",
            id: 8000639,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/alpha-methylstyrene",
            active: true,
            meta: {
              tags: "Alpha Methylstyrene",
              keywords: "Alpha Methylstyrene",
              description: "Alpha Methylstyrene",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52fe",
            },
            slug: "potassium-metabisulfite",
            title: "Potassium Metabisulfite",
            id: 8000649,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/potassium-metabisulfite",
            active: true,
            meta: {
              tags: "Potassium Metabisulfite",
              keywords: "Potassium Metabisulfite",
              description: "Potassium Metabisulfite",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52fa",
            },
            slug: "hydroxyl-terminated-polybutadiene",
            title: "Hydroxyl Terminated Polybutadiene",
            id: 8000645,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/hydroxyl-terminated-polybutadiene",
            active: true,
            meta: {
              tags: "Hydroxyl Terminated Polybutadiene",
              keywords: "Hydroxyl Terminated Polybutadiene",
              description: "Hydroxyl Terminated Polybutadiene",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5302",
            },
            slug: "triacetin",
            title: "Triacetin",
            id: 8000653,
            parent: "/chemicals-materials/fine-chemicals",
            path: "/chemicals-materials/fine-chemicals/triacetin",
            active: true,
            meta: {
              tags: "Triacetin",
              keywords: "Triacetin",
              description: "Triacetin",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d507b",
        },
        slug: "abrasives",
        title: "Abrasives",
        id: 61632,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/abrasives",
        active: true,
        meta: {
          tags: "Abrasives",
          keywords: "Abrasives",
          description: "Abrasives",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5081",
            },
            slug: "super-abrasives",
            title: "Super Abrasives",
            id: 8000007,
            parent: "/chemicals-materials/abrasives",
            path: "/chemicals-materials/abrasives/super-abrasives",
            active: true,
            meta: {
              tags: "Super Abrasives",
              keywords: "Super Abrasives",
              description: "Super Abrasives",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d507c",
            },
            slug: "bonded-abrasives",
            title: "Bonded Abrasives",
            id: 8000002,
            parent: "/chemicals-materials/abrasives",
            path: "/chemicals-materials/abrasives/bonded-abrasives",
            active: true,
            meta: {
              tags: "Bonded Abrasives",
              keywords: "Bonded Abrasives",
              description: "Bonded Abrasives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d507e",
                },
                slug: "rubber-bonded-abrasives",
                title: "Rubber Bonded Abrasives",
                id: 8000004,
                parent: "/chemicals-materials/abrasives/bonded-abrasives",
                path: "/chemicals-materials/abrasives/bonded-abrasives/rubber-bonded-abrasives",
                active: true,
                meta: {
                  tags: "Rubber Bonded Abrasives",
                  keywords: "Rubber Bonded Abrasives",
                  description: "Rubber Bonded Abrasives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d507d",
                },
                slug: "paper-backing-abrasives-adhesives-discs",
                title: "Paper Backing Abrasives Adhesives Discs",
                id: 8000003,
                parent: "/chemicals-materials/abrasives/bonded-abrasives",
                path: "/chemicals-materials/abrasives/bonded-abrasives/paper-backing-abrasives-adhesives-discs",
                active: true,
                meta: {
                  tags: "Paper Backing Abrasives Adhesives Discs",
                  keywords: "Paper Backing Abrasives Adhesives Discs",
                  description: "Paper Backing Abrasives Adhesives Discs",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d507f",
            },
            slug: "sandpaper",
            title: "Sandpaper",
            id: 8000005,
            parent: "/chemicals-materials/abrasives",
            path: "/chemicals-materials/abrasives/sandpaper",
            active: true,
            meta: {
              tags: "Sandpaper",
              keywords: "Sandpaper",
              description: "Sandpaper",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5080",
                },
                slug: "liquid-sandpaper",
                title: "Liquid Sandpaper",
                id: 8000006,
                parent: "/chemicals-materials/abrasives/sandpaper",
                path: "/chemicals-materials/abrasives/sandpaper/liquid-sandpaper",
                active: true,
                meta: {
                  tags: "Liquid Sandpaper",
                  keywords: "Liquid Sandpaper",
                  description: "Liquid Sandpaper",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5082",
            },
            slug: "tungsten-carbide-powder",
            title: "Tungsten Carbide Powder",
            id: 8000008,
            parent: "/chemicals-materials/abrasives",
            path: "/chemicals-materials/abrasives/tungsten-carbide-powder",
            active: true,
            meta: {
              tags: "Tungsten Carbide Powder",
              keywords: "Tungsten Carbide Powder",
              description: "Tungsten Carbide Powder",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d5130",
        },
        slug: "biobased-chemicals",
        title: "Biobased Chemicals",
        id: 61633,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/biobased-chemicals",
        active: true,
        meta: {
          tags: "Biobased Chemicals",
          keywords: "Biobased Chemicals",
          description: "Biobased Chemicals",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5131",
            },
            slug: "bio-acid",
            title: "Bio Acid",
            id: 8000185,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/bio-acid",
            active: true,
            meta: {
              tags: "Bio Acid",
              keywords: "Bio Acid",
              description: "Bio Acid",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5133",
                },
                slug: "bio-organic-acid",
                title: "Bio Organic Acid",
                id: 8000187,
                parent: "/chemicals-materials/biobased-chemicals/bio-acid",
                path: "/chemicals-materials/biobased-chemicals/bio-acid/bio-organic-acid",
                active: true,
                meta: {
                  tags: "Bio Organic Acid",
                  keywords: "Bio Organic Acid",
                  description: "Bio Organic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5132",
                },
                slug: "bio-acetic-acid",
                title: "Bio Acetic Acid",
                id: 8000186,
                parent: "/chemicals-materials/biobased-chemicals/bio-acid",
                path: "/chemicals-materials/biobased-chemicals/bio-acid/bio-acetic-acid",
                active: true,
                meta: {
                  tags: "Bio Acetic Acid",
                  keywords: "Bio Acetic Acid",
                  description: "Bio Acetic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5136",
                },
                slug: "natural-fatty-acid",
                title: "Natural Fatty Acid",
                id: 8000190,
                parent: "/chemicals-materials/biobased-chemicals/bio-acid",
                path: "/chemicals-materials/biobased-chemicals/bio-acid/natural-fatty-acid",
                active: true,
                meta: {
                  tags: "Natural Fatty Acid",
                  keywords: "Natural Fatty Acid",
                  description: "Natural Fatty Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5134",
                },
                slug: "bio-polylactic-acid",
                title: "Bio Polylactic Acid",
                id: 8000188,
                parent: "/chemicals-materials/biobased-chemicals/bio-acid",
                path: "/chemicals-materials/biobased-chemicals/bio-acid/bio-polylactic-acid",
                active: true,
                meta: {
                  tags: "Bio Polylactic Acid",
                  keywords: "Bio Polylactic Acid",
                  description: "Bio Polylactic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5135",
                },
                slug: "bio-succinic-acid",
                title: "Bio Succinic Acid",
                id: 8000189,
                parent: "/chemicals-materials/biobased-chemicals/bio-acid",
                path: "/chemicals-materials/biobased-chemicals/bio-acid/bio-succinic-acid",
                active: true,
                meta: {
                  tags: "Bio Succinic Acid",
                  keywords: "Bio Succinic Acid",
                  description: "Bio Succinic Acid",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5140",
            },
            slug: "fatty-acid-ester",
            title: "Fatty Acid Ester",
            id: 8000200,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/fatty-acid-ester",
            active: true,
            meta: {
              tags: "Fatty Acid Ester",
              keywords: "Fatty Acid Ester",
              description: "Fatty Acid Ester",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5144",
            },
            slug: "industrial-hemp",
            title: "Industrial Hemp",
            id: 8000204,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/industrial-hemp",
            active: true,
            meta: {
              tags: "Industrial Hemp",
              keywords: "Industrial Hemp",
              description: "Industrial Hemp",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d513e",
            },
            slug: "bio-ketones",
            title: "Bio Ketones",
            id: 8000198,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/bio-ketones",
            active: true,
            meta: {
              tags: "Bio Ketones",
              keywords: "Bio Ketones",
              description: "Bio Ketones",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d513f",
            },
            slug: "crude-tall-oil-derivatives",
            title: "Crude Tall Oil Derivatives",
            id: 8000199,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/crude-tall-oil-derivatives",
            active: true,
            meta: {
              tags: "Crude Tall Oil Derivatives",
              keywords: "Crude Tall Oil Derivatives",
              description: "Crude Tall Oil Derivatives",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5145",
            },
            slug: "pine-chemicals",
            title: "Pine Chemicals",
            id: 8000205,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/pine-chemicals",
            active: true,
            meta: {
              tags: "Pine Chemicals",
              keywords: "Pine Chemicals",
              description: "Pine Chemicals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5137",
            },
            slug: "bio-alcohols",
            title: "Bio Alcohols",
            id: 8000191,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/bio-alcohols",
            active: true,
            meta: {
              tags: "Bio Alcohols",
              keywords: "Bio Alcohols",
              description: "Bio Alcohols",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d513b",
                },
                slug: "glycerin",
                title: "Glycerin",
                id: 8000195,
                parent: "/chemicals-materials/biobased-chemicals/bio-alcohols",
                path: "/chemicals-materials/biobased-chemicals/bio-alcohols/glycerin",
                active: true,
                meta: {
                  tags: "Glycerin",
                  keywords: "Glycerin",
                  description: "Glycerin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5139",
                },
                slug: "biobutanol",
                title: "Biobutanol",
                id: 8000193,
                parent: "/chemicals-materials/biobased-chemicals/bio-alcohols",
                path: "/chemicals-materials/biobased-chemicals/bio-alcohols/biobutanol",
                active: true,
                meta: {
                  tags: "Biobutanol",
                  keywords: "Biobutanol",
                  description: "Biobutanol",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5138",
                },
                slug: "bio-based-14-butanediol",
                title: "Bio-based 1,4-Butanediol",
                id: 8000192,
                parent: "/chemicals-materials/biobased-chemicals/bio-alcohols",
                path: "/chemicals-materials/biobased-chemicals/bio-alcohols/bio-based-14-butanediol",
                active: true,
                meta: {
                  tags: "Bio-based 1,4-Butanediol",
                  keywords: "Bio-based 1,4-Butanediol",
                  description: "Bio-based 1,4-Butanediol",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d513a",
                },
                slug: "bioethanol",
                title: "Bioethanol",
                id: 8000194,
                parent: "/chemicals-materials/biobased-chemicals/bio-alcohols",
                path: "/chemicals-materials/biobased-chemicals/bio-alcohols/bioethanol",
                active: true,
                meta: {
                  tags: "Bioethanol",
                  keywords: "Bioethanol",
                  description: "Bioethanol",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d513d",
            },
            slug: "bio-based-resins",
            title: "Bio based Resins",
            id: 8000197,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/bio-based-resins",
            active: true,
            meta: {
              tags: "Bio based Resins",
              keywords: "Bio based Resins",
              description: "Bio based Resins",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5141",
            },
            slug: "fatty-acid-methyl-ester",
            title: "Fatty Acid Methyl Ester",
            id: 8000201,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/fatty-acid-methyl-ester",
            active: true,
            meta: {
              tags: "Fatty Acid Methyl Ester",
              keywords: "Fatty Acid Methyl Ester",
              description: "Fatty Acid Methyl Ester",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5142",
            },
            slug: "fatty-alcohol",
            title: "Fatty Alcohol",
            id: 8000202,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/fatty-alcohol",
            active: true,
            meta: {
              tags: "Fatty Alcohol",
              keywords: "Fatty Alcohol",
              description: "Fatty Alcohol",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5143",
            },
            slug: "fermentation-chemicals",
            title: "Fermentation Chemicals",
            id: 8000203,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/fermentation-chemicals",
            active: true,
            meta: {
              tags: "Fermentation Chemicals",
              keywords: "Fermentation Chemicals",
              description: "Fermentation Chemicals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d513c",
            },
            slug: "bio-based-platform-chemicals",
            title: "Bio based Platform Chemicals",
            id: 8000196,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/bio-based-platform-chemicals",
            active: true,
            meta: {
              tags: "Bio based Platform Chemicals",
              keywords: "Bio based Platform Chemicals",
              description: "Bio based Platform Chemicals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5146",
            },
            slug: "pyrolysis-oil",
            title: "Pyrolysis Oil",
            id: 8000206,
            parent: "/chemicals-materials/biobased-chemicals",
            path: "/chemicals-materials/biobased-chemicals/pyrolysis-oil",
            active: true,
            meta: {
              tags: "Pyrolysis Oil",
              keywords: "Pyrolysis Oil",
              description: "Pyrolysis Oil",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d517d",
        },
        slug: "chemical-technologies",
        title: "Chemical Technologies",
        id: 61635,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/chemical-technologies",
        active: true,
        meta: {
          tags: "Chemical Technologies",
          keywords: "Chemical Technologies",
          description: "Chemical Technologies",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d519e",
            },
            slug: "porous-media",
            title: "Porous Media",
            id: 8000294,
            parent: "/chemicals-materials/chemical-technologies",
            path: "/chemicals-materials/chemical-technologies/porous-media",
            active: true,
            meta: {
              tags: "Porous Media",
              keywords: "Porous Media",
              description: "Porous Media",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d519f",
                },
                slug: "molecular-sieves",
                title: "Molecular Sieves",
                id: 8000295,
                parent:
                  "/chemicals-materials/chemical-technologies/porous-media",
                path: "/chemicals-materials/chemical-technologies/porous-media/molecular-sieves",
                active: true,
                meta: {
                  tags: "Molecular Sieves",
                  keywords: "Molecular Sieves",
                  description: "Molecular Sieves",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d51a0",
                    },
                    slug: "zeolite-molecular-sieves",
                    title: "Zeolite Molecular Sieves",
                    id: 8000296,
                    parent:
                      "/chemicals-materials/chemical-technologies/porous-media/molecular-sieves",
                    path: "/chemicals-materials/chemical-technologies/porous-media/molecular-sieves/zeolite-molecular-sieves",
                    active: true,
                    meta: {
                      tags: "Zeolite Molecular Sieves",
                      keywords: "Zeolite Molecular Sieves",
                      description: "Zeolite Molecular Sieves",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d51a1",
                        },
                        slug: "specialty-zeolites",
                        title: "Specialty Zeolites",
                        id: 8000297,
                        parent:
                          "/chemicals-materials/chemical-technologies/porous-media/molecular-sieves/zeolite-molecular-sieves",
                        path: "/chemicals-materials/chemical-technologies/porous-media/molecular-sieves/zeolite-molecular-sieves/specialty-zeolites",
                        active: true,
                        meta: {
                          tags: "Specialty Zeolites",
                          keywords: "Specialty Zeolites",
                          description: "Specialty Zeolites",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d517f",
            },
            slug: "catalysts",
            title: "Catalysts",
            id: 8000263,
            parent: "/chemicals-materials/chemical-technologies",
            path: "/chemicals-materials/chemical-technologies/catalysts",
            active: true,
            meta: {
              tags: "Catalysts",
              keywords: "Catalysts",
              description: "Catalysts",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5180",
                },
                slug: "aluminum-nickel-catalysts",
                title: "Aluminum Nickel Catalysts",
                id: 8000264,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/aluminum-nickel-catalysts",
                active: true,
                meta: {
                  tags: "Aluminum Nickel Catalysts",
                  keywords: "Aluminum Nickel Catalysts",
                  description: "Aluminum Nickel Catalysts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5189",
                },
                slug: "polyolefin-catalysts",
                title: "Polyolefin Catalysts",
                id: 8000273,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/polyolefin-catalysts",
                active: true,
                meta: {
                  tags: "Polyolefin Catalysts",
                  keywords: "Polyolefin Catalysts",
                  description: "Polyolefin Catalysts",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d518a",
                    },
                    slug: "polypropylene-catalyst",
                    title: "Polypropylene Catalyst",
                    id: 8000274,
                    parent:
                      "/chemicals-materials/chemical-technologies/catalysts/polyolefin-catalysts",
                    path: "/chemicals-materials/chemical-technologies/catalysts/polyolefin-catalysts/polypropylene-catalyst",
                    active: true,
                    meta: {
                      tags: "Polypropylene Catalyst",
                      keywords: "Polypropylene Catalyst",
                      description: "Polypropylene Catalyst",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d518b",
                },
                slug: "refining-catalysts",
                title: "Refining Catalysts",
                id: 8000275,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/refining-catalysts",
                active: true,
                meta: {
                  tags: "Refining Catalysts",
                  keywords: "Refining Catalysts",
                  description: "Refining Catalysts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5181",
                },
                slug: "automotive-catalyst",
                title: "Automotive Catalyst",
                id: 8000265,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/automotive-catalyst",
                active: true,
                meta: {
                  tags: "Automotive Catalyst",
                  keywords: "Automotive Catalyst",
                  description: "Automotive Catalyst",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5188",
                },
                slug: "polymerization-catalysts",
                title: "Polymerization Catalysts",
                id: 8000272,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/polymerization-catalysts",
                active: true,
                meta: {
                  tags: "Polymerization Catalysts",
                  keywords: "Polymerization Catalysts",
                  description: "Polymerization Catalysts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5184",
                },
                slug: "emission-control-catalysts",
                title: "Emission Control Catalysts",
                id: 8000268,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/emission-control-catalysts",
                active: true,
                meta: {
                  tags: "Emission Control Catalysts",
                  keywords: "Emission Control Catalysts",
                  description: "Emission Control Catalysts",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5185",
                    },
                    slug: "stationary-emission-control-catalysts",
                    title: "Stationary Emission Control Catalysts",
                    id: 8000269,
                    parent:
                      "/chemicals-materials/chemical-technologies/catalysts/emission-control-catalysts",
                    path: "/chemicals-materials/chemical-technologies/catalysts/emission-control-catalysts/stationary-emission-control-catalysts",
                    active: true,
                    meta: {
                      tags: "Stationary Emission Control Catalysts",
                      keywords: "Stationary Emission Control Catalysts",
                      description: "Stationary Emission Control Catalysts",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5182",
                },
                slug: "catalyst-carrier",
                title: "Catalyst Carrier",
                id: 8000266,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/catalyst-carrier",
                active: true,
                meta: {
                  tags: "Catalyst Carrier",
                  keywords: "Catalyst Carrier",
                  description: "Catalyst Carrier",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5183",
                },
                slug: "catalyst-regeneration",
                title: "Catalyst Regeneration",
                id: 8000267,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/catalyst-regeneration",
                active: true,
                meta: {
                  tags: "Catalyst Regeneration",
                  keywords: "Catalyst Regeneration",
                  description: "Catalyst Regeneration",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5187",
                },
                slug: "photocatalyst",
                title: "Photocatalyst",
                id: 8000271,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/photocatalyst",
                active: true,
                meta: {
                  tags: "Photocatalyst",
                  keywords: "Photocatalyst",
                  description: "Photocatalyst",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5186",
                },
                slug: "phase-transfer-catalyst",
                title: "Phase Transfer Catalyst",
                id: 8000270,
                parent: "/chemicals-materials/chemical-technologies/catalysts",
                path: "/chemicals-materials/chemical-technologies/catalysts/phase-transfer-catalyst",
                active: true,
                meta: {
                  tags: "Phase Transfer Catalyst",
                  keywords: "Phase Transfer Catalyst",
                  description: "Phase Transfer Catalyst",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d518c",
            },
            slug: "chemical-machinery-and-equipment",
            title: "Chemical Machinery and Equipment",
            id: 8000276,
            parent: "/chemicals-materials/chemical-technologies",
            path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment",
            active: true,
            meta: {
              tags: "Chemical Machinery and Equipment",
              keywords: "Chemical Machinery and Equipment",
              description: "Chemical Machinery and Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d518e",
                },
                slug: "cold-heading-machine",
                title: "Cold Heading Machine",
                id: 8000278,
                parent:
                  "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment",
                path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/cold-heading-machine",
                active: true,
                meta: {
                  tags: "Cold Heading Machine",
                  keywords: "Cold Heading Machine",
                  description: "Cold Heading Machine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d518d",
                },
                slug: "chemical-injection-skids",
                title: "Chemical Injection Skids",
                id: 8000277,
                parent:
                  "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment",
                path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/chemical-injection-skids",
                active: true,
                meta: {
                  tags: "Chemical Injection Skids",
                  keywords: "Chemical Injection Skids",
                  description: "Chemical Injection Skids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d518f",
                },
                slug: "economizer",
                title: "Economizer",
                id: 8000279,
                parent:
                  "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment",
                path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/economizer",
                active: true,
                meta: {
                  tags: "Economizer",
                  keywords: "Economizer",
                  description: "Economizer",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5190",
                },
                slug: "gasket-and-seals",
                title: "Gasket and Seals",
                id: 8000280,
                parent:
                  "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment",
                path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/gasket-and-seals",
                active: true,
                meta: {
                  tags: "Gasket and Seals",
                  keywords: "Gasket and Seals",
                  description: "Gasket and Seals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5191",
                    },
                    slug: "mechanical-seals",
                    title: "Mechanical Seals",
                    id: 8000281,
                    parent:
                      "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/gasket-and-seals",
                    path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/gasket-and-seals/mechanical-seals",
                    active: true,
                    meta: {
                      tags: "Mechanical Seals",
                      keywords: "Mechanical Seals",
                      description: "Mechanical Seals",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5192",
                },
                slug: "pumps-and-valves",
                title: "Pumps and Valves",
                id: 8000282,
                parent:
                  "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment",
                path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/pumps-and-valves",
                active: true,
                meta: {
                  tags: "Pumps and Valves",
                  keywords: "Pumps and Valves",
                  description: "Pumps and Valves",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5193",
                    },
                    slug: "chemical-injection-metering-pumps-and-valves",
                    title: "Chemical Injection Metering Pumps and Valves",
                    id: 8000283,
                    parent:
                      "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/pumps-and-valves",
                    path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/pumps-and-valves/chemical-injection-metering-pumps-and-valves",
                    active: true,
                    meta: {
                      tags: "Chemical Injection Metering Pumps and Valves",
                      keywords: "Chemical Injection Metering Pumps and Valves",
                      description:
                        "Chemical Injection Metering Pumps and Valves",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5194",
                    },
                    slug: "valves",
                    title: "Valves",
                    id: 8000284,
                    parent:
                      "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/pumps-and-valves",
                    path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/pumps-and-valves/valves",
                    active: true,
                    meta: {
                      tags: "Valves",
                      keywords: "Valves",
                      description: "Valves",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5196",
                        },
                        slug: "industrial-valves",
                        title: "Industrial Valves",
                        id: 8000286,
                        parent:
                          "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/pumps-and-valves/valves",
                        path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/pumps-and-valves/valves/industrial-valves",
                        active: true,
                        meta: {
                          tags: "Industrial Valves",
                          keywords: "Industrial Valves",
                          description: "Industrial Valves",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5195",
                        },
                        slug: "cryogenic-valves",
                        title: "Cryogenic Valves",
                        id: 8000285,
                        parent:
                          "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/pumps-and-valves/valves",
                        path: "/chemicals-materials/chemical-technologies/chemical-machinery-and-equipment/pumps-and-valves/valves/cryogenic-valves",
                        active: true,
                        meta: {
                          tags: "Cryogenic Valves",
                          keywords: "Cryogenic Valves",
                          description: "Cryogenic Valves",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d517e",
            },
            slug: "carbon-capture-and-storage",
            title: "Carbon Capture and Storage",
            id: 8000262,
            parent: "/chemicals-materials/chemical-technologies",
            path: "/chemicals-materials/chemical-technologies/carbon-capture-and-storage",
            active: true,
            meta: {
              tags: "Carbon Capture and Storage",
              keywords: "Carbon Capture and Storage",
              description: "Carbon Capture and Storage",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5198",
            },
            slug: "microparticles",
            title: "Microparticles",
            id: 8000288,
            parent: "/chemicals-materials/chemical-technologies",
            path: "/chemicals-materials/chemical-technologies/microparticles",
            active: true,
            meta: {
              tags: "Microparticles",
              keywords: "Microparticles",
              description: "Microparticles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5199",
                },
                slug: "microspheres",
                title: "Microspheres",
                id: 8000289,
                parent:
                  "/chemicals-materials/chemical-technologies/microparticles",
                path: "/chemicals-materials/chemical-technologies/microparticles/microspheres",
                active: true,
                meta: {
                  tags: "Microspheres",
                  keywords: "Microspheres",
                  description: "Microspheres",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d519c",
                    },
                    slug: "polymer-microspheres",
                    title: "Polymer Microspheres",
                    id: 8000292,
                    parent:
                      "/chemicals-materials/chemical-technologies/microparticles/microspheres",
                    path: "/chemicals-materials/chemical-technologies/microparticles/microspheres/polymer-microspheres",
                    active: true,
                    meta: {
                      tags: "Polymer Microspheres",
                      keywords: "Polymer Microspheres",
                      description: "Polymer Microspheres",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d519d",
                    },
                    slug: "polyurethane-microspheres",
                    title: "Polyurethane Microspheres",
                    id: 8000293,
                    parent:
                      "/chemicals-materials/chemical-technologies/microparticles/microspheres",
                    path: "/chemicals-materials/chemical-technologies/microparticles/microspheres/polyurethane-microspheres",
                    active: true,
                    meta: {
                      tags: "Polyurethane Microspheres",
                      keywords: "Polyurethane Microspheres",
                      description: "Polyurethane Microspheres",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d519a",
                    },
                    slug: "flyash-cenospheres-microspheres",
                    title: "Flyash (Cenospheres) Microspheres",
                    id: 8000290,
                    parent:
                      "/chemicals-materials/chemical-technologies/microparticles/microspheres",
                    path: "/chemicals-materials/chemical-technologies/microparticles/microspheres/flyash-cenospheres-microspheres",
                    active: true,
                    meta: {
                      tags: "Flyash (Cenospheres) Microspheres",
                      keywords: "Flyash (Cenospheres) Microspheres",
                      description: "Flyash (Cenospheres) Microspheres",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d519b",
                    },
                    slug: "pmma-microspheres",
                    title: "PMMA Microspheres",
                    id: 8000291,
                    parent:
                      "/chemicals-materials/chemical-technologies/microparticles/microspheres",
                    path: "/chemicals-materials/chemical-technologies/microparticles/microspheres/pmma-microspheres",
                    active: true,
                    meta: {
                      tags: "PMMA Microspheres",
                      keywords: "PMMA Microspheres",
                      description: "PMMA Microspheres",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5197",
            },
            slug: "industrial-absorbent",
            title: "Industrial Absorbent",
            id: 8000287,
            parent: "/chemicals-materials/chemical-technologies",
            path: "/chemicals-materials/chemical-technologies/industrial-absorbent",
            active: true,
            meta: {
              tags: "Industrial Absorbent",
              keywords: "Industrial Absorbent",
              description: "Industrial Absorbent",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d5147",
        },
        slug: "ceramics",
        title: "Ceramics",
        id: 61634,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/ceramics",
        active: true,
        meta: {
          tags: "Ceramics",
          keywords: "Ceramics",
          description: "Ceramics",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d516f",
            },
            slug: "glass-ceramics",
            title: "Glass Ceramics",
            id: 8000247,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/glass-ceramics",
            active: true,
            meta: {
              tags: "Glass Ceramics",
              keywords: "Glass Ceramics",
              description: "Glass Ceramics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d517a",
            },
            slug: "technical-ceramics",
            title: "Technical Ceramics",
            id: 8000258,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/technical-ceramics",
            active: true,
            meta: {
              tags: "Technical Ceramics",
              keywords: "Technical Ceramics",
              description: "Technical Ceramics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d514d",
            },
            slug: "ceramic-foam",
            title: "Ceramic Foam",
            id: 8000213,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/ceramic-foam",
            active: true,
            meta: {
              tags: "Ceramic Foam",
              keywords: "Ceramic Foam",
              description: "Ceramic Foam",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d515b",
            },
            slug: "electronics-and-electrical-ceramics",
            title: "Electronics and Electrical Ceramics",
            id: 8000227,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/electronics-and-electrical-ceramics",
            active: true,
            meta: {
              tags: "Electronics and Electrical Ceramics",
              keywords: "Electronics and Electrical Ceramics",
              description: "Electronics and Electrical Ceramics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5171",
            },
            slug: "silica",
            title: "Silica",
            id: 8000249,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/silica",
            active: true,
            meta: {
              tags: "Silica",
              keywords: "Silica",
              description: "Silica",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5172",
                },
                slug: "silane",
                title: "Silane",
                id: 8000250,
                parent: "/chemicals-materials/ceramics/silica",
                path: "/chemicals-materials/ceramics/silica/silane",
                active: true,
                meta: {
                  tags: "Silane",
                  keywords: "Silane",
                  description: "Silane",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5173",
                },
                slug: "silica-sand",
                title: "Silica Sand",
                id: 8000251,
                parent: "/chemicals-materials/ceramics/silica",
                path: "/chemicals-materials/ceramics/silica/silica-sand",
                active: true,
                meta: {
                  tags: "Silica Sand",
                  keywords: "Silica Sand",
                  description: "Silica Sand",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5174",
                },
                slug: "specialty-silica",
                title: "Specialty Silica",
                id: 8000252,
                parent: "/chemicals-materials/ceramics/silica",
                path: "/chemicals-materials/ceramics/silica/specialty-silica",
                active: true,
                meta: {
                  tags: "Specialty Silica",
                  keywords: "Specialty Silica",
                  description: "Specialty Silica",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5176",
                    },
                    slug: "colloidal-silica",
                    title: "Colloidal Silica",
                    id: 8000254,
                    parent:
                      "/chemicals-materials/ceramics/silica/specialty-silica",
                    path: "/chemicals-materials/ceramics/silica/specialty-silica/colloidal-silica",
                    active: true,
                    meta: {
                      tags: "Colloidal Silica",
                      keywords: "Colloidal Silica",
                      description: "Colloidal Silica",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5177",
                    },
                    slug: "fumed-silica",
                    title: "Fumed Silica",
                    id: 8000255,
                    parent:
                      "/chemicals-materials/ceramics/silica/specialty-silica",
                    path: "/chemicals-materials/ceramics/silica/specialty-silica/fumed-silica",
                    active: true,
                    meta: {
                      tags: "Fumed Silica",
                      keywords: "Fumed Silica",
                      description: "Fumed Silica",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5178",
                    },
                    slug: "precipitated-silica",
                    title: "Precipitated Silica",
                    id: 8000256,
                    parent:
                      "/chemicals-materials/ceramics/silica/specialty-silica",
                    path: "/chemicals-materials/ceramics/silica/specialty-silica/precipitated-silica",
                    active: true,
                    meta: {
                      tags: "Precipitated Silica",
                      keywords: "Precipitated Silica",
                      description: "Precipitated Silica",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5179",
                    },
                    slug: "sodium-silicate",
                    title: "Sodium Silicate",
                    id: 8000257,
                    parent:
                      "/chemicals-materials/ceramics/silica/specialty-silica",
                    path: "/chemicals-materials/ceramics/silica/specialty-silica/sodium-silicate",
                    active: true,
                    meta: {
                      tags: "Sodium Silicate",
                      keywords: "Sodium Silicate",
                      description: "Sodium Silicate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5175",
                    },
                    slug: "active-calcium-silicate",
                    title: "Active Calcium Silicate",
                    id: 8000253,
                    parent:
                      "/chemicals-materials/ceramics/silica/specialty-silica",
                    path: "/chemicals-materials/ceramics/silica/specialty-silica/active-calcium-silicate",
                    active: true,
                    meta: {
                      tags: "Active Calcium Silicate",
                      keywords: "Active Calcium Silicate",
                      description: "Active Calcium Silicate",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d517c",
            },
            slug: "tungsten-carbide",
            title: "Tungsten Carbide",
            id: 8000260,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/tungsten-carbide",
            active: true,
            meta: {
              tags: "Tungsten Carbide",
              keywords: "Tungsten Carbide",
              description: "Tungsten Carbide",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5157",
            },
            slug: "ceramic-nanomaterials",
            title: "Ceramic NanoMaterials",
            id: 8000223,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/ceramic-nanomaterials",
            active: true,
            meta: {
              tags: "Ceramic NanoMaterials",
              keywords: "Ceramic NanoMaterials",
              description: "Ceramic NanoMaterials",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5158",
                },
                slug: "nanoceramic-powder",
                title: "Nanoceramic Powder",
                id: 8000224,
                parent: "/chemicals-materials/ceramics/ceramic-nanomaterials",
                path: "/chemicals-materials/ceramics/ceramic-nanomaterials/nanoceramic-powder",
                active: true,
                meta: {
                  tags: "Nanoceramic Powder",
                  keywords: "Nanoceramic Powder",
                  description: "Nanoceramic Powder",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d515a",
            },
            slug: "ceramic-textiles",
            title: "Ceramic Textiles",
            id: 8000226,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/ceramic-textiles",
            active: true,
            meta: {
              tags: "Ceramic Textiles",
              keywords: "Ceramic Textiles",
              description: "Ceramic Textiles",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d514c",
            },
            slug: "ceramic-fiber",
            title: "Ceramic Fiber",
            id: 8000212,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/ceramic-fiber",
            active: true,
            meta: {
              tags: "Ceramic Fiber",
              keywords: "Ceramic Fiber",
              description: "Ceramic Fiber",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d517b",
            },
            slug: "transparent-ceramics",
            title: "Transparent Ceramics",
            id: 8000259,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/transparent-ceramics",
            active: true,
            meta: {
              tags: "Transparent Ceramics",
              keywords: "Transparent Ceramics",
              description: "Transparent Ceramics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d514a",
            },
            slug: "bioceramics",
            title: "Bioceramics",
            id: 8000210,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/bioceramics",
            active: true,
            meta: {
              tags: "Bioceramics",
              keywords: "Bioceramics",
              description: "Bioceramics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d514e",
            },
            slug: "ceramic-membrane",
            title: "Ceramic Membrane",
            id: 8000214,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/ceramic-membrane",
            active: true,
            meta: {
              tags: "Ceramic Membrane",
              keywords: "Ceramic Membrane",
              description: "Ceramic Membrane",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d515c",
            },
            slug: "glass",
            title: "Glass",
            id: 8000228,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/glass",
            active: true,
            meta: {
              tags: "Glass",
              keywords: "Glass",
              description: "Glass",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d515e",
                },
                slug: "energy-efficient-glass",
                title: "Energy Efficient Glass",
                id: 8000230,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/energy-efficient-glass",
                active: true,
                meta: {
                  tags: "Energy Efficient Glass",
                  keywords: "Energy Efficient Glass",
                  description: "Energy Efficient Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5161",
                },
                slug: "flexible-glass",
                title: "Flexible Glass",
                id: 8000233,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/flexible-glass",
                active: true,
                meta: {
                  tags: "Flexible Glass",
                  keywords: "Flexible Glass",
                  description: "Flexible Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d516c",
                },
                slug: "specialty-glass",
                title: "Specialty Glass",
                id: 8000244,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/specialty-glass",
                active: true,
                meta: {
                  tags: "Specialty Glass",
                  keywords: "Specialty Glass",
                  description: "Specialty Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d516e",
                },
                slug: "ultra-thin-glass",
                title: "Ultra Thin Glass",
                id: 8000246,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/ultra-thin-glass",
                active: true,
                meta: {
                  tags: "Ultra Thin Glass",
                  keywords: "Ultra Thin Glass",
                  description: "Ultra Thin Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d516a",
                },
                slug: "solar-glass",
                title: "Solar Glass",
                id: 8000242,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/solar-glass",
                active: true,
                meta: {
                  tags: "Solar Glass",
                  keywords: "Solar Glass",
                  description: "Solar Glass",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d516b",
                    },
                    slug: "solar-photovoltaic-glass",
                    title: "Solar Photovoltaic Glass",
                    id: 8000243,
                    parent: "/chemicals-materials/ceramics/glass/solar-glass",
                    path: "/chemicals-materials/ceramics/glass/solar-glass/solar-photovoltaic-glass",
                    active: true,
                    meta: {
                      tags: "Solar Photovoltaic Glass",
                      keywords: "Solar Photovoltaic Glass",
                      description: "Solar Photovoltaic Glass",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d516d",
                },
                slug: "tempered-glass",
                title: "Tempered Glass",
                id: 8000245,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/tempered-glass",
                active: true,
                meta: {
                  tags: "Tempered Glass",
                  keywords: "Tempered Glass",
                  description: "Tempered Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5162",
                },
                slug: "float-glass",
                title: "Float Glass",
                id: 8000234,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/float-glass",
                active: true,
                meta: {
                  tags: "Float Glass",
                  keywords: "Float Glass",
                  description: "Float Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5164",
                },
                slug: "glass-substrate",
                title: "Glass Substrate",
                id: 8000236,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/glass-substrate",
                active: true,
                meta: {
                  tags: "Glass Substrate",
                  keywords: "Glass Substrate",
                  description: "Glass Substrate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5166",
                },
                slug: "laminated-glass",
                title: "Laminated Glass",
                id: 8000238,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/laminated-glass",
                active: true,
                meta: {
                  tags: "Laminated Glass",
                  keywords: "Laminated Glass",
                  description: "Laminated Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5168",
                },
                slug: "scratch-resistant-glass",
                title: "Scratch Resistant Glass",
                id: 8000240,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/scratch-resistant-glass",
                active: true,
                meta: {
                  tags: "Scratch Resistant Glass",
                  keywords: "Scratch Resistant Glass",
                  description: "Scratch Resistant Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5163",
                },
                slug: "foam-glass",
                title: "Foam Glass",
                id: 8000235,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/foam-glass",
                active: true,
                meta: {
                  tags: "Foam Glass",
                  keywords: "Foam Glass",
                  description: "Foam Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d515d",
                },
                slug: "bulletproof-glass",
                title: "Bulletproof Glass",
                id: 8000229,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/bulletproof-glass",
                active: true,
                meta: {
                  tags: "Bulletproof Glass",
                  keywords: "Bulletproof Glass",
                  description: "Bulletproof Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d515f",
                },
                slug: "fire-resistant-glass",
                title: "Fire Resistant Glass",
                id: 8000231,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/fire-resistant-glass",
                active: true,
                meta: {
                  tags: "Fire Resistant Glass",
                  keywords: "Fire Resistant Glass",
                  description: "Fire Resistant Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5160",
                },
                slug: "flat-glass",
                title: "Flat Glass",
                id: 8000232,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/flat-glass",
                active: true,
                meta: {
                  tags: "Flat Glass",
                  keywords: "Flat Glass",
                  description: "Flat Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5165",
                },
                slug: "impact-resistant-glass",
                title: "Impact Resistant Glass",
                id: 8000237,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/impact-resistant-glass",
                active: true,
                meta: {
                  tags: "Impact Resistant Glass",
                  keywords: "Impact Resistant Glass",
                  description: "Impact Resistant Glass",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5167",
                },
                slug: "perlite",
                title: "Perlite",
                id: 8000239,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/perlite",
                active: true,
                meta: {
                  tags: "Perlite",
                  keywords: "Perlite",
                  description: "Perlite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5169",
                },
                slug: "self-cleaning-glass",
                title: "Self Cleaning Glass",
                id: 8000241,
                parent: "/chemicals-materials/ceramics/glass",
                path: "/chemicals-materials/ceramics/glass/self-cleaning-glass",
                active: true,
                meta: {
                  tags: "Self Cleaning Glass",
                  keywords: "Self Cleaning Glass",
                  description: "Self Cleaning Glass",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5149",
            },
            slug: "advanced-structural-ceramics",
            title: "Advanced Structural Ceramics",
            id: 8000209,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/advanced-structural-ceramics",
            active: true,
            meta: {
              tags: "Advanced Structural Ceramics",
              keywords: "Advanced Structural Ceramics",
              description: "Advanced Structural Ceramics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5148",
            },
            slug: "advanced-ceramics",
            title: "Advanced Ceramics",
            id: 8000208,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/advanced-ceramics",
            active: true,
            meta: {
              tags: "Advanced Ceramics",
              keywords: "Advanced Ceramics",
              description: "Advanced Ceramics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d514b",
            },
            slug: "ceramic-binders",
            title: "Ceramic Binders",
            id: 8000211,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/ceramic-binders",
            active: true,
            meta: {
              tags: "Ceramic Binders",
              keywords: "Ceramic Binders",
              description: "Ceramic Binders",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d514f",
            },
            slug: "ceramic-minerals",
            title: "Ceramic Minerals",
            id: 8000215,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/ceramic-minerals",
            active: true,
            meta: {
              tags: "Ceramic Minerals",
              keywords: "Ceramic Minerals",
              description: "Ceramic Minerals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5152",
                },
                slug: "high-purity-quartz",
                title: "High Purity Quartz",
                id: 8000218,
                parent: "/chemicals-materials/ceramics/ceramic-minerals",
                path: "/chemicals-materials/ceramics/ceramic-minerals/high-purity-quartz",
                active: true,
                meta: {
                  tags: "High Purity Quartz",
                  keywords: "High Purity Quartz",
                  description: "High Purity Quartz",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5151",
                },
                slug: "fluorspar",
                title: "Fluorspar",
                id: 8000217,
                parent: "/chemicals-materials/ceramics/ceramic-minerals",
                path: "/chemicals-materials/ceramics/ceramic-minerals/fluorspar",
                active: true,
                meta: {
                  tags: "Fluorspar",
                  keywords: "Fluorspar",
                  description: "Fluorspar",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5153",
                },
                slug: "kaolin",
                title: "Kaolin",
                id: 8000219,
                parent: "/chemicals-materials/ceramics/ceramic-minerals",
                path: "/chemicals-materials/ceramics/ceramic-minerals/kaolin",
                active: true,
                meta: {
                  tags: "Kaolin",
                  keywords: "Kaolin",
                  description: "Kaolin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5156",
                },
                slug: "quartz",
                title: "Quartz",
                id: 8000222,
                parent: "/chemicals-materials/ceramics/ceramic-minerals",
                path: "/chemicals-materials/ceramics/ceramic-minerals/quartz",
                active: true,
                meta: {
                  tags: "Quartz",
                  keywords: "Quartz",
                  description: "Quartz",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5150",
                },
                slug: "barium-carbonate",
                title: "Barium Carbonate",
                id: 8000216,
                parent: "/chemicals-materials/ceramics/ceramic-minerals",
                path: "/chemicals-materials/ceramics/ceramic-minerals/barium-carbonate",
                active: true,
                meta: {
                  tags: "Barium Carbonate",
                  keywords: "Barium Carbonate",
                  description: "Barium Carbonate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5154",
                },
                slug: "pyrophyllite",
                title: "Pyrophyllite",
                id: 8000220,
                parent: "/chemicals-materials/ceramics/ceramic-minerals",
                path: "/chemicals-materials/ceramics/ceramic-minerals/pyrophyllite",
                active: true,
                meta: {
                  tags: "Pyrophyllite",
                  keywords: "Pyrophyllite",
                  description: "Pyrophyllite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5155",
                },
                slug: "pyrophyllite-powder",
                title: "Pyrophyllite Powder",
                id: 8000221,
                parent: "/chemicals-materials/ceramics/ceramic-minerals",
                path: "/chemicals-materials/ceramics/ceramic-minerals/pyrophyllite-powder",
                active: true,
                meta: {
                  tags: "Pyrophyllite Powder",
                  keywords: "Pyrophyllite Powder",
                  description: "Pyrophyllite Powder",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5159",
            },
            slug: "ceramic-substrate",
            title: "Ceramic Substrate",
            id: 8000225,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/ceramic-substrate",
            active: true,
            meta: {
              tags: "Ceramic Substrate",
              keywords: "Ceramic Substrate",
              description: "Ceramic Substrate",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5170",
            },
            slug: "refractories",
            title: "Refractories",
            id: 8000248,
            parent: "/chemicals-materials/ceramics",
            path: "/chemicals-materials/ceramics/refractories",
            active: true,
            meta: {
              tags: "Refractories",
              keywords: "Refractories",
              description: "Refractories",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d52ef",
        },
        slug: "electronic-materials",
        title: "Electronic Materials",
        id: 61636,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/electronic-materials",
        active: true,
        meta: {
          tags: "Electronic Materials",
          keywords: "Electronic Materials",
          description: "Electronic Materials",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f0",
            },
            slug: "battery-raw-materials",
            title: "Battery Raw Materials",
            id: 8000634,
            parent: "/chemicals-materials/electronic-materials",
            path: "/chemicals-materials/electronic-materials/battery-raw-materials",
            active: true,
            meta: {
              tags: "Battery Raw Materials",
              keywords: "Battery Raw Materials",
              description: "Battery Raw Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f2",
            },
            slug: "electronic-films",
            title: "Electronic Films",
            id: 8000636,
            parent: "/chemicals-materials/electronic-materials",
            path: "/chemicals-materials/electronic-materials/electronic-films",
            active: true,
            meta: {
              tags: "Electronic Films",
              keywords: "Electronic Films",
              description: "Electronic Films",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f1",
            },
            slug: "circuit-materials",
            title: "Circuit Materials",
            id: 8000635,
            parent: "/chemicals-materials/electronic-materials",
            path: "/chemicals-materials/electronic-materials/circuit-materials",
            active: true,
            meta: {
              tags: "Circuit Materials",
              keywords: "Circuit Materials",
              description: "Circuit Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d52f3",
            },
            slug: "led-materials",
            title: "LED Materials",
            id: 8000637,
            parent: "/chemicals-materials/electronic-materials",
            path: "/chemicals-materials/electronic-materials/led-materials",
            active: true,
            meta: {
              tags: "LED Materials",
              keywords: "LED Materials",
              description: "LED Materials",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d5318",
        },
        slug: "insulation",
        title: "Insulation",
        id: 61639,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/insulation",
        active: true,
        meta: {
          tags: "Insulation",
          keywords: "Insulation",
          description: "Insulation",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5319",
            },
            slug: "building-insulation",
            title: "Building Insulation",
            id: 8000676,
            parent: "/chemicals-materials/insulation",
            path: "/chemicals-materials/insulation/building-insulation",
            active: true,
            meta: {
              tags: "Building Insulation",
              keywords: "Building Insulation",
              description: "Building Insulation",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d531a",
                },
                slug: "building-insulation-materials",
                title: "Building Insulation Materials",
                id: 8000677,
                parent: "/chemicals-materials/insulation/building-insulation",
                path: "/chemicals-materials/insulation/building-insulation/building-insulation-materials",
                active: true,
                meta: {
                  tags: "Building Insulation Materials",
                  keywords: "Building Insulation Materials",
                  description: "Building Insulation Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d531b",
                },
                slug: "foam-insulation",
                title: "Foam Insulation",
                id: 8000678,
                parent: "/chemicals-materials/insulation/building-insulation",
                path: "/chemicals-materials/insulation/building-insulation/foam-insulation",
                active: true,
                meta: {
                  tags: "Foam Insulation",
                  keywords: "Foam Insulation",
                  description: "Foam Insulation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d531c",
                },
                slug: "insulation-systems",
                title: "Insulation Systems",
                id: 8000679,
                parent: "/chemicals-materials/insulation/building-insulation",
                path: "/chemicals-materials/insulation/building-insulation/insulation-systems",
                active: true,
                meta: {
                  tags: "Insulation Systems",
                  keywords: "Insulation Systems",
                  description: "Insulation Systems",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d531d",
                    },
                    slug: "acoustic-insulation",
                    title: "Acoustic Insulation",
                    id: 8000680,
                    parent:
                      "/chemicals-materials/insulation/building-insulation/insulation-systems",
                    path: "/chemicals-materials/insulation/building-insulation/insulation-systems/acoustic-insulation",
                    active: true,
                    meta: {
                      tags: "Acoustic Insulation",
                      keywords: "Acoustic Insulation",
                      description: "Acoustic Insulation",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d531e",
            },
            slug: "industrial-insulation",
            title: "Industrial Insulation",
            id: 8000681,
            parent: "/chemicals-materials/insulation",
            path: "/chemicals-materials/insulation/industrial-insulation",
            active: true,
            meta: {
              tags: "Industrial Insulation",
              keywords: "Industrial Insulation",
              description: "Industrial Insulation",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5322",
                },
                slug: "glass-insulation",
                title: "Glass Insulation",
                id: 8000685,
                parent: "/chemicals-materials/insulation/industrial-insulation",
                path: "/chemicals-materials/insulation/industrial-insulation/glass-insulation",
                active: true,
                meta: {
                  tags: "Glass Insulation",
                  keywords: "Glass Insulation",
                  description: "Glass Insulation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5320",
                },
                slug: "electrical-insulation",
                title: "Electrical Insulation",
                id: 8000683,
                parent: "/chemicals-materials/insulation/industrial-insulation",
                path: "/chemicals-materials/insulation/industrial-insulation/electrical-insulation",
                active: true,
                meta: {
                  tags: "Electrical Insulation",
                  keywords: "Electrical Insulation",
                  description: "Electrical Insulation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5321",
                },
                slug: "flexible-insulation",
                title: "Flexible Insulation",
                id: 8000684,
                parent: "/chemicals-materials/insulation/industrial-insulation",
                path: "/chemicals-materials/insulation/industrial-insulation/flexible-insulation",
                active: true,
                meta: {
                  tags: "Flexible Insulation",
                  keywords: "Flexible Insulation",
                  description: "Flexible Insulation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5323",
                },
                slug: "high-performance-insulation-materials",
                title: "High Performance Insulation Materials",
                id: 8000686,
                parent: "/chemicals-materials/insulation/industrial-insulation",
                path: "/chemicals-materials/insulation/industrial-insulation/high-performance-insulation-materials",
                active: true,
                meta: {
                  tags: "High Performance Insulation Materials",
                  keywords: "High Performance Insulation Materials",
                  description: "High Performance Insulation Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5325",
                },
                slug: "mineral-wool",
                title: "Mineral Wool",
                id: 8000688,
                parent: "/chemicals-materials/insulation/industrial-insulation",
                path: "/chemicals-materials/insulation/industrial-insulation/mineral-wool",
                active: true,
                meta: {
                  tags: "Mineral Wool",
                  keywords: "Mineral Wool",
                  description: "Mineral Wool",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5324",
                },
                slug: "high-temperature-insulation-materials",
                title: "High Temperature Insulation Materials",
                id: 8000687,
                parent: "/chemicals-materials/insulation/industrial-insulation",
                path: "/chemicals-materials/insulation/industrial-insulation/high-temperature-insulation-materials",
                active: true,
                meta: {
                  tags: "High Temperature Insulation Materials",
                  keywords: "High Temperature Insulation Materials",
                  description: "High Temperature Insulation Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d531f",
                },
                slug: "cold-insulation",
                title: "Cold Insulation",
                id: 8000682,
                parent: "/chemicals-materials/insulation/industrial-insulation",
                path: "/chemicals-materials/insulation/industrial-insulation/cold-insulation",
                active: true,
                meta: {
                  tags: "Cold Insulation",
                  keywords: "Cold Insulation",
                  description: "Cold Insulation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5326",
                },
                slug: "pipe-insulation",
                title: "Pipe Insulation",
                id: 8000689,
                parent: "/chemicals-materials/insulation/industrial-insulation",
                path: "/chemicals-materials/insulation/industrial-insulation/pipe-insulation",
                active: true,
                meta: {
                  tags: "Pipe Insulation",
                  keywords: "Pipe Insulation",
                  description: "Pipe Insulation",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5327",
                    },
                    slug: "subsea-thermal-insulation-materials",
                    title: "Subsea Thermal Insulation Materials",
                    id: 8000690,
                    parent:
                      "/chemicals-materials/insulation/industrial-insulation/pipe-insulation",
                    path: "/chemicals-materials/insulation/industrial-insulation/pipe-insulation/subsea-thermal-insulation-materials",
                    active: true,
                    meta: {
                      tags: "Subsea Thermal Insulation Materials",
                      keywords: "Subsea Thermal Insulation Materials",
                      description: "Subsea Thermal Insulation Materials",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5328",
                },
                slug: "thin-insulation",
                title: "Thin Insulation",
                id: 8000691,
                parent: "/chemicals-materials/insulation/industrial-insulation",
                path: "/chemicals-materials/insulation/industrial-insulation/thin-insulation",
                active: true,
                meta: {
                  tags: "Thin Insulation",
                  keywords: "Thin Insulation",
                  description: "Thin Insulation",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d5304",
        },
        slug: "gels",
        title: "Gels",
        id: 61637,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/gels",
        active: true,
        meta: {
          tags: "Gels",
          keywords: "Gels",
          description: "Gels",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5305",
            },
            slug: "aerogel",
            title: "Aerogel",
            id: 8000656,
            parent: "/chemicals-materials/gels",
            path: "/chemicals-materials/gels/aerogel",
            active: true,
            meta: {
              tags: "Aerogel",
              keywords: "Aerogel",
              description: "Aerogel",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5307",
            },
            slug: "hydrogel",
            title: "Hydrogel",
            id: 8000658,
            parent: "/chemicals-materials/gels",
            path: "/chemicals-materials/gels/hydrogel",
            active: true,
            meta: {
              tags: "Hydrogel",
              keywords: "Hydrogel",
              description: "Hydrogel",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5306",
            },
            slug: "hydrocolloids",
            title: "Hydrocolloids",
            id: 8000657,
            parent: "/chemicals-materials/gels",
            path: "/chemicals-materials/gels/hydrocolloids",
            active: true,
            meta: {
              tags: "Hydrocolloids",
              keywords: "Hydrocolloids",
              description: "Hydrocolloids",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d530a",
            },
            slug: "sol-gel-products",
            title: "Sol Gel Products",
            id: 8000661,
            parent: "/chemicals-materials/gels",
            path: "/chemicals-materials/gels/sol-gel-products",
            active: true,
            meta: {
              tags: "Sol Gel Products",
              keywords: "Sol Gel Products",
              description: "Sol Gel Products",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5308",
            },
            slug: "silica-gel",
            title: "Silica Gel",
            id: 8000659,
            parent: "/chemicals-materials/gels",
            path: "/chemicals-materials/gels/silica-gel",
            active: true,
            meta: {
              tags: "Silica Gel",
              keywords: "Silica Gel",
              description: "Silica Gel",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5309",
            },
            slug: "silicone-gel",
            title: "Silicone Gel",
            id: 8000660,
            parent: "/chemicals-materials/gels",
            path: "/chemicals-materials/gels/silicone-gel",
            active: true,
            meta: {
              tags: "Silicone Gel",
              keywords: "Silicone Gel",
              description: "Silicone Gel",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d535a",
        },
        slug: "metals-and-minerals",
        title: "Metals and Minerals",
        id: 61641,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/metals-and-minerals",
        active: true,
        meta: {
          tags: "Metals and Minerals",
          keywords: "Metals and Minerals",
          description: "Metals and Minerals",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d53b5",
            },
            slug: "minerals",
            title: "Minerals",
            id: 8000832,
            parent: "/chemicals-materials/metals-and-minerals",
            path: "/chemicals-materials/metals-and-minerals/minerals",
            active: true,
            meta: {
              tags: "Minerals",
              keywords: "Minerals",
              description: "Minerals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53b9",
                },
                slug: "barite",
                title: "Barite",
                id: 8000836,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/barite",
                active: true,
                meta: {
                  tags: "Barite",
                  keywords: "Barite",
                  description: "Barite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53c1",
                },
                slug: "diatomite",
                title: "Diatomite",
                id: 8000844,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/diatomite",
                active: true,
                meta: {
                  tags: "Diatomite",
                  keywords: "Diatomite",
                  description: "Diatomite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53b8",
                },
                slug: "ball-clay",
                title: "Ball Clay",
                id: 8000835,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/ball-clay",
                active: true,
                meta: {
                  tags: "Ball Clay",
                  keywords: "Ball Clay",
                  description: "Ball Clay",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53be",
                },
                slug: "conductive-materials",
                title: "Conductive Materials",
                id: 8000841,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/conductive-materials",
                active: true,
                meta: {
                  tags: "Conductive Materials",
                  keywords: "Conductive Materials",
                  description: "Conductive Materials",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53bf",
                    },
                    slug: "superconducting-materials",
                    title: "Superconducting Materials",
                    id: 8000842,
                    parent:
                      "/chemicals-materials/metals-and-minerals/minerals/conductive-materials",
                    path: "/chemicals-materials/metals-and-minerals/minerals/conductive-materials/superconducting-materials",
                    active: true,
                    meta: {
                      tags: "Superconducting Materials",
                      keywords: "Superconducting Materials",
                      description: "Superconducting Materials",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53c8",
                },
                slug: "magnetic-materials",
                title: "Magnetic Materials",
                id: 8000851,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/magnetic-materials",
                active: true,
                meta: {
                  tags: "Magnetic Materials",
                  keywords: "Magnetic Materials",
                  description: "Magnetic Materials",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53c9",
                    },
                    slug: "magnet-wire",
                    title: "Magnet Wire",
                    id: 8000852,
                    parent:
                      "/chemicals-materials/metals-and-minerals/minerals/magnetic-materials",
                    path: "/chemicals-materials/metals-and-minerals/minerals/magnetic-materials/magnet-wire",
                    active: true,
                    meta: {
                      tags: "Magnet Wire",
                      keywords: "Magnet Wire",
                      description: "Magnet Wire",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53ba",
                },
                slug: "bauxite",
                title: "Bauxite",
                id: 8000837,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/bauxite",
                active: true,
                meta: {
                  tags: "Bauxite",
                  keywords: "Bauxite",
                  description: "Bauxite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53bd",
                },
                slug: "calcium-carbonate",
                title: "Calcium Carbonate",
                id: 8000840,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/calcium-carbonate",
                active: true,
                meta: {
                  tags: "Calcium Carbonate",
                  keywords: "Calcium Carbonate",
                  description: "Calcium Carbonate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53bc",
                },
                slug: "calcite",
                title: "Calcite",
                id: 8000839,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/calcite",
                active: true,
                meta: {
                  tags: "Calcite",
                  keywords: "Calcite",
                  description: "Calcite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53c2",
                },
                slug: "dolomite",
                title: "Dolomite",
                id: 8000845,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/dolomite",
                active: true,
                meta: {
                  tags: "Dolomite",
                  keywords: "Dolomite",
                  description: "Dolomite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53ca",
                },
                slug: "permanent-magnet",
                title: "Permanent Magnet",
                id: 8000853,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/permanent-magnet",
                active: true,
                meta: {
                  tags: "Permanent Magnet",
                  keywords: "Permanent Magnet",
                  description: "Permanent Magnet",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53cb",
                },
                slug: "soft-magnetic-materials",
                title: "Soft Magnetic Materials",
                id: 8000854,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/soft-magnetic-materials",
                active: true,
                meta: {
                  tags: "Soft Magnetic Materials",
                  keywords: "Soft Magnetic Materials",
                  description: "Soft Magnetic Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53c0",
                },
                slug: "cryolite",
                title: "Cryolite",
                id: 8000843,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/cryolite",
                active: true,
                meta: {
                  tags: "Cryolite",
                  keywords: "Cryolite",
                  description: "Cryolite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53c3",
                },
                slug: "feldspathic",
                title: "Feldspathic",
                id: 8000846,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/feldspathic",
                active: true,
                meta: {
                  tags: "Feldspathic",
                  keywords: "Feldspathic",
                  description: "Feldspathic",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53cc",
                },
                slug: "strategic-mineral-materials",
                title: "Strategic Mineral Materials",
                id: 8000855,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/strategic-mineral-materials",
                active: true,
                meta: {
                  tags: "Strategic Mineral Materials",
                  keywords: "Strategic Mineral Materials",
                  description: "Strategic Mineral Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53cd",
                },
                slug: "talc",
                title: "Talc",
                id: 8000856,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/talc",
                active: true,
                meta: {
                  tags: "Talc",
                  keywords: "Talc",
                  description: "Talc",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53b6",
                },
                slug: "activated-bleaching-earth",
                title: "Activated Bleaching Earth",
                id: 8000833,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/activated-bleaching-earth",
                active: true,
                meta: {
                  tags: "Activated Bleaching Earth",
                  keywords: "Activated Bleaching Earth",
                  description: "Activated Bleaching Earth",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53b7",
                },
                slug: "andalusite",
                title: "Andalusite",
                id: 8000834,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/andalusite",
                active: true,
                meta: {
                  tags: "Andalusite",
                  keywords: "Andalusite",
                  description: "Andalusite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53bb",
                },
                slug: "bleaching-clays",
                title: "Bleaching Clays",
                id: 8000838,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/bleaching-clays",
                active: true,
                meta: {
                  tags: "Bleaching Clays",
                  keywords: "Bleaching Clays",
                  description: "Bleaching Clays",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53c4",
                },
                slug: "hydroxyapatite",
                title: "Hydroxyapatite",
                id: 8000847,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/hydroxyapatite",
                active: true,
                meta: {
                  tags: "Hydroxyapatite",
                  keywords: "Hydroxyapatite",
                  description: "Hydroxyapatite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53c5",
                },
                slug: "industrial-minerals",
                title: "Industrial Minerals",
                id: 8000848,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/industrial-minerals",
                active: true,
                meta: {
                  tags: "Industrial Minerals",
                  keywords: "Industrial Minerals",
                  description: "Industrial Minerals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53c6",
                    },
                    slug: "sulfur",
                    title: "Sulfur",
                    id: 8000849,
                    parent:
                      "/chemicals-materials/metals-and-minerals/minerals/industrial-minerals",
                    path: "/chemicals-materials/metals-and-minerals/minerals/industrial-minerals/sulfur",
                    active: true,
                    meta: {
                      tags: "Sulfur",
                      keywords: "Sulfur",
                      description: "Sulfur",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53c7",
                },
                slug: "magnesite",
                title: "Magnesite",
                id: 8000850,
                parent: "/chemicals-materials/metals-and-minerals/minerals",
                path: "/chemicals-materials/metals-and-minerals/minerals/magnesite",
                active: true,
                meta: {
                  tags: "Magnesite",
                  keywords: "Magnesite",
                  description: "Magnesite",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d535b",
            },
            slug: "metals",
            title: "Metals",
            id: 8000742,
            parent: "/chemicals-materials/metals-and-minerals",
            path: "/chemicals-materials/metals-and-minerals/metals",
            active: true,
            meta: {
              tags: "Metals",
              keywords: "Metals",
              description: "Metals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5375",
                },
                slug: "ferrous-alloys",
                title: "Ferrous Alloys",
                id: 8000768,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys",
                active: true,
                meta: {
                  tags: "Ferrous Alloys",
                  keywords: "Ferrous Alloys",
                  description: "Ferrous Alloys",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5376",
                    },
                    slug: "steel",
                    title: "Steel",
                    id: 8000769,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys",
                    path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                    active: true,
                    meta: {
                      tags: "Steel",
                      keywords: "Steel",
                      description: "Steel",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5379",
                        },
                        slug: "duplex-stainless-steel",
                        title: "Duplex Stainless Steel",
                        id: 8000772,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/duplex-stainless-steel",
                        active: true,
                        meta: {
                          tags: "Duplex Stainless Steel",
                          keywords: "Duplex Stainless Steel",
                          description: "Duplex Stainless Steel",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5377",
                        },
                        slug: "coated-steel",
                        title: "Coated Steel",
                        id: 8000770,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/coated-steel",
                        active: true,
                        meta: {
                          tags: "Coated Steel",
                          keywords: "Coated Steel",
                          description: "Coated Steel",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d537f",
                        },
                        slug: "stainless-steel",
                        title: "Stainless Steel",
                        id: 8000778,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/stainless-steel",
                        active: true,
                        meta: {
                          tags: "Stainless Steel",
                          keywords: "Stainless Steel",
                          description: "Stainless Steel",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d5380",
                            },
                            slug: "stainless-steel-container",
                            title: "Stainless Steel Container",
                            id: 8000779,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/stainless-steel",
                            path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/stainless-steel/stainless-steel-container",
                            active: true,
                            meta: {
                              tags: "Stainless Steel Container",
                              keywords: "Stainless Steel Container",
                              description: "Stainless Steel Container",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5378",
                        },
                        slug: "crude-steel",
                        title: "Crude Steel",
                        id: 8000771,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/crude-steel",
                        active: true,
                        meta: {
                          tags: "Crude Steel",
                          keywords: "Crude Steel",
                          description: "Crude Steel",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d537b",
                        },
                        slug: "heat-treated-steel",
                        title: "Heat Treated Steel",
                        id: 8000774,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/heat-treated-steel",
                        active: true,
                        meta: {
                          tags: "Heat Treated Steel",
                          keywords: "Heat Treated Steel",
                          description: "Heat Treated Steel",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d537c",
                            },
                            slug: "heat-treated-steel-plates",
                            title: "Heat Treated Steel Plates",
                            id: 8000775,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/heat-treated-steel",
                            path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/heat-treated-steel/heat-treated-steel-plates",
                            active: true,
                            meta: {
                              tags: "Heat Treated Steel Plates",
                              keywords: "Heat Treated Steel Plates",
                              description: "Heat Treated Steel Plates",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5383",
                        },
                        slug: "ultra-high-strength-steel",
                        title: "Ultra High Strength Steel",
                        id: 8000782,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/ultra-high-strength-steel",
                        active: true,
                        meta: {
                          tags: "Ultra High Strength Steel",
                          keywords: "Ultra High Strength Steel",
                          description: "Ultra High Strength Steel",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d537a",
                        },
                        slug: "finished-steel-products",
                        title: "Finished Steel Products",
                        id: 8000773,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/finished-steel-products",
                        active: true,
                        meta: {
                          tags: "Finished Steel Products",
                          keywords: "Finished Steel Products",
                          description: "Finished Steel Products",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5381",
                        },
                        slug: "steel-rebar",
                        title: "Steel Rebar",
                        id: 8000780,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/steel-rebar",
                        active: true,
                        meta: {
                          tags: "Steel Rebar",
                          keywords: "Steel Rebar",
                          description: "Steel Rebar",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5382",
                        },
                        slug: "steel-tire-cord",
                        title: "Steel Tire Cord",
                        id: 8000781,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/steel-tire-cord",
                        active: true,
                        meta: {
                          tags: "Steel Tire Cord",
                          keywords: "Steel Tire Cord",
                          description: "Steel Tire Cord",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d537d",
                        },
                        slug: "high-speed",
                        title: "High Speed",
                        id: 8000776,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/high-speed",
                        active: true,
                        meta: {
                          tags: "High Speed",
                          keywords: "High Speed",
                          description: "High Speed",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d537e",
                        },
                        slug: "high-strength-steel",
                        title: "High Strength Steel",
                        id: 8000777,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel",
                        path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/steel/high-strength-steel",
                        active: true,
                        meta: {
                          tags: "High Strength Steel",
                          keywords: "High Strength Steel",
                          description: "High Strength Steel",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5384",
                    },
                    slug: "tire-bead-wire",
                    title: "Tire Bead Wire",
                    id: 8000783,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys",
                    path: "/chemicals-materials/metals-and-minerals/metals/ferrous-alloys/tire-bead-wire",
                    active: true,
                    meta: {
                      tags: "Tire Bead Wire",
                      keywords: "Tire Bead Wire",
                      description: "Tire Bead Wire",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53af",
                },
                slug: "precious-metal",
                title: "Precious Metal",
                id: 8000826,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/precious-metal",
                active: true,
                meta: {
                  tags: "Precious Metal",
                  keywords: "Precious Metal",
                  description: "Precious Metal",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53b0",
                    },
                    slug: "diamond",
                    title: "Diamond",
                    id: 8000827,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/precious-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/precious-metal/diamond",
                    active: true,
                    meta: {
                      tags: "Diamond",
                      keywords: "Diamond",
                      description: "Diamond",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53b1",
                        },
                        slug: "synthetic-diamond",
                        title: "Synthetic Diamond",
                        id: 8000828,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/precious-metal/diamond",
                        path: "/chemicals-materials/metals-and-minerals/metals/precious-metal/diamond/synthetic-diamond",
                        active: true,
                        meta: {
                          tags: "Synthetic Diamond",
                          keywords: "Synthetic Diamond",
                          description: "Synthetic Diamond",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53b2",
                    },
                    slug: "platinum",
                    title: "Platinum",
                    id: 8000829,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/precious-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/precious-metal/platinum",
                    active: true,
                    meta: {
                      tags: "Platinum",
                      keywords: "Platinum",
                      description: "Platinum",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5385",
                },
                slug: "metal-powder",
                title: "Metal Powder",
                id: 8000784,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/metal-powder",
                active: true,
                meta: {
                  tags: "Metal Powder",
                  keywords: "Metal Powder",
                  description: "Metal Powder",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5386",
                    },
                    slug: "polishing-powder",
                    title: "Polishing Powder",
                    id: 8000785,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/metal-powder",
                    path: "/chemicals-materials/metals-and-minerals/metals/metal-powder/polishing-powder",
                    active: true,
                    meta: {
                      tags: "Polishing Powder",
                      keywords: "Polishing Powder",
                      description: "Polishing Powder",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5387",
                },
                slug: "metallic-foam",
                title: "Metallic Foam",
                id: 8000786,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/metallic-foam",
                active: true,
                meta: {
                  tags: "Metallic Foam",
                  keywords: "Metallic Foam",
                  description: "Metallic Foam",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d538e",
                },
                slug: "minor-metal",
                title: "Minor Metal",
                id: 8000793,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                active: true,
                meta: {
                  tags: "Minor Metal",
                  keywords: "Minor Metal",
                  description: "Minor Metal",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5397",
                    },
                    slug: "manganese",
                    title: "Manganese",
                    id: 8000802,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/manganese",
                    active: true,
                    meta: {
                      tags: "Manganese",
                      keywords: "Manganese",
                      description: "Manganese",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d539c",
                    },
                    slug: "rubidium",
                    title: "Rubidium",
                    id: 8000807,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/rubidium",
                    active: true,
                    meta: {
                      tags: "Rubidium",
                      keywords: "Rubidium",
                      description: "Rubidium",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53a3",
                    },
                    slug: "zirconium",
                    title: "Zirconium",
                    id: 8000814,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/zirconium",
                    active: true,
                    meta: {
                      tags: "Zirconium",
                      keywords: "Zirconium",
                      description: "Zirconium",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5399",
                    },
                    slug: "molybdenum",
                    title: "Molybdenum",
                    id: 8000804,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/molybdenum",
                    active: true,
                    meta: {
                      tags: "Molybdenum",
                      keywords: "Molybdenum",
                      description: "Molybdenum",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d539d",
                    },
                    slug: "silicon",
                    title: "Silicon",
                    id: 8000808,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon",
                    active: true,
                    meta: {
                      tags: "Silicon",
                      keywords: "Silicon",
                      description: "Silicon",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53a2",
                        },
                        slug: "silicon-metal",
                        title: "Silicon Metal",
                        id: 8000813,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon",
                        path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon/silicon-metal",
                        active: true,
                        meta: {
                          tags: "Silicon Metal",
                          keywords: "Silicon Metal",
                          description: "Silicon Metal",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d539e",
                        },
                        slug: "ferrosilicon",
                        title: "FerroSilicon",
                        id: 8000809,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon",
                        path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon/ferrosilicon",
                        active: true,
                        meta: {
                          tags: "FerroSilicon",
                          keywords: "FerroSilicon",
                          description: "FerroSilicon",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d539f",
                        },
                        slug: "polysilicon",
                        title: "Polysilicon",
                        id: 8000810,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon",
                        path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon/polysilicon",
                        active: true,
                        meta: {
                          tags: "Polysilicon",
                          keywords: "Polysilicon",
                          description: "Polysilicon",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53a0",
                        },
                        slug: "silicon-carbide",
                        title: "Silicon Carbide",
                        id: 8000811,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon",
                        path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon/silicon-carbide",
                        active: true,
                        meta: {
                          tags: "Silicon Carbide",
                          keywords: "Silicon Carbide",
                          description: "Silicon Carbide",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d53a1",
                            },
                            slug: "silicon-carbide-fiber",
                            title: "Silicon Carbide Fiber",
                            id: 8000812,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon/silicon-carbide",
                            path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/silicon/silicon-carbide/silicon-carbide-fiber",
                            active: true,
                            meta: {
                              tags: "Silicon Carbide Fiber",
                              keywords: "Silicon Carbide Fiber",
                              description: "Silicon Carbide Fiber",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5390",
                    },
                    slug: "hafnium",
                    title: "Hafnium",
                    id: 8000795,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/hafnium",
                    active: true,
                    meta: {
                      tags: "Hafnium",
                      keywords: "Hafnium",
                      description: "Hafnium",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5391",
                    },
                    slug: "lithium",
                    title: "Lithium",
                    id: 8000796,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/lithium",
                    active: true,
                    meta: {
                      tags: "Lithium",
                      keywords: "Lithium",
                      description: "Lithium",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5392",
                        },
                        slug: "lithium-compounds",
                        title: "Lithium Compounds",
                        id: 8000797,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/minor-metal/lithium",
                        path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/lithium/lithium-compounds",
                        active: true,
                        meta: {
                          tags: "Lithium Compounds",
                          keywords: "Lithium Compounds",
                          description: "Lithium Compounds",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d5393",
                            },
                            slug: "lithium-carbonate",
                            title: "Lithium Carbonate",
                            id: 8000798,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/minor-metal/lithium/lithium-compounds",
                            path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/lithium/lithium-compounds/lithium-carbonate",
                            active: true,
                            meta: {
                              tags: "Lithium Carbonate",
                              keywords: "Lithium Carbonate",
                              description: "Lithium Carbonate",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d5394",
                            },
                            slug: "lithium-hydroxide",
                            title: "Lithium Hydroxide",
                            id: 8000799,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/minor-metal/lithium/lithium-compounds",
                            path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/lithium/lithium-compounds/lithium-hydroxide",
                            active: true,
                            meta: {
                              tags: "Lithium Hydroxide",
                              keywords: "Lithium Hydroxide",
                              description: "Lithium Hydroxide",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d539a",
                    },
                    slug: "niobium",
                    title: "Niobium",
                    id: 8000805,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/niobium",
                    active: true,
                    meta: {
                      tags: "Niobium",
                      keywords: "Niobium",
                      description: "Niobium",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d539b",
                    },
                    slug: "rhenium",
                    title: "Rhenium",
                    id: 8000806,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/rhenium",
                    active: true,
                    meta: {
                      tags: "Rhenium",
                      keywords: "Rhenium",
                      description: "Rhenium",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d538f",
                    },
                    slug: "chromium",
                    title: "Chromium",
                    id: 8000794,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/chromium",
                    active: true,
                    meta: {
                      tags: "Chromium",
                      keywords: "Chromium",
                      description: "Chromium",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5398",
                    },
                    slug: "mercury",
                    title: "Mercury",
                    id: 8000803,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/mercury",
                    active: true,
                    meta: {
                      tags: "Mercury",
                      keywords: "Mercury",
                      description: "Mercury",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5395",
                    },
                    slug: "magnesium",
                    title: "Magnesium",
                    id: 8000800,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/minor-metal",
                    path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/magnesium",
                    active: true,
                    meta: {
                      tags: "Magnesium",
                      keywords: "Magnesium",
                      description: "Magnesium",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5396",
                        },
                        slug: "magnesium-alloys",
                        title: "Magnesium Alloys",
                        id: 8000801,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/minor-metal/magnesium",
                        path: "/chemicals-materials/metals-and-minerals/metals/minor-metal/magnesium/magnesium-alloys",
                        active: true,
                        meta: {
                          tags: "Magnesium Alloys",
                          keywords: "Magnesium Alloys",
                          description: "Magnesium Alloys",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d535c",
                },
                slug: "base-metals",
                title: "Base Metals",
                id: 8000743,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/base-metals",
                active: true,
                meta: {
                  tags: "Base Metals",
                  keywords: "Base Metals",
                  description: "Base Metals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d536d",
                    },
                    slug: "lead",
                    title: "Lead",
                    id: 8000760,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/base-metals",
                    path: "/chemicals-materials/metals-and-minerals/metals/base-metals/lead",
                    active: true,
                    meta: {
                      tags: "Lead",
                      keywords: "Lead",
                      description: "Lead",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d535d",
                    },
                    slug: "aluminum",
                    title: "Aluminum",
                    id: 8000744,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/base-metals",
                    path: "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum",
                    active: true,
                    meta: {
                      tags: "Aluminum",
                      keywords: "Aluminum",
                      description: "Aluminum",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d535e",
                        },
                        slug: "alumina",
                        title: "Alumina",
                        id: 8000745,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/alumina",
                        active: true,
                        meta: {
                          tags: "Alumina",
                          keywords: "Alumina",
                          description: "Alumina",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d535f",
                            },
                            slug: "activated-alumina",
                            title: "Activated Alumina",
                            id: 8000746,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/alumina",
                            path: "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/alumina/activated-alumina",
                            active: true,
                            meta: {
                              tags: "Activated Alumina",
                              keywords: "Activated Alumina",
                              description: "Activated Alumina",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d5360",
                            },
                            slug: "high-purity-alumina",
                            title: "High Purity Alumina",
                            id: 8000747,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/alumina",
                            path: "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/alumina/high-purity-alumina",
                            active: true,
                            meta: {
                              tags: "High Purity Alumina",
                              keywords: "High Purity Alumina",
                              description: "High Purity Alumina",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5361",
                        },
                        slug: "aluminum-compounds",
                        title: "Aluminum Compounds",
                        id: 8000748,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/aluminum-compounds",
                        active: true,
                        meta: {
                          tags: "Aluminum Compounds",
                          keywords: "Aluminum Compounds",
                          description: "Aluminum Compounds",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d5362",
                            },
                            slug: "aluminum-hydroxide",
                            title: "Aluminum Hydroxide",
                            id: 8000749,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/aluminum-compounds",
                            path: "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/aluminum-compounds/aluminum-hydroxide",
                            active: true,
                            meta: {
                              tags: "Aluminum Hydroxide",
                              keywords: "Aluminum Hydroxide",
                              description: "Aluminum Hydroxide",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d5364",
                            },
                            slug: "anhydrous-aluminum-chloride",
                            title: "Anhydrous Aluminum Chloride",
                            id: 8000751,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/aluminum-compounds",
                            path: "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/aluminum-compounds/anhydrous-aluminum-chloride",
                            active: true,
                            meta: {
                              tags: "Anhydrous Aluminum Chloride",
                              keywords: "Anhydrous Aluminum Chloride",
                              description: "Anhydrous Aluminum Chloride",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9d69a9d316ab7c2d5363",
                            },
                            slug: "aluminum-oxide",
                            title: "Aluminum Oxide",
                            id: 8000750,
                            parent:
                              "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/aluminum-compounds",
                            path: "/chemicals-materials/metals-and-minerals/metals/base-metals/aluminum/aluminum-compounds/aluminum-oxide",
                            active: true,
                            meta: {
                              tags: "Aluminum Oxide",
                              keywords: "Aluminum Oxide",
                              description: "Aluminum Oxide",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d536e",
                    },
                    slug: "nickel",
                    title: "Nickel",
                    id: 8000761,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/base-metals",
                    path: "/chemicals-materials/metals-and-minerals/metals/base-metals/nickel",
                    active: true,
                    meta: {
                      tags: "Nickel",
                      keywords: "Nickel",
                      description: "Nickel",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5371",
                    },
                    slug: "zinc-compounds",
                    title: "Zinc Compounds",
                    id: 8000764,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/base-metals",
                    path: "/chemicals-materials/metals-and-minerals/metals/base-metals/zinc-compounds",
                    active: true,
                    meta: {
                      tags: "Zinc Compounds",
                      keywords: "Zinc Compounds",
                      description: "Zinc Compounds",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5373",
                        },
                        slug: "zinc-citrate",
                        title: "Zinc Citrate",
                        id: 8000766,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/zinc-compounds",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/zinc-compounds/zinc-citrate",
                        active: true,
                        meta: {
                          tags: "Zinc Citrate",
                          keywords: "Zinc Citrate",
                          description: "Zinc Citrate",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5374",
                        },
                        slug: "zinc-oxide",
                        title: "Zinc Oxide",
                        id: 8000767,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/zinc-compounds",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/zinc-compounds/zinc-oxide",
                        active: true,
                        meta: {
                          tags: "Zinc Oxide",
                          keywords: "Zinc Oxide",
                          description: "Zinc Oxide",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5372",
                        },
                        slug: "zinc-chloride",
                        title: "Zinc Chloride",
                        id: 8000765,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/zinc-compounds",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/zinc-compounds/zinc-chloride",
                        active: true,
                        meta: {
                          tags: "Zinc Chloride",
                          keywords: "Zinc Chloride",
                          description: "Zinc Chloride",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5365",
                    },
                    slug: "battery-materials",
                    title: "Battery Materials",
                    id: 8000752,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/base-metals",
                    path: "/chemicals-materials/metals-and-minerals/metals/base-metals/battery-materials",
                    active: true,
                    meta: {
                      tags: "Battery Materials",
                      keywords: "Battery Materials",
                      description: "Battery Materials",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5366",
                        },
                        slug: "battery-anode-materials",
                        title: "Battery Anode Materials",
                        id: 8000753,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/battery-materials",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/battery-materials/battery-anode-materials",
                        active: true,
                        meta: {
                          tags: "Battery Anode Materials",
                          keywords: "Battery Anode Materials",
                          description: "Battery Anode Materials",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5367",
                    },
                    slug: "copper",
                    title: "Copper",
                    id: 8000754,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/base-metals",
                    path: "/chemicals-materials/metals-and-minerals/metals/base-metals/copper",
                    active: true,
                    meta: {
                      tags: "Copper",
                      keywords: "Copper",
                      description: "Copper",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5369",
                        },
                        slug: "high-end-copper-foil",
                        title: "High-end Copper Foil",
                        id: 8000756,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/copper",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/copper/high-end-copper-foil",
                        active: true,
                        meta: {
                          tags: "High-end Copper Foil",
                          keywords: "High-end Copper Foil",
                          description: "High-end Copper Foil",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d536a",
                        },
                        slug: "oxygen-free-copper",
                        title: "Oxygen Free Copper",
                        id: 8000757,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/copper",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/copper/oxygen-free-copper",
                        active: true,
                        meta: {
                          tags: "Oxygen Free Copper",
                          keywords: "Oxygen Free Copper",
                          description: "Oxygen Free Copper",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5368",
                        },
                        slug: "copper-stranded-wire",
                        title: "Copper Stranded Wire",
                        id: 8000755,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/copper",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/copper/copper-stranded-wire",
                        active: true,
                        meta: {
                          tags: "Copper Stranded Wire",
                          keywords: "Copper Stranded Wire",
                          description: "Copper Stranded Wire",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d536f",
                    },
                    slug: "tin",
                    title: "Tin",
                    id: 8000762,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/base-metals",
                    path: "/chemicals-materials/metals-and-minerals/metals/base-metals/tin",
                    active: true,
                    meta: {
                      tags: "Tin",
                      keywords: "Tin",
                      description: "Tin",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5370",
                    },
                    slug: "zinc-chemicals",
                    title: "Zinc Chemicals",
                    id: 8000763,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/base-metals",
                    path: "/chemicals-materials/metals-and-minerals/metals/base-metals/zinc-chemicals",
                    active: true,
                    meta: {
                      tags: "Zinc Chemicals",
                      keywords: "Zinc Chemicals",
                      description: "Zinc Chemicals",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d536b",
                    },
                    slug: "iron",
                    title: "Iron",
                    id: 8000758,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/base-metals",
                    path: "/chemicals-materials/metals-and-minerals/metals/base-metals/iron",
                    active: true,
                    meta: {
                      tags: "Iron",
                      keywords: "Iron",
                      description: "Iron",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d536c",
                        },
                        slug: "iron-powder",
                        title: "Iron Powder",
                        id: 8000759,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/base-metals/iron",
                        path: "/chemicals-materials/metals-and-minerals/metals/base-metals/iron/iron-powder",
                        active: true,
                        meta: {
                          tags: "Iron Powder",
                          keywords: "Iron Powder",
                          description: "Iron Powder",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5388",
                },
                slug: "metallurgy",
                title: "Metallurgy",
                id: 8000787,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/metallurgy",
                active: true,
                meta: {
                  tags: "Metallurgy",
                  keywords: "Metallurgy",
                  description: "Metallurgy",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d538d",
                    },
                    slug: "powder-metallurgy",
                    title: "Powder Metallurgy",
                    id: 8000792,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/metallurgy",
                    path: "/chemicals-materials/metals-and-minerals/metals/metallurgy/powder-metallurgy",
                    active: true,
                    meta: {
                      tags: "Powder Metallurgy",
                      keywords: "Powder Metallurgy",
                      description: "Powder Metallurgy",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5389",
                    },
                    slug: "cathode-materials",
                    title: "Cathode Materials",
                    id: 8000788,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/metallurgy",
                    path: "/chemicals-materials/metals-and-minerals/metals/metallurgy/cathode-materials",
                    active: true,
                    meta: {
                      tags: "Cathode Materials",
                      keywords: "Cathode Materials",
                      description: "Cathode Materials",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d538a",
                    },
                    slug: "friction-materials",
                    title: "Friction Materials",
                    id: 8000789,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/metallurgy",
                    path: "/chemicals-materials/metals-and-minerals/metals/metallurgy/friction-materials",
                    active: true,
                    meta: {
                      tags: "Friction Materials",
                      keywords: "Friction Materials",
                      description: "Friction Materials",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d538b",
                    },
                    slug: "metal-finishing-chemicals",
                    title: "Metal Finishing Chemicals",
                    id: 8000790,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/metallurgy",
                    path: "/chemicals-materials/metals-and-minerals/metals/metallurgy/metal-finishing-chemicals",
                    active: true,
                    meta: {
                      tags: "Metal Finishing Chemicals",
                      keywords: "Metal Finishing Chemicals",
                      description: "Metal Finishing Chemicals",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d538c",
                    },
                    slug: "metallurgical-grade-fluorospar",
                    title: "Metallurgical Grade Fluorospar",
                    id: 8000791,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/metallurgy",
                    path: "/chemicals-materials/metals-and-minerals/metals/metallurgy/metallurgical-grade-fluorospar",
                    active: true,
                    meta: {
                      tags: "Metallurgical Grade Fluorospar",
                      keywords: "Metallurgical Grade Fluorospar",
                      description: "Metallurgical Grade Fluorospar",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53a9",
                },
                slug: "non-ferrous-alloys",
                title: "Non ferrous Alloys",
                id: 8000820,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys",
                active: true,
                meta: {
                  tags: "Non ferrous Alloys",
                  keywords: "Non ferrous Alloys",
                  description: "Non ferrous Alloys",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53ad",
                    },
                    slug: "nickel-alloys",
                    title: "Nickel Alloys",
                    id: 8000824,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys",
                    path: "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys/nickel-alloys",
                    active: true,
                    meta: {
                      tags: "Nickel Alloys",
                      keywords: "Nickel Alloys",
                      description: "Nickel Alloys",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53aa",
                    },
                    slug: "braze-alloys",
                    title: "Braze Alloys",
                    id: 8000821,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys",
                    path: "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys/braze-alloys",
                    active: true,
                    meta: {
                      tags: "Braze Alloys",
                      keywords: "Braze Alloys",
                      description: "Braze Alloys",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53ac",
                    },
                    slug: "high-performance-alloys",
                    title: "High Performance Alloys",
                    id: 8000823,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys",
                    path: "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys/high-performance-alloys",
                    active: true,
                    meta: {
                      tags: "High Performance Alloys",
                      keywords: "High Performance Alloys",
                      description: "High Performance Alloys",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53ab",
                    },
                    slug: "bronze",
                    title: "Bronze",
                    id: 8000822,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys",
                    path: "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys/bronze",
                    active: true,
                    meta: {
                      tags: "Bronze",
                      keywords: "Bronze",
                      description: "Bronze",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53ae",
                    },
                    slug: "titanium-alloys",
                    title: "Titanium Alloys",
                    id: 8000825,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys",
                    path: "/chemicals-materials/metals-and-minerals/metals/non-ferrous-alloys/titanium-alloys",
                    active: true,
                    meta: {
                      tags: "Titanium Alloys",
                      keywords: "Titanium Alloys",
                      description: "Titanium Alloys",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53a4",
                },
                slug: "nanometals",
                title: "NanoMetals",
                id: 8000815,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/nanometals",
                active: true,
                meta: {
                  tags: "NanoMetals",
                  keywords: "NanoMetals",
                  description: "NanoMetals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53a5",
                    },
                    slug: "oxide",
                    title: "Oxide",
                    id: 8000816,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/nanometals",
                    path: "/chemicals-materials/metals-and-minerals/metals/nanometals/oxide",
                    active: true,
                    meta: {
                      tags: "Oxide",
                      keywords: "Oxide",
                      description: "Oxide",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53a6",
                        },
                        slug: "magnesium-oxide-nanopowder",
                        title: "Magnesium Oxide Nanopowder",
                        id: 8000817,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/nanometals/oxide",
                        path: "/chemicals-materials/metals-and-minerals/metals/nanometals/oxide/magnesium-oxide-nanopowder",
                        active: true,
                        meta: {
                          tags: "Magnesium Oxide Nanopowder",
                          keywords: "Magnesium Oxide Nanopowder",
                          description: "Magnesium Oxide Nanopowder",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d53a7",
                        },
                        slug: "nano-metal-oxide",
                        title: "Nano Metal Oxide",
                        id: 8000818,
                        parent:
                          "/chemicals-materials/metals-and-minerals/metals/nanometals/oxide",
                        path: "/chemicals-materials/metals-and-minerals/metals/nanometals/oxide/nano-metal-oxide",
                        active: true,
                        meta: {
                          tags: "Nano Metal Oxide",
                          keywords: "Nano Metal Oxide",
                          description: "Nano Metal Oxide",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53a8",
                },
                slug: "niobium-pentoxide",
                title: "Niobium Pentoxide",
                id: 8000819,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/niobium-pentoxide",
                active: true,
                meta: {
                  tags: "Niobium Pentoxide",
                  keywords: "Niobium Pentoxide",
                  description: "Niobium Pentoxide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d53b3",
                },
                slug: "rare-earths",
                title: "Rare Earths",
                id: 8000830,
                parent: "/chemicals-materials/metals-and-minerals/metals",
                path: "/chemicals-materials/metals-and-minerals/metals/rare-earths",
                active: true,
                meta: {
                  tags: "Rare Earths",
                  keywords: "Rare Earths",
                  description: "Rare Earths",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d53b4",
                    },
                    slug: "yttrium",
                    title: "Yttrium",
                    id: 8000831,
                    parent:
                      "/chemicals-materials/metals-and-minerals/metals/rare-earths",
                    path: "/chemicals-materials/metals-and-minerals/metals/rare-earths/yttrium",
                    active: true,
                    meta: {
                      tags: "Yttrium",
                      keywords: "Yttrium",
                      description: "Yttrium",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d5329",
        },
        slug: "lubricants-and-fuel-additives",
        title: "Lubricants and Fuel Additives",
        id: 61640,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/lubricants-and-fuel-additives",
        active: true,
        meta: {
          tags: "Lubricants and Fuel Additives",
          keywords: "Lubricants and Fuel Additives",
          description: "Lubricants and Fuel Additives",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d532a",
            },
            slug: "fuel-additives",
            title: "Fuel Additives",
            id: 8000693,
            parent: "/chemicals-materials/lubricants-and-fuel-additives",
            path: "/chemicals-materials/lubricants-and-fuel-additives/fuel-additives",
            active: true,
            meta: {
              tags: "Fuel Additives",
              keywords: "Fuel Additives",
              description: "Fuel Additives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d532b",
                },
                slug: "biofuel-enzymes",
                title: "Biofuel Enzymes",
                id: 8000694,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/fuel-additives",
                path: "/chemicals-materials/lubricants-and-fuel-additives/fuel-additives/biofuel-enzymes",
                active: true,
                meta: {
                  tags: "Biofuel Enzymes",
                  keywords: "Biofuel Enzymes",
                  description: "Biofuel Enzymes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d532c",
                },
                slug: "cold-flow-improver",
                title: "Cold Flow Improver",
                id: 8000695,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/fuel-additives",
                path: "/chemicals-materials/lubricants-and-fuel-additives/fuel-additives/cold-flow-improver",
                active: true,
                meta: {
                  tags: "Cold Flow Improver",
                  keywords: "Cold Flow Improver",
                  description: "Cold Flow Improver",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d532d",
                },
                slug: "friction-modifiers",
                title: "Friction Modifiers",
                id: 8000696,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/fuel-additives",
                path: "/chemicals-materials/lubricants-and-fuel-additives/fuel-additives/friction-modifiers",
                active: true,
                meta: {
                  tags: "Friction Modifiers",
                  keywords: "Friction Modifiers",
                  description: "Friction Modifiers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d532e",
                },
                slug: "octane-improver",
                title: "Octane Improver",
                id: 8000697,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/fuel-additives",
                path: "/chemicals-materials/lubricants-and-fuel-additives/fuel-additives/octane-improver",
                active: true,
                meta: {
                  tags: "Octane Improver",
                  keywords: "Octane Improver",
                  description: "Octane Improver",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d532f",
            },
            slug: "lubricants",
            title: "Lubricants",
            id: 8000698,
            parent: "/chemicals-materials/lubricants-and-fuel-additives",
            path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
            active: true,
            meta: {
              tags: "Lubricants",
              keywords: "Lubricants",
              description: "Lubricants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d533e",
                },
                slug: "heat-transfer-fluids",
                title: "Heat Transfer Fluids",
                id: 8000713,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/heat-transfer-fluids",
                active: true,
                meta: {
                  tags: "Heat Transfer Fluids",
                  keywords: "Heat Transfer Fluids",
                  description: "Heat Transfer Fluids",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d533f",
                    },
                    slug: "thermic-fluids",
                    title: "Thermic Fluids",
                    id: 8000714,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/heat-transfer-fluids",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/heat-transfer-fluids/thermic-fluids",
                    active: true,
                    meta: {
                      tags: "Thermic Fluids",
                      keywords: "Thermic Fluids",
                      description: "Thermic Fluids",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5350",
                },
                slug: "oil-and-gas-lubricants",
                title: "Oil and Gas Lubricants",
                id: 8000731,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/oil-and-gas-lubricants",
                active: true,
                meta: {
                  tags: "Oil and Gas Lubricants",
                  keywords: "Oil and Gas Lubricants",
                  description: "Oil and Gas Lubricants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5359",
                },
                slug: "transmission-fluids",
                title: "Transmission Fluids",
                id: 8000740,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/transmission-fluids",
                active: true,
                meta: {
                  tags: "Transmission Fluids",
                  keywords: "Transmission Fluids",
                  description: "Transmission Fluids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5351",
                },
                slug: "refrigeration-lubricants",
                title: "Refrigeration Lubricants",
                id: 8000732,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/refrigeration-lubricants",
                active: true,
                meta: {
                  tags: "Refrigeration Lubricants",
                  keywords: "Refrigeration Lubricants",
                  description: "Refrigeration Lubricants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5337",
                },
                slug: "gear-oil",
                title: "Gear Oil",
                id: 8000706,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/gear-oil",
                active: true,
                meta: {
                  tags: "Gear Oil",
                  keywords: "Gear Oil",
                  description: "Gear Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5341",
                },
                slug: "industrial-lubricants",
                title: "Industrial Lubricants",
                id: 8000716,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants",
                active: true,
                meta: {
                  tags: "Industrial Lubricants",
                  keywords: "Industrial Lubricants",
                  description: "Industrial Lubricants",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5345",
                    },
                    slug: "heavy-equipment-lubricants",
                    title: "Heavy Equipment Lubricants",
                    id: 8000720,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/heavy-equipment-lubricants",
                    active: true,
                    meta: {
                      tags: "Heavy Equipment Lubricants",
                      keywords: "Heavy Equipment Lubricants",
                      description: "Heavy Equipment Lubricants",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5346",
                        },
                        slug: "agriculture-lubricants",
                        title: "Agriculture Lubricants",
                        id: 8000721,
                        parent:
                          "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/heavy-equipment-lubricants",
                        path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/heavy-equipment-lubricants/agriculture-lubricants",
                        active: true,
                        meta: {
                          tags: "Agriculture Lubricants",
                          keywords: "Agriculture Lubricants",
                          description: "Agriculture Lubricants",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5347",
                        },
                        slug: "mining-lubricants",
                        title: "Mining Lubricants",
                        id: 8000722,
                        parent:
                          "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/heavy-equipment-lubricants",
                        path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/heavy-equipment-lubricants/mining-lubricants",
                        active: true,
                        meta: {
                          tags: "Mining Lubricants",
                          keywords: "Mining Lubricants",
                          description: "Mining Lubricants",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5342",
                    },
                    slug: "anti-static-oil",
                    title: "Anti Static Oil",
                    id: 8000717,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/anti-static-oil",
                    active: true,
                    meta: {
                      tags: "Anti Static Oil",
                      keywords: "Anti Static Oil",
                      description: "Anti Static Oil",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5343",
                    },
                    slug: "compressor-oil",
                    title: "Compressor Oil",
                    id: 8000718,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/compressor-oil",
                    active: true,
                    meta: {
                      tags: "Compressor Oil",
                      keywords: "Compressor Oil",
                      description: "Compressor Oil",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d534a",
                    },
                    slug: "process-oil",
                    title: "Process Oil",
                    id: 8000725,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/process-oil",
                    active: true,
                    meta: {
                      tags: "Process Oil",
                      keywords: "Process Oil",
                      description: "Process Oil",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5349",
                    },
                    slug: "metal-working-fluids",
                    title: "Metal Working Fluids",
                    id: 8000724,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/metal-working-fluids",
                    active: true,
                    meta: {
                      tags: "Metal Working Fluids",
                      keywords: "Metal Working Fluids",
                      description: "Metal Working Fluids",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5348",
                    },
                    slug: "marine-lubricants",
                    title: "Marine Lubricants",
                    id: 8000723,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/marine-lubricants",
                    active: true,
                    meta: {
                      tags: "Marine Lubricants",
                      keywords: "Marine Lubricants",
                      description: "Marine Lubricants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d534b",
                    },
                    slug: "transformer-oil",
                    title: "Transformer Oil",
                    id: 8000726,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/transformer-oil",
                    active: true,
                    meta: {
                      tags: "Transformer Oil",
                      keywords: "Transformer Oil",
                      description: "Transformer Oil",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5344",
                    },
                    slug: "general-industrial-oil",
                    title: "General Industrial Oil",
                    id: 8000719,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/industrial-lubricants/general-industrial-oil",
                    active: true,
                    meta: {
                      tags: "General Industrial Oil",
                      keywords: "General Industrial Oil",
                      description: "General Industrial Oil",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5338",
                },
                slug: "grease",
                title: "Grease",
                id: 8000707,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease",
                active: true,
                meta: {
                  tags: "Grease",
                  keywords: "Grease",
                  description: "Grease",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d533a",
                    },
                    slug: "specialty-greases",
                    title: "Specialty Greases",
                    id: 8000709,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease/specialty-greases",
                    active: true,
                    meta: {
                      tags: "Specialty Greases",
                      keywords: "Specialty Greases",
                      description: "Specialty Greases",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d533c",
                        },
                        slug: "thermally-conductive-grease",
                        title: "Thermally Conductive Grease",
                        id: 8000711,
                        parent:
                          "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease/specialty-greases",
                        path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease/specialty-greases/thermally-conductive-grease",
                        active: true,
                        meta: {
                          tags: "Thermally Conductive Grease",
                          keywords: "Thermally Conductive Grease",
                          description: "Thermally Conductive Grease",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d533d",
                        },
                        slug: "vacuum-grease",
                        title: "Vacuum Grease",
                        id: 8000712,
                        parent:
                          "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease/specialty-greases",
                        path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease/specialty-greases/vacuum-grease",
                        active: true,
                        meta: {
                          tags: "Vacuum Grease",
                          keywords: "Vacuum Grease",
                          description: "Vacuum Grease",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d533b",
                        },
                        slug: "high-temperature-grease",
                        title: "High Temperature Grease",
                        id: 8000710,
                        parent:
                          "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease/specialty-greases",
                        path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease/specialty-greases/high-temperature-grease",
                        active: true,
                        meta: {
                          tags: "High Temperature Grease",
                          keywords: "High Temperature Grease",
                          description: "High Temperature Grease",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5339",
                    },
                    slug: "marine-grease",
                    title: "Marine Grease",
                    id: 8000708,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/grease/marine-grease",
                    active: true,
                    meta: {
                      tags: "Marine Grease",
                      keywords: "Marine Grease",
                      description: "Marine Grease",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5340",
                },
                slug: "hydraulic-fluids",
                title: "Hydraulic Fluids",
                id: 8000715,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/hydraulic-fluids",
                active: true,
                meta: {
                  tags: "Hydraulic Fluids",
                  keywords: "Hydraulic Fluids",
                  description: "Hydraulic Fluids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5358",
                },
                slug: "synthetic-lubricants",
                title: "Synthetic Lubricants",
                id: 8000739,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/synthetic-lubricants",
                active: true,
                meta: {
                  tags: "Synthetic Lubricants",
                  keywords: "Synthetic Lubricants",
                  description: "Synthetic Lubricants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d534c",
                },
                slug: "lubricant-additives",
                title: "Lubricant Additives",
                id: 8000727,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/lubricant-additives",
                active: true,
                meta: {
                  tags: "Lubricant Additives",
                  keywords: "Lubricant Additives",
                  description: "Lubricant Additives",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d534e",
                    },
                    slug: "base-oil",
                    title: "Base Oil",
                    id: 8000729,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/lubricant-additives",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/lubricant-additives/base-oil",
                    active: true,
                    meta: {
                      tags: "Base Oil",
                      keywords: "Base Oil",
                      description: "Base Oil",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d534d",
                    },
                    slug: "1-decene",
                    title: "1-Decene",
                    id: 8000728,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/lubricant-additives",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/lubricant-additives/1-decene",
                    active: true,
                    meta: {
                      tags: "1-Decene",
                      keywords: "1-Decene",
                      description: "1-Decene",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d534f",
                },
                slug: "molybdenum-disulfide-mos2",
                title: "Molybdenum Disulfide (MoS2)",
                id: 8000730,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/molybdenum-disulfide-mos2",
                active: true,
                meta: {
                  tags: "Molybdenum Disulfide (MoS2)",
                  keywords: "Molybdenum Disulfide (MoS2)",
                  description: "Molybdenum Disulfide (MoS2)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5352",
                },
                slug: "rubber-process-oil",
                title: "Rubber Process Oil",
                id: 8000733,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/rubber-process-oil",
                active: true,
                meta: {
                  tags: "Rubber Process Oil",
                  keywords: "Rubber Process Oil",
                  description: "Rubber Process Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5353",
                },
                slug: "specialty-lubricants",
                title: "Specialty Lubricants",
                id: 8000734,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/specialty-lubricants",
                active: true,
                meta: {
                  tags: "Specialty Lubricants",
                  keywords: "Specialty Lubricants",
                  description: "Specialty Lubricants",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5354",
                    },
                    slug: "bio-lubricants",
                    title: "Bio Lubricants",
                    id: 8000735,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/specialty-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/specialty-lubricants/bio-lubricants",
                    active: true,
                    meta: {
                      tags: "Bio Lubricants",
                      keywords: "Bio Lubricants",
                      description: "Bio Lubricants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5355",
                    },
                    slug: "food-grade-lubricants",
                    title: "Food Grade Lubricants",
                    id: 8000736,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/specialty-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/specialty-lubricants/food-grade-lubricants",
                    active: true,
                    meta: {
                      tags: "Food Grade Lubricants",
                      keywords: "Food Grade Lubricants",
                      description: "Food Grade Lubricants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5357",
                    },
                    slug: "white-oil",
                    title: "White Oil",
                    id: 8000738,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/specialty-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/specialty-lubricants/white-oil",
                    active: true,
                    meta: {
                      tags: "White Oil",
                      keywords: "White Oil",
                      description: "White Oil",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5356",
                    },
                    slug: "solid/dry-lubricants",
                    title: "Solid/Dry Lubricants",
                    id: 8000737,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/specialty-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/specialty-lubricants/solid/dry-lubricants",
                    active: true,
                    meta: {
                      tags: "Solid/Dry Lubricants",
                      keywords: "Solid/Dry Lubricants",
                      description: "Solid/Dry Lubricants",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5330",
                },
                slug: "automotive-lubricants",
                title: "Automotive Lubricants",
                id: 8000699,
                parent:
                  "/chemicals-materials/lubricants-and-fuel-additives/lubricants",
                path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants",
                active: true,
                meta: {
                  tags: "Automotive Lubricants",
                  keywords: "Automotive Lubricants",
                  description: "Automotive Lubricants",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5332",
                    },
                    slug: "commercial-vehicle-lubricants",
                    title: "Commercial Vehicle Lubricants",
                    id: 8000701,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants/commercial-vehicle-lubricants",
                    active: true,
                    meta: {
                      tags: "Commercial Vehicle Lubricants",
                      keywords: "Commercial Vehicle Lubricants",
                      description: "Commercial Vehicle Lubricants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5335",
                    },
                    slug: "power-steering-fluids",
                    title: "Power Steering Fluids",
                    id: 8000704,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants/power-steering-fluids",
                    active: true,
                    meta: {
                      tags: "Power Steering Fluids",
                      keywords: "Power Steering Fluids",
                      description: "Power Steering Fluids",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5333",
                    },
                    slug: "engine-oil",
                    title: "Engine Oil",
                    id: 8000702,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants/engine-oil",
                    active: true,
                    meta: {
                      tags: "Engine Oil",
                      keywords: "Engine Oil",
                      description: "Engine Oil",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5334",
                    },
                    slug: "passenger-vehicles-lubricants",
                    title: "Passenger Vehicles Lubricants",
                    id: 8000703,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants/passenger-vehicles-lubricants",
                    active: true,
                    meta: {
                      tags: "Passenger Vehicles Lubricants",
                      keywords: "Passenger Vehicles Lubricants",
                      description: "Passenger Vehicles Lubricants",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5331",
                    },
                    slug: "brake-fluids",
                    title: "Brake Fluids",
                    id: 8000700,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants/brake-fluids",
                    active: true,
                    meta: {
                      tags: "Brake Fluids",
                      keywords: "Brake Fluids",
                      description: "Brake Fluids",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5336",
                    },
                    slug: "two-wheeler-lubricants",
                    title: "Two Wheeler lubricants",
                    id: 8000705,
                    parent:
                      "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants",
                    path: "/chemicals-materials/lubricants-and-fuel-additives/lubricants/automotive-lubricants/two-wheeler-lubricants",
                    active: true,
                    meta: {
                      tags: "Two Wheeler lubricants",
                      keywords: "Two Wheeler lubricants",
                      description: "Two Wheeler lubricants",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d530b",
        },
        slug: "industrial-gases",
        title: "Industrial Gases",
        id: 61638,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/industrial-gases",
        active: true,
        meta: {
          tags: "Industrial Gases",
          keywords: "Industrial Gases",
          description: "Industrial Gases",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5311",
            },
            slug: "noble-gases",
            title: "Noble Gases",
            id: 8000668,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/noble-gases",
            active: true,
            meta: {
              tags: "Noble Gases",
              keywords: "Noble Gases",
              description: "Noble Gases",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5312",
                },
                slug: "helium",
                title: "Helium",
                id: 8000669,
                parent: "/chemicals-materials/industrial-gases/noble-gases",
                path: "/chemicals-materials/industrial-gases/noble-gases/helium",
                active: true,
                meta: {
                  tags: "Helium",
                  keywords: "Helium",
                  description: "Helium",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5310",
            },
            slug: "liquid-nitrogen",
            title: "Liquid Nitrogen",
            id: 8000667,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/liquid-nitrogen",
            active: true,
            meta: {
              tags: "Liquid Nitrogen",
              keywords: "Liquid Nitrogen",
              description: "Liquid Nitrogen",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5313",
            },
            slug: "oxygen",
            title: "Oxygen",
            id: 8000670,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/oxygen",
            active: true,
            meta: {
              tags: "Oxygen",
              keywords: "Oxygen",
              description: "Oxygen",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5314",
            },
            slug: "propane",
            title: "Propane",
            id: 8000671,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/propane",
            active: true,
            meta: {
              tags: "Propane",
              keywords: "Propane",
              description: "Propane",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5315",
            },
            slug: "pyrolysis-gases",
            title: "Pyrolysis Gases",
            id: 8000672,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/pyrolysis-gases",
            active: true,
            meta: {
              tags: "Pyrolysis Gases",
              keywords: "Pyrolysis Gases",
              description: "Pyrolysis Gases",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d530e",
            },
            slug: "hydrogen",
            title: "Hydrogen",
            id: 8000665,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/hydrogen",
            active: true,
            meta: {
              tags: "Hydrogen",
              keywords: "Hydrogen",
              description: "Hydrogen",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d530f",
                },
                slug: "liquid-hydrogen",
                title: "Liquid Hydrogen",
                id: 8000666,
                parent: "/chemicals-materials/industrial-gases/hydrogen",
                path: "/chemicals-materials/industrial-gases/hydrogen/liquid-hydrogen",
                active: true,
                meta: {
                  tags: "Liquid Hydrogen",
                  keywords: "Liquid Hydrogen",
                  description: "Liquid Hydrogen",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5317",
            },
            slug: "syngas",
            title: "Syngas",
            id: 8000674,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/syngas",
            active: true,
            meta: {
              tags: "Syngas",
              keywords: "Syngas",
              description: "Syngas",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5316",
            },
            slug: "specialty-gases",
            title: "Specialty Gases",
            id: 8000673,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/specialty-gases",
            active: true,
            meta: {
              tags: "Specialty Gases",
              keywords: "Specialty Gases",
              description: "Specialty Gases",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d530c",
            },
            slug: "chloromethane",
            title: "Chloromethane",
            id: 8000663,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/chloromethane",
            active: true,
            meta: {
              tags: "Chloromethane",
              keywords: "Chloromethane",
              description: "Chloromethane",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d530d",
            },
            slug: "high-purity-gases",
            title: "High Purity Gases",
            id: 8000664,
            parent: "/chemicals-materials/industrial-gases",
            path: "/chemicals-materials/industrial-gases/high-purity-gases",
            active: true,
            meta: {
              tags: "High Purity Gases",
              keywords: "High Purity Gases",
              description: "High Purity Gases",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d55f2",
        },
        slug: "technical-textiles",
        title: "Technical Textiles",
        id: 61644,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/technical-textiles",
        active: true,
        meta: {
          tags: "Technical Textiles",
          keywords: "Technical Textiles",
          description: "Technical Textiles",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55f3",
            },
            slug: "coated-fabric",
            title: "Coated Fabric",
            id: 8001408,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/coated-fabric",
            active: true,
            meta: {
              tags: "Coated Fabric",
              keywords: "Coated Fabric",
              description: "Coated Fabric",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5600",
            },
            slug: "specialty-textiles",
            title: "Specialty Textiles",
            id: 8001421,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/specialty-textiles",
            active: true,
            meta: {
              tags: "Specialty Textiles",
              keywords: "Specialty Textiles",
              description: "Specialty Textiles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5601",
                },
                slug: "antimicrobial-textiles",
                title: "Antimicrobial Textiles",
                id: 8001422,
                parent:
                  "/chemicals-materials/technical-textiles/specialty-textiles",
                path: "/chemicals-materials/technical-textiles/specialty-textiles/antimicrobial-textiles",
                active: true,
                meta: {
                  tags: "Antimicrobial Textiles",
                  keywords: "Antimicrobial Textiles",
                  description: "Antimicrobial Textiles",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5602",
                },
                slug: "fire-resistant-fabric",
                title: "Fire Resistant Fabric",
                id: 8001423,
                parent:
                  "/chemicals-materials/technical-textiles/specialty-textiles",
                path: "/chemicals-materials/technical-textiles/specialty-textiles/fire-resistant-fabric",
                active: true,
                meta: {
                  tags: "Fire Resistant Fabric",
                  keywords: "Fire Resistant Fabric",
                  description: "Fire Resistant Fabric",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55fb",
            },
            slug: "nylon-tire-cord-fabric",
            title: "Nylon Tire Cord Fabric",
            id: 8001416,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/nylon-tire-cord-fabric",
            active: true,
            meta: {
              tags: "Nylon Tire Cord Fabric",
              keywords: "Nylon Tire Cord Fabric",
              description: "Nylon Tire Cord Fabric",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55f6",
            },
            slug: "healthcare-fabrics",
            title: "Healthcare Fabrics",
            id: 8001411,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/healthcare-fabrics",
            active: true,
            meta: {
              tags: "Healthcare Fabrics",
              keywords: "Healthcare Fabrics",
              description: "Healthcare Fabrics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55f8",
            },
            slug: "industrial-fabric",
            title: "Industrial Fabric",
            id: 8001413,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/industrial-fabric",
            active: true,
            meta: {
              tags: "Industrial Fabric",
              keywords: "Industrial Fabric",
              description: "Industrial Fabric",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55f5",
            },
            slug: "elastomer-coated-fabric",
            title: "Elastomer Coated Fabric",
            id: 8001410,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/elastomer-coated-fabric",
            active: true,
            meta: {
              tags: "Elastomer Coated Fabric",
              keywords: "Elastomer Coated Fabric",
              description: "Elastomer Coated Fabric",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55fd",
            },
            slug: "polyester-tire-cord-fabric",
            title: "Polyester Tire Cord Fabric",
            id: 8001418,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/polyester-tire-cord-fabric",
            active: true,
            meta: {
              tags: "Polyester Tire Cord Fabric",
              keywords: "Polyester Tire Cord Fabric",
              description: "Polyester Tire Cord Fabric",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5603",
            },
            slug: "tire-cord-fabric",
            title: "Tire Cord Fabric",
            id: 8001424,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/tire-cord-fabric",
            active: true,
            meta: {
              tags: "Tire Cord Fabric",
              keywords: "Tire Cord Fabric",
              description: "Tire Cord Fabric",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5604",
            },
            slug: "waterproof-breathable-textiles",
            title: "Waterproof Breathable Textiles",
            id: 8001425,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/waterproof-breathable-textiles",
            active: true,
            meta: {
              tags: "Waterproof Breathable Textiles",
              keywords: "Waterproof Breathable Textiles",
              description: "Waterproof Breathable Textiles",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55f4",
            },
            slug: "conductive-textiles",
            title: "Conductive Textiles",
            id: 8001409,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/conductive-textiles",
            active: true,
            meta: {
              tags: "Conductive Textiles",
              keywords: "Conductive Textiles",
              description: "Conductive Textiles",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55f7",
            },
            slug: "hybrid-fabric",
            title: "Hybrid Fabric",
            id: 8001412,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/hybrid-fabric",
            active: true,
            meta: {
              tags: "Hybrid Fabric",
              keywords: "Hybrid Fabric",
              description: "Hybrid Fabric",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55f9",
            },
            slug: "monofilament",
            title: "Monofilament",
            id: 8001414,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/monofilament",
            active: true,
            meta: {
              tags: "Monofilament",
              keywords: "Monofilament",
              description: "Monofilament",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55fa",
            },
            slug: "non-woven-fabric",
            title: "Non woven Fabric",
            id: 8001415,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/non-woven-fabric",
            active: true,
            meta: {
              tags: "Non woven Fabric",
              keywords: "Non woven Fabric",
              description: "Non woven Fabric",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55fc",
            },
            slug: "polyester-staple-fiber",
            title: "Polyester Staple Fiber",
            id: 8001417,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/polyester-staple-fiber",
            active: true,
            meta: {
              tags: "Polyester Staple Fiber",
              keywords: "Polyester Staple Fiber",
              description: "Polyester Staple Fiber",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55ff",
            },
            slug: "spandex",
            title: "Spandex",
            id: 8001420,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/spandex",
            active: true,
            meta: {
              tags: "Spandex",
              keywords: "Spandex",
              description: "Spandex",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55fe",
            },
            slug: "polymer-coated-fabric",
            title: "Polymer Coated Fabric",
            id: 8001419,
            parent: "/chemicals-materials/technical-textiles",
            path: "/chemicals-materials/technical-textiles/polymer-coated-fabric",
            active: true,
            meta: {
              tags: "Polymer Coated Fabric",
              keywords: "Polymer Coated Fabric",
              description: "Polymer Coated Fabric",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d555f",
        },
        slug: "specialty-carbon",
        title: "Specialty Carbon",
        id: 61642,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/specialty-carbon",
        active: true,
        meta: {
          tags: "Specialty Carbon",
          keywords: "Specialty Carbon",
          description: "Specialty Carbon",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5561",
            },
            slug: "advanced-carbon-materials",
            title: "Advanced Carbon Materials",
            id: 8001262,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/advanced-carbon-materials",
            active: true,
            meta: {
              tags: "Advanced Carbon Materials",
              keywords: "Advanced Carbon Materials",
              description: "Advanced Carbon Materials",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5563",
            },
            slug: "calcined-anthracite",
            title: "Calcined Anthracite",
            id: 8001264,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/calcined-anthracite",
            active: true,
            meta: {
              tags: "Calcined Anthracite",
              keywords: "Calcined Anthracite",
              description: "Calcined Anthracite",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5560",
            },
            slug: "activated-carbon",
            title: "Activated Carbon",
            id: 8001261,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/activated-carbon",
            active: true,
            meta: {
              tags: "Activated Carbon",
              keywords: "Activated Carbon",
              description: "Activated Carbon",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5562",
            },
            slug: "biochar",
            title: "Biochar",
            id: 8001263,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/biochar",
            active: true,
            meta: {
              tags: "Biochar",
              keywords: "Biochar",
              description: "Biochar",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5564",
            },
            slug: "carbon-black",
            title: "Carbon Black",
            id: 8001265,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/carbon-black",
            active: true,
            meta: {
              tags: "Carbon Black",
              keywords: "Carbon Black",
              description: "Carbon Black",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5567",
                },
                slug: "specialty-carbon-black",
                title: "Specialty Carbon Black",
                id: 8001268,
                parent: "/chemicals-materials/specialty-carbon/carbon-black",
                path: "/chemicals-materials/specialty-carbon/carbon-black/specialty-carbon-black",
                active: true,
                meta: {
                  tags: "Specialty Carbon Black",
                  keywords: "Specialty Carbon Black",
                  description: "Specialty Carbon Black",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5566",
                },
                slug: "rubber-carbon-black",
                title: "Rubber Carbon Black",
                id: 8001267,
                parent: "/chemicals-materials/specialty-carbon/carbon-black",
                path: "/chemicals-materials/specialty-carbon/carbon-black/rubber-carbon-black",
                active: true,
                meta: {
                  tags: "Rubber Carbon Black",
                  keywords: "Rubber Carbon Black",
                  description: "Rubber Carbon Black",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5565",
                },
                slug: "recovered-carbon-black",
                title: "Recovered Carbon Black",
                id: 8001266,
                parent: "/chemicals-materials/specialty-carbon/carbon-black",
                path: "/chemicals-materials/specialty-carbon/carbon-black/recovered-carbon-black",
                active: true,
                meta: {
                  tags: "Recovered Carbon Black",
                  keywords: "Recovered Carbon Black",
                  description: "Recovered Carbon Black",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5569",
            },
            slug: "charcoal",
            title: "Charcoal",
            id: 8001270,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/charcoal",
            active: true,
            meta: {
              tags: "Charcoal",
              keywords: "Charcoal",
              description: "Charcoal",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d556e",
            },
            slug: "graphene",
            title: "Graphene",
            id: 8001275,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/graphene",
            active: true,
            meta: {
              tags: "Graphene",
              keywords: "Graphene",
              description: "Graphene",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d556f",
            },
            slug: "graphite",
            title: "Graphite",
            id: 8001276,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/graphite",
            active: true,
            meta: {
              tags: "Graphite",
              keywords: "Graphite",
              description: "Graphite",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5570",
                },
                slug: "carbon-felt-and-grpahite-felt",
                title: "Carbon Felt and Grpahite Felt",
                id: 8001277,
                parent: "/chemicals-materials/specialty-carbon/graphite",
                path: "/chemicals-materials/specialty-carbon/graphite/carbon-felt-and-grpahite-felt",
                active: true,
                meta: {
                  tags: "Carbon Felt and Grpahite Felt",
                  keywords: "Carbon Felt and Grpahite Felt",
                  description: "Carbon Felt and Grpahite Felt",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5575",
                },
                slug: "special-graphite",
                title: "Special Graphite",
                id: 8001282,
                parent: "/chemicals-materials/specialty-carbon/graphite",
                path: "/chemicals-materials/specialty-carbon/graphite/special-graphite",
                active: true,
                meta: {
                  tags: "Special Graphite",
                  keywords: "Special Graphite",
                  description: "Special Graphite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5571",
                },
                slug: "expandable-graphite",
                title: "Expandable Graphite",
                id: 8001278,
                parent: "/chemicals-materials/specialty-carbon/graphite",
                path: "/chemicals-materials/specialty-carbon/graphite/expandable-graphite",
                active: true,
                meta: {
                  tags: "Expandable Graphite",
                  keywords: "Expandable Graphite",
                  description: "Expandable Graphite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5574",
                },
                slug: "graphite-felt",
                title: "Graphite Felt",
                id: 8001281,
                parent: "/chemicals-materials/specialty-carbon/graphite",
                path: "/chemicals-materials/specialty-carbon/graphite/graphite-felt",
                active: true,
                meta: {
                  tags: "Graphite Felt",
                  keywords: "Graphite Felt",
                  description: "Graphite Felt",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5576",
                },
                slug: "synthetic-graphite",
                title: "Synthetic Graphite",
                id: 8001283,
                parent: "/chemicals-materials/specialty-carbon/graphite",
                path: "/chemicals-materials/specialty-carbon/graphite/synthetic-graphite",
                active: true,
                meta: {
                  tags: "Synthetic Graphite",
                  keywords: "Synthetic Graphite",
                  description: "Synthetic Graphite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5572",
                },
                slug: "graphite-electrode",
                title: "Graphite Electrode",
                id: 8001279,
                parent: "/chemicals-materials/specialty-carbon/graphite",
                path: "/chemicals-materials/specialty-carbon/graphite/graphite-electrode",
                active: true,
                meta: {
                  tags: "Graphite Electrode",
                  keywords: "Graphite Electrode",
                  description: "Graphite Electrode",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5573",
                    },
                    slug: "uhp-graphite-electrode",
                    title: "UHP Graphite Electrode",
                    id: 8001280,
                    parent:
                      "/chemicals-materials/specialty-carbon/graphite/graphite-electrode",
                    path: "/chemicals-materials/specialty-carbon/graphite/graphite-electrode/uhp-graphite-electrode",
                    active: true,
                    meta: {
                      tags: "UHP Graphite Electrode",
                      keywords: "UHP Graphite Electrode",
                      description: "UHP Graphite Electrode",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5577",
            },
            slug: "needle-coke",
            title: "Needle Coke",
            id: 8001284,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/needle-coke",
            active: true,
            meta: {
              tags: "Needle Coke",
              keywords: "Needle Coke",
              description: "Needle Coke",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d556a",
            },
            slug: "coke",
            title: "Coke",
            id: 8001271,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/coke",
            active: true,
            meta: {
              tags: "Coke",
              keywords: "Coke",
              description: "Coke",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d556c",
                },
                slug: "metallurgical-coke",
                title: "Metallurgical Coke",
                id: 8001273,
                parent: "/chemicals-materials/specialty-carbon/coke",
                path: "/chemicals-materials/specialty-carbon/coke/metallurgical-coke",
                active: true,
                meta: {
                  tags: "Metallurgical Coke",
                  keywords: "Metallurgical Coke",
                  description: "Metallurgical Coke",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d556b",
                },
                slug: "green-petroleum-and-calcined-petroleum-coke",
                title: "Green Petroleum and Calcined Petroleum Coke",
                id: 8001272,
                parent: "/chemicals-materials/specialty-carbon/coke",
                path: "/chemicals-materials/specialty-carbon/coke/green-petroleum-and-calcined-petroleum-coke",
                active: true,
                meta: {
                  tags: "Green Petroleum and Calcined Petroleum Coke",
                  keywords: "Green Petroleum and Calcined Petroleum Coke",
                  description: "Green Petroleum and Calcined Petroleum Coke",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5568",
            },
            slug: "carbon-foam",
            title: "Carbon Foam",
            id: 8001269,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/carbon-foam",
            active: true,
            meta: {
              tags: "Carbon Foam",
              keywords: "Carbon Foam",
              description: "Carbon Foam",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d556d",
            },
            slug: "fullerene",
            title: "Fullerene",
            id: 8001274,
            parent: "/chemicals-materials/specialty-carbon",
            path: "/chemicals-materials/specialty-carbon/fullerene",
            active: true,
            meta: {
              tags: "Fullerene",
              keywords: "Fullerene",
              description: "Fullerene",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d5578",
        },
        slug: "specialty-chemicals",
        title: "Specialty Chemicals",
        id: 61643,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/specialty-chemicals",
        active: true,
        meta: {
          tags: "Specialty Chemicals",
          keywords: "Specialty Chemicals",
          description: "Specialty Chemicals",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55a6",
            },
            slug: "laboratory-chemicals",
            title: "Laboratory Chemicals",
            id: 8001331,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
            active: true,
            meta: {
              tags: "Laboratory Chemicals",
              keywords: "Laboratory Chemicals",
              description: "Laboratory Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55a7",
                },
                slug: "chelating-agents",
                title: "Chelating Agents",
                id: 8001332,
                parent:
                  "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
                path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals/chelating-agents",
                active: true,
                meta: {
                  tags: "Chelating Agents",
                  keywords: "Chelating Agents",
                  description: "Chelating Agents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ad",
                },
                slug: "phosphate-chemical-reagents",
                title: "Phosphate Chemical Reagents",
                id: 8001338,
                parent:
                  "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
                path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals/phosphate-chemical-reagents",
                active: true,
                meta: {
                  tags: "Phosphate Chemical Reagents",
                  keywords: "Phosphate Chemical Reagents",
                  description: "Phosphate Chemical Reagents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55a8",
                },
                slug: "chromatography-reagents",
                title: "Chromatography Reagents",
                id: 8001333,
                parent:
                  "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
                path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals/chromatography-reagents",
                active: true,
                meta: {
                  tags: "Chromatography Reagents",
                  keywords: "Chromatography Reagents",
                  description: "Chromatography Reagents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55af",
                },
                slug: "rhamnolipids",
                title: "Rhamnolipids",
                id: 8001340,
                parent:
                  "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
                path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals/rhamnolipids",
                active: true,
                meta: {
                  tags: "Rhamnolipids",
                  keywords: "Rhamnolipids",
                  description: "Rhamnolipids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ac",
                },
                slug: "organometallics",
                title: "Organometallics",
                id: 8001337,
                parent:
                  "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
                path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals/organometallics",
                active: true,
                meta: {
                  tags: "Organometallics",
                  keywords: "Organometallics",
                  description: "Organometallics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ab",
                },
                slug: "nucleotides",
                title: "Nucleotides",
                id: 8001336,
                parent:
                  "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
                path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals/nucleotides",
                active: true,
                meta: {
                  tags: "Nucleotides",
                  keywords: "Nucleotides",
                  description: "Nucleotides",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55a9",
                },
                slug: "chromotography-resins",
                title: "Chromotography Resins",
                id: 8001334,
                parent:
                  "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
                path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals/chromotography-resins",
                active: true,
                meta: {
                  tags: "Chromotography Resins",
                  keywords: "Chromotography Resins",
                  description: "Chromotography Resins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ae",
                },
                slug: "reactive-diluents",
                title: "Reactive Diluents",
                id: 8001339,
                parent:
                  "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
                path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals/reactive-diluents",
                active: true,
                meta: {
                  tags: "Reactive Diluents",
                  keywords: "Reactive Diluents",
                  description: "Reactive Diluents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55aa",
                },
                slug: "imaging-chemicals-and-materials",
                title: "Imaging Chemicals and Materials",
                id: 8001335,
                parent:
                  "/chemicals-materials/specialty-chemicals/laboratory-chemicals",
                path: "/chemicals-materials/specialty-chemicals/laboratory-chemicals/imaging-chemicals-and-materials",
                active: true,
                meta: {
                  tags: "Imaging Chemicals and Materials",
                  keywords: "Imaging Chemicals and Materials",
                  description: "Imaging Chemicals and Materials",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55b5",
            },
            slug: "oilfield-chemicals",
            title: "Oilfield Chemicals",
            id: 8001346,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/oilfield-chemicals",
            active: true,
            meta: {
              tags: "Oilfield Chemicals",
              keywords: "Oilfield Chemicals",
              description: "Oilfield Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ba",
                },
                slug: "proppants",
                title: "Proppants",
                id: 8001351,
                parent:
                  "/chemicals-materials/specialty-chemicals/oilfield-chemicals",
                path: "/chemicals-materials/specialty-chemicals/oilfield-chemicals/proppants",
                active: true,
                meta: {
                  tags: "Proppants",
                  keywords: "Proppants",
                  description: "Proppants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55b8",
                },
                slug: "onshore-drilling-fluids",
                title: "Onshore Drilling Fluids",
                id: 8001349,
                parent:
                  "/chemicals-materials/specialty-chemicals/oilfield-chemicals",
                path: "/chemicals-materials/specialty-chemicals/oilfield-chemicals/onshore-drilling-fluids",
                active: true,
                meta: {
                  tags: "Onshore Drilling Fluids",
                  keywords: "Onshore Drilling Fluids",
                  description: "Onshore Drilling Fluids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55b9",
                },
                slug: "pour-point-depressants",
                title: "Pour Point Depressants",
                id: 8001350,
                parent:
                  "/chemicals-materials/specialty-chemicals/oilfield-chemicals",
                path: "/chemicals-materials/specialty-chemicals/oilfield-chemicals/pour-point-depressants",
                active: true,
                meta: {
                  tags: "Pour Point Depressants",
                  keywords: "Pour Point Depressants",
                  description: "Pour Point Depressants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55b7",
                },
                slug: "demulsifier",
                title: "Demulsifier",
                id: 8001348,
                parent:
                  "/chemicals-materials/specialty-chemicals/oilfield-chemicals",
                path: "/chemicals-materials/specialty-chemicals/oilfield-chemicals/demulsifier",
                active: true,
                meta: {
                  tags: "Demulsifier",
                  keywords: "Demulsifier",
                  description: "Demulsifier",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55b6",
                },
                slug: "clear-brine-fluids",
                title: "Clear Brine Fluids",
                id: 8001347,
                parent:
                  "/chemicals-materials/specialty-chemicals/oilfield-chemicals",
                path: "/chemicals-materials/specialty-chemicals/oilfield-chemicals/clear-brine-fluids",
                active: true,
                meta: {
                  tags: "Clear Brine Fluids",
                  keywords: "Clear Brine Fluids",
                  description: "Clear Brine Fluids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55bb",
                },
                slug: "stimulation-materials",
                title: "Stimulation Materials",
                id: 8001352,
                parent:
                  "/chemicals-materials/specialty-chemicals/oilfield-chemicals",
                path: "/chemicals-materials/specialty-chemicals/oilfield-chemicals/stimulation-materials",
                active: true,
                meta: {
                  tags: "Stimulation Materials",
                  keywords: "Stimulation Materials",
                  description: "Stimulation Materials",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55bc",
            },
            slug: "personal-care-chemicals",
            title: "Personal Care Chemicals",
            id: 8001353,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/personal-care-chemicals",
            active: true,
            meta: {
              tags: "Personal Care Chemicals",
              keywords: "Personal Care Chemicals",
              description: "Personal Care Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55c1",
                },
                slug: "vetiver-oil",
                title: "Vetiver Oil",
                id: 8001358,
                parent:
                  "/chemicals-materials/specialty-chemicals/personal-care-chemicals",
                path: "/chemicals-materials/specialty-chemicals/personal-care-chemicals/vetiver-oil",
                active: true,
                meta: {
                  tags: "Vetiver Oil",
                  keywords: "Vetiver Oil",
                  description: "Vetiver Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55be",
                },
                slug: "emulsifier",
                title: "Emulsifier",
                id: 8001355,
                parent:
                  "/chemicals-materials/specialty-chemicals/personal-care-chemicals",
                path: "/chemicals-materials/specialty-chemicals/personal-care-chemicals/emulsifier",
                active: true,
                meta: {
                  tags: "Emulsifier",
                  keywords: "Emulsifier",
                  description: "Emulsifier",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55c0",
                },
                slug: "squalene",
                title: "Squalene",
                id: 8001357,
                parent:
                  "/chemicals-materials/specialty-chemicals/personal-care-chemicals",
                path: "/chemicals-materials/specialty-chemicals/personal-care-chemicals/squalene",
                active: true,
                meta: {
                  tags: "Squalene",
                  keywords: "Squalene",
                  description: "Squalene",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55bd",
                },
                slug: "emollient-ester",
                title: "Emollient Ester",
                id: 8001354,
                parent:
                  "/chemicals-materials/specialty-chemicals/personal-care-chemicals",
                path: "/chemicals-materials/specialty-chemicals/personal-care-chemicals/emollient-ester",
                active: true,
                meta: {
                  tags: "Emollient Ester",
                  keywords: "Emollient Ester",
                  description: "Emollient Ester",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55bf",
                },
                slug: "polyglycerol",
                title: "Polyglycerol",
                id: 8001356,
                parent:
                  "/chemicals-materials/specialty-chemicals/personal-care-chemicals",
                path: "/chemicals-materials/specialty-chemicals/personal-care-chemicals/polyglycerol",
                active: true,
                meta: {
                  tags: "Polyglycerol",
                  keywords: "Polyglycerol",
                  description: "Polyglycerol",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55c2",
            },
            slug: "pulp-and-paper-chemicals",
            title: "Pulp and Paper Chemicals",
            id: 8001359,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/pulp-and-paper-chemicals",
            active: true,
            meta: {
              tags: "Pulp and Paper Chemicals",
              keywords: "Pulp and Paper Chemicals",
              description: "Pulp and Paper Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55c3",
                },
                slug: "sizing-agents",
                title: "Sizing Agents",
                id: 8001360,
                parent:
                  "/chemicals-materials/specialty-chemicals/pulp-and-paper-chemicals",
                path: "/chemicals-materials/specialty-chemicals/pulp-and-paper-chemicals/sizing-agents",
                active: true,
                meta: {
                  tags: "Sizing Agents",
                  keywords: "Sizing Agents",
                  description: "Sizing Agents",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55cb",
            },
            slug: "specialty-additives",
            title: "Specialty Additives",
            id: 8001368,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/specialty-additives",
            active: true,
            meta: {
              tags: "Specialty Additives",
              keywords: "Specialty Additives",
              description: "Specialty Additives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ce",
                },
                slug: "barium-petroleum-sulfonate",
                title: "Barium Petroleum Sulfonate",
                id: 8001371,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/barium-petroleum-sulfonate",
                active: true,
                meta: {
                  tags: "Barium Petroleum Sulfonate",
                  keywords: "Barium Petroleum Sulfonate",
                  description: "Barium Petroleum Sulfonate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55cf",
                },
                slug: "bleaching-agent",
                title: "Bleaching Agent",
                id: 8001372,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/bleaching-agent",
                active: true,
                meta: {
                  tags: "Bleaching Agent",
                  keywords: "Bleaching Agent",
                  description: "Bleaching Agent",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55dd",
                },
                slug: "sodium-sulfide",
                title: "Sodium Sulfide",
                id: 8001386,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/sodium-sulfide",
                active: true,
                meta: {
                  tags: "Sodium Sulfide",
                  keywords: "Sodium Sulfide",
                  description: "Sodium Sulfide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55cc",
                },
                slug: "adhesion-promoters",
                title: "Adhesion Promoters",
                id: 8001369,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/adhesion-promoters",
                active: true,
                meta: {
                  tags: "Adhesion Promoters",
                  keywords: "Adhesion Promoters",
                  description: "Adhesion Promoters",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d6",
                },
                slug: "nitrite",
                title: "Nitrite",
                id: 8001379,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/nitrite",
                active: true,
                meta: {
                  tags: "Nitrite",
                  keywords: "Nitrite",
                  description: "Nitrite",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55df",
                },
                slug: "viscosity-reducing-agents",
                title: "Viscosity Reducing Agents",
                id: 8001388,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/viscosity-reducing-agents",
                active: true,
                meta: {
                  tags: "Viscosity Reducing Agents",
                  keywords: "Viscosity Reducing Agents",
                  description: "Viscosity Reducing Agents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55da",
                },
                slug: "rubber-additives",
                title: "Rubber Additives",
                id: 8001383,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/rubber-additives",
                active: true,
                meta: {
                  tags: "Rubber Additives",
                  keywords: "Rubber Additives",
                  description: "Rubber Additives",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d55db",
                    },
                    slug: "specialty-nitriles",
                    title: "Specialty Nitriles",
                    id: 8001384,
                    parent:
                      "/chemicals-materials/specialty-chemicals/specialty-additives/rubber-additives",
                    path: "/chemicals-materials/specialty-chemicals/specialty-additives/rubber-additives/specialty-nitriles",
                    active: true,
                    meta: {
                      tags: "Specialty Nitriles",
                      keywords: "Specialty Nitriles",
                      description: "Specialty Nitriles",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55de",
                },
                slug: "uv-absorber",
                title: "UV Absorber",
                id: 8001387,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/uv-absorber",
                active: true,
                meta: {
                  tags: "UV Absorber",
                  keywords: "UV Absorber",
                  description: "UV Absorber",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d0",
                },
                slug: "butylated-hydroxytoluene",
                title: "Butylated Hydroxytoluene",
                id: 8001373,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/butylated-hydroxytoluene",
                active: true,
                meta: {
                  tags: "Butylated Hydroxytoluene",
                  keywords: "Butylated Hydroxytoluene",
                  description: "Butylated Hydroxytoluene",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d4",
                },
                slug: "formulation-additives",
                title: "Formulation Additives",
                id: 8001377,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/formulation-additives",
                active: true,
                meta: {
                  tags: "Formulation Additives",
                  keywords: "Formulation Additives",
                  description: "Formulation Additives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d5",
                },
                slug: "methyl-ester-ethoxylate",
                title: "Methyl Ester Ethoxylate",
                id: 8001378,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/methyl-ester-ethoxylate",
                active: true,
                meta: {
                  tags: "Methyl Ester Ethoxylate",
                  keywords: "Methyl Ester Ethoxylate",
                  description: "Methyl Ester Ethoxylate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d9",
                },
                slug: "performance-additives",
                title: "Performance Additives",
                id: 8001382,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/performance-additives",
                active: true,
                meta: {
                  tags: "Performance Additives",
                  keywords: "Performance Additives",
                  description: "Performance Additives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55e0",
                },
                slug: "welan-gum",
                title: "Welan Gum",
                id: 8001389,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/welan-gum",
                active: true,
                meta: {
                  tags: "Welan Gum",
                  keywords: "Welan Gum",
                  description: "Welan Gum",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d7",
                },
                slug: "opacifiers",
                title: "Opacifiers",
                id: 8001380,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/opacifiers",
                active: true,
                meta: {
                  tags: "Opacifiers",
                  keywords: "Opacifiers",
                  description: "Opacifiers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55dc",
                },
                slug: "silanes",
                title: "Silanes",
                id: 8001385,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/silanes",
                active: true,
                meta: {
                  tags: "Silanes",
                  keywords: "Silanes",
                  description: "Silanes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d1",
                },
                slug: "coupling-agent",
                title: "Coupling Agent",
                id: 8001374,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/coupling-agent",
                active: true,
                meta: {
                  tags: "Coupling Agent",
                  keywords: "Coupling Agent",
                  description: "Coupling Agent",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d3",
                },
                slug: "fluid-loss-additives",
                title: "Fluid Loss Additives",
                id: 8001376,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/fluid-loss-additives",
                active: true,
                meta: {
                  tags: "Fluid Loss Additives",
                  keywords: "Fluid Loss Additives",
                  description: "Fluid Loss Additives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55cd",
                },
                slug: "barium-fluoride",
                title: "Barium Fluoride",
                id: 8001370,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/barium-fluoride",
                active: true,
                meta: {
                  tags: "Barium Fluoride",
                  keywords: "Barium Fluoride",
                  description: "Barium Fluoride",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d2",
                },
                slug: "cyclamate",
                title: "Cyclamate",
                id: 8001375,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/cyclamate",
                active: true,
                meta: {
                  tags: "Cyclamate",
                  keywords: "Cyclamate",
                  description: "Cyclamate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55d8",
                },
                slug: "optical-brightener",
                title: "Optical Brightener",
                id: 8001381,
                parent:
                  "/chemicals-materials/specialty-chemicals/specialty-additives",
                path: "/chemicals-materials/specialty-chemicals/specialty-additives/optical-brightener",
                active: true,
                meta: {
                  tags: "Optical Brightener",
                  keywords: "Optical Brightener",
                  description: "Optical Brightener",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d557c",
            },
            slug: "dust-control-systems-and-suppression-chemicals",
            title: "Dust Control Systems and Suppression Chemicals",
            id: 8001289,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/dust-control-systems-and-suppression-chemicals",
            active: true,
            meta: {
              tags: "Dust Control Systems and Suppression Chemicals",
              keywords: "Dust Control Systems and Suppression Chemicals",
              description: "Dust Control Systems and Suppression Chemicals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5597",
            },
            slug: "flame-retardants",
            title: "Flame Retardants",
            id: 8001316,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/flame-retardants",
            active: true,
            meta: {
              tags: "Flame Retardants",
              keywords: "Flame Retardants",
              description: "Flame Retardants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d559b",
                },
                slug: "phosphate-ester",
                title: "Phosphate Ester",
                id: 8001320,
                parent:
                  "/chemicals-materials/specialty-chemicals/flame-retardants",
                path: "/chemicals-materials/specialty-chemicals/flame-retardants/phosphate-ester",
                active: true,
                meta: {
                  tags: "Phosphate Ester",
                  keywords: "Phosphate Ester",
                  description: "Phosphate Ester",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5598",
                },
                slug: "flame-retardant-chemicals",
                title: "Flame Retardant Chemicals",
                id: 8001317,
                parent:
                  "/chemicals-materials/specialty-chemicals/flame-retardants",
                path: "/chemicals-materials/specialty-chemicals/flame-retardants/flame-retardant-chemicals",
                active: true,
                meta: {
                  tags: "Flame Retardant Chemicals",
                  keywords: "Flame Retardant Chemicals",
                  description: "Flame Retardant Chemicals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5599",
                    },
                    slug: "non-halogenated-flame-retardant-chemicals",
                    title: "Non Halogenated Flame Retardant Chemicals",
                    id: 8001318,
                    parent:
                      "/chemicals-materials/specialty-chemicals/flame-retardants/flame-retardant-chemicals",
                    path: "/chemicals-materials/specialty-chemicals/flame-retardants/flame-retardant-chemicals/non-halogenated-flame-retardant-chemicals",
                    active: true,
                    meta: {
                      tags: "Non Halogenated Flame Retardant Chemicals",
                      keywords: "Non Halogenated Flame Retardant Chemicals",
                      description: "Non Halogenated Flame Retardant Chemicals",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d559a",
                },
                slug: "flame-retardants-for-aerospace-plastics",
                title: "Flame Retardants for Aerospace Plastics",
                id: 8001319,
                parent:
                  "/chemicals-materials/specialty-chemicals/flame-retardants",
                path: "/chemicals-materials/specialty-chemicals/flame-retardants/flame-retardants-for-aerospace-plastics",
                active: true,
                meta: {
                  tags: "Flame Retardants for Aerospace Plastics",
                  keywords: "Flame Retardants for Aerospace Plastics",
                  description: "Flame Retardants for Aerospace Plastics",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55c4",
            },
            slug: "refining-chemicals",
            title: "Refining Chemicals",
            id: 8001361,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/refining-chemicals",
            active: true,
            meta: {
              tags: "Refining Chemicals",
              keywords: "Refining Chemicals",
              description: "Refining Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55c5",
                },
                slug: "crude-oil-flow-improvers",
                title: "Crude Oil Flow Improvers",
                id: 8001362,
                parent:
                  "/chemicals-materials/specialty-chemicals/refining-chemicals",
                path: "/chemicals-materials/specialty-chemicals/refining-chemicals/crude-oil-flow-improvers",
                active: true,
                meta: {
                  tags: "Crude Oil Flow Improvers",
                  keywords: "Crude Oil Flow Improvers",
                  description: "Crude Oil Flow Improvers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55c6",
                },
                slug: "fracking-chemicals-fluids",
                title: "Fracking Chemicals Fluids",
                id: 8001363,
                parent:
                  "/chemicals-materials/specialty-chemicals/refining-chemicals",
                path: "/chemicals-materials/specialty-chemicals/refining-chemicals/fracking-chemicals-fluids",
                active: true,
                meta: {
                  tags: "Fracking Chemicals Fluids",
                  keywords: "Fracking Chemicals Fluids",
                  description: "Fracking Chemicals Fluids",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d557a",
            },
            slug: "biocides",
            title: "Biocides",
            id: 8001287,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/biocides",
            active: true,
            meta: {
              tags: "Biocides",
              keywords: "Biocides",
              description: "Biocides",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d557b",
                },
                slug: "metal-biocide",
                title: "Metal Biocide",
                id: 8001288,
                parent: "/chemicals-materials/specialty-chemicals/biocides",
                path: "/chemicals-materials/specialty-chemicals/biocides/metal-biocide",
                active: true,
                meta: {
                  tags: "Metal Biocide",
                  keywords: "Metal Biocide",
                  description: "Metal Biocide",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5594",
            },
            slug: "explosives",
            title: "Explosives",
            id: 8001313,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/explosives",
            active: true,
            meta: {
              tags: "Explosives",
              keywords: "Explosives",
              description: "Explosives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5595",
                },
                slug: "ammonium-nitrate",
                title: "Ammonium Nitrate",
                id: 8001314,
                parent: "/chemicals-materials/specialty-chemicals/explosives",
                path: "/chemicals-materials/specialty-chemicals/explosives/ammonium-nitrate",
                active: true,
                meta: {
                  tags: "Ammonium Nitrate",
                  keywords: "Ammonium Nitrate",
                  description: "Ammonium Nitrate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5596",
                },
                slug: "trinitrotoluene-tnt",
                title: "Trinitrotoluene (TNT)",
                id: 8001315,
                parent: "/chemicals-materials/specialty-chemicals/explosives",
                path: "/chemicals-materials/specialty-chemicals/explosives/trinitrotoluene-tnt",
                active: true,
                meta: {
                  tags: "Trinitrotoluene (TNT)",
                  keywords: "Trinitrotoluene (TNT)",
                  description: "Trinitrotoluene (TNT)",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55c7",
            },
            slug: "refrigerants",
            title: "Refrigerants",
            id: 8001364,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/refrigerants",
            active: true,
            meta: {
              tags: "Refrigerants",
              keywords: "Refrigerants",
              description: "Refrigerants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55c9",
                },
                slug: "low-gwp-refrigerants",
                title: "Low GWP Refrigerants",
                id: 8001366,
                parent: "/chemicals-materials/specialty-chemicals/refrigerants",
                path: "/chemicals-materials/specialty-chemicals/refrigerants/low-gwp-refrigerants",
                active: true,
                meta: {
                  tags: "Low GWP Refrigerants",
                  keywords: "Low GWP Refrigerants",
                  description: "Low GWP Refrigerants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ca",
                },
                slug: "transcritical-co2",
                title: "Transcritical Co2",
                id: 8001367,
                parent: "/chemicals-materials/specialty-chemicals/refrigerants",
                path: "/chemicals-materials/specialty-chemicals/refrigerants/transcritical-co2",
                active: true,
                meta: {
                  tags: "Transcritical Co2",
                  keywords: "Transcritical Co2",
                  description: "Transcritical Co2",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55c8",
                },
                slug: "aerosol-refrigerants",
                title: "Aerosol Refrigerants",
                id: 8001365,
                parent: "/chemicals-materials/specialty-chemicals/refrigerants",
                path: "/chemicals-materials/specialty-chemicals/refrigerants/aerosol-refrigerants",
                active: true,
                meta: {
                  tags: "Aerosol Refrigerants",
                  keywords: "Aerosol Refrigerants",
                  description: "Aerosol Refrigerants",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5579",
            },
            slug: "aroma-chemicals",
            title: "Aroma Chemicals",
            id: 8001286,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/aroma-chemicals",
            active: true,
            meta: {
              tags: "Aroma Chemicals",
              keywords: "Aroma Chemicals",
              description: "Aroma Chemicals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55b0",
            },
            slug: "leather-chemicals",
            title: "Leather Chemicals",
            id: 8001341,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/leather-chemicals",
            active: true,
            meta: {
              tags: "Leather Chemicals",
              keywords: "Leather Chemicals",
              description: "Leather Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55b1",
                },
                slug: "tanning-agents",
                title: "Tanning Agents",
                id: 8001342,
                parent:
                  "/chemicals-materials/specialty-chemicals/leather-chemicals",
                path: "/chemicals-materials/specialty-chemicals/leather-chemicals/tanning-agents",
                active: true,
                meta: {
                  tags: "Tanning Agents",
                  keywords: "Tanning Agents",
                  description: "Tanning Agents",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55e3",
            },
            slug: "surfactants",
            title: "Surfactants",
            id: 8001392,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/surfactants",
            active: true,
            meta: {
              tags: "Surfactants",
              keywords: "Surfactants",
              description: "Surfactants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55e8",
                },
                slug: "biosurfactants",
                title: "Biosurfactants",
                id: 8001397,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/biosurfactants",
                active: true,
                meta: {
                  tags: "Biosurfactants",
                  keywords: "Biosurfactants",
                  description: "Biosurfactants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55e9",
                },
                slug: "detergents",
                title: "Detergents",
                id: 8001398,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/detergents",
                active: true,
                meta: {
                  tags: "Detergents",
                  keywords: "Detergents",
                  description: "Detergents",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ed",
                },
                slug: "nonylphenol-ethoxylate",
                title: "Nonylphenol Ethoxylate",
                id: 8001402,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/nonylphenol-ethoxylate",
                active: true,
                meta: {
                  tags: "Nonylphenol Ethoxylate",
                  keywords: "Nonylphenol Ethoxylate",
                  description: "Nonylphenol Ethoxylate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55f0",
                },
                slug: "specialty-surfactants",
                title: "Specialty Surfactants",
                id: 8001405,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/specialty-surfactants",
                active: true,
                meta: {
                  tags: "Specialty Surfactants",
                  keywords: "Specialty Surfactants",
                  description: "Specialty Surfactants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ea",
                },
                slug: "fatty-methyl-ester-sulfonate",
                title: "Fatty Methyl Ester Sulfonate",
                id: 8001399,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/fatty-methyl-ester-sulfonate",
                active: true,
                meta: {
                  tags: "Fatty Methyl Ester Sulfonate",
                  keywords: "Fatty Methyl Ester Sulfonate",
                  description: "Fatty Methyl Ester Sulfonate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55e4",
                },
                slug: "alcohol-ethoxylates",
                title: "Alcohol Ethoxylates",
                id: 8001393,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/alcohol-ethoxylates",
                active: true,
                meta: {
                  tags: "Alcohol Ethoxylates",
                  keywords: "Alcohol Ethoxylates",
                  description: "Alcohol Ethoxylates",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ee",
                },
                slug: "silicone-surfactants",
                title: "Silicone Surfactants",
                id: 8001403,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/silicone-surfactants",
                active: true,
                meta: {
                  tags: "Silicone Surfactants",
                  keywords: "Silicone Surfactants",
                  description: "Silicone Surfactants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ec",
                },
                slug: "low-foam-surfactants",
                title: "Low Foam Surfactants",
                id: 8001401,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/low-foam-surfactants",
                active: true,
                meta: {
                  tags: "Low Foam Surfactants",
                  keywords: "Low Foam Surfactants",
                  description: "Low Foam Surfactants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55e6",
                },
                slug: "amphoteric-surfactants",
                title: "Amphoteric Surfactants",
                id: 8001395,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/amphoteric-surfactants",
                active: true,
                meta: {
                  tags: "Amphoteric Surfactants",
                  keywords: "Amphoteric Surfactants",
                  description: "Amphoteric Surfactants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55e7",
                },
                slug: "betaine",
                title: "Betaine",
                id: 8001396,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/betaine",
                active: true,
                meta: {
                  tags: "Betaine",
                  keywords: "Betaine",
                  description: "Betaine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55ef",
                },
                slug: "sodium-lauryl-sulfate",
                title: "Sodium Lauryl Sulfate",
                id: 8001404,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/sodium-lauryl-sulfate",
                active: true,
                meta: {
                  tags: "Sodium Lauryl Sulfate",
                  keywords: "Sodium Lauryl Sulfate",
                  description: "Sodium Lauryl Sulfate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55e5",
                },
                slug: "alkyl-polyglycoside-apg",
                title: "Alkyl Polyglycoside (APG)",
                id: 8001394,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/alkyl-polyglycoside-apg",
                active: true,
                meta: {
                  tags: "Alkyl Polyglycoside (APG)",
                  keywords: "Alkyl Polyglycoside (APG)",
                  description: "Alkyl Polyglycoside (APG)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55eb",
                },
                slug: "fluorosurfactants",
                title: "Fluorosurfactants",
                id: 8001400,
                parent: "/chemicals-materials/specialty-chemicals/surfactants",
                path: "/chemicals-materials/specialty-chemicals/surfactants/fluorosurfactants",
                active: true,
                meta: {
                  tags: "Fluorosurfactants",
                  keywords: "Fluorosurfactants",
                  description: "Fluorosurfactants",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55f1",
            },
            slug: "textile-chemicals",
            title: "Textile Chemicals",
            id: 8001406,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/textile-chemicals",
            active: true,
            meta: {
              tags: "Textile Chemicals",
              keywords: "Textile Chemicals",
              description: "Textile Chemicals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d557d",
            },
            slug: "dyes-and-pigments",
            title: "Dyes and Pigments",
            id: 8001290,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments",
            active: true,
            meta: {
              tags: "Dyes and Pigments",
              keywords: "Dyes and Pigments",
              description: "Dyes and Pigments",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d557e",
                },
                slug: "dyes",
                title: "Dyes",
                id: 8001291,
                parent:
                  "/chemicals-materials/specialty-chemicals/dyes-and-pigments",
                path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/dyes",
                active: true,
                meta: {
                  tags: "Dyes",
                  keywords: "Dyes",
                  description: "Dyes",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5581",
                    },
                    slug: "prussian-blue",
                    title: "Prussian Blue",
                    id: 8001294,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/dyes",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/dyes/prussian-blue",
                    active: true,
                    meta: {
                      tags: "Prussian Blue",
                      keywords: "Prussian Blue",
                      description: "Prussian Blue",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5582",
                    },
                    slug: "textile-dyes",
                    title: "Textile Dyes",
                    id: 8001295,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/dyes",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/dyes/textile-dyes",
                    active: true,
                    meta: {
                      tags: "Textile Dyes",
                      keywords: "Textile Dyes",
                      description: "Textile Dyes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d557f",
                    },
                    slug: "cosmetic-dyes",
                    title: "Cosmetic Dyes",
                    id: 8001292,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/dyes",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/dyes/cosmetic-dyes",
                    active: true,
                    meta: {
                      tags: "Cosmetic Dyes",
                      keywords: "Cosmetic Dyes",
                      description: "Cosmetic Dyes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5580",
                    },
                    slug: "paper-dyes",
                    title: "Paper Dyes",
                    id: 8001293,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/dyes",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/dyes/paper-dyes",
                    active: true,
                    meta: {
                      tags: "Paper Dyes",
                      keywords: "Paper Dyes",
                      description: "Paper Dyes",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5583",
                },
                slug: "pigments",
                title: "Pigments",
                id: 8001296,
                parent:
                  "/chemicals-materials/specialty-chemicals/dyes-and-pigments",
                path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments",
                active: true,
                meta: {
                  tags: "Pigments",
                  keywords: "Pigments",
                  description: "Pigments",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d558a",
                    },
                    slug: "iron-oxide-pigments",
                    title: "Iron Oxide Pigments",
                    id: 8001303,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/iron-oxide-pigments",
                    active: true,
                    meta: {
                      tags: "Iron Oxide Pigments",
                      keywords: "Iron Oxide Pigments",
                      description: "Iron Oxide Pigments",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d558b",
                    },
                    slug: "organic-pigments",
                    title: "Organic Pigments",
                    id: 8001304,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/organic-pigments",
                    active: true,
                    meta: {
                      tags: "Organic Pigments",
                      keywords: "Organic Pigments",
                      description: "Organic Pigments",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d558c",
                        },
                        slug: "special-effect-pigments",
                        title: "Special Effect Pigments",
                        id: 8001305,
                        parent:
                          "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/organic-pigments",
                        path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/organic-pigments/special-effect-pigments",
                        active: true,
                        meta: {
                          tags: "Special Effect Pigments",
                          keywords: "Special Effect Pigments",
                          description: "Special Effect Pigments",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d558e",
                    },
                    slug: "thermochromic-pigments",
                    title: "Thermochromic Pigments",
                    id: 8001307,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/thermochromic-pigments",
                    active: true,
                    meta: {
                      tags: "Thermochromic Pigments",
                      keywords: "Thermochromic Pigments",
                      description: "Thermochromic Pigments",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d558f",
                    },
                    slug: "titanium-dioxide",
                    title: "Titanium Dioxide",
                    id: 8001308,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/titanium-dioxide",
                    active: true,
                    meta: {
                      tags: "Titanium Dioxide",
                      keywords: "Titanium Dioxide",
                      description: "Titanium Dioxide",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5585",
                    },
                    slug: "inorganic-pigments",
                    title: "Inorganic Pigments",
                    id: 8001298,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/inorganic-pigments",
                    active: true,
                    meta: {
                      tags: "Inorganic Pigments",
                      keywords: "Inorganic Pigments",
                      description: "Inorganic Pigments",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5587",
                        },
                        slug: "cadmium-pigments",
                        title: "Cadmium Pigments",
                        id: 8001300,
                        parent:
                          "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/inorganic-pigments",
                        path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/inorganic-pigments/cadmium-pigments",
                        active: true,
                        meta: {
                          tags: "Cadmium Pigments",
                          keywords: "Cadmium Pigments",
                          description: "Cadmium Pigments",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5588",
                        },
                        slug: "phosphorescent-pigments",
                        title: "Phosphorescent Pigments",
                        id: 8001301,
                        parent:
                          "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/inorganic-pigments",
                        path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/inorganic-pigments/phosphorescent-pigments",
                        active: true,
                        meta: {
                          tags: "Phosphorescent Pigments",
                          keywords: "Phosphorescent Pigments",
                          description: "Phosphorescent Pigments",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5589",
                        },
                        slug: "zinc-oxide-pigments",
                        title: "Zinc Oxide Pigments",
                        id: 8001302,
                        parent:
                          "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/inorganic-pigments",
                        path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/inorganic-pigments/zinc-oxide-pigments",
                        active: true,
                        meta: {
                          tags: "Zinc Oxide Pigments",
                          keywords: "Zinc Oxide Pigments",
                          description: "Zinc Oxide Pigments",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9d69a9d316ab7c2d5586",
                        },
                        slug: "aluminum-pigments",
                        title: "Aluminum Pigments",
                        id: 8001299,
                        parent:
                          "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/inorganic-pigments",
                        path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/inorganic-pigments/aluminum-pigments",
                        active: true,
                        meta: {
                          tags: "Aluminum Pigments",
                          keywords: "Aluminum Pigments",
                          description: "Aluminum Pigments",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d5584",
                    },
                    slug: "cosmetic-pigments",
                    title: "Cosmetic Pigments",
                    id: 8001297,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/cosmetic-pigments",
                    active: true,
                    meta: {
                      tags: "Cosmetic Pigments",
                      keywords: "Cosmetic Pigments",
                      description: "Cosmetic Pigments",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d558d",
                    },
                    slug: "paper-pigments",
                    title: "Paper Pigments",
                    id: 8001306,
                    parent:
                      "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments",
                    path: "/chemicals-materials/specialty-chemicals/dyes-and-pigments/pigments/paper-pigments",
                    active: true,
                    meta: {
                      tags: "Paper Pigments",
                      keywords: "Paper Pigments",
                      description: "Paper Pigments",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d559c",
            },
            slug: "industrial-and-institutional-cleaning-chemicals",
            title: "Industrial and Institutional Cleaning Chemicals",
            id: 8001321,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/industrial-and-institutional-cleaning-chemicals",
            active: true,
            meta: {
              tags: "Industrial and Institutional Cleaning Chemicals",
              keywords: "Industrial and Institutional Cleaning Chemicals",
              description: "Industrial and Institutional Cleaning Chemicals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d559d",
            },
            slug: "inks",
            title: "Inks",
            id: 8001322,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/inks",
            active: true,
            meta: {
              tags: "Inks",
              keywords: "Inks",
              description: "Inks",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d559f",
                },
                slug: "non-conductive-inks",
                title: "Non Conductive Inks",
                id: 8001324,
                parent: "/chemicals-materials/specialty-chemicals/inks",
                path: "/chemicals-materials/specialty-chemicals/inks/non-conductive-inks",
                active: true,
                meta: {
                  tags: "Non Conductive Inks",
                  keywords: "Non Conductive Inks",
                  description: "Non Conductive Inks",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d559e",
                },
                slug: "ink-additives",
                title: "Ink Additives",
                id: 8001323,
                parent: "/chemicals-materials/specialty-chemicals/inks",
                path: "/chemicals-materials/specialty-chemicals/inks/ink-additives",
                active: true,
                meta: {
                  tags: "Ink Additives",
                  keywords: "Ink Additives",
                  description: "Ink Additives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55a0",
                },
                slug: "printing-inks",
                title: "Printing Inks",
                id: 8001325,
                parent: "/chemicals-materials/specialty-chemicals/inks",
                path: "/chemicals-materials/specialty-chemicals/inks/printing-inks",
                active: true,
                meta: {
                  tags: "Printing Inks",
                  keywords: "Printing Inks",
                  description: "Printing Inks",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d55a1",
                    },
                    slug: "ceramic-printing-inks",
                    title: "Ceramic Printing Inks",
                    id: 8001326,
                    parent:
                      "/chemicals-materials/specialty-chemicals/inks/printing-inks",
                    path: "/chemicals-materials/specialty-chemicals/inks/printing-inks/ceramic-printing-inks",
                    active: true,
                    meta: {
                      tags: "Ceramic Printing Inks",
                      keywords: "Ceramic Printing Inks",
                      description: "Ceramic Printing Inks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d55a4",
                    },
                    slug: "flexographic-inks",
                    title: "Flexographic Inks",
                    id: 8001329,
                    parent:
                      "/chemicals-materials/specialty-chemicals/inks/printing-inks",
                    path: "/chemicals-materials/specialty-chemicals/inks/printing-inks/flexographic-inks",
                    active: true,
                    meta: {
                      tags: "Flexographic Inks",
                      keywords: "Flexographic Inks",
                      description: "Flexographic Inks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d55a5",
                    },
                    slug: "uv-cured-printing-inks",
                    title: "UV Cured Printing Inks",
                    id: 8001330,
                    parent:
                      "/chemicals-materials/specialty-chemicals/inks/printing-inks",
                    path: "/chemicals-materials/specialty-chemicals/inks/printing-inks/uv-cured-printing-inks",
                    active: true,
                    meta: {
                      tags: "UV Cured Printing Inks",
                      keywords: "UV Cured Printing Inks",
                      description: "UV Cured Printing Inks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d55a2",
                    },
                    slug: "digital-inks",
                    title: "Digital Inks",
                    id: 8001327,
                    parent:
                      "/chemicals-materials/specialty-chemicals/inks/printing-inks",
                    path: "/chemicals-materials/specialty-chemicals/inks/printing-inks/digital-inks",
                    active: true,
                    meta: {
                      tags: "Digital Inks",
                      keywords: "Digital Inks",
                      description: "Digital Inks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9d69a9d316ab7c2d55a3",
                    },
                    slug: "eco-friendly-inks",
                    title: "Eco Friendly Inks",
                    id: 8001328,
                    parent:
                      "/chemicals-materials/specialty-chemicals/inks/printing-inks",
                    path: "/chemicals-materials/specialty-chemicals/inks/printing-inks/eco-friendly-inks",
                    active: true,
                    meta: {
                      tags: "Eco Friendly Inks",
                      keywords: "Eco Friendly Inks",
                      description: "Eco Friendly Inks",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5590",
            },
            slug: "electronic-chemicals",
            title: "Electronic Chemicals",
            id: 8001309,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/electronic-chemicals",
            active: true,
            meta: {
              tags: "Electronic Chemicals",
              keywords: "Electronic Chemicals",
              description: "Electronic Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5591",
                },
                slug: "indium-tin-oxide",
                title: "Indium Tin Oxide",
                id: 8001310,
                parent:
                  "/chemicals-materials/specialty-chemicals/electronic-chemicals",
                path: "/chemicals-materials/specialty-chemicals/electronic-chemicals/indium-tin-oxide",
                active: true,
                meta: {
                  tags: "Indium Tin Oxide",
                  keywords: "Indium Tin Oxide",
                  description: "Indium Tin Oxide",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5593",
                },
                slug: "wet-chemicals",
                title: "Wet Chemicals",
                id: 8001312,
                parent:
                  "/chemicals-materials/specialty-chemicals/electronic-chemicals",
                path: "/chemicals-materials/specialty-chemicals/electronic-chemicals/wet-chemicals",
                active: true,
                meta: {
                  tags: "Wet Chemicals",
                  keywords: "Wet Chemicals",
                  description: "Wet Chemicals",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5592",
                },
                slug: "ultra-pure-water",
                title: "Ultra Pure Water",
                id: 8001311,
                parent:
                  "/chemicals-materials/specialty-chemicals/electronic-chemicals",
                path: "/chemicals-materials/specialty-chemicals/electronic-chemicals/ultra-pure-water",
                active: true,
                meta: {
                  tags: "Ultra Pure Water",
                  keywords: "Ultra Pure Water",
                  description: "Ultra Pure Water",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55b2",
            },
            slug: "mining-chemicals",
            title: "Mining Chemicals",
            id: 8001343,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/mining-chemicals",
            active: true,
            meta: {
              tags: "Mining Chemicals",
              keywords: "Mining Chemicals",
              description: "Mining Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55b4",
                },
                slug: "froth-floatation-chemicals",
                title: "Froth Floatation Chemicals",
                id: 8001345,
                parent:
                  "/chemicals-materials/specialty-chemicals/mining-chemicals",
                path: "/chemicals-materials/specialty-chemicals/mining-chemicals/froth-floatation-chemicals",
                active: true,
                meta: {
                  tags: "Froth Floatation Chemicals",
                  keywords: "Froth Floatation Chemicals",
                  description: "Froth Floatation Chemicals",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55b3",
                },
                slug: "floating-reagent",
                title: "Floating Reagent",
                id: 8001344,
                parent:
                  "/chemicals-materials/specialty-chemicals/mining-chemicals",
                path: "/chemicals-materials/specialty-chemicals/mining-chemicals/floating-reagent",
                active: true,
                meta: {
                  tags: "Floating Reagent",
                  keywords: "Floating Reagent",
                  description: "Floating Reagent",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d55e1",
            },
            slug: "surface-treatment-chemicals",
            title: "Surface Treatment Chemicals",
            id: 8001390,
            parent: "/chemicals-materials/specialty-chemicals",
            path: "/chemicals-materials/specialty-chemicals/surface-treatment-chemicals",
            active: true,
            meta: {
              tags: "Surface Treatment Chemicals",
              keywords: "Surface Treatment Chemicals",
              description: "Surface Treatment Chemicals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d55e2",
                },
                slug: "metal-cleaning-chemicals",
                title: "Metal Cleaning Chemicals",
                id: 8001391,
                parent:
                  "/chemicals-materials/specialty-chemicals/surface-treatment-chemicals",
                path: "/chemicals-materials/specialty-chemicals/surface-treatment-chemicals/metal-cleaning-chemicals",
                active: true,
                meta: {
                  tags: "Metal Cleaning Chemicals",
                  keywords: "Metal Cleaning Chemicals",
                  description: "Metal Cleaning Chemicals",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9d69a9d316ab7c2d5624",
        },
        slug: "wood-derivatives",
        title: "Wood Derivatives",
        id: 61645,
        parent: "/chemicals-materials",
        path: "/chemicals-materials/wood-derivatives",
        active: true,
        meta: {
          tags: "Wood Derivatives",
          keywords: "Wood Derivatives",
          description: "Wood Derivatives",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5633",
            },
            slug: "tall-oil-fatty-acid",
            title: "Tall Oil Fatty Acid",
            id: 8001473,
            parent: "/chemicals-materials/wood-derivatives",
            path: "/chemicals-materials/wood-derivatives/tall-oil-fatty-acid",
            active: true,
            meta: {
              tags: "Tall Oil Fatty Acid",
              keywords: "Tall Oil Fatty Acid",
              description: "Tall Oil Fatty Acid",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d562e",
            },
            slug: "nanocellulose",
            title: "Nanocellulose",
            id: 8001468,
            parent: "/chemicals-materials/wood-derivatives",
            path: "/chemicals-materials/wood-derivatives/nanocellulose",
            active: true,
            meta: {
              tags: "Nanocellulose",
              keywords: "Nanocellulose",
              description: "Nanocellulose",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d562b",
            },
            slug: "ester-gum",
            title: "Ester Gum",
            id: 8001465,
            parent: "/chemicals-materials/wood-derivatives",
            path: "/chemicals-materials/wood-derivatives/ester-gum",
            active: true,
            meta: {
              tags: "Ester Gum",
              keywords: "Ester Gum",
              description: "Ester Gum",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d562f",
            },
            slug: "specialty-wood-resins",
            title: "Specialty Wood Resins",
            id: 8001469,
            parent: "/chemicals-materials/wood-derivatives",
            path: "/chemicals-materials/wood-derivatives/specialty-wood-resins",
            active: true,
            meta: {
              tags: "Specialty Wood Resins",
              keywords: "Specialty Wood Resins",
              description: "Specialty Wood Resins",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5630",
                },
                slug: "gum-rosin",
                title: "Gum Rosin",
                id: 8001470,
                parent:
                  "/chemicals-materials/wood-derivatives/specialty-wood-resins",
                path: "/chemicals-materials/wood-derivatives/specialty-wood-resins/gum-rosin",
                active: true,
                meta: {
                  tags: "Gum Rosin",
                  keywords: "Gum Rosin",
                  description: "Gum Rosin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5631",
                },
                slug: "rosin",
                title: "Rosin",
                id: 8001471,
                parent:
                  "/chemicals-materials/wood-derivatives/specialty-wood-resins",
                path: "/chemicals-materials/wood-derivatives/specialty-wood-resins/rosin",
                active: true,
                meta: {
                  tags: "Rosin",
                  keywords: "Rosin",
                  description: "Rosin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5632",
                },
                slug: "tall-oil-rosin",
                title: "Tall Oil Rosin",
                id: 8001472,
                parent:
                  "/chemicals-materials/wood-derivatives/specialty-wood-resins",
                path: "/chemicals-materials/wood-derivatives/specialty-wood-resins/tall-oil-rosin",
                active: true,
                meta: {
                  tags: "Tall Oil Rosin",
                  keywords: "Tall Oil Rosin",
                  description: "Tall Oil Rosin",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5634",
            },
            slug: "wood-based-fiber",
            title: "Wood based Fiber",
            id: 8001474,
            parent: "/chemicals-materials/wood-derivatives",
            path: "/chemicals-materials/wood-derivatives/wood-based-fiber",
            active: true,
            meta: {
              tags: "Wood based Fiber",
              keywords: "Wood based Fiber",
              description: "Wood based Fiber",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d5625",
            },
            slug: "cellulose-derivatives",
            title: "Cellulose Derivatives",
            id: 8001459,
            parent: "/chemicals-materials/wood-derivatives",
            path: "/chemicals-materials/wood-derivatives/cellulose-derivatives",
            active: true,
            meta: {
              tags: "Cellulose Derivatives",
              keywords: "Cellulose Derivatives",
              description: "Cellulose Derivatives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5629",
                },
                slug: "hyroxypropyl-cellulose",
                title: "Hyroxypropyl Cellulose",
                id: 8001463,
                parent:
                  "/chemicals-materials/wood-derivatives/cellulose-derivatives",
                path: "/chemicals-materials/wood-derivatives/cellulose-derivatives/hyroxypropyl-cellulose",
                active: true,
                meta: {
                  tags: "Hyroxypropyl Cellulose",
                  keywords: "Hyroxypropyl Cellulose",
                  description: "Hyroxypropyl Cellulose",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5627",
                },
                slug: "cellulose-acetate",
                title: "Cellulose Acetate",
                id: 8001461,
                parent:
                  "/chemicals-materials/wood-derivatives/cellulose-derivatives",
                path: "/chemicals-materials/wood-derivatives/cellulose-derivatives/cellulose-acetate",
                active: true,
                meta: {
                  tags: "Cellulose Acetate",
                  keywords: "Cellulose Acetate",
                  description: "Cellulose Acetate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d562a",
                },
                slug: "microcrystalline-cellulose",
                title: "Microcrystalline Cellulose",
                id: 8001464,
                parent:
                  "/chemicals-materials/wood-derivatives/cellulose-derivatives",
                path: "/chemicals-materials/wood-derivatives/cellulose-derivatives/microcrystalline-cellulose",
                active: true,
                meta: {
                  tags: "Microcrystalline Cellulose",
                  keywords: "Microcrystalline Cellulose",
                  description: "Microcrystalline Cellulose",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5626",
                },
                slug: "carboxymethyl-cellulose",
                title: "Carboxymethyl Cellulose",
                id: 8001460,
                parent:
                  "/chemicals-materials/wood-derivatives/cellulose-derivatives",
                path: "/chemicals-materials/wood-derivatives/cellulose-derivatives/carboxymethyl-cellulose",
                active: true,
                meta: {
                  tags: "Carboxymethyl Cellulose",
                  keywords: "Carboxymethyl Cellulose",
                  description: "Carboxymethyl Cellulose",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d5628",
                },
                slug: "cellulosic-polymers",
                title: "Cellulosic Polymers",
                id: 8001462,
                parent:
                  "/chemicals-materials/wood-derivatives/cellulose-derivatives",
                path: "/chemicals-materials/wood-derivatives/cellulose-derivatives/cellulosic-polymers",
                active: true,
                meta: {
                  tags: "Cellulosic Polymers",
                  keywords: "Cellulosic Polymers",
                  description: "Cellulosic Polymers",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9d69a9d316ab7c2d562c",
            },
            slug: "lignin-products",
            title: "Lignin Products",
            id: 8001466,
            parent: "/chemicals-materials/wood-derivatives",
            path: "/chemicals-materials/wood-derivatives/lignin-products",
            active: true,
            meta: {
              tags: "Lignin Products",
              keywords: "Lignin Products",
              description: "Lignin Products",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9d69a9d316ab7c2d562d",
                },
                slug: "kraft-lignin-products",
                title: "Kraft Lignin Products",
                id: 8001467,
                parent: "/chemicals-materials/wood-derivatives/lignin-products",
                path: "/chemicals-materials/wood-derivatives/lignin-products/kraft-lignin-products",
                active: true,
                meta: {
                  tags: "Kraft Lignin Products",
                  keywords: "Kraft Lignin Products",
                  description: "Kraft Lignin Products",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b12e",
    },
    id: 62,
    title: "Food & Beverage",
    slug: "food-beverage",
    active: true,
    meta: {
      tags: "Food & Beverage Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "food and beverage market, food industry, beverage industry, f&b industry, us food market, food industry companies, food industry trends, food and beverage trends, food packaging industry, trends in food and beverage industry, beverage industry trends, food and beverage industry overview, food and beverage industry statistics, food industry overview, food industry report, food industry growth, global food and beverage market size\n",
      description:
        "Food & Beverage is one of the largest industries in the world. Our reports on Food & Beverage industry encompass both B2B and B2C food markets with focus on breaking down the intricacies of every element that goes into human consumption.",
    },
    description: {
      short_description:
        "Constantly fluctuating economies, evolving lifestyles, and consumer preferences are changing the consumption patterns and selling formats of food & beverages across the globe. Learn about the latest regulations, trends, and consumer behaviors shaping the global food & beverage market.",
      full_description:
        "Constantly fluctuating economies, evolving lifestyles, and consumer preferences are changing the consumption patterns and selling formats of food & beverages across the globe. Learn about the latest regulations, trends, and consumer behaviors shaping the global food & beverage market.",
    },
    picture: null,
    __v: 0,
    picture_url: "food-&-beverage.png",
    cat_email: "fnb@mordorintelligence.com",
    cat_pw_id: 12429,
    parent: "/",
    path: "/food-beverage",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b193",
        },
        id: 62115,
        title: "Beverages",
        slug: "beverage",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Beverage Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Beverages Market Research Reports, beverage market, food and beverage market, food and beverage market research, beverage industry market, beverage marketing trends, beverage market research reports, beverage industry researh reports.",
          tags: "Beverage Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "The report beverages market analyses trends in beverages market, new developments and players, beverages market trends, alcoholic new trends, carbonated beverages trends, soft drinks trends, functional beverages trends, water enhancer trends",
          short_description:
            "Mordor Intelligence™ beverages research reports analyses trends in the beverages market, new developments and players, beverages market trends, alcoholic new trends, carbonated beverages trends, soft drinks trends, functional beverages trends, and water enhancer trends.",
        },
        picture: null,
        __v: 0,
        parent: "/food-beverage",
        path: "/food-beverage/beverage",
        sub_categories: [
          {
            _id: {
              $oid: "64ad97c004b8af526096ac40",
            },
            slug: "alcoholic-beverages",
            title: "Alcoholic Beverages",
            id: 5000002,
            parent: "/food-beverage/beverage",
            path: "/food-beverage/beverage/alcoholic-beverages",
            active: true,
            meta: {
              tags: "Alcoholic Beverages",
              keywords: "Alcoholic Beverages",
              description: "Alcoholic Beverages",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac41",
                },
                slug: "beer",
                title: "Beer",
                id: 5000003,
                parent: "/food-beverage/beverage/alcoholic-beverages",
                path: "/food-beverage/beverage/alcoholic-beverages/beer",
                active: true,
                meta: {
                  tags: "Beer",
                  keywords: "Beer",
                  description: "Beer",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac42",
                    },
                    slug: "black-beer",
                    title: "Black Beer",
                    id: 5000004,
                    parent: "/food-beverage/beverage/alcoholic-beverages/beer",
                    path: "/food-beverage/beverage/alcoholic-beverages/beer/black-beer",
                    active: true,
                    meta: {
                      tags: "Black Beer",
                      keywords: "Black Beer",
                      description: "Black Beer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac43",
                    },
                    slug: "craft-beer",
                    title: "Craft Beer",
                    id: 5000005,
                    parent: "/food-beverage/beverage/alcoholic-beverages/beer",
                    path: "/food-beverage/beverage/alcoholic-beverages/beer/craft-beer",
                    active: true,
                    meta: {
                      tags: "Craft Beer",
                      keywords: "Craft Beer",
                      description: "Craft Beer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac44",
                    },
                    slug: "gluten-free-beer",
                    title: "Gluten Free Beer",
                    id: 5000006,
                    parent: "/food-beverage/beverage/alcoholic-beverages/beer",
                    path: "/food-beverage/beverage/alcoholic-beverages/beer/gluten-free-beer",
                    active: true,
                    meta: {
                      tags: "Gluten Free Beer",
                      keywords: "Gluten Free Beer",
                      description: "Gluten Free Beer",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac45",
                },
                slug: "brandy",
                title: "Brandy",
                id: 5000007,
                parent: "/food-beverage/beverage/alcoholic-beverages",
                path: "/food-beverage/beverage/alcoholic-beverages/brandy",
                active: true,
                meta: {
                  tags: "Brandy",
                  keywords: "Brandy",
                  description: "Brandy",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac46",
                },
                slug: "cider",
                title: "Cider",
                id: 5000008,
                parent: "/food-beverage/beverage/alcoholic-beverages",
                path: "/food-beverage/beverage/alcoholic-beverages/cider",
                active: true,
                meta: {
                  tags: "Cider",
                  keywords: "Cider",
                  description: "Cider",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac47",
                },
                slug: "premium-alcoholic-beverages",
                title: "Premium Alcoholic Beverages",
                id: 5000009,
                parent: "/food-beverage/beverage/alcoholic-beverages",
                path: "/food-beverage/beverage/alcoholic-beverages/premium-alcoholic-beverages",
                active: true,
                meta: {
                  tags: "Premium Alcoholic Beverages",
                  keywords: "Premium Alcoholic Beverages",
                  description: "Premium Alcoholic Beverages",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac48",
                },
                slug: "spirits",
                title: "Spirits",
                id: 5000010,
                parent: "/food-beverage/beverage/alcoholic-beverages",
                path: "/food-beverage/beverage/alcoholic-beverages/spirits",
                active: true,
                meta: {
                  tags: "Spirits",
                  keywords: "Spirits",
                  description: "Spirits",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac49",
                    },
                    slug: "craft-spirits",
                    title: "Craft Spirits",
                    id: 5000011,
                    parent:
                      "/food-beverage/beverage/alcoholic-beverages/spirits",
                    path: "/food-beverage/beverage/alcoholic-beverages/spirits/craft-spirits",
                    active: true,
                    meta: {
                      tags: "Craft Spirits",
                      keywords: "Craft Spirits",
                      description: "Craft Spirits",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac4a",
                    },
                    slug: "gin",
                    title: "Gin",
                    id: 5000012,
                    parent:
                      "/food-beverage/beverage/alcoholic-beverages/spirits",
                    path: "/food-beverage/beverage/alcoholic-beverages/spirits/gin",
                    active: true,
                    meta: {
                      tags: "Gin",
                      keywords: "Gin",
                      description: "Gin",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac4b",
                    },
                    slug: "mezcal",
                    title: "Mezcal",
                    id: 5000013,
                    parent:
                      "/food-beverage/beverage/alcoholic-beverages/spirits",
                    path: "/food-beverage/beverage/alcoholic-beverages/spirits/mezcal",
                    active: true,
                    meta: {
                      tags: "Mezcal",
                      keywords: "Mezcal",
                      description: "Mezcal",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac4c",
                    },
                    slug: "rum",
                    title: "Rum",
                    id: 5000014,
                    parent:
                      "/food-beverage/beverage/alcoholic-beverages/spirits",
                    path: "/food-beverage/beverage/alcoholic-beverages/spirits/rum",
                    active: true,
                    meta: {
                      tags: "Rum",
                      keywords: "Rum",
                      description: "Rum",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac4d",
                    },
                    slug: "tequilla",
                    title: "Tequilla",
                    id: 5000015,
                    parent:
                      "/food-beverage/beverage/alcoholic-beverages/spirits",
                    path: "/food-beverage/beverage/alcoholic-beverages/spirits/tequilla",
                    active: true,
                    meta: {
                      tags: "Tequilla",
                      keywords: "Tequilla",
                      description: "Tequilla",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac4e",
                    },
                    slug: "vodka",
                    title: "Vodka",
                    id: 5000016,
                    parent:
                      "/food-beverage/beverage/alcoholic-beverages/spirits",
                    path: "/food-beverage/beverage/alcoholic-beverages/spirits/vodka",
                    active: true,
                    meta: {
                      tags: "Vodka",
                      keywords: "Vodka",
                      description: "Vodka",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ac4f",
                        },
                        slug: "craft-vodka",
                        title: "Craft Vodka",
                        id: 5000017,
                        parent:
                          "/food-beverage/beverage/alcoholic-beverages/spirits/vodka",
                        path: "/food-beverage/beverage/alcoholic-beverages/spirits/vodka/craft-vodka",
                        active: true,
                        meta: {
                          tags: "Craft Vodka",
                          keywords: "Craft Vodka",
                          description: "Craft Vodka",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac50",
                    },
                    slug: "whiskey",
                    title: "Whiskey",
                    id: 5000018,
                    parent:
                      "/food-beverage/beverage/alcoholic-beverages/spirits",
                    path: "/food-beverage/beverage/alcoholic-beverages/spirits/whiskey",
                    active: true,
                    meta: {
                      tags: "Whiskey",
                      keywords: "Whiskey",
                      description: "Whiskey",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac51",
                },
                slug: "wine",
                title: "Wine",
                id: 5000019,
                parent: "/food-beverage/beverage/alcoholic-beverages",
                path: "/food-beverage/beverage/alcoholic-beverages/wine",
                active: true,
                meta: {
                  tags: "Wine",
                  keywords: "Wine",
                  description: "Wine",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac52",
                    },
                    slug: "champagne",
                    title: "Champagne",
                    id: 5000020,
                    parent: "/food-beverage/beverage/alcoholic-beverages/wine",
                    path: "/food-beverage/beverage/alcoholic-beverages/wine/champagne",
                    active: true,
                    meta: {
                      tags: "Champagne",
                      keywords: "Champagne",
                      description: "Champagne",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac53",
                    },
                    slug: "fortified-wine",
                    title: "Fortified Wine",
                    id: 5000021,
                    parent: "/food-beverage/beverage/alcoholic-beverages/wine",
                    path: "/food-beverage/beverage/alcoholic-beverages/wine/fortified-wine",
                    active: true,
                    meta: {
                      tags: "Fortified Wine",
                      keywords: "Fortified Wine",
                      description: "Fortified Wine",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ac54",
            },
            slug: "hot-drinks",
            title: "Hot Drinks",
            id: 5000022,
            parent: "/food-beverage/beverage",
            path: "/food-beverage/beverage/hot-drinks",
            active: true,
            meta: {
              tags: "Hot Drinks",
              keywords: "Hot Drinks",
              description: "Hot Drinks",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac55",
                },
                slug: "coffee",
                title: "Coffee",
                id: 5000023,
                parent: "/food-beverage/beverage/hot-drinks",
                path: "/food-beverage/beverage/hot-drinks/coffee",
                active: true,
                meta: {
                  tags: "Coffee",
                  keywords: "Coffee",
                  description: "Coffee",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac56",
                    },
                    slug: "freeze-dried-coffee",
                    title: "Freeze Dried Coffee",
                    id: 5000024,
                    parent: "/food-beverage/beverage/hot-drinks/coffee",
                    path: "/food-beverage/beverage/hot-drinks/coffee/freeze-dried-coffee",
                    active: true,
                    meta: {
                      tags: "Freeze Dried Coffee",
                      keywords: "Freeze Dried Coffee",
                      description: "Freeze Dried Coffee",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac57",
                    },
                    slug: "functional-coffee",
                    title: "Functional Coffee",
                    id: 5000025,
                    parent: "/food-beverage/beverage/hot-drinks/coffee",
                    path: "/food-beverage/beverage/hot-drinks/coffee/functional-coffee",
                    active: true,
                    meta: {
                      tags: "Functional Coffee",
                      keywords: "Functional Coffee",
                      description: "Functional Coffee",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac58",
                    },
                    slug: "organic-coffee",
                    title: "Organic Coffee",
                    id: 5000026,
                    parent: "/food-beverage/beverage/hot-drinks/coffee",
                    path: "/food-beverage/beverage/hot-drinks/coffee/organic-coffee",
                    active: true,
                    meta: {
                      tags: "Organic Coffee",
                      keywords: "Organic Coffee",
                      description: "Organic Coffee",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac59",
                    },
                    slug: "out-of-home-coffee",
                    title: "Out of Home Coffee",
                    id: 5000027,
                    parent: "/food-beverage/beverage/hot-drinks/coffee",
                    path: "/food-beverage/beverage/hot-drinks/coffee/out-of-home-coffee",
                    active: true,
                    meta: {
                      tags: "Out of Home Coffee",
                      keywords: "Out of Home Coffee",
                      description: "Out of Home Coffee",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac5a",
                    },
                    slug: "ready-to-drink-coffee",
                    title: "Ready to Drink Coffee",
                    id: 5000028,
                    parent: "/food-beverage/beverage/hot-drinks/coffee",
                    path: "/food-beverage/beverage/hot-drinks/coffee/ready-to-drink-coffee",
                    active: true,
                    meta: {
                      tags: "Ready to Drink Coffee",
                      keywords: "Ready to Drink Coffee",
                      description: "Ready to Drink Coffee",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ac5b",
                        },
                        slug: "coffee-pods-and-capsules",
                        title: "Coffee Pods and Capsules",
                        id: 5000029,
                        parent:
                          "/food-beverage/beverage/hot-drinks/coffee/ready-to-drink-coffee",
                        path: "/food-beverage/beverage/hot-drinks/coffee/ready-to-drink-coffee/coffee-pods-and-capsules",
                        active: true,
                        meta: {
                          tags: "Coffee Pods and Capsules",
                          keywords: "Coffee Pods and Capsules",
                          description: "Coffee Pods and Capsules",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ac5c",
                        },
                        slug: "instant-coffee",
                        title: "Instant Coffee",
                        id: 5000030,
                        parent:
                          "/food-beverage/beverage/hot-drinks/coffee/ready-to-drink-coffee",
                        path: "/food-beverage/beverage/hot-drinks/coffee/ready-to-drink-coffee/instant-coffee",
                        active: true,
                        meta: {
                          tags: "Instant Coffee",
                          keywords: "Instant Coffee",
                          description: "Instant Coffee",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac5d",
                    },
                    slug: "single-origin-coffee",
                    title: "Single-Origin Coffee",
                    id: 5000031,
                    parent: "/food-beverage/beverage/hot-drinks/coffee",
                    path: "/food-beverage/beverage/hot-drinks/coffee/single-origin-coffee",
                    active: true,
                    meta: {
                      tags: "Single-Origin Coffee",
                      keywords: "Single-Origin Coffee",
                      description: "Single-Origin Coffee",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac5e",
                },
                slug: "tea",
                title: "Tea",
                id: 5000032,
                parent: "/food-beverage/beverage/hot-drinks",
                path: "/food-beverage/beverage/hot-drinks/tea",
                active: true,
                meta: {
                  tags: "Tea",
                  keywords: "Tea",
                  description: "Tea",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac5f",
                    },
                    slug: "matcha",
                    title: "Matcha",
                    id: 5000033,
                    parent: "/food-beverage/beverage/hot-drinks/tea",
                    path: "/food-beverage/beverage/hot-drinks/tea/matcha",
                    active: true,
                    meta: {
                      tags: "Matcha",
                      keywords: "Matcha",
                      description: "Matcha",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac60",
                    },
                    slug: "organic-tea",
                    title: "Organic Tea",
                    id: 5000034,
                    parent: "/food-beverage/beverage/hot-drinks/tea",
                    path: "/food-beverage/beverage/hot-drinks/tea/organic-tea",
                    active: true,
                    meta: {
                      tags: "Organic Tea",
                      keywords: "Organic Tea",
                      description: "Organic Tea",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac61",
                    },
                    slug: "ready-to-drink-tea",
                    title: "Ready to Drink Tea",
                    id: 5000035,
                    parent: "/food-beverage/beverage/hot-drinks/tea",
                    path: "/food-beverage/beverage/hot-drinks/tea/ready-to-drink-tea",
                    active: true,
                    meta: {
                      tags: "Ready to Drink Tea",
                      keywords: "Ready to Drink Tea",
                      description: "Ready to Drink Tea",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac62",
                    },
                    slug: "single-origin",
                    title: "Single Origin",
                    id: 5000036,
                    parent: "/food-beverage/beverage/hot-drinks/tea",
                    path: "/food-beverage/beverage/hot-drinks/tea/single-origin",
                    active: true,
                    meta: {
                      tags: "Single Origin",
                      keywords: "Single Origin",
                      description: "Single Origin",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ac63",
            },
            slug: "non-alcoholic-beverages",
            title: "Non Alcoholic Beverages",
            id: 5000037,
            parent: "/food-beverage/beverage",
            path: "/food-beverage/beverage/non-alcoholic-beverages",
            active: true,
            meta: {
              tags: "Non Alcoholic Beverages",
              keywords: "Non Alcoholic Beverages",
              description: "Non Alcoholic Beverages",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac64",
                },
                slug: "aloe-vera-drinks",
                title: "Aloe Vera Drinks",
                id: 5000038,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/aloe-vera-drinks",
                active: true,
                meta: {
                  tags: "Aloe Vera Drinks",
                  keywords: "Aloe Vera Drinks",
                  description: "Aloe Vera Drinks",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac65",
                },
                slug: "bottled-water",
                title: "Bottled Water",
                id: 5000039,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/bottled-water",
                active: true,
                meta: {
                  tags: "Bottled Water",
                  keywords: "Bottled Water",
                  description: "Bottled Water",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac66",
                },
                slug: "carbonated-beverages",
                title: "Carbonated Beverages",
                id: 5000040,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/carbonated-beverages",
                active: true,
                meta: {
                  tags: "Carbonated Beverages",
                  keywords: "Carbonated Beverages",
                  description: "Carbonated Beverages",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac67",
                },
                slug: "coconut-water",
                title: "Coconut Water",
                id: 5000041,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/coconut-water",
                active: true,
                meta: {
                  tags: "Coconut Water",
                  keywords: "Coconut Water",
                  description: "Coconut Water",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac68",
                },
                slug: "concentrates",
                title: "Concentrates",
                id: 5000042,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/concentrates",
                active: true,
                meta: {
                  tags: "Concentrates",
                  keywords: "Concentrates",
                  description: "Concentrates",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac69",
                    },
                    slug: "concentrated-syrups",
                    title: "Concentrated Syrups",
                    id: 5000043,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/concentrates",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/concentrates/concentrated-syrups",
                    active: true,
                    meta: {
                      tags: "Concentrated Syrups",
                      keywords: "Concentrated Syrups",
                      description: "Concentrated Syrups",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac6a",
                },
                slug: "dairy-alternative-beverages",
                title: "Dairy Alternative Beverages",
                id: 5000044,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/dairy-alternative-beverages",
                active: true,
                meta: {
                  tags: "Dairy Alternative Beverages",
                  keywords: "Dairy Alternative Beverages",
                  description: "Dairy Alternative Beverages",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac6b",
                    },
                    slug: "malted-milk",
                    title: "Malted Milk",
                    id: 5000045,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/dairy-alternative-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/dairy-alternative-beverages/malted-milk",
                    active: true,
                    meta: {
                      tags: "Malted Milk",
                      keywords: "Malted Milk",
                      description: "Malted Milk",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac6c",
                    },
                    slug: "soy-beverages",
                    title: "Soy Beverages",
                    id: 5000046,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/dairy-alternative-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/dairy-alternative-beverages/soy-beverages",
                    active: true,
                    meta: {
                      tags: "Soy Beverages",
                      keywords: "Soy Beverages",
                      description: "Soy Beverages",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ac6d",
                        },
                        slug: "soy-milk",
                        title: "Soy Milk",
                        id: 5000047,
                        parent:
                          "/food-beverage/beverage/non-alcoholic-beverages/dairy-alternative-beverages/soy-beverages",
                        path: "/food-beverage/beverage/non-alcoholic-beverages/dairy-alternative-beverages/soy-beverages/soy-milk",
                        active: true,
                        meta: {
                          tags: "Soy Milk",
                          keywords: "Soy Milk",
                          description: "Soy Milk",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac6e",
                },
                slug: "dairy-beverages",
                title: "Dairy Beverages",
                id: 5000048,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/dairy-beverages",
                active: true,
                meta: {
                  tags: "Dairy Beverages",
                  keywords: "Dairy Beverages",
                  description: "Dairy Beverages",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac6f",
                    },
                    slug: "flavored-milk",
                    title: "Flavored Milk",
                    id: 5000049,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/dairy-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/dairy-beverages/flavored-milk",
                    active: true,
                    meta: {
                      tags: "Flavored Milk",
                      keywords: "Flavored Milk",
                      description: "Flavored Milk",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ac70",
                        },
                        slug: "chocolate-milk",
                        title: "Chocolate Milk",
                        id: 5000050,
                        parent:
                          "/food-beverage/beverage/non-alcoholic-beverages/dairy-beverages/flavored-milk",
                        path: "/food-beverage/beverage/non-alcoholic-beverages/dairy-beverages/flavored-milk/chocolate-milk",
                        active: true,
                        meta: {
                          tags: "Chocolate Milk",
                          keywords: "Chocolate Milk",
                          description: "Chocolate Milk",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac71",
                    },
                    slug: "sour-milk-drinks",
                    title: "Sour Milk Drinks",
                    id: 5000051,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/dairy-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/dairy-beverages/sour-milk-drinks",
                    active: true,
                    meta: {
                      tags: "Sour Milk Drinks",
                      keywords: "Sour Milk Drinks",
                      description: "Sour Milk Drinks",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac72",
                },
                slug: "fermented-drinks",
                title: "Fermented Drinks",
                id: 5000052,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/fermented-drinks",
                active: true,
                meta: {
                  tags: "Fermented Drinks",
                  keywords: "Fermented Drinks",
                  description: "Fermented Drinks",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac73",
                },
                slug: "functional-beverages",
                title: "Functional Beverages",
                id: 5000053,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages",
                active: true,
                meta: {
                  tags: "Functional Beverages",
                  keywords: "Functional Beverages",
                  description: "Functional Beverages",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac74",
                    },
                    slug: "antioxidant-beverages",
                    title: "Antioxidant Beverages",
                    id: 5000054,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/antioxidant-beverages",
                    active: true,
                    meta: {
                      tags: "Antioxidant Beverages",
                      keywords: "Antioxidant Beverages",
                      description: "Antioxidant Beverages",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac75",
                    },
                    slug: "energy-drinks",
                    title: "Energy Drinks",
                    id: 5000055,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/energy-drinks",
                    active: true,
                    meta: {
                      tags: "Energy Drinks",
                      keywords: "Energy Drinks",
                      description: "Energy Drinks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac76",
                    },
                    slug: "functional-water",
                    title: "Functional Water",
                    id: 5000056,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/functional-water",
                    active: true,
                    meta: {
                      tags: "Functional Water",
                      keywords: "Functional Water",
                      description: "Functional Water",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac77",
                    },
                    slug: "health-drinks",
                    title: "Health Drinks",
                    id: 5000057,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/health-drinks",
                    active: true,
                    meta: {
                      tags: "Health Drinks",
                      keywords: "Health Drinks",
                      description: "Health Drinks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac78",
                    },
                    slug: "isotonic-drinks",
                    title: "Isotonic Drinks",
                    id: 5000058,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/isotonic-drinks",
                    active: true,
                    meta: {
                      tags: "Isotonic Drinks",
                      keywords: "Isotonic Drinks",
                      description: "Isotonic Drinks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac79",
                    },
                    slug: "protein-beverages",
                    title: "Protein Beverages",
                    id: 5000059,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/protein-beverages",
                    active: true,
                    meta: {
                      tags: "Protein Beverages",
                      keywords: "Protein Beverages",
                      description: "Protein Beverages",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ac7a",
                        },
                        slug: "protein-water",
                        title: "Protein Water",
                        id: 5000060,
                        parent:
                          "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/protein-beverages",
                        path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/protein-beverages/protein-water",
                        active: true,
                        meta: {
                          tags: "Protein Water",
                          keywords: "Protein Water",
                          description: "Protein Water",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac7b",
                    },
                    slug: "sports-drinks",
                    title: "Sports Drinks",
                    id: 5000061,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/sports-drinks",
                    active: true,
                    meta: {
                      tags: "Sports Drinks",
                      keywords: "Sports Drinks",
                      description: "Sports Drinks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac7c",
                    },
                    slug: "water-enhancers",
                    title: "Water Enhancers",
                    id: 5000062,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/functional-beverages/water-enhancers",
                    active: true,
                    meta: {
                      tags: "Water Enhancers",
                      keywords: "Water Enhancers",
                      description: "Water Enhancers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac7d",
                },
                slug: "iced-tea",
                title: "Iced Tea",
                id: 5000063,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/iced-tea",
                active: true,
                meta: {
                  tags: "Iced Tea",
                  keywords: "Iced Tea",
                  description: "Iced Tea",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac7e",
                },
                slug: "juices",
                title: "Juices",
                id: 5000064,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/juices",
                active: true,
                meta: {
                  tags: "Juices",
                  keywords: "Juices",
                  description: "Juices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac7f",
                    },
                    slug: "cold-pressed-juice",
                    title: "Cold-Pressed Juice",
                    id: 5000065,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/juices",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/juices/cold-pressed-juice",
                    active: true,
                    meta: {
                      tags: "Cold-Pressed Juice",
                      keywords: "Cold-Pressed Juice",
                      description: "Cold-Pressed Juice",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac80",
                    },
                    slug: "vegetable-juice",
                    title: "Vegetable Juice",
                    id: 5000066,
                    parent:
                      "/food-beverage/beverage/non-alcoholic-beverages/juices",
                    path: "/food-beverage/beverage/non-alcoholic-beverages/juices/vegetable-juice",
                    active: true,
                    meta: {
                      tags: "Vegetable Juice",
                      keywords: "Vegetable Juice",
                      description: "Vegetable Juice",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac81",
                },
                slug: "kombucha",
                title: "Kombucha",
                id: 5000067,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/kombucha",
                active: true,
                meta: {
                  tags: "Kombucha",
                  keywords: "Kombucha",
                  description: "Kombucha",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac82",
                },
                slug: "maple-water",
                title: "Maple Water",
                id: 5000068,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/maple-water",
                active: true,
                meta: {
                  tags: "Maple Water",
                  keywords: "Maple Water",
                  description: "Maple Water",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac83",
                },
                slug: "meal-replacement-drink",
                title: "Meal Replacement Drink",
                id: 5000069,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/meal-replacement-drink",
                active: true,
                meta: {
                  tags: "Meal Replacement Drink",
                  keywords: "Meal Replacement Drink",
                  description: "Meal Replacement Drink",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac84",
                },
                slug: "organic-beverages",
                title: "Organic Beverages",
                id: 5000070,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/organic-beverages",
                active: true,
                meta: {
                  tags: "Organic Beverages",
                  keywords: "Organic Beverages",
                  description: "Organic Beverages",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac85",
                },
                slug: "probiotic-drinks",
                title: "Probiotic Drinks",
                id: 5000071,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/probiotic-drinks",
                active: true,
                meta: {
                  tags: "Probiotic Drinks",
                  keywords: "Probiotic Drinks",
                  description: "Probiotic Drinks",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac86",
                },
                slug: "ready-to-drink-beverages",
                title: "Ready to Drink Beverages",
                id: 5000072,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/ready-to-drink-beverages",
                active: true,
                meta: {
                  tags: "Ready to Drink Beverages",
                  keywords: "Ready to Drink Beverages",
                  description: "Ready to Drink Beverages",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac87",
                },
                slug: "smoothies",
                title: "Smoothies",
                id: 5000073,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/smoothies",
                active: true,
                meta: {
                  tags: "Smoothies",
                  keywords: "Smoothies",
                  description: "Smoothies",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac88",
                },
                slug: "soft-drink-concentrates",
                title: "Soft Drink Concentrates",
                id: 5000074,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/soft-drink-concentrates",
                active: true,
                meta: {
                  tags: "Soft Drink Concentrates",
                  keywords: "Soft Drink Concentrates",
                  description: "Soft Drink Concentrates",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac89",
                },
                slug: "tonic-water",
                title: "Tonic Water",
                id: 5000075,
                parent: "/food-beverage/beverage/non-alcoholic-beverages",
                path: "/food-beverage/beverage/non-alcoholic-beverages/tonic-water",
                active: true,
                meta: {
                  tags: "Tonic Water",
                  keywords: "Tonic Water",
                  description: "Tonic Water",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b194",
        },
        id: 62129,
        title: "Food",
        slug: "food",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Food Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Food Market Research Reports, food industry report, food and beverage market, food and beverage industry report, food market report, food and beverage market research reports, beverage market research",
          tags: "Food Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Statistics for the 2023 Food market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Food Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          short_description:
            "Statistics for the 2023 Food market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Food Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        picture: null,
        __v: 0,
        parent: "/food-beverage",
        path: "/food-beverage/food",
        sub_categories: [
          {
            _id: {
              $oid: "64ad97c004b8af526096ac8a",
            },
            slug: "f-and-b-cold-chain",
            title: "F&B Cold Chain",
            id: 5000077,
            parent: "/food-beverage/food",
            path: "/food-beverage/food/f-and-b-cold-chain",
            active: true,
            meta: {
              tags: "F&B Cold Chain",
              keywords: "F&B Cold Chain",
              description: "F&B Cold Chain",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ac8b",
            },
            slug: "food-intolerance-products",
            title: "Food Intolerance Products",
            id: 5000078,
            parent: "/food-beverage/food",
            path: "/food-beverage/food/food-intolerance-products",
            active: true,
            meta: {
              tags: "Food Intolerance Products",
              keywords: "Food Intolerance Products",
              description: "Food Intolerance Products",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ac8c",
            },
            slug: "food-processing-equipment",
            title: "Food Processing Equipment",
            id: 5000079,
            parent: "/food-beverage/food",
            path: "/food-beverage/food/food-processing-equipment",
            active: true,
            meta: {
              tags: "Food Processing Equipment",
              keywords: "Food Processing Equipment",
              description: "Food Processing Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac8d",
                },
                slug: "beverage-processing-equipment",
                title: "Beverage Processing Equipment",
                id: 5000080,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/beverage-processing-equipment",
                active: true,
                meta: {
                  tags: "Beverage Processing Equipment",
                  keywords: "Beverage Processing Equipment",
                  description: "Beverage Processing Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac8e",
                    },
                    slug: "coffee-roasters",
                    title: "Coffee Roasters",
                    id: 5000081,
                    parent:
                      "/food-beverage/food/food-processing-equipment/beverage-processing-equipment",
                    path: "/food-beverage/food/food-processing-equipment/beverage-processing-equipment/coffee-roasters",
                    active: true,
                    meta: {
                      tags: "Coffee Roasters",
                      keywords: "Coffee Roasters",
                      description: "Coffee Roasters",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac8f",
                },
                slug: "confectionery-processing-equipment",
                title: "Confectionery Processing Equipment",
                id: 5000082,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/confectionery-processing-equipment",
                active: true,
                meta: {
                  tags: "Confectionery Processing Equipment",
                  keywords: "Confectionery Processing Equipment",
                  description: "Confectionery Processing Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac90",
                },
                slug: "egg-processing-equipment",
                title: "Egg Processing Equipment",
                id: 5000083,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/egg-processing-equipment",
                active: true,
                meta: {
                  tags: "Egg Processing Equipment",
                  keywords: "Egg Processing Equipment",
                  description: "Egg Processing Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac91",
                },
                slug: "food-3d-printing",
                title: "Food 3D Printing",
                id: 5000084,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/food-3d-printing",
                active: true,
                meta: {
                  tags: "Food 3D Printing",
                  keywords: "Food 3D Printing",
                  description: "Food 3D Printing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac92",
                },
                slug: "food-encapsulation",
                title: "Food Encapsulation",
                id: 5000085,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/food-encapsulation",
                active: true,
                meta: {
                  tags: "Food Encapsulation",
                  keywords: "Food Encapsulation",
                  description: "Food Encapsulation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac93",
                },
                slug: "fruits-and-vegetables-processing-equipment",
                title: "Fruits and Vegetables Processing Equipment",
                id: 5000086,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/fruits-and-vegetables-processing-equipment",
                active: true,
                meta: {
                  tags: "Fruits and Vegetables Processing Equipment",
                  keywords: "Fruits and Vegetables Processing Equipment",
                  description: "Fruits and Vegetables Processing Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac94",
                },
                slug: "carbonated-beverage-processing-machinery",
                title: "Carbonated Beverage Processing Machinery",
                id: 5000087,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/carbonated-beverage-processing-machinery",
                active: true,
                meta: {
                  tags: "Carbonated Beverage Processing Machinery",
                  keywords: "Carbonated Beverage Processing Machinery",
                  description: "Carbonated Beverage Processing Machinery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac95",
                },
                slug: "dairy-processing-machinery",
                title: "Dairy Processing Machinery",
                id: 5000088,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/dairy-processing-machinery",
                active: true,
                meta: {
                  tags: "Dairy Processing Machinery",
                  keywords: "Dairy Processing Machinery",
                  description: "Dairy Processing Machinery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac96",
                },
                slug: "non-thermal-processing-machinery",
                title: "Non Thermal Processing Machinery",
                id: 5000089,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/non-thermal-processing-machinery",
                active: true,
                meta: {
                  tags: "Non Thermal Processing Machinery",
                  keywords: "Non Thermal Processing Machinery",
                  description: "Non Thermal Processing Machinery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac97",
                },
                slug: "slaughtering-equipment",
                title: "Slaughtering Equipment",
                id: 5000090,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/slaughtering-equipment",
                active: true,
                meta: {
                  tags: "Slaughtering Equipment",
                  keywords: "Slaughtering Equipment",
                  description: "Slaughtering Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac98",
                },
                slug: "spray-drying-equipment",
                title: "Spray Drying Equipment",
                id: 5000091,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/spray-drying-equipment",
                active: true,
                meta: {
                  tags: "Spray Drying Equipment",
                  keywords: "Spray Drying Equipment",
                  description: "Spray Drying Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac99",
                },
                slug: "uht-processing-machinery",
                title: "UHT Processing Machinery",
                id: 5000092,
                parent: "/food-beverage/food/food-processing-equipment",
                path: "/food-beverage/food/food-processing-equipment/uht-processing-machinery",
                active: true,
                meta: {
                  tags: "UHT Processing Machinery",
                  keywords: "UHT Processing Machinery",
                  description: "UHT Processing Machinery",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ac9a",
            },
            slug: "food-safety-testing-and-labelling",
            title: "Food Safety Testing and Labelling",
            id: 5000093,
            parent: "/food-beverage/food",
            path: "/food-beverage/food/food-safety-testing-and-labelling",
            active: true,
            meta: {
              tags: "Food Safety Testing and Labelling",
              keywords: "Food Safety Testing and Labelling",
              description: "Food Safety Testing and Labelling",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac9b",
                },
                slug: "food-labelling",
                title: "Food Labelling",
                id: 5000094,
                parent: "/food-beverage/food/food-safety-testing-and-labelling",
                path: "/food-beverage/food/food-safety-testing-and-labelling/food-labelling",
                active: true,
                meta: {
                  tags: "Food Labelling",
                  keywords: "Food Labelling",
                  description: "Food Labelling",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac9c",
                    },
                    slug: "food-certification",
                    title: "Food Certification",
                    id: 5000095,
                    parent:
                      "/food-beverage/food/food-safety-testing-and-labelling/food-labelling",
                    path: "/food-beverage/food/food-safety-testing-and-labelling/food-labelling/food-certification",
                    active: true,
                    meta: {
                      tags: "Food Certification",
                      keywords: "Food Certification",
                      description: "Food Certification",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ac9d",
                },
                slug: "food-safety-testing",
                title: "Food Safety Testing",
                id: 5000096,
                parent: "/food-beverage/food/food-safety-testing-and-labelling",
                path: "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing",
                active: true,
                meta: {
                  tags: "Food Safety Testing",
                  keywords: "Food Safety Testing",
                  description: "Food Safety Testing",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac9e",
                    },
                    slug: "dairy-testing",
                    title: "Dairy Testing",
                    id: 5000097,
                    parent:
                      "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing",
                    path: "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing/dairy-testing",
                    active: true,
                    meta: {
                      tags: "Dairy Testing",
                      keywords: "Dairy Testing",
                      description: "Dairy Testing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ac9f",
                    },
                    slug: "food-allergen-testing",
                    title: "Food Allergen Testing",
                    id: 5000098,
                    parent:
                      "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing",
                    path: "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing/food-allergen-testing",
                    active: true,
                    meta: {
                      tags: "Food Allergen Testing",
                      keywords: "Food Allergen Testing",
                      description: "Food Allergen Testing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aca0",
                    },
                    slug: "food-authentication-testing",
                    title: "Food Authentication Testing",
                    id: 5000099,
                    parent:
                      "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing",
                    path: "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing/food-authentication-testing",
                    active: true,
                    meta: {
                      tags: "Food Authentication Testing",
                      keywords: "Food Authentication Testing",
                      description: "Food Authentication Testing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aca1",
                    },
                    slug: "food-packaging-testing",
                    title: "Food Packaging Testing",
                    id: 5000100,
                    parent:
                      "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing",
                    path: "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing/food-packaging-testing",
                    active: true,
                    meta: {
                      tags: "Food Packaging Testing",
                      keywords: "Food Packaging Testing",
                      description: "Food Packaging Testing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aca2",
                    },
                    slug: "mycotoxin-testing",
                    title: "Mycotoxin Testing",
                    id: 5000101,
                    parent:
                      "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing",
                    path: "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing/mycotoxin-testing",
                    active: true,
                    meta: {
                      tags: "Mycotoxin Testing",
                      keywords: "Mycotoxin Testing",
                      description: "Mycotoxin Testing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aca3",
                    },
                    slug: "residue-testing",
                    title: "Residue Testing",
                    id: 5000102,
                    parent:
                      "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing",
                    path: "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing/residue-testing",
                    active: true,
                    meta: {
                      tags: "Residue Testing",
                      keywords: "Residue Testing",
                      description: "Residue Testing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aca4",
                    },
                    slug: "speciation-testing",
                    title: "Speciation Testing",
                    id: 5000103,
                    parent:
                      "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing",
                    path: "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing/speciation-testing",
                    active: true,
                    meta: {
                      tags: "Speciation Testing",
                      keywords: "Speciation Testing",
                      description: "Speciation Testing",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096aca5",
                        },
                        slug: "meat-speciation-testing",
                        title: "Meat Speciation Testing",
                        id: 5000104,
                        parent:
                          "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing/speciation-testing",
                        path: "/food-beverage/food/food-safety-testing-and-labelling/food-safety-testing/speciation-testing/meat-speciation-testing",
                        active: true,
                        meta: {
                          tags: "Meat Speciation Testing",
                          keywords: "Meat Speciation Testing",
                          description: "Meat Speciation Testing",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aca6",
                },
                slug: "gmo-testing",
                title: "GMO Testing",
                id: 5000105,
                parent: "/food-beverage/food/food-safety-testing-and-labelling",
                path: "/food-beverage/food/food-safety-testing-and-labelling/gmo-testing",
                active: true,
                meta: {
                  tags: "GMO Testing",
                  keywords: "GMO Testing",
                  description: "GMO Testing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aca7",
                },
                slug: "water-testing",
                title: "Water Testing",
                id: 5000106,
                parent: "/food-beverage/food/food-safety-testing-and-labelling",
                path: "/food-beverage/food/food-safety-testing-and-labelling/water-testing",
                active: true,
                meta: {
                  tags: "Water Testing",
                  keywords: "Water Testing",
                  description: "Water Testing",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aca8",
            },
            slug: "foodservice",
            title: "Foodservice",
            id: 5000107,
            parent: "/food-beverage/food",
            path: "/food-beverage/food/foodservice",
            active: true,
            meta: {
              tags: "Foodservice",
              keywords: "Foodservice",
              description: "Foodservice",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096aca9",
                },
                slug: "cafes-and-bars",
                title: "Cafes & Bars",
                id: 5000108,
                parent: "/food-beverage/food/foodservice",
                path: "/food-beverage/food/foodservice/cafes-and-bars",
                active: true,
                meta: {
                  tags: "Cafes & Bars",
                  keywords: "Cafes & Bars",
                  description: "Cafes & Bars",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acaa",
                },
                slug: "full-service-restaurant",
                title: "Full Service Restaurant",
                id: 5000109,
                parent: "/food-beverage/food/foodservice",
                path: "/food-beverage/food/foodservice/full-service-restaurant",
                active: true,
                meta: {
                  tags: "Full Service Restaurant",
                  keywords: "Full Service Restaurant",
                  description: "Full Service Restaurant",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acab",
                },
                slug: "hri",
                title: "HRI",
                id: 5000110,
                parent: "/food-beverage/food/foodservice",
                path: "/food-beverage/food/foodservice/hri",
                active: true,
                meta: {
                  tags: "HRI",
                  keywords: "HRI",
                  description: "HRI",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acac",
                },
                slug: "online-food-delivery",
                title: "Online Food Delivery",
                id: 5000111,
                parent: "/food-beverage/food/foodservice",
                path: "/food-beverage/food/foodservice/online-food-delivery",
                active: true,
                meta: {
                  tags: "Online Food Delivery",
                  keywords: "Online Food Delivery",
                  description: "Online Food Delivery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acad",
                },
                slug: "pizza-food-service",
                title: "Pizza Food service",
                id: 5000112,
                parent: "/food-beverage/food/foodservice",
                path: "/food-beverage/food/foodservice/pizza-food-service",
                active: true,
                meta: {
                  tags: "Pizza Food service",
                  keywords: "Pizza Food service",
                  description: "Pizza Food service",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acae",
                },
                slug: "quick-service-restaurant",
                title: "Quick Service Restaurant",
                id: 5000113,
                parent: "/food-beverage/food/foodservice",
                path: "/food-beverage/food/foodservice/quick-service-restaurant",
                active: true,
                meta: {
                  tags: "Quick Service Restaurant",
                  keywords: "Quick Service Restaurant",
                  description: "Quick Service Restaurant",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096acaf",
            },
            slug: "processed-food",
            title: "Processed Food",
            id: 5000114,
            parent: "/food-beverage/food",
            path: "/food-beverage/food/processed-food",
            active: true,
            meta: {
              tags: "Processed Food",
              keywords: "Processed Food",
              description: "Processed Food",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096acb0",
                },
                slug: "algae-products",
                title: "Algae Products",
                id: 5000115,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/algae-products",
                active: true,
                meta: {
                  tags: "Algae Products",
                  keywords: "Algae Products",
                  description: "Algae Products",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acb1",
                },
                slug: "animal-based-products",
                title: "Animal Based Products",
                id: 5000116,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/animal-based-products",
                active: true,
                meta: {
                  tags: "Animal Based Products",
                  keywords: "Animal Based Products",
                  description: "Animal Based Products",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acb2",
                },
                slug: "baby-food",
                title: "Baby Food",
                id: 5000117,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/baby-food",
                active: true,
                meta: {
                  tags: "Baby Food",
                  keywords: "Baby Food",
                  description: "Baby Food",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acb3",
                    },
                    slug: "infant-formula",
                    title: "Infant Formula",
                    id: 5000118,
                    parent: "/food-beverage/food/processed-food/baby-food",
                    path: "/food-beverage/food/processed-food/baby-food/infant-formula",
                    active: true,
                    meta: {
                      tags: "Infant Formula",
                      keywords: "Infant Formula",
                      description: "Infant Formula",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acb4",
                        },
                        slug: "lactose-free-infant-formula",
                        title: "Lactose Free Infant Formula",
                        id: 5000119,
                        parent:
                          "/food-beverage/food/processed-food/baby-food/infant-formula",
                        path: "/food-beverage/food/processed-food/baby-food/infant-formula/lactose-free-infant-formula",
                        active: true,
                        meta: {
                          tags: "Lactose Free Infant Formula",
                          keywords: "Lactose Free Infant Formula",
                          description: "Lactose Free Infant Formula",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acb5",
                    },
                    slug: "organic-baby-food",
                    title: "Organic Baby Food",
                    id: 5000120,
                    parent: "/food-beverage/food/processed-food/baby-food",
                    path: "/food-beverage/food/processed-food/baby-food/organic-baby-food",
                    active: true,
                    meta: {
                      tags: "Organic Baby Food",
                      keywords: "Organic Baby Food",
                      description: "Organic Baby Food",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acb6",
                },
                slug: "baked-foods-and-cereals",
                title: "Baked Foods and Cereals",
                id: 5000121,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/baked-foods-and-cereals",
                active: true,
                meta: {
                  tags: "Baked Foods and Cereals",
                  keywords: "Baked Foods and Cereals",
                  description: "Baked Foods and Cereals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acb7",
                    },
                    slug: "bakery",
                    title: "Bakery",
                    id: 5000122,
                    parent:
                      "/food-beverage/food/processed-food/baked-foods-and-cereals",
                    path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery",
                    active: true,
                    meta: {
                      tags: "Bakery",
                      keywords: "Bakery",
                      description: "Bakery",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acb8",
                        },
                        slug: "biscuits",
                        title: "Biscuits",
                        id: 5000123,
                        parent:
                          "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery",
                        path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/biscuits",
                        active: true,
                        meta: {
                          tags: "Biscuits",
                          keywords: "Biscuits",
                          description: "Biscuits",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096acb9",
                            },
                            slug: "crackers",
                            title: "Crackers",
                            id: 5000124,
                            parent:
                              "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/biscuits",
                            path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/biscuits/crackers",
                            active: true,
                            meta: {
                              tags: "Crackers",
                              keywords: "Crackers",
                              description: "Crackers",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096acba",
                            },
                            slug: "sweet-biscuits",
                            title: "Sweet Biscuits",
                            id: 5000125,
                            parent:
                              "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/biscuits",
                            path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/biscuits/sweet-biscuits",
                            active: true,
                            meta: {
                              tags: "Sweet Biscuits",
                              keywords: "Sweet Biscuits",
                              description: "Sweet Biscuits",
                            },
                            sub_categories: [
                              {
                                _id: {
                                  $oid: "64ad97c004b8af526096acbb",
                                },
                                slug: "cookies",
                                title: "Cookies",
                                id: 5000126,
                                parent:
                                  "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/biscuits/sweet-biscuits",
                                path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/biscuits/sweet-biscuits/cookies",
                                active: true,
                                meta: {
                                  tags: "Cookies",
                                  keywords: "Cookies",
                                  description: "Cookies",
                                },
                                sub_categories: [],
                              },
                            ],
                          },
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096acbc",
                            },
                            slug: "wafer-biscuits",
                            title: "Wafer Biscuits",
                            id: 5000127,
                            parent:
                              "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/biscuits",
                            path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/biscuits/wafer-biscuits",
                            active: true,
                            meta: {
                              tags: "Wafer Biscuits",
                              keywords: "Wafer Biscuits",
                              description: "Wafer Biscuits",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acbd",
                        },
                        slug: "bread",
                        title: "Bread",
                        id: 5000128,
                        parent:
                          "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery",
                        path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/bread",
                        active: true,
                        meta: {
                          tags: "Bread",
                          keywords: "Bread",
                          description: "Bread",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096acbe",
                            },
                            slug: "frozen-bread",
                            title: "Frozen Bread",
                            id: 5000129,
                            parent:
                              "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/bread",
                            path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/bread/frozen-bread",
                            active: true,
                            meta: {
                              tags: "Frozen Bread",
                              keywords: "Frozen Bread",
                              description: "Frozen Bread",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acbf",
                        },
                        slug: "cakes-pastries-sweet-pies",
                        title: "Cakes Pastries Sweet Pies",
                        id: 5000130,
                        parent:
                          "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery",
                        path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/cakes-pastries-sweet-pies",
                        active: true,
                        meta: {
                          tags: "Cakes Pastries Sweet Pies",
                          keywords: "Cakes Pastries Sweet Pies",
                          description: "Cakes Pastries Sweet Pies",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096acc0",
                            },
                            slug: "cakes",
                            title: "Cakes",
                            id: 5000131,
                            parent:
                              "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/cakes-pastries-sweet-pies",
                            path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/cakes-pastries-sweet-pies/cakes",
                            active: true,
                            meta: {
                              tags: "Cakes",
                              keywords: "Cakes",
                              description: "Cakes",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096acc1",
                            },
                            slug: "pastries",
                            title: "Pastries",
                            id: 5000132,
                            parent:
                              "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/cakes-pastries-sweet-pies",
                            path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/cakes-pastries-sweet-pies/pastries",
                            active: true,
                            meta: {
                              tags: "Pastries",
                              keywords: "Pastries",
                              description: "Pastries",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acc2",
                        },
                        slug: "fortified-bakery",
                        title: "Fortified Bakery",
                        id: 5000133,
                        parent:
                          "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery",
                        path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/fortified-bakery",
                        active: true,
                        meta: {
                          tags: "Fortified Bakery",
                          keywords: "Fortified Bakery",
                          description: "Fortified Bakery",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acc3",
                        },
                        slug: "frozen-bakery",
                        title: "Frozen Bakery",
                        id: 5000134,
                        parent:
                          "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery",
                        path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/frozen-bakery",
                        active: true,
                        meta: {
                          tags: "Frozen Bakery",
                          keywords: "Frozen Bakery",
                          description: "Frozen Bakery",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acc4",
                        },
                        slug: "premium-bakery",
                        title: "Premium Bakery",
                        id: 5000135,
                        parent:
                          "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery",
                        path: "/food-beverage/food/processed-food/baked-foods-and-cereals/bakery/premium-bakery",
                        active: true,
                        meta: {
                          tags: "Premium Bakery",
                          keywords: "Premium Bakery",
                          description: "Premium Bakery",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acc5",
                    },
                    slug: "breakfast-cereals",
                    title: "Breakfast Cereals",
                    id: 5000136,
                    parent:
                      "/food-beverage/food/processed-food/baked-foods-and-cereals",
                    path: "/food-beverage/food/processed-food/baked-foods-and-cereals/breakfast-cereals",
                    active: true,
                    meta: {
                      tags: "Breakfast Cereals",
                      keywords: "Breakfast Cereals",
                      description: "Breakfast Cereals",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acc6",
                    },
                    slug: "morning-goods",
                    title: "Morning Goods",
                    id: 5000137,
                    parent:
                      "/food-beverage/food/processed-food/baked-foods-and-cereals",
                    path: "/food-beverage/food/processed-food/baked-foods-and-cereals/morning-goods",
                    active: true,
                    meta: {
                      tags: "Morning Goods",
                      keywords: "Morning Goods",
                      description: "Morning Goods",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acc7",
                },
                slug: "beeswax",
                title: "Beeswax",
                id: 5000138,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/beeswax",
                active: true,
                meta: {
                  tags: "Beeswax",
                  keywords: "Beeswax",
                  description: "Beeswax",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acc8",
                },
                slug: "breakfast-food",
                title: "Breakfast Food",
                id: 5000139,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/breakfast-food",
                active: true,
                meta: {
                  tags: "Breakfast Food",
                  keywords: "Breakfast Food",
                  description: "Breakfast Food",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acc9",
                },
                slug: "coconut-products",
                title: "Coconut Products",
                id: 5000140,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/coconut-products",
                active: true,
                meta: {
                  tags: "Coconut Products",
                  keywords: "Coconut Products",
                  description: "Coconut Products",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acca",
                    },
                    slug: "desiccated-coconut-powder",
                    title: "Desiccated Coconut Powder",
                    id: 5000141,
                    parent:
                      "/food-beverage/food/processed-food/coconut-products",
                    path: "/food-beverage/food/processed-food/coconut-products/desiccated-coconut-powder",
                    active: true,
                    meta: {
                      tags: "Desiccated Coconut Powder",
                      keywords: "Desiccated Coconut Powder",
                      description: "Desiccated Coconut Powder",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096accb",
                },
                slug: "confectionery",
                title: "Confectionery",
                id: 5000142,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/confectionery",
                active: true,
                meta: {
                  tags: "Confectionery",
                  keywords: "Confectionery",
                  description: "Confectionery",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096accc",
                    },
                    slug: "chocolate",
                    title: "Chocolate",
                    id: 5000143,
                    parent: "/food-beverage/food/processed-food/confectionery",
                    path: "/food-beverage/food/processed-food/confectionery/chocolate",
                    active: true,
                    meta: {
                      tags: "Chocolate",
                      keywords: "Chocolate",
                      description: "Chocolate",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096accd",
                        },
                        slug: "bean-to-bar-chocolate",
                        title: "Bean to Bar Chocolate",
                        id: 5000144,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/chocolate",
                        path: "/food-beverage/food/processed-food/confectionery/chocolate/bean-to-bar-chocolate",
                        active: true,
                        meta: {
                          tags: "Bean to Bar Chocolate",
                          keywords: "Bean to Bar Chocolate",
                          description: "Bean to Bar Chocolate",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acce",
                        },
                        slug: "premium-chocolate",
                        title: "Premium Chocolate",
                        id: 5000145,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/chocolate",
                        path: "/food-beverage/food/processed-food/confectionery/chocolate/premium-chocolate",
                        active: true,
                        meta: {
                          tags: "Premium Chocolate",
                          keywords: "Premium Chocolate",
                          description: "Premium Chocolate",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096accf",
                        },
                        slug: "vegan-chocolate",
                        title: "Vegan Chocolate",
                        id: 5000146,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/chocolate",
                        path: "/food-beverage/food/processed-food/confectionery/chocolate/vegan-chocolate",
                        active: true,
                        meta: {
                          tags: "Vegan Chocolate",
                          keywords: "Vegan Chocolate",
                          description: "Vegan Chocolate",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acd0",
                    },
                    slug: "gums",
                    title: "Gums",
                    id: 5000147,
                    parent: "/food-beverage/food/processed-food/confectionery",
                    path: "/food-beverage/food/processed-food/confectionery/gums",
                    active: true,
                    meta: {
                      tags: "Gums",
                      keywords: "Gums",
                      description: "Gums",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acd1",
                        },
                        slug: "chewing-gums",
                        title: "Chewing Gums",
                        id: 5000148,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/gums",
                        path: "/food-beverage/food/processed-food/confectionery/gums/chewing-gums",
                        active: true,
                        meta: {
                          tags: "Chewing Gums",
                          keywords: "Chewing Gums",
                          description: "Chewing Gums",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096acd2",
                            },
                            slug: "sugar-free-chewing-gums",
                            title: "Sugar Free Chewing Gums",
                            id: 5000149,
                            parent:
                              "/food-beverage/food/processed-food/confectionery/gums/chewing-gums",
                            path: "/food-beverage/food/processed-food/confectionery/gums/chewing-gums/sugar-free-chewing-gums",
                            active: true,
                            meta: {
                              tags: "Sugar Free Chewing Gums",
                              keywords: "Sugar Free Chewing Gums",
                              description: "Sugar Free Chewing Gums",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acd3",
                    },
                    slug: "liquor-confectionery",
                    title: "Liquor Confectionery",
                    id: 5000150,
                    parent: "/food-beverage/food/processed-food/confectionery",
                    path: "/food-beverage/food/processed-food/confectionery/liquor-confectionery",
                    active: true,
                    meta: {
                      tags: "Liquor Confectionery",
                      keywords: "Liquor Confectionery",
                      description: "Liquor Confectionery",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acd4",
                    },
                    slug: "medicated-confectionery",
                    title: "Medicated Confectionery",
                    id: 5000151,
                    parent: "/food-beverage/food/processed-food/confectionery",
                    path: "/food-beverage/food/processed-food/confectionery/medicated-confectionery",
                    active: true,
                    meta: {
                      tags: "Medicated Confectionery",
                      keywords: "Medicated Confectionery",
                      description: "Medicated Confectionery",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acd5",
                    },
                    slug: "snack-bars",
                    title: "Snack Bars",
                    id: 5000152,
                    parent: "/food-beverage/food/processed-food/confectionery",
                    path: "/food-beverage/food/processed-food/confectionery/snack-bars",
                    active: true,
                    meta: {
                      tags: "Snack Bars",
                      keywords: "Snack Bars",
                      description: "Snack Bars",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acd6",
                        },
                        slug: "cereal-bars",
                        title: "Cereal Bars",
                        id: 5000153,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/snack-bars",
                        path: "/food-beverage/food/processed-food/confectionery/snack-bars/cereal-bars",
                        active: true,
                        meta: {
                          tags: "Cereal Bars",
                          keywords: "Cereal Bars",
                          description: "Cereal Bars",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acd7",
                        },
                        slug: "energy-bars",
                        title: "Energy Bars",
                        id: 5000154,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/snack-bars",
                        path: "/food-beverage/food/processed-food/confectionery/snack-bars/energy-bars",
                        active: true,
                        meta: {
                          tags: "Energy Bars",
                          keywords: "Energy Bars",
                          description: "Energy Bars",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acd8",
                        },
                        slug: "protein-bars",
                        title: "Protein Bars",
                        id: 5000155,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/snack-bars",
                        path: "/food-beverage/food/processed-food/confectionery/snack-bars/protein-bars",
                        active: true,
                        meta: {
                          tags: "Protein Bars",
                          keywords: "Protein Bars",
                          description: "Protein Bars",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acd9",
                    },
                    slug: "sugar-confectionery",
                    title: "Sugar Confectionery",
                    id: 5000156,
                    parent: "/food-beverage/food/processed-food/confectionery",
                    path: "/food-beverage/food/processed-food/confectionery/sugar-confectionery",
                    active: true,
                    meta: {
                      tags: "Sugar Confectionery",
                      keywords: "Sugar Confectionery",
                      description: "Sugar Confectionery",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acda",
                        },
                        slug: "candy",
                        title: "Candy",
                        id: 5000157,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/sugar-confectionery",
                        path: "/food-beverage/food/processed-food/confectionery/sugar-confectionery/candy",
                        active: true,
                        meta: {
                          tags: "Candy",
                          keywords: "Candy",
                          description: "Candy",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acdb",
                        },
                        slug: "mints",
                        title: "Mints",
                        id: 5000158,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/sugar-confectionery",
                        path: "/food-beverage/food/processed-food/confectionery/sugar-confectionery/mints",
                        active: true,
                        meta: {
                          tags: "Mints",
                          keywords: "Mints",
                          description: "Mints",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acdc",
                        },
                        slug: "pastilles-gummies-jellies",
                        title: "Pastilles Gummies Jellies",
                        id: 5000159,
                        parent:
                          "/food-beverage/food/processed-food/confectionery/sugar-confectionery",
                        path: "/food-beverage/food/processed-food/confectionery/sugar-confectionery/pastilles-gummies-jellies",
                        active: true,
                        meta: {
                          tags: "Pastilles Gummies Jellies",
                          keywords: "Pastilles Gummies Jellies",
                          description: "Pastilles Gummies Jellies",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096acdd",
                            },
                            slug: "jellies",
                            title: "Jellies",
                            id: 5000160,
                            parent:
                              "/food-beverage/food/processed-food/confectionery/sugar-confectionery/pastilles-gummies-jellies",
                            path: "/food-beverage/food/processed-food/confectionery/sugar-confectionery/pastilles-gummies-jellies/jellies",
                            active: true,
                            meta: {
                              tags: "Jellies",
                              keywords: "Jellies",
                              description: "Jellies",
                            },
                            sub_categories: [
                              {
                                _id: {
                                  $oid: "64ad97c004b8af526096acde",
                                },
                                slug: "fruit-jellies",
                                title: "Fruit Jellies",
                                id: 5000161,
                                parent:
                                  "/food-beverage/food/processed-food/confectionery/sugar-confectionery/pastilles-gummies-jellies/jellies",
                                path: "/food-beverage/food/processed-food/confectionery/sugar-confectionery/pastilles-gummies-jellies/jellies/fruit-jellies",
                                active: true,
                                meta: {
                                  tags: "Fruit Jellies",
                                  keywords: "Fruit Jellies",
                                  description: "Fruit Jellies",
                                },
                                sub_categories: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096acdf",
                },
                slug: "dairy-alternatives",
                title: "Dairy Alternatives",
                id: 5000162,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/dairy-alternatives",
                active: true,
                meta: {
                  tags: "Dairy Alternatives",
                  keywords: "Dairy Alternatives",
                  description: "Dairy Alternatives",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ace0",
                    },
                    slug: "coconut-cream",
                    title: "Coconut Cream",
                    id: 5000163,
                    parent:
                      "/food-beverage/food/processed-food/dairy-alternatives",
                    path: "/food-beverage/food/processed-food/dairy-alternatives/coconut-cream",
                    active: true,
                    meta: {
                      tags: "Coconut Cream",
                      keywords: "Coconut Cream",
                      description: "Coconut Cream",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ace1",
                    },
                    slug: "dairy-free-ice-cream",
                    title: "Dairy Free Ice Cream",
                    id: 5000164,
                    parent:
                      "/food-beverage/food/processed-food/dairy-alternatives",
                    path: "/food-beverage/food/processed-food/dairy-alternatives/dairy-free-ice-cream",
                    active: true,
                    meta: {
                      tags: "Dairy Free Ice Cream",
                      keywords: "Dairy Free Ice Cream",
                      description: "Dairy Free Ice Cream",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ace2",
                    },
                    slug: "non-dairy-cheese",
                    title: "Non Dairy Cheese",
                    id: 5000165,
                    parent:
                      "/food-beverage/food/processed-food/dairy-alternatives",
                    path: "/food-beverage/food/processed-food/dairy-alternatives/non-dairy-cheese",
                    active: true,
                    meta: {
                      tags: "Non Dairy Cheese",
                      keywords: "Non Dairy Cheese",
                      description: "Non Dairy Cheese",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ace3",
                    },
                    slug: "rice-milk",
                    title: "Rice Milk",
                    id: 5000166,
                    parent:
                      "/food-beverage/food/processed-food/dairy-alternatives",
                    path: "/food-beverage/food/processed-food/dairy-alternatives/rice-milk",
                    active: true,
                    meta: {
                      tags: "Rice Milk",
                      keywords: "Rice Milk",
                      description: "Rice Milk",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ace4",
                    },
                    slug: "walnut-milk",
                    title: "Walnut Milk",
                    id: 5000167,
                    parent:
                      "/food-beverage/food/processed-food/dairy-alternatives",
                    path: "/food-beverage/food/processed-food/dairy-alternatives/walnut-milk",
                    active: true,
                    meta: {
                      tags: "Walnut Milk",
                      keywords: "Walnut Milk",
                      description: "Walnut Milk",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ace5",
                },
                slug: "dairy-products",
                title: "Dairy Products",
                id: 5000168,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/dairy-products",
                active: true,
                meta: {
                  tags: "Dairy Products",
                  keywords: "Dairy Products",
                  description: "Dairy Products",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ace6",
                    },
                    slug: "butter",
                    title: "Butter",
                    id: 5000169,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/butter",
                    active: true,
                    meta: {
                      tags: "Butter",
                      keywords: "Butter",
                      description: "Butter",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ace7",
                        },
                        slug: "lactose-free-butter",
                        title: "Lactose Free Butter",
                        id: 5000170,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/butter",
                        path: "/food-beverage/food/processed-food/dairy-products/butter/lactose-free-butter",
                        active: true,
                        meta: {
                          tags: "Lactose Free Butter",
                          keywords: "Lactose Free Butter",
                          description: "Lactose Free Butter",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ace8",
                        },
                        slug: "reduced-fat-butter",
                        title: "Reduced Fat Butter",
                        id: 5000171,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/butter",
                        path: "/food-beverage/food/processed-food/dairy-products/butter/reduced-fat-butter",
                        active: true,
                        meta: {
                          tags: "Reduced Fat Butter",
                          keywords: "Reduced Fat Butter",
                          description: "Reduced Fat Butter",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ace9",
                    },
                    slug: "cheese",
                    title: "Cheese",
                    id: 5000172,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/cheese",
                    active: true,
                    meta: {
                      tags: "Cheese",
                      keywords: "Cheese",
                      description: "Cheese",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acea",
                        },
                        slug: "cheddar-cheese",
                        title: "Cheddar Cheese",
                        id: 5000173,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/cheese",
                        path: "/food-beverage/food/processed-food/dairy-products/cheese/cheddar-cheese",
                        active: true,
                        meta: {
                          tags: "Cheddar Cheese",
                          keywords: "Cheddar Cheese",
                          description: "Cheddar Cheese",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096aceb",
                        },
                        slug: "cheese-powder",
                        title: "Cheese Powder",
                        id: 5000174,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/cheese",
                        path: "/food-beverage/food/processed-food/dairy-products/cheese/cheese-powder",
                        active: true,
                        meta: {
                          tags: "Cheese Powder",
                          keywords: "Cheese Powder",
                          description: "Cheese Powder",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acec",
                        },
                        slug: "goat-cheese",
                        title: "Goat Cheese",
                        id: 5000175,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/cheese",
                        path: "/food-beverage/food/processed-food/dairy-products/cheese/goat-cheese",
                        active: true,
                        meta: {
                          tags: "Goat Cheese",
                          keywords: "Goat Cheese",
                          description: "Goat Cheese",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096aced",
                        },
                        slug: "parmesan-cheese",
                        title: "Parmesan Cheese",
                        id: 5000176,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/cheese",
                        path: "/food-beverage/food/processed-food/dairy-products/cheese/parmesan-cheese",
                        active: true,
                        meta: {
                          tags: "Parmesan Cheese",
                          keywords: "Parmesan Cheese",
                          description: "Parmesan Cheese",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acee",
                    },
                    slug: "coffee-creamer",
                    title: "Coffee Creamer",
                    id: 5000177,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/coffee-creamer",
                    active: true,
                    meta: {
                      tags: "Coffee Creamer",
                      keywords: "Coffee Creamer",
                      description: "Coffee Creamer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acef",
                    },
                    slug: "condensed-milk",
                    title: "Condensed Milk",
                    id: 5000178,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/condensed-milk",
                    active: true,
                    meta: {
                      tags: "Condensed Milk",
                      keywords: "Condensed Milk",
                      description: "Condensed Milk",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acf0",
                    },
                    slug: "dairy-cream",
                    title: "Dairy Cream",
                    id: 5000179,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/dairy-cream",
                    active: true,
                    meta: {
                      tags: "Dairy Cream",
                      keywords: "Dairy Cream",
                      description: "Dairy Cream",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acf1",
                        },
                        slug: "sour-cream",
                        title: "Sour Cream",
                        id: 5000180,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/dairy-cream",
                        path: "/food-beverage/food/processed-food/dairy-products/dairy-cream/sour-cream",
                        active: true,
                        meta: {
                          tags: "Sour Cream",
                          keywords: "Sour Cream",
                          description: "Sour Cream",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acf2",
                        },
                        slug: "whipping-cream",
                        title: "Whipping Cream",
                        id: 5000181,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/dairy-cream",
                        path: "/food-beverage/food/processed-food/dairy-products/dairy-cream/whipping-cream",
                        active: true,
                        meta: {
                          tags: "Whipping Cream",
                          keywords: "Whipping Cream",
                          description: "Whipping Cream",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acf3",
                    },
                    slug: "dairy-whitener",
                    title: "Dairy Whitener",
                    id: 5000182,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/dairy-whitener",
                    active: true,
                    meta: {
                      tags: "Dairy Whitener",
                      keywords: "Dairy Whitener",
                      description: "Dairy Whitener",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acf4",
                    },
                    slug: "ice-cream",
                    title: "Ice Cream",
                    id: 5000183,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/ice-cream",
                    active: true,
                    meta: {
                      tags: "Ice Cream",
                      keywords: "Ice Cream",
                      description: "Ice Cream",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acf5",
                    },
                    slug: "kefir",
                    title: "Kefir",
                    id: 5000184,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/kefir",
                    active: true,
                    meta: {
                      tags: "Kefir",
                      keywords: "Kefir",
                      description: "Kefir",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acf6",
                    },
                    slug: "milk",
                    title: "Milk",
                    id: 5000185,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/milk",
                    active: true,
                    meta: {
                      tags: "Milk",
                      keywords: "Milk",
                      description: "Milk",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acf7",
                        },
                        slug: "lactose-free-milk",
                        title: "Lactose Free Milk",
                        id: 5000186,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/milk",
                        path: "/food-beverage/food/processed-food/dairy-products/milk/lactose-free-milk",
                        active: true,
                        meta: {
                          tags: "Lactose Free Milk",
                          keywords: "Lactose Free Milk",
                          description: "Lactose Free Milk",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acf8",
                        },
                        slug: "low-fat-milk",
                        title: "Low Fat Milk",
                        id: 5000187,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/milk",
                        path: "/food-beverage/food/processed-food/dairy-products/milk/low-fat-milk",
                        active: true,
                        meta: {
                          tags: "Low Fat Milk",
                          keywords: "Low Fat Milk",
                          description: "Low Fat Milk",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acf9",
                        },
                        slug: "organic-milk",
                        title: "Organic Milk",
                        id: 5000188,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/milk",
                        path: "/food-beverage/food/processed-food/dairy-products/milk/organic-milk",
                        active: true,
                        meta: {
                          tags: "Organic Milk",
                          keywords: "Organic Milk",
                          description: "Organic Milk",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acfa",
                        },
                        slug: "skim-milk",
                        title: "Skim Milk",
                        id: 5000189,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/milk",
                        path: "/food-beverage/food/processed-food/dairy-products/milk/skim-milk",
                        active: true,
                        meta: {
                          tags: "Skim Milk",
                          keywords: "Skim Milk",
                          description: "Skim Milk",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acfb",
                        },
                        slug: "uht-milk",
                        title: "UHT Milk",
                        id: 5000190,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/milk",
                        path: "/food-beverage/food/processed-food/dairy-products/milk/uht-milk",
                        active: true,
                        meta: {
                          tags: "UHT Milk",
                          keywords: "UHT Milk",
                          description: "UHT Milk",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acfc",
                    },
                    slug: "milk-powder",
                    title: "Milk Powder",
                    id: 5000191,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/milk-powder",
                    active: true,
                    meta: {
                      tags: "Milk Powder",
                      keywords: "Milk Powder",
                      description: "Milk Powder",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acfd",
                    },
                    slug: "quark-and-fromage-frais",
                    title: "Quark and Fromage Frais",
                    id: 5000192,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/quark-and-fromage-frais",
                    active: true,
                    meta: {
                      tags: "Quark and Fromage Frais",
                      keywords: "Quark and Fromage Frais",
                      description: "Quark and Fromage Frais",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096acfe",
                    },
                    slug: "yogurt",
                    title: "Yogurt",
                    id: 5000193,
                    parent: "/food-beverage/food/processed-food/dairy-products",
                    path: "/food-beverage/food/processed-food/dairy-products/yogurt",
                    active: true,
                    meta: {
                      tags: "Yogurt",
                      keywords: "Yogurt",
                      description: "Yogurt",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096acff",
                        },
                        slug: "drinkable-yogurt",
                        title: "Drinkable Yogurt",
                        id: 5000194,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/yogurt",
                        path: "/food-beverage/food/processed-food/dairy-products/yogurt/drinkable-yogurt",
                        active: true,
                        meta: {
                          tags: "Drinkable Yogurt",
                          keywords: "Drinkable Yogurt",
                          description: "Drinkable Yogurt",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad00",
                        },
                        slug: "flavored-yogurt",
                        title: "Flavored Yogurt",
                        id: 5000195,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/yogurt",
                        path: "/food-beverage/food/processed-food/dairy-products/yogurt/flavored-yogurt",
                        active: true,
                        meta: {
                          tags: "Flavored Yogurt",
                          keywords: "Flavored Yogurt",
                          description: "Flavored Yogurt",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ad01",
                            },
                            slug: "fruit-yogurt",
                            title: "Fruit Yogurt",
                            id: 5000196,
                            parent:
                              "/food-beverage/food/processed-food/dairy-products/yogurt/flavored-yogurt",
                            path: "/food-beverage/food/processed-food/dairy-products/yogurt/flavored-yogurt/fruit-yogurt",
                            active: true,
                            meta: {
                              tags: "Fruit Yogurt",
                              keywords: "Fruit Yogurt",
                              description: "Fruit Yogurt",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad02",
                        },
                        slug: "frozen-yogurt",
                        title: "Frozen Yogurt",
                        id: 5000197,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/yogurt",
                        path: "/food-beverage/food/processed-food/dairy-products/yogurt/frozen-yogurt",
                        active: true,
                        meta: {
                          tags: "Frozen Yogurt",
                          keywords: "Frozen Yogurt",
                          description: "Frozen Yogurt",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad03",
                        },
                        slug: "spoonable-yogurt",
                        title: "Spoonable Yogurt",
                        id: 5000198,
                        parent:
                          "/food-beverage/food/processed-food/dairy-products/yogurt",
                        path: "/food-beverage/food/processed-food/dairy-products/yogurt/spoonable-yogurt",
                        active: true,
                        meta: {
                          tags: "Spoonable Yogurt",
                          keywords: "Spoonable Yogurt",
                          description: "Spoonable Yogurt",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad04",
                },
                slug: "desserts",
                title: "Desserts",
                id: 5000199,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/desserts",
                active: true,
                meta: {
                  tags: "Desserts",
                  keywords: "Desserts",
                  description: "Desserts",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad05",
                    },
                    slug: "frozen-desserts",
                    title: "Frozen Desserts",
                    id: 5000200,
                    parent: "/food-beverage/food/processed-food/desserts",
                    path: "/food-beverage/food/processed-food/desserts/frozen-desserts",
                    active: true,
                    meta: {
                      tags: "Frozen Desserts",
                      keywords: "Frozen Desserts",
                      description: "Frozen Desserts",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad06",
                },
                slug: "edible-cannabis-products",
                title: "Edible Cannabis Products",
                id: 5000201,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/edible-cannabis-products",
                active: true,
                meta: {
                  tags: "Edible Cannabis Products",
                  keywords: "Edible Cannabis Products",
                  description: "Edible Cannabis Products",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad07",
                },
                slug: "ethnic-foods",
                title: "Ethnic Foods",
                id: 5000202,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/ethnic-foods",
                active: true,
                meta: {
                  tags: "Ethnic Foods",
                  keywords: "Ethnic Foods",
                  description: "Ethnic Foods",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad08",
                },
                slug: "food-spreads",
                title: "Food Spreads",
                id: 5000203,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/food-spreads",
                active: true,
                meta: {
                  tags: "Food Spreads",
                  keywords: "Food Spreads",
                  description: "Food Spreads",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad09",
                    },
                    slug: "chocolate-spreads",
                    title: "Chocolate Spreads",
                    id: 5000204,
                    parent: "/food-beverage/food/processed-food/food-spreads",
                    path: "/food-beverage/food/processed-food/food-spreads/chocolate-spreads",
                    active: true,
                    meta: {
                      tags: "Chocolate Spreads",
                      keywords: "Chocolate Spreads",
                      description: "Chocolate Spreads",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad0a",
                    },
                    slug: "fruit-spreads",
                    title: "Fruit Spreads",
                    id: 5000205,
                    parent: "/food-beverage/food/processed-food/food-spreads",
                    path: "/food-beverage/food/processed-food/food-spreads/fruit-spreads",
                    active: true,
                    meta: {
                      tags: "Fruit Spreads",
                      keywords: "Fruit Spreads",
                      description: "Fruit Spreads",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad0b",
                        },
                        slug: "jam-jelly-and-preserves",
                        title: "Jam Jelly and Preserves",
                        id: 5000206,
                        parent:
                          "/food-beverage/food/processed-food/food-spreads/fruit-spreads",
                        path: "/food-beverage/food/processed-food/food-spreads/fruit-spreads/jam-jelly-and-preserves",
                        active: true,
                        meta: {
                          tags: "Jam Jelly and Preserves",
                          keywords: "Jam Jelly and Preserves",
                          description: "Jam Jelly and Preserves",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad0c",
                    },
                    slug: "honey-spreads",
                    title: "Honey Spreads",
                    id: 5000207,
                    parent: "/food-beverage/food/processed-food/food-spreads",
                    path: "/food-beverage/food/processed-food/food-spreads/honey-spreads",
                    active: true,
                    meta: {
                      tags: "Honey Spreads",
                      keywords: "Honey Spreads",
                      description: "Honey Spreads",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad0d",
                    },
                    slug: "mustard",
                    title: "Mustard",
                    id: 5000208,
                    parent: "/food-beverage/food/processed-food/food-spreads",
                    path: "/food-beverage/food/processed-food/food-spreads/mustard",
                    active: true,
                    meta: {
                      tags: "Mustard",
                      keywords: "Mustard",
                      description: "Mustard",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad0e",
                    },
                    slug: "nut-based-spreads",
                    title: "Nut Based Spreads",
                    id: 5000209,
                    parent: "/food-beverage/food/processed-food/food-spreads",
                    path: "/food-beverage/food/processed-food/food-spreads/nut-based-spreads",
                    active: true,
                    meta: {
                      tags: "Nut Based Spreads",
                      keywords: "Nut Based Spreads",
                      description: "Nut Based Spreads",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad0f",
                        },
                        slug: "walnut-based-spreads",
                        title: "Walnut Based Spreads",
                        id: 5000210,
                        parent:
                          "/food-beverage/food/processed-food/food-spreads/nut-based-spreads",
                        path: "/food-beverage/food/processed-food/food-spreads/nut-based-spreads/walnut-based-spreads",
                        active: true,
                        meta: {
                          tags: "Walnut Based Spreads",
                          keywords: "Walnut Based Spreads",
                          description: "Walnut Based Spreads",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad10",
                    },
                    slug: "sweet-spreads",
                    title: "Sweet Spreads",
                    id: 5000211,
                    parent: "/food-beverage/food/processed-food/food-spreads",
                    path: "/food-beverage/food/processed-food/food-spreads/sweet-spreads",
                    active: true,
                    meta: {
                      tags: "Sweet Spreads",
                      keywords: "Sweet Spreads",
                      description: "Sweet Spreads",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad11",
                    },
                    slug: "tahini",
                    title: "Tahini",
                    id: 5000212,
                    parent: "/food-beverage/food/processed-food/food-spreads",
                    path: "/food-beverage/food/processed-food/food-spreads/tahini",
                    active: true,
                    meta: {
                      tags: "Tahini",
                      keywords: "Tahini",
                      description: "Tahini",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad12",
                },
                slug: "free-from-food",
                title: "Free From Food",
                id: 5000213,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/free-from-food",
                active: true,
                meta: {
                  tags: "Free From Food",
                  keywords: "Free From Food",
                  description: "Free From Food",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad13",
                    },
                    slug: "gluten-free-food",
                    title: "Gluten Free Food",
                    id: 5000214,
                    parent: "/food-beverage/food/processed-food/free-from-food",
                    path: "/food-beverage/food/processed-food/free-from-food/gluten-free-food",
                    active: true,
                    meta: {
                      tags: "Gluten Free Food",
                      keywords: "Gluten Free Food",
                      description: "Gluten Free Food",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad14",
                        },
                        slug: "gluten-free-bakery",
                        title: "Gluten Free Bakery",
                        id: 5000215,
                        parent:
                          "/food-beverage/food/processed-food/free-from-food/gluten-free-food",
                        path: "/food-beverage/food/processed-food/free-from-food/gluten-free-food/gluten-free-bakery",
                        active: true,
                        meta: {
                          tags: "Gluten Free Bakery",
                          keywords: "Gluten Free Bakery",
                          description: "Gluten Free Bakery",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad15",
                        },
                        slug: "gluten-free-beverages",
                        title: "Gluten Free Beverages",
                        id: 5000216,
                        parent:
                          "/food-beverage/food/processed-food/free-from-food/gluten-free-food",
                        path: "/food-beverage/food/processed-food/free-from-food/gluten-free-food/gluten-free-beverages",
                        active: true,
                        meta: {
                          tags: "Gluten Free Beverages",
                          keywords: "Gluten Free Beverages",
                          description: "Gluten Free Beverages",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad16",
                        },
                        slug: "gluten-free-desserts",
                        title: "Gluten Free Desserts",
                        id: 5000217,
                        parent:
                          "/food-beverage/food/processed-food/free-from-food/gluten-free-food",
                        path: "/food-beverage/food/processed-food/free-from-food/gluten-free-food/gluten-free-desserts",
                        active: true,
                        meta: {
                          tags: "Gluten Free Desserts",
                          keywords: "Gluten Free Desserts",
                          description: "Gluten Free Desserts",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad17",
                        },
                        slug: "gluten-free-pasta",
                        title: "Gluten Free Pasta",
                        id: 5000218,
                        parent:
                          "/food-beverage/food/processed-food/free-from-food/gluten-free-food",
                        path: "/food-beverage/food/processed-food/free-from-food/gluten-free-food/gluten-free-pasta",
                        active: true,
                        meta: {
                          tags: "Gluten Free Pasta",
                          keywords: "Gluten Free Pasta",
                          description: "Gluten Free Pasta",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad18",
                        },
                        slug: "gluten-free-soups-and-sauces",
                        title: "Gluten Free Soups and Sauces",
                        id: 5000219,
                        parent:
                          "/food-beverage/food/processed-food/free-from-food/gluten-free-food",
                        path: "/food-beverage/food/processed-food/free-from-food/gluten-free-food/gluten-free-soups-and-sauces",
                        active: true,
                        meta: {
                          tags: "Gluten Free Soups and Sauces",
                          keywords: "Gluten Free Soups and Sauces",
                          description: "Gluten Free Soups and Sauces",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad19",
                    },
                    slug: "gmo-free-food",
                    title: "GMO Free Food",
                    id: 5000220,
                    parent: "/food-beverage/food/processed-food/free-from-food",
                    path: "/food-beverage/food/processed-food/free-from-food/gmo-free-food",
                    active: true,
                    meta: {
                      tags: "GMO Free Food",
                      keywords: "GMO Free Food",
                      description: "GMO Free Food",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad1a",
                    },
                    slug: "sugar-free-food",
                    title: "Sugar Free Food",
                    id: 5000221,
                    parent: "/food-beverage/food/processed-food/free-from-food",
                    path: "/food-beverage/food/processed-food/free-from-food/sugar-free-food",
                    active: true,
                    meta: {
                      tags: "Sugar Free Food",
                      keywords: "Sugar Free Food",
                      description: "Sugar Free Food",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad1b",
                },
                slug: "functional-food",
                title: "Functional Food",
                id: 5000222,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/functional-food",
                active: true,
                meta: {
                  tags: "Functional Food",
                  keywords: "Functional Food",
                  description: "Functional Food",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad1c",
                    },
                    slug: "omega-3-products",
                    title: "Omega 3 Products",
                    id: 5000223,
                    parent:
                      "/food-beverage/food/processed-food/functional-food",
                    path: "/food-beverage/food/processed-food/functional-food/omega-3-products",
                    active: true,
                    meta: {
                      tags: "Omega 3 Products",
                      keywords: "Omega 3 Products",
                      description: "Omega 3 Products",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad1d",
                },
                slug: "gmo-labelled-food",
                title: "GMO Labelled Food",
                id: 5000224,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/gmo-labelled-food",
                active: true,
                meta: {
                  tags: "GMO Labelled Food",
                  keywords: "GMO Labelled Food",
                  description: "GMO Labelled Food",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad1e",
                },
                slug: "gourmet-foods",
                title: "Gourmet Foods",
                id: 5000225,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/gourmet-foods",
                active: true,
                meta: {
                  tags: "Gourmet Foods",
                  keywords: "Gourmet Foods",
                  description: "Gourmet Foods",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad1f",
                },
                slug: "halal-food-and-beverage",
                title: "Halal Food and Beverage",
                id: 5000226,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/halal-food-and-beverage",
                active: true,
                meta: {
                  tags: "Halal Food and Beverage",
                  keywords: "Halal Food and Beverage",
                  description: "Halal Food and Beverage",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad20",
                },
                slug: "meat",
                title: "Meat",
                id: 5000227,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/meat",
                active: true,
                meta: {
                  tags: "Meat",
                  keywords: "Meat",
                  description: "Meat",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad21",
                    },
                    slug: "canned-meat",
                    title: "Canned Meat",
                    id: 5000228,
                    parent: "/food-beverage/food/processed-food/meat",
                    path: "/food-beverage/food/processed-food/meat/canned-meat",
                    active: true,
                    meta: {
                      tags: "Canned Meat",
                      keywords: "Canned Meat",
                      description: "Canned Meat",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad22",
                    },
                    slug: "edible-offal",
                    title: "Edible Offal",
                    id: 5000229,
                    parent: "/food-beverage/food/processed-food/meat",
                    path: "/food-beverage/food/processed-food/meat/edible-offal",
                    active: true,
                    meta: {
                      tags: "Edible Offal",
                      keywords: "Edible Offal",
                      description: "Edible Offal",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad23",
                    },
                    slug: "organic-meat",
                    title: "Organic Meat",
                    id: 5000230,
                    parent: "/food-beverage/food/processed-food/meat",
                    path: "/food-beverage/food/processed-food/meat/organic-meat",
                    active: true,
                    meta: {
                      tags: "Organic Meat",
                      keywords: "Organic Meat",
                      description: "Organic Meat",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad24",
                    },
                    slug: "poultry-meat",
                    title: "Poultry Meat",
                    id: 5000231,
                    parent: "/food-beverage/food/processed-food/meat",
                    path: "/food-beverage/food/processed-food/meat/poultry-meat",
                    active: true,
                    meta: {
                      tags: "Poultry Meat",
                      keywords: "Poultry Meat",
                      description: "Poultry Meat",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad25",
                    },
                    slug: "processed-meat",
                    title: "Processed Meat",
                    id: 5000232,
                    parent: "/food-beverage/food/processed-food/meat",
                    path: "/food-beverage/food/processed-food/meat/processed-meat",
                    active: true,
                    meta: {
                      tags: "Processed Meat",
                      keywords: "Processed Meat",
                      description: "Processed Meat",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad26",
                        },
                        slug: "bacon",
                        title: "Bacon",
                        id: 5000233,
                        parent:
                          "/food-beverage/food/processed-food/meat/processed-meat",
                        path: "/food-beverage/food/processed-food/meat/processed-meat/bacon",
                        active: true,
                        meta: {
                          tags: "Bacon",
                          keywords: "Bacon",
                          description: "Bacon",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad27",
                        },
                        slug: "brisket",
                        title: "Brisket",
                        id: 5000234,
                        parent:
                          "/food-beverage/food/processed-food/meat/processed-meat",
                        path: "/food-beverage/food/processed-food/meat/processed-meat/brisket",
                        active: true,
                        meta: {
                          tags: "Brisket",
                          keywords: "Brisket",
                          description: "Brisket",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad28",
                    },
                    slug: "red-meat",
                    title: "Red Meat",
                    id: 5000235,
                    parent: "/food-beverage/food/processed-food/meat",
                    path: "/food-beverage/food/processed-food/meat/red-meat",
                    active: true,
                    meta: {
                      tags: "Red Meat",
                      keywords: "Red Meat",
                      description: "Red Meat",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad29",
                        },
                        slug: "pork-meat",
                        title: "Pork Meat",
                        id: 5000236,
                        parent:
                          "/food-beverage/food/processed-food/meat/red-meat",
                        path: "/food-beverage/food/processed-food/meat/red-meat/pork-meat",
                        active: true,
                        meta: {
                          tags: "Pork Meat",
                          keywords: "Pork Meat",
                          description: "Pork Meat",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ad2a",
                            },
                            slug: "processed-pork-meat",
                            title: "Processed Pork Meat",
                            id: 5000237,
                            parent:
                              "/food-beverage/food/processed-food/meat/red-meat/pork-meat",
                            path: "/food-beverage/food/processed-food/meat/red-meat/pork-meat/processed-pork-meat",
                            active: true,
                            meta: {
                              tags: "Processed Pork Meat",
                              keywords: "Processed Pork Meat",
                              description: "Processed Pork Meat",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad2b",
                },
                slug: "meat-substitutes",
                title: "Meat Substitutes",
                id: 5000238,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/meat-substitutes",
                active: true,
                meta: {
                  tags: "Meat Substitutes",
                  keywords: "Meat Substitutes",
                  description: "Meat Substitutes",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad2c",
                    },
                    slug: "tempeh",
                    title: "Tempeh",
                    id: 5000239,
                    parent:
                      "/food-beverage/food/processed-food/meat-substitutes",
                    path: "/food-beverage/food/processed-food/meat-substitutes/tempeh",
                    active: true,
                    meta: {
                      tags: "Tempeh",
                      keywords: "Tempeh",
                      description: "Tempeh",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad2d",
                    },
                    slug: "textured-vegetable-protein",
                    title: "Textured Vegetable Protein",
                    id: 5000240,
                    parent:
                      "/food-beverage/food/processed-food/meat-substitutes",
                    path: "/food-beverage/food/processed-food/meat-substitutes/textured-vegetable-protein",
                    active: true,
                    meta: {
                      tags: "Textured Vegetable Protein",
                      keywords: "Textured Vegetable Protein",
                      description: "Textured Vegetable Protein",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad2e",
                    },
                    slug: "tofu",
                    title: "Tofu",
                    id: 5000241,
                    parent:
                      "/food-beverage/food/processed-food/meat-substitutes",
                    path: "/food-beverage/food/processed-food/meat-substitutes/tofu",
                    active: true,
                    meta: {
                      tags: "Tofu",
                      keywords: "Tofu",
                      description: "Tofu",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad30",
                },
                slug: "oats",
                title: "Oats",
                id: 5000243,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/oats",
                active: true,
                meta: {
                  tags: "Oats",
                  keywords: "Oats",
                  description: "Oats",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad31",
                },
                slug: "organic-food-and-beverage",
                title: "Organic Food and Beverage",
                id: 5000244,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/organic-food-and-beverage",
                active: true,
                meta: {
                  tags: "Organic Food and Beverage",
                  keywords: "Organic Food and Beverage",
                  description: "Organic Food and Beverage",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad32",
                },
                slug: "packaged-food",
                title: "Packaged Food",
                id: 5000245,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/packaged-food",
                active: true,
                meta: {
                  tags: "Packaged Food",
                  keywords: "Packaged Food",
                  description: "Packaged Food",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad33",
                },
                slug: "packaged-rice",
                title: "Packaged Rice",
                id: 5000246,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/packaged-rice",
                active: true,
                meta: {
                  tags: "Packaged Rice",
                  keywords: "Packaged Rice",
                  description: "Packaged Rice",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad34",
                    },
                    slug: "non-basmati-rice",
                    title: "Non Basmati Rice",
                    id: 5000247,
                    parent: "/food-beverage/food/processed-food/packaged-rice",
                    path: "/food-beverage/food/processed-food/packaged-rice/non-basmati-rice",
                    active: true,
                    meta: {
                      tags: "Non Basmati Rice",
                      keywords: "Non Basmati Rice",
                      description: "Non Basmati Rice",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad35",
                },
                slug: "packaged-seeds",
                title: "Packaged Seeds",
                id: 5000248,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/packaged-seeds",
                active: true,
                meta: {
                  tags: "Packaged Seeds",
                  keywords: "Packaged Seeds",
                  description: "Packaged Seeds",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad36",
                },
                slug: "pasta-noodles",
                title: "Pasta, Noodles",
                id: 5000249,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/pasta-noodles",
                active: true,
                meta: {
                  tags: "Pasta, Noodles",
                  keywords: "Pasta, Noodles",
                  description: "Pasta, Noodles",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad37",
                    },
                    slug: "noodles",
                    title: "Noodles",
                    id: 5000250,
                    parent: "/food-beverage/food/processed-food/pasta-noodles",
                    path: "/food-beverage/food/processed-food/pasta-noodles/noodles",
                    active: true,
                    meta: {
                      tags: "Noodles",
                      keywords: "Noodles",
                      description: "Noodles",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad38",
                        },
                        slug: "instant-noodles",
                        title: "Instant Noodles",
                        id: 5000251,
                        parent:
                          "/food-beverage/food/processed-food/pasta-noodles/noodles",
                        path: "/food-beverage/food/processed-food/pasta-noodles/noodles/instant-noodles",
                        active: true,
                        meta: {
                          tags: "Instant Noodles",
                          keywords: "Instant Noodles",
                          description: "Instant Noodles",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad39",
                },
                slug: "pea-flakes",
                title: "Pea Flakes",
                id: 5000252,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/pea-flakes",
                active: true,
                meta: {
                  tags: "Pea Flakes",
                  keywords: "Pea Flakes",
                  description: "Pea Flakes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad3a",
                },
                slug: "plant-based-food-and-beverage",
                title: "Plant Based Food & Beverage",
                id: 5000253,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/plant-based-food-and-beverage",
                active: true,
                meta: {
                  tags: "Plant Based Food & Beverage",
                  keywords: "Plant Based Food & Beverage",
                  description: "Plant Based Food & Beverage",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad3b",
                    },
                    slug: "plant-based-meat-and-dairy-products",
                    title: "Plant Based Meat & Dairy Products",
                    id: 5000254,
                    parent:
                      "/food-beverage/food/processed-food/plant-based-food-and-beverage",
                    path: "/food-beverage/food/processed-food/plant-based-food-and-beverage/plant-based-meat-and-dairy-products",
                    active: true,
                    meta: {
                      tags: "Plant Based Meat & Dairy Products",
                      keywords: "Plant Based Meat & Dairy Products",
                      description: "Plant Based Meat & Dairy Products",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad3c",
                        },
                        slug: "plant-based-milk",
                        title: "Plant Based Milk",
                        id: 5000255,
                        parent:
                          "/food-beverage/food/processed-food/plant-based-food-and-beverage/plant-based-meat-and-dairy-products",
                        path: "/food-beverage/food/processed-food/plant-based-food-and-beverage/plant-based-meat-and-dairy-products/plant-based-milk",
                        active: true,
                        meta: {
                          tags: "Plant Based Milk",
                          keywords: "Plant Based Milk",
                          description: "Plant Based Milk",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad3d",
                },
                slug: "preserved-food",
                title: "Preserved Food",
                id: 5000256,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/preserved-food",
                active: true,
                meta: {
                  tags: "Preserved Food",
                  keywords: "Preserved Food",
                  description: "Preserved Food",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad3e",
                    },
                    slug: "canned-food",
                    title: "Canned Food",
                    id: 5000257,
                    parent: "/food-beverage/food/processed-food/preserved-food",
                    path: "/food-beverage/food/processed-food/preserved-food/canned-food",
                    active: true,
                    meta: {
                      tags: "Canned Food",
                      keywords: "Canned Food",
                      description: "Canned Food",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad3f",
                        },
                        slug: "canned-fruits",
                        title: "Canned Fruits",
                        id: 5000258,
                        parent:
                          "/food-beverage/food/processed-food/preserved-food/canned-food",
                        path: "/food-beverage/food/processed-food/preserved-food/canned-food/canned-fruits",
                        active: true,
                        meta: {
                          tags: "Canned Fruits",
                          keywords: "Canned Fruits",
                          description: "Canned Fruits",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad40",
                    },
                    slug: "chilled-processed-food",
                    title: "Chilled Processed Food",
                    id: 5000259,
                    parent: "/food-beverage/food/processed-food/preserved-food",
                    path: "/food-beverage/food/processed-food/preserved-food/chilled-processed-food",
                    active: true,
                    meta: {
                      tags: "Chilled Processed Food",
                      keywords: "Chilled Processed Food",
                      description: "Chilled Processed Food",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad41",
                    },
                    slug: "dehydrated-food",
                    title: "Dehydrated Food",
                    id: 5000260,
                    parent: "/food-beverage/food/processed-food/preserved-food",
                    path: "/food-beverage/food/processed-food/preserved-food/dehydrated-food",
                    active: true,
                    meta: {
                      tags: "Dehydrated Food",
                      keywords: "Dehydrated Food",
                      description: "Dehydrated Food",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad42",
                    },
                    slug: "dried-food",
                    title: "Dried Food",
                    id: 5000261,
                    parent: "/food-beverage/food/processed-food/preserved-food",
                    path: "/food-beverage/food/processed-food/preserved-food/dried-food",
                    active: true,
                    meta: {
                      tags: "Dried Food",
                      keywords: "Dried Food",
                      description: "Dried Food",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad43",
                        },
                        slug: "dried-apricots",
                        title: "Dried Apricots",
                        id: 5000262,
                        parent:
                          "/food-beverage/food/processed-food/preserved-food/dried-food",
                        path: "/food-beverage/food/processed-food/preserved-food/dried-food/dried-apricots",
                        active: true,
                        meta: {
                          tags: "Dried Apricots",
                          keywords: "Dried Apricots",
                          description: "Dried Apricots",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad44",
                    },
                    slug: "emergency-food",
                    title: "Emergency Food",
                    id: 5000263,
                    parent: "/food-beverage/food/processed-food/preserved-food",
                    path: "/food-beverage/food/processed-food/preserved-food/emergency-food",
                    active: true,
                    meta: {
                      tags: "Emergency Food",
                      keywords: "Emergency Food",
                      description: "Emergency Food",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad45",
                    },
                    slug: "freeze-dried-food",
                    title: "Freeze Dried Food",
                    id: 5000264,
                    parent: "/food-beverage/food/processed-food/preserved-food",
                    path: "/food-beverage/food/processed-food/preserved-food/freeze-dried-food",
                    active: true,
                    meta: {
                      tags: "Freeze Dried Food",
                      keywords: "Freeze Dried Food",
                      description: "Freeze Dried Food",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad46",
                        },
                        slug: "freeze-dried-fruits-and-vegetables",
                        title: "Freeze Dried Fruits and Vegetables",
                        id: 5000265,
                        parent:
                          "/food-beverage/food/processed-food/preserved-food/freeze-dried-food",
                        path: "/food-beverage/food/processed-food/preserved-food/freeze-dried-food/freeze-dried-fruits-and-vegetables",
                        active: true,
                        meta: {
                          tags: "Freeze Dried Fruits and Vegetables",
                          keywords: "Freeze Dried Fruits and Vegetables",
                          description: "Freeze Dried Fruits and Vegetables",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad47",
                    },
                    slug: "frozen-food",
                    title: "Frozen Food",
                    id: 5000266,
                    parent: "/food-beverage/food/processed-food/preserved-food",
                    path: "/food-beverage/food/processed-food/preserved-food/frozen-food",
                    active: true,
                    meta: {
                      tags: "Frozen Food",
                      keywords: "Frozen Food",
                      description: "Frozen Food",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad48",
                        },
                        slug: "frozen-snacks",
                        title: "Frozen Snacks",
                        id: 5000267,
                        parent:
                          "/food-beverage/food/processed-food/preserved-food/frozen-food",
                        path: "/food-beverage/food/processed-food/preserved-food/frozen-food/frozen-snacks",
                        active: true,
                        meta: {
                          tags: "Frozen Snacks",
                          keywords: "Frozen Snacks",
                          description: "Frozen Snacks",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad49",
                        },
                        slug: "frozen-vegetables",
                        title: "Frozen Vegetables",
                        id: 5000268,
                        parent:
                          "/food-beverage/food/processed-food/preserved-food/frozen-food",
                        path: "/food-beverage/food/processed-food/preserved-food/frozen-food/frozen-vegetables",
                        active: true,
                        meta: {
                          tags: "Frozen Vegetables",
                          keywords: "Frozen Vegetables",
                          description: "Frozen Vegetables",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad4a",
                    },
                    slug: "spray-dried-food",
                    title: "Spray Dried Food",
                    id: 5000269,
                    parent: "/food-beverage/food/processed-food/preserved-food",
                    path: "/food-beverage/food/processed-food/preserved-food/spray-dried-food",
                    active: true,
                    meta: {
                      tags: "Spray Dried Food",
                      keywords: "Spray Dried Food",
                      description: "Spray Dried Food",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad4b",
                },
                slug: "probiotic-products",
                title: "Probiotic Products",
                id: 5000270,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/probiotic-products",
                active: true,
                meta: {
                  tags: "Probiotic Products",
                  keywords: "Probiotic Products",
                  description: "Probiotic Products",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad4c",
                },
                slug: "probiotics",
                title: "Probiotics",
                id: 5000271,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/probiotics",
                active: true,
                meta: {
                  tags: "Probiotics",
                  keywords: "Probiotics",
                  description: "Probiotics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad4d",
                },
                slug: "processed-egg",
                title: "Processed Egg",
                id: 5000272,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/processed-egg",
                active: true,
                meta: {
                  tags: "Processed Egg",
                  keywords: "Processed Egg",
                  description: "Processed Egg",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad4e",
                },
                slug: "ready-mixes",
                title: "Ready Mixes",
                id: 5000273,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/ready-mixes",
                active: true,
                meta: {
                  tags: "Ready Mixes",
                  keywords: "Ready Mixes",
                  description: "Ready Mixes",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad4f",
                    },
                    slug: "dessert-mixes",
                    title: "Dessert Mixes",
                    id: 5000274,
                    parent: "/food-beverage/food/processed-food/ready-mixes",
                    path: "/food-beverage/food/processed-food/ready-mixes/dessert-mixes",
                    active: true,
                    meta: {
                      tags: "Dessert Mixes",
                      keywords: "Dessert Mixes",
                      description: "Dessert Mixes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad50",
                    },
                    slug: "pancake-mixes",
                    title: "Pancake Mixes",
                    id: 5000275,
                    parent: "/food-beverage/food/processed-food/ready-mixes",
                    path: "/food-beverage/food/processed-food/ready-mixes/pancake-mixes",
                    active: true,
                    meta: {
                      tags: "Pancake Mixes",
                      keywords: "Pancake Mixes",
                      description: "Pancake Mixes",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad51",
                },
                slug: "ready-to-eat-food",
                title: "Ready to Eat Food",
                id: 5000276,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/ready-to-eat-food",
                active: true,
                meta: {
                  tags: "Ready to Eat Food",
                  keywords: "Ready to Eat Food",
                  description: "Ready to Eat Food",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad52",
                    },
                    slug: "ready-meals",
                    title: "Ready Meals",
                    id: 5000277,
                    parent:
                      "/food-beverage/food/processed-food/ready-to-eat-food",
                    path: "/food-beverage/food/processed-food/ready-to-eat-food/ready-meals",
                    active: true,
                    meta: {
                      tags: "Ready Meals",
                      keywords: "Ready Meals",
                      description: "Ready Meals",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad53",
                        },
                        slug: "frozen-cooked-ready-meals",
                        title: "Frozen Cooked Ready Meals",
                        id: 5000278,
                        parent:
                          "/food-beverage/food/processed-food/ready-to-eat-food/ready-meals",
                        path: "/food-beverage/food/processed-food/ready-to-eat-food/ready-meals/frozen-cooked-ready-meals",
                        active: true,
                        meta: {
                          tags: "Frozen Cooked Ready Meals",
                          keywords: "Frozen Cooked Ready Meals",
                          description: "Frozen Cooked Ready Meals",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad54",
                        },
                        slug: "gluten-free-ready-meals",
                        title: "Gluten Free Ready Meals",
                        id: 5000279,
                        parent:
                          "/food-beverage/food/processed-food/ready-to-eat-food/ready-meals",
                        path: "/food-beverage/food/processed-food/ready-to-eat-food/ready-meals/gluten-free-ready-meals",
                        active: true,
                        meta: {
                          tags: "Gluten Free Ready Meals",
                          keywords: "Gluten Free Ready Meals",
                          description: "Gluten Free Ready Meals",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad55",
                },
                slug: "sauces-condiments-dressings",
                title: "Sauces, Condiments, Dressings",
                id: 5000280,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/sauces-condiments-dressings",
                active: true,
                meta: {
                  tags: "Sauces, Condiments, Dressings",
                  keywords: "Sauces, Condiments, Dressings",
                  description: "Sauces, Condiments, Dressings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad56",
                    },
                    slug: "ketchup",
                    title: "Ketchup",
                    id: 5000281,
                    parent:
                      "/food-beverage/food/processed-food/sauces-condiments-dressings",
                    path: "/food-beverage/food/processed-food/sauces-condiments-dressings/ketchup",
                    active: true,
                    meta: {
                      tags: "Ketchup",
                      keywords: "Ketchup",
                      description: "Ketchup",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad57",
                    },
                    slug: "pickles",
                    title: "Pickles",
                    id: 5000282,
                    parent:
                      "/food-beverage/food/processed-food/sauces-condiments-dressings",
                    path: "/food-beverage/food/processed-food/sauces-condiments-dressings/pickles",
                    active: true,
                    meta: {
                      tags: "Pickles",
                      keywords: "Pickles",
                      description: "Pickles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad58",
                    },
                    slug: "salad-dressings",
                    title: "Salad Dressings",
                    id: 5000283,
                    parent:
                      "/food-beverage/food/processed-food/sauces-condiments-dressings",
                    path: "/food-beverage/food/processed-food/sauces-condiments-dressings/salad-dressings",
                    active: true,
                    meta: {
                      tags: "Salad Dressings",
                      keywords: "Salad Dressings",
                      description: "Salad Dressings",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad59",
                    },
                    slug: "sauces",
                    title: "Sauces",
                    id: 5000284,
                    parent:
                      "/food-beverage/food/processed-food/sauces-condiments-dressings",
                    path: "/food-beverage/food/processed-food/sauces-condiments-dressings/sauces",
                    active: true,
                    meta: {
                      tags: "Sauces",
                      keywords: "Sauces",
                      description: "Sauces",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad5a",
                        },
                        slug: "fish-sauce",
                        title: "Fish Sauce",
                        id: 5000285,
                        parent:
                          "/food-beverage/food/processed-food/sauces-condiments-dressings/sauces",
                        path: "/food-beverage/food/processed-food/sauces-condiments-dressings/sauces/fish-sauce",
                        active: true,
                        meta: {
                          tags: "Fish Sauce",
                          keywords: "Fish Sauce",
                          description: "Fish Sauce",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad5b",
                    },
                    slug: "vinegar",
                    title: "Vinegar",
                    id: 5000286,
                    parent:
                      "/food-beverage/food/processed-food/sauces-condiments-dressings",
                    path: "/food-beverage/food/processed-food/sauces-condiments-dressings/vinegar",
                    active: true,
                    meta: {
                      tags: "Vinegar",
                      keywords: "Vinegar",
                      description: "Vinegar",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad5c",
                        },
                        slug: "apple-cider-vinegar",
                        title: "Apple Cider Vinegar",
                        id: 5000287,
                        parent:
                          "/food-beverage/food/processed-food/sauces-condiments-dressings/vinegar",
                        path: "/food-beverage/food/processed-food/sauces-condiments-dressings/vinegar/apple-cider-vinegar",
                        active: true,
                        meta: {
                          tags: "Apple Cider Vinegar",
                          keywords: "Apple Cider Vinegar",
                          description: "Apple Cider Vinegar",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad5d",
                        },
                        slug: "wood-vinegar",
                        title: "Wood Vinegar",
                        id: 5000288,
                        parent:
                          "/food-beverage/food/processed-food/sauces-condiments-dressings/vinegar",
                        path: "/food-beverage/food/processed-food/sauces-condiments-dressings/vinegar/wood-vinegar",
                        active: true,
                        meta: {
                          tags: "Wood Vinegar",
                          keywords: "Wood Vinegar",
                          description: "Wood Vinegar",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad5e",
                },
                slug: "seafood",
                title: "Seafood",
                id: 5000289,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/seafood",
                active: true,
                meta: {
                  tags: "Seafood",
                  keywords: "Seafood",
                  description: "Seafood",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad5f",
                    },
                    slug: "canned-seafood",
                    title: "Canned Seafood",
                    id: 5000290,
                    parent: "/food-beverage/food/processed-food/seafood",
                    path: "/food-beverage/food/processed-food/seafood/canned-seafood",
                    active: true,
                    meta: {
                      tags: "Canned Seafood",
                      keywords: "Canned Seafood",
                      description: "Canned Seafood",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad60",
                        },
                        slug: "caviar",
                        title: "Caviar",
                        id: 5000291,
                        parent:
                          "/food-beverage/food/processed-food/seafood/canned-seafood",
                        path: "/food-beverage/food/processed-food/seafood/canned-seafood/caviar",
                        active: true,
                        meta: {
                          tags: "Caviar",
                          keywords: "Caviar",
                          description: "Caviar",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad61",
                        },
                        slug: "tinned-fish",
                        title: "Tinned Fish",
                        id: 5000292,
                        parent:
                          "/food-beverage/food/processed-food/seafood/canned-seafood",
                        path: "/food-beverage/food/processed-food/seafood/canned-seafood/tinned-fish",
                        active: true,
                        meta: {
                          tags: "Tinned Fish",
                          keywords: "Tinned Fish",
                          description: "Tinned Fish",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad62",
                    },
                    slug: "dried-seafood",
                    title: "Dried Seafood",
                    id: 5000293,
                    parent: "/food-beverage/food/processed-food/seafood",
                    path: "/food-beverage/food/processed-food/seafood/dried-seafood",
                    active: true,
                    meta: {
                      tags: "Dried Seafood",
                      keywords: "Dried Seafood",
                      description: "Dried Seafood",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad63",
                        },
                        slug: "dried-scallops",
                        title: "Dried Scallops",
                        id: 5000294,
                        parent:
                          "/food-beverage/food/processed-food/seafood/dried-seafood",
                        path: "/food-beverage/food/processed-food/seafood/dried-seafood/dried-scallops",
                        active: true,
                        meta: {
                          tags: "Dried Scallops",
                          keywords: "Dried Scallops",
                          description: "Dried Scallops",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad64",
                    },
                    slug: "frozen-and-canned-seafood",
                    title: "Frozen and Canned Seafood",
                    id: 5000295,
                    parent: "/food-beverage/food/processed-food/seafood",
                    path: "/food-beverage/food/processed-food/seafood/frozen-and-canned-seafood",
                    active: true,
                    meta: {
                      tags: "Frozen and Canned Seafood",
                      keywords: "Frozen and Canned Seafood",
                      description: "Frozen and Canned Seafood",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad65",
                    },
                    slug: "shrimp",
                    title: "Shrimp",
                    id: 5000296,
                    parent: "/food-beverage/food/processed-food/seafood",
                    path: "/food-beverage/food/processed-food/seafood/shrimp",
                    active: true,
                    meta: {
                      tags: "Shrimp",
                      keywords: "Shrimp",
                      description: "Shrimp",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad66",
                    },
                    slug: "fish",
                    title: "Fish",
                    id: 5000297,
                    parent: "/food-beverage/food/processed-food/seafood",
                    path: "/food-beverage/food/processed-food/seafood/fish",
                    active: true,
                    meta: {
                      tags: "Fish",
                      keywords: "Fish",
                      description: "Fish",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad67",
                        },
                        slug: "organic-fish",
                        title: "Organic Fish",
                        id: 5000298,
                        parent:
                          "/food-beverage/food/processed-food/seafood/fish",
                        path: "/food-beverage/food/processed-food/seafood/fish/organic-fish",
                        active: true,
                        meta: {
                          tags: "Organic Fish",
                          keywords: "Organic Fish",
                          description: "Organic Fish",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad68",
                },
                slug: "snacks",
                title: "Snacks",
                id: 5000299,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/snacks",
                active: true,
                meta: {
                  tags: "Snacks",
                  keywords: "Snacks",
                  description: "Snacks",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad69",
                    },
                    slug: "dairy-snacks",
                    title: "Dairy Snacks",
                    id: 5000300,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/dairy-snacks",
                    active: true,
                    meta: {
                      tags: "Dairy Snacks",
                      keywords: "Dairy Snacks",
                      description: "Dairy Snacks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad6a",
                    },
                    slug: "fruit-snacks",
                    title: "Fruit Snacks",
                    id: 5000301,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/fruit-snacks",
                    active: true,
                    meta: {
                      tags: "Fruit Snacks",
                      keywords: "Fruit Snacks",
                      description: "Fruit Snacks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad6b",
                    },
                    slug: "meat-snacks",
                    title: "Meat Snacks",
                    id: 5000302,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/meat-snacks",
                    active: true,
                    meta: {
                      tags: "Meat Snacks",
                      keywords: "Meat Snacks",
                      description: "Meat Snacks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad6c",
                    },
                    slug: "nuts-and-seeds",
                    title: "Nuts and Seeds",
                    id: 5000303,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/nuts-and-seeds",
                    active: true,
                    meta: {
                      tags: "Nuts and Seeds",
                      keywords: "Nuts and Seeds",
                      description: "Nuts and Seeds",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad6d",
                        },
                        slug: "nuts",
                        title: "Nuts",
                        id: 5000304,
                        parent:
                          "/food-beverage/food/processed-food/snacks/nuts-and-seeds",
                        path: "/food-beverage/food/processed-food/snacks/nuts-and-seeds/nuts",
                        active: true,
                        meta: {
                          tags: "Nuts",
                          keywords: "Nuts",
                          description: "Nuts",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ad6e",
                            },
                            slug: "processed-cashew",
                            title: "Processed Cashew",
                            id: 5000305,
                            parent:
                              "/food-beverage/food/processed-food/snacks/nuts-and-seeds/nuts",
                            path: "/food-beverage/food/processed-food/snacks/nuts-and-seeds/nuts/processed-cashew",
                            active: true,
                            meta: {
                              tags: "Processed Cashew",
                              keywords: "Processed Cashew",
                              description: "Processed Cashew",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ad6f",
                            },
                            slug: "processed-peanuts",
                            title: "Processed Peanuts",
                            id: 5000306,
                            parent:
                              "/food-beverage/food/processed-food/snacks/nuts-and-seeds/nuts",
                            path: "/food-beverage/food/processed-food/snacks/nuts-and-seeds/nuts/processed-peanuts",
                            active: true,
                            meta: {
                              tags: "Processed Peanuts",
                              keywords: "Processed Peanuts",
                              description: "Processed Peanuts",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ad70",
                            },
                            slug: "processed-walnuts",
                            title: "Processed Walnuts",
                            id: 5000307,
                            parent:
                              "/food-beverage/food/processed-food/snacks/nuts-and-seeds/nuts",
                            path: "/food-beverage/food/processed-food/snacks/nuts-and-seeds/nuts/processed-walnuts",
                            active: true,
                            meta: {
                              tags: "Processed Walnuts",
                              keywords: "Processed Walnuts",
                              description: "Processed Walnuts",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad71",
                    },
                    slug: "oat-based-snacks",
                    title: "Oat Based Snacks",
                    id: 5000308,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/oat-based-snacks",
                    active: true,
                    meta: {
                      tags: "Oat Based Snacks",
                      keywords: "Oat Based Snacks",
                      description: "Oat Based Snacks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad72",
                    },
                    slug: "popcorn",
                    title: "Popcorn",
                    id: 5000309,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/popcorn",
                    active: true,
                    meta: {
                      tags: "Popcorn",
                      keywords: "Popcorn",
                      description: "Popcorn",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad73",
                    },
                    slug: "premium-snacks",
                    title: "Premium Snacks",
                    id: 5000310,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/premium-snacks",
                    active: true,
                    meta: {
                      tags: "Premium Snacks",
                      keywords: "Premium Snacks",
                      description: "Premium Snacks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad74",
                    },
                    slug: "rice-snacks",
                    title: "Rice Snacks",
                    id: 5000311,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/rice-snacks",
                    active: true,
                    meta: {
                      tags: "Rice Snacks",
                      keywords: "Rice Snacks",
                      description: "Rice Snacks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad75",
                    },
                    slug: "savory-snacks",
                    title: "Savory Snacks",
                    id: 5000312,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/savory-snacks",
                    active: true,
                    meta: {
                      tags: "Savory Snacks",
                      keywords: "Savory Snacks",
                      description: "Savory Snacks",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad76",
                        },
                        slug: "baked-savory-snacks",
                        title: "Baked Savory Snacks",
                        id: 5000313,
                        parent:
                          "/food-beverage/food/processed-food/snacks/savory-snacks",
                        path: "/food-beverage/food/processed-food/snacks/savory-snacks/baked-savory-snacks",
                        active: true,
                        meta: {
                          tags: "Baked Savory Snacks",
                          keywords: "Baked Savory Snacks",
                          description: "Baked Savory Snacks",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad77",
                        },
                        slug: "chips",
                        title: "Chips",
                        id: 5000314,
                        parent:
                          "/food-beverage/food/processed-food/snacks/savory-snacks",
                        path: "/food-beverage/food/processed-food/snacks/savory-snacks/chips",
                        active: true,
                        meta: {
                          tags: "Chips",
                          keywords: "Chips",
                          description: "Chips",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ad78",
                            },
                            slug: "baked-chips",
                            title: "Baked Chips",
                            id: 5000315,
                            parent:
                              "/food-beverage/food/processed-food/snacks/savory-snacks/chips",
                            path: "/food-beverage/food/processed-food/snacks/savory-snacks/chips/baked-chips",
                            active: true,
                            meta: {
                              tags: "Baked Chips",
                              keywords: "Baked Chips",
                              description: "Baked Chips",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ad79",
                            },
                            slug: "kale-chips",
                            title: "Kale Chips",
                            id: 5000316,
                            parent:
                              "/food-beverage/food/processed-food/snacks/savory-snacks/chips",
                            path: "/food-beverage/food/processed-food/snacks/savory-snacks/chips/kale-chips",
                            active: true,
                            meta: {
                              tags: "Kale Chips",
                              keywords: "Kale Chips",
                              description: "Kale Chips",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ad7a",
                            },
                            slug: "potato-chips",
                            title: "Potato Chips",
                            id: 5000317,
                            parent:
                              "/food-beverage/food/processed-food/snacks/savory-snacks/chips",
                            path: "/food-beverage/food/processed-food/snacks/savory-snacks/chips/potato-chips",
                            active: true,
                            meta: {
                              tags: "Potato Chips",
                              keywords: "Potato Chips",
                              description: "Potato Chips",
                            },
                            sub_categories: [
                              {
                                _id: {
                                  $oid: "64ad97c004b8af526096ad7b",
                                },
                                slug: "fried-potato-chips",
                                title: "Fried Potato Chips",
                                id: 5000318,
                                parent:
                                  "/food-beverage/food/processed-food/snacks/savory-snacks/chips/potato-chips",
                                path: "/food-beverage/food/processed-food/snacks/savory-snacks/chips/potato-chips/fried-potato-chips",
                                active: true,
                                meta: {
                                  tags: "Fried Potato Chips",
                                  keywords: "Fried Potato Chips",
                                  description: "Fried Potato Chips",
                                },
                                sub_categories: [],
                              },
                            ],
                          },
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ad7c",
                            },
                            slug: "tortilla-chips",
                            title: "Tortilla Chips",
                            id: 5000319,
                            parent:
                              "/food-beverage/food/processed-food/snacks/savory-snacks/chips",
                            path: "/food-beverage/food/processed-food/snacks/savory-snacks/chips/tortilla-chips",
                            active: true,
                            meta: {
                              tags: "Tortilla Chips",
                              keywords: "Tortilla Chips",
                              description: "Tortilla Chips",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ad7d",
                        },
                        slug: "pretzel",
                        title: "Pretzel",
                        id: 5000320,
                        parent:
                          "/food-beverage/food/processed-food/snacks/savory-snacks",
                        path: "/food-beverage/food/processed-food/snacks/savory-snacks/pretzel",
                        active: true,
                        meta: {
                          tags: "Pretzel",
                          keywords: "Pretzel",
                          description: "Pretzel",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad7e",
                    },
                    slug: "snack-food",
                    title: "Snack Food",
                    id: 5000321,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/snack-food",
                    active: true,
                    meta: {
                      tags: "Snack Food",
                      keywords: "Snack Food",
                      description: "Snack Food",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad7f",
                    },
                    slug: "specialty-snacks",
                    title: "Specialty Snacks",
                    id: 5000322,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/specialty-snacks",
                    active: true,
                    meta: {
                      tags: "Specialty Snacks",
                      keywords: "Specialty Snacks",
                      description: "Specialty Snacks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad80",
                    },
                    slug: "trail-mix",
                    title: "Trail Mix",
                    id: 5000323,
                    parent: "/food-beverage/food/processed-food/snacks",
                    path: "/food-beverage/food/processed-food/snacks/trail-mix",
                    active: true,
                    meta: {
                      tags: "Trail Mix",
                      keywords: "Trail Mix",
                      description: "Trail Mix",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad81",
                },
                slug: "soups",
                title: "Soups",
                id: 5000324,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/soups",
                active: true,
                meta: {
                  tags: "Soups",
                  keywords: "Soups",
                  description: "Soups",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad82",
                    },
                    slug: "canned-soup",
                    title: "Canned Soup",
                    id: 5000325,
                    parent: "/food-beverage/food/processed-food/soups",
                    path: "/food-beverage/food/processed-food/soups/canned-soup",
                    active: true,
                    meta: {
                      tags: "Canned Soup",
                      keywords: "Canned Soup",
                      description: "Canned Soup",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad83",
                    },
                    slug: "dried-soups",
                    title: "Dried Soups",
                    id: 5000326,
                    parent: "/food-beverage/food/processed-food/soups",
                    path: "/food-beverage/food/processed-food/soups/dried-soups",
                    active: true,
                    meta: {
                      tags: "Dried Soups",
                      keywords: "Dried Soups",
                      description: "Dried Soups",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad84",
                },
                slug: "soy-based-food",
                title: "Soy Based Food",
                id: 5000327,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/soy-based-food",
                active: true,
                meta: {
                  tags: "Soy Based Food",
                  keywords: "Soy Based Food",
                  description: "Soy Based Food",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad85",
                },
                slug: "specialty-diets",
                title: "Specialty Diets",
                id: 5000328,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/specialty-diets",
                active: true,
                meta: {
                  tags: "Specialty Diets",
                  keywords: "Specialty Diets",
                  description: "Specialty Diets",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad86",
                    },
                    slug: "ketogenic-diet-food",
                    title: "Ketogenic Diet Food",
                    id: 5000329,
                    parent:
                      "/food-beverage/food/processed-food/specialty-diets",
                    path: "/food-beverage/food/processed-food/specialty-diets/ketogenic-diet-food",
                    active: true,
                    meta: {
                      tags: "Ketogenic Diet Food",
                      keywords: "Ketogenic Diet Food",
                      description: "Ketogenic Diet Food",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad87",
                    },
                    slug: "low-calorie-food",
                    title: "Low Calorie Food",
                    id: 5000330,
                    parent:
                      "/food-beverage/food/processed-food/specialty-diets",
                    path: "/food-beverage/food/processed-food/specialty-diets/low-calorie-food",
                    active: true,
                    meta: {
                      tags: "Low Calorie Food",
                      keywords: "Low Calorie Food",
                      description: "Low Calorie Food",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad88",
                    },
                    slug: "low-carb-food",
                    title: "Low Carb Food",
                    id: 5000331,
                    parent:
                      "/food-beverage/food/processed-food/specialty-diets",
                    path: "/food-beverage/food/processed-food/specialty-diets/low-carb-food",
                    active: true,
                    meta: {
                      tags: "Low Carb Food",
                      keywords: "Low Carb Food",
                      description: "Low Carb Food",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad89",
                    },
                    slug: "low-sodium-food",
                    title: "Low Sodium Food",
                    id: 5000332,
                    parent:
                      "/food-beverage/food/processed-food/specialty-diets",
                    path: "/food-beverage/food/processed-food/specialty-diets/low-sodium-food",
                    active: true,
                    meta: {
                      tags: "Low Sodium Food",
                      keywords: "Low Sodium Food",
                      description: "Low Sodium Food",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad8a",
                    },
                    slug: "meal-replacement-products",
                    title: "Meal Replacement Products",
                    id: 5000333,
                    parent:
                      "/food-beverage/food/processed-food/specialty-diets",
                    path: "/food-beverage/food/processed-food/specialty-diets/meal-replacement-products",
                    active: true,
                    meta: {
                      tags: "Meal Replacement Products",
                      keywords: "Meal Replacement Products",
                      description: "Meal Replacement Products",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad8b",
                },
                slug: "sports-nutrition",
                title: "Sports Nutrition",
                id: 5000334,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/sports-nutrition",
                active: true,
                meta: {
                  tags: "Sports Nutrition",
                  keywords: "Sports Nutrition",
                  description: "Sports Nutrition",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad8c",
                    },
                    slug: "recovery-drinks",
                    title: "Recovery Drinks",
                    id: 5000335,
                    parent:
                      "/food-beverage/food/processed-food/sports-nutrition",
                    path: "/food-beverage/food/processed-food/sports-nutrition/recovery-drinks",
                    active: true,
                    meta: {
                      tags: "Recovery Drinks",
                      keywords: "Recovery Drinks",
                      description: "Recovery Drinks",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad8d",
                },
                slug: "syrups",
                title: "Syrups",
                id: 5000336,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/syrups",
                active: true,
                meta: {
                  tags: "Syrups",
                  keywords: "Syrups",
                  description: "Syrups",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad8e",
                    },
                    slug: "maple-syrup",
                    title: "Maple Syrup",
                    id: 5000337,
                    parent: "/food-beverage/food/processed-food/syrups",
                    path: "/food-beverage/food/processed-food/syrups/maple-syrup",
                    active: true,
                    meta: {
                      tags: "Maple Syrup",
                      keywords: "Maple Syrup",
                      description: "Maple Syrup",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad8f",
                },
                slug: "vegan-foods",
                title: "Vegan Foods",
                id: 5000338,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/vegan-foods",
                active: true,
                meta: {
                  tags: "Vegan Foods",
                  keywords: "Vegan Foods",
                  description: "Vegan Foods",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad90",
                },
                slug: "weight-management-products",
                title: "Weight Management Products",
                id: 5000339,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/weight-management-products",
                active: true,
                meta: {
                  tags: "Weight Management Products",
                  keywords: "Weight Management Products",
                  description: "Weight Management Products",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad91",
                },
                slug: "wheatgrass-products",
                title: "Wheatgrass Products",
                id: 5000340,
                parent: "/food-beverage/food/processed-food",
                path: "/food-beverage/food/processed-food/wheatgrass-products",
                active: true,
                meta: {
                  tags: "Wheatgrass Products",
                  keywords: "Wheatgrass Products",
                  description: "Wheatgrass Products",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b195",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Ingredient market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Ingredient Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Ingredient market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Ingredient Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Ingredient Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Ingredient Market Research Reports, ingredient, ingredient market, ingredient market research report, ingredient industry research report, ingredient industry, ingredient market intelligence report.",
          description:
            "Mordor Intelligence™ 【  Ingredient Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Ingredient",
        slug: "ingredient",
        id: 62182,
        __v: 0,
        parent: "/food-beverage",
        path: "/food-beverage/ingredient",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b196",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Other Food & Beverage Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Food & Beverage Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Other Food & Beverage Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Food & Beverage Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Other Food & Beverage Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Food & Beverage Market Research Reports, food & beverage market, food & beverage market report, food & beverage market research, food & beverage industry, food & beverage industry report, food & beverage industry research",
          description:
            "Mordor Intelligence™ 【  Other Food & Beverage Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Other Food & Beverage Research",
        slug: "food-beverage-others",
        id: 62183,
        __v: 0,
        parent: "/food-beverage",
        path: "/food-beverage/food-beverage-others",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64ad97c004b8af526096ad92",
        },
        slug: "food-ingredients-and-food-additives",
        title: "Food Ingredients and Food Additives",
        id: 62622,
        parent: "/food-beverage",
        path: "/food-beverage/food-ingredients-and-food-additives",
        active: true,
        meta: {
          tags: "Food Ingredients and Food Additives",
          keywords: "Food Ingredients and Food Additives",
          description: "Food Ingredients and Food Additives",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad97c004b8af526096ad93",
            },
            slug: "alcohol-ingredients",
            title: "Alcohol Ingredients",
            id: 5000342,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/alcohol-ingredients",
            active: true,
            meta: {
              tags: "Alcohol Ingredients",
              keywords: "Alcohol Ingredients",
              description: "Alcohol Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ad94",
            },
            slug: "almond-ingredients",
            title: "Almond Ingredients",
            id: 5000343,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/almond-ingredients",
            active: true,
            meta: {
              tags: "Almond Ingredients",
              keywords: "Almond Ingredients",
              description: "Almond Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ad95",
            },
            slug: "aloe-vera-extracts",
            title: "Aloe Vera Extracts",
            id: 5000344,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/aloe-vera-extracts",
            active: true,
            meta: {
              tags: "Aloe Vera Extracts",
              keywords: "Aloe Vera Extracts",
              description: "Aloe Vera Extracts",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ad96",
            },
            slug: "anti-caking-agents",
            title: "Anti Caking Agents",
            id: 5000345,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/anti-caking-agents",
            active: true,
            meta: {
              tags: "Anti Caking Agents",
              keywords: "Anti Caking Agents",
              description: "Anti Caking Agents",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ad97",
            },
            slug: "antifoaming-agents",
            title: "Antifoaming Agents",
            id: 5000346,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/antifoaming-agents",
            active: true,
            meta: {
              tags: "Antifoaming Agents",
              keywords: "Antifoaming Agents",
              description: "Antifoaming Agents",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ad98",
            },
            slug: "apiculture",
            title: "Apiculture",
            id: 5000347,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/apiculture",
            active: true,
            meta: {
              tags: "Apiculture",
              keywords: "Apiculture",
              description: "Apiculture",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ad99",
            },
            slug: "apricot-oil",
            title: "Apricot Oil",
            id: 5000348,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/apricot-oil",
            active: true,
            meta: {
              tags: "Apricot Oil",
              keywords: "Apricot Oil",
              description: "Apricot Oil",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ad9a",
            },
            slug: "bakery-ingredients",
            title: "Bakery Ingredients",
            id: 5000349,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/bakery-ingredients",
            active: true,
            meta: {
              tags: "Bakery Ingredients",
              keywords: "Bakery Ingredients",
              description: "Bakery Ingredients",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad9b",
                },
                slug: "bread-mixes",
                title: "Bread Mixes",
                id: 5000350,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/bakery-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/bakery-ingredients/bread-mixes",
                active: true,
                meta: {
                  tags: "Bread Mixes",
                  keywords: "Bread Mixes",
                  description: "Bread Mixes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad9c",
                },
                slug: "sourdough",
                title: "Sourdough",
                id: 5000351,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/bakery-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/bakery-ingredients/sourdough",
                active: true,
                meta: {
                  tags: "Sourdough",
                  keywords: "Sourdough",
                  description: "Sourdough",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ad9d",
                },
                slug: "yeast-ingredients",
                title: "Yeast Ingredients",
                id: 5000352,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/bakery-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/bakery-ingredients/yeast-ingredients",
                active: true,
                meta: {
                  tags: "Yeast Ingredients",
                  keywords: "Yeast Ingredients",
                  description: "Yeast Ingredients",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ad9e",
                    },
                    slug: "bakers-yeast",
                    title: "Bakers Yeast",
                    id: 5000353,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/bakery-ingredients/yeast-ingredients",
                    path: "/food-beverage/food-ingredients-and-food-additives/bakery-ingredients/yeast-ingredients/bakers-yeast",
                    active: true,
                    meta: {
                      tags: "Bakers Yeast",
                      keywords: "Bakers Yeast",
                      description: "Bakers Yeast",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ad9f",
            },
            slug: "baking-soda-substitutes",
            title: "Baking Soda Substitutes",
            id: 5000354,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/baking-soda-substitutes",
            active: true,
            meta: {
              tags: "Baking Soda Substitutes",
              keywords: "Baking Soda Substitutes",
              description: "Baking Soda Substitutes",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ada0",
            },
            slug: "bioactive-ingredients",
            title: "Bioactive Ingredients",
            id: 5000355,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/bioactive-ingredients",
            active: true,
            meta: {
              tags: "Bioactive Ingredients",
              keywords: "Bioactive Ingredients",
              description: "Bioactive Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ada1",
            },
            slug: "botanicals",
            title: "Botanicals",
            id: 5000356,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/botanicals",
            active: true,
            meta: {
              tags: "Botanicals",
              keywords: "Botanicals",
              description: "Botanicals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ada2",
            },
            slug: "bulk-food-ingredients",
            title: "Bulk Food Ingredients",
            id: 5000357,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/bulk-food-ingredients",
            active: true,
            meta: {
              tags: "Bulk Food Ingredients",
              keywords: "Bulk Food Ingredients",
              description: "Bulk Food Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ada3",
            },
            slug: "cassia-gum",
            title: "Cassia Gum",
            id: 5000358,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/cassia-gum",
            active: true,
            meta: {
              tags: "Cassia Gum",
              keywords: "Cassia Gum",
              description: "Cassia Gum",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ada4",
            },
            slug: "cereal-ingredients",
            title: "Cereal Ingredients",
            id: 5000359,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/cereal-ingredients",
            active: true,
            meta: {
              tags: "Cereal Ingredients",
              keywords: "Cereal Ingredients",
              description: "Cereal Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ada5",
            },
            slug: "clean-label-ingredients",
            title: "Clean Label Ingredients",
            id: 5000360,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/clean-label-ingredients",
            active: true,
            meta: {
              tags: "Clean Label Ingredients",
              keywords: "Clean Label Ingredients",
              description: "Clean Label Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ada6",
            },
            slug: "confectionery-ingredients",
            title: "Confectionery Ingredients",
            id: 5000361,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/confectionery-ingredients",
            active: true,
            meta: {
              tags: "Confectionery Ingredients",
              keywords: "Confectionery Ingredients",
              description: "Confectionery Ingredients",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ada7",
                },
                slug: "confectionery-fillings",
                title: "Confectionery Fillings",
                id: 5000362,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/confectionery-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/confectionery-ingredients/confectionery-fillings",
                active: true,
                meta: {
                  tags: "Confectionery Fillings",
                  keywords: "Confectionery Fillings",
                  description: "Confectionery Fillings",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ada8",
            },
            slug: "dairy-ingredients",
            title: "Dairy Ingredients",
            id: 5000363,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/dairy-ingredients",
            active: true,
            meta: {
              tags: "Dairy Ingredients",
              keywords: "Dairy Ingredients",
              description: "Dairy Ingredients",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ada9",
                },
                slug: "dairy-blends",
                title: "Dairy Blends",
                id: 5000364,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/dairy-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/dairy-ingredients/dairy-blends",
                active: true,
                meta: {
                  tags: "Dairy Blends",
                  keywords: "Dairy Blends",
                  description: "Dairy Blends",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adaa",
            },
            slug: "dextrose",
            title: "Dextrose",
            id: 5000365,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/dextrose",
            active: true,
            meta: {
              tags: "Dextrose",
              keywords: "Dextrose",
              description: "Dextrose",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adab",
            },
            slug: "egg-replacers",
            title: "Egg Replacers",
            id: 5000366,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/egg-replacers",
            active: true,
            meta: {
              tags: "Egg Replacers",
              keywords: "Egg Replacers",
              description: "Egg Replacers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adac",
            },
            slug: "essential-oils",
            title: "Essential Oils",
            id: 5000367,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/essential-oils",
            active: true,
            meta: {
              tags: "Essential Oils",
              keywords: "Essential Oils",
              description: "Essential Oils",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adad",
            },
            slug: "fats-and-oils",
            title: "Fats and Oils",
            id: 5000368,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
            active: true,
            meta: {
              tags: "Fats and Oils",
              keywords: "Fats and Oils",
              description: "Fats and Oils",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096adae",
                },
                slug: "coconut-oil",
                title: "Coconut Oil",
                id: 5000369,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/coconut-oil",
                active: true,
                meta: {
                  tags: "Coconut Oil",
                  keywords: "Coconut Oil",
                  description: "Coconut Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adaf",
                },
                slug: "cooking-oil",
                title: "Cooking Oil",
                id: 5000370,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/cooking-oil",
                active: true,
                meta: {
                  tags: "Cooking Oil",
                  keywords: "Cooking Oil",
                  description: "Cooking Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adb0",
                },
                slug: "margarine",
                title: "Margarine",
                id: 5000371,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/margarine",
                active: true,
                meta: {
                  tags: "Margarine",
                  keywords: "Margarine",
                  description: "Margarine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adb1",
                },
                slug: "mustard-oil",
                title: "Mustard Oil",
                id: 5000372,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/mustard-oil",
                active: true,
                meta: {
                  tags: "Mustard Oil",
                  keywords: "Mustard Oil",
                  description: "Mustard Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adb2",
                },
                slug: "peanut-oil",
                title: "Peanut Oil",
                id: 5000373,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/peanut-oil",
                active: true,
                meta: {
                  tags: "Peanut Oil",
                  keywords: "Peanut Oil",
                  description: "Peanut Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adb3",
                },
                slug: "rice-bran-oil",
                title: "Rice Bran Oil",
                id: 5000374,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/rice-bran-oil",
                active: true,
                meta: {
                  tags: "Rice Bran Oil",
                  keywords: "Rice Bran Oil",
                  description: "Rice Bran Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adb4",
                },
                slug: "shortening-fats",
                title: "Shortening Fats",
                id: 5000375,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/shortening-fats",
                active: true,
                meta: {
                  tags: "Shortening Fats",
                  keywords: "Shortening Fats",
                  description: "Shortening Fats",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adb5",
                },
                slug: "soybean-oil",
                title: "Soybean Oil",
                id: 5000376,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/soybean-oil",
                active: true,
                meta: {
                  tags: "Soybean Oil",
                  keywords: "Soybean Oil",
                  description: "Soybean Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adb6",
                },
                slug: "specialty-fats-and-oils",
                title: "Specialty Fats and Oils",
                id: 5000377,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/specialty-fats-and-oils",
                active: true,
                meta: {
                  tags: "Specialty Fats and Oils",
                  keywords: "Specialty Fats and Oils",
                  description: "Specialty Fats and Oils",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adb7",
                },
                slug: "sunflower-oil",
                title: "Sunflower Oil",
                id: 5000378,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/sunflower-oil",
                active: true,
                meta: {
                  tags: "Sunflower Oil",
                  keywords: "Sunflower Oil",
                  description: "Sunflower Oil",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adb8",
                },
                slug: "vegetable-oil",
                title: "Vegetable Oil",
                id: 5000379,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fats-and-oils",
                path: "/food-beverage/food-ingredients-and-food-additives/fats-and-oils/vegetable-oil",
                active: true,
                meta: {
                  tags: "Vegetable Oil",
                  keywords: "Vegetable Oil",
                  description: "Vegetable Oil",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adb9",
            },
            slug: "fermented-ingredients",
            title: "Fermented Ingredients",
            id: 5000380,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/fermented-ingredients",
            active: true,
            meta: {
              tags: "Fermented Ingredients",
              keywords: "Fermented Ingredients",
              description: "Fermented Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adba",
            },
            slug: "flavor-and-seasoning",
            title: "Flavor and Seasoning",
            id: 5000381,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning",
            active: true,
            meta: {
              tags: "Flavor and Seasoning",
              keywords: "Flavor and Seasoning",
              description: "Flavor and Seasoning",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096adbb",
                },
                slug: "flavor-and-flavor-enhancer",
                title: "Flavor and Flavor Enhancer",
                id: 5000382,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning",
                path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer",
                active: true,
                meta: {
                  tags: "Flavor and Flavor Enhancer",
                  keywords: "Flavor and Flavor Enhancer",
                  description: "Flavor and Flavor Enhancer",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096adbc",
                    },
                    slug: "flavor-and-fragrance",
                    title: "Flavor and Fragrance",
                    id: 5000383,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer",
                    path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/flavor-and-fragrance",
                    active: true,
                    meta: {
                      tags: "Flavor and Fragrance",
                      keywords: "Flavor and Fragrance",
                      description: "Flavor and Fragrance",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096adbd",
                    },
                    slug: "flavor-enhancers",
                    title: "Flavor Enhancers",
                    id: 5000384,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer",
                    path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/flavor-enhancers",
                    active: true,
                    meta: {
                      tags: "Flavor Enhancers",
                      keywords: "Flavor Enhancers",
                      description: "Flavor Enhancers",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adbe",
                        },
                        slug: "msg",
                        title: "MSG",
                        id: 5000385,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/flavor-enhancers",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/flavor-enhancers/msg",
                        active: true,
                        meta: {
                          tags: "MSG",
                          keywords: "MSG",
                          description: "MSG",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096adbf",
                    },
                    slug: "food-flavors",
                    title: "Food Flavors",
                    id: 5000386,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer",
                    path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors",
                    active: true,
                    meta: {
                      tags: "Food Flavors",
                      keywords: "Food Flavors",
                      description: "Food Flavors",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adc0",
                        },
                        slug: "caramel-ingredients",
                        title: "Caramel Ingredients",
                        id: 5000387,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors/caramel-ingredients",
                        active: true,
                        meta: {
                          tags: "Caramel Ingredients",
                          keywords: "Caramel Ingredients",
                          description: "Caramel Ingredients",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adc1",
                        },
                        slug: "cetyl-stearyl-alcohol",
                        title: "Cetyl Stearyl Alcohol",
                        id: 5000388,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors/cetyl-stearyl-alcohol",
                        active: true,
                        meta: {
                          tags: "Cetyl Stearyl Alcohol",
                          keywords: "Cetyl Stearyl Alcohol",
                          description: "Cetyl Stearyl Alcohol",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adc2",
                        },
                        slug: "floral-flavors",
                        title: "Floral Flavors",
                        id: 5000389,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors/floral-flavors",
                        active: true,
                        meta: {
                          tags: "Floral Flavors",
                          keywords: "Floral Flavors",
                          description: "Floral Flavors",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adc3",
                        },
                        slug: "limonene",
                        title: "Limonene",
                        id: 5000390,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors/limonene",
                        active: true,
                        meta: {
                          tags: "Limonene",
                          keywords: "Limonene",
                          description: "Limonene",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adc4",
                        },
                        slug: "meat-flavors",
                        title: "Meat Flavors",
                        id: 5000391,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors/meat-flavors",
                        active: true,
                        meta: {
                          tags: "Meat Flavors",
                          keywords: "Meat Flavors",
                          description: "Meat Flavors",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adc5",
                        },
                        slug: "smoke-ingredients",
                        title: "Smoke Ingredients",
                        id: 5000392,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors/smoke-ingredients",
                        active: true,
                        meta: {
                          tags: "Smoke Ingredients",
                          keywords: "Smoke Ingredients",
                          description: "Smoke Ingredients",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adc6",
                        },
                        slug: "vanillin",
                        title: "Vanillin",
                        id: 5000393,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors/vanillin",
                        active: true,
                        meta: {
                          tags: "Vanillin",
                          keywords: "Vanillin",
                          description: "Vanillin",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096adc7",
                            },
                            slug: "bio-vanillin",
                            title: "Bio Vanillin",
                            id: 5000394,
                            parent:
                              "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors/vanillin",
                            path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/flavor-and-flavor-enhancer/food-flavors/vanillin/bio-vanillin",
                            active: true,
                            meta: {
                              tags: "Bio Vanillin",
                              keywords: "Bio Vanillin",
                              description: "Bio Vanillin",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adc8",
                },
                slug: "seasoning-and-spices",
                title: "Seasoning and Spices",
                id: 5000395,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning",
                path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices",
                active: true,
                meta: {
                  tags: "Seasoning and Spices",
                  keywords: "Seasoning and Spices",
                  description: "Seasoning and Spices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096adc9",
                    },
                    slug: "annatto",
                    title: "Annatto",
                    id: 5000396,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices",
                    path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/annatto",
                    active: true,
                    meta: {
                      tags: "Annatto",
                      keywords: "Annatto",
                      description: "Annatto",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096adca",
                    },
                    slug: "salt",
                    title: "Salt",
                    id: 5000397,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices",
                    path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/salt",
                    active: true,
                    meta: {
                      tags: "Salt",
                      keywords: "Salt",
                      description: "Salt",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adcb",
                        },
                        slug: "gourmet-salt",
                        title: "Gourmet Salt",
                        id: 5000398,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/salt",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/salt/gourmet-salt",
                        active: true,
                        meta: {
                          tags: "Gourmet Salt",
                          keywords: "Gourmet Salt",
                          description: "Gourmet Salt",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adcc",
                        },
                        slug: "pink-himalayan-salt",
                        title: "Pink Himalayan Salt",
                        id: 5000399,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/salt",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/salt/pink-himalayan-salt",
                        active: true,
                        meta: {
                          tags: "Pink Himalayan Salt",
                          keywords: "Pink Himalayan Salt",
                          description: "Pink Himalayan Salt",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096adcd",
                    },
                    slug: "spice-and-herb-extract",
                    title: "Spice and Herb Extract",
                    id: 5000400,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices",
                    path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/spice-and-herb-extract",
                    active: true,
                    meta: {
                      tags: "Spice and Herb Extract",
                      keywords: "Spice and Herb Extract",
                      description: "Spice and Herb Extract",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adce",
                        },
                        slug: "curcumin",
                        title: "Curcumin",
                        id: 5000401,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/spice-and-herb-extract",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/spice-and-herb-extract/curcumin",
                        active: true,
                        meta: {
                          tags: "Curcumin",
                          keywords: "Curcumin",
                          description: "Curcumin",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096adcf",
                        },
                        slug: "oleoresins",
                        title: "Oleoresins",
                        id: 5000402,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/spice-and-herb-extract",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/spice-and-herb-extract/oleoresins",
                        active: true,
                        meta: {
                          tags: "Oleoresins",
                          keywords: "Oleoresins",
                          description: "Oleoresins",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096add0",
                        },
                        slug: "vanilla",
                        title: "Vanilla",
                        id: 5000403,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/spice-and-herb-extract",
                        path: "/food-beverage/food-ingredients-and-food-additives/flavor-and-seasoning/seasoning-and-spices/spice-and-herb-extract/vanilla",
                        active: true,
                        meta: {
                          tags: "Vanilla",
                          keywords: "Vanilla",
                          description: "Vanilla",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096add1",
            },
            slug: "flours",
            title: "Flours",
            id: 5000404,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/flours",
            active: true,
            meta: {
              tags: "Flours",
              keywords: "Flours",
              description: "Flours",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096add2",
                },
                slug: "banana-flour",
                title: "Banana Flour",
                id: 5000405,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/flours",
                path: "/food-beverage/food-ingredients-and-food-additives/flours/banana-flour",
                active: true,
                meta: {
                  tags: "Banana Flour",
                  keywords: "Banana Flour",
                  description: "Banana Flour",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096add3",
                },
                slug: "functional-flour",
                title: "Functional Flour",
                id: 5000406,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/flours",
                path: "/food-beverage/food-ingredients-and-food-additives/flours/functional-flour",
                active: true,
                meta: {
                  tags: "Functional Flour",
                  keywords: "Functional Flour",
                  description: "Functional Flour",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096add4",
                },
                slug: "pulse-flour",
                title: "Pulse Flour",
                id: 5000407,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/flours",
                path: "/food-beverage/food-ingredients-and-food-additives/flours/pulse-flour",
                active: true,
                meta: {
                  tags: "Pulse Flour",
                  keywords: "Pulse Flour",
                  description: "Pulse Flour",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096add5",
                },
                slug: "soy-flour",
                title: "Soy Flour",
                id: 5000408,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/flours",
                path: "/food-beverage/food-ingredients-and-food-additives/flours/soy-flour",
                active: true,
                meta: {
                  tags: "Soy Flour",
                  keywords: "Soy Flour",
                  description: "Soy Flour",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096add6",
                },
                slug: "wheat-flour",
                title: "Wheat Flour",
                id: 5000409,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/flours",
                path: "/food-beverage/food-ingredients-and-food-additives/flours/wheat-flour",
                active: true,
                meta: {
                  tags: "Wheat Flour",
                  keywords: "Wheat Flour",
                  description: "Wheat Flour",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096add7",
            },
            slug: "food-acidulants",
            title: "Food Acidulants",
            id: 5000410,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-acidulants",
            active: true,
            meta: {
              tags: "Food Acidulants",
              keywords: "Food Acidulants",
              description: "Food Acidulants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096add8",
                },
                slug: "food-grade-phosphoric-acid",
                title: "Food Grade Phosphoric Acid",
                id: 5000411,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-acidulants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-acidulants/food-grade-phosphoric-acid",
                active: true,
                meta: {
                  tags: "Food Grade Phosphoric Acid",
                  keywords: "Food Grade Phosphoric Acid",
                  description: "Food Grade Phosphoric Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096add9",
                },
                slug: "succinic-acid",
                title: "Succinic Acid",
                id: 5000412,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-acidulants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-acidulants/succinic-acid",
                active: true,
                meta: {
                  tags: "Succinic Acid",
                  keywords: "Succinic Acid",
                  description: "Succinic Acid",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adda",
            },
            slug: "food-additives",
            title: "Food Additives",
            id: 5000413,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-additives",
            active: true,
            meta: {
              tags: "Food Additives",
              keywords: "Food Additives",
              description: "Food Additives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096addb",
                },
                slug: "antimicrobial-additives",
                title: "Antimicrobial Additives",
                id: 5000414,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-additives",
                path: "/food-beverage/food-ingredients-and-food-additives/food-additives/antimicrobial-additives",
                active: true,
                meta: {
                  tags: "Antimicrobial Additives",
                  keywords: "Antimicrobial Additives",
                  description: "Antimicrobial Additives",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096addc",
            },
            slug: "food-amino-acids",
            title: "Food Amino Acids",
            id: 5000415,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-amino-acids",
            active: true,
            meta: {
              tags: "Food Amino Acids",
              keywords: "Food Amino Acids",
              description: "Food Amino Acids",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096addd",
                },
                slug: "aspartic-acid",
                title: "Aspartic Acid",
                id: 5000416,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-amino-acids",
                path: "/food-beverage/food-ingredients-and-food-additives/food-amino-acids/aspartic-acid",
                active: true,
                meta: {
                  tags: "Aspartic Acid",
                  keywords: "Aspartic Acid",
                  description: "Aspartic Acid",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adde",
            },
            slug: "food-antioxidants",
            title: "Food Antioxidants",
            id: 5000417,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-antioxidants",
            active: true,
            meta: {
              tags: "Food Antioxidants",
              keywords: "Food Antioxidants",
              description: "Food Antioxidants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096addf",
                },
                slug: "antimicrobial-and-antioxidants",
                title: "Antimicrobial & Antioxidants",
                id: 5000418,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-antioxidants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-antioxidants/antimicrobial-and-antioxidants",
                active: true,
                meta: {
                  tags: "Antimicrobial & Antioxidants",
                  keywords: "Antimicrobial & Antioxidants",
                  description: "Antimicrobial & Antioxidants",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ade0",
            },
            slug: "food-bleaching-agents",
            title: "Food Bleaching Agents",
            id: 5000419,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-bleaching-agents",
            active: true,
            meta: {
              tags: "Food Bleaching Agents",
              keywords: "Food Bleaching Agents",
              description: "Food Bleaching Agents",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ade1",
            },
            slug: "food-coatings",
            title: "Food Coatings",
            id: 5000420,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-coatings",
            active: true,
            meta: {
              tags: "Food Coatings",
              keywords: "Food Coatings",
              description: "Food Coatings",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ade2",
                },
                slug: "citrus-fruit-coatings",
                title: "Citrus Fruit Coatings",
                id: 5000421,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-coatings",
                path: "/food-beverage/food-ingredients-and-food-additives/food-coatings/citrus-fruit-coatings",
                active: true,
                meta: {
                  tags: "Citrus Fruit Coatings",
                  keywords: "Citrus Fruit Coatings",
                  description: "Citrus Fruit Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ade3",
                },
                slug: "fruits-and-vegetables-coatings",
                title: "Fruits and Vegetables Coatings",
                id: 5000422,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-coatings",
                path: "/food-beverage/food-ingredients-and-food-additives/food-coatings/fruits-and-vegetables-coatings",
                active: true,
                meta: {
                  tags: "Fruits and Vegetables Coatings",
                  keywords: "Fruits and Vegetables Coatings",
                  description: "Fruits and Vegetables Coatings",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ade4",
                },
                slug: "glazing-agents",
                title: "Glazing Agents",
                id: 5000423,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-coatings",
                path: "/food-beverage/food-ingredients-and-food-additives/food-coatings/glazing-agents",
                active: true,
                meta: {
                  tags: "Glazing Agents",
                  keywords: "Glazing Agents",
                  description: "Glazing Agents",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ade5",
            },
            slug: "food-colorants",
            title: "Food Colorants",
            id: 5000424,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-colorants",
            active: true,
            meta: {
              tags: "Food Colorants",
              keywords: "Food Colorants",
              description: "Food Colorants",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ade6",
                },
                slug: "anthocyanin",
                title: "Anthocyanin",
                id: 5000425,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-colorants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-colorants/anthocyanin",
                active: true,
                meta: {
                  tags: "Anthocyanin",
                  keywords: "Anthocyanin",
                  description: "Anthocyanin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ade7",
                },
                slug: "astaxanthin",
                title: "Astaxanthin",
                id: 5000426,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-colorants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-colorants/astaxanthin",
                active: true,
                meta: {
                  tags: "Astaxanthin",
                  keywords: "Astaxanthin",
                  description: "Astaxanthin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ade8",
                },
                slug: "beta-carotene",
                title: "Beta Carotene",
                id: 5000427,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-colorants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-colorants/beta-carotene",
                active: true,
                meta: {
                  tags: "Beta Carotene",
                  keywords: "Beta Carotene",
                  description: "Beta Carotene",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ade9",
                },
                slug: "carotenoids",
                title: "Carotenoids",
                id: 5000428,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-colorants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-colorants/carotenoids",
                active: true,
                meta: {
                  tags: "Carotenoids",
                  keywords: "Carotenoids",
                  description: "Carotenoids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adea",
                },
                slug: "flavonoids",
                title: "Flavonoids",
                id: 5000429,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-colorants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-colorants/flavonoids",
                active: true,
                meta: {
                  tags: "Flavonoids",
                  keywords: "Flavonoids",
                  description: "Flavonoids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adeb",
                },
                slug: "lutein",
                title: "Lutein",
                id: 5000430,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-colorants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-colorants/lutein",
                active: true,
                meta: {
                  tags: "Lutein",
                  keywords: "Lutein",
                  description: "Lutein",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adec",
                },
                slug: "natural-food-colorants",
                title: "Natural Food Colorants",
                id: 5000431,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-colorants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-colorants/natural-food-colorants",
                active: true,
                meta: {
                  tags: "Natural Food Colorants",
                  keywords: "Natural Food Colorants",
                  description: "Natural Food Colorants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aded",
                },
                slug: "proanthocyanidins",
                title: "Proanthocyanidins",
                id: 5000432,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-colorants",
                path: "/food-beverage/food-ingredients-and-food-additives/food-colorants/proanthocyanidins",
                active: true,
                meta: {
                  tags: "Proanthocyanidins",
                  keywords: "Proanthocyanidins",
                  description: "Proanthocyanidins",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adee",
            },
            slug: "food-cultures",
            title: "Food Cultures",
            id: 5000433,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-cultures",
            active: true,
            meta: {
              tags: "Food Cultures",
              keywords: "Food Cultures",
              description: "Food Cultures",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096adef",
                },
                slug: "microbial-food-cultures",
                title: "Microbial Food Cultures",
                id: 5000434,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-cultures",
                path: "/food-beverage/food-ingredients-and-food-additives/food-cultures/microbial-food-cultures",
                active: true,
                meta: {
                  tags: "Microbial Food Cultures",
                  keywords: "Microbial Food Cultures",
                  description: "Microbial Food Cultures",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adf0",
            },
            slug: "food-emulsifiers",
            title: "Food Emulsifiers",
            id: 5000435,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-emulsifiers",
            active: true,
            meta: {
              tags: "Food Emulsifiers",
              keywords: "Food Emulsifiers",
              description: "Food Emulsifiers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096adf1",
                },
                slug: "carrageenan",
                title: "Carrageenan",
                id: 5000436,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-emulsifiers",
                path: "/food-beverage/food-ingredients-and-food-additives/food-emulsifiers/carrageenan",
                active: true,
                meta: {
                  tags: "Carrageenan",
                  keywords: "Carrageenan",
                  description: "Carrageenan",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adf2",
                },
                slug: "lecithin",
                title: "Lecithin",
                id: 5000437,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-emulsifiers",
                path: "/food-beverage/food-ingredients-and-food-additives/food-emulsifiers/lecithin",
                active: true,
                meta: {
                  tags: "Lecithin",
                  keywords: "Lecithin",
                  description: "Lecithin",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adf3",
            },
            slug: "food-enzymes",
            title: "Food Enzymes",
            id: 5000438,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-enzymes",
            active: true,
            meta: {
              tags: "Food Enzymes",
              keywords: "Food Enzymes",
              description: "Food Enzymes",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096adf4",
                },
                slug: "bakery-enzymes",
                title: "Bakery Enzymes",
                id: 5000439,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-enzymes",
                path: "/food-beverage/food-ingredients-and-food-additives/food-enzymes/bakery-enzymes",
                active: true,
                meta: {
                  tags: "Bakery Enzymes",
                  keywords: "Bakery Enzymes",
                  description: "Bakery Enzymes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adf5",
                },
                slug: "brewing-enzymes",
                title: "Brewing Enzymes",
                id: 5000440,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-enzymes",
                path: "/food-beverage/food-ingredients-and-food-additives/food-enzymes/brewing-enzymes",
                active: true,
                meta: {
                  tags: "Brewing Enzymes",
                  keywords: "Brewing Enzymes",
                  description: "Brewing Enzymes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adf6",
                },
                slug: "dairy-enzymes",
                title: "Dairy Enzymes",
                id: 5000441,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-enzymes",
                path: "/food-beverage/food-ingredients-and-food-additives/food-enzymes/dairy-enzymes",
                active: true,
                meta: {
                  tags: "Dairy Enzymes",
                  keywords: "Dairy Enzymes",
                  description: "Dairy Enzymes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adf7",
                },
                slug: "lipase",
                title: "Lipase",
                id: 5000442,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-enzymes",
                path: "/food-beverage/food-ingredients-and-food-additives/food-enzymes/lipase",
                active: true,
                meta: {
                  tags: "Lipase",
                  keywords: "Lipase",
                  description: "Lipase",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adf8",
                },
                slug: "proteases",
                title: "Proteases",
                id: 5000443,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-enzymes",
                path: "/food-beverage/food-ingredients-and-food-additives/food-enzymes/proteases",
                active: true,
                meta: {
                  tags: "Proteases",
                  keywords: "Proteases",
                  description: "Proteases",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adf9",
                },
                slug: "rennet",
                title: "Rennet",
                id: 5000444,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-enzymes",
                path: "/food-beverage/food-ingredients-and-food-additives/food-enzymes/rennet",
                active: true,
                meta: {
                  tags: "Rennet",
                  keywords: "Rennet",
                  description: "Rennet",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096adfa",
                },
                slug: "specialty-enzymes",
                title: "Specialty Enzymes",
                id: 5000445,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-enzymes",
                path: "/food-beverage/food-ingredients-and-food-additives/food-enzymes/specialty-enzymes",
                active: true,
                meta: {
                  tags: "Specialty Enzymes",
                  keywords: "Specialty Enzymes",
                  description: "Specialty Enzymes",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adfb",
            },
            slug: "food-fibers",
            title: "Food Fibers",
            id: 5000446,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-fibers",
            active: true,
            meta: {
              tags: "Food Fibers",
              keywords: "Food Fibers",
              description: "Food Fibers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096adfc",
                },
                slug: "insoluble-food-fibers",
                title: "Insoluble Food Fibers",
                id: 5000447,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-fibers",
                path: "/food-beverage/food-ingredients-and-food-additives/food-fibers/insoluble-food-fibers",
                active: true,
                meta: {
                  tags: "Insoluble Food Fibers",
                  keywords: "Insoluble Food Fibers",
                  description: "Insoluble Food Fibers",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adfd",
            },
            slug: "food-grade-alcohol",
            title: "Food Grade Alcohol",
            id: 5000448,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-grade-alcohol",
            active: true,
            meta: {
              tags: "Food Grade Alcohol",
              keywords: "Food Grade Alcohol",
              description: "Food Grade Alcohol",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adfe",
            },
            slug: "food-grade-industrial-gases",
            title: "Food Grade Industrial Gases",
            id: 5000449,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-grade-industrial-gases",
            active: true,
            meta: {
              tags: "Food Grade Industrial Gases",
              keywords: "Food Grade Industrial Gases",
              description: "Food Grade Industrial Gases",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096adff",
            },
            slug: "food-humectants",
            title: "Food Humectants",
            id: 5000450,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-humectants",
            active: true,
            meta: {
              tags: "Food Humectants",
              keywords: "Food Humectants",
              description: "Food Humectants",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae00",
            },
            slug: "food-hydrocolloids",
            title: "Food Hydrocolloids",
            id: 5000451,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-hydrocolloids",
            active: true,
            meta: {
              tags: "Food Hydrocolloids",
              keywords: "Food Hydrocolloids",
              description: "Food Hydrocolloids",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae01",
            },
            slug: "food-ingredients",
            title: "Food Ingredients",
            id: 5000452,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-ingredients",
            active: true,
            meta: {
              tags: "Food Ingredients",
              keywords: "Food Ingredients",
              description: "Food Ingredients",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae02",
                },
                slug: "microencapsulated-food-ingredients",
                title: "Microencapsulated Food Ingredients",
                id: 5000453,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/food-ingredients/microencapsulated-food-ingredients",
                active: true,
                meta: {
                  tags: "Microencapsulated Food Ingredients",
                  keywords: "Microencapsulated Food Ingredients",
                  description: "Microencapsulated Food Ingredients",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae03",
                },
                slug: "specialty-food-ingredients",
                title: "Specialty Food Ingredients",
                id: 5000454,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/food-ingredients/specialty-food-ingredients",
                active: true,
                meta: {
                  tags: "Specialty Food Ingredients",
                  keywords: "Specialty Food Ingredients",
                  description: "Specialty Food Ingredients",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae04",
            },
            slug: "food-premixes",
            title: "Food Premixes",
            id: 5000455,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-premixes",
            active: true,
            meta: {
              tags: "Food Premixes",
              keywords: "Food Premixes",
              description: "Food Premixes",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae05",
                },
                slug: "bakery-premixes",
                title: "Bakery Premixes",
                id: 5000456,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-premixes",
                path: "/food-beverage/food-ingredients-and-food-additives/food-premixes/bakery-premixes",
                active: true,
                meta: {
                  tags: "Bakery Premixes",
                  keywords: "Bakery Premixes",
                  description: "Bakery Premixes",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae06",
                    },
                    slug: "gluten-free-bakery-premixes",
                    title: "Gluten Free Bakery Premixes",
                    id: 5000457,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/food-premixes/bakery-premixes",
                    path: "/food-beverage/food-ingredients-and-food-additives/food-premixes/bakery-premixes/gluten-free-bakery-premixes",
                    active: true,
                    meta: {
                      tags: "Gluten Free Bakery Premixes",
                      keywords: "Gluten Free Bakery Premixes",
                      description: "Gluten Free Bakery Premixes",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae07",
                },
                slug: "batter-and-breader-premixes",
                title: "Batter & Breader Premixes",
                id: 5000458,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-premixes",
                path: "/food-beverage/food-ingredients-and-food-additives/food-premixes/batter-and-breader-premixes",
                active: true,
                meta: {
                  tags: "Batter & Breader Premixes",
                  keywords: "Batter & Breader Premixes",
                  description: "Batter & Breader Premixes",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae08",
            },
            slug: "food-preservatives",
            title: "Food Preservatives",
            id: 5000459,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-preservatives",
            active: true,
            meta: {
              tags: "Food Preservatives",
              keywords: "Food Preservatives",
              description: "Food Preservatives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae09",
                },
                slug: "benzoic-acid",
                title: "Benzoic Acid",
                id: 5000460,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-preservatives",
                path: "/food-beverage/food-ingredients-and-food-additives/food-preservatives/benzoic-acid",
                active: true,
                meta: {
                  tags: "Benzoic Acid",
                  keywords: "Benzoic Acid",
                  description: "Benzoic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae0a",
                },
                slug: "calcium-citrate",
                title: "Calcium Citrate",
                id: 5000461,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-preservatives",
                path: "/food-beverage/food-ingredients-and-food-additives/food-preservatives/calcium-citrate",
                active: true,
                meta: {
                  tags: "Calcium Citrate",
                  keywords: "Calcium Citrate",
                  description: "Calcium Citrate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae0b",
                },
                slug: "citric-acid",
                title: "Citric Acid",
                id: 5000462,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-preservatives",
                path: "/food-beverage/food-ingredients-and-food-additives/food-preservatives/citric-acid",
                active: true,
                meta: {
                  tags: "Citric Acid",
                  keywords: "Citric Acid",
                  description: "Citric Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae0c",
                },
                slug: "potassium-citrate",
                title: "Potassium Citrate",
                id: 5000463,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-preservatives",
                path: "/food-beverage/food-ingredients-and-food-additives/food-preservatives/potassium-citrate",
                active: true,
                meta: {
                  tags: "Potassium Citrate",
                  keywords: "Potassium Citrate",
                  description: "Potassium Citrate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae0d",
                },
                slug: "salicylic-acid",
                title: "Salicylic Acid",
                id: 5000464,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-preservatives",
                path: "/food-beverage/food-ingredients-and-food-additives/food-preservatives/salicylic-acid",
                active: true,
                meta: {
                  tags: "Salicylic Acid",
                  keywords: "Salicylic Acid",
                  description: "Salicylic Acid",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae0e",
            },
            slug: "food-stabilizers",
            title: "Food Stabilizers",
            id: 5000465,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-stabilizers",
            active: true,
            meta: {
              tags: "Food Stabilizers",
              keywords: "Food Stabilizers",
              description: "Food Stabilizers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae0f",
            },
            slug: "food-texturizers",
            title: "Food Texturizers",
            id: 5000466,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-texturizers",
            active: true,
            meta: {
              tags: "Food Texturizers",
              keywords: "Food Texturizers",
              description: "Food Texturizers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae10",
            },
            slug: "food-thickeners",
            title: "Food Thickeners",
            id: 5000467,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/food-thickeners",
            active: true,
            meta: {
              tags: "Food Thickeners",
              keywords: "Food Thickeners",
              description: "Food Thickeners",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae11",
                },
                slug: "agar",
                title: "Agar",
                id: 5000468,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/food-thickeners",
                path: "/food-beverage/food-ingredients-and-food-additives/food-thickeners/agar",
                active: true,
                meta: {
                  tags: "Agar",
                  keywords: "Agar",
                  description: "Agar",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae12",
            },
            slug: "fortifying-agents",
            title: "Fortifying Agents",
            id: 5000469,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/fortifying-agents",
            active: true,
            meta: {
              tags: "Fortifying Agents",
              keywords: "Fortifying Agents",
              description: "Fortifying Agents",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae13",
                },
                slug: "mineral-fortification",
                title: "Mineral Fortification",
                id: 5000470,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fortifying-agents",
                path: "/food-beverage/food-ingredients-and-food-additives/fortifying-agents/mineral-fortification",
                active: true,
                meta: {
                  tags: "Mineral Fortification",
                  keywords: "Mineral Fortification",
                  description: "Mineral Fortification",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae14",
            },
            slug: "fruits-and-vegetables-ingredients",
            title: "Fruits and Vegetables Ingredients",
            id: 5000471,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients",
            active: true,
            meta: {
              tags: "Fruits and Vegetables Ingredients",
              keywords: "Fruits and Vegetables Ingredients",
              description: "Fruits and Vegetables Ingredients",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae15",
                },
                slug: "blueberry-ingredients",
                title: "Blueberry Ingredients",
                id: 5000472,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/blueberry-ingredients",
                active: true,
                meta: {
                  tags: "Blueberry Ingredients",
                  keywords: "Blueberry Ingredients",
                  description: "Blueberry Ingredients",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae16",
                },
                slug: "fruit-concentrate",
                title: "Fruit Concentrate",
                id: 5000473,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/fruit-concentrate",
                active: true,
                meta: {
                  tags: "Fruit Concentrate",
                  keywords: "Fruit Concentrate",
                  description: "Fruit Concentrate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae17",
                },
                slug: "fruit-extracts",
                title: "Fruit Extracts",
                id: 5000474,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/fruit-extracts",
                active: true,
                meta: {
                  tags: "Fruit Extracts",
                  keywords: "Fruit Extracts",
                  description: "Fruit Extracts",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae18",
                    },
                    slug: "blueberry-extracts",
                    title: "Blueberry Extracts",
                    id: 5000475,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/fruit-extracts",
                    path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/fruit-extracts/blueberry-extracts",
                    active: true,
                    meta: {
                      tags: "Blueberry Extracts",
                      keywords: "Blueberry Extracts",
                      description: "Blueberry Extracts",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae19",
                    },
                    slug: "grapeseed-extract",
                    title: "Grapeseed Extract",
                    id: 5000476,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/fruit-extracts",
                    path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/fruit-extracts/grapeseed-extract",
                    active: true,
                    meta: {
                      tags: "Grapeseed Extract",
                      keywords: "Grapeseed Extract",
                      description: "Grapeseed Extract",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae1a",
                },
                slug: "fruit-powder",
                title: "Fruit Powder",
                id: 5000477,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/fruit-powder",
                active: true,
                meta: {
                  tags: "Fruit Powder",
                  keywords: "Fruit Powder",
                  description: "Fruit Powder",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae1b",
                },
                slug: "fruits-and-vegetables-pieces-and-powders",
                title: "Fruits and Vegetables Pieces & Powders",
                id: 5000478,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/fruits-and-vegetables-pieces-and-powders",
                active: true,
                meta: {
                  tags: "Fruits and Vegetables Pieces & Powders",
                  keywords: "Fruits and Vegetables Pieces & Powders",
                  description: "Fruits and Vegetables Pieces & Powders",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae1c",
                },
                slug: "fruit-juices",
                title: "Fruit Juices",
                id: 5000479,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/fruit-juices",
                active: true,
                meta: {
                  tags: "Fruit Juices",
                  keywords: "Fruit Juices",
                  description: "Fruit Juices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae1d",
                },
                slug: "vegetable-concentrates",
                title: "Vegetable Concentrates",
                id: 5000480,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/fruits-and-vegetables-ingredients/vegetable-concentrates",
                active: true,
                meta: {
                  tags: "Vegetable Concentrates",
                  keywords: "Vegetable Concentrates",
                  description: "Vegetable Concentrates",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae1e",
            },
            slug: "functional-food-ingredients",
            title: "Functional Food Ingredients",
            id: 5000481,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
            active: true,
            meta: {
              tags: "Functional Food Ingredients",
              keywords: "Functional Food Ingredients",
              description: "Functional Food Ingredients",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae1f",
                },
                slug: "acerola-extracts",
                title: "Acerola Extracts",
                id: 5000482,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/acerola-extracts",
                active: true,
                meta: {
                  tags: "Acerola Extracts",
                  keywords: "Acerola Extracts",
                  description: "Acerola Extracts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae20",
                },
                slug: "algae-ingredients",
                title: "Algae Ingredients",
                id: 5000483,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/algae-ingredients",
                active: true,
                meta: {
                  tags: "Algae Ingredients",
                  keywords: "Algae Ingredients",
                  description: "Algae Ingredients",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae21",
                },
                slug: "arachidonic-acid",
                title: "Arachidonic Acid",
                id: 5000484,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/arachidonic-acid",
                active: true,
                meta: {
                  tags: "Arachidonic Acid",
                  keywords: "Arachidonic Acid",
                  description: "Arachidonic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae22",
                },
                slug: "baobab",
                title: "Baobab",
                id: 5000485,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/baobab",
                active: true,
                meta: {
                  tags: "Baobab",
                  keywords: "Baobab",
                  description: "Baobab",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae23",
                },
                slug: "beta-glucan",
                title: "Beta Glucan",
                id: 5000486,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/beta-glucan",
                active: true,
                meta: {
                  tags: "Beta Glucan",
                  keywords: "Beta Glucan",
                  description: "Beta Glucan",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae24",
                    },
                    slug: "soluble-beta-glucan",
                    title: "Soluble Beta Glucan",
                    id: 5000487,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/beta-glucan",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/beta-glucan/soluble-beta-glucan",
                    active: true,
                    meta: {
                      tags: "Soluble Beta Glucan",
                      keywords: "Soluble Beta Glucan",
                      description: "Soluble Beta Glucan",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae25",
                },
                slug: "black-tea-extracts",
                title: "Black Tea Extracts",
                id: 5000488,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/black-tea-extracts",
                active: true,
                meta: {
                  tags: "Black Tea Extracts",
                  keywords: "Black Tea Extracts",
                  description: "Black Tea Extracts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae26",
                },
                slug: "bone-and-joint-health-ingredients",
                title: "Bone and Joint Health Ingredients",
                id: 5000489,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/bone-and-joint-health-ingredients",
                active: true,
                meta: {
                  tags: "Bone and Joint Health Ingredients",
                  keywords: "Bone and Joint Health Ingredients",
                  description: "Bone and Joint Health Ingredients",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae27",
                },
                slug: "calcium-gluconate",
                title: "Calcium Gluconate",
                id: 5000490,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/calcium-gluconate",
                active: true,
                meta: {
                  tags: "Calcium Gluconate",
                  keywords: "Calcium Gluconate",
                  description: "Calcium Gluconate",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae28",
                },
                slug: "chlorella-ingredients",
                title: "Chlorella Ingredients",
                id: 5000491,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/chlorella-ingredients",
                active: true,
                meta: {
                  tags: "Chlorella Ingredients",
                  keywords: "Chlorella Ingredients",
                  description: "Chlorella Ingredients",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae29",
                },
                slug: "commercial-seaweed",
                title: "Commercial Seaweed",
                id: 5000492,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/commercial-seaweed",
                active: true,
                meta: {
                  tags: "Commercial Seaweed",
                  keywords: "Commercial Seaweed",
                  description: "Commercial Seaweed",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae2a",
                },
                slug: "epa-and-dha",
                title: "EPA & DHA",
                id: 5000493,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/epa-and-dha",
                active: true,
                meta: {
                  tags: "EPA & DHA",
                  keywords: "EPA & DHA",
                  description: "EPA & DHA",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae2b",
                },
                slug: "fat-replacers",
                title: "Fat Replacers",
                id: 5000494,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/fat-replacers",
                active: true,
                meta: {
                  tags: "Fat Replacers",
                  keywords: "Fat Replacers",
                  description: "Fat Replacers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae2c",
                },
                slug: "functional-mushrooms",
                title: "Functional Mushrooms",
                id: 5000495,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/functional-mushrooms",
                active: true,
                meta: {
                  tags: "Functional Mushrooms",
                  keywords: "Functional Mushrooms",
                  description: "Functional Mushrooms",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae2d",
                    },
                    slug: "reishi-mushroom",
                    title: "Reishi Mushroom",
                    id: 5000496,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/functional-mushrooms",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/functional-mushrooms/reishi-mushroom",
                    active: true,
                    meta: {
                      tags: "Reishi Mushroom",
                      keywords: "Reishi Mushroom",
                      description: "Reishi Mushroom",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae2e",
                    },
                    slug: "shitake-mushrooms",
                    title: "Shitake Mushrooms",
                    id: 5000497,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/functional-mushrooms",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/functional-mushrooms/shitake-mushrooms",
                    active: true,
                    meta: {
                      tags: "Shitake Mushrooms",
                      keywords: "Shitake Mushrooms",
                      description: "Shitake Mushrooms",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae2f",
                },
                slug: "glucosamine",
                title: "Glucosamine",
                id: 5000498,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/glucosamine",
                active: true,
                meta: {
                  tags: "Glucosamine",
                  keywords: "Glucosamine",
                  description: "Glucosamine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae30",
                },
                slug: "health-ingredients",
                title: "Health Ingredients",
                id: 5000499,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/health-ingredients",
                active: true,
                meta: {
                  tags: "Health Ingredients",
                  keywords: "Health Ingredients",
                  description: "Health Ingredients",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae31",
                },
                slug: "heart-health-ingredients",
                title: "Heart Health Ingredients",
                id: 5000500,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/heart-health-ingredients",
                active: true,
                meta: {
                  tags: "Heart Health Ingredients",
                  keywords: "Heart Health Ingredients",
                  description: "Heart Health Ingredients",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae32",
                },
                slug: "hyaluronic-acid",
                title: "Hyaluronic Acid",
                id: 5000501,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/hyaluronic-acid",
                active: true,
                meta: {
                  tags: "Hyaluronic Acid",
                  keywords: "Hyaluronic Acid",
                  description: "Hyaluronic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae33",
                },
                slug: "lactic-acid",
                title: "Lactic Acid",
                id: 5000502,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/lactic-acid",
                active: true,
                meta: {
                  tags: "Lactic Acid",
                  keywords: "Lactic Acid",
                  description: "Lactic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae34",
                },
                slug: "omega-3-ingredients",
                title: "Omega 3 Ingredients",
                id: 5000503,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/omega-3-ingredients",
                active: true,
                meta: {
                  tags: "Omega 3 Ingredients",
                  keywords: "Omega 3 Ingredients",
                  description: "Omega 3 Ingredients",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae35",
                    },
                    slug: "algae-omega-3-ingredients",
                    title: "Algae Omega 3 Ingredients",
                    id: 5000504,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/omega-3-ingredients",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/omega-3-ingredients/algae-omega-3-ingredients",
                    active: true,
                    meta: {
                      tags: "Algae Omega 3 Ingredients",
                      keywords: "Algae Omega 3 Ingredients",
                      description: "Algae Omega 3 Ingredients",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae36",
                        },
                        slug: "epa-focused-algae-omega-3-ingredients",
                        title: "EPA-Focused Algae Omega 3 Ingredients",
                        id: 5000505,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/omega-3-ingredients/algae-omega-3-ingredients",
                        path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/omega-3-ingredients/algae-omega-3-ingredients/epa-focused-algae-omega-3-ingredients",
                        active: true,
                        meta: {
                          tags: "EPA-Focused Algae Omega 3 Ingredients",
                          keywords: "EPA-Focused Algae Omega 3 Ingredients",
                          description: "EPA-Focused Algae Omega 3 Ingredients",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae37",
                    },
                    slug: "fish-oil-omega-3-ingredients",
                    title: "Fish Oil Omega 3 Ingredients",
                    id: 5000506,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/omega-3-ingredients",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/omega-3-ingredients/fish-oil-omega-3-ingredients",
                    active: true,
                    meta: {
                      tags: "Fish Oil Omega 3 Ingredients",
                      keywords: "Fish Oil Omega 3 Ingredients",
                      description: "Fish Oil Omega 3 Ingredients",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae38",
                    },
                    slug: "omega-3-pufa",
                    title: "Omega 3 PUFA",
                    id: 5000507,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/omega-3-ingredients",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/omega-3-ingredients/omega-3-pufa",
                    active: true,
                    meta: {
                      tags: "Omega 3 PUFA",
                      keywords: "Omega 3 PUFA",
                      description: "Omega 3 PUFA",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae39",
                },
                slug: "pectin",
                title: "Pectin",
                id: 5000508,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/pectin",
                active: true,
                meta: {
                  tags: "Pectin",
                  keywords: "Pectin",
                  description: "Pectin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae3a",
                },
                slug: "phytonutrients",
                title: "Phytonutrients",
                id: 5000509,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/phytonutrients",
                active: true,
                meta: {
                  tags: "Phytonutrients",
                  keywords: "Phytonutrients",
                  description: "Phytonutrients",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae3b",
                },
                slug: "phytosterols",
                title: "Phytosterols",
                id: 5000510,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/phytosterols",
                active: true,
                meta: {
                  tags: "Phytosterols",
                  keywords: "Phytosterols",
                  description: "Phytosterols",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae3c",
                },
                slug: "polyphenol",
                title: "Polyphenol",
                id: 5000511,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/polyphenol",
                active: true,
                meta: {
                  tags: "Polyphenol",
                  keywords: "Polyphenol",
                  description: "Polyphenol",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae3d",
                },
                slug: "prebiotic-ingredients",
                title: "Prebiotic Ingredients",
                id: 5000512,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/prebiotic-ingredients",
                active: true,
                meta: {
                  tags: "Prebiotic Ingredients",
                  keywords: "Prebiotic Ingredients",
                  description: "Prebiotic Ingredients",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae3e",
                    },
                    slug: "fructo-oligo-saccharides",
                    title: "Fructo Oligo Saccharides",
                    id: 5000513,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/prebiotic-ingredients",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/prebiotic-ingredients/fructo-oligo-saccharides",
                    active: true,
                    meta: {
                      tags: "Fructo Oligo Saccharides",
                      keywords: "Fructo Oligo Saccharides",
                      description: "Fructo Oligo Saccharides",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae3f",
                    },
                    slug: "human-milk-oligosaccharides",
                    title: "Human Milk Oligosaccharides",
                    id: 5000514,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/prebiotic-ingredients",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/prebiotic-ingredients/human-milk-oligosaccharides",
                    active: true,
                    meta: {
                      tags: "Human Milk Oligosaccharides",
                      keywords: "Human Milk Oligosaccharides",
                      description: "Human Milk Oligosaccharides",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae40",
                    },
                    slug: "inulin",
                    title: "Inulin",
                    id: 5000515,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/prebiotic-ingredients",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/prebiotic-ingredients/inulin",
                    active: true,
                    meta: {
                      tags: "Inulin",
                      keywords: "Inulin",
                      description: "Inulin",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae41",
                },
                slug: "salt-substitutes",
                title: "Salt Substitutes",
                id: 5000516,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/salt-substitutes",
                active: true,
                meta: {
                  tags: "Salt Substitutes",
                  keywords: "Salt Substitutes",
                  description: "Salt Substitutes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae42",
                },
                slug: "sodium-reduction-ingredients",
                title: "Sodium Reduction Ingredients",
                id: 5000517,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/sodium-reduction-ingredients",
                active: true,
                meta: {
                  tags: "Sodium Reduction Ingredients",
                  keywords: "Sodium Reduction Ingredients",
                  description: "Sodium Reduction Ingredients",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae43",
                },
                slug: "spirulina-extract",
                title: "Spirulina Extract",
                id: 5000518,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/spirulina-extract",
                active: true,
                meta: {
                  tags: "Spirulina Extract",
                  keywords: "Spirulina Extract",
                  description: "Spirulina Extract",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae44",
                },
                slug: "tea-polyphenols",
                title: "Tea Polyphenols",
                id: 5000520,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/tea-polyphenols",
                active: true,
                meta: {
                  tags: "Tea Polyphenols",
                  keywords: "Tea Polyphenols",
                  description: "Tea Polyphenols",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae45",
                },
                slug: "vitamins",
                title: "Vitamins",
                id: 5000521,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins",
                active: true,
                meta: {
                  tags: "Vitamins",
                  keywords: "Vitamins",
                  description: "Vitamins",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae46",
                    },
                    slug: "ascorbic-acid",
                    title: "Ascorbic Acid",
                    id: 5000522,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/ascorbic-acid",
                    active: true,
                    meta: {
                      tags: "Ascorbic Acid",
                      keywords: "Ascorbic Acid",
                      description: "Ascorbic Acid",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae47",
                    },
                    slug: "folic-acid",
                    title: "Folic Acid",
                    id: 5000523,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/folic-acid",
                    active: true,
                    meta: {
                      tags: "Folic Acid",
                      keywords: "Folic Acid",
                      description: "Folic Acid",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae48",
                    },
                    slug: "riboflavin",
                    title: "Riboflavin",
                    id: 5000524,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/riboflavin",
                    active: true,
                    meta: {
                      tags: "Riboflavin",
                      keywords: "Riboflavin",
                      description: "Riboflavin",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae49",
                    },
                    slug: "tocopherol",
                    title: "Tocopherol",
                    id: 5000525,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/tocopherol",
                    active: true,
                    meta: {
                      tags: "Tocopherol",
                      keywords: "Tocopherol",
                      description: "Tocopherol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae4a",
                    },
                    slug: "vitamin-a",
                    title: "Vitamin A",
                    id: 5000526,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/vitamin-a",
                    active: true,
                    meta: {
                      tags: "Vitamin A",
                      keywords: "Vitamin A",
                      description: "Vitamin A",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae4b",
                    },
                    slug: "vitamin-e",
                    title: "Vitamin E",
                    id: 5000527,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins",
                    path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/vitamin-e",
                    active: true,
                    meta: {
                      tags: "Vitamin E",
                      keywords: "Vitamin E",
                      description: "Vitamin E",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae4c",
                        },
                        slug: "natural-vitamin-e",
                        title: "Natural Vitamin E",
                        id: 5000528,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/vitamin-e",
                        path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/vitamin-e/natural-vitamin-e",
                        active: true,
                        meta: {
                          tags: "Natural Vitamin E",
                          keywords: "Natural Vitamin E",
                          description: "Natural Vitamin E",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae4d",
                        },
                        slug: "tocotrienol",
                        title: "Tocotrienol",
                        id: 5000529,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/vitamin-e",
                        path: "/food-beverage/food-ingredients-and-food-additives/functional-food-ingredients/vitamins/vitamin-e/tocotrienol",
                        active: true,
                        meta: {
                          tags: "Tocotrienol",
                          keywords: "Tocotrienol",
                          description: "Tocotrienol",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae4e",
            },
            slug: "gelling-agents",
            title: "Gelling Agents",
            id: 5000530,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/gelling-agents",
            active: true,
            meta: {
              tags: "Gelling Agents",
              keywords: "Gelling Agents",
              description: "Gelling Agents",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae4f",
            },
            slug: "glucaric-acid",
            title: "Glucaric Acid",
            id: 5000531,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/glucaric-acid",
            active: true,
            meta: {
              tags: "Glucaric Acid",
              keywords: "Glucaric Acid",
              description: "Glucaric Acid",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae50",
            },
            slug: "gluconic-acid",
            title: "Gluconic Acid",
            id: 5000532,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/gluconic-acid",
            active: true,
            meta: {
              tags: "Gluconic Acid",
              keywords: "Gluconic Acid",
              description: "Gluconic Acid",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae51",
            },
            slug: "grain-mill-products",
            title: "Grain Mill Products",
            id: 5000533,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/grain-mill-products",
            active: true,
            meta: {
              tags: "Grain Mill Products",
              keywords: "Grain Mill Products",
              description: "Grain Mill Products",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae52",
            },
            slug: "industrial-chocolate",
            title: "Industrial Chocolate",
            id: 5000534,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/industrial-chocolate",
            active: true,
            meta: {
              tags: "Industrial Chocolate",
              keywords: "Industrial Chocolate",
              description: "Industrial Chocolate",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae53",
                },
                slug: "compound-chocolate",
                title: "Compound Chocolate",
                id: 5000535,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/industrial-chocolate",
                path: "/food-beverage/food-ingredients-and-food-additives/industrial-chocolate/compound-chocolate",
                active: true,
                meta: {
                  tags: "Compound Chocolate",
                  keywords: "Compound Chocolate",
                  description: "Compound Chocolate",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae54",
            },
            slug: "integrated-food-ingredients",
            title: "Integrated Food Ingredients",
            id: 5000536,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/integrated-food-ingredients",
            active: true,
            meta: {
              tags: "Integrated Food Ingredients",
              keywords: "Integrated Food Ingredients",
              description: "Integrated Food Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae55",
            },
            slug: "lipids",
            title: "Lipids",
            id: 5000537,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/lipids",
            active: true,
            meta: {
              tags: "Lipids",
              keywords: "Lipids",
              description: "Lipids",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae56",
            },
            slug: "malt-ingredients",
            title: "Malt Ingredients",
            id: 5000538,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/malt-ingredients",
            active: true,
            meta: {
              tags: "Malt Ingredients",
              keywords: "Malt Ingredients",
              description: "Malt Ingredients",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae57",
                },
                slug: "malt-extracts",
                title: "Malt Extracts",
                id: 5000539,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/malt-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/malt-ingredients/malt-extracts",
                active: true,
                meta: {
                  tags: "Malt Extracts",
                  keywords: "Malt Extracts",
                  description: "Malt Extracts",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae58",
                    },
                    slug: "hopped-malt-extracts",
                    title: "Hopped Malt Extracts",
                    id: 5000540,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/malt-ingredients/malt-extracts",
                    path: "/food-beverage/food-ingredients-and-food-additives/malt-ingredients/malt-extracts/hopped-malt-extracts",
                    active: true,
                    meta: {
                      tags: "Hopped Malt Extracts",
                      keywords: "Hopped Malt Extracts",
                      description: "Hopped Malt Extracts",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae59",
                },
                slug: "malted-wheat-flour",
                title: "Malted Wheat Flour",
                id: 5000541,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/malt-ingredients",
                path: "/food-beverage/food-ingredients-and-food-additives/malt-ingredients/malted-wheat-flour",
                active: true,
                meta: {
                  tags: "Malted Wheat Flour",
                  keywords: "Malted Wheat Flour",
                  description: "Malted Wheat Flour",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae5a",
            },
            slug: "meat-ingredients",
            title: "Meat Ingredients",
            id: 5000542,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/meat-ingredients",
            active: true,
            meta: {
              tags: "Meat Ingredients",
              keywords: "Meat Ingredients",
              description: "Meat Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae5b",
            },
            slug: "microbial-cultures",
            title: "Microbial Cultures",
            id: 5000543,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/microbial-cultures",
            active: true,
            meta: {
              tags: "Microbial Cultures",
              keywords: "Microbial Cultures",
              description: "Microbial Cultures",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae5c",
                },
                slug: "freeze-dried-microbial-cultures",
                title: "Freeze Dried Microbial Cultures",
                id: 5000544,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/microbial-cultures",
                path: "/food-beverage/food-ingredients-and-food-additives/microbial-cultures/freeze-dried-microbial-cultures",
                active: true,
                meta: {
                  tags: "Freeze Dried Microbial Cultures",
                  keywords: "Freeze Dried Microbial Cultures",
                  description: "Freeze Dried Microbial Cultures",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae5d",
                },
                slug: "protective-cultures",
                title: "Protective Cultures",
                id: 5000545,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/microbial-cultures",
                path: "/food-beverage/food-ingredients-and-food-additives/microbial-cultures/protective-cultures",
                active: true,
                meta: {
                  tags: "Protective Cultures",
                  keywords: "Protective Cultures",
                  description: "Protective Cultures",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae5e",
                },
                slug: "starter-cultures",
                title: "Starter Cultures",
                id: 5000546,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/microbial-cultures",
                path: "/food-beverage/food-ingredients-and-food-additives/microbial-cultures/starter-cultures",
                active: true,
                meta: {
                  tags: "Starter Cultures",
                  keywords: "Starter Cultures",
                  description: "Starter Cultures",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae5f",
            },
            slug: "non-meat-ingredients",
            title: "Non-Meat Ingredients",
            id: 5000547,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/non-meat-ingredients",
            active: true,
            meta: {
              tags: "Non-Meat Ingredients",
              keywords: "Non-Meat Ingredients",
              description: "Non-Meat Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae60",
            },
            slug: "nutraceutical-ingredients",
            title: "Nutraceutical Ingredients",
            id: 5000548,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/nutraceutical-ingredients",
            active: true,
            meta: {
              tags: "Nutraceutical Ingredients",
              keywords: "Nutraceutical Ingredients",
              description: "Nutraceutical Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae61",
            },
            slug: "organic-acids",
            title: "Organic Acids",
            id: 5000549,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/organic-acids",
            active: true,
            meta: {
              tags: "Organic Acids",
              keywords: "Organic Acids",
              description: "Organic Acids",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae62",
            },
            slug: "phospholipids",
            title: "Phospholipids",
            id: 5000550,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/phospholipids",
            active: true,
            meta: {
              tags: "Phospholipids",
              keywords: "Phospholipids",
              description: "Phospholipids",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae63",
            },
            slug: "probiotic-ingredients",
            title: "Probiotic Ingredients",
            id: 5000551,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/probiotic-ingredients",
            active: true,
            meta: {
              tags: "Probiotic Ingredients",
              keywords: "Probiotic Ingredients",
              description: "Probiotic Ingredients",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae64",
            },
            slug: "propolis",
            title: "Propolis",
            id: 5000552,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/propolis",
            active: true,
            meta: {
              tags: "Propolis",
              keywords: "Propolis",
              description: "Propolis",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae65",
            },
            slug: "proteins",
            title: "Proteins",
            id: 5000553,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/proteins",
            active: true,
            meta: {
              tags: "Proteins",
              keywords: "Proteins",
              description: "Proteins",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae66",
                },
                slug: "animal-protein",
                title: "Animal Protein",
                id: 5000554,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/proteins",
                path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein",
                active: true,
                meta: {
                  tags: "Animal Protein",
                  keywords: "Animal Protein",
                  description: "Animal Protein",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae67",
                    },
                    slug: "collagen",
                    title: "Collagen",
                    id: 5000555,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/collagen",
                    active: true,
                    meta: {
                      tags: "Collagen",
                      keywords: "Collagen",
                      description: "Collagen",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae68",
                        },
                        slug: "marine-collagen",
                        title: "Marine Collagen",
                        id: 5000556,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/collagen",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/collagen/marine-collagen",
                        active: true,
                        meta: {
                          tags: "Marine Collagen",
                          keywords: "Marine Collagen",
                          description: "Marine Collagen",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae69",
                    },
                    slug: "dairy-protein",
                    title: "Dairy Protein",
                    id: 5000557,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/dairy-protein",
                    active: true,
                    meta: {
                      tags: "Dairy Protein",
                      keywords: "Dairy Protein",
                      description: "Dairy Protein",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae6a",
                        },
                        slug: "casein-and-caseinates",
                        title: "Casein and Caseinates",
                        id: 5000558,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/dairy-protein",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/dairy-protein/casein-and-caseinates",
                        active: true,
                        meta: {
                          tags: "Casein and Caseinates",
                          keywords: "Casein and Caseinates",
                          description: "Casein and Caseinates",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae6b",
                        },
                        slug: "milk-protein",
                        title: "Milk Protein",
                        id: 5000559,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/dairy-protein",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/dairy-protein/milk-protein",
                        active: true,
                        meta: {
                          tags: "Milk Protein",
                          keywords: "Milk Protein",
                          description: "Milk Protein",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae6c",
                        },
                        slug: "whey-protein",
                        title: "Whey Protein",
                        id: 5000560,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/dairy-protein",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/dairy-protein/whey-protein",
                        active: true,
                        meta: {
                          tags: "Whey Protein",
                          keywords: "Whey Protein",
                          description: "Whey Protein",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad97c004b8af526096ae6d",
                            },
                            slug: "hydrolyzed-whey-protein",
                            title: "Hydrolyzed Whey Protein",
                            id: 5000561,
                            parent:
                              "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/dairy-protein/whey-protein",
                            path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/dairy-protein/whey-protein/hydrolyzed-whey-protein",
                            active: true,
                            meta: {
                              tags: "Hydrolyzed Whey Protein",
                              keywords: "Hydrolyzed Whey Protein",
                              description: "Hydrolyzed Whey Protein",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae6e",
                    },
                    slug: "egg-protein",
                    title: "Egg Protein",
                    id: 5000562,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/egg-protein",
                    active: true,
                    meta: {
                      tags: "Egg Protein",
                      keywords: "Egg Protein",
                      description: "Egg Protein",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae6f",
                        },
                        slug: "hen-egg-white-protein",
                        title: "Hen Egg White Protein",
                        id: 5000563,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/egg-protein",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/egg-protein/hen-egg-white-protein",
                        active: true,
                        meta: {
                          tags: "Hen Egg White Protein",
                          keywords: "Hen Egg White Protein",
                          description: "Hen Egg White Protein",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae70",
                    },
                    slug: "insect-protein",
                    title: "Insect Protein",
                    id: 5000564,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/animal-protein/insect-protein",
                    active: true,
                    meta: {
                      tags: "Insect Protein",
                      keywords: "Insect Protein",
                      description: "Insect Protein",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae71",
                },
                slug: "functional-proteins",
                title: "Functional Proteins",
                id: 5000565,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/proteins",
                path: "/food-beverage/food-ingredients-and-food-additives/proteins/functional-proteins",
                active: true,
                meta: {
                  tags: "Functional Proteins",
                  keywords: "Functional Proteins",
                  description: "Functional Proteins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae72",
                },
                slug: "gelatin",
                title: "Gelatin",
                id: 5000566,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/proteins",
                path: "/food-beverage/food-ingredients-and-food-additives/proteins/gelatin",
                active: true,
                meta: {
                  tags: "Gelatin",
                  keywords: "Gelatin",
                  description: "Gelatin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae73",
                },
                slug: "microbial-proteins",
                title: "Microbial Proteins",
                id: 5000567,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/proteins",
                path: "/food-beverage/food-ingredients-and-food-additives/proteins/microbial-proteins",
                active: true,
                meta: {
                  tags: "Microbial Proteins",
                  keywords: "Microbial Proteins",
                  description: "Microbial Proteins",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae74",
                    },
                    slug: "algae-proteins",
                    title: "Algae Proteins",
                    id: 5000568,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/microbial-proteins",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/microbial-proteins/algae-proteins",
                    active: true,
                    meta: {
                      tags: "Algae Proteins",
                      keywords: "Algae Proteins",
                      description: "Algae Proteins",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae75",
                },
                slug: "plant-protein",
                title: "Plant Protein",
                id: 5000569,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/proteins",
                path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                active: true,
                meta: {
                  tags: "Plant Protein",
                  keywords: "Plant Protein",
                  description: "Plant Protein",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae76",
                    },
                    slug: "hemp-protein",
                    title: "Hemp Protein",
                    id: 5000570,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/hemp-protein",
                    active: true,
                    meta: {
                      tags: "Hemp Protein",
                      keywords: "Hemp Protein",
                      description: "Hemp Protein",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae77",
                    },
                    slug: "hydrolyzed-plant-proteins",
                    title: "Hydrolyzed Plant Proteins",
                    id: 5000571,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/hydrolyzed-plant-proteins",
                    active: true,
                    meta: {
                      tags: "Hydrolyzed Plant Proteins",
                      keywords: "Hydrolyzed Plant Proteins",
                      description: "Hydrolyzed Plant Proteins",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae78",
                    },
                    slug: "oat-proteins",
                    title: "Oat Proteins",
                    id: 5000572,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/oat-proteins",
                    active: true,
                    meta: {
                      tags: "Oat Proteins",
                      keywords: "Oat Proteins",
                      description: "Oat Proteins",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae79",
                    },
                    slug: "pea-protein",
                    title: "Pea Protein",
                    id: 5000573,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/pea-protein",
                    active: true,
                    meta: {
                      tags: "Pea Protein",
                      keywords: "Pea Protein",
                      description: "Pea Protein",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae7a",
                    },
                    slug: "potato-protein",
                    title: "Potato Protein",
                    id: 5000574,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/potato-protein",
                    active: true,
                    meta: {
                      tags: "Potato Protein",
                      keywords: "Potato Protein",
                      description: "Potato Protein",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae7b",
                    },
                    slug: "rice-protein",
                    title: "Rice Protein",
                    id: 5000575,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/rice-protein",
                    active: true,
                    meta: {
                      tags: "Rice Protein",
                      keywords: "Rice Protein",
                      description: "Rice Protein",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae7c",
                        },
                        slug: "oryzenin",
                        title: "Oryzenin",
                        id: 5000576,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/rice-protein",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/rice-protein/oryzenin",
                        active: true,
                        meta: {
                          tags: "Oryzenin",
                          keywords: "Oryzenin",
                          description: "Oryzenin",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae7d",
                    },
                    slug: "soy-protein",
                    title: "Soy Protein",
                    id: 5000577,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/soy-protein",
                    active: true,
                    meta: {
                      tags: "Soy Protein",
                      keywords: "Soy Protein",
                      description: "Soy Protein",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae7e",
                        },
                        slug: "organic-soy-protein",
                        title: "Organic Soy Protein",
                        id: 5000578,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/soy-protein",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/soy-protein/organic-soy-protein",
                        active: true,
                        meta: {
                          tags: "Organic Soy Protein",
                          keywords: "Organic Soy Protein",
                          description: "Organic Soy Protein",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae7f",
                        },
                        slug: "soy-protein-isolates",
                        title: "Soy Protein Isolates",
                        id: 5000579,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/soy-protein",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/soy-protein/soy-protein-isolates",
                        active: true,
                        meta: {
                          tags: "Soy Protein Isolates",
                          keywords: "Soy Protein Isolates",
                          description: "Soy Protein Isolates",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae80",
                        },
                        slug: "textured-soy-protein",
                        title: "Textured Soy Protein",
                        id: 5000580,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/soy-protein",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/soy-protein/textured-soy-protein",
                        active: true,
                        meta: {
                          tags: "Textured Soy Protein",
                          keywords: "Textured Soy Protein",
                          description: "Textured Soy Protein",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae81",
                    },
                    slug: "wheat-protein",
                    title: "Wheat Protein",
                    id: 5000581,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/wheat-protein",
                    active: true,
                    meta: {
                      tags: "Wheat Protein",
                      keywords: "Wheat Protein",
                      description: "Wheat Protein",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97c004b8af526096ae82",
                        },
                        slug: "hydrolyzed-wheat-protein",
                        title: "Hydrolyzed Wheat Protein",
                        id: 5000582,
                        parent:
                          "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/wheat-protein",
                        path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/wheat-protein/hydrolyzed-wheat-protein",
                        active: true,
                        meta: {
                          tags: "Hydrolyzed Wheat Protein",
                          keywords: "Hydrolyzed Wheat Protein",
                          description: "Hydrolyzed Wheat Protein",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae83",
                    },
                    slug: "almond-proteins",
                    title: "Almond Proteins",
                    id: 5000583,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein",
                    path: "/food-beverage/food-ingredients-and-food-additives/proteins/plant-protein/almond-proteins",
                    active: true,
                    meta: {
                      tags: "Almond Proteins",
                      keywords: "Almond Proteins",
                      description: "Almond Proteins",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae84",
                },
                slug: "protein-alternatives",
                title: "Protein Alternatives",
                id: 5000584,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/proteins",
                path: "/food-beverage/food-ingredients-and-food-additives/proteins/protein-alternatives",
                active: true,
                meta: {
                  tags: "Protein Alternatives",
                  keywords: "Protein Alternatives",
                  description: "Protein Alternatives",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae85",
                },
                slug: "protein-hydrolysates",
                title: "Protein Hydrolysates",
                id: 5000585,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/proteins",
                path: "/food-beverage/food-ingredients-and-food-additives/proteins/protein-hydrolysates",
                active: true,
                meta: {
                  tags: "Protein Hydrolysates",
                  keywords: "Protein Hydrolysates",
                  description: "Protein Hydrolysates",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae86",
                },
                slug: "ready-mix",
                title: "Ready mix",
                id: 5000586,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/proteins",
                path: "/food-beverage/food-ingredients-and-food-additives/proteins/ready-mix",
                active: true,
                meta: {
                  tags: "Ready mix",
                  keywords: "Ready mix",
                  description: "Ready mix",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae87",
            },
            slug: "quillaia-extract",
            title: "Quillaia Extract",
            id: 5000587,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/quillaia-extract",
            active: true,
            meta: {
              tags: "Quillaia Extract",
              keywords: "Quillaia Extract",
              description: "Quillaia Extract",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae88",
            },
            slug: "release-agents",
            title: "Release Agents",
            id: 5000588,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/release-agents",
            active: true,
            meta: {
              tags: "Release Agents",
              keywords: "Release Agents",
              description: "Release Agents",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae89",
            },
            slug: "soybean-derivatives",
            title: "Soybean Derivatives",
            id: 5000589,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/soybean-derivatives",
            active: true,
            meta: {
              tags: "Soybean Derivatives",
              keywords: "Soybean Derivatives",
              description: "Soybean Derivatives",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae8a",
            },
            slug: "starches",
            title: "Starches",
            id: 5000590,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/starches",
            active: true,
            meta: {
              tags: "Starches",
              keywords: "Starches",
              description: "Starches",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae8b",
                },
                slug: "corn-starch",
                title: "Corn Starch",
                id: 5000591,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/starches",
                path: "/food-beverage/food-ingredients-and-food-additives/starches/corn-starch",
                active: true,
                meta: {
                  tags: "Corn Starch",
                  keywords: "Corn Starch",
                  description: "Corn Starch",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae8c",
                },
                slug: "industrial-starches",
                title: "Industrial Starches",
                id: 5000592,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/starches",
                path: "/food-beverage/food-ingredients-and-food-additives/starches/industrial-starches",
                active: true,
                meta: {
                  tags: "Industrial Starches",
                  keywords: "Industrial Starches",
                  description: "Industrial Starches",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae8d",
                },
                slug: "modified-starch",
                title: "Modified Starch",
                id: 5000593,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/starches",
                path: "/food-beverage/food-ingredients-and-food-additives/starches/modified-starch",
                active: true,
                meta: {
                  tags: "Modified Starch",
                  keywords: "Modified Starch",
                  description: "Modified Starch",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae8e",
                },
                slug: "native-starch",
                title: "Native Starch",
                id: 5000594,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/starches",
                path: "/food-beverage/food-ingredients-and-food-additives/starches/native-starch",
                active: true,
                meta: {
                  tags: "Native Starch",
                  keywords: "Native Starch",
                  description: "Native Starch",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae8f",
                },
                slug: "pea-starch",
                title: "Pea Starch",
                id: 5000595,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/starches",
                path: "/food-beverage/food-ingredients-and-food-additives/starches/pea-starch",
                active: true,
                meta: {
                  tags: "Pea Starch",
                  keywords: "Pea Starch",
                  description: "Pea Starch",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae90",
                },
                slug: "starch-derivatives",
                title: "Starch Derivatives",
                id: 5000596,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/starches",
                path: "/food-beverage/food-ingredients-and-food-additives/starches/starch-derivatives",
                active: true,
                meta: {
                  tags: "Starch Derivatives",
                  keywords: "Starch Derivatives",
                  description: "Starch Derivatives",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096ae91",
            },
            slug: "sweeteners",
            title: "Sweeteners",
            id: 5000597,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/sweeteners",
            active: true,
            meta: {
              tags: "Sweeteners",
              keywords: "Sweeteners",
              description: "Sweeteners",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae92",
                },
                slug: "agave-syrup",
                title: "Agave Syrup",
                id: 5000598,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/agave-syrup",
                active: true,
                meta: {
                  tags: "Agave Syrup",
                  keywords: "Agave Syrup",
                  description: "Agave Syrup",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae93",
                },
                slug: "alternative-sweeteners",
                title: "Alternative Sweeteners",
                id: 5000599,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/alternative-sweeteners",
                active: true,
                meta: {
                  tags: "Alternative Sweeteners",
                  keywords: "Alternative Sweeteners",
                  description: "Alternative Sweeteners",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae94",
                },
                slug: "artificial-sweeteners",
                title: "Artificial Sweeteners",
                id: 5000600,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/artificial-sweeteners",
                active: true,
                meta: {
                  tags: "Artificial Sweeteners",
                  keywords: "Artificial Sweeteners",
                  description: "Artificial Sweeteners",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae95",
                },
                slug: "high-intensity-sweetener",
                title: "High Intensity Sweetener",
                id: 5000601,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/high-intensity-sweetener",
                active: true,
                meta: {
                  tags: "High Intensity Sweetener",
                  keywords: "High Intensity Sweetener",
                  description: "High Intensity Sweetener",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae96",
                    },
                    slug: "high-fructose-corn-syrup",
                    title: "High Fructose Corn Syrup",
                    id: 5000602,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/sweeteners/high-intensity-sweetener",
                    path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/high-intensity-sweetener/high-fructose-corn-syrup",
                    active: true,
                    meta: {
                      tags: "High Fructose Corn Syrup",
                      keywords: "High Fructose Corn Syrup",
                      description: "High Fructose Corn Syrup",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae97",
                    },
                    slug: "neotame",
                    title: "Neotame",
                    id: 5000603,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/sweeteners/high-intensity-sweetener",
                    path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/high-intensity-sweetener/neotame",
                    active: true,
                    meta: {
                      tags: "Neotame",
                      keywords: "Neotame",
                      description: "Neotame",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096ae98",
                    },
                    slug: "stevia",
                    title: "Stevia",
                    id: 5000604,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/sweeteners/high-intensity-sweetener",
                    path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/high-intensity-sweetener/stevia",
                    active: true,
                    meta: {
                      tags: "Stevia",
                      keywords: "Stevia",
                      description: "Stevia",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae99",
                },
                slug: "lactobionic-acid",
                title: "Lactobionic Acid",
                id: 5000605,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/lactobionic-acid",
                active: true,
                meta: {
                  tags: "Lactobionic Acid",
                  keywords: "Lactobionic Acid",
                  description: "Lactobionic Acid",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae9a",
                },
                slug: "licorice-extract",
                title: "Licorice Extract",
                id: 5000606,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/licorice-extract",
                active: true,
                meta: {
                  tags: "Licorice Extract",
                  keywords: "Licorice Extract",
                  description: "Licorice Extract",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae9b",
                },
                slug: "low-calorie-sweetener",
                title: "Low Calorie Sweetener",
                id: 5000607,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/low-calorie-sweetener",
                active: true,
                meta: {
                  tags: "Low Calorie Sweetener",
                  keywords: "Low Calorie Sweetener",
                  description: "Low Calorie Sweetener",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae9c",
                },
                slug: "mannitol",
                title: "Mannitol",
                id: 5000608,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/mannitol",
                active: true,
                meta: {
                  tags: "Mannitol",
                  keywords: "Mannitol",
                  description: "Mannitol",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae9d",
                },
                slug: "monk-fruit-sweetener",
                title: "Monk Fruit Sweetener",
                id: 5000609,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/monk-fruit-sweetener",
                active: true,
                meta: {
                  tags: "Monk Fruit Sweetener",
                  keywords: "Monk Fruit Sweetener",
                  description: "Monk Fruit Sweetener",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae9e",
                },
                slug: "natural-sweeteners",
                title: "Natural Sweeteners",
                id: 5000610,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/natural-sweeteners",
                active: true,
                meta: {
                  tags: "Natural Sweeteners",
                  keywords: "Natural Sweeteners",
                  description: "Natural Sweeteners",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096ae9f",
                },
                slug: "nisin",
                title: "Nisin",
                id: 5000611,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/nisin",
                active: true,
                meta: {
                  tags: "Nisin",
                  keywords: "Nisin",
                  description: "Nisin",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aea0",
                },
                slug: "specialty-sweeteners",
                title: "Specialty Sweeteners",
                id: 5000612,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/specialty-sweeteners",
                active: true,
                meta: {
                  tags: "Specialty Sweeteners",
                  keywords: "Specialty Sweeteners",
                  description: "Specialty Sweeteners",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aea1",
                },
                slug: "starch-sweeteners",
                title: "Starch Sweeteners",
                id: 5000613,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/starch-sweeteners",
                active: true,
                meta: {
                  tags: "Starch Sweeteners",
                  keywords: "Starch Sweeteners",
                  description: "Starch Sweeteners",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aea2",
                    },
                    slug: "polydextrose",
                    title: "Polydextrose",
                    id: 5000614,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/sweeteners/starch-sweeteners",
                    path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/starch-sweeteners/polydextrose",
                    active: true,
                    meta: {
                      tags: "Polydextrose",
                      keywords: "Polydextrose",
                      description: "Polydextrose",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aea3",
                },
                slug: "sugar",
                title: "Sugar",
                id: 5000615,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar",
                active: true,
                meta: {
                  tags: "Sugar",
                  keywords: "Sugar",
                  description: "Sugar",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aea4",
                    },
                    slug: "brown-sugar",
                    title: "Brown Sugar",
                    id: 5000616,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar",
                    path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar/brown-sugar",
                    active: true,
                    meta: {
                      tags: "Brown Sugar",
                      keywords: "Brown Sugar",
                      description: "Brown Sugar",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aea5",
                    },
                    slug: "liquid-sugar",
                    title: "Liquid Sugar",
                    id: 5000617,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar",
                    path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar/liquid-sugar",
                    active: true,
                    meta: {
                      tags: "Liquid Sugar",
                      keywords: "Liquid Sugar",
                      description: "Liquid Sugar",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aea6",
                },
                slug: "sugar-alcohols",
                title: "Sugar Alcohols",
                id: 5000618,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar-alcohols",
                active: true,
                meta: {
                  tags: "Sugar Alcohols",
                  keywords: "Sugar Alcohols",
                  description: "Sugar Alcohols",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aea7",
                    },
                    slug: "inositol",
                    title: "Inositol",
                    id: 5000619,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar-alcohols",
                    path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar-alcohols/inositol",
                    active: true,
                    meta: {
                      tags: "Inositol",
                      keywords: "Inositol",
                      description: "Inositol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aea8",
                    },
                    slug: "sorbitol",
                    title: "Sorbitol",
                    id: 5000620,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar-alcohols",
                    path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar-alcohols/sorbitol",
                    active: true,
                    meta: {
                      tags: "Sorbitol",
                      keywords: "Sorbitol",
                      description: "Sorbitol",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad97c004b8af526096aea9",
                    },
                    slug: "xylitol",
                    title: "Xylitol",
                    id: 5000621,
                    parent:
                      "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar-alcohols",
                    path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar-alcohols/xylitol",
                    active: true,
                    meta: {
                      tags: "Xylitol",
                      keywords: "Xylitol",
                      description: "Xylitol",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aeaa",
                },
                slug: "sugar-polyols",
                title: "Sugar Polyols",
                id: 5000622,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar-polyols",
                active: true,
                meta: {
                  tags: "Sugar Polyols",
                  keywords: "Sugar Polyols",
                  description: "Sugar Polyols",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aeab",
                },
                slug: "sugar-substitutes",
                title: "Sugar Substitutes",
                id: 5000623,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/sugar-substitutes",
                active: true,
                meta: {
                  tags: "Sugar Substitutes",
                  keywords: "Sugar Substitutes",
                  description: "Sugar Substitutes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aeac",
                },
                slug: "trehalose",
                title: "Trehalose",
                id: 5000624,
                parent:
                  "/food-beverage/food-ingredients-and-food-additives/sweeteners",
                path: "/food-beverage/food-ingredients-and-food-additives/sweeteners/trehalose",
                active: true,
                meta: {
                  tags: "Trehalose",
                  keywords: "Trehalose",
                  description: "Trehalose",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aead",
            },
            slug: "tannin",
            title: "Tannin",
            id: 5000625,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/tannin",
            active: true,
            meta: {
              tags: "Tannin",
              keywords: "Tannin",
              description: "Tannin",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeae",
            },
            slug: "taste-modulators",
            title: "Taste Modulators",
            id: 5000626,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/taste-modulators",
            active: true,
            meta: {
              tags: "Taste Modulators",
              keywords: "Taste Modulators",
              description: "Taste Modulators",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeaf",
            },
            slug: "water-enhancer",
            title: "Water Enhancer",
            id: 5000627,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/water-enhancer",
            active: true,
            meta: {
              tags: "Water Enhancer",
              keywords: "Water Enhancer",
              description: "Water Enhancer",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeb0",
            },
            slug: "wheat-bran",
            title: "Wheat Bran",
            id: 5000628,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/wheat-bran",
            active: true,
            meta: {
              tags: "Wheat Bran",
              keywords: "Wheat Bran",
              description: "Wheat Bran",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeb1",
            },
            slug: "wheat-gluten",
            title: "Wheat Gluten",
            id: 5000629,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/wheat-gluten",
            active: true,
            meta: {
              tags: "Wheat Gluten",
              keywords: "Wheat Gluten",
              description: "Wheat Gluten",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeb2",
            },
            slug: "white-biotech",
            title: "White Biotech",
            id: 5000630,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/white-biotech",
            active: true,
            meta: {
              tags: "White Biotech",
              keywords: "White Biotech",
              description: "White Biotech",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeb3",
            },
            slug: "xanthan-gum",
            title: "Xanthan Gum",
            id: 5000631,
            parent: "/food-beverage/food-ingredients-and-food-additives",
            path: "/food-beverage/food-ingredients-and-food-additives/xanthan-gum",
            active: true,
            meta: {
              tags: "Xanthan Gum",
              keywords: "Xanthan Gum",
              description: "Xanthan Gum",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ad97c004b8af526096aeb4",
        },
        slug: "food-supplements",
        title: "Food Supplements",
        id: 62623,
        parent: "/food-beverage",
        path: "/food-beverage/food-supplements",
        active: true,
        meta: {
          tags: "Food Supplements",
          keywords: "Food Supplements",
          description: "Food Supplements",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad97c004b8af526096aeb5",
            },
            slug: "beauty-drinks",
            title: "Beauty Drinks",
            id: 5000633,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/beauty-drinks",
            active: true,
            meta: {
              tags: "Beauty Drinks",
              keywords: "Beauty Drinks",
              description: "Beauty Drinks",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeb6",
            },
            slug: "bone-and-joint-health-supplements",
            title: "Bone and Joint Health Supplements",
            id: 5000634,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/bone-and-joint-health-supplements",
            active: true,
            meta: {
              tags: "Bone and Joint Health Supplements",
              keywords: "Bone and Joint Health Supplements",
              description: "Bone and Joint Health Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeb7",
            },
            slug: "botanical-supplements",
            title: "Botanical Supplements",
            id: 5000635,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/botanical-supplements",
            active: true,
            meta: {
              tags: "Botanical Supplements",
              keywords: "Botanical Supplements",
              description: "Botanical Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeb8",
            },
            slug: "charcoal-supplements",
            title: "Charcoal Supplements",
            id: 5000636,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/charcoal-supplements",
            active: true,
            meta: {
              tags: "Charcoal Supplements",
              keywords: "Charcoal Supplements",
              description: "Charcoal Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeb9",
            },
            slug: "cognition-supplements",
            title: "Cognition Supplements",
            id: 5000637,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/cognition-supplements",
            active: true,
            meta: {
              tags: "Cognition Supplements",
              keywords: "Cognition Supplements",
              description: "Cognition Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aeba",
            },
            slug: "colostrum",
            title: "Colostrum",
            id: 5000638,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/colostrum",
            active: true,
            meta: {
              tags: "Colostrum",
              keywords: "Colostrum",
              description: "Colostrum",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aebb",
            },
            slug: "beauty-products",
            title: "Beauty Products",
            id: 5000639,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/beauty-products",
            active: true,
            meta: {
              tags: "Beauty Products",
              keywords: "Beauty Products",
              description: "Beauty Products",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096aebc",
                },
                slug: "cosmeceuticals",
                title: "Cosmeceuticals",
                id: 5000640,
                parent: "/food-beverage/food-supplements/beauty-products",
                path: "/food-beverage/food-supplements/beauty-products/cosmeceuticals",
                active: true,
                meta: {
                  tags: "Cosmeceuticals",
                  keywords: "Cosmeceuticals",
                  description: "Cosmeceuticals",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aebd",
            },
            slug: "digestive-health-supplements",
            title: "Digestive Health Supplements",
            id: 5000641,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/digestive-health-supplements",
            active: true,
            meta: {
              tags: "Digestive Health Supplements",
              keywords: "Digestive Health Supplements",
              description: "Digestive Health Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aebe",
            },
            slug: "electrolyte-mixes",
            title: "Electrolyte Mixes",
            id: 5000642,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/electrolyte-mixes",
            active: true,
            meta: {
              tags: "Electrolyte Mixes",
              keywords: "Electrolyte Mixes",
              description: "Electrolyte Mixes",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aebf",
            },
            slug: "gummy-supplements",
            title: "Gummy Supplements",
            id: 5000643,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/gummy-supplements",
            active: true,
            meta: {
              tags: "Gummy Supplements",
              keywords: "Gummy Supplements",
              description: "Gummy Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec0",
            },
            slug: "immune-health-supplements",
            title: "Immune Health Supplements",
            id: 5000644,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/immune-health-supplements",
            active: true,
            meta: {
              tags: "Immune Health Supplements",
              keywords: "Immune Health Supplements",
              description: "Immune Health Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec1",
            },
            slug: "krill-oil",
            title: "Krill Oil",
            id: 5000645,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/krill-oil",
            active: true,
            meta: {
              tags: "Krill Oil",
              keywords: "Krill Oil",
              description: "Krill Oil",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec2",
            },
            slug: "lifestyle-related-disease-supplements",
            title: "Lifestyle Related Disease Supplements",
            id: 5000646,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/lifestyle-related-disease-supplements",
            active: true,
            meta: {
              tags: "Lifestyle Related Disease Supplements",
              keywords: "Lifestyle Related Disease Supplements",
              description: "Lifestyle Related Disease Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec3",
            },
            slug: "nutraceuticals",
            title: "Nutraceuticals",
            id: 5000647,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/nutraceuticals",
            active: true,
            meta: {
              tags: "Nutraceuticals",
              keywords: "Nutraceuticals",
              description: "Nutraceuticals",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec4",
            },
            slug: "nutricosmetics",
            title: "Nutricosmetics",
            id: 5000648,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/nutricosmetics",
            active: true,
            meta: {
              tags: "Nutricosmetics",
              keywords: "Nutricosmetics",
              description: "Nutricosmetics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec5",
            },
            slug: "omega-3-supplements",
            title: "Omega 3 Supplements",
            id: 5000649,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/omega-3-supplements",
            active: true,
            meta: {
              tags: "Omega 3 Supplements",
              keywords: "Omega 3 Supplements",
              description: "Omega 3 Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec6",
            },
            slug: "pre-workout-supplements",
            title: "Pre Workout Supplements",
            id: 5000650,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/pre-workout-supplements",
            active: true,
            meta: {
              tags: "Pre Workout Supplements",
              keywords: "Pre Workout Supplements",
              description: "Pre Workout Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec7",
            },
            slug: "prenatal-supplements",
            title: "Prenatal Supplements",
            id: 5000651,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/prenatal-supplements",
            active: true,
            meta: {
              tags: "Prenatal Supplements",
              keywords: "Prenatal Supplements",
              description: "Prenatal Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec8",
            },
            slug: "probiotic-supplements",
            title: "Probiotic Supplements",
            id: 5000652,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/probiotic-supplements",
            active: true,
            meta: {
              tags: "Probiotic Supplements",
              keywords: "Probiotic Supplements",
              description: "Probiotic Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aec9",
            },
            slug: "protein-supplements",
            title: "Protein Supplements",
            id: 5000653,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/protein-supplements",
            active: true,
            meta: {
              tags: "Protein Supplements",
              keywords: "Protein Supplements",
              description: "Protein Supplements",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096aeca",
                },
                slug: "collagen-supplements",
                title: "Collagen Supplements",
                id: 5000654,
                parent: "/food-beverage/food-supplements/protein-supplements",
                path: "/food-beverage/food-supplements/protein-supplements/collagen-supplements",
                active: true,
                meta: {
                  tags: "Collagen Supplements",
                  keywords: "Collagen Supplements",
                  description: "Collagen Supplements",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aecb",
            },
            slug: "saffron-supplements",
            title: "Saffron Supplements",
            id: 5000655,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/saffron-supplements",
            active: true,
            meta: {
              tags: "Saffron Supplements",
              keywords: "Saffron Supplements",
              description: "Saffron Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aecc",
            },
            slug: "synbiotic-products",
            title: "Synbiotic Products",
            id: 5000656,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/synbiotic-products",
            active: true,
            meta: {
              tags: "Synbiotic Products",
              keywords: "Synbiotic Products",
              description: "Synbiotic Products",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aecd",
            },
            slug: "vegan-supplements",
            title: "Vegan Supplements",
            id: 5000657,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/vegan-supplements",
            active: true,
            meta: {
              tags: "Vegan Supplements",
              keywords: "Vegan Supplements",
              description: "Vegan Supplements",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aece",
            },
            slug: "vitamin-fortified-mineral-enriched-food-and-beverage",
            title: "Vitamin Fortified, Mineral Enriched Food and Beverage",
            id: 5000658,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/vitamin-fortified-mineral-enriched-food-and-beverage",
            active: true,
            meta: {
              tags: "Vitamin Fortified, Mineral Enriched Food and Beverage",
              keywords: "Vitamin Fortified, Mineral Enriched Food and Beverage",
              description:
                "Vitamin Fortified, Mineral Enriched Food and Beverage",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aecf",
            },
            slug: "vitamin-supplements",
            title: "Vitamin Supplements",
            id: 5000659,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/vitamin-supplements",
            active: true,
            meta: {
              tags: "Vitamin Supplements",
              keywords: "Vitamin Supplements",
              description: "Vitamin Supplements",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97c004b8af526096aed0",
                },
                slug: "gummy-vitamins",
                title: "Gummy Vitamins",
                id: 5000660,
                parent: "/food-beverage/food-supplements/vitamin-supplements",
                path: "/food-beverage/food-supplements/vitamin-supplements/gummy-vitamins",
                active: true,
                meta: {
                  tags: "Gummy Vitamins",
                  keywords: "Gummy Vitamins",
                  description: "Gummy Vitamins",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97c004b8af526096aed1",
                },
                slug: "vitamin-d-supplements",
                title: "Vitamin D Supplements",
                id: 5000661,
                parent: "/food-beverage/food-supplements/vitamin-supplements",
                path: "/food-beverage/food-supplements/vitamin-supplements/vitamin-d-supplements",
                active: true,
                meta: {
                  tags: "Vitamin D Supplements",
                  keywords: "Vitamin D Supplements",
                  description: "Vitamin D Supplements",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97c004b8af526096aed2",
            },
            slug: "weight-management-supplements",
            title: "Weight Management Supplements",
            id: 5000662,
            parent: "/food-beverage/food-supplements",
            path: "/food-beverage/food-supplements/weight-management-supplements",
            active: true,
            meta: {
              tags: "Weight Management Supplements",
              keywords: "Weight Management Supplements",
              description: "Weight Management Supplements",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b12f",
    },
    id: 63,
    title: "Packaging",
    slug: "packaging",
    active: true,
    meta: {
      tags: "Packaging Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "flexible packaging market, packaging market, food packaging market, cosmetic packaging market, plastic packaging market, glass packaging market, packaging machinery market, pharmaceutical packaging market, rigid packaging market, smart packaging market, flexible plastic packaging market, beverage packaging market, rigid plastic packaging market, fresh food packaging market, metal packaging market, paper packaging market, pharmaceutical packaging equipment market, packaging market size, protective packaging market, medical device packaging market, pet food packaging market, luxury packaging market, medical packaging market, food packaging market size, fresh meat packaging market, personal care packaging market, flexible packaging market size, pet packaging market, caps and closures market, agricultural packaging market, active packaging market, plastic packaging market size, plastic container market, medical packaging films market",
      description:
        "Mordor Intelligence™ 【  Packaging Market Research  】 Industry Reports, including market share insights for 2020, 2021, and 2022.  Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.\n",
    },
    description: {
      short_description:
        "The packaging industry continues to show strong growth, owing to increased consumption of consumer goods. MI helps you stay ahead by decoding market trends through more than 500 updated reports delineating the latest technologies, market trends, and competition.",
      full_description:
        "Packaging of goods refers to covering and wrapping the core product so as to safely reach the end customer. Packaging is of 2 types: Rigid and Flexible Packaging. Packaging is critically viewed by every organization in the sense that it projects the brand of the company as well apart from its traditional functionalities. The packaging industry continues to show strong growth, owing to increased consumption of consumer goods. MI helps you stay ahead by decoding market trends through more than 500 updated reports delineating the latest technologies, market trends, and competition.",
    },
    picture: null,
    __v: 0,
    picture_url: "packaging.png",
    cat_email: "ict@mordorintelligence.com",
    cat_pw_id: 12430,
    parent: "/",
    path: "/packaging",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b19e",
        },
        id: 63124,
        title: "Advanced Packaging",
        slug: "advanced-packaging",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Advanced Packaging Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Advanced Packaging Market Research Reports, intelligent packaging market, packaging market research report, advanced packaging market, advanced packaging market research report, advanced packaging industry, advanced packaging industry report.",
          tags: "Advanced Packaging Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Statistics for the 2023 Advanced Packaging market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Advanced Packaging Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          short_description:
            "Statistics for the 2023 Advanced Packaging market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Advanced Packaging Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        picture: null,
        __v: 0,
        parent: "/packaging",
        path: "/packaging/advanced-packaging",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328484e",
            },
            slug: "sustainable-packaging",
            title: "Sustainable Packaging",
            id: 10000008,
            parent: "/packaging/advanced-packaging",
            path: "/packaging/advanced-packaging/sustainable-packaging",
            active: true,
            meta: {
              tags: "Sustainable Packaging",
              keywords: "Sustainable Packaging",
              description: "Sustainable Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284849",
            },
            slug: "aseptic-and-retort-packaging",
            title: "Aseptic & Retort Packaging",
            id: 10000003,
            parent: "/packaging/advanced-packaging",
            path: "/packaging/advanced-packaging/aseptic-and-retort-packaging",
            active: true,
            meta: {
              tags: "Aseptic & Retort Packaging",
              keywords: "Aseptic & Retort Packaging",
              description: "Aseptic & Retort Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328484a",
                },
                slug: "aseptic-packaging",
                title: "Aseptic Packaging",
                id: 10000004,
                parent:
                  "/packaging/advanced-packaging/aseptic-and-retort-packaging",
                path: "/packaging/advanced-packaging/aseptic-and-retort-packaging/aseptic-packaging",
                active: true,
                meta: {
                  tags: "Aseptic Packaging",
                  keywords: "Aseptic Packaging",
                  description: "Aseptic Packaging",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328484b",
                },
                slug: "retort-packaging",
                title: "Retort Packaging",
                id: 10000005,
                parent:
                  "/packaging/advanced-packaging/aseptic-and-retort-packaging",
                path: "/packaging/advanced-packaging/aseptic-and-retort-packaging/retort-packaging",
                active: true,
                meta: {
                  tags: "Retort Packaging",
                  keywords: "Retort Packaging",
                  description: "Retort Packaging",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328484c",
            },
            slug: "e-commerce-packaging",
            title: "E-Commerce Packaging",
            id: 10000006,
            parent: "/packaging/advanced-packaging",
            path: "/packaging/advanced-packaging/e-commerce-packaging",
            active: true,
            meta: {
              tags: "E-Commerce Packaging",
              keywords: "E-Commerce Packaging",
              description: "E-Commerce Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284848",
            },
            slug: "active-and-intelligent-packaging",
            title: "Active & Intelligent Packaging",
            id: 10000002,
            parent: "/packaging/advanced-packaging",
            path: "/packaging/advanced-packaging/active-and-intelligent-packaging",
            active: true,
            meta: {
              tags: "Active & Intelligent Packaging",
              keywords: "Active & Intelligent Packaging",
              description: "Active & Intelligent Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328484d",
            },
            slug: "protective-packaging",
            title: "Protective Packaging",
            id: 10000007,
            parent: "/packaging/advanced-packaging",
            path: "/packaging/advanced-packaging/protective-packaging",
            active: true,
            meta: {
              tags: "Protective Packaging",
              keywords: "Protective Packaging",
              description: "Protective Packaging",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1a1",
        },
        id: 63127,
        title: "Packaging Equipment",
        slug: "packaging-equipment",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Packaging Equipment Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Packaging Equipment Market Research Reports, packaging equipment, packaging equipment industry, packaging equipment industry report, packaging equipment market report, packaging equipment market, packaging equipment industry analysis, packaging equipment industry growth, packaging equipment market research, packaging equipment market intelligence report",
          tags: "Packaging Equipment Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Statistics for the 2023 Packaging Equipment market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Packaging Equipment Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          short_description:
            "Statistics for the 2023 Packaging Equipment market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Packaging Equipment Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        picture: null,
        __v: 0,
        parent: "/packaging",
        path: "/packaging/packaging-equipment",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284868",
            },
            slug: "packaging-automation",
            title: "Packaging Automation",
            id: 10000035,
            parent: "/packaging/packaging-equipment",
            path: "/packaging/packaging-equipment/packaging-automation",
            active: true,
            meta: {
              tags: "Packaging Automation",
              keywords: "Packaging Automation",
              description: "Packaging Automation",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9f4c78447f3a5328484f",
        },
        slug: "contract-packaging",
        title: "Contract Packaging",
        id: 63653,
        parent: "/packaging",
        path: "/packaging/contract-packaging",
        active: true,
        meta: {
          tags: "Contract Packaging",
          keywords: "Contract Packaging",
          description: "Contract Packaging",
        },
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64ba9f4c78447f3a5328486d",
        },
        slug: "packaging-materials",
        title: "Packaging Materials",
        id: 63657,
        parent: "/packaging",
        path: "/packaging/packaging-materials",
        active: true,
        meta: {
          tags: "Packaging Materials",
          keywords: "Packaging Materials",
          description: "Packaging Materials",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284870",
            },
            slug: "metal-packaging",
            title: "Metal Packaging",
            id: 10000043,
            parent: "/packaging/packaging-materials",
            path: "/packaging/packaging-materials/metal-packaging",
            active: true,
            meta: {
              tags: "Metal Packaging",
              keywords: "Metal Packaging",
              description: "Metal Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9f4c78447f3a53284872",
                },
                slug: "rigid-metal-packaging",
                title: "Rigid Metal Packaging",
                id: 10000045,
                parent: "/packaging/packaging-materials/metal-packaging",
                path: "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging",
                active: true,
                meta: {
                  tags: "Rigid Metal Packaging",
                  keywords: "Rigid Metal Packaging",
                  description: "Rigid Metal Packaging",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9f4c78447f3a53284873",
                    },
                    slug: "metal-cans",
                    title: "Metal Cans",
                    id: 10000046,
                    parent:
                      "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging",
                    path: "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-cans",
                    active: true,
                    meta: {
                      tags: "Metal Cans",
                      keywords: "Metal Cans",
                      description: "Metal Cans",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9f4c78447f3a53284874",
                        },
                        slug: "aerosol-cans",
                        title: "Aerosol Cans",
                        id: 10000047,
                        parent:
                          "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-cans",
                        path: "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-cans/aerosol-cans",
                        active: true,
                        meta: {
                          tags: "Aerosol Cans",
                          keywords: "Aerosol Cans",
                          description: "Aerosol Cans",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9f4c78447f3a53284875",
                        },
                        slug: "food-cans",
                        title: "Food Cans",
                        id: 10000048,
                        parent:
                          "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-cans",
                        path: "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-cans/food-cans",
                        active: true,
                        meta: {
                          tags: "Food Cans",
                          keywords: "Food Cans",
                          description: "Food Cans",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9f4c78447f3a53284876",
                        },
                        slug: "beverage-cans",
                        title: "Beverage Cans",
                        id: 10000049,
                        parent:
                          "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-cans",
                        path: "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-cans/beverage-cans",
                        active: true,
                        meta: {
                          tags: "Beverage Cans",
                          keywords: "Beverage Cans",
                          description: "Beverage Cans",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9f4c78447f3a53284877",
                            },
                            slug: "beer-cans",
                            title: "Beer Cans",
                            id: 10000050,
                            parent:
                              "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-cans/beverage-cans",
                            path: "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-cans/beverage-cans/beer-cans",
                            active: true,
                            meta: {
                              tags: "Beer Cans",
                              keywords: "Beer Cans",
                              description: "Beer Cans",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9f4c78447f3a53284878",
                    },
                    slug: "metal-caps-and-closures",
                    title: "Metal Caps and Closures",
                    id: 10000051,
                    parent:
                      "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging",
                    path: "/packaging/packaging-materials/metal-packaging/rigid-metal-packaging/metal-caps-and-closures",
                    active: true,
                    meta: {
                      tags: "Metal Caps and Closures",
                      keywords: "Metal Caps and Closures",
                      description: "Metal Caps and Closures",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a53284871",
                },
                slug: "flexible-metal-packaging",
                title: "Flexible Metal Packaging",
                id: 10000044,
                parent: "/packaging/packaging-materials/metal-packaging",
                path: "/packaging/packaging-materials/metal-packaging/flexible-metal-packaging",
                active: true,
                meta: {
                  tags: "Flexible Metal Packaging",
                  keywords: "Flexible Metal Packaging",
                  description: "Flexible Metal Packaging",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328486e",
            },
            slug: "glass-packaging",
            title: "Glass Packaging",
            id: 10000041,
            parent: "/packaging/packaging-materials",
            path: "/packaging/packaging-materials/glass-packaging",
            active: true,
            meta: {
              tags: "Glass Packaging",
              keywords: "Glass Packaging",
              description: "Glass Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328486f",
                },
                slug: "glass-bottles",
                title: "Glass Bottles",
                id: 10000042,
                parent: "/packaging/packaging-materials/glass-packaging",
                path: "/packaging/packaging-materials/glass-packaging/glass-bottles",
                active: true,
                meta: {
                  tags: "Glass Bottles",
                  keywords: "Glass Bottles",
                  description: "Glass Bottles",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328487a",
            },
            slug: "plastic-packaging",
            title: "Plastic Packaging",
            id: 10000053,
            parent: "/packaging/packaging-materials",
            path: "/packaging/packaging-materials/plastic-packaging",
            active: true,
            meta: {
              tags: "Plastic Packaging",
              keywords: "Plastic Packaging",
              description: "Plastic Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328487d",
                },
                slug: "pet-packaging",
                title: "PET Packaging",
                id: 10000056,
                parent: "/packaging/packaging-materials/plastic-packaging",
                path: "/packaging/packaging-materials/plastic-packaging/pet-packaging",
                active: true,
                meta: {
                  tags: "PET Packaging",
                  keywords: "PET Packaging",
                  description: "PET Packaging",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328487b",
                },
                slug: "flexible-plastic-packaging",
                title: "Flexible Plastic Packaging",
                id: 10000054,
                parent: "/packaging/packaging-materials/plastic-packaging",
                path: "/packaging/packaging-materials/plastic-packaging/flexible-plastic-packaging",
                active: true,
                meta: {
                  tags: "Flexible Plastic Packaging",
                  keywords: "Flexible Plastic Packaging",
                  description: "Flexible Plastic Packaging",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9f4c78447f3a5328487c",
                    },
                    slug: "packaging-films",
                    title: "Packaging Films",
                    id: 10000055,
                    parent:
                      "/packaging/packaging-materials/plastic-packaging/flexible-plastic-packaging",
                    path: "/packaging/packaging-materials/plastic-packaging/flexible-plastic-packaging/packaging-films",
                    active: true,
                    meta: {
                      tags: "Packaging Films",
                      keywords: "Packaging Films",
                      description: "Packaging Films",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328487e",
                },
                slug: "rigid-plastic-packaging",
                title: "Rigid Plastic Packaging",
                id: 10000057,
                parent: "/packaging/packaging-materials/plastic-packaging",
                path: "/packaging/packaging-materials/plastic-packaging/rigid-plastic-packaging",
                active: true,
                meta: {
                  tags: "Rigid Plastic Packaging",
                  keywords: "Rigid Plastic Packaging",
                  description: "Rigid Plastic Packaging",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9f4c78447f3a5328487f",
                    },
                    slug: "plastic-bottles",
                    title: "Plastic Bottles",
                    id: 10000058,
                    parent:
                      "/packaging/packaging-materials/plastic-packaging/rigid-plastic-packaging",
                    path: "/packaging/packaging-materials/plastic-packaging/rigid-plastic-packaging/plastic-bottles",
                    active: true,
                    meta: {
                      tags: "Plastic Bottles",
                      keywords: "Plastic Bottles",
                      description: "Plastic Bottles",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9f4c78447f3a53284880",
                        },
                        slug: "pet-bottles",
                        title: "PET Bottles",
                        id: 10000059,
                        parent:
                          "/packaging/packaging-materials/plastic-packaging/rigid-plastic-packaging/plastic-bottles",
                        path: "/packaging/packaging-materials/plastic-packaging/rigid-plastic-packaging/plastic-bottles/pet-bottles",
                        active: true,
                        meta: {
                          tags: "PET Bottles",
                          keywords: "PET Bottles",
                          description: "PET Bottles",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9f4c78447f3a53284881",
                    },
                    slug: "plastic-caps-and-closures",
                    title: "Plastic Caps and Closures",
                    id: 10000060,
                    parent:
                      "/packaging/packaging-materials/plastic-packaging/rigid-plastic-packaging",
                    path: "/packaging/packaging-materials/plastic-packaging/rigid-plastic-packaging/plastic-caps-and-closures",
                    active: true,
                    meta: {
                      tags: "Plastic Caps and Closures",
                      keywords: "Plastic Caps and Closures",
                      description: "Plastic Caps and Closures",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284879",
            },
            slug: "paper-and-paperboard-packaging",
            title: "Paper & Paperboard Packaging",
            id: 10000052,
            parent: "/packaging/packaging-materials",
            path: "/packaging/packaging-materials/paper-and-paperboard-packaging",
            active: true,
            meta: {
              tags: "Paper & Paperboard Packaging",
              keywords: "Paper & Paperboard Packaging",
              description: "Paper & Paperboard Packaging",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9f4c78447f3a5328488a",
        },
        slug: "packaging-supplies",
        title: "Packaging Supplies",
        id: 63660,
        parent: "/packaging",
        path: "/packaging/packaging-supplies",
        active: true,
        meta: {
          tags: "Packaging Supplies",
          keywords: "Packaging Supplies",
          description: "Packaging Supplies",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328488c",
            },
            slug: "labels-and-sleeves",
            title: "Labels and Sleeves",
            id: 10000071,
            parent: "/packaging/packaging-supplies",
            path: "/packaging/packaging-supplies/labels-and-sleeves",
            active: true,
            meta: {
              tags: "Labels and Sleeves",
              keywords: "Labels and Sleeves",
              description: "Labels and Sleeves",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328488b",
            },
            slug: "labelling-machines",
            title: "Labelling Machines",
            id: 10000070,
            parent: "/packaging/packaging-supplies",
            path: "/packaging/packaging-supplies/labelling-machines",
            active: true,
            meta: {
              tags: "Labelling Machines",
              keywords: "Labelling Machines",
              description: "Labelling Machines",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9f4c78447f3a53284869",
        },
        slug: "packaging-forms",
        title: "Packaging Forms",
        id: 63656,
        parent: "/packaging",
        path: "/packaging/packaging-forms",
        active: true,
        meta: {
          tags: "Packaging Forms",
          keywords: "Packaging Forms",
          description: "Packaging Forms",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328486b",
            },
            slug: "rigid-packaging",
            title: "Rigid Packaging",
            id: 10000038,
            parent: "/packaging/packaging-forms",
            path: "/packaging/packaging-forms/rigid-packaging",
            active: true,
            meta: {
              tags: "Rigid Packaging",
              keywords: "Rigid Packaging",
              description: "Rigid Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328486c",
            },
            slug: "single-use-packaging",
            title: "Single-use Packaging",
            id: 10000039,
            parent: "/packaging/packaging-forms",
            path: "/packaging/packaging-forms/single-use-packaging",
            active: true,
            meta: {
              tags: "Single-use Packaging",
              keywords: "Single-use Packaging",
              description: "Single-use Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328486a",
            },
            slug: "flexible-packaging",
            title: "Flexible Packaging",
            id: 10000037,
            parent: "/packaging/packaging-forms",
            path: "/packaging/packaging-forms/flexible-packaging",
            active: true,
            meta: {
              tags: "Flexible Packaging",
              keywords: "Flexible Packaging",
              description: "Flexible Packaging",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9f4c78447f3a53284851",
        },
        slug: "packaging-customers",
        title: "Packaging Customers",
        id: 63655,
        parent: "/packaging",
        path: "/packaging/packaging-customers",
        active: true,
        meta: {
          tags: "Packaging Customers",
          keywords: "Packaging Customers",
          description: "Packaging Customers",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284853",
            },
            slug: "beverage-packaging",
            title: "Beverage Packaging",
            id: 10000013,
            parent: "/packaging/packaging-customers",
            path: "/packaging/packaging-customers/beverage-packaging",
            active: true,
            meta: {
              tags: "Beverage Packaging",
              keywords: "Beverage Packaging",
              description: "Beverage Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9f4c78447f3a53284854",
                },
                slug: "alcoholic-beverages-packaging",
                title: "Alcoholic Beverages Packaging",
                id: 10000014,
                parent: "/packaging/packaging-customers/beverage-packaging",
                path: "/packaging/packaging-customers/beverage-packaging/alcoholic-beverages-packaging",
                active: true,
                meta: {
                  tags: "Alcoholic Beverages Packaging",
                  keywords: "Alcoholic Beverages Packaging",
                  description: "Alcoholic Beverages Packaging",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a53284855",
                },
                slug: "non-alcoholic-beverages-packaging",
                title: "Non-Alcoholic Beverages Packaging",
                id: 10000015,
                parent: "/packaging/packaging-customers/beverage-packaging",
                path: "/packaging/packaging-customers/beverage-packaging/non-alcoholic-beverages-packaging",
                active: true,
                meta: {
                  tags: "Non-Alcoholic Beverages Packaging",
                  keywords: "Non-Alcoholic Beverages Packaging",
                  description: "Non-Alcoholic Beverages Packaging",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9f4c78447f3a53284856",
                    },
                    slug: "soft-drinks-packaging",
                    title: "Soft Drinks Packaging",
                    id: 10000016,
                    parent:
                      "/packaging/packaging-customers/beverage-packaging/non-alcoholic-beverages-packaging",
                    path: "/packaging/packaging-customers/beverage-packaging/non-alcoholic-beverages-packaging/soft-drinks-packaging",
                    active: true,
                    meta: {
                      tags: "Soft Drinks Packaging",
                      keywords: "Soft Drinks Packaging",
                      description: "Soft Drinks Packaging",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284865",
            },
            slug: "industrial-packaging",
            title: "Industrial Packaging",
            id: 10000031,
            parent: "/packaging/packaging-customers",
            path: "/packaging/packaging-customers/industrial-packaging",
            active: true,
            meta: {
              tags: "Industrial Packaging",
              keywords: "Industrial Packaging",
              description: "Industrial Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9f4c78447f3a53284866",
                },
                slug: "rtp",
                title: "RTP",
                id: 10000032,
                parent: "/packaging/packaging-customers/industrial-packaging",
                path: "/packaging/packaging-customers/industrial-packaging/rtp",
                active: true,
                meta: {
                  tags: "RTP",
                  keywords: "RTP",
                  description: "RTP",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a5328485a",
            },
            slug: "food-packaging",
            title: "Food Packaging",
            id: 10000020,
            parent: "/packaging/packaging-customers",
            path: "/packaging/packaging-customers/food-packaging",
            active: true,
            meta: {
              tags: "Food Packaging",
              keywords: "Food Packaging",
              description: "Food Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328485c",
                },
                slug: "dairy-packaging",
                title: "Dairy Packaging",
                id: 10000022,
                parent: "/packaging/packaging-customers/food-packaging",
                path: "/packaging/packaging-customers/food-packaging/dairy-packaging",
                active: true,
                meta: {
                  tags: "Dairy Packaging",
                  keywords: "Dairy Packaging",
                  description: "Dairy Packaging",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328485b",
                },
                slug: "baby-food-packaging",
                title: "Baby Food Packaging",
                id: 10000021,
                parent: "/packaging/packaging-customers/food-packaging",
                path: "/packaging/packaging-customers/food-packaging/baby-food-packaging",
                active: true,
                meta: {
                  tags: "Baby Food Packaging",
                  keywords: "Baby Food Packaging",
                  description: "Baby Food Packaging",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a53284860",
                },
                slug: "pet-food-packaging",
                title: "Pet Food Packaging",
                id: 10000026,
                parent: "/packaging/packaging-customers/food-packaging",
                path: "/packaging/packaging-customers/food-packaging/pet-food-packaging",
                active: true,
                meta: {
                  tags: "Pet Food Packaging",
                  keywords: "Pet Food Packaging",
                  description: "Pet Food Packaging",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328485e",
                },
                slug: "frozen-food-packaging",
                title: "Frozen Food Packaging",
                id: 10000024,
                parent: "/packaging/packaging-customers/food-packaging",
                path: "/packaging/packaging-customers/food-packaging/frozen-food-packaging",
                active: true,
                meta: {
                  tags: "Frozen Food Packaging",
                  keywords: "Frozen Food Packaging",
                  description: "Frozen Food Packaging",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328485f",
                },
                slug: "meat-packaging",
                title: "Meat Packaging",
                id: 10000025,
                parent: "/packaging/packaging-customers/food-packaging",
                path: "/packaging/packaging-customers/food-packaging/meat-packaging",
                active: true,
                meta: {
                  tags: "Meat Packaging",
                  keywords: "Meat Packaging",
                  description: "Meat Packaging",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a5328485d",
                },
                slug: "foodservice-packaging",
                title: "Foodservice Packaging",
                id: 10000023,
                parent: "/packaging/packaging-customers/food-packaging",
                path: "/packaging/packaging-customers/food-packaging/foodservice-packaging",
                active: true,
                meta: {
                  tags: "Foodservice Packaging",
                  keywords: "Foodservice Packaging",
                  description: "Foodservice Packaging",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284864",
            },
            slug: "home-care-packaging",
            title: "Home Care Packaging",
            id: 10000030,
            parent: "/packaging/packaging-customers",
            path: "/packaging/packaging-customers/home-care-packaging",
            active: true,
            meta: {
              tags: "Home Care Packaging",
              keywords: "Home Care Packaging",
              description: "Home Care Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284852",
            },
            slug: "agriculture-packaging",
            title: "Agriculture Packaging",
            id: 10000012,
            parent: "/packaging/packaging-customers",
            path: "/packaging/packaging-customers/agriculture-packaging",
            active: true,
            meta: {
              tags: "Agriculture Packaging",
              keywords: "Agriculture Packaging",
              description: "Agriculture Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284859",
            },
            slug: "electronics-packaging",
            title: "Electronics Packaging",
            id: 10000019,
            parent: "/packaging/packaging-customers",
            path: "/packaging/packaging-customers/electronics-packaging",
            active: true,
            meta: {
              tags: "Electronics Packaging",
              keywords: "Electronics Packaging",
              description: "Electronics Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284857",
            },
            slug: "consumer-goods-packaging",
            title: "Consumer Goods Packaging",
            id: 10000017,
            parent: "/packaging/packaging-customers",
            path: "/packaging/packaging-customers/consumer-goods-packaging",
            active: true,
            meta: {
              tags: "Consumer Goods Packaging",
              keywords: "Consumer Goods Packaging",
              description: "Consumer Goods Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9f4c78447f3a53284858",
                },
                slug: "cosmetic-packaging",
                title: "Cosmetic Packaging",
                id: 10000018,
                parent:
                  "/packaging/packaging-customers/consumer-goods-packaging",
                path: "/packaging/packaging-customers/consumer-goods-packaging/cosmetic-packaging",
                active: true,
                meta: {
                  tags: "Cosmetic Packaging",
                  keywords: "Cosmetic Packaging",
                  description: "Cosmetic Packaging",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284861",
            },
            slug: "healthcare-packaging",
            title: "Healthcare Packaging",
            id: 10000027,
            parent: "/packaging/packaging-customers",
            path: "/packaging/packaging-customers/healthcare-packaging",
            active: true,
            meta: {
              tags: "Healthcare Packaging",
              keywords: "Healthcare Packaging",
              description: "Healthcare Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9f4c78447f3a53284863",
                },
                slug: "pharmaceutical-packaging",
                title: "Pharmaceutical Packaging",
                id: 10000029,
                parent: "/packaging/packaging-customers/healthcare-packaging",
                path: "/packaging/packaging-customers/healthcare-packaging/pharmaceutical-packaging",
                active: true,
                meta: {
                  tags: "Pharmaceutical Packaging",
                  keywords: "Pharmaceutical Packaging",
                  description: "Pharmaceutical Packaging",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9f4c78447f3a53284862",
                },
                slug: "medical-devices-packaging",
                title: "Medical Devices Packaging",
                id: 10000028,
                parent: "/packaging/packaging-customers/healthcare-packaging",
                path: "/packaging/packaging-customers/healthcare-packaging/medical-devices-packaging",
                active: true,
                meta: {
                  tags: "Medical Devices Packaging",
                  keywords: "Medical Devices Packaging",
                  description: "Medical Devices Packaging",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284867",
            },
            slug: "tobacco-products-packaging",
            title: "Tobacco Products Packaging",
            id: 10000033,
            parent: "/packaging/packaging-customers",
            path: "/packaging/packaging-customers/tobacco-products-packaging",
            active: true,
            meta: {
              tags: "Tobacco Products Packaging",
              keywords: "Tobacco Products Packaging",
              description: "Tobacco Products Packaging",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9f4c78447f3a53284850",
        },
        slug: "overall-packaging",
        title: "Overall Packaging",
        id: 63654,
        parent: "/packaging",
        path: "/packaging/overall-packaging",
        active: true,
        meta: {
          tags: "Overall Packaging",
          keywords: "Overall Packaging",
          description: "Overall Packaging",
        },
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64ba9f4c78447f3a53284882",
        },
        slug: "packaging-printing",
        title: "Packaging Printing",
        id: 63658,
        parent: "/packaging",
        path: "/packaging/packaging-printing",
        active: true,
        meta: {
          tags: "Packaging Printing",
          keywords: "Packaging Printing",
          description: "Packaging Printing",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284883",
            },
            slug: "packaging-printers",
            title: "Packaging Printers",
            id: 10000062,
            parent: "/packaging/packaging-printing",
            path: "/packaging/packaging-printing/packaging-printers",
            active: true,
            meta: {
              tags: "Packaging Printers",
              keywords: "Packaging Printers",
              description: "Packaging Printers",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9f4c78447f3a53284884",
        },
        slug: "packaging-products",
        title: "Packaging Products",
        id: 63659,
        parent: "/packaging",
        path: "/packaging/packaging-products",
        active: true,
        meta: {
          tags: "Packaging Products",
          keywords: "Packaging Products",
          description: "Packaging Products",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284888",
            },
            slug: "pouch",
            title: "Pouch",
            id: 10000067,
            parent: "/packaging/packaging-products",
            path: "/packaging/packaging-products/pouch",
            active: true,
            meta: {
              tags: "Pouch",
              keywords: "Pouch",
              description: "Pouch",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284889",
            },
            slug: "tube-and-stick-packaging",
            title: "Tube and Stick Packaging",
            id: 10000068,
            parent: "/packaging/packaging-products",
            path: "/packaging/packaging-products/tube-and-stick-packaging",
            active: true,
            meta: {
              tags: "Tube and Stick Packaging",
              keywords: "Tube and Stick Packaging",
              description: "Tube and Stick Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284887",
            },
            slug: "blister-packaging",
            title: "Blister Packaging",
            id: 10000066,
            parent: "/packaging/packaging-products",
            path: "/packaging/packaging-products/blister-packaging",
            active: true,
            meta: {
              tags: "Blister Packaging",
              keywords: "Blister Packaging",
              description: "Blister Packaging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284885",
            },
            slug: "caps-and-closures",
            title: "Caps and Closures",
            id: 10000064,
            parent: "/packaging/packaging-products",
            path: "/packaging/packaging-products/caps-and-closures",
            active: true,
            meta: {
              tags: "Caps and Closures",
              keywords: "Caps and Closures",
              description: "Caps and Closures",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9f4c78447f3a53284886",
            },
            slug: "ampoules",
            title: "Ampoules",
            id: 10000065,
            parent: "/packaging/packaging-products",
            path: "/packaging/packaging-products/ampoules",
            active: true,
            meta: {
              tags: "Ampoules",
              keywords: "Ampoules",
              description: "Ampoules",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b132",
    },
    id: 69,
    title: "Manufacturing Products and Services",
    slug: "manufacturing-products-and-services",
    active: true,
    meta: {
      tags: "Manufacturing Processes Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "manufacturing industry analysis, manufacturing industry, manufacturing industry report, manufacturing industry trends, technology in manufacturing industry, smart manufacturing market, manufacturing industry overview, manufacturing market size",
      description:
        "Our Manufacturing Processes industry reports offer insights on major trends, technologies, competition and growth opportunities in the market. The global and regional analysis includes statistics, market segments, overview, key players, market drivers, and the future developments.",
    },
    description: {
      short_description:
        "Manufacturing Industry plays a pivotal role in the world economy. Mordor Intelligence™  helps determine the most reliable analytics and trends in the Industrial manufacturing market. Our studies encompass the entire Industrial manufacturing value chain and provide market intelligence for manufacturing technology, processes, services, and tools. Reports include supply chain analysis and outlook on investment opportunities.",
      full_description:
        "Manufacturing Industry plays a pivotal role in the world economy. Mordor Intelligence™ helps determine the most reliable analytics and trends in the Industrial manufacturing market. Our studies encompass the entire Industrial manufacturing value chain and provide market intelligence for manufacturing technology, processes, services, and tools. Reports include supply chain analysis and outlook on investment opportunities.",
    },
    picture: null,
    __v: 0,
    picture_url: "Manufacturing.png",
    cat_email: "neoteam@mordorintelligence.com",
    cat_pw_id: 129727,
    parent: "/",
    path: "/manufacturing-products-and-services",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1b6",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Textile Manufacturing market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Textile Manufacturing Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Textile Manufacturing market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Textile Manufacturing Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Textile Manufacturing Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Textile Manufacturing Market Research Reports, textile manufacturing market, textile manufacturing market report, textile manufacturing market research, textile manufacturing industry, textile manufacturing industry report, textile manufacturing industry research",
          description:
            "Mordor Intelligence™ 【  Textile Manufacturing Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Textiles",
        slug: "textiles",
        id: 69139,
        __v: 0,
        parent: "/manufacturing-products-and-services",
        path: "/manufacturing-products-and-services/textiles",
        sub_categories: [
          {
            _id: {
              $oid: "64c253e752c4caecce83d440",
            },
            slug: "textile-equipment",
            title: "Textile Equipment",
            id: 16000025,
            parent: "/manufacturing-products-and-services/textiles",
            path: "/manufacturing-products-and-services/textiles/textile-equipment",
            active: true,
            meta: {
              tags: "Textile Equipment",
              keywords: "Textile Equipment",
              description: "Textile Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253e752c4caecce83d441",
                },
                slug: "knitting-machines",
                title: "Knitting Machines",
                id: 16000026,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-equipment",
                path: "/manufacturing-products-and-services/textiles/textile-equipment/knitting-machines",
                active: true,
                meta: {
                  tags: "Knitting Machines",
                  keywords: "Knitting Machines",
                  description: "Knitting Machines",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d442",
                },
                slug: "sewbots",
                title: "Sewbots",
                id: 16000027,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-equipment",
                path: "/manufacturing-products-and-services/textiles/textile-equipment/sewbots",
                active: true,
                meta: {
                  tags: "Sewbots",
                  keywords: "Sewbots",
                  description: "Sewbots",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d443",
                },
                slug: "spinning-machines",
                title: "Spinning Machines",
                id: 16000028,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-equipment",
                path: "/manufacturing-products-and-services/textiles/textile-equipment/spinning-machines",
                active: true,
                meta: {
                  tags: "Spinning Machines",
                  keywords: "Spinning Machines",
                  description: "Spinning Machines",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d444",
                },
                slug: "textile-finishing-machinery",
                title: "Textile Finishing Machinery",
                id: 16000029,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-equipment",
                path: "/manufacturing-products-and-services/textiles/textile-equipment/textile-finishing-machinery",
                active: true,
                meta: {
                  tags: "Textile Finishing Machinery",
                  keywords: "Textile Finishing Machinery",
                  description: "Textile Finishing Machinery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d445",
                },
                slug: "textile-testing-equipment",
                title: "Textile Testing Equipment",
                id: 16000030,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-equipment",
                path: "/manufacturing-products-and-services/textiles/textile-equipment/textile-testing-equipment",
                active: true,
                meta: {
                  tags: "Textile Testing Equipment",
                  keywords: "Textile Testing Equipment",
                  description: "Textile Testing Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d446",
                },
                slug: "texturing-machine",
                title: "Texturing Machine",
                id: 16000031,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-equipment",
                path: "/manufacturing-products-and-services/textiles/textile-equipment/texturing-machine",
                active: true,
                meta: {
                  tags: "Texturing Machine",
                  keywords: "Texturing Machine",
                  description: "Texturing Machine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d447",
                },
                slug: "tricot-machine",
                title: "Tricot Machine",
                id: 16000032,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-equipment",
                path: "/manufacturing-products-and-services/textiles/textile-equipment/tricot-machine",
                active: true,
                meta: {
                  tags: "Tricot Machine",
                  keywords: "Tricot Machine",
                  description: "Tricot Machine",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d448",
                },
                slug: "weaving-machines",
                title: "Weaving Machines",
                id: 16000033,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-equipment",
                path: "/manufacturing-products-and-services/textiles/textile-equipment/weaving-machines",
                active: true,
                meta: {
                  tags: "Weaving Machines",
                  keywords: "Weaving Machines",
                  description: "Weaving Machines",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253e752c4caecce83d449",
            },
            slug: "textile-manufacturing",
            title: "Textile Manufacturing",
            id: 16000034,
            parent: "/manufacturing-products-and-services/textiles",
            path: "/manufacturing-products-and-services/textiles/textile-manufacturing",
            active: true,
            meta: {
              tags: "Textile Manufacturing",
              keywords: "Textile Manufacturing",
              description: "Textile Manufacturing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253e752c4caecce83d44a",
                },
                slug: "fabric",
                title: "Fabric",
                id: 16000035,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-manufacturing",
                path: "/manufacturing-products-and-services/textiles/textile-manufacturing/fabric",
                active: true,
                meta: {
                  tags: "Fabric",
                  keywords: "Fabric",
                  description: "Fabric",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d44b",
                },
                slug: "fibre",
                title: "Fibre",
                id: 16000036,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-manufacturing",
                path: "/manufacturing-products-and-services/textiles/textile-manufacturing/fibre",
                active: true,
                meta: {
                  tags: "Fibre",
                  keywords: "Fibre",
                  description: "Fibre",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d44c",
                },
                slug: "garment",
                title: "Garment",
                id: 16000037,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-manufacturing",
                path: "/manufacturing-products-and-services/textiles/textile-manufacturing/garment",
                active: true,
                meta: {
                  tags: "Garment",
                  keywords: "Garment",
                  description: "Garment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d44d",
                },
                slug: "industrial-and-technical-textiles",
                title: "Industrial and Technical Textiles",
                id: 16000038,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-manufacturing",
                path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                active: true,
                meta: {
                  tags: "Industrial and Technical Textiles",
                  keywords: "Industrial and Technical Textiles",
                  description: "Industrial and Technical Textiles",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d44e",
                    },
                    slug: "agro-textiles",
                    title: "Agro Textiles",
                    id: 16000039,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/agro-textiles",
                    active: true,
                    meta: {
                      tags: "Agro Textiles",
                      keywords: "Agro Textiles",
                      description: "Agro Textiles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d44f",
                    },
                    slug: "buildtech-textile",
                    title: "Buildtech Textile",
                    id: 16000040,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/buildtech-textile",
                    active: true,
                    meta: {
                      tags: "Buildtech Textile",
                      keywords: "Buildtech Textile",
                      description: "Buildtech Textile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d450",
                    },
                    slug: "clothtech-textile",
                    title: "Clothtech Textile",
                    id: 16000041,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/clothtech-textile",
                    active: true,
                    meta: {
                      tags: "Clothtech Textile",
                      keywords: "Clothtech Textile",
                      description: "Clothtech Textile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d451",
                    },
                    slug: "hometech-textile",
                    title: "Hometech Textile",
                    id: 16000042,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/hometech-textile",
                    active: true,
                    meta: {
                      tags: "Hometech Textile",
                      keywords: "Hometech Textile",
                      description: "Hometech Textile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d452",
                    },
                    slug: "indutech-textile",
                    title: "Indutech Textile",
                    id: 16000043,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/indutech-textile",
                    active: true,
                    meta: {
                      tags: "Indutech Textile",
                      keywords: "Indutech Textile",
                      description: "Indutech Textile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d453",
                    },
                    slug: "medtech-textile",
                    title: "Medtech Textile",
                    id: 16000044,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/medtech-textile",
                    active: true,
                    meta: {
                      tags: "Medtech Textile",
                      keywords: "Medtech Textile",
                      description: "Medtech Textile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d454",
                    },
                    slug: "packtech-textile",
                    title: "Packtech Textile",
                    id: 16000045,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/packtech-textile",
                    active: true,
                    meta: {
                      tags: "Packtech Textile",
                      keywords: "Packtech Textile",
                      description: "Packtech Textile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d455",
                    },
                    slug: "protech-textile",
                    title: "Protech Textile",
                    id: 16000046,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/protech-textile",
                    active: true,
                    meta: {
                      tags: "Protech Textile",
                      keywords: "Protech Textile",
                      description: "Protech Textile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d456",
                    },
                    slug: "sportech-textile",
                    title: "Sportech Textile",
                    id: 16000047,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/sportech-textile",
                    active: true,
                    meta: {
                      tags: "Sportech Textile",
                      keywords: "Sportech Textile",
                      description: "Sportech Textile",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d457",
                    },
                    slug: "transtech-textile",
                    title: "Transtech Textile",
                    id: 16000048,
                    parent:
                      "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles",
                    path: "/manufacturing-products-and-services/textiles/textile-manufacturing/industrial-and-technical-textiles/transtech-textile",
                    active: true,
                    meta: {
                      tags: "Transtech Textile",
                      keywords: "Transtech Textile",
                      description: "Transtech Textile",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d458",
                },
                slug: "yarn",
                title: "Yarn",
                id: 16000049,
                parent:
                  "/manufacturing-products-and-services/textiles/textile-manufacturing",
                path: "/manufacturing-products-and-services/textiles/textile-manufacturing/yarn",
                active: true,
                meta: {
                  tags: "Yarn",
                  keywords: "Yarn",
                  description: "Yarn",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1ba",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Metal Working market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Metal Working Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Metal Working market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Metal Working Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Metal Working Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Metal Working Market Research Reports, metal working market, metal working industry, metal working industry report, metal working market report, metal working industry analysis, metal working industry research, metal working market intelligence report, metal working market growth, metal working industry forecast",
          description:
            "Mordor Intelligence™ 【  Metal Working Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Metal Working and Fabrication",
        slug: "metal-working-and-fabrication",
        id: 69207,
        __v: 0,
        parent: "/manufacturing-products-and-services",
        path: "/manufacturing-products-and-services/metal-working-and-fabrication",
        sub_categories: [
          {
            _id: {
              $oid: "64c253e752c4caecce83d430",
            },
            slug: "fabricated-metal-products",
            title: "Fabricated Metal Products",
            id: 16000008,
            parent:
              "/manufacturing-products-and-services/metal-working-and-fabrication",
            path: "/manufacturing-products-and-services/metal-working-and-fabrication/fabricated-metal-products",
            active: true,
            meta: {
              tags: "Fabricated Metal Products",
              keywords: "Fabricated Metal Products",
              description: "Fabricated Metal Products",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c253e752c4caecce83d431",
            },
            slug: "metal-working-equipment",
            title: "Metal Working Equipment",
            id: 16000009,
            parent:
              "/manufacturing-products-and-services/metal-working-and-fabrication",
            path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment",
            active: true,
            meta: {
              tags: "Metal Working Equipment",
              keywords: "Metal Working Equipment",
              description: "Metal Working Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253e752c4caecce83d432",
                },
                slug: "additive-manufacturing-equipment",
                title: "Additive Manufacturing Equipment",
                id: 16000010,
                parent:
                  "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment",
                path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/additive-manufacturing-equipment",
                active: true,
                meta: {
                  tags: "Additive Manufacturing Equipment",
                  keywords: "Additive Manufacturing Equipment",
                  description: "Additive Manufacturing Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d433",
                },
                slug: "metal-fabrication-equipment",
                title: "Metal Fabrication Equipment",
                id: 16000011,
                parent:
                  "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment",
                path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/metal-fabrication-equipment",
                active: true,
                meta: {
                  tags: "Metal Fabrication Equipment",
                  keywords: "Metal Fabrication Equipment",
                  description: "Metal Fabrication Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d434",
                    },
                    slug: "machine-tools",
                    title: "Machine Tools",
                    id: 16000012,
                    parent:
                      "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/metal-fabrication-equipment",
                    path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/metal-fabrication-equipment/machine-tools",
                    active: true,
                    meta: {
                      tags: "Machine Tools",
                      keywords: "Machine Tools",
                      description: "Machine Tools",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d435",
                    },
                    slug: "metal-cutting-equipment",
                    title: "Metal Cutting Equipment",
                    id: 16000013,
                    parent:
                      "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/metal-fabrication-equipment",
                    path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/metal-fabrication-equipment/metal-cutting-equipment",
                    active: true,
                    meta: {
                      tags: "Metal Cutting Equipment",
                      keywords: "Metal Cutting Equipment",
                      description: "Metal Cutting Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d436",
                    },
                    slug: "metal-forming-equipment",
                    title: "Metal Forming Equipment",
                    id: 16000014,
                    parent:
                      "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/metal-fabrication-equipment",
                    path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/metal-fabrication-equipment/metal-forming-equipment",
                    active: true,
                    meta: {
                      tags: "Metal Forming Equipment",
                      keywords: "Metal Forming Equipment",
                      description: "Metal Forming Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d437",
                    },
                    slug: "welding-equipment",
                    title: "Welding Equipment",
                    id: 16000015,
                    parent:
                      "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/metal-fabrication-equipment",
                    path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/metal-fabrication-equipment/welding-equipment",
                    active: true,
                    meta: {
                      tags: "Welding Equipment",
                      keywords: "Welding Equipment",
                      description: "Welding Equipment",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d438",
                },
                slug: "precision-engineering-machines",
                title: "Precision Engineering Machines",
                id: 16000016,
                parent:
                  "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment",
                path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-equipment/precision-engineering-machines",
                active: true,
                meta: {
                  tags: "Precision Engineering Machines",
                  keywords: "Precision Engineering Machines",
                  description: "Precision Engineering Machines",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253e752c4caecce83d439",
            },
            slug: "metal-working-services",
            title: "Metal Working Services",
            id: 16000017,
            parent:
              "/manufacturing-products-and-services/metal-working-and-fabrication",
            path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services",
            active: true,
            meta: {
              tags: "Metal Working Services",
              keywords: "Metal Working Services",
              description: "Metal Working Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253e752c4caecce83d43a",
                },
                slug: "machining-services",
                title: "Machining Services",
                id: 16000018,
                parent:
                  "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services",
                path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services/machining-services",
                active: true,
                meta: {
                  tags: "Machining Services",
                  keywords: "Machining Services",
                  description: "Machining Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d43b",
                    },
                    slug: "metal-cutting-services",
                    title: "Metal Cutting Services",
                    id: 16000019,
                    parent:
                      "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services/machining-services",
                    path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services/machining-services/metal-cutting-services",
                    active: true,
                    meta: {
                      tags: "Metal Cutting Services",
                      keywords: "Metal Cutting Services",
                      description: "Metal Cutting Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d43c",
                    },
                    slug: "metal-forming-services",
                    title: "Metal Forming Services",
                    id: 16000020,
                    parent:
                      "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services/machining-services",
                    path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services/machining-services/metal-forming-services",
                    active: true,
                    meta: {
                      tags: "Metal Forming Services",
                      keywords: "Metal Forming Services",
                      description: "Metal Forming Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d43d",
                    },
                    slug: "precision-engineering-services",
                    title: "Precision Engineering Services",
                    id: 16000021,
                    parent:
                      "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services/machining-services",
                    path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services/machining-services/precision-engineering-services",
                    active: true,
                    meta: {
                      tags: "Precision Engineering Services",
                      keywords: "Precision Engineering Services",
                      description: "Precision Engineering Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253e752c4caecce83d43e",
                    },
                    slug: "welding-services",
                    title: "Welding Services",
                    id: 16000022,
                    parent:
                      "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services/machining-services",
                    path: "/manufacturing-products-and-services/metal-working-and-fabrication/metal-working-services/machining-services/welding-services",
                    active: true,
                    meta: {
                      tags: "Welding Services",
                      keywords: "Welding Services",
                      description: "Welding Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253e752c4caecce83d43f",
            },
            slug: "steel-fabrication",
            title: "Steel Fabrication",
            id: 16000023,
            parent:
              "/manufacturing-products-and-services/metal-working-and-fabrication",
            path: "/manufacturing-products-and-services/metal-working-and-fabrication/steel-fabrication",
            active: true,
            meta: {
              tags: "Steel Fabrication",
              keywords: "Steel Fabrication",
              description: "Steel Fabrication",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1bb",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 General Manufacturing market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. General Manufacturing Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 General Manufacturing market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. General Manufacturing Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "General Manufacturing Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "General Manufacturing Market Research Reports, general manufacturing, general manufacturing market growth, general manufacturing market analysis, general manufacturing industry analysis, general manufacturing market report, general manufacturing industry report, general manufacturing industry analysis, general manufacturing industry forecast, general manufacturing industry, general manufacturing market",
          description:
            "Mordor Intelligence™ 【  General Manufacturing Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "General Manufacturing",
        slug: "general-manufacturing",
        id: 69208,
        __v: 0,
        parent: "/manufacturing-products-and-services",
        path: "/manufacturing-products-and-services/general-manufacturing",
        sub_categories: [
          {
            _id: {
              $oid: "64c253e752c4caecce83d42b",
            },
            slug: "factory-equipment",
            title: "Factory Equipment",
            id: 16000002,
            parent:
              "/manufacturing-products-and-services/general-manufacturing",
            path: "/manufacturing-products-and-services/general-manufacturing/factory-equipment",
            active: true,
            meta: {
              tags: "Factory Equipment",
              keywords: "Factory Equipment",
              description: "Factory Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253e752c4caecce83d42c",
                },
                slug: "assembly-line-solutions",
                title: "Assembly Line Solutions",
                id: 16000003,
                parent:
                  "/manufacturing-products-and-services/general-manufacturing/factory-equipment",
                path: "/manufacturing-products-and-services/general-manufacturing/factory-equipment/assembly-line-solutions",
                active: true,
                meta: {
                  tags: "Assembly Line Solutions",
                  keywords: "Assembly Line Solutions",
                  description: "Assembly Line Solutions",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253e752c4caecce83d42d",
                },
                slug: "moulds-and-dies",
                title: "Moulds and Dies",
                id: 16000004,
                parent:
                  "/manufacturing-products-and-services/general-manufacturing/factory-equipment",
                path: "/manufacturing-products-and-services/general-manufacturing/factory-equipment/moulds-and-dies",
                active: true,
                meta: {
                  tags: "Moulds and Dies",
                  keywords: "Moulds and Dies",
                  description: "Moulds and Dies",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64c253e752c4caecce83d42e",
        },
        slug: "manufacturing-services",
        title: "Manufacturing Services",
        id: 69687,
        parent: "/manufacturing-products-and-services",
        path: "/manufacturing-products-and-services/manufacturing-services",
        active: true,
        meta: {
          tags: "Manufacturing Services",
          keywords: "Manufacturing Services",
          description: "Manufacturing Services",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c253e752c4caecce83d42f",
            },
            slug: "manufacturing-outsourcing",
            title: "Manufacturing Outsourcing",
            id: 16000006,
            parent:
              "/manufacturing-products-and-services/manufacturing-services",
            path: "/manufacturing-products-and-services/manufacturing-services/manufacturing-outsourcing",
            active: true,
            meta: {
              tags: "Manufacturing Outsourcing",
              keywords: "Manufacturing Outsourcing",
              description: "Manufacturing Outsourcing",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b131",
    },
    id: 66,
    title: "Consumer Goods and Services",
    slug: "consumer-goods-and-services",
    active: true,
    meta: {
      tags: "Consumer Goods and Services Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "cpg industry, skin care market, personal care industry, fmcg sector, skincare industry, consumer services industry, fmcg market, hair care industry, beauty industry analysis, skincare market size, cosmetic industry analysis, consumer goods market, cpg industry trends, beauty and personal care industry, skin care market analysis, retail market report, hair care industry statistics, household products industry, skincare industry growth, personal care market, footwear industry analysis",
      description:
        "Consumer Goods & Services reports have wide coverage of market analysis of common to niche consumer products and gives an in-depth understanding of the market dynamics in industries such as FMCG, apparel, footwear and accessories, sports and fitness industries.",
    },
    description: {
      short_description:
        "With the burgeoning middle class and changing consumer spending habits, the consumer goods and services industry is on the cusp of change, with leaders using marketing innovations to outperform competitors. Browse MI's repository of industry-relevant reports to gain insights about this industry.",
      full_description:
        "The section covers goods and services that are directly consumed as final goods by consumers and do not contribute to any other stage of production.  The domain includes topics ranging from durable goods, nondurable goods and services such as clothes, food, and jewelry.",
    },
    picture: null,
    __v: 0,
    picture_url: "consumer-goods.png",
    cat_email: "afnb@mordorintelligence.com",
    cat_pw_id: 12433,
    parent: "/",
    path: "/consumer-goods-and-services",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1ac",
        },
        id: 6699,
        title: "Appliances",
        slug: "appliances",
        active: true,
        meta: {
          description:
            "The section includes household and kitchen equipment and gadgets that perform specific household tasks such as frying, baking, toasting, cleaning, wiping, etc.",
          keywords:
            "Appliances Market Research Reports, appliances market growth, appliances market analysis, appliances industry analysis, appliances market report, appliances industry report, appliances industry analysis, appliances industry forecast, appliances industry, appliances market",
          tags: "Appliances Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Statistics for the 2023 Appliances market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Appliances Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          short_description:
            "Changes in government regulations coupled with the emergence of built-in appliances are expected to drive the next wave of change in the consumer devices domain. Our industry reports endeavor to uncover key trends in product development and the evolution of distribution channels.",
        },
        picture: null,
        __v: 0,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/appliances",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1ad",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Apparel, Footwear & Accessories market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Apparel, Footwear & Accessories Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Apparel, Footwear & Accessories market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Apparel, Footwear & Accessories Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Apparel, Footwear & Accessories Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Apparel, Footwear & Accessories Market Research Reports, apparel footwear accessories market, apparel footwear accessories market report, apparel footwear accessories market research, apparel footwear accessories industry, apparel footwear accessories industry report, apparel footwear accessories industry research",
          description:
            "Mordor Intelligence™ 【  Apparel, Footwear & Accessories Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Apparel, Footwear & Accessories",
        slug: "apparel-footwear-accessories",
        id: 66172,
        __v: 0,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/apparel-footwear-accessories",
        sub_categories: [
          {
            _id: {
              $oid: "64ad98328e934a04a5530d7b",
            },
            slug: "apparel",
            title: "Apparel",
            id: 6000002,
            parent: "/consumer-goods-and-services/apparel-footwear-accessories",
            path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
            active: true,
            meta: {
              tags: "Apparel",
              keywords: "Apparel",
              description: "Apparel",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d7c",
                },
                slug: "apparel-renting",
                title: "Apparel Renting",
                id: 6000003,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/apparel-renting",
                active: true,
                meta: {
                  tags: "Apparel Renting",
                  keywords: "Apparel Renting",
                  description: "Apparel Renting",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d7d",
                },
                slug: "athleisure",
                title: "Athleisure",
                id: 6000004,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/athleisure",
                active: true,
                meta: {
                  tags: "Athleisure",
                  keywords: "Athleisure",
                  description: "Athleisure",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d7e",
                },
                slug: "down-and-feather",
                title: "Down & Feather",
                id: 6000005,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/down-and-feather",
                active: true,
                meta: {
                  tags: "Down & Feather",
                  keywords: "Down & Feather",
                  description: "Down & Feather",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d7f",
                },
                slug: "hosiery",
                title: "Hosiery",
                id: 6000006,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/hosiery",
                active: true,
                meta: {
                  tags: "Hosiery",
                  keywords: "Hosiery",
                  description: "Hosiery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d80",
                },
                slug: "jeans",
                title: "Jeans",
                id: 6000007,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/jeans",
                active: true,
                meta: {
                  tags: "Jeans",
                  keywords: "Jeans",
                  description: "Jeans",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d81",
                },
                slug: "kids-wear",
                title: "Kids Wear",
                id: 6000008,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/kids-wear",
                active: true,
                meta: {
                  tags: "Kids Wear",
                  keywords: "Kids Wear",
                  description: "Kids Wear",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d82",
                },
                slug: "lingerie",
                title: "Lingerie",
                id: 6000009,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/lingerie",
                active: true,
                meta: {
                  tags: "Lingerie",
                  keywords: "Lingerie",
                  description: "Lingerie",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530d83",
                    },
                    slug: "erotic-lingerie",
                    title: "Erotic Lingerie",
                    id: 6000010,
                    parent:
                      "/consumer-goods-and-services/apparel-footwear-accessories/apparel/lingerie",
                    path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/lingerie/erotic-lingerie",
                    active: true,
                    meta: {
                      tags: "Erotic Lingerie",
                      keywords: "Erotic Lingerie",
                      description: "Erotic Lingerie",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d84",
                },
                slug: "luxury-apparel",
                title: "Luxury Apparel",
                id: 6000011,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/luxury-apparel",
                active: true,
                meta: {
                  tags: "Luxury Apparel",
                  keywords: "Luxury Apparel",
                  description: "Luxury Apparel",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d85",
                },
                slug: "mens-wear",
                title: "Mens Wear",
                id: 6000012,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/mens-wear",
                active: true,
                meta: {
                  tags: "Mens Wear",
                  keywords: "Mens Wear",
                  description: "Mens Wear",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d86",
                },
                slug: "running-apparel",
                title: "Running Apparel",
                id: 6000013,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/running-apparel",
                active: true,
                meta: {
                  tags: "Running Apparel",
                  keywords: "Running Apparel",
                  description: "Running Apparel",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d87",
                },
                slug: "socks",
                title: "Socks",
                id: 6000014,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/socks",
                active: true,
                meta: {
                  tags: "Socks",
                  keywords: "Socks",
                  description: "Socks",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d88",
                },
                slug: "sportswear",
                title: "Sportswear",
                id: 6000015,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/sportswear",
                active: true,
                meta: {
                  tags: "Sportswear",
                  keywords: "Sportswear",
                  description: "Sportswear",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d89",
                },
                slug: "swimwear",
                title: "Swimwear",
                id: 6000016,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/apparel",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/apparel/swimwear",
                active: true,
                meta: {
                  tags: "Swimwear",
                  keywords: "Swimwear",
                  description: "Swimwear",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530d8a",
            },
            slug: "fashion-accessories",
            title: "Fashion Accessories",
            id: 6000017,
            parent: "/consumer-goods-and-services/apparel-footwear-accessories",
            path: "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories",
            active: true,
            meta: {
              tags: "Fashion Accessories",
              keywords: "Fashion Accessories",
              description: "Fashion Accessories",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d8b",
                },
                slug: "eyewear",
                title: "Eyewear",
                id: 6000018,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/eyewear",
                active: true,
                meta: {
                  tags: "Eyewear",
                  keywords: "Eyewear",
                  description: "Eyewear",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530d8c",
                    },
                    slug: "contact-lens",
                    title: "Contact Lens",
                    id: 6000019,
                    parent:
                      "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/eyewear",
                    path: "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/eyewear/contact-lens",
                    active: true,
                    meta: {
                      tags: "Contact Lens",
                      keywords: "Contact Lens",
                      description: "Contact Lens",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530d8d",
                    },
                    slug: "online-eyewear",
                    title: "Online Eyewear",
                    id: 6000020,
                    parent:
                      "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/eyewear",
                    path: "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/eyewear/online-eyewear",
                    active: true,
                    meta: {
                      tags: "Online Eyewear",
                      keywords: "Online Eyewear",
                      description: "Online Eyewear",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530d8e",
                    },
                    slug: "sunglasses",
                    title: "Sunglasses",
                    id: 6000021,
                    parent:
                      "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/eyewear",
                    path: "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/eyewear/sunglasses",
                    active: true,
                    meta: {
                      tags: "Sunglasses",
                      keywords: "Sunglasses",
                      description: "Sunglasses",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d8f",
                },
                slug: "handbags",
                title: "Handbags",
                id: 6000022,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/handbags",
                active: true,
                meta: {
                  tags: "Handbags",
                  keywords: "Handbags",
                  description: "Handbags",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d90",
                },
                slug: "headwear",
                title: "Headwear",
                id: 6000023,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/headwear",
                active: true,
                meta: {
                  tags: "Headwear",
                  keywords: "Headwear",
                  description: "Headwear",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d91",
                },
                slug: "leather-goods",
                title: "Leather Goods",
                id: 6000024,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/leather-goods",
                active: true,
                meta: {
                  tags: "Leather Goods",
                  keywords: "Leather Goods",
                  description: "Leather Goods",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d92",
                },
                slug: "luggage",
                title: "Luggage",
                id: 6000025,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/fashion-accessories/luggage",
                active: true,
                meta: {
                  tags: "Luggage",
                  keywords: "Luggage",
                  description: "Luggage",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530d93",
            },
            slug: "footwear",
            title: "Footwear",
            id: 6000026,
            parent: "/consumer-goods-and-services/apparel-footwear-accessories",
            path: "/consumer-goods-and-services/apparel-footwear-accessories/footwear",
            active: true,
            meta: {
              tags: "Footwear",
              keywords: "Footwear",
              description: "Footwear",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d94",
                },
                slug: "athletic-footwear",
                title: "Athletic Footwear",
                id: 6000027,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/footwear",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/footwear/athletic-footwear",
                active: true,
                meta: {
                  tags: "Athletic Footwear",
                  keywords: "Athletic Footwear",
                  description: "Athletic Footwear",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d95",
                },
                slug: "diabetic-footwear",
                title: "Diabetic Footwear",
                id: 6000028,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/footwear",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/footwear/diabetic-footwear",
                active: true,
                meta: {
                  tags: "Diabetic Footwear",
                  keywords: "Diabetic Footwear",
                  description: "Diabetic Footwear",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d96",
                },
                slug: "non-athletic-footwear",
                title: "Non Athletic Footwear",
                id: 6000029,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/footwear",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/footwear/non-athletic-footwear",
                active: true,
                meta: {
                  tags: "Non Athletic Footwear",
                  keywords: "Non Athletic Footwear",
                  description: "Non Athletic Footwear",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530d97",
                    },
                    slug: "women-non-athletic-footwear",
                    title: "Women Non Athletic Footwear",
                    id: 6000030,
                    parent:
                      "/consumer-goods-and-services/apparel-footwear-accessories/footwear/non-athletic-footwear",
                    path: "/consumer-goods-and-services/apparel-footwear-accessories/footwear/non-athletic-footwear/women-non-athletic-footwear",
                    active: true,
                    meta: {
                      tags: "Women Non Athletic Footwear",
                      keywords: "Women Non Athletic Footwear",
                      description: "Women Non Athletic Footwear",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530d98",
            },
            slug: "mobile-protective-cases",
            title: "Mobile Protective Cases",
            id: 6000031,
            parent: "/consumer-goods-and-services/apparel-footwear-accessories",
            path: "/consumer-goods-and-services/apparel-footwear-accessories/mobile-protective-cases",
            active: true,
            meta: {
              tags: "Mobile Protective Cases",
              keywords: "Mobile Protective Cases",
              description: "Mobile Protective Cases",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530d99",
            },
            slug: "ppe",
            title: "PPE",
            id: 6000032,
            parent: "/consumer-goods-and-services/apparel-footwear-accessories",
            path: "/consumer-goods-and-services/apparel-footwear-accessories/ppe",
            active: true,
            meta: {
              tags: "PPE",
              keywords: "PPE",
              description: "PPE",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d9a",
                },
                slug: "protective-footwear",
                title: "Protective Footwear",
                id: 6000033,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/ppe",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/ppe/protective-footwear",
                active: true,
                meta: {
                  tags: "Protective Footwear",
                  keywords: "Protective Footwear",
                  description: "Protective Footwear",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d9b",
                },
                slug: "protective-eyewear",
                title: "Protective Eyewear",
                id: 6000034,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/ppe",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/ppe/protective-eyewear",
                active: true,
                meta: {
                  tags: "Protective Eyewear",
                  keywords: "Protective Eyewear",
                  description: "Protective Eyewear",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d9c",
                },
                slug: "protective-masks",
                title: "Protective Masks",
                id: 6000035,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/ppe",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/ppe/protective-masks",
                active: true,
                meta: {
                  tags: "Protective Masks",
                  keywords: "Protective Masks",
                  description: "Protective Masks",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530d9d",
                },
                slug: "safety-gloves",
                title: "Safety Gloves",
                id: 6000036,
                parent:
                  "/consumer-goods-and-services/apparel-footwear-accessories/ppe",
                path: "/consumer-goods-and-services/apparel-footwear-accessories/ppe/safety-gloves",
                active: true,
                meta: {
                  tags: "Safety Gloves",
                  keywords: "Safety Gloves",
                  description: "Safety Gloves",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530d9e",
            },
            slug: "workwear",
            title: "Workwear",
            id: 6000037,
            parent: "/consumer-goods-and-services/apparel-footwear-accessories",
            path: "/consumer-goods-and-services/apparel-footwear-accessories/workwear",
            active: true,
            meta: {
              tags: "Workwear",
              keywords: "Workwear",
              description: "Workwear",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1ae",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Cosmetics market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Cosmetics Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Cosmetics market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Cosmetics Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Cosmetics Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Cosmetics Market Research Reports, cosmetics market, personal care industry, personal care market, skin care products market, personal care products industry, personal care products market, cosmetics and personal care industry, personal care market research, cosmetic industry reports",
          description:
            "Mordor Intelligence™ 【  Cosmetics Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Cosmetics",
        slug: "cosmetics",
        id: 66173,
        __v: 0,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/cosmetics",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1b0",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Luxury Goods & Services market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Luxury Goods & Services Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Luxury Goods & Services market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Luxury Goods & Services Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Luxury Goods & Services Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Luxury Goods & Services Market Research Reports, luxury goods & services industry, luxury goods & services market, luxury goods & services market report, luxury goods & services market reports, luxury goods & services industry report, luxury goods & services industry reports, luxury goods & services industry analysis, luxury goods & services industry growth, luxury goods & services market intelligence report",
          description:
            "Mordor Intelligence™ 【  Luxury Goods & Services Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Luxury Goods & Services",
        slug: "luxury-goods-services",
        id: 66175,
        __v: 0,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/luxury-goods-services",
        sub_categories: [
          {
            _id: {
              $oid: "64ad98328e934a04a5530e08",
            },
            slug: "luxury-goods",
            title: "Luxury Goods",
            id: 6000144,
            parent: "/consumer-goods-and-services/luxury-goods-services",
            path: "/consumer-goods-and-services/luxury-goods-services/luxury-goods",
            active: true,
            meta: {
              tags: "Luxury Goods",
              keywords: "Luxury Goods",
              description: "Luxury Goods",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e09",
                },
                slug: "gems-and-jewelry",
                title: "Gems & Jewelry",
                id: 6000145,
                parent:
                  "/consumer-goods-and-services/luxury-goods-services/luxury-goods",
                path: "/consumer-goods-and-services/luxury-goods-services/luxury-goods/gems-and-jewelry",
                active: true,
                meta: {
                  tags: "Gems & Jewelry",
                  keywords: "Gems & Jewelry",
                  description: "Gems & Jewelry",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e0a",
                    },
                    slug: "jewelry",
                    title: "Jewelry",
                    id: 6000146,
                    parent:
                      "/consumer-goods-and-services/luxury-goods-services/luxury-goods/gems-and-jewelry",
                    path: "/consumer-goods-and-services/luxury-goods-services/luxury-goods/gems-and-jewelry/jewelry",
                    active: true,
                    meta: {
                      tags: "Jewelry",
                      keywords: "Jewelry",
                      description: "Jewelry",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530e0b",
                        },
                        slug: "chalcedony-earrings",
                        title: "Chalcedony Earrings",
                        id: 6000147,
                        parent:
                          "/consumer-goods-and-services/luxury-goods-services/luxury-goods/gems-and-jewelry/jewelry",
                        path: "/consumer-goods-and-services/luxury-goods-services/luxury-goods/gems-and-jewelry/jewelry/chalcedony-earrings",
                        active: true,
                        meta: {
                          tags: "Chalcedony Earrings",
                          keywords: "Chalcedony Earrings",
                          description: "Chalcedony Earrings",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e0c",
                },
                slug: "watch",
                title: "Watch",
                id: 6000148,
                parent:
                  "/consumer-goods-and-services/luxury-goods-services/luxury-goods",
                path: "/consumer-goods-and-services/luxury-goods-services/luxury-goods/watch",
                active: true,
                meta: {
                  tags: "Watch",
                  keywords: "Watch",
                  description: "Watch",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e0d",
                    },
                    slug: "digital-watch",
                    title: "Digital Watch",
                    id: 6000149,
                    parent:
                      "/consumer-goods-and-services/luxury-goods-services/luxury-goods/watch",
                    path: "/consumer-goods-and-services/luxury-goods-services/luxury-goods/watch/digital-watch",
                    active: true,
                    meta: {
                      tags: "Digital Watch",
                      keywords: "Digital Watch",
                      description: "Digital Watch",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e0e",
                    },
                    slug: "luxury-watch",
                    title: "Luxury Watch",
                    id: 6000150,
                    parent:
                      "/consumer-goods-and-services/luxury-goods-services/luxury-goods/watch",
                    path: "/consumer-goods-and-services/luxury-goods-services/luxury-goods/watch/luxury-watch",
                    active: true,
                    meta: {
                      tags: "Luxury Watch",
                      keywords: "Luxury Watch",
                      description: "Luxury Watch",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1b1",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Household Care market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Household Care Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Household Care market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Household Care Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Household Care Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Household Care Market Research Reports, household care industry, household care market, household care market report, household care market reports, household care industry report, household care industry reports, household care industry analysis, household care industry growth, household care market intelligence report",
          description:
            "Mordor Intelligence™ 【  Household Care Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Household Care",
        slug: "household-care",
        id: 66176,
        __v: 0,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/household-care",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1b2",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Other Consumer Goods and Services Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Consumer Goods and Services Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Other Consumer Goods and Services Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Consumer Goods and Services Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Other Consumer Goods Services Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Consumer Goods and Services Market Research Reports, consumer goods services, consumer goods services industry, consumer goods services industry report, consumer goods services market report, consumer goods services market, consumer goods services industry analysis, consumer goods services industry growth, consumer goods services market research, consumer goods services market intelligence report",
          description:
            "Mordor Intelligence™ 【  Other Consumer Goods Services Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Other Consumer Goods and Services Research",
        slug: "consumer-goods-services-others",
        id: 66177,
        __v: 0,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/consumer-goods-services-others",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1b3",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Recreational Goods market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Recreational Goods Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Recreational Goods market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Recreational Goods Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Recreational Goods Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Recreational Goods Market Research Reports, Recreational Goods Market, Recreational Goods industry, Recreational Goods market research, Recreational Goods industry research, Recreational Goods market research report, Recreational Goods industry research report",
          description:
            "Mordor Intelligence™ 【  Recreational Goods Market Research   】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Recreational Goods",
        slug: "recreational-goods",
        id: 66178,
        __v: 0,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/recreational-goods",
        sub_categories: [
          {
            _id: {
              $oid: "64ad98328e934a04a5530e0f",
            },
            slug: "hobby-equipment",
            title: "Hobby Equipment",
            id: 6000152,
            parent: "/consumer-goods-and-services/recreational-goods",
            path: "/consumer-goods-and-services/recreational-goods/hobby-equipment",
            active: true,
            meta: {
              tags: "Hobby Equipment",
              keywords: "Hobby Equipment",
              description: "Hobby Equipment",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530e10",
            },
            slug: "online-gambling",
            title: "Online Gambling",
            id: 6000153,
            parent: "/consumer-goods-and-services/recreational-goods",
            path: "/consumer-goods-and-services/recreational-goods/online-gambling",
            active: true,
            meta: {
              tags: "Online Gambling",
              keywords: "Online Gambling",
              description: "Online Gambling",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e11",
                },
                slug: "online-lottery",
                title: "Online Lottery",
                id: 6000154,
                parent:
                  "/consumer-goods-and-services/recreational-goods/online-gambling",
                path: "/consumer-goods-and-services/recreational-goods/online-gambling/online-lottery",
                active: true,
                meta: {
                  tags: "Online Lottery",
                  keywords: "Online Lottery",
                  description: "Online Lottery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e12",
                },
                slug: "online-sports-betting",
                title: "Online Sports Betting",
                id: 6000155,
                parent:
                  "/consumer-goods-and-services/recreational-goods/online-gambling",
                path: "/consumer-goods-and-services/recreational-goods/online-gambling/online-sports-betting",
                active: true,
                meta: {
                  tags: "Online Sports Betting",
                  keywords: "Online Sports Betting",
                  description: "Online Sports Betting",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530e13",
            },
            slug: "tobacco-and-e-cigarettes",
            title: "Tobacco and E-Cigarettes",
            id: 6000156,
            parent: "/consumer-goods-and-services/recreational-goods",
            path: "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes",
            active: true,
            meta: {
              tags: "Tobacco and E-Cigarettes",
              keywords: "Tobacco and E-Cigarettes",
              description: "Tobacco and E-Cigarettes",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e14",
                },
                slug: "e-cigarettes",
                title: "E-Cigarettes",
                id: 6000157,
                parent:
                  "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes",
                path: "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes/e-cigarettes",
                active: true,
                meta: {
                  tags: "E-Cigarettes",
                  keywords: "E-Cigarettes",
                  description: "E-Cigarettes",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e15",
                },
                slug: "premium-tobacco-products",
                title: "Premium Tobacco Products",
                id: 6000158,
                parent:
                  "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes",
                path: "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes/premium-tobacco-products",
                active: true,
                meta: {
                  tags: "Premium Tobacco Products",
                  keywords: "Premium Tobacco Products",
                  description: "Premium Tobacco Products",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e16",
                },
                slug: "tobacco",
                title: "Tobacco",
                id: 6000159,
                parent:
                  "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes",
                path: "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes/tobacco",
                active: true,
                meta: {
                  tags: "Tobacco",
                  keywords: "Tobacco",
                  description: "Tobacco",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e17",
                    },
                    slug: "cigar",
                    title: "Cigar",
                    id: 6000160,
                    parent:
                      "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes/tobacco",
                    path: "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes/tobacco/cigar",
                    active: true,
                    meta: {
                      tags: "Cigar",
                      keywords: "Cigar",
                      description: "Cigar",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e18",
                    },
                    slug: "cigarettes",
                    title: "Cigarettes",
                    id: 6000161,
                    parent:
                      "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes/tobacco",
                    path: "/consumer-goods-and-services/recreational-goods/tobacco-and-e-cigarettes/tobacco/cigarettes",
                    active: true,
                    meta: {
                      tags: "Cigarettes",
                      keywords: "Cigarettes",
                      description: "Cigarettes",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530e19",
            },
            slug: "toys-and-games",
            title: "Toys & Games",
            id: 6000162,
            parent: "/consumer-goods-and-services/recreational-goods",
            path: "/consumer-goods-and-services/recreational-goods/toys-and-games",
            active: true,
            meta: {
              tags: "Toys & Games",
              keywords: "Toys & Games",
              description: "Toys & Games",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e1a",
                },
                slug: "traditional-toys-and-games",
                title: "Traditional Toys & Games",
                id: 6000163,
                parent:
                  "/consumer-goods-and-services/recreational-goods/toys-and-games",
                path: "/consumer-goods-and-services/recreational-goods/toys-and-games/traditional-toys-and-games",
                active: true,
                meta: {
                  tags: "Traditional Toys & Games",
                  keywords: "Traditional Toys & Games",
                  description: "Traditional Toys & Games",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1b4",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Sports & Fitness market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Sports & Fitness Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Sports & Fitness market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Sports & Fitness Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Sports & Fitness Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Sports & Fitness Market Research Reports, sports and fitness market, sports and fitness industry, sports and fitness market report, sports market report, sports market analysis, fitness market research reports, sports and fitness market research reports",
          description:
            "Mordor Intelligence™ 【  Sports & Fitness Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Sports & Fitness",
        slug: "sports-fitness",
        id: 66179,
        __v: 0,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/sports-fitness",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64ad98328e934a04a5530d9f",
        },
        slug: "baby-care",
        title: "Baby Care",
        id: 66616,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/baby-care",
        active: true,
        meta: {
          tags: "Baby Care",
          keywords: "Baby Care",
          description: "Baby Care",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad98328e934a04a5530da0",
            },
            slug: "baby-carrier",
            title: "Baby Carrier",
            id: 6000039,
            parent: "/consumer-goods-and-services/baby-care",
            path: "/consumer-goods-and-services/baby-care/baby-carrier",
            active: true,
            meta: {
              tags: "Baby Carrier",
              keywords: "Baby Carrier",
              description: "Baby Carrier",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530da1",
            },
            slug: "baby-stroller",
            title: "Baby Stroller",
            id: 6000040,
            parent: "/consumer-goods-and-services/baby-care",
            path: "/consumer-goods-and-services/baby-care/baby-stroller",
            active: true,
            meta: {
              tags: "Baby Stroller",
              keywords: "Baby Stroller",
              description: "Baby Stroller",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530da2",
            },
            slug: "baby-diapers-and-wipes",
            title: "Baby Diapers & Wipes",
            id: 6000041,
            parent: "/consumer-goods-and-services/baby-care",
            path: "/consumer-goods-and-services/baby-care/baby-diapers-and-wipes",
            active: true,
            meta: {
              tags: "Baby Diapers & Wipes",
              keywords: "Baby Diapers & Wipes",
              description: "Baby Diapers & Wipes",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530da3",
                },
                slug: "baby-diapers",
                title: "Baby Diapers",
                id: 6000042,
                parent:
                  "/consumer-goods-and-services/baby-care/baby-diapers-and-wipes",
                path: "/consumer-goods-and-services/baby-care/baby-diapers-and-wipes/baby-diapers",
                active: true,
                meta: {
                  tags: "Baby Diapers",
                  keywords: "Baby Diapers",
                  description: "Baby Diapers",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530da4",
            },
            slug: "baby-oral-care",
            title: "Baby Oral Care",
            id: 6000043,
            parent: "/consumer-goods-and-services/baby-care",
            path: "/consumer-goods-and-services/baby-care/baby-oral-care",
            active: true,
            meta: {
              tags: "Baby Oral Care",
              keywords: "Baby Oral Care",
              description: "Baby Oral Care",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530da5",
            },
            slug: "baby-toiletries",
            title: "Baby Toiletries",
            id: 6000044,
            parent: "/consumer-goods-and-services/baby-care",
            path: "/consumer-goods-and-services/baby-care/baby-toiletries",
            active: true,
            meta: {
              tags: "Baby Toiletries",
              keywords: "Baby Toiletries",
              description: "Baby Toiletries",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530da6",
            },
            slug: "baby-monitors",
            title: "Baby Monitors",
            id: 6000045,
            parent: "/consumer-goods-and-services/baby-care",
            path: "/consumer-goods-and-services/baby-care/baby-monitors",
            active: true,
            meta: {
              tags: "Baby Monitors",
              keywords: "Baby Monitors",
              description: "Baby Monitors",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ad98328e934a04a5530da7",
        },
        slug: "beauty-and-personal-care",
        title: "Beauty and Personal Care",
        id: 66617,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/beauty-and-personal-care",
        active: true,
        meta: {
          tags: "Beauty and Personal Care",
          keywords: "Beauty and Personal Care",
          description: "Beauty and Personal Care",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad98328e934a04a5530da8",
            },
            slug: "cosmetics-and-beauty-products",
            title: "Cosmetics and Beauty Products",
            id: 6000047,
            parent: "/consumer-goods-and-services/beauty-and-personal-care",
            path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products",
            active: true,
            meta: {
              tags: "Cosmetics and Beauty Products",
              keywords: "Cosmetics and Beauty Products",
              description: "Cosmetics and Beauty Products",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530da9",
                },
                slug: "color-cosmetics",
                title: "Color Cosmetics",
                id: 6000048,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products",
                path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/color-cosmetics",
                active: true,
                meta: {
                  tags: "Color Cosmetics",
                  keywords: "Color Cosmetics",
                  description: "Color Cosmetics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530daa",
                    },
                    slug: "makeup",
                    title: "Makeup",
                    id: 6000049,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/color-cosmetics",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/color-cosmetics/makeup",
                    active: true,
                    meta: {
                      tags: "Makeup",
                      keywords: "Makeup",
                      description: "Makeup",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dab",
                        },
                        slug: "eye-makeup",
                        title: "Eye Makeup",
                        id: 6000050,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/color-cosmetics/makeup",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/color-cosmetics/makeup/eye-makeup",
                        active: true,
                        meta: {
                          tags: "Eye Makeup",
                          keywords: "Eye Makeup",
                          description: "Eye Makeup",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad98328e934a04a5530dac",
                            },
                            slug: "false-eyelashes",
                            title: "False Eyelashes",
                            id: 6000051,
                            parent:
                              "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/color-cosmetics/makeup/eye-makeup",
                            path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/color-cosmetics/makeup/eye-makeup/false-eyelashes",
                            active: true,
                            meta: {
                              tags: "False Eyelashes",
                              keywords: "False Eyelashes",
                              description: "False Eyelashes",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dad",
                        },
                        slug: "facial-makeup",
                        title: "Facial Makeup",
                        id: 6000052,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/color-cosmetics/makeup",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/color-cosmetics/makeup/facial-makeup",
                        active: true,
                        meta: {
                          tags: "Facial Makeup",
                          keywords: "Facial Makeup",
                          description: "Facial Makeup",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530dae",
                },
                slug: "emollient",
                title: "Emollient",
                id: 6000053,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products",
                path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/emollient",
                active: true,
                meta: {
                  tags: "Emollient",
                  keywords: "Emollient",
                  description: "Emollient",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530daf",
                },
                slug: "halal-cosmetics",
                title: "Halal Cosmetics",
                id: 6000054,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products",
                path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/halal-cosmetics",
                active: true,
                meta: {
                  tags: "Halal Cosmetics",
                  keywords: "Halal Cosmetics",
                  description: "Halal Cosmetics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530db0",
                },
                slug: "herbal-beauty-products",
                title: "Herbal Beauty Products",
                id: 6000055,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products",
                path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/herbal-beauty-products",
                active: true,
                meta: {
                  tags: "Herbal Beauty Products",
                  keywords: "Herbal Beauty Products",
                  description: "Herbal Beauty Products",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530db1",
                },
                slug: "online-cosmetics",
                title: "Online Cosmetics",
                id: 6000056,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products",
                path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/online-cosmetics",
                active: true,
                meta: {
                  tags: "Online Cosmetics",
                  keywords: "Online Cosmetics",
                  description: "Online Cosmetics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530db2",
                },
                slug: "organic-cosmetics",
                title: "Organic Cosmetics",
                id: 6000057,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products",
                path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/organic-cosmetics",
                active: true,
                meta: {
                  tags: "Organic Cosmetics",
                  keywords: "Organic Cosmetics",
                  description: "Organic Cosmetics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530db3",
                },
                slug: "probiotic-cosmetics",
                title: "Probiotic Cosmetics",
                id: 6000058,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products",
                path: "/consumer-goods-and-services/beauty-and-personal-care/cosmetics-and-beauty-products/probiotic-cosmetics",
                active: true,
                meta: {
                  tags: "Probiotic Cosmetics",
                  keywords: "Probiotic Cosmetics",
                  description: "Probiotic Cosmetics",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530db4",
            },
            slug: "personal-care",
            title: "Personal Care",
            id: 6000059,
            parent: "/consumer-goods-and-services/beauty-and-personal-care",
            path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
            active: true,
            meta: {
              tags: "Personal Care",
              keywords: "Personal Care",
              description: "Personal Care",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530db5",
                },
                slug: "bath-and-shower-products",
                title: "Bath & Shower Products",
                id: 6000060,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products",
                active: true,
                meta: {
                  tags: "Bath & Shower Products",
                  keywords: "Bath & Shower Products",
                  description: "Bath & Shower Products",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530db6",
                    },
                    slug: "body-scrub",
                    title: "Body Scrub",
                    id: 6000061,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products/body-scrub",
                    active: true,
                    meta: {
                      tags: "Body Scrub",
                      keywords: "Body Scrub",
                      description: "Body Scrub",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530db7",
                    },
                    slug: "soaps",
                    title: "Soaps",
                    id: 6000062,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products/soaps",
                    active: true,
                    meta: {
                      tags: "Soaps",
                      keywords: "Soaps",
                      description: "Soaps",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530db8",
                        },
                        slug: "liquid-soap",
                        title: "Liquid Soap",
                        id: 6000063,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products/soaps",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products/soaps/liquid-soap",
                        active: true,
                        meta: {
                          tags: "Liquid Soap",
                          keywords: "Liquid Soap",
                          description: "Liquid Soap",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad98328e934a04a5530db9",
                            },
                            slug: "professional-liquid-soap",
                            title: "Professional Liquid Soap",
                            id: 6000064,
                            parent:
                              "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products/soaps/liquid-soap",
                            path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products/soaps/liquid-soap/professional-liquid-soap",
                            active: true,
                            meta: {
                              tags: "Professional Liquid Soap",
                              keywords: "Professional Liquid Soap",
                              description: "Professional Liquid Soap",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dba",
                        },
                        slug: "organic-soap",
                        title: "Organic Soap",
                        id: 6000065,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products/soaps",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/bath-and-shower-products/soaps/organic-soap",
                        active: true,
                        meta: {
                          tags: "Organic Soap",
                          keywords: "Organic Soap",
                          description: "Organic Soap",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530dbb",
                },
                slug: "deodorants",
                title: "Deodorants",
                id: 6000066,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/deodorants",
                active: true,
                meta: {
                  tags: "Deodorants",
                  keywords: "Deodorants",
                  description: "Deodorants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530dbc",
                },
                slug: "fragrances-and-perfumes",
                title: "Fragrances & Perfumes",
                id: 6000067,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/fragrances-and-perfumes",
                active: true,
                meta: {
                  tags: "Fragrances & Perfumes",
                  keywords: "Fragrances & Perfumes",
                  description: "Fragrances & Perfumes",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dbd",
                    },
                    slug: "luxury-perfumes",
                    title: "Luxury Perfumes",
                    id: 6000068,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/fragrances-and-perfumes",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/fragrances-and-perfumes/luxury-perfumes",
                    active: true,
                    meta: {
                      tags: "Luxury Perfumes",
                      keywords: "Luxury Perfumes",
                      description: "Luxury Perfumes",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530dbe",
                },
                slug: "hair-care",
                title: "Hair Care",
                id: 6000069,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                active: true,
                meta: {
                  tags: "Hair Care",
                  keywords: "Hair Care",
                  description: "Hair Care",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dbf",
                    },
                    slug: "hair-conditioner",
                    title: "Hair Conditioner",
                    id: 6000070,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-conditioner",
                    active: true,
                    meta: {
                      tags: "Hair Conditioner",
                      keywords: "Hair Conditioner",
                      description: "Hair Conditioner",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dc0",
                        },
                        slug: "intensive-conditioner",
                        title: "Intensive Conditioner",
                        id: 6000071,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-conditioner",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-conditioner/intensive-conditioner",
                        active: true,
                        meta: {
                          tags: "Intensive Conditioner",
                          keywords: "Intensive Conditioner",
                          description: "Intensive Conditioner",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dc1",
                    },
                    slug: "hair-loss-treatment",
                    title: "Hair Loss Treatment",
                    id: 6000072,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-loss-treatment",
                    active: true,
                    meta: {
                      tags: "Hair Loss Treatment",
                      keywords: "Hair Loss Treatment",
                      description: "Hair Loss Treatment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dc2",
                    },
                    slug: "hair-oil",
                    title: "Hair Oil",
                    id: 6000073,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-oil",
                    active: true,
                    meta: {
                      tags: "Hair Oil",
                      keywords: "Hair Oil",
                      description: "Hair Oil",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dc3",
                    },
                    slug: "hair-styling-products",
                    title: "Hair Styling Products",
                    id: 6000074,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products",
                    active: true,
                    meta: {
                      tags: "Hair Styling Products",
                      keywords: "Hair Styling Products",
                      description: "Hair Styling Products",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dc4",
                        },
                        slug: "hair-colorants",
                        title: "Hair Colorants",
                        id: 6000075,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products/hair-colorants",
                        active: true,
                        meta: {
                          tags: "Hair Colorants",
                          keywords: "Hair Colorants",
                          description: "Hair Colorants",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad98328e934a04a5530dc5",
                            },
                            slug: "hair-color-sprays",
                            title: "Hair Color Sprays",
                            id: 6000076,
                            parent:
                              "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products/hair-colorants",
                            path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products/hair-colorants/hair-color-sprays",
                            active: true,
                            meta: {
                              tags: "Hair Color Sprays",
                              keywords: "Hair Color Sprays",
                              description: "Hair Color Sprays",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dc6",
                        },
                        slug: "hair-gel",
                        title: "Hair Gel",
                        id: 6000077,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products/hair-gel",
                        active: true,
                        meta: {
                          tags: "Hair Gel",
                          keywords: "Hair Gel",
                          description: "Hair Gel",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dc7",
                        },
                        slug: "hair-serum",
                        title: "Hair Serum",
                        id: 6000078,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products/hair-serum",
                        active: true,
                        meta: {
                          tags: "Hair Serum",
                          keywords: "Hair Serum",
                          description: "Hair Serum",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dc8",
                        },
                        slug: "perms-and-relaxants",
                        title: "Perms & Relaxants",
                        id: 6000079,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products/perms-and-relaxants",
                        active: true,
                        meta: {
                          tags: "Perms & Relaxants",
                          keywords: "Perms & Relaxants",
                          description: "Perms & Relaxants",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad98328e934a04a5530dc9",
                            },
                            slug: "hair-relaxer",
                            title: "Hair Relaxer",
                            id: 6000080,
                            parent:
                              "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products/perms-and-relaxants",
                            path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/hair-styling-products/perms-and-relaxants/hair-relaxer",
                            active: true,
                            meta: {
                              tags: "Hair Relaxer",
                              keywords: "Hair Relaxer",
                              description: "Hair Relaxer",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dca",
                    },
                    slug: "herbal-hair-care",
                    title: "Herbal Hair Care",
                    id: 6000081,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/herbal-hair-care",
                    active: true,
                    meta: {
                      tags: "Herbal Hair Care",
                      keywords: "Herbal Hair Care",
                      description: "Herbal Hair Care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dcb",
                    },
                    slug: "premium-hair-care",
                    title: "Premium Hair Care",
                    id: 6000082,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/premium-hair-care",
                    active: true,
                    meta: {
                      tags: "Premium Hair Care",
                      keywords: "Premium Hair Care",
                      description: "Premium Hair Care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dcc",
                    },
                    slug: "salon-hair-care",
                    title: "Salon Hair Care",
                    id: 6000083,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/salon-hair-care",
                    active: true,
                    meta: {
                      tags: "Salon Hair Care",
                      keywords: "Salon Hair Care",
                      description: "Salon Hair Care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dcd",
                    },
                    slug: "scalp-care",
                    title: "Scalp Care",
                    id: 6000084,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/scalp-care",
                    active: true,
                    meta: {
                      tags: "Scalp Care",
                      keywords: "Scalp Care",
                      description: "Scalp Care",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dce",
                        },
                        slug: "professional-scalp-care",
                        title: "Professional Scalp Care",
                        id: 6000085,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/scalp-care",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/scalp-care/professional-scalp-care",
                        active: true,
                        meta: {
                          tags: "Professional Scalp Care",
                          keywords: "Professional Scalp Care",
                          description: "Professional Scalp Care",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dcf",
                    },
                    slug: "shampoo",
                    title: "Shampoo",
                    id: 6000086,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/shampoo",
                    active: true,
                    meta: {
                      tags: "Shampoo",
                      keywords: "Shampoo",
                      description: "Shampoo",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dd0",
                        },
                        slug: "dry-shampoo",
                        title: "Dry Shampoo",
                        id: 6000087,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/shampoo",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/shampoo/dry-shampoo",
                        active: true,
                        meta: {
                          tags: "Dry Shampoo",
                          keywords: "Dry Shampoo",
                          description: "Dry Shampoo",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dd1",
                        },
                        slug: "herbal-shampoo",
                        title: "Herbal Shampoo",
                        id: 6000088,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/shampoo",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/shampoo/herbal-shampoo",
                        active: true,
                        meta: {
                          tags: "Herbal Shampoo",
                          keywords: "Herbal Shampoo",
                          description: "Herbal Shampoo",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dd2",
                        },
                        slug: "medicated-shampoo",
                        title: "Medicated Shampoo",
                        id: 6000089,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/shampoo",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hair-care/shampoo/medicated-shampoo",
                        active: true,
                        meta: {
                          tags: "Medicated Shampoo",
                          keywords: "Medicated Shampoo",
                          description: "Medicated Shampoo",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530dd3",
                },
                slug: "hygiene",
                title: "Hygiene",
                id: 6000090,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene",
                active: true,
                meta: {
                  tags: "Hygiene",
                  keywords: "Hygiene",
                  description: "Hygiene",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dd4",
                    },
                    slug: "adult-diapers",
                    title: "Adult Diapers",
                    id: 6000091,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene/adult-diapers",
                    active: true,
                    meta: {
                      tags: "Adult Diapers",
                      keywords: "Adult Diapers",
                      description: "Adult Diapers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dd5",
                    },
                    slug: "feminine-hygiene",
                    title: "Feminine Hygiene",
                    id: 6000092,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene/feminine-hygiene",
                    active: true,
                    meta: {
                      tags: "Feminine Hygiene",
                      keywords: "Feminine Hygiene",
                      description: "Feminine Hygiene",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dd6",
                    },
                    slug: "hand-sanitizer",
                    title: "Hand Sanitizer",
                    id: 6000093,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene/hand-sanitizer",
                    active: true,
                    meta: {
                      tags: "Hand Sanitizer",
                      keywords: "Hand Sanitizer",
                      description: "Hand Sanitizer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dd7",
                    },
                    slug: "wipes",
                    title: "Wipes",
                    id: 6000094,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene/wipes",
                    active: true,
                    meta: {
                      tags: "Wipes",
                      keywords: "Wipes",
                      description: "Wipes",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dd8",
                        },
                        slug: "alcohol-wipes",
                        title: "Alcohol Wipes",
                        id: 6000095,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene/wipes",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene/wipes/alcohol-wipes",
                        active: true,
                        meta: {
                          tags: "Alcohol Wipes",
                          keywords: "Alcohol Wipes",
                          description: "Alcohol Wipes",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dd9",
                    },
                    slug: "depilatories",
                    title: "Depilatories",
                    id: 6000096,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/hygiene/depilatories",
                    active: true,
                    meta: {
                      tags: "Depilatories",
                      keywords: "Depilatories",
                      description: "Depilatories",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530dda",
                },
                slug: "mens-grooming",
                title: "Mens Grooming",
                id: 6000097,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/mens-grooming",
                active: true,
                meta: {
                  tags: "Mens Grooming",
                  keywords: "Mens Grooming",
                  description: "Mens Grooming",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530ddb",
                },
                slug: "oral-care",
                title: "Oral Care",
                id: 6000098,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care",
                active: true,
                meta: {
                  tags: "Oral Care",
                  keywords: "Oral Care",
                  description: "Oral Care",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530ddc",
                    },
                    slug: "interdental-cleansing-products",
                    title: "Interdental Cleansing Products",
                    id: 6000099,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care/interdental-cleansing-products",
                    active: true,
                    meta: {
                      tags: "Interdental Cleansing Products",
                      keywords: "Interdental Cleansing Products",
                      description: "Interdental Cleansing Products",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530ddd",
                    },
                    slug: "mouthwash",
                    title: "Mouthwash",
                    id: 6000100,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care/mouthwash",
                    active: true,
                    meta: {
                      tags: "Mouthwash",
                      keywords: "Mouthwash",
                      description: "Mouthwash",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530dde",
                    },
                    slug: "toothbrush",
                    title: "Toothbrush",
                    id: 6000101,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care/toothbrush",
                    active: true,
                    meta: {
                      tags: "Toothbrush",
                      keywords: "Toothbrush",
                      description: "Toothbrush",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530ddf",
                        },
                        slug: "electric-toothbrush",
                        title: "Electric Toothbrush",
                        id: 6000102,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care/toothbrush",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care/toothbrush/electric-toothbrush",
                        active: true,
                        meta: {
                          tags: "Electric Toothbrush",
                          keywords: "Electric Toothbrush",
                          description: "Electric Toothbrush",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530de0",
                    },
                    slug: "toothpaste",
                    title: "Toothpaste",
                    id: 6000103,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care/toothpaste",
                    active: true,
                    meta: {
                      tags: "Toothpaste",
                      keywords: "Toothpaste",
                      description: "Toothpaste",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530de1",
                        },
                        slug: "ayurvedic-toothpaste",
                        title: "Ayurvedic Toothpaste",
                        id: 6000104,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care/toothpaste",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/oral-care/toothpaste/ayurvedic-toothpaste",
                        active: true,
                        meta: {
                          tags: "Ayurvedic Toothpaste",
                          keywords: "Ayurvedic Toothpaste",
                          description: "Ayurvedic Toothpaste",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530de2",
                },
                slug: "organic-personal-care",
                title: "Organic Personal Care",
                id: 6000105,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/organic-personal-care",
                active: true,
                meta: {
                  tags: "Organic Personal Care",
                  keywords: "Organic Personal Care",
                  description: "Organic Personal Care",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530de3",
                },
                slug: "sexual-wellness",
                title: "Sexual Wellness",
                id: 6000106,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/sexual-wellness",
                active: true,
                meta: {
                  tags: "Sexual Wellness",
                  keywords: "Sexual Wellness",
                  description: "Sexual Wellness",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530de4",
                    },
                    slug: "condoms",
                    title: "Condoms",
                    id: 6000107,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/sexual-wellness",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/sexual-wellness/condoms",
                    active: true,
                    meta: {
                      tags: "Condoms",
                      keywords: "Condoms",
                      description: "Condoms",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530de5",
                    },
                    slug: "contraceptive-sponges",
                    title: "Contraceptive Sponges",
                    id: 6000108,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/sexual-wellness",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/sexual-wellness/contraceptive-sponges",
                    active: true,
                    meta: {
                      tags: "Contraceptive Sponges",
                      keywords: "Contraceptive Sponges",
                      description: "Contraceptive Sponges",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530de6",
                },
                slug: "skin-care",
                title: "Skin Care",
                id: 6000109,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                active: true,
                meta: {
                  tags: "Skin Care",
                  keywords: "Skin Care",
                  description: "Skin Care",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530de7",
                    },
                    slug: "anti-aging-products",
                    title: "Anti Aging Products",
                    id: 6000110,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/anti-aging-products",
                    active: true,
                    meta: {
                      tags: "Anti Aging Products",
                      keywords: "Anti Aging Products",
                      description: "Anti Aging Products",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530de8",
                    },
                    slug: "body-care",
                    title: "Body Care",
                    id: 6000111,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/body-care",
                    active: true,
                    meta: {
                      tags: "Body Care",
                      keywords: "Body Care",
                      description: "Body Care",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530de9",
                        },
                        slug: "skin-lotion",
                        title: "Skin Lotion",
                        id: 6000112,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/body-care",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/body-care/skin-lotion",
                        active: true,
                        meta: {
                          tags: "Skin Lotion",
                          keywords: "Skin Lotion",
                          description: "Skin Lotion",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ad98328e934a04a5530dea",
                            },
                            slug: "oil-control-lotion",
                            title: "Oil Control Lotion",
                            id: 6000113,
                            parent:
                              "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/body-care/skin-lotion",
                            path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/body-care/skin-lotion/oil-control-lotion",
                            active: true,
                            meta: {
                              tags: "Oil Control Lotion",
                              keywords: "Oil Control Lotion",
                              description: "Oil Control Lotion",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530deb",
                    },
                    slug: "facial-care",
                    title: "Facial Care",
                    id: 6000114,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/facial-care",
                    active: true,
                    meta: {
                      tags: "Facial Care",
                      keywords: "Facial Care",
                      description: "Facial Care",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dec",
                        },
                        slug: "face-cleanser-and-toner",
                        title: "Face Cleanser and Toner",
                        id: 6000115,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/facial-care",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/facial-care/face-cleanser-and-toner",
                        active: true,
                        meta: {
                          tags: "Face Cleanser and Toner",
                          keywords: "Face Cleanser and Toner",
                          description: "Face Cleanser and Toner",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530ded",
                        },
                        slug: "face-mask",
                        title: "Face Mask",
                        id: 6000116,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/facial-care",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/facial-care/face-mask",
                        active: true,
                        meta: {
                          tags: "Face Mask",
                          keywords: "Face Mask",
                          description: "Face Mask",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ad98328e934a04a5530dee",
                        },
                        slug: "face-wash",
                        title: "Face Wash",
                        id: 6000117,
                        parent:
                          "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/facial-care",
                        path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/facial-care/face-wash",
                        active: true,
                        meta: {
                          tags: "Face Wash",
                          keywords: "Face Wash",
                          description: "Face Wash",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530def",
                    },
                    slug: "hand-care",
                    title: "Hand Care",
                    id: 6000118,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/hand-care",
                    active: true,
                    meta: {
                      tags: "Hand Care",
                      keywords: "Hand Care",
                      description: "Hand Care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530df0",
                    },
                    slug: "lip-care",
                    title: "Lip Care",
                    id: 6000119,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/lip-care",
                    active: true,
                    meta: {
                      tags: "Lip Care",
                      keywords: "Lip Care",
                      description: "Lip Care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530df1",
                    },
                    slug: "organic-skin-care",
                    title: "Organic Skin care",
                    id: 6000120,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/organic-skin-care",
                    active: true,
                    meta: {
                      tags: "Organic Skin care",
                      keywords: "Organic Skin care",
                      description: "Organic Skin care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530df2",
                    },
                    slug: "professional-skin-care",
                    title: "Professional Skin Care",
                    id: 6000121,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/professional-skin-care",
                    active: true,
                    meta: {
                      tags: "Professional Skin Care",
                      keywords: "Professional Skin Care",
                      description: "Professional Skin Care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530df3",
                    },
                    slug: "sensitive-skin-care",
                    title: "Sensitive Skin Care",
                    id: 6000122,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/sensitive-skin-care",
                    active: true,
                    meta: {
                      tags: "Sensitive Skin Care",
                      keywords: "Sensitive Skin Care",
                      description: "Sensitive Skin Care",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530df4",
                    },
                    slug: "sun-care",
                    title: "Sun Care",
                    id: 6000123,
                    parent:
                      "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care",
                    path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care/skin-care/sun-care",
                    active: true,
                    meta: {
                      tags: "Sun Care",
                      keywords: "Sun Care",
                      description: "Sun Care",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530df5",
            },
            slug: "personal-care-appliances",
            title: "Personal Care Appliances",
            id: 6000124,
            parent: "/consumer-goods-and-services/beauty-and-personal-care",
            path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care-appliances",
            active: true,
            meta: {
              tags: "Personal Care Appliances",
              keywords: "Personal Care Appliances",
              description: "Personal Care Appliances",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530df6",
                },
                slug: "hair-styling-tools",
                title: "Hair Styling Tools",
                id: 6000125,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care-appliances",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care-appliances/hair-styling-tools",
                active: true,
                meta: {
                  tags: "Hair Styling Tools",
                  keywords: "Hair Styling Tools",
                  description: "Hair Styling Tools",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530df7",
                },
                slug: "mens-grooming-appliances",
                title: "Mens Grooming Appliances",
                id: 6000126,
                parent:
                  "/consumer-goods-and-services/beauty-and-personal-care/personal-care-appliances",
                path: "/consumer-goods-and-services/beauty-and-personal-care/personal-care-appliances/mens-grooming-appliances",
                active: true,
                meta: {
                  tags: "Mens Grooming Appliances",
                  keywords: "Mens Grooming Appliances",
                  description: "Mens Grooming Appliances",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ad98328e934a04a5530df8",
        },
        slug: "consumer-services",
        title: "Consumer Services",
        id: 66618,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/consumer-services",
        active: true,
        meta: {
          tags: "Consumer Services",
          keywords: "Consumer Services",
          description: "Consumer Services",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad98328e934a04a5530df9",
            },
            slug: "home-shopping",
            title: "Home shopping",
            id: 6000128,
            parent: "/consumer-goods-and-services/consumer-services",
            path: "/consumer-goods-and-services/consumer-services/home-shopping",
            active: true,
            meta: {
              tags: "Home shopping",
              keywords: "Home shopping",
              description: "Home shopping",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ad98328e934a04a5530dfa",
        },
        slug: "home-care",
        title: "Home Care",
        id: 66619,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/home-care",
        active: true,
        meta: {
          tags: "Home Care",
          keywords: "Home Care",
          description: "Home Care",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad98328e934a04a5530dfb",
            },
            slug: "air-care",
            title: "Air Care",
            id: 6000130,
            parent: "/consumer-goods-and-services/home-care",
            path: "/consumer-goods-and-services/home-care/air-care",
            active: true,
            meta: {
              tags: "Air Care",
              keywords: "Air Care",
              description: "Air Care",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530dfc",
                },
                slug: "air-freshener",
                title: "Air Freshener",
                id: 6000131,
                parent: "/consumer-goods-and-services/home-care/air-care",
                path: "/consumer-goods-and-services/home-care/air-care/air-freshener",
                active: true,
                meta: {
                  tags: "Air Freshener",
                  keywords: "Air Freshener",
                  description: "Air Freshener",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530dfd",
                },
                slug: "aromatherapy-diffuser",
                title: "Aromatherapy Diffuser",
                id: 6000132,
                parent: "/consumer-goods-and-services/home-care/air-care",
                path: "/consumer-goods-and-services/home-care/air-care/aromatherapy-diffuser",
                active: true,
                meta: {
                  tags: "Aromatherapy Diffuser",
                  keywords: "Aromatherapy Diffuser",
                  description: "Aromatherapy Diffuser",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530dfe",
                },
                slug: "home-fragrances",
                title: "Home Fragrances",
                id: 6000133,
                parent: "/consumer-goods-and-services/home-care/air-care",
                path: "/consumer-goods-and-services/home-care/air-care/home-fragrances",
                active: true,
                meta: {
                  tags: "Home Fragrances",
                  keywords: "Home Fragrances",
                  description: "Home Fragrances",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530dff",
            },
            slug: "dishwashing-products",
            title: "Dishwashing Products",
            id: 6000134,
            parent: "/consumer-goods-and-services/home-care",
            path: "/consumer-goods-and-services/home-care/dishwashing-products",
            active: true,
            meta: {
              tags: "Dishwashing Products",
              keywords: "Dishwashing Products",
              description: "Dishwashing Products",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530e00",
            },
            slug: "household-cleaners",
            title: "Household Cleaners",
            id: 6000135,
            parent: "/consumer-goods-and-services/home-care",
            path: "/consumer-goods-and-services/home-care/household-cleaners",
            active: true,
            meta: {
              tags: "Household Cleaners",
              keywords: "Household Cleaners",
              description: "Household Cleaners",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e01",
                },
                slug: "toilet-care-products",
                title: "Toilet Care Products",
                id: 6000136,
                parent:
                  "/consumer-goods-and-services/home-care/household-cleaners",
                path: "/consumer-goods-and-services/home-care/household-cleaners/toilet-care-products",
                active: true,
                meta: {
                  tags: "Toilet Care Products",
                  keywords: "Toilet Care Products",
                  description: "Toilet Care Products",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530e02",
            },
            slug: "insect-repellents",
            title: "Insect Repellents",
            id: 6000137,
            parent: "/consumer-goods-and-services/home-care",
            path: "/consumer-goods-and-services/home-care/insect-repellents",
            active: true,
            meta: {
              tags: "Insect Repellents",
              keywords: "Insect Repellents",
              description: "Insect Repellents",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e03",
                },
                slug: "mosquito-repellents",
                title: "Mosquito Repellents",
                id: 6000138,
                parent:
                  "/consumer-goods-and-services/home-care/insect-repellents",
                path: "/consumer-goods-and-services/home-care/insect-repellents/mosquito-repellents",
                active: true,
                meta: {
                  tags: "Mosquito Repellents",
                  keywords: "Mosquito Repellents",
                  description: "Mosquito Repellents",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530e04",
            },
            slug: "laundry-care",
            title: "Laundry Care",
            id: 6000139,
            parent: "/consumer-goods-and-services/home-care",
            path: "/consumer-goods-and-services/home-care/laundry-care",
            active: true,
            meta: {
              tags: "Laundry Care",
              keywords: "Laundry Care",
              description: "Laundry Care",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e05",
                },
                slug: "fabric-softeners-and-conditioners",
                title: "Fabric Softeners & Conditioners",
                id: 6000140,
                parent: "/consumer-goods-and-services/home-care/laundry-care",
                path: "/consumer-goods-and-services/home-care/laundry-care/fabric-softeners-and-conditioners",
                active: true,
                meta: {
                  tags: "Fabric Softeners & Conditioners",
                  keywords: "Fabric Softeners & Conditioners",
                  description: "Fabric Softeners & Conditioners",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e06",
                },
                slug: "laundry-detergents",
                title: "Laundry Detergents",
                id: 6000141,
                parent: "/consumer-goods-and-services/home-care/laundry-care",
                path: "/consumer-goods-and-services/home-care/laundry-care/laundry-detergents",
                active: true,
                meta: {
                  tags: "Laundry Detergents",
                  keywords: "Laundry Detergents",
                  description: "Laundry Detergents",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e07",
                    },
                    slug: "liquid-detergents",
                    title: "Liquid Detergents",
                    id: 6000142,
                    parent:
                      "/consumer-goods-and-services/home-care/laundry-care/laundry-detergents",
                    path: "/consumer-goods-and-services/home-care/laundry-care/laundry-detergents/liquid-detergents",
                    active: true,
                    meta: {
                      tags: "Liquid Detergents",
                      keywords: "Liquid Detergents",
                      description: "Liquid Detergents",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ad98328e934a04a5530e1b",
        },
        slug: "sports-and-fitness",
        title: "Sports and Fitness",
        id: 66620,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/sports-and-fitness",
        active: true,
        meta: {
          tags: "Sports and Fitness",
          keywords: "Sports and Fitness",
          description: "Sports and Fitness",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad98328e934a04a5530e1c",
            },
            slug: "artificial-grass",
            title: "Artificial Grass",
            id: 6000165,
            parent: "/consumer-goods-and-services/sports-and-fitness",
            path: "/consumer-goods-and-services/sports-and-fitness/artificial-grass",
            active: true,
            meta: {
              tags: "Artificial Grass",
              keywords: "Artificial Grass",
              description: "Artificial Grass",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530e1d",
            },
            slug: "fitness",
            title: "Fitness",
            id: 6000166,
            parent: "/consumer-goods-and-services/sports-and-fitness",
            path: "/consumer-goods-and-services/sports-and-fitness/fitness",
            active: true,
            meta: {
              tags: "Fitness",
              keywords: "Fitness",
              description: "Fitness",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e1e",
                },
                slug: "bicycles",
                title: "Bicycles",
                id: 6000167,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/fitness",
                path: "/consumer-goods-and-services/sports-and-fitness/fitness/bicycles",
                active: true,
                meta: {
                  tags: "Bicycles",
                  keywords: "Bicycles",
                  description: "Bicycles",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e1f",
                },
                slug: "fitness-equipment",
                title: "Fitness Equipment",
                id: 6000168,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/fitness",
                path: "/consumer-goods-and-services/sports-and-fitness/fitness/fitness-equipment",
                active: true,
                meta: {
                  tags: "Fitness Equipment",
                  keywords: "Fitness Equipment",
                  description: "Fitness Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e20",
                    },
                    slug: "home-fitness-equipment",
                    title: "Home Fitness Equipment",
                    id: 6000169,
                    parent:
                      "/consumer-goods-and-services/sports-and-fitness/fitness/fitness-equipment",
                    path: "/consumer-goods-and-services/sports-and-fitness/fitness/fitness-equipment/home-fitness-equipment",
                    active: true,
                    meta: {
                      tags: "Home Fitness Equipment",
                      keywords: "Home Fitness Equipment",
                      description: "Home Fitness Equipment",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e21",
                },
                slug: "health-and-fitness-club",
                title: "Health & Fitness Club",
                id: 6000170,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/fitness",
                path: "/consumer-goods-and-services/sports-and-fitness/fitness/health-and-fitness-club",
                active: true,
                meta: {
                  tags: "Health & Fitness Club",
                  keywords: "Health & Fitness Club",
                  description: "Health & Fitness Club",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad98328e934a04a5530e22",
            },
            slug: "sports-equipment-and-apparel",
            title: "Sports Equipment and Apparel",
            id: 6000171,
            parent: "/consumer-goods-and-services/sports-and-fitness",
            path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
            active: true,
            meta: {
              tags: "Sports Equipment and Apparel",
              keywords: "Sports Equipment and Apparel",
              description: "Sports Equipment and Apparel",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e23",
                },
                slug: "skating-equipment",
                title: "Skating Equipment",
                id: 6000172,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/skating-equipment",
                active: true,
                meta: {
                  tags: "Skating Equipment",
                  keywords: "Skating Equipment",
                  description: "Skating Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e24",
                    },
                    slug: "skateboards",
                    title: "Skateboards",
                    id: 6000173,
                    parent:
                      "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/skating-equipment",
                    path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/skating-equipment/skateboards",
                    active: true,
                    meta: {
                      tags: "Skateboards",
                      keywords: "Skateboards",
                      description: "Skateboards",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e25",
                },
                slug: "angling-and-hunting-equipment",
                title: "Angling and Hunting Equipment",
                id: 6000174,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/angling-and-hunting-equipment",
                active: true,
                meta: {
                  tags: "Angling and Hunting Equipment",
                  keywords: "Angling and Hunting Equipment",
                  description: "Angling and Hunting Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e26",
                },
                slug: "active-wear",
                title: "Active Wear",
                id: 6000175,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/active-wear",
                active: true,
                meta: {
                  tags: "Active Wear",
                  keywords: "Active Wear",
                  description: "Active Wear",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e27",
                    },
                    slug: "women-active-wear",
                    title: "Women Active Wear",
                    id: 6000176,
                    parent:
                      "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/active-wear",
                    path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/active-wear/women-active-wear",
                    active: true,
                    meta: {
                      tags: "Women Active Wear",
                      keywords: "Women Active Wear",
                      description: "Women Active Wear",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e28",
                },
                slug: "archery-equipment",
                title: "Archery Equipment",
                id: 6000177,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/archery-equipment",
                active: true,
                meta: {
                  tags: "Archery Equipment",
                  keywords: "Archery Equipment",
                  description: "Archery Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e29",
                },
                slug: "baseball-equipment",
                title: "Baseball Equipment",
                id: 6000178,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/baseball-equipment",
                active: true,
                meta: {
                  tags: "Baseball Equipment",
                  keywords: "Baseball Equipment",
                  description: "Baseball Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e2a",
                },
                slug: "billiards-and-snooker-equipment",
                title: "Billiards and Snooker Equipment",
                id: 6000179,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/billiards-and-snooker-equipment",
                active: true,
                meta: {
                  tags: "Billiards and Snooker Equipment",
                  keywords: "Billiards and Snooker Equipment",
                  description: "Billiards and Snooker Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e2b",
                },
                slug: "boxing-equipment",
                title: "Boxing Equipment",
                id: 6000180,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/boxing-equipment",
                active: true,
                meta: {
                  tags: "Boxing Equipment",
                  keywords: "Boxing Equipment",
                  description: "Boxing Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e2c",
                },
                slug: "camping-equipment",
                title: "Camping Equipment",
                id: 6000181,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/camping-equipment",
                active: true,
                meta: {
                  tags: "Camping Equipment",
                  keywords: "Camping Equipment",
                  description: "Camping Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e2d",
                },
                slug: "field-hockey-equipment",
                title: "Field Hockey Equipment",
                id: 6000182,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/field-hockey-equipment",
                active: true,
                meta: {
                  tags: "Field Hockey Equipment",
                  keywords: "Field Hockey Equipment",
                  description: "Field Hockey Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e2e",
                },
                slug: "football-equipment",
                title: "Football Equipment",
                id: 6000183,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/football-equipment",
                active: true,
                meta: {
                  tags: "Football Equipment",
                  keywords: "Football Equipment",
                  description: "Football Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e2f",
                    },
                    slug: "football",
                    title: "Football",
                    id: 6000184,
                    parent:
                      "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/football-equipment",
                    path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/football-equipment/football",
                    active: true,
                    meta: {
                      tags: "Football",
                      keywords: "Football",
                      description: "Football",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e30",
                },
                slug: "golf-equipment",
                title: "Golf Equipment",
                id: 6000185,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/golf-equipment",
                active: true,
                meta: {
                  tags: "Golf Equipment",
                  keywords: "Golf Equipment",
                  description: "Golf Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e31",
                },
                slug: "ice-hockey-equipment",
                title: "Ice Hockey Equipment",
                id: 6000186,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/ice-hockey-equipment",
                active: true,
                meta: {
                  tags: "Ice Hockey Equipment",
                  keywords: "Ice Hockey Equipment",
                  description: "Ice Hockey Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e32",
                },
                slug: "mma-equipment",
                title: "MMA Equipment",
                id: 6000187,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/mma-equipment",
                active: true,
                meta: {
                  tags: "MMA Equipment",
                  keywords: "MMA Equipment",
                  description: "MMA Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e33",
                },
                slug: "racket-sports-equipment",
                title: "Racket Sports Equipment",
                id: 6000188,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/racket-sports-equipment",
                active: true,
                meta: {
                  tags: "Racket Sports Equipment",
                  keywords: "Racket Sports Equipment",
                  description: "Racket Sports Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e34",
                },
                slug: "ski-gear-and-equipment",
                title: "Ski Gear & Equipment",
                id: 6000189,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/ski-gear-and-equipment",
                active: true,
                meta: {
                  tags: "Ski Gear & Equipment",
                  keywords: "Ski Gear & Equipment",
                  description: "Ski Gear & Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e35",
                },
                slug: "sports-and-leisure-equipment",
                title: "Sports & Leisure Equipment",
                id: 6000190,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/sports-and-leisure-equipment",
                active: true,
                meta: {
                  tags: "Sports & Leisure Equipment",
                  keywords: "Sports & Leisure Equipment",
                  description: "Sports & Leisure Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e36",
                },
                slug: "sports-gun",
                title: "Sports Gun",
                id: 6000191,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/sports-gun",
                active: true,
                meta: {
                  tags: "Sports Gun",
                  keywords: "Sports Gun",
                  description: "Sports Gun",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e37",
                },
                slug: "sports-luggage",
                title: "Sports Luggage",
                id: 6000192,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/sports-luggage",
                active: true,
                meta: {
                  tags: "Sports Luggage",
                  keywords: "Sports Luggage",
                  description: "Sports Luggage",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e38",
                    },
                    slug: "hydration-backpack",
                    title: "Hydration Backpack",
                    id: 6000193,
                    parent:
                      "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/sports-luggage",
                    path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/sports-luggage/hydration-backpack",
                    active: true,
                    meta: {
                      tags: "Hydration Backpack",
                      keywords: "Hydration Backpack",
                      description: "Hydration Backpack",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e39",
                    },
                    slug: "sports-bags",
                    title: "Sports Bags",
                    id: 6000194,
                    parent:
                      "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/sports-luggage",
                    path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/sports-luggage/sports-bags",
                    active: true,
                    meta: {
                      tags: "Sports Bags",
                      keywords: "Sports Bags",
                      description: "Sports Bags",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e3a",
                },
                slug: "sports-merchandise",
                title: "Sports Merchandise",
                id: 6000195,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/sports-merchandise",
                active: true,
                meta: {
                  tags: "Sports Merchandise",
                  keywords: "Sports Merchandise",
                  description: "Sports Merchandise",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e3b",
                },
                slug: "volleyball-equipment",
                title: "Volleyball Equipment",
                id: 6000196,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/volleyball-equipment",
                active: true,
                meta: {
                  tags: "Volleyball Equipment",
                  keywords: "Volleyball Equipment",
                  description: "Volleyball Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e3c",
                },
                slug: "winter-sports-equipment",
                title: "Winter Sports Equipment",
                id: 6000197,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/winter-sports-equipment",
                active: true,
                meta: {
                  tags: "Winter Sports Equipment",
                  keywords: "Winter Sports Equipment",
                  description: "Winter Sports Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad98328e934a04a5530e3d",
                    },
                    slug: "snowboards",
                    title: "Snowboards",
                    id: 6000198,
                    parent:
                      "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/winter-sports-equipment",
                    path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/winter-sports-equipment/snowboards",
                    active: true,
                    meta: {
                      tags: "Snowboards",
                      keywords: "Snowboards",
                      description: "Snowboards",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad98328e934a04a5530e3e",
                },
                slug: "surfing-equipment",
                title: "Surfing Equipment",
                id: 6000199,
                parent:
                  "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel",
                path: "/consumer-goods-and-services/sports-and-fitness/sports-equipment-and-apparel/surfing-equipment",
                active: true,
                meta: {
                  tags: "Surfing Equipment",
                  keywords: "Surfing Equipment",
                  description: "Surfing Equipment",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ad98328e934a04a5530e3f",
        },
        slug: "beverage-appliances",
        title: "Beverage Appliances",
        id: 66621,
        parent: "/consumer-goods-and-services",
        path: "/consumer-goods-and-services/beverage-appliances",
        active: true,
        meta: {
          tags: "Beverage Appliances",
          keywords: "Beverage Appliances",
          description: "Beverage Appliances",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad98328e934a04a5530e40",
            },
            slug: "wine-cooler",
            title: "Wine Cooler",
            id: 6000201,
            parent: "/consumer-goods-and-services/beverage-appliances",
            path: "/consumer-goods-and-services/beverage-appliances/wine-cooler",
            active: true,
            meta: {
              tags: "Wine Cooler",
              keywords: "Wine Cooler",
              description: "Wine Cooler",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b130",
    },
    id: 65,
    title: "Automotive",
    slug: "automotive",
    active: true,
    meta: {
      tags: "Automotive Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "automotive market research, automotive market intelligence, automotive industry analysis, automotive industry, automotive market, automotive industry overview, automotive industry trends, automotive industry outlook, global automotive industry statistics, growth of automobile industry, global automotive market size, automobile market share.",
      description:
        "Our Automotive industry reports offer insights into major trends, technologies, competition, and growth opportunities in the market. The global and regional analysis includes statistics, market segments, overview , key players, market drivers, and the future developments.",
    },
    description: {
      short_description:
        "The natural consequence of rising income levels across the globe has been an increase in quality and quantity. MI understands this and brings you the latest in regulations, astute insights about trends and technologies through an array of regional and global reports on the automotive market.",
      full_description:
        "The Automotive domain comprises of trends and technologies in the vehicular and transportation systems market involving the design, development, production and manufacture of motor vehicles.  The section covers a variety of topics such as automotive and transportation market, electric and hybrid vehicles, vehicular safety and security systems, Navigation, Die Casting, Sensors and Telematics.",
    },
    picture: null,
    __v: 0,
    picture_url: "automotive.png",
    cat_email: "auto@mordorintelligence.com",
    cat_pw_id: 12432,
    parent: "/",
    path: "/automotive",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1a3",
        },
        id: 6528,
        title: "Automotive Materials & Coatings",
        slug: "automotive-materials-coatings",
        active: true,
        meta: {
          description:
            "Automotive Materials & Coatings segment offers reports that provide an in-depth analysis of market trends, technological advancements, growth opportunities, challenges, drivers, latest developments, and the future market scenario for various automotive materials and coatings.",
          keywords:
            "Automotive Materials & Coatings Market Research Reports, automotive coatings industry, automotive coatings market, automotive coatings market report, automotive coatings market reports, automotive coatings industry report, automotive coatings industry reports, automotive coatings industry analysis, automotive coatings industry growth, automotive coatings market intelligence report",
          tags: "Automotive Materials & Coatings Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Global/ Regional reports on automotive coatings market, automotive composites market, automotive carbon fiber market, automotive glass fiber market, automotive thermoplastic market, automotive nano paints market, automotive plastics market and many more",
          short_description:
            "Automotive technological advancements are in large part due to parts used in new and breakthrough vehicles. Advancements in automotive parts introduced in recent years are being overrun by newer, more recent technologies. Let Mordor Intelligence™ help you navigate the complex market dynamics presented by this high-value, low-risk market.",
        },
        picture: null,
        __v: 0,
        parent: "/automotive",
        path: "/automotive/automotive-materials-coatings",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9fcced7de0399bca277d",
            },
            slug: "automotive-materials",
            title: "Automotive Materials",
            id: 11000131,
            parent: "/automotive/automotive-materials-coatings",
            path: "/automotive/automotive-materials-coatings/automotive-materials",
            active: true,
            meta: {
              tags: "Automotive Materials",
              keywords: "Automotive Materials",
              description: "Automotive Materials",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2787",
                },
                slug: "automotive-metals",
                title: "Automotive Metals",
                id: 11000141,
                parent:
                  "/automotive/automotive-materials-coatings/automotive-materials",
                path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-metals",
                active: true,
                meta: {
                  tags: "Automotive Metals",
                  keywords: "Automotive Metals",
                  description: "Automotive Metals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2788",
                    },
                    slug: "automotive-steel",
                    title: "Automotive Steel",
                    id: 11000142,
                    parent:
                      "/automotive/automotive-materials-coatings/automotive-materials/automotive-metals",
                    path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-metals/automotive-steel",
                    active: true,
                    meta: {
                      tags: "Automotive Steel",
                      keywords: "Automotive Steel",
                      description: "Automotive Steel",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2784",
                },
                slug: "automotive-interior-coverings",
                title: "Automotive Interior Coverings",
                id: 11000138,
                parent:
                  "/automotive/automotive-materials-coatings/automotive-materials",
                path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-interior-coverings",
                active: true,
                meta: {
                  tags: "Automotive Interior Coverings",
                  keywords: "Automotive Interior Coverings",
                  description: "Automotive Interior Coverings",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2785",
                    },
                    slug: "automotive-floorboard-material",
                    title: "Automotive Floorboard Material",
                    id: 11000139,
                    parent:
                      "/automotive/automotive-materials-coatings/automotive-materials/automotive-interior-coverings",
                    path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-interior-coverings/automotive-floorboard-material",
                    active: true,
                    meta: {
                      tags: "Automotive Floorboard Material",
                      keywords: "Automotive Floorboard Material",
                      description: "Automotive Floorboard Material",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2786",
                    },
                    slug: "automotive-upholstery",
                    title: "Automotive Upholstery",
                    id: 11000140,
                    parent:
                      "/automotive/automotive-materials-coatings/automotive-materials/automotive-interior-coverings",
                    path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-interior-coverings/automotive-upholstery",
                    active: true,
                    meta: {
                      tags: "Automotive Upholstery",
                      keywords: "Automotive Upholstery",
                      description: "Automotive Upholstery",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2780",
                },
                slug: "automotive-composites",
                title: "Automotive Composites",
                id: 11000134,
                parent:
                  "/automotive/automotive-materials-coatings/automotive-materials",
                path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-composites",
                active: true,
                meta: {
                  tags: "Automotive Composites",
                  keywords: "Automotive Composites",
                  description: "Automotive Composites",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2781",
                    },
                    slug: "automotive-fiber-reinforced-composites",
                    title: "Automotive Fiber Reinforced Composites",
                    id: 11000135,
                    parent:
                      "/automotive/automotive-materials-coatings/automotive-materials/automotive-composites",
                    path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-composites/automotive-fiber-reinforced-composites",
                    active: true,
                    meta: {
                      tags: "Automotive Fiber Reinforced Composites",
                      keywords: "Automotive Fiber Reinforced Composites",
                      description: "Automotive Fiber Reinforced Composites",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2782",
                        },
                        slug: "automotive-carbon-fiber-composites",
                        title: "Automotive Carbon Fiber Composites",
                        id: 11000136,
                        parent:
                          "/automotive/automotive-materials-coatings/automotive-materials/automotive-composites/automotive-fiber-reinforced-composites",
                        path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-composites/automotive-fiber-reinforced-composites/automotive-carbon-fiber-composites",
                        active: true,
                        meta: {
                          tags: "Automotive Carbon Fiber Composites",
                          keywords: "Automotive Carbon Fiber Composites",
                          description: "Automotive Carbon Fiber Composites",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2783",
                        },
                        slug: "automotive-glass-fiber-composites",
                        title: "Automotive Glass Fiber Composites",
                        id: 11000137,
                        parent:
                          "/automotive/automotive-materials-coatings/automotive-materials/automotive-composites/automotive-fiber-reinforced-composites",
                        path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-composites/automotive-fiber-reinforced-composites/automotive-glass-fiber-composites",
                        active: true,
                        meta: {
                          tags: "Automotive Glass Fiber Composites",
                          keywords: "Automotive Glass Fiber Composites",
                          description: "Automotive Glass Fiber Composites",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2789",
                },
                slug: "automotive-synthetic-polymers",
                title: "Automotive Synthetic Polymers",
                id: 11000143,
                parent:
                  "/automotive/automotive-materials-coatings/automotive-materials",
                path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-synthetic-polymers",
                active: true,
                meta: {
                  tags: "Automotive Synthetic Polymers",
                  keywords: "Automotive Synthetic Polymers",
                  description: "Automotive Synthetic Polymers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca278a",
                    },
                    slug: "automotive-thermoplastic-polymers",
                    title: "Automotive Thermoplastic Polymers",
                    id: 11000144,
                    parent:
                      "/automotive/automotive-materials-coatings/automotive-materials/automotive-synthetic-polymers",
                    path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-synthetic-polymers/automotive-thermoplastic-polymers",
                    active: true,
                    meta: {
                      tags: "Automotive Thermoplastic Polymers",
                      keywords: "Automotive Thermoplastic Polymers",
                      description: "Automotive Thermoplastic Polymers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca277e",
                },
                slug: "automotive-acoustics",
                title: "Automotive Acoustics",
                id: 11000132,
                parent:
                  "/automotive/automotive-materials-coatings/automotive-materials",
                path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-acoustics",
                active: true,
                meta: {
                  tags: "Automotive Acoustics",
                  keywords: "Automotive Acoustics",
                  description: "Automotive Acoustics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca277f",
                    },
                    slug: "automotive-noise-vibration-and-harshness-systems",
                    title: "Automotive Noise, Vibration and Harshness Systems",
                    id: 11000133,
                    parent:
                      "/automotive/automotive-materials-coatings/automotive-materials/automotive-acoustics",
                    path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-acoustics/automotive-noise-vibration-and-harshness-systems",
                    active: true,
                    meta: {
                      tags: "Automotive Noise, Vibration and Harshness Systems",
                      keywords:
                        "Automotive Noise, Vibration and Harshness Systems",
                      description:
                        "Automotive Noise, Vibration and Harshness Systems",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca278b",
                },
                slug: "automotive-synthetic-shielding",
                title: "Automotive Synthetic Shielding",
                id: 11000145,
                parent:
                  "/automotive/automotive-materials-coatings/automotive-materials",
                path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-synthetic-shielding",
                active: true,
                meta: {
                  tags: "Automotive Synthetic Shielding",
                  keywords: "Automotive Synthetic Shielding",
                  description: "Automotive Synthetic Shielding",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca278c",
                },
                slug: "automotive-thermal-barrier",
                title: "Automotive Thermal Barrier",
                id: 11000146,
                parent:
                  "/automotive/automotive-materials-coatings/automotive-materials",
                path: "/automotive/automotive-materials-coatings/automotive-materials/automotive-thermal-barrier",
                active: true,
                meta: {
                  tags: "Automotive Thermal Barrier",
                  keywords: "Automotive Thermal Barrier",
                  description: "Automotive Thermal Barrier",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca277b",
            },
            slug: "automotive-fluids",
            title: "Automotive Fluids",
            id: 11000129,
            parent: "/automotive/automotive-materials-coatings",
            path: "/automotive/automotive-materials-coatings/automotive-fluids",
            active: true,
            meta: {
              tags: "Automotive Fluids",
              keywords: "Automotive Fluids",
              description: "Automotive Fluids",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca277c",
                },
                slug: "automotive-heat-transfer-fluids",
                title: "Automotive Heat Transfer Fluids",
                id: 11000130,
                parent:
                  "/automotive/automotive-materials-coatings/automotive-fluids",
                path: "/automotive/automotive-materials-coatings/automotive-fluids/automotive-heat-transfer-fluids",
                active: true,
                meta: {
                  tags: "Automotive Heat Transfer Fluids",
                  keywords: "Automotive Heat Transfer Fluids",
                  description: "Automotive Heat Transfer Fluids",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1a5",
        },
        id: 6535,
        title: "Automotive Technology",
        slug: "automotive-technology",
        active: true,
        meta: {
          description:
            "The Automotive domain comprises trends and technologies in the vehicular and transportation systems market involving the design, development, production, and manufacture of motor vehicles.",
          keywords:
            "Automotive Technology Market Research Reports, Automotive Technology, Automotive Technology market, Automotive Technology industry, Automotive Technology industry report, Automotive Technology market report, Automotive Technology industry analysis, Automotive Technology industry research, Automotive Technology market intelligence report, Automotive Technology market growth, Automotive Technology industry forecast",
          tags: "Automotive Technology Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "The Automotive domain comprises of trends and technologies in the vehicular and transportation systems market involving the design, development, production and manufacture of motor vehicles.  The section covers a variety of topics such as automotive and transportation market, electric and hybrid vehicles, vehicular safety and security systems, and Navigation and Telematics.",
          short_description:
            "From ADAS to electric actuators, wireless charging to steering sensors, the automotive technology segment studies trends and developments, regulations and opportunities in the market. Browse through some of our industry-specific reports on automotive technology below.",
        },
        picture: null,
        __v: 0,
        parent: "/automotive",
        path: "/automotive/automotive-technology",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27dc",
            },
            slug: "automotive-security",
            title: "Automotive Security",
            id: 11000228,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/automotive-security",
            active: true,
            meta: {
              tags: "Automotive Security",
              keywords: "Automotive Security",
              description: "Automotive Security",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27dd",
                },
                slug: "keyless-entry",
                title: "Keyless Entry",
                id: 11000229,
                parent: "/automotive/automotive-technology/automotive-security",
                path: "/automotive/automotive-technology/automotive-security/keyless-entry",
                active: true,
                meta: {
                  tags: "Keyless Entry",
                  keywords: "Keyless Entry",
                  description: "Keyless Entry",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27db",
            },
            slug: "automotive-robots",
            title: "Automotive Robots",
            id: 11000227,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/automotive-robots",
            active: true,
            meta: {
              tags: "Automotive Robots",
              keywords: "Automotive Robots",
              description: "Automotive Robots",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27d7",
            },
            slug: "automotive-data-management",
            title: "Automotive Data Management",
            id: 11000223,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/automotive-data-management",
            active: true,
            meta: {
              tags: "Automotive Data Management",
              keywords: "Automotive Data Management",
              description: "Automotive Data Management",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27e3",
            },
            slug: "vehicle-parking-system",
            title: "Vehicle Parking System",
            id: 11000235,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/vehicle-parking-system",
            active: true,
            meta: {
              tags: "Vehicle Parking System",
              keywords: "Vehicle Parking System",
              description: "Vehicle Parking System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27da",
            },
            slug: "automotive-machine-learning",
            title: "Automotive Machine Learning",
            id: 11000226,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/automotive-machine-learning",
            active: true,
            meta: {
              tags: "Automotive Machine Learning",
              keywords: "Automotive Machine Learning",
              description: "Automotive Machine Learning",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27df",
            },
            slug: "electronic-tolling-system",
            title: "Electronic Tolling System",
            id: 11000231,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/electronic-tolling-system",
            active: true,
            meta: {
              tags: "Electronic Tolling System",
              keywords: "Electronic Tolling System",
              description: "Electronic Tolling System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27d6",
            },
            slug: "automotive-ai",
            title: "Automotive AI",
            id: 11000222,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/automotive-ai",
            active: true,
            meta: {
              tags: "Automotive AI",
              keywords: "Automotive AI",
              description: "Automotive AI",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27d8",
            },
            slug: "automotive-design-and-engineering",
            title: "Automotive Design and Engineering",
            id: 11000224,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/automotive-design-and-engineering",
            active: true,
            meta: {
              tags: "Automotive Design and Engineering",
              keywords: "Automotive Design and Engineering",
              description: "Automotive Design and Engineering",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27d9",
                },
                slug: "vehicle-user-interface",
                title: "Vehicle User Interface",
                id: 11000225,
                parent:
                  "/automotive/automotive-technology/automotive-design-and-engineering",
                path: "/automotive/automotive-technology/automotive-design-and-engineering/vehicle-user-interface",
                active: true,
                meta: {
                  tags: "Vehicle User Interface",
                  keywords: "Vehicle User Interface",
                  description: "Vehicle User Interface",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27de",
            },
            slug: "automotive-simulation",
            title: "Automotive Simulation",
            id: 11000230,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/automotive-simulation",
            active: true,
            meta: {
              tags: "Automotive Simulation",
              keywords: "Automotive Simulation",
              description: "Automotive Simulation",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27e4",
            },
            slug: "vehicle-safety",
            title: "Vehicle Safety",
            id: 11000236,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/vehicle-safety",
            active: true,
            meta: {
              tags: "Vehicle Safety",
              keywords: "Vehicle Safety",
              description: "Vehicle Safety",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27ea",
                },
                slug: "connected-transportation-system",
                title: "Connected Transportation System",
                id: 11000242,
                parent: "/automotive/automotive-technology/vehicle-safety",
                path: "/automotive/automotive-technology/vehicle-safety/connected-transportation-system",
                active: true,
                meta: {
                  tags: "Connected Transportation System",
                  keywords: "Connected Transportation System",
                  description: "Connected Transportation System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27eb",
                },
                slug: "intelligent-road-safety-system",
                title: "Intelligent Road Safety System",
                id: 11000243,
                parent: "/automotive/automotive-technology/vehicle-safety",
                path: "/automotive/automotive-technology/vehicle-safety/intelligent-road-safety-system",
                active: true,
                meta: {
                  tags: "Intelligent Road Safety System",
                  keywords: "Intelligent Road Safety System",
                  description: "Intelligent Road Safety System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27e5",
                },
                slug: "adas",
                title: "ADAS",
                id: 11000237,
                parent: "/automotive/automotive-technology/vehicle-safety",
                path: "/automotive/automotive-technology/vehicle-safety/adas",
                active: true,
                meta: {
                  tags: "ADAS",
                  keywords: "ADAS",
                  description: "ADAS",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27e6",
                    },
                    slug: "connected-truck-system",
                    title: "Connected Truck System",
                    id: 11000238,
                    parent:
                      "/automotive/automotive-technology/vehicle-safety/adas",
                    path: "/automotive/automotive-technology/vehicle-safety/adas/connected-truck-system",
                    active: true,
                    meta: {
                      tags: "Connected Truck System",
                      keywords: "Connected Truck System",
                      description: "Connected Truck System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27e9",
                    },
                    slug: "pedestrian-safety-system",
                    title: "Pedestrian Safety System",
                    id: 11000241,
                    parent:
                      "/automotive/automotive-technology/vehicle-safety/adas",
                    path: "/automotive/automotive-technology/vehicle-safety/adas/pedestrian-safety-system",
                    active: true,
                    meta: {
                      tags: "Pedestrian Safety System",
                      keywords: "Pedestrian Safety System",
                      description: "Pedestrian Safety System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27e7",
                    },
                    slug: "intelligent-braking-system",
                    title: "Intelligent Braking System",
                    id: 11000239,
                    parent:
                      "/automotive/automotive-technology/vehicle-safety/adas",
                    path: "/automotive/automotive-technology/vehicle-safety/adas/intelligent-braking-system",
                    active: true,
                    meta: {
                      tags: "Intelligent Braking System",
                      keywords: "Intelligent Braking System",
                      description: "Intelligent Braking System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27e8",
                    },
                    slug: "lane-assist-system",
                    title: "Lane assist System",
                    id: 11000240,
                    parent:
                      "/automotive/automotive-technology/vehicle-safety/adas",
                    path: "/automotive/automotive-technology/vehicle-safety/adas/lane-assist-system",
                    active: true,
                    meta: {
                      tags: "Lane assist System",
                      keywords: "Lane assist System",
                      description: "Lane assist System",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27ec",
                },
                slug: "passenger-collision-avoidance",
                title: "Passenger Collision Avoidance",
                id: 11000244,
                parent: "/automotive/automotive-technology/vehicle-safety",
                path: "/automotive/automotive-technology/vehicle-safety/passenger-collision-avoidance",
                active: true,
                meta: {
                  tags: "Passenger Collision Avoidance",
                  keywords: "Passenger Collision Avoidance",
                  description: "Passenger Collision Avoidance",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27ed",
                },
                slug: "vehicle-stability-system",
                title: "Vehicle Stability System",
                id: 11000245,
                parent: "/automotive/automotive-technology/vehicle-safety",
                path: "/automotive/automotive-technology/vehicle-safety/vehicle-stability-system",
                active: true,
                meta: {
                  tags: "Vehicle Stability System",
                  keywords: "Vehicle Stability System",
                  description: "Vehicle Stability System",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27e0",
            },
            slug: "ev-inductive-charging",
            title: "EV Inductive Charging",
            id: 11000232,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/ev-inductive-charging",
            active: true,
            meta: {
              tags: "EV Inductive Charging",
              keywords: "EV Inductive Charging",
              description: "EV Inductive Charging",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27e1",
            },
            slug: "ultra-high-speed-transportation-system",
            title: "Ultra High Speed Transportation System",
            id: 11000233,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/ultra-high-speed-transportation-system",
            active: true,
            meta: {
              tags: "Ultra High Speed Transportation System",
              keywords: "Ultra High Speed Transportation System",
              description: "Ultra High Speed Transportation System",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27e2",
            },
            slug: "vehicle-gps",
            title: "Vehicle GPS",
            id: 11000234,
            parent: "/automotive/automotive-technology",
            path: "/automotive/automotive-technology/vehicle-gps",
            active: true,
            meta: {
              tags: "Vehicle GPS",
              keywords: "Vehicle GPS",
              description: "Vehicle GPS",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1a7",
        },
        id: 65103,
        title: "Vehicles",
        slug: "vehicles",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Vehicles Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Vehicles Market Research Reports, vehicles market, vehicles market report, vehicles market research, vehicles industry, vehicles industry report, vehicles industry research",
          tags: "Vehicles Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Global/Regional reports on lightweight cars market, LCV market, High performance EV market, wireless charging for EV market, Fuel Cell for EV market and many more",
          short_description:
            "The vehicles segment offers reports that provide an in-depth analysis of market trends, technological advancements, growth opportunities, challenges, drivers, the latest developments, and the future market scenario for passenger and commercial vehicles.",
        },
        picture: null,
        __v: 0,
        parent: "/automotive",
        path: "/automotive/vehicles",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2806",
            },
            slug: "autonomous-vehicles",
            title: "Autonomous Vehicles",
            id: 11000271,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/autonomous-vehicles",
            active: true,
            meta: {
              tags: "Autonomous Vehicles",
              keywords: "Autonomous Vehicles",
              description: "Autonomous Vehicles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2807",
                },
                slug: "autonomous-commercial-vehicles",
                title: "Autonomous Commercial Vehicles",
                id: 11000272,
                parent: "/automotive/vehicles/autonomous-vehicles",
                path: "/automotive/vehicles/autonomous-vehicles/autonomous-commercial-vehicles",
                active: true,
                meta: {
                  tags: "Autonomous Commercial Vehicles",
                  keywords: "Autonomous Commercial Vehicles",
                  description: "Autonomous Commercial Vehicles",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2808",
                },
                slug: "autonomous-passenger-vehicles",
                title: "Autonomous Passenger Vehicles",
                id: 11000273,
                parent: "/automotive/vehicles/autonomous-vehicles",
                path: "/automotive/vehicles/autonomous-vehicles/autonomous-passenger-vehicles",
                active: true,
                meta: {
                  tags: "Autonomous Passenger Vehicles",
                  keywords: "Autonomous Passenger Vehicles",
                  description: "Autonomous Passenger Vehicles",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca281c",
            },
            slug: "luxury-cars",
            title: "Luxury Cars",
            id: 11000293,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/luxury-cars",
            active: true,
            meta: {
              tags: "Luxury Cars",
              keywords: "Luxury Cars",
              description: "Luxury Cars",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2823",
            },
            slug: "passenger-vehicles",
            title: "Passenger Vehicles",
            id: 11000300,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/passenger-vehicles",
            active: true,
            meta: {
              tags: "Passenger Vehicles",
              keywords: "Passenger Vehicles",
              description: "Passenger Vehicles",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2824",
            },
            slug: "railway",
            title: "Railway",
            id: 11000301,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/railway",
            active: true,
            meta: {
              tags: "Railway",
              keywords: "Railway",
              description: "Railway",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2825",
                },
                slug: "low-emission-railway",
                title: "Low Emission Railway",
                id: 11000302,
                parent: "/automotive/vehicles/railway",
                path: "/automotive/vehicles/railway/low-emission-railway",
                active: true,
                meta: {
                  tags: "Low Emission Railway",
                  keywords: "Low Emission Railway",
                  description: "Low Emission Railway",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2826",
                    },
                    slug: "urban-rail-transit",
                    title: "Urban Rail Transit",
                    id: 11000303,
                    parent: "/automotive/vehicles/railway/low-emission-railway",
                    path: "/automotive/vehicles/railway/low-emission-railway/urban-rail-transit",
                    active: true,
                    meta: {
                      tags: "Urban Rail Transit",
                      keywords: "Urban Rail Transit",
                      description: "Urban Rail Transit",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2827",
                },
                slug: "rail-engine",
                title: "Rail Engine",
                id: 11000304,
                parent: "/automotive/vehicles/railway",
                path: "/automotive/vehicles/railway/rail-engine",
                active: true,
                meta: {
                  tags: "Rail Engine",
                  keywords: "Rail Engine",
                  description: "Rail Engine",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2833",
            },
            slug: "vehicle-ownership",
            title: "Vehicle Ownership",
            id: 11000316,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/vehicle-ownership",
            active: true,
            meta: {
              tags: "Vehicle Ownership",
              keywords: "Vehicle Ownership",
              description: "Vehicle Ownership",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2834",
                },
                slug: "pre-owned-vehicles",
                title: "Pre-owned Vehicles",
                id: 11000317,
                parent: "/automotive/vehicles/vehicle-ownership",
                path: "/automotive/vehicles/vehicle-ownership/pre-owned-vehicles",
                active: true,
                meta: {
                  tags: "Pre-owned Vehicles",
                  keywords: "Pre-owned Vehicles",
                  description: "Pre-owned Vehicles",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2836",
                    },
                    slug: "pre-owned-passenger-cars",
                    title: "Pre-owned Passenger Cars",
                    id: 11000319,
                    parent:
                      "/automotive/vehicles/vehicle-ownership/pre-owned-vehicles",
                    path: "/automotive/vehicles/vehicle-ownership/pre-owned-vehicles/pre-owned-passenger-cars",
                    active: true,
                    meta: {
                      tags: "Pre-owned Passenger Cars",
                      keywords: "Pre-owned Passenger Cars",
                      description: "Pre-owned Passenger Cars",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2835",
                    },
                    slug: "pre-owned-commercial-vehicles",
                    title: "Pre-Owned Commercial Vehicles",
                    id: 11000318,
                    parent:
                      "/automotive/vehicles/vehicle-ownership/pre-owned-vehicles",
                    path: "/automotive/vehicles/vehicle-ownership/pre-owned-vehicles/pre-owned-commercial-vehicles",
                    active: true,
                    meta: {
                      tags: "Pre-Owned Commercial Vehicles",
                      keywords: "Pre-Owned Commercial Vehicles",
                      description: "Pre-Owned Commercial Vehicles",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2837",
            },
            slug: "zero-emission-vehicles",
            title: "Zero Emission Vehicles",
            id: 11000320,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/zero-emission-vehicles",
            active: true,
            meta: {
              tags: "Zero Emission Vehicles",
              keywords: "Zero Emission Vehicles",
              description: "Zero Emission Vehicles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2839",
                },
                slug: "renewable-energy-vehicle",
                title: "Renewable Energy Vehicle",
                id: 11000322,
                parent: "/automotive/vehicles/zero-emission-vehicles",
                path: "/automotive/vehicles/zero-emission-vehicles/renewable-energy-vehicle",
                active: true,
                meta: {
                  tags: "Renewable Energy Vehicle",
                  keywords: "Renewable Energy Vehicle",
                  description: "Renewable Energy Vehicle",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2838",
                },
                slug: "hybrid-and-electric-propulsion",
                title: "Hybrid and Electric Propulsion",
                id: 11000321,
                parent: "/automotive/vehicles/zero-emission-vehicles",
                path: "/automotive/vehicles/zero-emission-vehicles/hybrid-and-electric-propulsion",
                active: true,
                meta: {
                  tags: "Hybrid and Electric Propulsion",
                  keywords: "Hybrid and Electric Propulsion",
                  description: "Hybrid and Electric Propulsion",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca283a",
                },
                slug: "zero-emission-evs",
                title: "Zero Emission EVs",
                id: 11000323,
                parent: "/automotive/vehicles/zero-emission-vehicles",
                path: "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs",
                active: true,
                meta: {
                  tags: "Zero Emission EVs",
                  keywords: "Zero Emission EVs",
                  description: "Zero Emission EVs",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca283b",
                    },
                    slug: "battery-evs",
                    title: "Battery EVs",
                    id: 11000324,
                    parent:
                      "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs",
                    path: "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs/battery-evs",
                    active: true,
                    meta: {
                      tags: "Battery EVs",
                      keywords: "Battery EVs",
                      description: "Battery EVs",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca283c",
                        },
                        slug: "electric-auto-rickshaw",
                        title: "Electric Auto-Rickshaw",
                        id: 11000325,
                        parent:
                          "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs/battery-evs",
                        path: "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs/battery-evs/electric-auto-rickshaw",
                        active: true,
                        meta: {
                          tags: "Electric Auto-Rickshaw",
                          keywords: "Electric Auto-Rickshaw",
                          description: "Electric Auto-Rickshaw",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca283d",
                        },
                        slug: "electric-two-wheeler",
                        title: "Electric Two-Wheeler",
                        id: 11000326,
                        parent:
                          "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs/battery-evs",
                        path: "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs/battery-evs/electric-two-wheeler",
                        active: true,
                        meta: {
                          tags: "Electric Two-Wheeler",
                          keywords: "Electric Two-Wheeler",
                          description: "Electric Two-Wheeler",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca283e",
                    },
                    slug: "fuel-cell-evs",
                    title: "Fuel Cell EVs",
                    id: 11000327,
                    parent:
                      "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs",
                    path: "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs/fuel-cell-evs",
                    active: true,
                    meta: {
                      tags: "Fuel Cell EVs",
                      keywords: "Fuel Cell EVs",
                      description: "Fuel Cell EVs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca283f",
                    },
                    slug: "scooters-and-motorcycles",
                    title: "Scooters and Motorcycles",
                    id: 11000328,
                    parent:
                      "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs",
                    path: "/automotive/vehicles/zero-emission-vehicles/zero-emission-evs/scooters-and-motorcycles",
                    active: true,
                    meta: {
                      tags: "Scooters and Motorcycles",
                      keywords: "Scooters and Motorcycles",
                      description: "Scooters and Motorcycles",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2805",
            },
            slug: "auto-dealerships",
            title: "Auto Dealerships",
            id: 11000270,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/auto-dealerships",
            active: true,
            meta: {
              tags: "Auto Dealerships",
              keywords: "Auto Dealerships",
              description: "Auto Dealerships",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2828",
            },
            slug: "recreational-vehicles",
            title: "Recreational Vehicles",
            id: 11000305,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/recreational-vehicles",
            active: true,
            meta: {
              tags: "Recreational Vehicles",
              keywords: "Recreational Vehicles",
              description: "Recreational Vehicles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2829",
                },
                slug: "motor-home",
                title: "Motor Home",
                id: 11000306,
                parent: "/automotive/vehicles/recreational-vehicles",
                path: "/automotive/vehicles/recreational-vehicles/motor-home",
                active: true,
                meta: {
                  tags: "Motor Home",
                  keywords: "Motor Home",
                  description: "Motor Home",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2830",
            },
            slug: "three-wheelers",
            title: "Three Wheelers",
            id: 11000313,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/three-wheelers",
            active: true,
            meta: {
              tags: "Three Wheelers",
              keywords: "Three Wheelers",
              description: "Three Wheelers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2816",
            },
            slug: "low-emission-vehicles",
            title: "Low Emission Vehicles",
            id: 11000287,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/low-emission-vehicles",
            active: true,
            meta: {
              tags: "Low Emission Vehicles",
              keywords: "Low Emission Vehicles",
              description: "Low Emission Vehicles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2817",
                },
                slug: "alternate-fuel-vehicles",
                title: "Alternate Fuel Vehicles",
                id: 11000288,
                parent: "/automotive/vehicles/low-emission-vehicles",
                path: "/automotive/vehicles/low-emission-vehicles/alternate-fuel-vehicles",
                active: true,
                meta: {
                  tags: "Alternate Fuel Vehicles",
                  keywords: "Alternate Fuel Vehicles",
                  description: "Alternate Fuel Vehicles",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2819",
                    },
                    slug: "natural-gas-vehicles",
                    title: "Natural Gas Vehicles",
                    id: 11000290,
                    parent:
                      "/automotive/vehicles/low-emission-vehicles/alternate-fuel-vehicles",
                    path: "/automotive/vehicles/low-emission-vehicles/alternate-fuel-vehicles/natural-gas-vehicles",
                    active: true,
                    meta: {
                      tags: "Natural Gas Vehicles",
                      keywords: "Natural Gas Vehicles",
                      description: "Natural Gas Vehicles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2818",
                    },
                    slug: "gas-vehicles",
                    title: "Gas Vehicles",
                    id: 11000289,
                    parent:
                      "/automotive/vehicles/low-emission-vehicles/alternate-fuel-vehicles",
                    path: "/automotive/vehicles/low-emission-vehicles/alternate-fuel-vehicles/gas-vehicles",
                    active: true,
                    meta: {
                      tags: "Gas Vehicles",
                      keywords: "Gas Vehicles",
                      description: "Gas Vehicles",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca281b",
                },
                slug: "low-emission-cars",
                title: "Low Emission Cars",
                id: 11000292,
                parent: "/automotive/vehicles/low-emission-vehicles",
                path: "/automotive/vehicles/low-emission-vehicles/low-emission-cars",
                active: true,
                meta: {
                  tags: "Low Emission Cars",
                  keywords: "Low Emission Cars",
                  description: "Low Emission Cars",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca281a",
                },
                slug: "hybrid-vehicles",
                title: "Hybrid Vehicles",
                id: 11000291,
                parent: "/automotive/vehicles/low-emission-vehicles",
                path: "/automotive/vehicles/low-emission-vehicles/hybrid-vehicles",
                active: true,
                meta: {
                  tags: "Hybrid Vehicles",
                  keywords: "Hybrid Vehicles",
                  description: "Hybrid Vehicles",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2814",
            },
            slug: "connected-vehicles",
            title: "Connected Vehicles",
            id: 11000285,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/connected-vehicles",
            active: true,
            meta: {
              tags: "Connected Vehicles",
              keywords: "Connected Vehicles",
              description: "Connected Vehicles",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2815",
            },
            slug: "e-bikes",
            title: "E-Bikes",
            id: 11000286,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/e-bikes",
            active: true,
            meta: {
              tags: "E-Bikes",
              keywords: "E-Bikes",
              description: "E-Bikes",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca282a",
            },
            slug: "special-purpose-vehicles",
            title: "Special Purpose Vehicles",
            id: 11000307,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/special-purpose-vehicles",
            active: true,
            meta: {
              tags: "Special Purpose Vehicles",
              keywords: "Special Purpose Vehicles",
              description: "Special Purpose Vehicles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca282b",
                },
                slug: "atv-and-utv",
                title: "ATV and UTV",
                id: 11000308,
                parent: "/automotive/vehicles/special-purpose-vehicles",
                path: "/automotive/vehicles/special-purpose-vehicles/atv-and-utv",
                active: true,
                meta: {
                  tags: "ATV and UTV",
                  keywords: "ATV and UTV",
                  description: "ATV and UTV",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca282d",
                },
                slug: "food-service-vehicles",
                title: "Food Service Vehicles",
                id: 11000310,
                parent: "/automotive/vehicles/special-purpose-vehicles",
                path: "/automotive/vehicles/special-purpose-vehicles/food-service-vehicles",
                active: true,
                meta: {
                  tags: "Food Service Vehicles",
                  keywords: "Food Service Vehicles",
                  description: "Food Service Vehicles",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca282f",
                },
                slug: "vehicles-for-disabled",
                title: "Vehicles for Disabled",
                id: 11000312,
                parent: "/automotive/vehicles/special-purpose-vehicles",
                path: "/automotive/vehicles/special-purpose-vehicles/vehicles-for-disabled",
                active: true,
                meta: {
                  tags: "Vehicles for Disabled",
                  keywords: "Vehicles for Disabled",
                  description: "Vehicles for Disabled",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca282c",
                },
                slug: "fire-trucks",
                title: "Fire Trucks",
                id: 11000309,
                parent: "/automotive/vehicles/special-purpose-vehicles",
                path: "/automotive/vehicles/special-purpose-vehicles/fire-trucks",
                active: true,
                meta: {
                  tags: "Fire Trucks",
                  keywords: "Fire Trucks",
                  description: "Fire Trucks",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca282e",
                },
                slug: "golf-carts",
                title: "Golf Carts",
                id: 11000311,
                parent: "/automotive/vehicles/special-purpose-vehicles",
                path: "/automotive/vehicles/special-purpose-vehicles/golf-carts",
                active: true,
                meta: {
                  tags: "Golf Carts",
                  keywords: "Golf Carts",
                  description: "Golf Carts",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2809",
            },
            slug: "commercial-vehicles",
            title: "Commercial Vehicles",
            id: 11000274,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/commercial-vehicles",
            active: true,
            meta: {
              tags: "Commercial Vehicles",
              keywords: "Commercial Vehicles",
              description: "Commercial Vehicles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca280b",
                },
                slug: "electric-commercial-vehicles",
                title: "Electric Commercial Vehicles",
                id: 11000276,
                parent: "/automotive/vehicles/commercial-vehicles",
                path: "/automotive/vehicles/commercial-vehicles/electric-commercial-vehicles",
                active: true,
                meta: {
                  tags: "Electric Commercial Vehicles",
                  keywords: "Electric Commercial Vehicles",
                  description: "Electric Commercial Vehicles",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca280d",
                    },
                    slug: "medium-and-heavy-duty-electric-vehicles",
                    title: "Medium and Heavy Duty Electric Vehicles",
                    id: 11000278,
                    parent:
                      "/automotive/vehicles/commercial-vehicles/electric-commercial-vehicles",
                    path: "/automotive/vehicles/commercial-vehicles/electric-commercial-vehicles/medium-and-heavy-duty-electric-vehicles",
                    active: true,
                    meta: {
                      tags: "Medium and Heavy Duty Electric Vehicles",
                      keywords: "Medium and Heavy Duty Electric Vehicles",
                      description: "Medium and Heavy Duty Electric Vehicles",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca280c",
                    },
                    slug: "electric-light-duty-vehicles",
                    title: "Electric Light Duty Vehicles",
                    id: 11000277,
                    parent:
                      "/automotive/vehicles/commercial-vehicles/electric-commercial-vehicles",
                    path: "/automotive/vehicles/commercial-vehicles/electric-commercial-vehicles/electric-light-duty-vehicles",
                    active: true,
                    meta: {
                      tags: "Electric Light Duty Vehicles",
                      keywords: "Electric Light Duty Vehicles",
                      description: "Electric Light Duty Vehicles",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca280f",
                },
                slug: "medium-and-heavy-duty-vehicles",
                title: "Medium and Heavy Duty Vehicles",
                id: 11000280,
                parent: "/automotive/vehicles/commercial-vehicles",
                path: "/automotive/vehicles/commercial-vehicles/medium-and-heavy-duty-vehicles",
                active: true,
                meta: {
                  tags: "Medium and Heavy Duty Vehicles",
                  keywords: "Medium and Heavy Duty Vehicles",
                  description: "Medium and Heavy Duty Vehicles",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2812",
                    },
                    slug: "trailer",
                    title: "Trailer",
                    id: 11000283,
                    parent:
                      "/automotive/vehicles/commercial-vehicles/medium-and-heavy-duty-vehicles",
                    path: "/automotive/vehicles/commercial-vehicles/medium-and-heavy-duty-vehicles/trailer",
                    active: true,
                    meta: {
                      tags: "Trailer",
                      keywords: "Trailer",
                      description: "Trailer",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2813",
                    },
                    slug: "trucks",
                    title: "Trucks",
                    id: 11000284,
                    parent:
                      "/automotive/vehicles/commercial-vehicles/medium-and-heavy-duty-vehicles",
                    path: "/automotive/vehicles/commercial-vehicles/medium-and-heavy-duty-vehicles/trucks",
                    active: true,
                    meta: {
                      tags: "Trucks",
                      keywords: "Trucks",
                      description: "Trucks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2810",
                    },
                    slug: "bus",
                    title: "Bus",
                    id: 11000281,
                    parent:
                      "/automotive/vehicles/commercial-vehicles/medium-and-heavy-duty-vehicles",
                    path: "/automotive/vehicles/commercial-vehicles/medium-and-heavy-duty-vehicles/bus",
                    active: true,
                    meta: {
                      tags: "Bus",
                      keywords: "Bus",
                      description: "Bus",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2811",
                        },
                        slug: "school-bus",
                        title: "School Bus",
                        id: 11000282,
                        parent:
                          "/automotive/vehicles/commercial-vehicles/medium-and-heavy-duty-vehicles/bus",
                        path: "/automotive/vehicles/commercial-vehicles/medium-and-heavy-duty-vehicles/bus/school-bus",
                        active: true,
                        meta: {
                          tags: "School Bus",
                          keywords: "School Bus",
                          description: "School Bus",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca280e",
                },
                slug: "light-duty-vehicles",
                title: "Light Duty Vehicles",
                id: 11000279,
                parent: "/automotive/vehicles/commercial-vehicles",
                path: "/automotive/vehicles/commercial-vehicles/light-duty-vehicles",
                active: true,
                meta: {
                  tags: "Light Duty Vehicles",
                  keywords: "Light Duty Vehicles",
                  description: "Light Duty Vehicles",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca280a",
                },
                slug: "adaptive-commercial-vehicles",
                title: "Adaptive Commercial Vehicles",
                id: 11000275,
                parent: "/automotive/vehicles/commercial-vehicles",
                path: "/automotive/vehicles/commercial-vehicles/adaptive-commercial-vehicles",
                active: true,
                meta: {
                  tags: "Adaptive Commercial Vehicles",
                  keywords: "Adaptive Commercial Vehicles",
                  description: "Adaptive Commercial Vehicles",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca281d",
            },
            slug: "marine-vehicles",
            title: "Marine Vehicles",
            id: 11000294,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/marine-vehicles",
            active: true,
            meta: {
              tags: "Marine Vehicles",
              keywords: "Marine Vehicles",
              description: "Marine Vehicles",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2821",
                },
                slug: "ships",
                title: "Ships",
                id: 11000298,
                parent: "/automotive/vehicles/marine-vehicles",
                path: "/automotive/vehicles/marine-vehicles/ships",
                active: true,
                meta: {
                  tags: "Ships",
                  keywords: "Ships",
                  description: "Ships",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2820",
                },
                slug: "electric-marine-vehicles",
                title: "Electric Marine Vehicles",
                id: 11000297,
                parent: "/automotive/vehicles/marine-vehicles",
                path: "/automotive/vehicles/marine-vehicles/electric-marine-vehicles",
                active: true,
                meta: {
                  tags: "Electric Marine Vehicles",
                  keywords: "Electric Marine Vehicles",
                  description: "Electric Marine Vehicles",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca281e",
                },
                slug: "boats",
                title: "Boats",
                id: 11000295,
                parent: "/automotive/vehicles/marine-vehicles",
                path: "/automotive/vehicles/marine-vehicles/boats",
                active: true,
                meta: {
                  tags: "Boats",
                  keywords: "Boats",
                  description: "Boats",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca281f",
                    },
                    slug: "leisure-and-rescue-boats",
                    title: "Leisure and Rescue Boats",
                    id: 11000296,
                    parent: "/automotive/vehicles/marine-vehicles/boats",
                    path: "/automotive/vehicles/marine-vehicles/boats/leisure-and-rescue-boats",
                    active: true,
                    meta: {
                      tags: "Leisure and Rescue Boats",
                      keywords: "Leisure and Rescue Boats",
                      description: "Leisure and Rescue Boats",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2822",
                },
                slug: "yachtes",
                title: "Yachtes",
                id: 11000299,
                parent: "/automotive/vehicles/marine-vehicles",
                path: "/automotive/vehicles/marine-vehicles/yachtes",
                active: true,
                meta: {
                  tags: "Yachtes",
                  keywords: "Yachtes",
                  description: "Yachtes",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2831",
            },
            slug: "two-wheelers",
            title: "Two Wheelers",
            id: 11000314,
            parent: "/automotive/vehicles",
            path: "/automotive/vehicles/two-wheelers",
            active: true,
            meta: {
              tags: "Two Wheelers",
              keywords: "Two Wheelers",
              description: "Two Wheelers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2832",
                },
                slug: "two--wheeler-driver-assistance-system",
                title: "Two -Wheeler Driver Assistance System",
                id: 11000315,
                parent: "/automotive/vehicles/two-wheelers",
                path: "/automotive/vehicles/two-wheelers/two--wheeler-driver-assistance-system",
                active: true,
                meta: {
                  tags: "Two -Wheeler Driver Assistance System",
                  keywords: "Two -Wheeler Driver Assistance System",
                  description: "Two -Wheeler Driver Assistance System",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1a9",
        },
        id: 65116,
        title: "Automotive Services",
        slug: "automotive-services",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Automotive Services Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Automotive Services Market Research Reports, automotive market research reports, automotive market report, automotive services market, automotive services market size, automotive services industry report, automotive services industry research, automotive services market analysis",
          tags: "Automotive Services Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "Statistics for the 2023 Automotive Services market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Automotive Services Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          short_description:
            "Statistics for the 2023 Automotive Services market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Automotive Services Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        picture: null,
        __v: 0,
        parent: "/automotive",
        path: "/automotive/automotive-services",
        sub_categories: [
          {
            _id: {
              $oid: "64ba9fcced7de0399bca279a",
            },
            slug: "auto-loans",
            title: "Auto Loans",
            id: 11000161,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/auto-loans",
            active: true,
            meta: {
              tags: "Auto Loans",
              keywords: "Auto Loans",
              description: "Auto Loans",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27ac",
            },
            slug: "automotive-advertising",
            title: "Automotive Advertising",
            id: 11000179,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/automotive-advertising",
            active: true,
            meta: {
              tags: "Automotive Advertising",
              keywords: "Automotive Advertising",
              description: "Automotive Advertising",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27ad",
                },
                slug: "advertising-on-wheels",
                title: "Advertising on Wheels",
                id: 11000180,
                parent:
                  "/automotive/automotive-services/automotive-advertising",
                path: "/automotive/automotive-services/automotive-advertising/advertising-on-wheels",
                active: true,
                meta: {
                  tags: "Advertising on Wheels",
                  keywords: "Advertising on Wheels",
                  description: "Advertising on Wheels",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27ae",
                    },
                    slug: "vehicle-digital-signage",
                    title: "Vehicle Digital Signage",
                    id: 11000181,
                    parent:
                      "/automotive/automotive-services/automotive-advertising/advertising-on-wheels",
                    path: "/automotive/automotive-services/automotive-advertising/advertising-on-wheels/vehicle-digital-signage",
                    active: true,
                    meta: {
                      tags: "Vehicle Digital Signage",
                      keywords: "Vehicle Digital Signage",
                      description: "Vehicle Digital Signage",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27b7",
            },
            slug: "automotive-repair-service",
            title: "Automotive Repair Service",
            id: 11000190,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/automotive-repair-service",
            active: true,
            meta: {
              tags: "Automotive Repair Service",
              keywords: "Automotive Repair Service",
              description: "Automotive Repair Service",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27b9",
                },
                slug: "ev-repair",
                title: "EV repair",
                id: 11000192,
                parent:
                  "/automotive/automotive-services/automotive-repair-service",
                path: "/automotive/automotive-services/automotive-repair-service/ev-repair",
                active: true,
                meta: {
                  tags: "EV repair",
                  keywords: "EV repair",
                  description: "EV repair",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27b8",
                },
                slug: "automotive-service-station",
                title: "Automotive Service Station",
                id: 11000191,
                parent:
                  "/automotive/automotive-services/automotive-repair-service",
                path: "/automotive/automotive-services/automotive-repair-service/automotive-service-station",
                active: true,
                meta: {
                  tags: "Automotive Service Station",
                  keywords: "Automotive Service Station",
                  description: "Automotive Service Station",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27bd",
            },
            slug: "equipment-rental",
            title: "Equipment Rental",
            id: 11000196,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/equipment-rental",
            active: true,
            meta: {
              tags: "Equipment Rental",
              keywords: "Equipment Rental",
              description: "Equipment Rental",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27be",
                },
                slug: "lifting-equipment-rental",
                title: "Lifting Equipment Rental",
                id: 11000197,
                parent: "/automotive/automotive-services/equipment-rental",
                path: "/automotive/automotive-services/equipment-rental/lifting-equipment-rental",
                active: true,
                meta: {
                  tags: "Lifting Equipment Rental",
                  keywords: "Lifting Equipment Rental",
                  description: "Lifting Equipment Rental",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27bf",
                    },
                    slug: "crane-rental",
                    title: "Crane Rental",
                    id: 11000198,
                    parent:
                      "/automotive/automotive-services/equipment-rental/lifting-equipment-rental",
                    path: "/automotive/automotive-services/equipment-rental/lifting-equipment-rental/crane-rental",
                    active: true,
                    meta: {
                      tags: "Crane Rental",
                      keywords: "Crane Rental",
                      description: "Crane Rental",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27c0",
                },
                slug: "mobile-industrial-equipment-rental",
                title: "Mobile Industrial Equipment Rental",
                id: 11000199,
                parent: "/automotive/automotive-services/equipment-rental",
                path: "/automotive/automotive-services/equipment-rental/mobile-industrial-equipment-rental",
                active: true,
                meta: {
                  tags: "Mobile Industrial Equipment Rental",
                  keywords: "Mobile Industrial Equipment Rental",
                  description: "Mobile Industrial Equipment Rental",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27c1",
                },
                slug: "off-highway-equipment-rental",
                title: "Off-highway Equipment Rental",
                id: 11000200,
                parent: "/automotive/automotive-services/equipment-rental",
                path: "/automotive/automotive-services/equipment-rental/off-highway-equipment-rental",
                active: true,
                meta: {
                  tags: "Off-highway Equipment Rental",
                  keywords: "Off-highway Equipment Rental",
                  description: "Off-highway Equipment Rental",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27c4",
                    },
                    slug: "construction-equipment-rental",
                    title: "Construction Equipment Rental",
                    id: 11000203,
                    parent:
                      "/automotive/automotive-services/equipment-rental/off-highway-equipment-rental",
                    path: "/automotive/automotive-services/equipment-rental/off-highway-equipment-rental/construction-equipment-rental",
                    active: true,
                    meta: {
                      tags: "Construction Equipment Rental",
                      keywords: "Construction Equipment Rental",
                      description: "Construction Equipment Rental",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27c2",
                    },
                    slug: "agricultural-machinery-rental",
                    title: "Agricultural Machinery Rental",
                    id: 11000201,
                    parent:
                      "/automotive/automotive-services/equipment-rental/off-highway-equipment-rental",
                    path: "/automotive/automotive-services/equipment-rental/off-highway-equipment-rental/agricultural-machinery-rental",
                    active: true,
                    meta: {
                      tags: "Agricultural Machinery Rental",
                      keywords: "Agricultural Machinery Rental",
                      description: "Agricultural Machinery Rental",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca27c3",
                        },
                        slug: "tractor-rental",
                        title: "Tractor Rental",
                        id: 11000202,
                        parent:
                          "/automotive/automotive-services/equipment-rental/off-highway-equipment-rental/agricultural-machinery-rental",
                        path: "/automotive/automotive-services/equipment-rental/off-highway-equipment-rental/agricultural-machinery-rental/tractor-rental",
                        active: true,
                        meta: {
                          tags: "Tractor Rental",
                          keywords: "Tractor Rental",
                          description: "Tractor Rental",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27ce",
            },
            slug: "ride-sharing",
            title: "Ride Sharing",
            id: 11000213,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/ride-sharing",
            active: true,
            meta: {
              tags: "Ride Sharing",
              keywords: "Ride Sharing",
              description: "Ride Sharing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27cf",
                },
                slug: "bicycle-sharing",
                title: "Bicycle Sharing",
                id: 11000214,
                parent: "/automotive/automotive-services/ride-sharing",
                path: "/automotive/automotive-services/ride-sharing/bicycle-sharing",
                active: true,
                meta: {
                  tags: "Bicycle Sharing",
                  keywords: "Bicycle Sharing",
                  description: "Bicycle Sharing",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27d0",
                    },
                    slug: "e-bike-sharing",
                    title: "E-Bike Sharing",
                    id: 11000215,
                    parent:
                      "/automotive/automotive-services/ride-sharing/bicycle-sharing",
                    path: "/automotive/automotive-services/ride-sharing/bicycle-sharing/e-bike-sharing",
                    active: true,
                    meta: {
                      tags: "E-Bike Sharing",
                      keywords: "E-Bike Sharing",
                      description: "E-Bike Sharing",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27d3",
            },
            slug: "tire-retreading-service",
            title: "Tire Retreading Service",
            id: 11000218,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/tire-retreading-service",
            active: true,
            meta: {
              tags: "Tire Retreading Service",
              keywords: "Tire Retreading Service",
              description: "Tire Retreading Service",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca279d",
            },
            slug: "auto-rental",
            title: "Auto Rental",
            id: 11000164,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/auto-rental",
            active: true,
            meta: {
              tags: "Auto Rental",
              keywords: "Auto Rental",
              description: "Auto Rental",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca279e",
                },
                slug: "marine-rental",
                title: "Marine Rental",
                id: 11000165,
                parent: "/automotive/automotive-services/auto-rental",
                path: "/automotive/automotive-services/auto-rental/marine-rental",
                active: true,
                meta: {
                  tags: "Marine Rental",
                  keywords: "Marine Rental",
                  description: "Marine Rental",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca279f",
                    },
                    slug: "boat-rental",
                    title: "Boat Rental",
                    id: 11000166,
                    parent:
                      "/automotive/automotive-services/auto-rental/marine-rental",
                    path: "/automotive/automotive-services/auto-rental/marine-rental/boat-rental",
                    active: true,
                    meta: {
                      tags: "Boat Rental",
                      keywords: "Boat Rental",
                      description: "Boat Rental",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27a0",
                },
                slug: "vehicle-rental",
                title: "Vehicle Rental",
                id: 11000167,
                parent: "/automotive/automotive-services/auto-rental",
                path: "/automotive/automotive-services/auto-rental/vehicle-rental",
                active: true,
                meta: {
                  tags: "Vehicle Rental",
                  keywords: "Vehicle Rental",
                  description: "Vehicle Rental",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27a1",
                    },
                    slug: "car-rental",
                    title: "Car Rental",
                    id: 11000168,
                    parent:
                      "/automotive/automotive-services/auto-rental/vehicle-rental",
                    path: "/automotive/automotive-services/auto-rental/vehicle-rental/car-rental",
                    active: true,
                    meta: {
                      tags: "Car Rental",
                      keywords: "Car Rental",
                      description: "Car Rental",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca27a2",
                        },
                        slug: "limousine-rental",
                        title: "Limousine Rental",
                        id: 11000169,
                        parent:
                          "/automotive/automotive-services/auto-rental/vehicle-rental/car-rental",
                        path: "/automotive/automotive-services/auto-rental/vehicle-rental/car-rental/limousine-rental",
                        active: true,
                        meta: {
                          tags: "Limousine Rental",
                          keywords: "Limousine Rental",
                          description: "Limousine Rental",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27aa",
                    },
                    slug: "two-wheeler-rental",
                    title: "Two Wheeler Rental",
                    id: 11000177,
                    parent:
                      "/automotive/automotive-services/auto-rental/vehicle-rental",
                    path: "/automotive/automotive-services/auto-rental/vehicle-rental/two-wheeler-rental",
                    active: true,
                    meta: {
                      tags: "Two Wheeler Rental",
                      keywords: "Two Wheeler Rental",
                      description: "Two Wheeler Rental",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27a6",
                    },
                    slug: "medium-and-heavy-duty-vehicles-rental",
                    title: "Medium and Heavy Duty Vehicles Rental",
                    id: 11000173,
                    parent:
                      "/automotive/automotive-services/auto-rental/vehicle-rental",
                    path: "/automotive/automotive-services/auto-rental/vehicle-rental/medium-and-heavy-duty-vehicles-rental",
                    active: true,
                    meta: {
                      tags: "Medium and Heavy Duty Vehicles Rental",
                      keywords: "Medium and Heavy Duty Vehicles Rental",
                      description: "Medium and Heavy Duty Vehicles Rental",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca27a7",
                        },
                        slug: "truck-rental",
                        title: "Truck Rental",
                        id: 11000174,
                        parent:
                          "/automotive/automotive-services/auto-rental/vehicle-rental/medium-and-heavy-duty-vehicles-rental",
                        path: "/automotive/automotive-services/auto-rental/vehicle-rental/medium-and-heavy-duty-vehicles-rental/truck-rental",
                        active: true,
                        meta: {
                          tags: "Truck Rental",
                          keywords: "Truck Rental",
                          description: "Truck Rental",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27a9",
                    },
                    slug: "recreational-vehicles-rental",
                    title: "Recreational Vehicles Rental",
                    id: 11000176,
                    parent:
                      "/automotive/automotive-services/auto-rental/vehicle-rental",
                    path: "/automotive/automotive-services/auto-rental/vehicle-rental/recreational-vehicles-rental",
                    active: true,
                    meta: {
                      tags: "Recreational Vehicles Rental",
                      keywords: "Recreational Vehicles Rental",
                      description: "Recreational Vehicles Rental",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27ab",
                    },
                    slug: "van-rental",
                    title: "Van Rental",
                    id: 11000178,
                    parent:
                      "/automotive/automotive-services/auto-rental/vehicle-rental",
                    path: "/automotive/automotive-services/auto-rental/vehicle-rental/van-rental",
                    active: true,
                    meta: {
                      tags: "Van Rental",
                      keywords: "Van Rental",
                      description: "Van Rental",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27a5",
                    },
                    slug: "leisure-rental",
                    title: "Leisure Rental",
                    id: 11000172,
                    parent:
                      "/automotive/automotive-services/auto-rental/vehicle-rental",
                    path: "/automotive/automotive-services/auto-rental/vehicle-rental/leisure-rental",
                    active: true,
                    meta: {
                      tags: "Leisure Rental",
                      keywords: "Leisure Rental",
                      description: "Leisure Rental",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27a3",
                    },
                    slug: "commercial-vehicles-rental",
                    title: "Commercial Vehicles Rental",
                    id: 11000170,
                    parent:
                      "/automotive/automotive-services/auto-rental/vehicle-rental",
                    path: "/automotive/automotive-services/auto-rental/vehicle-rental/commercial-vehicles-rental",
                    active: true,
                    meta: {
                      tags: "Commercial Vehicles Rental",
                      keywords: "Commercial Vehicles Rental",
                      description: "Commercial Vehicles Rental",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca27a4",
                        },
                        slug: "light-duty-vehicles-rental",
                        title: "Light Duty Vehicles Rental",
                        id: 11000171,
                        parent:
                          "/automotive/automotive-services/auto-rental/vehicle-rental/commercial-vehicles-rental",
                        path: "/automotive/automotive-services/auto-rental/vehicle-rental/commercial-vehicles-rental/light-duty-vehicles-rental",
                        active: true,
                        meta: {
                          tags: "Light Duty Vehicles Rental",
                          keywords: "Light Duty Vehicles Rental",
                          description: "Light Duty Vehicles Rental",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27a8",
                    },
                    slug: "recreational-vehicle-rental",
                    title: "Recreational Vehicle Rental",
                    id: 11000175,
                    parent:
                      "/automotive/automotive-services/auto-rental/vehicle-rental",
                    path: "/automotive/automotive-services/auto-rental/vehicle-rental/recreational-vehicle-rental",
                    active: true,
                    meta: {
                      tags: "Recreational Vehicle Rental",
                      keywords: "Recreational Vehicle Rental",
                      description: "Recreational Vehicle Rental",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27c8",
            },
            slug: "railway-service",
            title: "Railway Service",
            id: 11000207,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/railway-service",
            active: true,
            meta: {
              tags: "Railway Service",
              keywords: "Railway Service",
              description: "Railway Service",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27c9",
                },
                slug: "on-rail-food-service",
                title: "On-rail Food Service",
                id: 11000208,
                parent: "/automotive/automotive-services/railway-service",
                path: "/automotive/automotive-services/railway-service/on-rail-food-service",
                active: true,
                meta: {
                  tags: "On-rail Food Service",
                  keywords: "On-rail Food Service",
                  description: "On-rail Food Service",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27ba",
            },
            slug: "boat-and-yacht-charter",
            title: "Boat and Yacht Charter",
            id: 11000193,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/boat-and-yacht-charter",
            active: true,
            meta: {
              tags: "Boat and Yacht Charter",
              keywords: "Boat and Yacht Charter",
              description: "Boat and Yacht Charter",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27bb",
                },
                slug: "sailing-vessel-charter-services",
                title: "Sailing Vessel Charter Services",
                id: 11000194,
                parent:
                  "/automotive/automotive-services/boat-and-yacht-charter",
                path: "/automotive/automotive-services/boat-and-yacht-charter/sailing-vessel-charter-services",
                active: true,
                meta: {
                  tags: "Sailing Vessel Charter Services",
                  keywords: "Sailing Vessel Charter Services",
                  description: "Sailing Vessel Charter Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27bc",
                    },
                    slug: "cruising-vessel-charter",
                    title: "Cruising Vessel Charter",
                    id: 11000195,
                    parent:
                      "/automotive/automotive-services/boat-and-yacht-charter/sailing-vessel-charter-services",
                    path: "/automotive/automotive-services/boat-and-yacht-charter/sailing-vessel-charter-services/cruising-vessel-charter",
                    active: true,
                    meta: {
                      tags: "Cruising Vessel Charter",
                      keywords: "Cruising Vessel Charter",
                      description: "Cruising Vessel Charter",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27af",
            },
            slug: "automotive-e-commerce",
            title: "Automotive E-commerce",
            id: 11000182,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/automotive-e-commerce",
            active: true,
            meta: {
              tags: "Automotive E-commerce",
              keywords: "Automotive E-commerce",
              description: "Automotive E-commerce",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27b0",
                },
                slug: "auto-parts-e-commerce",
                title: "Auto Parts E-commerce",
                id: 11000183,
                parent: "/automotive/automotive-services/automotive-e-commerce",
                path: "/automotive/automotive-services/automotive-e-commerce/auto-parts-e-commerce",
                active: true,
                meta: {
                  tags: "Auto Parts E-commerce",
                  keywords: "Auto Parts E-commerce",
                  description: "Auto Parts E-commerce",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27b2",
            },
            slug: "automotive-financing",
            title: "Automotive Financing",
            id: 11000185,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/automotive-financing",
            active: true,
            meta: {
              tags: "Automotive Financing",
              keywords: "Automotive Financing",
              description: "Automotive Financing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27b3",
                },
                slug: "ev-financing",
                title: "EV Financing",
                id: 11000186,
                parent: "/automotive/automotive-services/automotive-financing",
                path: "/automotive/automotive-services/automotive-financing/ev-financing",
                active: true,
                meta: {
                  tags: "EV Financing",
                  keywords: "EV Financing",
                  description: "EV Financing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27b4",
                },
                slug: "pre-owned-vehicle-financing",
                title: "Pre-Owned Vehicle Financing",
                id: 11000187,
                parent: "/automotive/automotive-services/automotive-financing",
                path: "/automotive/automotive-services/automotive-financing/pre-owned-vehicle-financing",
                active: true,
                meta: {
                  tags: "Pre-Owned Vehicle Financing",
                  keywords: "Pre-Owned Vehicle Financing",
                  description: "Pre-Owned Vehicle Financing",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27b5",
            },
            slug: "automotive-outsourcing-service",
            title: "Automotive Outsourcing Service",
            id: 11000188,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/automotive-outsourcing-service",
            active: true,
            meta: {
              tags: "Automotive Outsourcing Service",
              keywords: "Automotive Outsourcing Service",
              description: "Automotive Outsourcing Service",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27b6",
                },
                slug: "automotive-engineering-service",
                title: "Automotive Engineering Service",
                id: 11000189,
                parent:
                  "/automotive/automotive-services/automotive-outsourcing-service",
                path: "/automotive/automotive-services/automotive-outsourcing-service/automotive-engineering-service",
                active: true,
                meta: {
                  tags: "Automotive Engineering Service",
                  keywords: "Automotive Engineering Service",
                  description: "Automotive Engineering Service",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27d1",
            },
            slug: "swapping-station",
            title: "Swapping Station",
            id: 11000216,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/swapping-station",
            active: true,
            meta: {
              tags: "Swapping Station",
              keywords: "Swapping Station",
              description: "Swapping Station",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27d2",
                },
                slug: "ev-battery-pack",
                title: "EV Battery Pack",
                id: 11000217,
                parent: "/automotive/automotive-services/swapping-station",
                path: "/automotive/automotive-services/swapping-station/ev-battery-pack",
                active: true,
                meta: {
                  tags: "EV Battery Pack",
                  keywords: "EV Battery Pack",
                  description: "EV Battery Pack",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27c6",
            },
            slug: "maritime-mro",
            title: "Maritime MRO",
            id: 11000205,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/maritime-mro",
            active: true,
            meta: {
              tags: "Maritime MRO",
              keywords: "Maritime MRO",
              description: "Maritime MRO",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27c7",
                },
                slug: "boats-and-ships-mro",
                title: "Boats and Ships MRO",
                id: 11000206,
                parent: "/automotive/automotive-services/maritime-mro",
                path: "/automotive/automotive-services/maritime-mro/boats-and-ships-mro",
                active: true,
                meta: {
                  tags: "Boats and Ships MRO",
                  keywords: "Boats and Ships MRO",
                  description: "Boats and Ships MRO",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca279b",
            },
            slug: "auto-parts-repair-service",
            title: "Auto Parts Repair Service",
            id: 11000162,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/auto-parts-repair-service",
            active: true,
            meta: {
              tags: "Auto Parts Repair Service",
              keywords: "Auto Parts Repair Service",
              description: "Auto Parts Repair Service",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca279c",
                },
                slug: "vehicle-transmission-repair",
                title: "Vehicle Transmission Repair",
                id: 11000163,
                parent:
                  "/automotive/automotive-services/auto-parts-repair-service",
                path: "/automotive/automotive-services/auto-parts-repair-service/vehicle-transmission-repair",
                active: true,
                meta: {
                  tags: "Vehicle Transmission Repair",
                  keywords: "Vehicle Transmission Repair",
                  description: "Vehicle Transmission Repair",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27b1",
            },
            slug: "automotive-electronic-device",
            title: "Automotive Electronic Device",
            id: 11000184,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/automotive-electronic-device",
            active: true,
            meta: {
              tags: "Automotive Electronic Device",
              keywords: "Automotive Electronic Device",
              description: "Automotive Electronic Device",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27c5",
            },
            slug: "ev-charging-station",
            title: "EV Charging Station",
            id: 11000204,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/ev-charging-station",
            active: true,
            meta: {
              tags: "EV Charging Station",
              keywords: "EV Charging Station",
              description: "EV Charging Station",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27ca",
            },
            slug: "ride-hailing",
            title: "Ride Hailing",
            id: 11000209,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/ride-hailing",
            active: true,
            meta: {
              tags: "Ride Hailing",
              keywords: "Ride Hailing",
              description: "Ride Hailing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27cb",
                },
                slug: "autonomous-taxi",
                title: "Autonomous Taxi",
                id: 11000210,
                parent: "/automotive/automotive-services/ride-hailing",
                path: "/automotive/automotive-services/ride-hailing/autonomous-taxi",
                active: true,
                meta: {
                  tags: "Autonomous Taxi",
                  keywords: "Autonomous Taxi",
                  description: "Autonomous Taxi",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27cc",
                },
                slug: "rickshaw-hailing",
                title: "Rickshaw Hailing",
                id: 11000211,
                parent: "/automotive/automotive-services/ride-hailing",
                path: "/automotive/automotive-services/ride-hailing/rickshaw-hailing",
                active: true,
                meta: {
                  tags: "Rickshaw Hailing",
                  keywords: "Rickshaw Hailing",
                  description: "Rickshaw Hailing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27cd",
                },
                slug: "two-wheeler-taxi",
                title: "Two Wheeler Taxi",
                id: 11000212,
                parent: "/automotive/automotive-services/ride-hailing",
                path: "/automotive/automotive-services/ride-hailing/two-wheeler-taxi",
                active: true,
                meta: {
                  tags: "Two Wheeler Taxi",
                  keywords: "Two Wheeler Taxi",
                  description: "Two Wheeler Taxi",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27d4",
            },
            slug: "transportation-service",
            title: "Transportation Service",
            id: 11000219,
            parent: "/automotive/automotive-services",
            path: "/automotive/automotive-services/transportation-service",
            active: true,
            meta: {
              tags: "Transportation Service",
              keywords: "Transportation Service",
              description: "Transportation Service",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27d5",
                },
                slug: "employee-transportation",
                title: "Employee Transportation",
                id: 11000220,
                parent:
                  "/automotive/automotive-services/transportation-service",
                path: "/automotive/automotive-services/transportation-service/employee-transportation",
                active: true,
                meta: {
                  tags: "Employee Transportation",
                  keywords: "Employee Transportation",
                  description: "Employee Transportation",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9fcced7de0399bca26fc",
        },
        slug: "auto-parts",
        title: "Auto Parts",
        id: 65661,
        parent: "/automotive",
        path: "/automotive/auto-parts",
        active: true,
        meta: {
          tags: "Auto Parts",
          keywords: "Auto Parts",
          description: "Auto Parts",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9fcced7de0399bca26ff",
            },
            slug: "automotive-aftermarket",
            title: "Automotive Aftermarket",
            id: 11000004,
            parent: "/automotive/auto-parts",
            path: "/automotive/auto-parts/automotive-aftermarket",
            active: true,
            meta: {
              tags: "Automotive Aftermarket",
              keywords: "Automotive Aftermarket",
              description: "Automotive Aftermarket",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2700",
            },
            slug: "automotive-electrical-and-electronics",
            title: "Automotive Electrical and Electronics",
            id: 11000005,
            parent: "/automotive/auto-parts",
            path: "/automotive/auto-parts/automotive-electrical-and-electronics",
            active: true,
            meta: {
              tags: "Automotive Electrical and Electronics",
              keywords: "Automotive Electrical and Electronics",
              description: "Automotive Electrical and Electronics",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca270b",
                },
                slug: "connected-vehicle-infrastructure",
                title: "Connected Vehicle Infrastructure",
                id: 11000016,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/connected-vehicle-infrastructure",
                active: true,
                meta: {
                  tags: "Connected Vehicle Infrastructure",
                  keywords: "Connected Vehicle Infrastructure",
                  description: "Connected Vehicle Infrastructure",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2713",
                },
                slug: "intelligent-steering-system",
                title: "Intelligent Steering System",
                id: 11000024,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/intelligent-steering-system",
                active: true,
                meta: {
                  tags: "Intelligent Steering System",
                  keywords: "Intelligent Steering System",
                  description: "Intelligent Steering System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2717",
                },
                slug: "tire-inflation",
                title: "Tire Inflation",
                id: 11000028,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/tire-inflation",
                active: true,
                meta: {
                  tags: "Tire Inflation",
                  keywords: "Tire Inflation",
                  description: "Tire Inflation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2708",
                },
                slug: "automotive-switches",
                title: "Automotive Switches",
                id: 11000013,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-switches",
                active: true,
                meta: {
                  tags: "Automotive Switches",
                  keywords: "Automotive Switches",
                  description: "Automotive Switches",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca270a",
                },
                slug: "automotive-testing-equipment",
                title: "Automotive Testing Equipment",
                id: 11000015,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-testing-equipment",
                active: true,
                meta: {
                  tags: "Automotive Testing Equipment",
                  keywords: "Automotive Testing Equipment",
                  description: "Automotive Testing Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca270c",
                },
                slug: "e-bike-drivetrain-components",
                title: "E-Bike Drivetrain Components",
                id: 11000017,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/e-bike-drivetrain-components",
                active: true,
                meta: {
                  tags: "E-Bike Drivetrain Components",
                  keywords: "E-Bike Drivetrain Components",
                  description: "E-Bike Drivetrain Components",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca270d",
                    },
                    slug: "e-bike-traction-motor",
                    title: "E-Bike Traction Motor",
                    id: 11000018,
                    parent:
                      "/automotive/auto-parts/automotive-electrical-and-electronics/e-bike-drivetrain-components",
                    path: "/automotive/auto-parts/automotive-electrical-and-electronics/e-bike-drivetrain-components/e-bike-traction-motor",
                    active: true,
                    meta: {
                      tags: "E-Bike Traction Motor",
                      keywords: "E-Bike Traction Motor",
                      description: "E-Bike Traction Motor",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2707",
                },
                slug: "automotive-sensors",
                title: "Automotive Sensors",
                id: 11000012,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-sensors",
                active: true,
                meta: {
                  tags: "Automotive Sensors",
                  keywords: "Automotive Sensors",
                  description: "Automotive Sensors",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2714",
                },
                slug: "off-highway-equipment-auxiliary-motors",
                title: "Off-highway Equipment Auxiliary Motors",
                id: 11000025,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/off-highway-equipment-auxiliary-motors",
                active: true,
                meta: {
                  tags: "Off-highway Equipment Auxiliary Motors",
                  keywords: "Off-highway Equipment Auxiliary Motors",
                  description: "Off-highway Equipment Auxiliary Motors",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2704",
                },
                slug: "automotive-ignition-system",
                title: "Automotive Ignition System",
                id: 11000009,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-ignition-system",
                active: true,
                meta: {
                  tags: "Automotive Ignition System",
                  keywords: "Automotive Ignition System",
                  description: "Automotive Ignition System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2703",
                },
                slug: "automotive-battery",
                title: "Automotive Battery",
                id: 11000008,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-battery",
                active: true,
                meta: {
                  tags: "Automotive Battery",
                  keywords: "Automotive Battery",
                  description: "Automotive Battery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2701",
                },
                slug: "automotive-auxiliary-motors",
                title: "Automotive Auxiliary Motors",
                id: 11000006,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-auxiliary-motors",
                active: true,
                meta: {
                  tags: "Automotive Auxiliary Motors",
                  keywords: "Automotive Auxiliary Motors",
                  description: "Automotive Auxiliary Motors",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2702",
                    },
                    slug: "automotive-actuators",
                    title: "Automotive Actuators",
                    id: 11000007,
                    parent:
                      "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-auxiliary-motors",
                    path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-auxiliary-motors/automotive-actuators",
                    active: true,
                    meta: {
                      tags: "Automotive Actuators",
                      keywords: "Automotive Actuators",
                      description: "Automotive Actuators",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca270f",
                },
                slug: "ev-components",
                title: "EV Components",
                id: 11000020,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/ev-components",
                active: true,
                meta: {
                  tags: "EV Components",
                  keywords: "EV Components",
                  description: "EV Components",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2710",
                    },
                    slug: "ev-sensors",
                    title: "EV Sensors",
                    id: 11000021,
                    parent:
                      "/automotive/auto-parts/automotive-electrical-and-electronics/ev-components",
                    path: "/automotive/auto-parts/automotive-electrical-and-electronics/ev-components/ev-sensors",
                    active: true,
                    meta: {
                      tags: "EV Sensors",
                      keywords: "EV Sensors",
                      description: "EV Sensors",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2705",
                },
                slug: "automotive-power-distribution",
                title: "Automotive Power Distribution",
                id: 11000010,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-power-distribution",
                active: true,
                meta: {
                  tags: "Automotive Power Distribution",
                  keywords: "Automotive Power Distribution",
                  description: "Automotive Power Distribution",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2706",
                    },
                    slug: "automotive-wires",
                    title: "Automotive Wires",
                    id: 11000011,
                    parent:
                      "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-power-distribution",
                    path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-power-distribution/automotive-wires",
                    active: true,
                    meta: {
                      tags: "Automotive Wires",
                      keywords: "Automotive Wires",
                      description: "Automotive Wires",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2716",
                },
                slug: "rider-assistance",
                title: "Rider Assistance",
                id: 11000027,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/rider-assistance",
                active: true,
                meta: {
                  tags: "Rider Assistance",
                  keywords: "Rider Assistance",
                  description: "Rider Assistance",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2718",
                },
                slug: "vehicle-wire-harness",
                title: "Vehicle Wire Harness",
                id: 11000029,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/vehicle-wire-harness",
                active: true,
                meta: {
                  tags: "Vehicle Wire Harness",
                  keywords: "Vehicle Wire Harness",
                  description: "Vehicle Wire Harness",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2709",
                },
                slug: "automotive-telematics",
                title: "Automotive Telematics",
                id: 11000014,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/automotive-telematics",
                active: true,
                meta: {
                  tags: "Automotive Telematics",
                  keywords: "Automotive Telematics",
                  description: "Automotive Telematics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2711",
                },
                slug: "ev-power-distribution",
                title: "EV Power Distribution",
                id: 11000022,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/ev-power-distribution",
                active: true,
                meta: {
                  tags: "EV Power Distribution",
                  keywords: "EV Power Distribution",
                  description: "EV Power Distribution",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2712",
                    },
                    slug: "ev-wires",
                    title: "EV Wires",
                    id: 11000023,
                    parent:
                      "/automotive/auto-parts/automotive-electrical-and-electronics/ev-power-distribution",
                    path: "/automotive/auto-parts/automotive-electrical-and-electronics/ev-power-distribution/ev-wires",
                    active: true,
                    meta: {
                      tags: "EV Wires",
                      keywords: "EV Wires",
                      description: "EV Wires",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2715",
                },
                slug: "off-highway-equipment-wiring-harness",
                title: "Off-highway Equipment Wiring Harness",
                id: 11000026,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/off-highway-equipment-wiring-harness",
                active: true,
                meta: {
                  tags: "Off-highway Equipment Wiring Harness",
                  keywords: "Off-highway Equipment Wiring Harness",
                  description: "Off-highway Equipment Wiring Harness",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca270e",
                },
                slug: "ev-charging-equipment",
                title: "EV Charging Equipment",
                id: 11000019,
                parent:
                  "/automotive/auto-parts/automotive-electrical-and-electronics",
                path: "/automotive/auto-parts/automotive-electrical-and-electronics/ev-charging-equipment",
                active: true,
                meta: {
                  tags: "EV Charging Equipment",
                  keywords: "EV Charging Equipment",
                  description: "EV Charging Equipment",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2752",
            },
            slug: "automotive-interior-products",
            title: "Automotive Interior Products",
            id: 11000087,
            parent: "/automotive/auto-parts",
            path: "/automotive/auto-parts/automotive-interior-products",
            active: true,
            meta: {
              tags: "Automotive Interior Products",
              keywords: "Automotive Interior Products",
              description: "Automotive Interior Products",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2756",
                },
                slug: "automotive-display",
                title: "Automotive Display",
                id: 11000091,
                parent: "/automotive/auto-parts/automotive-interior-products",
                path: "/automotive/auto-parts/automotive-interior-products/automotive-display",
                active: true,
                meta: {
                  tags: "Automotive Display",
                  keywords: "Automotive Display",
                  description: "Automotive Display",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2758",
                    },
                    slug: "e-bike-infotainment",
                    title: "E-Bike Infotainment",
                    id: 11000093,
                    parent:
                      "/automotive/auto-parts/automotive-interior-products/automotive-display",
                    path: "/automotive/auto-parts/automotive-interior-products/automotive-display/e-bike-infotainment",
                    active: true,
                    meta: {
                      tags: "E-Bike Infotainment",
                      keywords: "E-Bike Infotainment",
                      description: "E-Bike Infotainment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2757",
                    },
                    slug: "automotive-centre-console-display",
                    title: "Automotive Centre Console Display",
                    id: 11000092,
                    parent:
                      "/automotive/auto-parts/automotive-interior-products/automotive-display",
                    path: "/automotive/auto-parts/automotive-interior-products/automotive-display/automotive-centre-console-display",
                    active: true,
                    meta: {
                      tags: "Automotive Centre Console Display",
                      keywords: "Automotive Centre Console Display",
                      description: "Automotive Centre Console Display",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2759",
                },
                slug: "automotive-interior-electronics",
                title: "Automotive Interior Electronics",
                id: 11000094,
                parent: "/automotive/auto-parts/automotive-interior-products",
                path: "/automotive/auto-parts/automotive-interior-products/automotive-interior-electronics",
                active: true,
                meta: {
                  tags: "Automotive Interior Electronics",
                  keywords: "Automotive Interior Electronics",
                  description: "Automotive Interior Electronics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca275e",
                },
                slug: "automotive-ventilation",
                title: "Automotive Ventilation",
                id: 11000099,
                parent: "/automotive/auto-parts/automotive-interior-products",
                path: "/automotive/auto-parts/automotive-interior-products/automotive-ventilation",
                active: true,
                meta: {
                  tags: "Automotive Ventilation",
                  keywords: "Automotive Ventilation",
                  description: "Automotive Ventilation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca275b",
                },
                slug: "automotive-safety",
                title: "Automotive Safety",
                id: 11000096,
                parent: "/automotive/auto-parts/automotive-interior-products",
                path: "/automotive/auto-parts/automotive-interior-products/automotive-safety",
                active: true,
                meta: {
                  tags: "Automotive Safety",
                  keywords: "Automotive Safety",
                  description: "Automotive Safety",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca275c",
                    },
                    slug: "airbags",
                    title: "Airbags",
                    id: 11000097,
                    parent:
                      "/automotive/auto-parts/automotive-interior-products/automotive-safety",
                    path: "/automotive/auto-parts/automotive-interior-products/automotive-safety/airbags",
                    active: true,
                    meta: {
                      tags: "Airbags",
                      keywords: "Airbags",
                      description: "Airbags",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca275d",
                    },
                    slug: "seatbelt-system",
                    title: "Seatbelt System",
                    id: 11000098,
                    parent:
                      "/automotive/auto-parts/automotive-interior-products/automotive-safety",
                    path: "/automotive/auto-parts/automotive-interior-products/automotive-safety/seatbelt-system",
                    active: true,
                    meta: {
                      tags: "Seatbelt System",
                      keywords: "Seatbelt System",
                      description: "Seatbelt System",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2753",
                },
                slug: "automotive-cabin-interior",
                title: "Automotive Cabin Interior",
                id: 11000088,
                parent: "/automotive/auto-parts/automotive-interior-products",
                path: "/automotive/auto-parts/automotive-interior-products/automotive-cabin-interior",
                active: true,
                meta: {
                  tags: "Automotive Cabin Interior",
                  keywords: "Automotive Cabin Interior",
                  description: "Automotive Cabin Interior",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2754",
                    },
                    slug: "automotive-seats",
                    title: "Automotive Seats",
                    id: 11000089,
                    parent:
                      "/automotive/auto-parts/automotive-interior-products/automotive-cabin-interior",
                    path: "/automotive/auto-parts/automotive-interior-products/automotive-cabin-interior/automotive-seats",
                    active: true,
                    meta: {
                      tags: "Automotive Seats",
                      keywords: "Automotive Seats",
                      description: "Automotive Seats",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2755",
                    },
                    slug: "forklift-interior-products",
                    title: "Forklift Interior Products",
                    id: 11000090,
                    parent:
                      "/automotive/auto-parts/automotive-interior-products/automotive-cabin-interior",
                    path: "/automotive/auto-parts/automotive-interior-products/automotive-cabin-interior/forklift-interior-products",
                    active: true,
                    meta: {
                      tags: "Forklift Interior Products",
                      keywords: "Forklift Interior Products",
                      description: "Forklift Interior Products",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca275a",
                },
                slug: "automotive-interior-vision",
                title: "Automotive Interior Vision",
                id: 11000095,
                parent: "/automotive/auto-parts/automotive-interior-products",
                path: "/automotive/auto-parts/automotive-interior-products/automotive-interior-vision",
                active: true,
                meta: {
                  tags: "Automotive Interior Vision",
                  keywords: "Automotive Interior Vision",
                  description: "Automotive Interior Vision",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2762",
            },
            slug: "marine-vehicle-parts",
            title: "Marine Vehicle Parts",
            id: 11000103,
            parent: "/automotive/auto-parts",
            path: "/automotive/auto-parts/marine-vehicle-parts",
            active: true,
            meta: {
              tags: "Marine Vehicle Parts",
              keywords: "Marine Vehicle Parts",
              description: "Marine Vehicle Parts",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2766",
                },
                slug: "marine-powertrain",
                title: "Marine Powertrain",
                id: 11000107,
                parent: "/automotive/auto-parts/marine-vehicle-parts",
                path: "/automotive/auto-parts/marine-vehicle-parts/marine-powertrain",
                active: true,
                meta: {
                  tags: "Marine Powertrain",
                  keywords: "Marine Powertrain",
                  description: "Marine Powertrain",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2767",
                    },
                    slug: "marine-engine-components",
                    title: "Marine Engine Components",
                    id: 11000108,
                    parent:
                      "/automotive/auto-parts/marine-vehicle-parts/marine-powertrain",
                    path: "/automotive/auto-parts/marine-vehicle-parts/marine-powertrain/marine-engine-components",
                    active: true,
                    meta: {
                      tags: "Marine Engine Components",
                      keywords: "Marine Engine Components",
                      description: "Marine Engine Components",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2768",
                },
                slug: "marine-stability-system",
                title: "Marine Stability System",
                id: 11000109,
                parent: "/automotive/auto-parts/marine-vehicle-parts",
                path: "/automotive/auto-parts/marine-vehicle-parts/marine-stability-system",
                active: true,
                meta: {
                  tags: "Marine Stability System",
                  keywords: "Marine Stability System",
                  description: "Marine Stability System",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2769",
                    },
                    slug: "marine-maneuvering-system",
                    title: "Marine Maneuvering System",
                    id: 11000110,
                    parent:
                      "/automotive/auto-parts/marine-vehicle-parts/marine-stability-system",
                    path: "/automotive/auto-parts/marine-vehicle-parts/marine-stability-system/marine-maneuvering-system",
                    active: true,
                    meta: {
                      tags: "Marine Maneuvering System",
                      keywords: "Marine Maneuvering System",
                      description: "Marine Maneuvering System",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2763",
                },
                slug: "marine-hvac",
                title: "Marine HVAC",
                id: 11000104,
                parent: "/automotive/auto-parts/marine-vehicle-parts",
                path: "/automotive/auto-parts/marine-vehicle-parts/marine-hvac",
                active: true,
                meta: {
                  tags: "Marine HVAC",
                  keywords: "Marine HVAC",
                  description: "Marine HVAC",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca276a",
                },
                slug: "marine-telematics",
                title: "Marine Telematics",
                id: 11000111,
                parent: "/automotive/auto-parts/marine-vehicle-parts",
                path: "/automotive/auto-parts/marine-vehicle-parts/marine-telematics",
                active: true,
                meta: {
                  tags: "Marine Telematics",
                  keywords: "Marine Telematics",
                  description: "Marine Telematics",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2764",
                },
                slug: "marine-interior-products",
                title: "Marine Interior Products",
                id: 11000105,
                parent: "/automotive/auto-parts/marine-vehicle-parts",
                path: "/automotive/auto-parts/marine-vehicle-parts/marine-interior-products",
                active: true,
                meta: {
                  tags: "Marine Interior Products",
                  keywords: "Marine Interior Products",
                  description: "Marine Interior Products",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2765",
                    },
                    slug: "marine-comfort-and-convenience",
                    title: "Marine Comfort and Convenience",
                    id: 11000106,
                    parent:
                      "/automotive/auto-parts/marine-vehicle-parts/marine-interior-products",
                    path: "/automotive/auto-parts/marine-vehicle-parts/marine-interior-products/marine-comfort-and-convenience",
                    active: true,
                    meta: {
                      tags: "Marine Comfort and Convenience",
                      keywords: "Marine Comfort and Convenience",
                      description: "Marine Comfort and Convenience",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2719",
            },
            slug: "automotive-exterior-products",
            title: "Automotive Exterior Products",
            id: 11000030,
            parent: "/automotive/auto-parts",
            path: "/automotive/auto-parts/automotive-exterior-products",
            active: true,
            meta: {
              tags: "Automotive Exterior Products",
              keywords: "Automotive Exterior Products",
              description: "Automotive Exterior Products",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca271a",
                },
                slug: "automotive-body-parts",
                title: "Automotive Body Parts",
                id: 11000031,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-body-parts",
                active: true,
                meta: {
                  tags: "Automotive Body Parts",
                  keywords: "Automotive Body Parts",
                  description: "Automotive Body Parts",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca271d",
                    },
                    slug: "roof-panel",
                    title: "Roof Panel",
                    id: 11000034,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-body-parts",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-body-parts/roof-panel",
                    active: true,
                    meta: {
                      tags: "Roof Panel",
                      keywords: "Roof Panel",
                      description: "Roof Panel",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca271b",
                    },
                    slug: "automotive-fenders",
                    title: "Automotive Fenders",
                    id: 11000032,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-body-parts",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-body-parts/automotive-fenders",
                    active: true,
                    meta: {
                      tags: "Automotive Fenders",
                      keywords: "Automotive Fenders",
                      description: "Automotive Fenders",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca271c",
                    },
                    slug: "automotive-tailgate",
                    title: "Automotive Tailgate",
                    id: 11000033,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-body-parts",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-body-parts/automotive-tailgate",
                    active: true,
                    meta: {
                      tags: "Automotive Tailgate",
                      keywords: "Automotive Tailgate",
                      description: "Automotive Tailgate",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca271e",
                    },
                    slug: "roof-system",
                    title: "Roof System",
                    id: 11000035,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-body-parts",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-body-parts/roof-system",
                    active: true,
                    meta: {
                      tags: "Roof System",
                      keywords: "Roof System",
                      description: "Roof System",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2742",
                },
                slug: "vehicle-chassis",
                title: "Vehicle Chassis",
                id: 11000071,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/vehicle-chassis",
                active: true,
                meta: {
                  tags: "Vehicle Chassis",
                  keywords: "Vehicle Chassis",
                  description: "Vehicle Chassis",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2745",
                    },
                    slug: "body-in-white",
                    title: "Body-in-White",
                    id: 11000074,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/vehicle-chassis",
                    path: "/automotive/auto-parts/automotive-exterior-products/vehicle-chassis/body-in-white",
                    active: true,
                    meta: {
                      tags: "Body-in-White",
                      keywords: "Body-in-White",
                      description: "Body-in-White",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2743",
                    },
                    slug: "automotive-suspension-system",
                    title: "Automotive Suspension System",
                    id: 11000072,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/vehicle-chassis",
                    path: "/automotive/auto-parts/automotive-exterior-products/vehicle-chassis/automotive-suspension-system",
                    active: true,
                    meta: {
                      tags: "Automotive Suspension System",
                      keywords: "Automotive Suspension System",
                      description: "Automotive Suspension System",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2744",
                        },
                        slug: "vehicle-shock-absorber",
                        title: "Vehicle Shock Absorber",
                        id: 11000073,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/vehicle-chassis/automotive-suspension-system",
                        path: "/automotive/auto-parts/automotive-exterior-products/vehicle-chassis/automotive-suspension-system/vehicle-shock-absorber",
                        active: true,
                        meta: {
                          tags: "Vehicle Shock Absorber",
                          keywords: "Vehicle Shock Absorber",
                          description: "Vehicle Shock Absorber",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2730",
                },
                slug: "automotive-fuel-storage",
                title: "Automotive Fuel Storage",
                id: 11000053,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-fuel-storage",
                active: true,
                meta: {
                  tags: "Automotive Fuel Storage",
                  keywords: "Automotive Fuel Storage",
                  description: "Automotive Fuel Storage",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2731",
                    },
                    slug: "automotive-fuel-tank-components",
                    title: "Automotive Fuel Tank Components",
                    id: 11000054,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-fuel-storage",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-fuel-storage/automotive-fuel-tank-components",
                    active: true,
                    meta: {
                      tags: "Automotive Fuel Tank Components",
                      keywords: "Automotive Fuel Tank Components",
                      description: "Automotive Fuel Tank Components",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2732",
                },
                slug: "automotive-glass",
                title: "Automotive Glass",
                id: 11000055,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-glass",
                active: true,
                meta: {
                  tags: "Automotive Glass",
                  keywords: "Automotive Glass",
                  description: "Automotive Glass",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2733",
                    },
                    slug: "sunroof-glass",
                    title: "Sunroof Glass",
                    id: 11000056,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-glass",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-glass/sunroof-glass",
                    active: true,
                    meta: {
                      tags: "Sunroof Glass",
                      keywords: "Sunroof Glass",
                      description: "Sunroof Glass",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca271f",
                },
                slug: "automotive-communication-system",
                title: "Automotive Communication System",
                id: 11000036,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-communication-system",
                active: true,
                meta: {
                  tags: "Automotive Communication System",
                  keywords: "Automotive Communication System",
                  description: "Automotive Communication System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2734",
                },
                slug: "automotive-hvac",
                title: "Automotive HVAC",
                id: 11000057,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-hvac",
                active: true,
                meta: {
                  tags: "Automotive HVAC",
                  keywords: "Automotive HVAC",
                  description: "Automotive HVAC",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2735",
                    },
                    slug: "off-highway-equipment-hvac",
                    title: "Off-highway Equipment HVAC",
                    id: 11000058,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-hvac",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-hvac/off-highway-equipment-hvac",
                    active: true,
                    meta: {
                      tags: "Off-highway Equipment HVAC",
                      keywords: "Off-highway Equipment HVAC",
                      description: "Off-highway Equipment HVAC",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2746",
                },
                slug: "vehicle-exhaust-system",
                title: "Vehicle Exhaust System",
                id: 11000075,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/vehicle-exhaust-system",
                active: true,
                meta: {
                  tags: "Vehicle Exhaust System",
                  keywords: "Vehicle Exhaust System",
                  description: "Vehicle Exhaust System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2720",
                },
                slug: "automotive-drivetrain",
                title: "Automotive Drivetrain",
                id: 11000037,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain",
                active: true,
                meta: {
                  tags: "Automotive Drivetrain",
                  keywords: "Automotive Drivetrain",
                  description: "Automotive Drivetrain",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2721",
                    },
                    slug: "automotive-braking-system",
                    title: "Automotive Braking System",
                    id: 11000038,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-braking-system",
                    active: true,
                    meta: {
                      tags: "Automotive Braking System",
                      keywords: "Automotive Braking System",
                      description: "Automotive Braking System",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2722",
                        },
                        slug: "disc-brake-components",
                        title: "Disc Brake Components",
                        id: 11000039,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-braking-system",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-braking-system/disc-brake-components",
                        active: true,
                        meta: {
                          tags: "Disc Brake Components",
                          keywords: "Disc Brake Components",
                          description: "Disc Brake Components",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2723",
                    },
                    slug: "automotive-tires",
                    title: "Automotive Tires",
                    id: 11000040,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires",
                    active: true,
                    meta: {
                      tags: "Automotive Tires",
                      keywords: "Automotive Tires",
                      description: "Automotive Tires",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2726",
                        },
                        slug: "off-highway-equipment-tires",
                        title: "Off-highway Equipment Tires",
                        id: 11000043,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires/off-highway-equipment-tires",
                        active: true,
                        meta: {
                          tags: "Off-highway Equipment Tires",
                          keywords: "Off-highway Equipment Tires",
                          description: "Off-highway Equipment Tires",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2728",
                        },
                        slug: "wheel",
                        title: "Wheel",
                        id: 11000045,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires/wheel",
                        active: true,
                        meta: {
                          tags: "Wheel",
                          keywords: "Wheel",
                          description: "Wheel",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2724",
                        },
                        slug: "agricultural-tires",
                        title: "Agricultural Tires",
                        id: 11000041,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires/agricultural-tires",
                        active: true,
                        meta: {
                          tags: "Agricultural Tires",
                          keywords: "Agricultural Tires",
                          description: "Agricultural Tires",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2725",
                        },
                        slug: "forklift-tires",
                        title: "Forklift Tires",
                        id: 11000042,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires/forklift-tires",
                        active: true,
                        meta: {
                          tags: "Forklift Tires",
                          keywords: "Forklift Tires",
                          description: "Forklift Tires",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2727",
                        },
                        slug: "tire-pressure-management",
                        title: "Tire Pressure Management",
                        id: 11000044,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-tires/tire-pressure-management",
                        active: true,
                        meta: {
                          tags: "Tire Pressure Management",
                          keywords: "Tire Pressure Management",
                          description: "Tire Pressure Management",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2729",
                    },
                    slug: "automotive-transmission-system",
                    title: "Automotive Transmission System",
                    id: 11000046,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-transmission-system",
                    active: true,
                    meta: {
                      tags: "Automotive Transmission System",
                      keywords: "Automotive Transmission System",
                      description: "Automotive Transmission System",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca272a",
                        },
                        slug: "automatic-transmission-system",
                        title: "Automatic Transmission System",
                        id: 11000047,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-transmission-system",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/automotive-transmission-system/automatic-transmission-system",
                        active: true,
                        meta: {
                          tags: "Automatic Transmission System",
                          keywords: "Automatic Transmission System",
                          description: "Automatic Transmission System",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca272b",
                    },
                    slug: "e-bike-drivetrain",
                    title: "E-Bike Drivetrain",
                    id: 11000048,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-drivetrain/e-bike-drivetrain",
                    active: true,
                    meta: {
                      tags: "E-Bike Drivetrain",
                      keywords: "E-Bike Drivetrain",
                      description: "E-Bike Drivetrain",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca272c",
                },
                slug: "automotive-exterior-accessories",
                title: "Automotive Exterior Accessories",
                id: 11000049,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-exterior-accessories",
                active: true,
                meta: {
                  tags: "Automotive Exterior Accessories",
                  keywords: "Automotive Exterior Accessories",
                  description: "Automotive Exterior Accessories",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca272d",
                    },
                    slug: "automotive-roof-mounted-components",
                    title: "Automotive Roof Mounted Components",
                    id: 11000050,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-exterior-accessories",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-exterior-accessories/automotive-roof-mounted-components",
                    active: true,
                    meta: {
                      tags: "Automotive Roof Mounted Components",
                      keywords: "Automotive Roof Mounted Components",
                      description: "Automotive Roof Mounted Components",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca272e",
                },
                slug: "automotive-fuel-injection-system",
                title: "Automotive Fuel Injection System",
                id: 11000051,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-fuel-injection-system",
                active: true,
                meta: {
                  tags: "Automotive Fuel Injection System",
                  keywords: "Automotive Fuel Injection System",
                  description: "Automotive Fuel Injection System",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca272f",
                    },
                    slug: "diesel-injection-system",
                    title: "Diesel Injection System",
                    id: 11000052,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-fuel-injection-system",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-fuel-injection-system/diesel-injection-system",
                    active: true,
                    meta: {
                      tags: "Diesel Injection System",
                      keywords: "Diesel Injection System",
                      description: "Diesel Injection System",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2741",
                },
                slug: "ev-drivetrain-components",
                title: "EV Drivetrain Components",
                id: 11000070,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/ev-drivetrain-components",
                active: true,
                meta: {
                  tags: "EV Drivetrain Components",
                  keywords: "EV Drivetrain Components",
                  description: "EV Drivetrain Components",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2747",
                },
                slug: "vehicle-powertrain",
                title: "Vehicle Powertrain",
                id: 11000076,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain",
                active: true,
                meta: {
                  tags: "Vehicle Powertrain",
                  keywords: "Vehicle Powertrain",
                  description: "Vehicle Powertrain",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca274e",
                    },
                    slug: "ev-powertrain",
                    title: "EV Powertrain",
                    id: 11000083,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain",
                    path: "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/ev-powertrain",
                    active: true,
                    meta: {
                      tags: "EV Powertrain",
                      keywords: "EV Powertrain",
                      description: "EV Powertrain",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca274f",
                        },
                        slug: "ev-battery",
                        title: "EV Battery",
                        id: 11000084,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/ev-powertrain",
                        path: "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/ev-powertrain/ev-battery",
                        active: true,
                        meta: {
                          tags: "EV Battery",
                          keywords: "EV Battery",
                          description: "EV Battery",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2748",
                    },
                    slug: "automotive-engine",
                    title: "Automotive Engine",
                    id: 11000077,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain",
                    path: "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine",
                    active: true,
                    meta: {
                      tags: "Automotive Engine",
                      keywords: "Automotive Engine",
                      description: "Automotive Engine",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca274b",
                        },
                        slug: "diesel-engine-components",
                        title: "Diesel Engine Components",
                        id: 11000080,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine",
                        path: "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine/diesel-engine-components",
                        active: true,
                        meta: {
                          tags: "Diesel Engine Components",
                          keywords: "Diesel Engine Components",
                          description: "Diesel Engine Components",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9fcced7de0399bca274c",
                            },
                            slug: "diesel-ignition-system",
                            title: "Diesel Ignition System",
                            id: 11000081,
                            parent:
                              "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine/diesel-engine-components",
                            path: "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine/diesel-engine-components/diesel-ignition-system",
                            active: true,
                            meta: {
                              tags: "Diesel Ignition System",
                              keywords: "Diesel Ignition System",
                              description: "Diesel Ignition System",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2749",
                        },
                        slug: "automotive-pistons",
                        title: "Automotive Pistons",
                        id: 11000078,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine",
                        path: "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine/automotive-pistons",
                        active: true,
                        meta: {
                          tags: "Automotive Pistons",
                          keywords: "Automotive Pistons",
                          description: "Automotive Pistons",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca274d",
                        },
                        slug: "engine-block",
                        title: "Engine Block",
                        id: 11000082,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine",
                        path: "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine/engine-block",
                        active: true,
                        meta: {
                          tags: "Engine Block",
                          keywords: "Engine Block",
                          description: "Engine Block",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca274a",
                        },
                        slug: "automotive-valves",
                        title: "Automotive Valves",
                        id: 11000079,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine",
                        path: "/automotive/auto-parts/automotive-exterior-products/vehicle-powertrain/automotive-engine/automotive-valves",
                        active: true,
                        meta: {
                          tags: "Automotive Valves",
                          keywords: "Automotive Valves",
                          description: "Automotive Valves",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2750",
                },
                slug: "vehicle-propulsion",
                title: "Vehicle Propulsion",
                id: 11000085,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/vehicle-propulsion",
                active: true,
                meta: {
                  tags: "Vehicle Propulsion",
                  keywords: "Vehicle Propulsion",
                  description: "Vehicle Propulsion",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2751",
                    },
                    slug: "traction-motor",
                    title: "Traction Motor",
                    id: 11000086,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/vehicle-propulsion",
                    path: "/automotive/auto-parts/automotive-exterior-products/vehicle-propulsion/traction-motor",
                    active: true,
                    meta: {
                      tags: "Traction Motor",
                      keywords: "Traction Motor",
                      description: "Traction Motor",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2737",
                },
                slug: "automotive-power-management-system",
                title: "Automotive Power Management System",
                id: 11000060,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-power-management-system",
                active: true,
                meta: {
                  tags: "Automotive Power Management System",
                  keywords: "Automotive Power Management System",
                  description: "Automotive Power Management System",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2736",
                },
                slug: "automotive-polymer-parts",
                title: "Automotive Polymer Parts",
                id: 11000059,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-polymer-parts",
                active: true,
                meta: {
                  tags: "Automotive Polymer Parts",
                  keywords: "Automotive Polymer Parts",
                  description: "Automotive Polymer Parts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2738",
                },
                slug: "automotive-steering-system",
                title: "Automotive Steering System",
                id: 11000061,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-steering-system",
                active: true,
                meta: {
                  tags: "Automotive Steering System",
                  keywords: "Automotive Steering System",
                  description: "Automotive Steering System",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2739",
                    },
                    slug: "power-steering",
                    title: "Power Steering",
                    id: 11000062,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-steering-system",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-steering-system/power-steering",
                    active: true,
                    meta: {
                      tags: "Power Steering",
                      keywords: "Power Steering",
                      description: "Power Steering",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca273a",
                        },
                        slug: "electro-hydraulic-power-steering",
                        title: "Electro-Hydraulic Power Steering",
                        id: 11000063,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-steering-system/power-steering",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-steering-system/power-steering/electro-hydraulic-power-steering",
                        active: true,
                        meta: {
                          tags: "Electro-Hydraulic Power Steering",
                          keywords: "Electro-Hydraulic Power Steering",
                          description: "Electro-Hydraulic Power Steering",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca273b",
                        },
                        slug: "steering-sensors",
                        title: "Steering Sensors",
                        id: 11000064,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-steering-system/power-steering",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-steering-system/power-steering/steering-sensors",
                        active: true,
                        meta: {
                          tags: "Steering Sensors",
                          keywords: "Steering Sensors",
                          description: "Steering Sensors",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca273c",
                },
                slug: "automotive-vision",
                title: "Automotive Vision",
                id: 11000065,
                parent: "/automotive/auto-parts/automotive-exterior-products",
                path: "/automotive/auto-parts/automotive-exterior-products/automotive-vision",
                active: true,
                meta: {
                  tags: "Automotive Vision",
                  keywords: "Automotive Vision",
                  description: "Automotive Vision",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca273d",
                    },
                    slug: "automotive-lighting",
                    title: "Automotive Lighting",
                    id: 11000066,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-vision",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-vision/automotive-lighting",
                    active: true,
                    meta: {
                      tags: "Automotive Lighting",
                      keywords: "Automotive Lighting",
                      description: "Automotive Lighting",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca273f",
                        },
                        slug: "off-highway-equipment-lighting",
                        title: "Off-highway Equipment Lighting",
                        id: 11000068,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-vision/automotive-lighting",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-vision/automotive-lighting/off-highway-equipment-lighting",
                        active: true,
                        meta: {
                          tags: "Off-highway Equipment Lighting",
                          keywords: "Off-highway Equipment Lighting",
                          description: "Off-highway Equipment Lighting",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca273e",
                        },
                        slug: "e-bike-lighting",
                        title: "E-Bike Lighting",
                        id: 11000067,
                        parent:
                          "/automotive/auto-parts/automotive-exterior-products/automotive-vision/automotive-lighting",
                        path: "/automotive/auto-parts/automotive-exterior-products/automotive-vision/automotive-lighting/e-bike-lighting",
                        active: true,
                        meta: {
                          tags: "E-Bike Lighting",
                          keywords: "E-Bike Lighting",
                          description: "E-Bike Lighting",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2740",
                    },
                    slug: "automotive-mirror",
                    title: "Automotive Mirror",
                    id: 11000069,
                    parent:
                      "/automotive/auto-parts/automotive-exterior-products/automotive-vision",
                    path: "/automotive/auto-parts/automotive-exterior-products/automotive-vision/automotive-mirror",
                    active: true,
                    meta: {
                      tags: "Automotive Mirror",
                      keywords: "Automotive Mirror",
                      description: "Automotive Mirror",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2760",
            },
            slug: "hydraulic-machinery-parts",
            title: "Hydraulic Machinery Parts",
            id: 11000101,
            parent: "/automotive/auto-parts",
            path: "/automotive/auto-parts/hydraulic-machinery-parts",
            active: true,
            meta: {
              tags: "Hydraulic Machinery Parts",
              keywords: "Hydraulic Machinery Parts",
              description: "Hydraulic Machinery Parts",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2761",
                },
                slug: "hydraulic-machinery-transmission-parts",
                title: "Hydraulic Machinery Transmission Parts",
                id: 11000102,
                parent: "/automotive/auto-parts/hydraulic-machinery-parts",
                path: "/automotive/auto-parts/hydraulic-machinery-parts/hydraulic-machinery-transmission-parts",
                active: true,
                meta: {
                  tags: "Hydraulic Machinery Transmission Parts",
                  keywords: "Hydraulic Machinery Transmission Parts",
                  description: "Hydraulic Machinery Transmission Parts",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca276b",
            },
            slug: "rail-parts",
            title: "Rail Parts",
            id: 11000112,
            parent: "/automotive/auto-parts",
            path: "/automotive/auto-parts/rail-parts",
            active: true,
            meta: {
              tags: "Rail Parts",
              keywords: "Rail Parts",
              description: "Rail Parts",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca276c",
                },
                slug: "rail-propulsion",
                title: "Rail Propulsion",
                id: 11000113,
                parent: "/automotive/auto-parts/rail-parts",
                path: "/automotive/auto-parts/rail-parts/rail-propulsion",
                active: true,
                meta: {
                  tags: "Rail Propulsion",
                  keywords: "Rail Propulsion",
                  description: "Rail Propulsion",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca276d",
                    },
                    slug: "rail-power-transmission",
                    title: "Rail Power Transmission",
                    id: 11000114,
                    parent: "/automotive/auto-parts/rail-parts/rail-propulsion",
                    path: "/automotive/auto-parts/rail-parts/rail-propulsion/rail-power-transmission",
                    active: true,
                    meta: {
                      tags: "Rail Power Transmission",
                      keywords: "Rail Power Transmission",
                      description: "Rail Power Transmission",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca276e",
                },
                slug: "railway-body-parts",
                title: "Railway Body Parts",
                id: 11000115,
                parent: "/automotive/auto-parts/rail-parts",
                path: "/automotive/auto-parts/rail-parts/railway-body-parts",
                active: true,
                meta: {
                  tags: "Railway Body Parts",
                  keywords: "Railway Body Parts",
                  description: "Railway Body Parts",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca276f",
                    },
                    slug: "rail-glass",
                    title: "Rail Glass",
                    id: 11000116,
                    parent:
                      "/automotive/auto-parts/rail-parts/railway-body-parts",
                    path: "/automotive/auto-parts/rail-parts/railway-body-parts/rail-glass",
                    active: true,
                    meta: {
                      tags: "Rail Glass",
                      keywords: "Rail Glass",
                      description: "Rail Glass",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2770",
                },
                slug: "railway-infrastructure",
                title: "Railway Infrastructure",
                id: 11000117,
                parent: "/automotive/auto-parts/rail-parts",
                path: "/automotive/auto-parts/rail-parts/railway-infrastructure",
                active: true,
                meta: {
                  tags: "Railway Infrastructure",
                  keywords: "Railway Infrastructure",
                  description: "Railway Infrastructure",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2771",
                },
                slug: "rolling-stock",
                title: "Rolling Stock",
                id: 11000118,
                parent: "/automotive/auto-parts/rail-parts",
                path: "/automotive/auto-parts/rail-parts/rolling-stock",
                active: true,
                meta: {
                  tags: "Rolling Stock",
                  keywords: "Rolling Stock",
                  description: "Rolling Stock",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2772",
                    },
                    slug: "rail-bearings",
                    title: "Rail Bearings",
                    id: 11000119,
                    parent: "/automotive/auto-parts/rail-parts/rolling-stock",
                    path: "/automotive/auto-parts/rail-parts/rolling-stock/rail-bearings",
                    active: true,
                    meta: {
                      tags: "Rail Bearings",
                      keywords: "Rail Bearings",
                      description: "Rail Bearings",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2773",
                    },
                    slug: "railway-hvac",
                    title: "Railway HVAC",
                    id: 11000120,
                    parent: "/automotive/auto-parts/rail-parts/rolling-stock",
                    path: "/automotive/auto-parts/rail-parts/rolling-stock/railway-hvac",
                    active: true,
                    meta: {
                      tags: "Railway HVAC",
                      keywords: "Railway HVAC",
                      description: "Railway HVAC",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2774",
                    },
                    slug: "railway-interior-products",
                    title: "Railway Interior Products",
                    id: 11000121,
                    parent: "/automotive/auto-parts/rail-parts/rolling-stock",
                    path: "/automotive/auto-parts/rail-parts/rolling-stock/railway-interior-products",
                    active: true,
                    meta: {
                      tags: "Railway Interior Products",
                      keywords: "Railway Interior Products",
                      description: "Railway Interior Products",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2775",
                        },
                        slug: "railway-interior-wires",
                        title: "Railway Interior Wires",
                        id: 11000122,
                        parent:
                          "/automotive/auto-parts/rail-parts/rolling-stock/railway-interior-products",
                        path: "/automotive/auto-parts/rail-parts/rolling-stock/railway-interior-products/railway-interior-wires",
                        active: true,
                        meta: {
                          tags: "Railway Interior Wires",
                          keywords: "Railway Interior Wires",
                          description: "Railway Interior Wires",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2776",
                        },
                        slug: "railway-seating",
                        title: "Railway Seating",
                        id: 11000123,
                        parent:
                          "/automotive/auto-parts/rail-parts/rolling-stock/railway-interior-products",
                        path: "/automotive/auto-parts/rail-parts/rolling-stock/railway-interior-products/railway-seating",
                        active: true,
                        meta: {
                          tags: "Railway Seating",
                          keywords: "Railway Seating",
                          description: "Railway Seating",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca26fd",
            },
            slug: "automotive-accessories",
            title: "Automotive Accessories",
            id: 11000002,
            parent: "/automotive/auto-parts",
            path: "/automotive/auto-parts/automotive-accessories",
            active: true,
            meta: {
              tags: "Automotive Accessories",
              keywords: "Automotive Accessories",
              description: "Automotive Accessories",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca26fe",
                },
                slug: "driver-wearbles",
                title: "Driver Wearbles",
                id: 11000003,
                parent: "/automotive/auto-parts/automotive-accessories",
                path: "/automotive/auto-parts/automotive-accessories/driver-wearbles",
                active: true,
                meta: {
                  tags: "Driver Wearbles",
                  keywords: "Driver Wearbles",
                  description: "Driver Wearbles",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca275f",
            },
            slug: "automotive-mechanical-components",
            title: "Automotive Mechanical Components",
            id: 11000100,
            parent: "/automotive/auto-parts",
            path: "/automotive/auto-parts/automotive-mechanical-components",
            active: true,
            meta: {
              tags: "Automotive Mechanical Components",
              keywords: "Automotive Mechanical Components",
              description: "Automotive Mechanical Components",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9fcced7de0399bca27ee",
        },
        slug: "heavy-equipment",
        title: "Heavy Equipment",
        id: 65664,
        parent: "/automotive",
        path: "/automotive/heavy-equipment",
        active: true,
        meta: {
          tags: "Heavy Equipment",
          keywords: "Heavy Equipment",
          description: "Heavy Equipment",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27fc",
            },
            slug: "metal-and-alloy-casting-machinery",
            title: "Metal and Alloy Casting Machinery",
            id: 11000260,
            parent: "/automotive/heavy-equipment",
            path: "/automotive/heavy-equipment/metal-and-alloy-casting-machinery",
            active: true,
            meta: {
              tags: "Metal and Alloy Casting Machinery",
              keywords: "Metal and Alloy Casting Machinery",
              description: "Metal and Alloy Casting Machinery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27fd",
            },
            slug: "mining-equipment",
            title: "Mining Equipment",
            id: 11000261,
            parent: "/automotive/heavy-equipment",
            path: "/automotive/heavy-equipment/mining-equipment",
            active: true,
            meta: {
              tags: "Mining Equipment",
              keywords: "Mining Equipment",
              description: "Mining Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27fe",
                },
                slug: "ore-dressing-equipment",
                title: "Ore Dressing Equipment",
                id: 11000262,
                parent: "/automotive/heavy-equipment/mining-equipment",
                path: "/automotive/heavy-equipment/mining-equipment/ore-dressing-equipment",
                active: true,
                meta: {
                  tags: "Ore Dressing Equipment",
                  keywords: "Ore Dressing Equipment",
                  description: "Ore Dressing Equipment",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27ef",
            },
            slug: "construction-equipment",
            title: "Construction Equipment",
            id: 11000247,
            parent: "/automotive/heavy-equipment",
            path: "/automotive/heavy-equipment/construction-equipment",
            active: true,
            meta: {
              tags: "Construction Equipment",
              keywords: "Construction Equipment",
              description: "Construction Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27f0",
                },
                slug: "off-highway-equipment",
                title: "Off-highway Equipment",
                id: 11000248,
                parent: "/automotive/heavy-equipment/construction-equipment",
                path: "/automotive/heavy-equipment/construction-equipment/off-highway-equipment",
                active: true,
                meta: {
                  tags: "Off-highway Equipment",
                  keywords: "Off-highway Equipment",
                  description: "Off-highway Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27f2",
                    },
                    slug: "demolition-equipment",
                    title: "Demolition Equipment",
                    id: 11000250,
                    parent:
                      "/automotive/heavy-equipment/construction-equipment/off-highway-equipment",
                    path: "/automotive/heavy-equipment/construction-equipment/off-highway-equipment/demolition-equipment",
                    active: true,
                    meta: {
                      tags: "Demolition Equipment",
                      keywords: "Demolition Equipment",
                      description: "Demolition Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27f1",
                    },
                    slug: "asphalt-paving-equipment",
                    title: "Asphalt Paving Equipment",
                    id: 11000249,
                    parent:
                      "/automotive/heavy-equipment/construction-equipment/off-highway-equipment",
                    path: "/automotive/heavy-equipment/construction-equipment/off-highway-equipment/asphalt-paving-equipment",
                    active: true,
                    meta: {
                      tags: "Asphalt Paving Equipment",
                      keywords: "Asphalt Paving Equipment",
                      description: "Asphalt Paving Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27f3",
                    },
                    slug: "earth-moving-equipment",
                    title: "Earth Moving Equipment",
                    id: 11000251,
                    parent:
                      "/automotive/heavy-equipment/construction-equipment/off-highway-equipment",
                    path: "/automotive/heavy-equipment/construction-equipment/off-highway-equipment/earth-moving-equipment",
                    active: true,
                    meta: {
                      tags: "Earth Moving Equipment",
                      keywords: "Earth Moving Equipment",
                      description: "Earth Moving Equipment",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27f4",
                    },
                    slug: "road-construction-equipment",
                    title: "Road Construction Equipment",
                    id: 11000252,
                    parent:
                      "/automotive/heavy-equipment/construction-equipment/off-highway-equipment",
                    path: "/automotive/heavy-equipment/construction-equipment/off-highway-equipment/road-construction-equipment",
                    active: true,
                    meta: {
                      tags: "Road Construction Equipment",
                      keywords: "Road Construction Equipment",
                      description: "Road Construction Equipment",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27f5",
                },
                slug: "pre-owned-construction-equipment",
                title: "Pre-owned Construction Equipment",
                id: 11000253,
                parent: "/automotive/heavy-equipment/construction-equipment",
                path: "/automotive/heavy-equipment/construction-equipment/pre-owned-construction-equipment",
                active: true,
                meta: {
                  tags: "Pre-owned Construction Equipment",
                  keywords: "Pre-owned Construction Equipment",
                  description: "Pre-owned Construction Equipment",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca27f6",
            },
            slug: "material-handling-equipment",
            title: "Material Handling Equipment",
            id: 11000254,
            parent: "/automotive/heavy-equipment",
            path: "/automotive/heavy-equipment/material-handling-equipment",
            active: true,
            meta: {
              tags: "Material Handling Equipment",
              keywords: "Material Handling Equipment",
              description: "Material Handling Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27f8",
                },
                slug: "container-handling-equipment",
                title: "Container Handling Equipment",
                id: 11000256,
                parent:
                  "/automotive/heavy-equipment/material-handling-equipment",
                path: "/automotive/heavy-equipment/material-handling-equipment/container-handling-equipment",
                active: true,
                meta: {
                  tags: "Container Handling Equipment",
                  keywords: "Container Handling Equipment",
                  description: "Container Handling Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27fb",
                },
                slug: "offshore-material-handling-equipment",
                title: "Offshore Material Handling Equipment",
                id: 11000259,
                parent:
                  "/automotive/heavy-equipment/material-handling-equipment",
                path: "/automotive/heavy-equipment/material-handling-equipment/offshore-material-handling-equipment",
                active: true,
                meta: {
                  tags: "Offshore Material Handling Equipment",
                  keywords: "Offshore Material Handling Equipment",
                  description: "Offshore Material Handling Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27f7",
                },
                slug: "cargo-handling-equipment",
                title: "Cargo Handling Equipment",
                id: 11000255,
                parent:
                  "/automotive/heavy-equipment/material-handling-equipment",
                path: "/automotive/heavy-equipment/material-handling-equipment/cargo-handling-equipment",
                active: true,
                meta: {
                  tags: "Cargo Handling Equipment",
                  keywords: "Cargo Handling Equipment",
                  description: "Cargo Handling Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca27f9",
                },
                slug: "lifting-equipment",
                title: "Lifting Equipment",
                id: 11000257,
                parent:
                  "/automotive/heavy-equipment/material-handling-equipment",
                path: "/automotive/heavy-equipment/material-handling-equipment/lifting-equipment",
                active: true,
                meta: {
                  tags: "Lifting Equipment",
                  keywords: "Lifting Equipment",
                  description: "Lifting Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca27fa",
                    },
                    slug: "mobile-lifting-equipment",
                    title: "Mobile Lifting Equipment",
                    id: 11000258,
                    parent:
                      "/automotive/heavy-equipment/material-handling-equipment/lifting-equipment",
                    path: "/automotive/heavy-equipment/material-handling-equipment/lifting-equipment/mobile-lifting-equipment",
                    active: true,
                    meta: {
                      tags: "Mobile Lifting Equipment",
                      keywords: "Mobile Lifting Equipment",
                      description: "Mobile Lifting Equipment",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9fcced7de0399bca27ff",
        },
        slug: "maritime",
        title: "Maritime",
        id: 65665,
        parent: "/automotive",
        path: "/automotive/maritime",
        active: true,
        meta: {
          tags: "Maritime",
          keywords: "Maritime",
          description: "Maritime",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2803",
            },
            slug: "unmanned-marine-vessel",
            title: "Unmanned Marine Vessel",
            id: 11000267,
            parent: "/automotive/maritime",
            path: "/automotive/maritime/unmanned-marine-vessel",
            active: true,
            meta: {
              tags: "Unmanned Marine Vessel",
              keywords: "Unmanned Marine Vessel",
              description: "Unmanned Marine Vessel",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2800",
            },
            slug: "marine-parts",
            title: "Marine Parts",
            id: 11000264,
            parent: "/automotive/maritime",
            path: "/automotive/maritime/marine-parts",
            active: true,
            meta: {
              tags: "Marine Parts",
              keywords: "Marine Parts",
              description: "Marine Parts",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2801",
                },
                slug: "marine-battery",
                title: "Marine Battery",
                id: 11000265,
                parent: "/automotive/maritime/marine-parts",
                path: "/automotive/maritime/marine-parts/marine-battery",
                active: true,
                meta: {
                  tags: "Marine Battery",
                  keywords: "Marine Battery",
                  description: "Marine Battery",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2802",
                },
                slug: "marine-propeller",
                title: "Marine Propeller",
                id: 11000266,
                parent: "/automotive/maritime/marine-parts",
                path: "/automotive/maritime/marine-parts/marine-propeller",
                active: true,
                meta: {
                  tags: "Marine Propeller",
                  keywords: "Marine Propeller",
                  description: "Marine Propeller",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9fcced7de0399bca278d",
        },
        slug: "automotive-processes",
        title: "Automotive Processes",
        id: 65663,
        parent: "/automotive",
        path: "/automotive/automotive-processes",
        active: true,
        meta: {
          tags: "Automotive Processes",
          keywords: "Automotive Processes",
          description: "Automotive Processes",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2792",
            },
            slug: "casting",
            title: "Casting",
            id: 11000152,
            parent: "/automotive/automotive-processes",
            path: "/automotive/automotive-processes/casting",
            active: true,
            meta: {
              tags: "Casting",
              keywords: "Casting",
              description: "Casting",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2794",
                },
                slug: "die-casting",
                title: "Die Casting",
                id: 11000154,
                parent: "/automotive/automotive-processes/casting",
                path: "/automotive/automotive-processes/casting/die-casting",
                active: true,
                meta: {
                  tags: "Die Casting",
                  keywords: "Die Casting",
                  description: "Die Casting",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ba9fcced7de0399bca2795",
                    },
                    slug: "automotive-die-casting",
                    title: "Automotive Die Casting",
                    id: 11000155,
                    parent:
                      "/automotive/automotive-processes/casting/die-casting",
                    path: "/automotive/automotive-processes/casting/die-casting/automotive-die-casting",
                    active: true,
                    meta: {
                      tags: "Automotive Die Casting",
                      keywords: "Automotive Die Casting",
                      description: "Automotive Die Casting",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ba9fcced7de0399bca2796",
                        },
                        slug: "non-ferrous-die-casting",
                        title: "Non-Ferrous Die Casting",
                        id: 11000156,
                        parent:
                          "/automotive/automotive-processes/casting/die-casting/automotive-die-casting",
                        path: "/automotive/automotive-processes/casting/die-casting/automotive-die-casting/non-ferrous-die-casting",
                        active: true,
                        meta: {
                          tags: "Non-Ferrous Die Casting",
                          keywords: "Non-Ferrous Die Casting",
                          description: "Non-Ferrous Die Casting",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ba9fcced7de0399bca2797",
                            },
                            slug: "gravity-casting",
                            title: "Gravity Casting",
                            id: 11000157,
                            parent:
                              "/automotive/automotive-processes/casting/die-casting/automotive-die-casting/non-ferrous-die-casting",
                            path: "/automotive/automotive-processes/casting/die-casting/automotive-die-casting/non-ferrous-die-casting/gravity-casting",
                            active: true,
                            meta: {
                              tags: "Gravity Casting",
                              keywords: "Gravity Casting",
                              description: "Gravity Casting",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ba9fcced7de0399bca2798",
                            },
                            slug: "pressure-die-casting",
                            title: "Pressure Die Casting",
                            id: 11000158,
                            parent:
                              "/automotive/automotive-processes/casting/die-casting/automotive-die-casting/non-ferrous-die-casting",
                            path: "/automotive/automotive-processes/casting/die-casting/automotive-die-casting/non-ferrous-die-casting/pressure-die-casting",
                            active: true,
                            meta: {
                              tags: "Pressure Die Casting",
                              keywords: "Pressure Die Casting",
                              description: "Pressure Die Casting",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2799",
                },
                slug: "lost-wax-casting",
                title: "Lost Wax Casting",
                id: 11000159,
                parent: "/automotive/automotive-processes/casting",
                path: "/automotive/automotive-processes/casting/lost-wax-casting",
                active: true,
                meta: {
                  tags: "Lost Wax Casting",
                  keywords: "Lost Wax Casting",
                  description: "Lost Wax Casting",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2793",
                },
                slug: "automotive-die-casted-parts",
                title: "Automotive Die Casted Parts",
                id: 11000153,
                parent: "/automotive/automotive-processes/casting",
                path: "/automotive/automotive-processes/casting/automotive-die-casted-parts",
                active: true,
                meta: {
                  tags: "Automotive Die Casted Parts",
                  keywords: "Automotive Die Casted Parts",
                  description: "Automotive Die Casted Parts",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca278f",
            },
            slug: "automotive-metal-forming",
            title: "Automotive Metal Forming",
            id: 11000149,
            parent: "/automotive/automotive-processes",
            path: "/automotive/automotive-processes/automotive-metal-forming",
            active: true,
            meta: {
              tags: "Automotive Metal Forming",
              keywords: "Automotive Metal Forming",
              description: "Automotive Metal Forming",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ba9fcced7de0399bca2790",
                },
                slug: "automotive-extrusion",
                title: "Automotive Extrusion",
                id: 11000150,
                parent:
                  "/automotive/automotive-processes/automotive-metal-forming",
                path: "/automotive/automotive-processes/automotive-metal-forming/automotive-extrusion",
                active: true,
                meta: {
                  tags: "Automotive Extrusion",
                  keywords: "Automotive Extrusion",
                  description: "Automotive Extrusion",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2791",
            },
            slug: "automotive-metal-working",
            title: "Automotive Metal Working",
            id: 11000151,
            parent: "/automotive/automotive-processes",
            path: "/automotive/automotive-processes/automotive-metal-working",
            active: true,
            meta: {
              tags: "Automotive Metal Working",
              keywords: "Automotive Metal Working",
              description: "Automotive Metal Working",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca278e",
            },
            slug: "automotive-additive-manufacturing",
            title: "Automotive Additive Manufacturing",
            id: 11000148,
            parent: "/automotive/automotive-processes",
            path: "/automotive/automotive-processes/automotive-additive-manufacturing",
            active: true,
            meta: {
              tags: "Automotive Additive Manufacturing",
              keywords: "Automotive Additive Manufacturing",
              description: "Automotive Additive Manufacturing",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ba9fcced7de0399bca2804",
        },
        slug: "sea-port-infrastructure",
        title: "Sea Port Infrastructure",
        id: 65666,
        parent: "/automotive",
        path: "/automotive/sea-port-infrastructure",
        active: true,
        meta: {
          tags: "Sea Port Infrastructure",
          keywords: "Sea Port Infrastructure",
          description: "Sea Port Infrastructure",
        },
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64ba9fcced7de0399bca2777",
        },
        slug: "automotive-equipment",
        title: "Automotive Equipment",
        id: 65662,
        parent: "/automotive",
        path: "/automotive/automotive-equipment",
        active: true,
        meta: {
          tags: "Automotive Equipment",
          keywords: "Automotive Equipment",
          description: "Automotive Equipment",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2779",
            },
            slug: "ev-diagnostic-equipment",
            title: "EV Diagnostic Equipment",
            id: 11000126,
            parent: "/automotive/automotive-equipment",
            path: "/automotive/automotive-equipment/ev-diagnostic-equipment",
            active: true,
            meta: {
              tags: "EV Diagnostic Equipment",
              keywords: "EV Diagnostic Equipment",
              description: "EV Diagnostic Equipment",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca277a",
            },
            slug: "vehicle-service-station-equipment",
            title: "Vehicle Service Station Equipment",
            id: 11000127,
            parent: "/automotive/automotive-equipment",
            path: "/automotive/automotive-equipment/vehicle-service-station-equipment",
            active: true,
            meta: {
              tags: "Vehicle Service Station Equipment",
              keywords: "Vehicle Service Station Equipment",
              description: "Vehicle Service Station Equipment",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ba9fcced7de0399bca2778",
            },
            slug: "autonomous-garden-equipment",
            title: "Autonomous Garden Equipment",
            id: 11000125,
            parent: "/automotive/automotive-equipment",
            path: "/automotive/automotive-equipment/autonomous-garden-equipment",
            active: true,
            meta: {
              tags: "Autonomous Garden Equipment",
              keywords: "Autonomous Garden Equipment",
              description: "Autonomous Garden Equipment",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b133",
    },
    id: 70,
    title: "Animal Nutrition & Wellness",
    slug: "animal-nutrition",
    active: true,
    meta: {
      tags: "Animal Nutrition & Wellness Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "animal nutrition market intelligence, animal health reports, animal health industry, animal nutrition industry, animal nutrition, animal feed industry, feed market, animal health market, animal feed market, animal feed additives market, animal food industry, compound feed market, animal health and nutrition, animal nutrition and biotechnology, global animal health market, animal nutrition industry, global compound feed market, animal feed market report, animal feed antioxidants market, ",
      description:
        "The reports under Animal Nutrition cover B2B and B2C sides of the market and encompass all forms of nutrition provided to farm animals (ruminants, poultry, swine and aquaculture) and to pets (dogs, cats, birds, etc.)",
    },
    description: {
      short_description:
        "Animal Health and Wellness plays a unique role in expanding economic opportunity,  as it's the most powerful trend across all segments of the industry and will continue to do so.",
      full_description:
        "Animal Health and wellness plays a unique role in expanding economic opportunity,  as it's the most powerful trend across all segments of the industry and will continue to do so.",
    },
    picture: null,
    __v: 0,
    picture_url: "advanced-tech.png",
    cat_email: "afnb@mordorintelligence.com",
    cat_pw_id: 164016,
    parent: "/",
    path: "/animal-nutrition",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1bc",
        },
        id: 7062,
        title: "Compound Feed",
        slug: "compound-feed",
        active: true,
        meta: {
          description:
            "Compound Feed Industry coverage includes reports on ruminant feed, poultry feed, equine feed, current industry trends, market sizes, company shares, investment analysis, and forecasts.",
          keywords:
            "Compound Feed Market Research Reports, compound feed market, compound feed market report, compound feed market research, compound feed industry, compound feed industry report, compound feed industry research, compound feed market research reports",
          tags: "Compound Feed Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "The compound feed segment covers 70+ reports on different animal feed varieties like that of Ruminant Feed, Poultry Feed, Swine Feed, Aqua Feed. We also analyze recent developments, market needs and trends, future opportunities and offer market insights in more than 20 countries across 5 regions.",
          short_description:
            "The compound feed segment covers 70+ reports on different animal feed varieties like that Ruminant Feed, Poultry Feed, Swine Feed, and Aqua Feed. We also analyze recent developments, market needs and trends, and future opportunities and offer market insights in more than 20 countries across 5 regions.",
        },
        picture: null,
        __v: 0,
        parent: "/animal-nutrition",
        path: "/animal-nutrition/compound-feed",
        sub_categories: [
          {
            _id: {
              $oid: "64ad97871e7906988682cb4e",
            },
            slug: "aquaculture-feed",
            title: "Aquaculture Feed",
            id: 4000009,
            parent: "/animal-nutrition/compound-feed",
            path: "/animal-nutrition/compound-feed/aquaculture-feed",
            active: true,
            meta: {
              tags: "Aquaculture Feed",
              keywords: "Aquaculture Feed",
              description: "Aquaculture Feed",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb4f",
            },
            slug: "forage-feed",
            title: "Forage Feed",
            id: 4000010,
            parent: "/animal-nutrition/compound-feed",
            path: "/animal-nutrition/compound-feed/forage-feed",
            active: true,
            meta: {
              tags: "Forage Feed",
              keywords: "Forage Feed",
              description: "Forage Feed",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb50",
            },
            slug: "gluten-feed",
            title: "Gluten Feed",
            id: 4000011,
            parent: "/animal-nutrition/compound-feed",
            path: "/animal-nutrition/compound-feed/gluten-feed",
            active: true,
            meta: {
              tags: "Gluten Feed",
              keywords: "Gluten Feed",
              description: "Gluten Feed",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb51",
            },
            slug: "insect-feed",
            title: "Insect Feed",
            id: 4000012,
            parent: "/animal-nutrition/compound-feed",
            path: "/animal-nutrition/compound-feed/insect-feed",
            active: true,
            meta: {
              tags: "Insect Feed",
              keywords: "Insect Feed",
              description: "Insect Feed",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb52",
            },
            slug: "liquid-feed",
            title: "Liquid Feed",
            id: 4000013,
            parent: "/animal-nutrition/compound-feed",
            path: "/animal-nutrition/compound-feed/liquid-feed",
            active: true,
            meta: {
              tags: "Liquid Feed",
              keywords: "Liquid Feed",
              description: "Liquid Feed",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb53",
            },
            slug: "poultry-feed",
            title: "Poultry Feed",
            id: 4000014,
            parent: "/animal-nutrition/compound-feed",
            path: "/animal-nutrition/compound-feed/poultry-feed",
            active: true,
            meta: {
              tags: "Poultry Feed",
              keywords: "Poultry Feed",
              description: "Poultry Feed",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97871e7906988682cb54",
                },
                slug: "backyard-poultry-feed",
                title: "Backyard Poultry Feed",
                id: 4000015,
                parent: "/animal-nutrition/compound-feed/poultry-feed",
                path: "/animal-nutrition/compound-feed/poultry-feed/backyard-poultry-feed",
                active: true,
                meta: {
                  tags: "Backyard Poultry Feed",
                  keywords: "Backyard Poultry Feed",
                  description: "Backyard Poultry Feed",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb55",
            },
            slug: "ruminant-feed",
            title: "Ruminant Feed",
            id: 4000016,
            parent: "/animal-nutrition/compound-feed",
            path: "/animal-nutrition/compound-feed/ruminant-feed",
            active: true,
            meta: {
              tags: "Ruminant Feed",
              keywords: "Ruminant Feed",
              description: "Ruminant Feed",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97871e7906988682cb56",
                },
                slug: "cattle-feed",
                title: "Cattle Feed",
                id: 4000017,
                parent: "/animal-nutrition/compound-feed/ruminant-feed",
                path: "/animal-nutrition/compound-feed/ruminant-feed/cattle-feed",
                active: true,
                meta: {
                  tags: "Cattle Feed",
                  keywords: "Cattle Feed",
                  description: "Cattle Feed",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb57",
            },
            slug: "swine-feed",
            title: "Swine Feed",
            id: 4000018,
            parent: "/animal-nutrition/compound-feed",
            path: "/animal-nutrition/compound-feed/swine-feed",
            active: true,
            meta: {
              tags: "Swine Feed",
              keywords: "Swine Feed",
              description: "Swine Feed",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1be",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Feed Additive market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Feed Additive Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Feed Additive market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Feed Additive Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Feed Additive Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Feed Additive Market Research Reports, feed additive market, feed additive market research report, feed additive industry research report, feed additive market intelligence report.",
          description:
            "Mordor Intelligence™ 【  Feed Additive Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Feed Additive",
        slug: "feed-additive",
        id: 70161,
        __v: 0,
        parent: "/animal-nutrition",
        path: "/animal-nutrition/feed-additive",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1bf",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Other Animal Nutrition & Wellness Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Animal Nutrition & Wellness Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Other Animal Nutrition & Wellness Research market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Other Animal Nutrition & Wellness Research Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Other Animal Nutrition Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Animal Nutrition & Wellness Market Research Reports, animal nutrition, animal nutrition market growth, animal nutrition market analysis, animal nutrition industry analysis, animal nutrition market report, animal nutrition industry report, animal nutrition industry analysis, animal nutrition industry forecast, animal nutrition industry, animal nutrition market",
          description:
            "Mordor Intelligence™ 【  Other Animal Nutrition Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Other Animal Nutrition & Wellness Research",
        slug: "animal-nutrition-others",
        id: 70165,
        __v: 0,
        parent: "/animal-nutrition",
        path: "/animal-nutrition/animal-nutrition-others",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64ad97871e7906988682cb47",
        },
        slug: "animal-farm-services",
        title: "Animal Farm Services",
        id: 70613,
        parent: "/animal-nutrition",
        path: "/animal-nutrition/animal-farm-services",
        active: true,
        meta: {
          tags: "Animal Farm Services",
          keywords: "Animal Farm Services",
          description: "Animal Farm Services",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad97871e7906988682cb48",
            },
            slug: "animal-husbandry",
            title: "Animal Husbandry",
            id: 4000002,
            parent: "/animal-nutrition/animal-farm-services",
            path: "/animal-nutrition/animal-farm-services/animal-husbandry",
            active: true,
            meta: {
              tags: "Animal Husbandry",
              keywords: "Animal Husbandry",
              description: "Animal Husbandry",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97871e7906988682cb49",
                },
                slug: "livestock-monitoring",
                title: "Livestock Monitoring",
                id: 4000003,
                parent:
                  "/animal-nutrition/animal-farm-services/animal-husbandry",
                path: "/animal-nutrition/animal-farm-services/animal-husbandry/livestock-monitoring",
                active: true,
                meta: {
                  tags: "Livestock Monitoring",
                  keywords: "Livestock Monitoring",
                  description: "Livestock Monitoring",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb4a",
                },
                slug: "poultry-farming",
                title: "Poultry Farming",
                id: 4000004,
                parent:
                  "/animal-nutrition/animal-farm-services/animal-husbandry",
                path: "/animal-nutrition/animal-farm-services/animal-husbandry/poultry-farming",
                active: true,
                meta: {
                  tags: "Poultry Farming",
                  keywords: "Poultry Farming",
                  description: "Poultry Farming",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97871e7906988682cb4b",
                    },
                    slug: "automated-poultry-farming",
                    title: "Automated Poultry Farming",
                    id: 4000005,
                    parent:
                      "/animal-nutrition/animal-farm-services/animal-husbandry/poultry-farming",
                    path: "/animal-nutrition/animal-farm-services/animal-husbandry/poultry-farming/automated-poultry-farming",
                    active: true,
                    meta: {
                      tags: "Automated Poultry Farming",
                      keywords: "Automated Poultry Farming",
                      description: "Automated Poultry Farming",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb4c",
            },
            slug: "feed-processing-machinery",
            title: "Feed Processing Machinery",
            id: 4000006,
            parent: "/animal-nutrition/animal-farm-services",
            path: "/animal-nutrition/animal-farm-services/feed-processing-machinery",
            active: true,
            meta: {
              tags: "Feed Processing Machinery",
              keywords: "Feed Processing Machinery",
              description: "Feed Processing Machinery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb4d",
            },
            slug: "feed-software",
            title: "Feed Software",
            id: 4000007,
            parent: "/animal-nutrition/animal-farm-services",
            path: "/animal-nutrition/animal-farm-services/feed-software",
            active: true,
            meta: {
              tags: "Feed Software",
              keywords: "Feed Software",
              description: "Feed Software",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ad97871e7906988682cb58",
        },
        slug: "feed-additives",
        title: "Feed Additives",
        id: 70611,
        parent: "/animal-nutrition",
        path: "/animal-nutrition/feed-additives",
        active: true,
        meta: {
          tags: "Feed Additives",
          keywords: "Feed Additives",
          description: "Feed Additives",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad97871e7906988682cb59",
            },
            slug: "aquafeed-additives",
            title: "Aquafeed Additives",
            id: 4000020,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/aquafeed-additives",
            active: true,
            meta: {
              tags: "Aquafeed Additives",
              keywords: "Aquafeed Additives",
              description: "Aquafeed Additives",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb5a",
            },
            slug: "beta-agnostic-feed-additives",
            title: "Beta-Agnostic Feed Additives",
            id: 4000021,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/beta-agnostic-feed-additives",
            active: true,
            meta: {
              tags: "Beta-Agnostic Feed Additives",
              keywords: "Beta-Agnostic Feed Additives",
              description: "Beta-Agnostic Feed Additives",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb5b",
            },
            slug: "ddgs-feed",
            title: "DDGS Feed",
            id: 4000022,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/ddgs-feed",
            active: true,
            meta: {
              tags: "DDGS Feed",
              keywords: "DDGS Feed",
              description: "DDGS Feed",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb5c",
            },
            slug: "eubiotics",
            title: "Eubiotics",
            id: 4000023,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/eubiotics",
            active: true,
            meta: {
              tags: "Eubiotics",
              keywords: "Eubiotics",
              description: "Eubiotics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb5d",
            },
            slug: "feed-acidifiers",
            title: "Feed Acidifiers",
            id: 4000024,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-acidifiers",
            active: true,
            meta: {
              tags: "Feed Acidifiers",
              keywords: "Feed Acidifiers",
              description: "Feed Acidifiers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb5e",
            },
            slug: "feed-amino-acids",
            title: "Feed Amino Acids",
            id: 4000025,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-amino-acids",
            active: true,
            meta: {
              tags: "Feed Amino Acids",
              keywords: "Feed Amino Acids",
              description: "Feed Amino Acids",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb5f",
            },
            slug: "feed-anti-caking-agents",
            title: "Feed Anti Caking Agents",
            id: 4000026,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-anti-caking-agents",
            active: true,
            meta: {
              tags: "Feed Anti Caking Agents",
              keywords: "Feed Anti Caking Agents",
              description: "Feed Anti Caking Agents",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb60",
            },
            slug: "feed-antibiotics",
            title: "Feed Antibiotics",
            id: 4000027,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-antibiotics",
            active: true,
            meta: {
              tags: "Feed Antibiotics",
              keywords: "Feed Antibiotics",
              description: "Feed Antibiotics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb61",
            },
            slug: "feed-antioxidants",
            title: "Feed Antioxidants",
            id: 4000028,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-antioxidants",
            active: true,
            meta: {
              tags: "Feed Antioxidants",
              keywords: "Feed Antioxidants",
              description: "Feed Antioxidants",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb62",
            },
            slug: "feed-binders",
            title: "Feed Binders",
            id: 4000029,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-binders",
            active: true,
            meta: {
              tags: "Feed Binders",
              keywords: "Feed Binders",
              description: "Feed Binders",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb63",
            },
            slug: "feed-enzymes",
            title: "Feed Enzymes",
            id: 4000030,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-enzymes",
            active: true,
            meta: {
              tags: "Feed Enzymes",
              keywords: "Feed Enzymes",
              description: "Feed Enzymes",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb64",
            },
            slug: "feed-flavors-and-sweeteners",
            title: "Feed Flavors & Sweeteners",
            id: 4000031,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-flavors-and-sweeteners",
            active: true,
            meta: {
              tags: "Feed Flavors & Sweeteners",
              keywords: "Feed Flavors & Sweeteners",
              description: "Feed Flavors & Sweeteners",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb65",
            },
            slug: "feed-minerals",
            title: "Feed Minerals",
            id: 4000032,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-minerals",
            active: true,
            meta: {
              tags: "Feed Minerals",
              keywords: "Feed Minerals",
              description: "Feed Minerals",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97871e7906988682cb66",
                },
                slug: "feed-macrominerals",
                title: "Feed Macrominerals",
                id: 4000033,
                parent: "/animal-nutrition/feed-additives/feed-minerals",
                path: "/animal-nutrition/feed-additives/feed-minerals/feed-macrominerals",
                active: true,
                meta: {
                  tags: "Feed Macrominerals",
                  keywords: "Feed Macrominerals",
                  description: "Feed Macrominerals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97871e7906988682cb67",
                    },
                    slug: "feed-phosphate",
                    title: "Feed Phosphate",
                    id: 4000034,
                    parent:
                      "/animal-nutrition/feed-additives/feed-minerals/feed-macrominerals",
                    path: "/animal-nutrition/feed-additives/feed-minerals/feed-macrominerals/feed-phosphate",
                    active: true,
                    meta: {
                      tags: "Feed Phosphate",
                      keywords: "Feed Phosphate",
                      description: "Feed Phosphate",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb68",
                },
                slug: "feed-microminerals",
                title: "Feed Microminerals",
                id: 4000035,
                parent: "/animal-nutrition/feed-additives/feed-minerals",
                path: "/animal-nutrition/feed-additives/feed-minerals/feed-microminerals",
                active: true,
                meta: {
                  tags: "Feed Microminerals",
                  keywords: "Feed Microminerals",
                  description: "Feed Microminerals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ad97871e7906988682cb69",
                    },
                    slug: "feed-trace-minerals",
                    title: "Feed Trace Minerals",
                    id: 4000036,
                    parent:
                      "/animal-nutrition/feed-additives/feed-minerals/feed-microminerals",
                    path: "/animal-nutrition/feed-additives/feed-minerals/feed-microminerals/feed-trace-minerals",
                    active: true,
                    meta: {
                      tags: "Feed Trace Minerals",
                      keywords: "Feed Trace Minerals",
                      description: "Feed Trace Minerals",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ad97871e7906988682cb6a",
                        },
                        slug: "organic-feed-trace-minerals",
                        title: "Organic Feed Trace Minerals",
                        id: 4000037,
                        parent:
                          "/animal-nutrition/feed-additives/feed-minerals/feed-microminerals/feed-trace-minerals",
                        path: "/animal-nutrition/feed-additives/feed-minerals/feed-microminerals/feed-trace-minerals/organic-feed-trace-minerals",
                        active: true,
                        meta: {
                          tags: "Organic Feed Trace Minerals",
                          keywords: "Organic Feed Trace Minerals",
                          description: "Organic Feed Trace Minerals",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb6b",
            },
            slug: "feed-mycotoxin-detoxifiers",
            title: "Feed Mycotoxin Detoxifiers",
            id: 4000038,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-mycotoxin-detoxifiers",
            active: true,
            meta: {
              tags: "Feed Mycotoxin Detoxifiers",
              keywords: "Feed Mycotoxin Detoxifiers",
              description: "Feed Mycotoxin Detoxifiers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb6c",
            },
            slug: "feed-palatability-enhancers",
            title: "Feed Palatability Enhancers",
            id: 4000039,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-palatability-enhancers",
            active: true,
            meta: {
              tags: "Feed Palatability Enhancers",
              keywords: "Feed Palatability Enhancers",
              description: "Feed Palatability Enhancers",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb6d",
            },
            slug: "feed-pelletizing-agents",
            title: "Feed Pelletizing Agents",
            id: 4000040,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-pelletizing-agents",
            active: true,
            meta: {
              tags: "Feed Pelletizing Agents",
              keywords: "Feed Pelletizing Agents",
              description: "Feed Pelletizing Agents",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb6e",
            },
            slug: "feed-phytogenic",
            title: "Feed Phytogenic",
            id: 4000041,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-phytogenic",
            active: true,
            meta: {
              tags: "Feed Phytogenic",
              keywords: "Feed Phytogenic",
              description: "Feed Phytogenic",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb6f",
            },
            slug: "feed-pigments",
            title: "Feed Pigments",
            id: 4000042,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-pigments",
            active: true,
            meta: {
              tags: "Feed Pigments",
              keywords: "Feed Pigments",
              description: "Feed Pigments",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97871e7906988682cb70",
                },
                slug: "feed-carotenoids",
                title: "Feed Carotenoids",
                id: 4000043,
                parent: "/animal-nutrition/feed-additives/feed-pigments",
                path: "/animal-nutrition/feed-additives/feed-pigments/feed-carotenoids",
                active: true,
                meta: {
                  tags: "Feed Carotenoids",
                  keywords: "Feed Carotenoids",
                  description: "Feed Carotenoids",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb71",
                },
                slug: "lutein-and-zeaxanthin",
                title: "Lutein and Zeaxanthin",
                id: 4000044,
                parent: "/animal-nutrition/feed-additives/feed-pigments",
                path: "/animal-nutrition/feed-additives/feed-pigments/lutein-and-zeaxanthin",
                active: true,
                meta: {
                  tags: "Lutein and Zeaxanthin",
                  keywords: "Lutein and Zeaxanthin",
                  description: "Lutein and Zeaxanthin",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb72",
            },
            slug: "feed-prebiotics",
            title: "Feed Prebiotics",
            id: 4000045,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-prebiotics",
            active: true,
            meta: {
              tags: "Feed Prebiotics",
              keywords: "Feed Prebiotics",
              description: "Feed Prebiotics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb73",
            },
            slug: "feed-premix",
            title: "Feed Premix",
            id: 4000046,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-premix",
            active: true,
            meta: {
              tags: "Feed Premix",
              keywords: "Feed Premix",
              description: "Feed Premix",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97871e7906988682cb74",
                },
                slug: "animal-growth-promoters",
                title: "Animal Growth Promoters",
                id: 4000047,
                parent: "/animal-nutrition/feed-additives/feed-premix",
                path: "/animal-nutrition/feed-additives/feed-premix/animal-growth-promoters",
                active: true,
                meta: {
                  tags: "Animal Growth Promoters",
                  keywords: "Animal Growth Promoters",
                  description: "Animal Growth Promoters",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb75",
                },
                slug: "feed-concentrates-and-base-mix",
                title: "Feed Concentrates & Base mix",
                id: 4000048,
                parent: "/animal-nutrition/feed-additives/feed-premix",
                path: "/animal-nutrition/feed-additives/feed-premix/feed-concentrates-and-base-mix",
                active: true,
                meta: {
                  tags: "Feed Concentrates & Base mix",
                  keywords: "Feed Concentrates & Base mix",
                  description: "Feed Concentrates & Base mix",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb76",
                },
                slug: "ruminant-feed-premix",
                title: "Ruminant Feed Premix",
                id: 4000049,
                parent: "/animal-nutrition/feed-additives/feed-premix",
                path: "/animal-nutrition/feed-additives/feed-premix/ruminant-feed-premix",
                active: true,
                meta: {
                  tags: "Ruminant Feed Premix",
                  keywords: "Ruminant Feed Premix",
                  description: "Ruminant Feed Premix",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb77",
                },
                slug: "swine-feed-premix",
                title: "Swine Feed Premix",
                id: 4000050,
                parent: "/animal-nutrition/feed-additives/feed-premix",
                path: "/animal-nutrition/feed-additives/feed-premix/swine-feed-premix",
                active: true,
                meta: {
                  tags: "Swine Feed Premix",
                  keywords: "Swine Feed Premix",
                  description: "Swine Feed Premix",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb78",
            },
            slug: "feed-probiotics",
            title: "Feed Probiotics",
            id: 4000051,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-probiotics",
            active: true,
            meta: {
              tags: "Feed Probiotics",
              keywords: "Feed Probiotics",
              description: "Feed Probiotics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb79",
            },
            slug: "feed-vitamins",
            title: "Feed Vitamins",
            id: 4000052,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-vitamins",
            active: true,
            meta: {
              tags: "Feed Vitamins",
              keywords: "Feed Vitamins",
              description: "Feed Vitamins",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb7a",
            },
            slug: "feed-yeast",
            title: "Feed Yeast",
            id: 4000053,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/feed-yeast",
            active: true,
            meta: {
              tags: "Feed Yeast",
              keywords: "Feed Yeast",
              description: "Feed Yeast",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb7b",
            },
            slug: "fishmeal-and-fish-oil",
            title: "Fishmeal and Fish oil",
            id: 4000054,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/fishmeal-and-fish-oil",
            active: true,
            meta: {
              tags: "Fishmeal and Fish oil",
              keywords: "Fishmeal and Fish oil",
              description: "Fishmeal and Fish oil",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb7c",
            },
            slug: "insect-feed-additive",
            title: "Insect Feed Additive",
            id: 4000055,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/insect-feed-additive",
            active: true,
            meta: {
              tags: "Insect Feed Additive",
              keywords: "Insect Feed Additive",
              description: "Insect Feed Additive",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb7d",
            },
            slug: "plasma-feed",
            title: "Plasma Feed",
            id: 4000056,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/plasma-feed",
            active: true,
            meta: {
              tags: "Plasma Feed",
              keywords: "Plasma Feed",
              description: "Plasma Feed",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb7e",
            },
            slug: "silage-additives",
            title: "Silage Additives",
            id: 4000057,
            parent: "/animal-nutrition/feed-additives",
            path: "/animal-nutrition/feed-additives/silage-additives",
            active: true,
            meta: {
              tags: "Silage Additives",
              keywords: "Silage Additives",
              description: "Silage Additives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97871e7906988682cb7f",
                },
                slug: "silage-inoculants",
                title: "Silage Inoculants",
                id: 4000058,
                parent: "/animal-nutrition/feed-additives/silage-additives",
                path: "/animal-nutrition/feed-additives/silage-additives/silage-inoculants",
                active: true,
                meta: {
                  tags: "Silage Inoculants",
                  keywords: "Silage Inoculants",
                  description: "Silage Inoculants",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ad97871e7906988682cb80",
        },
        slug: "pet-care",
        title: "Pet Care",
        id: 70612,
        parent: "/animal-nutrition",
        path: "/animal-nutrition/pet-care",
        active: true,
        meta: {
          tags: "Pet Care",
          keywords: "Pet Care",
          description: "Pet Care",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64ad97871e7906988682cb81",
            },
            slug: "pet-food",
            title: "Pet Food",
            id: 4000060,
            parent: "/animal-nutrition/pet-care",
            path: "/animal-nutrition/pet-care/pet-food",
            active: true,
            meta: {
              tags: "Pet Food",
              keywords: "Pet Food",
              description: "Pet Food",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97871e7906988682cb82",
                },
                slug: "cat-food",
                title: "Cat Food",
                id: 4000061,
                parent: "/animal-nutrition/pet-care/pet-food",
                path: "/animal-nutrition/pet-care/pet-food/cat-food",
                active: true,
                meta: {
                  tags: "Cat Food",
                  keywords: "Cat Food",
                  description: "Cat Food",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb83",
                },
                slug: "cbd-pet-products",
                title: "CBD Pet Products",
                id: 4000062,
                parent: "/animal-nutrition/pet-care/pet-food",
                path: "/animal-nutrition/pet-care/pet-food/cbd-pet-products",
                active: true,
                meta: {
                  tags: "CBD Pet Products",
                  keywords: "CBD Pet Products",
                  description: "CBD Pet Products",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb84",
                },
                slug: "dog-food",
                title: "Dog Food",
                id: 4000063,
                parent: "/animal-nutrition/pet-care/pet-food",
                path: "/animal-nutrition/pet-care/pet-food/dog-food",
                active: true,
                meta: {
                  tags: "Dog Food",
                  keywords: "Dog Food",
                  description: "Dog Food",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb85",
                },
                slug: "pet-dietary-supplements",
                title: "Pet Dietary Supplements",
                id: 4000064,
                parent: "/animal-nutrition/pet-care/pet-food",
                path: "/animal-nutrition/pet-care/pet-food/pet-dietary-supplements",
                active: true,
                meta: {
                  tags: "Pet Dietary Supplements",
                  keywords: "Pet Dietary Supplements",
                  description: "Pet Dietary Supplements",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb86",
                },
                slug: "pet-nutraceuticals",
                title: "Pet Nutraceuticals",
                id: 4000065,
                parent: "/animal-nutrition/pet-care/pet-food",
                path: "/animal-nutrition/pet-care/pet-food/pet-nutraceuticals",
                active: true,
                meta: {
                  tags: "Pet Nutraceuticals",
                  keywords: "Pet Nutraceuticals",
                  description: "Pet Nutraceuticals",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb87",
                },
                slug: "pet-oral-care",
                title: "Pet Oral Care",
                id: 4000066,
                parent: "/animal-nutrition/pet-care/pet-food",
                path: "/animal-nutrition/pet-care/pet-food/pet-oral-care",
                active: true,
                meta: {
                  tags: "Pet Oral Care",
                  keywords: "Pet Oral Care",
                  description: "Pet Oral Care",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb88",
                },
                slug: "pet-treats",
                title: "Pet Treats",
                id: 4000067,
                parent: "/animal-nutrition/pet-care/pet-food",
                path: "/animal-nutrition/pet-care/pet-food/pet-treats",
                active: true,
                meta: {
                  tags: "Pet Treats",
                  keywords: "Pet Treats",
                  description: "Pet Treats",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb89",
                },
                slug: "pet-veterinary-diets",
                title: "Pet Veterinary Diets",
                id: 4000068,
                parent: "/animal-nutrition/pet-care/pet-food",
                path: "/animal-nutrition/pet-care/pet-food/pet-veterinary-diets",
                active: true,
                meta: {
                  tags: "Pet Veterinary Diets",
                  keywords: "Pet Veterinary Diets",
                  description: "Pet Veterinary Diets",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb8a",
            },
            slug: "pet-products",
            title: "Pet Products",
            id: 4000069,
            parent: "/animal-nutrition/pet-care",
            path: "/animal-nutrition/pet-care/pet-products",
            active: true,
            meta: {
              tags: "Pet Products",
              keywords: "Pet Products",
              description: "Pet Products",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ad97871e7906988682cb8b",
                },
                slug: "cat-litter",
                title: "Cat Litter",
                id: 4000070,
                parent: "/animal-nutrition/pet-care/pet-products",
                path: "/animal-nutrition/pet-care/pet-products/cat-litter",
                active: true,
                meta: {
                  tags: "Cat Litter",
                  keywords: "Cat Litter",
                  description: "Cat Litter",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb8c",
                },
                slug: "pet-furniture",
                title: "Pet Furniture",
                id: 4000071,
                parent: "/animal-nutrition/pet-care/pet-products",
                path: "/animal-nutrition/pet-care/pet-products/pet-furniture",
                active: true,
                meta: {
                  tags: "Pet Furniture",
                  keywords: "Pet Furniture",
                  description: "Pet Furniture",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb8d",
                },
                slug: "pet-insect-repellants",
                title: "Pet Insect Repellants",
                id: 4000073,
                parent: "/animal-nutrition/pet-care/pet-products",
                path: "/animal-nutrition/pet-care/pet-products/pet-insect-repellants",
                active: true,
                meta: {
                  tags: "Pet Insect Repellants",
                  keywords: "Pet Insect Repellants",
                  description: "Pet Insect Repellants",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ad97871e7906988682cb8e",
                },
                slug: "pet-toys",
                title: "Pet Toys",
                id: 4000074,
                parent: "/animal-nutrition/pet-care/pet-products",
                path: "/animal-nutrition/pet-care/pet-products/pet-toys",
                active: true,
                meta: {
                  tags: "Pet Toys",
                  keywords: "Pet Toys",
                  description: "Pet Toys",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ad97871e7906988682cb8f",
            },
            slug: "pet-services",
            title: "Pet Services",
            id: 4000075,
            parent: "/animal-nutrition/pet-care",
            path: "/animal-nutrition/pet-care/pet-services",
            active: true,
            meta: {
              tags: "Pet Services",
              keywords: "Pet Services",
              description: "Pet Services",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64c246c8fc9d360f328ff944",
    },
    picture_url: null,
    description: {
      short_description: "Professional and Commercial Services",
      full_description: "Professional and Commercial Services",
    },
    meta: {
      tags: "Professional and Commercial Services",
      keywords: "Professional and Commercial Services",
      description: "Professional and Commercial Services",
    },
    active: true,
    title: "Professional and Commercial Services",
    slug: "professional-and-commercial-services",
    id: 83,
    parent: "/",
    path: "/professional-and-commercial-services",
    sub_categories: [
      {
        _id: {
          $oid: "64c246c8fc9d360f328ff948",
        },
        picture_url: null,
        description: {
          short_description: "Commercial Services",
          full_description: "Commercial Services",
        },
        meta: {
          tags: "Commercial Services",
          keywords: "Commercial Services",
          description: "Commercial Services",
        },
        active: true,
        title: "Commercial Services",
        slug: "commercial-services",
        id: 83674,
        __v: 0,
        parent: "/professional-and-commercial-services",
        path: "/professional-and-commercial-services/commercial-services",
        sub_categories: [
          {
            _id: {
              $oid: "64c253fcb45c8a1cb97eb30c",
            },
            slug: "media-and-events",
            title: "Media and Events",
            id: 17000002,
            parent: "/professional-and-commercial-services/commercial-services",
            path: "/professional-and-commercial-services/commercial-services/media-and-events",
            active: true,
            meta: {
              tags: "Media and Events",
              keywords: "Media and Events",
              description: "Media and Events",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb30d",
                },
                slug: "events",
                title: "Events",
                id: 17000003,
                parent:
                  "/professional-and-commercial-services/commercial-services/media-and-events",
                path: "/professional-and-commercial-services/commercial-services/media-and-events/events",
                active: true,
                meta: {
                  tags: "Events",
                  keywords: "Events",
                  description: "Events",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb30e",
                    },
                    slug: "event-management",
                    title: "Event Management",
                    id: 17000004,
                    parent:
                      "/professional-and-commercial-services/commercial-services/media-and-events/events",
                    path: "/professional-and-commercial-services/commercial-services/media-and-events/events/event-management",
                    active: true,
                    meta: {
                      tags: "Event Management",
                      keywords: "Event Management",
                      description: "Event Management",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb30f",
                },
                slug: "media",
                title: "Media",
                id: 17000005,
                parent:
                  "/professional-and-commercial-services/commercial-services/media-and-events",
                path: "/professional-and-commercial-services/commercial-services/media-and-events/media",
                active: true,
                meta: {
                  tags: "Media",
                  keywords: "Media",
                  description: "Media",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb310",
                    },
                    slug: "newspapers",
                    title: "Newspapers",
                    id: 17000006,
                    parent:
                      "/professional-and-commercial-services/commercial-services/media-and-events/media",
                    path: "/professional-and-commercial-services/commercial-services/media-and-events/media/newspapers",
                    active: true,
                    meta: {
                      tags: "Newspapers",
                      keywords: "Newspapers",
                      description: "Newspapers",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253fcb45c8a1cb97eb311",
            },
            slug: "product-testing",
            title: "Product Testing",
            id: 17000007,
            parent: "/professional-and-commercial-services/commercial-services",
            path: "/professional-and-commercial-services/commercial-services/product-testing",
            active: true,
            meta: {
              tags: "Product Testing",
              keywords: "Product Testing",
              description: "Product Testing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb312",
                },
                slug: "product-testing-equipment",
                title: "Product Testing Equipment",
                id: 17000008,
                parent:
                  "/professional-and-commercial-services/commercial-services/product-testing",
                path: "/professional-and-commercial-services/commercial-services/product-testing/product-testing-equipment",
                active: true,
                meta: {
                  tags: "Product Testing Equipment",
                  keywords: "Product Testing Equipment",
                  description: "Product Testing Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb313",
                },
                slug: "product-testing-services",
                title: "Product Testing Services",
                id: 17000009,
                parent:
                  "/professional-and-commercial-services/commercial-services/product-testing",
                path: "/professional-and-commercial-services/commercial-services/product-testing/product-testing-services",
                active: true,
                meta: {
                  tags: "Product Testing Services",
                  keywords: "Product Testing Services",
                  description: "Product Testing Services",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253fcb45c8a1cb97eb314",
            },
            slug: "waste-management",
            title: "Waste Management",
            id: 17000010,
            parent: "/professional-and-commercial-services/commercial-services",
            path: "/professional-and-commercial-services/commercial-services/waste-management",
            active: true,
            meta: {
              tags: "Waste Management",
              keywords: "Waste Management",
              description: "Waste Management",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb315",
                },
                slug: "waste-management-equipment",
                title: "Waste Management Equipment",
                id: 17000011,
                parent:
                  "/professional-and-commercial-services/commercial-services/waste-management",
                path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment",
                active: true,
                meta: {
                  tags: "Waste Management Equipment",
                  keywords: "Waste Management Equipment",
                  description: "Waste Management Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb316",
                    },
                    slug: "waste-disposal-equipment",
                    title: "Waste Disposal Equipment",
                    id: 17000012,
                    parent:
                      "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment",
                    path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-disposal-equipment",
                    active: true,
                    meta: {
                      tags: "Waste Disposal Equipment",
                      keywords: "Waste Disposal Equipment",
                      description: "Waste Disposal Equipment",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253fcb45c8a1cb97eb317",
                        },
                        slug: "dumpsters",
                        title: "Dumpsters",
                        id: 17000013,
                        parent:
                          "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-disposal-equipment",
                        path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-disposal-equipment/dumpsters",
                        active: true,
                        meta: {
                          tags: "Dumpsters",
                          keywords: "Dumpsters",
                          description: "Dumpsters",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253fcb45c8a1cb97eb318",
                        },
                        slug: "waste-compactors",
                        title: "Waste Compactors",
                        id: 17000014,
                        parent:
                          "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-disposal-equipment",
                        path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-disposal-equipment/waste-compactors",
                        active: true,
                        meta: {
                          tags: "Waste Compactors",
                          keywords: "Waste Compactors",
                          description: "Waste Compactors",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253fcb45c8a1cb97eb319",
                        },
                        slug: "waste-composters",
                        title: "Waste Composters",
                        id: 17000015,
                        parent:
                          "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-disposal-equipment",
                        path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-disposal-equipment/waste-composters",
                        active: true,
                        meta: {
                          tags: "Waste Composters",
                          keywords: "Waste Composters",
                          description: "Waste Composters",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb31a",
                    },
                    slug: "waste-recycling-and-sorting-equipment",
                    title: "Waste Recycling & Sorting Equipment",
                    id: 17000016,
                    parent:
                      "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment",
                    path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-recycling-and-sorting-equipment",
                    active: true,
                    meta: {
                      tags: "Waste Recycling & Sorting Equipment",
                      keywords: "Waste Recycling & Sorting Equipment",
                      description: "Waste Recycling & Sorting Equipment",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253fcb45c8a1cb97eb31b",
                        },
                        slug: "waste-conveyor-systems",
                        title: "Waste Conveyor Systems",
                        id: 17000017,
                        parent:
                          "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-recycling-and-sorting-equipment",
                        path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-recycling-and-sorting-equipment/waste-conveyor-systems",
                        active: true,
                        meta: {
                          tags: "Waste Conveyor Systems",
                          keywords: "Waste Conveyor Systems",
                          description: "Waste Conveyor Systems",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253fcb45c8a1cb97eb31c",
                        },
                        slug: "waste-screeners",
                        title: "Waste Screeners",
                        id: 17000018,
                        parent:
                          "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-recycling-and-sorting-equipment",
                        path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-recycling-and-sorting-equipment/waste-screeners",
                        active: true,
                        meta: {
                          tags: "Waste Screeners",
                          keywords: "Waste Screeners",
                          description: "Waste Screeners",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253fcb45c8a1cb97eb31d",
                        },
                        slug: "waste-shredders",
                        title: "Waste Shredders",
                        id: 17000019,
                        parent:
                          "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-recycling-and-sorting-equipment",
                        path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-equipment/waste-recycling-and-sorting-equipment/waste-shredders",
                        active: true,
                        meta: {
                          tags: "Waste Shredders",
                          keywords: "Waste Shredders",
                          description: "Waste Shredders",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb31e",
                },
                slug: "waste-management-services",
                title: "Waste Management Services",
                id: 17000020,
                parent:
                  "/professional-and-commercial-services/commercial-services/waste-management",
                path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services",
                active: true,
                meta: {
                  tags: "Waste Management Services",
                  keywords: "Waste Management Services",
                  description: "Waste Management Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb31f",
                    },
                    slug: "industrial-waste-management",
                    title: "Industrial Waste Management",
                    id: 17000021,
                    parent:
                      "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services",
                    path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management",
                    active: true,
                    meta: {
                      tags: "Industrial Waste Management",
                      keywords: "Industrial Waste Management",
                      description: "Industrial Waste Management",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253fcb45c8a1cb97eb320",
                        },
                        slug: "hazardous-waste",
                        title: "Hazardous Waste",
                        id: 17000022,
                        parent:
                          "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management",
                        path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management/hazardous-waste",
                        active: true,
                        meta: {
                          tags: "Hazardous Waste",
                          keywords: "Hazardous Waste",
                          description: "Hazardous Waste",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64c253fcb45c8a1cb97eb321",
                            },
                            slug: "bio-medical-waste",
                            title: "Bio Medical Waste",
                            id: 17000023,
                            parent:
                              "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management/hazardous-waste",
                            path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management/hazardous-waste/bio-medical-waste",
                            active: true,
                            meta: {
                              tags: "Bio Medical Waste",
                              keywords: "Bio Medical Waste",
                              description: "Bio Medical Waste",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64c253fcb45c8a1cb97eb322",
                            },
                            slug: "manufacturing-waste",
                            title: "Manufacturing Waste",
                            id: 17000024,
                            parent:
                              "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management/hazardous-waste",
                            path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management/hazardous-waste/manufacturing-waste",
                            active: true,
                            meta: {
                              tags: "Manufacturing Waste",
                              keywords: "Manufacturing Waste",
                              description: "Manufacturing Waste",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64c253fcb45c8a1cb97eb323",
                        },
                        slug: "non-hazardous-waste",
                        title: "Non Hazardous Waste",
                        id: 17000025,
                        parent:
                          "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management",
                        path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management/non-hazardous-waste",
                        active: true,
                        meta: {
                          tags: "Non Hazardous Waste",
                          keywords: "Non Hazardous Waste",
                          description: "Non Hazardous Waste",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64c253fcb45c8a1cb97eb324",
                            },
                            slug: "construction-waste",
                            title: "Construction Waste",
                            id: 17000026,
                            parent:
                              "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management/non-hazardous-waste",
                            path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/industrial-waste-management/non-hazardous-waste/construction-waste",
                            active: true,
                            meta: {
                              tags: "Construction Waste",
                              keywords: "Construction Waste",
                              description: "Construction Waste",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb325",
                    },
                    slug: "municipal-waste-management",
                    title: "Municipal Waste Management",
                    id: 17000027,
                    parent:
                      "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services",
                    path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management",
                    active: true,
                    meta: {
                      tags: "Municipal Waste Management",
                      keywords: "Municipal Waste Management",
                      description: "Municipal Waste Management",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253fcb45c8a1cb97eb326",
                        },
                        slug: "solid-waste-management",
                        title: "Solid Waste Management",
                        id: 17000028,
                        parent:
                          "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management",
                        path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management",
                        active: true,
                        meta: {
                          tags: "Solid Waste Management",
                          keywords: "Solid Waste Management",
                          description: "Solid Waste Management",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64c253fcb45c8a1cb97eb327",
                            },
                            slug: "e-waste-management",
                            title: "E-Waste Management",
                            id: 17000029,
                            parent:
                              "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management",
                            path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management/e-waste-management",
                            active: true,
                            meta: {
                              tags: "E-Waste Management",
                              keywords: "E-Waste Management",
                              description: "E-Waste Management",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64c253fcb45c8a1cb97eb328",
                            },
                            slug: "glass-waste-management",
                            title: "Glass Waste Management",
                            id: 17000030,
                            parent:
                              "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management",
                            path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management/glass-waste-management",
                            active: true,
                            meta: {
                              tags: "Glass Waste Management",
                              keywords: "Glass Waste Management",
                              description: "Glass Waste Management",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64c253fcb45c8a1cb97eb329",
                            },
                            slug: "metal-waste-management",
                            title: "Metal Waste Management",
                            id: 17000031,
                            parent:
                              "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management",
                            path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management/metal-waste-management",
                            active: true,
                            meta: {
                              tags: "Metal Waste Management",
                              keywords: "Metal Waste Management",
                              description: "Metal Waste Management",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64c253fcb45c8a1cb97eb32a",
                            },
                            slug: "organic-waste-management",
                            title: "Organic Waste Management",
                            id: 17000032,
                            parent:
                              "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management",
                            path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management/organic-waste-management",
                            active: true,
                            meta: {
                              tags: "Organic Waste Management",
                              keywords: "Organic Waste Management",
                              description: "Organic Waste Management",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64c253fcb45c8a1cb97eb32b",
                            },
                            slug: "plastic-waste-management",
                            title: "Plastic Waste Management",
                            id: 17000033,
                            parent:
                              "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management",
                            path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management/plastic-waste-management",
                            active: true,
                            meta: {
                              tags: "Plastic Waste Management",
                              keywords: "Plastic Waste Management",
                              description: "Plastic Waste Management",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64c253fcb45c8a1cb97eb32c",
                            },
                            slug: "textiles-waste-management",
                            title: "Textiles Waste Management",
                            id: 17000034,
                            parent:
                              "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management",
                            path: "/professional-and-commercial-services/commercial-services/waste-management/waste-management-services/municipal-waste-management/solid-waste-management/textiles-waste-management",
                            active: true,
                            meta: {
                              tags: "Textiles Waste Management",
                              keywords: "Textiles Waste Management",
                              description: "Textiles Waste Management",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64c246c8fc9d360f328ff949",
        },
        picture_url: null,
        description: {
          short_description: "Consumer and B2C Services",
          full_description: "Consumer and B2C Services",
        },
        meta: {
          tags: "Consumer and B2C Services",
          keywords: "Consumer and B2C Services",
          description: "Consumer and B2C Services",
        },
        active: true,
        title: "Consumer and B2C Services",
        slug: "consumer-and-b2c-services",
        id: 83675,
        __v: 0,
        parent: "/professional-and-commercial-services",
        path: "/professional-and-commercial-services/consumer-and-b2c-services",
        sub_categories: [
          {
            _id: {
              $oid: "64c253fcb45c8a1cb97eb32d",
            },
            slug: "creatives",
            title: "Creatives",
            id: 17000036,
            parent:
              "/professional-and-commercial-services/consumer-and-b2c-services",
            path: "/professional-and-commercial-services/consumer-and-b2c-services/creatives",
            active: true,
            meta: {
              tags: "Creatives",
              keywords: "Creatives",
              description: "Creatives",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb32e",
                },
                slug: "performing-arts",
                title: "Performing Arts",
                id: 17000037,
                parent:
                  "/professional-and-commercial-services/consumer-and-b2c-services/creatives",
                path: "/professional-and-commercial-services/consumer-and-b2c-services/creatives/performing-arts",
                active: true,
                meta: {
                  tags: "Performing Arts",
                  keywords: "Performing Arts",
                  description: "Performing Arts",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253fcb45c8a1cb97eb32f",
            },
            slug: "education",
            title: "Education",
            id: 17000038,
            parent:
              "/professional-and-commercial-services/consumer-and-b2c-services",
            path: "/professional-and-commercial-services/consumer-and-b2c-services/education",
            active: true,
            meta: {
              tags: "Education",
              keywords: "Education",
              description: "Education",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb330",
                },
                slug: "digital-education",
                title: "Digital Education",
                id: 17000039,
                parent:
                  "/professional-and-commercial-services/consumer-and-b2c-services/education",
                path: "/professional-and-commercial-services/consumer-and-b2c-services/education/digital-education",
                active: true,
                meta: {
                  tags: "Digital Education",
                  keywords: "Digital Education",
                  description: "Digital Education",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb331",
                },
                slug: "k-12",
                title: "K-12",
                id: 17000040,
                parent:
                  "/professional-and-commercial-services/consumer-and-b2c-services/education",
                path: "/professional-and-commercial-services/consumer-and-b2c-services/education/k-12",
                active: true,
                meta: {
                  tags: "K-12",
                  keywords: "K-12",
                  description: "K-12",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64c246c8fc9d360f328ff94a",
        },
        picture_url: null,
        description: {
          short_description: "Professional Services",
          full_description: "Professional Services",
        },
        meta: {
          tags: "Professional Services",
          keywords: "Professional Services",
          description: "Professional Services",
        },
        active: true,
        title: "Professional Services",
        slug: "professional-services",
        id: 83676,
        __v: 0,
        parent: "/professional-and-commercial-services",
        path: "/professional-and-commercial-services/professional-services",
        sub_categories: [
          {
            _id: {
              $oid: "64c253fcb45c8a1cb97eb332",
            },
            slug: "advisory-and-consulting",
            title: "Advisory and Consulting",
            id: 17000042,
            parent:
              "/professional-and-commercial-services/professional-services",
            path: "/professional-and-commercial-services/professional-services/advisory-and-consulting",
            active: true,
            meta: {
              tags: "Advisory and Consulting",
              keywords: "Advisory and Consulting",
              description: "Advisory and Consulting",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb333",
                },
                slug: "financial-advisory",
                title: "Financial Advisory",
                id: 17000043,
                parent:
                  "/professional-and-commercial-services/professional-services/advisory-and-consulting",
                path: "/professional-and-commercial-services/professional-services/advisory-and-consulting/financial-advisory",
                active: true,
                meta: {
                  tags: "Financial Advisory",
                  keywords: "Financial Advisory",
                  description: "Financial Advisory",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb334",
                },
                slug: "management-consulting",
                title: "Management Consulting",
                id: 17000044,
                parent:
                  "/professional-and-commercial-services/professional-services/advisory-and-consulting",
                path: "/professional-and-commercial-services/professional-services/advisory-and-consulting/management-consulting",
                active: true,
                meta: {
                  tags: "Management Consulting",
                  keywords: "Management Consulting",
                  description: "Management Consulting",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb335",
                },
                slug: "operations-consulting",
                title: "Operations Consulting",
                id: 17000045,
                parent:
                  "/professional-and-commercial-services/professional-services/advisory-and-consulting",
                path: "/professional-and-commercial-services/professional-services/advisory-and-consulting/operations-consulting",
                active: true,
                meta: {
                  tags: "Operations Consulting",
                  keywords: "Operations Consulting",
                  description: "Operations Consulting",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253fcb45c8a1cb97eb336",
            },
            slug: "design-services",
            title: "Design Services",
            id: 17000046,
            parent:
              "/professional-and-commercial-services/professional-services",
            path: "/professional-and-commercial-services/professional-services/design-services",
            active: true,
            meta: {
              tags: "Design Services",
              keywords: "Design Services",
              description: "Design Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb337",
                },
                slug: "interior-design-services",
                title: "Interior Design Services",
                id: 17000047,
                parent:
                  "/professional-and-commercial-services/professional-services/design-services",
                path: "/professional-and-commercial-services/professional-services/design-services/interior-design-services",
                active: true,
                meta: {
                  tags: "Interior Design Services",
                  keywords: "Interior Design Services",
                  description: "Interior Design Services",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253fcb45c8a1cb97eb338",
            },
            slug: "legal-accounting-and-hr",
            title: "Legal, Accounting, and HR",
            id: 17000048,
            parent:
              "/professional-and-commercial-services/professional-services",
            path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr",
            active: true,
            meta: {
              tags: "Legal, Accounting, and HR",
              keywords: "Legal, Accounting, and HR",
              description: "Legal, Accounting, and HR",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb339",
                },
                slug: "accounting-services",
                title: "Accounting Services",
                id: 17000049,
                parent:
                  "/professional-and-commercial-services/professional-services/legal-accounting-and-hr",
                path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/accounting-services",
                active: true,
                meta: {
                  tags: "Accounting Services",
                  keywords: "Accounting Services",
                  description: "Accounting Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb33a",
                    },
                    slug: "other-accounting-services",
                    title: "Other Accounting Services",
                    id: 17000050,
                    parent:
                      "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/accounting-services",
                    path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/accounting-services/other-accounting-services",
                    active: true,
                    meta: {
                      tags: "Other Accounting Services",
                      keywords: "Other Accounting Services",
                      description: "Other Accounting Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb33b",
                    },
                    slug: "payroll-accounting",
                    title: "Payroll Accounting",
                    id: 17000051,
                    parent:
                      "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/accounting-services",
                    path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/accounting-services/payroll-accounting",
                    active: true,
                    meta: {
                      tags: "Payroll Accounting",
                      keywords: "Payroll Accounting",
                      description: "Payroll Accounting",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb33c",
                    },
                    slug: "tax-accounting",
                    title: "Tax Accounting",
                    id: 17000052,
                    parent:
                      "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/accounting-services",
                    path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/accounting-services/tax-accounting",
                    active: true,
                    meta: {
                      tags: "Tax Accounting",
                      keywords: "Tax Accounting",
                      description: "Tax Accounting",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb33d",
                },
                slug: "auditing-services",
                title: "Auditing Services",
                id: 17000053,
                parent:
                  "/professional-and-commercial-services/professional-services/legal-accounting-and-hr",
                path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/auditing-services",
                active: true,
                meta: {
                  tags: "Auditing Services",
                  keywords: "Auditing Services",
                  description: "Auditing Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb33e",
                    },
                    slug: "financial-auditing",
                    title: "Financial Auditing",
                    id: 17000054,
                    parent:
                      "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/auditing-services",
                    path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/auditing-services/financial-auditing",
                    active: true,
                    meta: {
                      tags: "Financial Auditing",
                      keywords: "Financial Auditing",
                      description: "Financial Auditing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb33f",
                    },
                    slug: "other-auditing-services",
                    title: "Other Auditing Services",
                    id: 17000055,
                    parent:
                      "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/auditing-services",
                    path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/auditing-services/other-auditing-services",
                    active: true,
                    meta: {
                      tags: "Other Auditing Services",
                      keywords: "Other Auditing Services",
                      description: "Other Auditing Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb340",
                },
                slug: "bpo-services",
                title: "BPO services",
                id: 17000056,
                parent:
                  "/professional-and-commercial-services/professional-services/legal-accounting-and-hr",
                path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/bpo-services",
                active: true,
                meta: {
                  tags: "BPO services",
                  keywords: "BPO services",
                  description: "BPO services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb341",
                },
                slug: "hr-professional-services",
                title: "HR Professional Services",
                id: 17000057,
                parent:
                  "/professional-and-commercial-services/professional-services/legal-accounting-and-hr",
                path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/hr-professional-services",
                active: true,
                meta: {
                  tags: "HR Professional Services",
                  keywords: "HR Professional Services",
                  description: "HR Professional Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253fcb45c8a1cb97eb342",
                },
                slug: "legal-services",
                title: "Legal Services",
                id: 17000058,
                parent:
                  "/professional-and-commercial-services/professional-services/legal-accounting-and-hr",
                path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/legal-services",
                active: true,
                meta: {
                  tags: "Legal Services",
                  keywords: "Legal Services",
                  description: "Legal Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb343",
                    },
                    slug: "b2b-legal-services",
                    title: "B2B Legal Services",
                    id: 17000059,
                    parent:
                      "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/legal-services",
                    path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/legal-services/b2b-legal-services",
                    active: true,
                    meta: {
                      tags: "B2B Legal Services",
                      keywords: "B2B Legal Services",
                      description: "B2B Legal Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c253fcb45c8a1cb97eb344",
                    },
                    slug: "b2c-legal-services",
                    title: "B2C Legal Services",
                    id: 17000060,
                    parent:
                      "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/legal-services",
                    path: "/professional-and-commercial-services/professional-services/legal-accounting-and-hr/legal-services/b2c-legal-services",
                    active: true,
                    meta: {
                      tags: "B2C Legal Services",
                      keywords: "B2C Legal Services",
                      description: "B2C Legal Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253fcb45c8a1cb97eb345",
            },
            slug: "photography-services",
            title: "Photography Services",
            id: 17000061,
            parent:
              "/professional-and-commercial-services/professional-services",
            path: "/professional-and-commercial-services/professional-services/photography-services",
            active: true,
            meta: {
              tags: "Photography Services",
              keywords: "Photography Services",
              description: "Photography Services",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b137",
    },
    picture_url: null,
    description: {
      short_description:
        "Statistics for the 2023 Hospitality & Tourism market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Hospitality & Tourism analysis includes a market forecast outlook to 2028 and historical overview. Get a sample of this industry analysis as a free report PDF download.",
      full_description:
        "Statistics for the 2023 Hospitality & Tourism market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Hospitality & Tourism analysis includes a market forecast outlook to 2028 and historical overview. Get a sample of this industry analysis as a free report PDF download.",
    },
    meta: {
      tags: "Hospitality & Tourism Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "tourism and hospitality industry, hospitality industry, trends in hospitality industry, hospitality industry in India, trends in tourism and hospitality industry, scope of hospitality industry, tourism and hospitality sector, global hospitality industry statistics, overview of tourism and hospitality industry, growth of hospitality industry, ",
      description:
        "Mordor Intelligence™ 【  Hospitality & Tourism Market Research  】 Industry Reports, including market share insights for 2020, 2021, and 2022.  Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
    },
    active: true,
    title: "Hospitality and Tourism",
    slug: "hospitality-and-tourism",
    id: 74,
    __v: 0,
    cat_email: "neoteam@mordorintelligence.com",
    cat_pw_id: 457947,
    parent: "/",
    path: "/hospitality-and-tourism",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1c9",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Travel and Tourism Industry Reports market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Travel and Tourism Industry Reports Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Travel and Tourism Industry Reports market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Travel and Tourism Industry Reports Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Travel and Tourism Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Travel and Tourism Industry Reports Market Research Reports, travel and tourism market, travel industry reports, travel industry market research, travel and tourism industry, tourism industry report, tourism market report, travel and tourism industry analysis",
          description:
            "Mordor Intelligence™ 【  Travel and Tourism Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Travel and Tourism",
        slug: "travel-and-tourism",
        id: 74203,
        __v: 0,
        parent: "/hospitality-and-tourism",
        path: "/hospitality-and-tourism/travel-and-tourism",
        sub_categories: [
          {
            _id: {
              $oid: "64c253b51f8a9a287457d8e6",
            },
            slug: "tourism",
            title: "Tourism",
            id: 15000008,
            parent: "/hospitality-and-tourism/travel-and-tourism",
            path: "/hospitality-and-tourism/travel-and-tourism/tourism",
            active: true,
            meta: {
              tags: "Tourism",
              keywords: "Tourism",
              description: "Tourism",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253b51f8a9a287457d8e7",
                },
                slug: "entertainment-and-recreation",
                title: "Entertainment and Recreation",
                id: 15000009,
                parent: "/hospitality-and-tourism/travel-and-tourism/tourism",
                path: "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation",
                active: true,
                meta: {
                  tags: "Entertainment and Recreation",
                  keywords: "Entertainment and Recreation",
                  description: "Entertainment and Recreation",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253b51f8a9a287457d8e8",
                    },
                    slug: "recreation",
                    title: "Recreation",
                    id: 15000010,
                    parent:
                      "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation",
                    path: "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation/recreation",
                    active: true,
                    meta: {
                      tags: "Recreation",
                      keywords: "Recreation",
                      description: "Recreation",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c253b51f8a9a287457d8e9",
                        },
                        slug: "amusement-parks",
                        title: "Amusement Parks",
                        id: 15000011,
                        parent:
                          "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation/recreation",
                        path: "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation/recreation/amusement-parks",
                        active: true,
                        meta: {
                          tags: "Amusement Parks",
                          keywords: "Amusement Parks",
                          description: "Amusement Parks",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253b51f8a9a287457d8ea",
                        },
                        slug: "camping-and-motorparks",
                        title: "Camping and Motorparks",
                        id: 15000012,
                        parent:
                          "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation/recreation",
                        path: "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation/recreation/camping-and-motorparks",
                        active: true,
                        meta: {
                          tags: "Camping and Motorparks",
                          keywords: "Camping and Motorparks",
                          description: "Camping and Motorparks",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253b51f8a9a287457d8eb",
                        },
                        slug: "casinos",
                        title: "Casinos",
                        id: 15000013,
                        parent:
                          "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation/recreation",
                        path: "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation/recreation/casinos",
                        active: true,
                        meta: {
                          tags: "Casinos",
                          keywords: "Casinos",
                          description: "Casinos",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c253b51f8a9a287457d8ec",
                        },
                        slug: "recreational-equipment",
                        title: "Recreational Equipment",
                        id: 15000014,
                        parent:
                          "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation/recreation",
                        path: "/hospitality-and-tourism/travel-and-tourism/tourism/entertainment-and-recreation/recreation/recreational-equipment",
                        active: true,
                        meta: {
                          tags: "Recreational Equipment",
                          keywords: "Recreational Equipment",
                          description: "Recreational Equipment",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c253b51f8a9a287457d8ed",
                },
                slug: "mice-tourism",
                title: "MICE Tourism",
                id: 15000015,
                parent: "/hospitality-and-tourism/travel-and-tourism/tourism",
                path: "/hospitality-and-tourism/travel-and-tourism/tourism/mice-tourism",
                active: true,
                meta: {
                  tags: "MICE Tourism",
                  keywords: "MICE Tourism",
                  description: "MICE Tourism",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253b51f8a9a287457d8ee",
                },
                slug: "wellness-tourism",
                title: "Wellness Tourism",
                id: 15000016,
                parent: "/hospitality-and-tourism/travel-and-tourism/tourism",
                path: "/hospitality-and-tourism/travel-and-tourism/tourism/wellness-tourism",
                active: true,
                meta: {
                  tags: "Wellness Tourism",
                  keywords: "Wellness Tourism",
                  description: "Wellness Tourism",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c253b51f8a9a287457d8ef",
                    },
                    slug: "medical-tourism",
                    title: "Medical Tourism",
                    id: 15000017,
                    parent:
                      "/hospitality-and-tourism/travel-and-tourism/tourism/wellness-tourism",
                    path: "/hospitality-and-tourism/travel-and-tourism/tourism/wellness-tourism/medical-tourism",
                    active: true,
                    meta: {
                      tags: "Medical Tourism",
                      keywords: "Medical Tourism",
                      description: "Medical Tourism",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253b51f8a9a287457d8f0",
            },
            slug: "travel",
            title: "Travel",
            id: 15000018,
            parent: "/hospitality-and-tourism/travel-and-tourism",
            path: "/hospitality-and-tourism/travel-and-tourism/travel",
            active: true,
            meta: {
              tags: "Travel",
              keywords: "Travel",
              description: "Travel",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253b51f8a9a287457d8f1",
                },
                slug: "online-travel",
                title: "Online Travel",
                id: 15000019,
                parent: "/hospitality-and-tourism/travel-and-tourism/travel",
                path: "/hospitality-and-tourism/travel-and-tourism/travel/online-travel",
                active: true,
                meta: {
                  tags: "Online Travel",
                  keywords: "Online Travel",
                  description: "Online Travel",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64c253b51f8a9a287457d8e0",
        },
        slug: "hospitality",
        title: "Hospitality",
        id: 74673,
        parent: "/hospitality-and-tourism",
        path: "/hospitality-and-tourism/hospitality",
        active: true,
        meta: {
          tags: "Hospitality",
          keywords: "Hospitality",
          description: "Hospitality",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c253b51f8a9a287457d8e1",
            },
            slug: "general-hospitality",
            title: "General Hospitality",
            id: 15000002,
            parent: "/hospitality-and-tourism/hospitality",
            path: "/hospitality-and-tourism/hospitality/general-hospitality",
            active: true,
            meta: {
              tags: "General Hospitality",
              keywords: "General Hospitality",
              description: "General Hospitality",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253b51f8a9a287457d8e2",
                },
                slug: "online-accommodation",
                title: "Online Accommodation",
                id: 15000003,
                parent:
                  "/hospitality-and-tourism/hospitality/general-hospitality",
                path: "/hospitality-and-tourism/hospitality/general-hospitality/online-accommodation",
                active: true,
                meta: {
                  tags: "Online Accommodation",
                  keywords: "Online Accommodation",
                  description: "Online Accommodation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c253b51f8a9a287457d8e3",
                },
                slug: "student-accommodation",
                title: "Student Accommodation",
                id: 15000004,
                parent:
                  "/hospitality-and-tourism/hospitality/general-hospitality",
                path: "/hospitality-and-tourism/hospitality/general-hospitality/student-accommodation",
                active: true,
                meta: {
                  tags: "Student Accommodation",
                  keywords: "Student Accommodation",
                  description: "Student Accommodation",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c253b51f8a9a287457d8e4",
            },
            slug: "luxury-hospitality",
            title: "Luxury Hospitality",
            id: 15000005,
            parent: "/hospitality-and-tourism/hospitality",
            path: "/hospitality-and-tourism/hospitality/luxury-hospitality",
            active: true,
            meta: {
              tags: "Luxury Hospitality",
              keywords: "Luxury Hospitality",
              description: "Luxury Hospitality",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c253b51f8a9a287457d8e5",
                },
                slug: "spa",
                title: "Spa",
                id: 15000006,
                parent:
                  "/hospitality-and-tourism/hospitality/luxury-hospitality",
                path: "/hospitality-and-tourism/hospitality/luxury-hospitality/spa",
                active: true,
                meta: {
                  tags: "Spa",
                  keywords: "Spa",
                  description: "Spa",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64ccdbc490efcbc0445d3a6f",
    },
    picture_url: null,
    description: {
      short_description: "Technology, Media and Telecom",
      full_description: "Technology, Media and Telecom",
    },
    meta: {
      tags: "Technology, Media and Telecom",
      keywords: "Technology, Media and Telecom",
      description: "Technology, Media and Telecom",
    },
    active: true,
    title: "Technology, Media and Telecom",
    slug: "technology-media-and-telecom",
    id: 84,
    parent: "/",
    path: "/technology-media-and-telecom",
    sub_categories: [
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a73",
        },
        picture_url: null,
        description: {
          short_description: "Information Technology",
          full_description: "Information Technology",
        },
        meta: {
          tags: "Information Technology",
          keywords: "Information Technology",
          description: "Information Technology",
        },
        active: true,
        title: "Information Technology",
        slug: "information-technology",
        id: 84680,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/information-technology",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f14d6",
            },
            slug: "it-infrastructure",
            title: "IT Infrastructure",
            id: 19000179,
            parent: "/technology-media-and-telecom/information-technology",
            path: "/technology-media-and-telecom/information-technology/it-infrastructure",
            active: true,
            meta: {
              tags: "IT Infrastructure",
              keywords: "IT Infrastructure",
              description: "IT Infrastructure",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14d7",
                },
                slug: "data-centers",
                title: "Data Centers",
                id: 19000180,
                parent:
                  "/technology-media-and-telecom/information-technology/it-infrastructure",
                path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers",
                active: true,
                meta: {
                  tags: "Data Centers",
                  keywords: "Data Centers",
                  description: "Data Centers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14e5",
                    },
                    slug: "data-center-software",
                    title: "Data Center Software",
                    id: 19000194,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers",
                    path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-software",
                    active: true,
                    meta: {
                      tags: "Data Center Software",
                      keywords: "Data Center Software",
                      description: "Data Center Software",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14d8",
                    },
                    slug: "cloud-and-colocation-data-center",
                    title: "Cloud & Colocation Data Center",
                    id: 19000181,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers",
                    path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/cloud-and-colocation-data-center",
                    active: true,
                    meta: {
                      tags: "Cloud & Colocation Data Center",
                      keywords: "Cloud & Colocation Data Center",
                      description: "Cloud & Colocation Data Center",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14d9",
                    },
                    slug: "data-center-construction",
                    title: "Data Center Construction",
                    id: 19000182,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers",
                    path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction",
                    active: true,
                    meta: {
                      tags: "Data Center Construction",
                      keywords: "Data Center Construction",
                      description: "Data Center Construction",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f14db",
                        },
                        slug: "data-center-it",
                        title: "Data Center IT",
                        id: 19000184,
                        parent:
                          "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction",
                        path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it",
                        active: true,
                        meta: {
                          tags: "Data Center IT",
                          keywords: "Data Center IT",
                          description: "Data Center IT",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f14e2",
                            },
                            slug: "data-center-storage",
                            title: "Data Center Storage",
                            id: 19000191,
                            parent:
                              "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it",
                            path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-storage",
                            active: true,
                            meta: {
                              tags: "Data Center Storage",
                              keywords: "Data Center Storage",
                              description: "Data Center Storage",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f14df",
                            },
                            slug: "data-center-network",
                            title: "Data Center Network",
                            id: 19000188,
                            parent:
                              "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it",
                            path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-network",
                            active: true,
                            meta: {
                              tags: "Data Center Network",
                              keywords: "Data Center Network",
                              description: "Data Center Network",
                            },
                            sub_categories: [
                              {
                                _id: {
                                  $oid: "64ccdc0cc917ae0b494f14e0",
                                },
                                slug: "data-center-switch",
                                title: "Data Center Switch",
                                id: 19000189,
                                parent:
                                  "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-network",
                                path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-network/data-center-switch",
                                active: true,
                                meta: {
                                  tags: "Data Center Switch",
                                  keywords: "Data Center Switch",
                                  description: "Data Center Switch",
                                },
                                sub_categories: [],
                              },
                            ],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f14dc",
                            },
                            slug: "data-center-compute",
                            title: "Data Center Compute",
                            id: 19000185,
                            parent:
                              "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it",
                            path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-compute",
                            active: true,
                            meta: {
                              tags: "Data Center Compute",
                              keywords: "Data Center Compute",
                              description: "Data Center Compute",
                            },
                            sub_categories: [
                              {
                                _id: {
                                  $oid: "64ccdc0cc917ae0b494f14de",
                                },
                                slug: "data-center-server",
                                title: "Data Center Server",
                                id: 19000187,
                                parent:
                                  "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-compute",
                                path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-compute/data-center-server",
                                active: true,
                                meta: {
                                  tags: "Data Center Server",
                                  keywords: "Data Center Server",
                                  description: "Data Center Server",
                                },
                                sub_categories: [],
                              },
                              {
                                _id: {
                                  $oid: "64ccdc0cc917ae0b494f14dd",
                                },
                                slug: "data-center-chipset",
                                title: "Data Center Chipset",
                                id: 19000186,
                                parent:
                                  "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-compute",
                                path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-compute/data-center-chipset",
                                active: true,
                                meta: {
                                  tags: "Data Center Chipset",
                                  keywords: "Data Center Chipset",
                                  description: "Data Center Chipset",
                                },
                                sub_categories: [],
                              },
                            ],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f14e1",
                            },
                            slug: "data-center-racks",
                            title: "Data Center Racks",
                            id: 19000190,
                            parent:
                              "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it",
                            path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-it/data-center-racks",
                            active: true,
                            meta: {
                              tags: "Data Center Racks",
                              keywords: "Data Center Racks",
                              description: "Data Center Racks",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f14da",
                        },
                        slug: "data-center-cooling",
                        title: "Data Center Cooling",
                        id: 19000183,
                        parent:
                          "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction",
                        path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-cooling",
                        active: true,
                        meta: {
                          tags: "Data Center Cooling",
                          keywords: "Data Center Cooling",
                          description: "Data Center Cooling",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f14e3",
                        },
                        slug: "data-center-power",
                        title: "Data Center Power",
                        id: 19000192,
                        parent:
                          "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction",
                        path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-construction/data-center-power",
                        active: true,
                        meta: {
                          tags: "Data Center Power",
                          keywords: "Data Center Power",
                          description: "Data Center Power",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14e4",
                    },
                    slug: "data-center-security",
                    title: "Data Center Security",
                    id: 19000193,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers",
                    path: "/technology-media-and-telecom/information-technology/it-infrastructure/data-centers/data-center-security",
                    active: true,
                    meta: {
                      tags: "Data Center Security",
                      keywords: "Data Center Security",
                      description: "Data Center Security",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14e6",
                },
                slug: "high-performance-computing",
                title: "High-Performance Computing",
                id: 19000195,
                parent:
                  "/technology-media-and-telecom/information-technology/it-infrastructure",
                path: "/technology-media-and-telecom/information-technology/it-infrastructure/high-performance-computing",
                active: true,
                meta: {
                  tags: "High-Performance Computing",
                  keywords: "High-Performance Computing",
                  description: "High-Performance Computing",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f14e7",
            },
            slug: "it-services",
            title: "IT Services",
            id: 19000196,
            parent: "/technology-media-and-telecom/information-technology",
            path: "/technology-media-and-telecom/information-technology/it-services",
            active: true,
            meta: {
              tags: "IT Services",
              keywords: "IT Services",
              description: "IT Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14e8",
                },
                slug: "business-consulting-services",
                title: "Business Consulting Services",
                id: 19000197,
                parent:
                  "/technology-media-and-telecom/information-technology/it-services",
                path: "/technology-media-and-telecom/information-technology/it-services/business-consulting-services",
                active: true,
                meta: {
                  tags: "Business Consulting Services",
                  keywords: "Business Consulting Services",
                  description: "Business Consulting Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14ea",
                },
                slug: "it-consulting-services",
                title: "IT Consulting Services",
                id: 19000199,
                parent:
                  "/technology-media-and-telecom/information-technology/it-services",
                path: "/technology-media-and-telecom/information-technology/it-services/it-consulting-services",
                active: true,
                meta: {
                  tags: "IT Consulting Services",
                  keywords: "IT Consulting Services",
                  description: "IT Consulting Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14eb",
                },
                slug: "managed-services",
                title: "Managed Services",
                id: 19000200,
                parent:
                  "/technology-media-and-telecom/information-technology/it-services",
                path: "/technology-media-and-telecom/information-technology/it-services/managed-services",
                active: true,
                meta: {
                  tags: "Managed Services",
                  keywords: "Managed Services",
                  description: "Managed Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14e9",
                },
                slug: "business-process-services",
                title: "Business Process Services",
                id: 19000198,
                parent:
                  "/technology-media-and-telecom/information-technology/it-services",
                path: "/technology-media-and-telecom/information-technology/it-services/business-process-services",
                active: true,
                meta: {
                  tags: "Business Process Services",
                  keywords: "Business Process Services",
                  description: "Business Process Services",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f14a6",
            },
            slug: "cybersecurity-and-it-security",
            title: "Cybersecurity and IT Security",
            id: 19000131,
            parent: "/technology-media-and-telecom/information-technology",
            path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
            active: true,
            meta: {
              tags: "Cybersecurity and IT Security",
              keywords: "Cybersecurity and IT Security",
              description: "Cybersecurity and IT Security",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14aa",
                },
                slug: "data-security",
                title: "Data Security",
                id: 19000135,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/data-security",
                active: true,
                meta: {
                  tags: "Data Security",
                  keywords: "Data Security",
                  description: "Data Security",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14ad",
                },
                slug: "network-security",
                title: "Network Security",
                id: 19000138,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/network-security",
                active: true,
                meta: {
                  tags: "Network Security",
                  keywords: "Network Security",
                  description: "Network Security",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14ae",
                },
                slug: "risk-management-and-compliance",
                title: "Risk Management & Compliance",
                id: 19000139,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/risk-management-and-compliance",
                active: true,
                meta: {
                  tags: "Risk Management & Compliance",
                  keywords: "Risk Management & Compliance",
                  description: "Risk Management & Compliance",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14b0",
                },
                slug: "threat-management",
                title: "Threat Management",
                id: 19000141,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/threat-management",
                active: true,
                meta: {
                  tags: "Threat Management",
                  keywords: "Threat Management",
                  description: "Threat Management",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14ab",
                },
                slug: "end-point-security",
                title: "End-point Security",
                id: 19000136,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/end-point-security",
                active: true,
                meta: {
                  tags: "End-point Security",
                  keywords: "End-point Security",
                  description: "End-point Security",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14af",
                },
                slug: "security-operations",
                title: "Security Operations",
                id: 19000140,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/security-operations",
                active: true,
                meta: {
                  tags: "Security Operations",
                  keywords: "Security Operations",
                  description: "Security Operations",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14ac",
                },
                slug: "identity-and-access-management",
                title: "Identity & Access Management",
                id: 19000137,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/identity-and-access-management",
                active: true,
                meta: {
                  tags: "Identity & Access Management",
                  keywords: "Identity & Access Management",
                  description: "Identity & Access Management",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14a9",
                },
                slug: "cybersecurity-services",
                title: "Cybersecurity Services",
                id: 19000134,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/cybersecurity-services",
                active: true,
                meta: {
                  tags: "Cybersecurity Services",
                  keywords: "Cybersecurity Services",
                  description: "Cybersecurity Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14a8",
                },
                slug: "cloud-security",
                title: "Cloud Security",
                id: 19000133,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/cloud-security",
                active: true,
                meta: {
                  tags: "Cloud Security",
                  keywords: "Cloud Security",
                  description: "Cloud Security",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14a7",
                },
                slug: "application-security",
                title: "Application Security",
                id: 19000132,
                parent:
                  "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security",
                path: "/technology-media-and-telecom/information-technology/cybersecurity-and-it-security/application-security",
                active: true,
                meta: {
                  tags: "Application Security",
                  keywords: "Application Security",
                  description: "Application Security",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f14b1",
            },
            slug: "it-applications",
            title: "IT Applications",
            id: 19000142,
            parent: "/technology-media-and-telecom/information-technology",
            path: "/technology-media-and-telecom/information-technology/it-applications",
            active: true,
            meta: {
              tags: "IT Applications",
              keywords: "IT Applications",
              description: "IT Applications",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14b2",
                },
                slug: "edutech",
                title: "EduTech",
                id: 19000143,
                parent:
                  "/technology-media-and-telecom/information-technology/it-applications",
                path: "/technology-media-and-telecom/information-technology/it-applications/edutech",
                active: true,
                meta: {
                  tags: "EduTech",
                  keywords: "EduTech",
                  description: "EduTech",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14b3",
                    },
                    slug: "educational-and-learning-platforms",
                    title: "Educational and Learning Platforms",
                    id: 19000144,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-applications/edutech",
                    path: "/technology-media-and-telecom/information-technology/it-applications/edutech/educational-and-learning-platforms",
                    active: true,
                    meta: {
                      tags: "Educational and Learning Platforms",
                      keywords: "Educational and Learning Platforms",
                      description: "Educational and Learning Platforms",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14b6",
                },
                slug: "retail-technology",
                title: "Retail Technology",
                id: 19000147,
                parent:
                  "/technology-media-and-telecom/information-technology/it-applications",
                path: "/technology-media-and-telecom/information-technology/it-applications/retail-technology",
                active: true,
                meta: {
                  tags: "Retail Technology",
                  keywords: "Retail Technology",
                  description: "Retail Technology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14b4",
                },
                slug: "healthcare-information-technology",
                title: "Healthcare Information Technology",
                id: 19000145,
                parent:
                  "/technology-media-and-telecom/information-technology/it-applications",
                path: "/technology-media-and-telecom/information-technology/it-applications/healthcare-information-technology",
                active: true,
                meta: {
                  tags: "Healthcare Information Technology",
                  keywords: "Healthcare Information Technology",
                  description: "Healthcare Information Technology",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14b5",
                },
                slug: "maritime-technology",
                title: "Maritime Technology",
                id: 19000146,
                parent:
                  "/technology-media-and-telecom/information-technology/it-applications",
                path: "/technology-media-and-telecom/information-technology/it-applications/maritime-technology",
                active: true,
                meta: {
                  tags: "Maritime Technology",
                  keywords: "Maritime Technology",
                  description: "Maritime Technology",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f14ec",
            },
            slug: "it-software",
            title: "IT Software",
            id: 19000201,
            parent: "/technology-media-and-telecom/information-technology",
            path: "/technology-media-and-telecom/information-technology/it-software",
            active: true,
            meta: {
              tags: "IT Software",
              keywords: "IT Software",
              description: "IT Software",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f2",
                },
                slug: "development-and-testing-software",
                title: "Development and Testing Software",
                id: 19000207,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/development-and-testing-software",
                active: true,
                meta: {
                  tags: "Development and Testing Software",
                  keywords: "Development and Testing Software",
                  description: "Development and Testing Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f5",
                },
                slug: "financial-software",
                title: "Financial Software",
                id: 19000210,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/financial-software",
                active: true,
                meta: {
                  tags: "Financial Software",
                  keywords: "Financial Software",
                  description: "Financial Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f7",
                },
                slug: "location-intelligence-software",
                title: "Location Intelligence Software",
                id: 19000212,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/location-intelligence-software",
                active: true,
                meta: {
                  tags: "Location Intelligence Software",
                  keywords: "Location Intelligence Software",
                  description: "Location Intelligence Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f3",
                },
                slug: "engineering-software",
                title: "Engineering Software",
                id: 19000208,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/engineering-software",
                active: true,
                meta: {
                  tags: "Engineering Software",
                  keywords: "Engineering Software",
                  description: "Engineering Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14ee",
                },
                slug: "asset-management-software",
                title: "Asset Management Software",
                id: 19000203,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/asset-management-software",
                active: true,
                meta: {
                  tags: "Asset Management Software",
                  keywords: "Asset Management Software",
                  description: "Asset Management Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f0",
                },
                slug: "crm-software",
                title: "CRM Software",
                id: 19000205,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/crm-software",
                active: true,
                meta: {
                  tags: "CRM Software",
                  keywords: "CRM Software",
                  description: "CRM Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f8",
                },
                slug: "network-software",
                title: "Network Software",
                id: 19000213,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/network-software",
                active: true,
                meta: {
                  tags: "Network Software",
                  keywords: "Network Software",
                  description: "Network Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14ef",
                },
                slug: "content-management-software",
                title: "Content Management Software",
                id: 19000204,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/content-management-software",
                active: true,
                meta: {
                  tags: "Content Management Software",
                  keywords: "Content Management Software",
                  description: "Content Management Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f4",
                },
                slug: "enterprise-collaboration-software",
                title: "Enterprise Collaboration Software",
                id: 19000209,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/enterprise-collaboration-software",
                active: true,
                meta: {
                  tags: "Enterprise Collaboration Software",
                  keywords: "Enterprise Collaboration Software",
                  description: "Enterprise Collaboration Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f6",
                },
                slug: "hcm-software",
                title: "HCM Software",
                id: 19000211,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/hcm-software",
                active: true,
                meta: {
                  tags: "HCM Software",
                  keywords: "HCM Software",
                  description: "HCM Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f9",
                },
                slug: "project-management-software",
                title: "Project Management Software",
                id: 19000214,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/project-management-software",
                active: true,
                meta: {
                  tags: "Project Management Software",
                  keywords: "Project Management Software",
                  description: "Project Management Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14fb",
                },
                slug: "system-management-software",
                title: "System Management Software",
                id: 19000216,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/system-management-software",
                active: true,
                meta: {
                  tags: "System Management Software",
                  keywords: "System Management Software",
                  description: "System Management Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14ed",
                },
                slug: "analytics-and-business-intelligence",
                title: "Analytics and Business Intelligence",
                id: 19000202,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/analytics-and-business-intelligence",
                active: true,
                meta: {
                  tags: "Analytics and Business Intelligence",
                  keywords: "Analytics and Business Intelligence",
                  description: "Analytics and Business Intelligence",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14f1",
                },
                slug: "data-management-software",
                title: "Data Management Software",
                id: 19000206,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/data-management-software",
                active: true,
                meta: {
                  tags: "Data Management Software",
                  keywords: "Data Management Software",
                  description: "Data Management Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14fa",
                },
                slug: "supply-chain-management-software",
                title: "Supply Chain Management Software",
                id: 19000215,
                parent:
                  "/technology-media-and-telecom/information-technology/it-software",
                path: "/technology-media-and-telecom/information-technology/it-software/supply-chain-management-software",
                active: true,
                meta: {
                  tags: "Supply Chain Management Software",
                  keywords: "Supply Chain Management Software",
                  description: "Supply Chain Management Software",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f14b7",
            },
            slug: "it-hardware",
            title: "IT Hardware",
            id: 19000148,
            parent: "/technology-media-and-telecom/information-technology",
            path: "/technology-media-and-telecom/information-technology/it-hardware",
            active: true,
            meta: {
              tags: "IT Hardware",
              keywords: "IT Hardware",
              description: "IT Hardware",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14cc",
                },
                slug: "networking-equipment",
                title: "Networking Equipment",
                id: 19000169,
                parent:
                  "/technology-media-and-telecom/information-technology/it-hardware",
                path: "/technology-media-and-telecom/information-technology/it-hardware/networking-equipment",
                active: true,
                meta: {
                  tags: "Networking Equipment",
                  keywords: "Networking Equipment",
                  description: "Networking Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14cd",
                    },
                    slug: "network-switches",
                    title: "Network Switches",
                    id: 19000170,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/networking-equipment",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/networking-equipment/network-switches",
                    active: true,
                    meta: {
                      tags: "Network Switches",
                      keywords: "Network Switches",
                      description: "Network Switches",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14cf",
                    },
                    slug: "wireless-access-points",
                    title: "Wireless Access Points",
                    id: 19000172,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/networking-equipment",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/networking-equipment/wireless-access-points",
                    active: true,
                    meta: {
                      tags: "Wireless Access Points",
                      keywords: "Wireless Access Points",
                      description: "Wireless Access Points",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14ce",
                    },
                    slug: "routers",
                    title: "Routers",
                    id: 19000171,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/networking-equipment",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/networking-equipment/routers",
                    active: true,
                    meta: {
                      tags: "Routers",
                      keywords: "Routers",
                      description: "Routers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14d0",
                },
                slug: "peripherals",
                title: "Peripherals",
                id: 19000173,
                parent:
                  "/technology-media-and-telecom/information-technology/it-hardware",
                path: "/technology-media-and-telecom/information-technology/it-hardware/peripherals",
                active: true,
                meta: {
                  tags: "Peripherals",
                  keywords: "Peripherals",
                  description: "Peripherals",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14d5",
                    },
                    slug: "webcam",
                    title: "Webcam",
                    id: 19000178,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/peripherals",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/peripherals/webcam",
                    active: true,
                    meta: {
                      tags: "Webcam",
                      keywords: "Webcam",
                      description: "Webcam",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14d3",
                    },
                    slug: "monitors",
                    title: "Monitors",
                    id: 19000176,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/peripherals",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/peripherals/monitors",
                    active: true,
                    meta: {
                      tags: "Monitors",
                      keywords: "Monitors",
                      description: "Monitors",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14d2",
                    },
                    slug: "mice",
                    title: "Mice",
                    id: 19000175,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/peripherals",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/peripherals/mice",
                    active: true,
                    meta: {
                      tags: "Mice",
                      keywords: "Mice",
                      description: "Mice",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14d1",
                    },
                    slug: "keyboard",
                    title: "Keyboard",
                    id: 19000174,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/peripherals",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/peripherals/keyboard",
                    active: true,
                    meta: {
                      tags: "Keyboard",
                      keywords: "Keyboard",
                      description: "Keyboard",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14d4",
                    },
                    slug: "printers-and-scanners",
                    title: "Printers & Scanners",
                    id: 19000177,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/peripherals",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/peripherals/printers-and-scanners",
                    active: true,
                    meta: {
                      tags: "Printers & Scanners",
                      keywords: "Printers & Scanners",
                      description: "Printers & Scanners",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14be",
                },
                slug: "computer-hardware-components",
                title: "Computer Hardware Components",
                id: 19000155,
                parent:
                  "/technology-media-and-telecom/information-technology/it-hardware",
                path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components",
                active: true,
                meta: {
                  tags: "Computer Hardware Components",
                  keywords: "Computer Hardware Components",
                  description: "Computer Hardware Components",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14c1",
                    },
                    slug: "gpu",
                    title: "GPU",
                    id: 19000158,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/gpu",
                    active: true,
                    meta: {
                      tags: "GPU",
                      keywords: "GPU",
                      description: "GPU",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14c3",
                    },
                    slug: "pcb",
                    title: "PCB",
                    id: 19000160,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/pcb",
                    active: true,
                    meta: {
                      tags: "PCB",
                      keywords: "PCB",
                      description: "PCB",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14c0",
                    },
                    slug: "cpu",
                    title: "CPU",
                    id: 19000157,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/cpu",
                    active: true,
                    meta: {
                      tags: "CPU",
                      keywords: "CPU",
                      description: "CPU",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14c5",
                    },
                    slug: "storage-devices",
                    title: "Storage Devices",
                    id: 19000162,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices",
                    active: true,
                    meta: {
                      tags: "Storage Devices",
                      keywords: "Storage Devices",
                      description: "Storage Devices",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f14c8",
                        },
                        slug: "next-generation-storage",
                        title: "Next-generation Storage",
                        id: 19000165,
                        parent:
                          "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices",
                        path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices/next-generation-storage",
                        active: true,
                        meta: {
                          tags: "Next-generation Storage",
                          keywords: "Next-generation Storage",
                          description: "Next-generation Storage",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f14c6",
                        },
                        slug: "hdd",
                        title: "HDD",
                        id: 19000163,
                        parent:
                          "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices",
                        path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices/hdd",
                        active: true,
                        meta: {
                          tags: "HDD",
                          keywords: "HDD",
                          description: "HDD",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f14c7",
                        },
                        slug: "nas",
                        title: "NAS",
                        id: 19000164,
                        parent:
                          "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices",
                        path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices/nas",
                        active: true,
                        meta: {
                          tags: "NAS",
                          keywords: "NAS",
                          description: "NAS",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f14cb",
                        },
                        slug: "storage-area-network",
                        title: "Storage Area Network",
                        id: 19000168,
                        parent:
                          "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices",
                        path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices/storage-area-network",
                        active: true,
                        meta: {
                          tags: "Storage Area Network",
                          keywords: "Storage Area Network",
                          description: "Storage Area Network",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f14c9",
                        },
                        slug: "optical-storage",
                        title: "Optical Storage",
                        id: 19000166,
                        parent:
                          "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices",
                        path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices/optical-storage",
                        active: true,
                        meta: {
                          tags: "Optical Storage",
                          keywords: "Optical Storage",
                          description: "Optical Storage",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f14ca",
                        },
                        slug: "ssd",
                        title: "SSD",
                        id: 19000167,
                        parent:
                          "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices",
                        path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/storage-devices/ssd",
                        active: true,
                        meta: {
                          tags: "SSD",
                          keywords: "SSD",
                          description: "SSD",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14c2",
                    },
                    slug: "motherboard",
                    title: "Motherboard",
                    id: 19000159,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/motherboard",
                    active: true,
                    meta: {
                      tags: "Motherboard",
                      keywords: "Motherboard",
                      description: "Motherboard",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14bf",
                    },
                    slug: "cooling-systems",
                    title: "Cooling Systems",
                    id: 19000156,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/cooling-systems",
                    active: true,
                    meta: {
                      tags: "Cooling Systems",
                      keywords: "Cooling Systems",
                      description: "Cooling Systems",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14c4",
                    },
                    slug: "power-supply",
                    title: "Power Supply",
                    id: 19000161,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware-components/power-supply",
                    active: true,
                    meta: {
                      tags: "Power Supply",
                      keywords: "Power Supply",
                      description: "Power Supply",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14b8",
                },
                slug: "computer-hardware",
                title: "Computer Hardware",
                id: 19000149,
                parent:
                  "/technology-media-and-telecom/information-technology/it-hardware",
                path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware",
                active: true,
                meta: {
                  tags: "Computer Hardware",
                  keywords: "Computer Hardware",
                  description: "Computer Hardware",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14b9",
                    },
                    slug: "desktops",
                    title: "Desktops",
                    id: 19000150,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware/desktops",
                    active: true,
                    meta: {
                      tags: "Desktops",
                      keywords: "Desktops",
                      description: "Desktops",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14bc",
                    },
                    slug: "supercomputers",
                    title: "Supercomputers",
                    id: 19000153,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware/supercomputers",
                    active: true,
                    meta: {
                      tags: "Supercomputers",
                      keywords: "Supercomputers",
                      description: "Supercomputers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14ba",
                    },
                    slug: "laptops",
                    title: "Laptops",
                    id: 19000151,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware/laptops",
                    active: true,
                    meta: {
                      tags: "Laptops",
                      keywords: "Laptops",
                      description: "Laptops",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14bd",
                    },
                    slug: "workstations",
                    title: "Workstations",
                    id: 19000154,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware/workstations",
                    active: true,
                    meta: {
                      tags: "Workstations",
                      keywords: "Workstations",
                      description: "Workstations",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14bb",
                    },
                    slug: "servers",
                    title: "Servers",
                    id: 19000152,
                    parent:
                      "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware",
                    path: "/technology-media-and-telecom/information-technology/it-hardware/computer-hardware/servers",
                    active: true,
                    meta: {
                      tags: "Servers",
                      keywords: "Servers",
                      description: "Servers",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a77",
        },
        picture_url: null,
        description: {
          short_description: "Technology Scouting",
          full_description: "Technology Scouting",
        },
        meta: {
          tags: "Technology Scouting",
          keywords: "Technology Scouting",
          description: "Technology Scouting",
        },
        active: true,
        title: "Technology Scouting",
        slug: "technology-scouting",
        id: 84684,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/technology-scouting",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1592",
            },
            slug: "ai",
            title: "AI",
            id: 19000371,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/ai",
            active: true,
            meta: {
              tags: "AI",
              keywords: "AI",
              description: "AI",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1593",
                },
                slug: "computer-vision",
                title: "Computer Vision",
                id: 19000372,
                parent: "/technology-media-and-telecom/technology-scouting/ai",
                path: "/technology-media-and-telecom/technology-scouting/ai/computer-vision",
                active: true,
                meta: {
                  tags: "Computer Vision",
                  keywords: "Computer Vision",
                  description: "Computer Vision",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1594",
            },
            slug: "big-data",
            title: "Big Data",
            id: 19000373,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/big-data",
            active: true,
            meta: {
              tags: "Big Data",
              keywords: "Big Data",
              description: "Big Data",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1596",
            },
            slug: "cloud-computing",
            title: "Cloud Computing",
            id: 19000375,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/cloud-computing",
            active: true,
            meta: {
              tags: "Cloud Computing",
              keywords: "Cloud Computing",
              description: "Cloud Computing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1597",
                },
                slug: "cloud-services",
                title: "Cloud Services",
                id: 19000376,
                parent:
                  "/technology-media-and-telecom/technology-scouting/cloud-computing",
                path: "/technology-media-and-telecom/technology-scouting/cloud-computing/cloud-services",
                active: true,
                meta: {
                  tags: "Cloud Services",
                  keywords: "Cloud Services",
                  description: "Cloud Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f159a",
                },
                slug: "paas",
                title: "PaaS",
                id: 19000379,
                parent:
                  "/technology-media-and-telecom/technology-scouting/cloud-computing",
                path: "/technology-media-and-telecom/technology-scouting/cloud-computing/paas",
                active: true,
                meta: {
                  tags: "PaaS",
                  keywords: "PaaS",
                  description: "PaaS",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f159b",
                },
                slug: "saas",
                title: "SaaS",
                id: 19000380,
                parent:
                  "/technology-media-and-telecom/technology-scouting/cloud-computing",
                path: "/technology-media-and-telecom/technology-scouting/cloud-computing/saas",
                active: true,
                meta: {
                  tags: "SaaS",
                  keywords: "SaaS",
                  description: "SaaS",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1598",
                },
                slug: "cloud-storage",
                title: "Cloud Storage",
                id: 19000377,
                parent:
                  "/technology-media-and-telecom/technology-scouting/cloud-computing",
                path: "/technology-media-and-telecom/technology-scouting/cloud-computing/cloud-storage",
                active: true,
                meta: {
                  tags: "Cloud Storage",
                  keywords: "Cloud Storage",
                  description: "Cloud Storage",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1599",
                },
                slug: "iaas",
                title: "IaaS",
                id: 19000378,
                parent:
                  "/technology-media-and-telecom/technology-scouting/cloud-computing",
                path: "/technology-media-and-telecom/technology-scouting/cloud-computing/iaas",
                active: true,
                meta: {
                  tags: "IaaS",
                  keywords: "IaaS",
                  description: "IaaS",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1595",
            },
            slug: "blockchain",
            title: "Blockchain",
            id: 19000374,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/blockchain",
            active: true,
            meta: {
              tags: "Blockchain",
              keywords: "Blockchain",
              description: "Blockchain",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f159d",
            },
            slug: "digital-twin",
            title: "Digital Twin",
            id: 19000382,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/digital-twin",
            active: true,
            meta: {
              tags: "Digital Twin",
              keywords: "Digital Twin",
              description: "Digital Twin",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f15a2",
            },
            slug: "iot",
            title: "IoT",
            id: 19000387,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/iot",
            active: true,
            meta: {
              tags: "IoT",
              keywords: "IoT",
              description: "IoT",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15bf",
                },
                slug: "iot-connectivity",
                title: "IoT Connectivity",
                id: 19000416,
                parent: "/technology-media-and-telecom/technology-scouting/iot",
                path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                active: true,
                meta: {
                  tags: "IoT Connectivity",
                  keywords: "IoT Connectivity",
                  description: "IoT Connectivity",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15cb",
                    },
                    slug: "wireless-sensor-networks",
                    title: "Wireless Sensor Networks",
                    id: 19000428,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/wireless-sensor-networks",
                    active: true,
                    meta: {
                      tags: "Wireless Sensor Networks",
                      keywords: "Wireless Sensor Networks",
                      description: "Wireless Sensor Networks",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15cd",
                    },
                    slug: "zigbee",
                    title: "Zigbee",
                    id: 19000430,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/zigbee",
                    active: true,
                    meta: {
                      tags: "Zigbee",
                      keywords: "Zigbee",
                      description: "Zigbee",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15ce",
                    },
                    slug: "z-wave",
                    title: "Z-Wave",
                    id: 19000431,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/z-wave",
                    active: true,
                    meta: {
                      tags: "Z-Wave",
                      keywords: "Z-Wave",
                      description: "Z-Wave",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15c8",
                    },
                    slug: "satellite-iot",
                    title: "Satellite IoT",
                    id: 19000425,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/satellite-iot",
                    active: true,
                    meta: {
                      tags: "Satellite IoT",
                      keywords: "Satellite IoT",
                      description: "Satellite IoT",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15ca",
                    },
                    slug: "wired-iot",
                    title: "Wired IoT",
                    id: 19000427,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/wired-iot",
                    active: true,
                    meta: {
                      tags: "Wired IoT",
                      keywords: "Wired IoT",
                      description: "Wired IoT",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15cc",
                    },
                    slug: "wi-sun",
                    title: "Wi-SUN",
                    id: 19000429,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/wi-sun",
                    active: true,
                    meta: {
                      tags: "Wi-SUN",
                      keywords: "Wi-SUN",
                      description: "Wi-SUN",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15c2",
                    },
                    slug: "lpwan",
                    title: "LPWAN",
                    id: 19000419,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan",
                    active: true,
                    meta: {
                      tags: "LPWAN",
                      keywords: "LPWAN",
                      description: "LPWAN",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15c4",
                        },
                        slug: "lte-iot",
                        title: "LTE IoT",
                        id: 19000421,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan/lte-iot",
                        active: true,
                        meta: {
                          tags: "LTE IoT",
                          keywords: "LTE IoT",
                          description: "LTE IoT",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15c7",
                        },
                        slug: "sigfox",
                        title: "Sigfox",
                        id: 19000424,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan/sigfox",
                        active: true,
                        meta: {
                          tags: "Sigfox",
                          keywords: "Sigfox",
                          description: "Sigfox",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15c6",
                        },
                        slug: "nb-iot",
                        title: "NB-IoT",
                        id: 19000423,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan/nb-iot",
                        active: true,
                        meta: {
                          tags: "NB-IoT",
                          keywords: "NB-IoT",
                          description: "NB-IoT",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15c3",
                        },
                        slug: "lora",
                        title: "LoRA",
                        id: 19000420,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan/lora",
                        active: true,
                        meta: {
                          tags: "LoRA",
                          keywords: "LoRA",
                          description: "LoRA",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15c5",
                        },
                        slug: "lte-m",
                        title: "LTE-M",
                        id: 19000422,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/lpwan/lte-m",
                        active: true,
                        meta: {
                          tags: "LTE-M",
                          keywords: "LTE-M",
                          description: "LTE-M",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15c9",
                    },
                    slug: "thread",
                    title: "Thread",
                    id: 19000426,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/thread",
                    active: true,
                    meta: {
                      tags: "Thread",
                      keywords: "Thread",
                      description: "Thread",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15c0",
                    },
                    slug: "cellular-iot",
                    title: "Cellular IoT",
                    id: 19000417,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/cellular-iot",
                    active: true,
                    meta: {
                      tags: "Cellular IoT",
                      keywords: "Cellular IoT",
                      description: "Cellular IoT",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15c1",
                        },
                        slug: "5g-iot",
                        title: "5G IoT",
                        id: 19000418,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/cellular-iot",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-connectivity/cellular-iot/5g-iot",
                        active: true,
                        meta: {
                          tags: "5G IoT",
                          keywords: "5G IoT",
                          description: "5G IoT",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15a3",
                },
                slug: "iot-applications",
                title: "IoT Applications",
                id: 19000388,
                parent: "/technology-media-and-telecom/technology-scouting/iot",
                path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                active: true,
                meta: {
                  tags: "IoT Applications",
                  keywords: "IoT Applications",
                  description: "IoT Applications",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15b4",
                    },
                    slug: "smart-utilities",
                    title: "Smart Utilities",
                    id: 19000405,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities",
                    active: true,
                    meta: {
                      tags: "Smart Utilities",
                      keywords: "Smart Utilities",
                      description: "Smart Utilities",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15ba",
                        },
                        slug: "smart-metering",
                        title: "Smart Metering",
                        id: 19000411,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/smart-metering",
                        active: true,
                        meta: {
                          tags: "Smart Metering",
                          keywords: "Smart Metering",
                          description: "Smart Metering",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15bb",
                            },
                            slug: "smart-electricity-meters",
                            title: "Smart Electricity Meters",
                            id: 19000412,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/smart-metering",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/smart-metering/smart-electricity-meters",
                            active: true,
                            meta: {
                              tags: "Smart Electricity Meters",
                              keywords: "Smart Electricity Meters",
                              description: "Smart Electricity Meters",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15bc",
                            },
                            slug: "smart-gas-meters",
                            title: "Smart Gas Meters",
                            id: 19000413,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/smart-metering",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/smart-metering/smart-gas-meters",
                            active: true,
                            meta: {
                              tags: "Smart Gas Meters",
                              keywords: "Smart Gas Meters",
                              description: "Smart Gas Meters",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15bd",
                            },
                            slug: "smart-water-meters",
                            title: "Smart Water Meters",
                            id: 19000414,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/smart-metering",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/smart-metering/smart-water-meters",
                            active: true,
                            meta: {
                              tags: "Smart Water Meters",
                              keywords: "Smart Water Meters",
                              description: "Smart Water Meters",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15b9",
                        },
                        slug: "smart-grids",
                        title: "Smart Grids",
                        id: 19000410,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/smart-grids",
                        active: true,
                        meta: {
                          tags: "Smart Grids",
                          keywords: "Smart Grids",
                          description: "Smart Grids",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15be",
                        },
                        slug: "smart-water-management",
                        title: "Smart Water Management",
                        id: 19000415,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/smart-water-management",
                        active: true,
                        meta: {
                          tags: "Smart Water Management",
                          keywords: "Smart Water Management",
                          description: "Smart Water Management",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15b5",
                        },
                        slug: "energy-management-systems",
                        title: "Energy Management Systems",
                        id: 19000406,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/energy-management-systems",
                        active: true,
                        meta: {
                          tags: "Energy Management Systems",
                          keywords: "Energy Management Systems",
                          description: "Energy Management Systems",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15b7",
                            },
                            slug: "hems",
                            title: "HEMS",
                            id: 19000408,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/energy-management-systems",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/energy-management-systems/hems",
                            active: true,
                            meta: {
                              tags: "HEMS",
                              keywords: "HEMS",
                              description: "HEMS",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15b6",
                            },
                            slug: "bems",
                            title: "BEMS",
                            id: 19000407,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/energy-management-systems",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/energy-management-systems/bems",
                            active: true,
                            meta: {
                              tags: "BEMS",
                              keywords: "BEMS",
                              description: "BEMS",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15b8",
                            },
                            slug: "iems",
                            title: "IEMS",
                            id: 19000409,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/energy-management-systems",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-utilities/energy-management-systems/iems",
                            active: true,
                            meta: {
                              tags: "IEMS",
                              keywords: "IEMS",
                              description: "IEMS",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15ad",
                    },
                    slug: "smart-home-and-building",
                    title: "Smart Home & Building",
                    id: 19000398,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-home-and-building",
                    active: true,
                    meta: {
                      tags: "Smart Home & Building",
                      keywords: "Smart Home & Building",
                      description: "Smart Home & Building",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15ae",
                        },
                        slug: "home-automation",
                        title: "Home Automation",
                        id: 19000399,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-home-and-building",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-home-and-building/home-automation",
                        active: true,
                        meta: {
                          tags: "Home Automation",
                          keywords: "Home Automation",
                          description: "Home Automation",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15af",
                        },
                        slug: "smart-home-services",
                        title: "Smart Home Services",
                        id: 19000400,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-home-and-building",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-home-and-building/smart-home-services",
                        active: true,
                        meta: {
                          tags: "Smart Home Services",
                          keywords: "Smart Home Services",
                          description: "Smart Home Services",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15b1",
                    },
                    slug: "smart-retail",
                    title: "Smart Retail",
                    id: 19000402,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-retail",
                    active: true,
                    meta: {
                      tags: "Smart Retail",
                      keywords: "Smart Retail",
                      description: "Smart Retail",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15ab",
                    },
                    slug: "smart-agriculture",
                    title: "Smart Agriculture",
                    id: 19000396,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-agriculture",
                    active: true,
                    meta: {
                      tags: "Smart Agriculture",
                      keywords: "Smart Agriculture",
                      description: "Smart Agriculture",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15b0",
                    },
                    slug: "smart-mining",
                    title: "Smart Mining",
                    id: 19000401,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-mining",
                    active: true,
                    meta: {
                      tags: "Smart Mining",
                      keywords: "Smart Mining",
                      description: "Smart Mining",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15a9",
                    },
                    slug: "connected-healthcare",
                    title: "Connected Healthcare",
                    id: 19000394,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-healthcare",
                    active: true,
                    meta: {
                      tags: "Connected Healthcare",
                      keywords: "Connected Healthcare",
                      description: "Connected Healthcare",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15b2",
                    },
                    slug: "smart-transportation-and-logistics",
                    title: "Smart Transportation and Logistics",
                    id: 19000403,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-transportation-and-logistics",
                    active: true,
                    meta: {
                      tags: "Smart Transportation and Logistics",
                      keywords: "Smart Transportation and Logistics",
                      description: "Smart Transportation and Logistics",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15b3",
                        },
                        slug: "parking-management",
                        title: "Parking Management",
                        id: 19000404,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-transportation-and-logistics",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-transportation-and-logistics/parking-management",
                        active: true,
                        meta: {
                          tags: "Parking Management",
                          keywords: "Parking Management",
                          description: "Parking Management",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15a4",
                    },
                    slug: "connected-automotive",
                    title: "Connected Automotive",
                    id: 19000389,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-automotive",
                    active: true,
                    meta: {
                      tags: "Connected Automotive",
                      keywords: "Connected Automotive",
                      description: "Connected Automotive",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15a6",
                        },
                        slug: "fleet-management",
                        title: "Fleet Management",
                        id: 19000391,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-automotive",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-automotive/fleet-management",
                        active: true,
                        meta: {
                          tags: "Fleet Management",
                          keywords: "Fleet Management",
                          description: "Fleet Management",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15a5",
                        },
                        slug: "connected-mobility",
                        title: "Connected Mobility",
                        id: 19000390,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-automotive",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-automotive/connected-mobility",
                        active: true,
                        meta: {
                          tags: "Connected Mobility",
                          keywords: "Connected Mobility",
                          description: "Connected Mobility",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15a7",
                        },
                        slug: "telematics",
                        title: "Telematics",
                        id: 19000392,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-automotive",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-automotive/telematics",
                        active: true,
                        meta: {
                          tags: "Telematics",
                          keywords: "Telematics",
                          description: "Telematics",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15a8",
                            },
                            slug: "insurance-telematics",
                            title: "Insurance Telematics",
                            id: 19000393,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-automotive/telematics",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/connected-automotive/telematics/insurance-telematics",
                            active: true,
                            meta: {
                              tags: "Insurance Telematics",
                              keywords: "Insurance Telematics",
                              description: "Insurance Telematics",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15aa",
                    },
                    slug: "industrial-iot",
                    title: "Industrial IoT",
                    id: 19000395,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/industrial-iot",
                    active: true,
                    meta: {
                      tags: "Industrial IoT",
                      keywords: "Industrial IoT",
                      description: "Industrial IoT",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15ac",
                    },
                    slug: "smart-cities",
                    title: "Smart Cities",
                    id: 19000397,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-applications",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-applications/smart-cities",
                    active: true,
                    meta: {
                      tags: "Smart Cities",
                      keywords: "Smart Cities",
                      description: "Smart Cities",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15cf",
                },
                slug: "iot-hardware-and-devices",
                title: "IoT Hardware and Devices",
                id: 19000432,
                parent: "/technology-media-and-telecom/technology-scouting/iot",
                path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices",
                active: true,
                meta: {
                  tags: "IoT Hardware and Devices",
                  keywords: "IoT Hardware and Devices",
                  description: "IoT Hardware and Devices",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15dd",
                    },
                    slug: "wearables",
                    title: "Wearables",
                    id: 19000446,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables",
                    active: true,
                    meta: {
                      tags: "Wearables",
                      keywords: "Wearables",
                      description: "Wearables",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15e0",
                        },
                        slug: "hmd",
                        title: "HMD",
                        id: 19000449,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables/hmd",
                        active: true,
                        meta: {
                          tags: "HMD",
                          keywords: "HMD",
                          description: "HMD",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15de",
                        },
                        slug: "ear-worn",
                        title: "Ear-worn",
                        id: 19000447,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables/ear-worn",
                        active: true,
                        meta: {
                          tags: "Ear-worn",
                          keywords: "Ear-worn",
                          description: "Ear-worn",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15e1",
                        },
                        slug: "smart-clothing",
                        title: "Smart Clothing",
                        id: 19000450,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables/smart-clothing",
                        active: true,
                        meta: {
                          tags: "Smart Clothing",
                          keywords: "Smart Clothing",
                          description: "Smart Clothing",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15e2",
                        },
                        slug: "smart-patches",
                        title: "Smart Patches",
                        id: 19000451,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables/smart-patches",
                        active: true,
                        meta: {
                          tags: "Smart Patches",
                          keywords: "Smart Patches",
                          description: "Smart Patches",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15e3",
                        },
                        slug: "smart-watch",
                        title: "Smart Watch",
                        id: 19000452,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables/smart-watch",
                        active: true,
                        meta: {
                          tags: "Smart Watch",
                          keywords: "Smart Watch",
                          description: "Smart Watch",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15e4",
                        },
                        slug: "wristband",
                        title: "Wristband",
                        id: 19000453,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables/wristband",
                        active: true,
                        meta: {
                          tags: "Wristband",
                          keywords: "Wristband",
                          description: "Wristband",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15df",
                        },
                        slug: "hearables",
                        title: "Hearables",
                        id: 19000448,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/wearables/hearables",
                        active: true,
                        meta: {
                          tags: "Hearables",
                          keywords: "Hearables",
                          description: "Hearables",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15d1",
                    },
                    slug: "iot-communication-modules",
                    title: "IoT Communication Modules",
                    id: 19000434,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/iot-communication-modules",
                    active: true,
                    meta: {
                      tags: "IoT Communication Modules",
                      keywords: "IoT Communication Modules",
                      description: "IoT Communication Modules",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15d3",
                    },
                    slug: "smart-home-devices",
                    title: "Smart Home Devices",
                    id: 19000436,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices",
                    active: true,
                    meta: {
                      tags: "Smart Home Devices",
                      keywords: "Smart Home Devices",
                      description: "Smart Home Devices",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15d4",
                        },
                        slug: "smart-home-appliances",
                        title: "Smart Home Appliances",
                        id: 19000437,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-home-appliances",
                        active: true,
                        meta: {
                          tags: "Smart Home Appliances",
                          keywords: "Smart Home Appliances",
                          description: "Smart Home Appliances",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15db",
                        },
                        slug: "smart-speakers",
                        title: "Smart Speakers",
                        id: 19000444,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-speakers",
                        active: true,
                        meta: {
                          tags: "Smart Speakers",
                          keywords: "Smart Speakers",
                          description: "Smart Speakers",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15d5",
                        },
                        slug: "smart-home-security-devices",
                        title: "Smart Home Security Devices",
                        id: 19000438,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-home-security-devices",
                        active: true,
                        meta: {
                          tags: "Smart Home Security Devices",
                          keywords: "Smart Home Security Devices",
                          description: "Smart Home Security Devices",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15d6",
                            },
                            slug: "smart-doorbells",
                            title: "Smart Doorbells",
                            id: 19000439,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-home-security-devices",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-home-security-devices/smart-doorbells",
                            active: true,
                            meta: {
                              tags: "Smart Doorbells",
                              keywords: "Smart Doorbells",
                              description: "Smart Doorbells",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15d8",
                            },
                            slug: "smart-security-cameras",
                            title: "Smart Security Cameras",
                            id: 19000441,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-home-security-devices",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-home-security-devices/smart-security-cameras",
                            active: true,
                            meta: {
                              tags: "Smart Security Cameras",
                              keywords: "Smart Security Cameras",
                              description: "Smart Security Cameras",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15d7",
                            },
                            slug: "smart-lock",
                            title: "Smart Lock",
                            id: 19000440,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-home-security-devices",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-home-security-devices/smart-lock",
                            active: true,
                            meta: {
                              tags: "Smart Lock",
                              keywords: "Smart Lock",
                              description: "Smart Lock",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15dc",
                        },
                        slug: "smart-thermostats",
                        title: "Smart Thermostats",
                        id: 19000445,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-thermostats",
                        active: true,
                        meta: {
                          tags: "Smart Thermostats",
                          keywords: "Smart Thermostats",
                          description: "Smart Thermostats",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15d9",
                        },
                        slug: "smart-lighting-systems",
                        title: "Smart Lighting Systems",
                        id: 19000442,
                        parent:
                          "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices",
                        path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-lighting-systems",
                        active: true,
                        meta: {
                          tags: "Smart Lighting Systems",
                          keywords: "Smart Lighting Systems",
                          description: "Smart Lighting Systems",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f15da",
                            },
                            slug: "smart-bulb",
                            title: "Smart Bulb",
                            id: 19000443,
                            parent:
                              "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-lighting-systems",
                            path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/smart-home-devices/smart-lighting-systems/smart-bulb",
                            active: true,
                            meta: {
                              tags: "Smart Bulb",
                              keywords: "Smart Bulb",
                              description: "Smart Bulb",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15d0",
                    },
                    slug: "iot-asset-tracking",
                    title: "IoT Asset Tracking",
                    id: 19000433,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/iot-asset-tracking",
                    active: true,
                    meta: {
                      tags: "IoT Asset Tracking",
                      keywords: "IoT Asset Tracking",
                      description: "IoT Asset Tracking",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15d2",
                    },
                    slug: "iot-gateways",
                    title: "IoT Gateways",
                    id: 19000435,
                    parent:
                      "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices",
                    path: "/technology-media-and-telecom/technology-scouting/iot/iot-hardware-and-devices/iot-gateways",
                    active: true,
                    meta: {
                      tags: "IoT Gateways",
                      keywords: "IoT Gateways",
                      description: "IoT Gateways",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15e5",
                },
                slug: "iot-platforms-and-software",
                title: "IoT Platforms and Software",
                id: 19000454,
                parent: "/technology-media-and-telecom/technology-scouting/iot",
                path: "/technology-media-and-telecom/technology-scouting/iot/iot-platforms-and-software",
                active: true,
                meta: {
                  tags: "IoT Platforms and Software",
                  keywords: "IoT Platforms and Software",
                  description: "IoT Platforms and Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15e6",
                },
                slug: "iot-security",
                title: "IoT Security",
                id: 19000455,
                parent: "/technology-media-and-telecom/technology-scouting/iot",
                path: "/technology-media-and-telecom/technology-scouting/iot/iot-security",
                active: true,
                meta: {
                  tags: "IoT Security",
                  keywords: "IoT Security",
                  description: "IoT Security",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15e7",
                },
                slug: "iot-services",
                title: "IoT Services",
                id: 19000456,
                parent: "/technology-media-and-telecom/technology-scouting/iot",
                path: "/technology-media-and-telecom/technology-scouting/iot/iot-services",
                active: true,
                meta: {
                  tags: "IoT Services",
                  keywords: "IoT Services",
                  description: "IoT Services",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f159e",
            },
            slug: "extended-reality",
            title: "Extended Reality",
            id: 19000383,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/extended-reality",
            active: true,
            meta: {
              tags: "Extended Reality",
              keywords: "Extended Reality",
              description: "Extended Reality",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15a0",
                },
                slug: "mixed-reality",
                title: "Mixed Reality",
                id: 19000385,
                parent:
                  "/technology-media-and-telecom/technology-scouting/extended-reality",
                path: "/technology-media-and-telecom/technology-scouting/extended-reality/mixed-reality",
                active: true,
                meta: {
                  tags: "Mixed Reality",
                  keywords: "Mixed Reality",
                  description: "Mixed Reality",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f159f",
                },
                slug: "augmented-reality-ar",
                title: "Augmented Reality (AR)",
                id: 19000384,
                parent:
                  "/technology-media-and-telecom/technology-scouting/extended-reality",
                path: "/technology-media-and-telecom/technology-scouting/extended-reality/augmented-reality-ar",
                active: true,
                meta: {
                  tags: "Augmented Reality (AR)",
                  keywords: "Augmented Reality (AR)",
                  description: "Augmented Reality (AR)",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15a1",
                },
                slug: "virtual-reality-vr",
                title: "Virtual Reality (VR)",
                id: 19000386,
                parent:
                  "/technology-media-and-telecom/technology-scouting/extended-reality",
                path: "/technology-media-and-telecom/technology-scouting/extended-reality/virtual-reality-vr",
                active: true,
                meta: {
                  tags: "Virtual Reality (VR)",
                  keywords: "Virtual Reality (VR)",
                  description: "Virtual Reality (VR)",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1590",
            },
            slug: "additive-manufacturing",
            title: "Additive Manufacturing",
            id: 19000369,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/additive-manufacturing",
            active: true,
            meta: {
              tags: "Additive Manufacturing",
              keywords: "Additive Manufacturing",
              description: "Additive Manufacturing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1591",
                },
                slug: "3d-printing",
                title: "3D Printing",
                id: 19000370,
                parent:
                  "/technology-media-and-telecom/technology-scouting/additive-manufacturing",
                path: "/technology-media-and-telecom/technology-scouting/additive-manufacturing/3d-printing",
                active: true,
                meta: {
                  tags: "3D Printing",
                  keywords: "3D Printing",
                  description: "3D Printing",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f15e8",
            },
            slug: "metaverse",
            title: "Metaverse",
            id: 19000457,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/metaverse",
            active: true,
            meta: {
              tags: "Metaverse",
              keywords: "Metaverse",
              description: "Metaverse",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f159c",
            },
            slug: "digital-transformation",
            title: "Digital Transformation",
            id: 19000381,
            parent: "/technology-media-and-telecom/technology-scouting",
            path: "/technology-media-and-telecom/technology-scouting/digital-transformation",
            active: true,
            meta: {
              tags: "Digital Transformation",
              keywords: "Digital Transformation",
              description: "Digital Transformation",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a71",
        },
        picture_url: null,
        description: {
          short_description: "Digital Commerce",
          full_description: "Digital Commerce",
        },
        meta: {
          tags: "Digital Commerce",
          keywords: "Digital Commerce",
          description: "Digital Commerce",
        },
        active: true,
        title: "Digital Commerce",
        slug: "digital-commerce",
        id: 84678,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/digital-commerce",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f145a",
            },
            slug: "marketplace",
            title: "Marketplace",
            id: 19000053,
            parent: "/technology-media-and-telecom/digital-commerce",
            path: "/technology-media-and-telecom/digital-commerce/marketplace",
            active: true,
            meta: {
              tags: "Marketplace",
              keywords: "Marketplace",
              description: "Marketplace",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f145b",
                },
                slug: "e-commerce",
                title: "E-Commerce",
                id: 19000054,
                parent:
                  "/technology-media-and-telecom/digital-commerce/marketplace",
                path: "/technology-media-and-telecom/digital-commerce/marketplace/e-commerce",
                active: true,
                meta: {
                  tags: "E-Commerce",
                  keywords: "E-Commerce",
                  description: "E-Commerce",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f145c",
                    },
                    slug: "b2b-e-commerce",
                    title: "B2B E-Commerce",
                    id: 19000055,
                    parent:
                      "/technology-media-and-telecom/digital-commerce/marketplace/e-commerce",
                    path: "/technology-media-and-telecom/digital-commerce/marketplace/e-commerce/b2b-e-commerce",
                    active: true,
                    meta: {
                      tags: "B2B E-Commerce",
                      keywords: "B2B E-Commerce",
                      description: "B2B E-Commerce",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f145e",
                    },
                    slug: "subscription-based-e-commerce",
                    title: "Subscription based E-Commerce",
                    id: 19000057,
                    parent:
                      "/technology-media-and-telecom/digital-commerce/marketplace/e-commerce",
                    path: "/technology-media-and-telecom/digital-commerce/marketplace/e-commerce/subscription-based-e-commerce",
                    active: true,
                    meta: {
                      tags: "Subscription based E-Commerce",
                      keywords: "Subscription based E-Commerce",
                      description: "Subscription based E-Commerce",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f145d",
                    },
                    slug: "d2c-e-commerce",
                    title: "D2C E-Commerce",
                    id: 19000056,
                    parent:
                      "/technology-media-and-telecom/digital-commerce/marketplace/e-commerce",
                    path: "/technology-media-and-telecom/digital-commerce/marketplace/e-commerce/d2c-e-commerce",
                    active: true,
                    meta: {
                      tags: "D2C E-Commerce",
                      keywords: "D2C E-Commerce",
                      description: "D2C E-Commerce",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f145f",
                },
                slug: "m-commerce",
                title: "m-Commerce",
                id: 19000058,
                parent:
                  "/technology-media-and-telecom/digital-commerce/marketplace",
                path: "/technology-media-and-telecom/digital-commerce/marketplace/m-commerce",
                active: true,
                meta: {
                  tags: "m-Commerce",
                  keywords: "m-Commerce",
                  description: "m-Commerce",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1460",
            },
            slug: "payments",
            title: "Payments",
            id: 19000059,
            parent: "/technology-media-and-telecom/digital-commerce",
            path: "/technology-media-and-telecom/digital-commerce/payments",
            active: true,
            meta: {
              tags: "Payments",
              keywords: "Payments",
              description: "Payments",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f146a",
                },
                slug: "payments-security",
                title: "Payments Security",
                id: 19000069,
                parent:
                  "/technology-media-and-telecom/digital-commerce/payments",
                path: "/technology-media-and-telecom/digital-commerce/payments/payments-security",
                active: true,
                meta: {
                  tags: "Payments Security",
                  keywords: "Payments Security",
                  description: "Payments Security",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1466",
                },
                slug: "payment-platforms",
                title: "Payment Platforms",
                id: 19000065,
                parent:
                  "/technology-media-and-telecom/digital-commerce/payments",
                path: "/technology-media-and-telecom/digital-commerce/payments/payment-platforms",
                active: true,
                meta: {
                  tags: "Payment Platforms",
                  keywords: "Payment Platforms",
                  description: "Payment Platforms",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1468",
                    },
                    slug: "digital-asset-platforms",
                    title: "Digital Asset Platforms",
                    id: 19000067,
                    parent:
                      "/technology-media-and-telecom/digital-commerce/payments/payment-platforms",
                    path: "/technology-media-and-telecom/digital-commerce/payments/payment-platforms/digital-asset-platforms",
                    active: true,
                    meta: {
                      tags: "Digital Asset Platforms",
                      keywords: "Digital Asset Platforms",
                      description: "Digital Asset Platforms",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1469",
                    },
                    slug: "digital-remittances",
                    title: "Digital Remittances",
                    id: 19000068,
                    parent:
                      "/technology-media-and-telecom/digital-commerce/payments/payment-platforms",
                    path: "/technology-media-and-telecom/digital-commerce/payments/payment-platforms/digital-remittances",
                    active: true,
                    meta: {
                      tags: "Digital Remittances",
                      keywords: "Digital Remittances",
                      description: "Digital Remittances",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1467",
                    },
                    slug: "chargeback-management",
                    title: "Chargeback Management",
                    id: 19000066,
                    parent:
                      "/technology-media-and-telecom/digital-commerce/payments/payment-platforms",
                    path: "/technology-media-and-telecom/digital-commerce/payments/payment-platforms/chargeback-management",
                    active: true,
                    meta: {
                      tags: "Chargeback Management",
                      keywords: "Chargeback Management",
                      description: "Chargeback Management",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1461",
                },
                slug: "digital-payments",
                title: "Digital Payments",
                id: 19000060,
                parent:
                  "/technology-media-and-telecom/digital-commerce/payments",
                path: "/technology-media-and-telecom/digital-commerce/payments/digital-payments",
                active: true,
                meta: {
                  tags: "Digital Payments",
                  keywords: "Digital Payments",
                  description: "Digital Payments",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1463",
                    },
                    slug: "real-time-payments",
                    title: "Real Time Payments",
                    id: 19000062,
                    parent:
                      "/technology-media-and-telecom/digital-commerce/payments/digital-payments",
                    path: "/technology-media-and-telecom/digital-commerce/payments/digital-payments/real-time-payments",
                    active: true,
                    meta: {
                      tags: "Real Time Payments",
                      keywords: "Real Time Payments",
                      description: "Real Time Payments",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1462",
                    },
                    slug: "mobile-payments",
                    title: "Mobile Payments",
                    id: 19000061,
                    parent:
                      "/technology-media-and-telecom/digital-commerce/payments/digital-payments",
                    path: "/technology-media-and-telecom/digital-commerce/payments/digital-payments/mobile-payments",
                    active: true,
                    meta: {
                      tags: "Mobile Payments",
                      keywords: "Mobile Payments",
                      description: "Mobile Payments",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1464",
                },
                slug: "payment-infrastructure",
                title: "Payment Infrastructure",
                id: 19000063,
                parent:
                  "/technology-media-and-telecom/digital-commerce/payments",
                path: "/technology-media-and-telecom/digital-commerce/payments/payment-infrastructure",
                active: true,
                meta: {
                  tags: "Payment Infrastructure",
                  keywords: "Payment Infrastructure",
                  description: "Payment Infrastructure",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1465",
                    },
                    slug: "pos-terminals",
                    title: "PoS Terminals",
                    id: 19000064,
                    parent:
                      "/technology-media-and-telecom/digital-commerce/payments/payment-infrastructure",
                    path: "/technology-media-and-telecom/digital-commerce/payments/payment-infrastructure/pos-terminals",
                    active: true,
                    meta: {
                      tags: "PoS Terminals",
                      keywords: "PoS Terminals",
                      description: "PoS Terminals",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a70",
        },
        picture_url: null,
        description: {
          short_description: "Automation",
          full_description: "Automation",
        },
        meta: {
          tags: "Automation",
          keywords: "Automation",
          description: "Automation",
        },
        active: true,
        title: "Automation",
        slug: "automation",
        id: 84677,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/automation",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1432",
            },
            slug: "industrial-automation",
            title: "Industrial Automation",
            id: 19000012,
            parent: "/technology-media-and-telecom/automation",
            path: "/technology-media-and-telecom/automation/industrial-automation",
            active: true,
            meta: {
              tags: "Industrial Automation",
              keywords: "Industrial Automation",
              description: "Industrial Automation",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1435",
                },
                slug: "industrial-components",
                title: "Industrial Components",
                id: 19000015,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/industrial-components",
                active: true,
                meta: {
                  tags: "Industrial Components",
                  keywords: "Industrial Components",
                  description: "Industrial Components",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1436",
                },
                slug: "industrial-control-systems",
                title: "Industrial Control Systems",
                id: 19000016,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/industrial-control-systems",
                active: true,
                meta: {
                  tags: "Industrial Control Systems",
                  keywords: "Industrial Control Systems",
                  description: "Industrial Control Systems",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1437",
                    },
                    slug: "plc",
                    title: "PLC",
                    id: 19000017,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/industrial-control-systems",
                    path: "/technology-media-and-telecom/automation/industrial-automation/industrial-control-systems/plc",
                    active: true,
                    meta: {
                      tags: "PLC",
                      keywords: "PLC",
                      description: "PLC",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1438",
                },
                slug: "industrial-imaging",
                title: "Industrial Imaging",
                id: 19000018,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging",
                active: true,
                meta: {
                  tags: "Industrial Imaging",
                  keywords: "Industrial Imaging",
                  description: "Industrial Imaging",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1439",
                    },
                    slug: "industrial-imaging-components",
                    title: "Industrial Imaging Components",
                    id: 19000019,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging",
                    path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-components",
                    active: true,
                    meta: {
                      tags: "Industrial Imaging Components",
                      keywords: "Industrial Imaging Components",
                      description: "Industrial Imaging Components",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f143a",
                        },
                        slug: "industrial-cameras",
                        title: "Industrial Cameras",
                        id: 19000020,
                        parent:
                          "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-components",
                        path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-components/industrial-cameras",
                        active: true,
                        meta: {
                          tags: "Industrial Cameras",
                          keywords: "Industrial Cameras",
                          description: "Industrial Cameras",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f143b",
                    },
                    slug: "industrial-imaging-techniques",
                    title: "Industrial Imaging Techniques",
                    id: 19000021,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging",
                    path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques",
                    active: true,
                    meta: {
                      tags: "Industrial Imaging Techniques",
                      keywords: "Industrial Imaging Techniques",
                      description: "Industrial Imaging Techniques",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1441",
                        },
                        slug: "industrial-x-ray-imaging",
                        title: "Industrial X-Ray Imaging",
                        id: 19000027,
                        parent:
                          "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques",
                        path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/industrial-x-ray-imaging",
                        active: true,
                        meta: {
                          tags: "Industrial X-Ray Imaging",
                          keywords: "Industrial X-Ray Imaging",
                          description: "Industrial X-Ray Imaging",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1442",
                        },
                        slug: "infrared-imaging",
                        title: "Infrared Imaging",
                        id: 19000028,
                        parent:
                          "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques",
                        path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/infrared-imaging",
                        active: true,
                        meta: {
                          tags: "Infrared Imaging",
                          keywords: "Infrared Imaging",
                          description: "Infrared Imaging",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1444",
                        },
                        slug: "thermal-imaging",
                        title: "Thermal Imaging",
                        id: 19000030,
                        parent:
                          "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques",
                        path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/thermal-imaging",
                        active: true,
                        meta: {
                          tags: "Thermal Imaging",
                          keywords: "Thermal Imaging",
                          description: "Thermal Imaging",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1443",
                        },
                        slug: "machine-vision",
                        title: "Machine Vision",
                        id: 19000029,
                        parent:
                          "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques",
                        path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/machine-vision",
                        active: true,
                        meta: {
                          tags: "Machine Vision",
                          keywords: "Machine Vision",
                          description: "Machine Vision",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1445",
                        },
                        slug: "uv-imaging",
                        title: "UV Imaging",
                        id: 19000031,
                        parent:
                          "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques",
                        path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/uv-imaging",
                        active: true,
                        meta: {
                          tags: "UV Imaging",
                          keywords: "UV Imaging",
                          description: "UV Imaging",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f143c",
                        },
                        slug: "active-remote-sensing",
                        title: "Active Remote Sensing",
                        id: 19000022,
                        parent:
                          "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques",
                        path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/active-remote-sensing",
                        active: true,
                        meta: {
                          tags: "Active Remote Sensing",
                          keywords: "Active Remote Sensing",
                          description: "Active Remote Sensing",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f143e",
                            },
                            slug: "radar",
                            title: "RADAR",
                            id: 19000024,
                            parent:
                              "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/active-remote-sensing",
                            path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/active-remote-sensing/radar",
                            active: true,
                            meta: {
                              tags: "RADAR",
                              keywords: "RADAR",
                              description: "RADAR",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f143d",
                            },
                            slug: "lidar",
                            title: "LiDAR",
                            id: 19000023,
                            parent:
                              "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/active-remote-sensing",
                            path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/active-remote-sensing/lidar",
                            active: true,
                            meta: {
                              tags: "LiDAR",
                              keywords: "LiDAR",
                              description: "LiDAR",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f143f",
                            },
                            slug: "sonar",
                            title: "SONAR",
                            id: 19000025,
                            parent:
                              "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/active-remote-sensing",
                            path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/active-remote-sensing/sonar",
                            active: true,
                            meta: {
                              tags: "SONAR",
                              keywords: "SONAR",
                              description: "SONAR",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1440",
                        },
                        slug: "hyperspectral-imaging",
                        title: "Hyperspectral Imaging",
                        id: 19000026,
                        parent:
                          "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques",
                        path: "/technology-media-and-telecom/automation/industrial-automation/industrial-imaging/industrial-imaging-techniques/hyperspectral-imaging",
                        active: true,
                        meta: {
                          tags: "Hyperspectral Imaging",
                          keywords: "Hyperspectral Imaging",
                          description: "Hyperspectral Imaging",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1433",
                },
                slug: "industrial-automation-software",
                title: "Industrial Automation Software",
                id: 19000013,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/industrial-automation-software",
                active: true,
                meta: {
                  tags: "Industrial Automation Software",
                  keywords: "Industrial Automation Software",
                  description: "Industrial Automation Software",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f144c",
                },
                slug: "industrial-security",
                title: "Industrial Security",
                id: 19000038,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/industrial-security",
                active: true,
                meta: {
                  tags: "Industrial Security",
                  keywords: "Industrial Security",
                  description: "Industrial Security",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f144e",
                },
                slug: "material-handling",
                title: "Material Handling",
                id: 19000040,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/material-handling",
                active: true,
                meta: {
                  tags: "Material Handling",
                  keywords: "Material Handling",
                  description: "Material Handling",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f144f",
                    },
                    slug: "asrs",
                    title: "ASRS",
                    id: 19000041,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/material-handling",
                    path: "/technology-media-and-telecom/automation/industrial-automation/material-handling/asrs",
                    active: true,
                    meta: {
                      tags: "ASRS",
                      keywords: "ASRS",
                      description: "ASRS",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1450",
                    },
                    slug: "conveyors-and-sortations",
                    title: "Conveyors and Sortations",
                    id: 19000042,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/material-handling",
                    path: "/technology-media-and-telecom/automation/industrial-automation/material-handling/conveyors-and-sortations",
                    active: true,
                    meta: {
                      tags: "Conveyors and Sortations",
                      keywords: "Conveyors and Sortations",
                      description: "Conveyors and Sortations",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1454",
                    },
                    slug: "palletizers",
                    title: "Palletizers",
                    id: 19000046,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/material-handling",
                    path: "/technology-media-and-telecom/automation/industrial-automation/material-handling/palletizers",
                    active: true,
                    meta: {
                      tags: "Palletizers",
                      keywords: "Palletizers",
                      description: "Palletizers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1451",
                    },
                    slug: "mobile-robots",
                    title: "Mobile Robots",
                    id: 19000043,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/material-handling",
                    path: "/technology-media-and-telecom/automation/industrial-automation/material-handling/mobile-robots",
                    active: true,
                    meta: {
                      tags: "Mobile Robots",
                      keywords: "Mobile Robots",
                      description: "Mobile Robots",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1452",
                        },
                        slug: "agv",
                        title: "AGV",
                        id: 19000044,
                        parent:
                          "/technology-media-and-telecom/automation/industrial-automation/material-handling/mobile-robots",
                        path: "/technology-media-and-telecom/automation/industrial-automation/material-handling/mobile-robots/agv",
                        active: true,
                        meta: {
                          tags: "AGV",
                          keywords: "AGV",
                          description: "AGV",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f1453",
                            },
                            slug: "amr",
                            title: "AMR",
                            id: 19000045,
                            parent:
                              "/technology-media-and-telecom/automation/industrial-automation/material-handling/mobile-robots/agv",
                            path: "/technology-media-and-telecom/automation/industrial-automation/material-handling/mobile-robots/agv/amr",
                            active: true,
                            meta: {
                              tags: "AMR",
                              keywords: "AMR",
                              description: "AMR",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1446",
                },
                slug: "industrial-machinery-and-equipment",
                title: "Industrial Machinery and Equipment",
                id: 19000032,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/industrial-machinery-and-equipment",
                active: true,
                meta: {
                  tags: "Industrial Machinery and Equipment",
                  keywords: "Industrial Machinery and Equipment",
                  description: "Industrial Machinery and Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1447",
                    },
                    slug: "commercial-and-industrial-pumps",
                    title: "Commercial and Industrial Pumps",
                    id: 19000033,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/industrial-machinery-and-equipment",
                    path: "/technology-media-and-telecom/automation/industrial-automation/industrial-machinery-and-equipment/commercial-and-industrial-pumps",
                    active: true,
                    meta: {
                      tags: "Commercial and Industrial Pumps",
                      keywords: "Commercial and Industrial Pumps",
                      description: "Commercial and Industrial Pumps",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1448",
                    },
                    slug: "commercial-and-industrial-valves",
                    title: "Commercial and Industrial Valves",
                    id: 19000034,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/industrial-machinery-and-equipment",
                    path: "/technology-media-and-telecom/automation/industrial-automation/industrial-machinery-and-equipment/commercial-and-industrial-valves",
                    active: true,
                    meta: {
                      tags: "Commercial and Industrial Valves",
                      keywords: "Commercial and Industrial Valves",
                      description: "Commercial and Industrial Valves",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1449",
                    },
                    slug: "motors-and-drives",
                    title: "Motors and Drives",
                    id: 19000035,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/industrial-machinery-and-equipment",
                    path: "/technology-media-and-telecom/automation/industrial-automation/industrial-machinery-and-equipment/motors-and-drives",
                    active: true,
                    meta: {
                      tags: "Motors and Drives",
                      keywords: "Motors and Drives",
                      description: "Motors and Drives",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f144a",
                },
                slug: "industrial-maintenance",
                title: "Industrial Maintenance",
                id: 19000036,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/industrial-maintenance",
                active: true,
                meta: {
                  tags: "Industrial Maintenance",
                  keywords: "Industrial Maintenance",
                  description: "Industrial Maintenance",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1459",
                },
                slug: "sensors-and-actuators",
                title: "Sensors and Actuators",
                id: 19000051,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/sensors-and-actuators",
                active: true,
                meta: {
                  tags: "Sensors and Actuators",
                  keywords: "Sensors and Actuators",
                  description: "Sensors and Actuators",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1434",
                },
                slug: "industrial-communication",
                title: "Industrial Communication",
                id: 19000014,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/industrial-communication",
                active: true,
                meta: {
                  tags: "Industrial Communication",
                  keywords: "Industrial Communication",
                  description: "Industrial Communication",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f144b",
                },
                slug: "industrial-safety",
                title: "Industrial Safety",
                id: 19000037,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/industrial-safety",
                active: true,
                meta: {
                  tags: "Industrial Safety",
                  keywords: "Industrial Safety",
                  description: "Industrial Safety",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f144d",
                },
                slug: "lab-automation",
                title: "Lab Automation",
                id: 19000039,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/lab-automation",
                active: true,
                meta: {
                  tags: "Lab Automation",
                  keywords: "Lab Automation",
                  description: "Lab Automation",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1455",
                },
                slug: "robotics",
                title: "Robotics",
                id: 19000047,
                parent:
                  "/technology-media-and-telecom/automation/industrial-automation",
                path: "/technology-media-and-telecom/automation/industrial-automation/robotics",
                active: true,
                meta: {
                  tags: "Robotics",
                  keywords: "Robotics",
                  description: "Robotics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1458",
                    },
                    slug: "service-robotics",
                    title: "Service Robotics",
                    id: 19000050,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/robotics",
                    path: "/technology-media-and-telecom/automation/industrial-automation/robotics/service-robotics",
                    active: true,
                    meta: {
                      tags: "Service Robotics",
                      keywords: "Service Robotics",
                      description: "Service Robotics",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1457",
                    },
                    slug: "robotics-components",
                    title: "Robotics Components",
                    id: 19000049,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/robotics",
                    path: "/technology-media-and-telecom/automation/industrial-automation/robotics/robotics-components",
                    active: true,
                    meta: {
                      tags: "Robotics Components",
                      keywords: "Robotics Components",
                      description: "Robotics Components",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1456",
                    },
                    slug: "industrial-robotics",
                    title: "Industrial Robotics",
                    id: 19000048,
                    parent:
                      "/technology-media-and-telecom/automation/industrial-automation/robotics",
                    path: "/technology-media-and-telecom/automation/industrial-automation/robotics/industrial-robotics",
                    active: true,
                    meta: {
                      tags: "Industrial Robotics",
                      keywords: "Industrial Robotics",
                      description: "Industrial Robotics",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1428",
            },
            slug: "building-automation",
            title: "Building Automation",
            id: 19000002,
            parent: "/technology-media-and-telecom/automation",
            path: "/technology-media-and-telecom/automation/building-automation",
            active: true,
            meta: {
              tags: "Building Automation",
              keywords: "Building Automation",
              description: "Building Automation",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f142d",
                },
                slug: "facility-management",
                title: "Facility Management",
                id: 19000007,
                parent:
                  "/technology-media-and-telecom/automation/building-automation",
                path: "/technology-media-and-telecom/automation/building-automation/facility-management",
                active: true,
                meta: {
                  tags: "Facility Management",
                  keywords: "Facility Management",
                  description: "Facility Management",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1429",
                },
                slug: "building-design-services",
                title: "Building Design Services",
                id: 19000003,
                parent:
                  "/technology-media-and-telecom/automation/building-automation",
                path: "/technology-media-and-telecom/automation/building-automation/building-design-services",
                active: true,
                meta: {
                  tags: "Building Design Services",
                  keywords: "Building Design Services",
                  description: "Building Design Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f142a",
                    },
                    slug: "architectural-and-engineering-services",
                    title: "Architectural and Engineering Services",
                    id: 19000004,
                    parent:
                      "/technology-media-and-telecom/automation/building-automation/building-design-services",
                    path: "/technology-media-and-telecom/automation/building-automation/building-design-services/architectural-and-engineering-services",
                    active: true,
                    meta: {
                      tags: "Architectural and Engineering Services",
                      keywords: "Architectural and Engineering Services",
                      description: "Architectural and Engineering Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f142b",
                },
                slug: "building-energy-management-systems",
                title: "Building Energy Management Systems",
                id: 19000005,
                parent:
                  "/technology-media-and-telecom/automation/building-automation",
                path: "/technology-media-and-telecom/automation/building-automation/building-energy-management-systems",
                active: true,
                meta: {
                  tags: "Building Energy Management Systems",
                  keywords: "Building Energy Management Systems",
                  description: "Building Energy Management Systems",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f142c",
                },
                slug: "elevators",
                title: "Elevators",
                id: 19000006,
                parent:
                  "/technology-media-and-telecom/automation/building-automation",
                path: "/technology-media-and-telecom/automation/building-automation/elevators",
                active: true,
                meta: {
                  tags: "Elevators",
                  keywords: "Elevators",
                  description: "Elevators",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f142f",
                },
                slug: "hvac",
                title: "HVAC",
                id: 19000009,
                parent:
                  "/technology-media-and-telecom/automation/building-automation",
                path: "/technology-media-and-telecom/automation/building-automation/hvac",
                active: true,
                meta: {
                  tags: "HVAC",
                  keywords: "HVAC",
                  description: "HVAC",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1430",
                    },
                    slug: "district-heating-and-cooling",
                    title: "District Heating and Cooling",
                    id: 19000010,
                    parent:
                      "/technology-media-and-telecom/automation/building-automation/hvac",
                    path: "/technology-media-and-telecom/automation/building-automation/hvac/district-heating-and-cooling",
                    active: true,
                    meta: {
                      tags: "District Heating and Cooling",
                      keywords: "District Heating and Cooling",
                      description: "District Heating and Cooling",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1431",
                },
                slug: "self-storage-services",
                title: "Self-storage Services",
                id: 19000011,
                parent:
                  "/technology-media-and-telecom/automation/building-automation",
                path: "/technology-media-and-telecom/automation/building-automation/self-storage-services",
                active: true,
                meta: {
                  tags: "Self-storage Services",
                  keywords: "Self-storage Services",
                  description: "Self-storage Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f142e",
                },
                slug: "fire-detection-and-suppression",
                title: "Fire Detection and Suppression",
                id: 19000008,
                parent:
                  "/technology-media-and-telecom/automation/building-automation",
                path: "/technology-media-and-telecom/automation/building-automation/fire-detection-and-suppression",
                active: true,
                meta: {
                  tags: "Fire Detection and Suppression",
                  keywords: "Fire Detection and Suppression",
                  description: "Fire Detection and Suppression",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a74",
        },
        picture_url: null,
        description: {
          short_description: "Media and Entertainment",
          full_description: "Media and Entertainment",
        },
        meta: {
          tags: "Media and Entertainment",
          keywords: "Media and Entertainment",
          description: "Media and Entertainment",
        },
        active: true,
        title: "Media and Entertainment",
        slug: "media-and-entertainment",
        id: 84681,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/media-and-entertainment",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f150a",
            },
            slug: "broadcasting-networks",
            title: "Broadcasting Networks",
            id: 19000232,
            parent: "/technology-media-and-telecom/media-and-entertainment",
            path: "/technology-media-and-telecom/media-and-entertainment/broadcasting-networks",
            active: true,
            meta: {
              tags: "Broadcasting Networks",
              keywords: "Broadcasting Networks",
              description: "Broadcasting Networks",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f151f",
            },
            slug: "publishing-and-print-media",
            title: "Publishing and Print Media",
            id: 19000253,
            parent: "/technology-media-and-telecom/media-and-entertainment",
            path: "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media",
            active: true,
            meta: {
              tags: "Publishing and Print Media",
              keywords: "Publishing and Print Media",
              description: "Publishing and Print Media",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1524",
                },
                slug: "newspaper-publishing",
                title: "Newspaper Publishing",
                id: 19000258,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media",
                path: "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media/newspaper-publishing",
                active: true,
                meta: {
                  tags: "Newspaper Publishing",
                  keywords: "Newspaper Publishing",
                  description: "Newspaper Publishing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1521",
                },
                slug: "digital-publishing",
                title: "Digital Publishing",
                id: 19000255,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media",
                path: "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media/digital-publishing",
                active: true,
                meta: {
                  tags: "Digital Publishing",
                  keywords: "Digital Publishing",
                  description: "Digital Publishing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1520",
                },
                slug: "book-publishing",
                title: "Book Publishing",
                id: 19000254,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media",
                path: "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media/book-publishing",
                active: true,
                meta: {
                  tags: "Book Publishing",
                  keywords: "Book Publishing",
                  description: "Book Publishing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1523",
                },
                slug: "magazine-publishing",
                title: "Magazine Publishing",
                id: 19000257,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media",
                path: "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media/magazine-publishing",
                active: true,
                meta: {
                  tags: "Magazine Publishing",
                  keywords: "Magazine Publishing",
                  description: "Magazine Publishing",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1522",
                },
                slug: "e-book-platforms",
                title: "E-book Platforms",
                id: 19000256,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media",
                path: "/technology-media-and-telecom/media-and-entertainment/publishing-and-print-media/e-book-platforms",
                active: true,
                meta: {
                  tags: "E-book Platforms",
                  keywords: "E-book Platforms",
                  description: "E-book Platforms",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f14fc",
            },
            slug: "advertising",
            title: "Advertising",
            id: 19000218,
            parent: "/technology-media-and-telecom/media-and-entertainment",
            path: "/technology-media-and-telecom/media-and-entertainment/advertising",
            active: true,
            meta: {
              tags: "Advertising",
              keywords: "Advertising",
              description: "Advertising",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14fd",
                },
                slug: "advertising-and-branding-agencies",
                title: "Advertising and Branding Agencies",
                id: 19000219,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/advertising",
                path: "/technology-media-and-telecom/media-and-entertainment/advertising/advertising-and-branding-agencies",
                active: true,
                meta: {
                  tags: "Advertising and Branding Agencies",
                  keywords: "Advertising and Branding Agencies",
                  description: "Advertising and Branding Agencies",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1508",
                },
                slug: "sponsorship-and-event-management",
                title: "Sponsorship and Event Management",
                id: 19000230,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/advertising",
                path: "/technology-media-and-telecom/media-and-entertainment/advertising/sponsorship-and-event-management",
                active: true,
                meta: {
                  tags: "Sponsorship and Event Management",
                  keywords: "Sponsorship and Event Management",
                  description: "Sponsorship and Event Management",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1504",
                },
                slug: "digital-marketing",
                title: "Digital Marketing",
                id: 19000226,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/advertising",
                path: "/technology-media-and-telecom/media-and-entertainment/advertising/digital-marketing",
                active: true,
                meta: {
                  tags: "Digital Marketing",
                  keywords: "Digital Marketing",
                  description: "Digital Marketing",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1505",
                    },
                    slug: "in-app-advertising",
                    title: "In-app Advertising",
                    id: 19000227,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/advertising/digital-marketing",
                    path: "/technology-media-and-telecom/media-and-entertainment/advertising/digital-marketing/in-app-advertising",
                    active: true,
                    meta: {
                      tags: "In-app Advertising",
                      keywords: "In-app Advertising",
                      description: "In-app Advertising",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1506",
                    },
                    slug: "influencer-advertising",
                    title: "Influencer Advertising",
                    id: 19000228,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/advertising/digital-marketing",
                    path: "/technology-media-and-telecom/media-and-entertainment/advertising/digital-marketing/influencer-advertising",
                    active: true,
                    meta: {
                      tags: "Influencer Advertising",
                      keywords: "Influencer Advertising",
                      description: "Influencer Advertising",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14fe",
                },
                slug: "digital-advertising",
                title: "Digital Advertising",
                id: 19000220,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/advertising",
                path: "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising",
                active: true,
                meta: {
                  tags: "Digital Advertising",
                  keywords: "Digital Advertising",
                  description: "Digital Advertising",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14ff",
                    },
                    slug: "ooh-advertising",
                    title: "OOH Advertising",
                    id: 19000221,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising",
                    path: "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising/ooh-advertising",
                    active: true,
                    meta: {
                      tags: "OOH Advertising",
                      keywords: "OOH Advertising",
                      description: "OOH Advertising",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1500",
                        },
                        slug: "digital-ooh-advertising",
                        title: "Digital OOH Advertising",
                        id: 19000222,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising/ooh-advertising",
                        path: "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising/ooh-advertising/digital-ooh-advertising",
                        active: true,
                        meta: {
                          tags: "Digital OOH Advertising",
                          keywords: "Digital OOH Advertising",
                          description: "Digital OOH Advertising",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1501",
                    },
                    slug: "print-advertising",
                    title: "Print Advertising",
                    id: 19000223,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising",
                    path: "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising/print-advertising",
                    active: true,
                    meta: {
                      tags: "Print Advertising",
                      keywords: "Print Advertising",
                      description: "Print Advertising",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1502",
                    },
                    slug: "social-media-advertising",
                    title: "Social Media Advertising",
                    id: 19000224,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising",
                    path: "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising/social-media-advertising",
                    active: true,
                    meta: {
                      tags: "Social Media Advertising",
                      keywords: "Social Media Advertising",
                      description: "Social Media Advertising",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1503",
                    },
                    slug: "tv-and-radio-advertising",
                    title: "TV and Radio Advertising",
                    id: 19000225,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising",
                    path: "/technology-media-and-telecom/media-and-entertainment/advertising/digital-advertising/tv-and-radio-advertising",
                    active: true,
                    meta: {
                      tags: "TV and Radio Advertising",
                      keywords: "TV and Radio Advertising",
                      description: "TV and Radio Advertising",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1507",
                },
                slug: "public-relations",
                title: "Public Relations",
                id: 19000229,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/advertising",
                path: "/technology-media-and-telecom/media-and-entertainment/advertising/public-relations",
                active: true,
                meta: {
                  tags: "Public Relations",
                  keywords: "Public Relations",
                  description: "Public Relations",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f150b",
            },
            slug: "film-and-tv-production",
            title: "Film and TV Production",
            id: 19000233,
            parent: "/technology-media-and-telecom/media-and-entertainment",
            path: "/technology-media-and-telecom/media-and-entertainment/film-and-tv-production",
            active: true,
            meta: {
              tags: "Film and TV Production",
              keywords: "Film and TV Production",
              description: "Film and TV Production",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1526",
            },
            slug: "tv-and-online-video",
            title: "TV & Online Video",
            id: 19000260,
            parent: "/technology-media-and-telecom/media-and-entertainment",
            path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video",
            active: true,
            meta: {
              tags: "TV & Online Video",
              keywords: "TV & Online Video",
              description: "TV & Online Video",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1537",
                },
                slug: "video-streaming-devices",
                title: "Video Streaming Devices",
                id: 19000277,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video",
                path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-streaming-devices",
                active: true,
                meta: {
                  tags: "Video Streaming Devices",
                  keywords: "Video Streaming Devices",
                  description: "Video Streaming Devices",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1527",
                },
                slug: "video-content-services",
                title: "Video Content Services",
                id: 19000261,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video",
                path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services",
                active: true,
                meta: {
                  tags: "Video Content Services",
                  keywords: "Video Content Services",
                  description: "Video Content Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1533",
                    },
                    slug: "pay-tv",
                    title: "Pay TV",
                    id: 19000273,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services",
                    path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/pay-tv",
                    active: true,
                    meta: {
                      tags: "Pay TV",
                      keywords: "Pay TV",
                      description: "Pay TV",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1535",
                        },
                        slug: "iptv",
                        title: "IPTV",
                        id: 19000275,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/pay-tv",
                        path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/pay-tv/iptv",
                        active: true,
                        meta: {
                          tags: "IPTV",
                          keywords: "IPTV",
                          description: "IPTV",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1534",
                        },
                        slug: "cable-tv",
                        title: "Cable TV",
                        id: 19000274,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/pay-tv",
                        path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/pay-tv/cable-tv",
                        active: true,
                        meta: {
                          tags: "Cable TV",
                          keywords: "Cable TV",
                          description: "Cable TV",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1536",
                        },
                        slug: "satellite-tv",
                        title: "Satellite TV",
                        id: 19000276,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/pay-tv",
                        path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/pay-tv/satellite-tv",
                        active: true,
                        meta: {
                          tags: "Satellite TV",
                          keywords: "Satellite TV",
                          description: "Satellite TV",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1528",
                    },
                    slug: "ott",
                    title: "OTT",
                    id: 19000262,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services",
                    path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott",
                    active: true,
                    meta: {
                      tags: "OTT",
                      keywords: "OTT",
                      description: "OTT",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1529",
                        },
                        slug: "live-streaming-platforms",
                        title: "Live Streaming Platforms",
                        id: 19000263,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott",
                        path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/live-streaming-platforms",
                        active: true,
                        meta: {
                          tags: "Live Streaming Platforms",
                          keywords: "Live Streaming Platforms",
                          description: "Live Streaming Platforms",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f152b",
                        },
                        slug: "sports-streaming-platforms",
                        title: "Sports Streaming Platforms",
                        id: 19000265,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott",
                        path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/sports-streaming-platforms",
                        active: true,
                        meta: {
                          tags: "Sports Streaming Platforms",
                          keywords: "Sports Streaming Platforms",
                          description: "Sports Streaming Platforms",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f152d",
                        },
                        slug: "video-on-demand",
                        title: "Video on Demand",
                        id: 19000267,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott",
                        path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand",
                        active: true,
                        meta: {
                          tags: "Video on Demand",
                          keywords: "Video on Demand",
                          description: "Video on Demand",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f1532",
                            },
                            slug: "tvod",
                            title: "TVOD",
                            id: 19000272,
                            parent:
                              "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand",
                            path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand/tvod",
                            active: true,
                            meta: {
                              tags: "TVOD",
                              keywords: "TVOD",
                              description: "TVOD",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f152f",
                            },
                            slug: "broadcast-vod",
                            title: "Broadcast VoD",
                            id: 19000269,
                            parent:
                              "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand",
                            path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand/broadcast-vod",
                            active: true,
                            meta: {
                              tags: "Broadcast VoD",
                              keywords: "Broadcast VoD",
                              description: "Broadcast VoD",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f1531",
                            },
                            slug: "svod",
                            title: "SVOD",
                            id: 19000271,
                            parent:
                              "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand",
                            path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand/svod",
                            active: true,
                            meta: {
                              tags: "SVOD",
                              keywords: "SVOD",
                              description: "SVOD",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f152e",
                            },
                            slug: "avod",
                            title: "AVOD",
                            id: 19000268,
                            parent:
                              "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand",
                            path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand/avod",
                            active: true,
                            meta: {
                              tags: "AVOD",
                              keywords: "AVOD",
                              description: "AVOD",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f1530",
                            },
                            slug: "pay-per-view",
                            title: "Pay-Per-View",
                            id: 19000270,
                            parent:
                              "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand",
                            path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/video-on-demand/pay-per-view",
                            active: true,
                            meta: {
                              tags: "Pay-Per-View",
                              keywords: "Pay-Per-View",
                              description: "Pay-Per-View",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f152c",
                        },
                        slug: "user-generated-content-platform",
                        title: "User-Generated Content Platform",
                        id: 19000266,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott",
                        path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/user-generated-content-platform",
                        active: true,
                        meta: {
                          tags: "User-Generated Content Platform",
                          keywords: "User-Generated Content Platform",
                          description: "User-Generated Content Platform",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f152a",
                        },
                        slug: "news-and-media-streaming",
                        title: "News and Media Streaming",
                        id: 19000264,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott",
                        path: "/technology-media-and-telecom/media-and-entertainment/tv-and-online-video/video-content-services/ott/news-and-media-streaming",
                        active: true,
                        meta: {
                          tags: "News and Media Streaming",
                          keywords: "News and Media Streaming",
                          description: "News and Media Streaming",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1519",
            },
            slug: "music-and-digital-audio",
            title: "Music & Digital Audio",
            id: 19000247,
            parent: "/technology-media-and-telecom/media-and-entertainment",
            path: "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio",
            active: true,
            meta: {
              tags: "Music & Digital Audio",
              keywords: "Music & Digital Audio",
              description: "Music & Digital Audio",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f151a",
                },
                slug: "internet-radio-services",
                title: "Internet Radio Services",
                id: 19000248,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio",
                path: "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio/internet-radio-services",
                active: true,
                meta: {
                  tags: "Internet Radio Services",
                  keywords: "Internet Radio Services",
                  description: "Internet Radio Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f151b",
                },
                slug: "live-music-and-concerts",
                title: "Live Music and Concerts",
                id: 19000249,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio",
                path: "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio/live-music-and-concerts",
                active: true,
                meta: {
                  tags: "Live Music and Concerts",
                  keywords: "Live Music and Concerts",
                  description: "Live Music and Concerts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f151c",
                },
                slug: "music-licensing-and-royalties",
                title: "Music Licensing and Royalties",
                id: 19000250,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio",
                path: "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio/music-licensing-and-royalties",
                active: true,
                meta: {
                  tags: "Music Licensing and Royalties",
                  keywords: "Music Licensing and Royalties",
                  description: "Music Licensing and Royalties",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f151d",
                },
                slug: "music-production-and-recording",
                title: "Music Production and Recording",
                id: 19000251,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio",
                path: "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio/music-production-and-recording",
                active: true,
                meta: {
                  tags: "Music Production and Recording",
                  keywords: "Music Production and Recording",
                  description: "Music Production and Recording",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f151e",
                },
                slug: "music-streaming-platforms",
                title: "Music Streaming Platforms",
                id: 19000252,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio",
                path: "/technology-media-and-telecom/media-and-entertainment/music-and-digital-audio/music-streaming-platforms",
                active: true,
                meta: {
                  tags: "Music Streaming Platforms",
                  keywords: "Music Streaming Platforms",
                  description: "Music Streaming Platforms",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f150c",
            },
            slug: "gaming",
            title: "Gaming",
            id: 19000234,
            parent: "/technology-media-and-telecom/media-and-entertainment",
            path: "/technology-media-and-telecom/media-and-entertainment/gaming",
            active: true,
            meta: {
              tags: "Gaming",
              keywords: "Gaming",
              description: "Gaming",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f150d",
                },
                slug: "esports",
                title: "eSports",
                id: 19000235,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/gaming",
                path: "/technology-media-and-telecom/media-and-entertainment/gaming/esports",
                active: true,
                meta: {
                  tags: "eSports",
                  keywords: "eSports",
                  description: "eSports",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1511",
                },
                slug: "gaming-platforms",
                title: "Gaming Platforms",
                id: 19000239,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/gaming",
                path: "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms",
                active: true,
                meta: {
                  tags: "Gaming Platforms",
                  keywords: "Gaming Platforms",
                  description: "Gaming Platforms",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1513",
                    },
                    slug: "console-games",
                    title: "Console Games",
                    id: 19000241,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms",
                    path: "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/console-games",
                    active: true,
                    meta: {
                      tags: "Console Games",
                      keywords: "Console Games",
                      description: "Console Games",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1514",
                        },
                        slug: "hardware-console",
                        title: "Hardware Console",
                        id: 19000242,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/console-games",
                        path: "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/console-games/hardware-console",
                        active: true,
                        meta: {
                          tags: "Hardware Console",
                          keywords: "Hardware Console",
                          description: "Hardware Console",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1515",
                    },
                    slug: "mobile-games",
                    title: "Mobile Games",
                    id: 19000243,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms",
                    path: "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/mobile-games",
                    active: true,
                    meta: {
                      tags: "Mobile Games",
                      keywords: "Mobile Games",
                      description: "Mobile Games",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1516",
                    },
                    slug: "pc-games",
                    title: "PC Games",
                    id: 19000244,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms",
                    path: "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/pc-games",
                    active: true,
                    meta: {
                      tags: "PC Games",
                      keywords: "PC Games",
                      description: "PC Games",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1517",
                        },
                        slug: "downloaded-pc-games",
                        title: "Downloaded PC Games",
                        id: 19000245,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/pc-games",
                        path: "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/pc-games/downloaded-pc-games",
                        active: true,
                        meta: {
                          tags: "Downloaded PC Games",
                          keywords: "Downloaded PC Games",
                          description: "Downloaded PC Games",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1518",
                        },
                        slug: "online-pc-games",
                        title: "Online PC Games",
                        id: 19000246,
                        parent:
                          "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/pc-games",
                        path: "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/pc-games/online-pc-games",
                        active: true,
                        meta: {
                          tags: "Online PC Games",
                          keywords: "Online PC Games",
                          description: "Online PC Games",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1512",
                    },
                    slug: "cloud-gaming",
                    title: "Cloud Gaming",
                    id: 19000240,
                    parent:
                      "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms",
                    path: "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-platforms/cloud-gaming",
                    active: true,
                    meta: {
                      tags: "Cloud Gaming",
                      keywords: "Cloud Gaming",
                      description: "Cloud Gaming",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f150e",
                },
                slug: "game-development",
                title: "Game Development",
                id: 19000236,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/gaming",
                path: "/technology-media-and-telecom/media-and-entertainment/gaming/game-development",
                active: true,
                meta: {
                  tags: "Game Development",
                  keywords: "Game Development",
                  description: "Game Development",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f150f",
                },
                slug: "game-streaming",
                title: "Game Streaming",
                id: 19000237,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/gaming",
                path: "/technology-media-and-telecom/media-and-entertainment/gaming/game-streaming",
                active: true,
                meta: {
                  tags: "Game Streaming",
                  keywords: "Game Streaming",
                  description: "Game Streaming",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1510",
                },
                slug: "gaming-accessories",
                title: "Gaming Accessories",
                id: 19000238,
                parent:
                  "/technology-media-and-telecom/media-and-entertainment/gaming",
                path: "/technology-media-and-telecom/media-and-entertainment/gaming/gaming-accessories",
                active: true,
                meta: {
                  tags: "Gaming Accessories",
                  keywords: "Gaming Accessories",
                  description: "Gaming Accessories",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1509",
            },
            slug: "animation-and-visual-effects",
            title: "Animation and Visual Effects",
            id: 19000231,
            parent: "/technology-media-and-telecom/media-and-entertainment",
            path: "/technology-media-and-telecom/media-and-entertainment/animation-and-visual-effects",
            active: true,
            meta: {
              tags: "Animation and Visual Effects",
              keywords: "Animation and Visual Effects",
              description: "Animation and Visual Effects",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1525",
            },
            slug: "sports",
            title: "Sports",
            id: 19000259,
            parent: "/technology-media-and-telecom/media-and-entertainment",
            path: "/technology-media-and-telecom/media-and-entertainment/sports",
            active: true,
            meta: {
              tags: "Sports",
              keywords: "Sports",
              description: "Sports",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a75",
        },
        picture_url: null,
        description: {
          short_description: "Security & Surveillance",
          full_description: "Security & Surveillance",
        },
        meta: {
          tags: "Security & Surveillance",
          keywords: "Security & Surveillance",
          description: "Security & Surveillance",
        },
        active: true,
        title: "Security & Surveillance",
        slug: "security-and-surveillance",
        id: 84682,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/security-and-surveillance",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1550",
            },
            slug: "public-safety",
            title: "Public Safety",
            id: 19000303,
            parent: "/technology-media-and-telecom/security-and-surveillance",
            path: "/technology-media-and-telecom/security-and-surveillance/public-safety",
            active: true,
            meta: {
              tags: "Public Safety",
              keywords: "Public Safety",
              description: "Public Safety",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1555",
                },
                slug: "safety-equipment-and-technologies",
                title: "Safety Equipment and Technologies",
                id: 19000308,
                parent:
                  "/technology-media-and-telecom/security-and-surveillance/public-safety",
                path: "/technology-media-and-telecom/security-and-surveillance/public-safety/safety-equipment-and-technologies",
                active: true,
                meta: {
                  tags: "Safety Equipment and Technologies",
                  keywords: "Safety Equipment and Technologies",
                  description: "Safety Equipment and Technologies",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1553",
                },
                slug: "law-enforcement",
                title: "Law Enforcement",
                id: 19000306,
                parent:
                  "/technology-media-and-telecom/security-and-surveillance/public-safety",
                path: "/technology-media-and-telecom/security-and-surveillance/public-safety/law-enforcement",
                active: true,
                meta: {
                  tags: "Law Enforcement",
                  keywords: "Law Enforcement",
                  description: "Law Enforcement",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1554",
                },
                slug: "public-infrastructure-protection",
                title: "Public Infrastructure Protection",
                id: 19000307,
                parent:
                  "/technology-media-and-telecom/security-and-surveillance/public-safety",
                path: "/technology-media-and-telecom/security-and-surveillance/public-safety/public-infrastructure-protection",
                active: true,
                meta: {
                  tags: "Public Infrastructure Protection",
                  keywords: "Public Infrastructure Protection",
                  description: "Public Infrastructure Protection",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1556",
                },
                slug: "security-services",
                title: "Security Services",
                id: 19000309,
                parent:
                  "/technology-media-and-telecom/security-and-surveillance/public-safety",
                path: "/technology-media-and-telecom/security-and-surveillance/public-safety/security-services",
                active: true,
                meta: {
                  tags: "Security Services",
                  keywords: "Security Services",
                  description: "Security Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1551",
                },
                slug: "crisis-and-incidence-management",
                title: "Crisis and Incidence Management",
                id: 19000304,
                parent:
                  "/technology-media-and-telecom/security-and-surveillance/public-safety",
                path: "/technology-media-and-telecom/security-and-surveillance/public-safety/crisis-and-incidence-management",
                active: true,
                meta: {
                  tags: "Crisis and Incidence Management",
                  keywords: "Crisis and Incidence Management",
                  description: "Crisis and Incidence Management",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1552",
                },
                slug: "emergency-services",
                title: "Emergency Services",
                id: 19000305,
                parent:
                  "/technology-media-and-telecom/security-and-surveillance/public-safety",
                path: "/technology-media-and-telecom/security-and-surveillance/public-safety/emergency-services",
                active: true,
                meta: {
                  tags: "Emergency Services",
                  keywords: "Emergency Services",
                  description: "Emergency Services",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1538",
            },
            slug: "logical-security",
            title: "Logical Security",
            id: 19000279,
            parent: "/technology-media-and-telecom/security-and-surveillance",
            path: "/technology-media-and-telecom/security-and-surveillance/logical-security",
            active: true,
            meta: {
              tags: "Logical Security",
              keywords: "Logical Security",
              description: "Logical Security",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1539",
            },
            slug: "physical-security",
            title: "Physical Security",
            id: 19000280,
            parent: "/technology-media-and-telecom/security-and-surveillance",
            path: "/technology-media-and-telecom/security-and-surveillance/physical-security",
            active: true,
            meta: {
              tags: "Physical Security",
              keywords: "Physical Security",
              description: "Physical Security",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1543",
                },
                slug: "security-screening",
                title: "Security Screening",
                id: 19000290,
                parent:
                  "/technology-media-and-telecom/security-and-surveillance/physical-security",
                path: "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening",
                active: true,
                meta: {
                  tags: "Security Screening",
                  keywords: "Security Screening",
                  description: "Security Screening",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1546",
                    },
                    slug: "people-screening",
                    title: "People Screening",
                    id: 19000293,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening/people-screening",
                    active: true,
                    meta: {
                      tags: "People Screening",
                      keywords: "People Screening",
                      description: "People Screening",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1548",
                    },
                    slug: "trace-detection",
                    title: "Trace Detection",
                    id: 19000295,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening/trace-detection",
                    active: true,
                    meta: {
                      tags: "Trace Detection",
                      keywords: "Trace Detection",
                      description: "Trace Detection",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1545",
                    },
                    slug: "cargo-and-vehicle-screening",
                    title: "Cargo and Vehicle Screening",
                    id: 19000292,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening/cargo-and-vehicle-screening",
                    active: true,
                    meta: {
                      tags: "Cargo and Vehicle Screening",
                      keywords: "Cargo and Vehicle Screening",
                      description: "Cargo and Vehicle Screening",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1547",
                    },
                    slug: "radiation-detection",
                    title: "Radiation Detection",
                    id: 19000294,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening/radiation-detection",
                    active: true,
                    meta: {
                      tags: "Radiation Detection",
                      keywords: "Radiation Detection",
                      description: "Radiation Detection",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1544",
                    },
                    slug: "baggage-and-parcel-screening",
                    title: "Baggage and Parcel Screening",
                    id: 19000291,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/security-screening/baggage-and-parcel-screening",
                    active: true,
                    meta: {
                      tags: "Baggage and Parcel Screening",
                      keywords: "Baggage and Parcel Screening",
                      description: "Baggage and Parcel Screening",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1549",
                },
                slug: "video-surveillance",
                title: "Video Surveillance",
                id: 19000296,
                parent:
                  "/technology-media-and-telecom/security-and-surveillance/physical-security",
                path: "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance",
                active: true,
                meta: {
                  tags: "Video Surveillance",
                  keywords: "Video Surveillance",
                  description: "Video Surveillance",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f154a",
                    },
                    slug: "surveillance-cameras",
                    title: "Surveillance Cameras",
                    id: 19000297,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance/surveillance-cameras",
                    active: true,
                    meta: {
                      tags: "Surveillance Cameras",
                      keywords: "Surveillance Cameras",
                      description: "Surveillance Cameras",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f154c",
                    },
                    slug: "video-management-system",
                    title: "Video Management System",
                    id: 19000299,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance/video-management-system",
                    active: true,
                    meta: {
                      tags: "Video Management System",
                      keywords: "Video Management System",
                      description: "Video Management System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f154d",
                    },
                    slug: "video-surveillance-storage",
                    title: "Video Surveillance Storage",
                    id: 19000300,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance/video-surveillance-storage",
                    active: true,
                    meta: {
                      tags: "Video Surveillance Storage",
                      keywords: "Video Surveillance Storage",
                      description: "Video Surveillance Storage",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f154f",
                        },
                        slug: "nvr",
                        title: "NVR",
                        id: 19000302,
                        parent:
                          "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance/video-surveillance-storage",
                        path: "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance/video-surveillance-storage/nvr",
                        active: true,
                        meta: {
                          tags: "NVR",
                          keywords: "NVR",
                          description: "NVR",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f154e",
                        },
                        slug: "dvr",
                        title: "DVR",
                        id: 19000301,
                        parent:
                          "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance/video-surveillance-storage",
                        path: "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance/video-surveillance-storage/dvr",
                        active: true,
                        meta: {
                          tags: "DVR",
                          keywords: "DVR",
                          description: "DVR",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f154b",
                    },
                    slug: "video-analytics",
                    title: "Video Analytics",
                    id: 19000298,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/video-surveillance/video-analytics",
                    active: true,
                    meta: {
                      tags: "Video Analytics",
                      keywords: "Video Analytics",
                      description: "Video Analytics",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f153a",
                },
                slug: "access-control",
                title: "Access Control",
                id: 19000281,
                parent:
                  "/technology-media-and-telecom/security-and-surveillance/physical-security",
                path: "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control",
                active: true,
                meta: {
                  tags: "Access Control",
                  keywords: "Access Control",
                  description: "Access Control",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1541",
                    },
                    slug: "pin-based-access-control",
                    title: "PIN-based Access Control",
                    id: 19000288,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control/pin-based-access-control",
                    active: true,
                    meta: {
                      tags: "PIN-based Access Control",
                      keywords: "PIN-based Access Control",
                      description: "PIN-based Access Control",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1542",
                    },
                    slug: "proximity-access-control",
                    title: "Proximity Access Control",
                    id: 19000289,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control/proximity-access-control",
                    active: true,
                    meta: {
                      tags: "Proximity Access Control",
                      keywords: "Proximity Access Control",
                      description: "Proximity Access Control",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f153e",
                    },
                    slug: "card-based-access-control",
                    title: "Card-based Access Control",
                    id: 19000285,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control/card-based-access-control",
                    active: true,
                    meta: {
                      tags: "Card-based Access Control",
                      keywords: "Card-based Access Control",
                      description: "Card-based Access Control",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f153f",
                    },
                    slug: "hardware-tokens",
                    title: "Hardware Tokens",
                    id: 19000286,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control/hardware-tokens",
                    active: true,
                    meta: {
                      tags: "Hardware Tokens",
                      keywords: "Hardware Tokens",
                      description: "Hardware Tokens",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f153c",
                    },
                    slug: "access-control-software",
                    title: "Access Control Software",
                    id: 19000283,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control/access-control-software",
                    active: true,
                    meta: {
                      tags: "Access Control Software",
                      keywords: "Access Control Software",
                      description: "Access Control Software",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f153d",
                    },
                    slug: "biometric-access-control",
                    title: "Biometric Access Control",
                    id: 19000284,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control/biometric-access-control",
                    active: true,
                    meta: {
                      tags: "Biometric Access Control",
                      keywords: "Biometric Access Control",
                      description: "Biometric Access Control",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1540",
                    },
                    slug: "mobile-based-authentication",
                    title: "Mobile-based Authentication",
                    id: 19000287,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control/mobile-based-authentication",
                    active: true,
                    meta: {
                      tags: "Mobile-based Authentication",
                      keywords: "Mobile-based Authentication",
                      description: "Mobile-based Authentication",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f153b",
                    },
                    slug: "2fa-and-mfa",
                    title: "2FA and MFA",
                    id: 19000282,
                    parent:
                      "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control",
                    path: "/technology-media-and-telecom/security-and-surveillance/physical-security/access-control/2fa-and-mfa",
                    active: true,
                    meta: {
                      tags: "2FA and MFA",
                      keywords: "2FA and MFA",
                      description: "2FA and MFA",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a79",
        },
        picture_url: null,
        description: {
          short_description: "Testing, Inspection and Certification",
          full_description: "Testing, Inspection and Certification",
        },
        meta: {
          tags: "Testing, Inspection and Certification",
          keywords: "Testing, Inspection and Certification",
          description: "Testing, Inspection and Certification",
        },
        active: true,
        title: "Testing, Inspection and Certification",
        slug: "testing-inspection-and-certification",
        id: 84686,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/testing-inspection-and-certification",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f161e",
            },
            slug: "inspection-equipment-and-services",
            title: "Inspection Equipment and Services",
            id: 19000513,
            parent:
              "/technology-media-and-telecom/testing-inspection-and-certification",
            path: "/technology-media-and-telecom/testing-inspection-and-certification/inspection-equipment-and-services",
            active: true,
            meta: {
              tags: "Inspection Equipment and Services",
              keywords: "Inspection Equipment and Services",
              description: "Inspection Equipment and Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f161f",
            },
            slug: "testing-equipment-and-services",
            title: "Testing Equipment and Services",
            id: 19000514,
            parent:
              "/technology-media-and-telecom/testing-inspection-and-certification",
            path: "/technology-media-and-telecom/testing-inspection-and-certification/testing-equipment-and-services",
            active: true,
            meta: {
              tags: "Testing Equipment and Services",
              keywords: "Testing Equipment and Services",
              description: "Testing Equipment and Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1621",
                },
                slug: "ndt-equipment-and-services",
                title: "NDT Equipment and Services",
                id: 19000516,
                parent:
                  "/technology-media-and-telecom/testing-inspection-and-certification/testing-equipment-and-services",
                path: "/technology-media-and-telecom/testing-inspection-and-certification/testing-equipment-and-services/ndt-equipment-and-services",
                active: true,
                meta: {
                  tags: "NDT Equipment and Services",
                  keywords: "NDT Equipment and Services",
                  description: "NDT Equipment and Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1620",
                },
                slug: "material-testing",
                title: "Material Testing",
                id: 19000515,
                parent:
                  "/technology-media-and-telecom/testing-inspection-and-certification/testing-equipment-and-services",
                path: "/technology-media-and-telecom/testing-inspection-and-certification/testing-equipment-and-services/material-testing",
                active: true,
                meta: {
                  tags: "Material Testing",
                  keywords: "Material Testing",
                  description: "Material Testing",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f161c",
            },
            slug: "calibration-equipment-and-services",
            title: "Calibration Equipment and Services",
            id: 19000511,
            parent:
              "/technology-media-and-telecom/testing-inspection-and-certification",
            path: "/technology-media-and-telecom/testing-inspection-and-certification/calibration-equipment-and-services",
            active: true,
            meta: {
              tags: "Calibration Equipment and Services",
              keywords: "Calibration Equipment and Services",
              description: "Calibration Equipment and Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f161d",
            },
            slug: "certification-services",
            title: "Certification Services",
            id: 19000512,
            parent:
              "/technology-media-and-telecom/testing-inspection-and-certification",
            path: "/technology-media-and-telecom/testing-inspection-and-certification/certification-services",
            active: true,
            meta: {
              tags: "Certification Services",
              keywords: "Certification Services",
              description: "Certification Services",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a72",
        },
        picture_url: null,
        description: {
          short_description: "Electronics",
          full_description: "Electronics",
        },
        meta: {
          tags: "Electronics",
          keywords: "Electronics",
          description: "Electronics",
        },
        active: true,
        title: "Electronics",
        slug: "electronics",
        id: 84679,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/electronics",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1479",
            },
            slug: "electrical-parts",
            title: "Electrical Parts",
            id: 19000085,
            parent: "/technology-media-and-telecom/electronics",
            path: "/technology-media-and-telecom/electronics/electrical-parts",
            active: true,
            meta: {
              tags: "Electrical Parts",
              keywords: "Electrical Parts",
              description: "Electrical Parts",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f146b",
            },
            slug: "advanced-electronics",
            title: "Advanced Electronics",
            id: 19000071,
            parent: "/technology-media-and-telecom/electronics",
            path: "/technology-media-and-telecom/electronics/advanced-electronics",
            active: true,
            meta: {
              tags: "Advanced Electronics",
              keywords: "Advanced Electronics",
              description: "Advanced Electronics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f146c",
            },
            slug: "audio-video",
            title: "Audio Video",
            id: 19000072,
            parent: "/technology-media-and-telecom/electronics",
            path: "/technology-media-and-telecom/electronics/audio-video",
            active: true,
            meta: {
              tags: "Audio Video",
              keywords: "Audio Video",
              description: "Audio Video",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f146f",
                },
                slug: "displays",
                title: "Displays",
                id: 19000075,
                parent: "/technology-media-and-telecom/electronics/audio-video",
                path: "/technology-media-and-telecom/electronics/audio-video/displays",
                active: true,
                meta: {
                  tags: "Displays",
                  keywords: "Displays",
                  description: "Displays",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1470",
                    },
                    slug: "smart-tv",
                    title: "Smart TV",
                    id: 19000076,
                    parent:
                      "/technology-media-and-telecom/electronics/audio-video/displays",
                    path: "/technology-media-and-telecom/electronics/audio-video/displays/smart-tv",
                    active: true,
                    meta: {
                      tags: "Smart TV",
                      keywords: "Smart TV",
                      description: "Smart TV",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1471",
                    },
                    slug: "smartphone-display-panel",
                    title: "Smartphone Display Panel",
                    id: 19000077,
                    parent:
                      "/technology-media-and-telecom/electronics/audio-video/displays",
                    path: "/technology-media-and-telecom/electronics/audio-video/displays/smartphone-display-panel",
                    active: true,
                    meta: {
                      tags: "Smartphone Display Panel",
                      keywords: "Smartphone Display Panel",
                      description: "Smartphone Display Panel",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1476",
                },
                slug: "projectors",
                title: "Projectors",
                id: 19000082,
                parent: "/technology-media-and-telecom/electronics/audio-video",
                path: "/technology-media-and-telecom/electronics/audio-video/projectors",
                active: true,
                meta: {
                  tags: "Projectors",
                  keywords: "Projectors",
                  description: "Projectors",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1472",
                },
                slug: "headphones",
                title: "Headphones",
                id: 19000078,
                parent: "/technology-media-and-telecom/electronics/audio-video",
                path: "/technology-media-and-telecom/electronics/audio-video/headphones",
                active: true,
                meta: {
                  tags: "Headphones",
                  keywords: "Headphones",
                  description: "Headphones",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1473",
                    },
                    slug: "professional-headphones",
                    title: "Professional Headphones",
                    id: 19000079,
                    parent:
                      "/technology-media-and-telecom/electronics/audio-video/headphones",
                    path: "/technology-media-and-telecom/electronics/audio-video/headphones/professional-headphones",
                    active: true,
                    meta: {
                      tags: "Professional Headphones",
                      keywords: "Professional Headphones",
                      description: "Professional Headphones",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f146d",
                },
                slug: "audio-systems",
                title: "Audio Systems",
                id: 19000073,
                parent: "/technology-media-and-telecom/electronics/audio-video",
                path: "/technology-media-and-telecom/electronics/audio-video/audio-systems",
                active: true,
                meta: {
                  tags: "Audio Systems",
                  keywords: "Audio Systems",
                  description: "Audio Systems",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1474",
                },
                slug: "microphones",
                title: "Microphones",
                id: 19000080,
                parent: "/technology-media-and-telecom/electronics/audio-video",
                path: "/technology-media-and-telecom/electronics/audio-video/microphones",
                active: true,
                meta: {
                  tags: "Microphones",
                  keywords: "Microphones",
                  description: "Microphones",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1475",
                    },
                    slug: "professional-microphones",
                    title: "Professional Microphones",
                    id: 19000081,
                    parent:
                      "/technology-media-and-telecom/electronics/audio-video/microphones",
                    path: "/technology-media-and-telecom/electronics/audio-video/microphones/professional-microphones",
                    active: true,
                    meta: {
                      tags: "Professional Microphones",
                      keywords: "Professional Microphones",
                      description: "Professional Microphones",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1477",
                },
                slug: "speakers",
                title: "Speakers",
                id: 19000083,
                parent: "/technology-media-and-telecom/electronics/audio-video",
                path: "/technology-media-and-telecom/electronics/audio-video/speakers",
                active: true,
                meta: {
                  tags: "Speakers",
                  keywords: "Speakers",
                  description: "Speakers",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1478",
                    },
                    slug: "professional-speakers",
                    title: "Professional Speakers",
                    id: 19000084,
                    parent:
                      "/technology-media-and-telecom/electronics/audio-video/speakers",
                    path: "/technology-media-and-telecom/electronics/audio-video/speakers/professional-speakers",
                    active: true,
                    meta: {
                      tags: "Professional Speakers",
                      keywords: "Professional Speakers",
                      description: "Professional Speakers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f146e",
                },
                slug: "digital-signage",
                title: "Digital Signage",
                id: 19000074,
                parent: "/technology-media-and-telecom/electronics/audio-video",
                path: "/technology-media-and-telecom/electronics/audio-video/digital-signage",
                active: true,
                meta: {
                  tags: "Digital Signage",
                  keywords: "Digital Signage",
                  description: "Digital Signage",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f149f",
            },
            slug: "test-and-measurement",
            title: "Test and Measurement",
            id: 19000123,
            parent: "/technology-media-and-telecom/electronics",
            path: "/technology-media-and-telecom/electronics/test-and-measurement",
            active: true,
            meta: {
              tags: "Test and Measurement",
              keywords: "Test and Measurement",
              description: "Test and Measurement",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14a0",
                },
                slug: "analytical-instrumentation",
                title: "Analytical Instrumentation",
                id: 19000124,
                parent:
                  "/technology-media-and-telecom/electronics/test-and-measurement",
                path: "/technology-media-and-telecom/electronics/test-and-measurement/analytical-instrumentation",
                active: true,
                meta: {
                  tags: "Analytical Instrumentation",
                  keywords: "Analytical Instrumentation",
                  description: "Analytical Instrumentation",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f14a1",
                    },
                    slug: "spectrometers",
                    title: "Spectrometers",
                    id: 19000125,
                    parent:
                      "/technology-media-and-telecom/electronics/test-and-measurement/analytical-instrumentation",
                    path: "/technology-media-and-telecom/electronics/test-and-measurement/analytical-instrumentation/spectrometers",
                    active: true,
                    meta: {
                      tags: "Spectrometers",
                      keywords: "Spectrometers",
                      description: "Spectrometers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14a4",
                },
                slug: "environmental-test-and-measurement",
                title: "Environmental Test and Measurement",
                id: 19000128,
                parent:
                  "/technology-media-and-telecom/electronics/test-and-measurement",
                path: "/technology-media-and-telecom/electronics/test-and-measurement/environmental-test-and-measurement",
                active: true,
                meta: {
                  tags: "Environmental Test and Measurement",
                  keywords: "Environmental Test and Measurement",
                  description: "Environmental Test and Measurement",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14a5",
                },
                slug: "physical-test-and-measurement",
                title: "Physical Test and Measurement",
                id: 19000129,
                parent:
                  "/technology-media-and-telecom/electronics/test-and-measurement",
                path: "/technology-media-and-telecom/electronics/test-and-measurement/physical-test-and-measurement",
                active: true,
                meta: {
                  tags: "Physical Test and Measurement",
                  keywords: "Physical Test and Measurement",
                  description: "Physical Test and Measurement",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14a3",
                },
                slug: "environment-test-and-measurement",
                title: "Environment Test and Measurement",
                id: 19000127,
                parent:
                  "/technology-media-and-telecom/electronics/test-and-measurement",
                path: "/technology-media-and-telecom/electronics/test-and-measurement/environment-test-and-measurement",
                active: true,
                meta: {
                  tags: "Environment Test and Measurement",
                  keywords: "Environment Test and Measurement",
                  description: "Environment Test and Measurement",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f14a2",
                },
                slug: "electronic-test-and-measurement",
                title: "Electronic Test and Measurement",
                id: 19000126,
                parent:
                  "/technology-media-and-telecom/electronics/test-and-measurement",
                path: "/technology-media-and-telecom/electronics/test-and-measurement/electronic-test-and-measurement",
                active: true,
                meta: {
                  tags: "Electronic Test and Measurement",
                  keywords: "Electronic Test and Measurement",
                  description: "Electronic Test and Measurement",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f147a",
            },
            slug: "electronic-components",
            title: "Electronic Components",
            id: 19000086,
            parent: "/technology-media-and-telecom/electronics",
            path: "/technology-media-and-telecom/electronics/electronic-components",
            active: true,
            meta: {
              tags: "Electronic Components",
              keywords: "Electronic Components",
              description: "Electronic Components",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1489",
                },
                slug: "connectors",
                title: "Connectors",
                id: 19000101,
                parent:
                  "/technology-media-and-telecom/electronics/electronic-components",
                path: "/technology-media-and-telecom/electronics/electronic-components/connectors",
                active: true,
                meta: {
                  tags: "Connectors",
                  keywords: "Connectors",
                  description: "Connectors",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f147b",
                },
                slug: "active-electronics",
                title: "Active Electronics",
                id: 19000087,
                parent:
                  "/technology-media-and-telecom/electronics/electronic-components",
                path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics",
                active: true,
                meta: {
                  tags: "Active Electronics",
                  keywords: "Active Electronics",
                  description: "Active Electronics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f147e",
                    },
                    slug: "relays",
                    title: "Relays",
                    id: 19000090,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/active-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/relays",
                    active: true,
                    meta: {
                      tags: "Relays",
                      keywords: "Relays",
                      description: "Relays",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f147f",
                    },
                    slug: "switch",
                    title: "Switch",
                    id: 19000091,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/active-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/switch",
                    active: true,
                    meta: {
                      tags: "Switch",
                      keywords: "Switch",
                      description: "Switch",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1480",
                    },
                    slug: "thyristors",
                    title: "Thyristors",
                    id: 19000092,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/active-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/thyristors",
                    active: true,
                    meta: {
                      tags: "Thyristors",
                      keywords: "Thyristors",
                      description: "Thyristors",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1482",
                        },
                        slug: "silicon-controlled-rectifiers",
                        title: "Silicon-Controlled Rectifiers",
                        id: 19000094,
                        parent:
                          "/technology-media-and-telecom/electronics/electronic-components/active-electronics/thyristors",
                        path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/thyristors/silicon-controlled-rectifiers",
                        active: true,
                        meta: {
                          tags: "Silicon-Controlled Rectifiers",
                          keywords: "Silicon-Controlled Rectifiers",
                          description: "Silicon-Controlled Rectifiers",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1481",
                        },
                        slug: "diacs",
                        title: "Diacs",
                        id: 19000093,
                        parent:
                          "/technology-media-and-telecom/electronics/electronic-components/active-electronics/thyristors",
                        path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/thyristors/diacs",
                        active: true,
                        meta: {
                          tags: "Diacs",
                          keywords: "Diacs",
                          description: "Diacs",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1483",
                        },
                        slug: "triacs",
                        title: "Triacs",
                        id: 19000095,
                        parent:
                          "/technology-media-and-telecom/electronics/electronic-components/active-electronics/thyristors",
                        path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/thyristors/triacs",
                        active: true,
                        meta: {
                          tags: "Triacs",
                          keywords: "Triacs",
                          description: "Triacs",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1484",
                    },
                    slug: "transistors",
                    title: "Transistors",
                    id: 19000096,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/active-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/transistors",
                    active: true,
                    meta: {
                      tags: "Transistors",
                      keywords: "Transistors",
                      description: "Transistors",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1486",
                        },
                        slug: "fet",
                        title: "FET",
                        id: 19000098,
                        parent:
                          "/technology-media-and-telecom/electronics/electronic-components/active-electronics/transistors",
                        path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/transistors/fet",
                        active: true,
                        meta: {
                          tags: "FET",
                          keywords: "FET",
                          description: "FET",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1487",
                        },
                        slug: "igbt",
                        title: "IGBT",
                        id: 19000099,
                        parent:
                          "/technology-media-and-telecom/electronics/electronic-components/active-electronics/transistors",
                        path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/transistors/igbt",
                        active: true,
                        meta: {
                          tags: "IGBT",
                          keywords: "IGBT",
                          description: "IGBT",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1488",
                        },
                        slug: "mosfet",
                        title: "MOSFET",
                        id: 19000100,
                        parent:
                          "/technology-media-and-telecom/electronics/electronic-components/active-electronics/transistors",
                        path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/transistors/mosfet",
                        active: true,
                        meta: {
                          tags: "MOSFET",
                          keywords: "MOSFET",
                          description: "MOSFET",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1485",
                        },
                        slug: "bjt",
                        title: "BJT",
                        id: 19000097,
                        parent:
                          "/technology-media-and-telecom/electronics/electronic-components/active-electronics/transistors",
                        path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/transistors/bjt",
                        active: true,
                        meta: {
                          tags: "BJT",
                          keywords: "BJT",
                          description: "BJT",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f147c",
                    },
                    slug: "diodes",
                    title: "Diodes",
                    id: 19000088,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/active-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/diodes",
                    active: true,
                    meta: {
                      tags: "Diodes",
                      keywords: "Diodes",
                      description: "Diodes",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f147d",
                    },
                    slug: "jfet",
                    title: "JFET",
                    id: 19000089,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/active-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/active-electronics/jfet",
                    active: true,
                    meta: {
                      tags: "JFET",
                      keywords: "JFET",
                      description: "JFET",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f148a",
                },
                slug: "passive-electronics",
                title: "Passive Electronics",
                id: 19000102,
                parent:
                  "/technology-media-and-telecom/electronics/electronic-components",
                path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics",
                active: true,
                meta: {
                  tags: "Passive Electronics",
                  keywords: "Passive Electronics",
                  description: "Passive Electronics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f148b",
                    },
                    slug: "capacitors",
                    title: "Capacitors",
                    id: 19000103,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/passive-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/capacitors",
                    active: true,
                    meta: {
                      tags: "Capacitors",
                      keywords: "Capacitors",
                      description: "Capacitors",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f148e",
                        },
                        slug: "supercapacitors",
                        title: "Supercapacitors",
                        id: 19000106,
                        parent:
                          "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/capacitors",
                        path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/capacitors/supercapacitors",
                        active: true,
                        meta: {
                          tags: "Supercapacitors",
                          keywords: "Supercapacitors",
                          description: "Supercapacitors",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f148c",
                        },
                        slug: "ceramic-capacitors",
                        title: "Ceramic Capacitors",
                        id: 19000104,
                        parent:
                          "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/capacitors",
                        path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/capacitors/ceramic-capacitors",
                        active: true,
                        meta: {
                          tags: "Ceramic Capacitors",
                          keywords: "Ceramic Capacitors",
                          description: "Ceramic Capacitors",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f148d",
                            },
                            slug: "mlcc",
                            title: "MLCC",
                            id: 19000105,
                            parent:
                              "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/capacitors/ceramic-capacitors",
                            path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/capacitors/ceramic-capacitors/mlcc",
                            active: true,
                            meta: {
                              tags: "MLCC",
                              keywords: "MLCC",
                              description: "MLCC",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1493",
                    },
                    slug: "motors",
                    title: "Motors",
                    id: 19000111,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/passive-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/motors",
                    active: true,
                    meta: {
                      tags: "Motors",
                      keywords: "Motors",
                      description: "Motors",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1491",
                    },
                    slug: "fuse",
                    title: "Fuse",
                    id: 19000109,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/passive-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/fuse",
                    active: true,
                    meta: {
                      tags: "Fuse",
                      keywords: "Fuse",
                      description: "Fuse",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f148f",
                    },
                    slug: "circuit-breakers-and-safety-switches",
                    title: "Circuit Breakers and Safety Switches",
                    id: 19000107,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/passive-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/circuit-breakers-and-safety-switches",
                    active: true,
                    meta: {
                      tags: "Circuit Breakers and Safety Switches",
                      keywords: "Circuit Breakers and Safety Switches",
                      description: "Circuit Breakers and Safety Switches",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1490",
                    },
                    slug: "frequency-control-and-timing-devices",
                    title: "Frequency Control and Timing Devices",
                    id: 19000108,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/passive-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/frequency-control-and-timing-devices",
                    active: true,
                    meta: {
                      tags: "Frequency Control and Timing Devices",
                      keywords: "Frequency Control and Timing Devices",
                      description: "Frequency Control and Timing Devices",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1492",
                    },
                    slug: "inductors-chokes-and-coils",
                    title: "Inductors, Chokes and Coils",
                    id: 19000110,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/passive-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/inductors-chokes-and-coils",
                    active: true,
                    meta: {
                      tags: "Inductors, Chokes and Coils",
                      keywords: "Inductors, Chokes and Coils",
                      description: "Inductors, Chokes and Coils",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1495",
                    },
                    slug: "transformers",
                    title: "Transformers",
                    id: 19000113,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/passive-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/transformers",
                    active: true,
                    meta: {
                      tags: "Transformers",
                      keywords: "Transformers",
                      description: "Transformers",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1494",
                    },
                    slug: "potentiometers",
                    title: "Potentiometers",
                    id: 19000112,
                    parent:
                      "/technology-media-and-telecom/electronics/electronic-components/passive-electronics",
                    path: "/technology-media-and-telecom/electronics/electronic-components/passive-electronics/potentiometers",
                    active: true,
                    meta: {
                      tags: "Potentiometers",
                      keywords: "Potentiometers",
                      description: "Potentiometers",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1496",
            },
            slug: "photonics",
            title: "Photonics",
            id: 19000114,
            parent: "/technology-media-and-telecom/electronics",
            path: "/technology-media-and-telecom/electronics/photonics",
            active: true,
            meta: {
              tags: "Photonics",
              keywords: "Photonics",
              description: "Photonics",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1497",
                },
                slug: "lasers-and-laser-parts",
                title: "Lasers and Laser Parts",
                id: 19000115,
                parent: "/technology-media-and-telecom/electronics/photonics",
                path: "/technology-media-and-telecom/electronics/photonics/lasers-and-laser-parts",
                active: true,
                meta: {
                  tags: "Lasers and Laser Parts",
                  keywords: "Lasers and Laser Parts",
                  description: "Lasers and Laser Parts",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1498",
                },
                slug: "optoelectronics",
                title: "Optoelectronics",
                id: 19000116,
                parent: "/technology-media-and-telecom/electronics/photonics",
                path: "/technology-media-and-telecom/electronics/photonics/optoelectronics",
                active: true,
                meta: {
                  tags: "Optoelectronics",
                  keywords: "Optoelectronics",
                  description: "Optoelectronics",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f149a",
                    },
                    slug: "solid-state-lighting",
                    title: "Solid-state Lighting",
                    id: 19000118,
                    parent:
                      "/technology-media-and-telecom/electronics/photonics/optoelectronics",
                    path: "/technology-media-and-telecom/electronics/photonics/optoelectronics/solid-state-lighting",
                    active: true,
                    meta: {
                      tags: "Solid-state Lighting",
                      keywords: "Solid-state Lighting",
                      description: "Solid-state Lighting",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f149c",
                        },
                        slug: "micro-led",
                        title: "Micro-LED",
                        id: 19000120,
                        parent:
                          "/technology-media-and-telecom/electronics/photonics/optoelectronics/solid-state-lighting",
                        path: "/technology-media-and-telecom/electronics/photonics/optoelectronics/solid-state-lighting/micro-led",
                        active: true,
                        meta: {
                          tags: "Micro-LED",
                          keywords: "Micro-LED",
                          description: "Micro-LED",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f149d",
                        },
                        slug: "oled",
                        title: "OLED",
                        id: 19000121,
                        parent:
                          "/technology-media-and-telecom/electronics/photonics/optoelectronics/solid-state-lighting",
                        path: "/technology-media-and-telecom/electronics/photonics/optoelectronics/solid-state-lighting/oled",
                        active: true,
                        meta: {
                          tags: "OLED",
                          keywords: "OLED",
                          description: "OLED",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f149e",
                        },
                        slug: "qled",
                        title: "QLED",
                        id: 19000122,
                        parent:
                          "/technology-media-and-telecom/electronics/photonics/optoelectronics/solid-state-lighting",
                        path: "/technology-media-and-telecom/electronics/photonics/optoelectronics/solid-state-lighting/qled",
                        active: true,
                        meta: {
                          tags: "QLED",
                          keywords: "QLED",
                          description: "QLED",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f149b",
                        },
                        slug: "led",
                        title: "LED",
                        id: 19000119,
                        parent:
                          "/technology-media-and-telecom/electronics/photonics/optoelectronics/solid-state-lighting",
                        path: "/technology-media-and-telecom/electronics/photonics/optoelectronics/solid-state-lighting/led",
                        active: true,
                        meta: {
                          tags: "LED",
                          keywords: "LED",
                          description: "LED",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1499",
                    },
                    slug: "optoelectronic-devices",
                    title: "Optoelectronic Devices",
                    id: 19000117,
                    parent:
                      "/technology-media-and-telecom/electronics/photonics/optoelectronics",
                    path: "/technology-media-and-telecom/electronics/photonics/optoelectronics/optoelectronic-devices",
                    active: true,
                    meta: {
                      tags: "Optoelectronic Devices",
                      keywords: "Optoelectronic Devices",
                      description: "Optoelectronic Devices",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a76",
        },
        picture_url: null,
        description: {
          short_description: "Semiconductors",
          full_description: "Semiconductors",
        },
        meta: {
          tags: "Semiconductors",
          keywords: "Semiconductors",
          description: "Semiconductors",
        },
        active: true,
        title: "Semiconductors",
        slug: "semiconductors",
        id: 84683,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/semiconductors",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1580",
            },
            slug: "semiconductor-foundries",
            title: "Semiconductor Foundries",
            id: 19000352,
            parent: "/technology-media-and-telecom/semiconductors",
            path: "/technology-media-and-telecom/semiconductors/semiconductor-foundries",
            active: true,
            meta: {
              tags: "Semiconductor Foundries",
              keywords: "Semiconductor Foundries",
              description: "Semiconductor Foundries",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1582",
                },
                slug: "osat",
                title: "OSAT",
                id: 19000354,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-foundries",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-foundries/osat",
                active: true,
                meta: {
                  tags: "OSAT",
                  keywords: "OSAT",
                  description: "OSAT",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1581",
                },
                slug: "idm",
                title: "IDM",
                id: 19000353,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-foundries",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-foundries/idm",
                active: true,
                meta: {
                  tags: "IDM",
                  keywords: "IDM",
                  description: "IDM",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1583",
                },
                slug: "pure-play-foundries",
                title: "Pure-play Foundries",
                id: 19000355,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-foundries",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-foundries/pure-play-foundries",
                active: true,
                meta: {
                  tags: "Pure-play Foundries",
                  keywords: "Pure-play Foundries",
                  description: "Pure-play Foundries",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1559",
            },
            slug: "electronic-manufacturing-services",
            title: "Electronic Manufacturing Services",
            id: 19000313,
            parent: "/technology-media-and-telecom/semiconductors",
            path: "/technology-media-and-telecom/semiconductors/electronic-manufacturing-services",
            active: true,
            meta: {
              tags: "Electronic Manufacturing Services",
              keywords: "Electronic Manufacturing Services",
              description: "Electronic Manufacturing Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1584",
            },
            slug: "semiconductor-materials",
            title: "Semiconductor Materials",
            id: 19000356,
            parent: "/technology-media-and-telecom/semiconductors",
            path: "/technology-media-and-telecom/semiconductors/semiconductor-materials",
            active: true,
            meta: {
              tags: "Semiconductor Materials",
              keywords: "Semiconductor Materials",
              description: "Semiconductor Materials",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1585",
                },
                slug: "fab-materials",
                title: "Fab Materials",
                id: 19000357,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-materials",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-materials/fab-materials",
                active: true,
                meta: {
                  tags: "Fab Materials",
                  keywords: "Fab Materials",
                  description: "Fab Materials",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1586",
                },
                slug: "semiconductor-packaging-materials",
                title: "Semiconductor Packaging Materials",
                id: 19000358,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-materials",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-materials/semiconductor-packaging-materials",
                active: true,
                meta: {
                  tags: "Semiconductor Packaging Materials",
                  keywords: "Semiconductor Packaging Materials",
                  description: "Semiconductor Packaging Materials",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1557",
            },
            slug: "eda-tools",
            title: "EDA Tools",
            id: 19000311,
            parent: "/technology-media-and-telecom/semiconductors",
            path: "/technology-media-and-telecom/semiconductors/eda-tools",
            active: true,
            meta: {
              tags: "EDA Tools",
              keywords: "EDA Tools",
              description: "EDA Tools",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1558",
                },
                slug: "semiconductor-ip",
                title: "Semiconductor IP",
                id: 19000312,
                parent:
                  "/technology-media-and-telecom/semiconductors/eda-tools",
                path: "/technology-media-and-telecom/semiconductors/eda-tools/semiconductor-ip",
                active: true,
                meta: {
                  tags: "Semiconductor IP",
                  keywords: "Semiconductor IP",
                  description: "Semiconductor IP",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1579",
            },
            slug: "semiconductor-components-and-devices",
            title: "Semiconductor Components and Devices",
            id: 19000345,
            parent: "/technology-media-and-telecom/semiconductors",
            path: "/technology-media-and-telecom/semiconductors/semiconductor-components-and-devices",
            active: true,
            meta: {
              tags: "Semiconductor Components and Devices",
              keywords: "Semiconductor Components and Devices",
              description: "Semiconductor Components and Devices",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f157b",
                },
                slug: "semiconductor-chipsets",
                title: "Semiconductor Chipsets",
                id: 19000347,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-components-and-devices",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-components-and-devices/semiconductor-chipsets",
                active: true,
                meta: {
                  tags: "Semiconductor Chipsets",
                  keywords: "Semiconductor Chipsets",
                  description: "Semiconductor Chipsets",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f157a",
                },
                slug: "mems",
                title: "MEMS",
                id: 19000346,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-components-and-devices",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-components-and-devices/mems",
                active: true,
                meta: {
                  tags: "MEMS",
                  keywords: "MEMS",
                  description: "MEMS",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f157c",
            },
            slug: "semiconductor-equipment",
            title: "Semiconductor Equipment",
            id: 19000348,
            parent: "/technology-media-and-telecom/semiconductors",
            path: "/technology-media-and-telecom/semiconductors/semiconductor-equipment",
            active: true,
            meta: {
              tags: "Semiconductor Equipment",
              keywords: "Semiconductor Equipment",
              description: "Semiconductor Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f157d",
                },
                slug: "assembly-and-packaging-equipment",
                title: "Assembly and Packaging Equipment",
                id: 19000349,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-equipment",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-equipment/assembly-and-packaging-equipment",
                active: true,
                meta: {
                  tags: "Assembly and Packaging Equipment",
                  keywords: "Assembly and Packaging Equipment",
                  description: "Assembly and Packaging Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f157f",
                },
                slug: "wafer-fabrication-equipment",
                title: "Wafer Fabrication Equipment",
                id: 19000351,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-equipment",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-equipment/wafer-fabrication-equipment",
                active: true,
                meta: {
                  tags: "Wafer Fabrication Equipment",
                  keywords: "Wafer Fabrication Equipment",
                  description: "Wafer Fabrication Equipment",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f157e",
                },
                slug: "semiconductor-test-equipment",
                title: "Semiconductor Test Equipment",
                id: 19000350,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-equipment",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-equipment/semiconductor-test-equipment",
                active: true,
                meta: {
                  tags: "Semiconductor Test Equipment",
                  keywords: "Semiconductor Test Equipment",
                  description: "Semiconductor Test Equipment",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1587",
            },
            slug: "semiconductor-packaging",
            title: "Semiconductor Packaging",
            id: 19000359,
            parent: "/technology-media-and-telecom/semiconductors",
            path: "/technology-media-and-telecom/semiconductors/semiconductor-packaging",
            active: true,
            meta: {
              tags: "Semiconductor Packaging",
              keywords: "Semiconductor Packaging",
              description: "Semiconductor Packaging",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1588",
                },
                slug: "advanced-semiconductor-packaging",
                title: "Advanced Semiconductor Packaging",
                id: 19000360,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-packaging",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging",
                active: true,
                meta: {
                  tags: "Advanced Semiconductor Packaging",
                  keywords: "Advanced Semiconductor Packaging",
                  description: "Advanced Semiconductor Packaging",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1589",
                    },
                    slug: "2.5d-and-3d-packaging",
                    title: "2.5D and 3D Packaging",
                    id: 19000361,
                    parent:
                      "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging",
                    path: "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging/2.5d-and-3d-packaging",
                    active: true,
                    meta: {
                      tags: "2.5D and 3D Packaging",
                      keywords: "2.5D and 3D Packaging",
                      description: "2.5D and 3D Packaging",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f158d",
                    },
                    slug: "flip-chip-packaging",
                    title: "Flip-chip Packaging",
                    id: 19000365,
                    parent:
                      "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging",
                    path: "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging/flip-chip-packaging",
                    active: true,
                    meta: {
                      tags: "Flip-chip Packaging",
                      keywords: "Flip-chip Packaging",
                      description: "Flip-chip Packaging",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f158a",
                    },
                    slug: "embedded-die-packaging",
                    title: "Embedded Die Packaging",
                    id: 19000362,
                    parent:
                      "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging",
                    path: "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging/embedded-die-packaging",
                    active: true,
                    meta: {
                      tags: "Embedded Die Packaging",
                      keywords: "Embedded Die Packaging",
                      description: "Embedded Die Packaging",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f158b",
                    },
                    slug: "fan-in-wlp-packaging",
                    title: "Fan-in WLP Packaging",
                    id: 19000363,
                    parent:
                      "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging",
                    path: "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging/fan-in-wlp-packaging",
                    active: true,
                    meta: {
                      tags: "Fan-in WLP Packaging",
                      keywords: "Fan-in WLP Packaging",
                      description: "Fan-in WLP Packaging",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f158e",
                    },
                    slug: "system-in-package",
                    title: "System-in Package",
                    id: 19000366,
                    parent:
                      "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging",
                    path: "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging/system-in-package",
                    active: true,
                    meta: {
                      tags: "System-in Package",
                      keywords: "System-in Package",
                      description: "System-in Package",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f158c",
                    },
                    slug: "fan-out-packaging",
                    title: "Fan-out Packaging",
                    id: 19000364,
                    parent:
                      "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging",
                    path: "/technology-media-and-telecom/semiconductors/semiconductor-packaging/advanced-semiconductor-packaging/fan-out-packaging",
                    active: true,
                    meta: {
                      tags: "Fan-out Packaging",
                      keywords: "Fan-out Packaging",
                      description: "Fan-out Packaging",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f158f",
                },
                slug: "traditional-semicondcutor-packaging",
                title: "Traditional Semicondcutor Packaging",
                id: 19000367,
                parent:
                  "/technology-media-and-telecom/semiconductors/semiconductor-packaging",
                path: "/technology-media-and-telecom/semiconductors/semiconductor-packaging/traditional-semicondcutor-packaging",
                active: true,
                meta: {
                  tags: "Traditional Semicondcutor Packaging",
                  keywords: "Traditional Semicondcutor Packaging",
                  description: "Traditional Semicondcutor Packaging",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f155a",
            },
            slug: "ic",
            title: "IC",
            id: 19000314,
            parent: "/technology-media-and-telecom/semiconductors",
            path: "/technology-media-and-telecom/semiconductors/ic",
            active: true,
            meta: {
              tags: "IC",
              keywords: "IC",
              description: "IC",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f155b",
                },
                slug: "analog-ic",
                title: "Analog IC",
                id: 19000315,
                parent: "/technology-media-and-telecom/semiconductors/ic",
                path: "/technology-media-and-telecom/semiconductors/ic/analog-ic",
                active: true,
                meta: {
                  tags: "Analog IC",
                  keywords: "Analog IC",
                  description: "Analog IC",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f155c",
                    },
                    slug: "asic",
                    title: "ASIC",
                    id: 19000316,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/analog-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/analog-ic/asic",
                    active: true,
                    meta: {
                      tags: "ASIC",
                      keywords: "ASIC",
                      description: "ASIC",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f155d",
                    },
                    slug: "general-purpose-ic",
                    title: "General Purpose IC",
                    id: 19000317,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/analog-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/analog-ic/general-purpose-ic",
                    active: true,
                    meta: {
                      tags: "General Purpose IC",
                      keywords: "General Purpose IC",
                      description: "General Purpose IC",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f155e",
                },
                slug: "digital-ic",
                title: "Digital IC",
                id: 19000318,
                parent: "/technology-media-and-telecom/semiconductors/ic",
                path: "/technology-media-and-telecom/semiconductors/ic/digital-ic",
                active: true,
                meta: {
                  tags: "Digital IC",
                  keywords: "Digital IC",
                  description: "Digital IC",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1560",
                    },
                    slug: "microcontroller-unit",
                    title: "Microcontroller Unit",
                    id: 19000320,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/digital-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/digital-ic/microcontroller-unit",
                    active: true,
                    meta: {
                      tags: "Microcontroller Unit",
                      keywords: "Microcontroller Unit",
                      description: "Microcontroller Unit",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f155f",
                    },
                    slug: "fpga",
                    title: "FPGA",
                    id: 19000319,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/digital-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/digital-ic/fpga",
                    active: true,
                    meta: {
                      tags: "FPGA",
                      keywords: "FPGA",
                      description: "FPGA",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1562",
                    },
                    slug: "system-on-chip",
                    title: "System on Chip",
                    id: 19000322,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/digital-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/digital-ic/system-on-chip",
                    active: true,
                    meta: {
                      tags: "System on Chip",
                      keywords: "System on Chip",
                      description: "System on Chip",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1561",
                    },
                    slug: "microprocessor",
                    title: "Microprocessor",
                    id: 19000321,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/digital-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/digital-ic/microprocessor",
                    active: true,
                    meta: {
                      tags: "Microprocessor",
                      keywords: "Microprocessor",
                      description: "Microprocessor",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1564",
                },
                slug: "memory-ic",
                title: "Memory IC",
                id: 19000324,
                parent: "/technology-media-and-telecom/semiconductors/ic",
                path: "/technology-media-and-telecom/semiconductors/ic/memory-ic",
                active: true,
                meta: {
                  tags: "Memory IC",
                  keywords: "Memory IC",
                  description: "Memory IC",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1566",
                    },
                    slug: "ram",
                    title: "RAM",
                    id: 19000326,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/memory-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/ram",
                    active: true,
                    meta: {
                      tags: "RAM",
                      keywords: "RAM",
                      description: "RAM",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1568",
                        },
                        slug: "sram",
                        title: "SRAM",
                        id: 19000328,
                        parent:
                          "/technology-media-and-telecom/semiconductors/ic/memory-ic/ram",
                        path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/ram/sram",
                        active: true,
                        meta: {
                          tags: "SRAM",
                          keywords: "SRAM",
                          description: "SRAM",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f1567",
                        },
                        slug: "dram",
                        title: "DRAM",
                        id: 19000327,
                        parent:
                          "/technology-media-and-telecom/semiconductors/ic/memory-ic/ram",
                        path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/ram/dram",
                        active: true,
                        meta: {
                          tags: "DRAM",
                          keywords: "DRAM",
                          description: "DRAM",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1565",
                    },
                    slug: "advanced-memory-ic",
                    title: "Advanced Memory IC",
                    id: 19000325,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/memory-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/advanced-memory-ic",
                    active: true,
                    meta: {
                      tags: "Advanced Memory IC",
                      keywords: "Advanced Memory IC",
                      description: "Advanced Memory IC",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1569",
                    },
                    slug: "rom",
                    title: "ROM",
                    id: 19000329,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/memory-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom",
                    active: true,
                    meta: {
                      tags: "ROM",
                      keywords: "ROM",
                      description: "ROM",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f156a",
                        },
                        slug: "mrom",
                        title: "MROM",
                        id: 19000330,
                        parent:
                          "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom",
                        path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/mrom",
                        active: true,
                        meta: {
                          tags: "MROM",
                          keywords: "MROM",
                          description: "MROM",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f156b",
                        },
                        slug: "prom",
                        title: "PROM",
                        id: 19000331,
                        parent:
                          "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom",
                        path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom",
                        active: true,
                        meta: {
                          tags: "PROM",
                          keywords: "PROM",
                          description: "PROM",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f156c",
                            },
                            slug: "eeprom",
                            title: "EEPROM",
                            id: 19000332,
                            parent:
                              "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom",
                            path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom/eeprom",
                            active: true,
                            meta: {
                              tags: "EEPROM",
                              keywords: "EEPROM",
                              description: "EEPROM",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f1571",
                            },
                            slug: "pcm",
                            title: "PCM",
                            id: 19000337,
                            parent:
                              "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom",
                            path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom/pcm",
                            active: true,
                            meta: {
                              tags: "PCM",
                              keywords: "PCM",
                              description: "PCM",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f156d",
                            },
                            slug: "eprom",
                            title: "EPROM",
                            id: 19000333,
                            parent:
                              "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom",
                            path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom/eprom",
                            active: true,
                            meta: {
                              tags: "EPROM",
                              keywords: "EPROM",
                              description: "EPROM",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64ccdc0cc917ae0b494f156e",
                            },
                            slug: "flash",
                            title: "Flash",
                            id: 19000334,
                            parent:
                              "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom",
                            path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom/flash",
                            active: true,
                            meta: {
                              tags: "Flash",
                              keywords: "Flash",
                              description: "Flash",
                            },
                            sub_categories: [
                              {
                                _id: {
                                  $oid: "64ccdc0cc917ae0b494f156f",
                                },
                                slug: "nand-flash",
                                title: "NAND Flash",
                                id: 19000335,
                                parent:
                                  "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom/flash",
                                path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom/flash/nand-flash",
                                active: true,
                                meta: {
                                  tags: "NAND Flash",
                                  keywords: "NAND Flash",
                                  description: "NAND Flash",
                                },
                                sub_categories: [],
                              },
                              {
                                _id: {
                                  $oid: "64ccdc0cc917ae0b494f1570",
                                },
                                slug: "nor-flash",
                                title: "NOR Flash",
                                id: 19000336,
                                parent:
                                  "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom/flash",
                                path: "/technology-media-and-telecom/semiconductors/ic/memory-ic/rom/prom/flash/nor-flash",
                                active: true,
                                meta: {
                                  tags: "NOR Flash",
                                  keywords: "NOR Flash",
                                  description: "NOR Flash",
                                },
                                sub_categories: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1563",
                },
                slug: "logic-ic",
                title: "Logic IC",
                id: 19000323,
                parent: "/technology-media-and-telecom/semiconductors/ic",
                path: "/technology-media-and-telecom/semiconductors/ic/logic-ic",
                active: true,
                meta: {
                  tags: "Logic IC",
                  keywords: "Logic IC",
                  description: "Logic IC",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1578",
                },
                slug: "radio-frequency-ic",
                title: "Radio Frequency IC",
                id: 19000344,
                parent: "/technology-media-and-telecom/semiconductors/ic",
                path: "/technology-media-and-telecom/semiconductors/ic/radio-frequency-ic",
                active: true,
                meta: {
                  tags: "Radio Frequency IC",
                  keywords: "Radio Frequency IC",
                  description: "Radio Frequency IC",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1572",
                },
                slug: "mixed-signal-ic",
                title: "Mixed Signal IC",
                id: 19000338,
                parent: "/technology-media-and-telecom/semiconductors/ic",
                path: "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic",
                active: true,
                meta: {
                  tags: "Mixed Signal IC",
                  keywords: "Mixed Signal IC",
                  description: "Mixed Signal IC",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1577",
                    },
                    slug: "signal-processing-ics",
                    title: "Signal Processing ICs",
                    id: 19000343,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic/signal-processing-ics",
                    active: true,
                    meta: {
                      tags: "Signal Processing ICs",
                      keywords: "Signal Processing ICs",
                      description: "Signal Processing ICs",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1575",
                    },
                    slug: "digital-to-analog-converters",
                    title: "Digital-to-Analog Converters",
                    id: 19000341,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic/digital-to-analog-converters",
                    active: true,
                    meta: {
                      tags: "Digital-to-Analog Converters",
                      keywords: "Digital-to-Analog Converters",
                      description: "Digital-to-Analog Converters",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1576",
                    },
                    slug: "power-management-ic",
                    title: "Power Management IC",
                    id: 19000342,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic/power-management-ic",
                    active: true,
                    meta: {
                      tags: "Power Management IC",
                      keywords: "Power Management IC",
                      description: "Power Management IC",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1573",
                    },
                    slug: "analog-to-digital-converters",
                    title: "Analog-to-Digital Converters",
                    id: 19000339,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic/analog-to-digital-converters",
                    active: true,
                    meta: {
                      tags: "Analog-to-Digital Converters",
                      keywords: "Analog-to-Digital Converters",
                      description: "Analog-to-Digital Converters",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1574",
                    },
                    slug: "data-acquisition-systems",
                    title: "Data Acquisition Systems",
                    id: 19000340,
                    parent:
                      "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic",
                    path: "/technology-media-and-telecom/semiconductors/ic/mixed-signal-ic/data-acquisition-systems",
                    active: true,
                    meta: {
                      tags: "Data Acquisition Systems",
                      keywords: "Data Acquisition Systems",
                      description: "Data Acquisition Systems",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64ccdbc490efcbc0445d3a78",
        },
        picture_url: null,
        description: {
          short_description: "Telecom",
          full_description: "Telecom",
        },
        meta: {
          tags: "Telecom",
          keywords: "Telecom",
          description: "Telecom",
        },
        active: true,
        title: "Telecom",
        slug: "telecom",
        id: 84685,
        __v: 0,
        parent: "/technology-media-and-telecom",
        path: "/technology-media-and-telecom/telecom",
        sub_categories: [
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f15e9",
            },
            slug: "communication-protocols",
            title: "Communication Protocols",
            id: 19000459,
            parent: "/technology-media-and-telecom/telecom",
            path: "/technology-media-and-telecom/telecom/communication-protocols",
            active: true,
            meta: {
              tags: "Communication Protocols",
              keywords: "Communication Protocols",
              description: "Communication Protocols",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15ea",
                },
                slug: "wired-communication-protocols",
                title: "Wired Communication Protocols",
                id: 19000460,
                parent:
                  "/technology-media-and-telecom/telecom/communication-protocols",
                path: "/technology-media-and-telecom/telecom/communication-protocols/wired-communication-protocols",
                active: true,
                meta: {
                  tags: "Wired Communication Protocols",
                  keywords: "Wired Communication Protocols",
                  description: "Wired Communication Protocols",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15eb",
                },
                slug: "wireless-communication-protocols",
                title: "Wireless Communication Protocols",
                id: 19000461,
                parent:
                  "/technology-media-and-telecom/telecom/communication-protocols",
                path: "/technology-media-and-telecom/telecom/communication-protocols/wireless-communication-protocols",
                active: true,
                meta: {
                  tags: "Wireless Communication Protocols",
                  keywords: "Wireless Communication Protocols",
                  description: "Wireless Communication Protocols",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15ef",
                    },
                    slug: "wigig",
                    title: "WiGig",
                    id: 19000465,
                    parent:
                      "/technology-media-and-telecom/telecom/communication-protocols/wireless-communication-protocols",
                    path: "/technology-media-and-telecom/telecom/communication-protocols/wireless-communication-protocols/wigig",
                    active: true,
                    meta: {
                      tags: "WiGig",
                      keywords: "WiGig",
                      description: "WiGig",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15ee",
                    },
                    slug: "wifi",
                    title: "WiFi",
                    id: 19000464,
                    parent:
                      "/technology-media-and-telecom/telecom/communication-protocols/wireless-communication-protocols",
                    path: "/technology-media-and-telecom/telecom/communication-protocols/wireless-communication-protocols/wifi",
                    active: true,
                    meta: {
                      tags: "WiFi",
                      keywords: "WiFi",
                      description: "WiFi",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15ed",
                    },
                    slug: "uwb",
                    title: "UWB",
                    id: 19000463,
                    parent:
                      "/technology-media-and-telecom/telecom/communication-protocols/wireless-communication-protocols",
                    path: "/technology-media-and-telecom/telecom/communication-protocols/wireless-communication-protocols/uwb",
                    active: true,
                    meta: {
                      tags: "UWB",
                      keywords: "UWB",
                      description: "UWB",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15ec",
                    },
                    slug: "bluetooth",
                    title: "Bluetooth",
                    id: 19000462,
                    parent:
                      "/technology-media-and-telecom/telecom/communication-protocols/wireless-communication-protocols",
                    path: "/technology-media-and-telecom/telecom/communication-protocols/wireless-communication-protocols/bluetooth",
                    active: true,
                    meta: {
                      tags: "Bluetooth",
                      keywords: "Bluetooth",
                      description: "Bluetooth",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f15f3",
            },
            slug: "telecom-equipment",
            title: "Telecom Equipment",
            id: 19000469,
            parent: "/technology-media-and-telecom/telecom",
            path: "/technology-media-and-telecom/telecom/telecom-equipment",
            active: true,
            meta: {
              tags: "Telecom Equipment",
              keywords: "Telecom Equipment",
              description: "Telecom Equipment",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1601",
                },
                slug: "wireless-communication-equipment",
                title: "Wireless Communication Equipment",
                id: 19000483,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-equipment",
                path: "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment",
                active: true,
                meta: {
                  tags: "Wireless Communication Equipment",
                  keywords: "Wireless Communication Equipment",
                  description: "Wireless Communication Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1606",
                    },
                    slug: "small-cells",
                    title: "Small Cells",
                    id: 19000488,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment",
                    path: "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment/small-cells",
                    active: true,
                    meta: {
                      tags: "Small Cells",
                      keywords: "Small Cells",
                      description: "Small Cells",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1604",
                    },
                    slug: "distributed-antenna-system",
                    title: "Distributed Antenna System",
                    id: 19000486,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment",
                    path: "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment/distributed-antenna-system",
                    active: true,
                    meta: {
                      tags: "Distributed Antenna System",
                      keywords: "Distributed Antenna System",
                      description: "Distributed Antenna System",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1602",
                    },
                    slug: "antennas",
                    title: "Antennas",
                    id: 19000484,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment",
                    path: "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment/antennas",
                    active: true,
                    meta: {
                      tags: "Antennas",
                      keywords: "Antennas",
                      description: "Antennas",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1603",
                    },
                    slug: "base-stations",
                    title: "Base Stations",
                    id: 19000485,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment",
                    path: "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment/base-stations",
                    active: true,
                    meta: {
                      tags: "Base Stations",
                      keywords: "Base Stations",
                      description: "Base Stations",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1605",
                    },
                    slug: "repeaters",
                    title: "Repeaters",
                    id: 19000487,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment",
                    path: "/technology-media-and-telecom/telecom/telecom-equipment/wireless-communication-equipment/repeaters",
                    active: true,
                    meta: {
                      tags: "Repeaters",
                      keywords: "Repeaters",
                      description: "Repeaters",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15f5",
                },
                slug: "telecom-transmission-equipment",
                title: "Telecom Transmission Equipment",
                id: 19000471,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-equipment",
                path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment",
                active: true,
                meta: {
                  tags: "Telecom Transmission Equipment",
                  keywords: "Telecom Transmission Equipment",
                  description: "Telecom Transmission Equipment",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15f7",
                    },
                    slug: "microwave-trasmission",
                    title: "Microwave Trasmission",
                    id: 19000473,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment",
                    path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/microwave-trasmission",
                    active: true,
                    meta: {
                      tags: "Microwave Trasmission",
                      keywords: "Microwave Trasmission",
                      description: "Microwave Trasmission",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1600",
                    },
                    slug: "satellite-communication",
                    title: "Satellite Communication",
                    id: 19000482,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment",
                    path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/satellite-communication",
                    active: true,
                    meta: {
                      tags: "Satellite Communication",
                      keywords: "Satellite Communication",
                      description: "Satellite Communication",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15f6",
                    },
                    slug: "dsl",
                    title: "DSL",
                    id: 19000472,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment",
                    path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/dsl",
                    active: true,
                    meta: {
                      tags: "DSL",
                      keywords: "DSL",
                      description: "DSL",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15f8",
                    },
                    slug: "optical-tranmission",
                    title: "Optical Tranmission",
                    id: 19000474,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment",
                    path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission",
                    active: true,
                    meta: {
                      tags: "Optical Tranmission",
                      keywords: "Optical Tranmission",
                      description: "Optical Tranmission",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15fb",
                        },
                        slug: "optical-amplifiers",
                        title: "Optical Amplifiers",
                        id: 19000477,
                        parent:
                          "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission",
                        path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission/optical-amplifiers",
                        active: true,
                        meta: {
                          tags: "Optical Amplifiers",
                          keywords: "Optical Amplifiers",
                          description: "Optical Amplifiers",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15fc",
                        },
                        slug: "optical-cross-connect",
                        title: "Optical Cross-connect",
                        id: 19000478,
                        parent:
                          "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission",
                        path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission/optical-cross-connect",
                        active: true,
                        meta: {
                          tags: "Optical Cross-connect",
                          keywords: "Optical Cross-connect",
                          description: "Optical Cross-connect",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15fd",
                        },
                        slug: "optical-multiplexer",
                        title: "Optical Multiplexer",
                        id: 19000479,
                        parent:
                          "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission",
                        path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission/optical-multiplexer",
                        active: true,
                        meta: {
                          tags: "Optical Multiplexer",
                          keywords: "Optical Multiplexer",
                          description: "Optical Multiplexer",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15fa",
                        },
                        slug: "fiber-optic-cables",
                        title: "Fiber Optic Cables",
                        id: 19000476,
                        parent:
                          "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission",
                        path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission/fiber-optic-cables",
                        active: true,
                        meta: {
                          tags: "Fiber Optic Cables",
                          keywords: "Fiber Optic Cables",
                          description: "Fiber Optic Cables",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15fe",
                        },
                        slug: "optical-transciever",
                        title: "Optical Transciever",
                        id: 19000480,
                        parent:
                          "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission",
                        path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission/optical-transciever",
                        active: true,
                        meta: {
                          tags: "Optical Transciever",
                          keywords: "Optical Transciever",
                          description: "Optical Transciever",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15f9",
                        },
                        slug: "dwdm",
                        title: "DWDM",
                        id: 19000475,
                        parent:
                          "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission",
                        path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission/dwdm",
                        active: true,
                        meta: {
                          tags: "DWDM",
                          keywords: "DWDM",
                          description: "DWDM",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64ccdc0cc917ae0b494f15ff",
                        },
                        slug: "optical-transport",
                        title: "Optical Transport",
                        id: 19000481,
                        parent:
                          "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission",
                        path: "/technology-media-and-telecom/telecom/telecom-equipment/telecom-transmission-equipment/optical-tranmission/optical-transport",
                        active: true,
                        meta: {
                          tags: "Optical Transport",
                          keywords: "Optical Transport",
                          description: "Optical Transport",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15f4",
                },
                slug: "network-testing-and-monitoring-equipment",
                title: "Network Testing and Monitoring Equipment",
                id: 19000470,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-equipment",
                path: "/technology-media-and-telecom/telecom/telecom-equipment/network-testing-and-monitoring-equipment",
                active: true,
                meta: {
                  tags: "Network Testing and Monitoring Equipment",
                  keywords: "Network Testing and Monitoring Equipment",
                  description: "Network Testing and Monitoring Equipment",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1611",
            },
            slug: "telecom-services",
            title: "Telecom Services",
            id: 19000499,
            parent: "/technology-media-and-telecom/telecom",
            path: "/technology-media-and-telecom/telecom/telecom-services",
            active: true,
            meta: {
              tags: "Telecom Services",
              keywords: "Telecom Services",
              description: "Telecom Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1614",
                },
                slug: "mobile-services",
                title: "Mobile Services",
                id: 19000502,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-services",
                path: "/technology-media-and-telecom/telecom/telecom-services/mobile-services",
                active: true,
                meta: {
                  tags: "Mobile Services",
                  keywords: "Mobile Services",
                  description: "Mobile Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1617",
                    },
                    slug: "6g",
                    title: "6G",
                    id: 19000505,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-services/mobile-services",
                    path: "/technology-media-and-telecom/telecom/telecom-services/mobile-services/6g",
                    active: true,
                    meta: {
                      tags: "6G",
                      keywords: "6G",
                      description: "6G",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1615",
                    },
                    slug: "4g",
                    title: "4G",
                    id: 19000503,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-services/mobile-services",
                    path: "/technology-media-and-telecom/telecom/telecom-services/mobile-services/4g",
                    active: true,
                    meta: {
                      tags: "4G",
                      keywords: "4G",
                      description: "4G",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1616",
                    },
                    slug: "5g",
                    title: "5G",
                    id: 19000504,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-services/mobile-services",
                    path: "/technology-media-and-telecom/telecom/telecom-services/mobile-services/5g",
                    active: true,
                    meta: {
                      tags: "5G",
                      keywords: "5G",
                      description: "5G",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1619",
                },
                slug: "value-add-services",
                title: "Value-add Services",
                id: 19000507,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-services",
                path: "/technology-media-and-telecom/telecom/telecom-services/value-add-services",
                active: true,
                meta: {
                  tags: "Value-add Services",
                  keywords: "Value-add Services",
                  description: "Value-add Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f161a",
                },
                slug: "voip-services",
                title: "VoIP Services",
                id: 19000508,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-services",
                path: "/technology-media-and-telecom/telecom/telecom-services/voip-services",
                active: true,
                meta: {
                  tags: "VoIP Services",
                  keywords: "VoIP Services",
                  description: "VoIP Services",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1618",
                },
                slug: "mvno",
                title: "MVNO",
                id: 19000506,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-services",
                path: "/technology-media-and-telecom/telecom/telecom-services/mvno",
                active: true,
                meta: {
                  tags: "MVNO",
                  keywords: "MVNO",
                  description: "MVNO",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1613",
                },
                slug: "mno",
                title: "MNO",
                id: 19000501,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-services",
                path: "/technology-media-and-telecom/telecom/telecom-services/mno",
                active: true,
                meta: {
                  tags: "MNO",
                  keywords: "MNO",
                  description: "MNO",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1612",
                },
                slug: "messaging-services",
                title: "Messaging Services",
                id: 19000500,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-services",
                path: "/technology-media-and-telecom/telecom/telecom-services/messaging-services",
                active: true,
                meta: {
                  tags: "Messaging Services",
                  keywords: "Messaging Services",
                  description: "Messaging Services",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f161b",
            },
            slug: "telecom-software",
            title: "Telecom Software",
            id: 19000509,
            parent: "/technology-media-and-telecom/telecom",
            path: "/technology-media-and-telecom/telecom/telecom-software",
            active: true,
            meta: {
              tags: "Telecom Software",
              keywords: "Telecom Software",
              description: "Telecom Software",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f15f0",
            },
            slug: "telecom-devices",
            title: "Telecom Devices",
            id: 19000466,
            parent: "/technology-media-and-telecom/telecom",
            path: "/technology-media-and-telecom/telecom/telecom-devices",
            active: true,
            meta: {
              tags: "Telecom Devices",
              keywords: "Telecom Devices",
              description: "Telecom Devices",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f15f1",
                },
                slug: "smartphones",
                title: "Smartphones",
                id: 19000467,
                parent: "/technology-media-and-telecom/telecom/telecom-devices",
                path: "/technology-media-and-telecom/telecom/telecom-devices/smartphones",
                active: true,
                meta: {
                  tags: "Smartphones",
                  keywords: "Smartphones",
                  description: "Smartphones",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f15f2",
                    },
                    slug: "used-and-refurbished-smartphones",
                    title: "Used and Refurbished Smartphones",
                    id: 19000468,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-devices/smartphones",
                    path: "/technology-media-and-telecom/telecom/telecom-devices/smartphones/used-and-refurbished-smartphones",
                    active: true,
                    meta: {
                      tags: "Used and Refurbished Smartphones",
                      keywords: "Used and Refurbished Smartphones",
                      description: "Used and Refurbished Smartphones",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64ccdc0cc917ae0b494f1607",
            },
            slug: "telecom-infrastructure",
            title: "Telecom Infrastructure",
            id: 19000489,
            parent: "/technology-media-and-telecom/telecom",
            path: "/technology-media-and-telecom/telecom/telecom-infrastructure",
            active: true,
            meta: {
              tags: "Telecom Infrastructure",
              keywords: "Telecom Infrastructure",
              description: "Telecom Infrastructure",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1609",
                },
                slug: "fixed-wireless-infrastructure",
                title: "Fixed Wireless Infrastructure",
                id: 19000491,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-infrastructure",
                path: "/technology-media-and-telecom/telecom/telecom-infrastructure/fixed-wireless-infrastructure",
                active: true,
                meta: {
                  tags: "Fixed Wireless Infrastructure",
                  keywords: "Fixed Wireless Infrastructure",
                  description: "Fixed Wireless Infrastructure",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f160f",
                },
                slug: "wires-and-cables",
                title: "Wires and Cables",
                id: 19000497,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-infrastructure",
                path: "/technology-media-and-telecom/telecom/telecom-infrastructure/wires-and-cables",
                active: true,
                meta: {
                  tags: "Wires and Cables",
                  keywords: "Wires and Cables",
                  description: "Wires and Cables",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f1610",
                    },
                    slug: "cable-management",
                    title: "Cable Management",
                    id: 19000498,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-infrastructure/wires-and-cables",
                    path: "/technology-media-and-telecom/telecom/telecom-infrastructure/wires-and-cables/cable-management",
                    active: true,
                    meta: {
                      tags: "Cable Management",
                      keywords: "Cable Management",
                      description: "Cable Management",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f160a",
                },
                slug: "telecom-towers",
                title: "Telecom Towers",
                id: 19000492,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-infrastructure",
                path: "/technology-media-and-telecom/telecom/telecom-infrastructure/telecom-towers",
                active: true,
                meta: {
                  tags: "Telecom Towers",
                  keywords: "Telecom Towers",
                  description: "Telecom Towers",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f160b",
                },
                slug: "telephony-systems",
                title: "Telephony Systems",
                id: 19000493,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-infrastructure",
                path: "/technology-media-and-telecom/telecom/telecom-infrastructure/telephony-systems",
                active: true,
                meta: {
                  tags: "Telephony Systems",
                  keywords: "Telephony Systems",
                  description: "Telephony Systems",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f160e",
                    },
                    slug: "pbx",
                    title: "PBX",
                    id: 19000496,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-infrastructure/telephony-systems",
                    path: "/technology-media-and-telecom/telecom/telecom-infrastructure/telephony-systems/pbx",
                    active: true,
                    meta: {
                      tags: "PBX",
                      keywords: "PBX",
                      description: "PBX",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f160c",
                    },
                    slug: "cloud-communications",
                    title: "Cloud Communications",
                    id: 19000494,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-infrastructure/telephony-systems",
                    path: "/technology-media-and-telecom/telecom/telecom-infrastructure/telephony-systems/cloud-communications",
                    active: true,
                    meta: {
                      tags: "Cloud Communications",
                      keywords: "Cloud Communications",
                      description: "Cloud Communications",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64ccdc0cc917ae0b494f160d",
                    },
                    slug: "ip-telephony",
                    title: "IP Telephony",
                    id: 19000495,
                    parent:
                      "/technology-media-and-telecom/telecom/telecom-infrastructure/telephony-systems",
                    path: "/technology-media-and-telecom/telecom/telecom-infrastructure/telephony-systems/ip-telephony",
                    active: true,
                    meta: {
                      tags: "IP Telephony",
                      keywords: "IP Telephony",
                      description: "IP Telephony",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64ccdc0cc917ae0b494f1608",
                },
                slug: "contact-center",
                title: "Contact Center",
                id: 19000490,
                parent:
                  "/technology-media-and-telecom/telecom/telecom-infrastructure",
                path: "/technology-media-and-telecom/telecom/telecom-infrastructure/contact-center",
                active: true,
                meta: {
                  tags: "Contact Center",
                  keywords: "Contact Center",
                  description: "Contact Center",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b134",
    },
    id: 71,
    title: "Financial Services and Investment Intelligence",
    slug: "financial-services-and-investment-intelligence",
    active: true,
    meta: {
      description:
        "Our Financial Services and Investment industry reports offer insights on major trends, Competitive Analysis of Industry and growth opportunities in the market. it includes market share insights for 2020, 2021, and 2022.  Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
      keywords:
        "financial market research reports, financial services industry analysis, financial services reports, financial services industry statistics, Financial Services and Investment Intelligence Market Reports, financial market analysis report, financial services market overview, financial services market Statistics",
      tags: "Financial Services and Investment Intelligence Market Size & Share Analysis - Industry Research Reports - Growth Trends",
    },
    description: {
      full_description:
        "In these times of low-interest rates, and outdated and numerous regulations across geographies in a time of macroeconomic, financial volatility, it is more important than ever to be aware of impacts of the decisions your company takes. Our research group delivers detailed reports with market analysis and insights to cater to our clients' specific needs, while assisting them in making financial decisions and supporting successful strategies.",
      short_description:
        "In these times of low-interest rates, and outdated and numerous regulations across geographies in a time of macroeconomic and financial volatility, it is more important than ever to be aware of the impacts of your company's decisions. Our research group delivers detailed reports with market analysis and insights to cater to our client's specific needs while assisting them in making financial decisions and supporting successful strategies.",
    },
    picture: null,
    __v: 0,
    cat_email: "neoteam@mordorintelligence.com",
    cat_pw_id: 457946,
    parent: "/",
    path: "/financial-services-and-investment-intelligence",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1c0",
        },
        id: 7160,
        title: "Insurance",
        slug: "insurance",
        active: true,
        meta: {
          description:
            "Mordor Intelligence™ 【  Insurance Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
          keywords:
            "Insurance Market Research Reports, insurance market, insurance market report, insurance market research, insurance industry, insurance industry report, insurance industry research",
          tags: "Insurance Market Size & Share Analysis - Industry Research Reports - Growth Trends",
        },
        description: {
          full_description:
            "In a traditionally profitable business segment, insurance is necessary to bring stability to firms (property and casualty, D&O, and others) and individuals (life insurance, health insurance, and others). However, declining bottom lines across the industry imply that a change is in order. We are here to help your firm transition successfully.",
          short_description:
            "In a traditionally profitable business segment, insurance is necessary to bring stability to firms (property and casualty, D&O, and others) and individuals (life insurance, health insurance, and others). However, declining bottom lines across the industry imply that a change is in order. We are here to help your firm transition successfully.",
        },
        picture: null,
        __v: 0,
        parent: "/financial-services-and-investment-intelligence",
        path: "/financial-services-and-investment-intelligence/insurance",
        sub_categories: [
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0cc",
            },
            slug: "insurance-service-providers",
            title: "Insurance Service Providers",
            id: 13000043,
            parent: "/financial-services-and-investment-intelligence/insurance",
            path: "/financial-services-and-investment-intelligence/insurance/insurance-service-providers",
            active: true,
            meta: {
              tags: "Insurance Service Providers",
              keywords: "Insurance Service Providers",
              description: "Insurance Service Providers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0cd",
                },
                slug: "bancassurance",
                title: "Bancassurance",
                id: 13000044,
                parent:
                  "/financial-services-and-investment-intelligence/insurance/insurance-service-providers",
                path: "/financial-services-and-investment-intelligence/insurance/insurance-service-providers/bancassurance",
                active: true,
                meta: {
                  tags: "Bancassurance",
                  keywords: "Bancassurance",
                  description: "Bancassurance",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0ce",
                },
                slug: "insurance-bpo",
                title: "Insurance BPO",
                id: 13000045,
                parent:
                  "/financial-services-and-investment-intelligence/insurance/insurance-service-providers",
                path: "/financial-services-and-investment-intelligence/insurance/insurance-service-providers/insurance-bpo",
                active: true,
                meta: {
                  tags: "Insurance BPO",
                  keywords: "Insurance BPO",
                  description: "Insurance BPO",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0cf",
                },
                slug: "insurance-brokerage",
                title: "Insurance Brokerage",
                id: 13000046,
                parent:
                  "/financial-services-and-investment-intelligence/insurance/insurance-service-providers",
                path: "/financial-services-and-investment-intelligence/insurance/insurance-service-providers/insurance-brokerage",
                active: true,
                meta: {
                  tags: "Insurance Brokerage",
                  keywords: "Insurance Brokerage",
                  description: "Insurance Brokerage",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0d0",
                },
                slug: "insurance-tpa",
                title: "Insurance TPA",
                id: 13000047,
                parent:
                  "/financial-services-and-investment-intelligence/insurance/insurance-service-providers",
                path: "/financial-services-and-investment-intelligence/insurance/insurance-service-providers/insurance-tpa",
                active: true,
                meta: {
                  tags: "Insurance TPA",
                  keywords: "Insurance TPA",
                  description: "Insurance TPA",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0d1",
            },
            slug: "insurance-technology",
            title: "Insurance Technology",
            id: 13000048,
            parent: "/financial-services-and-investment-intelligence/insurance",
            path: "/financial-services-and-investment-intelligence/insurance/insurance-technology",
            active: true,
            meta: {
              tags: "Insurance Technology",
              keywords: "Insurance Technology",
              description: "Insurance Technology",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0d2",
            },
            slug: "life-and-non-life-insurance",
            title: "Life and Non Life Insurance",
            id: 13000049,
            parent: "/financial-services-and-investment-intelligence/insurance",
            path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance",
            active: true,
            meta: {
              tags: "Life and Non Life Insurance",
              keywords: "Life and Non Life Insurance",
              description: "Life and Non Life Insurance",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0d3",
                },
                slug: "life-and-annuity-insurance",
                title: "Life and Annuity Insurance",
                id: 13000050,
                parent:
                  "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance",
                path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/life-and-annuity-insurance",
                active: true,
                meta: {
                  tags: "Life and Annuity Insurance",
                  keywords: "Life and Annuity Insurance",
                  description: "Life and Annuity Insurance",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0d4",
                },
                slug: "non-life-insurance",
                title: "Non Life Insurance",
                id: 13000051,
                parent:
                  "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance",
                path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance",
                active: true,
                meta: {
                  tags: "Non Life Insurance",
                  keywords: "Non Life Insurance",
                  description: "Non Life Insurance",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2537167c85914c9e4b0d5",
                    },
                    slug: "medical-and-health-insurance",
                    title: "Medical and Health Insurance",
                    id: 13000052,
                    parent:
                      "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance",
                    path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/medical-and-health-insurance",
                    active: true,
                    meta: {
                      tags: "Medical and Health Insurance",
                      keywords: "Medical and Health Insurance",
                      description: "Medical and Health Insurance",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c2537167c85914c9e4b0d6",
                        },
                        slug: "dental-insurance",
                        title: "Dental Insurance",
                        id: 13000053,
                        parent:
                          "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/medical-and-health-insurance",
                        path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/medical-and-health-insurance/dental-insurance",
                        active: true,
                        meta: {
                          tags: "Dental Insurance",
                          keywords: "Dental Insurance",
                          description: "Dental Insurance",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "64c2537167c85914c9e4b0d7",
                    },
                    slug: "property-and-casualty-insurance",
                    title: "Property and Casualty Insurance",
                    id: 13000054,
                    parent:
                      "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance",
                    path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance",
                    active: true,
                    meta: {
                      tags: "Property and Casualty Insurance",
                      keywords: "Property and Casualty Insurance",
                      description: "Property and Casualty Insurance",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "64c2537167c85914c9e4b0d8",
                        },
                        slug: "liability-insurance",
                        title: "Liability Insurance",
                        id: 13000055,
                        parent:
                          "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance",
                        path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/liability-insurance",
                        active: true,
                        meta: {
                          tags: "Liability Insurance",
                          keywords: "Liability Insurance",
                          description: "Liability Insurance",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64c2537167c85914c9e4b0d9",
                            },
                            slug: "cyber-insurance",
                            title: "Cyber Insurance",
                            id: 13000056,
                            parent:
                              "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/liability-insurance",
                            path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/liability-insurance/cyber-insurance",
                            active: true,
                            meta: {
                              tags: "Cyber Insurance",
                              keywords: "Cyber Insurance",
                              description: "Cyber Insurance",
                            },
                            sub_categories: [],
                          },
                          {
                            _id: {
                              $oid: "64c2537167c85914c9e4b0da",
                            },
                            slug: "general-liability-insurance",
                            title: "General Liability Insurance",
                            id: 13000057,
                            parent:
                              "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/liability-insurance",
                            path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/liability-insurance/general-liability-insurance",
                            active: true,
                            meta: {
                              tags: "General Liability Insurance",
                              keywords: "General Liability Insurance",
                              description: "General Liability Insurance",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64c2537167c85914c9e4b0db",
                        },
                        slug: "pet-insurance",
                        title: "Pet Insurance",
                        id: 13000058,
                        parent:
                          "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance",
                        path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/pet-insurance",
                        active: true,
                        meta: {
                          tags: "Pet Insurance",
                          keywords: "Pet Insurance",
                          description: "Pet Insurance",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c2537167c85914c9e4b0dc",
                        },
                        slug: "property-insurance",
                        title: "Property Insurance",
                        id: 13000059,
                        parent:
                          "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance",
                        path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/property-insurance",
                        active: true,
                        meta: {
                          tags: "Property Insurance",
                          keywords: "Property Insurance",
                          description: "Property Insurance",
                        },
                        sub_categories: [
                          {
                            _id: {
                              $oid: "64c2537167c85914c9e4b0dd",
                            },
                            slug: "homeowners-insurance",
                            title: "HomeOwners Insurance",
                            id: 13000060,
                            parent:
                              "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/property-insurance",
                            path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/property-insurance/homeowners-insurance",
                            active: true,
                            meta: {
                              tags: "HomeOwners Insurance",
                              keywords: "HomeOwners Insurance",
                              description: "HomeOwners Insurance",
                            },
                            sub_categories: [],
                          },
                        ],
                      },
                      {
                        _id: {
                          $oid: "64c2537167c85914c9e4b0de",
                        },
                        slug: "travel-insurance",
                        title: "Travel Insurance",
                        id: 13000061,
                        parent:
                          "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance",
                        path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/travel-insurance",
                        active: true,
                        meta: {
                          tags: "Travel Insurance",
                          keywords: "Travel Insurance",
                          description: "Travel Insurance",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "64c2537167c85914c9e4b0df",
                        },
                        slug: "vehicle-insurance",
                        title: "Vehicle Insurance",
                        id: 13000062,
                        parent:
                          "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance",
                        path: "/financial-services-and-investment-intelligence/insurance/life-and-non-life-insurance/non-life-insurance/property-and-casualty-insurance/vehicle-insurance",
                        active: true,
                        meta: {
                          tags: "Vehicle Insurance",
                          keywords: "Vehicle Insurance",
                          description: "Vehicle Insurance",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0e0",
            },
            slug: "reinsurance",
            title: "Reinsurance",
            id: 13000063,
            parent: "/financial-services-and-investment-intelligence/insurance",
            path: "/financial-services-and-investment-intelligence/insurance/reinsurance",
            active: true,
            meta: {
              tags: "Reinsurance",
              keywords: "Reinsurance",
              description: "Reinsurance",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1c1",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Alternative Investments market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Alternative Investments Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Alternative Investments market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Alternative Investments Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Alternative Investments Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Alternative Investments Market Research Reports, alternative investments market growth, alternative investments market analysis, alternative investments industry analysis, alternative investments market report, alternative investments industry report, alternative investments industry analysis, alternative investments industry forecast, alternative investments industry, alternative investments market",
          description:
            "Mordor Intelligence™ 【  Alternative Investments Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Alternative Investments",
        slug: "alternative-investments",
        id: 71198,
        __v: 0,
        parent: "/financial-services-and-investment-intelligence",
        path: "/financial-services-and-investment-intelligence/alternative-investments",
        sub_categories: [
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0a5",
            },
            slug: "hedge-funds",
            title: "Hedge Funds",
            id: 13000002,
            parent:
              "/financial-services-and-investment-intelligence/alternative-investments",
            path: "/financial-services-and-investment-intelligence/alternative-investments/hedge-funds",
            active: true,
            meta: {
              tags: "Hedge Funds",
              keywords: "Hedge Funds",
              description: "Hedge Funds",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0a6",
            },
            slug: "private-equity",
            title: "Private Equity",
            id: 13000003,
            parent:
              "/financial-services-and-investment-intelligence/alternative-investments",
            path: "/financial-services-and-investment-intelligence/alternative-investments/private-equity",
            active: true,
            meta: {
              tags: "Private Equity",
              keywords: "Private Equity",
              description: "Private Equity",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0a7",
            },
            slug: "venture-capital",
            title: "Venture Capital",
            id: 13000004,
            parent:
              "/financial-services-and-investment-intelligence/alternative-investments",
            path: "/financial-services-and-investment-intelligence/alternative-investments/venture-capital",
            active: true,
            meta: {
              tags: "Venture Capital",
              keywords: "Venture Capital",
              description: "Venture Capital",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1c2",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Wealth Management market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Wealth Management Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Wealth Management market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Wealth Management Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Wealth Management Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Wealth Management Market Research Reports, wealth management, wealth management market, wealth management industry, wealth management industry report, wealth management market report, wealth management industry analysis, wealth management industry research, wealth management market intelligence report, wealth management market growth, wealth management industry forecast",
          description:
            "Mordor Intelligence™ 【  Wealth Management Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Wealth Management",
        slug: "wealth-management",
        id: 71199,
        __v: 0,
        parent: "/financial-services-and-investment-intelligence",
        path: "/financial-services-and-investment-intelligence/wealth-management",
        sub_categories: [
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0e1",
            },
            slug: "family-offices",
            title: "Family offices",
            id: 13000066,
            parent:
              "/financial-services-and-investment-intelligence/wealth-management",
            path: "/financial-services-and-investment-intelligence/wealth-management/family-offices",
            active: true,
            meta: {
              tags: "Family offices",
              keywords: "Family offices",
              description: "Family offices",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0e2",
            },
            slug: "investment-banking",
            title: "Investment Banking",
            id: 13000067,
            parent:
              "/financial-services-and-investment-intelligence/wealth-management",
            path: "/financial-services-and-investment-intelligence/wealth-management/investment-banking",
            active: true,
            meta: {
              tags: "Investment Banking",
              keywords: "Investment Banking",
              description: "Investment Banking",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0e3",
            },
            slug: "investment-brokers",
            title: "Investment Brokers",
            id: 13000068,
            parent:
              "/financial-services-and-investment-intelligence/wealth-management",
            path: "/financial-services-and-investment-intelligence/wealth-management/investment-brokers",
            active: true,
            meta: {
              tags: "Investment Brokers",
              keywords: "Investment Brokers",
              description: "Investment Brokers",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0e4",
                },
                slug: "e-brokers",
                title: "E Brokers",
                id: 13000069,
                parent:
                  "/financial-services-and-investment-intelligence/wealth-management/investment-brokers",
                path: "/financial-services-and-investment-intelligence/wealth-management/investment-brokers/e-brokers",
                active: true,
                meta: {
                  tags: "E Brokers",
                  keywords: "E Brokers",
                  description: "E Brokers",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0e5",
            },
            slug: "pension-funds",
            title: "Pension Funds",
            id: 13000070,
            parent:
              "/financial-services-and-investment-intelligence/wealth-management",
            path: "/financial-services-and-investment-intelligence/wealth-management/pension-funds",
            active: true,
            meta: {
              tags: "Pension Funds",
              keywords: "Pension Funds",
              description: "Pension Funds",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1c3",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Fintech market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Fintech Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Fintech market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Fintech Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Fintech Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Fintech Market Research Reports, Fintech, Fintech Market, Fintech industry, Fintech Market research report, Fintech industry research report, Fintech market research, Fintech industry research",
          description:
            "Mordor Intelligence™ 【  Fintech Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Fintech",
        slug: "fintech",
        id: 71200,
        __v: 0,
        parent: "/financial-services-and-investment-intelligence",
        path: "/financial-services-and-investment-intelligence/fintech",
        sub_categories: [
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0c3",
            },
            slug: "banking-as-a-service",
            title: "Banking As A Service",
            id: 13000033,
            parent: "/financial-services-and-investment-intelligence/fintech",
            path: "/financial-services-and-investment-intelligence/fintech/banking-as-a-service",
            active: true,
            meta: {
              tags: "Banking As A Service",
              keywords: "Banking As A Service",
              description: "Banking As A Service",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0c4",
            },
            slug: "cryptocurrency-ecosystem",
            title: "CryptoCurrency Ecosystem",
            id: 13000034,
            parent: "/financial-services-and-investment-intelligence/fintech",
            path: "/financial-services-and-investment-intelligence/fintech/cryptocurrency-ecosystem",
            active: true,
            meta: {
              tags: "CryptoCurrency Ecosystem",
              keywords: "CryptoCurrency Ecosystem",
              description: "CryptoCurrency Ecosystem",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0c5",
                },
                slug: "cryptocurrency",
                title: "CryptoCurrency",
                id: 13000035,
                parent:
                  "/financial-services-and-investment-intelligence/fintech/cryptocurrency-ecosystem",
                path: "/financial-services-and-investment-intelligence/fintech/cryptocurrency-ecosystem/cryptocurrency",
                active: true,
                meta: {
                  tags: "CryptoCurrency",
                  keywords: "CryptoCurrency",
                  description: "CryptoCurrency",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0c6",
            },
            slug: "digital-banks",
            title: "Digital Banks",
            id: 13000036,
            parent: "/financial-services-and-investment-intelligence/fintech",
            path: "/financial-services-and-investment-intelligence/fintech/digital-banks",
            active: true,
            meta: {
              tags: "Digital Banks",
              keywords: "Digital Banks",
              description: "Digital Banks",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0c7",
            },
            slug: "fintech-services",
            title: "Fintech Services",
            id: 13000037,
            parent: "/financial-services-and-investment-intelligence/fintech",
            path: "/financial-services-and-investment-intelligence/fintech/fintech-services",
            active: true,
            meta: {
              tags: "Fintech Services",
              keywords: "Fintech Services",
              description: "Fintech Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0c8",
                },
                slug: "anti-money-laundering",
                title: "Anti Money Laundering",
                id: 13000038,
                parent:
                  "/financial-services-and-investment-intelligence/fintech/fintech-services",
                path: "/financial-services-and-investment-intelligence/fintech/fintech-services/anti-money-laundering",
                active: true,
                meta: {
                  tags: "Anti Money Laundering",
                  keywords: "Anti Money Laundering",
                  description: "Anti Money Laundering",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0c9",
            },
            slug: "lending",
            title: "Lending",
            id: 13000039,
            parent: "/financial-services-and-investment-intelligence/fintech",
            path: "/financial-services-and-investment-intelligence/fintech/lending",
            active: true,
            meta: {
              tags: "Lending",
              keywords: "Lending",
              description: "Lending",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0ca",
                },
                slug: "buy-now-pay-later",
                title: "Buy Now Pay Later",
                id: 13000040,
                parent:
                  "/financial-services-and-investment-intelligence/fintech/lending",
                path: "/financial-services-and-investment-intelligence/fintech/lending/buy-now-pay-later",
                active: true,
                meta: {
                  tags: "Buy Now Pay Later",
                  keywords: "Buy Now Pay Later",
                  description: "Buy Now Pay Later",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0cb",
            },
            slug: "regulation-technology",
            title: "Regulation Technology",
            id: 13000041,
            parent: "/financial-services-and-investment-intelligence/fintech",
            path: "/financial-services-and-investment-intelligence/fintech/regulation-technology",
            active: true,
            meta: {
              tags: "Regulation Technology",
              keywords: "Regulation Technology",
              description: "Regulation Technology",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1c4",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Islamic Finance market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Islamic Finance Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Islamic Finance market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Islamic Finance Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Islamic Finance Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Islamic Finance Market Research Reports, islamic finance market, islamic finance industry, islamic finance market report, islamic finance industry report, finance market, investment market, fintech market, finance market research reports",
          description:
            "Mordor Intelligence™ 【  Islamic Finance Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Islamic Finance",
        slug: "islamic-finance",
        id: 71201,
        __v: 0,
        parent: "/financial-services-and-investment-intelligence",
        path: "/financial-services-and-investment-intelligence/islamic-finance",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "64c2537167c85914c9e4b0a8",
        },
        slug: "asset-management",
        title: "Asset Management",
        id: 71670,
        parent: "/financial-services-and-investment-intelligence",
        path: "/financial-services-and-investment-intelligence/asset-management",
        active: true,
        meta: {
          tags: "Asset Management",
          keywords: "Asset Management",
          description: "Asset Management",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0a9",
            },
            slug: "etfs",
            title: "ETFs",
            id: 13000006,
            parent:
              "/financial-services-and-investment-intelligence/asset-management",
            path: "/financial-services-and-investment-intelligence/asset-management/etfs",
            active: true,
            meta: {
              tags: "ETFs",
              keywords: "ETFs",
              description: "ETFs",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0aa",
            },
            slug: "fixed-income-asset-management",
            title: "Fixed Income Asset Management",
            id: 13000007,
            parent:
              "/financial-services-and-investment-intelligence/asset-management",
            path: "/financial-services-and-investment-intelligence/asset-management/fixed-income-asset-management",
            active: true,
            meta: {
              tags: "Fixed Income Asset Management",
              keywords: "Fixed Income Asset Management",
              description: "Fixed Income Asset Management",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0ab",
            },
            slug: "mutual-funds",
            title: "Mutual Funds",
            id: 13000008,
            parent:
              "/financial-services-and-investment-intelligence/asset-management",
            path: "/financial-services-and-investment-intelligence/asset-management/mutual-funds",
            active: true,
            meta: {
              tags: "Mutual Funds",
              keywords: "Mutual Funds",
              description: "Mutual Funds",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0ac",
            },
            slug: "reit",
            title: "REIT",
            id: 13000009,
            parent:
              "/financial-services-and-investment-intelligence/asset-management",
            path: "/financial-services-and-investment-intelligence/asset-management/reit",
            active: true,
            meta: {
              tags: "REIT",
              keywords: "REIT",
              description: "REIT",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64c2537167c85914c9e4b0ad",
        },
        slug: "banking-and-non-bank-finance",
        title: "Banking and Non Bank Finance",
        id: 71671,
        parent: "/financial-services-and-investment-intelligence",
        path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance",
        active: true,
        meta: {
          tags: "Banking and Non Bank Finance",
          keywords: "Banking and Non Bank Finance",
          description: "Banking and Non Bank Finance",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0ae",
            },
            slug: "commercial-banking",
            title: "Commercial Banking",
            id: 13000011,
            parent:
              "/financial-services-and-investment-intelligence/banking-and-non-bank-finance",
            path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/commercial-banking",
            active: true,
            meta: {
              tags: "Commercial Banking",
              keywords: "Commercial Banking",
              description: "Commercial Banking",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0af",
            },
            slug: "credit-rating-and-information-services",
            title: "Credit Rating and Information Services",
            id: 13000012,
            parent:
              "/financial-services-and-investment-intelligence/banking-and-non-bank-finance",
            path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/credit-rating-and-information-services",
            active: true,
            meta: {
              tags: "Credit Rating and Information Services",
              keywords: "Credit Rating and Information Services",
              description: "Credit Rating and Information Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0b0",
            },
            slug: "loans-and-leasing",
            title: "Loans and Leasing",
            id: 13000013,
            parent:
              "/financial-services-and-investment-intelligence/banking-and-non-bank-finance",
            path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing",
            active: true,
            meta: {
              tags: "Loans and Leasing",
              keywords: "Loans and Leasing",
              description: "Loans and Leasing",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0b1",
                },
                slug: "mortgage-lending",
                title: "Mortgage Lending",
                id: 13000014,
                parent:
                  "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing",
                path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing/mortgage-lending",
                active: true,
                meta: {
                  tags: "Mortgage Lending",
                  keywords: "Mortgage Lending",
                  description: "Mortgage Lending",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2537167c85914c9e4b0b2",
                    },
                    slug: "home-equity-lending",
                    title: "Home Equity Lending",
                    id: 13000015,
                    parent:
                      "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing/mortgage-lending",
                    path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing/mortgage-lending/home-equity-lending",
                    active: true,
                    meta: {
                      tags: "Home Equity Lending",
                      keywords: "Home Equity Lending",
                      description: "Home Equity Lending",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2537167c85914c9e4b0b3",
                    },
                    slug: "home-mortgage-finance",
                    title: "Home Mortgage Finance",
                    id: 13000016,
                    parent:
                      "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing/mortgage-lending",
                    path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing/mortgage-lending/home-mortgage-finance",
                    active: true,
                    meta: {
                      tags: "Home Mortgage Finance",
                      keywords: "Home Mortgage Finance",
                      description: "Home Mortgage Finance",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2537167c85914c9e4b0b4",
                    },
                    slug: "mortgage-and-loan-brokers",
                    title: "Mortgage and Loan Brokers",
                    id: 13000017,
                    parent:
                      "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing/mortgage-lending",
                    path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing/mortgage-lending/mortgage-and-loan-brokers",
                    active: true,
                    meta: {
                      tags: "Mortgage and Loan Brokers",
                      keywords: "Mortgage and Loan Brokers",
                      description: "Mortgage and Loan Brokers",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0b5",
                },
                slug: "vehicle-finance",
                title: "Vehicle Finance",
                id: 13000018,
                parent:
                  "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing",
                path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/loans-and-leasing/vehicle-finance",
                active: true,
                meta: {
                  tags: "Vehicle Finance",
                  keywords: "Vehicle Finance",
                  description: "Vehicle Finance",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0b6",
            },
            slug: "money-transfer-and-payments",
            title: "Money Transfer and Payments",
            id: 13000019,
            parent:
              "/financial-services-and-investment-intelligence/banking-and-non-bank-finance",
            path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/money-transfer-and-payments",
            active: true,
            meta: {
              tags: "Money Transfer and Payments",
              keywords: "Money Transfer and Payments",
              description: "Money Transfer and Payments",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0b7",
                },
                slug: "credit-cards",
                title: "Credit Cards",
                id: 13000020,
                parent:
                  "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/money-transfer-and-payments",
                path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/money-transfer-and-payments/credit-cards",
                active: true,
                meta: {
                  tags: "Credit Cards",
                  keywords: "Credit Cards",
                  description: "Credit Cards",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0b8",
                },
                slug: "gift-cards-and-incentive-cards",
                title: "Gift Cards and Incentive Cards",
                id: 13000021,
                parent:
                  "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/money-transfer-and-payments",
                path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/money-transfer-and-payments/gift-cards-and-incentive-cards",
                active: true,
                meta: {
                  tags: "Gift Cards and Incentive Cards",
                  keywords: "Gift Cards and Incentive Cards",
                  description: "Gift Cards and Incentive Cards",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0b9",
                },
                slug: "prepaid-cards",
                title: "Prepaid Cards",
                id: 13000022,
                parent:
                  "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/money-transfer-and-payments",
                path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/money-transfer-and-payments/prepaid-cards",
                active: true,
                meta: {
                  tags: "Prepaid Cards",
                  keywords: "Prepaid Cards",
                  description: "Prepaid Cards",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0ba",
                },
                slug: "virtual-cards",
                title: "Virtual Cards",
                id: 13000023,
                parent:
                  "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/money-transfer-and-payments",
                path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/money-transfer-and-payments/virtual-cards",
                active: true,
                meta: {
                  tags: "Virtual Cards",
                  keywords: "Virtual Cards",
                  description: "Virtual Cards",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0bb",
            },
            slug: "private-banking",
            title: "Private Banking",
            id: 13000024,
            parent:
              "/financial-services-and-investment-intelligence/banking-and-non-bank-finance",
            path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/private-banking",
            active: true,
            meta: {
              tags: "Private Banking",
              keywords: "Private Banking",
              description: "Private Banking",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0bc",
            },
            slug: "retail-banking",
            title: "Retail Banking",
            id: 13000025,
            parent:
              "/financial-services-and-investment-intelligence/banking-and-non-bank-finance",
            path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/retail-banking",
            active: true,
            meta: {
              tags: "Retail Banking",
              keywords: "Retail Banking",
              description: "Retail Banking",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0bd",
            },
            slug: "trade-and-supply-chain-finance",
            title: "Trade and Supply Chain Finance",
            id: 13000026,
            parent:
              "/financial-services-and-investment-intelligence/banking-and-non-bank-finance",
            path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/trade-and-supply-chain-finance",
            active: true,
            meta: {
              tags: "Trade and Supply Chain Finance",
              keywords: "Trade and Supply Chain Finance",
              description: "Trade and Supply Chain Finance",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0be",
                },
                slug: "supply-chain-finance",
                title: "Supply Chain Finance",
                id: 13000027,
                parent:
                  "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/trade-and-supply-chain-finance",
                path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/trade-and-supply-chain-finance/supply-chain-finance",
                active: true,
                meta: {
                  tags: "Supply Chain Finance",
                  keywords: "Supply Chain Finance",
                  description: "Supply Chain Finance",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0bf",
                },
                slug: "trade-finance",
                title: "Trade Finance",
                id: 13000028,
                parent:
                  "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/trade-and-supply-chain-finance",
                path: "/financial-services-and-investment-intelligence/banking-and-non-bank-finance/trade-and-supply-chain-finance/trade-finance",
                active: true,
                meta: {
                  tags: "Trade Finance",
                  keywords: "Trade Finance",
                  description: "Trade Finance",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64c2537167c85914c9e4b0c0",
        },
        slug: "capital-market",
        title: "Capital Market",
        id: 71672,
        parent: "/financial-services-and-investment-intelligence",
        path: "/financial-services-and-investment-intelligence/capital-market",
        active: true,
        meta: {
          tags: "Capital Market",
          keywords: "Capital Market",
          description: "Capital Market",
        },
        sub_categories: [
          {
            _id: {
              $oid: "64c2537167c85914c9e4b0c1",
            },
            slug: "capital-market-services",
            title: "Capital Market Services",
            id: 13000030,
            parent:
              "/financial-services-and-investment-intelligence/capital-market",
            path: "/financial-services-and-investment-intelligence/capital-market/capital-market-services",
            active: true,
            meta: {
              tags: "Capital Market Services",
              keywords: "Capital Market Services",
              description: "Capital Market Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2537167c85914c9e4b0c2",
                },
                slug: "clearing-houses",
                title: "Clearing Houses",
                id: 13000031,
                parent:
                  "/financial-services-and-investment-intelligence/capital-market/capital-market-services",
                path: "/financial-services-and-investment-intelligence/capital-market/capital-market-services/clearing-houses",
                active: true,
                meta: {
                  tags: "Clearing Houses",
                  keywords: "Clearing Houses",
                  description: "Clearing Houses",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b135",
    },
    picture_url: null,
    description: {
      short_description:
        "Statistics for the 2023 Logistics market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Logistics analysis includes a market forecast outlook to 2028 and historical overview. Get a sample of this industry analysis as a free report PDF download.",
      full_description:
        "Statistics for the 2023 Logistics market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Logistics analysis includes a market forecast outlook to 2028 and historical overview. Get a sample of this industry analysis as a free report PDF download.",
    },
    meta: {
      tags: "Logistics Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "logistics market, market logistics, last mile delivery market size, smart transportation market, logistics market size, last mile delivery market, transport market, cold chain market, logistics marketplace, freight forwarding market, cold chain market size\nfreight forwarding market size, pharmaceutical logistics market, same day delivery market, cold chain logistics market, transportation management systems market, packaging market size, international marketing logistics, us logistics market report,, logistics market trends, logistics market report, food packaging market size",
      description:
        "Mordor Intelligence™ 【  Logistics Market Research  】 Industry Reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.\n",
    },
    active: true,
    title: "Logistics",
    slug: "logistics",
    id: 72,
    __v: 0,
    cat_email: "neoteam@mordorintelligence.com",
    cat_pw_id: 457949,
    parent: "/",
    path: "/logistics",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1c6",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Logistics Industry Report market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Logistics Industry Report Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Logistics Industry Report market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Logistics Industry Report Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Logistics Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Logistics Industry Report Market Research Reports, logistics market research, market research logistics industry, logistics market, logistics market research report, logistics industry report, logistics industry research report, logistics market intelligence report.",
          description:
            "Mordor Intelligence™ 【  Logistics Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Logistics Industry",
        slug: "logistics-industry-report",
        id: 72205,
        __v: 0,
        parent: "/logistics",
        path: "/logistics/logistics-industry-report",
        sub_categories: [],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b4fe",
        },
        slug: "freight-transport",
        title: "Freight Transport",
        id: 72602,
        parent: "/logistics",
        path: "/logistics/freight-transport",
        active: true,
        meta: {
          tags: "Freight Transport",
          keywords: "Freight Transport",
          description: "Freight Transport",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b4ff",
            },
            slug: "road-transport",
            title: "Road Transport",
            id: 2000002,
            parent: "/logistics/freight-transport",
            path: "/logistics/freight-transport/road-transport",
            active: true,
            meta: {
              tags: "Road Transport",
              keywords: "Road Transport",
              description: "Road Transport",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b500",
                },
                slug: "trucking",
                title: "Trucking",
                id: 2000003,
                parent: "/logistics/freight-transport/road-transport",
                path: "/logistics/freight-transport/road-transport/trucking",
                active: true,
                meta: {
                  tags: "Trucking",
                  keywords: "Trucking",
                  description: "Trucking",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b501",
                },
                slug: "ftl",
                title: "FTL",
                id: 2000004,
                parent: "/logistics/freight-transport/road-transport",
                path: "/logistics/freight-transport/road-transport/ftl",
                active: true,
                meta: {
                  tags: "FTL",
                  keywords: "FTL",
                  description: "FTL",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b502",
                },
                slug: "ltl",
                title: "LTL",
                id: 2000005,
                parent: "/logistics/freight-transport/road-transport",
                path: "/logistics/freight-transport/road-transport/ltl",
                active: true,
                meta: {
                  tags: "LTL",
                  keywords: "LTL",
                  description: "LTL",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b503",
                },
                slug: "short-haul-trucking",
                title: "Short Haul Trucking",
                id: 2000006,
                parent: "/logistics/freight-transport/road-transport",
                path: "/logistics/freight-transport/road-transport/short-haul-trucking",
                active: true,
                meta: {
                  tags: "Short Haul Trucking",
                  keywords: "Short Haul Trucking",
                  description: "Short Haul Trucking",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b504",
                },
                slug: "long-haul-trucking",
                title: "Long Haul Trucking",
                id: 2000007,
                parent: "/logistics/freight-transport/road-transport",
                path: "/logistics/freight-transport/road-transport/long-haul-trucking",
                active: true,
                meta: {
                  tags: "Long Haul Trucking",
                  keywords: "Long Haul Trucking",
                  description: "Long Haul Trucking",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b505",
                },
                slug: "tank-truck",
                title: "Tank Truck",
                id: 2000008,
                parent: "/logistics/freight-transport/road-transport",
                path: "/logistics/freight-transport/road-transport/tank-truck",
                active: true,
                meta: {
                  tags: "Tank Truck",
                  keywords: "Tank Truck",
                  description: "Tank Truck",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b506",
                },
                slug: "refrigerated-trucking",
                title: "Refrigerated Trucking",
                id: 2000009,
                parent: "/logistics/freight-transport/road-transport",
                path: "/logistics/freight-transport/road-transport/refrigerated-trucking",
                active: true,
                meta: {
                  tags: "Refrigerated Trucking",
                  keywords: "Refrigerated Trucking",
                  description: "Refrigerated Trucking",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b507",
                },
                slug: "cross-border-transport",
                title: "Cross Border Transport",
                id: 2000010,
                parent: "/logistics/freight-transport/road-transport",
                path: "/logistics/freight-transport/road-transport/cross-border-transport",
                active: true,
                meta: {
                  tags: "Cross Border Transport",
                  keywords: "Cross Border Transport",
                  description: "Cross Border Transport",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b508",
                },
                slug: "last-mile-delivery",
                title: "Last Mile Delivery",
                id: 2000011,
                parent: "/logistics/freight-transport/road-transport",
                path: "/logistics/freight-transport/road-transport/last-mile-delivery",
                active: true,
                meta: {
                  tags: "Last Mile Delivery",
                  keywords: "Last Mile Delivery",
                  description: "Last Mile Delivery",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b509",
            },
            slug: "rail-transport",
            title: "Rail Transport",
            id: 2000012,
            parent: "/logistics/freight-transport",
            path: "/logistics/freight-transport/rail-transport",
            active: true,
            meta: {
              tags: "Rail Transport",
              keywords: "Rail Transport",
              description: "Rail Transport",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b50a",
                },
                slug: "intermodal-rail-freight",
                title: "Intermodal Rail Freight",
                id: 2000013,
                parent: "/logistics/freight-transport/rail-transport",
                path: "/logistics/freight-transport/rail-transport/intermodal-rail-freight",
                active: true,
                meta: {
                  tags: "Intermodal Rail Freight",
                  keywords: "Intermodal Rail Freight",
                  description: "Intermodal Rail Freight",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b50b",
            },
            slug: "air-transport",
            title: "Air Transport",
            id: 2000014,
            parent: "/logistics/freight-transport",
            path: "/logistics/freight-transport/air-transport",
            active: true,
            meta: {
              tags: "Air Transport",
              keywords: "Air Transport",
              description: "Air Transport",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b50c",
                },
                slug: "chartered-air-freight",
                title: "Chartered Air Freight",
                id: 2000015,
                parent: "/logistics/freight-transport/air-transport",
                path: "/logistics/freight-transport/air-transport/chartered-air-freight",
                active: true,
                meta: {
                  tags: "Chartered Air Freight",
                  keywords: "Chartered Air Freight",
                  description: "Chartered Air Freight",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b50d",
            },
            slug: "water-transport",
            title: "Water Transport",
            id: 2000016,
            parent: "/logistics/freight-transport",
            path: "/logistics/freight-transport/water-transport",
            active: true,
            meta: {
              tags: "Water Transport",
              keywords: "Water Transport",
              description: "Water Transport",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b50e",
                },
                slug: "sea-freight",
                title: "Sea Freight",
                id: 2000017,
                parent: "/logistics/freight-transport/water-transport",
                path: "/logistics/freight-transport/water-transport/sea-freight",
                active: true,
                meta: {
                  tags: "Sea Freight",
                  keywords: "Sea Freight",
                  description: "Sea Freight",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b50f",
                    },
                    slug: "container-shipping",
                    title: "Container Shipping",
                    id: 2000018,
                    parent:
                      "/logistics/freight-transport/water-transport/sea-freight",
                    path: "/logistics/freight-transport/water-transport/sea-freight/container-shipping",
                    active: true,
                    meta: {
                      tags: "Container Shipping",
                      keywords: "Container Shipping",
                      description: "Container Shipping",
                    },
                    sub_categories: [
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b510",
                        },
                        slug: "full-container-load",
                        title: "Full Container Load",
                        id: 2000019,
                        parent:
                          "/logistics/freight-transport/water-transport/sea-freight/container-shipping",
                        path: "/logistics/freight-transport/water-transport/sea-freight/container-shipping/full-container-load",
                        active: true,
                        meta: {
                          tags: "Full Container Load",
                          keywords: "Full Container Load",
                          description: "Full Container Load",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b511",
                        },
                        slug: "less-than-container-load",
                        title: "Less than Container Load",
                        id: 2000020,
                        parent:
                          "/logistics/freight-transport/water-transport/sea-freight/container-shipping",
                        path: "/logistics/freight-transport/water-transport/sea-freight/container-shipping/less-than-container-load",
                        active: true,
                        meta: {
                          tags: "Less than Container Load",
                          keywords: "Less than Container Load",
                          description: "Less than Container Load",
                        },
                        sub_categories: [],
                      },
                      {
                        _id: {
                          $oid: "6480c9e11b708a832f67b512",
                        },
                        slug: "refrigerated-container-shipping",
                        title: "Refrigerated Container Shipping",
                        id: 2000021,
                        parent:
                          "/logistics/freight-transport/water-transport/sea-freight/container-shipping",
                        path: "/logistics/freight-transport/water-transport/sea-freight/container-shipping/refrigerated-container-shipping",
                        active: true,
                        meta: {
                          tags: "Refrigerated Container Shipping",
                          keywords: "Refrigerated Container Shipping",
                          description: "Refrigerated Container Shipping",
                        },
                        sub_categories: [],
                      },
                    ],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b513",
                    },
                    slug: "tanker-shipping",
                    title: "Tanker Shipping",
                    id: 2000022,
                    parent:
                      "/logistics/freight-transport/water-transport/sea-freight",
                    path: "/logistics/freight-transport/water-transport/sea-freight/tanker-shipping",
                    active: true,
                    meta: {
                      tags: "Tanker Shipping",
                      keywords: "Tanker Shipping",
                      description: "Tanker Shipping",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b514",
                    },
                    slug: "roro-shipping",
                    title: "RORO Shipping",
                    id: 2000023,
                    parent:
                      "/logistics/freight-transport/water-transport/sea-freight",
                    path: "/logistics/freight-transport/water-transport/sea-freight/roro-shipping",
                    active: true,
                    meta: {
                      tags: "RORO Shipping",
                      keywords: "RORO Shipping",
                      description: "RORO Shipping",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b515",
                },
                slug: "inland-water-freight",
                title: "Inland Water Freight",
                id: 2000024,
                parent: "/logistics/freight-transport/water-transport",
                path: "/logistics/freight-transport/water-transport/inland-water-freight",
                active: true,
                meta: {
                  tags: "Inland Water Freight",
                  keywords: "Inland Water Freight",
                  description: "Inland Water Freight",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b516",
        },
        slug: "freight-transportation-arrangement",
        title: "Freight Transportation Arrangement",
        id: 72603,
        parent: "/logistics",
        path: "/logistics/freight-transportation-arrangement",
        active: true,
        meta: {
          tags: "Freight Transportation Arrangement",
          keywords: "Freight Transportation Arrangement",
          description: "Freight Transportation Arrangement",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b517",
            },
            slug: "freight-forwarding",
            title: "Freight Forwarding",
            id: 2000026,
            parent: "/logistics/freight-transportation-arrangement",
            path: "/logistics/freight-transportation-arrangement/freight-forwarding",
            active: true,
            meta: {
              tags: "Freight Forwarding",
              keywords: "Freight Forwarding",
              description: "Freight Forwarding",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b518",
                },
                slug: "air-freight-forwarding",
                title: "Air Freight Forwarding",
                id: 2000027,
                parent:
                  "/logistics/freight-transportation-arrangement/freight-forwarding",
                path: "/logistics/freight-transportation-arrangement/freight-forwarding/air-freight-forwarding",
                active: true,
                meta: {
                  tags: "Air Freight Forwarding",
                  keywords: "Air Freight Forwarding",
                  description: "Air Freight Forwarding",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b519",
                },
                slug: "sea-freight-forwarding",
                title: "Sea Freight Forwarding",
                id: 2000028,
                parent:
                  "/logistics/freight-transportation-arrangement/freight-forwarding",
                path: "/logistics/freight-transportation-arrangement/freight-forwarding/sea-freight-forwarding",
                active: true,
                meta: {
                  tags: "Sea Freight Forwarding",
                  keywords: "Sea Freight Forwarding",
                  description: "Sea Freight Forwarding",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "6480c9e11b708a832f67b51a",
                    },
                    slug: "nvocc",
                    title: "NVOCC",
                    id: 2000029,
                    parent:
                      "/logistics/freight-transportation-arrangement/freight-forwarding/sea-freight-forwarding",
                    path: "/logistics/freight-transportation-arrangement/freight-forwarding/sea-freight-forwarding/nvocc",
                    active: true,
                    meta: {
                      tags: "NVOCC",
                      keywords: "NVOCC",
                      description: "NVOCC",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b51b",
                },
                slug: "digital-freight-forwarding",
                title: "Digital Freight Forwarding",
                id: 2000030,
                parent:
                  "/logistics/freight-transportation-arrangement/freight-forwarding",
                path: "/logistics/freight-transportation-arrangement/freight-forwarding/digital-freight-forwarding",
                active: true,
                meta: {
                  tags: "Digital Freight Forwarding",
                  keywords: "Digital Freight Forwarding",
                  description: "Digital Freight Forwarding",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b51c",
            },
            slug: "customs-brokerage",
            title: "Customs Brokerage",
            id: 2000031,
            parent: "/logistics/freight-transportation-arrangement",
            path: "/logistics/freight-transportation-arrangement/customs-brokerage",
            active: true,
            meta: {
              tags: "Customs Brokerage",
              keywords: "Customs Brokerage",
              description: "Customs Brokerage",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b51d",
            },
            slug: "freight-brokerage",
            title: "Freight Brokerage",
            id: 2000032,
            parent: "/logistics/freight-transportation-arrangement",
            path: "/logistics/freight-transportation-arrangement/freight-brokerage",
            active: true,
            meta: {
              tags: "Freight Brokerage",
              keywords: "Freight Brokerage",
              description: "Freight Brokerage",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b51e",
            },
            slug: "trucking-aggregators",
            title: "Trucking Aggregators",
            id: 2000033,
            parent: "/logistics/freight-transportation-arrangement",
            path: "/logistics/freight-transportation-arrangement/trucking-aggregators",
            active: true,
            meta: {
              tags: "Trucking Aggregators",
              keywords: "Trucking Aggregators",
              description: "Trucking Aggregators",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b51f",
            },
            slug: "shipping-agency-services",
            title: "Shipping Agency Services",
            id: 2000034,
            parent: "/logistics/freight-transportation-arrangement",
            path: "/logistics/freight-transportation-arrangement/shipping-agency-services",
            active: true,
            meta: {
              tags: "Shipping Agency Services",
              keywords: "Shipping Agency Services",
              description: "Shipping Agency Services",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b520",
        },
        slug: "courier-express-parcel",
        title: "Courier Express Parcel",
        id: 72604,
        parent: "/logistics",
        path: "/logistics/courier-express-parcel",
        active: true,
        meta: {
          tags: "Courier Express Parcel",
          keywords: "Courier Express Parcel",
          description: "Courier Express Parcel",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b521",
            },
            slug: "domestic-courier-express-parcel",
            title: "Domestic Courier Express Parcel",
            id: 2000036,
            parent: "/logistics/courier-express-parcel",
            path: "/logistics/courier-express-parcel/domestic-courier-express-parcel",
            active: true,
            meta: {
              tags: "Domestic Courier Express Parcel",
              keywords: "Domestic Courier Express Parcel",
              description: "Domestic Courier Express Parcel",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b522",
            },
            slug: "international-courier-express-parcel",
            title: "International Courier Express Parcel",
            id: 2000037,
            parent: "/logistics/courier-express-parcel",
            path: "/logistics/courier-express-parcel/international-courier-express-parcel",
            active: true,
            meta: {
              tags: "International Courier Express Parcel",
              keywords: "International Courier Express Parcel",
              description: "International Courier Express Parcel",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b523",
            },
            slug: "express-delivery",
            title: "Express Delivery",
            id: 2000038,
            parent: "/logistics/courier-express-parcel",
            path: "/logistics/courier-express-parcel/express-delivery",
            active: true,
            meta: {
              tags: "Express Delivery",
              keywords: "Express Delivery",
              description: "Express Delivery",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b524",
            },
            slug: "small-parcels",
            title: "Small Parcels",
            id: 2000039,
            parent: "/logistics/courier-express-parcel",
            path: "/logistics/courier-express-parcel/small-parcels",
            active: true,
            meta: {
              tags: "Small Parcels",
              keywords: "Small Parcels",
              description: "Small Parcels",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b525",
            },
            slug: "same-day-delivery",
            title: "Same Day Delivery",
            id: 2000040,
            parent: "/logistics/courier-express-parcel",
            path: "/logistics/courier-express-parcel/same-day-delivery",
            active: true,
            meta: {
              tags: "Same Day Delivery",
              keywords: "Same Day Delivery",
              description: "Same Day Delivery",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b526",
        },
        slug: "integrated-logistics",
        title: "Integrated Logistics",
        id: 72605,
        parent: "/logistics",
        path: "/logistics/integrated-logistics",
        active: true,
        meta: {
          tags: "Integrated Logistics",
          keywords: "Integrated Logistics",
          description: "Integrated Logistics",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b527",
            },
            slug: "3pl",
            title: "3PL",
            id: 2000042,
            parent: "/logistics/integrated-logistics",
            path: "/logistics/integrated-logistics/3pl",
            active: true,
            meta: {
              tags: "3PL",
              keywords: "3PL",
              description: "3PL",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b528",
                },
                slug: "automotive-3pl",
                title: "Automotive 3PL",
                id: 2000043,
                parent: "/logistics/integrated-logistics/3pl",
                path: "/logistics/integrated-logistics/3pl/automotive-3pl",
                active: true,
                meta: {
                  tags: "Automotive 3PL",
                  keywords: "Automotive 3PL",
                  description: "Automotive 3PL",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b529",
                },
                slug: "retail-3pl",
                title: "Retail 3PL",
                id: 2000044,
                parent: "/logistics/integrated-logistics/3pl",
                path: "/logistics/integrated-logistics/3pl/retail-3pl",
                active: true,
                meta: {
                  tags: "Retail 3PL",
                  keywords: "Retail 3PL",
                  description: "Retail 3PL",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b52a",
                },
                slug: "pharmaceutical-3pl",
                title: "Pharmaceutical 3PL",
                id: 2000045,
                parent: "/logistics/integrated-logistics/3pl",
                path: "/logistics/integrated-logistics/3pl/pharmaceutical-3pl",
                active: true,
                meta: {
                  tags: "Pharmaceutical 3PL",
                  keywords: "Pharmaceutical 3PL",
                  description: "Pharmaceutical 3PL",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b52b",
                },
                slug: "high-tech-3pl",
                title: "High Tech 3PL",
                id: 2000046,
                parent: "/logistics/integrated-logistics/3pl",
                path: "/logistics/integrated-logistics/3pl/high-tech-3pl",
                active: true,
                meta: {
                  tags: "High Tech 3PL",
                  keywords: "High Tech 3PL",
                  description: "High Tech 3PL",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b52c",
            },
            slug: "contract-logistics",
            title: "Contract Logistics",
            id: 2000047,
            parent: "/logistics/integrated-logistics",
            path: "/logistics/integrated-logistics/contract-logistics",
            active: true,
            meta: {
              tags: "Contract Logistics",
              keywords: "Contract Logistics",
              description: "Contract Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b52d",
            },
            slug: "warehousing-distribution-logistics",
            title: "Warehousing Distribution Logistics",
            id: 2000048,
            parent: "/logistics/integrated-logistics",
            path: "/logistics/integrated-logistics/warehousing-distribution-logistics",
            active: true,
            meta: {
              tags: "Warehousing Distribution Logistics",
              keywords: "Warehousing Distribution Logistics",
              description: "Warehousing Distribution Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b52e",
            },
            slug: "reverse-logistics",
            title: "Reverse Logistics",
            id: 2000049,
            parent: "/logistics/integrated-logistics",
            path: "/logistics/integrated-logistics/reverse-logistics",
            active: true,
            meta: {
              tags: "Reverse Logistics",
              keywords: "Reverse Logistics",
              description: "Reverse Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b52f",
            },
            slug: "4pl",
            title: "4PL",
            id: 2000050,
            parent: "/logistics/integrated-logistics",
            path: "/logistics/integrated-logistics/4pl",
            active: true,
            meta: {
              tags: "4PL",
              keywords: "4PL",
              description: "4PL",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b530",
            },
            slug: "d2c-logistics",
            title: "D2C Logistics",
            id: 2000051,
            parent: "/logistics/integrated-logistics",
            path: "/logistics/integrated-logistics/d2c-logistics",
            active: true,
            meta: {
              tags: "D2C Logistics",
              keywords: "D2C Logistics",
              description: "D2C Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b531",
            },
            slug: "intra-city-logistics",
            title: "Intra-city Logistics",
            id: 2000052,
            parent: "/logistics/integrated-logistics",
            path: "/logistics/integrated-logistics/intra-city-logistics",
            active: true,
            meta: {
              tags: "Intra-city Logistics",
              keywords: "Intra-city Logistics",
              description: "Intra-city Logistics",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b532",
        },
        slug: "cold-chain-logistics",
        title: "Cold Chain Logistics",
        id: 72606,
        parent: "/logistics",
        path: "/logistics/cold-chain-logistics",
        active: true,
        meta: {
          tags: "Cold Chain Logistics",
          keywords: "Cold Chain Logistics",
          description: "Cold Chain Logistics",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b533",
            },
            slug: "food-cold-chain",
            title: "Food Cold Chain",
            id: 2000054,
            parent: "/logistics/cold-chain-logistics",
            path: "/logistics/cold-chain-logistics/food-cold-chain",
            active: true,
            meta: {
              tags: "Food Cold Chain",
              keywords: "Food Cold Chain",
              description: "Food Cold Chain",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b534",
                },
                slug: "meat-and-seafood-cold-chain",
                title: "Meat and Seafood Cold Chain",
                id: 2000055,
                parent: "/logistics/cold-chain-logistics/food-cold-chain",
                path: "/logistics/cold-chain-logistics/food-cold-chain/meat-and-seafood-cold-chain",
                active: true,
                meta: {
                  tags: "Meat and Seafood Cold Chain",
                  keywords: "Meat and Seafood Cold Chain",
                  description: "Meat and Seafood Cold Chain",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b535",
            },
            slug: "healthcare-cold-chain",
            title: "Healthcare Cold Chain",
            id: 2000056,
            parent: "/logistics/cold-chain-logistics",
            path: "/logistics/cold-chain-logistics/healthcare-cold-chain",
            active: true,
            meta: {
              tags: "Healthcare Cold Chain",
              keywords: "Healthcare Cold Chain",
              description: "Healthcare Cold Chain",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b536",
                },
                slug: "pharma-cold-chain",
                title: "Pharma Cold Chain",
                id: 2000057,
                parent: "/logistics/cold-chain-logistics/healthcare-cold-chain",
                path: "/logistics/cold-chain-logistics/healthcare-cold-chain/pharma-cold-chain",
                active: true,
                meta: {
                  tags: "Pharma Cold Chain",
                  keywords: "Pharma Cold Chain",
                  description: "Pharma Cold Chain",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b537",
            },
            slug: "chemical-cold-chain",
            title: "Chemical Cold Chain",
            id: 2000058,
            parent: "/logistics/cold-chain-logistics",
            path: "/logistics/cold-chain-logistics/chemical-cold-chain",
            active: true,
            meta: {
              tags: "Chemical Cold Chain",
              keywords: "Chemical Cold Chain",
              description: "Chemical Cold Chain",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b538",
        },
        slug: "warehousing",
        title: "Warehousing",
        id: 72607,
        parent: "/logistics",
        path: "/logistics/warehousing",
        active: true,
        meta: {
          tags: "Warehousing",
          keywords: "Warehousing",
          description: "Warehousing",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b539",
            },
            slug: "inland-container-depot",
            title: "Inland Container Depot",
            id: 2000060,
            parent: "/logistics/warehousing",
            path: "/logistics/warehousing/inland-container-depot",
            active: true,
            meta: {
              tags: "Inland Container Depot",
              keywords: "Inland Container Depot",
              description: "Inland Container Depot",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b53a",
            },
            slug: "refrigerated-warehousing",
            title: "Refrigerated Warehousing",
            id: 2000061,
            parent: "/logistics/warehousing",
            path: "/logistics/warehousing/refrigerated-warehousing",
            active: true,
            meta: {
              tags: "Refrigerated Warehousing",
              keywords: "Refrigerated Warehousing",
              description: "Refrigerated Warehousing",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b53b",
            },
            slug: "bonded-warehousing",
            title: "Bonded Warehousing",
            id: 2000062,
            parent: "/logistics/warehousing",
            path: "/logistics/warehousing/bonded-warehousing",
            active: true,
            meta: {
              tags: "Bonded Warehousing",
              keywords: "Bonded Warehousing",
              description: "Bonded Warehousing",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b53c",
            },
            slug: "farm-product-warehousing",
            title: "Farm Product Warehousing",
            id: 2000063,
            parent: "/logistics/warehousing",
            path: "/logistics/warehousing/farm-product-warehousing",
            active: true,
            meta: {
              tags: "Farm Product Warehousing",
              keywords: "Farm Product Warehousing",
              description: "Farm Product Warehousing",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b53d",
        },
        slug: "customers-of-logistics-services",
        title: "Customers of Logistics Services",
        id: 72608,
        parent: "/logistics",
        path: "/logistics/customers-of-logistics-services",
        active: true,
        meta: {
          tags: "Customers of Logistics Services",
          keywords: "Customers of Logistics Services",
          description: "Customers of Logistics Services",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b53e",
            },
            slug: "agriculture-logistics",
            title: "Agriculture Logistics",
            id: 2000065,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/agriculture-logistics",
            active: true,
            meta: {
              tags: "Agriculture Logistics",
              keywords: "Agriculture Logistics",
              description: "Agriculture Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b53f",
            },
            slug: "mining-logistics",
            title: "Mining Logistics",
            id: 2000066,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/mining-logistics",
            active: true,
            meta: {
              tags: "Mining Logistics",
              keywords: "Mining Logistics",
              description: "Mining Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b540",
            },
            slug: "automotive-logistics",
            title: "Automotive Logistics",
            id: 2000067,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/automotive-logistics",
            active: true,
            meta: {
              tags: "Automotive Logistics",
              keywords: "Automotive Logistics",
              description: "Automotive Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b541",
            },
            slug: "healthcare-logistics",
            title: "Healthcare Logistics",
            id: 2000068,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/healthcare-logistics",
            active: true,
            meta: {
              tags: "Healthcare Logistics",
              keywords: "Healthcare Logistics",
              description: "Healthcare Logistics",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b542",
                },
                slug: "pharma-logistics",
                title: "Pharma Logistics",
                id: 2000069,
                parent:
                  "/logistics/customers-of-logistics-services/healthcare-logistics",
                path: "/logistics/customers-of-logistics-services/healthcare-logistics/pharma-logistics",
                active: true,
                meta: {
                  tags: "Pharma Logistics",
                  keywords: "Pharma Logistics",
                  description: "Pharma Logistics",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b543",
            },
            slug: "chemical-logistics",
            title: "Chemical Logistics",
            id: 2000070,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/chemical-logistics",
            active: true,
            meta: {
              tags: "Chemical Logistics",
              keywords: "Chemical Logistics",
              description: "Chemical Logistics",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "6480c9e11b708a832f67b544",
                },
                slug: "petrochemical-logistics",
                title: "Petrochemical Logistics",
                id: 2000071,
                parent:
                  "/logistics/customers-of-logistics-services/chemical-logistics",
                path: "/logistics/customers-of-logistics-services/chemical-logistics/petrochemical-logistics",
                active: true,
                meta: {
                  tags: "Petrochemical Logistics",
                  keywords: "Petrochemical Logistics",
                  description: "Petrochemical Logistics",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b545",
            },
            slug: "ecommerce-logistics",
            title: "Ecommerce Logistics",
            id: 2000072,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/ecommerce-logistics",
            active: true,
            meta: {
              tags: "Ecommerce Logistics",
              keywords: "Ecommerce Logistics",
              description: "Ecommerce Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b546",
            },
            slug: "dangerous-goods",
            title: "Dangerous Goods",
            id: 2000073,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/dangerous-goods",
            active: true,
            meta: {
              tags: "Dangerous Goods",
              keywords: "Dangerous Goods",
              description: "Dangerous Goods",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b547",
            },
            slug: "project-logistics",
            title: "Project Logistics",
            id: 2000074,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/project-logistics",
            active: true,
            meta: {
              tags: "Project Logistics",
              keywords: "Project Logistics",
              description: "Project Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b548",
            },
            slug: "apparel-logistics",
            title: "Apparel Logistics",
            id: 2000075,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/apparel-logistics",
            active: true,
            meta: {
              tags: "Apparel Logistics",
              keywords: "Apparel Logistics",
              description: "Apparel Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b549",
            },
            slug: "fmcg-logistics",
            title: "FMCG Logistics",
            id: 2000076,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/fmcg-logistics",
            active: true,
            meta: {
              tags: "FMCG Logistics",
              keywords: "FMCG Logistics",
              description: "FMCG Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b54a",
            },
            slug: "high-tech-logistics",
            title: "High tech Logistics",
            id: 2000077,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/high-tech-logistics",
            active: true,
            meta: {
              tags: "High tech Logistics",
              keywords: "High tech Logistics",
              description: "High tech Logistics",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b54b",
            },
            slug: "event-logistics",
            title: "Event Logistics",
            id: 2000078,
            parent: "/logistics/customers-of-logistics-services",
            path: "/logistics/customers-of-logistics-services/event-logistics",
            active: true,
            meta: {
              tags: "Event Logistics",
              keywords: "Event Logistics",
              description: "Event Logistics",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b54c",
        },
        slug: "postal-services",
        title: "Postal Services",
        id: 72609,
        parent: "/logistics",
        path: "/logistics/postal-services",
        active: true,
        meta: {
          tags: "Postal Services",
          keywords: "Postal Services",
          description: "Postal Services",
        },
        sub_categories: [],
      },
      {
        _id: {
          $oid: "6480c9e11b708a832f67b54d",
        },
        slug: "logistics-support-services",
        title: "Logistics Support Services",
        id: 72610,
        parent: "/logistics",
        path: "/logistics/logistics-support-services",
        active: true,
        meta: {
          tags: "Logistics Support Services",
          keywords: "Logistics Support Services",
          description: "Logistics Support Services",
        },
        sub_categories: [
          {
            _id: {
              $oid: "6480c9e11b708a832f67b54e",
            },
            slug: "stevedoring",
            title: "Stevedoring",
            id: 2000081,
            parent: "/logistics/logistics-support-services",
            path: "/logistics/logistics-support-services/stevedoring",
            active: true,
            meta: {
              tags: "Stevedoring",
              keywords: "Stevedoring",
              description: "Stevedoring",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b54f",
            },
            slug: "port-services",
            title: "Port Services",
            id: 2000082,
            parent: "/logistics/logistics-support-services",
            path: "/logistics/logistics-support-services/port-services",
            active: true,
            meta: {
              tags: "Port Services",
              keywords: "Port Services",
              description: "Port Services",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "6480c9e11b708a832f67b550",
            },
            slug: "terminal-operations",
            title: "Terminal Operations",
            id: 2000083,
            parent: "/logistics/logistics-support-services",
            path: "/logistics/logistics-support-services/terminal-operations",
            active: true,
            meta: {
              tags: "Terminal Operations",
              keywords: "Terminal Operations",
              description: "Terminal Operations",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64809557188eb1b8a1c5b139",
    },
    id: 68,
    title: "Real Estate and Construction",
    slug: "real-estate-and-construction",
    active: true,
    meta: {
      tags: "Real Estate and Construction Market Size & Share Analysis - Industry Research Reports - Growth Trends",
      keywords:
        "Real estate market, real estate research, real estate research reports, real estate industry, real estate industry analysis, market research report on real estate, commercial real estate market, commercial real estate industry overview, commercial real estate reports, residential real estate market, residential real estate market analysis, residential real estate market size, infrastructure and real estate, property management services, hospitality real estate, hospitality real estate trends, hotel real estate report",
      description:
        "Real Estate industry reports offer insights on major trends, technologies, competition, and growth opportunities in the market. The global and regional analysis includes statistics, market segments, overview, key players, market drivers, and future developments.",
    },
    description: {
      short_description:
        "Currently, the dynamic real estate industry is operating in a volatile environment. It requires expert knowledge and understanding to achieve a competitive edge in this industry. Our research group delivers detailed reports with market analysis and insights to cater to our clients' specific needs while assisting them in understanding the overall market in-depth and supporting successful strategies.",
      full_description:
        "Global & Regional reports on the real estate market that analyzes the market size, market dynamics, industry outlook, market data analysis, real estate investment analysis on commercial real estate, residential real estate, hospitality real estate, infrastructure & real estate, and property management services.",
    },
    picture: null,
    __v: 0,
    picture_url: null,
    cat_email: "neoteam@mordorintelligence.com",
    cat_pw_id: 457950,
    parent: "/",
    path: "/real-estate-and-construction",
    sub_categories: [
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1e1",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Real Estate market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Real Estate Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Real Estate market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Real Estate Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Real Estate Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Real Estate Market Research Reports, Real Estate Market, Real Estate industry, Real Estate Market Research, Real estate industry Research, Real Estate Market research report, Real Estate industry research report",
          description:
            "Mordor Intelligence™ 【  Real Estate Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Real Estate",
        slug: "real-estate",
        id: 68209,
        __v: 0,
        parent: "/real-estate-and-construction",
        path: "/real-estate-and-construction/real-estate",
        sub_categories: [
          {
            _id: {
              $oid: "64c2540d05059a7805c03a22",
            },
            slug: "commercial-real-estate",
            title: "Commercial Real Estate",
            id: 18000027,
            parent: "/real-estate-and-construction/real-estate",
            path: "/real-estate-and-construction/real-estate/commercial-real-estate",
            active: true,
            meta: {
              tags: "Commercial Real Estate",
              keywords: "Commercial Real Estate",
              description: "Commercial Real Estate",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a23",
                },
                slug: "hospitality-real-estate",
                title: "Hospitality Real Estate",
                id: 18000028,
                parent:
                  "/real-estate-and-construction/real-estate/commercial-real-estate",
                path: "/real-estate-and-construction/real-estate/commercial-real-estate/hospitality-real-estate",
                active: true,
                meta: {
                  tags: "Hospitality Real Estate",
                  keywords: "Hospitality Real Estate",
                  description: "Hospitality Real Estate",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a24",
                    },
                    slug: "senior-living",
                    title: "Senior Living",
                    id: 18000029,
                    parent:
                      "/real-estate-and-construction/real-estate/commercial-real-estate/hospitality-real-estate",
                    path: "/real-estate-and-construction/real-estate/commercial-real-estate/hospitality-real-estate/senior-living",
                    active: true,
                    meta: {
                      tags: "Senior Living",
                      keywords: "Senior Living",
                      description: "Senior Living",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a25",
                },
                slug: "office-real-estate",
                title: "Office Real Estate",
                id: 18000030,
                parent:
                  "/real-estate-and-construction/real-estate/commercial-real-estate",
                path: "/real-estate-and-construction/real-estate/commercial-real-estate/office-real-estate",
                active: true,
                meta: {
                  tags: "Office Real Estate",
                  keywords: "Office Real Estate",
                  description: "Office Real Estate",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a26",
                    },
                    slug: "coworking-spaces",
                    title: "Coworking Spaces",
                    id: 18000031,
                    parent:
                      "/real-estate-and-construction/real-estate/commercial-real-estate/office-real-estate",
                    path: "/real-estate-and-construction/real-estate/commercial-real-estate/office-real-estate/coworking-spaces",
                    active: true,
                    meta: {
                      tags: "Coworking Spaces",
                      keywords: "Coworking Spaces",
                      description: "Coworking Spaces",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a27",
                    },
                    slug: "co-working-spaces",
                    title: "Co-Working Spaces",
                    id: 18000032,
                    parent:
                      "/real-estate-and-construction/real-estate/commercial-real-estate/office-real-estate",
                    path: "/real-estate-and-construction/real-estate/commercial-real-estate/office-real-estate/co-working-spaces",
                    active: true,
                    meta: {
                      tags: "Co-Working Spaces",
                      keywords: "Co-Working Spaces",
                      description: "Co-Working Spaces",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a28",
                    },
                    slug: "flexible-offices",
                    title: "Flexible Offices",
                    id: 18000033,
                    parent:
                      "/real-estate-and-construction/real-estate/commercial-real-estate/office-real-estate",
                    path: "/real-estate-and-construction/real-estate/commercial-real-estate/office-real-estate/flexible-offices",
                    active: true,
                    meta: {
                      tags: "Flexible Offices",
                      keywords: "Flexible Offices",
                      description: "Flexible Offices",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2540d05059a7805c03a29",
            },
            slug: "real-estate-services",
            title: "Real Estate Services",
            id: 18000034,
            parent: "/real-estate-and-construction/real-estate",
            path: "/real-estate-and-construction/real-estate/real-estate-services",
            active: true,
            meta: {
              tags: "Real Estate Services",
              keywords: "Real Estate Services",
              description: "Real Estate Services",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a2a",
                },
                slug: "property-management",
                title: "Property Management",
                id: 18000035,
                parent:
                  "/real-estate-and-construction/real-estate/real-estate-services",
                path: "/real-estate-and-construction/real-estate/real-estate-services/property-management",
                active: true,
                meta: {
                  tags: "Property Management",
                  keywords: "Property Management",
                  description: "Property Management",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a2b",
                    },
                    slug: "building-inspection-services",
                    title: "Building Inspection Services",
                    id: 18000036,
                    parent:
                      "/real-estate-and-construction/real-estate/real-estate-services/property-management",
                    path: "/real-estate-and-construction/real-estate/real-estate-services/property-management/building-inspection-services",
                    active: true,
                    meta: {
                      tags: "Building Inspection Services",
                      keywords: "Building Inspection Services",
                      description: "Building Inspection Services",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a2c",
                    },
                    slug: "mep-services",
                    title: "MEP Services",
                    id: 18000037,
                    parent:
                      "/real-estate-and-construction/real-estate/real-estate-services/property-management",
                    path: "/real-estate-and-construction/real-estate/real-estate-services/property-management/mep-services",
                    active: true,
                    meta: {
                      tags: "MEP Services",
                      keywords: "MEP Services",
                      description: "MEP Services",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a2d",
                },
                slug: "rental-services",
                title: "Rental Services",
                id: 18000038,
                parent:
                  "/real-estate-and-construction/real-estate/real-estate-services",
                path: "/real-estate-and-construction/real-estate/real-estate-services/rental-services",
                active: true,
                meta: {
                  tags: "Rental Services",
                  keywords: "Rental Services",
                  description: "Rental Services",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a2e",
                    },
                    slug: "office-rental",
                    title: "Office Rental",
                    id: 18000039,
                    parent:
                      "/real-estate-and-construction/real-estate/real-estate-services/rental-services",
                    path: "/real-estate-and-construction/real-estate/real-estate-services/rental-services/office-rental",
                    active: true,
                    meta: {
                      tags: "Office Rental",
                      keywords: "Office Rental",
                      description: "Office Rental",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a2f",
                    },
                    slug: "residential-rental",
                    title: "Residential Rental",
                    id: 18000040,
                    parent:
                      "/real-estate-and-construction/real-estate/real-estate-services/rental-services",
                    path: "/real-estate-and-construction/real-estate/real-estate-services/rental-services/residential-rental",
                    active: true,
                    meta: {
                      tags: "Residential Rental",
                      keywords: "Residential Rental",
                      description: "Residential Rental",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2540d05059a7805c03a30",
            },
            slug: "residential-real-estate",
            title: "Residential Real Estate",
            id: 18000041,
            parent: "/real-estate-and-construction/real-estate",
            path: "/real-estate-and-construction/real-estate/residential-real-estate",
            active: true,
            meta: {
              tags: "Residential Real Estate",
              keywords: "Residential Real Estate",
              description: "Residential Real Estate",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a31",
                },
                slug: "condominium-and-apartments",
                title: "Condominium and Apartments",
                id: 18000042,
                parent:
                  "/real-estate-and-construction/real-estate/residential-real-estate",
                path: "/real-estate-and-construction/real-estate/residential-real-estate/condominium-and-apartments",
                active: true,
                meta: {
                  tags: "Condominium and Apartments",
                  keywords: "Condominium and Apartments",
                  description: "Condominium and Apartments",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a32",
                },
                slug: "luxury-residential-real-estate-market",
                title: "Luxury Residential Real Estate Market",
                id: 18000043,
                parent:
                  "/real-estate-and-construction/real-estate/residential-real-estate",
                path: "/real-estate-and-construction/real-estate/residential-real-estate/luxury-residential-real-estate-market",
                active: true,
                meta: {
                  tags: "Luxury Residential Real Estate Market",
                  keywords: "Luxury Residential Real Estate Market",
                  description: "Luxury Residential Real Estate Market",
                },
                sub_categories: [],
              },
            ],
          },
        ],
      },
      {
        _id: {
          $oid: "64809557188eb1b8a1c5b1e2",
        },
        picture_url: null,
        description: {
          short_description:
            "Statistics for the 2023 Construction market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Construction Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
          full_description:
            "Statistics for the 2023 Construction market share, size and revenue growth rate, created by Mordor Intelligence™ Industry Reports. Construction Market analysis includes a market forecast outlook to 2028 and historical overview.  Get a sample of this industry analysis as a free report PDF download.",
        },
        meta: {
          tags: "Real Estate and Construction Market Size & Share Analysis - Industry Research Reports - Growth Trends",
          keywords:
            "Construction Market Research Reports, real estate market research, construction market research, construction industry market research, construction industry reports, construction market research reports, global construction market",
          description:
            "Mordor Intelligence™ 【  Real Estate and Construction Market Research  】 Industry reports, including market share insights for 2020, 2021, and 2022. Plus, the comprehensive growth forecasts for 2023 to 2028 and beyond.",
        },
        active: true,
        title: "Construction",
        slug: "construction",
        id: 68210,
        __v: 0,
        parent: "/real-estate-and-construction",
        path: "/real-estate-and-construction/construction",
        sub_categories: [
          {
            _id: {
              $oid: "64c2540d05059a7805c03a0a",
            },
            slug: "commercial-construction",
            title: "Commercial Construction",
            id: 18000002,
            parent: "/real-estate-and-construction/construction",
            path: "/real-estate-and-construction/construction/commercial-construction",
            active: true,
            meta: {
              tags: "Commercial Construction",
              keywords: "Commercial Construction",
              description: "Commercial Construction",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2540d05059a7805c03a0b",
            },
            slug: "construction-and-building-material",
            title: "Construction and Building Material",
            id: 18000003,
            parent: "/real-estate-and-construction/construction",
            path: "/real-estate-and-construction/construction/construction-and-building-material",
            active: true,
            meta: {
              tags: "Construction and Building Material",
              keywords: "Construction and Building Material",
              description: "Construction and Building Material",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a0c",
                },
                slug: "construction-and-building-sheets",
                title: "Construction and Building Sheets",
                id: 18000004,
                parent:
                  "/real-estate-and-construction/construction/construction-and-building-material",
                path: "/real-estate-and-construction/construction/construction-and-building-material/construction-and-building-sheets",
                active: true,
                meta: {
                  tags: "Construction and Building Sheets",
                  keywords: "Construction and Building Sheets",
                  description: "Construction and Building Sheets",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a0d",
                    },
                    slug: "cladding",
                    title: "Cladding",
                    id: 18000005,
                    parent:
                      "/real-estate-and-construction/construction/construction-and-building-material/construction-and-building-sheets",
                    path: "/real-estate-and-construction/construction/construction-and-building-material/construction-and-building-sheets/cladding",
                    active: true,
                    meta: {
                      tags: "Cladding",
                      keywords: "Cladding",
                      description: "Cladding",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a0e",
                    },
                    slug: "facade",
                    title: "Facade",
                    id: 18000006,
                    parent:
                      "/real-estate-and-construction/construction/construction-and-building-material/construction-and-building-sheets",
                    path: "/real-estate-and-construction/construction/construction-and-building-material/construction-and-building-sheets/facade",
                    active: true,
                    meta: {
                      tags: "Facade",
                      keywords: "Facade",
                      description: "Facade",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a0f",
                    },
                    slug: "fencing",
                    title: "Fencing",
                    id: 18000007,
                    parent:
                      "/real-estate-and-construction/construction/construction-and-building-material/construction-and-building-sheets",
                    path: "/real-estate-and-construction/construction/construction-and-building-material/construction-and-building-sheets/fencing",
                    active: true,
                    meta: {
                      tags: "Fencing",
                      keywords: "Fencing",
                      description: "Fencing",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a10",
                    },
                    slug: "roofing-products",
                    title: "Roofing Products",
                    id: 18000008,
                    parent:
                      "/real-estate-and-construction/construction/construction-and-building-material/construction-and-building-sheets",
                    path: "/real-estate-and-construction/construction/construction-and-building-material/construction-and-building-sheets/roofing-products",
                    active: true,
                    meta: {
                      tags: "Roofing Products",
                      keywords: "Roofing Products",
                      description: "Roofing Products",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a11",
                },
                slug: "facade-gasket",
                title: "Facade Gasket",
                id: 18000009,
                parent:
                  "/real-estate-and-construction/construction/construction-and-building-material",
                path: "/real-estate-and-construction/construction/construction-and-building-material/facade-gasket",
                active: true,
                meta: {
                  tags: "Facade Gasket",
                  keywords: "Facade Gasket",
                  description: "Facade Gasket",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a12",
                },
                slug: "wooden-building-material",
                title: "Wooden Building Material",
                id: 18000010,
                parent:
                  "/real-estate-and-construction/construction/construction-and-building-material",
                path: "/real-estate-and-construction/construction/construction-and-building-material/wooden-building-material",
                active: true,
                meta: {
                  tags: "Wooden Building Material",
                  keywords: "Wooden Building Material",
                  description: "Wooden Building Material",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a13",
                    },
                    slug: "carpentry",
                    title: "Carpentry",
                    id: 18000011,
                    parent:
                      "/real-estate-and-construction/construction/construction-and-building-material/wooden-building-material",
                    path: "/real-estate-and-construction/construction/construction-and-building-material/wooden-building-material/carpentry",
                    active: true,
                    meta: {
                      tags: "Carpentry",
                      keywords: "Carpentry",
                      description: "Carpentry",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a14",
                    },
                    slug: "wooden-formwork",
                    title: "Wooden Formwork",
                    id: 18000012,
                    parent:
                      "/real-estate-and-construction/construction/construction-and-building-material/wooden-building-material",
                    path: "/real-estate-and-construction/construction/construction-and-building-material/wooden-building-material/wooden-formwork",
                    active: true,
                    meta: {
                      tags: "Wooden Formwork",
                      keywords: "Wooden Formwork",
                      description: "Wooden Formwork",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2540d05059a7805c03a15",
            },
            slug: "infrastructure-construction",
            title: "Infrastructure Construction",
            id: 18000013,
            parent: "/real-estate-and-construction/construction",
            path: "/real-estate-and-construction/construction/infrastructure-construction",
            active: true,
            meta: {
              tags: "Infrastructure Construction",
              keywords: "Infrastructure Construction",
              description: "Infrastructure Construction",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a16",
                },
                slug: "total-infrastructure-construction",
                title: "Total Infrastructure Construction",
                id: 18000014,
                parent:
                  "/real-estate-and-construction/construction/infrastructure-construction",
                path: "/real-estate-and-construction/construction/infrastructure-construction/total-infrastructure-construction",
                active: true,
                meta: {
                  tags: "Total Infrastructure Construction",
                  keywords: "Total Infrastructure Construction",
                  description: "Total Infrastructure Construction",
                },
                sub_categories: [],
              },
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a17",
                },
                slug: "transport-infrastructure",
                title: "Transport Infrastructure",
                id: 18000015,
                parent:
                  "/real-estate-and-construction/construction/infrastructure-construction",
                path: "/real-estate-and-construction/construction/infrastructure-construction/transport-infrastructure",
                active: true,
                meta: {
                  tags: "Transport Infrastructure",
                  keywords: "Transport Infrastructure",
                  description: "Transport Infrastructure",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a18",
                    },
                    slug: "airports",
                    title: "Airports",
                    id: 18000016,
                    parent:
                      "/real-estate-and-construction/construction/infrastructure-construction/transport-infrastructure",
                    path: "/real-estate-and-construction/construction/infrastructure-construction/transport-infrastructure/airports",
                    active: true,
                    meta: {
                      tags: "Airports",
                      keywords: "Airports",
                      description: "Airports",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a19",
                    },
                    slug: "ports-and-waterways",
                    title: "Ports & Waterways",
                    id: 18000017,
                    parent:
                      "/real-estate-and-construction/construction/infrastructure-construction/transport-infrastructure",
                    path: "/real-estate-and-construction/construction/infrastructure-construction/transport-infrastructure/ports-and-waterways",
                    active: true,
                    meta: {
                      tags: "Ports & Waterways",
                      keywords: "Ports & Waterways",
                      description: "Ports & Waterways",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a1a",
                    },
                    slug: "railways-infrastructure",
                    title: "Railways Infrastructure",
                    id: 18000018,
                    parent:
                      "/real-estate-and-construction/construction/infrastructure-construction/transport-infrastructure",
                    path: "/real-estate-and-construction/construction/infrastructure-construction/transport-infrastructure/railways-infrastructure",
                    active: true,
                    meta: {
                      tags: "Railways Infrastructure",
                      keywords: "Railways Infrastructure",
                      description: "Railways Infrastructure",
                    },
                    sub_categories: [],
                  },
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a1b",
                    },
                    slug: "roads",
                    title: "Roads",
                    id: 18000019,
                    parent:
                      "/real-estate-and-construction/construction/infrastructure-construction/transport-infrastructure",
                    path: "/real-estate-and-construction/construction/infrastructure-construction/transport-infrastructure/roads",
                    active: true,
                    meta: {
                      tags: "Roads",
                      keywords: "Roads",
                      description: "Roads",
                    },
                    sub_categories: [],
                  },
                ],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2540d05059a7805c03a1c",
            },
            slug: "prefabricated-construction",
            title: "Prefabricated Construction",
            id: 18000020,
            parent: "/real-estate-and-construction/construction",
            path: "/real-estate-and-construction/construction/prefabricated-construction",
            active: true,
            meta: {
              tags: "Prefabricated Construction",
              keywords: "Prefabricated Construction",
              description: "Prefabricated Construction",
            },
            sub_categories: [
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a1d",
                },
                slug: "prefabricated-building",
                title: "Prefabricated Building",
                id: 18000021,
                parent:
                  "/real-estate-and-construction/construction/prefabricated-construction",
                path: "/real-estate-and-construction/construction/prefabricated-construction/prefabricated-building",
                active: true,
                meta: {
                  tags: "Prefabricated Building",
                  keywords: "Prefabricated Building",
                  description: "Prefabricated Building",
                },
                sub_categories: [
                  {
                    _id: {
                      $oid: "64c2540d05059a7805c03a1e",
                    },
                    slug: "manufactured-homes",
                    title: "Manufactured Homes",
                    id: 18000022,
                    parent:
                      "/real-estate-and-construction/construction/prefabricated-construction/prefabricated-building",
                    path: "/real-estate-and-construction/construction/prefabricated-construction/prefabricated-building/manufactured-homes",
                    active: true,
                    meta: {
                      tags: "Manufactured Homes",
                      keywords: "Manufactured Homes",
                      description: "Manufactured Homes",
                    },
                    sub_categories: [],
                  },
                ],
              },
              {
                _id: {
                  $oid: "64c2540d05059a7805c03a1f",
                },
                slug: "prefabricated-wood-building",
                title: "Prefabricated Wood Building",
                id: 18000023,
                parent:
                  "/real-estate-and-construction/construction/prefabricated-construction",
                path: "/real-estate-and-construction/construction/prefabricated-construction/prefabricated-wood-building",
                active: true,
                meta: {
                  tags: "Prefabricated Wood Building",
                  keywords: "Prefabricated Wood Building",
                  description: "Prefabricated Wood Building",
                },
                sub_categories: [],
              },
            ],
          },
          {
            _id: {
              $oid: "64c2540d05059a7805c03a20",
            },
            slug: "residential-construction",
            title: "Residential Construction",
            id: 18000024,
            parent: "/real-estate-and-construction/construction",
            path: "/real-estate-and-construction/construction/residential-construction",
            active: true,
            meta: {
              tags: "Residential Construction",
              keywords: "Residential Construction",
              description: "Residential Construction",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c2540d05059a7805c03a21",
            },
            slug: "total-construction",
            title: "Total Construction",
            id: 18000025,
            parent: "/real-estate-and-construction/construction",
            path: "/real-estate-and-construction/construction/total-construction",
            active: true,
            meta: {
              tags: "Total Construction",
              keywords: "Total Construction",
              description: "Total Construction",
            },
            sub_categories: [],
          },
        ],
      },
    ],
  },
  {
    _id: {
      $oid: "64c246c8fc9d360f328ff943",
    },
    picture_url: null,
    description: {
      short_description: "Retail",
      full_description: "Retail",
    },
    meta: {
      tags: "Retail",
      keywords: "Retail",
      description: "Retail",
    },
    active: true,
    title: "Retail",
    slug: "retail",
    id: 82,
    parent: "/",
    path: "/retail",
    sub_categories: [
      {
        _id: {
          $oid: "64c246c8fc9d360f328ff945",
        },
        picture_url: null,
        description: {
          short_description: "General Retail",
          full_description: "General Retail",
        },
        meta: {
          tags: "General Retail",
          keywords: "General Retail",
          description: "General Retail",
        },
        active: true,
        title: "General Retail",
        slug: "general-retail",
        id: 82667,
        __v: 0,
        parent: "/retail",
        path: "/retail/general-retail",
        sub_categories: [
          {
            _id: {
              $oid: "64c25267d755cc8ea1b1b49c",
            },
            slug: "retail-procurement",
            title: "Retail Procurement",
            id: 12000002,
            parent: "/retail/general-retail",
            path: "/retail/general-retail/retail-procurement",
            active: true,
            meta: {
              tags: "Retail Procurement",
              keywords: "Retail Procurement",
              description: "Retail Procurement",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64c246c8fc9d360f328ff946",
        },
        picture_url: null,
        description: {
          short_description: "Retail Stores",
          full_description: "Retail Stores",
        },
        meta: {
          tags: "Retail Stores",
          keywords: "Retail Stores",
          description: "Retail Stores",
        },
        active: true,
        title: "Retail Stores",
        slug: "retail-stores",
        id: 82668,
        __v: 0,
        parent: "/retail",
        path: "/retail/retail-stores",
        sub_categories: [
          {
            _id: {
              $oid: "64c25267d755cc8ea1b1b49d",
            },
            slug: "convenience-stores",
            title: "Convenience Stores",
            id: 12000004,
            parent: "/retail/retail-stores",
            path: "/retail/retail-stores/convenience-stores",
            active: true,
            meta: {
              tags: "Convenience Stores",
              keywords: "Convenience Stores",
              description: "Convenience Stores",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c25267d755cc8ea1b1b49e",
            },
            slug: "department-stores",
            title: "Department Stores",
            id: 12000005,
            parent: "/retail/retail-stores",
            path: "/retail/retail-stores/department-stores",
            active: true,
            meta: {
              tags: "Department Stores",
              keywords: "Department Stores",
              description: "Department Stores",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c25267d755cc8ea1b1b49f",
            },
            slug: "online-retail",
            title: "Online Retail",
            id: 12000006,
            parent: "/retail/retail-stores",
            path: "/retail/retail-stores/online-retail",
            active: true,
            meta: {
              tags: "Online Retail",
              keywords: "Online Retail",
              description: "Online Retail",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c25267d755cc8ea1b1b4a0",
            },
            slug: "q-commerce",
            title: "Q-Commerce",
            id: 12000007,
            parent: "/retail/retail-stores",
            path: "/retail/retail-stores/q-commerce",
            active: true,
            meta: {
              tags: "Q-Commerce",
              keywords: "Q-Commerce",
              description: "Q-Commerce",
            },
            sub_categories: [],
          },
          {
            _id: {
              $oid: "64c25267d755cc8ea1b1b4a1",
            },
            slug: "specialty-retail-stores",
            title: "Specialty Retail Stores",
            id: 12000008,
            parent: "/retail/retail-stores",
            path: "/retail/retail-stores/specialty-retail-stores",
            active: true,
            meta: {
              tags: "Specialty Retail Stores",
              keywords: "Specialty Retail Stores",
              description: "Specialty Retail Stores",
            },
            sub_categories: [],
          },
        ],
      },
      {
        _id: {
          $oid: "64c246c8fc9d360f328ff947",
        },
        picture_url: null,
        description: {
          short_description: "Travel Retail",
          full_description: "Travel Retail",
        },
        meta: {
          tags: "Travel Retail",
          keywords: "Travel Retail",
          description: "Travel Retail",
        },
        active: true,
        title: "Travel Retail",
        slug: "travel-retail",
        id: 82669,
        __v: 0,
        parent: "/retail",
        path: "/retail/travel-retail",
        sub_categories: [],
      },
    ],
  },
];
