import React from 'react';
import PreloaderWrapper from './Preloader.style';

const Preloader = () => {
  return(
    <PreloaderWrapper>
      <div>
        <i className='layer'></i>
        <i className='layer'></i>
        <i className='layer'></i>
      </div>
    </PreloaderWrapper>
  )
}

export default Preloader;