import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import dayjs from "dayjs";

function RenderCards(formatData) {
  const mainState = useSelector((state) => state);

  const previouslySelectedReport = useSelector(
    (state) => state?.[formatData?.reduxToolkitKey].selectedReport
  );
  const subscribedReport = useSelector(
    (state) => state?.[formatData?.reduxToolkitKey].subscribedReport
  );

  const result = _.mergeWith(
    {},
    previouslySelectedReport,
    subscribedReport,
    (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return _.unionBy(srcValue, objValue, "id");
      }
    }
  );

  const renderSelectedReport = (key, selectedRowData, formatData) => {
    return (
      <Box key={_.uniqueId()}>
        {/* <Typography
          sx={{ fontSize: "1.5rem", textTransform: "capitalize" }}
          variant="h6"
          component="div"
        >
          {key}
        </Typography> */}
        {/* <Divider sx={{ margin: "0.5rem" }} /> */}
        <Grid container spacing={2}>
          {selectedRowData?.map((row, index) => (
            <Grid item xs={12} sm={6}>
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  marginBottom: "1rem",
                  transition: "transform .2s", // Add transition for smooth hover effect
                  "&:hover": {
                    transform: "scale(1.02)", // Increase size of card on hover
                    cursor: "pointer", // Change cursor to pointer on hover
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    sx={{ fontSize: "1rem", textTransform: "capitalize" }}
                    variant="h6"
                    component="div"
                  >
                    {row.title ?? ""}
                  </Typography>
                  <Divider sx={{ margin: "0.5rem" }} />
                  <Typography
                    sx={{ display: "flex" }}
                    variant="body2"
                    component="div"
                  >
                    <div style={{ fontWeight: "bolder" }}>Report Id: </div>
                    {row.id}
                  </Typography>

                  <Typography
                    sx={{ display: "flex", marginBottom: "0.7rem" }}
                    variant="body2"
                    component="div"
                  >
                    {/* <div style={{ fontWeight: "bolder" }}>Module Access: </div> */}

                    {/* <Table size="small">
                      <TableBody>
                        {row.permission.map((item, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <strong>{item.name}</strong>
                              </TableCell>
                              <TableCell>{item.child}</TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table> */}
                  </Typography>
                  {/* {console.log(
                    "ROWW:",
                    dayjs(row.expiry_date).format("YYYY/MM/DD")
                  )} */}
                  <Typography
                    sx={{ display: "flex" }}
                    variant="body2"
                    component="div"
                  >
                    <div style={{ fontWeight: "bolder" }}>
                      Subscription Date:{" "}
                    </div>
                    {row?.subscription_date
                      ? dayjs(row?.subscription_date).format("YYYY/MM/DD")
                      : " - "}
                  </Typography>
                  <Typography
                    sx={{ display: "flex" }}
                    variant="body2"
                    component="div"
                  >
                    <div style={{ fontWeight: "bolder" }}>Expiry Date: </div>
                    {row?.expiry_date
                      ? dayjs(row.expiry_date).format("YYYY/MM/DD")
                      : " - "}
                  </Typography>
                  <Typography
                    sx={{ display: "flex" }}
                    variant="body2"
                    component="div"
                  >
                    <div style={{ fontWeight: "bolder" }}>Published Date: </div>
                    {dayjs(row.published_year).format("YYYY/MM/DD")}
                  </Typography>
                  <Typography
                    sx={{ display: "flex" }}
                    variant="body2"
                    component="div"
                  >
                    <div style={{ fontWeight: "bolder" }}>Access Type: </div>
                    {formatData?.formatData?.accessType}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {/* {Object?.entries(formatData?.formatData)?.map(
        ([key, selectedRowData]) => {
          return renderSelectedReport(key, selectedRowData);
        }
      )} */}
      {Object?.entries(result)?.map(([key, selectedRowData]) => {
        if (selectedRowData?.length > 0) {
          return renderSelectedReport(key, selectedRowData, formatData);
        }
        return null;
      })}
    </>
  );
}

export default RenderCards;
