import styled from 'styled-components';

const MenuWrapper = styled.div`
    .menu-container {
        margin: 5px 10px;
        box-shadow: 0 0 5px 1px #ccc;
        height: calc(100vh - 107px);
        padding-top: 15px;
        overflow: auto;
        background-color:white;
        border-radius: 0px 0px 0px 10px;
    }
`;
export default MenuWrapper;

