import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flash: [],
  categoryData: "",
};

const flashReportAssignmentSlice = createSlice({
  name: "flashReportAssignment",
  initialState,
  reducers: {
    setFlashReports(state, action) {
      const newReports = action.payload;
      const mergedReports = [...state.flash, ...newReports].reduce((acc, report) => {
        const existingReportIndex = acc.findIndex(r => r.id === report.id);
        if (existingReportIndex !== -1) {
          acc[existingReportIndex] = report;
        } else {
          acc.push(report);
        }
        return acc;
      }, []);
      state.flash = mergedReports;
    },
    clearFlashReportState(state) {
      state.flash = [];
    },
    addCategoryData(state, action) {
      state.categoryData = action.payload;
    },
    updateFlashReportDate(state, action) {
      const { reportId, dateType, date } = action.payload;
      state.flash = state.flash.map(report =>
        report.id === reportId
          ? { ...report, [dateType]: date }
          : report
      );
    },
    removeFlashReport(state, action) {
      const reportId = action.payload;
      state.flash = state.flash.filter(report => report.id !== reportId);
    },
  },
});

export const { setFlashReports, clearFlashReportState, addCategoryData, updateFlashReportDate, removeFlashReport } =
  flashReportAssignmentSlice.actions;
export default flashReportAssignmentSlice.reducer;
