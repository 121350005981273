import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import Header from "../../components/Header";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import API from "../../utils/api";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import UploadSheets from "../../components/UploadSheets/index";
import AssignPermission from "../../components/AssignPermission/index";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Layout";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const CreateUser = () => {
  const isNavBarShrink = useSelector((store) => store.interface.isNavBarShrink);
  const cookies = new Cookies();

  let token = cookies.get("TOKEN");
  const { Base_URL, getHubList } = API;

  let config = {
    method: "get",
    headers: { authorization: `Bearer ${token}` },
    maxBodyLength: Infinity,
    url: `${Base_URL}${getHubList}`,
  };

  const [hubList, setHubList] = useState([]);

  useEffect(() => {
    axios(config).then((res) => {
      setHubList(res?.data?.data);
    });
  }, []);

  const [searchData, setSearchData] = useState({
    hubSelect: "",
    accessType: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };
  const [value, setValue] = React.useState(0);
  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Tabs
        value={value}
        onChange={tabHandleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Create a User" {...a11yProps(0)} />
        <Tab label="Upload Sheet" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <AssignPermission />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UploadSheets />
      </CustomTabPanel>
    </div>
  );
};

export default CreateUser;
