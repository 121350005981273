import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function RenderTableRows({
  labelId,
  row,
  rowIndex,
  isItemSelected,
  handleClick,
  handleChange,
  handleCheckBoxChange,
  handleRadioChange,
  handleDateChange,
  rowAccess,
  permissionTypeList,
  isViewAccountType = false,
  selectedHub,
  reportAssignmentFalse,
  enableAll = false,
}) {
  useEffect(() => {
    if (!isItemSelected) {
      setOpen(false);
    }
  }, [isItemSelected]);
  const [open, setOpen] = React.useState(false);
  const handleExpandIcon = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow selected={isItemSelected} key={row.id}>
        <TableCell>
          <IconButton
            // disabled={!isItemSelected}
            disabled={true}
            onClick={handleExpandIcon}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onClick={(event) => handleClick(event, row.id)}
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            disabled={!selectedHub && !enableAll}
          />
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.title}</TableCell>
        {reportAssignmentFalse ? (
          ""
        ) : (
          <>
            <TableCell>
              <DatePicker
                disabled={!isItemSelected}
                slotProps={{
                  textField: {
                    variant: "standard",
                  },
                }}
                label="Subscription Date"
                format="YYYY-MM-DD"
                value={row.subscription_date}
                onChange={(newDate) =>
                  handleDateChange(
                    newDate,
                    rowIndex,
                    "subscription_date",
                    row.id
                  )
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                // defaultValue={dayjs()}
              />
            </TableCell>
            <TableCell>
              <DatePicker
                disabled={!isItemSelected}
                slotProps={{
                  textField: {
                    variant: "standard",
                  },
                }}
                label="Expiry Date"
                format="YYYY-MM-DD"
                value={row.expiry_date}
                onChange={(newDate) =>
                  handleDateChange(newDate, rowIndex, "expiry_date", row.id)
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                // defaultValue={dayjs()}
              />
            </TableCell>
            <TableCell>{row.forecast_year}</TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography variant="h6" gutterBottom component="div">
              Module Type:
            </Typography>
            <Box sx={{ display: "flex", marginLeft: "4rem" }}>
              {permissionTypeList?.map((hub, index) => {
                const isSelected = row?.permission?.filter(
                  (item) => item.name === hub.name
                );
                return (
                  <div>
                    <FormControlLabel
                      label={hub.name}
                      checked={isSelected?.length > 0}
                      control={
                        <Checkbox
                          onChange={(e) =>
                            handleCheckBoxChange(
                              e.target.checked,
                              rowIndex,
                              hub.name
                            )
                          }
                          name={hub.name}
                        />
                      }
                    />
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue="Download Access"
                      name="radio-buttons-group"
                      sx={{ marginLeft: "1rem" }}
                      onChange={(e) => handleRadioChange(e, rowIndex, hub.name)}
                      value={
                        isSelected?.length > 0 ? isSelected?.[0]?.child : ""
                      }
                    >
                      {hub.child?.map((child) => (
                        <FormControlLabel
                          value={child}
                          control={
                            <Radio disabled={isSelected?.length === 0} />
                          }
                          label={child}
                          disabled={
                            isViewAccountType && child !== "View Access"
                          }
                        />
                      ))}
                    </RadioGroup>
                  </div>
                );
              })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default RenderTableRows;
