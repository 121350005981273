import React from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";

import NavBar from "../../components/NavBar";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import { ExportOutlined } from '@ant-design/icons';
import { Button } from "antd";
import axios from "axios";
import API from "../../utils/api";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
let _id = 0;

function Dashboard() {
  const isNavBarShrink = useSelector((store) => store.interface.isNavBarShrink);
  const cookies = new Cookies();

  let token = cookies.get("TOKEN");


  const { Base_URL } = API;
  const [isLoading, setIsLoading] = React.useState(false);

  async function exportReport(slug,id) {
    _id = id
    try {
      setIsLoading(true);
      axios
        .get(`${Base_URL}/${slug}`, {
          headers: { authorization: `Bearer ${token}` },

        })
        .then((response) => {
          if (response?.data?.message) {
            toast.success(response.data.message);
          } else {
            toast.error("Error exporting reports");
          }
        })
        .finally(() => {
          setIsLoading(false);
          id = 0
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
        <Button loading={isLoading && _id === 1} icon={<ExportOutlined />} onClick={() => exportReport("exportSynapseReports", 1)} type="primary">
          Export Synapse Reports
        </Button>
        <Button loading={isLoading && _id === 2} icon={<ExportOutlined />} onClick={() => exportReport("exportUsers",2)} type="primary">
          Export Users
        </Button>
      </div>
      {/* <h1 >Dashboard</h1> */}
    </div>
  );
}

export default Dashboard;
