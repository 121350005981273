import React, { useEffect } from "react";
import UserList from "../../components/UserList/UserList";
import UserReportsList from "../../components/UserReportsList/UserReportsList";
import AttachFiles from "../../components/Box/AttachFiles/AttachFiles";
import { useGetUserApiQuery } from "../../redux/api/createUserApi";
import {
  assignReportApi,
  useAttachCustomReportMutation,
  useLazyGetAttachReportQuery,
  useLazyGetUserPurchaseReportQuery,
} from "../../redux/api/assignReportApi";
import Preloader from "../../components/Preloader/Preloader";
import Fuse from "fuse.js";
import _ from "lodash";

const AttachReport = () => {
  const [userId, setUserId] = React.useState("");
  const [name, setName] = React.useState("");
  const [modalVisible, setModalVisible] = React.useState(false);
  const [reportToAttach, setReportToAttach] = React.useState([]);
  const [selectedReportDoc, setSelectedReportDoc] = React.useState({});
  const [filteredData, setFilteredData] = React.useState([]);
  const [
    getUserPurchaseReport,
    {
      data: userPurchaseReportData,
      error: userPurchaseReportError,
      isLoading: userPurchaseReportLoading,
    },
  ] = useLazyGetUserPurchaseReportQuery();

  const [
    assignCustomReport,
    {
      data: assignCustomReportData,
      error: assignCustomReportError,
      isLoading: assignCustomReportLoading,
    },
  ] = useAttachCustomReportMutation();

  const [
    getAttachReport,
    {
      data: attachReportData,
      error: attachReportError,
      isLoading: attachReportLoading,
    },
  ] = useLazyGetAttachReportQuery();
  const [reportId, setReportId] = React.useState("");
  useEffect(() => {
    if (attachReportData?.data) {
      //Select attachReportData which is available in the user reports
      //Match the reportId with the user reports and set the reportToAttach
      let reportList = attachReportData?.data;
      let userReports = attachReportData?.userData;
      let reports = [];
      reportList.forEach((report) => {
        userReports.forEach((userReport) => {
          const mainReportIdString = report.report_id.toString();
          if (
            mainReportIdString === userReport.report_id &&
            report.type === userReport.type &&
            report.time === userReport.time
          ) {
            reports.push(
              `${report.type}:${report.report_id}:${report.document_id}`
            );
          }
        });
      });
      setReportToAttach(reports);
    } else {
      setReportToAttach([]);
    }
  }, [attachReportData]);

  let fuse;
  useEffect(() => {
    if (userPurchaseReportData) {
      fuse = new Fuse(userPurchaseReportData.data, {
        threshold: 0.2,
        keys: ["report_id", "title"], // replace with your own keys
        includeScore: true,
      });
    }
    setFilteredData(userPurchaseReportData?.data);
  }, [userPurchaseReportData]);
  const [hubId, setHubId] = React.useState("");
  const handleCurrentView = (id, name) => {
    setUserId(id);
    setName(name);
  };

  const handleModalVisible = (reportId, hubId) => {
    assignReportApi.endpoints.getAttachReport.initiate({ reportId, userId });
    setModalVisible(true);
    setReportId(reportId);
    setHubId(hubId);
  };

  const handleBack = () => {
    setUserId("");
  };

  const onSearch = (query) => {
    if (!query) {
      setFilteredData(userPurchaseReportData.data);
      return;
    }

    if (!fuse) {
      fuse = new Fuse(userPurchaseReportData.data, {
        keys: ["report_id", "title"], // replace with your own keys
        includeScore: true,
      });
    }

    const results = fuse.search(query);
    const matchedItems = results?.map((item) => item.item);
    setFilteredData(matchedItems);
  };

  const { data: userData, error, isLoading, isFetching } = useGetUserApiQuery();

  useEffect(() => {
    if (userId) {
      getUserPurchaseReport({ userId });
    }
  }, [userId]);

  useEffect(() => {
    if (reportId) {
      getAttachReport({ reportId, userId }, { force: true });
    }
  }, [reportId, userId]);

  const closeAttachModal = () => {
    setModalVisible(false);
    setReportId("");
  };

  const handleReportsToAttach = (value) => {
    setReportToAttach(value);
  };

  if (userPurchaseReportLoading || isLoading || isFetching)
    return <Preloader />;

  return userId ? (
    <>
      {modalVisible ? (
        <AttachFiles
          reportListData={attachReportData?.data}
          closeAttachModal={closeAttachModal}
          visible={true}
          user_reports={attachReportData?.userData}
          reportToAttach={reportToAttach}
          handleReportsToAttach={handleReportsToAttach}
          spinning={attachReportLoading}
          key={reportId}
          userId={userId}
          reportId={reportId}
          hubId={hubId}
          syndicateData={attachReportData?.isSyndicate ?? false}
          showSyndicate={hubId ? true : false}
          isEmailToClient={false}
        />
      ) : null}
      <UserReportsList
        data={filteredData}
        handleBack={handleBack}
        toggleAttachPopup={handleModalVisible}
        onSearch={onSearch}
        user={{ name: name }}
      />
    </>
  ) : (
    <UserList
      key={_.uniqueId()}
      data={userData?.data}
      getUserReports={handleCurrentView}
      from="attach report"
    />
  );
};

export default AttachReport;
