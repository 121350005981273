import _ from "lodash";

// Function to find the matching object

// Function to find matching objects
export const findMatches = (searchData, presentSource) => {
  if (searchData.length === 0) return presentSource;
  // Custom function to count how many category_ids match searchData
  const countMatches = (categoryIds, searchData) =>
    _.intersection(categoryIds, searchData).length;

  // Filter only the items that have at least one match
  const filteredData = presentSource.filter(
    (item) => countMatches(item.category_ids, searchData) > 0
  );

  // Sort the filtered data based on the number of matches in category_ids
  return _.orderBy(
    filteredData,
    (item) => countMatches(item.category_ids, searchData),
    "desc"
  );
};

// import _ from "lodash";

// // Function to find the matching object

// // Function to find matching objects
// export const findMatches = (searchData, presentSource) => {
//   // if (searchData.length === 0) return presentSource;
//   // // Custom function to count how many category_ids match searchData
//   // const countMatches = (categoryIds, searchData) =>
//   //   _.intersection(categoryIds, searchData).length;

//   // // Filter only the items that have at least one match
//   // const filteredData = presentSource.filter(
//   //   (item) => countMatches(item.category_ids, searchData) > 0
//   // );

//   // // Sort the filtered data based on the number of matches in category_ids
//   // return _.orderBy(
//   //   filteredData,
//   //   (item) => countMatches(item.category_ids, searchData),
//   //   "desc"
//   // );
//   // Custom function to check if all searchData elements are present in category_ids
//   const isMatch = (categoryIds, searchData) =>
//     _.difference(searchData, categoryIds).length === 0;

//   // Filter only the items where all searchData elements exist in category_ids
//   const filteredData = presentSource.filter((item) =>
//     isMatch(item.category_ids, searchData)
//   );

//   return filteredData;
// };
