import styled from 'styled-components';

const AttachReportFormWrapper = styled.div`
  .template-options {
    align-items: start;
  }
  .add-from-scratch {
    margin: 20px 0 0 0;
    .scratch-report-tags {
      flex-direction: column;
      margin: 20px 0;
      .ant-tag {
        align-self: flex-start;
        white-space:normal !important;
        height:auto !important;
        margin: 5px 0;
        .scratch-report-tag {
          margin: 0;
          display: inline-block;
        }
      }
    }
  }
  .section-header {
    align-items: center;
    h2 {
      margin:0 0 0 20px;
    }
    .back-arrow-btn {
      font-size: 20px;
      cursor: pointer;
      transition: transform 0.3s ease-out;
      &:hover {
        transform:rotate(360deg);
      }
    }
  }
`;

export default AttachReportFormWrapper;