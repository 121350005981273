import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
};

const selectedUserSlice = createSlice({
  name: 'selectedUser',
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    clearState(state) {
      state.email = '';
    },
  },
});

export const { setEmail, clearState } = selectedUserSlice.actions;
export default selectedUserSlice.reducer;
