import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Menu } from "antd";
import Icon from "@ant-design/icons";
import MenuWrapper from "./Menu.style";
import { privateRoutesMeta } from "../../../routes";
import { useSelector } from "react-redux";

const SubMenu = Menu.SubMenu;

const MenuComponent = ({
  openKeys,
  selectedKeys,
  onSubMenuSelect,
  onMenuItemClick,
}) => {
  const location = useLocation();
  const { permission } = useSelector((state) => state.userPermission);
  let menu_items;
  let firstItemKey = "";

  // We generate menu items and determine the first item's key
  menu_items = privateRoutesMeta.map((route, index) => {
    // Initialize firstItemKey with the first available key
    // if (!firstItemKey && route.key) {
    //   firstItemKey = route.key;
    // }

    if (route.child) {
      return (
        <SubMenu
          onTitleClick={onSubMenuSelect}
          key={route.key}
          title={route.title}
        >
          {route.child.map((child, index) => {
            // if (!firstItemKey && child.key) {
            //   firstItemKey = child.key;
            // }
            if (child.key === "update-user") {
              if (location.pathname === "/home/user-management/update-user") {
                return (
                  <Menu.Item key={child.key}>
                    <NavLink to={child.path}>{child.title}</NavLink>
                  </Menu.Item>
                );
              }
              return;
            }
            return (
              <Menu.Item key={child.key}>
                <NavLink to={child.path}>{child.title}</NavLink>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    } else {
      if (route.key === "update-user") {
        if (location.pathname === "/home/user-management/update-user") {
          return (
            <Menu.Item key={route.key}>
              <NavLink to={route.path}>{route.title}</NavLink>
            </Menu.Item>
          );
        } else {
          return;
        }
      }
      return (
        <Menu.Item key={route.key}>
          <NavLink to={route.path}>{route.title}</NavLink>
        </Menu.Item>
      );
    }
  });

  return (
    <MenuWrapper>
      <div className="menu-container">
        <Menu
          mode="inline"
          inlineCollapsed={false}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onSelect={onMenuItemClick}
        >
          {menu_items}
        </Menu>
      </div>
    </MenuWrapper>
  );
};

export default MenuComponent;
