import styles from "./styles.module.css";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import URLS from "../../utils/urls";

const { driftImage, synapseLogo, heroBanner1, heroBanner2 } = URLS;

function Banner() {
  const Year = new Date().getFullYear();
  const cookies = new Cookies();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (cookies.get("TOKEN")) {
  //     navigate("/home/dashboard", { replace: true });
  //   }
  //   return () => {};
  // }, []);

  return (
    <div className={styles.wrapper}>
      <img src={driftImage} alt="driftImage" className={styles.driftSection} />
      <div className={styles.mainContainer}>
        <div className={styles.topBar}>
          <figure>
            <img src={synapseLogo} alt="main logo" />
            <figcaption>
              <h3>CMS-synapse</h3>
              <small>By Mordor Intelligence</small>
            </figcaption>
          </figure>
        </div>
        <div className={styles.mainDetailsContainer}>
          <section className={styles.leftSection}>
            <div className={styles.textWrapper}>
              <h4 className={styles.nametag}>
                Delivering Competitive Edge,{" "}
                <span>Turning Precise Data into Decisions</span>
              </h4>
              <h4 className={styles.nametag}>
                Mapping Complex Business Ecosystems,{" "}
                <span>Predicting Butterfly Effects</span>
              </h4>
            </div>
            <div className={styles.imgWrapper}>
              <img
                src={heroBanner1}
                alt="hero banner 1"
                className={styles.bgFade}
              />
              <img
                src={heroBanner2}
                alt="hero banner 2"
                className={styles.bgFade2}
              />
            </div>
          </section>
          <section className={styles.rightSection}>
            <Outlet />
          </section>
        </div>
      </div>
      <div className={styles.credit}>
        © {Year}. All Rights Reserved to Mordor Intelligence.
      </div>
    </div>
  );
}

export default Banner;
