import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permission: {
    Dashboard: true,
    "User Management": false,
    "Content Management": false,
    "Custom Reports Management": false,
    Settings: true,
  },
};

const userPermissionSlice = createSlice({
  name: "userPermission",
  initialState: initialState,
  reducers: {
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
  },
});

export const { setPermission } = userPermissionSlice.actions;
export default userPermissionSlice.reducer;
