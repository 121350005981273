import {createSlice} from "@reduxjs/toolkit";

const initialState= {
  isNavBarShrink :false
}

const interfaceSlice = createSlice({
   name:'interface',
   initialState:initialState,
   reducers:{
    setIsNavBarShrink: (state, action)=>{
         state.isNavBarShrink = action.payload
     }
   }
})

export default interfaceSlice.reducer;
export const {setIsNavBarShrink} = interfaceSlice.actions;