import React from "react";
import styles from "./styles.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setIsNavBarShrink } from "../../redux/interfaceSlice";

import Dashboard from "../../assets/Dashboard.svg";
import Sheet from "../../assets/Sheet.svg";
import Settings from "../../assets/Settings.svg";
import Logout from "../../assets/Logout.svg";
import LeftArrow from "../../assets/LeftArrow.svg";
import RightArrow from "../../assets/RightArrow.svg";
import PersonIcon from "@mui/icons-material/Person";

import URLS from "../../utils/urls";
const { synapseLogo } = URLS;

function NavBar() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isNavBarShrink = useSelector((store) => store.interface.isNavBarShrink);

  function handleLogout() {
    cookies.remove("TOKEN");
    navigate("/");
  }
  const subCategories = [
    { label: "Subcategory 1", path: "/upload-sheet" },

    // Add more subcategories as needed
  ];
  if (isNavBarShrink) {
    return (
      <aside className={styles.navBarContainerSmall}>
        <div className={styles.logoContainerSmall}>
          <Tooltip title="CMS-Synapse" placement="right">
            <img src={synapseLogo} alt="CMS-Synapse Logo" />
          </Tooltip>
        </div>
        <nav>
          <ul style={{ height: "200px" }}>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/dashboard"
              >
                <Tooltip title="Dashboard" placement="right">
                  <img src={Dashboard} alt="Dashboard" />
                </Tooltip>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/create-user"
              >
                <Tooltip title="Create User" placement="right">
                  <img src={Sheet} alt="Upload User Sheet" />
                </Tooltip>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/user-list"
              >
                <Tooltip title="User List" placement="right">
                  <PersonIcon />
                </Tooltip>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/uploads"
              >
                <Tooltip title="Upload" placement="right">
                  <PersonIcon />
                </Tooltip>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/settings"
              >
                <Tooltip title="Settings" placement="right">
                  <img src={Settings} alt="Settings" />
                </Tooltip>
              </NavLink>
            </li>
            <li>
              <button
                onClick={handleLogout}
                className={styles.navButton}
                to="/login"
              >
                <Tooltip title="Log Out" placement="right">
                  <img src={Logout} alt="Logout" />
                </Tooltip>
              </button>
            </li>
          </ul>
        </nav>

        <div
          className={styles.toggle}
          onClick={() => dispatch(setIsNavBarShrink(false))}
        >
          <img src={RightArrow} alt="Open Nav Bar" />
        </div>
      </aside>
    );
  } else {
    return (
      <aside className={styles.navBarContainerLarge}>
        <div className={styles.logoContainerLarge}>
          <img src={synapseLogo} alt="CMS-Synapse Logo" />
          <div>
            <h1>CMS-Synapse</h1>
            <small>BY MORDOR INTELLIGENCE</small>
          </div>
        </div>
        <nav>
          <ul style={{ border: "1px solid blue,", height: "200px" }}>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/dashboard"
              >
                <img src={Dashboard} alt="Dashboard" />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/create-user"
              >
                <img src={Sheet} alt="Create User" />
                <span>Create User</span>
              </NavLink>

              <ul>
                {subCategories?.map((subcategory) => {
                  <li key={subcategory.path}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? styles.activeNav : styles.nav
                      }
                      to={subcategory.path}
                    >
                      <span>{subcategory.label}</span>
                    </NavLink>
                  </li>;
                })}
              </ul>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/user-list"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PersonIcon />
                <span>User List</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/uploads"
              >
                <img src={Settings} alt="Settings" />
                <span>Uploads</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.nav
                }
                to="/settings"
              >
                <img src={Settings} alt="Settings" />
                <span>Settings</span>
              </NavLink>
            </li>
            <li>
              <button
                onClick={handleLogout}
                className={styles.navButton}
                to="/login"
              >
                <img src={Logout} alt="Logout" />
                <span>Log Out</span>
              </button>
            </li>
          </ul>
        </nav>

        <div
          className={styles.toggle}
          onClick={() => dispatch(setIsNavBarShrink(true))}
        >
          <img src={LeftArrow} alt="Collapse Nav Bar" />
        </div>
      </aside>
    );
  }
}

export default NavBar;
