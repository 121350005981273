import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedReport: [],
  libraryFormDetails: {},
  allReportData: {},
};

const libraryAssignmentMain = createSlice({
  name: "libraryAssignmentMain",
  initialState: initialState,
  reducers: {
    storeSelectedReport: (state, action) => {
      state.selectedReport = action.payload;
    },
    updateSelectedReport: (state, action) => {
      if (action.payload.isSearch) {
        // console.log("state.selectedReport", state.selectedReport);
        //Comparing search source with existing selectedReport
        const compareWithExisting = action.payload.exitingStateData.filter(
          (item) => {
            //Returning the item which is not present in the search source
            return !action.payload.searchSource.some(
              (searchItem) => searchItem.id === item.id
            );
          }
        );
        //Appending the search source with the existing selectedReport
        state.selectedReport = [...compareWithExisting, ...action.payload.data];
      } else {
        state.selectedReport = action.payload.data;
      }
    },
    updateLibraryFormDetails: (state, action) => {
      const { key, value } = action.payload;
      state.libraryFormDetails[key] = value;
    },
    deleteSelectedReport: (state, action) => {
      const { key } = action.payload;
      delete state.selectedReport[key];
    },
    resetAll: (state) => {
      state.selectedReport = [];
      state.libraryFormDetails = {};
    },
    addReportOnAllReportData: (state, action) => {
      const { key, value } = action.payload;
      state.allReportData[key] = value;
    },
    updateAllReportData: (state, action) => {
      const updatedRowData = action.payload;
      //Iterating updatedRowData to update allReportData
      updatedRowData.forEach((row) => {
        const { id } = row;
        state.allReportData[id] = row;
      });
    },
    deleteReportOnAllReportData: (state, action) => {
      const { key } = action.payload;
      delete state.allReportData[key];
    },
  },
});

export const {
  storeSelectedReport,
  updateSelectedReport,
  updateLibraryFormDetails,
  deleteSelectedReport,
  resetAll,
  addReportOnAllReportData,
  updateAllReportData,
  deleteReportOnAllReportData,
} = libraryAssignmentMain.actions;

export default libraryAssignmentMain.reducer;
