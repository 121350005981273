import React from "react";
import MainWrapper from "./Main.style";
import MainHeader from "../MainHeader/MainHeader";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {
  return (
    <MainWrapper>
      {/* <MainHeader /> */}
      <ToastContainer autoClose={5000} />
      <div className="content">
        <Outlet />
      </div>
      {/* <Footer /> */}
    </MainWrapper>
  );
};

export default Main;
