const API = {
  debugMode: process.env.REACT_APP_DEBUG === "true",
  environment: process.env.REACT_APP_ENVIRONMENT,
  Base_URL: process.env.REACT_APP_API_BASE_URL,
  Synapse_URL: process.env.REACT_APP_SYNAPSE_BASE_URL,
  logIn: "salesUser/login/",
  resetPassword: "salesUser/resetPassword",
  uploadSheet: "cms/upload/",
  // https://api-cms.mordorlabs.com/api/addUser/getHubList
  getHubList: "addUser/getHubList",
  getReports: "addUser/getReports",
  addReport: "createReport",
  updateReport: "updateReport",
  categoryTree: "category-tree",
  searchReport: "searchSynapseReports?title=",
  getFlashReport: "reportAssignment/getFlashList",
  userDataReportAssignment  : "reportAssignment/getUserData"
};

export default API;
