import React, { useEffect, useState } from "react";
import { MainHeaderWrapper, UserBadge } from "./MainHeader.style";
import Icon from "@ant-design/icons/lib/components/Icon";
import {
  Button,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";

import { HomeOutlined, SettingOutlined } from "@ant-design/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setPermission } from "../../redux/userPermission";
import Designation from "../../assets/Designation.svg";

const menuItemStyle = {
  borderBottom: "1px solid #ccc", // Add a bottom border to menu items
  padding: "15px", // Add padding to adjust the spacing
  margin: 0,
};

const MainHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { title } = useSelector((state) => state.slider);
  // const [anchorElNav, setAnchorElNav] = useState(null);
  // const [open, setOpen] = useState(false);

  const role = cookies.get("ROLE");
  const name = cookies.get("NAME");
  const email = cookies.get("EMAIL");
  const { permission } = useSelector((state) => state.userPermission);
  const dispatch = useDispatch();
  function handleLogout() {
    cookies.remove("TOKEN", { secure: true, sameSite: "strict", path: "/" });
    navigate("/");
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpenNavMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!role) {
      navigate("/");
    }
    if (role === "SUPER_ADMIN") {
      dispatch(
        setPermission({
          Dashboard: true,
          "User Management": true,
          "Content Management": true,
          "Custom Reports Management": true,
          Settings: true,
        })
      );
    } else if (role === "SALES_MANAGER" || role === "SALES_EXECUTIVE") {
      dispatch(
        setPermission({
          Dashboard: true,
          "User Management": true,
          "Content Management": false,
          "Custom Reports Management": false,
          Settings: true,
        })
      );
    } else if (
      role === "FORMATTING_MANAGER" ||
      role === "FORMATTING_EXECUTIVE"
    ) {
      const permission = {
        Dashboard: true,
        "User Management": false,
        "Content Management": true,
        "Custom Reports Management": true,
        Settings: true,
      };
      if (role === "FORMATTING_MANAGER") {
        dispatch(
          setPermission({
            ...permission,
            "User Management": true,
          })
        );
      } else {
        dispatch(
          setPermission({
            Dashboard: true,
            "User Management": false,
            "Content Management": true,
            "Custom Reports Management": true,
            Settings: true,
          })
        );
      }
    }
  }, [role]);

  return (
    <MainHeaderWrapper>
      {/* <div>
        <HomeOutlined
          style={{
            fontSize: 24,
            cursor: "pointer",
            marginLeft: 7,
            color:
              location.pathname === "/home/dashboard" ? "#1677ff" : "black",
          }}
          onClick={() => {
            navigate("/home/dashboard");
          }}
        />
        <SettingOutlined
          style={{
            fontSize: 24,
            cursor: "pointer",
            marginLeft: 7,
            color: location.pathname === "/home/settings" ? "#1677ff" : "black",
          }}
          onClick={() => {
            navigate("/home/settings");
          }}
        />
      </div> */}
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          alignItems: "center",

          // justifyContent: "start",
          // ml: "11%",
        }}
      >
        {permission.Dashboard && (
          <NavLink
            // onClick={() => sessionStorage.removeItem("companies")}
            to="/home/dashboard"
            style={({ isActive }) => ({
              textDecoration: "none",
              margin: "0 10px",
              // color: "black",
              color:
                isActive || location.pathname.includes("/home/dashboard")
                  ? "#2c8ebf"
                  : "black",
              // background: isActive ? "#2c8ebf" : "#fff",
              borderBottom:
                isActive || location.pathname.includes("/home/dashboard")
                  ? "4px solid #2c8ebf"
                  : "4px solid white",
            })}
          >
            <Button
              // onClick={handleCloseNavMenu}
              sx={{
                // height: "54px",
                height: "66px",
                py: "10px",
                px: 3,
                color: "inherit",
                display: "block",
                textTransform: "capitalize",
                borderRadius: "none",
              }}
            >
              Dashboard
            </Button>
          </NavLink>
        )}
        {permission?.["User Management"] && (
          <NavLink
            // onClick={() => sessionStorage.removeItem("companies")}
            to={
              role === "FORMATTING_MANAGER"
                ? "/home/user-management/create-cms-user"
                : "/home/user-management/create-user"
            }
            style={({ isActive }) => ({
              textDecoration: "none",
              margin: "0 10px",
              // color: "black",
              color:
                isActive || location.pathname.includes("/home/user-management")
                  ? "#2c8ebf"
                  : "black",
              // background: isActive ? "#2c8ebf" : "#fff",
              borderBottom:
                isActive || location.pathname.includes("/home/user-management")
                  ? "4px solid #2c8ebf"
                  : "4px solid white",
            })}
          >
            <Button
              // onClick={handleCloseNavMenu}
              sx={{
                // height: "54px",
                height: "66px",
                py: "10px",
                px: 3,
                color: "inherit",
                display: "block",
                textTransform: "capitalize",
                borderRadius: "none",
              }}
            >
              User Management
            </Button>
          </NavLink>
        )}
        {permission?.["Content Management"] && (
          <NavLink
            // onClick={() => sessionStorage.removeItem("companies")}
            to="/home/content-management/view-and-edit-reports"
            style={({ isActive }) => ({
              textDecoration: "none",
              margin: "0 10px",
              // color: "black",
              color:
                isActive ||
                location.pathname.includes("/home/content-management")
                  ? "#2c8ebf"
                  : "black",
              // background: isActive ? "#2c8ebf" : "#fff",
              borderBottom:
                isActive ||
                location.pathname.includes("/home/content-management")
                  ? "4px solid #2c8ebf"
                  : "4px solid white",
            })}
          >
            <Button
              // onClick={handleCloseNavMenu}
              sx={{
                // height: "54px",
                height: "66px",
                py: "10px",
                px: 3,
                color: "inherit",
                display: "block",
                textTransform: "capitalize",
                borderRadius: "none",
              }}
            >
              Content Management
            </Button>
          </NavLink>
        )}
        {permission?.["Custom Reports Management"] && (
          <NavLink
            // onClick={() => sessionStorage.removeItem("companies")}
            to="/home/custom-reports-management/upload-files-2"
            style={({ isActive }) => ({
              textDecoration: "none",
              margin: "0 10px",
              // color: "black",
              color:
                isActive ||
                location.pathname.includes("/home/custom-reports-management")
                  ? "#2c8ebf"
                  : "black",
              // background: isActive ? "#2c8ebf" : "#fff",
              borderBottom:
                isActive ||
                location.pathname.includes("/home/custom-reports-management")
                  ? "4px solid #2c8ebf"
                  : "4px solid white",
            })}
          >
            <Button
              // onClick={handleCloseNavMenu}
              sx={{
                // height: "54px",
                height: "66px",
                py: "10px",
                px: 3,
                color: "inherit",
                display: "block",
                textTransform: "capitalize",
                borderRadius: "none",
              }}
            >
              Custom Reports Management
            </Button>
          </NavLink>
        )}
        {/* {permission?.Settings && (
          <NavLink
            // onClick={() => sessionStorage.removeItem("companies")}
            to="/home/settings/view-login-history"
            style={({ isActive }) => ({
              textDecoration: "none",
              margin: "0 10px",
              // color: "black",
              color:
                isActive || location.pathname.includes("/home/settings")
                  ? "#2c8ebf"
                  : "black",
              // background: isActive ? "#2c8ebf" : "#fff",
              borderBottom:
                isActive || location.pathname.includes("/home/settings")
                  ? "4px solid #2c8ebf"
                  : "4px solid white",
            })}
          >
            <Button
              // onClick={handleCloseNavMenu}
              sx={{
                // height: "54px",
                height: "66px",
                py: "10px",
                px: 3,
                color: "inherit",
                display: "block",
                textTransform: "capitalize",
                borderRadius: "none",
              }}
            >
              Settings
            </Button>
          </NavLink>
        )} */}
      </Box>

      <div>
        <Tooltip title="Open Settings">
          <UserBadge onClick={handleOpenNavMenu}>
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: "18px",
              }}
            >
              {name && name[0]?.toUpperCase()}
            </Typography>
          </UserBadge>
        </Tooltip>
        <Menu
          id="nav-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseNavMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            marginTop: "14px",
            marginLeft: "-22px",
          }}
        >
          <Box
            sx={{
              padding: "15px 15px 6px 15px",
              width: "280px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "800",
                lineHeight: "25.6px",
                textTransform: "capitalize",
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#5a5a5a",
                lineHeight: "18px",
                py: "2px",
              }}
            >
              {email}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "5px",
              background:
                "linear-gradient(to right,#189CDE 0%,#EAFFEA 0%,#EAF6FE 60%,#E3E1FC 115%)",
              padding: "18px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <img
                src={Designation}
                style={{
                  height: "20px",
                  width: "20px",
                  marginLeft: "-5px",
                  marginRight: "5px",
                }}
              />

              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                Designation
              </Typography>
            </Box>

            <Box sx={{ paddingLeft: "20px" }}>
              <Typography sx={{ color: "#5a5a5a", fontSize: "13px" }}>
                {role?.split("_").join(" ").toUpperCase()}
              </Typography>
            </Box>
          </Box>
          <MenuItem
            style={menuItemStyle}
            onClick={() => {
              navigate("/home/settings/view-login-history");
            }}
          >
            <Typography
              sx={{
                color: "#000000",
                fontSize: "15px",
              }}
            >
              View Log In History
            </Typography>
          </MenuItem>
          <MenuItem
            style={menuItemStyle}
            onClick={() => {
              navigate("/home/settings/change-password");
            }}
          >
            <Typography
              sx={{
                color: "#000000",
                fontSize: "15px",
              }}
            >
              Change Password
            </Typography>
          </MenuItem>
          <MenuItem
            style={{ ...menuItemStyle, borderBottom: "none" }}
            onClick={() => {
              handleLogout();
            }}
          >
            <Typography
              sx={{
                color: "#FF0000",
                fontSize: "16px",
              }}
            >
              Log Out
            </Typography>
          </MenuItem>
        </Menu>
      </div>
    </MainHeaderWrapper>
  );
};

export default MainHeader;
