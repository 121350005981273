import React from "react";
import { Select, AutoComplete, Tooltip, Button, Row, Col } from "antd";
import AssignReportsFormWrapper from "./AttachReportsForm.style";
import Icon from "@ant-design/icons/lib/components/Icon";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

const Option = Select.Option;
const AutoCompOption = AutoComplete.Option;

const AttachReportsForm = ({
  templateList,
  setTemplateId,
  templateReportList,
  selectedTemplateReports,
  searchReportTitles,
  searchReportResults,
  scratchReports,
  setScratchReports,
  removeScratchReports,
  handleAssignReports,
  handleTemplateReports,
  isReportAssigning,
  setConversationUser,
  handleBack,
}) => {
  const [selectedField, setSelectedField] = React.useState("title");
  const template_list = templateList?.map((template) => (
    <Option value={template.id}>{template.name}</Option>
  ));
  let template_report_list = templateReportList?.map((report) => (
    <Option value={report.id}>{report.title}</Option>
  ));
  const search_report_results = searchReportResults?.map((report) => (
    <AutoCompOption value={report.title + ":" + report.id}>
      {report.title}
    </AutoCompOption>
  ));

  const debouncedSearch = React.useCallback(
    debounce(
      (value, selectedField) => searchReportTitles(value, selectedField),
      300
    ),
    []
  );

  return (
    <AssignReportsFormWrapper>
      <div className="flex section-header">
        <Tooltip message="Go Back" onClick={handleBack}>
          <ArrowLeftOutlined type="arrow-left" className="back-arrow-btn" />
        </Tooltip>
        <h2>Assign Reports Form</h2>
      </div>

      <div className="add-from-scratch">
        <Row gutter={16}>
          <Col span={18}>
            <h4>Search Report</h4>
            <AutoComplete
              allowClear
              mode="multiple"
              style={{ width: "100%", marginBottom: "10px" }}
              onSearch={(value) => debouncedSearch(value, selectedField)}
              onSelect={setScratchReports}
              placeholder="input here"
            >
              {search_report_results}
            </AutoComplete>
          </Col>
          <Col span={6}>
            <h4>Select Field</h4>
            <Select
              defaultValue={"title"}
              style={{ width: "100%" }}
              placeholder="Select a field"
              onChange={(value) => setSelectedField(value)}
            >
              <Option value="title">Title</Option>
              <Option value="id">ID</Option>
              <Option value="slug">Slug</Option>
            </Select>
          </Col>
        </Row>
      </div>

      <Button
        type="primary"
        loading={isReportAssigning}
        onClick={handleAssignReports}
      >
        Assign
      </Button>
    </AssignReportsFormWrapper>
  );
};

export default AttachReportsForm;
