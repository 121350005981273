import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignReport } from "../../redux/api/assignedReportsNewSlice";
import { Form, Button, DatePicker, Select, message, Col, Row } from "antd";
import axios from "axios";
import Cookies from "universal-cookie";
import API from "../../utils/api";
import _ from "lodash";
import moment from "moment";
import ReportTable from "./ReportTable";
import AssignedReports from "./AssignedReports";
import dayjs from "dayjs";
import { useLazyGetReportsQuery } from "../../redux/api/createUserApi";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { setRowKeys } from "../../redux/assignedReportsLimitedReport";

const { Option } = Select;

const AssignReportForm = ({ formData, onCancel, selectedUserEmail }) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [trigger, result] = useLazyGetReportsQuery();
  const { Base_URL, getHubList, userDataReportAssignment } = API;
  const token = cookies.get("TOKEN");

  const [form] = Form.useForm();
  const [hubs, setHubs] = useState([]);
  const [hubLevelType, setHubLevelType] = useState("");
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedHubs, setSelectedHubs] = useState([]);
  const [prevSelectedHubs, setPrevSelectedHubs] = useState([]);
  const [showReportTable, setShowReportTable] = useState(false);
  const [hideAssignedReports, setHideAssignedReports] = useState(false);
  const [noAssignedReports, setNoAssignedReports] = useState(false);
  const [subscriptionDate, setSubscriptionDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const selectedRowKeys = useSelector((state) => state.limitedReports);

  const assignedReportsNew = useSelector((state) => state.assignedReportsNew);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const handleSubscriptionDateChange = (date) => {
    setSubscriptionDate(date);
  };

  const handleExpiryDateChange = (date) => {
    setExpiryDate(date);
  };

  useEffect(() => {
    setNoAssignedReports(
      assignedReportsNew?.trial?.length +
        assignedReportsNew?.full?.length +
        assignedReportsNew?.limited?.length ===
        0
    );
  }, [assignedReportsNew]);

  const dateReverseFun = (dateRev) => {
    const date = dayjs(dateRev).tz("Asia/Kolkata");
    const $d = date.toDate();
    const $L = "en";
    const $y = date.year();
    const $M = date.month(); // 0-based index for month (6 represents July)
    const $D = date.date();
    const $H = date.hour();
    const $m = date.minute();
    const $s = date.second();
    const $ms = date.millisecond();
    const $W = date.day(); // Day of the week (4 represents Thursday)
    const $u = undefined;
    const $isDayjsObject = true;
    const $x = {};
    const result = {
      $D,
      $H,
      $L,
      $M,
      $W,
      $d,
      $isDayjsObject,
      $m,
      $ms,
      $s,
      $u,
      $x,
      $y,
    };
    return date;
  };

  useEffect(() => {
    // Fetch hub list
    const config = {
      method: "get",
      headers: { authorization: `Bearer ${token}` },
      maxBodyLength: Infinity,
      url: `${Base_URL}${getHubList}`,
    };

    axios(config)
      .then((res) => {
        const hubList = res?.data?.data;
        const sortedData = _.sortBy(hubList, "name");
        setHubs(sortedData);
      })
      .catch((error) => {
        message.error("Failed to load hubs");
      });
  }, [Base_URL, getHubList, token]);

  useEffect(() => {
    if (formData) {
      setHubLevelType("");
      setSelectedHubs([]);
      setHideAssignedReports(true);
      setSubscriptionDate(
        formData?.subscription_date
          ? dateReverseFun(formData?.subscription_date)
          : formData?.subscription_date
      );
      setExpiryDate(
        formData?.expiry_date
          ? dateReverseFun(formData?.expiry_date)
          : formData?.expiry_date
      );
      // Set form fields with initial values from formData if provided
      setSelectedHubs(formData?.selectHub);
      setPrevSelectedHubs(formData?.selectHub);
      const reportIDs = formData?.report?.map((item) => item.id);
      dispatch(setRowKeys(reportIDs));
      form.setFieldsValue({
        access_type: formData.access_type,
        modules: formData.modules,
        selectHub: formData.selectHub.map((item) => item.name),
        subscription_date: formData?.subscription_date
          ? dayjs(formData.subscription_date)
          : null,
        expiry_date: formData?.expiry_date ? dayjs(formData.expiry_date) : null,
        report: formData?.report ? formData?.report : null,
      });

      setShowReportTable(formData.access_type === "limited");
    }
  }, [formData, form]);

  const handleSelectChange = (value, options) => {
    if (
      (formData ? formData?.access_type !== "limited" : false) ||
      (hubLevelType ? hubLevelType !== "limited" : false)
    ) {
      const selectedHubsArray = options.map((option) => ({
        id: option.key,
        name: option.value,
      }));
      setSelectedHubs(selectedHubsArray);
    } else {
      const singleSelectedArray = [{ id: options.key, name: options.value }];
      setSelectedHubs(singleSelectedArray);
    }
    resetLimitedReports();
  };

  const onFinish = (values) => {
    if (values.access_type === "full" && !formData) {
      if (
        (values.subscription_date &&
          values.subscription_date < moment().startOf("day")) ||
        (values.expiry_date && values.expiry_date < values.subscription_date)
      ) {
        message.error("Please check the subscription and expiry dates.");
        return;
      }
    }
    let updatedValues = { ...values, selectHub: selectedHubs };
    if (!values?.modules) {
      updatedValues = { ...updatedValues, modules: [] };
    }
    if (values.access_type === "limited") {
      updatedValues = { ...updatedValues, report: selectedReports };
      dispatch(assignReport(updatedValues));
    }
    if (values.access_type === "full" || values.access_type === "trial") {
      const reportAssign = !values?.selectHub?.[0].key
        ? formData?.selectHub.map((item) => item.id).join(",")
        : values?.selectHub.map((item) => item.key).join(",");
      const fetchReports = async () => {
        try {
          const response = await trigger({
            hubId: reportAssign,
          });
          updatedValues = {
            ...updatedValues,
            report: response?.data?.data?.reports,
          };
          dispatch(assignReport(updatedValues));
        } catch (error) {
          console.error("Error fetching reports:", error);
        } finally {
        }
      };

      // Call the fetchReports function where necessary
      fetchReports();
    }
    form.resetFields();
    setHubLevelType("");
    setExpiryDate(null);
    setSubscriptionDate(null);
    setShowReportTable(false);
    message.success("Hub Assigned Successfully");
    resetLimitedReports();
    onCancel();
  };

  const resetLimitedReports = () => {
    dispatch(setRowKeys([]));
  };

  const handleShowReport = () => {
    if (selectedHubs?.length === 0) {
      message.error("Please select a hub");
      return;
    }
    setShowReportTable(true);
  };

  const handleAssignReports = (reportsIdArray) => {
    setSelectedReports(reportsIdArray);
  };

  const availableHubs = [
    ...hubs.filter(
      (hub) =>
        !assignedReportsNew.full.some(({ selectHub }) =>
          selectHub.some((h) => h.name === hub.name)
        ) &&
        !assignedReportsNew.trial.some(({ selectHub }) =>
          selectHub.some((h) => h.name === hub.name)
        ) &&
        !assignedReportsNew.limited.some(({ selectHub }) =>
          selectHub.some((h) => h.name === hub.name)
        )
    ),
    ...prevSelectedHubs,
  ].sort((a, b) => a?.name?.localeCompare(b?.name));

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ modules: ["Reports"] }}
      >
        <Row gutter={16}>
          <Col span={hideAssignedReports ? 16 : 8}>
            <Form.Item
              label="Hub Level Access Type"
              name="access_type"
              rules={[{ required: true, message: "Please select access type" }]}
            >
              <Select
                onChange={(value) => {
                  setSelectedHubs([]);
                  setHubLevelType(value);
                  setShowReportTable(false);
                  setSelectedReports([]);
                  resetLimitedReports();
                }}
                disabled={formData}
              >
                {!assignedReportsNew.full.length && (
                  <Option value="full">Full</Option>
                )}
                {!assignedReportsNew.trial.length && (
                  <Option value="trial">Trial</Option>
                )}
                {availableHubs?.length > 0 && (
                  <Option value="limited">Limited</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={hideAssignedReports ? 16 : 8}>
            <Form.Item
              label="Hub Module Access"
              name="modules"
              // rules={[{ required: true, message: 'Please select module access' }]}
            >
              <Select
                mode="multiple"
                defaultValue={["Reports"]}
                onChange={(value) => {
                  // Ensure "Reports" is always included
                  if (!value.includes("Reports")) {
                    value.push("Reports");
                  }
                  form.setFieldsValue({ modules: value });
                }}
              >
                <Option value="Reports">Reports</Option>
                {/* <Option value="Companies">Companies</Option> */}
                <Option value="Market Share">Market Share</Option>
                <Option value="Visualize">Visualize</Option>
                <Option value="Master Data">Master Data</Option>
                {(formData?.access_type === "full" ||
                  hubLevelType === "full") && (
                  <>
                    <Option value="Indicators">Indicators</Option>
                    <Option value="myRA AI">myRA AI</Option>
                  </>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={hideAssignedReports ? 16 : 8}>
            <Form.Item
              label="Select Hubs"
              name="selectHub"
              rules={[{ required: true, message: "Please select a hub" }]}
            >
              {(formData ? formData?.access_type === "limited" : false) ||
              hubLevelType === "limited" ? (
                <Select
                  onChange={handleSelectChange}
                  labelInValue
                  value={
                    selectedHubs
                      ? { key: selectedHubs.id, value: selectedHubs.name }
                      : null
                  }
                  disabled={formData}
                >
                  {availableHubs.map((hub) => (
                    <Select.Option key={hub.id} value={hub.name}>
                      {hub.name}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Select
                  mode="multiple"
                  onChange={handleSelectChange}
                  labelInValue
                  value={selectedHubs.map((hub) => ({
                    key: hub.id,
                    value: hub.name,
                  }))}
                  maxTagCount={2}
                >
                  {availableHubs.map((hub) => (
                    <Select.Option key={hub.id} value={hub.name}>
                      {hub.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          {/* {((formData ? formData?.access_type !== 'trial' : false) || (hubLevelType? hubLevelType !== 'trial' : false)) && <> */}
          <Col span={hideAssignedReports ? 16 : 4}>
            <Form.Item
              label="Subscription Start"
              name="subscription_date"
              rules={[
                {
                  required: formData
                    ? formData?.access_type !== "limited"
                    : false || hubLevelType
                    ? hubLevelType !== "limited"
                    : false,
                  message: "Please select subscription date",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      moment(value).isSameOrAfter(moment().startOf("day"))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Subscription date cannot be earlier than today."
                      )
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                minDate={dayjs()}
                onChange={handleSubscriptionDateChange}
              />
            </Form.Item>
          </Col>
          <Col span={hideAssignedReports ? 16 : 4}>
            <Form.Item
              label="Subscription Ends"
              name="expiry_date"
              rules={[
                {
                  required: formData
                    ? formData?.access_type !== "limited"
                    : false || hubLevelType
                    ? hubLevelType !== "limited"
                    : false,
                  message: "Please select subscription ends",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      moment(value).isSameOrAfter(
                        getFieldValue("subscription_date")
                      )
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Expiry date must be greater than or equal to subscription date."
                      )
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                minDate={subscriptionDate || dayjs()}
                onChange={handleExpiryDateChange}
              />
            </Form.Item>
          </Col>
          {/* </>} */}
        </Row>
        {(hubLevelType === "limited" ||
          formData?.access_type === "limited") && (
          <Form.Item>
            <Button type="primary" onClick={handleShowReport}>
              Show Reports
            </Button>
            <Button type="secondary" onClick={() => setShowReportTable(false)}>
              Hide Reports
            </Button>
          </Form.Item>
        )}
        {showReportTable && (
          <ReportTable
            onAssignReports={handleAssignReports}
            hubLevelType={hubLevelType || formData?.access_type}
            selectedHub={selectedHubs?.[0] || formData?.selectHub?.[0]}
            report={formData?.report}
            subscriptionDate={subscriptionDate}
            expiryDate={expiryDate}
          />
        )}

        {((showReportTable &&
          selectedReports.length > 0 &&
          selectedRowKeys?.length > 0) ||
          (hubLevelType ? hubLevelType !== "limited" : false) ||
          (formData ? formData?.access_type !== "limited" : false)) && (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {!formData ? "Assign" : "Update"} Hubs
            </Button>
          </Form.Item>
        )}
      </Form>
      {!hideAssignedReports && !noAssignedReports && <AssignedReports />}
    </>
  );
};

export default AssignReportForm;
