import React from "react";
import styles from "./styles.module.css";

import { Link } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";

const PageNotFound = () => {
  return (
    <Box className={styles.container}>
      <Typography variant="h1" className={styles.errorcode}>
        404
      </Typography>

      <Typography variant="h4" className={styles.errormessage}>
        Oops! The page you are looking for could not be found.
      </Typography>

      <Button
        component={Link}
        to="/home/dashboard"
        variant="contained"
        className={styles.button}
      >
        Go back to home page
      </Button>
    </Box>
  );
};

export default PageNotFound;
