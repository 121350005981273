export const CATEGORYDATA = [
  {
    _id: "5a532e28f1607c4f8bc00162",
    id: 59,
    title: "Aerospace & Defense",
    slug: "aerospace-defense",
    picture_url: "aerospace-&.png",
    subcategory: [
      {
        id: 25,
        title: "Defense",
        slug: "defense",
        active: true,
      },
      {
        id: 84,
        title: "Airport Operations",
        slug: "airport-operations",
        active: true,
      },
      {
        id: 95,
        title: "Aviation",
        slug: "aviation",
        active: true,
      },
      {
        active: true,
        title: "Aerospace & Defense Technology",
        slug: "aerospace-and-defense-technology",
        id: 647,
      },
      {
        active: true,
        title: "Air Taxi",
        slug: "air-taxi",
        id: 648,
      },
      {
        active: true,
        title: "Aircraft Parts",
        slug: "aircraft-parts",
        id: 649,
      },
      {
        active: true,
        title: "Aviation MRO",
        slug: "aviation-mro",
        id: 650,
      },
      {
        active: true,
        title: "Space",
        slug: "space",
        id: 651,
      },
      {
        active: true,
        title: "Unmanned Systems",
        slug: "unmanned-systems",
        id: 652,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc00160",
    id: 58,
    title: "Agriculture",
    slug: "agriculture",
    picture_url: "agriculture.png",
    subcategory: [
      {
        id: 104,
        title: "Seeds",
        slug: "seeds",
        active: true,
      },
      {
        active: true,
        title: "Crop Protection",
        slug: "crop-protection",
        id: 154,
      },
      {
        active: true,
        title: "Fertilizers",
        slug: "fertilizers",
        id: 155,
      },
      {
        active: true,
        title: "Agricultural Commodities",
        slug: "agricultural-commodities",
        id: 157,
      },
      {
        id: 614,
        title: "Agriculture Services",
        slug: "agriculture-services",
        active: true,
      },
      {
        id: 615,
        title: "Agricultural Machinery and Technology",
        slug: "agricultural-machinery-and-technology",
        active: true,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc0016e",
    id: 70,
    title: "Animal Nutrition & Wellness",
    slug: "animal-nutrition",
    picture_url: "advanced-tech.png",
    subcategory: [
      {
        id: 62,
        title: "Compound Feed",
        slug: "compound-feed",
        active: true,
      },
      {
        active: true,
        title: "Feed Additives",
        slug: "feed-additives",
        id: 611,
      },
      {
        active: true,
        title: "Pet Care",
        slug: "pet-care",
        id: 612,
      },
      {
        active: true,
        title: "Animal Farm Services",
        slug: "animal-farm-services",
        id: 613,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc00168",
    id: 65,
    title: "Automotive",
    slug: "automotive",
    picture_url: "automotive.png",
    subcategory: [
      {
        id: 28,
        title: "Automotive Materials & Coatings",
        slug: "automotive-materials-coatings",
        active: true,
      },
      {
        id: 35,
        title: "Automotive Technology",
        slug: "automotive-technology",
        active: true,
      },
      {
        id: 103,
        title: "Vehicles",
        slug: "vehicles",
        active: true,
      },
      {
        id: 116,
        title: "Automotive Services",
        slug: "automotive-services",
        active: true,
      },
      {
        active: true,
        title: "Auto Parts",
        slug: "auto-parts",
        id: 661,
      },
      {
        active: true,
        title: "Automotive Equipment",
        slug: "automotive-equipment",
        id: 662,
      },
      {
        active: true,
        title: "Automotive Processes",
        slug: "automotive-processes",
        id: 663,
      },
      {
        active: true,
        title: "Heavy Equipment",
        slug: "heavy-equipment",
        id: 664,
      },
      {
        active: true,
        title: "Maritime",
        slug: "maritime",
        id: 665,
      },
      {
        active: true,
        title: "Sea Port Infrastructure",
        slug: "sea-port-infrastructure",
        id: 666,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc00164",
    id: 61,
    title: "Chemicals & Materials",
    slug: "chemicals-materials",
    picture_url: "chemicals-.png",
    subcategory: [
      {
        id: 32,
        title: "Plastics, Polymers, and Elastomers",
        slug: "plastics-polymers-and-elastomers",
        active: true,
      },
      {
        id: 42,
        title: "Adhesives and Sealants",
        slug: "adhesives-and-sealants",
        active: true,
      },
      {
        id: 46,
        title: "Commodity Chemicals",
        slug: "commodity-chemicals",
        active: true,
      },
      {
        id: 50,
        title: "Water and Wastewater Treatment",
        slug: "water-and-wastewater-treatment",
        active: true,
      },
      {
        id: 52,
        title: "Advanced Materials",
        slug: "advanced-materials",
        active: true,
      },
      {
        id: 106,
        title: "Construction Chemicals and Materials",
        slug: "construction-chemicals-and-materials",
        active: true,
      },
      {
        id: 107,
        title: "Paints and Coatings",
        slug: "paints-and-coatings",
        active: true,
      },
      {
        id: 109,
        title: "Fine Chemicals",
        slug: "fine-chemicals",
        active: true,
      },
      {
        active: true,
        title: "Abrasives",
        slug: "abrasives",
        id: 632,
      },
      {
        active: true,
        title: "Biobased Chemicals",
        slug: "biobased-chemicals",
        id: 633,
      },
      {
        active: true,
        title: "Ceramics",
        slug: "ceramics",
        id: 634,
      },
      {
        active: true,
        title: "Chemical Technologies",
        slug: "chemical-technologies",
        id: 635,
      },
      {
        active: true,
        title: "Electronic Materials",
        slug: "electronic-materials",
        id: 636,
      },
      {
        active: true,
        title: "Gels",
        slug: "gels",
        id: 637,
      },
      {
        active: true,
        title: "Industrial Gases",
        slug: "industrial-gases",
        id: 638,
      },
      {
        active: true,
        title: "Insulation",
        slug: "insulation",
        id: 639,
      },
      {
        active: true,
        title: "Lubricants and Fuel Additives",
        slug: "lubricants-and-fuel-additives",
        id: 640,
      },
      {
        active: true,
        title: "Metals and Minerals",
        slug: "metals-and-minerals",
        id: 641,
      },
      {
        active: true,
        title: "Specialty Carbon",
        slug: "specialty-carbon",
        id: 642,
      },
      {
        active: true,
        title: "Specialty Chemicals",
        slug: "specialty-chemicals",
        id: 643,
      },
      {
        active: true,
        title: "Technical Textiles",
        slug: "technical-textiles",
        id: 644,
      },
      {
        active: true,
        title: "Wood Derivatives",
        slug: "wood-derivatives",
        id: 645,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc00169",
    id: 66,
    title: "Consumer Goods and Services",
    slug: "consumer-goods-and-services",
    picture_url: "consumer-goods.png",
    subcategory: [
      {
        active: true,
        title: "Apparel, Footwear & Accessories",
        slug: "apparel-footwear-accessories",
        id: 172,
      },
      {
        active: true,
        title: "Luxury Goods & Services",
        slug: "luxury-goods-services",
        id: 175,
      },
      {
        active: true,
        title: "Recreational Goods",
        slug: "recreational-goods",
        id: 178,
      },
      {
        active: true,
        title: "Baby Care",
        slug: "baby-care",
        id: 616,
      },
      {
        active: true,
        title: "Beauty and Personal Care",
        slug: "beauty-and-personal-care",
        id: 617,
      },
      {
        active: true,
        title: "Consumer Services",
        slug: "consumer-services",
        id: 618,
      },
      {
        active: true,
        title: "Home Care",
        slug: "home-care",
        id: 619,
      },
      {
        active: true,
        title: "Sports and Fitness",
        slug: "sports-and-fitness",
        id: 620,
      },
      {
        active: true,
        title: "Beverage Appliances",
        slug: "beverage-appliances",
        id: 621,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc00161",
    id: 57,
    title: "Energy & Power",
    slug: "energy-power",
    picture_url: "energy-&-power.png",
    subcategory: [
      {
        id: 4,
        title: "Oil and Gas",
        slug: "oil-and-gas",
        active: true,
      },
      {
        id: 15,
        title: "Power",
        slug: "power",
        active: true,
      },
      {
        active: true,
        title: "Battery",
        slug: "battery",
        id: 624,
      },
      {
        active: true,
        title: "Decommissioning",
        slug: "decommissioning",
        id: 625,
      },
      {
        active: true,
        title: "Emerging Energy Technologies",
        slug: "emerging-energy-technologies",
        id: 626,
      },
      {
        active: true,
        title: "Energy Storage Technology",
        slug: "energy-storage-technology",
        id: 627,
      },
      {
        active: true,
        title: "Environmental Control Solutions",
        slug: "environmental-control-solutions",
        id: 628,
      },
      {
        active: true,
        title: "Fuels",
        slug: "fuels",
        id: 629,
      },
      {
        active: true,
        title: "Offshore Support Vessels",
        slug: "offshore-support-vessels",
        id: 630,
      },
      {
        active: true,
        title: "Power Equipment",
        slug: "power-equipment",
        id: 631,
      },
      {
        active: true,
        title: "Industrial Machinery",
        slug: "industrial-machinery",
        id: 646,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc0016d",
    id: 71,
    title: "Financial Services and Investment Intelligence",
    slug: "financial-services-and-investment-intelligence",
    subcategory: [
      {
        id: 60,
        title: "Insurance",
        slug: "insurance",
        active: true,
      },
      {
        active: true,
        title: "Alternative Investments",
        slug: "alternative-investments",
        id: 198,
      },
      {
        active: true,
        title: "Wealth Management",
        slug: "wealth-management",
        id: 199,
      },
      {
        active: true,
        title: "Fintech",
        slug: "fintech",
        id: 200,
      },
      {
        active: true,
        title: "Islamic Finance",
        slug: "islamic-finance",
        id: 201,
      },
      {
        active: true,
        title: "Asset Management",
        slug: "asset-management",
        id: 670,
      },
      {
        active: true,
        title: "Banking and Non Bank Finance",
        slug: "banking-and-non-bank-finance",
        id: 671,
      },
      {
        active: true,
        title: "Capital Market",
        slug: "capital-market",
        id: 672,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc00165",
    id: 62,
    title: "Food & Beverage",
    slug: "food-beverage",
    picture_url: "food-&-beverage.png",
    subcategory: [
      {
        id: 115,
        title: "Beverages",
        slug: "beverage",
        active: true,
      },
      {
        id: 129,
        title: "Food",
        slug: "food",
        active: true,
      },
      {
        active: true,
        title: "Food Ingredients and Food Additives",
        slug: "food-ingredients-and-food-additives",
        id: 622,
      },
      {
        active: true,
        title: "Food Supplements",
        slug: "food-supplements",
        id: 623,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc0015f",
    id: 56,
    title: "Healthcare",
    slug: "healthcare",
    picture_url: "healthcare.png",
    subcategory: [
      {
        id: 24,
        title: "Biotechnology",
        slug: "biotechnology",
        active: true,
      },
      {
        id: 38,
        title: "Pharmaceuticals",
        slug: "pharmaceuticals",
        active: true,
      },
      {
        id: 41,
        title: "Healthcare IT",
        slug: "healthcare-it",
        active: true,
      },
      {
        id: 100,
        title: "Medical Devices",
        slug: "medical-devices",
        active: true,
      },
      {
        id: 126,
        title: "Animal Health",
        slug: "animal-health",
        active: true,
      },
      {
        id: 600,
        title: "Device Drug Combination",
        slug: "device-drug-combination",
        active: true,
      },
      {
        id: 601,
        title: "Health Services",
        slug: "health-services",
        active: true,
      },
    ],
  },
  {
    _id: "5ca498124535ec7f813f97cd",
    picture_url: null,
    title: "Home and Property Improvement",
    slug: "home-property-improvement",
    id: 80,
    subcategory: [
      {
        active: true,
        title: "Kitchen Appliances",
        slug: "kitchen-appliances",
        id: 213,
      },
      {
        active: true,
        title: "Home Appliances",
        slug: "home-appliances",
        id: 214,
      },
      {
        active: true,
        title: "Laundry Appliances",
        slug: "laundry-appliances",
        id: 215,
      },
      {
        active: true,
        title: "Home Decor",
        slug: "home-decor",
        id: 216,
      },
      {
        active: true,
        title: "Furniture and Furnishings",
        slug: "furniture-and-furnishings",
        id: 217,
      },
      {
        active: true,
        title: "Floor Coverings",
        slug: "floor-coverings",
        id: 689,
      },
      {
        active: true,
        title: "Commercial Appliances",
        slug: "commercial-appliances",
        id: 692,
      },
      {
        active: true,
        title: "Bathroom Fittings",
        slug: "bathroom-fittings",
        id: 688,
      },
      {
        active: true,
        title: "Pool and Playground",
        slug: "pool-and-playground",
        id: 690,
      },
      {
        active: true,
        title: "Home Utility",
        slug: "home-utility",
        id: 691,
      },
    ],
  },
  {
    _id: "5afd6bdf0130b41b982fbd92",
    picture_url: null,
    title: "Hospitality and Tourism",
    slug: "hospitality-and-tourism",
    id: 74,
    subcategory: [
      {
        active: true,
        title: "Travel and Tourism",
        slug: "travel-and-tourism",
        id: 203,
      },
      {
        active: true,
        title: "Hospitality",
        slug: "hospitality",
        id: 673,
      },
    ],
  },
  {
    _id: "5afd6b570130b41b982fbd8e",
    picture_url: null,
    title: "Logistics",
    slug: "logistics",
    id: 72,
    subcategory: [
      {
        active: true,
        title: "Freight Transport",
        slug: "freight-transport",
        id: 602,
      },
      {
        active: true,
        title: "Freight Transportation Arrangement",
        slug: "freight-transportation-arrangement",
        id: 603,
      },
      {
        active: true,
        title: "Courier Express Parcel",
        slug: "courier-express-parcel",
        id: 604,
      },
      {
        active: true,
        title: "Integrated Logistics",
        slug: "integrated-logistics",
        id: 605,
      },
      {
        active: true,
        title: "Cold Chain Logistics",
        slug: "cold-chain-logistics",
        id: 606,
      },
      {
        active: true,
        title: "Customers of Logistics Services",
        slug: "customers-of-logistics-services",
        id: 608,
      },
      {
        active: true,
        title: "Postal Services",
        slug: "postal-services",
        id: 609,
      },
      {
        active: true,
        title: "Logistics Support Services",
        slug: "logistics-support-services",
        id: 610,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc0016b",
    id: 69,
    title: "Manufacturing Products and Services",
    slug: "manufacturing-products-and-services",
    picture_url: "Manufacturing.png",
    subcategory: [
      {
        active: true,
        title: "Textiles",
        slug: "textiles",
        id: 139,
      },
      {
        active: true,
        title: "Metal Working and Fabrication",
        slug: "metal-working-and-fabrication",
        id: 207,
      },
      {
        active: true,
        title: "General Manufacturing",
        slug: "general-manufacturing",
        id: 208,
      },
      {
        active: true,
        title: "Manufacturing Services",
        slug: "manufacturing-services",
        id: 687,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc00166",
    id: 63,
    title: "Packaging",
    slug: "packaging",
    picture_url: "packaging.png",
    subcategory: [
      {
        id: 124,
        title: "Advanced Packaging",
        slug: "advanced-packaging",
        active: true,
      },
      {
        id: 127,
        title: "Packaging Equipment",
        slug: "packaging-equipment",
        active: true,
      },
      {
        active: true,
        title: "Contract Packaging",
        slug: "contract-packaging",
        id: 653,
      },
      {
        active: true,
        title: "Overall Packaging",
        slug: "overall-packaging",
        id: 654,
      },
      {
        active: true,
        title: "Packaging Customers",
        slug: "packaging-customers",
        id: 655,
      },
      {
        active: true,
        title: "Packaging Forms",
        slug: "packaging-forms",
        id: 656,
      },
      {
        active: true,
        title: "Packaging Materials",
        slug: "packaging-materials",
        id: 657,
      },
      {
        active: true,
        title: "Packaging Printing",
        slug: "packaging-printing",
        id: 658,
      },
      {
        active: true,
        title: "Packaging Products",
        slug: "packaging-products",
        id: 659,
      },
      {
        active: true,
        title: "Packaging Supplies",
        slug: "packaging-supplies",
        id: 660,
      },
    ],
  },
  {
    _id: "64c3530ed56368bb175c92c3",
    picture_url: null,
    title: "Professional and Commercial Services",
    slug: "professional-and-commercial-services",
    id: 83,
    subcategory: [
      {
        active: true,
        title: "Commercial Services",
        slug: "commercial-services",
        id: 674,
      },
      {
        active: true,
        title: "Consumer and B2C Services",
        slug: "consumer-and-b2c-services",
        id: 675,
      },
      {
        active: true,
        title: "Professional Services",
        slug: "professional-services",
        id: 676,
      },
    ],
  },
  {
    _id: "5a532e28f1607c4f8bc0016c",
    id: 68,
    title: "Real Estate and Construction",
    slug: "real-estate-and-construction",
    picture_url: null,
    subcategory: [
      {
        active: true,
        title: "Real Estate",
        slug: "real-estate",
        id: 209,
      },
      {
        active: true,
        title: "Construction",
        slug: "construction",
        id: 210,
      },
    ],
  },
  {
    _id: "64c3530ed56368bb175c92c2",
    picture_url: null,
    title: "Retail",
    slug: "retail",
    id: 82,
    subcategory: [
      {
        active: true,
        title: "General Retail",
        slug: "general-retail",
        id: 667,
      },
      {
        active: true,
        title: "Retail Stores",
        slug: "retail-stores",
        id: 668,
      },
      {
        active: true,
        title: "Travel Retail",
        slug: "travel-retail",
        id: 669,
      },
    ],
  },
  {
    _id: "64d1fd28d473556d515a8c72",
    picture_url: null,
    title: "Technology, Media and Telecom",
    slug: "technology-media-and-telecom",
    id: 84,
    subcategory: [
      {
        active: true,
        title: "Automation",
        slug: "automation",
        id: 677,
      },
      {
        active: true,
        title: "Digital Commerce",
        slug: "digital-commerce",
        id: 678,
      },
      {
        active: true,
        title: "Electronics",
        slug: "electronics",
        id: 679,
      },
      {
        active: true,
        title: "Information Technology",
        slug: "information-technology",
        id: 680,
      },
      {
        active: true,
        title: "Media and Entertainment",
        slug: "media-and-entertainment",
        id: 681,
      },
      {
        active: true,
        title: "Security & Surveillance",
        slug: "security-and-surveillance",
        id: 682,
      },
      {
        active: true,
        title: "Semiconductors",
        slug: "semiconductors",
        id: 683,
      },
      {
        active: true,
        title: "Technology Scouting",
        slug: "technology-scouting",
        id: 684,
      },
      {
        active: true,
        title: "Telecom",
        slug: "telecom",
        id: 685,
      },
      {
        active: true,
        title: "Testing, Inspection and Certification",
        slug: "testing-inspection-and-certification",
        id: 686,
      },
    ],
  },
];
