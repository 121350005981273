export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const checkStores = (
  hubsReport,
  flashReport,
  selectedReportData,
  libraryFormDetails
) => {
  const hubTotal =
    hubsReport?.trial?.length +
      hubsReport?.full?.length +
      hubsReport?.limited?.length >
    0;
  return (
    !hubTotal &&
    !flashReport.length &&
    isObjectEmpty(selectedReportData) &&
    isObjectEmpty(libraryFormDetails)
  );
};
