import React, { useEffect, useState } from "react";
import AttachFilesWrapper from "./AttachFiles.style";
import { Checkbox, List, Modal, Select, Spin } from "antd";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  useAttachCustomReportMutation,
  useDeleteCustomAttachReportMutation,
  useAssignCustomReportMutation,
} from "../../../redux/api/assignReportApi";

const Option = Select.Option;

const AttachFiles = ({
  user_reports = [],
  loading = false,
  visible = false,
  closeAttachModal,
  reportListData = [],
  reportToAttach,
  // handleReportsToAttach,
  spinning = false,
  userId,
  reportId,
  syndicateData = false,
  showSyndicate,
}) => {
  let available_reports_list = reportListData?.map((report, i) => (
    <Option
      key={report.report_id}
      value={`${report.type}:${report.report_id}:${report.document_id}`}
    >
      {`${report?.version ? `V(${report?.version}): ` : ""}`}
      {`${report?.comment ? `(${report?.comment}): ` : ""}`}
      {`${report?.is_blur === "yes" ? "(Blur) " : ""}`}
      {report?.file_name?.split("/")[2] ?? report?.file_name} - ({report.type})
    </Option>
  ));

  const [filteredReport, setFilteredReport] = useState([]);

  const handleReportsToAttach = (value) => {
    setFilteredReport(value);
  };

  const [assigningCustomReport, { isLoading: assigningCustomReportLoading }] =
    useAssignCustomReportMutation();

  const [
    assignCustomReport,
    {
      data: assignCustomReportData,
      error: assignCustomReportError,
      isLoading: assignCustomReportLoading,
    },
  ] = useAttachCustomReportMutation();

  const [
    deleteCustomReport,
    {
      data: deleteCustomReportData,
      error: deleteCustomReportError,
      isLoading: deleteCustomReportLoading,
    },
  ] = useDeleteCustomAttachReportMutation();

  const [isSyndicate, setIsSyndicate] = useState();
  const [isEmailToClient, setIsEmailToClient] = useState();

  useEffect(() => {
    if (syndicateData) {
      setIsSyndicate(syndicateData);
    }
  }, [syndicateData]);

  useEffect(() => {
    if (isEmailToClient) {
      setIsEmailToClient(isEmailToClient);
    }
  }, []);

  const assignReportToAttach = async (report_id, user_id) => {
    await assigningCustomReport({
      report_id,
      user_id,
      is_syndicate_report: isSyndicate,
    });
  };

  const addReportsToAttach = async () => {
    let documentIds = [];
    filteredReport.forEach((report) => {
      let reportData = report.split(":");
      documentIds.push(parseInt(reportData[2]));
    });

    // ASSIGN REPORTS
    await assignReportToAttach(reportId, userId);

    let body = {
      user_id: userId,
      report_id: reportId,
      document_ids: documentIds,
      is_email_to_client: isEmailToClient,
    };
    assignCustomReport(body)
      .then((res) => {
        if (res?.data?.message) {
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 5000,
          });
          closeAttachModal();
        }
        if (res.error.data) {
          toast.error(res.error.data.message, {
            position: "top-center",
            autoClose: 5000,
          });
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message, {
          position: "top-center",
          autoClose: 5000,
        });
      });
  };

  useEffect(() => {
    if (reportListData) {
      //Select attachReportData which is available in the user reports
      //Match the reportId with the user reports and set the reportToAttach
      let reportList = reportListData;
      let userReports = user_reports;
      let reports = [];
      reportList.forEach((report) => {
        userReports.forEach((userReport) => {
          const mainReportIdString = report.report_id.toString();
          if (
            mainReportIdString === userReport.report_id &&
            report.type === userReport.type &&
            userReport.file_name === report.file_name
          ) {
            reports.push(
              `${report.type}:${report.report_id}:${report.document_id}`
            );
          }
        });
      });
      setFilteredReport(reports);
    } else {
      setFilteredReport([]);
    }
  }, [reportListData]);

  return (
    <AttachFilesWrapper>
      <Modal
        visible={visible}
        title={
          <h3 className="conversation-header">
            Attach Reports to Conversation
          </h3>
        }
        onOk={addReportsToAttach}
        okText="Attach"
        okButtonProps={{
          loading:
            loading || assignCustomReportLoading || deleteCustomReportLoading,
        }}
        onCancel={closeAttachModal}
        className="attach-files-modal"
        destroyOnClose={true}
      >
        <Spin
          spinning={
            spinning || assignCustomReportLoading || deleteCustomReportLoading
          }
          tip="Loading List of Files"
        >
          <div className="attach-files-container">
            <div className="purchased-user-reports">
              <List
                header={
                  <div className="flex report-list-header">
                    <p className="report-name">Name</p>
                    <p className="report-type">Type</p>
                  </div>
                }
                dataSource={user_reports}
                renderItem={(report, i) => (
                  <List.Item key={i + 1}>
                    <div className="flex report-list-item">
                      <p className="report-name">{report.name}</p>
                      <p className="report-type">{report.type}</p>
                    </div>
                  </List.Item>
                )}
                pagination={true}
              />
            </div>
            <div className="update-user-reports">
              <h3>Show available reports list here</h3>
              <Select
                style={{ width: "100%" }}
                onChange={handleReportsToAttach}
                allowClear
                mode="multiple"
                placeholder="Select reports to attach"
                value={filteredReport ?? []}
                key={_.uniqueId()}
                onDeselect={(value) => {
                  deleteCustomReport({
                    user_id: userId,
                    report_id: reportId,
                    document_id: parseInt(value.split(":")[2]),
                    report_type: value.split(":")[0],
                  })
                    .then((res) => {
                      if (res?.data?.message) {
                        toast.success(res?.data?.message, {
                          position: "top-center",
                          autoClose: 5000,
                        });
                      } else {
                        toast.error(res?.data?.message, {
                          position: "top-center",
                          autoClose: 5000,
                        });
                      }
                    })
                    .catch((err) => {
                      toast.error(err?.data?.message, {
                        position: "top-center",
                        autoClose: 5000,
                      });
                    });
                }}
              >
                {available_reports_list}
              </Select>
              {/* Checkbox for Include Syndicate Report */}
              {showSyndicate && (
                <Checkbox
                  checked={isSyndicate}
                  onChange={(e) => setIsSyndicate(e.target.checked)}
                  style={{ marginTop: "10px" }}
                >
                  Include syndicate report
                </Checkbox>
              )}
              <Checkbox
                checked={isEmailToClient}
                onChange={(e) => setIsEmailToClient(e.target.checked)}
                style={{ marginTop: "10px" }}
              >
                Send email to client
              </Checkbox>
            </div>
          </div>
        </Spin>
      </Modal>
    </AttachFilesWrapper>
  );
};

export default AttachFiles;
