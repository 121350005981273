import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tabs, Card, Row, Col, Tooltip, Button } from "antd";
import { format } from "date-fns";
import { ArrowLeftOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { GenerateCard } from "./SelectedCard";
import { useUpdateUserDataMutation } from "../../redux/api/createUserApi";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const ReviewTheDataCards = ({
  handleBackReportAssignment,
  selectedUserEmail,
  navigateToUserList,
  selectedUserName
}) => {
  const { trial, full, limited } = useSelector(
    (state) => state.assignedReportsNew
  );

  const flashReports = useSelector(
    (state) => state.flashReportAssignment.flash
  );
  const [showHubs, setShowHubs] = useState(true);
  const [showFlash, setShowFlash] = useState(true);
  const [showLibrary, setShowLibrary] = useState(true);
  const [updateUser, updateResult] = useUpdateUserDataMutation();
  const LibraryData = useSelector(
    (state) => state.libraryAssignmentMain.selectedReport
  );
  const libraryFormData = useSelector(
    (state) => state.libraryAssignmentMain.libraryFormDetails
  );
  const navigate = useNavigate();
  const HubsData = useSelector((state) => state.assignedReportsNew);
  const transformLibraryData = (data) => {
    return data.map((item) => ({
      synapse_report_id: item.synapse_report_id,
      title: item.title,
      subscription_date: formatDate(item.subscription_date),
      expiry_date: formatDate(item.expiry_date),
    }));
  };

  const transformDateArray = (data) => {
    return data.map((item) => ({
      id: item.id,
      title: item.title,
      subscription_date: formatDate(item.subscription_date),
      expiry_date: formatDate(item.expiry_date),
    }));
  };

  // Helper function to format date in mm/dd/yyyy
  const formatDate = (dateStr) => {
    if (!dateStr) {
      return "N/A";
    }
    const date = new Date(dateStr);
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero
    const day = ("0" + date.getDate()).slice(-2); // Add leading zero
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  // Helper function to transform report items
  const transformReport = (report) => ({
    id: report.id,
    title: report.title,
    subscription_date: formatDate(report.subscription_date),
    expiry_date: formatDate(report.expiry_date),
  });

  // Helper function to transform data items
  const transformItem = (item) => ({
    // hub_id: parseInt(item.selectHub.id, 10),
    // title: item.selectHub.name,
    selected_hubs: item.selectHub,
    access_type: item.access_type,
    modules: item.modules,
    subscription_date:
      // item.access_type === "limited"
      //   ? null
      //   :
      item?.subscription_date ? formatDate(item.subscription_date) : null,
    expiry_date:
      // item.access_type === "limited"
      //   ? null
      //   :
      item?.expiry_date ? formatDate(item.expiry_date) : null,
    reports_arr: item?.report ? item?.report?.map(transformReport) : [],
  });

  // Transform each type of access
  const hubs = ["trial", "full", "limited"].flatMap((type) =>
    HubsData[type].map(transformItem)
  );

  const getLibraryData = () => {
    const computeData = {
      access_type: null,
      expiry_date: null,
      subscription_date: null,
      reports_arr: [],
    };
    if (libraryFormData.hubAccessType === "trial") {
      computeData.access_type = "trial";
      // computeData.expiry_date = libraryFormData.expiry_date
      //   ? dayjs(libraryFormData.expiry_date).format("YYYY/MM/DD")
      //   : null;
      return computeData;
    } else if (libraryFormData.hubAccessType === "full") {
      computeData.access_type = "full";
      computeData.expiry_date = libraryFormData.expiry_date
        ? dayjs(libraryFormData.expiry_date).format("YYYY/MM/DD")
        : null;
      computeData.subscription_date = libraryFormData.subscription_date
        ? dayjs(libraryFormData.subscription_date).format("YYYY/MM/DD")
        : null;

      return computeData;
    } else if (libraryFormData.hubAccessType === "limited") {
      if (LibraryData && LibraryData.length > 0) {
        computeData.access_type = "limited";
        computeData.subscription_date = null;
        computeData.expiry_date = null;
        computeData.reports_arr = transformLibraryData(LibraryData);
        return computeData;
      } else {
        computeData.access_type = "limited";
        computeData.subscription_date = null;
        computeData.expiry_date = null;
        computeData.reports_arr = [];
        return computeData;
      }
    } else {
      return {};
    }
  };

  const UpdateTheUser = () => {
    // debugger;
    const computeData = {
      email: selectedUserEmail,
      data: {
        hubs: hubs,
        flash: transformDateArray(flashReports),
        // library:
        //   LibraryData && LibraryData.length > 0
        //     ? {
        //         access_type: "limited",
        //         subscription_date: null,
        //         expiry_date: null,
        //         reports_arr: transformLibraryData(LibraryData),
        //       }
        //     : {},
        library: getLibraryData(),
      },
    };
    updateUser(computeData)
      .then((res) => {
        if (res?.error?.data) {
          toast.error(res?.error?.data ?? "Failed to submit");
          return;
        }
        toast.success("Report assigned successfully");
        navigateToUserList();
        //   form.resetFields();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const reportData = useSelector((state) => state.libraryAssignmentMain);

  const renderTrialAndFullCards = (data) =>
    data.map((item, index) => (
      <Col span={12} key={`trial-full-${index}`}>
        <Card
          title={`${
            item?.access_type?.charAt(0).toUpperCase() +
            item?.access_type?.slice(1)
          }`}
          style={{ marginBottom: 16 }}
        >
          <p>Hub: {item?.selectHub?.map((item) => item?.name).join(", ")}</p>
          {item?.modules?.length > 0 && (
            <p>
              <strong>Module Access:</strong> {item?.modules?.join(", ")}
            </p>
          )}
          {/* {item?.access_type === "full" && ( */}
          <>
            <p>
              <strong>Subscription Starts:</strong>{" "}
              {formatDate(item.subscription_date)}
            </p>
            <p>
              <strong>Subscription Ends:</strong> {formatDate(item.expiry_date)}
            </p>
          </>
          {/* )} */}
        </Card>
      </Col>
    ));

  const renderLimitedCards = (data) =>
    data.map((item, index) => (
      <Col span={12} key={`limited-${index}`}>
        <Card
          title={`${
            item?.access_type?.charAt(0).toUpperCase() +
            item?.access_type?.slice(1)
          }`}
          style={{ marginBottom: 16 }}
        >
          <p>Hub: {item?.selectHub?.map((item) => item?.name)}</p>
          <p>
            <strong>Module Access:</strong> {item?.modules?.join(", ")}
          </p>
          {/* <p>
            <strong>Subscription Date:</strong>{" "}
            {formatDate(item.subscription_date)}
          </p>
          <p>
            <strong>Expiry Date:</strong> {formatDate(item.expiry_date)}
          </p> */}
          <p>
            <strong>Reports:</strong>
          </p>
          <ul>
            {item.report ? (
              item.report.map((report, idx) => (
                <li key={`report-${idx}`}>
                  {idx + 1}
                  {") "}
                  {report.title} - Subscription Starts:{" "}
                  {formatDate(report.subscription_date)}, Subscription Ends:{" "}
                  {formatDate(report.expiry_date)}
                </li>
              ))
            ) : (
              <li>N/A</li>
            )}
          </ul>
        </Card>
      </Col>
    ));

  const renderFlashCards = (data) =>
    data.map((item, index) => (
      <Col span={12} key={`flash-${index}`}>
        <Card title={item.title} style={{ marginTop: 16 }}>
          <p>
            <strong>Subscription Date:</strong>{" "}
            {formatDate(item.subscription_date)}
          </p>
          <p>
            <strong>Expiry Date:</strong> {formatDate(item.expiry_date)}
          </p>
        </Card>
      </Col>
    ));

  const renderLibraryOtherCards = (data, isTrial = false) => {
    if (Object.keys(data).length === 0) return null;
    return (
      <Col style={{ marginBottom: "1rem" }} span={6} key={_.uniqueId()}>
        <Card title={data.hubAccessType}>
          {!isTrial ? (
            <>
              <p>
                <strong>Subscription Date:</strong>{" "}
                {data?.subscription_date
                  ? dayjs(data.subscription_date).format("YYYY/MM/DD")
                  : "N/A"}
              </p>
              <p>
                <strong>Subscription End:</strong>{" "}
                {data?.expiry_date
                  ? dayjs(data.expiry_date).format("YYYY/MM/DD")
                  : "N/A"}
              </p>
            </>
          ) : (
            <p>
              <strong>Expiry Date:</strong>{" "}
              {/* {data?.expiry_date
                ? dayjs(data.expiry_date).format("YYYY/MM/DD")
                : "N/A"} */}
              N/A
            </p>
          )}
        </Card>
      </Col>
    );
  };

  return (
    <>
      <div
        className="flex section-header"
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tooltip title="Go Back" onClick={handleBackReportAssignment}>
          <ArrowLeftOutlined className="back-arrow-btn" />
        </Tooltip>
        <Button onClick={() => UpdateTheUser()}>Confirm</Button>
      </div>
      {trial?.length + full?.length + limited?.length > 0 && (
        <div
          style={{
            marginBottom: "20px",
            borderBottom: "1px solid grey",
            paddingBottom: "20px",
          }}
        >
          <Button onClick={() => setShowHubs(!showHubs)}>
            Hubs {!showHubs ? <DownOutlined /> : <UpOutlined />}
          </Button>
          {showHubs && (
            <>
              <Tabs defaultActiveKey="1">
                {trial?.length > 0 && (
                  <TabPane tab="Trial" key="1">
                    <Row gutter={[16, 16]}>
                      {renderTrialAndFullCards(trial)}
                    </Row>
                  </TabPane>
                )}
                {full?.length > 0 && (
                  <TabPane tab="Full" key="2">
                    <Row gutter={[16, 16]}>{renderTrialAndFullCards(full)}</Row>
                  </TabPane>
                )}
                {limited?.length > 0 && (
                  <TabPane tab="Limited" key="3">
                    <Row gutter={[16, 16]}>{renderLimitedCards(limited)}</Row>
                  </TabPane>
                )}
              </Tabs>
            </>
          )}
        </div>
      )}
      {flashReports?.length > 0 && (
        <div
          style={{
            marginBottom: "20px",
            borderBottom: "1px solid grey",
            paddingBottom: "20px",
          }}
        >
          <Button onClick={() => setShowFlash(!showFlash)}>
            Flash{!showFlash ? <DownOutlined /> : <UpOutlined />}
          </Button>
          {showFlash && (
            <Row gutter={[16, 16]}>{renderFlashCards(flashReports)}</Row>
          )}
        </div>
      )}
      <div
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid grey",
          paddingBottom: "20px",
          visibility:
            libraryFormData.hubAccessType ||
            reportData?.selectedReport?.length > 0
              ? "visible"
              : "hidden",
        }}
      >
        <Button onClick={() => setShowLibrary(!showLibrary)}>
          Library{!showLibrary ? <DownOutlined /> : <UpOutlined />}
        </Button>
        {showLibrary && (
          <>
            <Tabs defaultActiveKey="1">
              {libraryFormData.hubAccessType === "trial" && (
                <TabPane tab="Trial" key="1">
                  <Row gutter={[16, 16]}>
                    {renderLibraryOtherCards(
                      libraryFormData.hubAccessType === "trial"
                        ? libraryFormData
                        : {},
                      true
                    )}
                  </Row>
                </TabPane>
              )}
              {libraryFormData.hubAccessType === "full" && (
                <TabPane tab="Full" key="2">
                  <Row gutter={[16, 16]}>
                    {renderLibraryOtherCards(
                      libraryFormData.hubAccessType === "full"
                        ? libraryFormData
                        : {}
                    )}
                  </Row>
                </TabPane>
              )}
              {libraryFormData.hubAccessType === "limited" && (
                <TabPane tab="Limited" key="3">
                  {libraryFormData.hubAccessType === "limited" ? (
                    <Row gutter={[16, 16]}>
                      {reportData.selectedReport.map((report) => (
                        <GenerateCard key={report.key} report={report} />
                      ))}
                    </Row>
                  ) : null}
                </TabPane>
              )}
            </Tabs>
          </>
        )}
      </div>
    </>
  );
};

export default ReviewTheDataCards;
