import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Modal, message, Drawer } from 'antd';
import { format } from 'date-fns';
import AssignReportForm from './AssignReportForm';
import { deleteReport } from '../../redux/api/assignedReportsNewSlice';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import './ReportAssignment.css'

const AssignedReports = () => {
  const { trial, full, limited } = useSelector(state => state.assignedReportsNew);
  const dispatch = useDispatch();
  const formatDate = (dateString) => {
    return dateString ? format(new Date(dateString), 'yyyy-MM-dd') : 'N/A';
  };
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [editFormData, setEditFormData] = React.useState(null);
  const showEditModal = (record) => {
    setEditFormData(record);
    setEditModalVisible(true);
  };
  const { confirm } = Modal;
  const handleEditCancel = () => {
    setEditFormData(null);
    setEditModalVisible(false);
  };

  const handleDelete = (record) => {
    const { access_type, selectHub } = record;
    dispatch(deleteReport({ access_type, selectHub }));
    message.success('Report deleted successfully.');
  };

  const columns = [
    {
      title: 'Hub Level Access Type',
      dataIndex: 'access_type',
      key: 'access_type',
      render: text => text?.charAt(0).toUpperCase() + text?.slice(1), // Capitalize first letter
    },
    {
      title: 'Hub Module Access',
      dataIndex: 'modules',
      key: 'modules',
      render: (text) => text?.join(', '),
    },
    {
      title: 'Selected Hubs',
      dataIndex: 'selectHub',
      key: 'selectHub',
      render: hubs => hubs.map(hub => hub?.name?.charAt(0).toUpperCase() + hub?.name?.slice(1)).join(', '),
    },    
    {
      title: 'Subscription Starts',
      dataIndex: 'subscription_date',
      key: 'subscription_date',
      render: (text) => formatDate(text),
    },
    {
      title: 'Subscription Ends',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (text) => formatDate(text),
    },
    {
      title: 'Total Reports',
      dataIndex: 'report',
      key: 'report',
      render: (text) => (text ? text.length : "N/A"),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <div style={{display:'flex'}}>
          <Button type="primary" style={{height: '40px'}} onClick={() => showEditModal(record)}><CreateOutlinedIcon/></Button>
          <Button
            type="danger"
            style={{ backgroundColor: 'red', borderColor: 'red', color: 'white',marginLeft:'5px',height: '40px' }}
            onClick={() => showDeleteConfirm(record, handleDelete)}
          >
            <DeleteOutlineOutlinedIcon/>
          </Button>
        </div>
      ),
    },
  ];

  const showDeleteConfirm = (record, handleDelete) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const data = [
    ...trial.map((item, index) => ({ ...item, key: `trial-${index}` })),
    ...full.map((item, index) => ({ ...item, key: `full-${index}` })),
    ...limited.map((item, index) => ({ ...item, key: `limited-${index}` })),
  ];

  return (
    <>
      <Table style={{border:'1px solid lightgray',borderRadius:'10px'}} className="custom-table" columns={columns} dataSource={data} rowKey="key" />

      {/* Edit Modal */}
      <Drawer
      title="Edit Assigned Report"
      placement="right"
      onClose={handleEditCancel}
      open={editModalVisible}
      width={'50%'}
    >
      {editFormData && <AssignReportForm formData={editFormData} onCancel={handleEditCancel} />}
    </Drawer>
    </>
  );
};

export default AssignedReports;
