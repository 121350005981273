// src/redux/limitedReports.js
import { createSlice } from '@reduxjs/toolkit';

const limitedReportsSlice = createSlice({
  name: 'limitedReports',
  initialState: [],
  reducers: {
    addRowKey: (state, action) => {
      state.push(action.payload);
    },
    removeRowKey: (state, action) => {
      return state.filter(id => id !== action.payload);
    },
    setRowKeys: (state, action) => {
      return action.payload;
    },
  },
});

export const { addRowKey, removeRowKey, setRowKeys } = limitedReportsSlice.actions;
export default limitedReportsSlice.reducer;
