import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import interfaceReducer from "./interfaceSlice";
import assignPermissionReducer from "./assignPermissionSlice";
import cryptApi from "../components/API/cryptApi";
import { postApi } from "./postApi";
import { createUserApi } from "./api/createUserApi";
import SidebarReducer from "./sidebarSlice";
import { assignReportApi } from "./api/assignReportApi";
import libraryAssignmentReducer from "./libraryAssignment";
import libraryAssignmentMainReducer from "./libraryAssignmentMain";
import assignedReportsNewReducer from "../redux/api/assignedReportsNewSlice";
import flashReportAssignmentReducer from "./flashReportAssignment";
import selectedUserReducer from "./selectedUserSlice";
import limitedReportsReducer from "./assignedReportsLimitedReport";
import userPermissionReducer from "./userPermission";

const store = configureStore({
  reducer: {
    interface: interfaceReducer,
    assignPermission: assignPermissionReducer,
    libraryAssignment: libraryAssignmentReducer,
    libraryAssignmentMain: libraryAssignmentMainReducer,
    [cryptApi.reducerPath]: cryptApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [createUserApi.reducerPath]: createUserApi.reducer,
    [assignReportApi.reducerPath]: assignReportApi.reducer,
    slider: SidebarReducer,
    assignedReportsNew: assignedReportsNewReducer,
    flashReportAssignment: flashReportAssignmentReducer,
    selectedUser: selectedUserReducer,
    limitedReports: limitedReportsReducer,
    userPermission: userPermissionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      cryptApi.middleware,
      postApi.middleware,
      createUserApi.middleware,
      assignReportApi.middleware,
      logger
    ),
});

export default store;
