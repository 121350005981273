let filteredArr = {
    Global: [
      'Turkey',
      'Sweden',
      'Colombia',
      'France',
      'Indonesia',
      'Philippines',
      'Egypt',
      'Vietnam',
      'Ethiopia',
      'Malaysia',
      'United Kingdom',
      'Hong Kong',
      'Australia',
      'Estonia',
      'Italy',
      'Brazil',
      'Oman',
      'Austria',
      'Papua New Guinea',
      'Netherlands',
      'Thailand',
      'Pakistan',
      'Mexico',
      'Spain',
      'Ireland',
      'Denmark',
      'China',
      'Kazakhstan',
      'Taiwan',
      'Japan',
      'Germany',
      'South Africa',
      'Libya',
      'Ecuador',
      'Chile',
      'India',
      'Qatar',
      'Equatorial Guinea',
      'Israel',
      'Argentina',
      'Romania',
      'Canada',
      'Uzbekistan',
      'Turkmenistan',
      'Tanzania',
      'South Korea',
      'Singapore',
      'Azerbaijan',
      'New Zealand',
      'Venezuela',
      'Czech Republic',
      'Angola',
      'Saudi Arabia',
      'Finland',
      'Kuwait',
      'Algeria',
      'Sudan',
      'Russia',
      'Uganda',
      'Iraq',
      'Bangladesh',
      'Poland',
      'Peru',
      'Iran',
      'Myanmar',
      'Norway',
      'Bahrain',
      'Switzerland',
      'Portugal',
      'Cameroon',
      'Belgium',
      'Morocco',
      'Uruguay',
      'Slovakia',
      'Sri Lanka',
      'Nigeria',
      'Greece',
      'Hungary',
      'Brunei',
      'Ghana',
      'Cuba',
      'Chad',
      'Gabon',
      'Bulgaria',
      'Trinidad and Tobago',
      'Zimbabwe',
      'Tunisia',
      'Belarus',
      'Nepal',
      'Slovenia',
      'Congo, Republic of the',
      'Paraguay',
      'Cambodia',
      'Laos',
      'Kenya',
      'Jordan',
      'Croatia',
      'Maldives',
      'Central African Republic',
      'Iceland',
      'Ukraine',
      'Armenia',
      'Moldova',
      'Albania',
      'Malta',
      'Cyprus',
      'Luxembourg',
      'Latvia',
      'Panama',
      'Lithuania',
      'Macedonia',
      'Serbia',
      'Costa Rica',
      'Serbia and Montenegro',
      'Botswana',
      'Bolivia',
      'US',
      'UK',
      'UAE',
      'Dominican Republic',
      'Honduras'
    ],
    'North America': [ 'US', 'Canada', 'Mexico', 'Cuba', 'Panama', 'Costa Rica','Dominican Republic', 'Honduras'],
    'South America': ['Brazil', 'Argentina', 'Colombia', 'Chile', 'Ecuador', 'Venezuela', 'Peru', 'Uruguay', 'Trinidad and Tobago', 'Paraguay','Bolivia'],
    Europe: [
      'Russia',
      'Germany',
      'France',
      'UK',
      'Poland',
      'Italy',
      'Spain',
      'Netherlands',
      'Ukraine',
      'Ireland',
      'Denmark',
      'Bulgaria',
      'Norway',
      'Estonia',
      'Sweden',
      'Austria',
      'Romania',
      'Czech Republic',
      'Finland',
      'Switzerland',
      'Portugal',
      'Belgium',
      'Slovakia',
      'Greece',
      'Hungary',
      'Belarus',
      'Slovenia',
      'Croatia',
      'Iceland',
      'Moldova',
      'Albania',
      'Malta',
      'Cyprus',
      'Luxembourg',
      'Latvia',
      'Lithuania',
      'Macedonia',
      'Serbia',
      'Serbia and Montenegro'
    ],
    'Asia Pacific': [
      'China',
      'India',
      'Japan',
      'Australia',
      'South Korea',
      'Vietnam',
      'Thailand',
      'Indonesia',
      'New Zealand',
      'Philippines',
      'Malaysia',
      'Singapore',
      'Pakistan',
      'Hong Kong',
      'Turkey',
      'Oman',
      'Kazakhstan',
      'Taiwan',
      'Uzbekistan',
      'Turkmenistan',
      'Azerbaijan',
      'New Zealand',
      'Bangladesh',
      'Bahrain',
      'Myanmar',
      'Sri Lanka',
      'Brunei',
      'Nepal',
      'Cambodia',
      'Laos',
      'Maldives',
      'Armenia',
    ],
    'Middle East': [
      'Turkey',
      'Saudi Arabia',
      'Iran',
      'Kuwait',
      'Iraq',
      'Israel',
      'Qatar',
      'Jordan',
      'UAE'
    ],
    Africa: [
      'South Africa',
      'Kenya',
      'Egypt',
      'Morocco',
      'Nigeria',
      'Angola',
      'Cameroon',
      'Ethiopia',
      'Libya',
      'Equatorial Guinea',
      'Tanzania',
      'Algeria',
      'Sudan',
      'Uganda',
      'Ghana',
      'Chad',
      'Gabon',
      'Zimbabwe',
      'Tunisia',
      'Congo, Republic of the',
      'Central African Republic',
      'Botswana'
    ],
    'Middle East and Africa': ['South Africa','Egypt','Morocco','Nigeria','Qatar','Saudi Arabia','United Arab Emirates','UAE'],
    Asia: [
      'China',
      'India',
      'Japan',
      'Australia',
      'South Korea',
      'Vietnam',
      'Thailand',
      'Indonesia',
      'New Zealand',
      'Philippines',
      'Malaysia',
      'Singapore',
      'Pakistan',
      'Hong Kong',
      'Turkey',
      'Oman',
      'Kazakhstan',
      'Taiwan',
      'Uzbekistan',
      'Turkmenistan',
      'Azerbaijan',
      'New Zealand',
      'Bangladesh',
      'Bahrain',
      'Myanmar',
      'Sri Lanka',
      'Brunei',
      'Nepal',
      'Cambodia',
      'Laos',
      'Maldives',
      'Armenia',
    ],
    'Latin America': ['Brazil','Argentina','Chile','Colombia','Mexico','Bolivia','Costa Rica','Ecuador','El Salvador','Guatemala','Honduras','Panama','Venezuela','French Guiana','Nicaragua','Uruguay','Belize','Guyana','Paraguay','Cuba','Dominican Republic','Peru','Suriname'],
    Americas: [],
    'South East Asia': ['Papua New Guinea'],
    'North America and Europe': [],
    'Middle East and North Africa': [],
    'South Asia': [],
    'Europe, Middle East and Africa': [],
    'Australia and New Zealand': ['New Zealand', 'Australia'],
    GCC: ['Qatar','Saudi Arabia', 'UAE', 'United Arab Emirates'],
    // 'Rest of World': [],
  };

  export default filteredArr;
