const URLS = {
  driftImage :
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/drift_design.svg",
  synapseLogo :
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/logo.svg",
  heroBanner1 :
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/hero_banner_1.svg",
  heroBanner2 :
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/hero_banner_2.svg",
};

export default URLS;
