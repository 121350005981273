import styled from 'styled-components';

const UserReportsListWrapper = styled.div`

.ant-list-header{
  background: #67bfff;
  padding: 15px;
  color: white;
}
.user-wrapper {
  align-items: center;
  width: 100%;
  .user-name, .user-actions {
    margin: 0;
    width: 10%;
    align-self: center;
  }
  .view-chat-icon {
    opacity: 0;
    cursor: pointer;
    font-size: 20px;
    pointer-events: none;
    transition: all 0.3s ease-out;
    margin-left: 10px;
  }
  .user-email-id {
    margin: 0;
    width: 80%;
    align-self: center;
  }
}
.header-user-wrapper {
  align-items: center;
  width: 100%;
  .user-name {
    width: 10%;
    margin: 0;
    font-size: 18px;
    color: white;
  }
  .user-email-id {
    margin: 0;
    width: 80%;
    font-size: 18px;
    color: white;
  }
}
.ant-list-item {
  position: relative;
  padding: 15px;
  &:hover {
    color: #2979ff;
    background-color: #f0f8ff;
    .view-chat-icon {
      opacity: 1;
      pointer-events: all;
    }
  }
}
.section-header {
  align-items: center;
  h2 {
    margin:0 0 0 20px;
  }
  .back-arrow-btn {
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-out;
    &:hover {
      transform:rotate(360deg);
    }
  }
}

`;

export default UserReportsListWrapper;