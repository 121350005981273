import Styled from "styled-components";

export const MainHeaderWrapper = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    .user-icon {
        border-radius:50%;
    }
    .logout-btn {
        height: 30px;
    }

`;


export const UserBadge = Styled.div`
border:2px solid #189CDE;
border-radius: 50%;
height: 45px;
width:45px;
background-color: #BDBDBD;
margin-right: 25px;
display:flex;
justify-content:center;
align-items:center;
&:hover{
cursor:pointer;
}
`;
