import styled from 'styled-components';

const FooterWrapper = styled.div`
  .footer-container {
    background-color: #fff;
    box-shadow: 0 0 5px 1px #b2b2b2;
    padding: 10px;
    
    .footer-text {
      margin: 0;
      text-align: center;
    }
    
  }
`;

export default FooterWrapper;