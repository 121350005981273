import { Card, Row, Col } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

export const GenerateCard = ({ report }) => {
  return (
    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
      <Card title={report.title} style={{ width: "100%" }}>
        {/* <p>
          <strong>Slug:</strong> {report.slug}
        </p>
        <p>
          <strong>Forecast Year:</strong> {report.forecast_year}
        </p>
        <p>
          <strong>Study Period:</strong> {report.study_period}
        </p>
        <p>
          <strong>Synapse Report ID:</strong> {report.synapse_report_id}
        </p>
        <p>
          <strong>Key:</strong> {report.key}
        </p>
        <p>
          <strong>ID:</strong> {report.id}
        </p> */}
        <p>
          <strong>Subscription Start:</strong>{" "}
          {dayjs(report?.subscription_date).format("MM/DD/YYYY")}
        </p>
        <p>
          <strong>Subscription End:</strong>{" "}
          {dayjs(report.expiry_date).format("MM/DD/YYYY")}
        </p>
      </Card>
    </Col>
  );
};

const SelectedCard = () => {
  const reportData = useSelector((state) => state.libraryAssignmentMain);
  return (
    <Row gutter={16}>
      {reportData.selectedReport.map((report) => (
        <GenerateCard key={report.key} report={report} />
      ))}
    </Row>
  );
};

export default SelectedCard;
